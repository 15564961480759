export const SORT_OPEN_BY = {
    VIEWS: 'views',
    LAST_ACTION_DATE: 'lastActionDate',
    CREATION_DATE: 'creationDate',
}

export const sortParamsOpened = [
    { id: SORT_OPEN_BY.VIEWS, sortOrder: 'desc', title: 'Most Popular', name: 'Most Popular' },
    { id: SORT_OPEN_BY.LAST_ACTION_DATE, sortOrder: 'desc', title: 'Last action', name: 'Last action' },
    { id: SORT_OPEN_BY.CREATION_DATE, sortOrder: 'desc', title: 'Creation date', name: 'Creation date' },
];

export const SORT_CLOSED_BY = {
    REASON: 'reason',
    LATEST_CLOSED: 'closedAt',
    CLUB: 'club',
    CREATION: 'cratedAt',
}

export const sortParamsClosed = [
    { id: SORT_CLOSED_BY.LATEST_CLOSED, sortOrder: 'desc', title: 'Latest Closed', name: 'Latest Closed' },
    { id: SORT_CLOSED_BY.REASON, sortOrder: 'desc', title: 'Close reason', name: 'Close reason' },
    { id: SORT_CLOSED_BY.CLUB, sortOrder: 'desc', title: 'Club', name: 'Club' },
    { id: SORT_CLOSED_BY.CREATION, sortOrder: 'desc', title: 'Creation time', name: 'Creation time' },
];

export const SORT_PLAYER_BY = {
    VIEWS: 'views',
    LAST_ACTION_DATE: 'lastActionDate',
    CREATION_DATE: 'creationDate',
}

export const sortPlayerParams = [
    { id: SORT_PLAYER_BY.VIEWS, sortOrder: 'desc', title: 'Most Popular', name: 'Most Popular' },
    { id: SORT_PLAYER_BY.LAST_ACTION_DATE, sortOrder: 'desc', title: 'Last action', name: 'Last action' },
    { id: SORT_PLAYER_BY.CREATION_DATE, sortOrder: 'desc', title: 'Creation date', name: 'Creation date' },
];