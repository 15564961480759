import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { UPDATE_PASSWORD } from 'store/actionTypes';
import userActivityInsert from '../../user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

const logUpdatePasswordEpic = (action$, state$) => {
    return action$.pipe(
        ofType(UPDATE_PASSWORD.SUCCESS),
        map(() => {
            const isSuccess = state$.value.editprofile.isSuccess;

            const activityMessage = isSuccess
                ? 'Set New Password (Success)'
                : 'Set New Password (Error)';

            return userActivityInsert({
                Message: activityMessage,
                PageName: 'Edit Profile [Change pasword]',
                PageType: PageType.EditProfile,
            });
        }),
    );
};

export default logUpdatePasswordEpic;
