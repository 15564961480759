import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyFinderService from 'api/player-v2/agency-finder.service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as SearchActions } from './search.controller'
import { AgencyModel, PlayerMarkedInterestAreaSourceEnum } from 'api/player-v2/models'
import { getAuth } from 'store/auth/authReducer';


class State {
    processingIds: number[]
}

const defaultState: State = {
    processingIds: []
}

const stateController = new StateController<State>("PLAYERV2/AGENCY-FINDER/AGENCIES", defaultState);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static requestRepresentation(item: AgencyModel) {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState())
            const { agencyId } = item;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                processingIds: [...prevState.processingIds, agencyId]
            })))
            try {
                await AgencyFinderService.sendRepresentationRequestToAgency([agencyId]);

                let areaIds = [];
                if (item.primaryMarketArea != null && item.primaryMarketArea.id != null) areaIds.push(item.primaryMarketArea.id);
                if (item.secondaryMarketArea != null && item.secondaryMarketArea.id != null) areaIds.push(item.secondaryMarketArea.id);
                AgencyFinderService.savePlayerInterestedMarkets(areaIds, PlayerMarkedInterestAreaSourceEnum.Suggestion);
                dispatch(SearchActions.reloadAgencyItem(agencyId))
                dispatch(userActivityInsert({
                    PageName: 'Agent Finder [Countries]',
                    Message: `Clicked Request Representation: ${item.agencyName}`,
                    PlayerId: playerId,
                }))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingIds: prevState.processingIds.filter(x => x !== agencyId)
                })))
            }
        }
    }
    public static sendUserActivity(agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Countries]',
                Message: `Opened Agency Profile`,
                PlayerId: playerId,
                AgencyId: agencyId,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.agencyFinder.agencies;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



