import compose from 'lodash/fp/compose';
import * as types from 'store/actionTypes';
import { getRequests } from 'store/requests/requestsReducer';
import PasswordValidation from 'utils/validate-password';

const initialState = {
    personal: {
        fullName: '',
        email: '',
        clubName: '',
        positions: [],
        phone: '',
        userPositionId: 0,
        userPositionName: '',
    },
    usersList: [],
    positions: [],
    isCreatingUser: false,
    isSuccess: false,
    isAgencyDeactivationProcessing: false,
    passwordRules: null,
    isOldPasswordValid: null,
};

export default (editProfile = initialState, action) => {
    switch (action.type) {
        case types.PROFILE_PERSONAL.SUCCESS:
            return {
                ...editProfile,
                personal: { ...action.payload.data },
            };

        case types.UPDATE_PROFILE.SUCCESS:
            let personal = editProfile.personal;
            const updatedData = action.payload.request.payload;
            personal.firstName = updatedData.firstName;
            personal.lastName = updatedData.lastName;
            personal.userPositionId = updatedData.userPositionId;
            personal.userPositionName = updatedData.userPositionName;
            
            return {
                ...editProfile,
                personal: { ...personal },
                isSuccess: action.payload.data,
            };

        case types.UPDATE_PASSWORD.SUCCESS: {
            if (!action.payload.data) {
                return { ...editProfile, isSuccess: false };
            }

            return {
                ...editProfile,
                personal: { ...editProfile.personal },
                isSuccess: action.payload.data.isSuccess,
                passwordRules: action.payload.data,
                isOldPasswordValid: !action.payload.data.currentPasswordWrong,
            };
        }
        case types.VALIDATE_PASSWORD.REQUEST:
        case types.VALIDATE_PASSWORD.ERROR: {
            return {
                ...editProfile,
                isOldPasswordValid: null,
            };
        }
        case types.VALIDATE_PASSWORD.SUCCESS: {
            return {
                ...editProfile,
                isOldPasswordValid: action.payload.data,
            };
        }
        case types.VALIDATE_EDIT_PASSWORD: {
            const validation = PasswordValidation.validate(action.payload);
            return {
                ...editProfile,
                passwordRules: {
                    rules: validation.rules,
                    isSuccess: validation.isSuccess,
                },
                isSuccess: validation.isSuccess,
            };
        }
        case types.CLEAR_PASSWORD_REQUEST: {
            return {
                ...editProfile,
                passwordRules: null,
                isSuccess: true,
                isOldPasswordValid: null,
            };
        }
        case types.GET_THE_PROFILE_USERS.SUCCESS: {
            return {
                ...editProfile,
                usersList: action.payload.data.profiles,
                positions: action.payload.data.positions,
            };
        }

        case types.UPDATE_THE_PROFILE_USERS.SUCCESS:
            return {
                ...editProfile,
                usersProfile: action.usersProfile.data,
        };

        case types.ADD_THE_PROFILE_USERS.SUCCESS:
            return {
                ...editProfile,
                //usersProfile: action.usersProfile.data,
                isSuccess: action.payload.data,
            };

        case types.DELETE_THE_PROFILE_USERS:
            return {
                ...editProfile,
                //profileUsers: action.profileUsers.data,
                isSuccess: action.payload.data,
            };
        case types.AGENT_FEATURE_DEACTIVATION_PROCESSING_STATE: {
            return {
                ...editProfile,
                isAgencyDeactivationProcessing: action.payload
            }
        }
        case USER_IS_CREATING: {
            return {
                ...editProfile,
                isCreatingUser: true
            }
        }
        case USER_IS_CREATED: {
            return {
                ...editProfile,
                isCreatingUser: false
            }
        }
        default:
            return editProfile;
    }
};

const path = state => state.editprofile;

export const getProfilePersonal = compose(
    profile => profile.personal,
    path,
);

export const getProfileId = compose(
    profile => profile.personal.id,
    path,
);

export const getSquadId = compose(
    profile => profile.squadId,
    path,
);

export const getUsersList = () => {
    compose(
        profile => profile.usersList,
        path,
    );
};

export const getUserPositions = () => {
    compose(
        profile => profile.positions,
        path,
    );
};

export const getLoadingStatus = state =>
    getRequests(state).some(
        r =>
            (r.type === types.PROFILE_PERSONAL ||
                r.type === types.UPDATE_PROFILE ||
                r.type === types.DELETE_THE_PROFILE_USERS ||
                r.type === types.UPDATE_PASSWORD ||
                r.type === types.GET_THE_PROFILE_USERS) &&
            r.scope === 'editProfile',
    );

export const USER_IS_CREATED = "USER_IS_CREATED";
export const USER_IS_CREATING = "USER_IS_CREATING";

export const getPasswordRules = compose(
    profile => profile.passwordRules,
    path,
);

export const isSuccess = compose(
    profile => profile.isSuccess,
    path,
);


export const isOldPasswordValid  = compose(
    profile => profile.isOldPasswordValid || profile.isOldPasswordValid == null,
    path,
);