import React from 'react'
import ClubImage from 'components/images/club-image';
import styled from 'styled-components'
import Tooltip from 'components/tooltip/tooltip.component'

export const PastEventClubLogos = ({ attendees = [], title = '', countlimit = 200, confirmedLayout = false }) => (
    <Section>
        {title !== undefined && title !== null && title.length > 0 && <h2>{title}</h2>}
        <ul className="attendees">
            {attendees && attendees.slice(0, countlimit).map((item, index) => (
                <Tooltip
                    placement={'top'}
                    trigger={['hover']}
                    overlayClassName='event-logo-tooltip'
                    offset={[0, 0]}
                    render={<span>{item.name}</span>}
                    key={`${index}-${item.id}-list`}
                >
                    <li key={`${index}-${item.id}-list`}>
                        <div className={`image-container ${confirmedLayout ? 'as-label' : ''}`}>
                            <ClubImage customSize={32} name={item.logo} />
                        </div>
                    </li>
                </Tooltip>
            ))}
        </ul>
    </Section>
);

const Section = styled.div`
    white-space: nowrap;
    max-width: 280px;
    @media (max-width: 767px) {
        max-width: unset;
    }
    .attendees {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding-left: 0;
        &.main {
            li {
                width: 60px;
                height: 60px;
            }
        }
        li {
            position: relative;
            width: 43px;
            height: 43px;
            display: flex;
            justify-content: center;
            .tr-club-image--medium {
                width: 32px;
                text-align: center;
                display: flex;
                justify-content: center;
            }
            img {
                transition: 0.2s ease all;
            }
            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
        .image-container {
            padding: 3px;
            display: block;
            margin: 0 auto;
            &.as-label {
                width: 45px;
                margin: 0;
                > div:first-child {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
`