import _ from 'lodash';
import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { SuperPitchService } from 'api/super-pitch/super-pitch'
import { ReceiverSuperPitch } from 'api/super-pitch/models/received-super-pitch'
import { ComparisonLists, ComparisonRequest } from 'api/super-pitch/models/comparison'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import * as CommonController from '../common.controller'
import * as MessageSend from 'pages/landing-page/redux/message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { CancelToken } from 'axios';
import { PitchPageType } from '../../constants/page-type';
import * as CommontController from 'pages/player-ads/redux/common.controller'
import { ActionType, PageType, UserActivityType } from 'constants/enums';

class SuperPitchesState {
    superPitches: Array<ReceiverSuperPitch>;
    savedSuperPitches: Array<ReceiverSuperPitch>;
    isLoading: boolean;
    initialized: boolean;
    processingPitchIds: Array<number>;
    declareInterestProcessingIds: Array<number>;
    superPitchComparisonData: { [id: number]: ComparisonLists; }
}

const defaultState: SuperPitchesState = {
    superPitches: [],
    savedSuperPitches: [],
    isLoading: false,
    initialized: false,
    processingPitchIds: [],
    declareInterestProcessingIds: [],
    superPitchComparisonData: {}
}

const stateController = new StateController<SuperPitchesState>(
    "PLAYR_ADS/PITCH/SUPER_PITCH",
    defaultState
);

class SuperPitchSendMessageSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private playerId: number;
    private superPitchId: number;
    private squadSuperPitchId: number;
    private isSavedMode: boolean;
    private isInShortlist: boolean;

    constructor(dispatch, toSquadId: number, playerId: number, superPitchId: number, squadSuperPitchId: number, isSavedMode: boolean, isInShortlist: boolean) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.playerId = playerId;
        this.superPitchId = superPitchId;
        this.squadSuperPitchId = squadSuperPitchId;
        this.isSavedMode = isSavedMode;
        this.isInShortlist = isInShortlist;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {

        this.dispatch(userActivityInsert({
            PageName: this.isSavedMode ? 'Player Ads [Saved Super Pitch]' : 'Player Ads [Test Super Pitch]',
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            ClubId: this.toSquadId,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.PlayerAds,
            UserActivityType: UserActivityType.Action
        }));

        if (!this.isSavedMode) {
            if (checked) {
                this.dispatch(Actions.deleteTestInterest(PitchPageType.testSuperPitch, this.superPitchId, this.squadSuperPitchId))
            } else {
                this.dispatch(Actions.dismissPitch(PitchPageType.savedSuperPitch, this.superPitchId, this.squadSuperPitchId))
            }
        }
        // else {
        //     if(!this.isInShortlist) {
        //          this.dispatch(Actions.togglePlayerInShortList(PitchPageType.superPitch, this.playerId, this.superPitchId))
        //     }
        // }

        await this.dispatch(messageSend(this.toSquadId, message, null, session.officialName, session.mesageTypeId, session.subject, false, this.superPitchId));
    }

    public cancelMessage() {
        this.dispatch(userActivityInsert({
            PageName: this.isSavedMode ? 'Player Ads [Saved Super Pitch]' : 'Player Ads [Test Super Pitch]',
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            ClubId: this.toSquadId,
            PageType: PageType.PlayerAds
        }));
    }
}

class Actions {

    

    public static dispose(): any {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static preloadComparisonInfo(id: number): any {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            let currentDict = { ...state.playerAdPage.superPitch.superPitchComparisonData };
            if (Object.keys(currentDict).includes(id.toString())) {
                return;
            }
            let pitch = [...state.playerAdPage.superPitch.superPitches, ...state.playerAdPage.superPitch.savedSuperPitches].find(x => x.squadSuperPitchId == id);

            let statistic = null;
            if (pitch.comparisonKeys.length > 0) {
                let payload = new ComparisonRequest();
                payload.keys = pitch.comparisonKeys;
                payload.superPitchId = pitch.superPitchId;

                statistic = await SuperPitchService.loadComparisonDataOnSelect(pitch.playerInfo.playerId, payload);
            }

            currentDict[id] = { ...statistic };

            dispatch(stateController.setState({ superPitchComparisonData: currentDict }))

        }
    }

    public static loadSavedSuperPitches(cancelToken?: CancelToken) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            let superPitches = await SuperPitchService.getSavedSuperpitches(cancelToken);
            if (superPitches) {
                superPitches.forEach(item => item.uid = _.uniqueId('plus-pitch-'))
                dispatch(stateController.setState({ isLoading: false, savedSuperPitches: superPitches, initialized: true }));
            }
        }
    }

    public static loadAllsuperPitches(cancelToken?: CancelToken) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            let superPitches = await SuperPitchService.getReceivedSuperpitches(cancelToken);

            if (superPitches) {
                dispatch(stateController.setState({ isLoading: false, superPitches: superPitches, initialized: true }));
            }
        }
    }

    public static sendMessage(superPitchId: number, isSavedMode: boolean) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            let pitch = [...state.playerAdPage.superPitch.superPitches, ...state.playerAdPage.superPitch.savedSuperPitches].find(x => x.superPitchId == superPitchId);
            if (pitch != null) {
                dispatch(userActivityInsert({
                    PageName: isSavedMode ? 'Player Ads [Saved Super Pitch]' : 'Player Ads [Test Super Pitch]',
                    Message: 'Opened New Message',
                    PlayerId: pitch.playerInfo.playerId,
                    ClubId: pitch.playerInfo.parentSquad.id,
                    PageType: PageType.PlayerAds
                }));
            }

            let session: MessageSend.MessageSendSession = {
                subject: "RE: " + pitch.playerInfo.fullName + " Super Pitch",
                logo: pitch.createdBySquadLogo,
                checkBoxTitle: isSavedMode ? null : 'Keep player in saved pitches',
                officialName: pitch.playerInfo.parentSquad.name,
                leagueName: pitch.playerInfo.parentSquad.league + (pitch.playerInfo.parentSquad && pitch.playerInfo.parentSquad.areaName ?
                    `, ${pitch.playerInfo.parentSquad.areaName}` : "") || "",
                mesageTypeId: 7,
            }

            const playerId = pitch.playerInfo.playerId;
            const isInShortlist = getState().playerAdPage.common.shortListedPlayers.includes(playerId)

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SuperPitchSendMessageSpecification(dispatch, pitch.playerInfo.parentSquad.id, playerId, superPitchId, pitch.squadSuperPitchId, isSavedMode, isInShortlist)
                )
            )
        }
    }

    

    public static declareInterest(pageType: PitchPageType, superpitchId: number, squadSuperPitchId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let state = getState();
            let subState = state.playerAdPage.superPitch;
            try {
                let superPitches = [...subState.superPitches, ...subState.savedSuperPitches];
                let pitch = this.getPitch(superpitchId, superPitches);

                dispatch(stateController.setState({
                    processingPitchIds: [...subState.processingPitchIds, superpitchId],
                    declareInterestProcessingIds: [...subState.declareInterestProcessingIds, superpitchId]
                }))

                await SuperPitchService.declareInterestPitchSquad(squadSuperPitchId)

                subState = getState().playerAdPage.superPitch

                const applyDeclareInterestToSaved = (items: Array<ReceiverSuperPitch>, processingId: number) => {
                    return [...items.map(
                        x => {
                            if (x.squadSuperPitchId == processingId) {
                                return {
                                    ...x,
                                    saved: true,
                                    interestWasDeclared: true,
                                }
                            } else {
                                return x
                            }
                        })
                    ]
                }

                const applyDeclareInterestToActive = (items: Array<ReceiverSuperPitch>, processingId: number) => {
                    return [...items.filter(x => x.squadSuperPitchId != processingId)]
                }

                let savedPitches = applyDeclareInterestToSaved(subState.savedSuperPitches, squadSuperPitchId);
                let activePitches = applyDeclareInterestToActive(subState.superPitches, squadSuperPitchId);

                dispatch(stateController.setState({
                    savedSuperPitches: savedPitches,
                    superPitches: activePitches
                }))

                if (!state.playerAdPage.common.shortListedPlayers.includes(pitch.playerInfo.playerId)) {
                    await dispatch(CommonController.Actions.togglePlayerInShortList(PitchPageType.superPitch, pitch.playerInfo.playerId))
                }

                dispatch(CommonController.Actions.loadCounters());

                dispatch(Actions.userActivityInsert(pageType, 'Declared Interest', pitch, ActionType.DeclaredInterest, UserActivityType.Action))

                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                const subState = getState().playerAdPage.superPitch
                dispatch(stateController.setState({
                    processingPitchIds: [...subState.processingPitchIds.filter(x => x != superpitchId)],
                    declareInterestProcessingIds: [...subState.declareInterestProcessingIds.filter(x => x != superpitchId)]
                }))

            }

        })
    }

    public static dismissPitch(pitchPageType: PitchPageType, superpitchId: number, squadSuperPitchId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let state = getState();
            let subState = state.playerAdPage.superPitch;
            try {
                let superPitches = [...subState.superPitches, ...subState.savedSuperPitches];
                let pitch = this.getPitch(superpitchId, superPitches);

                dispatch(stateController.setState({ processingPitchIds: [...subState.processingPitchIds, superpitchId] }))

                await SuperPitchService.dismissPitch(squadSuperPitchId)
                subState = getState().playerAdPage.superPitch;


                const applyDismissToCollection = (items: Array<ReceiverSuperPitch>, processingId: number) => {
                    return [...items.filter(x => x.superPitchId != processingId)]
                }

                let savedPitches = applyDismissToCollection(subState.savedSuperPitches, superpitchId);
                let activePitches = applyDismissToCollection(subState.superPitches, superpitchId);

                dispatch(stateController.setState({
                    savedSuperPitches: savedPitches,
                    superPitches: activePitches
                }));

                dispatch(CommonController.Actions.loadCounters());

                const message = pitchPageType === PitchPageType.savedSuperPitch ? 'Removed Pitch' : pitchPageType === PitchPageType.superPitch ? 'Dismissed Super Pitch' : 'Deleted Saved Pitch'
                dispatch(Actions.userActivityInsert(pitchPageType, message, pitch, null))
                
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                let subState = getState().playerAdPage.superPitch;
                dispatch(stateController.setState({
                    processingPitchIds: [...subState.processingPitchIds.filter(x => x != superpitchId)],
                }));
            }
        })
    }

    public static deleteTestInterest(pitchPageType: PitchPageType, squadPitchId: number, squadSuperPitchId: number): any {
        return async (dispatch, getState) => new Promise(async (resolve, reject) => {
            let state = getState();
            let subState = state.playerAdPage.superPitch;
            try {
                
                let superPitches = [...subState.superPitches, ...subState.savedSuperPitches];
                let pitch = this.getPitch(squadSuperPitchId, superPitches);

                dispatch(stateController.setState({
                    processingPitchIds: [...subState.processingPitchIds, squadPitchId],
                }))

                await SuperPitchService.deleteTI(squadSuperPitchId)

                const applyDismissToCollection = (items: Array<ReceiverSuperPitch>, processingId: number) => {
                    return [...items.filter(x => x.superPitchId != processingId)]
                }

                subState = getState().playerAdPage.superPitch;
                let activePitches = applyDismissToCollection(subState.superPitches, squadPitchId);

                dispatch(stateController.setState({ superPitches: activePitches }))
                dispatch(CommonController.Actions.loadCounters());

                dispatch(Actions.userActivityInsert(pitchPageType, 'Deleted Saved Pitch', pitch, null))

                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState({
                    processingPitchIds: [...subState.processingPitchIds.filter(x => x != squadPitchId)],
                }))
            }
        })
    }

    public static savePitch(pitchPageType: PitchPageType, superpitchId: number, squadSuperPitchId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.superPitch;
            try {
                let superPitches = [...subState.superPitches, ...subState.savedSuperPitches];
                let pitch = this.getPitch(superpitchId, superPitches);

                dispatch(stateController.setState({ processingPitchIds: [...subState.processingPitchIds, superpitchId] }))

                await SuperPitchService.saveSuperPitch(squadSuperPitchId)
                subState = getState().playerAdPage.superPitch

                const applyDismissToCollection = (items: Array<ReceiverSuperPitch>, processingId: number) => {
                    return [...items.filter(x => x.superPitchId != processingId)]
                }
                let activePitches = applyDismissToCollection(subState.superPitches, superpitchId);

                dispatch(stateController.setState({ superPitches: activePitches }))

                dispatch(CommonController.Actions.loadCounters());

                dispatch(Actions.userActivityInsert(pitchPageType, 'Saved Super Pitch', pitch, null))
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                let subState = getState().playerAdPage.superPitch;
                dispatch(stateController.setState({
                    processingPitchIds: [...subState.processingPitchIds.filter(x => x != superpitchId)]
                }))
            }
        })
    }

    private static getPitch(superPitchId: number, superPitches: any): ReceiverSuperPitch {
        let pitch = null;
        superPitches.map(p => {
            if (p.superPitchId == superPitchId) {
                pitch = p;
            }
        });

        return pitch;
    }

    public static userActivityInsert(pageType: PitchPageType, message: string, pitch: any, actionType: ActionType | null, userActivityType: number = null) {
        return async (dispatch) => {
            if (pitch) {
                dispatch(userActivityInsert({
                    PageName: `Player Ads [${pageType}]`,
                    Message: message,
                    PlayerId: pitch.playerInfo.playerId,
                    ClubId: pitch.playerInfo.parentSquad.id,
                    ActionType: actionType,
                    PageType: PageType.PlayerAds,
                    Link: [{ Type: 3, TargetId: pitch.superPitchId }],
                    UserActivityType: userActivityType
                }));
            }
        }
    }

    public static togglePlayerInShortList(pageType, playerId, pitchId) {
        return (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage.superPitch;
            const pitch = this.getPitch(pitchId, [
                ...subState.superPitches,
                ...subState.savedSuperPitches,
            ]);
            let clubId = pitch.playerInfo.parentSquad ? pitch.playerInfo.parentSquad.id : null;

            dispatch(CommontController.Actions.togglePlayerInShortList(pageType, playerId, pitchId, clubId, null, pitch.pitchType))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SuperPitchesState as State,
    Actions as Actions,
    stateController as Controller
};



