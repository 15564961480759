import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'

const title = "TransferRoom Virtual Winter Deal Day";

const eventSettings: EventInfoModel = {
    shortTitle: title,
    title: title,
    shortEventDates: "16th January 2023",
    eventDates: "16th January 2023",
    location: "Virtual",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null, //SpeakerCardBox,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        title: `${title} for Clubs and Trusted Agents`,
        userActivityPageName: "Home [TransferRoom Virtual Winter Deal Day]",
        description: "Register for our next event and book meetings with decision makers from up to 300 clubs as well as trusted agencies."
    },
    registration: {
        activateAgencyFeature: false,
        hasDateSelectorStep: false
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: false
    }
};

export default eventSettings;