export enum MessageType {
    ChatMessage = 1,
    PlayerInterest = 2,
    FriendlyInterest = 3,
    PlusPitchMessage = 4,
    FriendlyInvitation = 5,
    PitchMessage = 6,
    SuperPitchMessage = 7,
    ClubAgencyChatMessage = 8,
    ClubAgencyPlayerInterest = 9,
    WelcomeMessageToAgency = 10,
    CustomPitchMessage = 11,
    AgencyToPlayerRemoval = 12,
    AgencyToPlayerTransferConfirmation = 13,
    ProfileSharingStart = 14,
    ProfileSharingStop = 15,
    TransferRoomMessage = 16,
    PlayerToAgency = 17,
    AgencyToPlayer = 18,
}
export enum ConversationTypeEnum {
    Chat = 1,
    PlayerInterest = 2,
    FriendlyInterest = 3,
    PlusPitch = 4,
    FriendlyInvitation = 5,
    Pitch = 6,
    SuperPitch = 7,
    ClubAgencyChat = 8,
    ClubAgencyPlayerInterest = 9,
    WelcomeMessageToAgency = 10,
    CustomPitch = 11,
    AgencyToPlayerRemoval = 12,
    AgencyToPlayerTransferConfirmation = 13,
    TransferRoomMessage = 16,
    PlayerToAgencyRepresentation = 17,
    AgencyToPlayerRepresentation = 18,
    ClubAgencyHeadCoachInterest = 19,
}

export enum SenderType {
    Club = 0,
    Agency = 1
}

export class NewMessage {
    text: string;
    messageType: MessageType;
    chatType?: ConversationTypeEnum;
    refId?: number;
    subject?: string;
}

export class ChannelMessages {
    messages: Array<MessageItem>;
    isReadonly: boolean;
    isUnregisteredReceiver: boolean;
}
export class MessageItem {
    id: number;
    body: string;
    senderName: string;
    sentDate: Date | string | null;
    messageType: MessageType;
    responseType: number
    senderId: number;
    isMineSquadMessage: boolean;
    isUnread: boolean;
    isDeleted: boolean;
}