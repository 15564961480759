import { StateController } from 'utils/action-declaration';
import UserPasswordService from 'api/user-password/user-password';
import { AppState } from 'root.reducer';
import validator from "services/validator";
import AgencyProfileService from "api/user-password/user-password";
import history from "../../history-accessor";
import {playerPathsV2, userPaths} from "../../routes/paths";
import {PageType, UserTypeEnum} from "../../constants/enums";
import userActivityInsert from "../user-activity/actions/user-activity.actions";
import {Actions as PlayerActions, SettingsSteps} from "pages/player-v2/settings-page/settings-page.controller"
import {refreshAuthSingle} from "store/auth/auth.actions";

class State {
	isUpdateEmailPageProcessing: boolean;
	modalInfo: {
		isOpen: boolean;
		modalType: 'password' | 'email';
	}
	emailChange: {
		newEmail: string,
		confirmedEmail: string,
		newEmailError: boolean,
		newEmailErrorText: string,
		confirmedEmailError: boolean,
		isLoading: boolean,
	}
	isThankYouModalOpen: boolean;
}

const defaultState: State = {
	isUpdateEmailPageProcessing: false,
	modalInfo: {
		isOpen: false,
		modalType: 'password',
	},
	emailChange: {
		newEmail: '',
		newEmailError: false,
		newEmailErrorText: '',
		confirmedEmail: '',
		confirmedEmailError: false,
		isLoading: false,
	},
	isThankYouModalOpen: false
}

const stateController = new StateController<State>(
	"AGENCY_CLUB/CHANGE_EMAIL_PROFILE",
	defaultState
);

class Actions {
	public static dispose() {
		return dispatch => {
			dispatch(stateController.setState(defaultState))
		}
	}
	
	public static validateGuid(guid: string) {
		return async (dispatch, getState: () => AppState) => {
			try {
				dispatch(stateController.setState({ isUpdateEmailPageProcessing: true }));
				if(guid) {
					const {isSuccess} =	await AgencyProfileService.confirmEmail(guid);
					await dispatch(refreshAuthSingle(getState().auth.aspNetUserId));
				}
			} catch (err) {
				console.error(err)
			} finally {
				const isPlayerType = getState().auth.userTypeId === UserTypeEnum.Player;
				const redirectPath = isPlayerType ? playerPathsV2.settings : userPaths.editProfileProfile;
				dispatch(stateController.setState({ isUpdateEmailPageProcessing: false }));
				
				history.push(redirectPath);
			}
		}
	}
	
	public static openChangedEmailModal() {
		return async (dispatch) => {
			dispatch(Actions.sendUserActivity('Opened Change Email', 'Edit Profile [Change Email]'));
			dispatch(stateController.setState((prevState) =>({
				...prevState,
				modalInfo: {
					isOpen: true,
					modalType: 'email',
				}
			})));
		}
	}
	public static sendUserActivity(message: string, pageName) {
		return (dispatch, getState: () => AppState) => {
			dispatch(userActivityInsert({
				Message: message,
				PageName: pageName,
				PageType: PageType.Settings,
			}));
		}
	}
	public static closeChangedEmailModal() {
		return async (dispatch) => {
			dispatch(stateController.setState((prevState) =>({
				...prevState,
				emailChange: {
					...defaultState.emailChange,
				},
				modalInfo: {
					isOpen: false,
					modalType: 'password',
				}
			})));
		}
	}
	
	public static changeEmail() {
		return async (dispatch, getState: () => AppState) => {
			try {
				const currentEmail = getState().auth.userEmail;
				const newEmail = getState().updateEmail.emailChange.newEmail;
				const isPlayerType = getState().auth.userTypeId === UserTypeEnum.Player;
				const pageName = isPlayerType ? 'My Page [Settings - Change Email]' : 'Edit Profile [Change Email]'
				
				dispatch(stateController.setState(prevState => ({
						...prevState,
						emailChange:
							{ ...prevState.emailChange,
								isLoading: true,
							}
					})))
				const data = await UserPasswordService.updateEmail({ email: currentEmail, newEmail: newEmail });
				dispatch(Actions.sendUserActivity('Verification Sent', pageName));
				
				if(data.isSuccess) {
					if(isPlayerType) {
						dispatch(PlayerActions.setStep(SettingsSteps.ThankYouMessage))
					} else {
						dispatch(Actions.closeChangedEmailModal());
						dispatch(Actions.toggleThankYouModal());
					}
				} else {
					dispatch(stateController.setState(prevState => ({
						...prevState,
						emailChange:
							{ ...prevState.emailChange,
								newEmailError: true,
								newEmailErrorText: data.message,
							}
					})));
				}
				
			} catch (error) {
				console.log(error);
			} finally {
				dispatch(stateController.setState(prevState => ({
					...prevState,
					emailChange:
						{ ...prevState.emailChange,
							isLoading: false,
						}
				})))
			}
		}
	}
	public static validateEmail () {
		return async (dispatch, getState: () => AppState) => {
			const newEmail = getState().updateEmail.emailChange.newEmail;
			const isEmailValid = validator.isValidEmail(newEmail);
			
			if (!isEmailValid) {
				return dispatch(stateController.setState(prevState => ({
					...prevState,
					emailChange:
						{ ...prevState.emailChange,
							newEmailError: true,
							newEmailErrorText: 'Incorrect email'
						}
				})))
			}
		}
	}
	public static setNewEmail (email: string) {
		return async (dispatch, getState: () => AppState) => {
			const confirmedEmail = getState().updateEmail.emailChange.confirmedEmail;
	
			const isEmailMatched = confirmedEmail === email || confirmedEmail === '';
			dispatch(stateController.setState(prevState => ({
				...prevState,
				emailChange:
					{ ...prevState.emailChange,
						newEmail: email,
						newEmailError: false,
						confirmedEmailError: !isEmailMatched,
					}
			})))
		}
	}
	public static setConfirmedEmail (email: string) {
		return async (dispatch, getState: () => AppState) => {
			const newEmail =  getState().updateEmail.emailChange.newEmail;
			
			const isEmailMatched = newEmail === email || email === '';
			dispatch(stateController.setState(prevState => ({
				...prevState,
				emailChange:
					{ ...prevState.emailChange,
						confirmedEmail: email,
						confirmedEmailError: !isEmailMatched,
					}
			})))
		}
	}
	public static toggleThankYouModal = () => {
		return (dispatch, getState: () => AppState) => {
			dispatch(stateController.setState(prevState => ({
				...prevState,
				isThankYouModalOpen: !prevState.isThankYouModalOpen,
			})))
		}
	}
}

class Selectors {
	public static getRoot = (state: AppState): State => state.updateEmail;
	public static getModalInfo = (state: AppState) =>  Selectors.getRoot(state).modalInfo;
}

const reducer = stateController.getReducer();

export {
	reducer as Reducer,
	State as State,
	Actions as Actions,
	stateController as Controller,
	Selectors as Selectors,
};
