import config from 'config';

export function getBlobImage(imageName, blobPath) {
    if (imageName == null) return null;

    if (imageName && imageName.indexOf("https://") === 0) {
        return imageName;
    }
    return `${config.imagesUrl}/${blobPath}/${imageName}`;
}

export const clubBlobPath = "clubs";
export const agencyProfileHeaderPath = "agency/header";
export const agencyProfileLogoPath = "agency/logo";

//crop functions
export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.src = url
    })

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}