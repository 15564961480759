import React from 'react';
import styled from 'styled-components';
import BgLondon from 'media/images/events/eventtitle-london@2x.png';
import BgBarcelona from 'media/images/events/eventtitle-barcelona@2x.png';
import BgDealDay from 'media/images/events/deal-day-bg.png';
import BgVirtualDealDay from 'media/images/events/virtual-deal-day-bg.png';

export const EventTitle = ({ number, th, title1, title2, date, bgImageNumber, vs = false }) => (
    <Section bgImageNumber={bgImageNumber}>
        <div className="number">
            {number} <sup>{th}</sup>
        </div>
        <div className="title">
            <h3 className={vs ? "vs" : ""}>
                {title1} <span>{title2}</span>
            </h3>
            <h4>{date}</h4>
        </div>
    </Section>
);

const Section = styled.div`
    display: flex;
    align-items: center;
    background: #0E9655 center center;
    background-size: cover;
    background-image: ${props => {
        if (props.bgImageNumber === 3) return `url(${BgLondon})`
        if (props.bgImageNumber === 4) return `url(${BgBarcelona})`
        if (props.bgImageNumber === 5) return `url(${BgLondon})`
        if (props.bgImageNumber === 6) return `url(${BgLondon})`
        if (props.bgImageNumber === 7) return `url(${BgDealDay})`
        if (props.bgImageNumber === 8) return `url(${BgVirtualDealDay})`
        return 'url(~media/images/events/eventtitle-barcelona@2x.jpg)'
    }} !important;
    margin: -40px -40px 40px -40px;
    border-radius: 0px 0px 0 0;
    padding: 20px 34px;
    color: #fff;
    font-size: 36px;
    @media (max-width: 1050px) {
        padding: 10px 15px;
    }
    .number {
        font-size: 62px;
        font-weight: bold;
        line-height: 1;
        position: relative;
        margin-right: 0.7em;
        @media (max-width: 1050px) {
            font-size: 1.5em;
        }
        @media (max-width: 400px) {
            font-size: 1.2em;
        }
        sup {
            position: absolute;
            top: 0.33em;
            left: 100%;
            font-size: 18px;
            text-transform: uppercase;
            vertical-align: top;
            display: block;
            line-height: 1;
            font-weight: bold;
        }
    }
    .title {
        h3 {
            font-size: 1em;
            font-weight: bold;
            margin: 0;
            color: #fff;
        }
        .vs{
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
            margin: 0;
            color: #fff;
            @media (max-width: 1050px) {
                font-size: 0.7em;
            }
            @media (max-width: 400px) {
                font-size: 0.6em;
            }
        }
        h4 {
            font-size: 20px;
            font-weight: normal;
            margin: 0;
            color: #fff;
            @media (max-width: 1050px) {
                font-size: 0.6em;
            }
            @media (max-width: 400px) {
                font-size: 0.5em;
            }
        }
    }
`