import * as redux from 'redux';
import * as mySquad from './my-squad.controller';
import * as matchmaker from './my-squad-matchmaker.controller';
import * as filter from './my-squad-filter.controller';

export class MySquadV2RootState {
    mySquad: mySquad.State;
    matchmaker: matchmaker.State;
    filter: filter.State;
}

export function combineReducers() {

    let mySquadV2Root = {
        mySquad: mySquad.Reducer,
        matchmaker: matchmaker.Reducer,
        filter: filter.Reducer
    };

    return redux.combineReducers(mySquadV2Root)
}