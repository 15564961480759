import { ComparisonLists, ComparisonModel } from 'api/super-pitch/models/comparison';
import { CancelToken } from 'axios';
import axios from 'axios-config';
import { 
    ListResponse, CloseType, CloseReason, CustomPitchComparisonRequest, CreateCustomPitchRequest, VerifiedPlayer, 
    SquadToPitch, PublishPitchInfo, CustomPitchPreviewInfo, LogCustomPitchRequest, 
    HeadToHeadInfo, GetInTouchRequest, InitInfo, PlayerAdditionalInfo 
} from './models'

export default class AgencyCustomPitchService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getInfo(): Promise<InitInfo> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/InitInfo`,
            {},
            this.axiosConfig
        );

        return data;
    }

    public static async list(): Promise<ListResponse> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/List`,
            this.axiosConfig
        );
        return data;
    }

    public static async playerAdditionalInfo(playerId: number): Promise<PlayerAdditionalInfo> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/PlayerAdditionalInfo/${playerId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async closePitchSquad(request: { pitchSquadIds: number[], type: CloseType, reason?: CloseReason }): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/ClosePitchSquad`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async sendGetInTouchData(request: GetInTouchRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/GetInTouch`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async extendExpiry(request: { pitchSquadId: number }): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/ExtendExpiry`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async create(request: CreateCustomPitchRequest): Promise<string> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/Create`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async loadComparisonData(playerId: number, payload: CustomPitchComparisonRequest): Promise<ComparisonModel> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/ComparisonSelection/${playerId}`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async loadComparisonDataOnSelect(playerId: number, payload: CustomPitchComparisonRequest): Promise<ComparisonLists> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/Comparison/${playerId}`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async getPlayers(): Promise<Array<VerifiedPlayer>> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/GetPlayers`,
            this.axiosConfig
        );
        return data;
    }

    public static async getClubs(playerId: number): Promise<Array<SquadToPitch>> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/GetClubs/${playerId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getPublishedPitch(pitchId: string): Promise<PublishPitchInfo> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/GetPublishedPitch/${pitchId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getPitchPreviewData(playerId: number, squadId: number): Promise<CustomPitchPreviewInfo> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/GetPitchPreviewInfo/${playerId}/${squadId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async logCustomPitchAction(request: LogCustomPitchRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyCustomPitch/LogCustomPitchAction`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async getHeadToHeadInfo(firstPlayerId: number, secondPlayerId: number | null, secondPlayerType: number | null): Promise<HeadToHeadInfo> {
        const { data } = await axios.get(
            `api/Statistic/GetPlayerComparison?firstPlayerId=${firstPlayerId}&secondPlayerId=${secondPlayerId ?? 0}&thirdPlayerId=${0}&secondPlayerType=${secondPlayerType ?? -1}&thirdPlayerType=${-1}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSuggestions(mainPlayerId: number, keyword: string, cancelToken: CancelToken){
        const { data } = await axios.post(
            `api/AgencyCustomPitch/Suggestions`,
            {
                mainPlayerId,
                keyword,
            },
            { ...this.axiosConfig, cancelToken: cancelToken }
        );
        return data;
    }

    public static async comparisonSearchSuggestedPlayers(mainPlayerId: number, characteristicKey: string, searchKey: string): Promise<any> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/ComparisonSearchSuggestedPlayers/${mainPlayerId}/${characteristicKey}/?searchKey=${searchKey}`,
            this.axiosConfig
        )
        return data
    }

    public static async getSuggestedHeadToHeadPlayer(playerId: number, squadId: number): Promise<number> {
        const { data } = await axios.get(
            `api/AgencyCustomPitch/GetSuggestedHeadToHeadPlayer/${playerId}/${squadId}`,
            this.axiosConfig
        );
        return data;
    }
}