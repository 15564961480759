import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'root.reducer';
import "./tab-meetings.scss";
import Button from "components/Buttons/Button/Button";
import { MeetingSessionComponent } from '../meeting-session/meeting-session'
import SpinnerCentered from 'components/Spinner/spinner-centered';
import { MeetingPreparationModel, AgendaModel } from 'api/virtual-summit/models/meeting-preparation'
import moment from 'moment';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { userPaths } from 'routes/paths'
import { PageType } from 'constants/enums';
import { getAuth } from 'store/auth/authReducer';
import AuthState from 'store/auth/auth-state';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import historyAccessor from 'history-accessor';

class StateProps {
    isLoading: boolean;
    preparation: Array<MeetingPreparationModel>;
    auth: AuthState;
}
class DispatchProps {
    userActivityInsert: (obj: any) => void
}

export class TabMeetings extends Component<StateProps & DispatchProps, any> {
    state = { selectedTab: 1 }

    renderNoContent() {
        const { auth } = this.props;

        this.props.userActivityInsert({
            PageName: 'Event [Agenda Builder]',
            Message: `Opened Meetings Set Up`,
            PageType: PageType.Events,
            EventId: auth.eventId
        });
        return <div>
            <div className="vs-meeting__hr" ></div>
            <div className="vs-meeting__nocontent">
                <div className="vs-meeting__nocontent-header">There is nothing to show at the moment.</div>
                <div className="vs-meeting__nocontent-text">Either no meetings have been set up yet or the meetings have not yet been synchronised to TransferRoom. It can take up to 8 hours before a booked meeting gets synchronised, so please be patient.</div>
                <Button effect={true ? "no" : 'disabled'} type="primary" large style={{ width: 170, marginTop: 50 }} disabled={false ? 'disabled' : ''}
                    onClick={() => {
                        this.props.userActivityInsert({
                            PageName: 'Event [Agenda Builder]',
                            Message: `Set Up Meetings for Table ${this.state.selectedTab}`,
                            PageType: PageType.Events,
                            EventId: auth.eventId
                        })
                        window.open('https://events.transferroom.com/', '_blank')
                    }} >
                    SET UP MEETINGS HERE
                </Button>
            </div>
        </div>
    }

    renderMeetings = (preparationItem: MeetingPreparationModel, tableIndex: number) => {

        let table = preparationItem.tables[tableIndex];


        if (table === undefined || table.meetings.length === 0
            || !table.userSummitTable.confirmed
        ) return this.renderNoContent()

        const { auth } = this.props;

        const { timeZone } = getEventInfo(auth.eventId);

        return table.meetings.map((item, index) => {

            let myAgendaModel: AgendaModel = item.host;
            let oponentAgendaModel: AgendaModel = item.guest;

            if (!item.amIHost) {
                myAgendaModel = item.guest;
                oponentAgendaModel = item.host;
            }

            return <MeetingSessionComponent
                isAgency={oponentAgendaModel.agencyId > 0}
                key={index}
                entity={{
                    id: item.id,
                    atFormatted: moment(item.schedulledTime).format("HH.mm"),
                    atTimeZone: timeZone,
                    title: oponentAgendaModel.agencyId > 0 ? oponentAgendaModel.agencyName : oponentAgendaModel.squadName,
                    playersPersonWantToDiscuss: myAgendaModel.players.map(item => ({ id: item.id, name: item.shortName, position: item.firstPositionCode })),
                    playersToDiscuss: oponentAgendaModel.players.map(item => ({ id: item.id, name: item.shortName, position: item.firstPositionCode })),
                    contactPerson: oponentAgendaModel.userName,
                    contactPersonRole: oponentAgendaModel.userPosition,
                    agendaPassed: myAgendaModel.players.length > 0,
                    squadHasPlayers: oponentAgendaModel.squadHasPlayers
                }}
                onAgendaClick={() => {
                    if (myAgendaModel.players.length + oponentAgendaModel.players.length > 0) {
                        this.props.userActivityInsert({
                            PageName: 'Event [Agenda Builder]',
                            Message: `Update agenda for ${oponentAgendaModel.squadName} at ${item.schedulledTime}`,
                            PageType: PageType.Events,
                            EventId: auth.eventId
                        })
                    } else {
                        this.props.userActivityInsert({
                            PageName: 'Event [Agenda Builder]',
                            Message: `Prepare agenda for ${oponentAgendaModel.squadName} at ${item.schedulledTime}`,
                            PageType: PageType.Events,
                            EventId: auth.eventId,
                        })
                    }
                    historyAccessor.push(`${userPaths.eventMeetingAgenda}/${item.id}`)
                }}
                onPlayerClick={(id: number) => {
                    let url =
                        window.location.origin + `/profile/${id}`;
                    let instance = window.open(url, '');
                    instance.focus();
                }}
            />
        }
        )
    }

    renderTable(preparationItem: MeetingPreparationModel) {

        let table = preparationItem.tables[this.state.selectedTab - 1];
        let tableHost = "";
        if (table !== undefined) {
            tableHost = table.hosterName + " (" + table.hosterPosition + ")";
        }
        else {
            return null;
        }

        const { auth } = this.props;

        return <div>
            <div className="vs-meeting__table-line">
                <div className="vs-meeting__table-line-host">TABLE HOST: {tableHost}</div>
                {table.userSummitTable.accessVideoCalls &&
                    <Button effect='outline' type="primary" large className="vs-meeting__button" onClick={() => {
                        this.props.userActivityInsert({
                            PageName: 'Event [Agenda Builder]',
                            Message: `Opened video calls for Table ${this.state.selectedTab}`,
                            PageType: PageType.Events,
                            EventId: auth.eventId
                        })
                    }} >
                        <div className="vs-meeting__button-content">
                            <i className="far fa-comment-alt vs-meeting__button-content-icon"></i>
                            <div className="vs-meeting__button-content-text" >
                                ACCESS VIDEO CALLS FOR TABLE {this.state.selectedTab}
                            </div>
                        </div>
                    </Button>
                }
            </div>
            {this.renderMeetings(preparationItem, this.state.selectedTab - 1)}
        </div>
    }

    render() {
        const { auth } = this.props;
        const { eventDates } = getEventInfo(auth.eventId);

        return (
            <div className="vs-meeting" style={{ minHeight: 375 }}>
                <div className="vs-meeting__header-vs-meeting-prep">Agenda Builder</div>
                <div><b>Note:&nbsp;</b>It can take up to 8 hours before a booked meeting gets synchronised, so please be patient if your meetings are not displayed below yet.</div>

                {this.props.isLoading || this.props.preparation == null ? (
                    <div style={{ height: 300 }}>
                        <SpinnerCentered />
                    </div>
                ) : (
                    this.props.preparation.map((preparationItem, index) => {
                        let firstTableExist = preparationItem.tables[0] !== undefined;
                        let secondTableExist = preparationItem.tables[1] !== undefined;
                        let thirdTableExist = preparationItem.tables[2] !== undefined;

                        let formattedDate = eventDates;
                        if(preparationItem.date){
                            const date = moment(preparationItem.date);
                            const dayOfWeek = date.format('dddd').toUpperCase();
                            const dayOfMonth = date.format('Do');
                            const month = date.format('MMMM').toUpperCase();
                            formattedDate = `${dayOfWeek} ${dayOfMonth} ${month}`;
                        }

                        return (
                            <div key={index}>
                                <div className="vs-meeting__date">{formattedDate}</div>
                                <div>

                                    <ul className="tr-tables-tabs">
                                        {
                                            preparationItem.tables.length == 0
                                            &&
                                            <div>There is no tables.</div>
                                        }
                                        {
                                            firstTableExist
                                            &&
                                            <li style={{ marginLeft: 0 }} className={`tr-tabs__item ${this.state.selectedTab == 1 ? 'active' : ''}`}
                                                onClick={() => {
                                                    if (this.state.selectedTab !== 1) {
                                                        this.props.userActivityInsert({
                                                            PageName: 'Event [Agenda Builder]',
                                                            Message: 'Selected Table 1',
                                                            PageType: PageType.Events,
                                                            EventId: auth.eventId
                                                        })
                                                    }
                                                    this.setState({ selectedTab: 1 })
                                                }}>
                                                TABLE 1
                                            </li>
                                        }
                                        {
                                            secondTableExist
                                            &&
                                            <li className={`tr-tabs__item ${false ? 'disabled' : ''} ${this.state.selectedTab == 2 ? 'active' : ''}`}
                                                onClick={() => {
                                                    if (this.state.selectedTab !== 2) {
                                                        this.props.userActivityInsert({
                                                            PageName: 'Event [Agenda Builder]',
                                                            Message: 'Selected Table 2',
                                                            PageType: PageType.Events,
                                                            EventId: auth.eventId
                                                        })
                                                    }
                                                    this.setState({ selectedTab: 2 })
                                                }}>
                                                TABLE 2
                                            </li>
                                        }
                                        {
                                            thirdTableExist
                                            &&
                                            <li className={`tr-tabs__item ${false ? 'disabled' : ''} ${this.state.selectedTab == 3 ? 'active' : ''}`}
                                                onClick={() => {
                                                    if (this.state.selectedTab !== 3) {
                                                        this.props.userActivityInsert({
                                                            PageName: 'Event [Agenda Builder]',
                                                            Message: 'Selected Table 3',
                                                            PageType: PageType.Events,
                                                            EventId: auth.eventId
                                                        })
                                                    }
                                                    this.setState({ selectedTab: 3 })
                                                }}>
                                                TABLE 3
                                            </li>
                                        }
                                    </ul>
                                </div>
                                <div className="vs-meeting__hr"></div>
                                <>
                                    {this.renderTable(preparationItem)}
                                </>
                            </div>
                        )
                    })
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    isLoading: (state as any).events.virtualSummit.meetingPreparation.isLoading,
    preparation: (state as any).events.virtualSummit.meetingPreparation.info,
    auth: getAuth(state)
})

const mapDispatchToProps = {
    userActivityInsert
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMeetings)
