import axios from 'axios-config';
import { ActiveLoansModel } from "./models/active-loans-model";
import { CompletedLoansModels } from "./models/completed-loans-model";
import { LoanInsightsClubInfoModel } from './models/club-info';
import LoanBenchmarkingModel from './models/benchmarking-model'

export class LoanInsightsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async postMethod(arg: any): Promise<any> {
        const { data } = await axios.post(
            `api/Controller/Action/${arg}`,
            {},
            this.axiosConfig
        );
        return data.map(item => item.id);
    }

    public static async getActiveLoansTabData(userId: number, squadId: number): Promise<ActiveLoansModel> {
        const { data } = await axios.get(
            `api/LoanInsights/ActiveLoans/${userId}/${squadId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCompletedLoansTabData(userId: number, squadId: number): Promise<CompletedLoansModels> {
        const { data } = await axios.get(
            `api/LoanInsights/CompletedLoans/${userId}/${squadId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getClubInfo(squadId: number, userId: number): Promise<LoanInsightsClubInfoModel> {
        const { data } = await axios.get(
            `api/LoanInsights/ClubInfo/${squadId}/${userId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getBenchmarking(squadId: number, userId: number): Promise<LoanBenchmarkingModel> {
        const { data } = await axios.get(
            `api/LoanInsights/Benchmarking/${squadId}`,
            this.axiosConfig
        );
        return data;
    }
}