

export class PlayerDecleraInterestDefinition {
    /// <summary>
    /// True - interest was declared
    /// False - Interest can be declared
    /// Null - declare interest disabled
    /// </summary>
    public interestDeclaredToClub?: boolean;
    public interestDeclaredToAgent?: boolean;

    public messageRule: NewMessageRule;

    public declareInterestToRole: DeclareInterestToRole;

    public tooltip: DeclareInterestToAgentTooltip;
}

export class DeclareInterestToRole {
    public interestDeclared?: boolean;
    public toRole: ReceiverRole;
}

export class NewMessageRule {
    public isDisabled: boolean;

    public receiver: ReceiverRole;
}

export enum ReceiverRole {
    All = 0,
    Agent = 1,
    Club = 2
}

export enum DeclareInterestToAgentTooltip {
    None = 0,
    PlayerHasNoClub = 1,
    LessThan6Months = 2,
    RepresentedByAgent = 3,
    LessThan12Months = 4,
    RepresentedByTrustedAgent = 5
}
