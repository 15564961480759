import React from 'react'
import styled from 'styled-components'

class Props {
    title: string;
    noTopMargin?: boolean;
}

export default function DividerLine({ title, noTopMargin }: Props) {
    return (
        <Section noTopMargin={noTopMargin}>
            <div className="line">
                <div />
                <span>{title}</span>
                <div />
            </div>
        </Section>
    )
}

const Section = styled.div`
    margin-bottom: 30px;
    margin-top: ${props => props.noTopMargin ? '0px' : '35px'};
    text-transform: uppercase;
    .line {
        width: 100%;
        align-items: center;
        display: flex;
        margin: 0 auto;
        padding: 0 15px;

        div {
            border-bottom: 2px solid #dfdfdf;
            display: flex;
            width: 100%;
        }

        span {
            font-size: 18px;
            font-weight: bold;
            color: #a6a6a6;
            position: relative;
            flex: 0 0 auto;
            margin-left: 12px;
            margin-right: 12px;
        }
    }
`