import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyPlusPitchService from 'api/agency/plus-pitch/agency-plus-pitch.service'
import AgencyCustomPitchService from 'api/agency/custom-pitch/custom-pitch.service'
import { isLowerTierAgency } from 'store/auth/authReducer';

class CommonPitchState {
    createdPlusPitchesCount: number;
    availablePlusPitchesCount: number;
    pitchTestInterestTimeframe: number;

    availableCustomPitchesCount: number;
    createdCustomPitchesCount: number;
    isCustomPitchActive: boolean;
}

const defaultState: CommonPitchState = {
    createdPlusPitchesCount: 0,
    availablePlusPitchesCount: 0,
    pitchTestInterestTimeframe: 0,
    availableCustomPitchesCount: 0,
    createdCustomPitchesCount: 0,
    isCustomPitchActive: false
}

const stateController = new StateController<CommonPitchState>(
    "AGENCY/COMMON_PITCH",
    defaultState
);


class Actions {
    public static loadPlusPitchInfo() {
        return async (dispatch) => {
            try {
                const info = await AgencyPlusPitchService.getInfo()
                dispatch(stateController.setState({ 
                    availablePlusPitchesCount: info.left, 
                    createdPlusPitchesCount: info.created, 
                    pitchTestInterestTimeframe: info.pitchTestInterestTimeframe,
                }))
            } catch(e) {
                console.error(e)
            }
        }
    }

    public static loadCustomPitchInfo() {
        return async (dispatch) => {
            try {
                const info = await AgencyCustomPitchService.getInfo()
                dispatch(stateController.setState({ 
                    availableCustomPitchesCount: info.left,
                    createdCustomPitchesCount: info.created, 
                    isCustomPitchActive: info.isCustomPitchActive
                }))
            } catch(e) {
                console.error(e)
            }
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState}))
        }
    }
}

class Selectors {
    public static getPlusPitch = (state: AppState) => state.agency.commonPitch
    public static getPlusPitchCount = (state: AppState) => Selectors.getPlusPitch(state).availablePlusPitchesCount
    public static getCreatedCount = (state: AppState) => Selectors.getPlusPitch(state).createdPlusPitchesCount
    public static isPlusPageVisible = (state: AppState) => isLowerTierAgency(state) || Selectors.getPlusPitchCount(state) > 0 || Selectors.getCreatedCount(state) > 0;

    public static getCustomPitchCount = (state: AppState) => Selectors.getPlusPitch(state).availableCustomPitchesCount
    public static getCreatedCustomPitchCount = (state: AppState) => Selectors.getPlusPitch(state).createdCustomPitchesCount
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonPitchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



