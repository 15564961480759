import { StateController } from 'utils/action-declaration';
import { ShortlistService } from 'api/shortlist/shortlist-service';
import { PlayerService } from 'api/player/player-service';
import { AppState } from 'root.reducer';
import { Player, PlayerPortfolioModel } from 'api/agency/club/agency-profile/models/player-portfolio.model'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
class PlayerPortfolioState {
    isLoading: boolean;
    shortlistedPlayers: Array<number>;
    players: Array<Player>;
    indicators: {
        strength: number;
        verifiedPlayersAmount: number;
        playersAmount: number;
    };
    declareInterestProcessingIds: Array<number>;
}

const defaultState: PlayerPortfolioState = {
    isLoading: false,
    shortlistedPlayers: [],
    players: [],
    indicators: {
        strength: 0,
        verifiedPlayersAmount: 0,
        playersAmount: 0,
    },
    declareInterestProcessingIds: []
}

const stateController = new StateController<PlayerPortfolioState>(
    'AGENCY_PROFILE/PLAYER_PORTFOLIO',
    defaultState
)

class Actions {

    public static setDataToState(data: PlayerPortfolioModel) {
        return async (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                players: data.players,
                playersAmount: data.playersAmount,
                indicators: {
                    strength: data.strength,
                    playersAmount: data.playersAmount,
                    verifiedPlayersAmount: data.verifiedPlayersAmount
                }
            })))
        }
    }

    public static loadShortlistedPlayers() {
        return async dispatch => {
            const shortlistedPlayers = await ShortlistService.loadShortListedPlayers()
            dispatch(stateController.setState({ shortlistedPlayers }))
        }
    }

    public static shortlistToggle(playerId: number, isInShortlist: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const isPreview = getState().agencyProfile.common.isPreview;
            if (isPreview) {
                return
            }

            try {

                const player = getState().agencyProfile.playerPortfolio.players.find(item => item.id === playerId)

                if (!isInShortlist) {
                    dispatch(stateController.setState(prevState => ({ ...prevState, shortlistedPlayers: [...prevState.shortlistedPlayers, playerId] })))
                    await ShortlistService.addToShortlist(playerId);
                } else {
                    dispatch(stateController.setState(prevState => ({ ...prevState, shortlistedPlayers: prevState.shortlistedPlayers.filter(item => item !== playerId) })))
                    await ShortlistService.removeFromShortlist(playerId);
                }
                
                dispatch(userActivityInsert({
                    Message: !isInShortlist ? 'Added to Shortlist' : 'Removed from Shortlist',
                    PageName: 'Club Agents [Agency Profile]',
                    PlayerId: playerId,
                    ClubId: (player.parentSquad && player.parentSquad.id) || (player.currentSquad && player.currentSquad.id) || null,
                    CurrentClubId: (player.currentSquad && player.currentSquad.id) || null,
                    ActionType: !isInShortlist ? ActionType.AddToShortlist : null,
                    PageType: PageType.Agents
                }))
            } catch (e) {
                console.error(e)

                // Discard change if request not succeeded
                if (!isInShortlist) {
                    dispatch(stateController.setState(prevState => ({ ...prevState, shortlistedPlayers: prevState.shortlistedPlayers.filter(item => item !== playerId) })))
                } else {
                    dispatch(stateController.setState(prevState => ({ ...prevState, shortlistedPlayers: [...prevState.shortlistedPlayers, playerId] })))
                }
            }
        }
    }


    public static showMorePlayers() {
        return async (dispatch, getState: () => AppState) => {
            const agent = getState().agencyProfile.agencyInfo
            const isPreview = getState().agencyProfile.common.isPreview

            if (!isPreview) {
                dispatch(userActivityInsert({
                    PageName: 'Club Agents [Agency profile]',
                    Message: `View More Players`,
                    AgencyId: agent.agencyId,
                    PageType: PageType.Agents
                }))
            }
        }
    }

    public static openPlayerProfile(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agencyProfile.playerPortfolio
            const player = substate.players.find(({ id }) => playerId === id);

            window.open(`profile/${playerId}`, '_blank');

            dispatch(userActivityInsert({
                PageName: 'Club Agents [Agency profile]',
                Message: `Opened Player Profile`,
                PlayerId: playerId,
                ClubId: player.parentSquad.id,
                PageType: PageType.Agents
            }))
        }
    }

    public static openClubsPlayers(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agencyProfile.playerPortfolio
            const player = substate.players.find(({ id }) => playerId === id);

            window.open(`/playersearch?q=${player.parentSquad.name}`, '_blank')

            dispatch(userActivityInsert({
                PageName: 'Club Agents [Agency profile]',
                Message: `Opened Clubs Players`,
                PlayerId: playerId,
                ClubId: player.parentSquad.id,
                PageType: PageType.Agents
            }))
        }
    }

    public static declareInterest(playerId: number, agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const isPreview = getState().agencyProfile.common.isPreview;
            if (isPreview) {
                return
            }
            try {
                const substate = getState().agencyProfile.playerPortfolio
                const agent = getState().agencyProfile.agencyInfo

                const isPlayerInShortlist = substate.shortlistedPlayers.includes(playerId)
                if (!isPlayerInShortlist) {
                    dispatch(Actions.shortlistToggle(playerId, isPlayerInShortlist))
                }

                dispatch(stateController.setState({ declareInterestProcessingIds: [ ...substate.declareInterestProcessingIds, playerId]}))
                await PlayerService.declareInterestToAgent(playerId)

                const player = substate.players.find(({ id }) => playerId == id);

                if (player != null) {
                    dispatch(userActivityInsert({
                        PageName: 'Club Agents [Agency profile]',
                        Message: `Declared Interest`,
                        PlayerId: playerId,
                        // ClubId: player.parentSquad.id,
                        AgencyId: agent.agencyId,
                        ActionType: ActionType.DeclaredInterest,
                        PageType: PageType.Agents,
                        UserActivityType: UserActivityType.Action
                    }));
                }

                let players = substate.players
                players.find(item => item.id === playerId).isInterestWasDeclared = true

                dispatch(stateController.setState({
                    players: [...players]
                }))
                
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({ ...prevState, declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== playerId)})))
            }
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerPortfolioState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};