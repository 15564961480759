import axios from 'axios-config'
import { AdminExpertCardResponse, SaveExpertCardRequestModel } from './models'


export default class ExpertCardsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }
    private static axiosConfigWithFile = {
        headers: { 'Content-Type': 'multipart/form-data' },
    }

    public static async getAllExpertCards(): Promise<AdminExpertCardResponse> {
        const { data } = await axios.get(
            `api/v2/AdminExpertCards/GetAllExpertCards/`,
            this.axiosConfig
        )
        return data
    }

    public static async setDefaultSupervisor(adminUserId) {
        return await axios.post(
            `api/v2/AdminExpertCards/SetDefaultSupervisor/${adminUserId}`,
            this.axiosConfig
        )
    }

    public static async setDefaultAdvisor(adminUserId) {
        return await axios.post(
            `api/v2/AdminExpertCards/SetDefaultAdvisor/${adminUserId}`,
            this.axiosConfig
        )
    }

    public static async updatePhoto(adminUserId, photoFile) {
        var bodyFormData = new FormData();
        bodyFormData.append('file', photoFile);

        const {data} = await axios.post(
            `api/v2/AdminExpertCards/UploadImage/${adminUserId}`,
            bodyFormData,
            this.axiosConfigWithFile
        )
        return data
    }

    public static async deletePhoto(adminUserId) {
        await axios.post(
            `api/v2/AdminExpertCards/DeleteImage/${adminUserId}`,
            this.axiosConfig
        )
        return true;
    }

    public static async getExpertAgencies(adminUserId) {
        const { data } = await axios.get(
            `api/v2/AdminExpertCards/GetExpertAgencies/${adminUserId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getExpertSquads(adminUserId) {
        const { data } = await axios.get(
            `api/v2/AdminExpertCards/GetExpertSquads/${adminUserId}`,
            this.axiosConfig
        )
        return data
    }

    public static async deleteExpertCard(adminUserId) {
        await axios.delete(
            `api/v2/AdminExpertCards/DeleteExpertCard/${adminUserId}`,
            this.axiosConfig
        )
    }

    public static async getPhoneCodes() {
        const { data } = await axios.get(
            `api/Area/GetAreaPhoneCodes`,
            this.axiosConfig
        )
        return data
    }

    public static async getAdminsWithoutCards() {
        const { data } = await axios.get(
            `api/AdminSquads/GetSalesSupervisorUsers/true`,
            this.axiosConfig
        )
        return data
    }

    public static async saveExpertCard(expertCard: SaveExpertCardRequestModel) {
        return await axios.post(
            `api/v2/AdminExpertCards/SaveExpertCard/`,
            {
                AdminUserId: expertCard.adminUserId,
                Email: expertCard.email,
                ExpertPosition: expertCard.expertPosition,
                PhoneCodeAreaId: expertCard.phoneCodeAreaId,
                Phone: expertCard.phone,
                CalendarId: expertCard.googleCalendarId
            },
            this.axiosConfig
        )
    }
}