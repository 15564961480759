export type SuggestionItem = {
    id: number;
    title: string;
    subTitle: string;
    flags: FlagWithReason[];
    searchItemType: SearchItemType;
    isRestricted: boolean;
    currentClubId: number;
    isSuspendedContract?: boolean;
    agencyId?: number;
    isOnReleaseList?: boolean;
}

export type FlagWithReason = {
    tooltip: string; 
}

export enum SearchItemType {
    Player = 1,
    Club = 2,
    Agency = 3
}

