import React from 'react'
import styled from 'styled-components'

class Props {
    title: string;
}

const HprTitle = (props: Props) => {
    const { title } = props;
    return <StyledDiv>{title}</StyledDiv>
}

const StyledDiv = styled.div`
    padding-right: 20px;

    @media (max-width: 767px) {
        padding-right: 0;
    }
`
export default HprTitle