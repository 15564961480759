import React from 'react'
import styled from 'styled-components'
import BrazilFlag from 'media/images/icons/Brazil-flag.png'

type Props = {
    title: string
}

const HprTitle = (props: Props) => {
    const { title } = props;
    return <StyledDiv>{title} 
        {/* <img src={BrazilFlag} alt='flag'/> */}
    </StyledDiv>
}

const StyledDiv = styled.div`
    img {
        height: 20px
    }
`
export default HprTitle