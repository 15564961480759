import { AppState } from 'root.reducer';
import axiosLib, { CancelTokenSource } from 'axios';
import { StateController } from 'utils/action-declaration';
import { StaffProfileService } from 'api/staff/staff-profile/staff-profile.service';
import CommonService from 'api/common/common.service';
import { AreaService } from 'api/area/area.service';
import {
    SaveProfileHighlightItem,
    StaffProfileModel,
    QualificationSelectItem,
    CoachingLicensesSelectItem,
    SkillsSelectItem
} from 'api/staff/staff-profile/model';
import { getIsPersonalAccount } from 'store/auth/authReducer';
import { getCancelTokenSource } from 'axios-config';
import { createImage, getRadianAngle, rotateSize } from 'utils/blob-image-path';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { CLUB_PERMISSIONS_PERSONAL_ACCOUNT_UPDATE } from 'store/actionTypes';
import { getClubUserPermissions } from 'store/userPreference/userPreference.reducer';
import { cloneDeep } from 'lodash';
import { NationalitySelectItem, LocationsSelectItem } from 'api/core/area-short-info';
import { IdName } from 'api/core/id-name';
import { StaffAccountService } from 'api/staff/staff-account/staff-account.service';
import { StaffRolesSelectItem } from 'api/staff/staff-account/model';
import { convertToUtcDate } from 'utils/date';

export interface LanguagesSelectItem extends IdName{
    label: string;
    value: string;
}

export type EditBannerModalData = {
    isOpen: boolean;
    bannerImageServerPath: string;
    bannerImage: string;
    bannerImageName: string;
    bannerImageSize: number;
    bannerImageUploadProgress: number;
    bannerImageValidationErrors: string;
}

export type EditProfileImageModalData = {
    isOpen: boolean;
    profileImageServerPath: string;
    profileImage: string;
    profileImageName: string;
    profileImageSize: number;
    profileImageUploadProgress: number;
    profileImageValidationErrors: string;
}

export type EditNameModalData = {
    isOpen: boolean;
    firstName: string;
    lastName: string;
}

export type EditPositionModalData = {
    isOpen: boolean;
    team: string;
    position: StaffRolesSelectItem | null;
}

export type EditAgeModalData = {
    isOpen: boolean;
    birthDate: string | Date | null;
    isAgeVisible: boolean;
}

export type EditAboutModalData = {
    isOpen: boolean;
    aboutText: string;
}

export type EditPersonalSummaryModalData = {
    isOpen: boolean;
    nationality: NationalitySelectItem[];
    languages: LanguagesSelectItem[];
    locations: LocationsSelectItem[];
}

export type EditHighlightsModalData = {
    isOpen: boolean;
    highlights: SaveProfileHighlightItem[];
}

export type EditKeySkillsModalData = {
    isOpen: boolean;
    qualifications: QualificationSelectItem[];
    coachingLicense: CoachingLicensesSelectItem | null;
    skills: SkillsSelectItem[];
}

export interface HighlightsModalValidation {
    isTitleSpecified: boolean;
    isDescriptionSpecified: boolean;
}

class State {
    isLoading: boolean;
    isProcessing: boolean;
    profile: StaffProfileModel;
    editBannerModal: EditBannerModalData;
    editProfileImageModal: EditProfileImageModalData;
    editNameModal: EditNameModalData;
    editPositionModal: EditPositionModalData;
    editAgeModal: EditAgeModalData;
    editAboutModal: EditAboutModalData;
    editPersonalSummaryModal: EditPersonalSummaryModalData;
    editHighlightsModal: EditHighlightsModalData;
    editKeySkillsModal: EditKeySkillsModalData;
    nationalitiesList: NationalitySelectItem[];
    languagesList: LanguagesSelectItem[];
    locationsList: LocationsSelectItem[];
    positionList: StaffRolesSelectItem[];
    coachingLicensesList: CoachingLicensesSelectItem[];
    skillsList: SkillsSelectItem[];
}

const defaultState: State = {
    isLoading: false,
    isProcessing: false,
    profile: null,
    editBannerModal: {
        isOpen: false,
        bannerImageServerPath: null,
        bannerImage: null,
        bannerImageName: null,
        bannerImageSize: null,
        bannerImageUploadProgress: null,
        bannerImageValidationErrors: null,
    },
    editProfileImageModal: {
        isOpen: false,
        profileImageServerPath: null,
        profileImage: null,
        profileImageName: null,
        profileImageSize: null,
        profileImageUploadProgress: null,
        profileImageValidationErrors: null,
    },
    editNameModal: {
        isOpen: false,
        firstName: '',
        lastName: '',
    },
    editPositionModal: {
        isOpen: false,
        team: '',
        position: null
    },
    editAgeModal: {
        isOpen: false,
        birthDate: null,
        isAgeVisible: true,
    },
    editAboutModal: {
        isOpen: false,
        aboutText: ''
    },
    editPersonalSummaryModal: {
        isOpen: false,
        nationality: [],
        languages: [],
        locations: []
    },
    editHighlightsModal: {
        isOpen: false,
        highlights: [],
    },
    editKeySkillsModal: {
        isOpen: false,
        qualifications: [],
        coachingLicense: null,
        skills: [],
    },
    nationalitiesList: [],
    languagesList: [],
    locationsList: [],
    positionList: [],
    coachingLicensesList: [],
    skillsList: []
}

const stateController = new StateController<State>("STAFF_PROFILE/MAIN_INFO", defaultState);

class Actions {
    public static cancelToken: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static init(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await dispatch(Actions.getProfile(staffId))
            } catch (err) {
                console.error(err);
                dispatch(stateController.setState({ isLoading: false }));
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getProfile(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await StaffProfileService.getProfile(staffId);
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...data,
                        nationalities: data.nationalities.map((item) => ({
                            ...item,
                            ico: item.flagPath,
                        })),
                        career: data.career.map((item, index) => ({
                            ...item,
                            key: `${item.role}-${index}`
                        })),
                        playingCareer: data.playingCareer.map((item, index) => ({
                            ...item,
                            key: `${item.role}-${index}`
                        })),
                        references: data.references.map((item, index) => ({
                            ...item,
                            key: `${item.role}-${index}`
                        })),
                    },
                    editBannerModal: {
                        ...prevState.editBannerModal,
                        bannerImageServerPath: data.generalInfo.backgroundImage,
                    },
                    editProfileImageModal: {
                        ...prevState.editProfileImageModal,
                        profileImageServerPath: data.generalInfo.photo,
                    },
                })));
            } catch (err) {
              console.error(err);
            }
        }
    }

    // EDIT BANNER MODAL START

    public static openEditBannerModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editBannerModal: {
                    ...prevState.editBannerModal,
                    isOpen: true,
                }
            })));
        }
    }

    public static closeEditBannerModal() {
        return (dispatch, getState: () => AppState) => {
            const bannerModalState = Selectors.getEditBannerModal(getState());
            if (bannerModalState.bannerImage) {
                window.URL.revokeObjectURL(bannerModalState.bannerImage);
            }
            dispatch(Actions.cancelUploadBannerImage());
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editBannerModal: {
                    ...defaultState.editBannerModal,
                    isOpen: false,
                    bannerImage: null,
                    bannerImageName: null,
                    bannerImageSize: null,
                    bannerImageUploadProgress: null
                }
            })));
        }
    }

    public static cancelUploadBannerImage() {
        return (dispatch, getState: () => AppState) => {
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editBannerModal: {
                    ...prevState.editBannerModal,
                    bannerImageUploadProgress: null,
                }
            })));
        }
    }

    public static removeBannerImage() {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editBannerModal: {
                    ...prevState.editBannerModal,
                    bannerImage: null,
                    bannerImageName: null,
                    bannerImageSize: null,
                    bannerImageUploadProgress: null,
                    bannerImageValidationErrors: null
                }
            })));
        }
    }

    public static onDropBannerImage(files: File[]) {
        return (dispatch, getState: () => AppState) => {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = "";
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 1400) {
                        errors = errors + "Image width should be greater than 1400px. "
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + "File size should not exceed 4MB. "
                    }
                    if (files[0].type.indexOf("jpeg") === -1 && files[0].type.indexOf("png") === -1 && files[0].type.indexOf("svg") === -1 && files[0].type.indexOf("gif") === -1) {
                        errors = errors + "Wrong file type. "
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(Actions.setBannerImage(localImageUrl, files[0].name, files[0].size, errors));
                };
            };
        }
    }

    public static onChangeBannerImage(event: any) {
        return async (dispatch, getState: () => AppState) => {
            let files = event.target.files;

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = "";
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 1400) {
                        errors = errors + "Image width should be greater than 1400px. "
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + "File size should not exceed 4MB. "
                    }
                    if (files[0].type.indexOf("jpeg") === -1 && files[0].type.indexOf("png") === -1 && files[0].type.indexOf("svg") === -1 && files[0].type.indexOf("gif") === -1) {
                        errors = errors + "Wrong file type. "
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(Actions.setBannerImage(localImageUrl, files[0].name, files[0].size, errors));
                };
            };
        }
    }

    public static setBannerImage(image: string, name: string, size: number, validationErrors: string) {
        return async (dispatch, getState: () => AppState) => {
            try {

                dispatch(stateController.setState((prevState) =>({
                    ...prevState,
                    editBannerModal: {
                        ...prevState.editBannerModal,
                        bannerImage: image,
                        bannerImageName: name,
                        bannerImageSize: size,
                        bannerImageValidationErrors: validationErrors
                    }
                })));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static async getCroppedImg(imageSrc, type, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
        const image: any = await createImage(imageSrc);
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) { return null }
        const rotRad = getRadianAngle(rotation);
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)
        ctx.fillStyle = '#FFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0);

        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(data, 0, 0);

        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file))
            }, 'image/png')
        })
    }

    public static onSaveBannerImage(croppedAreaPixels: { height: number, width: number, x: number, y: number }) {
        return async (dispatch, getState: () => AppState) => {
            Actions.cancelToken = getCancelTokenSource();

            try {
                const bannerModalState = Selectors.getEditBannerModal(getState());
                dispatch(stateController.setState({ isProcessing: true }));
                const req = await axiosLib({ method: 'GET', url: bannerModalState.bannerImage, responseType: 'blob' });
                const cropped: any = await Actions.getCroppedImg(bannerModalState.bannerImage, req.data.type, croppedAreaPixels);
                const req2 = await axiosLib({ method: 'GET', url: cropped, responseType: 'blob' });

                const res = await StaffProfileService.uploadBackgroundImage(
                    req2.data,
                    bannerModalState.bannerImageName,
                    (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(stateController.setState((prevState) =>({
                            ...prevState,
                            editBannerModal: {
                                ...prevState.editBannerModal,
                                bannerImageUploadProgress: percentCompleted
                            }
                        })));
                    },
                    Actions.cancelToken.token
                );

                if (res) {
                    dispatch(notificationCreate({ message: 'Banner image has been added successfully', level: 'success'}));
                    dispatch(Actions.onBannerSaved(cropped));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static onBannerSaved(cropped: string) {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            await dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditBannerModal());
        }
    }

    // EDIT BANNER MODAL END

    // EDIT PROFILE IMAGE MODAL START

    public static openEditProfileImageModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editProfileImageModal: {
                    ...prevState.editProfileImageModal,
                    isOpen: true,
                }
            })));
        }
    }

    public static closeEditProfileImageModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editProfileImageModal: {
                    ...prevState.editProfileImageModal,
                    isOpen: false,
                }
            })));


            const profileImageModalState = Selectors.getEditProfileImageModal(getState());
            if (profileImageModalState.profileImage) {
                window.URL.revokeObjectURL(profileImageModalState.profileImage);
            }
            dispatch(Actions.cancelUploadProfileImage());
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editProfileImageModal: {
                    ...defaultState.editProfileImageModal,
                    isOpen: false,
                    profileImage: null,
                    profileImageName: null,
                    profileImageSize: null,
                    profileImageUploadProgress: null,
                }
            })));
        }
    }

    public static cancelUploadProfileImage() {
        return (dispatch, getState: () => AppState) => {
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editProfileImageModal: {
                    ...prevState.editProfileImageModal,
                    profileImageUploadProgress: null,
                }
            })));
        }
    }

    public static removeProfileImage() {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editProfileImageModal: {
                    ...prevState.editProfileImageModal,
                    profileImage: null,
                    profileImageName: null,
                    profileImageSize: null,
                    profileImageUploadProgress: null,
                    profileImageValidationErrors: null
                }
            })));
        }
    }

    public static onDropProfileImage(files: File[]) {
        return (dispatch, getState: () => AppState) => {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = "";
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 140) {
                        errors = errors + "Image width should be greater than 140px. "
                    }
                    if ((e.target as any).height < 140) {
                        errors = errors + "Image height should be greater than 140px. "
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + "File size should not exceed 4MB. "
                    }
                    if (files[0].type.indexOf("jpeg") === -1 && files[0].type.indexOf("png") === -1 && files[0].type.indexOf("svg") === -1 && files[0].type.indexOf("gif") === -1) {
                        errors = errors + "Wrong file type. "
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(Actions.setProfileImage(localImageUrl, files[0].name, files[0].size, errors));
                };
            };
        }
    }

    public static onChangeProfileImage(event: any) {
        return async (dispatch, getState: () => AppState) => {
            let files = event.target.files;

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = "";
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 140) {
                        errors = errors + "Image width should be greater than 140px. "
                    }
                    if ((e.target as any).height < 140) {
                        errors = errors + "Image height should be greater than 140px. "
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + "File size should not exceed 4MB. "
                    }
                    if (files[0].type.indexOf("jpeg") === -1 && files[0].type.indexOf("png") === -1 && files[0].type.indexOf("svg") === -1 && files[0].type.indexOf("gif") === -1) {
                        errors = errors + "Wrong file type. "
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(Actions.setProfileImage(localImageUrl, files[0].name, files[0].size, errors));
                };
            };
        }
    }

    public static setProfileImage(image: string, name: string, size: number, validationErrors: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState((prevState) =>({
                    ...prevState,
                    editProfileImageModal: {
                        ...prevState.editProfileImageModal,
                        profileImage: image,
                        profileImageName: name,
                        profileImageSize: size,
                        profileImageValidationErrors: validationErrors
                    }
                })));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onSaveProfileImage(croppedAreaPixels: { height: number, width: number, x: number, y: number }) {
        return async (dispatch, getState: () => AppState) => {
            Actions.cancelToken = getCancelTokenSource();

            try {
                const profileImageModalState = Selectors.getEditProfileImageModal(getState());
                dispatch(stateController.setState({ isProcessing: true }));
                const req = await axiosLib({ method: 'GET', url: profileImageModalState.profileImage, responseType: 'blob' });
                const cropped: any = await Actions.getCroppedImg(profileImageModalState.profileImage, req.data.type, croppedAreaPixels);
                const req2 = await axiosLib({ method: 'GET', url: cropped, responseType: 'blob' });

                const res = await StaffProfileService.uploadImage(
                    req2.data,
                    profileImageModalState.profileImageName,
                    (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(stateController.setState((prevState) =>({
                            ...prevState,
                            editProfileImageModal: {
                                ...prevState.editProfileImageModal,
                                profileImageUploadProgress: percentCompleted
                            }
                        })));
                    },
                    Actions.cancelToken.token
                );

                if (res) {
                    dispatch(Actions.onProfileImageSaved(cropped));
                    dispatch(notificationCreate({ message: 'Profile image has been added successfully', level: 'success'}));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static onProfileImageSaved(cropped: string) {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            const { personalAccount } = getClubUserPermissions(getState());

            dispatch({
                type: CLUB_PERMISSIONS_PERSONAL_ACCOUNT_UPDATE,
                payload: {
                    data: {
                        ...personalAccount,
                        photo: cropped
                    }
                }
            });

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditProfileImageModal());
        }
    }

    // EDIT PROFILE IMAGE MODAL END

    // EDIT NAME MODAL START

    public static openEditNameModal() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editNameModal: {
                    ...defaultState.editNameModal,
                    isOpen: true,
                    firstName: profile?.generalInfo?.firstName,
                    lastName: profile?.generalInfo?.lastName,
                }
            })));
        }
    }

    public static closeEditNameModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editNameModal: {
                    ...prevState.editNameModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onFirstNameChange(firstName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editNameModal: {
                    ...prevState.editNameModal,
                    firstName: firstName,
                }
            })));
        }
    }

    public static onLastNameChange(lastName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editNameModal: {
                    ...prevState.editNameModal,
                    lastName: lastName,
                }
            })));
        }
    }

    public static onSaveProfileName() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const nameModalState = Selectors.getEditNameModal(getState());
                const res = await StaffProfileService.saveName({
                    firstName: nameModalState.firstName,
                    lastName: nameModalState.lastName
                });

                dispatch(notificationCreate({ message: 'Name has been saved successfully', level: 'success'}));
                dispatch(Actions.onNameSaved(nameModalState.firstName, nameModalState.lastName));

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onNameSaved(firstName: string, lastName: string) {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            const { personalAccount } = getClubUserPermissions(getState());

            dispatch({
                type: CLUB_PERMISSIONS_PERSONAL_ACCOUNT_UPDATE,
                payload: {
                    data: {
                        ...personalAccount,
                        firstName: firstName,
                        lastName: lastName
                    }
                }
            });

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditNameModal());
        }
    }

    // EDIT NAME MODAL END

    // EDIT POSITION MODAL START

    public static getPositionsList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await StaffProfileService.getStaffRoles();

                await dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    positionList: items.map((item) => ({
                        ...item,
                        value: item.name,
                        label: item.name,
                    })),
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }

    public static openEditPositionModal() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            await dispatch(Actions.getPositionsList());
            const positionList = Selectors.getPositionList(getState());

            const currentPosition = positionList?.find((item) => item.id === profile?.generalInfo?.staffRole?.id);

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPositionModal: {
                    ...defaultState.editPositionModal,
                    isOpen: true,
                    team: profile?.generalInfo?.squad.name,
                    position: currentPosition ? currentPosition : null,
                }
            })));
        }
    }

    public static closeEditPositionModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPositionModal: {
                    ...prevState.editPositionModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onPositionChange(selected: StaffRolesSelectItem) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPositionModal: {
                    ...prevState.editPositionModal,
                    position: selected,
                }
            })));
        }
    }

    public static onSavePosition() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const positionModalState = Selectors.getEditPositionModal(getState());
                const res = await StaffProfileService.savePosition({
                    roleId: positionModalState.position.id,
                });

                dispatch(notificationCreate({ message: 'Position has been saved successfully', level: 'success'}));
                dispatch(Actions.onPositionSaved());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onPositionSaved() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditPositionModal());
        }
    }

    // EDIT POSITION MODAL END

    // EDIT AGE MODAL START

    public static openEditAgeModal() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAgeModal: {
                    ...defaultState.editAgeModal,
                    isOpen: true,
                    birthDate: profile.generalInfo.birthDate,
                    isAgeVisible: profile.generalInfo.isAgeVisible,
                }
            })));
        }
    }

    public static closeEditAgeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAgeModal: {
                    ...prevState.editAgeModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onBirthDateChange(date: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAgeModal: {
                    ...prevState.editAgeModal,
                    birthDate: date,
                }
            })));
        }
    }

    public static onShowAgeToggle(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAgeModal: {
                    ...prevState.editAgeModal,
                    isAgeVisible: value,
                }
            })));
        }
    }

    public static onSaveAge() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const ageModalState = Selectors.getEditAgeModal(getState());
                const res = await StaffProfileService.saveAge({
                    birthDate: convertToUtcDate(ageModalState.birthDate).toDate(),
                    isAgeVisible: ageModalState.isAgeVisible
                });

                dispatch(notificationCreate({ message: 'Age has been saved successfully', level: 'success'}));
                dispatch(Actions.onAgeSaved());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onAgeSaved() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditAgeModal());
        }
    }

    // EDIT AGE MODAL END

    // EDIT ABOUT MODAL START

    public static openEditAboutModal() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAboutModal: {
                    ...defaultState.editAboutModal,
                    isOpen: true,
                    aboutText: profile?.about ? profile.about : ''
                }
            })));
        }
    }

    public static closeEditAboutModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAboutModal: {
                    ...prevState.editAboutModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onAboutTextChange(aboutText: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editAboutModal: {
                    ...prevState.editAboutModal,
                    aboutText: aboutText,
                }
            })));
        }
    }

    public static onSaveAbout() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const aboutModalState = Selectors.getEditAboutModal(getState());
                const res = await StaffProfileService.saveAboutText({
                    about: aboutModalState.aboutText.trim(),
                });

                dispatch(notificationCreate({ message: 'About text has been saved successfully', level: 'success'}));
                dispatch(Actions.onAboutSaved());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onAboutSaved() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditAboutModal());
        }
    }

    // EDIT ABOUT MODAL END

    // EDIT PERSONAL SUMMARY MODAL START

    public static getNationalitiesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getNationalitiesList();
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    nationalitiesList: data.map((item) => ({
                        ...item,
                        ico: item.flagPath,
                        value: item.name,
                        label: item.name,
                    })),
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }

    public static getLanguagesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getLanguages();
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    languagesList: data.map((item) => ({
                        ...item,
                        value: item.name,
                        label: item.name,
                    })),
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }

    public static getLocationsList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await AreaService.getCountries();
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    locationsList: items.map((item) => ({
                        ...item,
                        value: item.name,
                        label: item.name,
                    })),
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }

    public static openEditPersonalSummaryModal() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const profile = Selectors.getProfile(getState());

                const currentNationality = profile?.nationalities.map((item) => ({
                    ...item,
                    value: item.name,
                    label: item.name,
                    ico: item.flagPath,
                }));

                const currentLanguages = profile?.languages.map((item) => ({
                    ...item,
                    value: item.name,
                    label: item.name,
                }));

                const currentLocations = profile?.preferredAreas.map((item) => ({
                    ...item,
                    value: item.name,
                    label: item.name,
                }));

                dispatch(Actions.getNationalitiesList());
                dispatch(Actions.getLanguagesList());
                dispatch(Actions.getLocationsList());
                dispatch(stateController.setState((prevState) =>({
                    ...prevState,
                    editPersonalSummaryModal: {
                        ...defaultState.editPersonalSummaryModal,
                        isOpen: true,
                        nationality: currentNationality,
                        languages: currentLanguages,
                        locations: currentLocations
                    }
                })));

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static closeEditPersonalSummaryModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPersonalSummaryModal: {
                    ...prevState.editPersonalSummaryModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onNationalityChange(selected: NationalitySelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPersonalSummaryModal: {
                    ...prevState.editPersonalSummaryModal,
                    nationality: selected,
                }
            })));
        }
    }

    public static onLanguagesChange(selected: LanguagesSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPersonalSummaryModal: {
                    ...prevState.editPersonalSummaryModal,
                    languages: selected,
                }
            })));
        }
    }

    public static onLocationsChange(selected: LocationsSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editPersonalSummaryModal: {
                    ...prevState.editPersonalSummaryModal,
                    locations: selected,
                }
            })));
        }
    }

    public static onSavePersonalSummary() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const aboutModalState = Selectors.getEditPersonalSummaryModal(getState());
                const res = await StaffProfileService.savePersonalSummary({
                    nationalityAreaIds: aboutModalState.nationality.map((item) => item.id),
                    languageIds: aboutModalState.languages.map((item) => item.id),
                    preferredAreaIds:aboutModalState.locations.map((item) => item.id),
                });

                dispatch(notificationCreate({ message: 'Personal summary has been saved successfully', level: 'success'}));
                dispatch(Actions.onPersonalSummarySaved());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onPersonalSummarySaved() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditPersonalSummaryModal());
        }
    }

    // EDIT PERSONAL SUMMARY MODAL END

    // EDIT HIGHLIGHTS MODAL START

    public static openEditHighlightsModal() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            const highlightsDeepCopy =  cloneDeep(profile.highlights);

            const highlightsInitial = highlightsDeepCopy.length === 0 ? [
                {
                    id: null,
                    title: '',
                    description: ''
                }
            ] : [...highlightsDeepCopy];

            await dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editHighlightsModal: {
                    ...defaultState.editHighlightsModal,
                    isOpen: true,
                    highlights: highlightsInitial
                }
            })));
        }
    }

    public static closeEditHighlightsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editHighlightsModal: {
                    ...prevState.editHighlightsModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onHighlightTitleChange(index: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());

            highlightsState[index].title = value;

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editHighlightsModal: {
                    ...prevState.editHighlightsModal,
                    highlights: [...highlightsState]
                }
            })));
        }
    }

    public static onHighlightDescriptionChange(index: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());

            highlightsState[index].description = value;

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editHighlightsModal: {
                    ...prevState.editHighlightsModal,
                    highlights: [...highlightsState]
                }
            })));
        }
    }

    public static createNewHighlight() {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());
            const newHighlight = {
                id: null,
                title: '',
                description: ''
            };

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editHighlightsModal: {
                    ...prevState.editHighlightsModal,
                    highlights: [...highlightsState, newHighlight]
                }
            })));
        }
    }

    public static deleteHighlight(index: number) {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());
            highlightsState.splice(index, 1);

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editHighlightsModal: {
                    ...prevState.editHighlightsModal,
                    highlights: [...highlightsState]
                }
            })));
        }
    }

    public static onSaveHighlights() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const highlightsState = Selectors.getHighlights(getState());

                const requestData = highlightsState.filter(x => !!x.title && !!x.description).map((item) => ({
                    id: item.id ? item.id : null,
                    title: item.title.trim(),
                    description: item.description.trim(),
                }));

                const res = await StaffProfileService.saveHighlights({
                    highlights: requestData
                });

                dispatch(notificationCreate({ message: 'Highlights have been saved successfully', level: 'success'}));
                dispatch(Actions.onHighlightsSaved());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onHighlightsSaved() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditHighlightsModal());
        }
    }

    // EDIT HIGHLIGHTS MODAL END

    // EDIT KEY SKILLS MODAL START

    public static getCoachingLicensesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await StaffProfileService.getCoachingLicenses();
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    coachingLicensesList: items.map((item) => ({
                        ...item,
                        value: item.name,
                        label: item.name,
                    })),
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }

    public static getSkillsList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await StaffProfileService.getSkills();
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    skillsList: items.map((item) => ({
                        ...item,
                        value: item.name,
                        label: item.name,
                    })),
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }

    public static openEditKeySkillsModal() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            await dispatch(Actions.getCoachingLicensesList());
            await dispatch(Actions.getSkillsList());
            const coachingLicensesList = Selectors.getCoachingLicensesList(getState());

            const currentCoachingLicense = coachingLicensesList?.find((item) => item.id === profile?.coachingLicense?.id);

            const currentQualifications = profile?.qualifications.map((item) => ({
                ...item,
                value: item.name,
                label: item.name,
            }));

            const currentSkills = profile?.skills.map((item) => ({
                ...item,
                value: item.name,
                label: item.name,
            }));

            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editKeySkillsModal: {
                    ...defaultState.editKeySkillsModal,
                    isOpen: true,
                    coachingLicense: currentCoachingLicense ? currentCoachingLicense : null,
                    qualifications: currentQualifications,
                    skills: currentSkills
                }
            })));
        }
    }

    public static closeEditKeySkillsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editKeySkillsModal: {
                    ...prevState.editKeySkillsModal,
                    isOpen: false,
                }
            })));
        }
    }

    public static onCoachingLicenseChange(selected: CoachingLicensesSelectItem) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editKeySkillsModal: {
                    ...prevState.editKeySkillsModal,
                    coachingLicense: selected,
                }
            })));
        }
    }

    public static onQualificationsChange(selected: QualificationSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editKeySkillsModal: {
                    ...prevState.editKeySkillsModal,
                    qualifications: selected,
                }
            })));
        }
    }

    public static onSkillsChange(selected: SkillsSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) =>({
                ...prevState,
                editKeySkillsModal: {
                    ...prevState.editKeySkillsModal,
                    skills: selected,
                }
            })));
        }
    }

    public static onSaveKeySkills() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const keySkillsModalState = Selectors.getEditKeySkillsModal(getState());
                const res = await StaffProfileService.saveKeySkills({
                    coachLicenseId: keySkillsModalState.coachingLicense?.id ?? null,
                    qualifications: keySkillsModalState.qualifications.map((item) => ({name: item.name})),
                    skillIds: keySkillsModalState.skills.map((item) => item.id),
                });

                dispatch(notificationCreate({ message: 'Key skills have been saved successfully', level: 'success'}));
                dispatch(Actions.onKeySkillsSaved());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onKeySkillsSaved() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(Actions.getProfile(profile.staffId));
            dispatch(Actions.closeEditKeySkillsModal());
        }
    }

    // EDIT KEY SKILLS MODAL END
}

class Selectors {
    public static getRoot = (state: AppState): State => state.staffProfile;
    public static getProfile = (state: AppState) => Selectors.getRoot(state).profile
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static isOwnProfile = (state: AppState) => {
        const isPersonalAccount = getIsPersonalAccount(state);
        const profile = Selectors.getProfile(state);
        return isPersonalAccount && profile?.staffId === state?.auth?.staffId;
    }

    public static getEditBannerModal = (state: AppState) => Selectors.getRoot(state).editBannerModal;
    public static getEditProfileImageModal = (state: AppState) => Selectors.getRoot(state).editProfileImageModal;
    public static getEditNameModal = (state: AppState) => Selectors.getRoot(state).editNameModal;
    public static getEditPositionModal = (state: AppState) => Selectors.getRoot(state).editPositionModal;
    public static getEditAgeModal = (state: AppState) => Selectors.getRoot(state).editAgeModal;
    public static getEditAboutModal = (state: AppState) => Selectors.getRoot(state).editAboutModal;
    public static getEditPersonalSummaryModal = (state: AppState) => Selectors.getRoot(state).editPersonalSummaryModal;
    public static getEditHighlightsModal = (state: AppState) => Selectors.getRoot(state).editHighlightsModal;
    public static getEditKeySkillsModal = (state: AppState) => Selectors.getRoot(state).editKeySkillsModal;

    public static getNationalitiesList = (state: AppState) => Selectors.getRoot(state).nationalitiesList;
    public static getLanguagesList = (state: AppState) => Selectors.getRoot(state).languagesList;
    public static getLocationsList = (state: AppState) => Selectors.getRoot(state).locationsList;
    public static getPositionList = (state: AppState) => Selectors.getRoot(state).positionList;
    public static getCoachingLicensesList = (state: AppState) => Selectors.getRoot(state).coachingLicensesList;
    public static getSkillsList = (state: AppState) => Selectors.getRoot(state).skillsList;

    public static isFirstNameValid = (state: AppState) => {
        return !!Selectors.getEditNameModal(state).firstName && !!Selectors.getEditNameModal(state).firstName?.trim()
    };
    public static isLastNameValid = (state: AppState) => {
        return !!Selectors.getEditNameModal(state).lastName && !!Selectors.getEditNameModal(state).lastName?.trim()
    };
    public static isEditNameModalDataInvalid = (state: AppState) => {
        return !Selectors.isFirstNameValid(state) || !Selectors.isLastNameValid(state);
    }
    public static isEditNameModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const editNameModal = Selectors.getEditNameModal(state);
        return profile?.generalInfo?.firstName !== editNameModal.firstName || profile?.generalInfo?.lastName !== editNameModal.lastName;
    }
    public static isEditAboutModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const aboutTextServer = profile?.about ?? '';
        const editAboutModal = Selectors.getEditAboutModal(state);
        const aboutTextModal = editAboutModal.aboutText ?? '';
        return aboutTextServer !== aboutTextModal;
    }

    public static getHighlights = (state: AppState) => Selectors.getEditHighlightsModal(state).highlights;
    public static isEditHighlightsModalDataValid = (state: AppState) => {
        const highlightsValidation = Selectors.highlightsModalValidation(state);

        return highlightsValidation.every((x) => (x.isTitleSpecified && x.isDescriptionSpecified));
    }
    public static highlightsModalValidation = (state: AppState): HighlightsModalValidation[] => {
        const highlights = Selectors.getHighlights(state);

        return highlights.map(item => {
            const isTitleSpecified = !!item.title?.trim();
            const isDescriptionSpecified = !!item.description?.trim();
            return {
                isTitleSpecified: isTitleSpecified,
                isDescriptionSpecified: isDescriptionSpecified,
            }
        });
    }
    public static isEditHighlightsModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const highlightsServer = profile?.highlights ?? [];
        const highlightsModal = Selectors.getHighlights(state);

        return !Helpers.isArraysEqual(highlightsServer, highlightsModal);
    }

    public static isEditAgeModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const ageModal = Selectors.getEditAgeModal(state);
        const serverBirthDate = profile?.generalInfo?.birthDate ? new Date(profile?.generalInfo?.birthDate).toISOString() : null;
        const localBirthDate = ageModal?.birthDate ? new Date(ageModal.birthDate).toISOString() : null;

        return serverBirthDate !== localBirthDate || profile?.generalInfo?.isAgeVisible !== ageModal.isAgeVisible;
    }

    public static isNationalityValid = (state: AppState) => Selectors.getEditPersonalSummaryModal(state).nationality.length > 0;
    public static isEditPersonalSummaryModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const personalSummaryModal = Selectors.getEditPersonalSummaryModal(state);

        const serverNationality = profile?.nationalities.map((item) => item.id);
        const localNationality = personalSummaryModal?.nationality.map((item) => item.id);
        const serverLanguages = profile?.languages.map((item) => item.id);
        const localLanguages = personalSummaryModal?.languages.map((item) => item.id);
        const serverLocations = profile?.preferredAreas.map((item) => item.id);
        const localLocations = personalSummaryModal?.locations.map((item) => item.id);

        return !Helpers.isArraysEqual(serverNationality, localNationality) ||
            !Helpers.isArraysEqual(serverLanguages, localLanguages) ||
            !Helpers.isArraysEqual(serverLocations, localLocations);
    }

    public static isEditPositionModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const positionModal = Selectors.getEditPositionModal(state);

        return profile?.generalInfo?.staffRole?.id !== positionModal?.position?.id;
    }

    public static isEditKeySkillsModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const positionModal = Selectors.getEditKeySkillsModal(state);

        const serverQualifications = profile?.qualifications.map((item) => item.name);
        const localQualifications = positionModal.qualifications.map((item) => item.name);
        const serverSkills = profile?.skills.map((item) => item.id);
        const localSkills = positionModal?.skills.map((item) => item.id);

        return !Helpers.isArraysEqual(serverQualifications, localQualifications) ||
            !Helpers.isArraysEqual(serverSkills, localSkills) ||
            profile?.coachingLicense?.id !== positionModal?.coachingLicense?.id;
    }
}

class Helpers {
    public static isArraysEqual = (arr1: any[], arr2: any[]) => {
        if (!arr1 || !arr2) return false;
        if (arr1.length !== arr2.length) return false;
        return arr1.every((obj, index) => JSON.stringify(obj) === JSON.stringify(arr2[index]));
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};