import axios from 'axios-config';

import {CoachProfileModel, CoachAdditionalInfoModel, CoachComparisonResponse, CoachSuggestionsResponse, CoachProfileAccessModel} from './model';
import {CancelToken} from 'axios';

export class CoachProfileService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async checkProfileAccess(coachId: number): Promise<CoachProfileAccessModel> {
        const { data } = await axios.get(
            `api/CoachProfile/CheckProfileAccess/${coachId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCoachProfile(coachId: number): Promise<CoachProfileModel> {
        const { data } = await axios.get(
            `api/CoachProfile/GetProfile/${coachId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getAdditionalInfo(coachId: number): Promise<CoachAdditionalInfoModel> {
        const { data } = await axios.get(
            `api/CoachProfile/GetAdditionalInfo/${coachId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCurrentCoachProfile(): Promise<CoachProfileModel> {
        const { data } = await axios.get(
            `api/CoachProfile/Current`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCurrentAdditionalInfo(): Promise<CoachAdditionalInfoModel> {
        const { data } = await axios.get(
            `api/CoachProfile/CurrentAdditionalInfo`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCoachComparison(firstCoachId: number, seasonId: number, cancelToken: CancelToken, secondCoachId?: number) {
        const url = secondCoachId
            ? `api/CoachProfile/GetCoachComparison?firstCoachId=${firstCoachId}&seasonId=${seasonId}&secondCoachId=${secondCoachId}`
            : `api/CoachProfile/GetCoachComparison?firstCoachId=${firstCoachId}&seasonId=${seasonId}`
        const { data } = await axios.get<CoachComparisonResponse>(
            url,
            {
                ...this.axiosConfig,
                cancelToken: cancelToken
            }
        );
        return data;
    }
    public static async getCoachSuggestions(keyword: string, selectedSeasonId: number, cancelToken: CancelToken) {
        const { data } = await axios.post<CoachSuggestionsResponse>('api/CoachProfile/CoachSuggestions', {
                keyword,
                selectedSeasonId
            },
            {...this.axiosConfig, cancelToken: cancelToken })
        return data;
    }

    public static async saveProfileView(coachId: number) {
        const { data } = await axios.post<any>(`api/CoachProfile/SaveProfileView/${coachId}`, {}, this.axiosConfig)
        return data;
    }
    
    public static async showInterestToCoachProfile() {
        const { data } = await axios.post<any>('api/CoachProfile/ShowInterestToCoachProfile', {
                
            },
            {...this.axiosConfig })
        return data;
    }

    public static async declareInterestToAgentForCoach(staffId: number): Promise<any> {
        const { data } = await axios.post(
            `api/v2/SquadAgentInterest/DeclareInterestForCoach/${staffId}`,
            null,
            this.axiosConfig
        )
        return data
    }
}
