import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuth } from 'store/auth/authReducer';
import hostoryAccessor from 'history-accessor'

import { Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'
import Spinner from 'components/Spinner/Spinner';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { userPaths } from 'routes/paths'
import * as MeetingPreparationController from 'app/events/redux/virtual-summit/meeting-preparation.controller'
import BannerRegistered from 'app/events/components/authorized-event-page/common/banners/banner-registered-rome'
import Tabs from 'app/events/components/authorized-event-page/common/tabs-for-registered-state/tabs'
import TabAttendance from './tab-attendance/tab-attendance.entry'
import TabMeetings from 'app/events/components/authorized-event-page/common/tab-meetings/tab-meetings.entry'
// import TabInfo from './tab-info/tab-info.entry'
import { PageType } from 'constants/enums';
//import BlogIframeModalCTA from '../../common/blog-iframe-modal/cta'
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import { AppState } from 'root.reducer';
import AuthState from 'store/auth/auth-state';
import { UserSummitModel } from 'api/virtual-summit/models/summit-invitationl';

class StateProps {
    isLoading: boolean;
    upcommingEventData: UserSummitModel;
    isRegistrationShown: boolean;
    auth: AuthState;
    headerHeight: number;
}
class DispatchProps {
    
    userActivityInsert: (obj: any) => void;
    loadMeetingPreparationData: (redirect, needToRedirect, defaultPath?: boolean) => void;
}

class Events extends Component<StateProps & DispatchProps, {}> {
    state = {
        activeIndex: 0,
        attendees: null,
        modalVideo: false,
        modalMap: false,
    };

    toggle = modal => {
        this.setState({
            [modal]: !this.state[modal],
        });
    };

    componentDidMount() {

        let search = new URLSearchParams(hostoryAccessor.location.search)

        let from = search.get("from");
        let type = search.get("type");
        let club = search.get("club");
        let redirect = search.get("redirect");

        if (from === "email" && type === "preparation" && club !== null) {
            this.props.userActivityInsert({
                Message: `Opened Link from ${club}`,
                PageName: 'Event [Agenda Email] ',
                PageType: PageType.Events,
                EventId: this.props.auth.eventId,
            });
        }
        if (from === "email" && type === "agenda") {
            this.props.userActivityInsert({
                Message: 'Clicked Prepare Agenda Now',
                PageName: 'Event [Agenda Email] ',
                PageType: PageType.Events, 
                EventId: this.props.auth.eventId,
            });
        }

        this.props.loadMeetingPreparationData(hostoryAccessor.push, redirect === 'true')
    }

    render() {
        const { isLoading, upcommingEventData } = this.props;

        const {
            isTransferIn,
            isTransferOut,
            isGeneralNetworking,
            transferInStepCompleted,
            transferOutStepCompleted,
            generalNetworkingStepCompleted,
            registerNowVisible,
            user,
            isUserRegistered,
            extraAttendees,
            offlineEventTimeZone,
            // isShowPracticalInformation
        } = upcommingEventData

        const isObjectivesDefined = isTransferIn || isTransferOut || isGeneralNetworking
        const isUserCompletedAnyStep = transferInStepCompleted || transferOutStepCompleted || generalNetworkingStepCompleted
        //for successfully npm build 
        if (isUserCompletedAnyStep) { }

        const registeredAttendees = [];
        if (extraAttendees) {
            if (isUserRegistered) {
                registeredAttendees.push(user.name)
            }
            extraAttendees.filter(i => i.isRegistered && i.name !== null).forEach(i => {
                registeredAttendees.push(i.name)
            })
        }

        const eventInfo = getEventInfo(this.props.auth.eventId);
        const { title, eventDates, location } = eventInfo;
        const { canShowAgendaBuilder } = eventInfo.visibility;
        return (
            <div className="authorized-event-page">
                {isLoading && <Spinner />}
                {/* <BannerRegistered
                    title='TransferRoom Summit Rome + '
                    altTitle='Online'
                    altClassName='alternative-title'
                    date='18-19 March 2024, Rome, Italy'
                    altDate='20 March 2024, Online Only'
                    description='Exclusively for decision makers. By invitation only.'
                    isLoading={isLoading}
                    isCompleted={isObjectivesDefined}
                    className="event-banner-layout"
                    onCompleteAttendanceClick={() => {
                        this.props.userActivityInsert({
                            Message: 'Complete Details',
                            PageName: 'Event',
                            PageType: PageType.Events,
                            EventId: this.props.auth.eventId,
                        })
                        hostoryAccessor.push(userPaths.eventCompleteDetails + "?first=true");
                    }}
                    eventAttendeess={registeredAttendees}
                    onAddExtraAttendeeClick={() => {
                        hostoryAccessor.push(userPaths.eventRegistration + "?fromEvent=true")
                    }}
                    showAddExtraAttendeeBtn={true}
                /> */}
                 {!isLoading && canShowAgendaBuilder && (
                    <div className="authorized-event-page__navigation-container" style={{ marginTop: 20 }}>
                        <Tabs headerHeight={this.props.headerHeight} isRegistered={!registerNowVisible} isShowPracticalInfo={false} />
                    </div>
                )}
                            
                {/* <BlogIframeModalCTA /> */}

                {!isLoading && canShowAgendaBuilder &&
                    <>
                        <Switch>
                            <Route exact path="/events/attendance">
                                <div className="events-page-wrapper">
                                    <TabAttendance />
                                </div>
                            </Route>
                            {!registerNowVisible && <Route exact path="/events/agenda-builder">
                                <div className="events-page-wrapper">
                                    <TabMeetings />
                                </div>
                            </Route>}
                            {/* {!registerNowVisible && isShowPracticalInformation && <Route exact path="/events/info">
                                <div className="events-page-wrapper">
                                    <TabInfo />
                                </div>
                            </Route>} */}
                            {<Redirect to="/events/attendance" />}
                        </Switch>
                    </>
                }

                {!isLoading && !canShowAgendaBuilder &&
                    <div style={{ marginTop: 60 }} className="events-page-wrapper">
                        <TabAttendance />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isLoading: MiscEventSelectors.selectIsLoading(state),
    upcommingEventData: state.events.misc.upcommingSummitData,
    isRegistrationShown: state.events.misc.isRegistrationShown,
    auth: getAuth(state),
    headerHeight: state.commonStyles.header.headerHeight
});

const mapDispatchToProps: DispatchProps = {
    userActivityInsert,
    loadMeetingPreparationData: MeetingPreparationController.Actions.loadMeetingPreparationData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
