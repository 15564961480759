import React, { Component } from 'react';
import { connect } from 'react-redux';
import userActivityInsert from '../../../app/user-activity/actions/user-activity.actions';
import FormInputRange from '../FormInputRange/FormInputRange';

export class Log {
    constructor(opts) {
        this.minpos = opts.minpos || 0;
        this.maxpos = opts.maxpos || 100;

        this.minval = Math.log(opts.minval || 1);
        this.maxval = Math.log(opts.maxval || 9000);

        this.opts = opts;

        this.scale = (this.maxval - this.minval) / (this.maxpos - this.minpos);
    }

    value(position) {
        let res;
        if(position === this.maxpos){
            return  this.opts.maxval;
        }
        else  if (position <= 100) {
            res = Math.floor(position / 10) * 100000;
        } else {
            const v1 = (position - this.minpos) * this.scale + this.minval;

            res = Math.expm1(v1);
            if (res === Infinity) {
                return this.maxval;
            }
        }

        return res;
    }

    position(value) {
        let res;

        if (value <= 1000000) {
            res = Math.floor((value / 100000) * 10);
        } else {
            res = this.minpos + (Math.log(value) - this.minval) / this.scale;
            if (res === -Infinity) {
                return this.minpos;
            }
        }

        return res;
    }
}

const beginLogScaling = 2000000;
const minLogPos = 100;
class MarketValueSelector extends Component {
    static roundMarketValue = value => {
        const c1M = 1000000;
        const c1K = 1000;
        const c10K = c1K * 10;
        const c10M = c1M * 10;
        const c100M = c1M * 100;

        if (value >= c100M) {
            const t1 = value / c100M
            const t2 = Number(t1).toFixed(2);
            value = t2 * c100M;
        } else if (value >= c10M) {
            const t2 = Number(value / c10M).toFixed(2);
            value = t2 * c10M;
        } else if (value >= c1M) {
            const t2 = Number(value / c1M).toFixed(1);
            value = t2 * c1M;
        } else if (value >= c10K) {
            const t2 = Number(value / c1K).toFixed(0);
            value = t2 * c1K;
        } else if(value >= c1K) {
            const t2 = Number(value / c1K).toFixed(1);
            value = t2 * c1K;
        } else if(value >= 100){
            const t2 = Number(value / 100).toFixed(0);
            value = t2 * 100;
        } else {
            value = Number(value).toFixed(0)
        }

        return value;
    };
    
    static displayMarketValue = value => {
        const val = MarketValueSelector.roundMarketValue(value);
        if (val >= 1000000) {
            return parseFloat(Number(val / 1000000).toFixed(1));
        } else if (val >= 1000) {
            return parseFloat(Number(val / 1000).toFixed(2));
        } else return val;
    };

    static valueUnts = value => {
        const val = MarketValueSelector.roundMarketValue(value);
        return val >= 1000000 ? 'M' : val >= 1000 ? 'k' : '';
    };

    state = {
        value: { min: 0, max: 0 },
    };

    componentDidMount() {
        this.logSlider = new Log({
            minpos: minLogPos,
            maxpos: 500,
            minval: beginLogScaling,
            maxval:
                this.props.structure.maxMarketValue ||
                this.props.criteria.maxMarketValue,
        });

        const min = this.logSlider.position(0);
        const max = this.logSlider.position(
            this.props.structure.maxMarketValue ||
                this.props.criteria.maxMarketValue,
        );
        this.setState({
            changing: false,
            value: {
                min: min,
                max: max,
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.changing) {
            if (
                this.props.criteria.minMarketValue !==
                    prevProps.criteria.minMarketValue ||
                this.props.criteria.maxMarketValue !==
                    prevProps.criteria.maxMarketValue ||
                this.props.structure.minMarketValue !==
                    prevProps.structure.minMarketValue ||
                this.props.structure.maxMarketValue !==
                    prevProps.structure.maxMarketValue
            ) {
                this.logSlider = new Log({
                    minpos: minLogPos,
                    maxpos: 500,
                    minval: beginLogScaling,
                    maxval:
                        this.props.structure.maxMarketValue ||
                        this.props.criteria.maxMarketValue,
                });
                const newValue = {
                    value: {
                        min: this.logSlider.position(0),
                        max: this.logSlider.position(
                            this.props.structure.maxMarketValue ||
                                this.props.criteria.maxMarketValue,
                        ),
                    },
                };
                this.setState(newValue);
            }
        }
    }

    calcPos = pos => {
        if (pos === 0) return 0;
        const val = this.roundValue(this.logSlider.value(pos));
        if (val > 1000) return Math.round(val / 100) * 100;
        if (val > 500) return Math.round(val / 10) * 10;
        return Math.round(val);
    };

    roundValue = value => {
        const c1M = 1000000;
        const c1K = 1000;
        const c10K = c1K * 10;
        const c74M = c1M * 74;
        const c29M = c1M * 29;
        if (value >= c74M) {
            const t1 = parseInt(value / c1M);
            const t2 = parseInt(Math.ceil(t1 / 25));
            value = t2 * 25 * c1M;
        } else if (value >= c29M) {
            const t1 = parseInt(value / c1M);
            const t2 = parseInt(Math.ceil(t1 / 5));
            value = t2 * 5 * c1M;
        } else if (value >= c1M) {
            const t1 = parseInt(value / c1M);
            value = t1 * c1M;
        } else if (value >= c10K) {
            const t1 = parseInt(value / c1K);
            const t2 = parseInt(Math.ceil(t1 / 10));
            value = t2 * 10 * c1K;
        } else {
            const t2 = parseInt(Math.ceil(value / c1K));
            value = t2 * c1K;
        }

        if (value > this.props.structure.maxMarketValue)
            value = this.props.structure.maxMarketValue;
        if (value < 0) value = 0;

        return value;
    };

    onChange = value => {
        let minValue = this.logSlider.value(value.min);
        let maxValue = this.logSlider.value(value.max);
        maxValue = this.roundValue(maxValue);
        minValue = this.roundValue(minValue);
        this.setState({
            value: {
                min: this.logSlider.position(minValue),
                max: this.logSlider.position(maxValue),
            },
        });
    };

    display = value => {
        const val = `${this.calcPos(value)}`;
        if (val >= 1000000) {
            return parseInt(val / 1000000);
        } else if (val >= 1000) {
            return parseInt(val / 1000);
        } else return val;
    };

    unts = value => {
        const val = `${this.calcPos(value)}`;
        return val >= 1000000 ? 'M' : val >= 1000 ? 'K' : '';
    };


    
    render() {
        const { onChange, pageName, structure } = this.props;

        const label = (
            <p className="tr-range-selector__status">
                {this.display(this.state.value.min)}
                {this.unts(this.state.value.min)}-
                {this.display(this.state.value.max)}
                {`${this.unts(this.state.value.max)}${this.calcPos(this.state.value.max) >= structure.maxMarketValue ? '+' : ''}`}
            </p>
        );

        return (
            <div>
                {this.props.hideLabel ? '' : label}
                <FormInputRange
                    allowSameValues
                    range
                    maxValue={500}
                    minValue={0}
                    value={this.state.value}
                    onChange={this.onChange}
                    onChangeStart={value => {
                        this.setState({ changing: true });
                    }}
                    onChangeComplete={value => {
                        if (pageName !== undefined) {
                            this.props.userActivityInsert({
                                Message:
                                    'Market Value: ' +
                                    this.display(this.state.value.min) + this.unts(this.state.value.min) +
                                    ' - ' +
                                    this.display(this.state.value.max) + this.unts(this.state.value.max),
                                PageName: pageName,
                            });
                        }
                        const minValue = this.roundValue(
                            this.logSlider.value(value.min),
                        );
                        const res = {
                            minMarketValue: minValue <= 0 ? 0 : minValue,
                            maxMarketValue: this.roundValue(
                                this.logSlider.value(value.max),
                            ),
                            minMarketValueFormatted: this.display(this.state.value.min) + this.unts(this.state.value.min),
                            maxMarketValueFormatted: `${this.display(this.state.value.max)}${this.unts(this.state.value.max)}${this.calcPos(this.state.value.max) >= structure.maxMarketValue ? '+' : ''}`,
                        };
                        onChange(res);
                        this.setState({ changing: false });
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = { userActivityInsert };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarketValueSelector);
