import { createElement } from 'react'
import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
// import SpeakerCard from './speaker-card/speaker-card-box';
import HprContent from './hpr/hpr-content';
import HprTitle from './hpr/hpr-title';

const title = 'TransferRoom Deal Day: Madrid + Online';

const eventSettings: EventInfoModel = {
    shortTitle: "Madrid Summit",
    title: title,
    shortEventDates: "5-6th August 2024",
    eventDates: "5-6th August 2024",
    location: "Madrid, Spain",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CEST',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        title: createElement(HprTitle, { title }),
        userActivityPageName: "Home [TransferRoom Summit Madrid]",
        description: createElement(HprContent),
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: true,
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: false,
    }
};

export default eventSettings;