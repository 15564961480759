import { StateController } from 'utils/action-declaration';
import UserPasswordService from 'api/user-password/user-password';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import AgencySettingsService from 'api/agency/agent/agent-profile/agent-profile.service';
import { getAuth } from 'store/auth/authReducer';
import PasswordValidation from 'utils/validate-password';

class State {
    modalData?: any;
    password: string;
    newPassword: string;
    confirmNewPassword: string;
    newPasswordError: any;
    rePasswordError: any;
    isProcessing: boolean;
    isValidPassword: boolean;
    isOldPasswordValid: boolean;
}

const defaultState: State = {
    modalData: null,
    password: "",
    confirmNewPassword: "",
    newPassword: "",
    newPasswordError: null,
    rePasswordError: null,
    isProcessing: false,
    isValidPassword: true,
    isOldPasswordValid: null,
}

const stateController = new StateController<State>(
    "AGENCY/CHANGE_PASSWORD_PROFILE",
    defaultState
);

class Actions {
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static clearErrors() {
        return dispatch => {
            dispatch(stateController.setState({
                newPasswordError: null,
                rePasswordError: null,
                isValidPassword: true,
            }));
        }
    }

    public static setPassword(password: string) {
        return async dispatch => {
            dispatch(stateController.setState({ password: password }));
        }
    }

    public static setNewPassword(newPassword: string) {
        return async (dispatch, getState: () => AppState) => {
            const rePassword = Selectors.getRePass(getState())
            const validation = PasswordValidation.validate(newPassword)
            let rePasswordError = PasswordValidation.validateConfirmPassword(newPassword, rePassword);

            dispatch(stateController.setState({
                newPassword,
                newPasswordError: validation.rules,
                isValidPassword: validation.isSuccess,
                rePasswordError,
            }));
        }
    }

    public static setPasswordRe(confirmNewPassword: string) {
        return async (dispatch, getState: () => AppState) => {
            const password = Selectors.getNewPassword(getState())
            let rePasswordError = PasswordValidation.validateConfirmPassword(password, confirmNewPassword);
            dispatch(stateController.setState({
                confirmNewPassword,
                rePasswordError
            }));
        }
    }

    public static openChangedPasswordModal(data) {
        return async (dispatch) => {
            dispatch(stateController.setState({ modalData: data }))
            dispatch(userActivityInsert({
                PageName: 'Edit Profile [Change password]',
                Message: `Opened Change Password`,
                PageType: PageType.EditProfile
            }))
        }
    }
    public static closeChangedPasswordModal() {
        return async (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static cancelChangedPasswordModal() {
        return async (dispatch) => {
            dispatch(Actions.closeChangedPasswordModal())
            dispatch(userActivityInsert({
                PageName: 'Edit Profile [Change password]',
                Message: `Cancelled`,
                PageType: PageType.EditProfile
            }))
        }
    }

    public static changePassword() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const state = getState();
                let oldPassword = Selectors.getPassword(state);
                let confirmNewPassword = Selectors.getRePass(state);
                let newPassword = Selectors.getNewPassword(state);

                if (!oldPassword || !confirmNewPassword || !newPassword) {
                    return;
                }

                const validation = PasswordValidation.validate(newPassword)
                if (!validation.isSuccess) {
                    dispatch(stateController.setState({
                        newPasswordError: validation.rules,
                        isValidPassword: validation.isSuccess,
                    }));
                    return
                }

                let rePasswordError = PasswordValidation.validateConfirmPassword(newPassword, confirmNewPassword);

                if (rePasswordError) {
                    dispatch(stateController.setState({
                        newPasswordError: null,
                        rePasswordError: rePasswordError,
                    }));
                    return;
                }

                dispatch(stateController.setState({ isProcessing: true }))

                const data = await UserPasswordService.updatePassword({ password: oldPassword, newPasword: newPassword });

                if (data.currentPasswordWrong) {
                    dispatch(stateController.setState({
                        newPasswordError: null,
                        rePasswordError: null,
                        isValidPassword: true,
                        isOldPasswordValid: false,
                    }));
                } else if (!data.isSuccess) {
                    dispatch(stateController.setState({
                        newPasswordError: data.rules,
                        rePasswordError: null,
                        isValidPassword: false,
                        isOldPasswordValid: !data.currentPasswordWrong,
                    }));
                } else {
                    dispatch(userActivityInsert({
                        PageName: 'Edit Profile [Change password]',
                        Message: `Set new password (success)`,
                        PageType: PageType.EditProfile
                    }))
                    dispatch(Actions.closeChangedPasswordModal())
                }
            } catch (error) {
                dispatch(userActivityInsert({
                    PageName: 'Edit Profile [Change password]',
                    Message: `Set New Password (Error)`,
                    PageType: PageType.EditProfile
                }))
            } finally {
                dispatch(stateController.setState({
                    isProcessing: false,
                }))
            }
        }
    }

    public static validateOldPassword() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isOldPasswordValid: null }))
            const userId = getAuth(getState()).userId;
            const password = Selectors.getPassword(getState())

            try {
                if (userId && password) {
                    const resp = await AgencySettingsService.validatePassword(
                        userId,
                        password,
                    );
                    dispatch(stateController.setState({ isOldPasswordValid: resp }))
                }
            } catch {
                dispatch(stateController.setState({ isOldPasswordValid: null }))
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.agent.agencySettings.changePassword;
    public static getPasswordChangedModalData = (state: AppState): number =>
        Selectors.getRoot(state).modalData;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).password;
    public static getNewPassword = (state: AppState) => Selectors.getRoot(state).newPassword;
    public static getRePass = (state: AppState) => Selectors.getRoot(state).confirmNewPassword;
    public static getNewPasswordError = (state: AppState) => Selectors.getRoot(state).newPasswordError;
    public static getRePasswordError = (state: AppState) => Selectors.getRoot(state).rePasswordError;
    public static getProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static isValidPassword = (state: AppState) => Selectors.getRoot(state).isValidPassword;
    public static isOldPasswordValid = (state: AppState) => {
        const isValid = Selectors.getRoot(state).isOldPasswordValid;
        return isValid || isValid === null
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};
