// import axios from 'axios-config';
import axios from 'axios';

export const convertToPlayableUrl = (url) => {
    // Check youtube and vimeo
    // eslint-disable-next-line
    const firstCheck = isSupportedVideoUrl(url)
    if (firstCheck) {
        if (RegExp.$3.indexOf('youtu') > -1) {
            if (url.indexOf('/shorts/') > -1)
            {
                return url.replace(/\/shorts\//g, '/embed/');
            } else {
                return "//www.youtube.com/embed/" + RegExp.$6;
            }
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            return 'https://player.vimeo.com/video/' + RegExp.$6 + '?h=' + RegExp.$8
        }
    } else {
        // throw Error("Not supported video resource.");
        return;
    }
}

export const getPlayableDropboxLink = (url) => {
    // Check dropbox
    let candidate = ''
    if (url.indexOf('.mp4') !== -1) {
        candidate = url.slice(0, url.indexOf('.mp4') + 4)
    } else if (url.indexOf('.m4v') !== -1) {
        candidate = url.slice(0, url.indexOf('.m4v') + 4)
    } else if (url.indexOf('.webm') !== -1) {
        candidate = url.slice(0, url.indexOf('.webm') + 5)
    }

    let result = candidate.match(/(http:|https:|)\/\/(player.|www.)?(dropbox\.com)\/(s\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*\/)?(.*)/);
    if (result) {
        return 'https://dropbox.com/' + RegExp.$4 + RegExp.$5 + RegExp.$6 + '?raw=1'
    }
}

export const isDropboxLink = (url) => {
    let candidate = ''
    if (url.indexOf('.mp4') !== -1) {
        candidate = url.slice(0, url.indexOf('.mp4') + 4)
    } else if (url.indexOf('.m4v') !== -1) {
        candidate = url.slice(0, url.indexOf('.m4v') + 4)
    } else if (url.indexOf('.webm') !== -1) {
        candidate = url.slice(0, url.indexOf('.webm') + 5)
    }
    let result = candidate.match(/(http:|https:|)\/\/(player.|www.)?(dropbox\.com)\/(s\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*\/)?(.*)/);

    return !!result;
}

export const getTumbnailUrl = async (url) => {
    // Check youtube and vimeo
    // eslint-disable-next-line
    const firstCheck = isSupportedVideoUrl(url)
    if (firstCheck) {
        if (RegExp.$3.indexOf('youtu') > -1) {
            return "https://img.youtube.com/vi/" + RegExp.$6 + '/hqdefault.jpg';
        }
        else if (RegExp.$3.indexOf('vimeo') > -1) {
            //return 'https://i.vimeocdn.com/video/' + RegExp.$6 + '.jpg'

            let response = await axios({
                url: 'https://vimeo.com/api/v2/video/' + RegExp.$6 + '.json',
                method: 'GET',
            });
        
            return response.data[0].thumbnail_large


        }
    } else {
        // throw Error("Not supported video resource.");
        return;
    }
} 

export const isSupportedVideoUrl = (url) => {
    // Check youtube and vimeo
    // eslint-disable-next-line
    return  url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\/([A-Za-z0-9._%-]*))*(\&\S+)?/);
}

export const isYoutubeVideoExists = async (videoId) => {
    const searchParams = new URLSearchParams({
        url: `https://www.youtube.com/watch?v=${videoId}`,
    });
    const res = await fetch('https://www.youtube.com/oembed?' + searchParams, { method: "HEAD" });
    return res.status >= 200 && res.status < 400;
}

export const isVimeoVideoExists = async (url) => {
    const searchParams = new URLSearchParams({
        url: url,
    });
    const res = await fetch('https://vimeo.com/api/oembed.json?' + searchParams, { method: "HEAD" });
    return res.status >= 200 && res.status < 400;
}