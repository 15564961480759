import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import AdminPermissionsService from 'api/admin/admin-permissions/admin-permissions.service'
import {
    AdminPermissionsResponseModel,
    AdminPagePermissionsModel,
    AdminPermissionsModalState,
    ClubsTabState,
    AreasTabState,
    PermissionEnum,
    AdminPermissionsModalTabTypeEnum
} from 'api/admin/admin-permissions/models'

class AdminPermissions {
    items: AdminPermissionsResponseModel['userPermissions']
    adminPageGroups: AdminPermissionsResponseModel['adminPageGroups']
    processingPermissionIds: Array<{ id: number, colId: number }>
    processingHistoryDepthIds: Array<number>
    isLoading: boolean
    modalState: AdminPermissionsModalState
    clubsTabState: ClubsTabState
    areasTabState: AreasTabState
}

const defaultState: AdminPermissions = {
    items: [],
    adminPageGroups: [],
    processingPermissionIds: [],
    processingHistoryDepthIds: [],
    isLoading: false,
    modalState: {
        isModalOpen: false,
        loading: false,
        supervisors: [],
        areas: [],
        userId: null,
        permissionId: null,
        windowTitle: '',
        isAllAreasSelected: false,
        tabKey: AdminPermissionsModalTabTypeEnum.AreasSelectTab,
        processingTabCheckboxIds: []
    },
    clubsTabState: {
        keyword: '',
        filteredSupervisorsList: [],
        selectedSupervisors: [],
        processingSupervisorsIds: [],
    },
    areasTabState: {
        keyword: '',
        filteredAreasList: [],
        selectedAreas: [],
        processingAreasIds: [],
    }
}

const stateController = new StateController<AdminPermissions>(
    'ADMIN_PERMISSIONS',
    defaultState
)

class Actions {
    public static load() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }))
            try {
                const data = await AdminPermissionsService.loadPermissionsData()

                let { userPermissions, adminPages } = data

                if (userPermissions.length > 0) {

                    const rowSpans = userPermissions.reduce((result, item) => {
                        result[item.staffTypeId] = (result[item.staffTypeId] || 0) + 1
                        return result;
                    }, {})

                    userPermissions = userPermissions.map((item, index) => {
                        item.isFirst = false
                        item.isLast = false
                        item.rowSpan = 1

                        var previousItem = index === 0 ? null : userPermissions[index - 1]
                        var nextItem = index === userPermissions.length - 1 ? null : userPermissions[index + 1]

                        if (previousItem === null || item.staffTypeId !== previousItem.staffTypeId) {
                            item.isFirst = true
                            item.rowSpan = rowSpans[item.staffTypeId]
                        }
                        if (nextItem === null || item.staffTypeId !== nextItem.staffTypeId) {
                            item.isLast = true
                        }

                        return item
                    })

                    userPermissions = userPermissions.map((userItem, index) => {

                        const isPermitted = (user, permissionId) => !!user.allowedPageCodes.find(permission => permission === permissionId);
                        const getAllowedAreasIds = (user, permissionId) => user.allowedAreas[permissionId];
                        const getAllowedAssignedClubsIds = (user, permissionId) => user.allowedAssignedClubs[permissionId]

                        adminPages.map((pageItem, index) => {
                            const { id, code } = pageItem;
                            if (pageItem.isForAreas) {
                                userItem[code] = {
                                    isPermitted: isPermitted(userItem, code),
                                    allowedAreas: getAllowedAreasIds(userItem, id),
                                    allowedAssignedClubs: getAllowedAssignedClubsIds(userItem, code),
                                    isAssignedClubs: userItem['IsAssignedClubs'],
                                }
                            } else if (pageItem.isForColumns) {
                            } else if (pageItem.isForHistoryDepth) {
                            } else {
                                userItem[code] = isPermitted(userItem, code)
                            }
                            return true
                        })

                        return userItem
                    })
                }

                const items = userPermissions.map(item => ({
                    ...item,
                    key: item.userId,
                }))

                const areas = data.areas.map(item => ({
                    ...item,
                    key: item.id,
                }))
                const supervisors = data.supervisors.map(item => ({
                    ...item,
                    key: item.id,
                }))

                // filter areas without empty item (id = 271)
                const countries = areas.filter(item => item.name !== '')

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: items,
                    adminPageGroups: data.adminPageGroups,
                    modalState: {
                        ...prevState.modalState,
                        supervisors: supervisors,
                        areas: countries,
                    },
                })))
                dispatch(stateController.setState({ isLoading: false }))
            } finally {

            }
        }
    }
    public static changePermission(value: boolean, userId: number, permissionId: number, permission: PermissionEnum) {
        return (dispatch) => {
            if (value) {
                dispatch(Actions.removePermission(userId, permissionId, permission))
            }
            if (!value) {
                dispatch(Actions.addPermission(userId, permissionId, permission))
            }
        }
    }
    public static addPermission(userId: number, permissionId: number, permission: PermissionEnum) {
        return async (dispatch, getState: () => AppState) => {
            const processingItem = {
                id: userId,
                colId: permissionId,
            }
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPermissionIds: [
                        ...prevState.processingPermissionIds,
                        processingItem,
                    ]
                })))
                const data = await AdminPermissionsService.addAdminPermission(userId, permissionId)
                if (data.hasResult) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [
                            ...prevState.items.map(item => item.userId === userId ? ({ ...item, [permission]: true }) : ({ ...item }))
                        ]
                    })))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPermissionIds: [
                        ...prevState.processingPermissionIds.filter(item => item !== processingItem)
                    ]
                })))
            }
        }
    }
    public static removePermission(userId: number, permissionId: number, permission: PermissionEnum) {
        return async (dispatch, getState: () => AppState) => {
            const processingItem = {
                id: userId,
                colId: permissionId,
            }
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPermissionIds: [
                        ...prevState.processingPermissionIds,
                        processingItem,
                    ]
                })))
                const data = await AdminPermissionsService.removeAdminPermission(userId, permissionId)
                if (data.hasResult) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [
                            ...prevState.items.map(item => item.userId === userId ? ({ ...item, [permission]: false }) : ({ ...item }))
                        ]
                    })))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPermissionIds: [
                        ...prevState.processingPermissionIds.filter(item => item !== processingItem)
                    ]
                })))
            }
        }
    }

    public static disposeModalState() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...defaultState.modalState
                },
                areasTabState: {
                    ...defaultState.areasTabState
                },
                clubsTabState: {
                    ...defaultState.clubsTabState
                },
            })));
        }
    }
    public static openModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...prevState.modalState,
                    isModalOpen: true
                },
            })))
        }
    }
    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.load())
            dispatch(Actions.disposeModalState())
        }
    }
    public static onKeywordChangeSupervisor(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubsTabState: {
                    ...prevState.clubsTabState,
                    keyword: value
                }
            })))
            dispatch(Actions.filteringSupervisors(value))
        }
    }
    public static onKeywordChangeArea(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                areasTabState: {
                    ...prevState.areasTabState,
                    keyword: value
                }
            })))
            dispatch(Actions.filteringAreas(value))
        }
    }
    public static filteringSupervisors(value: string) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().admin.adminPermissions

            const supervisors = substate.modalState.supervisors
            const supervisorsList = (supervisors || [])
                .filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
            dispatch(stateController.setState({
                clubsTabState: { ...substate.clubsTabState, filteredSupervisorsList: supervisorsList },
            }));
        }
    }
    public static selectSupervisor(value: number, checked: boolean) {
        return async (dispatch) => {
            if (checked) {
                dispatch(Actions.addSupervisor(value))
            } else {
                dispatch(Actions.removeSupervisor(value))
            }
        }
    }
    public static addSupervisor(value: number) {
        return async (dispatch, getState: () => AppState) => {

            const permissionId = getState().admin.adminPermissions.modalState.permissionId
            const userId = getState().admin.adminPermissions.modalState.userId

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTabState: {
                        ...prevState.clubsTabState,
                        processingSupervisorsIds: [...prevState.clubsTabState.processingSupervisorsIds, value]
                    }
                })))
                const data = await AdminPermissionsService.changeAssignedClubs(userId, value, permissionId, true, false)

                if (data.hasResult) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        clubsTabState: {
                            ...prevState.clubsTabState,
                            selectedSupervisors: [...prevState.clubsTabState.selectedSupervisors, value]
                        }
                    })))
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTabState: {
                        ...prevState.clubsTabState,
                        processingSupervisorsIds: prevState.clubsTabState.processingSupervisorsIds.filter(item => item !== value)
                    }
                })))
            }
        }
    }

    public static removeSupervisor(value: number) {
        return async (dispatch, getState: () => AppState) => {

            const permissionId = getState().admin.adminPermissions.modalState.permissionId
            const userId = getState().admin.adminPermissions.modalState.userId

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTabState: {
                        ...prevState.clubsTabState,
                        processingSupervisorsIds: [...prevState.clubsTabState.processingSupervisorsIds, value]
                    }
                })))
                const data = await AdminPermissionsService.changeAssignedClubs(userId, value, permissionId, false, false)

                if (data.hasResult) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        clubsTabState: {
                            ...prevState.clubsTabState,
                            selectedSupervisors: prevState.clubsTabState.selectedSupervisors.filter(item => item !== value)
                        }
                    })))
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTabState: {
                        ...prevState.clubsTabState,
                        processingSupervisorsIds: prevState.clubsTabState.processingSupervisorsIds.filter(item => item !== value)
                    }
                })))
            }
        }
    }

    public static filteringAreas(value: string) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().admin.adminPermissions

            const countries = substate.modalState.areas
            const countriesList = (countries || [])
                .filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
            dispatch(stateController.setState({
                areasTabState: { ...substate.areasTabState, filteredAreasList: countriesList },
            }));
        }
    }

    public static selectArea(value: number, checked: boolean) {
        return async (dispatch) => {
            if (checked) {
                dispatch(Actions.addArea(value))
            } else {
                dispatch(Actions.removeArea(value))
            }
        }
    }

    public static addArea(value: number) {
        return async (dispatch, getState: () => AppState) => {
            const permissionId = getState().admin.adminPermissions.modalState.permissionId
            const userId = getState().admin.adminPermissions.modalState.userId

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    areasTabState: {
                        ...prevState.areasTabState,
                        processingAreasIds: [...prevState.areasTabState.processingAreasIds, value]
                    }
                })))
                const data = await AdminPermissionsService.allowArea(userId, permissionId, value)

                if (data.hasResult) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        areasTabState: {
                            ...prevState.areasTabState,
                            selectedAreas: [...prevState.areasTabState.selectedAreas, value]
                        }
                    })))
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    areasTabState: {
                        ...prevState.areasTabState,
                        processingAreasIds: prevState.areasTabState.processingAreasIds.filter(item => item !== value)
                    }
                })))
            }
        }
    }

    public static removeArea(value: number) {
        return async (dispatch, getState: () => AppState) => {

            const permissionId = getState().admin.adminPermissions.modalState.permissionId
            const userId = getState().admin.adminPermissions.modalState.userId

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    areasTabState: {
                        ...prevState.areasTabState,
                        processingAreasIds: [...prevState.areasTabState.processingAreasIds, value]
                    }
                })))
                const data = await AdminPermissionsService.disallowArea(userId, permissionId, value)

                if (data.hasResult) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        areasTabState: {
                            ...prevState.areasTabState,
                            selectedAreas: prevState.areasTabState.selectedAreas.filter(item => item !== value)
                        }
                    })))
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    areasTabState: {
                        ...prevState.areasTabState,
                        processingAreasIds: prevState.areasTabState.processingAreasIds.filter(item => item !== value)
                    }
                })))
            }
        }
    }

    public static selectAll() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...prevState.modalState,
                    loading: true
                }
            })))
            try {
                const permissionId = getState().admin.adminPermissions.modalState.permissionId
                const userId = getState().admin.adminPermissions.modalState.userId
                const key = getState().admin.adminPermissions.modalState.tabKey
                const isAreasTab = key === AdminPermissionsModalTabTypeEnum.AreasSelectTab

                if (isAreasTab) {
                    const areas = getState().admin.adminPermissions.modalState.areas.map(item => item.id)

                    await AdminPermissionsService.allowAllAreas(userId, permissionId)

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        areasTabState: {
                            ...prevState.areasTabState,
                            selectedAreas: areas
                        }
                    })))
                } else {
                    const supervisors = getState().admin.adminPermissions.modalState.supervisors.map(item => item.id)

                    await AdminPermissionsService.changeAssignedClubs(userId, userId, permissionId, true, true)

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        clubsTabState: {
                            ...prevState.clubsTabState,
                            selectedSupervisors: supervisors
                        }
                    })))
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalState: {
                        ...prevState.modalState,
                        loading: false
                    }
                })))
            }
        }
    }

    public static changeTab(key: number, checked: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...prevState.modalState,
                    loading: true,
                    processingTabCheckboxIds: [key]
                }
            })))

            const permissionId = getState().admin.adminPermissions.modalState.permissionId
            const userId = getState().admin.adminPermissions.modalState.userId
            const currentStep = Selectors.getTabKey(getState())

            try {
                if (key === AdminPermissionsModalTabTypeEnum.AllAreasSelectedTab) {
                    if (checked) {
                        if (currentStep === AdminPermissionsModalTabTypeEnum.ClubsSelectTab) {
                            await AdminPermissionsService.removeAssignedClubByPermissionIdAndUserId(userId, permissionId)
                        }
                        await AdminPermissionsService.addAdminPermission(userId, permissionId)
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            modalState: {
                                ...prevState.modalState,
                                tabKey: AdminPermissionsModalTabTypeEnum.AllAreasSelectedTab
                            },
                            areasTabState: {
                                ...prevState.areasTabState,
                                selectedAreas: []
                            }
                        })))
                    }
                    if (!checked) {
                        await AdminPermissionsService.removeAdminPermission(userId, permissionId)
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            modalState: {
                                ...prevState.modalState,
                                tabKey: AdminPermissionsModalTabTypeEnum.AreasSelectTab
                            }
                        })))
                    }
                }

                if (key === AdminPermissionsModalTabTypeEnum.ClubsSelectTab) {
                    if (checked) {
                        await AdminPermissionsService.changeAssignedClubs(userId, userId, permissionId, true, false)
                        await AdminPermissionsService.removeAdminPermission(userId, permissionId)
                        await AdminPermissionsService.disallowAllAreas(userId, permissionId)
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            modalState: {
                                ...prevState.modalState,
                                tabKey: AdminPermissionsModalTabTypeEnum.ClubsSelectTab
                            },
                            clubsTabState: {
                                ...prevState.clubsTabState,
                                selectedSupervisors: [...prevState.clubsTabState.selectedSupervisors, userId]
                            }
                        })))
                    }
                    if (!checked) {
                        await AdminPermissionsService.removeAssignedClubByPermissionIdAndUserId(userId, permissionId)
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            modalState: {
                                ...prevState.modalState,
                                tabKey: AdminPermissionsModalTabTypeEnum.AreasSelectTab
                            }
                        })))
                    }
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalState: {
                        ...prevState.modalState,
                        loading: false,
                        processingTabCheckboxIds: []
                    }
                })))
            }
        }
    }
    public static clearAll() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...prevState.modalState,
                    loading: true
                }
            })))
            try {
                const permissionId = getState().admin.adminPermissions.modalState.permissionId
                const userId = getState().admin.adminPermissions.modalState.userId
                const key = getState().admin.adminPermissions.modalState.tabKey
                const isAreasTab = key === AdminPermissionsModalTabTypeEnum.AreasSelectTab

                if (isAreasTab) {

                    await AdminPermissionsService.disallowAllAreas(userId, permissionId)

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        areasTabState: {
                            ...prevState.areasTabState,
                            selectedAreas: []
                        }
                    })))
                } else {

                    await AdminPermissionsService.removeAssignedClubByPermissionIdAndUserId(userId, permissionId)

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        clubsTabState: {
                            ...prevState.clubsTabState,
                            selectedSupervisors: []
                        }
                    })))
                }
            }
            catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalState: {
                        ...prevState.modalState,
                        loading: false
                    }
                })))
            }
        }
    }

    public static setModalData(admin: AdminPagePermissionsModel, permissionId: number) {
        return async (dispatch, getState: () => AppState) => {
            const user = admin

            let supervisorsList;
            let areasList;
            let isAllAreasSelected

            switch (permissionId) {
                case 3:
                    supervisorsList = user.UsersActivity.allowedAssignedClubs || [];
                    areasList = user.UsersActivity.allowedAreas || []
                    isAllAreasSelected = !!user.UsersActivity.isPermitted
                    break;
                case 29:
                    supervisorsList = user.AllPitchesReport.allowedAssignedClubs || [];
                    areasList = user.AllPitchesReport.allowedAreas || []
                    isAllAreasSelected = !!user.AllPitchesReport.isPermitted
                    break;
                case 30:
                    supervisorsList = user.DeclareInterestReport.allowedAssignedClubs || [];
                    areasList = user.DeclareInterestReport.allowedAreas || []
                    isAllAreasSelected = !!user.DeclareInterestReport.isPermitted
                    break;
                case 72:
                    supervisorsList = user.UpgradeBanner.allowedAssignedClubs || [];
                    areasList = user.UpgradeBanner.allowedAreas || []
                    isAllAreasSelected = !!user.UpgradeBanner.isPermitted
                    break;
                case 43:
                    supervisorsList = user.SuccessStories.allowedAssignedClubs || [];
                    areasList = user.SuccessStories.allowedAreas || []
                    isAllAreasSelected = !!user.SuccessStories.isPermitted
                    break;
                case 19:
                    supervisorsList = user.AllUsers.allowedAssignedClubs || [];
                    areasList = user.AllUsers.allowedAreas || []
                    isAllAreasSelected = !!user.AllUsers.isPermitted
                    break;
                case 52:
                    supervisorsList = user.Impersonate.allowedAssignedClubs || [];
                    areasList = user.Impersonate.allowedAreas || []
                    isAllAreasSelected = !!user.Impersonate.isPermitted
                    break;
                case 79:
                    supervisorsList = user.AgencyImpersonate.allowedAssignedClubs || [];
                    areasList = user.AgencyImpersonate.allowedAreas || []
                    isAllAreasSelected = !!user.AgencyImpersonate.isPermitted
                    break;
                default:
                    break;
            }

            const supervisors = Selectors.getSupervisors(getState());
            const areas = Selectors.getAreas(getState());

            await dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...prevState.modalState,
                    permissionId: permissionId,
                    userId: user.userId,
                    windowTitle: user.userName,
                    isAllAreasSelected: isAllAreasSelected
                },
                clubsTabState: {
                    ...prevState.clubsTabState,
                    filteredSupervisorsList: supervisors,
                    selectedSupervisors: supervisorsList,
                },
                areasTabState: {
                    ...prevState.areasTabState,
                    filteredAreasList: areas,
                    selectedAreas: areasList,
                },
            })))

            const initTabKey = Selectors.getInitialTab(getState())

            await dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalState: {
                    ...prevState.modalState,
                    tabKey: initTabKey,
                }
            })))

            dispatch(Actions.openModal())
        }
    }

    public static setHistoryDepth(userId: number, pageId: number, depth: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.adminPermissions;
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingHistoryDepthIds: [
                        ...prevState.processingHistoryDepthIds,
                        userId
                    ]
                })))
                const result = await AdminPermissionsService.setHistoryDepth(userId, pageId, depth);
                if (result) {
                    const updatedItems = [...substate.items];
                    updatedItems.find(item => item.userId === userId).allowedHistoryDepths[35] = Number(depth);
                    updatedItems.find(item => item.userId === userId).allowedUserActivityHistoryMonths = Number(depth);
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: updatedItems
                    })));
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingHistoryDepthIds: prevState.processingHistoryDepthIds.filter(item => item !== userId)
                })));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.admin.adminPermissions
    public static getSupervisors = (state: AppState) => Selectors.getRoot(state).modalState.supervisors
    public static getAreas = (state: AppState) => Selectors.getRoot(state).modalState.areas
    public static getInitialTab = (state: AppState) => {
        
        const isAllAreasSelected = Selectors.getRoot(state).modalState.isAllAreasSelected
        const isSupervisorsSelected = Selectors.getRoot(state).clubsTabState.selectedSupervisors.length > 0

        if (isAllAreasSelected) {
            return AdminPermissionsModalTabTypeEnum.AllAreasSelectedTab
        }

        if (isSupervisorsSelected) {
            return AdminPermissionsModalTabTypeEnum.ClubsSelectTab
        } else return AdminPermissionsModalTabTypeEnum.AreasSelectTab
    }
    public static getTabKey = (state: AppState) => Selectors.getRoot(state).modalState.tabKey
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminPermissions as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}