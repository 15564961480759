export const eventClubs = [
  {
    squadId: 1,
    squadName: "AC Milan",
    shortSquadName: "AC Milan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1834_AC_Milan.png"
  },
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 3,
    squadName: "Juventus FC",
    shortSquadName: "Juventus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1832_Juventus_FC.png"
  },
  {
    squadId: 4,
    squadName: "Parma Calcio 1913",
    shortSquadName: "Parma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png"
  },
  {
    squadId: 7,
    squadName: "Udinese Calcio",
    shortSquadName: "Udinese Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1844_Udinese_Calcio.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 47,
    squadName: "Tottenham Hotspur",
    shortSquadName: "Tottenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1122_Tottenham_Hotspur.png"
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 66,
    squadName: "Leicester City",
    shortSquadName: "Leicester",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leicester_City.png"
  },
  {
    squadId: 67,
    squadName: "West Ham United",
    shortSquadName: "West Ham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1633_West_Ham_United.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 174,
    squadName: "CA Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 255,
    squadName: "Zaglebie Lubin",
    shortSquadName: "Zaglebie Lubin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KGHM_Zaglebie_Lubin.png"
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
  },
  {
    squadId: 330,
    squadName: "SD Eibar",
    shortSquadName: "SD Eibar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1724_SD_Eibar.png"
  },
  {
    squadId: 416,
    squadName: "Al-Fateh SC",
    shortSquadName: "Al-Fateh",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16483_Al-Fateh_SC.png"
  },
  {
    squadId: 496,
    squadName: "HNK Rijeka",
    shortSquadName: "HNK Rijeka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png"
  },
  {
    squadId: 513,
    squadName: "Rodez AF",
    shortSquadName: "Rodez AF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3867_Rodez_AF.png"
  },
  {
    squadId: 542,
    squadName: "FK Atyrau",
    shortSquadName: "Atyrau",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_542_FK_Atyrau.png"
  },
  {
    squadId: 606,
    squadName: "Quevilly - Rouen Metropole",
    shortSquadName: "QRM",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3865_Quevilly_-_Rouen_Métropole.png"
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "O'Higgins",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
  },
  {
    squadId: 976,
    squadName: "AS Monaco",
    shortSquadName: "Monaco",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/19830_AS_Monaco.png"
  },
  {
    squadId: 1003,
    squadName: "Hull City",
    shortSquadName: "Hull City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1672_Hull_City.png"
  },
  {
    squadId: 1065,
    squadName: "SC Braga",
    shortSquadName: "SC Braga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sporting_Braga.png"
  },
  {
    squadId: 1142,
    squadName: "AZ Alkmaar",
    shortSquadName: "AZ Alkmaar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10_AZ_Alkmaar.png"
  },
  {
    squadId: 1224,
    squadName: "Botafogo FC",
    shortSquadName: "NULL",
    squadLogo: null
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
  },
  {
    squadId: 1423,
    squadName: "LD Alajuelense",
    shortSquadName: "LD Alajuelense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16835_LD_Alajuelense.png"
  },
  {
    squadId: 1494,
    squadName: "NEC Nijmegen",
    shortSquadName: "NEC Nijmegen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/22_NEC_Nijmegen.png"
  },
  {
    squadId: 1535,
    squadName: "FC Zurich",
    shortSquadName: "FC Zurich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6412_FC_Zürich.png"
  },
  {
    squadId: 1559,
    squadName: "FC Cincinnati",
    shortSquadName: "Cincinnati",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 1771,
    squadName: "Bryne FK",
    shortSquadName: "Bryne",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7107_Bryne_FK.png"
  },
  {
    squadId: 1792,
    squadName: "Monagas SC",
    shortSquadName: "Monagas SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1792_Monagas_SC.png"
  },
  {
    squadId: 1799,
    squadName: "Angostura FC",
    shortSquadName: "Angostura FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1799_Angostura_FC.png"
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "Viktoria Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
  },
  {
    squadId: 2031,
    squadName: "1.FC Union Berlin",
    shortSquadName: "Union Berlin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1277_1.FC_Union_Berlin.png"
  },
  {
    squadId: 2086,
    squadName: "FC Erzgebirge Aue",
    shortSquadName: "Erzgebirge Aue",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Erzgebirge_Aue.png"
  },
  {
    squadId: 2226,
    squadName: "Sevilla FC",
    shortSquadName: "Sevilla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/680_Sevilla_FC.png"
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 2994,
    squadName: "1.FC Saarbrucken",
    shortSquadName: "Saarbrucken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2476_1.FC_Saarbrücken.png"
  },
  {
    squadId: 3028,
    squadName: "Palermo FC",
    shortSquadName: "Palermo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3171_Palermo_FC.png"
  },
  {
    squadId: 3476,
    squadName: "Sporting CP",
    shortSquadName: "Sporting CP",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9608_Sporting_CP.png"
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11242_SK_Slavia_Prague.png"
  },
  {
    squadId: 3725,
    squadName: "KV Oostende",
    shortSquadName: "KV Oostende",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5080_KV_Oostende.png"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 3795,
    squadName: "Shonan Bellmare",
    shortSquadName: "Shonan Bellmare",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2422_Shonan_Bellmare.png"
  },
  {
    squadId: 3928,
    squadName: "Pakhtakor Tashkent",
    shortSquadName: "Pakhtakor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3928_Pakhtakor_Tashkent.png"
  },
  {
    squadId: 4059,
    squadName: "OGC Nice",
    shortSquadName: "OGC Nice",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1965_OGC_Nice.png"
  },
  {
    squadId: 4178,
    squadName: "Servette FC",
    shortSquadName: "Servette FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2355_Servette_FC.png"
  },
  {
    squadId: 4186,
    squadName: "Ermis Aradippou",
    shortSquadName: "Ermis Aradippou",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10711_Ermis_Aradippou.png"
  },
  {
    squadId: 4230,
    squadName: "Stade de Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 5109,
    squadName: "PEC Zwolle",
    shortSquadName: "PEC Zwolle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1543_PEC_Zwolle.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
  },
  {
    squadId: 5359,
    squadName: "Caracas FC",
    shortSquadName: "Caracas FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
  },
  {
    squadId: 5592,
    squadName: "SD Aucas",
    shortSquadName: "SD Aucas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_5592_SD_Aucas.png"
  },
  {
    squadId: 5796,
    squadName: "FC Academy Pandev",
    shortSquadName: "NULL",
    squadLogo: null
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
  },
  {
    squadId: 5924,
    squadName: "Everton de Vina del Mar",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 5968,
    squadName: "AFC Wimbledon",
    shortSquadName: "AFC Wimbledon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1178_AFC_Wimbledon.png"
  },
  {
    squadId: 6040,
    squadName: "FC Universitatea Cluj",
    shortSquadName: "U Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_6040_FC_Universitatea_Cluj.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/690_Real_Valladolid_CF.png"
  },
  {
    squadId: 6059,
    squadName: "CD Leganes",
    shortSquadName: "CD Leganes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/712_CD_Leganés.png"
  },
  {
    squadId: 6098,
    squadName: "Kalmar FF",
    shortSquadName: "Kalmar FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kalmar_FF.png"
  },
  {
    squadId: 6207,
    squadName: "Molde FK",
    shortSquadName: "Molde FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7098_Molde_FK.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "B. Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 7354,
    squadName: "Forest Green Rovers",
    shortSquadName: "Forest Green",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Forest_Green_Rovers.png"
  },
  {
    squadId: 7368,
    squadName: "Nueva Esparta F.C",
    shortSquadName: "N. Esparta F.C",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/58948_Nueva_Esparta_F.C.png"
  },
  {
    squadId: 7374,
    squadName: "Lommel SK",
    shortSquadName: "Lommel SK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5122_Lommel_SK.png"
  },
  {
    squadId: 7390,
    squadName: "Club Atletico Lanus",
    shortSquadName: "Lanus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12063_Club_Atlético_Lanús.png"
  },
  {
    squadId: 7527,
    squadName: "FC Vaduz",
    shortSquadName: "FC Vaduz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2354_FC_Vaduz.png"
  },
  {
    squadId: 7633,
    squadName: "Real Salt Lake City",
    shortSquadName: "Salt Lake",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2117_Real_Salt_Lake_City.png"
  },
  {
    squadId: 7783,
    squadName: "Pyramids FC",
    shortSquadName: "Pyramids FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/27533_Pyramids_Al_Ahram_FC.png"
  },
  {
    squadId: 7794,
    squadName: "MTK Budapest",
    shortSquadName: "MTK Budapest",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7794_MTK_Budapest.png"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 7845,
    squadName: "CF Pachuca",
    shortSquadName: "Pachuca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
  },
  {
    squadId: 8064,
    squadName: "Kawasaki Frontale",
    shortSquadName: "Kawasaki Front.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2410_Kawasaki_Frontale.png"
  },
  {
    squadId: 8076,
    squadName: "CD Universidad Catolica",
    shortSquadName: "U. Catolica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12295_CD_Universidad_Católica.png"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 8228,
    squadName: "AC Horsens",
    shortSquadName: "AC Horsens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7465_AC_Horsens.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 8271,
    squadName: "AA Estudiantes (Rio Cuarto)",
    shortSquadName: "Estudiantes RC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_8271_AA_Estudiantes_(Rio_Cuarto).png"
  },
  {
    squadId: 8837,
    squadName: "FC Sheriff Tiraspol",
    shortSquadName: "FC Sheriff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11056_FC_Sheriff_Tiraspol.png"
  },
  {
    squadId: 9029,
    squadName: "FC Ingolstadt 04",
    shortSquadName: "FC Ingolstadt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2579_FC_Ingolstadt_04.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.svg"
  },
  {
    squadId: 9276,
    squadName: "CF Monterrey",
    shortSquadName: "Monterrey",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2267_CF_Monterrey.png"
  },
  {
    squadId: 9385,
    squadName: "Sporting Kansas City",
    shortSquadName: "Kansas City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7849_Sporting_Kansas_City.png"
  },
  {
    squadId: 9451,
    squadName: "Hamilton Academical FC",
    shortSquadName: "Hamilton Acad.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hamilton_Academical.png"
  },
  {
    squadId: 9930,
    squadName: "Esbjerg fB",
    shortSquadName: "Esbjerg fB",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Esbjerg_FB.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 10026,
    squadName: "Los Angeles Galaxy",
    shortSquadName: "Los Angeles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
  },
  {
    squadId: 10158,
    squadName: "Toulouse FC",
    shortSquadName: "Toulouse FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png"
  },
  {
    squadId: 10223,
    squadName: "CD Castellon",
    shortSquadName: "Castellon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3039_CD_Castellon.png"
  },
  {
    squadId: 10282,
    squadName: "Neftchi Baku",
    shortSquadName: "Neftchi Baku",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3504_Neftchi_Baku.png"
  },
  {
    squadId: 10317,
    squadName: "New York Red Bulls",
    shortSquadName: "New York",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
  },
  {
    squadId: 10371,
    squadName: "Audax Italiano",
    shortSquadName: "Audax Italiano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12292_Audax_Italiano.png"
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
  },
  {
    squadId: 10585,
    squadName: "Breidablik Kopavogur",
    shortSquadName: "Breidablik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1513_Breidablik_Kopavogur.png"
  },
  {
    squadId: 10774,
    squadName: "IFK Varnamo",
    shortSquadName: "Varnamo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6769_IFK_Värnamo.png"
  },
  {
    squadId: 11087,
    squadName: "SK Brann",
    shortSquadName: "Brann",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7095_SK_Brann.png"
  },
  {
    squadId: 11256,
    squadName: "Club Guarani",
    shortSquadName: "Guarani",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_11256_Club_Guarani.png"
  },
  {
    squadId: 11544,
    squadName: "Grasshopper Club Zurich",
    shortSquadName: "Grasshoppers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1936_Grasshopper_Club_Zurich.png"
  },
  {
    squadId: 11585,
    squadName: "Tromso IL",
    shortSquadName: "Tromso",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
  },
  {
    squadId: 11717,
    squadName: "Fehervar FC",
    shortSquadName: "Fehervar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10260_Fehérvár_FC.png"
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "Jerv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
  },
  {
    squadId: 12023,
    squadName: "Wadi Degla SC",
    shortSquadName: "NULL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_12023_Wadi_Degla_SC.png"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 12098,
    squadName: "CD Santa Clara",
    shortSquadName: "Santa Clara",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9634_CD_Santa_Clara.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 12359,
    squadName: "Fortuna Sittard",
    shortSquadName: "Fortuna Sittard",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/44_Fortuna_Sittard.png"
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_VfL_Wolfsburg.png"
  },
  {
    squadId: 12598,
    squadName: "VfB Stuttgart",
    shortSquadName: "VfB Stuttgart",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1273_VfB_Stuttgart.png"
  },
  {
    squadId: 12612,
    squadName: "Hammarby IF",
    shortSquadName: "Hammarby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6710_Hammarby_IF.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 12761,
    squadName: "RCD Espanyol Barcelona",
    shortSquadName: "Espanyol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1722_RCD_Espanyol_Barcelona.png"
  },
  {
    squadId: 13008,
    squadName: "Paris FC",
    shortSquadName: "Paris FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3846_Paris_FC.png"
  },
  {
    squadId: 13020,
    squadName: "IL Hodd",
    shortSquadName: "Hodd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7112_IL_Hödd.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 13099,
    squadName: "CF Montreal",
    shortSquadName: "Montreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14969_CF_Montréal.png"
  },
  {
    squadId: 13109,
    squadName: "Sint-Truidense VV",
    shortSquadName: "Sint-Truiden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1582_Sint-Truidense_VV.png"
  },
  {
    squadId: 13297,
    squadName: "GD Estoril Praia",
    shortSquadName: "Estoril Praia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1680_GD_Estoril_Praia.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
  },
  {
    squadId: 13576,
    squadName: "Sonderjyske Fodbold",
    shortSquadName: "Sonderjyske",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7499_Sønderjyske_Fodbold.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 13693,
    squadName: "Metropolitanos FC",
    shortSquadName: "Metropolitanos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/23073_Metropolitanos_FC.jpg"
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9918_FK_Sarajevo.png"
  },
  {
    squadId: 14337,
    squadName: "Nea Salamina Famagusta",
    shortSquadName: "Nea Salamis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10706_Nea_Salamis.png"
  },
  {
    squadId: 14649,
    squadName: "SK Beveren",
    shortSquadName: "SK Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5104_SK_Beveren.png"
  },
  {
    squadId: 14737,
    squadName: "SV 07 Elversberg",
    shortSquadName: "SV Elversberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1371_SV_07_Elversberg.png"
  },
  {
    squadId: 14930,
    squadName: "Celaya FC",
    shortSquadName: "Celaya",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png"
  },
  {
    squadId: 14994,
    squadName: "CD Leones Negros de la UdeG",
    shortSquadName: "Leones Negros",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
  },
  {
    squadId: 15024,
    squadName: "Yverdon Sport FC",
    shortSquadName: "Yverdon Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6422_Yverdon_Sport_FC.png"
  },
  {
    squadId: 15130,
    squadName: "AIK Solna",
    shortSquadName: "AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png"
  },
  {
    squadId: 15241,
    squadName: "Magallanes CF",
    shortSquadName: "Magallanes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
  },
  {
    squadId: 15268,
    squadName: "Widzew Lodz",
    shortSquadName: "Widzew Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
  },
  {
    squadId: 15326,
    squadName: "Albion FC",
    shortSquadName: "Albion FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15326_Albion_FC.png"
  },
  {
    squadId: 15469,
    squadName: "Club Nacional",
    shortSquadName: "Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15634_Club_Nacional.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5121_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 15549,
    squadName: "FC Basel 1893",
    shortSquadName: "FC Basel",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1932_FC_Basel_1893.png"
  },
  {
    squadId: 15581,
    squadName: "Lech Poznan",
    shortSquadName: "Lech Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1595_Lech_Poznan.png"
  },
  {
    squadId: 15741,
    squadName: "US Triestina",
    shortSquadName: "Triestina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1905_US_Triestina.png"
  },
  {
    squadId: 15817,
    squadName: "FC Dinamo Bucharest",
    shortSquadName: "FC Dinamo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2342_Dinamo_Bukarest.png"
  },
  {
    squadId: 15821,
    squadName: "Arminia Bielefeld",
    shortSquadName: "Arm. Bielefeld",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 15862,
    squadName: "Universitario de Deportes",
    shortSquadName: "Universitario",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15862_Universitario_de_Deportes.png"
  },
  {
    squadId: 16046,
    squadName: "Olympique Lyonnais",
    shortSquadName: "Olympique Lyon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3766_Olympique_Lyon.png"
  },
  {
    squadId: 16173,
    squadName: "SCR Altach",
    shortSquadName: "SCR Altach",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1946_SC_Rheindorf_Altach.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Athletico-PR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 16335,
    squadName: "Beerschot V.A.",
    shortSquadName: "Beerschot V.A.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
  },
  {
    squadId: 16345,
    squadName: "Mjondalen IF",
    shortSquadName: "Mjondalen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1486_Mjondalen_IF.png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 16675,
    squadName: "Odds BK",
    shortSquadName: "Odd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7090_Odds_BK.png"
  },
  {
    squadId: 16775,
    squadName: "Reading FC",
    shortSquadName: "Reading",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1147_Reading_FC.png"
  },
  {
    squadId: 16832,
    squadName: "Boldklubben af 1893",
    shortSquadName: "B.93",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_16832_Boldklubben_af_1893.png"
  },
  {
    squadId: 17043,
    squadName: "FC Tokyo",
    shortSquadName: "FC Tokyo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2416_FC_Tokyo.png"
  },
  {
    squadId: 17331,
    squadName: "TOP Oss",
    shortSquadName: "TOP Oss",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1565_TOP_Oss.png"
  },
  {
    squadId: 17371,
    squadName: "DAC Dunajska Streda",
    shortSquadName: "Dunajska Streda",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14390_DAC_Dunajska_Streda.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 17418,
    squadName: "Valerenga Fotball Elite",
    shortSquadName: "Valerenga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png"
  },
  {
    squadId: 17454,
    squadName: "Avai FC",
    shortSquadName: "Avai FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
  },
  {
    squadId: 17668,
    squadName: "FC Famalicao",
    shortSquadName: "Famalicao",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Famalicao.png"
  },
  {
    squadId: 17761,
    squadName: "Kashima Antlers",
    shortSquadName: "Kashima Antlers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2411_Kashima_Antlers.png"
  },
  {
    squadId: 17788,
    squadName: "Paris Saint-Germain",
    shortSquadName: "Paris SG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3767_Paris_Saint-Germain.png"
  },
  {
    squadId: 17795,
    squadName: "Eintracht Braunschweig",
    shortSquadName: "E. Braunschweig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Eintracht_Braunschweig.png"
  },
  {
    squadId: 17923,
    squadName: "FC Eindhoven",
    shortSquadName: "FC Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/42_FC_Eindhoven.png"
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
  },
  {
    squadId: 18179,
    squadName: "SC Verl",
    shortSquadName: "SC Verl",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
  },
  {
    squadId: 18499,
    squadName: "CSKA 1948",
    shortSquadName: "CSKA 1948",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/60354_FK_CSKA_1948_Sofia.png"
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos Athens",
    shortSquadName: "Panathinaikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 18833,
    squadName: "SL Benfica",
    shortSquadName: "Benfica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1673_SL_Benfica.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 18960,
    squadName: "FC Porto",
    shortSquadName: "FC Porto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1672_FC_Porto.png"
  },
  {
    squadId: 19151,
    squadName: "Clermont Foot 63",
    shortSquadName: "Clermont Foot",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3797_Clermont_Foot_63.png"
  },
  {
    squadId: 19206,
    squadName: "Santos FC",
    shortSquadName: "Santos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
  },
  {
    squadId: 19251,
    squadName: "SV Waldhof Mannheim",
    shortSquadName: "W. Mannheim",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1370_SV_Waldhof_Mannheim.png"
  },
  {
    squadId: 19385,
    squadName: "Cerezo Osaka",
    shortSquadName: "Cerezo Osaka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2408_Cerezo_Osaka.png"
  },
  {
    squadId: 19616,
    squadName: "Aalesunds FK",
    shortSquadName: "Aalesund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png"
  },
  {
    squadId: 19631,
    squadName: "FK Vojvodina Novi Sad",
    shortSquadName: "Vojvodina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1660_FK_Vojvodina_Novi_Sad.png"
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 20127,
    squadName: "FCV Farul Constanta",
    shortSquadName: "FCV Farul",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11797_FCV_Farul_Constanta.png"
  },
  {
    squadId: 20133,
    squadName: "Chicago Fire FC",
    shortSquadName: "Chicago",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7848_Chicago_Fire_FC.png"
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
  },
  {
    squadId: 20302,
    squadName: "Hallescher FC",
    shortSquadName: "Hallescher FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hallescher_FC.png"
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 20830,
    squadName: "SV Sandhausen",
    shortSquadName: "SV Sandhausen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Sandhausen.png"
  },
  {
    squadId: 20887,
    squadName: "Millwall FC",
    shortSquadName: "Millwall",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1160_Millwall_FC.png"
  },
  {
    squadId: 20897,
    squadName: "Hannover 96",
    shortSquadName: "Hannover 96",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hannover_96.png"
  },
  {
    squadId: 20983,
    squadName: "Club Atletico Belgrano",
    shortSquadName: "Belgrano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2591_Club_Atletico_Belgrano.png"
  },
  {
    squadId: 21299,
    squadName: "Estudiantes de Merida",
    shortSquadName: "Estudiantes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_21299_Estudiantes_de_Merida.png"
  },
  {
    squadId: 21425,
    squadName: "FC St. Gallen 1879",
    shortSquadName: "FC St. Gallen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
  },
  {
    squadId: 21491,
    squadName: "FBC Melgar",
    shortSquadName: "FBC Melgar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15534_FBC_Melgar.png"
  },
  {
    squadId: 21706,
    squadName: "FC Hansa Rostock",
    shortSquadName: "Hansa Rostock",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hansa_Rostock.png"
  },
  {
    squadId: 22011,
    squadName: "Puebla FC",
    shortSquadName: "Puebla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15414_Puebla_FC.png"
  },
  {
    squadId: 22191,
    squadName: "Gamba Osaka",
    shortSquadName: "Gamba Osaka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2413_Gamba_Osaka.png"
  },
  {
    squadId: 22196,
    squadName: "SpVgg Greuther Furth",
    shortSquadName: "Greuther Furth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Salonika",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 22352,
    squadName: "Cruzeiro Esporte Clube",
    shortSquadName: "Cruzeiro",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5640_Cruzeiro_Esporte_Clube.png"
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13270_Maccabi_Tel_Aviv.png"
  },
  {
    squadId: 22419,
    squadName: "FUS Rabat",
    shortSquadName: "FUS Rabat",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_22419_FUS_Rabat.png"
  },
  {
    squadId: 22719,
    squadName: "Clube de Regatas Vasco da Gama",
    shortSquadName: "Vasco da Gama",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
  },
  {
    squadId: 22720,
    squadName: "Korona Kielce",
    shortSquadName: "Korona Kielce",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1605_Korona_Kielce.png"
  },
  {
    squadId: 22740,
    squadName: "Birmingham City",
    shortSquadName: "Birmingham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Birmingham_City.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 22866,
    squadName: "KMSK Deinze",
    shortSquadName: "KMSK Deinze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2368_KMSK_Deinze.png"
  },
  {
    squadId: 22922,
    squadName: "FK Zeleziarne Podbrezova",
    shortSquadName: "Podbrezova",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14379_FK_Zeleziarne_Podbrezova.png"
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
  },
  {
    squadId: 23117,
    squadName: "FC Versailles 78",
    shortSquadName: "FC Versailles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4213_FC_Versailles_78.png"
  },
  {
    squadId: 23168,
    squadName: "1.FC Koln",
    shortSquadName: "1.FC Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
  },
  {
    squadId: 23352,
    squadName: "Stade Rennais FC",
    shortSquadName: "Stade Rennais",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Stade_Rennes.png"
  },
  {
    squadId: 23506,
    squadName: "Atlas Guadalajara",
    shortSquadName: "Atlas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15407_Atlas.png"
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
  },
  {
    squadId: 23647,
    squadName: "Atletico de San Luis",
    shortSquadName: "San Luis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
  },
  {
    squadId: 23767,
    squadName: "Crawley Town",
    shortSquadName: "Crawley Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1198_Crawley_Town.png"
  },
  {
    squadId: 23775,
    squadName: "VfB Lubeck",
    shortSquadName: "VfB Lubeck",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2499_VfB_Lübeck.png"
  },
  {
    squadId: 23819,
    squadName: "FC Dordrecht",
    shortSquadName: "FC Dordrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1568_FC_Dordrecht.png"
  },
  {
    squadId: 23873,
    squadName: "Botev Plovdiv",
    shortSquadName: "Botev Plovdiv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
  },
  {
    squadId: 24099,
    squadName: "Valenciennes FC",
    shortSquadName: "Valenciennes FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3814_Valenciennes_FC.png"
  },
  {
    squadId: 24408,
    squadName: "Club Alianza Lima",
    shortSquadName: "Alianza Lima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7239_Club_Alianza_Lima.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
  },
  {
    squadId: 24701,
    squadName: "CF Atlante",
    shortSquadName: "Atlante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
  },
  {
    squadId: 24755,
    squadName: "Milton Keynes Dons",
    shortSquadName: "MK Dons",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1164_Milton_Keynes_Dons.png"
  },
  {
    squadId: 24812,
    squadName: "Bolivar La Paz",
    shortSquadName: "Bolivar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10474_Bolívar_La_Paz.png"
  },
  {
    squadId: 25037,
    squadName: "Montevideo City Torque",
    shortSquadName: "Mvd City Torque",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/23497_Montevideo_City_Torque.png"
  },
  {
    squadId: 25039,
    squadName: "LDU Quito",
    shortSquadName: "LDU Quito",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15764_LDU_Quito.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 25123,
    squadName: "AeZ Zakakiou",
    shortSquadName: "AeZ Zakakiou",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10720_AEZ_Zakakiou.png"
  },
  {
    squadId: 25436,
    squadName: "Nashville SC",
    shortSquadName: "Nashville",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 25629,
    squadName: "RSC Anderlecht",
    shortSquadName: "RSC Anderlecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_RSC_Anderlecht.png"
  },
  {
    squadId: 25911,
    squadName: "Royal Antwerp FC",
    shortSquadName: "Royal Antwerp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
  },
  {
    squadId: 26054,
    squadName: "CFR Cluj",
    shortSquadName: "CFR Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2341_CFR_Cluj.png"
  },
  {
    squadId: 26056,
    squadName: "AeK Athens",
    shortSquadName: "AeK Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
  },
  {
    squadId: 26232,
    squadName: "FC Schalke 04",
    shortSquadName: "FC Schalke 04",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Schalke_04.png"
  },
  {
    squadId: 26329,
    squadName: "Trabzonspor",
    shortSquadName: "Trabzonspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2006_Trabzonspor.png"
  },
  {
    squadId: 26333,
    squadName: "SV Ried",
    shortSquadName: "SV Ried",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Ried.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 26367,
    squadName: "Santos Laguna",
    shortSquadName: "Santos Laguna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
  },
  {
    squadId: 26540,
    squadName: "Orlando City SC",
    shortSquadName: "Orlando",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
  },
  {
    squadId: 26635,
    squadName: "Swift Hesperange",
    shortSquadName: "Hesperange",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_26635_Swift_Hesperange.png"
  },
  {
    squadId: 26667,
    squadName: "Coritiba Foot Ball Club",
    shortSquadName: "Coritiba FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
  },
  {
    squadId: 26699,
    squadName: "Galatasaray",
    shortSquadName: "Galatasaray",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2003_Galatasaray_SK.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 27180,
    squadName: "NS Mura",
    shortSquadName: "NS Mura",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31278_NŠ_Mura.png"
  },
  {
    squadId: 27230,
    squadName: "CD America de Cali",
    shortSquadName: "CD America",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2664_CD_America_de_Cali.png"
  },
  {
    squadId: 27251,
    squadName: "CR Flamengo",
    shortSquadName: "Flamengo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
  },
  {
    squadId: 27570,
    squadName: "Puskas Akademia FC",
    shortSquadName: "Puskas AFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1630_Puskas_Akademia_FC.png"
  },
  {
    squadId: 27650,
    squadName: "FC Juarez",
    shortSquadName: "FC Juarez",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34402_FC_Juárez.png"
  },
  {
    squadId: 27833,
    squadName: "America Futebol Clube (MG)",
    shortSquadName: "America-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5673_América_Futebol_Clube_(MG).png"
  },
  {
    squadId: 28108,
    squadName: "FC Wil 1900",
    shortSquadName: "FC Wil 1900",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2361_FC_Wil_1900.png"
  },
  {
    squadId: 28130,
    squadName: "IF Brommapojkarna",
    shortSquadName: "Brommapojkarna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_IF_Brommapojkarna.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "R. Strasbourg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
  },
  {
    squadId: 28242,
    squadName: "Kryvbas Kryvyi Rig",
    shortSquadName: "Kryvbas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_28242_Kryvbas_Kryvyi_Rig.png"
  },
  {
    squadId: 28372,
    squadName: "Club Atletico Morelia",
    shortSquadName: "Morelia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
  },
  {
    squadId: 28535,
    squadName: "Naestved Boldklub",
    shortSquadName: "Naestved BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
  },
  {
    squadId: 28879,
    squadName: "Athletic Bilbao",
    shortSquadName: "Athletic",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/678_Athletic_Bilbao.png"
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 29155,
    squadName: "1.FC Kaiserslautern",
    shortSquadName: "1.FC K'lautern",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1286_1.FC_Kaiserslautern.png"
  },
  {
    squadId: 29198,
    squadName: "FC Den Bosch",
    shortSquadName: "FC Den Bosch",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Den_Bosch.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 29306,
    squadName: "Mineros de Zacatecas",
    shortSquadName: "Min. Zacatecas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png"
  },
  {
    squadId: 29455,
    squadName: "Nottingham Forest",
    shortSquadName: "Nottm Forest",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1152_Nottingham_Forest.png"
  },
  {
    squadId: 29469,
    squadName: "GNK Dinamo Zagreb",
    shortSquadName: "Dinamo Zagreb",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2163_GNK_Dinamo_Zagreb.png"
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
  },
  {
    squadId: 29548,
    squadName: "Ae Kifisias",
    shortSquadName: "Ae Kifisias",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_29548_Ae_Kifisias.png"
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 29646,
    squadName: "NK Domzale",
    shortSquadName: "NK Domzale",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2463_NK_Domzale.png"
  },
  {
    squadId: 29747,
    squadName: "Ajax Amsterdam",
    shortSquadName: "Ajax",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9_Ajax_Amsterdam.png"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 29777,
    squadName: "LR Vicenza",
    shortSquadName: "LR Vicenza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png"
  },
  {
    squadId: 29796,
    squadName: "Alebrijes de Oaxaca",
    shortSquadName: "Oaxaca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 30168,
    squadName: "Deportivo Saprissa",
    shortSquadName: "Saprissa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30168_Deportivo_Saprissa.png"
  },
  {
    squadId: 30351,
    squadName: "AC Ajaccio",
    shortSquadName: "AC Ajaccio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3781_AC_Ajaccio.png"
  },
  {
    squadId: 30352,
    squadName: "FC Vizela",
    shortSquadName: "Vizela",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9683_FC_Vizela.png"
  },
  {
    squadId: 30372,
    squadName: "Envigado FC",
    shortSquadName: "Envigado",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
  },
  {
    squadId: 30411,
    squadName: "FK TSC Backa Topola",
    shortSquadName: "FK TSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30411_FK_TSC_Backa_Topola.png"
  },
  {
    squadId: 30440,
    squadName: "CA Independiente",
    shortSquadName: "Independiente",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30440_CA_Independiente.png"
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "Sparta Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
  },
  {
    squadId: 30723,
    squadName: "Borussia Dortmund",
    shortSquadName: "Bor. Dortmund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1259_Borussia_Dortmund.png"
  },
  {
    squadId: 30734,
    squadName: "Altinordu FK",
    shortSquadName: "Altinordu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
  },
  {
    squadId: 30736,
    squadName: "FC Sochaux-Montbeliard",
    shortSquadName: "FC Sochaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3768_FC_Sochaux-Montbéliard.png"
  },
  {
    squadId: 30849,
    squadName: "Atlanta United FC",
    shortSquadName: "Atlanta",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
  },
  {
    squadId: 30997,
    squadName: "Red Bull Salzburg",
    shortSquadName: "RB Salzburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1942_Red_Bull_Salzburg.png"
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Leon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "Dynamo Dresden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2478_SG_Dynamo_Dresden.png"
  },
  {
    squadId: 31306,
    squadName: "Kolding IF",
    shortSquadName: "Kolding IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7622_Kolding_IF.png"
  },
  {
    squadId: 31328,
    squadName: "Sabah FK",
    shortSquadName: "Sabah FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png"
  },
  {
    squadId: 31340,
    squadName: "Casa Pia AC",
    shortSquadName: "Casa Pia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9708_Casa_Pia_AC.png"
  },
  {
    squadId: 31366,
    squadName: "Silkeborg IF",
    shortSquadName: "Silkeborg IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1416_Silkeborg_IF.png"
  },
  {
    squadId: 31367,
    squadName: "Sheffield United",
    shortSquadName: "Sheff Utd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1159_Sheffield_United.png"
  },
  {
    squadId: 31484,
    squadName: "Deportivo Guadalajara",
    shortSquadName: "Chivas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15409_Deportivo_Guadalajara.png"
  },
  {
    squadId: 31536,
    squadName: "Aris Limassol",
    shortSquadName: "Aris Limassol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_31536_Aris_Limassol.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 32082,
    squadName: "SV Kapfenberg",
    shortSquadName: "SV Kapfenberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1961_SV_Kapfenberg.png"
  },
  {
    squadId: 32259,
    squadName: "Portuguesa FC",
    shortSquadName: "Portuguesa FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_32259_Portuguesa_FC.png"
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "E. Frankfurt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
  },
  {
    squadId: 32489,
    squadName: "Stockport County",
    shortSquadName: "Stockport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1667_Stockport_County.jpg"
  },
  {
    squadId: 32515,
    squadName: "IK Sirius",
    shortSquadName: "Sirius",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1446_IK_Sirius.png"
  },
  {
    squadId: 32586,
    squadName: "Omonia Nicosia",
    shortSquadName: "Omonia Nikosia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2175_Omonia_Nicosia.png"
  },
  {
    squadId: 32775,
    squadName: "FC Nantes",
    shortSquadName: "FC Nantes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3770_FC_Nantes.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid Bucharest",
    shortSquadName: "Rapid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
  },
  {
    squadId: 33048,
    squadName: "RSC Charleroi",
    shortSquadName: "RSC Charleroi",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1580_RSC_Charleroi.png"
  },
  {
    squadId: 33192,
    squadName: "Sligo Rovers",
    shortSquadName: "Sligo Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2097_Sligo_Rovers.png"
  },
  {
    squadId: 33217,
    squadName: "Real CD Espana",
    shortSquadName: "Real Espana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
  },
  {
    squadId: 33615,
    squadName: "Zalaegerszegi TE FC",
    shortSquadName: "Zalaegerszeg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_33615_Zalaegerszegi_TE_FC.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 33813,
    squadName: "Karlsruher SC",
    shortSquadName: "Karlsruher SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
  },
  {
    squadId: 33893,
    squadName: "FC Emmen",
    shortSquadName: "FC Emmen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Emmen.png"
  },
  {
    squadId: 33927,
    squadName: "UD Almeria",
    shortSquadName: "UD Almeria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/708_UD_Almería.png"
  },
  {
    squadId: 34038,
    squadName: "Club Cerro Porteno",
    shortSquadName: "Cerro Porteno",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_34038_Club_Cerro_Porteno.png"
  },
  {
    squadId: 34616,
    squadName: "Vejle Boldklub",
    shortSquadName: "Vejle BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1422_Vejle_Boldklub.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 34923,
    squadName: "Harrogate Town",
    shortSquadName: "Harrogate Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
  },
  {
    squadId: 35103,
    squadName: "Standard Liege",
    shortSquadName: "Standard Liege",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
  },
  {
    squadId: 35181,
    squadName: "SpVgg Unterhaching",
    shortSquadName: "Unterhaching",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2471_SpVgg_Unterhaching.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 35189,
    squadName: "Port Vale FC",
    shortSquadName: "Port Vale",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1192_Port_Vale_FC.png"
  },
  {
    squadId: 35303,
    squadName: "Stal Mielec",
    shortSquadName: "Stal Mielec",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1614_Stal_Mielec.png"
  },
  {
    squadId: 35822,
    squadName: "Bayern Munich",
    shortSquadName: "Bayern Munich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Bayern_Munich.png"
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Palmeiras",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
  },
  {
    squadId: 36226,
    squadName: "Go Ahead Eagles",
    shortSquadName: "Go Ahead Eagles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1553_Go_Ahead_Eagles_Deventer.png"
  },
  {
    squadId: 36401,
    squadName: "Slovan Bratislava",
    shortSquadName: "Slo. Bratislava",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2123_Slovan_Bratislava.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 36627,
    squadName: "FC Metz",
    shortSquadName: "FC Metz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3777_FC_Metz.png"
  },
  {
    squadId: 36736,
    squadName: "Olimpia Asuncion",
    shortSquadName: "Olimpia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15486_Olimpia.png"
  },
  {
    squadId: 36784,
    squadName: "Lechia Gdansk",
    shortSquadName: "Lechia Gdansk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1596_Lechia_Gdansk.png"
  },
  {
    squadId: 36837,
    squadName: "AJ Auxerre",
    shortSquadName: "AJ Auxerre",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3769_AJ_Auxerre.png"
  },
  {
    squadId: 37066,
    squadName: "SSV Ulm 1846",
    shortSquadName: "SSV Ulm 1846",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2508_SSV_Ulm_1846.png"
  },
  {
    squadId: 37217,
    squadName: "Deportes Tolima",
    shortSquadName: "Deportes Tolima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
  },
  {
    squadId: 37333,
    squadName: "NK Istra 1961",
    shortSquadName: "NK Istra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2169_NK_Istra_1961.png"
  },
  {
    squadId: 37341,
    squadName: "IFK Goteborg",
    shortSquadName: "IFK Goteborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1438_IFK_Goteborg.png"
  },
  {
    squadId: 37475,
    squadName: "Mamelodi Sundowns FC",
    shortSquadName: "Sundowns",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
  },
  {
    squadId: 37760,
    squadName: "Carabobo FC",
    shortSquadName: "Carabobo FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15681_Carabobo_FC.png"
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
  },
  {
    squadId: 38553,
    squadName: "Aston Villa",
    shortSquadName: "Aston Villa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
  },
  {
    squadId: 38564,
    squadName: "FC Volendam",
    shortSquadName: "FC Volendam",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Volendam.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
  },
  {
    squadId: 38945,
    squadName: "SM Caen",
    shortSquadName: "SM Caen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3783_SM_Caen.png"
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 39191,
    squadName: "LKS Lodz",
    shortSquadName: "LKS Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13528_Łódzki_KS.png"
  },
  {
    squadId: 39292,
    squadName: "Liverpool FC Montevideo",
    shortSquadName: "Liverpool FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15632_Liverpool_FC_Montevideo.png"
  },
  {
    squadId: 39339,
    squadName: "Villarreal CF",
    shortSquadName: "Villarreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1714_Villarreal_CF.png"
  },
  {
    squadId: 39599,
    squadName: "FC Midtjylland",
    shortSquadName: "FC Midtjylland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 39885,
    squadName: "AP Brera",
    shortSquadName: "AP Brera",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/39679_AP_Brera.png"
  },
  {
    squadId: 40208,
    squadName: "Tlaxcala FC",
    shortSquadName: "Tlaxcala",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31554_Tlaxcala_FC.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 40498,
    squadName: "Swansea City",
    shortSquadName: "Swansea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1131_Swansea_City.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 40550,
    squadName: "Rot-Weiss Essen",
    shortSquadName: "RW Essen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1391_Rot-Weiss_Essen.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 42798,
    squadName: "Cavalry FC",
    shortSquadName: "Cavalry FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 200001,
    squadName: "Mazatlan FC",
    shortSquadName: "Mazatlan FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
  },
  {
    squadId: 200003,
    squadName: "Oakland Roots SC",
    shortSquadName: "Oakland Roots",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69044_Oakland_Roots_SC.png"
  },
  {
    squadId: 200102,
    squadName: "FC KTP",
    shortSquadName: "FC KTP",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/7315.png?lm=1443854478"
  },
  {
    squadId: 200108,
    squadName: "Tepatitlan FC",
    shortSquadName: "Tepatitlan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64345_Tepatitlán_FC.png"
  },
  {
    squadId: 200109,
    squadName: "Cancun FC",
    shortSquadName: "Cancun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
  },
  {
    squadId: 200113,
    squadName: "1.FC Phonix Lubeck",
    shortSquadName: "Phonix Lubeck",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/2773.png?lm=1593726229"
  },
  {
    squadId: 200193,
    squadName: "Manisa FK",
    shortSquadName: "Manisa FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/48913_Manisa_FK.png"
  },
  {
    squadId: 200414,
    squadName: "Real Soacha Cundinamarca",
    shortSquadName: "Real Soacha C.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/18111.png?lm=1512254152"
  },
  {
    squadId: 224024,
    squadName: "St. Louis CITY SC",
    shortSquadName: "St. Louis CITY",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82686_St._Louis_City_SC.png"
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "Real Cartagena",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
  },
  {
    squadId: 225037,
    squadName: "Atletico La Paz",
    shortSquadName: "Atletico La Paz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/100124_Atlético_La_Paz.png"
  },
  {
    squadId: 225430,
    squadName: "Dvo. Rayo Zuliano",
    shortSquadName: "Rayo Zuliano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-93338_Dvo._Rayo_Zuliano.png"
  },
  {
    squadId: 225557,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Atletico-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-330_Clube_Atlético_Mineiro.png"
  },
  {
    squadId: 225590,
    squadName: "Deportivo Miranda FC",
    shortSquadName: "Dep. Miranda",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/29750_Deportivo_Miranda_FC.png"
  },
  {
    squadId: 225593,
    squadName: "Heroes de Falcon",
    shortSquadName: "Heroes Falcon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/100079_Héroes_de_Falcón.png"
  },
    // {
    //   squadId: 225180,
    //   squadName: "" "",
    //   shortSquadName: "NULL",
    //   squadLogo: null
    // },
  ];