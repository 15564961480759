import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { LandingPageService } from 'api/landing-page/landing-page-service'
import { getAuthSquadId, getAuthUserId } from 'store/auth/authReducer'
import { PlayerPitchModel } from 'api/landing-page/models/pitch-players-modal';
import { cloneDeep } from 'lodash';
import * as Recommendation from './performance-data.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class PitchPlayersState {
    players: Array<any>;
    recommendations: any;
    isLoading: boolean;
    currentPlayerIndex: number;
    selectedPlayers: Array<any>;
    done: boolean;
    toPitch: Array<PlayerPitchModel>
    toDecline: Array<{ userId: number; playerAdId: number; relatedPlayerId: number, squadId: number }>
    toPitchClubsUnique: Array<{ squadId: number, relatedPlayerId: number }>
    toPitchPlayersUnique: Array<number>
    activePitches: number;
    proposalsInProgress: Array<{ playerAdId: number, playerId: number }>;
    pitchProcessingIds: Array<number>;
    declineProcessingIds: Array<number>;
}

const defaultState: PitchPlayersState = {
    players: [],
    recommendations: null,
    isLoading: false,
    currentPlayerIndex: null,
    selectedPlayers: [],
    done: false,
    toPitch: [],
    toDecline: [],
    toPitchClubsUnique: [],
    toPitchPlayersUnique: [],
    activePitches: 0,
    proposalsInProgress: [],
    pitchProcessingIds: [],
    declineProcessingIds: []
}

const stateController = new StateController<PitchPlayersState>(
    "LANDING_PAGE/PITCH_PLAYERS",
    defaultState
);

class Actions {
    public static loadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();

            dispatch(stateController.setState({ isLoading: true }));

            let recommendations = await LandingPageService.loadMySquadRecommendation();

            dispatch(stateController.setState({
                isLoading: false, players: recommendations.players, activePitches: appState.landingPage.performance.indicators.playerPitchesAmount,
                recommendations: { ...recommendations.matchedPlayers, ...recommendations.unmatchedPlayers }
            }));
        }
    }

    public static goToPitches() {
        return async (dispatch, getState: () => AppState) => {

            dispatch(userActivityInsert({
                Message: 'Continue',
                PageName: 'Home [Pitch your players]',
                PageType: PageType.Home
            }));

            dispatch(stateController.setState({ currentPlayerIndex: 0 }))
        }
    }

    public static playerNext() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.pitchPlayers;
            let next = substate.currentPlayerIndex + 1;
            dispatch(stateController.setState({
                currentPlayerIndex: next <= substate.selectedPlayers.length - 1 ? next : substate.currentPlayerIndex,
            }));
            if (next > substate.selectedPlayers.length - 1)
                dispatch(this.pitchBatch());
        }
    }

    public static pitchBatch() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.pitchPlayers;

            dispatch(stateController.setState({ isLoading: true }));
            if (substate.toPitch.length != 0) {
                dispatch(Recommendation.Actions.completedPitchPlayersRecommendation())
            }

            dispatch(stateController.setState({ isLoading: false, done: true }));
        }
    }

    public static playerBack() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.pitchPlayers;
            let prev = substate.currentPlayerIndex - 1;

            dispatch(userActivityInsert({
                Message: 'Back',
                PageName: 'Home [Pitch your players]',
                PageType: PageType.Home
            }));

            dispatch(stateController.setState({ currentPlayerIndex: prev >= 0 ? prev : null }))
        }
    }

    public static setSelectedPlayer(player: any) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.pitchPlayers;
            if (substate.selectedPlayers.find(x => x.player.id == player.player.id)) {
                await dispatch(stateController.setState({
                    selectedPlayers: substate.selectedPlayers.filter(i => i.player.id != player.player.id),
                    toPitchClubsUnique: substate.toPitchClubsUnique.filter(i => i.relatedPlayerId != player.player.id),
                    toPitchPlayersUnique: substate.toPitchPlayersUnique.filter(i => i != player.player.id),
                    toPitch: substate.toPitch.filter(i => i.playerId != player.player.id),
                    toDecline: substate.toDecline.filter(i => i.relatedPlayerId != player.player.id)
                }))
            }
            else {
                await dispatch(stateController.setState({ selectedPlayers: [...substate.selectedPlayers, player] }));
            }

            substate = getState().landingPage.pitchPlayers;
            let pitchedPlayers = substate.selectedPlayers;
            let names = '';
            pitchedPlayers.map(player => { names += player.player.shortName + ', ' });

            dispatch(userActivityInsert({
                Message: `Selected Players: ${names}`,
                PageName: 'Home [Pitch your players]',
                PageType: PageType.Home
            }));

        }
    }

    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
        }
    }
    public static setToPitch(playerAdId: number, playerId: number, amount: number, toSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentSquadId = getAuthSquadId(appState);
            let currentUserId = getAuthUserId(appState);

            let model: PlayerPitchModel = { amount: amount, playerAdId: playerAdId, playerId: playerId, squadId: currentSquadId, userId: currentUserId, squadName: "" };

            let substate = getState().landingPage.pitchPlayers;

            let newRecommendations = cloneDeep(substate.recommendations);
            let rec = newRecommendations[playerId];
            if (rec) {
                newRecommendations[playerId] = rec.filter(x => x.id != playerAdId);
            }

            let squads = substate.toPitchClubsUnique;
            if (substate.toPitchClubsUnique.find(x => x.squadId == toSquadId) == null) {
                squads = [...substate.toPitchClubsUnique, { relatedPlayerId: playerId, squadId: toSquadId }];
            }

            let players = substate.toPitchPlayersUnique;
            if (substate.toPitchPlayersUnique.find(x => x == playerId) == null) {
                players = [...substate.toPitchPlayersUnique, playerId];
            }

            if (substate.toPitch.find(x => x.playerAdId == model.playerAdId && x.playerId == model.playerId) == null) {

                dispatch(stateController.setState({ pitchProcessingIds: [...substate.pitchProcessingIds, playerAdId] }))

                try {
                    await LandingPageService.pitchPlayer(currentUserId, playerAdId, amount, playerId, currentSquadId, null)

                    dispatch(stateController.setState({
                        toPitch: [...substate.toPitch, model], recommendations: newRecommendations,
                        toPitchClubsUnique: squads, toPitchPlayersUnique: players, activePitches: substate.activePitches + 1
                    }));

                    const currentPlayerIndex = getState().landingPage.pitchPlayers.currentPlayerIndex
                    const selectedPlayer = getState().landingPage.pitchPlayers.selectedPlayers[currentPlayerIndex]
                    const isPlayerWithRecommendedMatches = selectedPlayer.numberOfPitchRecommendations > 0

                    dispatch(userActivityInsert({
                        Message: isPlayerWithRecommendedMatches ? 'Pitched Recommended Player' : 'Pitched Player',
                        PageName: 'Home [Pitch your players]',
                        PlayerId: playerId,
                        ClubId: toSquadId,
                        PageType: PageType.Home
                    }));

                    if (newRecommendations[playerId].length == 0)
                        dispatch(this.playerNext())

                } catch (e) {
                    console.error(e)
                } finally {
                    let substate = getState().landingPage.pitchPlayers;
                    dispatch(stateController.setState({ pitchProcessingIds: substate.pitchProcessingIds.filter(item => item !== playerAdId) }))
                }
            }
        }
    }

    public static setToDecline(playerAdId: number, playerId: number, toSquadId: number) {

        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentUserId = getAuthUserId(appState);
            let model = { playerAdId: playerAdId, userId: currentUserId, relatedPlayerId: playerId, squadId: toSquadId };

            let substate = getState().landingPage.pitchPlayers;

            let newRecommendations = cloneDeep(substate.recommendations);
            let rec = newRecommendations[playerId];
            if (rec) {
                newRecommendations[playerId] = rec.filter(x => x.id != playerAdId);
            }

            if (substate.toDecline.find(x => x.playerAdId == model.playerAdId && x.userId == model.userId) == null) {

                try {

                    dispatch(stateController.setState({ declineProcessingIds: [...substate.declineProcessingIds, playerAdId] }))

                    await LandingPageService.declineAd(playerId, playerAdId);

                    dispatch(stateController.setState({ toDecline: [...substate.toDecline, model], recommendations: newRecommendations }));

                    const currentPlayerIndex = getState().landingPage.pitchPlayers.currentPlayerIndex
                    const selectedPlayer = getState().landingPage.pitchPlayers.selectedPlayers[currentPlayerIndex]
                    const isPlayerWithRecommendedMatches = selectedPlayer.numberOfPitchRecommendations > 0

                    dispatch(userActivityInsert({
                        Message: isPlayerWithRecommendedMatches ? 'Declined Recommended Player' : 'Declined Player',
                        PageName: 'Home [Pitch your players]',
                        PlayerId: playerId,
                        ClubId: toSquadId,
                        PageType: PageType.Home
                    }));

                    if (newRecommendations[playerId].length == 0)
                        dispatch(this.playerNext())

                } catch (e) {
                    console.error(e)
                } finally {
                    let substate = getState().landingPage.pitchPlayers;
                    dispatch(stateController.setState({ declineProcessingIds: substate.declineProcessingIds.filter(item => item !== playerAdId) }))
                }
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PitchPlayersState as State,
    Actions as Actions,
    stateController as Controller
};



