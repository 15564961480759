import axios from 'axios-config';

export class UserAttentionService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async surveyShown(userId: number, eventId: number): Promise<any> {
        const { data } = await axios.post(
            `api/UserAttention/SurveyShown`, {
                userId,
                eventId
            },
            this.axiosConfig
        );
        return;
    }

    public static async showSurvey(userId: number, eventId: number): Promise<boolean> {
        const { data } = await axios.get(
            `api/UserAttention/ShowSurvey/${userId}/${eventId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async markSummitAdAsDecline(userId: number, declineType: DeclineType): Promise<any> {
        const { data } = await axios.post(
            `api/UserAttention/SepSummitAdDecline`,
            { UserId: userId, DeclineType: declineType },
            this.axiosConfig
        );
        return;
    }

    public static async canShowSummitAd(userId: number): Promise<boolean> {
        const { data } = await axios.get(
            `api/UserAttention/CanShowSepSummitAd/${userId}`,
            this.axiosConfig
        );
        return data;
    }
}

export enum DeclineType {
    Permanent = 1,
    Later = 2
}