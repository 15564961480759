import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { PlayerInstatIndexService } from 'api/player/player-instat-index.service'
import { PlayerInstatData } from 'api/player/model/player-instat-index'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class InstatIndexState {
    isLoading: boolean;
    firstPlayerData: PlayerInstatData;
    secondPlayerLoading: boolean;
    secondPlayerData: PlayerInstatData;
    secondPlayer: any;
}

const defaultState: InstatIndexState = {
    isLoading: false,
    firstPlayerData: null,
    secondPlayerLoading: false,
    secondPlayerData: null,
    secondPlayer: null
}

const stateController = new StateController<InstatIndexState>(
    "PROFILE/INSTAT_INDEX",
    defaultState
);

class Actions {
    public static loadFirstPlayerData(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currentPlayer = (state as any).playerProfile
            var data = await PlayerInstatIndexService.getData(id);
            
            dispatch(stateController.setState({ firstPlayerData: data }));

            var similarPlayer = await PlayerInstatIndexService.getSimilarPlayer(state.auth.squadId, id);

            if (similarPlayer != null && similarPlayer != "") {
                dispatch(this.loadSecondPlayerData(similarPlayer, false));
            }
        }
    }

    public static loadSecondPlayerData(player: any, track: boolean = true) {
      
        return async (dispatch, getState: () => AppState) => {
            dispatch(this.removeSecondPlayer())
            dispatch(this.resetFirstPlayerMaxIndex())
            let state = getState();
            let currentPlayer = (state as any).playerProfile
            if (track && player != null) {
                dispatch(
                    userActivityInsert(
                        {
                            Message: `Compared to: "${player.englishShortName}", "${player.parentSquadName}"`,
                            PageName: "Player Profile [InStat index]",
                            PlayerId: currentPlayer.details.id,
                            ClubId: currentPlayer.details.parentSquadId,
                            PageType: PageType.PlayerProfile
                        }
                    )
                );
            }
            
            dispatch(stateController.setState({ secondPlayerLoading: true, secondPlayerData: null }));

            if (player != null) {
                var data = await PlayerInstatIndexService.getData(player.id);
            }

            dispatch(stateController.setState({ secondPlayerData: data, secondPlayer: player, secondPlayerLoading: false }));
            dispatch(this.setHighestMaxIndexForTwoPlayers())
        }
    }

    public static setHighestMaxIndexForTwoPlayers() {
        return (dispatch, getState: () => AppState) => {
            let state = getState()
            let firstPlayerMaxIndex = state.profileInstatIndex.firstPlayerData.maxIndex
            let secondPlayerMaxIndex = state.profileInstatIndex.secondPlayerData.maxIndex
            let highestIndex = firstPlayerMaxIndex > secondPlayerMaxIndex ? firstPlayerMaxIndex : secondPlayerMaxIndex
            dispatch(stateController.setState( state => ({
                ...state,
                firstPlayerData: {
                    ...state.firstPlayerData,
                    maxIndex: highestIndex
                },
                secondPlayerData: {
                    ...state.secondPlayerData,
                    maxIndex: highestIndex
                }
            })))
        }
        
    }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState },"DISPOSING INSTAT"));
        }
    }

    public static removeSecondPlayer() {
        return (dispatch) => {
            dispatch(stateController.setState(state => ({
                ...state,
                secondPlayerData: null
            })))
            dispatch(this.resetFirstPlayerMaxIndex())
        }
    }

    public static resetFirstPlayerMaxIndex() {
        return (dispatch, getState: () => AppState) => {

            let firstPlayerData = getState().profileInstatIndex.firstPlayerData
            let playersFirstFiveMatches = firstPlayerData.matches.slice(0,8)
            let maxIndex = Math.max(...playersFirstFiveMatches.map(i => i.instatIndex))

            dispatch(stateController.setState(state => ({
                ...state,
                firstPlayerData: {
                    ...state.firstPlayerData,
                    maxIndex: maxIndex
                }
            })))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    InstatIndexState as State,
    Actions as Actions,
    stateController as Controller
};



