import axios from 'axios-config'
import { AdminPermissionsResponseModel } from './models'

export default class AdminPermissionsService{
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async loadPermissionsData(): Promise<AdminPermissionsResponseModel> {
        const { data } = await axios.get(
            `api/AdminPagePermissions/GetAdminPagePermissions`,
            this.axiosConfig
        )
        return data.output
    }

    public static async addAdminPermission(userId: number, pageId: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/AddAdminPagePermission/${userId}/${pageId}`,
            this.axiosConfig
        )
        return data
    }

    public static async removeAdminPermission(userId: number, pageId: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/RemoveAdminPagePermission/${userId}/${pageId}`,
            this.axiosConfig
        )
        return data
    } 

    public static async setHistoryDepth(userId: number, pageId: number, depth: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/SetHistoryDepth/${userId}/${pageId}/${depth}`,
            this.axiosConfig
        )
        return data
    } 

    public static async changeAssignedClubs(userId: number, supervisorId: number, permissionId: number, isAssignedClubs: boolean, assignAllSupervisors:boolean = false) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/UpdateAssignedClubByPermission/${userId}/${supervisorId}/${permissionId}/${isAssignedClubs}/${assignAllSupervisors}`,
            this.axiosConfig
        )
        return data
    } 
    
    public static async removeAssignedClubByPermissionIdAndUserId(userId: number, permissionId: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/RemoveAssignedClubByPermissionIdAndUserId/${userId}/${permissionId}`,
            this.axiosConfig
        )
        return data
    } 
    
    public static async allowArea(userId: number, permissionId: number, areaId: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/AddAdminPagePermissionArea/${userId}/${permissionId}/${areaId}`,
            this.axiosConfig
        )
        return data
    }

    public static async disallowArea(userId: number, permissionId: number, areaId: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/DeleteAdminPagePermissionArea/${userId}/${permissionId}/${areaId}`,
            this.axiosConfig
        )
        return data
    } 
    public static async allowAllAreas(userId: number, permissionId: number) {
        const { data } = await axios.get(
            `api/AdminPagePermissions/AddAdminPagePermissionAllAreas/${userId}/${permissionId}`,
            this.axiosConfig
        )
        return data
    }

    public static async disallowAllAreas(userId: number, permissionId: number) {        
        const { data } = await axios.get(
            `api/AdminPagePermissions/DeleteAdminPagePermissionAllAreas/${userId}/${permissionId}`,
            this.axiosConfig
        )
        return data
    }

    public static async hasUserPermissionByCode(code){
        const { data } = await axios.get(
          `api/AdminPagePermissions/HasUserPermissionByCode/${code}`,
          this.axiosConfig
        );
        return data;
    }

    public static async getAllowedColumns(userId: number, pageId: number): Promise<string[]>{
        const { data } = await axios.get(
          `api/AdminPagePermissions/GetAllowedColumns/${userId}/${pageId}`,
          this.axiosConfig
        );
        return data.output;
    }
    
}