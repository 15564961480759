import {  PlayerAdModel } from 'api/agency/agent/landing/agency-dashboard';

export interface PreconnectedPlayerItem {
    playerId: number;
    playerName: string;
    opportunityAdsQty: number;
    parentSquad: SquadShortInfo;
    currentSquad: SquadShortInfo;
    isFreeAgent: boolean;
    playerEmail: string;
    playerPhone: string;
    phoneCodeAreaId: number;
    invitationUid: string;
}

export interface SquadShortInfo {
    id: number
    name: string
    league: string
    country: string
    logo: string
}

export enum PitchSuggestionTransferTypeEnum {
    Transfer = 1,
    Loan = 2,
    Both = 3
}

export interface PitchSuggestionsItem {
    playerId: number;
    transferType: PitchSuggestionTransferTypeEnum;
}

export enum VerificationStatus {
    Available = 0,
    VerifiedByAgent = 1,
    VerificationPending = 2,
    Added = 3,
}

export interface AddPlayersModel {
    pendingPlayersList: Array<AddedPlayerItem>;
    suggestionPlayersList: Array<AddedPlayerItem>;
    isLoading: boolean;
    processing: boolean;
    keyword: string;
    showSuggestionsList: boolean;
    addedPlayersIds: number[];
}

export interface AddedPlayerItem {
    playerId: number;
    playerName: string;
    parentSquad: SquadShortInfo;
    currentSquad: SquadShortInfo;
    isFreeAgent: boolean;
    status: number;
}


export interface PitchSuggestionsResponse {
    players: PitchSuggestionsModel[];
}

export interface PitchSuggestionsModel {
    playerId: number;
    playerName: string;
    pitchSuggestions: PitchSuggestionModel[];
}

export interface PitchSuggestionModel {
    playerAd: PlayerAdModel;
    suggestions: PitchSuggestionItemModel[];
}

export interface FormattedPriceModelExtended {
    price: number | string;
    formattedComma: string;
    formattedKMB: string;
}

export interface PitchSuggestionItemModel {
    isRecommended: boolean;
    amount: FormattedPriceModelExtended;
}

export interface PitchSuggestionsModelExtended {
    playerId: number;
    playerName: string;
    pitchSuggestions: PitchSuggestionModelExtended[];
}

export interface PitchSuggestionModelExtended  {
    playerAd: PlayerAdModel;
    suggestions: PitchSuggestionItemModelExtended[];
}

export interface PitchSuggestionItemModelExtended extends PitchSuggestionItemModel {
    id: number;
    isSelected: boolean;
    isCustom: boolean;
    isEdit: boolean;
}

export interface RecommendedPitchesRequest {
    playerId: number;
    pitches: RecommendedPitch[];
}

export interface RecommendedPitch {
    playerAdId: number;
    amount: number;
}

export interface PendingVerificationResponse {
    players: PendingVerificationPlayer[]
}
export interface PendingVerificationPlayer {
    playerId: number
    insightsShared: boolean
    playerName: string
    playerEmail: string
    playerPhone: string
    playerDateBirth: string
    playerParentSquadId: number
    playerParentSquadName: string
    isPriority: boolean
    timeout: number
    pitchesAwaitingVerification: number
    opportunities: number
    phoneCodeAreaId: number
    currentSquad: {
        country: string
        id: number
        league: string
        logo: string
        name: string
    }
    parentSquad: {
        country: string
        id: number
        league: string
        logo: string
        name: string
    },
    verificationMessage: string;
}

export interface PlayerInvitationSent {
    age: number
    firstPositionCode: string
    insightsShared: boolean
    isFreeAgent: boolean
    isRegular: boolean
    parentClubContractExpiry: string
    parentClubContractExpiryPlus: any
    photo: any
    playerId: number
    playerName: string
    playerVideos: Array<any>
    promoText: any
    secondaryPositionCode: any
    squadId: number
    squadName: string
    strategy: any
    verifiedStatus: number
}

export interface PreconnectedPlayersResponse {
    players: PreconnectedPlayerItem[]
}

export interface VerificationPlayerMessage {
    playerId: number;
    message: string;
}