import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { getAuth } from 'store/auth/authReducer';

class B2MatchObjectivitiesState {
    isLoading: boolean;
    transferIn: boolean;
    transferOut: boolean;
    moreAccessRequested: boolean;
    transferOutBlocked: boolean;
    transferOutDisabled: boolean;
}

const defaultState: B2MatchObjectivitiesState = {
    isLoading: false,
    transferIn: false,
    transferOut: false,
    moreAccessRequested: false,
    transferOutBlocked: true,
    transferOutDisabled: false
}

const stateController = new StateController<B2MatchObjectivitiesState>(
    "VIRTUAL_SUMMIT/B2MATCH_OBJECTIVITIES",
    defaultState
);

class Actions {
    public static getData = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ isLoading: false }));
        }
    }

    public static setInitialData = (data: B2MatchObjectivitiesState) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(data));
        }
    }

    public static toggleTranssferIn = () => {
        return async (dispatch, getState) => {
            let substate: B2MatchObjectivitiesState = getState().events.virtualSummit.b2matchObjectivities;
            dispatch(stateController.setState({ transferIn: !substate.transferIn }));
        }
    }

    public static toggleTranssferOut = () => {
        return async (dispatch, getState) => {
            let substate: B2MatchObjectivitiesState = getState().events.virtualSummit.b2matchObjectivities;
            dispatch(stateController.setState({ transferOut: !substate.transferOut }));
        }
    }


    public static requestMoreAccess = () => {
        return async (dispatch, getState) => {
            let appState = getState();
            let { userId, eventId } = appState.auth;
            let substate: B2MatchObjectivitiesState = getState().events.virtualSummit.b2matchObjectivities;
            dispatch(stateController.setState({ isLoading: true }))
            const data = await VirtualSummitService.requestUpgradeToGetAccess(userId);
            dispatch(stateController.setState({ moreAccessRequested: true, isLoading: false }));

            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match
            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Requested "Upgrade Info" on Transfers Out (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }))
        }
    }

    public static goBack = (redirect) => {
        return (dispatch, getState) => {
            const appState = getState();
            let { scheduledTime, meetingClubName } = appState.events.virtualSummit.b2match
            const { eventId } = getAuth(appState);

            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Closed meeting set up (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }))
            redirect()
        }
    }

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState(defaultState));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    B2MatchObjectivitiesState as State,
    Actions as Actions,
    stateController as Controller
};



