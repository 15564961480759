import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import * as CommocnController from 'pages/player-ads/redux/common.controller'
import { Actions as PlayerStatisticActions } from 'pages/landing-page/redux/player-statistic.controller';

import PitchService from 'api/pitch-v2/pitch-service.v2'
import { PitchGroupModel } from 'api/pitch-v2/models/pitch-group.model';
import { PitchModel, PitchType } from 'api/pitch-v2/models/pitch.model';
import PlayerPitchServiceV2 from 'api/pitch-v2/pitch-service.v2'
import * as MessageSend from './message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { translate } from 'services/localization';
import { ActionType, PageType, UserActivityType } from 'constants/enums';

class PitchV2State {
    activePitchesGroups: Array<PitchGroupModel>;
    testedPitches: Array<PitchModel>;
    isLoading: boolean;
    initialized: boolean;
    processingPitchIds: Array<number>;
    declareInterestProcessingIds: number[];
    showWarningPopup: boolean;
    processingTestedPitch: PitchModel;
}

const defaultState: PitchV2State = {
    activePitchesGroups: [],
    testedPitches: [],
    isLoading: false,
    initialized: false,
    processingPitchIds: [],
    declareInterestProcessingIds: [],
    showWarningPopup: false,
    processingTestedPitch: null,
}

const stateController = new StateController<PitchV2State>(
    "LANDING_PAGE/V2/PITCH",
    defaultState
);

class SendTestPlusPitchSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private playerId: number;
    private squadPitchId: number;
    private isSavedMode: boolean;
    private isAgencyReceiver: boolean;
    private agencyId: number;

    constructor(dispatch, toSquadId: number, playerId: number, squadPitchId: number, isSavedMode: boolean, isAgencyReceiver: boolean, agencyId: number) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.playerId = playerId;
        this.squadPitchId = squadPitchId;
        this.isSavedMode = isSavedMode;
        this.isAgencyReceiver = isAgencyReceiver;
        this.agencyId = agencyId;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {
        // this.dispatch(CommonController.Actions.togglePlayerInShortList(this.playerId, false));

        const pitchSource = this.isAgencyReceiver ? 'Agents ' : ''

        let receiverId = this.isAgencyReceiver ? this.agencyId : this.toSquadId;

        this.dispatch(userActivityInsert({
            PageName: `Home [${pitchSource}Test Pitch]`,
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            ClubId: !this.isAgencyReceiver ? this.toSquadId : -1,
            AgencyId: this.isAgencyReceiver ? this.agencyId : null,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.Home,
            UserActivityType: UserActivityType.Action
        }));

        if (!checked) {
            this.dispatch(Actions.deleteSavedPitch(this.squadPitchId));
        } else {
            this.dispatch(Actions.deleteTestInterestPitch(this.squadPitchId));
        }

        await this.dispatch(messageSend(receiverId, message, null, session.officialName, session.mesageTypeId, session.subject, this.isAgencyReceiver, this.squadPitchId));
    }

    public cancelMessage() {
        const pitchSource = this.isAgencyReceiver ? 'Agents ' : ''

        this.dispatch(userActivityInsert({
            PageName: `Home [${pitchSource}Test Pitch]`,
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            ClubId: !this.isAgencyReceiver ? this.toSquadId : -1,
            AgencyId: this.isAgencyReceiver ? this.agencyId : null,
            PageType: PageType.Home
        }));
    }
}


class Actions {

    public static dispose(): any {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static openWarningPopup() {
        return (dispatch, getState: () => AppState) => {

            const substate = getState().landingPage.pitchV2.processingTestedPitch

            dispatch(userActivityInsert({
                PageName: 'Home [Reveal Name Warning Pop-Up]',
                Message: 'Opened Reveal Name Warning Pop-Up',
                PageType: PageType.PlayerAds,
                PlayerId: substate?.player.id,
                ClubId: substate?.player.clubInfo.parentSquad?.id,
            }))

            dispatch(stateController.setState({ showWarningPopup: true }))
        }
    }

    public static cencelWarningPopup() {
        return (dispatch, getState: () => AppState) => {

            const substate = getState().landingPage.pitchV2.processingTestedPitch

            dispatch(userActivityInsert({
                PageName: 'Home [Reveal Name Warning Pop-Up]',
                Message: 'Cancelled Reveal Name Warning Pop-Up',
                PageType: PageType.PlayerAds,
                PlayerId: substate?.player.id,
                ClubId: substate?.player.clubInfo.parentSquad?.id,

            }))

            dispatch(Actions.closeWarningPopup())
        }
    }

    public static closeWarningPopup() {
        return (dispatch) => {
            dispatch(stateController.setState({
                showWarningPopup: false,
                processingTestedPitch: null,
            }))
        }
    }

    public static openSendMessage() {
        return (dispatch, getState: () => AppState) => {

            const substate = getState().landingPage.pitchV2.processingTestedPitch
            
            dispatch(userActivityInsert({
                PageName: 'Home [Reveal Name Warning Pop-Up]',
                Message: 'Clicked Confirm',
                PageType: PageType.Home,
                PlayerId: substate?.player.id,
                ClubId: substate?.player.clubInfo.parentSquad?.id,
            }))

            dispatch(Actions.newMessage(substate?.pitchId))
            dispatch(Actions.closeWarningPopup())
        }
    }

    public static onSendMessageClick(pitchId: number) {
        return (dispatch, getState: () => AppState) => {
            const pitch = getState().landingPage.pitchV2.testedPitches.find(item => item.pitchId === pitchId)
            
            const isAdAnonymised = pitch?.isAdAnonymised
            const interestWasDeclared = pitch?.interestDeclared
            
            if (isAdAnonymised && !interestWasDeclared) {
                dispatch(stateController.setState({ processingTestedPitch: pitch }))
                dispatch(Actions.openWarningPopup())
            } else {
                dispatch(Actions.newMessage(pitchId))
            }
        }
    }

    public static newMessage(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            let pitch = state.landingPage.pitchV2.testedPitches.find(x => x.pitchId == pitchId);

            dispatch(Actions.insertUserActivity(pitchId, 'Opened New Message', null))

            let pitchSource = "";

            if (pitch.type == PitchType.Agency) {
                pitchSource = "Agents "
            }

            let session: MessageSend.MessageSendSession = {
                subject: "Re: " + pitch.player.englishShortName + " Pitch",
                logo: pitch.player.clubInfo.logo,
                checkBoxTitle: 'Keep player in saved pitches',
                officialName: pitch.player.clubInfo.parentSquad.name,
                leagueName: pitch.player.clubInfo.parentSquadCompetitionName + ", " + pitch.player.clubInfo.parentSquadCompetitionCountry,
                mesageTypeId: 6,
            }

            if (pitch.type == PitchType.Agency) {

                let area = translate(`apiTexts.areas.${pitch.agentInfo.agencyAreaId}`)

                session = {
                    subject: "Re: " + pitch.player.englishShortName + " Pitch",
                    logo: false,
                    checkBoxTitle: 'Keep player in saved pitches',
                    officialName: pitch.agentInfo.agencyName,
                    leagueName: `Agent${area ? ', ' : ''} ${area}`,
                    mesageTypeId: 6,
                }
            }


            let agencyId = null;
            if (pitch.agentInfo) {
                agencyId = pitch.agentInfo.agencyId;
            }

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SendTestPlusPitchSpecification(dispatch, pitch.player.clubInfo.parentSquad.id, pitch.player.id, pitch.pitchId, false, pitch.type == PitchType.Agency, agencyId)
                )
            )
        }
    }

    public static loadAllPitches() {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({ isLoading: true }));

            let pitchesResult = await PlayerPitchServiceV2.GetPitches();

            dispatch(stateController.setState({ isLoading: false, activePitchesGroups: pitchesResult.groupedPitches, testedPitches: pitchesResult.testInterestedPitches, initialized: true }));
        }
    }

    public static deleteSavedPitch(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await PlayerPitchServiceV2.dismiss(pitchId);
                            dispatch(Actions.insertUserActivity(pitchId, "Deleted Saved Pitch", null));
                            resolve(true)
                        } catch (e) {
                            reject(e)
                        }
                    })
                )
            );
            // A.Z potential refresh
        }
    }

    public static deleteTestInterestPitch(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await PlayerPitchServiceV2.deleteTestInterest(pitchId);
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            // A.Z potential refresh
        }
    }

    public static declareInterest(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.pitchV2;

            let pitchGroups = subState.activePitchesGroups;
            let pitch = this.getPitch(pitchId, pitchGroups);
            if (pitch == null) {
                pitch = subState.testedPitches.find(x => x.pitchId == pitchId);
            }

            let shortlistedPlayers = state.landingPage.playerStatistic.shortListedPlayers;
            if (!shortlistedPlayers.includes(pitch.player.id)) {
                dispatch(PlayerStatisticActions.togglePlayerInShortList(pitch.player.id, false));
            }

            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            dispatch(stateController.setState(prevState => ({
                                ...prevState,
                                declareInterestProcessingIds: [...prevState.declareInterestProcessingIds, pitchId]
                            })))
                            await PitchService.declareInterest(pitchId);
                            dispatch(Actions.insertUserActivity(pitchId, "Declared Interest", ActionType.DeclaredInterest, UserActivityType.Action));
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        } finally {
                            dispatch(stateController.setState(prevState => ({
                                ...prevState,
                                declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== pitchId)
                            })))
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static dismissPitch(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await PlayerPitchServiceV2.dismiss(pitchId);
                            dispatch(Actions.insertUserActivity(pitchId, "Dismissed Pitch", null));

                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static savePitch(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await PlayerPitchServiceV2.savePitch(pitchId);
                            dispatch(Actions.insertUserActivity(pitchId, "Saved Pitch", null));
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static insertUserActivity(pitchId: number, message: string, actionType: ActionType | null, userActivityType: number = null) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            let subState = state.landingPage.pitchV2;

            let pitchGroups = subState.activePitchesGroups;
            let pitch = this.getPitch(pitchId, pitchGroups);
            let testedPrefix = '';
            if (pitch == null) {
                pitch = subState.testedPitches.find(x => x.pitchId == pitchId);
                testedPrefix = 'Test '
            }

            if (pitch != null) {
                let agencyId = null;
                let clubId = null
                let pageName = `Home [${testedPrefix}Pitch]`
                let links = [];
                links.push({ Type: 2, TargetId: pitchId });

                if (pitch.agentInfo) {
                    agencyId = pitch.agentInfo.agencyId;
                    pageName = `Home [Agents ${testedPrefix}Pitch]`
                } else {
                    clubId = pitch.player.clubInfo.parentSquad.id
                }

                dispatch(userActivityInsert({
                    PageName: pageName,
                    Message: message,
                    PlayerId: pitch.player.id,
                    ClubId: clubId,
                    Links: links,
                    AgencyId: agencyId,
                    ActionType: actionType,
                    PageType: PageType.Home,
                    UserActivityType: userActivityType
                }));
            }
        }
    }

    private static proceedPitch(pitchId: number, action: () => Promise<any>) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().landingPage.pitchV2;
            let currentProcesses = subState.processingPitchIds;

            dispatch(stateController.setState({ processingPitchIds: [...currentProcesses, pitchId] }))

            try {
                await action();
                dispatch(CommocnController.Actions.loadCounters());

                currentProcesses = subState.processingPitchIds;

                let pitchesGroup = subState.activePitchesGroups;
                let refreshedPitchesGroup = pitchesGroup.map(item => {
                    if (item.pitches.find(x => x.pitchId == pitchId)) {
                        return { ...item, pitches: item.pitches.filter(x => x.pitchId != pitchId) }
                    }
                    return item;
                })

                dispatch(stateController.setState(
                    {
                        activePitchesGroups: refreshedPitchesGroup.filter(x => x.pitches.length > 0),
                        testedPitches: [...subState.testedPitches.filter(x => x.pitchId != pitchId)]
                    })
                )
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState(
                    { processingPitchIds: [...currentProcesses.filter(x => x != pitchId)] })
                )
            }
        })
    }

    private static getPitch(pitchId: number, pitchGroups: any[]) {
        let pitch = null;
        pitchGroups.map(group => {
            return group.pitches.map(p => {
                if (p.pitchId == pitchId) {
                    pitch = p;
                }
            })
        });

        return pitch;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PitchV2State as State,
    Actions as Actions,
    stateController as Controller
};



