import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { UserSummitPreferenceEnum } from 'api/virtual-summit/models/summit-invitationl'
import { Actions as StepActions, RegistrationState } from './confirm.controller';

class ConfirmStep3State {
    isLoading: boolean;
    selectedOption: UserSummitPreferenceEnum;
    disabledOptions: Array<UserSummitPreferenceEnum>;
    isCompleting: boolean;
}

const defaultState: ConfirmStep3State = {
    isLoading: false,
    selectedOption: null,
    isCompleting: false,
    disabledOptions: [UserSummitPreferenceEnum.Ticket1]
}

const stateController = new StateController<ConfirmStep3State>(
    "VIRTUAL_SUMMIT/CONFIRM_STEP3",
    defaultState
);

class Actions {
    public static getData = () => {
        return async (dispatch, getState: () => AppState) => {
        }
    }

    public static setPreference = () => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = state.virtualSummit.confirmStep3;

            dispatch(stateController.setState({ isCompleting: true }));
            await VirtualSummitService.setPreferences(state.auth.userId, substate.selectedOption);
            dispatch(stateController.setState({ isCompleting: false }));

            dispatch(StepActions.setStep(RegistrationState.Done));
        }
    }

    public static setSelectedRadio = (option: UserSummitPreferenceEnum) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep3;

            if (!substate.disabledOptions.some((i) => i == option))
                dispatch(stateController.setState({ selectedOption: option }));
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmStep3State as State,
    Actions as Actions,
    stateController as Controller
};



