import { messageSend } from 'app/send-message/actions/send-message.actions'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {
    IMessageSendSpecificaion,
    MessageSendSession,
    Actions as MessageModalController
} from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller'
import { AppState } from 'root.reducer'
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { ReceiverRole } from 'api/search/model/declare-interest-definition';

class Actions {
    public static openModalAgent() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const player = Selectors.getPlayerProfile(appState);
            const {
                agencyRelatedInfo,
                generalInfo
            } = player;
            const { agencyId } = agencyRelatedInfo
            let messageSession: MessageSendSession = {
                toClub: false,
                toAgency: true,

                subject: `${generalInfo.shortName} interest`,
                mesageTypeId: 8,
                checkBoxTitle: '',
                agencyId: agencyId,

                squadId: null, squadName: null, squadLeagueName: null, squadLeagueCountry: null, squadLogo: null,

                agencyCountry: agencyRelatedInfo.agencyCountry,
                agencyName: agencyRelatedInfo.agencyName,
                isAgentPlayerVerified: agencyRelatedInfo.isAgentPlayerVerified,
                recipientId: agencyId,
                recipientType: ReceiverType.Agency
            }

            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    dispatch(userActivityInsert({
                        Message: 'Clicked Cancel',
                        PageName: 'Player Profile [Send Message Pop-up]',
                        AgencyId: session.recipientId,
                        PageType: PageType.PlayerProfile
                    }))
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {
                        await dispatch(messageSend(session.recipientId, message, player, session.agencyName, session.mesageTypeId, session.subject, true));
                        dispatch(userActivityInsert({
                            Message: `Clicked Send`,
                            PageName: 'Player Profile [Send Message Pop-up]',
                            PlayerId: player.id,
                            AgencyId: session.recipientId,
                            ActionType: ActionType.SentNewMessage,
                            PageType: PageType.PlayerProfile,
                            UserActivityType: UserActivityType.Action
                        }));
                    } catch(e) {
                        console.error(e)
                    }
                }
            }

            // dispatch(userActivityInsert({
            //     Message: 'Opened New Message',
            //     PageName: 'Player Profile [Agents section]',
            //     PlayerId: player.id,
            //     AgencyId: agencyId,
            //     PageType: PageType.PlayerProfile
            // }))

            dispatch(MessageModalController.openSession(messageSession, specification))
        }
    }

    public static openModal(messageReceiverType: ReceiverType) {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const player = Selectors.getPlayerProfile(appState);

            const {
                parentSquad,
                latestSquad,
                agencyRelatedInfo,
                generalInfo
            } = player;

            const { agencyId } = agencyRelatedInfo;

            const canSendToSquad = player.declareInterest?.messageRule?.receiver === ReceiverRole.Club;
            const canSendToAgency = player.declareInterest?.messageRule?.receiver === ReceiverRole.Agent;
            const canSendToSquadAndToAgency = player.declareInterest?.messageRule?.receiver === ReceiverRole.All;

            let messageSession: MessageSendSession = {
                toClub: messageReceiverType === ReceiverType.Squad && (canSendToSquadAndToAgency || canSendToSquad),
                toAgency: messageReceiverType === ReceiverType.Agency && (canSendToSquadAndToAgency || canSendToAgency),

                subject: `${generalInfo.shortName} interest`,
                mesageTypeId: canSendToAgency ? 8 : 1,
                checkBoxTitle: '',

                agencyId: agencyId,
                squadId: parentSquad ? parentSquad.id : (latestSquad ||{}).id,

                squadName: player.parentSquad ? player.parentSquad?.name : (player.latestSquad ||{}).shortName,
                squadLeagueName: player.parentSquad ? player.parentSquad.league : (player.latestSquad ||{}).league,
                squadLeagueCountry: player.parentSquad ? player.parentSquad.country :(player.latestSquad ||{}).country,
                squadLogo: player.parentSquad ? player.parentSquad.logo : (player.latestSquad ||{}).logo,

                agencyCountry: agencyRelatedInfo.agencyCountry,
                agencyName: agencyRelatedInfo.agencyName,
                isAgentPlayerVerified: agencyRelatedInfo.isAgentPlayerVerified,
            }

            if (!canSendToSquadAndToAgency && canSendToSquad) {
                messageSession = { ...messageSession, recipientId: messageSession.squadId, recipientType: ReceiverType.Squad }
            } else if (!canSendToSquadAndToAgency && canSendToAgency) {
                messageSession = { ...messageSession, recipientId: messageSession.agencyId, recipientType: ReceiverType.Agency }
            }
            if (canSendToSquadAndToAgency && messageReceiverType !== undefined) {
                if (messageReceiverType === ReceiverType.Squad) {
                    messageSession = { ...messageSession, recipientId: messageSession.squadId, recipientType: ReceiverType.Squad }
                } else if (messageReceiverType === ReceiverType.Agency) {
                    messageSession = { ...messageSession, recipientId: messageSession.agencyId, recipientType: ReceiverType.Agency }
                }
            }



            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    const messageToAgency = session.recipientType === ReceiverType.Agency;
                    dispatch(userActivityInsert({
                        Message: `Clicked Cancel`,
                            PageName: 'Player Profile [Send Message Pop-up]',
                            PlayerId: player.id,
                            AgencyId: messageToAgency ? session.recipientId : null,
                            ClubId: !messageToAgency ? session.recipientId : null,
                            // CurrentClubId: player.currentSquad ? player.currentSquad.id : -1,
                            PageType: PageType.PlayerProfile,
                    }))
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {
                        const messageToAgency = session.recipientType === ReceiverType.Agency;
                        if (messageToAgency) {
                            await dispatch(messageSend(session.recipientId, message, player, session.agencyName, session.mesageTypeId, session.subject, true));
                        } else {
                            await dispatch(messageSend(session.recipientId, message, player, session.squadName, session.mesageTypeId, session.subject, false));
                        }

                        dispatch(userActivityInsert({
                            Message: `Clicked Send`,
                            PageName: 'Player Profile [Send Message Pop-up]',
                            PlayerId: player.id,
                            AgencyId: messageToAgency ? session.recipientId : null,
                            ClubId: !messageToAgency ? session.recipientId : null,
                            //CurrentClubId: player.currentSquad ? player.currentSquad.id : -1,
                            ActionType: ActionType.SentNewMessage,
                            PageType: PageType.PlayerProfile,
                            UserActivityType: UserActivityType.Action
                        }));
                    } catch(e) {
                        console.error(e)
                    }
                }
            }

            const messageToAgency = messageSession.recipientType === ReceiverType.Agency;
            // dispatch(userActivityInsert({
            //     Message: 'Opened New Message',
            //     PageName: 'Player Profile',
            //     PlayerId: player.id,
            //     AgencyId: messageToAgency ? messageSession.recipientId : null,
            //     ClubId: !messageToAgency ? messageSession.recipientId : null,
            //     CurrentClubId: player.currentSquad?.id,
            //     PageType: PageType.PlayerProfile
            // }))

            dispatch(MessageModalController.openSession(messageSession, specification))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.playerProfileV2;
    public static getPlayerProfile = (state: AppState) => Selectors.getRoot(state).profile;
}

export {
    Actions as Actions,
    Selectors as Selectors,
};