import { StateController } from 'utils/action-declaration';
import { AgencyLandingService } from 'api/agency/agency-landing.service';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { BOOK_EXPERT_SESSION_MODAL } from 'constants/modals';
import { AppState } from 'root.reducer';
import { LandingPageService } from 'api/landing-page/landing-page-service'
class FeatureLandingState {
    isActivateTrialProcessing: boolean;
    isFeatureActivated: boolean;
    successExpert: any;
}

const defaultState: FeatureLandingState = {
    isActivateTrialProcessing: false,
    isFeatureActivated: false,
    successExpert: null,
}

const stateController = new StateController<FeatureLandingState>(
    'AGENCY_FEATURE_LANDING',
    defaultState
)

class Actions {
    public static activateFreeTrial() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isActivateTrialProcessing: true }))
                await AgencyLandingService.activateAgencyFreeTrial()
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isActivateTrialProcessing: false }))
            }
        }
    }

    public static initTrialState() {
        return async (dispatch) => {
            const isFreeTrialActive =  await AgencyLandingService.getIsAgencyFreeTrialActive()
            dispatch(stateController.setState({ isFeatureActivated: isFreeTrialActive }))
        }
    }

    public static openBookSessionModal = () => {
        return (dispatch, getState: () => AppState) => {
        
            const state = getState();
            const salesExpert = state.agencyFeatureLanding.successExpert;
            let command = {
                type: MODAL_OPEN,
                payload: { 
                    id: BOOK_EXPERT_SESSION_MODAL, 
                    className: 'hp-book-session-modal',
                    content: {
                        pageName: 'Club Agents [Success manager]',
                        salesExpert: salesExpert || {},
                    }
                },
            };
            dispatch(command);
        }
    }

    public static closeBookSessionModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: BOOK_EXPERT_SESSION_MODAL,
            };
            dispatch(command);
        }
    }

    public static loadSuccessExpert() {
        return async (dispatch) => {
            let currentNotification = await LandingPageService.getUnreadNotification();
            dispatch(stateController.setState({ successExpert: currentNotification.successSalesExpert }))
        }
    }

    public static initSuccessExpert() {
        return async (dispatch) => {
             dispatch(Actions.loadSuccessExpert())
        }
    }
}

class Selectors {
    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FeatureLandingState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};