import axios from 'axios-config';
import {
    CreateCompetitionListRequest,
    UpdateCompetitionListRequest,
} from './models';

export default class CompetitionListService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async create(request: CreateCompetitionListRequest): Promise<any> {
        const { data } = await axios.post(
            `api/Competition/UpdateSquadCompetitionList`,
            request,
            { ...this.axiosConfig }
        );
        return data;
    }

    public static async update(request: UpdateCompetitionListRequest): Promise<any> {
        const { data } = await axios.post(
            `api/Competition/UpdateSquadCompetitionList`,
            request,
            { ...this.axiosConfig }
        );
        return data;
    }

    public static async delete(id: number): Promise<any> {
        const { data } = await axios.delete(
            `api/Competition/DeleteCompetitionList/${id}`,
            { ...this.axiosConfig }
        );
        return data;
    }
}