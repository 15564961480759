import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
    DELETE_THE_PROFILE_USERS,
    ADD_THE_PROFILE_USERS,
    UPDATE_THE_PROFILE_USERS,
    UPDATE_PROFILE,
} from 'store/actionTypes';
import { getSquadUsersProfiles } from '../actions/edit-profile.action';
import { getAuth } from 'store/auth/authReducer';

const refreshUsersOnProfileChangeEpic = (action$, state$) => {
    return action$.pipe(
        ofType(
            DELETE_THE_PROFILE_USERS.SUCCESS,
            ADD_THE_PROFILE_USERS.SUCCESS,
            UPDATE_THE_PROFILE_USERS.SUCCESS,
            UPDATE_PROFILE.SUCCESS,
        ),
        map(() => {
            var squadId = getAuth(state$.value).squadId;
            return getSquadUsersProfiles(squadId);
        }),
    );
};

export default refreshUsersOnProfileChangeEpic;
