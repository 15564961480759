import _ from 'lodash'
import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import { SuggestionModel } from 'api/player/player-search'
import EventsService from 'api/admin/events/events.service'
import { PlayerSearchService } from 'api/player/player-search'
import { getPlayerSearchFilterCriteria } from 'store/playerSearch/reducers/playerSearchFilterReducer'
import { EventModel, ParticipantModel, AttendeeModel, InvitedClubModel, TransferInModel, TransferOutModel } from 'api/admin/events/models'



export interface ClubsTabState {
    participants: Array<ParticipantModel>
    processingParticipants: Array<number>
    currentPage: number
    recordsPerPage: number
    totalSquads: number
    isRegisterProcessing: boolean
    isClearSquadsModalOpen: boolean
    isClearSquadsProcessing: boolean
    filterByName: string

    suggestionItems: Array<SuggestionModel>
    keyword: string
    processing: boolean
    idOfSelectedClub: number
}
export interface UsersTabState {
    attendees: Array<AttendeeModel>
    processingAttendees: Array<number>
    currentPage: number
    totalAttendees: number
    recordsPerPage: number
    filterByCustomAttendee: string

    

    isModalFetching: boolean
        
    itemsOfTransfersIn: Array<TransferInModel>
    isTransfersInModalOpen: boolean
    titleOfTransfersIn: string

    itemsOfTransfersOut: Array<TransferOutModel>
    isTransfersOutModalOpen: boolean
    titleOfTransfersOut: string
    filterByPlayerName: string

    itemsOfInvitedClubs: Array<InvitedClubModel>
    isInvitedClubsModalOpen: boolean
    titleOfInvitedClubs: string
    filterBySquadName: string
}

class AdminEventsState {
    activeEvents: Array<EventModel>
    nameOfSelectedEvent: string
    idOfSelectedEvent: number
    isFetching: boolean

    clubsTab: ClubsTabState
    usersTab: UsersTabState
}

const defaultState = {
    activeEvents: [],
    nameOfSelectedEvent: '',
    idOfSelectedEvent: 0,
    isFetching: false,

    clubsTab: {
        participants: [],
        processingParticipants: [],
        currentPage: 1,
        recordsPerPage: 10,
        totalSquads: 0,
        isRegisterProcessing: false,
        filterByName: '',

        suggestionItems: [], //autosuggest
        keyword: '',
        processing: false,
        idOfSelectedClub: 0,

        isClearSquadsModalOpen: false, //clear squads modal
        isClearSquadsProcessing: false,
    },
    usersTab: {
        attendees: [],
        processingAttendees: [],
        currentPage: 1,
        totalAttendees: 0,
        recordsPerPage: 10,
        filterByCustomAttendee: 'All',
        
        
        
        isModalFetching: false,
        
        itemsOfTransfersIn: [],
        isTransfersInModalOpen: false,
        titleOfTransfersIn: '',

        itemsOfTransfersOut: [],
        isTransfersOutModalOpen: false,
        titleOfTransfersOut: '',
        filterByPlayerName: '',

        itemsOfInvitedClubs: [],
        isInvitedClubsModalOpen: false,
        titleOfInvitedClubs: '',
        filterBySquadName: '',
    },
}


const stateController = new StateController<AdminEventsState>(
    'ADMIN_V2/EVENTS',
    defaultState
)


class Actions {

    public static getData() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isFetching: true }))
                await dispatch(Actions.getActiveEvents())
                await dispatch(Actions.getParticipants())
                await dispatch(Actions.getAttendees())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }



    //================================================= Clubs tab =======================================================

    public static getParticipants(spinner?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                spinner && dispatch(stateController.setState({ isFetching: true }))

                const eventId = getState().admin.events.idOfSelectedEvent
                const pageNumber = getState().admin.events.clubsTab.currentPage
                const recordsPerPage = getState().admin.events.clubsTab.recordsPerPage
                const keyword = getState().admin.events.clubsTab.filterByName

                if(!eventId)
                {
                    spinner && dispatch(stateController.setState({ isFetching: false }));
                    return;
                }

                const data = await EventsService.getParticipants(eventId, pageNumber, recordsPerPage, keyword)
             
                const participants = data.data.eventModel.registeredSquads.map((item: ParticipantModel) => ({
                    ...item,
                    key: item.id
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        participants: participants,
                        totalSquads: data.data.count,
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                spinner && dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }
    public static registerSquad() {
        return async (dispatch, getState: () => AppState) => {
            const squadId = getState().admin.events.clubsTab.idOfSelectedClub
            const eventId = getState().admin.events.idOfSelectedEvent
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        isRegisterProcessing: true,
                    }
                })))
                await EventsService.registerSquad(squadId, eventId)
                await dispatch(Actions.getParticipants())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        isRegisterProcessing: false,
                    }
                })))
            }
        }
    }
    public static unregisterSquad(item: ParticipantModel) {
        return async (dispatch, getState: () => AppState) => {
            const eventId = getState().admin.events.idOfSelectedEvent
            const squadId = item.mainSquadId
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        processingParticipants: [
                            ...prevState.clubsTab.processingParticipants,
                            squadId
                        ]
                    }
                })))
                await EventsService.unregisterSquad(squadId, eventId)
                await dispatch(Actions.getParticipants())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        processingParticipants: [
                            ...prevState.clubsTab.processingParticipants.filter(item => item !== squadId)
                        ]
                    }
                })))
            }
        }
    }
    public static clearRegisteredSquads() {
        return async (dispatch, getState: () => AppState) => {
            const eventId = getState().admin.events.idOfSelectedEvent
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        isClearSquadsProcessing: true,
                    }
                })))
                await EventsService.clearRegisteredSquads(eventId)
                await dispatch(Actions.getParticipants())
                dispatch(Actions.clearSquadsCloseModal())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        isClearSquadsProcessing: false,
                    }
                })))
            }
        }
    }
    public static onChangeFilteredName(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubsTab: {
                    ...prevState.clubsTab,
                    filterByName: value,
                }
            })))
        }
    }
    public static onChangeRecordsPerPageClubsTab(size: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubsTab: {
                    ...prevState.clubsTab,
                    recordsPerPage: size,
                }
            })))
        }
    }
    public static onChangeCurrentPageClubsTab(page: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    isFetching: true,
                    clubsTab: {
                        ...prevState.clubsTab,
                        currentPage: page,
                    }
                })))
                await dispatch(Actions.getParticipants())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }

    //====== Select club (autosuggest) ==================================================================================

    public static selectClub(id: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubsTab: {
                    ...prevState.clubsTab,
                    idOfSelectedClub: id,
                }
            })))
        }
    }
    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            const filter = getPlayerSearchFilterCriteria(getState())
            dispatch(stateController.setState(prevState => ({ 
                ...prevState,
                clubsTab: {
                    ...prevState.clubsTab,
                    keyword: keyword,
                }
            })))
            if (keyword) {
                Actions.loadFirstSuggestionsDebounceFunc(dispatch, filter, keyword)
            } else {
                dispatch(stateController.setState(prevState => ({ 
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        suggestionItems: [],
                    }
                })))
            }
        }
    }
    public static loadSuggestions(filter: any, keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({ 
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        processing: true,
                    }
                })))
                const data = await PlayerSearchService.getPlayerAutosuggestions(0, 0, { ...filter, keyword }, true)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        suggestionItems: data,
                    }
                })))
            } catch (err) {
                console.error(err) 
            } finally {
                dispatch(stateController.setState(prevState => ({ 
                    ...prevState,
                    clubsTab: {
                        ...prevState.clubsTab,
                        processing: false,
                    }
                })))
            }
        }
    }
    private static loadFirstSuggestionsDebounceFunc = _.debounce((dispatch, filter, keyword) => dispatch(Actions.loadSuggestions(filter, keyword)), 1000)

    //====== Select event ===============================================================================================

    public static getActiveEvents() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const events = (await EventsService.getActiveEvents()).data;
                const event = events[0];
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    activeEvents: events,
                    nameOfSelectedEvent: event?.name,
                    idOfSelectedEvent: event?.id,
                })))
            } catch (err) {
                console.error(err)
            }
        }
    }
    public static selectEvent(item: {key: string, value: string, children: string}) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    nameOfSelectedEvent: item.children,
                    idOfSelectedEvent: parseInt(item.key),
                    isFetching: true,
                })))
                await dispatch(Actions.getParticipants())
                await dispatch(Actions.getAttendees())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }

    //====== Clear registered squads (Modal) ============================================================================

    public static clearSquadsOpenModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubsTab: {
                    ...prevState.clubsTab,
                    isClearSquadsModalOpen: true
                },
            })))
        }
    }
    public static clearSquadsCloseModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubsTab: {
                    ...prevState.clubsTab,
                    isClearSquadsModalOpen: false
                },
            })))
        }
    }



    //==================================================== Users tab ====================================================

    public static getAttendees() {
        return async (dispatch, getState: () => AppState) => {
            try {
                let customAttendee = null
                const filterByCustomAttendee = getState().admin.events.usersTab.filterByCustomAttendee
                if (filterByCustomAttendee === 'Yes') { 
                    customAttendee = 'true'
                }
                if (filterByCustomAttendee === 'No') {
                    customAttendee = 'false'
                }
                const searchParameters = {
                    eventId: getState().admin.events.idOfSelectedEvent,
                    customAttendee: customAttendee,
                    sortOrder: 'date',
                    isSortOrderAscending: false,
                    page: getState().admin.events.usersTab.currentPage,
                    recordsPerPage: getState().admin.events.usersTab.recordsPerPage,
                }
                
                const data = await EventsService.getAttendees(searchParameters)
                const attendees = data.data.output.map((item: AttendeeModel) => ({
                    ...item,
                    key: item.userId
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        attendees: attendees,
                        totalAttendees: data.data.rowCount,
                    }
                })))
            } catch (err) {
                console.error(err)
            }
        }
    }
    public static deleteAttendee(summitId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        processingAttendees: [
                            ...prevState.usersTab.processingAttendees,
                            summitId
                        ]
                    }
                })))
                await EventsService.deleteAttendee(summitId)
                await dispatch(Actions.getAttendees())
            } catch (err) {
                console.error(err) 
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        processingAttendees: [
                            ...prevState.usersTab.processingAttendees.filter(item => item !== summitId)
                        ]
                    }
                })))
            }
        }
    }
    public static selectCustomAttendee(value: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isFetching: true }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        filterByCustomAttendee: value,
                    }
                })))
                await dispatch(Actions.getAttendees())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }
    public static onChangeRecordsPerPage(size: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                usersTab: {
                    ...prevState.usersTab,
                    recordsPerPage: size,
                }
            })))
        }
    }
    public static onChangeCurrentPage(page: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    isFetching: true,
                    usersTab: {
                        ...prevState.usersTab,
                        currentPage: page,
                    }
                })))
                await dispatch(Actions.getAttendees())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }

    //====== Transfers In (Modal) ======================================================================================

    public static transfersInOpenModal(item: any) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        isModalFetching: true,
                        titleOfTransfersIn: item.squadName,
                        isTransfersInModalOpen: true
                    },
                })))
                const data = await EventsService.getAttendeesTransfersIn(item.summitId)
                const items = data.data.map((item: TransferInModel, index: number) => ({
                    ...item,
                    key: index,
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        itemsOfTransfersIn: items,
                    },
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        isModalFetching: false,
                    },
                })))
            }
        }
    }
    public static transfersInCloseModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                usersTab: {
                    ...prevState.usersTab,
                    isTransfersInModalOpen: false,
                    itemsOfTransfersIn: [],
                    titleOfTransfersIn: '',
                },
            })))
        }
    }

    //====== Transfers Out (Modal) ====================================================================================

    public static transfersOutOpenModal(item: any) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        isModalFetching: true,
                        titleOfTransfersOut: item.squadName,
                        isTransfersOutModalOpen: true
                    },
                })))
                const data = await EventsService.getAttendeesTransfersOut(item.summitId)
                const items = data.data.map((item: TransferOutModel, index: number) => ({
                    ...item,
                    key: index,
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        itemsOfTransfersOut: items,
                    },
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        isModalFetching: false,
                    },
                })))
            }
        }
    }
    public static transfersOutCloseModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                usersTab: {
                    ...prevState.usersTab,
                    isTransfersOutModalOpen: false,
                    itemsOfTransfersOut: [],
                    titleOfTransfersOut: '',
                },
            })))
        }
    }
    public static onChangeKeywordByPlayerName(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                usersTab: {
                    ...prevState.usersTab,
                    filterByPlayerName: keyword,
                }
            })))
        }
    }

    //====== Invited Clubs (Modal) ====================================================================================

    public static InvitedClubsOpenModal(item: any) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        isModalFetching: true,
                        titleOfInvitedClubs: item.squadName,
                        isInvitedClubsModalOpen: true,
                    },
                })))
                const data = await EventsService.getAttendeesClubInvites(item.summitId)
                const items = data.data.map((item: InvitedClubModel, index: number) => ({
                    key: index,
                    areaName: item.areaName ?? '(no data)',
                    squadName: item.squadName ?? '(no data)',
                    shortSquadName: item.shortSquadName ?? '(no data)',
                    competitionName: item.competitionName ?? '(no data)',
                    
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        itemsOfInvitedClubs: items,
                    },
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    usersTab: {
                        ...prevState.usersTab,
                        isModalFetching: false,
                    },
                })))
            }
        }
    }
    public static InvitedClubsCloseModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                usersTab: {
                    ...prevState.usersTab,
                    isInvitedClubsModalOpen: false,
                    itemsOfInvitedClubs: [],
                    titleOfInvitedClubs: '',
                },
            })))
        }
    }
    public static onChangeKeywordBySquadName(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                usersTab: {
                    ...prevState.usersTab,
                    filterBySquadName: keyword,
                }
            })))
        }
    }

}


class Selectors {

    public static isRegisterBtnDisabled(state: AppState) {
        const clubsTab = state.admin.events.clubsTab
        const eventId = state.admin.events.idOfSelectedEvent
        const isFetching = state.admin.events.isFetching
        if (clubsTab.idOfSelectedClub === 0 || eventId === 0) {
            return true
        } else if (clubsTab.participants.some(i => i.id === clubsTab.idOfSelectedClub)) {
            return true
        } else if (isFetching) {
            return true
        } else {
            return false
        }
    }
    public static filteredItemsOfInvitedClubs(state: AppState) {
        const items = state.admin.events.usersTab.itemsOfInvitedClubs
        const filterBySquadName = state.admin.events.usersTab.filterBySquadName
        return items.filter(item => (item.squadName && item.squadName.toLowerCase() || '').includes(filterBySquadName.toLowerCase()))
    }
    public static filteredItemsOfTransfersOut(state: AppState) {
        const items = state.admin.events.usersTab.itemsOfTransfersOut
        const filterByPlayerName = state.admin.events.usersTab.filterByPlayerName
        return items.filter(item => (item.playerName && item.playerName.toLowerCase() || '').includes(filterByPlayerName.toLowerCase()))
    }
}


const reducer = stateController.getReducer()


export {
    reducer as Reducer,
    AdminEventsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}