import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import Troels from 'media/images/events/madrid/troels.png';
import Paul from 'media/images/events/madrid/paul.png';
import Rasmus from 'media/images/events/madrid/rasmus-2.png';
import Replay from 'app/events/components/authorized-event-page/common/video-replay-new/replay'
import Thumb from 'app/events/components/authorized-event-page/common/video/video-thumb.png';

const MadridSummit21_22ndMarch = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper" >
                    <EventTitle
                        number="6"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom Summit"
                        title2=""
                        date="21st-22nd Mar 2022, Madrid, Spain"
                        vs={true}
                    />
                    <SpeakersSection >
                        <Replay
                            compactMode
                            trackUserActivity={() => {
                                // this.props.userActivityInsert({
                                //     PageName: 'Event',
                                //     Message: 'Opened video (6th TransferRoom Summit)',
                                //     EventId: eventId
                                // })
                            }}
                            content={{
                                videoUrl: 'https://player.vimeo.com/video/696040095',
                                backgroundThumb: Thumb,
                                heading1: '6th TransferRoom Summit',
                                heading2: 'Madrid 21st-22nd March 2022',
                                highlightsText: 'Highlights 01:43',
                                speakers: [
                                    { imgSrc: Troels, name: 'Troels Bech', title: 'Host and', organization: 'Moderator' },
                                    { imgSrc: Paul, name: 'Paul Mitchell', title: 'Sporting Director', organization: 'AS Monaco' },
                                    { imgSrc: Rasmus, name: 'Rasmus Ankersen', title: 'CEO and Co-Founder', organization: 'Sport Republic' },
                                ]
                            }}
                        />
                        {/* <h2>Speakers</h2>
                        <div className="speaker-card-container">
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${Troels}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Troels Bech</div>
                                    <div className="speaker-info__position">Host and Moderator</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${Paul}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Paul Mitchell</div>
                                    <div className="speaker-info__position">Sporting Director AS Monaco</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${Rasmus}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Rasmus Ankersen</div>
                                    <div className="speaker-info__position"><span style={{ whiteSpace: 'nowrap' }}>CEO and Co-Founder</span> Sport Republic</div>
                                </div>
                            </div>
                        </div> */}
                    </SpeakersSection>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                {/* <div className="col-md-12 col-sm-6 col-12" style={{height: "148px"}}>
                </div> */}
                <div className="col-12">
                    <PastEventStats
                        participants='300'
                        countries='45'
                        leagues='60'
                        agents='25'
                    />
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={36}
                    />
                </div>
            </div>
        </div>
    )
}

const SpeakersSection = styled.div`
    h2 {
        font-size: 21px;
        line-height: 34px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .speaker-card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .speaker-card {
            width: 128px;
            margin-bottom: 10px;

            .speaker-image {
                display: flex;
                justify-content: center;
        
                & > div{
                    width: 128px;
                    height: 128px;
                    border-radius: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        
            .speaker-info {
                margin-top: 15px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
        
                &__name {
                    font-weight: bold;
                }
        
                &__position {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

        }
    }
`;

export default MadridSummit21_22ndMarch

export const previousEventAttendees = [
    { id: 1, name: 'AC Milan', logo: 'logo_1834_AC_Milan.png' },
    { id: '2', name: 'AS Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '16', name: 'SSC Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '67', name: 'West Ham United', logo: '1633_West_Ham_United.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '6249', name: 'Bayer Leverkusen', logo: 'logo_1260_Bayer_04_Leverkusen.png' },
    { id: '8239', name: 'Brentford FC', logo: 'logo_1153_Brentford_FC.png' },
    { id: '8640', name: 'SC Freiburg', logo: 'logo_SC_Freiburg.png' },
    { id: '10088', name: 'RB Leipzig', logo: 'logo_1261_RB_Leipzig.png' },
    { id: '12294', name: 'Olympique Marseille', logo: 'logo_1964_Olympique_Marseille.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '28998', name: 'Real Betis Balompié', logo: '684_Real_Betis_Balompié.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '32429', name: 'Eintracht Frankfurt', logo: 'logo_1268_Eintracht_Frankfurt.png' },
    { id: '33906', name: 'FC Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '38580', name: 'Valencia CF', logo: 'logo_1715_Valencia_CF.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '16247', name: 'Club Athletico Paranaense', logo: '5651_Club_Athletico_Paranaense.png' },
    { id: '39981', name: 'Clube Atlético Mineiro', logo: '5653_Atlético-MG.png' },
    { id: '7845', name: 'CF Pachuca', logo: 'logo_2276_CF_Pachuca.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: 'logo_2101_Los_Angeles_Galaxy.png' },
    { id: '10317', name: 'New York Red Bulls', logo: 'logo_2112_New_York_Red_Bulls.png' },
    { id: '31484', name: 'Deportivo Guadalajara', logo: '15409_Deportivo_Guadalajara.png' },
    { id: '23647', name: 'Atlético de San Luis', logo: '25866_Atlético_de_San_Luis.png' },
    { id: '26367', name: 'Santos Laguna', logo: 'logo_2268_Santos_Laguna.png' },
    { id: '26540', name: 'Orlando City SC', logo: '8068_Orlando_City_SC.png' },
    { id: '32865', name: 'Portland Timbers', logo: '8113_Portland_Timbers.png' },
]