export const LANDING_PAGE_LOADING = 'app/landing-page/LANDING_PAGE_LOADING';

export class CommonReducerTypes {
    isLoading: boolean
}

const initialState: CommonReducerTypes = {
    isLoading: false
}

const commonReducer = (state = initialState, { type, payload }) => {
    switch(type) {
        case LANDING_PAGE_LOADING: {
            return {
                ...state,
                isLoading: payload
            }
        }
        default: {
            return state
        }
    }
}

export default commonReducer