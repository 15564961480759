import { StateController } from 'utils/action-declaration';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'

import { ClubConnection, LeagueConnection } from 'api/agency/agent/landing/agency-dashboard'
class State {
    isLoading: boolean
    clubs: Array<ClubConnection>
    leagues: Array<LeagueConnection>
}

const defaultState: State = {
    clubs: [],
    leagues: [],
    isLoading: false,
}

const stateController = new StateController<State>(
    "AGENCY/LANDING_PAGE/CONNECTIONS",
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static load() {
        return async (dispatch) => {

            dispatch(stateController.setState({ isLoading: true }))

            const data = await AgencyHomePageService.getClubConnections()

            dispatch(stateController.setState({
                isLoading: false,
                clubs: data.clubs,
                leagues: data.leagues,
            }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};
