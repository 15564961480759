import { PageType, ActionType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PlayerAdTypeEnumModel, PlayerAdModel } from 'api/agency/agent/landing/agency-dashboard'
import { GridToggleState } from './grid.controller';

export class PitchActivityService {
    private static page: string = 'Search [Pitch opportunities]';

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, actionType?: ActionType) {

        const currentUrl = window.location.href;
        const isProfile = currentUrl.includes('/profile/');
        const currentPage = isProfile ? 'Player Profile [Pitch opportunities]': 'Search [Pitch opportunities]'
        const currentPageType = isProfile ? PageType.PlayerProfile : PageType.Search;
        
        return {
            Message: action,
            PageType: currentPageType,
            PlayerId: playerId,
            ClubId: clubId,
            PageName: `${currentPage}${container ? ` [${container}]` : ''}`,
            ActionType: actionType,
        }
    }

    public static notInterest(playerId: number, positionCode: string, ads: PlayerAdModel, fee: number, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PitchActivityService.getStatePage(gridState)
            const message = `Dismissed: ${PitchActivityService.getPitchMessage(positionCode, ads, fee)}`
            const command = this.buildCommand(state, message, playerId, clubId)

            dispatch(userActivityInsert(command))
        }
    }

    public static sentPitch(playerId: number, positionCode: string, ads: PlayerAdModel, fee: number, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PitchActivityService.getStatePage(gridState)
            const message = `Pitched player: ${PitchActivityService.getPitchMessage(positionCode, ads, fee)}`
            const command = this.buildCommand(state, message, playerId, clubId)

            dispatch(userActivityInsert(command))
        }
    }
    public static makePitch(playerId: number, positionCode: string, ads: PlayerAdModel, fee: number, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PitchActivityService.getStatePage(gridState)
            const message = `Make pitch: ${PitchActivityService.getPitchMessage(positionCode, ads, fee)}`
            const command = this.buildCommand(state, message, playerId, clubId)

            dispatch(userActivityInsert(command))
        }
    }

    public static cancelledPitch(playerId: number, positionCode: string, ads: PlayerAdModel, fee: number, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PitchActivityService.getStatePage(gridState)
            const message = `Cancelled pitch: ${PitchActivityService.getPitchMessage(positionCode, ads, fee)}`
            const command = this.buildCommand(state, message, playerId, clubId)

            dispatch(userActivityInsert(command))
        }
    }

    public static done(playerId: number, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PitchActivityService.getStatePage(gridState)
            const message = 'Done'
            const command = this.buildCommand(state, message, playerId, clubId)

            dispatch(userActivityInsert(command))
        }
    }


    public static getPitchMessage(positionCode: string, ads: PlayerAdModel, fee: string | number) {
        fee = fee || 0;
        return `${ads.createdBySquad.name}, ${positionCode}, ${ads.type == PlayerAdTypeEnumModel.Buy ? 'To Buy' : 'To Loan'}, ${ads.currency.sign}${fee || 0}${ads.type == PlayerAdTypeEnumModel.Buy ? '/yr' : '/mo'}`
    }

    private static getStatePage(state: GridToggleState) {
        let action = ''
        if (state === GridToggleState.ShortList) { action = 'Shortlist' }
        return action;
    }
}
