const getKeyName = space => `@${space}/pagesize`;

export function setPageSize(space: string, data: number) {
    window.localStorage.setItem(getKeyName(space), JSON.stringify(data));
}

export function getPageSize(space, defaultValue: number): number {
    return (
        parseInt(window.localStorage.getItem(getKeyName(space))) || defaultValue
    );
}

// function PagingStore(space) {
//     if (!space) {
//         throw new Error('Param `space` is required');
//     }

//     const keyName = `@${space}/pagesize`;

//     function setPageSize(data: number) {
//         window.localStorage.setItem(keyName, JSON.stringify(data));
//     }

//     function getPageSize(defaultValue: number) {
//         const value = window.localStorage.getItem(keyName);

//         try {
//             return JSON.parse(value) || defaultValue;
//         } catch {
//             return value || defaultValue;
//         }
//     }

//     return Object.freeze({ getPageSize, setPageSize });
// }

// export default PagingStore;

