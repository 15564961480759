import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import PlayerMarketBannerService from 'api/agency/agent/landing/player-market-banner.service';
import { HUBSPOT_PLAYER_MARKET_URL } from 'pages/PlayerSearch-v3/agency/constants/common-urls';
import { Selectors as FilterSelectors } from 'pages/PlayerSearch-v3/agency/redux/filter.controller';
import { SearchMode } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';

export enum PlayerMarketModalStepEnum {
    Default = 1,
    Done = 2,
}

class PlayerMarketModalState {
    isModalOpen: boolean;
    isProcessing: boolean;
    step: number;
}

const defaultState: PlayerMarketModalState = {
    isModalOpen: false,
    isProcessing: false,
    step: PlayerMarketModalStepEnum.Default
}

const stateController = new StateController<PlayerMarketModalState>(
    'AGENCY_SEARCH/PLAYER_MARKET_MODAL',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static openPlayerMarketModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                isModalOpen: true
            }));
        }
    }

    public static closePlayerMarketModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                isModalOpen: false,
                step: PlayerMarketModalStepEnum.Default
            }));

            dispatch(userActivityInsert({
                PageName: 'Player Search [Player Market Pop-up]',
                Message: 'Clicked Close Success Pop-up',
                PageType: PageType.Search,
            }));
        }
    }

    public static onDeclinePlayerMarketModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                isModalOpen: false,
                step: PlayerMarketModalStepEnum.Default
            }));

            dispatch(userActivityInsert({
                PageName: 'Player Search [Player Market Pop-up]',
                Message: 'Clicked No Thanks',
                PageType: PageType.Search,
            }));
        }
    }

    public static interestedPlayerMarketModal() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({
                    isProcessing: true
                }));
                const searchMode = FilterSelectors.getRoot(getState()).searchMode;

                await PlayerMarketBannerService.interest();


                // if (searchMode === SearchMode.Default) {
                //     dispatch(userActivityInsert({
                //         PageName: 'Search [Player Market Restriction]',
                //         Message: 'Interested',
                //         PageType: PageType.Search,
                //     }));
                // }
                // if (searchMode === SearchMode.Filtered) {
                //     dispatch(userActivityInsert({
                //         PageName: 'Player Search [Player Market Pop-up]',
                //         Message: `Clicked I'm Interested`,
                //         PageType: PageType.Search,
                //     }));
                // }
                dispatch(userActivityInsert({
                    PageName: 'Player Search [Player Market Pop-up]',
                    Message: `Clicked I'm Interested`,
                    PageType: PageType.Search,
                }));

                dispatch(Actions.goToStep(PlayerMarketModalStepEnum.Done));

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({
                    isProcessing: false
                }));
            }
        }
    }

    public static goToStep(step: PlayerMarketModalStepEnum) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                step
            }));
        }
    }

    public static onTellMeMoreClick(){
        return (dispatch, getState: () => AppState) => {

            dispatch(userActivityInsert({
                PageName: 'Player Search [Player Market Pop-up]',
                Message: 'Clicked Tell Me More',
                PageType: PageType.Search,
            }));

            window.open(HUBSPOT_PLAYER_MARKET_URL, '_blank');
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState): PlayerMarketModalState => state.agencySearch.playerMarketModal;
    public static isModalOpen = (state: AppState) => Selectors.getRoot(state).isModalOpen;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerMarketModalState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};
