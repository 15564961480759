import axios from 'axios-config'
import { AgencySuggestionsResponse } from './models'

export class AgencySearchFilterSuggestionService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getAgencySearchFilterSuggestion(keyword: string): Promise<AgencySuggestionsResponse>  {
        const { data } = await axios.post(
            `api/Suggestion/GetAgencySearchFilterSuggestion`,
            { keyword: keyword },
            this.axiosConfig
        )
        return data
    }
}