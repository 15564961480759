import {AppState} from "root.reducer";
import historyAccessor from "history-accessor";
import {coachPaths} from "routes/paths";
import { getCoachUserPermissions } from "store/userPreference/userPreference.reducer";

class Actions {
    public static checkIfRedirectNeeded(redirectToProfile: boolean = false) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { isAgencyConfirmationNeeded } = getCoachUserPermissions(state);
            if(isAgencyConfirmationNeeded){
                historyAccessor.push(coachPaths.agencyConfirm);
                return;
            }

            if(redirectToProfile)
                historyAccessor.push(coachPaths.myProfile);
        }
    }
}

export {
    Actions as Actions
}