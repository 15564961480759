import { StateController } from 'utils/action-declaration';
import { PlayerService } from 'api/player/player-service'
import { PlayerPointsService } from 'api/player/player-points'
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { TopPanelModel } from 'api/player/model/player-point'
import history from 'history-accessor'
import { getAuth } from 'store/auth/authReducer';
import { createSelector } from 'reselect';
import AuthState from 'store/auth/auth-state';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
export class PlayerPointsState {
    isLoading: boolean;
    gbeInfo?: TopPanelModel;
    isDeclareInterestProcessing: boolean;
    isDeclareInterestToAgentProcessing: boolean;
}

const defaultState: PlayerPointsState = {
    isLoading: false,
    isDeclareInterestProcessing: false,
    isDeclareInterestToAgentProcessing: false,
    gbeInfo: {
        transferWindow: '',
        nationalTeam: '',
        u21: false,
        u21CutOff: '',
        faPointTotalPts: 0,
        faPointResult: '-',
        player: {
            shortName: '',
            parentSquadName: '',
            imageFileName: '',
            birthDate: '',
            currentSquadName: '',
            nationalSquad: {
                name: '',
                id: -1
            },
            id: -1,
            interestWasDeclared: false,
            parentSquadId: null,
            isParentSquadUseTranserRoom: false
        },
    }
}

const stateController = new StateController<PlayerPointsState>(
    'FA_PLAYER_POINTS/PLAYER',
    defaultState
)

class Actions {
    public static init(id: number) {
        return async dispatch => {
            if(!id){
                return;
            }

            dispatch(stateController.setState({ isLoading: true }));

            const gbeInfo: TopPanelModel = await PlayerPointsService.getPlayerPoint(id);

            if (gbeInfo.player) {
                dispatch(userActivityInsert({
                    PageName: 'GBE Calculator',
                    Message: 'Opened Player Profile',
                    ClubId: gbeInfo.player.parentSquadId,
                    PlayerId: gbeInfo.player.id,
                    AgencyId: gbeInfo.player.agencyId,
                    PageType: PageType.GBE_Calculator
                }))
            }

            dispatch(stateController.setState({ isLoading: false, gbeInfo }));
        }
    }

    public static initDefaultPlayer() {
        return async dispatch => {
            dispatch(stateController.setState({ isLoading: true }))
            const defaultPlayerId = await PlayerPointsService.getDefaultPlayerId()
            history.replace(`/player-points/${defaultPlayerId}`)
            dispatch(Actions.init(defaultPlayerId))
        }
    }

    public static declareInterestToClub() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isDeclareInterestProcessing: true }))
                const player = Selectors.getPlayer(getState())
                const auth = getAuth(getState())
    
                await PlayerService.declareInterestSave(player.id, auth.squadId, auth.userId);
                await PlayerService.addToShortList(player.id);
    
                dispatch(userActivityInsert({
                    PageName: 'GBE Calculator',
                    Message: 'Declared Interest to Club',
                    ClubId: player.parentSquadId,
                    PlayerId: player.id,
                    ActionType: ActionType.DeclaredInterest,
                    PageType: PageType.GBE_Calculator,
                    UserActivityType: UserActivityType.Action
                }))
    
                dispatch(stateController.setState(prevState => ({
                    isDeclareInterestProcessing: false,
                    gbeInfo: {
                        ...prevState.gbeInfo,
                        player: {
                            ...prevState.gbeInfo.player,
                            interestWasDeclared: true
                        }
                    }
                })))
            } catch (error) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    isDeclareInterestProcessing: false,
                })))
            }
        }
    }

    public static declareInterestToAgent() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isDeclareInterestToAgentProcessing: true }))
                const player = Selectors.getPlayer(getState())
    
                await PlayerService.declareInterestToAgent(player.id);
                await PlayerService.addToShortList(player.id);
    
    
                dispatch(userActivityInsert({
                    PageName: 'GBE Calculator',
                    Message: 'Declared Interest to Agent',
                    AgencyId: player.agencyId,
                    PlayerId: player.id,
                    ActionType: ActionType.DeclaredInterest,
                    PageType: PageType.GBE_Calculator,
                    UserActivityType: UserActivityType.Action
                }))
    
                dispatch(stateController.setState(prevState => ({
                    isDeclareInterestToAgentProcessing: false,
                    gbeInfo: {
                        ...prevState.gbeInfo,
                        player: {
                            ...prevState.gbeInfo.player,
                            interestWasDeclaredToAgent: true,
                        }
                    }
                })))
            } catch (error) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    isDeclareInterestToAgentProcessing: false,
                })))
            }
     
        }
    }

    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static sendPermissionRequest() {
        return async (dispatch, getState: () => AppState) => {
            const { squadId, userId } = getState().auth
            await PlayerPointsService.sendPermissionRequest(squadId, userId)
            dispatch(userActivityInsert({
                PageName: 'GBE Calculator [Sample]',
                Message: `Upgrade banner - clicked 'more'`,
                PageType: PageType.GBE_Calculator
            }))
        }
    }
}

class Selectors {
    public static getPlayerPoints = (state: AppState) => state.playerPoints.player;
    public static getPlayerPanel = (state: AppState): TopPanelModel => Selectors.getPlayerPoints(state).gbeInfo;
    public static isLoading = (state: AppState) => Selectors.getPlayerPoints(state).isLoading;
    public static isDeclareInterestProcessing = (state: AppState) => Selectors.getPlayerPoints(state).isDeclareInterestProcessing;
    public static isDeclareInterestToAgentProcessing = (state: AppState) => Selectors.getPlayerPoints(state).isDeclareInterestToAgentProcessing;

    public static getPlayer  = (state: AppState): any => Selectors.getPlayerPanel(state).player || {};
    public static getPlayerId = (state: AppState) => Selectors.getPlayer(state).id;

    public static isPlayerFromCurrentSquad = createSelector([Selectors.getPlayerPanel, getAuth], (gbeInfo: TopPanelModel, auth: AuthState) => {
        const currentClubId = auth.clubId;
        const currentSquadId = auth.squadId;
        const player = gbeInfo.player
        
        if (player === null) return false;

        if(player.parentSquad === null && player.latestSquad === null){
            return false;
        }

        let playerCurrentSquadId = player.parentSquad ? player.parentSquad.id : player.latestSquad.id;
        let playerCurrentClubId = player.parentSquad ? player.parentSquad.clubId : player.latestSquad.clubId;

        if (
            playerCurrentClubId !== null &&
            playerCurrentClubId === currentClubId
        )
            return true;
        if (playerCurrentClubId === null)
            return playerCurrentSquadId === currentSquadId;

        return false;
    })
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerPointsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};