const size = {
	mobileS: '480px',
	mobileM: '576px',
	tabletS: '768px',
	tabletM: '992px',
	desktop: '1024px'
};
export const maxDevice = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	tabletS: `(max-width: ${size.tabletS})`,
	tabletM: `(max-width: ${size.tabletM})`,
	desktop: `(max-width: ${size.desktop})`,
};
export const minDevice = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	tabletS: `(min-width: ${size.tabletS})`,
	tabletM: `(min-width: ${size.tabletM})`,
	desktop: `(min-width: ${size.desktop})`,
};