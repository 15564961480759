import config from 'config'
import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { isAgencyUser, isPlayerUser, isClubUser } from "store/auth/authReducer";
import AgencyPublicProfileService from 'api/agency/agency-profile/agency-profile.service';
import { AgencyPublicProfileResponse, AgencyPublicProfileShortInfo } from 'api/agency/agency-profile/models';
import { IMessageSendSpecificaion, MessageSendSession, Actions as MessageModalController } from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller';
import { MessageType, NewMessage } from 'api/messaging/models/message';
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { ShortlistService } from 'api/shortlist/shortlist-service';
import { PlayerService } from 'api/player/player-service'
import { AgecnyAccessService } from 'api/agency/club/agency-access/agency-access.service';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { MessagingService } from 'api/messaging/messaging.service';
import { getAuth } from 'store/auth/authReducer';
import { isValid } from 'utils/validation';
import { HubspotFormService } from 'api/hubspot-form/hubspot-form.service';
import historyAccessor from "../../../history-accessor";
import { agencyPaths } from "../../../routes/paths";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType } from "../../../constants/enums";

export type FormData = {
    name: string;
    email: string;
    jobTitle: string;
    phoneCodeAreaId: any;
    phoneNumberInput: string;
    message: string;
    isPolicyChecked: boolean;
}

export interface GetInTouchModalState {
    isModalOpen: boolean;
    agencyName?: string;
    isLoadingResponse: boolean;
    isRequestSent: boolean;
    phoneNumber: string;
    isFormValid: boolean;
    formData: FormData;
}

export interface SendMessageModalState {
    newMessageAvailability?: {
        availableCount?: number;
        lockedTill?: any;
    };
    restrictedAvailableCountModal: boolean;
    restrictedMessageModal: boolean;
}

export type ClaimThisProfileFormData = {
    name: string;
    email: string;
    jobTitle: string;
    phoneCodeAreaId: any;
    phoneNumberInput: string;
    numberOfPlayers: string;
    isPolicyChecked: boolean
}

export interface ClaimThisProfileModalState {
    isModalOpen: boolean;
    agencyName: string;
    isLoadingResponse: boolean;
    isRequestSent: boolean;
    phoneNumber: string;
    isFormValid: boolean;
    formData: ClaimThisProfileFormData;
}

class State {
    isLoading: boolean;
    isUserClub: boolean;
    isUserAgency: boolean;
    isUserPlayer: boolean;
    isPitchAccessGranted: boolean;
    isShareModalOpen: boolean;
    activeSpinnerNumber: number;
    profile: AgencyPublicProfileResponse;
    declareInterestProcessingIds: number[];
    getInTouchModal: GetInTouchModalState;
    sendMessageModalState: SendMessageModalState;
    claimThisProfileModal: ClaimThisProfileModalState;
}

const defaultState: State = {
    isLoading: false,
    isUserClub: false,
    isUserAgency: false,
    isUserPlayer: false,
    isShareModalOpen: false,
    activeSpinnerNumber: 0,
    profile: null,
    isPitchAccessGranted: null,
    declareInterestProcessingIds: [],
    getInTouchModal: {
        isModalOpen: false,
        agencyName: '',
        isLoadingResponse: false,
        isRequestSent: false,
        phoneNumber: '',
        isFormValid: false,
        formData: {
            name: '',
            email: '',
            jobTitle: '',
            phoneCodeAreaId: null,
            phoneNumberInput: null,
            message: '',
            isPolicyChecked: false,
        }
    },
    sendMessageModalState: {
        newMessageAvailability: null,
        restrictedAvailableCountModal: false,
        restrictedMessageModal: false
    },
    claimThisProfileModal: {
        isModalOpen: false,
        agencyName: '',
        isLoadingResponse: false,
        isRequestSent: false,
        phoneNumber: '',
        isFormValid: false,
        formData: {
            name: '',
            email: '',
            jobTitle: '',
            phoneCodeAreaId: null,
            phoneNumberInput: null,
            numberOfPlayers: '',
            isPolicyChecked: false,
        }
    },
}

const stateController = new StateController<State>("AGENCY_PROFILE/AGENCY_PROFILE_V2", defaultState);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static init(agencyName: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const isUserClub = isClubUser(getState());
                const isUserAgency = isAgencyUser(getState());
                const isUserPlayer = isPlayerUser(getState());

                dispatch(stateController.setState({
                    isUserClub: isUserClub,
                    isUserAgency: isUserAgency,
                    isUserPlayer: isUserPlayer
                }));
                await dispatch(Actions.checkNewMessageLimit());
                await dispatch(Actions.getAgencyProfile(agencyName));
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getAgencyProfile(agencyName: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                // dispatch(stateController.setState({ isLoading: true }));
                const agencyProfile = await AgencyPublicProfileService.getAgencyPublicProfile(agencyName);
                dispatch(stateController.setState({
                    profile: agencyProfile,
                    isPitchAccessGranted: agencyProfile.accessGranted
                }));
            } finally {
                // dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static shortlistToggle(playerId: number, isInShortlist: boolean, agencyId?: number, squadId?: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { profile } = Selectors.getRoot(getState());
                const newPlayers = profile.players.map(item => {
                    if (item.id === playerId) {
                        return { ...item, isShortlisted: !isInShortlist }
                    }
                    return item;
                });

                dispatch(stateController.setState({ profile: { ...profile, players: newPlayers } }))

                if (!isInShortlist) {
                    await ShortlistService.addToShortlist(playerId);
                    dispatch(userActivityInsert({
                        Message: 'Shortlisted Player',
                        PageName: 'Agency Profile [Profile Representation]',
                        AgencyId: profile.agencyId,
                        PlayerId: playerId,
                        ActionType: ActionType.AddToShortlist,
                    }));
                } else {
                    await ShortlistService.removeFromShortlist(playerId);
                    dispatch(userActivityInsert({
                        Message: 'Removed Player from Shortlist',
                        PageName: 'Agency Profile [Profile Representation]',
                        AgencyId: profile.agencyId,
                        PlayerId: playerId,
                        ActionType: ActionType.AddToShortlist,
                    }));
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static declareInterest(playerId: number, agencyId?: number, squadId?: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { profile } = Selectors.getRoot(getState());
                const player = Selectors.getRoot(getState()).profile.players.find(item => item.id === playerId)
                const isPlayerInShortlist = player.isShortlisted;

                if (!isPlayerInShortlist) {
                    dispatch(Actions.shortlistToggle(playerId, isPlayerInShortlist, agencyId, squadId))
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    declareInterestProcessingIds: [...prevState.declareInterestProcessingIds, playerId]
                })))
                await PlayerService.declareInterestToAgent(playerId);

                dispatch(stateController.setState(prevState => {
                    const players = prevState.profile.players.map(item => {
                        if (item.id === playerId) {
                            return { ...item, isInterestWasDeclared: true }
                        }
                        return item;
                    });
                    return {
                        ...prevState,
                        profile: {
                            ...prevState.profile,
                            players
                        },
                        declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== playerId)
                    }
                }));

                dispatch(userActivityInsert({
                    Message: 'Declared Interest to Agent',
                    PageName: 'Agency Profile [Profile Representation]',
                    AgencyId: profile.agencyId,
                    PlayerId: playerId,
                    ActionType: ActionType.DeclaredInterest,
                }));

            } catch (e) {
                console.error(e)
            }
        }
    }

    public static givePitchAccess(externalAgencyId: number) {

        return async (dispatch, getState: () => AppState) => {
            if (Selectors.getRoot(getState()).isPitchAccessGranted === true) {
                return;
            }
            try {
                dispatch(stateController.setState({ activeSpinnerNumber: 1 }));
                await AgecnyAccessService.giveAccess(externalAgencyId);
                dispatch(userActivityInsert({
                    Message: 'Granted Pitch Access',
                    PageName: 'Agency Profile',
                    AgencyId: externalAgencyId,
                }));
                dispatch(stateController.setState({ isPitchAccessGranted: true }));
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(stateController.setState({ activeSpinnerNumber: 0 }))
            }
        }
    }

    public static revokePitchAccess(externalAgencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            if (Selectors.getRoot(getState()).isPitchAccessGranted === false) {
                return;
            }
            try {
                dispatch(stateController.setState({ activeSpinnerNumber: 2 }));
                await AgecnyAccessService.revokeAccess(externalAgencyId);
                dispatch(userActivityInsert({
                    Message: 'Not Granted Pitch Access',
                    PageName: 'Agency Profile',
                    AgencyId: externalAgencyId,
                }));
                dispatch(stateController.setState({ isPitchAccessGranted: false }));
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(stateController.setState({ activeSpinnerNumber: 0 }))
            }
        }
    }

    public static openShareModal() {
        return (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            dispatch(stateController.setState({ isShareModalOpen: true }));
            dispatch(userActivityInsert({
                Message: 'Opened Profile Sharing',
                PageName: 'Agency Profile',
                AgencyId: profile.agencyId,
            }));
        }
    }

    public static closeShareModal() {
        return (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            dispatch(stateController.setState({ isShareModalOpen: false }));
            dispatch(userActivityInsert({
                Message: 'Closed Sharing',
                PageName: 'Agency Profile',
                AgencyId: profile.agencyId,
            }));
        }
    }

    public static copyLink(url: string) {
        return (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            // const convertedUrl = new URL(url);
            copy(url);
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }));
            dispatch(userActivityInsert({
                Message: 'Copied Profile Link',
                PageName: 'Agency Profile',
                AgencyId: profile.agencyId,
            }));
        }
    }

    public static getInTouchWindowOpen(agency?: AgencyPublicProfileShortInfo) {
        return (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            const agencyName = agency?.agencyName ?? profile?.agencyName;
            const agencyId = agency?.agencyId ?? profile?.agencyId;
            const message = `Opened Get in Touch with ${agencyName}`;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: {
                    ...prevState.getInTouchModal,
                    isModalOpen: true
                }
            })))
        }
    }

    public static toggleGetInTouchModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: {
                    ...prevState.getInTouchModal,
                    isModalOpen: false,
                }
            })))
        }
    }

    public static disposeForm() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: defaultState.getInTouchModal
            })))
        }
    }

    public static onFormDataChange(formData: { [K in keyof FormData]?: FormData[K] }) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: {
                    ...prevState.getInTouchModal,
                    formData: {
                        ...prevState.getInTouchModal.formData,
                        ...formData,
                    },
                }
            })))
            dispatch(Actions.setPhoneNumber());
            dispatch(Actions.validateForm())
        };
    }

    public static validateForm() {
        return (dispatch, getState: () => AppState) => {
            const { name, email, phoneNumberInput, jobTitle, message, isPolicyChecked } = Selectors.getRoot(getState()).getInTouchModal.formData;
            const { phoneNumber } = Selectors.getRoot(getState()).getInTouchModal;

            const isValidName = name && isValid.name(name ?? '');
            const isValidEmail = email && isValid.email(email ?? '');
            const isValidPhone = phoneNumber && phoneNumberInput && isValid.phone(phoneNumber ?? '');


            const isFormValid = isValidName && isValidEmail && isValidPhone && jobTitle && message && phoneNumber && isPolicyChecked;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: {
                    ...prevState.getInTouchModal,
                    isFormValid: isFormValid,
                }
            })))
        };
    }

    public static setPhoneNumber() {
        return (dispatch, getState: () => AppState) => {
            const { phoneCodeAreaId, phoneNumberInput } = Selectors.getRoot(getState()).getInTouchModal.formData;
            // TODO: move phoneCodes to new state from entities
            // @ts-ignore
            const phoneCodes = getState().entities.phoneCodes.phoneCodes;
            const phoneCodeArea = phoneCodes.find(x => x.id === phoneCodeAreaId);
            const phoneNumber = `+${(phoneCodeArea?.phoneCode ?? '')} ${(phoneNumberInput ?? '')}`;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: {
                    ...prevState.getInTouchModal,
                    phoneNumber: phoneNumber,
                }
            })))
        };
    }

    public static submitGetInTouchHubspotForm(name: string, email: string, jobTitle: string, message: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                getInTouchModal: {
                    ...prevState.getInTouchModal,
                    isLoadingResponse: true,
                }
            })))
            try {
                const { agencyName, phoneNumber } = Selectors.getRoot(getState()).getInTouchModal;
                const portalId = config.hubspotForms.portalId;
                const formId = config.hubspotForms.getInTouchFormId;

                const fields = [
                    {
                        name: 'name',
                        value: name,
                    },
                    {
                        name: 'email',
                        value: email,
                    },
                    {
                        name: 'phone',
                        value: phoneNumber,
                    },
                    {
                        name: 'jobtitle',
                        value: jobTitle,
                    },
                    {
                        name: 'agency_profile___get_in_touch',
                        value: message,
                    },
                    {
                        name: 'agency_profile___agency_name',
                        value: agencyName,
                    },
                ]

                await HubspotFormService.sendDataToHubspotForm(fields, portalId, formId)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    getInTouchModal: {
                        ...prevState.getInTouchModal,
                        isRequestSent: true,
                    }
                })))
            }
            finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    getInTouchModal: {
                        ...prevState.getInTouchModal,
                        isLoadingResponse: false,
                    }
                })))
            }
        }
    }

    public static openPlayerProfile(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const { agencyProfileV2, auth } = getState();
            const { profile } = agencyProfileV2;
            dispatch(userActivityInsert({
                Message: 'Opened Player Profile',
                PageName: 'Agency Profile [Profile Representation]',
                AgencyId: profile.agencyId,
                PlayerId: playerId
            }));
        }
    }

    public static openXTVPlayerProfile(playerId: number, playerName: string, sectionName: string) {
        return (dispatch, getState: () => AppState) => {
            const { agencyProfileV2 } = getState();
            const { profile } = agencyProfileV2;
            dispatch(userActivityInsert({
                Message: 'Opened xTV profile',
                PageName: `Agency Profile [Profile ${sectionName}]`,
                AgencyId: profile.agencyId,
                PlayerId: playerId
            }));

            window.open(`${config.profileWebAppUrl}/xtv-profile/${playerId}/${playerName}`, '_blank');
        }
    }

    public static openPlayerProfileTrackRecord(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const { agencyProfileV2, auth } = getState();
            const { profile } = agencyProfileV2;
            dispatch(userActivityInsert({
                Message: 'Opened Player Profile',
                PageName: 'Agency Profile [Profile Track Record]',
                AgencyId: profile.agencyId,
                PlayerId: playerId
            }));
        }
    }

    public static editProfile() {
        return async (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            dispatch(userActivityInsert({
                Message: 'Clicked Edit Profile',
                PageName: 'Agency Profile',
                AgencyId: profile.agencyId,
            }));

            // window.open(`${config.baseUrlForWebApp}/public-profile`, '_self')
            historyAccessor.push(`${agencyPaths.publicProfile}`);
        }
    }

    //====== Message Modal ==============================================================================================

    public static openMessageModal(agencyId?: number, agentName?: string) {
        return (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            let messageSession: MessageSendSession = {
                toClub: false,
                toAgency: true,
                subject: `New Message`,
                mesageTypeId: MessageType.ClubAgencyChatMessage,
                agencyId: profile.agencyId,
                agencyCountry: profile.primaryMarketArea.name,
                agencyName: profile.agencyName,
                isTrustedAgent: profile.isTrusted,
                recipientId: profile.agencyId,
                recipientType: ReceiverType.Agency
            }

            if (!Selectors.isPermitedToSendMessages(getState())) {
                dispatch(Actions.toggleRestrictedMessageModal());
                return;
            }

            if (Selectors.isNewMessageLimitRichedOutOrHide(getState())) {
                dispatch(Actions.toggleRestrictedAvailableModal());
                dispatch(userActivityInsert({
                    Message: 'Exceeded Message Limit',
                    PageName: 'Agency Profile [Message]',
                    AgencyId: profile.agencyId,
                    ActionType: ActionType.SentNewMessage
                }));
                return;
            }

            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    dispatch(userActivityInsert({
                        Message: 'Clicked Cancel',
                        PageName: 'Agency Profile [Message]',
                        AgencyId: profile.agencyId,
                        ActionType: ActionType.SentNewMessage
                    }));
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {
                        const newMessage: NewMessage = {
                            messageType: session.mesageTypeId,
                            chatType: session.mesageTypeId as any,
                            text: message,
                            subject: session.subject || 'New Message',
                            refId: null
                        }

                        let chat = await MessagingService.createChatWithAgency(session.recipientId, newMessage)
                        let auth = getAuth(getState());
                        await MessagingService.sendPushNotificationToAgency({
                            squadId: auth.squadId,
                            agencyId: 0,
                            playerId: 0,
                            channelId: chat.id,
                            messageText: "New Message",
                            receiverAgencyId: session.recipientId,
                            receiverSquadId: 0,
                            receiverPlayerId: 0
                        });

                        dispatch(Actions.checkNewMessageLimit());

                        dispatch(userActivityInsert({
                            Message: 'Sent Message',
                            PageName: 'Agency Profile [Message]',
                            AgencyId: profile.agencyId,
                            ActionType: ActionType.SentNewMessage
                        }));
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            dispatch(userActivityInsert({
                Message: 'Opened Message',
                PageName: 'Agency Profile',
                AgencyId: profile.agencyId,
                ActionType: ActionType.SentNewMessage
            }));

            dispatch(MessageModalController.openSession(messageSession, specification))
        }
    }

    public static toggleRestrictedAvailableModal() {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const restrictedAvailableCountModal = Selectors.getRestrictedAvailableCountModal(getState())
            dispatch(stateController.setState(
                {
                    ...substate,
                    sendMessageModalState: {
                        ...substate.sendMessageModalState,
                        restrictedAvailableCountModal: !restrictedAvailableCountModal
                    }
                }
            ));

            if (restrictedAvailableCountModal) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Close',
                    PageName: 'Agency Profile [Message]',
                    AgencyId: substate.profile.agencyId,
                    ActionType: ActionType.SentNewMessage
                }));
            }
        }
    };

    public static toggleRestrictedMessageModal() {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const restrictedMessageModal = Selectors.getRestrictedMessageModal(getState())
            dispatch(stateController.setState(
                {
                    ...substate,
                    sendMessageModalState: {
                        ...substate.sendMessageModalState,
                        restrictedMessageModal: !restrictedMessageModal
                    }
                }
            ))
        }
    };

    public static checkNewMessageLimit(cookie: string = null) {
        return async (dispatch, getState: () => AppState) => {
            const { userId } = getState().auth;
            const substate = Selectors.getRoot(getState());
            try {
                let newMessageAvailability;
                if (cookie) {
                    newMessageAvailability = await MessagingService.checkNewMessageLimitServer(userId, cookie);
                } else {
                    newMessageAvailability = await MessagingService.checkNewMessageLimit(userId);
                }
                dispatch(stateController.setState(
                    {
                        ...substate,
                        sendMessageModalState: {
                            ...substate.sendMessageModalState,
                            newMessageAvailability: newMessageAvailability
                        }
                    }
                ))
            } catch (e) {
                console.error(e)
            }
        }
    };


    //====== Claim profile Window ========================================================================================

    public static claimThisProfileModalOpen(agency?: AgencyPublicProfileShortInfo) {
        return (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            const agencyName = agency?.agencyName ?? profile?.agencyName;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: {
                    ...prevState.claimThisProfileModal,
                    isModalOpen: true,
                    agencyName: agencyName,
                }
            })))

        }
    }

    public static toggleClaimThisProfileModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: {
                    ...prevState.claimThisProfileModal,
                    isModalOpen: false,
                    agencyName: '',
                }
            })))
        }
    }

    public static submitClaimThisProfileHubspotForm(name: string, email: string, jobTitle: string, numberOfPlayers: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: {
                    ...prevState.claimThisProfileModal,
                    isLoadingResponse: true,
                }
            })))
            try {
                const { agencyName, phoneNumber } = Selectors.getRoot(getState()).claimThisProfileModal;
                const portalId = config.hubspotForms.portalId;
                const formId = config.hubspotForms.claimThisProfileFormId;

                const fields = [
                    {
                        name: 'name',
                        value: name,
                    },
                    {
                        name: 'email',
                        value: email,
                    },
                    {
                        name: 'phone',
                        value: phoneNumber,
                    },
                    {
                        name: 'jobtitle',
                        value: jobTitle,
                    },
                    {
                        name: 'agency_profile___agency_name',
                        value: agencyName,
                    },
                    {
                        name: 'agency_profile___num_players',
                        value: numberOfPlayers,
                    },
                ]

                await HubspotFormService.sendDataToHubspotForm(fields, portalId, formId)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    claimThisProfileModal: {
                        ...prevState.claimThisProfileModal,
                        isRequestSent: true,
                    }
                })))

                if (numberOfPlayers === '10 or fewer') {
                    window.open('https://meetings.hubspot.com/transferroom/agency-meeting', '_self');
                }
            }
            finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    claimThisProfileModal: {
                        ...prevState.claimThisProfileModal,
                        isLoadingResponse: false,
                    }
                })))
            }
        }
    }

    public static disposeClaimThisProfileForm() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: defaultState.claimThisProfileModal
            })))
        }
    }

    public static onClaimThisProfileFormChange(formData: { [K in keyof ClaimThisProfileFormData]?: ClaimThisProfileFormData[K] }) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: {
                    ...prevState.claimThisProfileModal,
                    formData: {
                        ...prevState.claimThisProfileModal.formData,
                        ...formData,
                    },
                }
            })))
            dispatch(Actions.setPhoneNumberForClaimThisProfile());
            dispatch(Actions.validateClaimThisProfileForm())
        };
    }

    public static validateClaimThisProfileForm() {
        return (dispatch, getState: () => AppState) => {
            const { name, email, phoneNumberInput, jobTitle, numberOfPlayers, isPolicyChecked } = Selectors.getRoot(getState()).claimThisProfileModal.formData;
            const { phoneNumber } = Selectors.getRoot(getState()).claimThisProfileModal;

            const isValidName = name && isValid.name(name ?? '')
            const isValidEmail = email && isValid.email(email ?? '')
            const isValidPhone = phoneNumber && phoneNumberInput && isValid.phone(phoneNumber ?? '')

            const isFormValid = isValidName && isValidEmail && isValidPhone && jobTitle && numberOfPlayers && isPolicyChecked

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: {
                    ...prevState.claimThisProfileModal,
                    isFormValid: isFormValid,
                }
            })))
        };
    }

    public static setPhoneNumberForClaimThisProfile() {
        return (dispatch, getState: () => AppState) => {
            const { phoneCodeAreaId, phoneNumberInput } = Selectors.getRoot(getState()).claimThisProfileModal.formData;
            // TODO: move phoneCodes to new state from entities
            // @ts-ignore
            const phoneCodes = getState().entities.phoneCodes.phoneCodes;
            const phoneCodeArea = phoneCodes.find(x => x.id === phoneCodeAreaId);
            const phoneNumber = `+${(phoneCodeArea?.phoneCode ?? '')} ${(phoneNumberInput ?? '')}`;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                claimThisProfileModal: {
                    ...prevState.claimThisProfileModal,
                    phoneNumber: phoneNumber,
                }
            })))
        };
    }

    // =========== USER ACTIVITY =========== //

    public static sendAgencyProfileUserActivity = (message: string, sectionName?: string, agencyId?: number) => {
        return async (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            const isUserClub = isClubUser(getState())

            const args = {
                Message: message,
                PageName: 'Agency Profile',
                AgencyId: agencyId ?? profile.agencyId,
            };

            dispatch(userActivityInsert(args))
        }
    }

    public static trackingUserActivityToOpenPage() {
        return async (dispatch, getState: () => AppState) => {
            const { profile } = Selectors.getRoot(getState());
            const message = `Opened ${profile.agencyName} Profile`;

            dispatch(Actions.sendAgencyProfileUserActivity(message));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agencyProfileV2;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading;
    public static isUserClub = (state: AppState): boolean => Selectors.getRoot(state).isUserClub;
    public static isUserAgency = (state: AppState): boolean => Selectors.getRoot(state).isUserAgency;
    public static isUserPlayer = (state: AppState): boolean => Selectors.getRoot(state).isUserPlayer;

    public static getRestrictedAvailableCountModal = (state: AppState) =>
        Selectors.getRoot(state).sendMessageModalState.restrictedAvailableCountModal;
    public static getRestrictedMessageModal = (state: AppState) =>
        Selectors.getRoot(state).sendMessageModalState.restrictedMessageModal;

    public static isPermitedToSendMessages = (state: AppState) => state.auth.clubPermission.canSendMessages;
    public static getNewMessageCheckAvailability = (state: AppState) =>
        Selectors.getRoot(state).sendMessageModalState.newMessageAvailability;
    public static isNewMessageLimitRichedOutOrHide = (state: AppState) =>
        (Selectors.getNewMessageCheckAvailability(state) || { availableCount: 5 }).availableCount <= 0;
    public static lockMessage = (state: AppState) => !Selectors.isPermitedToSendMessages(state) || Selectors.isNewMessageLimitRichedOutOrHide(state);
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};