import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ADMIN_FRIENDLY_PROPOSALS_SEND_EMAIL } from 'store/actionTypes';
//import debounceDuration from '../../../services/debounce-duration';
import { notificationCreate } from '../../../app/notifications/notifications.actions';

const friendlyProposalsSentEpic = (action$, state$) => {
    return action$.pipe(
        ofType(ADMIN_FRIENDLY_PROPOSALS_SEND_EMAIL.SUCCESS),
        map(() => {
            const isSuccess = state$.value.friendlyProposals.isSuccess;

            const message = isSuccess
                ? 'Friendly Proposals Newsletter email sent successfully'
                : 'Failed to send Friendly Proposals Newsletter email';
            const level = isSuccess ? 'success' : 'error';

            return notificationCreate({
                message: message,
                level: level,
            });
        }),
    );
};

export default friendlyProposalsSentEpic;