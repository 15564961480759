import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import history from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import historyAccessor from 'history-accessor';
import userActivityInsert from "app/user-activity/actions/user-activity.actions";
import { PageType } from 'constants/enums';
import CoachOnboardingService from 'api/agency/agent/onboarding/coaches/coach-onboarding.service';
import AgencyHeadCoachesService from 'api/agency/agent/head-coaches/head-coaches.service';
import { AgentCoachInvitationItemModel, PreconnectedCoachModel } from 'api/agency/agent/onboarding/coaches/models';

export enum AddCoachesStepEnum {
    AddCoaches = 1,
}

class State {
    isLoading: boolean;
    processing: boolean;
    step: AddCoachesStepEnum;
    amountOfAvailableCredits: number;
    pendingItems: PreconnectedCoachModel[];
}

const defaultState: State = {
    isLoading: false,
    processing: false,
    step: AddCoachesStepEnum.AddCoaches,
    amountOfAvailableCredits: 0,
    pendingItems: []
}

const stateController = new StateController<State>(
    'AGENCY/ADD_COACHES',
    defaultState
)

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                defaultState
            ));
        }

    }

    public static goToStep(step: AddCoachesStepEnum) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                step
            }));
        }
    }

    public static init() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Add Head Coach]',
                Message: 'Opened Add Head Coach Pop-up',
                PageType: PageType.AgencyCoaches
            }));
        }
    }

    // public static init() {
    //     return async (dispatch, getState: () => AppState) => {
    //         try {
    //             dispatch(stateController.setState(({ isLoading: true })));
    //             await dispatch(this.loadSubscriptionStats());
    //
    //         } catch (err) {
    //             console.error(err);
    //         } finally {
    //             dispatch(stateController.setState({ isLoading: false }));
    //         }
    //     }
    // }
    //
    // public static loadSubscriptionStats = () => {
    //     return async (dispatch, getState: () => AppState) => {
    //         const data = await OnboardingService.getSubscriptionStats();
    //         const amountOfAvailableCredits = data.totalSlots - data.usedSlots;
    //         dispatch(stateController.setState(({ amountOfAvailableCredits })));
    //     }
    // }

    public static goToAddCoaches() {
        return (dispatch, getState: () => AppState) => {
            history.push(agencyPaths.addCoaches)
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches',
                Message: 'Add Head Coaches',
                PageType: PageType.AgencyCoaches
            }));
        }
    }

    public static closed() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Clicked Cancel`,
                PageName: `Agency Head Coaches [Add Head Coach]`,
                PageType: PageType.AgencyCoaches
            }));
            historyAccessor.push('/');
        }
    }

    public static getCoachesByKeyword = (keyword) => {
        return async (dispatch, getState: () => AppState) => {
            return await AgencyHeadCoachesService.getByKeyword(keyword);
        }
    }

    public static addCoach(item: PreconnectedCoachModel) {
        return (dispatch, getState: () => AppState) => {
            const pendingItems = Selectors.getPendingItems(getState())
            item.isRegular = true;
            let newItems = [...pendingItems, item];
            let sortedItems = newItems.sort((a, b) => a.shortName.localeCompare(b.shortName));

            dispatch(userActivityInsert({
                Message: 'Add Head Coach',
                PageName: 'Agency Head Coaches [Add Head Coach]',
                CoachId: item.id,
                ClubId: item.squadId,
                PageType: PageType.AgencyCoaches
            }));

            dispatch(stateController.setState({ pendingItems: sortedItems }));
        }
    }

    public static removeCoach(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const pendingItems = Selectors.getPendingItems(getState())
            const newItems = [...pendingItems.filter(x => x.id !== playerId)];
            const coachRemoved = pendingItems.find(x => x.id == playerId);

            dispatch(userActivityInsert({
                Message: 'Remove Head Coach',
                PageName: 'Agency Head Coaches [Add Head Coach]',
                CoachId: coachRemoved.id,
                ClubId: coachRemoved.squadId,
                PageType: PageType.AgencyCoaches
            }));

            dispatch(stateController.setState({ pendingItems: newItems }));
        }
    }

    public static completeAdding(): any {
        return async (dispatch, getState: () => AppState) => {
            let pendingItems = Selectors.getPendingItems(getState());

            try {
                dispatch(stateController.setState(({ processing: true })));

                let coachItems: AgentCoachInvitationItemModel[] = pendingItems.map((item) => ({ staffId: item.id, isRegular: item.isRegular }))

                await CoachOnboardingService.addCoaches(coachItems);

                dispatch(userActivityInsert({
                    Message: `Clicked Continue`,
                    PageName: 'Agency Head Coaches [Add Head Coach]',
                    PageType: PageType.AgencyCoaches
                }));

                pendingItems.map(coach => {
                    dispatch(userActivityInsert({
                        Message: 'Added Head Coaches',
                        PageName: 'Agency Head Coaches [Add Head Coach]',
                        PageType: PageType.AgencyCoaches,
                        CoachId: coach.id
                    }));
                })

                historyAccessor.push(agencyPaths.coachVerification);
            } catch (err) {
                console.error(err);
                dispatch(stateController.setState({ processing: false }));
                dispatch({ type: '--ERROR' });
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.agent.addCoaches;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).processing;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getPendingItems = (state: AppState) => Selectors.getRoot(state).pendingItems;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
