import {
    LOAD_SUBSCRIPTION_PROMOTION_STATE,
    SEND_SUBSCRIPTION_PROMOTION_REACTION,
    SUBSCRIPTION_PROMOTION_THNX_BANNER,
    DISPOSE_SUBSCRIPTION_PROMOTION_RULES
} from 'store/actionTypes'

export class SubscriptionPromotionState {
    isFetching: boolean;
    shouldShowThnxBanner: boolean;
    promotionPlan: number;
    expiration: string;
    expirationDateFormatted: string;
    assignedAt: string;
    isVisible: boolean;
    daysLeft: number;
    isUpgradeNowClicked: boolean;
    isDoneClicked: boolean;
    isRemoveClicked: boolean;
}

const initialState: SubscriptionPromotionState = {
    isFetching: false,
    shouldShowThnxBanner: false,
    promotionPlan: 0,
    expiration: "",
    expirationDateFormatted: "",
    assignedAt: "",
    isVisible: false,
    daysLeft: 0,
    isUpgradeNowClicked: false,
    isDoneClicked: false,
    isRemoveClicked: false,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case LOAD_SUBSCRIPTION_PROMOTION_STATE.SUCCESS:
            return { ...state, ...payload.data }

        case SEND_SUBSCRIPTION_PROMOTION_REACTION.REQUEST:
            return { ...state, isFetching: true }

        case SEND_SUBSCRIPTION_PROMOTION_REACTION.SUCCESS:
            return { ...state, shouldShowThnxBanner: true, isFetching: false }

        case SUBSCRIPTION_PROMOTION_THNX_BANNER:
            return { ...state, shouldShowThnxBanner: payload }

        case DISPOSE_SUBSCRIPTION_PROMOTION_RULES:
            return initialState

        default:
            return state
    }
}
