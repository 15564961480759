import { combineReducers } from 'redux'
import * as FilterByPlayerController from './filter-by-player.controller'

export class AdsState {
    filterByPlayer: FilterByPlayerController.State
}

export const Reducers = combineReducers({
    filterByPlayer: FilterByPlayerController.Reducer,
})
