import { StateController } from 'utils/action-declaration'
import { AppState } from "root.reducer";
import PasswordValidation from "utils/validate-password";
import UserPasswordService from "api/user-password/user-password";
import { getAuth } from 'store/auth/authReducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { commonPagesPaths } from 'routes/paths';
import AgencySettingsService from 'api/agency/agent/agent-profile/agent-profile.service';
import { PageType } from 'constants/enums';

export enum SettingsSteps {
    Main = 1,
    ChangePassword = 2,
    PasswordUpdated = 3,
    Documents = 4
}

class State {
    step: SettingsSteps;
    passwordUpdates: {
        isLoading: boolean,
        password: string,
        confirmedPassword: string,
        newPassword: string,
        newPasswordError: any,
        confirmedPasswordError: any,
        isProcessing: boolean,
        isValidPassword: boolean,
        isOldPasswordValid: boolean | null,
    };
}

const defaultState: State = {
    step: SettingsSteps.Main,
    passwordUpdates: {
        isLoading: false,
        password: "",
        confirmedPassword: "",
        newPassword: "",
        newPasswordError: null,
        confirmedPasswordError: null,
        isProcessing: false,
        isValidPassword: true,
        isOldPasswordValid: null,
    },
}

const stateController = new StateController<State>(
    'COACH/SETTINGS',
    defaultState
);

class Actions {
    public static init() {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;
            dispatch(userActivityInsert({
                Message: 'Clicked Settings',
                PageName: 'My Page [Settings]',
                CoachId: coachId,
                PageType: PageType.CoachPage
            }));
        }
    }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setStep(step: SettingsSteps) {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;
            dispatch(stateController.setState({ step: step }));

            if (step === SettingsSteps.ChangePassword) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Change Password',
                    PageName: 'Head Coach Settings',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }

            if (step === SettingsSteps.Documents) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Documents',
                    PageName: 'Head Coach Settings',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }
        }
    }

    public static clickBackBtn(step: SettingsSteps) {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;

            if (step === SettingsSteps.Documents) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Back',
                    PageName: 'Head Coach Settings [Documents]',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }

            if (step === SettingsSteps.ChangePassword) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Back',
                    PageName: 'Head Coach Settings [Change Password]',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }
        }
    }

    public static initDoneStep() {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;

            dispatch(userActivityInsert({
                Message: 'Viewed Change Password Success Page',
                PageName: 'Head Coach Settings [Change Password]',
                CoachId: coachId,
                PageType: PageType.CoachPage
            }));
        }
    }

    public static onDoneStepClose() {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;

            dispatch(userActivityInsert({
                Message: 'Clicked Close',
                PageName: 'Head Coach Settings [Change Password]',
                CoachId: coachId,
                PageType: PageType.CoachPage
            }));

            dispatch(Actions.dispose());
        }
    }

    /*-------- Documents  ------*/

    public static openDocument = (url: string) => {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;
            if (url === commonPagesPaths.coachTermsOfService) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Terms of Service',
                    PageName: 'Head Coach Settings [Documents]',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }
            if (url === commonPagesPaths.coachPrivacyPolicy) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Privacy Policy',
                    PageName: 'Head Coach Settings [Documents]',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }

            if (url === commonPagesPaths.newDataProcessingTerms) {
                dispatch(userActivityInsert({
                    Message: 'Clicked Data Processing Terms',
                    PageName: 'Head Coach Settings [Documents]',
                    CoachId: coachId,
                    PageType: PageType.CoachPage
                }));
            }

            window.open(url, '_blank');
        }

    }

    /*-------- Change Password  ------*/

    public static saveNewPassword() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const state = getState();
                const oldPassword = Selectors.getPassword(state);
                const confirmNewPassword = Selectors.getConfirmedPassword(state);
                const newPassword = Selectors.getNewPassword(state);
                const coachId = getState().auth.coachId;

                if (!oldPassword || !confirmNewPassword || !newPassword) {
                    return;
                }

                const validation = PasswordValidation.validate(newPassword)
                if (!validation.isSuccess) {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: validation.rules,
                            isValidPassword: validation.isSuccess,
                        }
                    })));
                    return
                }

                const confirmedPasswordError = PasswordValidation.validateConfirmPassword(newPassword, confirmNewPassword);

                if (confirmedPasswordError) {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: null,
                            confirmedPasswordError
                        }
                    })));
                    return;
                }

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    passwordUpdates: {
                        ...prevState.passwordUpdates,
                        isLoading: true
                    }
                })));

                const passwordData = await UserPasswordService.updatePassword({ password: oldPassword, newPasword: newPassword });

                if (passwordData.currentPasswordWrong) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: null,
                            rePasswordError: null,
                            isValidPassword: true,
                            isOldPasswordValid: false,
                        }
                    })));
                } else if (!passwordData.isSuccess) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: passwordData.rules,
                            rePasswordError: null,
                            isValidPassword: false,
                            isOldPasswordValid: !passwordData.currentPasswordWrong,
                        }
                    })));
                } else {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        passwordUpdates: {
                            ...defaultState,
                        }
                    })));
                    dispatch(userActivityInsert({
                        Message: 'Clicked Save',
                        PageName: 'Head Coach Settings [Change Password]',
                        CoachId: coachId,
                        PageType: PageType.CoachPage
                    }));
                    dispatch(Actions.setStep(SettingsSteps.PasswordUpdated));
                }
            } catch (error) {
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    passwordUpdates: {
                        isLoading: false,
                    }
                })))
            }
        }
    }

    public static setPassword(password: string) {
        return async dispatch => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    password: password,
                }
            }))));
        }
    }

    public static setConfirmedPassword(confirmedPassword: string) {
        return (dispatch, getState: () => AppState) => {
            const password = Selectors.getNewPassword(getState())
            const confirmedPasswordError = PasswordValidation.validateConfirmPassword(password, confirmedPassword);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    confirmedPassword,
                    confirmedPasswordError
                }
            })));
        }
    }

    public static validateOldPassword() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    isOldPasswordValid: null,
                }
            })))
            const userId = getAuth(getState()).userId;
            const password = Selectors.getPassword(getState())

            try {
                if (userId && password) {
                    const isOldPasswordValid = await AgencySettingsService.validatePassword(
                        userId,
                        password,
                    );
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        passwordUpdates: {
                            ...prevState.passwordUpdates,
                            isOldPasswordValid,
                        }
                    })))
                }
            } catch {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    passwordUpdates: {
                        isOldPasswordValid: null,
                    }
                })))
            }
        }
    }

    public static cancelChangedPassword() {
        return (dispatch, getState: () => AppState) => {
            const coachId = getState().auth.coachId;
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                passwordUpdates: {
                    ...defaultState,
                }
            })));
            dispatch(Actions.setStep(SettingsSteps.Main));
            dispatch(userActivityInsert({
                Message: 'Clicked Cancel',
                PageName: 'Head Coach Settings [Change Password]',
                CoachId: coachId,
                PageType: PageType.CoachPage
            }));
        }
    }

    public static setNewPassword(newPassword: string) {
        return (dispatch, getState: () => AppState) => {
            const confirmedPassword = Selectors.getConfirmedPassword(getState())
            const validation = PasswordValidation.validate(newPassword)
            const confirmedPasswordError = PasswordValidation.validateConfirmPassword(newPassword, confirmedPassword);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    newPassword,
                    newPasswordError: validation.rules,
                    isValidPassword: validation.isSuccess,
                    confirmedPasswordError,
                }
            })));
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState): State => state.coach.settings;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
    public static getConfirmedPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.confirmedPassword;
    public static getNewPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.newPassword;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.password;
    public static getNewPasswordError = (state: AppState) => Selectors.getRoot(state).passwordUpdates.newPasswordError;
    public static getConfirmedPasswordError = (state: AppState) => Selectors.getRoot(state).passwordUpdates.confirmedPasswordError;
    public static isValidPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.isValidPassword;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).passwordUpdates.isLoading;
    public static isOldPasswordValid = (state: AppState) => {
        const isValid = Selectors.getRoot(state).passwordUpdates.isOldPasswordValid;
        return isValid || isValid === null
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}