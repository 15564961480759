import { EventInfoModel } from './models';
import DefaultEventSettings from 'app/events/components/authorized-event-page/multiple-events/default/event-settings'
import Event_2022_21_March_EventSettings from 'app/events/components/authorized-event-page/multiple-events/madrid/event-settings'
import Event_2022_16_June_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2022-16-june-madrid/event-settings'
import Event_2022_1_August_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2022-1-august-virtual/event-settings'
import Event_2022_13_November_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2022-13-november-berlin/event-settings'
import Event_2023_17_January_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2023-17-january-virtual/event-settings'
import Event_2023_March_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2023-march-london/event-settings'
import Event_2023_June_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2023-june-sao-paolo/event-settings'
import Event_2023_August_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2023-august-virtual/event-settings'
import Event_2023_November_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2023-november-lisbon/event-settings'
import Event_2024_January_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2024-january-virtual/event-settings'
import Event_2024_March_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2024-march-rome/event-settings'
import Event_2024_June_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2024-june-cancun/event-settings'
import Event_2024_August_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2024-august-madrid/event-settings'
import Event_2024_November_EventSettings from 'app/events/components/authorized-event-page/multiple-events/2024-november-istanbul/event-settings'

import { 
    VIRTUAL_SUMMIT_10TH_NOVEMBER_2021_ID,
    MADRID_SUMMIT_21ST_22ND_MARCH_2022_ID,
    MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID, 
    VIRTUAL_DEAL_DAY_AUGUST_2022_ID,
    BERLIN_SUMMIT_13TH_16TH_NOVEMBER_2022_ID, 
    VIRTUAL_DEAL_DAY_JANUARY_2023_ID,
    LONDON_SUMMIT_MARCH_2023_ID,
    SAO_PAOLO_SUMMIT_JUNE_2023_ID,
    VIRTUAL_DEAL_DAY_AUGUST_2023_ID,
    LISBON_SUMMIT_NOVEMBER_2023_ID,
    VIRTUAL_DEAL_DAY_JANUARY_2024_ID,
    ROME_SUMMIT_MARCH_2024_ID,
    CANCUN_SUMMIT_JUNE_2024_ID,
    MADRID_SUMMIT_AUGUST_2024_ID,
    ISTANBUL_SUMMIT_NOVEMBER_2024_ID
} from './constants';

export const getEventInfo = (eventId: number): EventInfoModel => {
    switch (eventId) {

        case ISTANBUL_SUMMIT_NOVEMBER_2024_ID:
            return Event_2024_November_EventSettings;
        case MADRID_SUMMIT_AUGUST_2024_ID:
            return Event_2024_August_EventSettings;

        case CANCUN_SUMMIT_JUNE_2024_ID:
            return Event_2024_June_EventSettings;

        case ROME_SUMMIT_MARCH_2024_ID:
            return Event_2024_March_EventSettings;
        
        case VIRTUAL_DEAL_DAY_JANUARY_2024_ID:
            return Event_2024_January_EventSettings;

        case LISBON_SUMMIT_NOVEMBER_2023_ID:
            return Event_2023_November_EventSettings;

        case VIRTUAL_DEAL_DAY_AUGUST_2023_ID:
            return Event_2023_August_EventSettings;

        case SAO_PAOLO_SUMMIT_JUNE_2023_ID:
            return Event_2023_June_EventSettings;

        case LONDON_SUMMIT_MARCH_2023_ID:
            return Event_2023_March_EventSettings;

        case VIRTUAL_DEAL_DAY_JANUARY_2023_ID:
            return Event_2023_17_January_EventSettings;

        case BERLIN_SUMMIT_13TH_16TH_NOVEMBER_2022_ID:
            return Event_2022_13_November_EventSettings;

        case VIRTUAL_DEAL_DAY_AUGUST_2022_ID:
            return Event_2022_1_August_EventSettings;

        case MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID:
            return Event_2022_16_June_EventSettings;

        case MADRID_SUMMIT_21ST_22ND_MARCH_2022_ID:
            return Event_2022_21_March_EventSettings;

        case VIRTUAL_SUMMIT_10TH_NOVEMBER_2021_ID:
        default:
            return DefaultEventSettings;
    }
}