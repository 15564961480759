import compose from 'lodash/fp/compose';
import { getRequests } from '../requests/requestsReducer';
import {
    PLAYER_PROFILE_FETCH,
    PLAYERS_DECLARE_INTEREST_SAVE,
    PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE
} from '../actionTypes';
import { MARKET_VALUE_PLAYER_INTEREST } from 'store/playerProfile/market-value/market-value.reducer';
import { PLAYER_RANKING_SET_IS_IN_SHORT_LIST } from 'store/playerProfile/playerRanking.reducer';
import { cloneDeep } from 'lodash';
import { AppState } from 'root.reducer';
import { PlayerModelV2 } from 'store/entities/players/player.model.v2';

const map = {
    1: 'isTransferAvailabilityDeclared',
    2: 'isLoanAvailabilityDeclared',
    3: 'isLoanContributionDeclared',
    4: 'isAskingPriceDeclared',
};

export class PlayerProfileState {
    details: PlayerModelV2;
    isDeclareInterestProcessing: boolean;
    isDeclareAgentInterestProcessing: boolean;
}

const initialState: PlayerProfileState = {
    details: <any>{},
    isDeclareInterestProcessing: false,
    isDeclareAgentInterestProcessing: false,
};

export default (playerProfile = initialState, action): PlayerProfileState => {
    switch (action.type) {
        case PLAYER_PROFILE_FETCH.SUCCESS: {
            let playerDetails = action.payload.data
            if (!playerDetails.playerTransfers) playerDetails.playerTransfers = [];
            playerDetails.playerTransfers.forEach((item, index) => item.index = index)
            return { ...playerProfile, details: playerDetails };
        }

        case PLAYER_PROFILE_FETCH.REQUEST: {
            return { ...playerProfile };
        }

        case PLAYERS_DECLARE_INTEREST_SAVE.REQUEST: {
            let playerIdDeclared = action.payload.playerId;
            if (playerProfile.details.id != playerIdDeclared)
                return { ...playerProfile }
            return { ...playerProfile, isDeclareInterestProcessing: true }
        }
        case PLAYERS_DECLARE_INTEREST_SAVE.SUCCESS: {
            let playerIdDeclared = action.payload.request.playerId;
            if (playerProfile.details.id != playerIdDeclared)
                return { ...playerProfile }

            return {
                ...playerProfile,
                details: {
                    ...playerProfile.details,
                    interestWasDeclared: true,
                },
                isDeclareInterestProcessing: false
            }
        }
        case PLAYERS_DECLARE_INTEREST_SAVE.ERROR: {
            return { ...playerProfile, isDeclareInterestProcessing: false }
        }

        case PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.REQUEST: {
            return { ...playerProfile, isDeclareAgentInterestProcessing: true }
        }
        case PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.SUCCESS: {
            return {
                ...playerProfile,
                details: {
                    ...playerProfile.details,
                    agencyRelatedInfo: {
                        ...playerProfile.details.agencyRelatedInfo,
                        interestWasDeclaredToAgent: true,
                    }
                },
                isDeclareAgentInterestProcessing: false
            }
        }
        case PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.ERROR: {
            return { ...playerProfile, isDeclareAgentInterestProcessing: false }
        }

        case MARKET_VALUE_PLAYER_INTEREST.REQUEST: {
            let newDetailsRequest = { ...playerProfile.details }
            newDetailsRequest[map[action.payload.requestType]] = true;
            return { ...playerProfile, details: newDetailsRequest }
        }

        case MARKET_VALUE_PLAYER_INTEREST.ERROR: {
            let newDetailsError = { ...playerProfile.details }
            newDetailsError[map[action.payload.requestType]] = false;
            return { ...playerProfile, details: newDetailsError }
        }

        case PLAYER_RANKING_SET_IS_IN_SHORT_LIST.SUCCESS: {
            let shortListTogglePayload = action.payload.request.payload;
            let existingDetails = cloneDeep(playerProfile.details);
            existingDetails.isInShortList = shortListTogglePayload.on;
            return { ...playerProfile, details: existingDetails }
        }

        default: {
            return playerProfile;
        }
    }
};

const path = (state: AppState) => state.playerProfile;

export const getPlayerProfile = compose(
    profile => profile.details,
    path,
);

export const getProfileLoadingStatus = (state: AppState) =>
    getRequests(state).some(r => r.type === PLAYER_PROFILE_FETCH);
