import { PlayerAdInitialInfoResponse } from 'api/player-ad/models/player-ad'
import { GeneralPositionEnum } from 'api/core/general-position.model';


export const getPreferredPlayingStyles = (initialInfo: PlayerAdInitialInfoResponse, positionId: number) => {
    const positions = initialInfo.positionStyles;
    
    if (!positions || positionId === GeneralPositionEnum.Goalkeeper) {
        return null
    }

    const styles = positions.find(item => item.positionId === positionId).playingStyles

    return [
        {
            id: null,
            name: 'None',
            shortName: null,
        },
        ...styles
    ]
};
