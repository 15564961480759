export interface OnboardingAccessModel {
    isValid: boolean;
    agencyName: string;
    agentEmail: string;
    isAgencyCreating: boolean;
    agencyId: number;
    externalAgencyId: number;
    externalAgentId: number;
    agentFullName: string;
    isUsedInvitation: boolean; 
    webSiteUrl: string;
    agencyCountryId?: number;
    agencyCity: string;
    isLtaAwaitingPayment: boolean;
    userId?: number;
    aspNetUserId: string;
}
export interface AreaResponse {
    id: number;
    code: string;
    name: string;
    phoneCode: string;
    mask: string;
}

export interface ExternalAgentShortModel {
    id: number;
    fullName: string;
    email: string;
}

export interface AgencyPlayerDefaultResponse {
    id: number;
    shortName: string;
    parentSquadName: string;
    age: number | null;
    positionCode: string;
    parentSquadId?: number;
}

export interface AgencyPlayerSearchResponse {
    id: number;
    shortName: string;
    parentSquadName: string;
    age: number;
    isAlreadyAssignedToAnotherAgent: boolean;
    isForAnotherAgency: boolean;
    positionCode: string;
}

export enum AgentRegistrationState
{
    AlreadyRegisteredInAgency = 1,
    RegistrationAllow = 2,
    RegistrationRejected = 3
}

export interface AgentPlayerInvitationItemModel {
    playerId: number;
    isRegular: boolean;
}

export interface PreconnectedPlayerModel {
    id: number;
    shortName: string;
    parentSquadId?: number;
    parentSquadName: string;
    age: number | null;
    positionCode: string;
    isRegular: boolean;
}

export interface AgencyRegularPlayerSlotStats {
    regularPlayersSlots: number;
    usedRegularPlayersSlots: number;
    availableRegularPlayerSlots: number;
    isUnlimitedRegularPlayerSlots: boolean;
}