export const benefits = [
    'Expand your network through one-to-one meetings',
    'Unlock huge transfer opportunities through real-time intelligence',
    'Get transfer business sorted before the peak of the winter window'
]

export const agentBenefits = benefits;
 


