import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { AgencyPlayerOnboardingService } from 'api/agency/player/onboarding-v2/onboarding-v2.service';
import { PlayerInvitationLinkOnboardingAccessModel } from 'api/agency/player/onboarding-v2/models';
import PasswordValidation from 'utils/validate-password';
import { modalClose, modalOpen } from 'store/modals/modalsActions';
import { PLAYER_TERMS_OF_SERVICE, PLAYER_HEALTH_CONSENT } from 'constants/modals';
import { isValid } from "utils/validation";
import { AUTH_LOGIN } from 'store/actionTypes';
import { Actions as OnboardingActions } from "pages/player-v2/onboarding/onboarding.controller";
import { eventHubInsert, insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import historyAccessor from 'history-accessor';
import { playerPathsV2 } from 'routes/paths';
import { getAuth } from 'store/auth/authReducer';
import { PageType } from 'constants/enums';

export enum OnboardingByEmailScreen {
    EmailVerification = 1,
    RegistrationForm = 2,
}

class State {
    isLoading: boolean;
    screen: OnboardingByEmailScreen;
    accessData: PlayerInvitationLinkOnboardingAccessModel;
    token: string;
    emailVerificationStep: {
        email: string;
        showEmailValidationError: boolean;
    }
    registrationStep: {
        phoneCodeAreaId: number | null;
        phoneNumber: string;
        password: string;
        confirmedPassword: string;
        isAgreedTermsOfService: boolean;
        isAgreedHealthConsent: boolean;
        isRegistrationProcessing: boolean;
        phoneCodeAreaIdError: boolean;
        phoneNumberError: boolean;
        passwordRules: Array<{ ruleCode: string, passed: boolean }>;
        passwordError: boolean;
        confirmedPasswordError: any;
    }
}

const defaultState: State = {
    isLoading: false,
    screen: OnboardingByEmailScreen.EmailVerification,
    accessData: null,
    emailVerificationStep: {
        email: null,
        showEmailValidationError: false
    },
    token: '',
    registrationStep: {
        phoneCodeAreaId: null,
        phoneNumber: '',
        password: '',
        confirmedPassword: '',
        isAgreedTermsOfService: false,
        isAgreedHealthConsent: false,
        isRegistrationProcessing: false,
        phoneCodeAreaIdError: false,
        phoneNumberError: false,
        passwordRules: [],
        passwordError: false,
        confirmedPasswordError: false
    }
}

const stateController = new StateController<State>("PLAYERV2/ONBOARDING-BY-EMAIL", defaultState);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static setScreen(screen: OnboardingByEmailScreen) {
        return (dispatch) => {
            dispatch(stateController.setState({ screen: screen }));
        }
    }

    public static loadInitData(token: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const data = await AgencyPlayerOnboardingService.getAccessForInvitationLinkSignUp(token);
                const { metaInfo } = getAuth(getState());
                if (!data.isValid)
                    return historyAccessor.push(playerPathsV2.homePage);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    accessData: data,
                    token: token,
                    emailVerificationStep: {
                        ...prevState.emailVerificationStep,
                        email: data.email
                    },
                    registrationStep: {
                        ...prevState.registrationStep,
                        phoneNumber: data.phone ?? '',
                        phoneCodeAreaId: data.phoneCodeAreaId
                    }
                })));

                dispatch(Actions.trackUserActivity());
                const userActivity = {
                    PageName: `Onboarding [Email Flow]`,
                    Message: `Prefilled Email ${data.email}`,
                    PlayerId: data.playerId,
                    UserId: data.userId,
                    PageType: PageType.PlayerOnboarding,
                };
                await eventHubInsert(userActivity, data.userId, null, null, metaInfo);

            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setToken(token: string) {
        return (dispatch) => {
            dispatch(stateController.setState({ token }));
        }
    }

    public static onEmailChange(email: string) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                emailVerificationStep: {
                    ...prevState.emailVerificationStep,
                    email
                }
            })));
        }
    }

    public static onPhoneCodeChange(value: number) {
        return async (dispatch, getState: () => AppState) => {
            const isPhoneCodeAreaIdValid = isValid.areaId(value);
            const { accessData } = Selectors.getRoot(getState());
            const { playerId, userId } = accessData;
            const { metaInfo } = getAuth(getState());

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    phoneCodeAreaId: value,
                    phoneCodeAreaIdError: !isPhoneCodeAreaIdValid,
                }
            })));
            const userActivity = {
                PageName: 'Onboarding [Player Details]',
                Message: 'Selected Country Code',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            };
            await eventHubInsert(userActivity, userId, null, null, metaInfo);

        }
    }

    public static onPhoneNumberChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const isPhoneNumberValid = isValid.numbers(value) && value.length >= 5;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    phoneNumber: value,
                    phoneNumberError: !isPhoneNumberValid,
                }
            })));
        }
    }

    public static onPasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const rePassword = subState.registrationStep.confirmedPassword;

            const validation = PasswordValidation.validate(value);
            const passwordError = !validation.isSuccess;
            const confirmedPasswordError = PasswordValidation.validateConfirmPassword(value, rePassword);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    password: value,
                    passwordError,
                    confirmedPasswordError,
                    passwordRules: validation.rules,
                }
            })));
        }
    }

    public static onRetypePasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const password = subState.registrationStep.password;
            const confirmedPasswordError = PasswordValidation.validateConfirmPassword(password, value);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    confirmedPassword: value,
                    confirmedPasswordError: confirmedPasswordError
                }
            })))
        }
    }

    public static onTermsOfServiceCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    isAgreedTermsOfService: isChecked
                }
            })))
        }
    }

    public static onHealthConsentCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    isAgreedHealthConsent: isChecked
                }
            })))
        }
    }

    public static register() {
        return async (dispatch, getState: () => AppState) => {
            const { token, registrationStep, accessData } = Selectors.getRoot(getState());
            const { playerId, userId } = accessData;
            const { metaInfo } = getAuth(getState());
            const {
                phoneCodeAreaIdError,
                phoneNumberError,
                password,
                phoneNumber,
                phoneCodeAreaId,
                isAgreedTermsOfService,
                isAgreedHealthConsent,
                passwordError,
                confirmedPasswordError
            } = registrationStep

            if (!phoneCodeAreaIdError && !phoneNumberError && !passwordError && !confirmedPasswordError && isAgreedTermsOfService && isAgreedHealthConsent) {
                try {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: true
                        }
                    })))

                    const { auth, isSuccess } = await AgencyPlayerOnboardingService.invitationLinkSignup({
                        token,
                        password,
                        phone: phoneNumber,
                        phoneCodeAreaId,
                    });

                    if (isSuccess) {
                        dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: auth } });
                        dispatch(OnboardingActions.sendEmailConfirmation());
                        dispatch(OnboardingActions.openEmailVerificationPage());
                    }
                } catch (err) {
                    console.error(err)
                } finally {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: false
                        }
                    })))
                }
                const userActivity = {
                    PageName: 'Onboarding [Player Details]',
                    Message: 'Clicked Continue',
                    PlayerId: playerId,
                    PageType: PageType.PlayerOnboarding,
                };
                await eventHubInsert(userActivity, userId, null, null, metaInfo);
            } else {
                return;
            }
        }
    }

    public static openTermsOfServiceModal = () => (dispatch, getState: () => AppState) => {
        dispatch(modalOpen({
            id: PLAYER_TERMS_OF_SERVICE,
            content: {
                closeModal: () => dispatch(Actions.closeTermsOfServiceModal)
            }
        }))
    }

    public static openHealthConsentModal = () => (dispatch, getState: () => AppState) => {
        dispatch(modalOpen({
            id: PLAYER_HEALTH_CONSENT,
            content: {
                closeModal: () => dispatch(Actions.closeHealthConsentModal)
            }
        }))
    }
    public static closeTermsOfServiceModal = () => (dispatch) => {
        dispatch(modalClose(PLAYER_TERMS_OF_SERVICE))
    }
    public static closeHealthConsentModal = () => (dispatch) => {
        dispatch(modalClose(PLAYER_HEALTH_CONSENT))
    }

    public static goToNextScreen() {
        return (dispatch, getState: () => AppState) => {
            const { emailVerificationStep, accessData } = Selectors.getRoot(getState());
            if (emailVerificationStep.email !== accessData.email) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    emailVerificationStep: {
                        ...prevState.emailVerificationStep,
                        showEmailValidationError: true
                    }
                })))
                return;
            }

            dispatch(Actions.setScreen(OnboardingByEmailScreen.RegistrationForm))
        }
    }

    public static trackUserActivity() {
        return async (dispatch, getState: () => AppState) => {
            const { accessData } = Selectors.getRoot(getState());
            const playerId = accessData?.playerId;
            const userId = accessData?.userId;
            const { metaInfo } = getAuth(getState());
            const userActivity = {
                PageName: `Onboarding [Email Flow]`,
                Message: `Opened Player onboarding by email link`,
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            };
            await eventHubInsert(userActivity, userId, null, null, metaInfo);
        }
    }

    public static trackEnteredPhoneNumberUserActivity() {
        return async (dispatch, getState: () => AppState) => {
            const { registrationStep, accessData } = Selectors.getRoot(getState());
            const { phoneNumber } = registrationStep;
            const { playerId, userId } = accessData;
            const { metaInfo } = getAuth(getState());

            const userActivity = {
                PageName: 'Onboarding [Player Details]',
                Message: 'Entered Phone Number',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            };
            if (phoneNumber) {
                await eventHubInsert(userActivity, userId, null, null, metaInfo);
            }
        }
    }

    public static trackOpenedPhoneCodeUserActivity() {
        return async (dispatch, getState: () => AppState) => {
            const { accessData } = Selectors.getRoot(getState());
            const { playerId, userId } = accessData;
            const { metaInfo } = getAuth(getState());
            
            const userActivity = {
                PageName: 'Onboarding [Player Details]',
                Message: 'Opened Country Code Dropdown',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            };

            await eventHubInsert(userActivity, userId, null, null, metaInfo);
        }
    }

    public static trackEnteredPasswordUserActivity() {
        return async(dispatch, getState: () => AppState) => {
            const { accessData, registrationStep } = Selectors.getRoot(getState());
            const { password } = registrationStep;
            const { playerId, userId } = accessData;
            const { metaInfo } = getAuth(getState());

            const userActivity = {
                PageName: 'Onboarding [Player Details]',
                Message: 'Entered New Password',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            };
            if (password) {
                await eventHubInsert(userActivity, userId, null, null, metaInfo);
            }
        }
    }

    public static trackEnteredPasswordConfirmationUserActivity() {
        return async (dispatch, getState: () => AppState) => {
            const { accessData, registrationStep } = Selectors.getRoot(getState());
            const { confirmedPassword } = registrationStep;
            const { playerId, userId } = accessData;
            const { metaInfo } = getAuth(getState());

            const userActivity = {
                PageName: 'Onboarding [Player Details]',
                Message: 'Entered Password Confirmation',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            }

            if (confirmedPassword) {
                await eventHubInsert(userActivity, userId, null, null, metaInfo);
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.onboardingByEmail;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).registrationStep.password;
    public static getConfirmedPassword = (state: AppState) => Selectors.getRoot(state).registrationStep.confirmedPassword;
    public static getConfirmedPasswordError = (state: AppState) => Selectors.getRoot(state).registrationStep.confirmedPasswordError;
    public static getPasswordRules = (state: AppState) => Selectors.getRoot(state).registrationStep.passwordRules;
    public static getPhoneCodeAreaId = (state: AppState) => Selectors.getRoot(state).registrationStep.phoneCodeAreaId;
    public static getPhoneNumber = (state: AppState) => Selectors.getRoot(state).registrationStep.phoneNumber;
    public static getPhoneNumberError = (state: AppState) => Selectors.getRoot(state).registrationStep.phoneNumberError;
    public static getPhoneCodeAreaIdError = (state: AppState) => Selectors.getRoot(state).registrationStep.phoneCodeAreaIdError;
    public static getPasswordError = (state: AppState) => Selectors.getRoot(state).registrationStep.passwordError;
    public static isAgreedTermsOfService = (state: AppState) => Selectors.getRoot(state).registrationStep.isAgreedTermsOfService;
    public static isAgreedHealthConsent = (state: AppState) => Selectors.getRoot(state).registrationStep.isAgreedHealthConsent;
    public static isRegistrationProcessing = (state: AppState) => Selectors.getRoot(state).registrationStep.isRegistrationProcessing;

    public static isRegistrationFormValid = (state: AppState) => {
        const substate = Selectors.getRoot(state).registrationStep;

        return substate.isAgreedTermsOfService
            && substate.isAgreedHealthConsent
            && substate.phoneCodeAreaId !== null
            && substate.phoneNumber !== ''
            && substate.password !== ''
            && substate.confirmedPassword !== ''
            && !substate.phoneCodeAreaIdError
            && !substate.phoneNumberError
            && !substate.passwordError
            && !substate.confirmedPasswordError;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



