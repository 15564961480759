import { normalize } from 'normalizr';
import axios from '../axios';
import { createAction } from 'redux-actions';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

const prepareUrl = (url) => {
  if (url.startsWith('/')) {
    console.warn(`CreateApiThunk function removed extra '/' symbol from start of the url string. Please check url in action related to: ${url}`)
    return url.substring(1)
  } else {
    return url
  }
}

const createApiThunk = (request, trackingObj = null) => async dispatch => {
  dispatch(createAction(request.type.REQUEST)(request));
  try {
    let { data } = await axios[request.method](
      `api/${prepareUrl(request.url)}`,
      request.payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (request.responseSchema) {
      data = normalize(data, request.responseSchema);
    }
    dispatch(createAction(request.type.SUCCESS)({ request, data }));
    if (!!trackingObj) {
      dispatch(userActivityInsert(trackingObj))
    }
    return data;
  } catch (e) {
    console.error(e);
    dispatch(createAction(request.type.ERROR)(request));
  }
};

export default createApiThunk;

export const createApiThunkWithCancel = (request, token) => async dispatch => {
  dispatch(createAction(request.type.REQUEST)(request));
  try {
    let { data } = await axios[request.method](
      `api/${prepareUrl(request.url)}`,
      request.payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        cancelToken: token
      },
    );
    if (request.responseSchema) {
      data = normalize(data, request.responseSchema);
    }
    dispatch(createAction(request.type.SUCCESS)({ request, data }));
    return data;
  } catch (exc) {
    if (!exc.__CANCEL__) {
      console.error(exc);
      dispatch(createAction(request.type.ERROR)(request));
    }
  }
};

export const createApiWithCancel = async (request, token) =>  {
    let { data } = await axios[request.method](
      `api/${prepareUrl(request.url)}`,
      request.payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        cancelToken: token
      },
    );
    if (request.responseSchema) {
      data = normalize(data, request.responseSchema);
    }
    return data;
};


export const createApi = async request => {
  let { data } = await axios[request.method](
    `api/${prepareUrl(request.url)}`,
    request.payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  if (request.responseSchema) {
    data = normalize(data, request.responseSchema);
  }
  return data;
}

export const createApiWithCorrelationID = async request => {
  let response = await axios[request.method](
    `api/${prepareUrl(request.url)}`,
    request.payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-correlation-id': request.correlationId
      },
    });
  let data = response.data;
  if (request.responseSchema) {
    data = normalize(response.data, request.responseSchema);
  }
  return { data: data, correlationID: response.config.headers['x-correlation-id'] };
}


export const createApiThunkFileUpload = request => async dispatch => {
  dispatch(createAction(request.type.REQUEST)(request));
  try {
    let formData = new FormData();
    formData.append("file", request.payload.file);

    let { data } = await axios[request.method](
      `api/${prepareUrl(request.url)}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
    );
    if (request.responseSchema) {
      data = normalize(data, request.responseSchema);
    }
    dispatch(createAction(request.type.SUCCESS)({ request, data }));
    return data;
  } catch (e) {
    console.error(e);
    dispatch(createAction(request.type.ERROR)(request));
  }
};

export const createApiThunkWithBearer = (request, token) => async dispatch => {
  dispatch(createAction(request.type.REQUEST)(request));
  try {
    let { data } = await axios[request.method](
      `api/${prepareUrl(request.url)}`,
      request.payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token
        },
      },
    );
    if (request.responseSchema) {
      data = normalize(data, request.responseSchema);
    }
    dispatch(createAction(request.type.SUCCESS)({ request, data }));
    return data;
  } catch (e) {
    console.error(e);
    dispatch(createAction(request.type.ERROR)(request));
  }
};
