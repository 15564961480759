import { combineReducers } from 'redux';
import * as SearchController from './search.controller'
import * as MarketsController from './markets.controller'
import * as AgenciesController from './agencies.controller'
import * as CommonController from './common.controller'
import * as RecommendationsController from './recommendations.controller'

export type AgencyFinderState = {
    search: SearchController.State;
    markets: MarketsController.State;
    agencies: AgenciesController.State;
    common: CommonController.State;
    recommendations: RecommendationsController.State;
}

export default combineReducers({
    search: SearchController.Reducer,
    markets: MarketsController.Reducer,
    agencies: AgenciesController.Reducer,
    common: CommonController.Reducer,
    recommendations: RecommendationsController.Reducer
})