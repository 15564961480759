import React, { Component } from 'react';
import TestimonialSlider from 'app/testimonial-slider/testimonials-slider'
import styled, { css } from 'styled-components'

class Props {
    isAgencyRegistered?: boolean;
    extraText?: string;
    benefits?: string[];
    testimonials: any[];
    hideBottomLine?: boolean;
}

class WhyAttend extends Component<Props, {}> {
    render() {

        const { extraText, benefits } = this.props;

        return (
            <Section hideBottomLine={this.props.hideBottomLine}>
                <h2>{this.props.isAgencyRegistered ? 'Your agency is attending' : 'Why Attend?'}</h2>
                <div className="row">
                    {extraText && <p className='extra-text'>{extraText}</p>}
                    <div className="list-container">
                        <ul>
                            {benefits.map((item, index) => (
                                <li className={'mobile-li'} key={index}>
                                    <span className="fa fa-check" />
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {this.props.testimonials.length > 0 &&
                    <>
                        <div style={{ height: 20 }} />
                        <TestimonialSlider testimonials={this.props.testimonials} />
                    </>
                }
            </Section>
        )
    }
}

const Section = styled.div`
    margin: 0 auto;
    margin-bottom: 60px;
    padding-left: 5px;
    margin-top: 74px;

    ${props => !props.hideBottomLine && css`
        border-bottom: solid 1px #d4d4d4;
        padding-bottom: 50px;
    `}

    h2 {
        font-size: 40px;
        font-weight: bold;
    }

    .extra-text {
        font-size: 18px;
        margin: 0;
        padding-left: 15px;
    }

    .list-container {
        padding: 0;
        margin-top: 15px;

        @media (max-width: 768px) {
            padding: 0 15px;
        }

        ul {
            font-size: 18px;
            padding: 0;

            .mobile-li {
                margin-bottom: 7px;
                font-weight: 500;
            }
            li {
                display: flex;
            }
            span {
                color: #0E854C;
                font-size: 21px;
                margin-right: 15px;
                margin-top: 4px;
            }
        }

        @media (min-width: 768px) {
            padding-left: 15px;
        }
    }
`


export default WhyAttend