import { StateController } from 'utils/action-declaration';
import { LandingPageService } from 'api/landing-page/landing-page-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { getAuth } from 'store/auth/authReducer';

class SpeakerCardState {
    canShowComponent: boolean;
    isDismissing: boolean;
    isLoading: boolean;
}

const defaultState: SpeakerCardState = {
    canShowComponent: false,
    isDismissing: false,
    isLoading: false,
}

const stateController = new StateController<SpeakerCardState>(
    "LANDING_POAGE/SPEAKER_CARD",
    defaultState
);

class Actions {

    public static init() {
        return async (dispatch, getState) => {
            const result = await LandingPageService.getIsSpeakerCardDismissed();
            dispatch(stateController.setState({ canShowComponent: result }))
        }
    }

    public static dismiss(isAgentPage: boolean) {
        return async (dispatch, getState) => {
            try {
                const { eventId } = getAuth(getState());
                dispatch(stateController.setState({ isDismissing: true }));
                await LandingPageService.dismissSpeakerCard();
                dispatch(stateController.setState({ canShowComponent: false }));
                dispatch(userActivityInsert({
                    PageName: isAgentPage ? "Agency Home [Event card Speaker]" : "Home [Event card Speaker]",
                    Message: "Not Attending",
                    PageType: isAgentPage ? PageType.AgencyHome : PageType.Home,
                    EventId: eventId
                }))
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isDismissing: false }));
            }
        }
    }

    public static hide() {
        return async (dispatch, getState) => {
            dispatch(stateController.setState({ canShowComponent: false }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SpeakerCardState as State,
    Actions as Actions,
    stateController as Controller
};



