import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import {
    AchievementItem,
    AchievementTypeEnum,
    CoachingLicenseTypeEnum,
    FormationEnum,
    PlayingStyleEnum,
    SortByKeyEnum,
    HeadCoachExperienceEnum,
} from "api/search-v3/model/search-request";
import getSections from 'services/filterSections';
import SearchService from 'api/search-v3/search.clubside.service';
import {
    AreaCriteria,
    AvailableLeagueCriteria,
    AvailableNationalityCriteria,
    PositionRoleItem,
    PositionRoleCriteria,
    PlayingStyleRoleEnum
} from 'api/search-v3/model/search-filter-criteria';
import Range from 'api/core/range';
import { IdName } from 'api/core/id-name';
import { Actions as GridActions, Selectors as GridSelectors } from './grid.controller';
import { GridToggleState, SearchMode, SelectNationalityModalData } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import * as Suggestions from './autosuggest.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import MarketValueSelector from 'components/Forms/MarketValueSelector/index';
import {
    SearchSuggestionSubset,
    LatestFilter,
    FeaturedAgency
} from 'api/search/model/suggestion-subsets';
import { getPositionCodeByName } from 'utils/position-helper';
import positionFullNames from 'constants/positionFullNames'
import { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from 'axios-config';
import { SearchPlayerPosition, SearchPlayerPositionEnum } from 'api/search-v3/model/player-positions';
import { CustomLeagueList } from 'api/search-v3/model/suggestion-list';
import { Actions as CompetitionsListActions } from './competition-list.controller'
import { Structure } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import { isClubUser } from 'store/auth/authReducer';

const sections = getSections();
class SortItem {
    id: SortByKeyEnum;
    title: string;
    shortTitle: string;
    sortDirection: 'asc' | 'desc';
}

export class TransferTypeFilter {
    showOnlyAvailablePlayers: boolean;
    showOnlyPlayersWithSuspendedContract: boolean;
    showOnlyPlayersOnReleaseList: boolean;
    transferFee: Range<number>;
    anuallGrossSalaryYearly: Range<number>;
    loanFee: Range<number>;
}

export class TransferTypeFilterModalData {
    showOnlyAvailablePlayers: boolean;
    transferFee: number;
    anuallGrossSalaryYearly: number;
    loanFee: number;
}

export class PlayerAttributesFilterData {
    showOnlyFreeAgentPlayers: boolean;
    lessThanSixMonth: boolean;
    lessThanTwelveMonth: boolean;
    isAboveSixFt: boolean;
    contractExpiryMonthesMax: number | null;
    isCurrentLeague: boolean;
    isCurrentAllLeagues: boolean;
    isPreviousLeague: boolean;
    isPreviousAllLeagues: boolean;
    leaguesList: number[];
    currentLeagueList?: CustomLeagueList[];
    previousLeagueList?: CustomLeagueList[];
    previousLeaguesList: number[];
    rating: Range<number>;
    age: Range<number>;
    isAmbitious: boolean;
    isMatchingMe: boolean;
    isCurrentNationality: boolean;
    nationalityList: number[];
    height: Range<number>;
    preferredFootLeft: boolean;
    preferredFootRight: boolean;
    preferredFootBoth: boolean;
    gbePass: boolean;
    playingStyleRoles?: number[];
    playingStyleAllRoles?: PositionRoleItem[];
}   

export interface PlayerAttributesFilter {
    filterData: PlayerAttributesFilterData;
    ambitious: Range<number>;
    matchingMe: Range<number>;
    availableNationalities: AvailableNationalityCriteria[];
}

export interface CoachAttributesFilterData {
    isOverTwoYears: boolean;
    isOverFiveYears: boolean;
    isOverEightYears: boolean;
    isOverTenYears: boolean;
    hasPlayingCareer: boolean;
    isCurrentLeague: boolean;
    isCurrentAllLeagues: boolean;
    leaguesList: number[];
    leagueExperienceList?: Array<CustomLeagueList>;
    rating: Range<number>;
    isAmbitious: boolean;
    isMatchingMe: boolean;
    isHighSuitability: boolean;
    trustInYouth: Range<number>;
    isHighTrustInYouth: boolean;
    isMediumTrustInYouth: boolean;
    isCurrentNationality: boolean;
    nationalityList: number[];
    isUnder35: boolean;
    isUnder40: boolean;
    isUnder50: boolean;
    isUnder60: boolean;
    achievementList: AchievementItem[];
    playingStyleList: PlayingStyleEnum[];
    formationList: FormationEnum[];
    licenseTypeList: CoachingLicenseTypeEnum[];
    headCoachExperienceList: HeadCoachExperienceEnum[];
}

export interface CoachAttributesFilter {
    filterData: CoachAttributesFilterData;
    ambitious: Range<number>;
    matchingMe: Range<number>;
    highTrustInYouth: Range<number>;
    mediumTrustInYouth: Range<number>;
    formationListAll: IdName[];
    licenseTypeListAll: IdName[];
    playingStyleListAll: IdName[];
    achievementListAll: any[];
    headCoachExperienceListAll: IdName[];
    availableNationalities: AvailableNationalityCriteria[];
}

const sortInfoMap = new Map<SortByKeyEnum, SortItem>([
    [SortByKeyEnum.Availability, {
        id: SortByKeyEnum.Availability,
        title: 'Relevance',
        shortTitle: 'Relevance',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.AvailableSinceNewestToOldest, {
        id: SortByKeyEnum.AvailableSinceNewestToOldest,
        title: 'Date made available: Newest to oldest',
        shortTitle: 'Date made available',
        sortDirection: 'desc',
    }],
    [SortByKeyEnum.ContractExpiry, {
        id: SortByKeyEnum.ContractExpiry,
        title: 'Contract expiry: Soonest to latest',
        shortTitle: 'Contract expiry',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.XtvHighToLow, {
        id: SortByKeyEnum.XtvHighToLow,
        title: 'xTV: High to low',
        shortTitle: 'xTV',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.XtvLowToHigh, {
        id: SortByKeyEnum.XtvLowToHigh,
        title: 'xTV: Low to high',
        shortTitle: 'xTV',
        sortDirection: 'asc'
    }],
    [SortByKeyEnum.RatingHighToLow, {
        id: SortByKeyEnum.RatingHighToLow,
        title: 'Rating: High to low',
        shortTitle: 'Rating',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.DateWhenAddedToShortList, {
        id: SortByKeyEnum.DateWhenAddedToShortList,
        title: 'Date when added',
        shortTitle: 'Date when added',
        sortDirection: 'asc'
    }],
    [SortByKeyEnum.Alphabetically, {
        id: SortByKeyEnum.Alphabetically,
        title: 'Alphabetically: A to Z',
        shortTitle: 'Alphabetically',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.AlphabeticallyReversed, {
        id: SortByKeyEnum.AlphabeticallyReversed,
        title: 'Alphabetically: Z to A',
        shortTitle: 'Alphabetically',
        sortDirection: 'asc'
    }],
    [SortByKeyEnum.ByRelevant, {
        id: SortByKeyEnum.ByRelevant,
        title: 'Relevance',
        shortTitle: 'Relevance',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.TrustInYouthHighToLow, {
        id: SortByKeyEnum.TrustInYouthHighToLow,
        title: 'Trust in Youth: High to low',
        shortTitle: 'Trust in Youth',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.PotentialRatingHighToLow, {
        id: SortByKeyEnum.PotentialRatingHighToLow,
        title: 'Potential Rating: High to low',
        shortTitle: 'Potential rating',
        sortDirection: 'desc'
    }],
    [SortByKeyEnum.MaxSpeedHighToLow, {
        id: SortByKeyEnum.MaxSpeedHighToLow,
        title: 'Max Speed: High to low',
        shortTitle: 'Max speed',
        sortDirection: 'desc'
    }],
]);

// Basic sortList sets
export const playersSortList: Array<SortItem> = [
    sortInfoMap.get(SortByKeyEnum.Availability),
    sortInfoMap.get(SortByKeyEnum.AvailableSinceNewestToOldest),
    sortInfoMap.get(SortByKeyEnum.ContractExpiry),
    sortInfoMap.get(SortByKeyEnum.XtvHighToLow),
    sortInfoMap.get(SortByKeyEnum.XtvLowToHigh),
    sortInfoMap.get(SortByKeyEnum.RatingHighToLow),
    sortInfoMap.get(SortByKeyEnum.PotentialRatingHighToLow),
    sortInfoMap.get(SortByKeyEnum.MaxSpeedHighToLow),
];
export const shortListSortList: Array<SortItem> = [
    sortInfoMap.get(SortByKeyEnum.Availability),
    sortInfoMap.get(SortByKeyEnum.AvailableSinceNewestToOldest),
    sortInfoMap.get(SortByKeyEnum.ContractExpiry),
    sortInfoMap.get(SortByKeyEnum.XtvHighToLow),
    sortInfoMap.get(SortByKeyEnum.XtvLowToHigh),
    sortInfoMap.get(SortByKeyEnum.RatingHighToLow),
    sortInfoMap.get(SortByKeyEnum.PotentialRatingHighToLow),
    sortInfoMap.get(SortByKeyEnum.MaxSpeedHighToLow),
]

export const coachSortList: Array<SortItem> = [
    sortInfoMap.get(SortByKeyEnum.ByRelevant),
    sortInfoMap.get(SortByKeyEnum.RatingHighToLow),
    sortInfoMap.get(SortByKeyEnum.TrustInYouthHighToLow)
]

export const agencySortList: Array<SortItem> = [
    sortInfoMap.get(SortByKeyEnum.Alphabetically),
    sortInfoMap.get(SortByKeyEnum.AlphabeticallyReversed),
]

//export const similaritySortList: Array<SortItem> = [
//    { id: SortPlayersByKeyEnum.Similarity, title: 'Similarity' },
//    { id: SortPlayersByKeyEnum.RatingHightToLow, title: 'Rating: High to Low' },
//    { id: SortPlayersByKeyEnum.RatingLowToHigh, title: 'Rating: Low to High' },
//    { id: SortPlayersByKeyEnum.XtvHightToLow, title: 'xTV: High to Low' },
//    { id: SortPlayersByKeyEnum.XtvLowToHigh, title: 'xTV: Low to High' },
//    { id: SortPlayersByKeyEnum.AgeHigtToLow, title: 'Age: High to Low', },
//    { id: SortPlayersByKeyEnum.AgeLowHigh, title: 'Age: Low to High' },
//    { id: SortPlayersByKeyEnum.ContractExpiry, title: 'Contract Expiry' },
//];

// Additional specific Sort items
export const byRelevantClubOrAgency = { id: SortByKeyEnum.ByRelevant, title: 'By Relevant' }

const getPlayerAttributesStructure = ():PlayerAttributesFilterData => {
    return {
        showOnlyFreeAgentPlayers: false,
        lessThanSixMonth: false,
        lessThanTwelveMonth: false,
        isAboveSixFt: false,
        contractExpiryMonthesMax: null,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        isPreviousLeague: false,
        isPreviousAllLeagues: false,
        previousLeagueList: [],
        previousLeaguesList: [],
        rating: {
            min: 0,
            max: 100,
        },
        age: {
            min: 16,
            max: 40,
        },
        isAmbitious: false,
        isMatchingMe: false,
        isCurrentNationality: false,
        nationalityList: [],
        currentLeagueList: [],
        height: {
            min: null,
            max: null,
        },
        preferredFootLeft: false,
        preferredFootRight: false,
        preferredFootBoth: false,
        gbePass: false,
        playingStyleRoles: [],
    };
}

const getCoachAttributesStructure = ():CoachAttributesFilterData => {
    return {
        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        rating: {
            min: 0,
            max: 100,
        },
        isAmbitious: false,
        isMatchingMe: false,
        isHighSuitability: false,
        trustInYouth: {
            min: 0,
            max: 100,
        },
        isHighTrustInYouth: false,
        isMediumTrustInYouth: false,
        isCurrentNationality: false,
        nationalityList: [],
        isUnder35: false,
        isUnder40: false,
        isUnder50: false,
        isUnder60: false,
        achievementList: [],
        playingStyleList: [],
        formationList: [],
        licenseTypeList: [],
        headCoachExperienceList: [],
        leagueExperienceList: [],
    }
}
const getStructure = (): Structure => {
    return {
        age: {
            min: sections.age.default.minAge,
            max: sections.age.default.maxAge,
        },
        height: {
            min: sections.height.default.minHeight,
            max: sections.height.default.maxHeight,
        },
        isAboveSixFt: false,
        contractExpiryMonthes: null,
        lessThanSixMonth: false,
        lessThanTwelveMonth: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        isPreviousLeague: false,
        isPreviousAllLeagues: false,
        previousLeaguesList: [],
        isCurrentNationality: false,
        nationalityList: [],
        gbePass: sections.faPlayerPoints.default.faPointsPassResult,
        showOnlyFreeAgentPlayers: false,
        transferFee: null,
        anuallGrossSalaryYearly: null,
        loanFee: null,
        minutesPlayed: {
            min: 0,
            max: null,
        },
        rating: {
            min: 0,
            max: 100,
        },
        coachRating: {
            min: 10, // TODO: need to use data from load criteria
            max: 100,
        },
        marketValue: {
            min: null,
            max: null,
        },
        position: null,
        showOnlyAvailablePlayers: false,

        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isHighSuitability: false,
        trustInYouth: {
            min: 0,
            max: 100,
        },
        isHighTrustInYouth: false,
        isMediumTrustInYouth: false,
        isUnder35: false,
        isUnder40: false,
        isUnder50: false,
        isUnder60: false,
        achievementList: [],
        playingStyleList: [],
        formationList: [],
        licenseTypeList: [],
        headCoachExperienceList: [],
        isAmbitious: false,
        isMatchingMe: false,
        currentLeagueList: [],
        previousLeagueList: [],
        preferredFootLeft: false,
        preferredFootRight: false,
        preferredFootBoth: false,
        playingStyleRoles: [],
    }
}

// TODO: move to Selectors and take data from structure state
export const getTransferTypeFilterCriteria = (): TransferTypeFilter => {
    return {
        transferFee: { max: null },
        anuallGrossSalaryYearly: { max: null },
        loanFee: { max: null },
        showOnlyAvailablePlayers: false,
        showOnlyPlayersWithSuspendedContract: false,
        showOnlyPlayersOnReleaseList: false
    };
};

// TODO: move to Selectors and take data from structure state
export const getPlayerAttributesFilterCriteria = (): PlayerAttributesFilterData => {
    return {
        showOnlyFreeAgentPlayers: false,
        lessThanSixMonth: false,
        lessThanTwelveMonth: false,
        isAboveSixFt: false,
        contractExpiryMonthesMax: null,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        isPreviousLeague: false,
        isPreviousAllLeagues: false,
        previousLeagueList: [],
        previousLeaguesList: [],
        rating: {
            min: 0,
            max: 100,
        },
        age: {
            min: 16,
            max: 40,
        },
        isAmbitious: false,
        isMatchingMe: false,
        isCurrentNationality: false,
        nationalityList: [],
        currentLeagueList: [],
        height: {
            min: null,
            max: null,
        },
        preferredFootLeft: false,
        preferredFootRight: false,
        preferredFootBoth: false,
        gbePass: false,
        playingStyleRoles: [],
    };
};

// TODO: move to Selectors and take data from structure state
export const getCoachAttributesFilterCriteria = (): CoachAttributesFilterData => {
    return {
        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        rating: {
            min: 0,
            max: 100,
        },
        isAmbitious: false,
        isMatchingMe: false,
        isHighSuitability: false,
        trustInYouth: {
            min: 0,
            max: 100,
        },
        isHighTrustInYouth: false,
        isMediumTrustInYouth: false,
        isCurrentNationality: false,
        nationalityList: [],
        isUnder35: false,
        isUnder40: false,
        isUnder50: false,
        isUnder60: false,
        achievementList: [],
        playingStyleList: [],
        formationList: [],
        licenseTypeList: [],
        headCoachExperienceList: [],
        leagueExperienceList: []
    }
}
interface userActivityItem {
    id: string;
    message: string;
    pageType: string;
    shouldToJoin?: boolean;
}

export class SearchState {
    searchMode: SearchMode;
    searchSubset: SearchSuggestionSubset;
    searchSubsets: SearchSuggestionSubset[];
    latestSearches: LatestFilter[];
    featuredAgency: FeaturedAgency;
    availableLeagues: AvailableLeagueCriteria[];
    currentCountry: AreaCriteria;
    currentLeague: IdName;
    
    playerAttributesStructure: PlayerAttributesFilterData;
    coachAttributesStructure: CoachAttributesFilterData;
    structure: Structure;
    positionFilter: SearchPlayerPositionEnum | null;
    transferTypeFilter: TransferTypeFilter;
    playerAttributesFilter: PlayerAttributesFilter;
    coachAttributesFilter: CoachAttributesFilter;
    justReleasedSet: boolean;

    sortBy?: SortByKeyEnum;
    structureLoading: boolean;
    structureWasLoaded: boolean;
    suggestionsLoading: boolean;
    mpMaxUpdatedFromServer: boolean;
    marketValue: any;

    positionFilterModal: any;
    transferTypeFilterModal: TransferTypeFilterModalData;
    playerAttributesFilterModal: PlayerAttributesFilter;
    coachAttributesFilterModal: CoachAttributesFilter;
    selectLeaguesModal: any;
    selectNationalityModal: SelectNationalityModalData;

    showOnlyPlayersLookingForAgent: boolean;
    showOnlyFreeAgentCoaches: boolean;
    isQuickSearch: boolean;
    userActivityData: userActivityItem[];
    positions: SearchPlayerPosition[];
    isPositionsLoading: boolean;
    showOnlyShortListedPlayers: boolean;

    deleteModal: {
        isListDeleted: boolean;
        isModalOpen: boolean;
        deletedListId: number;
    };

    playingStyleRolesList: PositionRoleCriteria[];
}

const defaultState: SearchState = {
    searchMode: SearchMode.Default,
    searchSubset: null,
    searchSubsets: [],
    latestSearches: [],
    featuredAgency: null,
    availableLeagues: [],
    currentCountry: null,
    currentLeague: null,
    playerAttributesStructure: getPlayerAttributesStructure(),
    coachAttributesStructure: getCoachAttributesStructure(),
    structure: getStructure(),
    positionFilter: null,
    transferTypeFilter: getTransferTypeFilterCriteria(),
    playerAttributesFilter: {
        filterData: getPlayerAttributesFilterCriteria(),
        ambitious: {
            min: 0,
            max: 100,
        },
        matchingMe: {
            min: 0,
            max: 100,
        },
        availableNationalities: [],
    },
    coachAttributesFilter: {
        filterData: getCoachAttributesFilterCriteria(),
        ambitious: {
            min: 0,
            max: 100,
        },
        matchingMe: {
            min: 0,
            max: 100,
        },
        highTrustInYouth: {
            min: 75,
            max: 100,
        },
        mediumTrustInYouth: {
            min: 50,
            max: 75,
        },
        formationListAll: [
            { id: FormationEnum.F442, name: '4-4-2' },
            { id: FormationEnum.F4231, name: '4-2-3-1' },
            { id: FormationEnum.F4141, name: '4-1-4-1' },
            { id: FormationEnum.F433, name: '4-3-3' },
            { id: FormationEnum.F352, name: '3-5-2' },
            { id: FormationEnum.F541, name: '5-4-1' },
        ],
        licenseTypeListAll: [
            { id: CoachingLicenseTypeEnum.ProLicense, name: 'Pro Licences' },
            { id: CoachingLicenseTypeEnum.ALicense, name: 'A Licence' },
            { id: CoachingLicenseTypeEnum.BLicense, name: 'B Licence' },
            { id: CoachingLicenseTypeEnum.CLicense, name: 'C Licence' },
        ],
        playingStyleListAll: [
            { id: PlayingStyleEnum.FavoursPossession, name: 'Favours Possession' },
            { id: PlayingStyleEnum.FavoursDirectAttack, name: 'Favours Direct Attack' },
            { id: PlayingStyleEnum.FavoursHighPress, name: 'Favours High Press' },
            { id: PlayingStyleEnum.FavoursLowBlock, name: 'Favours Low Block' },
        ],
        achievementListAll: [
            // GETTING from dynamic criteria
            // { id: AchievementTypeEnum.Champion, name: 'Has won the ', achievementType: AchievementTypeEnum.Champion },
            // { id: AchievementTypeEnum.PromotedTo, name: 'Has been promoted to ', achievementType: AchievementTypeEnum.PromotedTo },
            // { id: AchievementTypeEnum.PromotedFrom, name: 'Has been promoted from ', achievementType: AchievementTypeEnum.PromotedFrom },
            // { id: AchievementTypeEnum.RelegatedFrom, name: 'Has avoided relegation from ', achievementType: AchievementTypeEnum.RelegatedFrom },
        ],
        headCoachExperienceListAll: [
            { id: HeadCoachExperienceEnum.OverTwoYears, name: 'Over 2yrs' },
            { id: HeadCoachExperienceEnum.OverFiveYears, name: 'Over 5yrs' },
            { id: HeadCoachExperienceEnum.OverEightYears, name: 'Over 8yrs' },
            { id: HeadCoachExperienceEnum.OverTenYears, name: '10yrs +' },
            { id: HeadCoachExperienceEnum.HasPlayingCareer, name: 'Has Playing Career' },
        ],
        availableNationalities: [],
    },
    justReleasedSet: false,

    sortBy: SortByKeyEnum.XtvHighToLow,
    structureLoading: false,
    structureWasLoaded: false,
    suggestionsLoading: false,
    mpMaxUpdatedFromServer: false,
    marketValue: null,

    positionFilterModal: null,
    transferTypeFilterModal: null,
    playerAttributesFilterModal: null,
    coachAttributesFilterModal: null,
    selectLeaguesModal: null,
    selectNationalityModal: null,

    showOnlyPlayersLookingForAgent: false,
    showOnlyFreeAgentCoaches: false,
    isQuickSearch: false,
    userActivityData: [],
    positions: [],
    isPositionsLoading: false,
    showOnlyShortListedPlayers: false,
    
    deleteModal: {
        isListDeleted: false,
        isModalOpen: false,
        deletedListId: null,
    },

    playingStyleRolesList: []
};

const stateController = new StateController<SearchState>(
    'SQUAD_SEARCH/FILTER',
    defaultState,
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static init() {
        return (dispatch, getState: () => AppState) => {

            dispatch(userActivityInsert({
                Message: 'Opened Player Search',
                PageName: 'Search',
                PageType: PageType.Search
            }))
        }
    }

    public static loadPlayerPositions = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isPositionsLoading: true }));
                const { positions } = await SearchService.getPlayerPositions();
                dispatch(stateController.setState({ positions }));
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isPositionsLoading: false }));
            }
        }
    }

    public static loadCriteria = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ structureLoading: true }));

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();
                const criteria = await SearchService.getCriteria(Actions.token.token);
                const structure = getState().squadSearch.filter;
                const justReleasedAge = {min: 16, max: 20};
                
                dispatch(stateController.setState((prevState: SearchState) => ({
                    ...prevState,
                    playerAttributesStructure: {
                        ...prevState.playerAttributesStructure,
                        currentLeagueList: [...criteria.customLeagueList.map(item => ({ ...item, checked: false }))],
                        previousLeagueList: [...criteria.customLeagueList.map(item => ({ ...item, checked: false }))],
                        age: criteria.playerCriteria.age,
                        height: criteria.playerCriteria.height,
                        rating: criteria.playerCriteria.rating.default,
                    },
                    coachAttributesStructure: {
                        ...prevState.coachAttributesStructure,
                        rating: criteria.coachCriteria.rating.default,
                        leagueExperienceList: [...criteria.customLeagueList.map(item => ({ ...item, checked: false }))],
                    },
                    structure: {
                        ...prevState.structure,
                        age: criteria.playerCriteria.age,
                        rating: criteria.playerCriteria.rating.default,
                        coachRating: criteria.coachCriteria.rating.default,
                    },
                    playerAttributesFilter: {
                        ...prevState.playerAttributesFilter,
                        filterData: {
                            ...prevState.playerAttributesFilter.filterData,
                            age: structure.justReleasedSet ? justReleasedAge : criteria.playerCriteria.age,
                            height: criteria.playerCriteria.height,
                            rating: criteria.playerCriteria.rating.default,
                            currentLeagueList: [...criteria.customLeagueList.map(item => ({ ...item, checked: false }))],
                            previousLeagueList: [...criteria.customLeagueList.map(item => ({ ...item, checked: false }))], 
                        },
                        ambitious: criteria.playerCriteria.rating.ambitious,
                        matchingMe: criteria.playerCriteria.rating.matchingMe,
                        availableNationalities: criteria.playerCriteria.availableNationalities
                    },
                    coachAttributesFilter: {
                        ...prevState.coachAttributesFilter,
                        filterData: {
                            ...prevState.coachAttributesFilter.filterData,
                            rating: criteria.coachCriteria.rating.default,
                            leagueExperienceList: [...criteria.customLeagueList.map(item => ({ ...item, checked: false }))],
                        },
                        ambitious: criteria.coachCriteria.rating.ambitious,
                        matchingMe: criteria.coachCriteria.rating.matchingMe,
                        availableNationalities: criteria.coachCriteria.availableNationalities
                    },
                    latestSearches: criteria.latestFilters,
                    availableLeagues: criteria.availableLeagues,
                    currentCountry: criteria.country,
                    currentLeague: criteria.league,
                    mpMaxUpdatedFromServer: true,
                    structureLoading: false,
                    structureWasLoaded: true,
                    playingStyleRolesList: criteria.playerCriteria.positionRoles,
                    justReleasedSet: false,
                })))
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ structureLoading: false }));
            }
        }
    }

    public static loadDynamicCriteria = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subState = Selectors.getCoachAttributesFilter(getState());
                const { achievementListAll } = subState;

                const { coachSearchFilterDynamicCriteria } = await SearchService.getDynamicCriteria();

                if (coachSearchFilterDynamicCriteria?.availableAchievements?.length > 0) {
                    const availableAchievementsExtended = coachSearchFilterDynamicCriteria.availableAchievements.map((x) => {
                        return ({
                            id: x.achievementType,
                            name: Helpers.getAchievementName(x.achievementType),
                            achievementType: x.achievementType,
                        })
                    });

                    const achievementListAllExtended = [...achievementListAll, ...availableAchievementsExtended]

                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        coachAttributesFilter: {
                            ...prevState.coachAttributesFilter,
                            achievementListAll: achievementListAllExtended
                        }
                    })));
                }
            } catch (err) {
                console.error(err);
            } finally {

            }
        }
    }
    // ---------- Short Listed Player Filter START  ---------- //

    public static setShowOnlyShortListedPlayers(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ showOnlyShortListedPlayers: value }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        }
    }
    
    public static clearShowOnlyShortListedPlayersFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                showOnlyShortListedPlayers: false
            })));
        }
    }
    
    // ---------- Short Listed Player Filter END  ---------- //
    
    // ---------- Position Filters START  ---------- //
    public static togglePositionFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const position = Selectors.getPositionFilter(getState())
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                positionFilterModal: isOpen ? { position } : null,
            })))
        }
    }

    public static clearPositionFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                positionFilter: null,
            })));
            dispatch(Actions.disposePlayingStyleRolesFilter())
        }
    }

    public static clearCoachFreeAgentFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                showOnlyFreeAgentCoaches: false
            })));
        }
    }

    public static clearPositionFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearPositionFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            // dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setPositionFilterValue(positionId: SearchPlayerPositionEnum, itemName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ positionFilter: positionId }));
            dispatch(Actions.sendUserActivity(`Position: ${getPositionCodeByName(itemName)}`, 'Player Search [Filter]'));
        }
    }

    public static applyPositionFilter(positionId: SearchPlayerPositionEnum, positionName: string) {
        return (dispatch, getState: () => AppState) => {
            const position = positionName === positionFullNames.W || positionName === positionFullNames.F ?
                positionName :
                getPositionCodeByName(positionName)

            dispatch(stateController.setState({ positionFilter: positionId }));

            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            dispatch(Actions.togglePositionFilterModal(false));
            dispatch(Actions.sendUserActivity(`Position: ${position}`, 'Player Search [Filter]'));
        }
    }

    public static onSelectPlayerPosition(positionId: SearchPlayerPositionEnum, positionName: string){
        return (dispatch, getState: () => AppState) => {
            const position = positionName === positionFullNames.W || positionName === positionFullNames.F ?
                positionName :
                getPositionCodeByName(positionName)

            dispatch(Actions.disposePlayingStyleRolesFilter())
            dispatch(Actions.sendUserActivity(`Position: ${position}`, 'Player Search [Filter By Position]'))
        }
    }

    // ---------- Position Filters END  ---------- //


    // ---------- OnlyAvailablePlayers Filters START  ---------- //
    public static setFilterByOnlyAvailablePlayers() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeFilter: {
                    ...prevState.transferTypeFilter,
                    showOnlyAvailablePlayers: true
                },
            })));
        }
    }
    // ---------- OnlyAvailablePlayers Filters END  ---------- //
    // ---------- Only JustReleasedPlayers START  ---------- //
    public static setFilterByJustReleasedPlayers() {
        return (dispatch, getState: () => AppState) => {
            const englishNationality = [80];
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                justReleasedSet: true,
                transferTypeFilter: {
                    ...prevState.transferTypeFilter,
                    showOnlyAvailablePlayers: true,
                },
                playerAttributesFilter: {
                    ...prevState.playerAttributesFilter,
                    filterData: {
                        ...prevState.playerAttributesFilter.filterData,
                        age: {
                            min: 16,
                            max: 20,
                        },
                        showOnlyFreeAgentPlayers: true,
                        nationalityList: englishNationality
                    }
                }
            })));
        }
    }
    // ---------- Only JustReleasedPlayers END  ---------- //
    public static removeJustReleased() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                justReleasedSet: false,
            })));
        }
    }
    // ---------- Transfer Type Filters START  ---------- //
    public static toggleTransferTypeFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const modalData = Selectors.getTransferTypeFilterModalInitial(getState())
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeFilterModal: isOpen ? { ...modalData } : null,
            })));
        }
    }

    public static clearTransferTypeFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeFilter: { ...getTransferTypeFilterCriteria() },
            })));
        }
    }

    public static setTransferTypeValue(key: string, value: any) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeFilterModal: {
                    ...prevState.transferTypeFilterModal,
                    [key]: value
                },
            })));
        }
    }

    public static clearTransferTypeFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearTransferTypeFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            // dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static clearTransferTypeFilterRowParams(params: string[]) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState());
            const clearParams = [...params];

            clearParams.forEach((param) => {

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    transferTypeFilterModal: {
                        ...prevState.transferTypeFilterModal,
                        [`${param}`]: structure[`${param}`]
                    },
                })));

            });
        }
    }

    public static applyTransferTypeFilter() {
        return (dispatch, getState: () => AppState) => {
            const filterData = Selectors.getTransferTypeFilterModal(getState());
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeFilter: {
                    ...prevState.transferTypeFilter,
                    showOnlyAvailablePlayers: filterData.showOnlyAvailablePlayers,
                    transferFee: {
                        ...prevState.transferTypeFilter.transferFee,
                        max: filterData.transferFee
                    },
                    anuallGrossSalaryYearly: {
                        ...prevState.transferTypeFilter.anuallGrossSalaryYearly,
                        max: filterData.anuallGrossSalaryYearly
                    },
                    loanFee: {
                        ...prevState.transferTypeFilter.loanFee,
                        max: filterData.loanFee
                    }
                },
                sortBy: filterData.showOnlyAvailablePlayers ? SortByKeyEnum.AvailableSinceNewestToOldest : prevState.sortBy
            })));

            if (filterData.transferFee !== null || filterData.anuallGrossSalaryYearly !== null) {
                dispatch(Actions.sendUserActivity(
                    `To Buy: (${filterData.transferFee !== null ? `Fee: ${filterData.transferFee}` : ''}${(filterData.transferFee !== null && filterData.anuallGrossSalaryYearly !== null) ? ' + ' : ''}${filterData.anuallGrossSalaryYearly !== null ? (`Gross Salary: ${filterData.anuallGrossSalaryYearly}`) : ''})`,
                    'Player Search [Filter]'
                )
                )
            }

            if (filterData.loanFee !== null) {
                dispatch(Actions.sendUserActivity(`To Loan: ${filterData.loanFee}`, 'Player Search [Filter]'))
            }

            if (filterData.showOnlyAvailablePlayers) {
                dispatch(Actions.sendUserActivity('Show only available players', 'Player Search [Filter]'))
            }

            dispatch(Actions.toggleTransferTypeFilterModal(false));

            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
            // dispatch(GridActions.Actions.refresh(action));

            dispatch(Actions.toggleTransferTypeFilterModal(false));

            // dispatch(Actions.saveLatestFilterSet())
        }
    }

    // ---------- Transfer Type Filters END  ---------- //

    // ---------- Player Attributes START  ---------- //
    public static togglePlayerAttributesFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getPlayerAttributesFilter(getState())
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: isOpen ? { ...filter } : null,
            })));
            
            if (isOpen) {
                dispatch(Actions.getPlayingStyleRoles())
            }
        }
    }

    public static clearPlayerAttributesFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilter: {
                    ...prevState.playerAttributesFilter,
                    filterData: {
                        ...prevState.playerAttributesFilter.filterData,
                        ...prevState.playerAttributesStructure,
                    }
                },
            })));
        }
    }

    public static clearPlayerAttributesFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearPlayerAttributesFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            // dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setPlayerAttributesValue(e: any) {
        return (dispatch, getState: () => AppState) => {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        [e.target.name]: value
                    }
                },
            })));
        }
    }

    public static setPlayerMinAge(e: any) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState());
            if (Number(e.target.value) > structure.age.max) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        age: {
                            ...prevState.playerAttributesFilterModal.filterData.age,
                            min: e.target.value !== null ? Number(e.target.value) : e.target.value
                        }
                    }
                },
            })));
        }
    }

    public static setPlayerMaxAge(e: any) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState());
            
            if (Number(e.target.value) > structure.age.max) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        age: {
                            ...prevState.playerAttributesFilterModal.filterData.age,
                            max: e.target.value !== null ? Number(e.target.value) : e.target.value
                        }
                    }
                },
            })));
        }
    }
    public static setPlayerMinHeight(value: number) {
        return (dispatch, getState: () => AppState) => {
            
            if (value > 200) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        height: {
                            ...prevState.playerAttributesFilterModal.filterData.height,
                            min: value,
                        },
                    }
                },
            })));
        }
    }

    public static setPlayerMaxHeight(value: number) {
        return (dispatch, getState: () => AppState) => {

            if (value > 200) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        height: {
                            ...prevState.playerAttributesFilterModal.filterData.height,
                            max: value,
                        },
                    }
                },
            })));
        }
    }

    public static onChangePlayerAge(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        age: {
                            ...prevState.playerAttributesFilterModal.filterData.age,
                            min: Number(value.min),
                            max: Number(value.max)
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static onChangePlayerHeight(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        height: {
                            ...prevState.playerAttributesFilterModal.filterData.height,
                            min: Number(value.min),
                            max: Number(value.max)
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static setPlayerMinRating(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > 100) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        rating: {
                            ...prevState.playerAttributesFilterModal.filterData.rating,
                            min: value
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static setPlayerMaxRating(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > 100) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        rating: {
                            ...prevState.playerAttributesFilterModal.filterData.rating,
                            max: value
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static onChangePlayerRange(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        rating: {
                            ...prevState.playerAttributesFilterModal.filterData.rating,
                            min: Number(value.min),
                            max: Number(value.max)
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static setPlayerAmbitiousRating(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const ambitiousCriteria = Selectors.getPlayerAttributesFilter(getState()).ambitious;


            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        isAmbitious: value,
                        isMatchingMe: false,
                        rating: {
                            min: value ? ambitiousCriteria.min : subState.structure.rating.min,
                            max: value ? ambitiousCriteria.max : subState.structure.rating.max,
                        }
                    }
                },
            })));
        }
    }

    public static setPlayerMatchingMeRating(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const matchingMeCriteria = Selectors.getPlayerAttributesFilter(getState()).matchingMe;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        isMatchingMe: value,
                        isAmbitious: false,
                        rating: {
                            min: value ? matchingMeCriteria.min : subState.structure.rating.min,
                            max: value ? matchingMeCriteria.max : subState.structure.rating.max,
                        }
                    }
                },
            })));
        }
    }

    public static clearPlayerAttributesFilterRowParams(params: string[]) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState());
            // const filterDataState = Selectors.getPlayerAttributesModal(getState()).filterData;
            const clearParams = [...params];

            clearParams.forEach((param) => {

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    playerAttributesFilterModal: {
                        ...prevState.playerAttributesFilterModal,
                        filterData: {
                            ...prevState.playerAttributesFilterModal.filterData,
                            [`${param}`]: structure[`${param}`]
                        }
                    },
                })));

            });
        }
    }
    public static getNamesByIds(arr1, arr2) {
        return  arr1.filter(item => arr2.includes(item.id)).map(item => item.name);
    }
    public static getPlayerLeaguesUA() {
        return (dispatch, getState: () => AppState) => {
            const filterData = Selectors.getPlayerAttributesModal(getState()).filterData;
            const availableLeagues = Selectors.getRoot(getState()).availableLeagues;
            if(filterData.leaguesList.length !==0) {
                const nameList = Actions.getNamesByIds(availableLeagues, filterData.leaguesList);
                dispatch(Actions.storeUserActivityData(`Current League: ${nameList}`, 'Player Search [Filter]','currentLeague'));
            }
            if(filterData.previousLeaguesList.length !==0) {
                const nameList = Actions.getNamesByIds(availableLeagues, filterData.previousLeaguesList);
                dispatch(Actions.storeUserActivityData(`Previous League: ${nameList}`, 'Player Search [Filter]', 'previousLeague'));
            }
            
            const currentLeagues = filterData.currentLeagueList.filter(item=>item.checked);
            
            if(currentLeagues.length !==0) {
                const listMessage = currentLeagues.map(item => {
                    return `${item.name} (${Actions.getNamesByIds(availableLeagues, item.competitionIds)})`}
                );
                dispatch(Actions.storeUserActivityData(`Current League List: '${listMessage}'`, 'Player Search [Filter]','currentLeagueList'));
            }
            const previousLeagues = filterData.previousLeagueList.filter(item=>item.checked);
            
            if(previousLeagues.length !==0) {
                const listMessage = previousLeagues.map(item => {
                    return `${item.name} (${Actions.getNamesByIds(availableLeagues, item.competitionIds)})`}
                );
                dispatch(Actions.storeUserActivityData(`Previous League List: '${listMessage}'`, 'Player Search [Filter]','previousLeagueList'));
            }
        }
    }
    
    public static applyPlayerAttributesFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPlayerLeaguesUA());
            const filterData = Selectors.getPlayerAttributesModal(getState()).filterData;
            const currentUserActivityData = Selectors.getRoot(getState()).userActivityData;
            
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilter: {
                    ...prevState.playerAttributesFilter,
                    filterData: {
                        ...prevState.playerAttributesFilter.filterData,
                        ...filterData,
                    }
                },
            })));
            
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            dispatch(Actions.togglePlayerAttributesFilterModal(false));

            // dispatch(Actions.saveLatestFilterSet());

            currentUserActivityData.map(item => {
                dispatch(Actions.sendUserActivity(item.message, item.pageType));
            })
        }
    }
    // ---------- Player Attributes END  ---------- //

    // ---------- Coach Attributes START  ---------- //

    public static toggleCoachAttributesFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getCoachAttributesFilter(getState())
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: isOpen ? { ...filter } : null,
            })));
        }
    }

    public static clearCoachAttributesFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilter: {
                    ...prevState.coachAttributesFilter,
                    filterData: {
                        ...prevState.coachAttributesFilter.filterData,
                        ...prevState.coachAttributesStructure,
                    }
                },
            })));
        }
    }

    public static clearCoachAttributesFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearCoachAttributesFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            // dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setCoachAttributesValue(e: any) {
        return (dispatch, getState: () => AppState) => {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        [e.target.name]: value
                    }
                },
            })));
        }
    }

    public static clearCoachAttributesFilterRowParams(params: string[]) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState());
            // const filterDataState = Selectors.getPlayerAttributesModal(getState()).filterData;
            const clearParams = [...params];

            clearParams.forEach((param) => {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    coachAttributesFilterModal: {
                        ...prevState.coachAttributesFilterModal,
                        filterData: {
                            ...prevState.coachAttributesFilterModal.filterData,
                            [`${param}`]: structure[`${param === 'rating' ? 'coachRating' : param}`]
                        }
                    },
                })));
            });
        }
    }

    public static setCoachMinRating(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > 100) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        rating: {
                            ...prevState.coachAttributesFilterModal.filterData.rating,
                            min: !!value ? Number(value) : value
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static setCoachMaxRating(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > 100) {
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        rating: {
                            ...prevState.coachAttributesFilterModal.filterData.rating,
                            max: !!value ? Number(value) : value
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static onChangeCoachRange(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        rating: {
                            ...prevState.coachAttributesFilterModal.filterData.rating,
                            min: Number(value.min),
                            max: Number(value.max)
                        },
                        isAmbitious: false,
                        isMatchingMe: false,
                    }
                },
            })));
        }
    }

    public static setCoachAmbitiousRating(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const ambitiousCriteria = Selectors.getCoachAttributesFilter(getState()).ambitious;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        isAmbitious: value,
                        isMatchingMe: false,
                        rating: {
                            min: value ? ambitiousCriteria.min : subState.structure.coachRating.min,
                            max: value ? ambitiousCriteria.max : subState.structure.coachRating.max,
                        }
                    }
                },
            })));
        }
    }

    public static setCoachMatchingMeRating(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const matchingMeCriteria = Selectors.getCoachAttributesFilter(getState()).matchingMe;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        isMatchingMe: value,
                        isAmbitious: false,
                        rating: {
                            min: value ? matchingMeCriteria.min : subState.structure.coachRating.min,
                            max: value ? matchingMeCriteria.max : subState.structure.coachRating.max,
                        }
                    }
                },
            })));
        }
    }

    public static setCoachHighSuitability(value: boolean) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        isHighSuitability: value,
                    }
                },
            })));
        }
    }

    public static setCoachHighTrustInYouth(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const structureTrustInYouth = subState.structure.trustInYouth;
            const highTrustInYouthCriteria = Selectors.getCoachAttributesFilter(getState()).highTrustInYouth;
            const isMediumTrustInYouth = Selectors.getCoachAttributesModal(getState()).filterData.isMediumTrustInYouth;
            const mediumTrustInYouthCriteria = Selectors.getCoachAttributesFilter(getState()).mediumTrustInYouth;

            const getTrustInYouthRange = () => {
                if (value) {
                    if (isMediumTrustInYouth) {
                        return {
                            min: mediumTrustInYouthCriteria.min,
                            max: highTrustInYouthCriteria.max,
                        }
                    } else {
                        return {
                            min: highTrustInYouthCriteria.min,
                            max: highTrustInYouthCriteria.max,
                        }
                    }
                } else {
                    if (isMediumTrustInYouth) {
                        return {
                            min: mediumTrustInYouthCriteria.min,
                            max: mediumTrustInYouthCriteria.max,
                        }
                    } else {
                        return {
                            min: structureTrustInYouth.min,
                            max: structureTrustInYouth.max,
                        }
                    }
                }
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        isHighTrustInYouth: value,
                        trustInYouth: getTrustInYouthRange()
                    }
                },
            })));
        }
    }

    public static setCoachMediumTrustInYouth(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const structureTrustInYouth = subState.structure.trustInYouth;
            const mediumTrustInYouthCriteria = Selectors.getCoachAttributesFilter(getState()).mediumTrustInYouth;
            const isHighTrustInYouth = Selectors.getCoachAttributesModal(getState()).filterData.isHighTrustInYouth;
            const highTrustInYouthCriteria = Selectors.getCoachAttributesFilter(getState()).highTrustInYouth;

            const getTrustInYouthRange = () => {
                if (value) {
                    if (isHighTrustInYouth) {
                        return {
                            min: mediumTrustInYouthCriteria.min,
                            max: highTrustInYouthCriteria.max,
                        }
                    } else {
                        return {
                            min: mediumTrustInYouthCriteria.min,
                            max: mediumTrustInYouthCriteria.max,
                        }
                    }
                } else {
                    if (isHighTrustInYouth) {
                        return {
                            min: highTrustInYouthCriteria.min,
                            max: highTrustInYouthCriteria.max,
                        }
                    } else {
                        return {
                            min: structureTrustInYouth.min,
                            max: structureTrustInYouth.max,
                        }
                    }
                }
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        isMediumTrustInYouth: value,
                        trustInYouth: getTrustInYouthRange()
                    }
                },
            })));
        }
    }

    public static setMostUsedFormations(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getCoachAttributesModal(getState()).filterData;
            const formationList = filterDataState.formationList;

            let formationListUpdated;
            if (formationList.includes(id)) {
                formationListUpdated = formationList.filter(x => x != id)
            } else {
                formationListUpdated = [...formationList, id]
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        formationList: [...formationListUpdated]
                    }
                },
            })));
        }
    }

    public static setCoachingLicence(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getCoachAttributesModal(getState()).filterData;
            const licenseTypeList = filterDataState.licenseTypeList;

            let licenseTypeListUpdated;
            if (licenseTypeList.includes(id)) {
                licenseTypeListUpdated = licenseTypeList.filter(x => x != id)
            } else {
                licenseTypeListUpdated = [...licenseTypeList, id]
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        licenseTypeList: [...licenseTypeListUpdated]
                    }
                },
            })));
        }
    }

    public static setPlayingStyle(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getCoachAttributesModal(getState()).filterData;
            const playingStyleList = filterDataState.playingStyleList;

            let playingStyleListUpdated;
            if (playingStyleList.includes(id)) {
                playingStyleListUpdated = playingStyleList.filter(x => x != id)
            } else {
                if (id === 1) {
                    playingStyleListUpdated = [...playingStyleList.filter(x => x != 2), id];
                }
                if (id === 2) {
                    playingStyleListUpdated = [...playingStyleList.filter(x => x != 1), id]
                }
                if (id === 3) {
                    playingStyleListUpdated = [...playingStyleList.filter(x => x != 4), id]
                }
                if (id === 4) {
                    playingStyleListUpdated = [...playingStyleList.filter(x => x != 3), id]
                }
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        playingStyleList: [...playingStyleListUpdated]
                    }
                },
            })));

            dispatch(Actions.storePlayingStyleUserActivity());
        }
    }

    public static storePlayingStyleUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getCoachAttributesModal(getState()).filterData.playingStyleList;
            const playingStyleList = Selectors.getRoot(getState()).coachAttributesFilter.playingStyleListAll;
            dispatch(Actions.clearAllCategory('Favours'));

            filterDataState.map(item => {
                const matchingObject = playingStyleList.find(obj => obj.id === item);

                if (matchingObject) {
                    dispatch(Actions.storeUserActivityData(`Playing Style: '${playingStyleList[item - 1].name}'`, 'Coach Search [Filter]', playingStyleList[item - 1].name));
                }
                return null;
            });
        }
    }

    public static setHeadCoachExperience(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getCoachAttributesModal(getState()).filterData;
            const headCoachExperienceList = filterDataState.headCoachExperienceList;
            const { OverTwoYears, OverFiveYears, OverEightYears, OverTenYears, HasPlayingCareer } = HeadCoachExperienceEnum;

            let headCoachExperienceListUpdated;
            if (headCoachExperienceList.includes(id)) {
                headCoachExperienceListUpdated = headCoachExperienceList.filter(x => x != id)
            } else {
                if (id === OverTwoYears) {
                    headCoachExperienceListUpdated = [...headCoachExperienceList.filter(x => x != OverFiveYears && x != OverEightYears && x != OverTenYears), id]
                }
                if (id === OverFiveYears) {
                    headCoachExperienceListUpdated = [...headCoachExperienceList.filter(x => x != OverTwoYears && x != OverEightYears && x != OverTenYears), id]
                }
                if (id === OverEightYears) {
                    headCoachExperienceListUpdated = [...headCoachExperienceList.filter(x => x != OverTwoYears && x != OverFiveYears && x != OverTenYears), id]
                }
                if (id === OverTenYears) {
                    headCoachExperienceListUpdated = [...headCoachExperienceList.filter(x => x != OverTwoYears && x != OverFiveYears && x != OverEightYears), id]
                }
                if (id === HasPlayingCareer) {
                    headCoachExperienceListUpdated = [...headCoachExperienceList.filter(x => x != HasPlayingCareer), id]
                }
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        headCoachExperienceList: [...headCoachExperienceListUpdated]
                    }
                },
            })));
        }
    }

    public static setCareerAchievement(selectedAchievement: AchievementItem) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getCoachAttributesModal(getState()).filterData;
            const achievementList = filterDataState.achievementList;
            const achievementIndex = achievementList.findIndex(x => x.achievementType === selectedAchievement.achievementType);

            let achievementListUpdated;
            if (achievementIndex !== -1) {
                achievementListUpdated = achievementList.filter(x => x.achievementType !== selectedAchievement.achievementType)
            } else {
                achievementListUpdated = [...achievementList, selectedAchievement]
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilterModal: {
                    ...prevState.coachAttributesFilterModal,
                    filterData: {
                        ...prevState.coachAttributesFilterModal.filterData,
                        achievementList: [...achievementListUpdated]
                    }
                },
            })));
        }
    }

    public static headCoachExperienceUA(currentUserActivityData: userActivityItem[]) {
        return (dispatch, getState: () => AppState) => {
            const coachExperienceItems = currentUserActivityData.filter(item => item.shouldToJoin);
            const coachExperience = coachExperienceItems.find(item => item.id === 'coachExperience').message.split(': ');
            const hasPlayingCareer = coachExperienceItems.find(item => item.id === 'hasPlayingCareer').message.split(': ');
            let joinedMessage = '';

            if (coachExperience[0] === hasPlayingCareer[0]) {
                joinedMessage = `${coachExperience[0]}: ${coachExperience[1]}, ${hasPlayingCareer[1]}`;
            }

            const newJoinedItem = {
                id: "coachExperienceJoin",
                message: joinedMessage,
                pageType: "Coach Search [Filter]",
            };

            const filteredCurrentUserActivityData = currentUserActivityData.filter(
                item => !item.shouldToJoin
            );
            const updatedCurrentUserActivityData = [...filteredCurrentUserActivityData, newJoinedItem];

            updatedCurrentUserActivityData.map(item => {
                dispatch(Actions.sendUserActivity(item.message, item.pageType));
            });
        }
    }
    
    public static getCoachLeaguesUA() {
        return (dispatch, getState: () => AppState) => {
            const filterData = Selectors.getCoachAttributesModal(getState()).filterData;
            const availableLeagues = Selectors.getRoot(getState()).availableLeagues;
            if(filterData.leaguesList.length !==0) {
                const nameList = Actions.getNamesByIds(availableLeagues, filterData.leaguesList);
                dispatch(Actions.storeUserActivityData(`League Experience: ${nameList}`, 'Coach Search [Filter]','currentLeague'));
            }
            
            const experienceLeagues = filterData.leagueExperienceList.filter(item=>item.checked);
            
            if(experienceLeagues.length !==0) {
                const listMessage = experienceLeagues.map(item => {
                    return `${item.name} (${Actions.getNamesByIds(availableLeagues, item.competitionIds)})`}
                );
                dispatch(Actions.storeUserActivityData(`League List: '${listMessage}'`, 'Coach Search [Filter]','experienceLeagueList'));
            }
        }
    }
    
    public static applyCoachAttributesFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getCoachLeaguesUA());
            const filterData = Selectors.getCoachAttributesModal(getState()).filterData;
            const currentUserActivityData = Selectors.getRoot(getState()).userActivityData;
            
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                coachAttributesFilter: {
                    ...prevState.coachAttributesFilter,
                    filterData: {
                        ...prevState.coachAttributesFilter.filterData,
                        ...filterData
                    }
                },
            })));

            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            dispatch(Actions.toggleCoachAttributesFilterModal(false));

            // dispatch(Actions.saveLatestFilterSet())

            const coachExperienceItems = currentUserActivityData.filter(item => item.shouldToJoin);

            if (coachExperienceItems.length > 1) {
                dispatch(Actions.headCoachExperienceUA(currentUserActivityData));
            } else {
                currentUserActivityData.map(item => {
                    dispatch(Actions.sendUserActivity(item.message, item.pageType));
                })
            }
            
        }
    }

    // ---------- Coach Attributes END  ---------- //

    // ---------- Select Leagues modal START  ---------- //
    public static toggleSelectLeaguesModal(isOpen: boolean, modalKey?: string, listKey?: string, searchType?: string) {
        return (dispatch, getState: () => AppState) => {
            if (isOpen) {
                const selectedLeagues = Selectors.getRoot(getState())[modalKey].filterData[listKey];
                const modalData = {
                    modalKey,
                    listKey,
                    searchType,
                    selectedLeagues: [...selectedLeagues],
                };

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    selectLeaguesModal: { ...modalData }
                })));
            } else {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    selectLeaguesModal: null
                })));
            }
        }
    }

    public static clearSelectLeaguesModal() {
        return dispatch => {
            console.log('CLEAR current selected countries');
        }
    }

    public static applySelectLeaguesModal(selectedLeagues, modalKey?: string, listKey?: string) {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                [`${modalKey}`]: {
                    ...prevState[`${modalKey}`],
                    filterData: {
                        ...prevState[`${modalKey}`].filterData,
                        [`${listKey}`]: [...selectedLeagues]
                    }
                },
            })));
            dispatch(Actions.toggleSelectLeaguesModal(false));
        }
    }
    // ---------- Select Leagues modal END  ---------- //


    // ---------- Select Nationality modal START  ---------- //
    public static toggleSelectNationalityModal(isOpen: boolean, searchType?: 'Player' | 'Coach') {
        return (dispatch, getState: () => AppState) => {
            if (isOpen) {
                const modalKey = searchType === 'Player' ? 'playerAttributesFilterModal' : 'coachAttributesFilterModal';
                const filterModal = Selectors.getRoot(getState())[modalKey];
                const listKey = 'nationalityList';
                const selectedNationalities = filterModal.filterData[listKey];
                const modalData: SelectNationalityModalData = {
                    searchType,
                    modalKey,
                    listKey,
                    selectedNationalities: [...selectedNationalities],
                    availableNationalities: filterModal.availableNationalities
                };

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    selectNationalityModal: { ...modalData }
                })));
            } else {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    selectNationalityModal: null
                })));
            }
        }
    }

    public static clearSelectNationalityModal() {
        return dispatch => {
            console.log('CLEAR current selected countries');
        }
    }

    public static applySelectNationalityModal(selectedNationalities: number[], modalKey?: 'playerAttributesFilterModal' | 'coachAttributesFilterModal', listKey?: 'nationalityList') {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                [`${modalKey}`]: {
                    ...prevState[`${modalKey}`],
                    filterData: {
                        ...prevState[`${modalKey}`].filterData,
                        [`${listKey}`]: [...selectedNationalities]
                    }
                },
            })));
            dispatch(Actions.toggleSelectNationalityModal(false));
        }
    }
    // ---------- Select Nationality modal END  ---------- //

    public static setShowOnlyFreeAgentCoaches(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ showOnlyFreeAgentCoaches: value }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        }
    }


    public static resetFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearTransferTypeFilter());
            dispatch(Actions.clearPositionFilter());
            dispatch(Actions.clearPlayerAttributesFilter());
            dispatch(Actions.clearShowOnlyShortListedPlayersFilter())
            dispatch(GridActions.resetPage());
            dispatch(Actions.initSorting());
        }
    }

    public static resetCoachFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearCoachFreeAgentFilter());
            dispatch(Actions.clearCoachAttributesFilter());
        }
    }



    // public static init(gridState: GridActions.GridToggleState) {
    //     return (dispatch, getState: () => AppState) => {
    //         const activeTabWasChanged = GridActions.Selectors.getGridState(getState()) !== gridState
    //         if (activeTabWasChanged) dispatch(Actions.disposeFilters())
    //
    //         dispatch(GridActions.Actions.toggleGridState(gridState))
    //         // dispatch(Actions.restoredFilter(gridState)) // disable filter storage
    //         dispatch(GridActions.Actions.resetPage())
    //         dispatch(Suggestions.Actions.onKeywordClearWithoutRefresh())
    //         dispatch(GridActions.Actions.load(gridState))
    //     }
    // }

    public static resetFilterAsync() {
        return dispatch => {
            dispatch(Actions.resetFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
            dispatch(Actions.clearAllUserActivityData());
        }
    }

    public static resetCoachFilterAsync() {
        return dispatch => {
            dispatch(Actions.resetCoachFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
            dispatch(Actions.clearAllUserActivityData());
        }
    }

    public static applySorting(sortBy: SortByKeyEnum, column: string) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridSelectors.getGridState(getState())

            dispatch(stateController.setState({ sortBy }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            const specificState = gridState === GridToggleState.ShortList ? 'Shortlist' : '';
            const container = specificState ? `${specificState}-Sort by` : 'Sort by';
            const pageType = gridState === GridToggleState.PlayersSearch ? 'Player' : gridState === GridToggleState.HeadCoachesSearch ? 'Coach' : ''
            dispatch(userActivityInsert({
                Message: column,
                PageType: PageType.Search,
                PageName: `${pageType} Search${container ? ` [${container}]` : ''}`
            }))
        }
    }

    public static initSorting(definedSorting: SortByKeyEnum = null) {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            const gridState = GridSelectors.getGridState(state);

            if (definedSorting != null) {
                dispatch(stateController.setState({ sortBy: definedSorting }));
                return;
            }

            let sortBy;

            if (Selectors.isAvailable(state)) {
                sortBy = SortByKeyEnum.Availability
            } else {
                sortBy = SortByKeyEnum.XtvHighToLow
            }

            if (gridState === GridToggleState.AgenciesSearch) {
                sortBy = SortByKeyEnum.Alphabetically;
            }

            if (gridState === GridToggleState.HeadCoachesSearch) {
                sortBy = SortByKeyEnum.ByRelevant;
            }

            dispatch(stateController.setState({ sortBy }));
        }
    }
    //
    public static updateLeaguesList(leagueId: number, action: string) {
        return (dispatch, getState: () => AppState) => {

            const substate = Selectors.getRoot(getState()).playerAttributesFilterModal.filterData.leaguesList;

            let updatedList = [];
            if (action === 'remove') {
                updatedList = substate.filter(id => id !== leagueId);
            }
            if (action === 'add') {
                updatedList = [...substate, leagueId]
            }

            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    playerAttributesFilterModal: {
                        ...prevState.playerAttributesFilterModal,
                        filterData: {
                            ...prevState.playerAttributesFilterModal.filterData,
                            leaguesList: updatedList,
                        }
                    }
                }
            }))
        }
    }
    
   public static updatePreviousLeaguesList(leagueId: number, action: string) {
        return (dispatch, getState: () => AppState) => {

            const substate = Selectors.getRoot(getState()).playerAttributesFilterModal.filterData.previousLeaguesList;

            let updatedList = [];
            if (action === 'remove') {
                updatedList = substate.filter(id => id !== leagueId);
            }
            if (action === 'add') {
                updatedList = [...substate, leagueId]
            }

            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    playerAttributesFilterModal: {
                        ...prevState.playerAttributesFilterModal,
                        filterData: {
                            ...prevState.playerAttributesFilterModal.filterData,
                            previousLeaguesList: updatedList,
                        }
                    }
                }
            }))
        }
    }
    
   public static updateLeagueExperienceList(leagueId: number, action: string) {
        return (dispatch, getState: () => AppState) => {

            const leagueList = Selectors.getRoot(getState()).coachAttributesFilterModal.filterData.leaguesList;
            
            let updatedList = [];
            if (action === 'remove') {
                updatedList = leagueList.filter(id => id !== leagueId);
            }
            if (action === 'add') {
                updatedList = [...leagueList, leagueId]
            }

            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    coachAttributesFilterModal: {
                        ...prevState.coachAttributesFilterModal,
                        filterData: {
                            ...prevState.coachAttributesFilterModal.filterData,
                            leaguesList: updatedList,
                        }
                    }
                }
            }))
        }
    }

    public static checkLeagueExperienceList(leagueId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    coachAttributesFilterModal: {
                        ...prevState.coachAttributesFilterModal,
                        filterData: {
                            ...prevState.coachAttributesFilterModal.filterData,
                            leagueExperienceList: [...prevState.coachAttributesFilterModal.filterData.leagueExperienceList.map(item => {
                                if (item.id === leagueId) {
                                    return {
                                        ...item,
                                        checked: !item.checked,
                                    }
                                }
                                return item;
                            })]
                        },
                    }
                }
            }))
        }
    }
    public static checkCustomLeaguesList(leagueId) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    playerAttributesFilterModal: {
                        ...prevState.playerAttributesFilterModal,
                        filterData: {
                            ...prevState.playerAttributesFilterModal.filterData,
                            currentLeagueList: [...prevState.playerAttributesFilterModal.filterData.currentLeagueList.map(item => {
                                if (item.id === leagueId) {
                                    return {
                                        ...item,
                                        checked: !item.checked,
                                    }
                                }
                                return item;
                            })]
                        },
                    }
                }
            }))
        }
    }
    
    public static checkPreviousLeagueList(leagueId) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    playerAttributesFilterModal: {
                        ...prevState.playerAttributesFilterModal,
                        filterData: {
                            ...prevState.playerAttributesFilterModal.filterData,
                            previousLeagueList: [...prevState.playerAttributesFilterModal.filterData.previousLeagueList.map(item => {
                                if (item.id === leagueId) {
                                    return {
                                        ...item,
                                        checked: !item.checked,
                                    }
                                }
                                return item;
                            })]
                        },
                    }
                }
            }))
        }
    }
    //     public static updateCustomLeaguesList(leagueId) {
    //     return (dispatch, getState: () => AppState) => {
    //         const isInLeagueList = Selectors.getRoot(getState()).structure.leaguesLists.indexOf(leagueId);
    //
    //         dispatch(stateController.setState((prevState) => {
    //             const updatedLeagueLists = isInLeagueList === 0 ? [...prevState.structure.leaguesLists.filter(id => id !== leagueId)] : [...prevState.playerAttributesFilterModal.filterData.leaguesLists, leagueId];
    //                 return {
    //                     ...prevState,
    //                     structure: {
    //                         ...prevState.structure,
    //                         leaguesLists: [...updatedLeagueLists]
    //                     }
    //                 }
    //         }))
    //     }
    // }
    //
    public static deleteCompetitionList(listId: number) {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                playerAttributesStructure: {
                    ...draftState.playerAttributesStructure,
                    currentLeagueList: [...draftState.playerAttributesStructure.currentLeagueList.filter(item => item.id !== listId)],
                    previousLeagueList: [...draftState.playerAttributesStructure.previousLeagueList.filter(item => item.id !== listId)],
                },
                coachAttributesStructure: {
                    ...draftState.coachAttributesStructure,
                    leagueExperienceList: [...draftState.coachAttributesStructure.leagueExperienceList.filter(item => item.id !== listId)],
                },
                playerAttributesFilter: {
                    ...draftState.playerAttributesFilter,
                    filterData: {
                        ...draftState.playerAttributesFilter.filterData,
                        currentLeagueList: [...draftState.playerAttributesFilter.filterData.currentLeagueList.filter(item => item.id !== listId)],
                        previousLeagueList: [...draftState.playerAttributesFilter.filterData.previousLeagueList.filter(item => item.id !== listId)],
                    }
                },
                coachAttributesFilter: {
                    ...draftState.coachAttributesFilter,
                    filterData: {
                        ...draftState.coachAttributesFilter.filterData,
                        leagueExperienceList: [...draftState.coachAttributesFilter.filterData.leagueExperienceList.filter(item => item.id !== listId)],
                    }
                }
            })));
            dispatch(stateController.setState((draftState) => {
                if (draftState.playerAttributesFilterModal) {
                    return {
                        ...draftState,
                        playerAttributesFilterModal: {
                            ...draftState.playerAttributesFilterModal,
                            filterData: {
                                ...draftState.playerAttributesFilterModal.filterData,
                                currentLeagueList: [...draftState.playerAttributesFilterModal.filterData.currentLeagueList.filter(item => item.id !== listId)],
                                previousLeagueList: [...draftState.playerAttributesFilterModal.filterData.previousLeagueList.filter(item => item.id !== listId)],
                            }
                        },
                    }
                }
                if (draftState.coachAttributesFilterModal) {
                    return {
                        ...draftState,
                        coachAttributesFilterModal: {
                            ...draftState.coachAttributesFilterModal,
                            filterData: {
                                ...draftState.coachAttributesFilterModal.filterData,
                                leagueExperienceList: [...draftState.coachAttributesFilterModal.filterData.leagueExperienceList.filter(item => item.id !== listId)],
                            }
                        }
                    }
                }
            }))
        }
    }
    
    public static addLeagueList(list: CustomLeagueList) {
        return (dispatch, getState: () => AppState) => {
            const filterModal = Selectors.getPlayerAttributesFilter(getState()).filterData;
            const currentList = filterModal.currentLeagueList.find(item => item.id === list.id);
            
            if (currentList) {
                //need to change leagues list in AttributesFilter and AttributesFilterModal since add/remove not should change filters
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    playerAttributesStructure: {
                        ...draftState.playerAttributesStructure,
                        currentLeagueList: draftState.playerAttributesStructure.currentLeagueList.map((item) => item.id === list.id ? { ...list } : item),
                        previousLeagueList: draftState.playerAttributesStructure.previousLeagueList.map((item) => item.id === list.id ? { ...list } : item),
                    },
                    coachAttributesStructure: {
                        ...draftState.coachAttributesStructure,
                        leagueExperienceList: draftState.coachAttributesStructure.leagueExperienceList.map((item) => item.id === list.id ? { ...list } : item),
                    },
                    playerAttributesFilter: {
                        ...draftState.playerAttributesFilter,
                        filterData: {
                            ...draftState.playerAttributesFilter.filterData,
                            currentLeagueList: draftState.playerAttributesFilter.filterData.currentLeagueList.map((item) => item.id === list.id ? { ...list } : item),
                            previousLeagueList: draftState.playerAttributesFilter.filterData.previousLeagueList.map((item) => item.id === list.id ? { ...list } : item),
                        }
                    },
                    coachAttributesFilter: {
                        ...draftState.coachAttributesFilter,
                        filterData: {
                            ...draftState.coachAttributesFilter.filterData,
                            leagueExperienceList: draftState.coachAttributesFilter.filterData.leagueExperienceList.map((item) => item.id === list.id ? { ...list } : item),
                        }
                    }
                })));
                dispatch(stateController.setState((draftState) => {
                    if (draftState.playerAttributesFilterModal) {
                        return {
                            ...draftState,
                            playerAttributesFilterModal: {
                                ...draftState.playerAttributesFilterModal,
                                filterData: {
                                    ...draftState.playerAttributesFilterModal.filterData,
                                    currentLeagueList: draftState.playerAttributesFilterModal.filterData.currentLeagueList.map((item) => item.id === list.id ? { ...list } : item),
                                    previousLeagueList: draftState.playerAttributesFilterModal.filterData.previousLeagueList.map((item) => item.id === list.id ? { ...list } : item),
                                }
                            },
                        }
                    }
                    if (draftState.coachAttributesFilterModal) {
                        return {
                            ...draftState,
                            coachAttributesFilterModal: {
                                ...draftState.coachAttributesFilterModal,
                                filterData: {
                                    ...draftState.coachAttributesFilterModal.filterData,
                                    leagueExperienceList: draftState.coachAttributesFilterModal.filterData.leagueExperienceList.map((item) => item.id === list.id ? { ...list } : item),
                                }
                            }
                        }
                    }
                }));
            } else {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    playerAttributesStructure: {
                        ...draftState.playerAttributesStructure,
                        currentLeagueList: [...draftState.playerAttributesStructure.currentLeagueList, list],
                        previousLeagueList: [...draftState.playerAttributesStructure.previousLeagueList, list],
                    },
                    coachAttributesStructure: {
                        ...draftState.coachAttributesStructure,
                        leagueExperienceList: [...draftState.coachAttributesStructure.leagueExperienceList, list],
                    },
                    playerAttributesFilter: {
                        ...draftState.playerAttributesFilter,
                        filterData: {
                            ...draftState.playerAttributesFilter.filterData,
                            currentLeagueList: [...draftState.playerAttributesFilter.filterData.currentLeagueList, list],
                            previousLeagueList: [...draftState.playerAttributesFilter.filterData.previousLeagueList, list],
                        }
                    },
                    coachAttributesFilter: {
                        ...draftState.coachAttributesFilter,
                        filterData: {
                            ...draftState.coachAttributesFilter.filterData,
                            leagueExperienceList: [...draftState.coachAttributesFilter.filterData.leagueExperienceList, list],
                        }
                    }
                })));
                dispatch(stateController.setState((draftState) => {
                    if (draftState.playerAttributesFilterModal) {
                        return {
                            ...draftState,
                            playerAttributesFilterModal: {
                                ...draftState.playerAttributesFilterModal,
                                filterData: {
                                    ...draftState.playerAttributesFilterModal.filterData,
                                    currentLeagueList: [...draftState.playerAttributesFilterModal.filterData.currentLeagueList,list],
                                    previousLeagueList: [...draftState.playerAttributesFilterModal.filterData.previousLeagueList, list],
                                }
                            },
                        }
                    }
                    if (draftState.coachAttributesFilterModal) {
                        return {
                            ...draftState,
                            coachAttributesFilterModal: {
                                ...draftState.coachAttributesFilterModal,
                                filterData: {
                                    ...draftState.coachAttributesFilterModal.filterData,
                                    leagueExperienceList: [...draftState.coachAttributesFilterModal.filterData.leagueExperienceList,list],
                                }
                            }
                        }
                    }
                }));
            }
          
        }
    }


    // public static selectLatestSearch(subset: LatestFilter) {
    //     return (dispatch, getState: () => AppState) => {
    //         const latestFilterSet = subset.latestFilterSet
    //         const structure = getState().newPlayerSearch.filter.structure
    //         // if custom leagues lists where changed after  was saved
    //         const filters: FiltersSet = {
    //             transferTypeFilter: latestFilterSet.transferTypeFilter,
    //         }
    //         const keyword = latestFilterSet.keyword
    //         dispatch(stateController.setState(prevState => ({
    //             ...prevState,
    //             ...filters
    //         })))
    //         dispatch(Suggestions.Actions.setKeyword(keyword || ''))
    //
    //
    //         dispatch(Actions.setSearchModeToFiltered())
    //         dispatch(GridActions.Actions.resetPage())
    //         dispatch(GridActions.Actions.refresh())
    //
    //         const currencyCode = currencyById[getState().auth.currency.id]
    //         const filtersMessage = FilterActivityService.getLatestSearchMessage(filters, structure, currencyCode)
    //         dispatch(userActivityInsert({
    //             PageName: 'Search [Latest Search]',
    //             Message: `Selected Latest Search - ${filtersMessage}`,
    //             PageType: PageType.Search
    //         }))
    //
    //     }
    // }
    // public static clearSubsetFilter() {
    //     return dispatch => {
    //         dispatch(stateController.setState({
    //             searchSubset: null,
    //             sortBy: SearchByKey.XtvHightToLow
    //         }))
    //     }
    // }

    public static setSearchModeToDefault() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ searchMode: SearchMode.Default }))
            dispatch(Suggestions.Actions.onKeywordClearWithoutRefresh()) // clear keywortd as well
            dispatch(Actions.resetFilter());
            dispatch(Actions.resetCoachFilter());
            dispatch(Actions.initSorting());
            dispatch(Actions.toggleQuickSearchMode(false));
            dispatch(GridActions.dispose());
            dispatch(Actions.clearAllUserActivityData());
            dispatch(userActivityInsert({
                Message: 'Moved Back to Search',
                PageName: 'Search',
                PageType: PageType.Search
            }))
        }
    }

    public static setSearchModeToFiltered() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ searchMode: SearchMode.Filtered }))
            // dispatch(GridActions.Actions.toggleGridState(GridToggleState.Search)) // we need to set to default Search Tab before refresh
        }
    }

    // public static saveLatestFilterSet(keywordOwerride?: string) {
    //     return async (dispatch, getState: () => AppState) => {
    //         const isSuggestedSearchApplied = Selectors.getRoot(getState()).searchSubset
    //         if (isSuggestedSearchApplied) return // Ignore saving if Suggested Search player set was applied
    //
    //         let latestFilterSet = Selectors.getLatestFilterSet(getState());
    //         if (keywordOwerride !== undefined && keywordOwerride !== null) {
    //             latestFilterSet = { ...latestFilterSet, keyword: keywordOwerride }
    //         }
    //         await SearchService.saveLatestSearch(latestFilterSet)
    //     }
    // }

    public static disposeFilters() {
        return (dispatch, getState: () => AppState) => {
            const initialFilters = Selectors.getInitialFiltersSet(getState())
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                positionFilter: initialFilters.positionFilter,
                transferTypeFilter: initialFilters.transferTypeFilter,
                playerAttributesFilter: initialFilters.playerAttributesFilter,
            })))
        }
    }

    public static toggleQuickSearchMode(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isQuickSearch: value }))
        }
    }

    public static clearUserActivityData(...ids: string[]) {
        return (dispatch, getState: () => AppState) => {
            let currentUserActivityData = Selectors.getRoot(getState()).userActivityData;

            currentUserActivityData = currentUserActivityData.filter(item => !ids.includes(item.id));

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                userActivityData: [...currentUserActivityData]
            })));
        }
    }
    public static clearAllUserActivityData() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                userActivityData: []
            })));
        }
    }
    public static clearAllCategory(startWith: string) {
        return (dispatch, getState: () => AppState) => {
            let currentUserActivityData = Selectors.getRoot(getState()).userActivityData;

            currentUserActivityData = currentUserActivityData.filter(obj => !obj.id.startsWith(startWith));

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                userActivityData: [...currentUserActivityData]
            })));
        }
    }

    public static storeUserActivityData(message: string, pageType: string, userActivityId: string, shouldReplace: boolean = false, shouldToJoin: boolean = false) {
        return (dispatch, getState: () => AppState) => {
            let currentUserActivityData = Selectors.getRoot(getState()).userActivityData;
            const isFoundIndex = currentUserActivityData.findIndex(obj => obj.id === userActivityId);

            if (isFoundIndex !== -1) {
                currentUserActivityData.splice(isFoundIndex, 1);
            }
            const shouldAdd = shouldReplace || isFoundIndex === -1;
            if (shouldAdd) {
                const uaData: userActivityItem = {
                    id: userActivityId,
                    message: message,
                    pageType: pageType,
                    shouldToJoin: shouldToJoin,
                }
                currentUserActivityData = [...currentUserActivityData, uaData]
            }
            
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                userActivityData: [...currentUserActivityData]
            })));
        }
    }

    public static sendUserActivity(message, pageType) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: pageType,
                Message: message,
                PageType: PageType.Search,
            }))
        }
    }
    
    public static openDeleteModal(id) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deleteModal: {
                    isModalOpen: true,
                    isListDeleted:  false,
                    deletedListId: id,
                }
            })));
        }
    }
    public static closeDeleteModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deleteModal: {
                    ...prevState.deleteModal,
                    isModalOpen: false,
                }
            })));
        }
    }
    public static confirmDeleteList() {
        return async (dispatch, getState: () => AppState) => {
            const deleteListId = Selectors.getRoot(getState()).deleteModal.deletedListId;
            await dispatch(CompetitionsListActions.competitionListDelete(deleteListId))
            dispatch(Actions.deleteCompetitionList(deleteListId));
            
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deleteModal: {
                    ...prevState.deleteModal,
                    isListDeleted: true,
                    deletedListId: null,
                }
            })));
        }
    }

    public static onTogglePreferredFoot(e: any) {
        return async (dispatch) => {
            dispatch(Actions.clearPlayerAttributesFilterRowParams(['preferredFootLeft', 'preferredFootRight', 'preferredFootBoth']))
            dispatch(Actions.clearUserActivityData('preferredFootLeft', 'preferredFootRight', 'preferredFootBoth'))

            if (e.target.checked) {
                dispatch(Actions.setPlayerAttributesValue(e))
            
                let message = ''
                if (e.target.id === 'preferredFootLeft') {
                    message = `Preferred Foot: 'Left'`
                }
                if (e.target.id === 'preferredFootRight') {
                    message = `Preferred Foot: 'Right'`
                }
                if (e.target.id === 'preferredFootBoth') {
                    message = `Preferred Foot: 'Both'`
                }

                dispatch(Actions.storeUserActivityData(message, 'Player Search [Filter]', e.target.id))                
            }
        }
    }

    public static setPlayingStyleRole(id: number) {
        return async (dispatch, getState: () => AppState) => {
            
            let subState = Selectors.getRoot(getState()).playerAttributesFilterModal.filterData.playingStyleRoles
            let list = [];            
            
            if (subState.includes(id)) { 
                list = subState.filter(i => i !== id)
            } 
            
            if (!subState.includes(id)) {
                
                let filteredList = subState;
                
                if (subState.includes(PlayingStyleRoleEnum.BalancedPlayer) || id === PlayingStyleRoleEnum.BalancedPlayer) {
                    filteredList = [];
                }

                if (subState.includes(PlayingStyleRoleEnum.Agressive) && id === PlayingStyleRoleEnum.Shadow) {
                    filteredList = subState.filter(i => i !== PlayingStyleRoleEnum.Agressive);
                }

                if (subState.includes(PlayingStyleRoleEnum.Shadow) && id === PlayingStyleRoleEnum.Agressive) {
                    filteredList = subState.filter(i => i !== PlayingStyleRoleEnum.Shadow);
                }

                list = [...filteredList, id]
            }
                      
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerAttributesFilterModal: {
                    ...prevState.playerAttributesFilterModal,
                    filterData: {
                        ...prevState.playerAttributesFilterModal.filterData,
                        playingStyleRoles: [...list]
                    },
                }
            })))
            
            dispatch(Actions.storePlayingStyleRoleUserActivity(list));
        }
    }
    
    public static storePlayingStyleRoleUserActivity(selectedRoles: number[]) {
        return (dispatch, getState: () => AppState) => {
            
            const playingStyleList = Selectors.getRoot(getState()).playerAttributesFilter.filterData.playingStyleAllRoles;
                    
            const playingStyleRoles = playingStyleList.filter(item => selectedRoles.includes(item.id)).map(item => item.name);
            
            dispatch(Actions.clearUserActivityData('playingStyleRoles'))
            
            if (playingStyleRoles.length > 0) {
                const selectedRoles = playingStyleRoles.join(', ')                
                dispatch(Actions.storeUserActivityData(`Playing Style: '${selectedRoles}'`, 'Player Search [Filter]', 'playingStyleRoles'));
            }
        }
    }

    public static getPlayingStyleRoles() {
        return async (dispatch, getState: () => AppState) => {
            const positionId = Selectors.getRoot(getState()).positionFilter
            
            let list = [];
            if (!!positionId && positionId !== 1) {
                const roles = Selectors.getPlayingStyleRoles(getState())
                list = roles.find(item => item.id === positionId)?.roles
            }
            
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                playerAttributesFilterModal: {
                    ...draftState.playerAttributesFilterModal,
                    filterData: {
                        ...draftState.playerAttributesFilterModal.filterData,
                        playingStyleAllRoles: list,
                    }
                },
                playerAttributesFilter: {
                    ...draftState.playerAttributesFilter,
                    filterData: {
                        ...draftState.playerAttributesFilter.filterData,
                        playingStyleAllRoles: list,
                    }
                },
                playerAttributesStructure: {
                    ...draftState.playerAttributesStructure,
                    playingStyleAllRoles: list,
                },
            })));           
        }
    }

    public static disposePlayingStyleRolesFilter() {
        return (dispatch) => {
            dispatch(stateController.setState((prevState: SearchState) => ({
                ...prevState,
                playerAttributesFilter: {
                    ...prevState.playerAttributesFilter,
                    filterData: {
                        ...prevState.playerAttributesFilter.filterData,
                        playingStyleRoles: [],
                        playingStyleAllRoles: [],
                    }
                },
                playerAttributesStructure: {
                    ...prevState.playerAttributesStructure,
                    playingStyleRoles: [],
                    playingStyleAllRoles: [],
                },
            })))
        }
    }

    public static onFindOutMoreClick() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Clicked Playing Style Link', 'Player Search [Filter]'))
            window.open('https://blog.transferroom.com/transferrooms-playing-styles-explained', '_blank');
        }
    }

    public static onPlayingStyleTooltipHover() {
        return async (dispatch) => {
            dispatch(Actions.sendUserActivity('Viewed Playing Style Tooltip', 'Player Search [Filter]'))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.squadSearch.filter;
    public static getStructure = (state: AppState) => Selectors.getRoot(state).structure;
    public static getPositionFilter = (state: AppState) => Selectors.getRoot(state).positionFilter;
    public static getPositionFilterName = (state: AppState) => {
        const substate = Selectors.getRoot(state);
        const position = substate.positionFilter;
        if (!position) {
            return '';
        }
        const positionName = substate.positions.find(p => p.id === position).name;

        return positionName ? positionName : '';
    }
    public static getPositionFilterModal = (state: AppState) => Selectors.getRoot(state).positionFilterModal;
    public static getDeleteModal = (state: AppState) => Selectors.getRoot(state).deleteModal;
    public static isActivePositionFilter = (state: AppState) => Selectors.getPositionFilter(state) !== null;

    public static getTransferTypeFilter = (state: AppState) => Selectors.getRoot(state).transferTypeFilter;
    public static getTransferTypeFilterModal = (state: AppState) => Selectors.getRoot(state).transferTypeFilterModal;
    public static getTransferTypeFilterModalInitial = (state: AppState): TransferTypeFilterModalData => {
        const filterData = Selectors.getTransferTypeFilter(state)
        return {
            showOnlyAvailablePlayers: filterData.showOnlyAvailablePlayers,
            transferFee: filterData.transferFee?.max ?? null,
            anuallGrossSalaryYearly: filterData.anuallGrossSalaryYearly?.max ?? null,
            loanFee: filterData.loanFee?.max ?? null,
        }
    };
    public static isActiveTransferTypeFilter = (state: AppState) =>
        JSON.stringify(Selectors.getTransferTypeFilter(state)) !== JSON.stringify(getTransferTypeFilterCriteria());

    public static isChangedTransferTypeFilter = (state: AppState) => {
        if (!Selectors.getTransferTypeFilterModal(state)) {
            return false;
        }
        return JSON.stringify(Selectors.getTransferTypeFilterModalInitial(state)) !== JSON.stringify(Selectors.getTransferTypeFilterModal(state))
    };

    public static getTransferTypeFilterName = (state: AppState) => {
        const { transferTypeFilter } = Selectors.getRoot(state);
        const {
            transferFee,
            anuallGrossSalaryYearly,
            loanFee,
            showOnlyAvailablePlayers
        } = transferTypeFilter;

        if (!transferFee?.max && !anuallGrossSalaryYearly?.max && !loanFee?.max && !showOnlyAvailablePlayers) {
            return null;
        }

        const displayWithUnits = (value: number) => {
            const numberWithoutCommas = parseInt(value.toString().replace(/,/g, ''))

            return MarketValueSelector.displayMarketValue(numberWithoutCommas) + MarketValueSelector.valueUnts(numberWithoutCommas);
        };

        if ((transferFee?.max || anuallGrossSalaryYearly?.max) && loanFee?.max)
            return 'Buy & Loan';

        if (loanFee?.max) {
            return `Loan - Max: ${displayWithUnits(loanFee.max)}`;
        }

        if (transferFee?.max) {
            return `Buy - Max: ${displayWithUnits(transferFee.max)}`;
        }

        if (anuallGrossSalaryYearly?.max) {
            return `Salary - Max: ${displayWithUnits(anuallGrossSalaryYearly.max)}`;
        }

        if (showOnlyAvailablePlayers) {
            return 'Only available';
        }

        return null;
    }

    public static getPlayerAttributesFilter = (state: AppState) => Selectors.getRoot(state).playerAttributesFilter;
    public static getPlayerAttributesModal = (state: AppState) => Selectors.getRoot(state).playerAttributesFilterModal;
    
    public static getPlayerAttributesFilterStructure = (state: AppState) => Selectors.getRoot(state).playerAttributesStructure;
    
    public static isActivePlayerAttributesFilter = (state: AppState) => {
        return JSON.stringify(Selectors.getPlayerAttributesFilter(state).filterData) !== JSON.stringify(Selectors.getPlayerAttributesFilterStructure(state))
    };
    public static isChangedPlayerAttributesFilter = (state: AppState) => {
        if (!Selectors.getPlayerAttributesModal(state)) {
            return false;
        }
        return JSON.stringify(Selectors.getPlayerAttributesFilter(state).filterData) !== JSON.stringify(Selectors.getPlayerAttributesModal(state).filterData)
    };

    public static isAppliedPlayerContractFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.showOnlyFreeAgentPlayers !== structure.showOnlyFreeAgentPlayers ||
            filterData.lessThanSixMonth !== structure.lessThanSixMonth ||
            filterData.lessThanTwelveMonth !== structure.lessThanTwelveMonth;
    }

    public static isAppliedPlayerCurrentLeagueFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        const isCustomLeaguesSelected = filterData.leaguesList.length > 0;

        return filterData.isCurrentLeague !== structure.isCurrentLeague ||
            filterData.isCurrentAllLeagues !== structure.isCurrentAllLeagues ||
            isCustomLeaguesSelected;
    }

    public static isAppliedCurrentLeagueListFilter = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData.currentLeagueList;
        if (!filterData) {
            return false;
        }
        return filterData.some(item => item.checked);
    }
    
     public static isAppliedPreviousLeagueListFilter = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData.previousLeagueList;
        if (!filterData) {
            return false;
        }
        return filterData.some(item => item.checked);
    }
    
    public static isAppliedPlayerPreviousLeagueFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        const isCustomPreviousLeaguesSelected = filterData.previousLeaguesList.length > 0;

        return filterData.isPreviousLeague !== structure.isPreviousLeague ||
            filterData.isPreviousAllLeagues !== structure.isPreviousAllLeagues ||
            isCustomPreviousLeaguesSelected;
    }

    public static isAppliedPlayerNationalityFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        const isCustomNationalitySelected = filterData.nationalityList.length > 0;

        return filterData.isCurrentNationality !== structure.isCurrentNationality ||
            isCustomNationalitySelected;
    }

    public static isAppliedPlayerRatingFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.rating.min !== structure.rating.min || filterData.rating.max !== structure.rating.max;
    }

    public static isAppliedPlayerAgeFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.age.min !== structure.age.min || filterData.age.max !== structure.age.max;
    }

    public static isAppliedHeightFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        const isUserClub = isClubUser(state);

        if (!filterData) {
            return false;
        }
        const result = isUserClub ? 
            (filterData.height.min !== structure.height.min || filterData.height.max !== structure.height.max) 
            : 
            (filterData.isAboveSixFt !== structure.isAboveSixFt) // TODO: refactoring after height filter will be chenged on agency
        
            return result
    }

    public static isAppliedPlayerPreferredFootFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return !!filterData.preferredFootBoth || !!filterData.preferredFootLeft || !!filterData.preferredFootRight;
    }

    public static isAppliedGbeFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.gbePass !== structure.gbePass;
    }
    
    public static isAppliedPlayingStyleFilters = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.playingStyleRoles.length > 0;
    }

    public static getPlayerAttributesSelectedFormRowCount = (state: AppState) => {
        const isAppliedPlayerContractFilters = Selectors.isAppliedPlayerContractFilters(state);
        const isAppliedPlayerCurrentLeagueFilters = Selectors.isAppliedPlayerCurrentLeagueFilters(state);
        const isAppliedPlayerPreviousLeagueFilters = Selectors.isAppliedPlayerPreviousLeagueFilters(state);
        const isAppliedPlayerNationalityFilters = Selectors.isAppliedPlayerNationalityFilters(state);
        const isAppliedPlayerRatingFilters = Selectors.isAppliedPlayerRatingFilters(state);
        const isAppliedPlayerAgeFilters = Selectors.isAppliedPlayerAgeFilters(state);
        const isAppliedHeightFilters = Selectors.isAppliedHeightFilters(state);
        const isAppliedPlayerPreferredFootFilters = Selectors.isAppliedPlayerPreferredFootFilters(state);
        const isAppliedCurrentLeagueList = Selectors.isAppliedCurrentLeagueListFilter(state);
        const isAppliedPreviousLeagueList = Selectors.isAppliedPreviousLeagueListFilter(state);
        const isAppliedGbeFilters = Selectors.isAppliedGbeFilters(state);
        const isAppliedPlayingStyleFilters = Selectors.isAppliedPlayingStyleFilters(state);

        return [
            isAppliedPlayerContractFilters,
            isAppliedPlayerCurrentLeagueFilters,
            isAppliedPlayerPreviousLeagueFilters,
            isAppliedPlayerNationalityFilters,
            isAppliedPlayerRatingFilters,
            isAppliedPlayerAgeFilters,
            isAppliedHeightFilters,
            isAppliedPlayerPreferredFootFilters,
            isAppliedCurrentLeagueList,
            isAppliedPreviousLeagueList,
            isAppliedGbeFilters,
            isAppliedPlayingStyleFilters
        ].filter(Boolean).length;
    }

    public static getCoachAttributesFilter = (state: AppState) => Selectors.getRoot(state).coachAttributesFilter;
    public static getCoachAttributesModal = (state: AppState) => Selectors.getRoot(state).coachAttributesFilterModal;
    public static getCoachAttributesStructure = (state: AppState) => Selectors.getRoot(state).coachAttributesStructure;
    public static isActiveCoachAttributesFilter = (state: AppState) =>
        JSON.stringify(Selectors.getCoachAttributesFilter(state).filterData) !== JSON.stringify(Selectors.getCoachAttributesStructure(state));
    public static isChangedCoachAttributesFilter = (state: AppState) => {
        if (!Selectors.getCoachAttributesModal(state)) {
            return false;
        }
        return JSON.stringify(Selectors.getCoachAttributesFilter(state).filterData) !== JSON.stringify(Selectors.getCoachAttributesModal(state).filterData)
    };

    public static isAppliedCoachExperienceFilters = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
   
        if (!filterData) {
            return false;
        }
        return filterData.headCoachExperienceList.length != 0;
    }

    public static isAppliedCoachLeagueExperienceFilters = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }
        const isCustomLeaguesSelected = filterData.leaguesList.length > 0;
        return filterData.isCurrentLeague !== structure.isCurrentLeague ||
            filterData.isCurrentAllLeagues !== structure.isCurrentAllLeagues ||
            isCustomLeaguesSelected;
    }
    public static isAppliedCoachRatingFilters = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);

        if (!filterData) {
            return false;
        }
        return filterData.rating.min !== structure.coachRating.min || filterData.rating.max !== structure.coachRating.max;
    }

    public static isAppliedCoachHighSuitability = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        // const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.isHighSuitability
    }

    public static isAppliedCoachTrustInYouth = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.isHighTrustInYouth !== structure.isHighTrustInYouth ||
            filterData.isMediumTrustInYouth !== structure.isMediumTrustInYouth;
    }

    public static isAppliedCoachNationalityFilters = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }
        const isCustomNationalitySelected = filterData.nationalityList.length > 0;

        return filterData.isCurrentNationality !== structure.isCurrentNationality ||
            isCustomNationalitySelected;
    }

    public static isAppliedCoachAgeFilters = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        const structure = Selectors.getStructure(state);
        if (!filterData) {
            return false;
        }

        return filterData.isUnder35 !== structure.isUnder35 ||
            filterData.isUnder40 !== structure.isUnder40 ||
            filterData.isUnder50 !== structure.isUnder50 ||
            filterData.isUnder60 !== structure.isUnder60;
    }

    public static isAppliedCoachFormation = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.formationList.length > 0;
    }

    public static isAppliedCoachLicenseType = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.licenseTypeList.length > 0;
    }

    public static isAppliedCoachPlayingStyle = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.playingStyleList.length > 0;
    }

    public static isAppliedCoachCareerAchievements = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.achievementList.length > 0;
    }
    
    public static isAppliedLeagueExperience = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesFilter(state)?.filterData.leagueExperienceList;
        if (!filterData) {
            return false;
        }
        return filterData.some(item => item.checked);
    }
    
    public static getCoachAttributesSelectedFormRowCount = (state: AppState) => {
        const isAppliedCoachExperienceFilters = Selectors.isAppliedCoachExperienceFilters(state);
        const isAppliedCoachLeagueExperienceFilters = Selectors.isAppliedCoachLeagueExperienceFilters(state);
        const isAppliedCoachRatingFilters = Selectors.isAppliedCoachRatingFilters(state);
        const isAppliedCoachHighSuitability = Selectors.isAppliedCoachHighSuitability(state);
        const isAppliedCoachTrustInYouth = Selectors.isAppliedCoachTrustInYouth(state);
        const isAppliedCoachNationalityFilters = Selectors.isAppliedCoachNationalityFilters(state);
        const isAppliedCoachAgeFilters = Selectors.isAppliedCoachAgeFilters(state);
        const isAppliedCoachFormation = Selectors.isAppliedCoachFormation(state);
        const isAppliedCoachLicenseType = Selectors.isAppliedCoachLicenseType(state);
        const isAppliedCoachPlayingStyle = Selectors.isAppliedCoachPlayingStyle(state);
        const isAppliedCoachCareerAchievements = Selectors.isAppliedCoachCareerAchievements(state);
        const isAppliedLeagueExperience = Selectors.isAppliedLeagueExperience(state);

        return [
            isAppliedCoachExperienceFilters,
            isAppliedCoachLeagueExperienceFilters,
            isAppliedCoachRatingFilters,
            isAppliedCoachHighSuitability,
            isAppliedCoachTrustInYouth,
            isAppliedCoachNationalityFilters,
            isAppliedCoachAgeFilters,
            isAppliedCoachFormation,
            isAppliedCoachLicenseType,
            isAppliedCoachPlayingStyle,
            isAppliedCoachCareerAchievements,
            isAppliedLeagueExperience,
        ].filter(Boolean).length;
    }

    public static isAppliedCoachFreeAgentsFilter = (state: AppState) => {
        return Selectors.getRoot(state).showOnlyFreeAgentCoaches;
    }

    public static isAppliedCoachFilters = (state: AppState) => {
        const isActiveCoachAttributesFilter = Selectors.isActiveCoachAttributesFilter(state);
        const isAppliedCoachFreeAgentsFilter = Selectors.isAppliedCoachFreeAgentsFilter(state);

        return isActiveCoachAttributesFilter && isAppliedCoachFreeAgentsFilter;
    }

    public static isStructureLoading = (state: AppState) => Selectors.getRoot(state).structureLoading;
    public static isStructureWasLoaded = (state: AppState) => Selectors.getRoot(state).structureWasLoaded;
    public static getSelectLeaguesModal = (state: AppState) => Selectors.getRoot(state).selectLeaguesModal;
    public static getSelectNationalityModal = (state: AppState) => Selectors.getRoot(state).selectNationalityModal;

    public static validationPlayerRating = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesModal(state)?.filterData;
        if (!filterData) {
            return {
                hasError: false,
                text: null
            }
        }

        if (filterData.rating.min === null || filterData.rating.min === undefined) {
            return {
                hasError: true,
                text: "Value 'min' must be filled"
            }
        }

        if (filterData.rating.max === null || filterData.rating.max === undefined) {
            return {
                hasError: true,
                text: "Value 'max' must be filled"
            }
        }

        return {
            hasError: Number(filterData.rating.min) > Number(filterData.rating.max),
            text: Number(filterData.rating.min) > Number(filterData.rating.max) ? "Value 'max' must be greater than or equal to 'min'" : null
        }
    }

    public static validationCoachRating = (state: AppState) => {
        const filterData = Selectors.getCoachAttributesModal(state)?.filterData;
        if (!filterData) {
            return {
                hasError: false,
                text: null
            }
        }

        if (filterData.rating.min === null || filterData.rating.min === undefined) {
            return {
                hasError: true,
                text: "Value 'min' must be filled"
            }
        }

        if (filterData.rating.max === null || filterData.rating.max === undefined) {
            return {
                hasError: true,
                text: "Value 'max' must be filled"
            }
        }

        return {
            hasError: Number(filterData.rating.min) > Number(filterData.rating.max),
            text: Number(filterData.rating.min) > Number(filterData.rating.max) ? "Value 'max' must be greater than or equal to 'min'" : null
        }
    }

    public static validationPlayerAge = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesModal(state)?.filterData;
        if (!filterData) {
            return {
                hasError: false,
                text: null
            }
        }

        if (filterData.age.min === null || filterData.age.min === undefined) {
            return {
                hasError: true,
                text: "Value 'min' must be filled"
            }
        }

        if (filterData.age.max === null || filterData.age.max === undefined) {
            return {
                hasError: true,
                text: "Value 'max' must be filled"
            }
        }

        return {
            hasError: Number(filterData.age.min) > Number(filterData.age.max),
            text: Number(filterData.age.min) > Number(filterData.age.max) ? "Value 'max' must be greater than or equal to 'min'" : null
        }
    }
    
    public static validationPlayerHeight = (state: AppState) => {
        const filterData = Selectors.getPlayerAttributesModal(state)?.filterData;
        if (!filterData) {
            return {
                hasError: false,
                text: null
            }
        }

        if (filterData.height.min === null || filterData.height.max === null) {
            return {
                hasError: false,
                text: null
            }
        }
        
        return {
            hasError: Number(filterData.height.min) > Number(filterData.height.max),
            text: Number(filterData.height.min) > Number(filterData.height.max) ? "Value 'max' must be greater than or equal to 'min'" : null
        }
    }

    public static getSortBy = (state: AppState) => Selectors.getRoot(state).sortBy;
    public static isAvailable = (state: AppState) => !state.auth.clubPermission.availabilityNo || state.auth.isSubscriptionEntryPackage

    public static isFiltersInActiveState = (state: AppState) => {
        return Selectors.isActiveTransferTypeFilter(state)
    }
    public static isSearchModeInFilteredState = (state: AppState) => Selectors.getRoot(state).searchMode === SearchMode.Filtered
    public static getLatestFilterSet = (state: AppState): any => {
        const playerSearchSubstate = state.squadSearch;
        return {
            keyword: playerSearchSubstate.search.keyword,
            positionFilter: playerSearchSubstate.filter.positionFilter,
            transferTypeFilter: playerSearchSubstate.filter.transferTypeFilter,
            playerAttributesFilter: playerSearchSubstate.filter.playerAttributesFilter,
        }
    }
    public static getInitialFiltersSet = (state: AppState): any => {
        return {
            positionFilter: null,
            transferTypeFilter: getTransferTypeFilterCriteria(),
            playerAttributesFilter: getPlayerAttributesFilterCriteria(),
        }
    }

    public static getPlayingStyleRoles = (state: AppState) => Selectors.getRoot(state).playingStyleRolesList;

}

class Helpers {
    public static getAchievementName(achievementType: AchievementTypeEnum) {
        switch (achievementType) {
            case AchievementTypeEnum.Champion: {
                return 'Has won the ';
            }
            case AchievementTypeEnum.PromotedTo: {
                return 'Has been promoted to ';
            }
            case AchievementTypeEnum.PromotedFrom: {
                return 'Has been promoted from ';
            }
            case AchievementTypeEnum.RelegatedFrom: {
                return 'Has avoided relegation from ';
            }

            default:
                return '';
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchState as State,
    Actions,
    Selectors,
    stateController as Controller,
};