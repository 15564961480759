import styled from 'styled-components';
import React, { Component } from 'react';

class Props {
    eventName: string;
}

class ToBeAnnouncedSoon extends Component<Props, {}> {
    render() {
        return (
            <Styled >
                <h2>
                    Next event to be announced soon
                </h2>
                <p>
                    {this.props.eventName} has ended. Thank you to all who participated.
                </p>
            </Styled>
        )
    }
}

const Styled = styled.div`
    padding: 30px 30px;
    color: #131A36;
    @media (max-width: 650px) {
        padding: 15px;
    }
    > h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #131A36;
        margin-bottom: 13px;
        @media (max-width: 650px) {
            font-size: 26px;
        }
    }
    > p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #131A36;
        @media (max-width: 650px) {
            font-size: 20px;
        }
    }
    
`

export default ToBeAnnouncedSoon;