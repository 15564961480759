import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { AgenciesService } from 'api/admin/agencies/agencies.service';
import { ItemAgency, SupervisorSelectOption, ConnectionsItems, ImpersonateData, FilterOptions } from "api/admin/agencies/model";
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { RcFile } from 'antd/lib/upload';
import { notificationCreate } from 'app/notifications/notifications.actions';


export enum TypeConnectionsEnum {
    ClubConnections = 1,
    PlayerConnections = 2,
    UserConnections = 3,
};
export interface AddImageModalState {
    isOpen: boolean;
    isBackgroundImageModal: boolean;
    modalTitle: string;
    currentImage: string | null;
    currentBgImage: string | null;
    agencyId: number | null;
}

export interface ConnectionsModalState {
    isOpen: boolean;
    isLoadingConnections: boolean;
    dataConnections: ConnectionsItems[];
    currentPage: number;
    recordsPerPage: number;
    totalRecords: number;
    agencyId: number;
    typeConnections: number;
}

export interface ImpersonateModalState {
    isOpen: boolean;
    agencyId: number;
    userId: number;
    agencyName: string;
    impersonateData: ImpersonateData[];
    isLoadingImpersonate: boolean;
}

export interface DeactivateModalState {
    isOpen: boolean;
    agencyId: number;
    agencyName: string;
    isLoadingDeactivate: boolean;
}

export class ProcessingState {
    successExpertProcessingIds: number[];
}

export class AgenciesState {
    isLoading: boolean;
    page: number;
    recordsPerPage: number;
    totalRecords: number;
    agencyItems: ItemAgency[];
    supervisors: SupervisorSelectOption[];
    addImageModal: AddImageModalState;
    connectionsModal: ConnectionsModalState;
    impersonateModal: ImpersonateModalState;
    deactivateModal: DeactivateModalState;
    processing: ProcessingState;
    filterOptions: FilterOptions;
    previousFilterSnapshot: FilterOptions;
}

const defaultFilterOptions: FilterOptions = {
    isWithLogo: null,
    isWithBackground: null,
    agencyName: '',
    supervisor: null,
}

const defaultState: AgenciesState = {
    isLoading: false,
    page: 1,
    recordsPerPage: 25,
    totalRecords: 0,
    agencyItems: [],
    supervisors: [],
    addImageModal: {
        isOpen: false,
        isBackgroundImageModal: false,
        modalTitle: '',
        currentImage: null,
        currentBgImage: null,
        agencyId: null,
    },
    connectionsModal: {
        isOpen: false,
        isLoadingConnections: false,
        dataConnections: [],
        currentPage: 1,
        recordsPerPage: 25,
        totalRecords: 0,
        agencyId: 0,
        typeConnections: TypeConnectionsEnum.ClubConnections,
    },
    impersonateModal: {
        isOpen: false,
        agencyId: null,
        userId: null,
        agencyName: null,
        impersonateData: [],
        isLoadingImpersonate: false,
    },
    deactivateModal: {
        isOpen: false,
        agencyId: null,
        agencyName: null,
        isLoadingDeactivate: false,
    },
    processing: {
        successExpertProcessingIds: [],
    },
    filterOptions: defaultFilterOptions,
    previousFilterSnapshot: defaultFilterOptions,
}



const stateController = new StateController<AgenciesState>(
    'ADMIN_V2/AGENCIES',
    defaultState
);
class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static loadInitialData = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                await Promise.allSettled([
                    dispatch(Actions.getSupervisors()),
                    dispatch(Actions.agenciesFetch()),
                ]);
            } catch (err) {
                console.error(err);
            }
        };
    };

    public static loadConnections = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    connectionsModal: {
                        ...prevState.connectionsModal,
                        isLoadingConnections: true,
                    }
                })))
                const typeConnections = Selectors.selectConnectionsModalState(getState()).typeConnections;
                const { agencyId, currentPage, recordsPerPage, } = Selectors.selectConnectionsModalState(getState());

                const searchParameters = {
                    agencyId: agencyId,
                    pageIndex: currentPage,
                    pageSize: recordsPerPage,
                }

                if (typeConnections === TypeConnectionsEnum.ClubConnections) {
                    const data = await AgenciesService.agencyClubConnectionsFetch(searchParameters);

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        connectionsModal: {
                            ...prevState.connectionsModal,
                            dataConnections: data.pageItems,
                            totalRecords: data.total,
                        },
                    })))
                } else if (typeConnections === TypeConnectionsEnum.PlayerConnections) {
                    const data = await AgenciesService.agencyPlayerConnectionsFetch(searchParameters);

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        connectionsModal: {
                            ...prevState.connectionsModal,
                            dataConnections: data.pageItems,
                            totalRecords: data.total,
                        },
                    })))

                } else if (typeConnections === TypeConnectionsEnum.UserConnections) {
                    const data = await AgenciesService.agencyAgentConnectionsFetch(searchParameters);

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        connectionsModal: {
                            ...prevState.connectionsModal,
                            dataConnections: data.pageItems,
                            totalRecords: data.total,
                        },
                    })))
                }

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    connectionsModal: {
                        ...prevState.connectionsModal,
                        isLoadingConnections: false,
                    },
                })))
            }
        };
    };

    public static onPaginationConnectionsChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                connectionsModal: {
                    ...prevState.connectionsModal,
                    currentPage: pageNumber,
                    recordsPerPage: pageSize,
                },
            })))

            dispatch(Actions.loadConnections());
        }
    }

    public static agenciesFetch() {
        const wasFiltersChanged = (filterOptions: FilterOptions, previousFilterSnapshot: FilterOptions) => {
            return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
        }
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                const { filterOptions, previousFilterSnapshot, page, recordsPerPage, totalRecords } = Selectors.selectState(getState());

                const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    previousFilterSnapshot: {
                        ...prevState.filterOptions,
                    },
                    page: shouldDisposePagination ? 1 : page
                })));

                const searchParameters = {
                    withAgencyLogo: filterOptions.isWithLogo,
                    withAgencyBgImage: filterOptions.isWithBackground,
                    agencyName: filterOptions.agencyName,
                    successExpertId: filterOptions.supervisor && filterOptions.supervisor.value > -1 ? filterOptions.supervisor.value : null,
                    page: shouldDisposePagination ? 1 : page,
                    recordsPerPage: recordsPerPage,
                    totalRecords: totalRecords,
                }

                const data = await AgenciesService.agenciesFetch(searchParameters);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyItems: data.pageItems,
                    totalRecords: data.total,
                })))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static onPaginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                page: pageNumber,
                recordsPerPage: pageSize
            })))

            dispatch(Actions.agenciesFetch());
        }
    }

    public static onChangeAgencyName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    agencyName: value
                }
            })));
        }
    }

    public static disposeFilters() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: defaultState.filterOptions
            })))
            dispatch(Actions.agenciesFetch());
        }
    }

    public static onChangeSupervisor(value: SupervisorSelectOption) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    supervisor: value
                }
            })));
        }
    }

    public static onChangeHasLogo(value: boolean | null) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    isWithLogo: value
                }
            })));
        };
    }

    public static onChangeHasBackground(value: boolean | null) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    isWithBackground: value
                }
            })));
        };
    }

    public static getSupervisors() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const supervisorsData = await AgenciesService.supervisorsFetch();

                const supervisors = supervisorsData.output.map(item => ({
                    value: item.id,
                    label: item.name,
                    key: item.id
                }));
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    supervisors: supervisors
                })));
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static onAssignSupervisor(agencyId: number, successExpertId: number | string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.agencies;
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        successExpertProcessingIds: [...prevState.processing.successExpertProcessingIds, agencyId]
                    }
                })))

                const result = await AgenciesService.assignSupervisor(agencyId, successExpertId)
                if (result) {
                    const updatedItems = [...substate.agencyItems];
                    updatedItems.find(item => item.id === agencyId).successExpertId = successExpertId;

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        agencyItems: updatedItems
                    })))
                }
                await dispatch(Actions.agenciesFetch())
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        successExpertProcessingIds: prevState.processing.successExpertProcessingIds.filter(item => item !== agencyId)
                    }
                })))
            }
        }
    }

    public static openAddImageModal(agency: ItemAgency) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addImageModal: {
                    ...prevState.addImageModal,
                    isOpen: true,
                    modalTitle: agency.agencyName,
                    currentImage: agency.agencyLogo ? agency.agencyLogo : null,
                    agencyId: agency.id,
                },
            })));
        }
    }

    public static openAddBgImageModal(agency: ItemAgency) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addImageModal: {
                    ...prevState.addImageModal,
                    isOpen: true,
                    isBackgroundImageModal: true,
                    modalTitle: agency.agencyName,
                    currentBgImage: agency.agencyBgImage ? agency.agencyBgImage : null,
                    agencyId: agency.id,
                },
            })));
        }
    }

    public static closeAddImageModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addImageModal: {
                    ...defaultState.addImageModal
                },
            })));
        }
    }

    public static uploadImage(agencyId: number, file: Blob | RcFile | string) {
        return async (dispatch, getState: () => AppState) => {
            const isBackgroundImageModal = Selectors.selectState(getState()).addImageModal.isBackgroundImageModal;
            if (isBackgroundImageModal) {
                const { agencyBgImage } = await AgenciesService.uploadAgencyBgImage(agencyId, file);
                const newBgImageName = agencyBgImage + `?${new Date().getTime()}`;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyItems: prevState.agencyItems.map(item => {
                        if (item.id === agencyId) {
                            return {
                                ...item,
                                agencyBgImage: newBgImageName
                            }
                        } else {
                            return item
                        }
                    }),
                    addImageModal: {
                        ...prevState.addImageModal,
                        currentBgImage: newBgImageName
                    }
                })));
            } else {
                const { agencyLogo } = await AgenciesService.uploadAgencyLogo(agencyId, file);
                const newLogoName = agencyLogo + `?${new Date().getTime()}`;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyItems: prevState.agencyItems.map(item => {
                        if (item.id === agencyId) {
                            return {
                                ...item,
                                agencyLogo: newLogoName
                            }
                        } else {
                            return item
                        }
                    }),
                    addImageModal: {
                        ...prevState.addImageModal,
                        currentImage: newLogoName
                    }
                })));
            }

            dispatch(notificationCreate({
                message: "Image successfully changed",
                level: 'success'
            }))
        }
    }

    public static deleteImage(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const isBackgroundImageModal = Selectors.selectState(getState()).addImageModal.isBackgroundImageModal;
            if (isBackgroundImageModal) {
                await AgenciesService.deleteAgencyBgImage(agencyId)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyItems: prevState.agencyItems.map(item => {
                        if (item.id === agencyId) {
                            return {
                                ...item,
                                agencyBgImage: null
                            }
                        } else {
                            return item
                        }
                    }),
                    addImageModal: {
                        ...prevState.addImageModal,
                        currentBgImage: null,
                    }
                })));
            } else {
                await AgenciesService.deleteAgencyLogo(agencyId)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyItems: prevState.agencyItems.map(item => {
                        if (item.id === agencyId) {
                            return {
                                ...item,
                                agencyLogo: null
                            }
                        } else {
                            return item
                        }
                    }),
                    addImageModal: {
                        ...prevState.addImageModal,
                        currentImage: null,
                    }
                })));
            }

        }
    }

    public static openConnectionsModal(type: number, id: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                connectionsModal: {
                    ...prevState.connectionsModal,
                    isOpen: true,
                    typeConnections: type,
                    agencyId: id,
                },
            })));

            dispatch(Actions.loadConnections());
        }
    }

    public static closeConnectionsModal() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                connectionsModal: {
                    ...prevState.connectionsModal,
                    isOpen: false,
                    dataConnections: [],
                    totalRecords: 0,
                    currentPage: 1,
                    recordsPerPage: 25,
                },
            })));
        }
    }

    public static openImpersonateModal(item: ItemAgency) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                impersonateModal: {
                    ...prevState.impersonateModal,
                    isOpen: true,
                    agencyId: item.id,
                    agencyName: item.agencyName
                },
            })));

            dispatch(Actions.loadImpersonate(item.id));
        }
    }

    public static closeImpersonateModal() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                impersonateModal: {
                    ...defaultState.impersonateModal
                },
            })));
        }
    }

    public static loadImpersonate = (id: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    impersonateModal: {
                        ...prevState.impersonateModal,
                        isLoadingImpersonate: true,
                    }
                })))

                const data = await AgenciesService.getAgencyUsersToImpersonate(id);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    impersonateModal: {
                        ...prevState.impersonateModal,
                        impersonateData: data,
                    },
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    impersonateModal: {
                        ...prevState.impersonateModal,
                        isLoadingImpersonate: false,
                    },
                })))
            }
        };
    };

    public static onChangeImpersonate(userId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                impersonateModal: {
                    ...prevState.impersonateModal,
                    userId: userId,
                },
            })));
        }
    }

    public static getSessionClick = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const agencyId = Selectors.selectState(getState()).impersonateModal.agencyId;
                const userId = Selectors.selectState(getState()).impersonateModal.userId;
                let generateLink = ''

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    impersonateModal: {
                        ...prevState.impersonateModal,
                        isLoadingImpersonate: true,
                    }
                })))

                const token = await AgenciesService.getSession(agencyId, userId);

                if (token) {
                    generateLink = window.location.origin + '/admin-external/' + token;
                }

                copy(generateLink);

                dispatch(notificationCreate({
                    message: "The link is copied to the clipboard. Please open it in the new private browser tab.",
                    level: 'success'
                }))

                dispatch(Actions.closeImpersonateModal());
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    impersonateModal: {
                        ...prevState.impersonateModal,
                        isLoadingImpersonate: false,
                    },
                })))
            }
        };
    };

    public static openDeactivateModal(item: ItemAgency) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deactivateModal: {
                    ...prevState.deactivateModal,
                    isOpen: true,
                    agencyId: item.id,
                    agencyName: item.agencyName
                },
            })));
        }
    }

    public static closeDeactivateModal() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deactivateModal: {
                    ...defaultState.deactivateModal
                },
            })));
        }
    }

    public static deactivateUsers = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const agencyId = Selectors.selectState(getState()).deactivateModal.agencyId;
                const substate = Selectors.selectState(getState());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    deactivateModal: {
                        ...prevState.deactivateModal,
                        isLoadingDeactivate: true,
                    }
                })))

                const result = await AgenciesService.deactivateUsers(agencyId);

                if (result) {
                    const updatedItems = [...substate.agencyItems];
                    updatedItems.find(item => item.id === agencyId).isVerified = false;

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        agencyItems: updatedItems
                    })))
                }

                dispatch(Actions.closeDeactivateModal());
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    deactivateModal: {
                        ...prevState.deactivateModal,
                        isLoadingDeactivate: false,
                    },
                })))
            }
        };
    };
}
class Selectors {
    public static selectState = (state: AppState) => state.admin.agencies;

    public static selectConnectionsModalState = (state: AppState) => Selectors.selectState(state).connectionsModal;

    public static getSupervisorsList = (state: AppState) => {
        const supervisors = Selectors.selectState(state).supervisors;
        return [
            {
                value: 0,
                label: 'No supervisor',
                key: 0
            },
            ...supervisors
        ];
    };

    public static getSupervisorsFilters = (state: AppState) => {
        const supervisors = Selectors.selectState(state).supervisors;
        return [
            {
                value: -1,
                label: 'All',
                key: -1
            },
            {
                value: 0,
                label: 'No Success Expert',
                key: 0
            },
            ...supervisors
        ];
    };

    public static selectFilterOptions = (state: AppState) => Selectors.selectState(state).filterOptions;

    public static isFiltersSetToDefault(state: AppState) {
        return JSON.stringify(defaultState.filterOptions) === JSON.stringify(Selectors.selectState(state).filterOptions)
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgenciesState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};