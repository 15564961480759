import isEqual from 'lodash/fp/isEqual';
import getSections from 'services/filterSections';
import {
    PLAYERSEARCH_FILTER_CHANGED,
    PLAYERSEARCH_FILTER_STRUCTURE_FETCH,
    PLAYERSEARCH_FILTER_COLLAPSE_TOGGLE,
    PLAYERSEARCH_FILTER_CLEAR,
    PLAYERSEARCH_SET_TAB_KEY,
    PLAYERSEARCH_SCREEN_DISPOSE,
    PLAYERS_FETCH,
    PLAYERSEARCH_SET_SIMILARITY_PLAYER,
    COMPETITION_LIST_DELETE,
    PLAYERSEARCH_SET_FILTER_OPEN,
    PLAYERSEARCH_SET_SORT,
    PLAYERSEARCH_FILTER_CLEAR_ALL,
    REVERT_FILTER_CHANGED,
    PLAYERSEARCH_V2_FILTER_CHANGED,
} from '../../actionTypes';
import compose from 'lodash/fp/compose';
import { getAuth } from '../../auth/authReducer';
import {
    getCurrentPage,
    getPageSize,
} from '../../../app/pagination/reducers/pagination.reducer';
import { createSelector } from 'reselect';


export const playerSearchTabs = {
    all: 'ALL',
    shortList: 'SHORT_LIST',
    similarity: 'SIMILARITY',
};

const sections = getSections();

const initialCriteria = () => ({
    competitionLists: [],
    competitionsIds: [],
    keyword: '',
    squadId: undefined,
    ...sections.status.default,
    ...sections.marketValue.default,
    ...sections.age.default,
    ...sections.contractExpiry.default,
    ...sections.position.default,
    ...sections.league.default,
    ...sections.transferType.default,
    ...sections.transferFee.default,
    ...sections.loanFee.default,
    ...sections.gsnPerformance.default,
    ...sections.minutesPlayed.default,
    ...sections.faPlayerPoints.default,
});

const initialState = {
    leaguesChangdTimestamp: new Date(),
    expandedSections: Object.values(sections)
        .filter(x => !x.closedByDefault)
        .map(s => s.id),
    structure: {
        statuses: [],
        minMarketValue: sections.marketValue.default.minMarketValue,
        maxMarketValue: sections.marketValue.default.maxMarketValue,
        minAge: sections.age.default.minAge,
        maxAge: sections.age.default.maxAge,
        contractExpiryMin: sections.contractExpiry.default.contractExpiryMin,
        contractExpiryMax: sections.contractExpiry.default.contractExpiryMax,
        positions: sections.position.default.positions,
        gsnMin: sections.gsnPerformance.default.gsnMin,
        gsnMax: sections.gsnPerformance.default.gsnMax,
        mpMin: sections.minutesPlayed.default.mpMin,
        mpMax: sections.minutesPlayed.default.mpMax,
        isAnyReleasedPlayers: null,
        faPointsPassResult: sections.faPlayerPoints.default.faPointsAutoPassResult,
        faPointsAutoPassResult: sections.faPlayerPoints.default.faPointsAutoPassResult,
        faPointsExemptionsPanelResult: sections.faPlayerPoints.default.faPointsExemptionsPanelResult,
        faPointsUkNationalsOrSettled: sections.faPlayerPoints.default.faPointsUkNationalsOrSettled,
    },
    criteria: initialCriteria(),
    screenIsDisposed: true,
    tabKey: playerSearchTabs.all,
    similarityPlayer: { id: null, fullName: '' },
    filterOpen: false,
    sortBy: '',
    sortAscending: 'desc',
};

export default (filter = initialState, action) => {
    switch (action.type) {
        case PLAYERSEARCH_SCREEN_DISPOSE: {
            return initialState;
        }

        case PLAYERSEARCH_SET_FILTER_OPEN: {
            const newFilter = {
                ...filter,
                filterOpen: action.payload,
                cachedFilter: action.payload ? filter.criteria : null,
            };
            return newFilter;
        }

        case REVERT_FILTER_CHANGED: {
            return {
                ...filter,
                criteria: { ...filter.cachedFilter },
                cachedFilter: null,
            };
        }

        case PLAYERSEARCH_SET_TAB_KEY: {
            const newFilter = {
                ...filter,
                ...{
                    criteria: {
                        ...filter.criteria,
                        ...{
                            recommended: false,
                        },
                    },
                },
                tabKey: action.payload,
            };
            return newFilter;
        }

        case COMPETITION_LIST_DELETE.SUCCESS: {
            const ok = action.payload.data;
            const { deletedListId } = action.payload.request;
            const newCompList = filter.criteria.competitionLists.filter(
                i => i.id !== deletedListId,
            );
            if (!ok) {
                return filter;
            }
            return {
                ...filter,
                criteria: { ...filter.criteria, competitionLists: newCompList },
            };
        }

        case PLAYERSEARCH_V2_FILTER_CHANGED:
        case PLAYERSEARCH_FILTER_CHANGED: {
            let change = action.payload || {};
            if (
                change.keyword !== undefined &&
                change.keyword !== filter.criteria.keyword &&
                filter.criteria.squadId
            ) {
                change = { ...change, squadId: initialCriteria().squadId };
            }
            const res = {
                ...filter,
                criteria: { ...filter.criteria, ...change },
                leaguesChangdTimestamp: new Date(),
            };
            return res;
        }
        case PLAYERSEARCH_FILTER_CLEAR: {
            const section = action.payload;
            return {
                ...filter,
                criteria: { ...filter.criteria, ...section.default },
            };
        }
        case PLAYERSEARCH_FILTER_CLEAR_ALL: {
            return {
                ...filter,
                criteria: {
                    ...filter.criteria,
                    ...initialCriteria(),
                    maxMarketValue: filter.structure.maxMarketValue,
                    mpMax: filter.structure.mpMax,
                },
            };
        }
        case PLAYERSEARCH_FILTER_STRUCTURE_FETCH.SUCCESS: {
            return {
                ...filter,
                structure: { ...filter.structure, ...action.payload.data },
            };
        }
        case PLAYERS_FETCH.SUCCESS: {
            if (
                !action.payload.data ||
                action.payload.request.scope === 'mySquad'
            ) {
                return {
                    ...filter,
                };
            }

            filter = { ...filter, screenIsDisposed: undefined };
            if (!action.payload.data.groupedSquads) {
                return {
                    ...filter,
                    structure: {
                        ...filter.structure,
                        isAnyReleasedPlayers:
                            action.payload.data.isAnyReleasedPlayers,
                    },
                };
            }
            const r = {
                ...filter,

                structure: {
                    ...filter.structure,
                    ...action.payload.data.groupedSquads,
                    maxMarketValue: filter.structure.maxMarketValue,
                    isAnyReleasedPlayers:
                        action.payload.data.isAnyReleasedPlayers,
                },
                criteria: {
                    ...filter.criteria,
                },
            };

            // if (
            //     action.payload.data.groupedSquads.maxMarketValue &&
            //     r.criteria.maxMarketValueUpdatedFromServer !== true
            // ) {
            //     r.criteria.maxMarketValue =
            //         action.payload.data.groupedSquads.maxMarketValue;
            //     r.criteria.maxMarketValueUpdatedFromServer = true;
            // }
            
            r.structure.mpMax =
                action.payload.data.groupedSquads.maxMinutesPlayed;

            if (
                action.payload.data.groupedSquads.maxMinutesPlayed &&
                r.criteria.mpMaxUpdatedFromServer !== true
            ) {
                r.criteria.mpMax =
                    action.payload.data.groupedSquads.maxMinutesPlayed;
                r.criteria.mpMaxUpdatedFromServer = true;
            }

            return r;
        }

        case PLAYERSEARCH_SET_SIMILARITY_PLAYER: {
            return { ...filter, similarityPlayer: action.payload };
        }

        case PLAYERSEARCH_FILTER_COLLAPSE_TOGGLE: {
            const toggledSectionId = action.payload;
            const { expandedSections } = filter;

            return {
                ...filter,
                expandedSections: expandedSections.includes(toggledSectionId)
                    ? expandedSections.filter(s => s !== toggledSectionId)
                    : [...expandedSections, toggledSectionId],
            };
        }

        case PLAYERSEARCH_SET_SORT: {
            return {
                ...filter,
                sortAscending: action.payload.sortAscending,
                sortBy: action.payload.sortBy,
            };
        }

        default:
            return filter;
    }
};

const path = state => state.playerSearch.filter;

export const getTabKey = state => {
    return path(state).tabKey;
};

export const getSimilarityPlayer = state => {
    return path(state).similarityPlayer;
};

export const getPlayerSearchExpandedSections = compose(
    filter => filter.expandedSections,
    path,
);

export const getLeaguesChangdTimestamp = compose(
    filter => filter.leaguesChangdTimestamp,
    path,
);

export const getPlayerSearchFilterStructure = compose(
    filter => filter.structure,
    path,
);

export const getFilterOpen = compose(filter => filter.filterOpen, path);

export const getSortAsceding = compose(filter => filter.sortAscending, path);

export const getSortBy = compose(filter => filter.sortBy, path);

export const getPlayerSearchFilterCriteria = compose(
    filter => ({
        ...filter.criteria,
        competitionsIds: filter.criteria.competitionLists
            ? filter.criteria.competitionLists.reduce(
                (res, list) => res.concat(list.competitionIds),
                [],
            )
            : [],
    }),
    path,
);

export const getPlayerSearchIsShortList = compose(
    filter => filter.playersHasShortList,
    path,
);

export const getPreviousFiler = compose(
    filter => filter.cachedFilter,
    path,
);


export const getPlayerSearchFetchRequest = createSelector(
    getAuth,
    getPlayerSearchFilterCriteria,
    getPlayerSearchFilterStructure,
    getPlayerSearchIsShortList,
    getCurrentPage('playerSearch'),
    getPageSize('playerSearch'),
    getTabKey,
    getSortAsceding,
    getSortBy,
    (
        auth,
        criteria,
        structure,
        isShortList,
        currentPage,
        pageSize,
        shortListOnly,
        sortAscending,
        sortBy,
    ) => {
        let newcriteria = { ...criteria };
        if (newcriteria.minMarketValue === 0) newcriteria.minMarketValue = null;
        if (
            structure.maxMarketValue &&
            newcriteria.maxMarketValue === structure.maxMarketValue
        )
            newcriteria.maxMarketValue = null;

        if (structure.mpMax && newcriteria.mpMax === structure.mpMax)
            newcriteria.mpMax = null;


        return {
            ...auth,
            scope: 'playerSearch',
            criteria: newcriteria,
            playersHasShortList: isShortList,
            currentPageNumber: currentPage,
            pageSize: pageSize,
            sortBy,
            sortAscending: sortAscending === 'asc',
            shortListedOnly: shortListOnly === playerSearchTabs.shortList,
        };
    },
);

export const filterActivityBuilder = (structure, filter, initialCriteria = {}) => {
    let str = '';

    if (!structure || !filter) {
        return str;
    }

    const defaulValue = {
        ...initialCriteria,
        maxMarketValue: structure.maxMarketValue,
        mpMax: structure.mpMax,
    }

    const status = [];

    if (filter.isForSale && defaulValue.isForSale !== filter.isForSale) {
        status.push('To Buy');
    }

    if (filter.isForSale && defaulValue.isForSale !== filter.isForSale) {
        status.push('To Loan');
    }

    if (filter.isForFree && defaulValue.isForFree !== filter.isForFree) {
        status.push('Free');
    }

    if (
        filter.recommended &&
        defaulValue.recommended !== filter.recommended
    ) {
        status.push('Recommended');
    }

    if (status[0]) {
        str = `Show: ${status.join(',')}; `;
    }

    if (
        filter.positions[0] &&
        !isEqual(defaulValue.positions.sort(), filter.positions.sort())
    ) {
        str += `Position: ${filter.positions.join(',')}; `;
    }

    if (
        filter.faPointsPassResult &&
        defaulValue.faPointsPassResult !== filter.faPointsPassResult
    ) {
        str += `GBE: Pass; `;
    }

    if (
        filter.competitionsIds[0] &&
        !isEqual(
            defaulValue.competitionsIds.sort(),
            filter.competitionsIds.sort(),
        )
    ) {
        str += `League List: ${filter.competitionLists
            .map(item => item.name)
            .join(',')}; `;
    }

    if (
        defaulValue.minAge !== filter.minAge ||
        defaulValue.maxAge !== filter.maxAge
    ) {
        str += `Age: ${filter.minAge === filter.maxAge
            ? filter.maxAge
            : filter.minAge + '-' + filter.maxAge
            }; `;
    }

    if (
        defaulValue.minMarketValue !== filter.minMarketValue ||
        defaulValue.maxMarketValue !== filter.maxMarketValue
    ) {
        str += `xTV: ${filter.minMarketValue === filter.maxMarketValue
            ? filter.minMarketValueFormatted
            : filter.minMarketValueFormatted + '-' + filter.maxMarketValueFormatted
            }; `;
    }

    if (
        defaulValue.mpMin !== filter.mpMin ||
        defaulValue.mpMax !== filter.mpMax
    ) {
        str += `Min: ${filter.mpMin === filter.mpMax
            ? filter.mpMax
            : filter.mpMin + '-' + filter.mpMax
            }; `;
    }

    if (
        defaulValue.contractExpiryMin !== filter.contractExpiryMin ||
        defaulValue.contractExpiryMax !== filter.contractExpiryMax
    ) {
        str += `Contract: ${filter.contractExpiryMinFormated}-${filter.contractExpiryMaxFormated}; `;
    }

    return str.trim();
};

export const getActivityString = createSelector(
    [getPlayerSearchFilterStructure, getPlayerSearchFilterCriteria, initialCriteria],
    filterActivityBuilder,
);
