import axios from 'axios';

export class HubspotFormService {
    private static axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    public static async sendDataToHubspotForm(fields: { name: string; value: string; }[], portalId: string, formId: string ) {
        const response = await axios.post(
            ` https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
            {
                portalId,
                formId,
                fields,
            },
            this.axiosConfig
        )
        return response
    }
}