import { StateController } from 'utils/action-declaration';
import { SAVE_LANDING_PAGE_RESTRICTIONS } from './restrictions.reducer'
import { AppState } from 'root.reducer'
// import { PlayerAd } from 'api/landing-page/models/player-ad'
import { LandingPageService } from 'api/landing-page/landing-page-service'
import { getAuthSquadId, getAuthUserId, getAuthClubId } from 'store/auth/authReducer'
//import { Actions as StepsActions } from 'agency/new-registration/redux/new-registration.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PageType } from 'constants/enums';
import MiscEventService from 'api/virtual-summit/misc-event.service';
export const HpLocalSotore = "hp";

class PerformanceData {
    indicators: {
        friendlyAdsAmount: number | null;
        playerAdsAmount: number | null;
        playerPitchesAmount: number | null;
        playersAvailableAmount: number | null;
        showLinkToPitchYourPlayers: boolean;
        showFriendlyAds: boolean;
    };
    recommendations: {
        makePlayersAvailable: {
            show: boolean,
            fixed: boolean,
            showDismiss: boolean,
            passedDismissFlowRules: boolean
        };
        pitchYourPlayers: {
            show: boolean,
            fixed: boolean,
            showDismiss: boolean,
            passedDismissFlowRules: boolean
        };
        createPlayerAds: {
            show: boolean,
            fixed: boolean,
            showDismiss: boolean,
            passedDismissFlowRules: boolean
        };
        launchFriendlyAds: {
            show: boolean,
            fixed: boolean,
            showDismiss: boolean,
            passedDismissFlowRules: boolean
        };
        addMoreUsers: {
            show: boolean,
            fixed: boolean,
            showDismiss: boolean,
            passedDismissFlowRules: boolean
        };
    }
    showEvent: boolean;
    isLoading: boolean;
}

const defaultState: PerformanceData = {
    indicators: {
        friendlyAdsAmount: null,
        playerAdsAmount: null,
        playerPitchesAmount: null,
        playersAvailableAmount: null,
        showLinkToPitchYourPlayers: false,
        showFriendlyAds: false
    },
    recommendations: {
        makePlayersAvailable: {
            show: false,
            fixed: false,
            showDismiss: false,
            passedDismissFlowRules: false
        },
        pitchYourPlayers: {
            show: false,
            fixed: false,
            showDismiss: false,
            passedDismissFlowRules: false
        },
        createPlayerAds: {
            show: false,
            fixed: false,
            showDismiss: false,
            passedDismissFlowRules: false
        },
        launchFriendlyAds: {
            show: false,
            fixed: false,
            showDismiss: false,
            passedDismissFlowRules: false
        },
        addMoreUsers: {
            show: false,
            fixed: false,
            showDismiss: false,
            passedDismissFlowRules: false
        },
    },
    showEvent: false,
    isLoading: false,
}

export enum DismissType {
    AgentInviteColleagues = 1,
    HomeMakePlayerAvailable = 2,
    HomePitchYourPlayer = 3,
    LaunchFriendlyAds = 4
}

const stateController = new StateController<PerformanceData>(
    "LANDING_PAGE/PERFORMANCE_DATA",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static eventRegistration() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let userId = state.auth.userId;
            
            dispatch(
                stateController.setState((prev) => ({ ...prev, showEvent: false }))
            )
            await MiscEventService.preRegisterEvent(userId);

        }
    }

    public static completedMakeAvailableRecommendation() {
        return (dispatch, getState: () => AppState) => {
            let toShow = getState().landingPage.performance.recommendations.makePlayersAvailable.show;
            dispatch(
                stateController.setState((prev) => ({ ...prev, recommendations: { ...prev.recommendations, makePlayersAvailable: { show: toShow, fixed: true } } }))
            )
            dispatch(Actions.refreshCounters());
        }
    }

    public static completedPitchPlayersRecommendation() {
        return async (dispatch, getState: () => AppState) => {
            let toShow = getState().landingPage.performance.recommendations.pitchYourPlayers.show;
            dispatch(
                stateController.setState((prev) => ({ ...prev, recommendations: { ...prev.recommendations, pitchYourPlayers: { show: toShow, fixed: true } } }))
            )
            dispatch(Actions.refreshCounters());
        }
    }

    public static completedCreatePlayerAdsRecommendation() {
        return async (dispatch, getState: () => AppState) => {
            let toShow = getState().landingPage.performance.recommendations.createPlayerAds.show;
            dispatch(
                stateController.setState((prev) => ({ ...prev, recommendations: { ...prev.recommendations, createPlayerAds: { show: toShow, fixed: true } } }))
            )
            dispatch(Actions.refreshCounters());
        }
    }

    public static completedFriendlyAdsRecommendation() {
        return async (dispatch, getState: () => AppState) => {
            let toShow = getState().landingPage.performance.recommendations.launchFriendlyAds.show;
            dispatch(
                stateController.setState((prev) => ({ ...prev, recommendations: { ...prev.recommendations, launchFriendlyAds: { show: toShow, fixed: true } } }))
            )
            dispatch(Actions.refreshCounters());
        }
    }

    public static completedAddUsersRecommendation() {
        return async (dispatch) => {
            dispatch(
                stateController.setState((prev) => ({ ...prev, recommendations: { ...prev.recommendations, addMoreUsers: { show: true, fixed: true } } }))
            )
            dispatch(Actions.refreshCounters());

        }
    }

    public static refreshCounters() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentClubId = getAuthClubId(appState);
            let currentUserId = getAuthUserId(appState)
            let currentSquadId = getAuthSquadId(appState);

            dispatch(stateController.setState({ isLoading: true }));
            let result = await LandingPageService.loadPerformanceData(currentClubId, currentUserId, currentSquadId);

            dispatch(stateController.setState({
                isLoading: false,
                indicators: {
                    friendlyAdsAmount: result.friendlyAdsAmount,
                    playerAdsAmount: result.playerAdsAmount,
                    playerPitchesAmount: result.playerPitchesAmount,
                    playersAvailableAmount: result.playersAvailableAmount,
                    showLinkToPitchYourPlayers: result.showLinkToPitchYourPlayers,
                    showFriendlyAds: result.showFriendlyAds
                },                
            }))
        }
    }

    public static loadPerformanceData() {
        return async (dispatch, getState: () => AppState) => {

            let appState = getState();
            let currentClubId = getAuthClubId(appState);
            let currentUserId = getAuthUserId(appState)
            let currentSquadId = getAuthSquadId(appState);

            dispatch(stateController.setState({ isLoading: true }));
            let result = await LandingPageService.loadPerformanceData(currentClubId, currentUserId, currentSquadId);

            dispatch({
                type: SAVE_LANDING_PAGE_RESTRICTIONS,
                payload: {
                    showPitchClubNames: result.showPitchClubNames  // Show or hide club names and logos in all landing page components
                }
            })

            let getHpLocalStore = this.getHpLocalStore();

            dispatch(stateController.setState(prevState => ({
                isLoading: false,
                indicators: {
                    friendlyAdsAmount: result.friendlyAdsAmount,
                    playerAdsAmount: result.playerAdsAmount,
                    playerPitchesAmount: result.playerPitchesAmount,
                    playersAvailableAmount: result.playersAvailableAmount,
                    showLinkToPitchYourPlayers: result.showLinkToPitchYourPlayers,
                    showFriendlyAds: result.showFriendlyAds
                },                
                recommendations: {
                    makePlayersAvailable: {
                        fixed: prevState.recommendations.makePlayersAvailable.fixed,
                        show: result.showMakePlayersAvailable,
                        showDismiss: result.showMakePlayersAvailableDismiss,
                        passedDismissFlowRules: getHpLocalStore.recommendations.makePlayersAvailable.passedDismissFlowRules
                    },
                    pitchYourPlayers: {
                        fixed: prevState.recommendations.pitchYourPlayers.fixed,
                        show: result.showPitchYourPlayers,
                        showDismiss: result.showPitchYourPlayersDismiss,
                        passedDismissFlowRules: getHpLocalStore.recommendations.pitchYourPlayers.passedDismissFlowRules
                    },
                    createPlayerAds: {
                        fixed: prevState.recommendations.createPlayerAds.fixed,
                        show: result.showCreatePlayerAds
                    },
                    launchFriendlyAds: {
                        fixed: prevState.recommendations.launchFriendlyAds.fixed,
                        show: result.showLaunchFriendlyAds,
                        showDismiss: result.showLaunchFriendlyAdsDismiss
                    },
                    addMoreUsers: {
                        fixed: prevState.recommendations.addMoreUsers.fixed,
                        show: result.showAddMoreUsers
                    },
                },
                showEvent: result.showEvent
            })))
        }
    }

    public static passDismissFlowRules(type: DismissType) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            let appState = getState();
            let p = appState.landingPage.performance;
            let recomendations = p.recommendations;

            switch (type) {
                case DismissType.HomeMakePlayerAvailable:
                    recomendations.makePlayersAvailable.passedDismissFlowRules = true;
                    break;
                case DismissType.HomePitchYourPlayer:
                    recomendations.pitchYourPlayers.passedDismissFlowRules = true;
                    break;
            }

            let hp = this.getHpLocalStore();
            hp.recommendations.makePlayersAvailable.passedDismissFlowRules = recomendations.makePlayersAvailable.passedDismissFlowRules
            hp.recommendations.pitchYourPlayers.passedDismissFlowRules = recomendations.pitchYourPlayers.passedDismissFlowRules
            localStorage.setItem(HpLocalSotore, JSON.stringify(hp))

            dispatch(stateController.setState({ isLoading: false }));
        }
    }

    public static dismiss(type: DismissType) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            let hp = Actions.getHpLocalStore();
            let userActivityPageAction = '';

            let result = await LandingPageService.dismissPerformance(type);

            let appState = getState();
            let p = appState.landingPage.performance;
            let recomendations = p.recommendations
            switch (type) {
                case DismissType.HomeMakePlayerAvailable:
                    recomendations.makePlayersAvailable.show = false;
                    hp.recommendations.makePlayersAvailable.passedDismissFlowRules = false;
                    userActivityPageAction = "Make player available";
                    break;
                case DismissType.HomePitchYourPlayer:
                    recomendations.pitchYourPlayers.show = false;
                    hp.recommendations.pitchYourPlayers.passedDismissFlowRules = false;
                    userActivityPageAction = "Pitch your players";
                    break;
                case DismissType.LaunchFriendlyAds:
                    recomendations.launchFriendlyAds.show = false;
                    userActivityPageAction = "Launch friendly ads";
                    break;
            }

            dispatch(userActivityInsert({
                Message: `Dismiss`,
                PageName: `Home [${userActivityPageAction}]`,
                PageType: PageType.Home
            }));

            localStorage.setItem(HpLocalSotore, JSON.stringify(hp));

            dispatch(stateController.setState({ isLoading: false }));
        }
    }

    private static getHpLocalStore = () => {
        let hp = JSON.parse(localStorage.getItem(HpLocalSotore));

        if (hp == null) {
            hp = {
                recommendations: {
                    makePlayersAvailable: {
                        passedDismissFlowRules: false
                    },
                    pitchYourPlayers: {
                        passedDismissFlowRules: false
                    }
                }
            }
        }
        return hp;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PerformanceData as State,
    Actions as Actions,
    stateController as Controller
};



