import { asyncActionType } from 'store/actionTypes'

export const AD_FORCE_UPDATE_LOAD_OUTDATED_ADS = asyncActionType('AD_FORCE_UPDATE_LOAD_OUTDATED_ADS')
export const AD_FORCE_UPDATE_UPDATE_BATCH_ADS = asyncActionType('AD_FORCE_UPDATE_UPDATE_BATCH_ADS')
export const AD_FORCE_UPDATE_UPDATE_AD = asyncActionType('AD_FORCE_UPDATE_UPDATE_AD')
export const AD_FORCE_UPDATE_REMOVE_AD = asyncActionType('AD_FORCE_UPDATE_REMOVE_AD')
export const AD_FORCE_FLOW_CREATE_AD = asyncActionType('AD_FORCE_FLOW_CREATE_AD')
export const AD_FORCE_UPDATE_SET_SHOULD_FORCE_UPDATE = 'AD_FORCE_UPDATE_SET_SHOULD_FORCE_UPDATE';

const initialState = {
    outdatedAds: [],
    shouldForceUpdate: false,
    counter: 0
};

export const getAdForceUpdateState = state => state.adForceUpdate;

export default (forceUpdateState = initialState, action) => {
    switch (action.type) {
        case AD_FORCE_UPDATE_LOAD_OUTDATED_ADS.SUCCESS: {
            return {
                ...forceUpdateState,
                outdatedAds: action.payload.data,
            };
        }

        case AD_FORCE_UPDATE_SET_SHOULD_FORCE_UPDATE: {
            return {
                ...forceUpdateState,
                shouldForceUpdate: action.payload,
            }
        }

        case AD_FORCE_UPDATE_UPDATE_BATCH_ADS.SUCCESS: {
            return initialState;
        }

        case AD_FORCE_UPDATE_UPDATE_AD.SUCCESS: {
            return {
                ...forceUpdateState,
                outdatedAds: [
                    ...forceUpdateState.outdatedAds.map(item => {
                        if (action.payload.data.id === item.id) return { ...item, justUpdated: true };
                        return item;
                    })
                ]
            }
        }

        case AD_FORCE_UPDATE_REMOVE_AD.SUCCESS: {
            return {
                ...forceUpdateState,
                outdatedAds: [
                    ...forceUpdateState.outdatedAds.filter(item => item.id !== action.payload.data.id)
                ]
            }
        }
        case AD_FORCE_FLOW_CREATE_AD.SUCCESS: {
            return {
                ...forceUpdateState,
                counter: forceUpdateState.counter + 1
            }
        }

        default:
            return forceUpdateState;
    }
};
