import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import UserPasswordService from 'api/user-password/user-password';
import { refreshAuthSingle } from 'store/auth/auth.actions'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import PasswordValidation from 'utils/validate-password';
import { PageType } from 'constants/enums';
import { getAuth } from 'store/auth/authReducer';

class UpdatePassword {
    newPassword: string;
    confirmNewPassword: string;
    newPasswordError: any;
    rePasswordError: any;
    isProcessing: boolean;
    isValidPassword: boolean;
}

const defaultState: UpdatePassword = {
    confirmNewPassword: "",
    newPassword: "",
    newPasswordError: null,
    rePasswordError: null,
    isProcessing: false,
    isValidPassword: true,
}

const stateController = new StateController<UpdatePassword>(
    "RESET_PASSWORD_MANDATORY",
    defaultState
);

class Actions {
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static clearErrors() {
        return dispatch => {
            dispatch(stateController.setState({
                newPasswordError: null,
                rePasswordError: null,
                isValidPassword: true,
            }));
        }
    }

    public static setNewPassword(newPassword: string) {
        return async (dispatch, getState: () => AppState) => {
            const rePassword = Selectors.getRePass(getState())
            const validation = PasswordValidation.validate(newPassword)
            let rePasswordError = PasswordValidation.validateConfirmPassword(newPassword, rePassword);

            dispatch(stateController.setState({
                newPassword,
                newPasswordError: validation.rules,
                isValidPassword: validation.isSuccess,
                rePasswordError,
            }));
        }
    }

    public static setPasswordRe(confirmNewPassword: string) {
        return async (dispatch, getState: () => AppState) => {
            const password = Selectors.getNewPassword(getState())
            let rePasswordError = PasswordValidation.validateConfirmPassword(password, confirmNewPassword);
            dispatch(stateController.setState({
                confirmNewPassword,
                rePasswordError
            }));
        }
    }

    public static changePassword() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const state = getState();
                let confirmNewPassword = Selectors.getRePass(state);
                let newPassword = Selectors.getNewPassword(state);

                if (!confirmNewPassword || !newPassword) {
                    return;
                }

                const validation = PasswordValidation.validate(newPassword)
                if (!validation.isSuccess) {
                    dispatch(stateController.setState({
                        newPasswordError: validation.rules,
                        isValidPassword: validation.isSuccess,
                    }));
                    return
                }

                let rePasswordError = PasswordValidation.validateConfirmPassword(newPassword, confirmNewPassword);

                if (rePasswordError) {
                    dispatch(stateController.setState({
                        newPasswordError: null,
                        rePasswordError: rePasswordError,
                    }));
                    return;
                }

                dispatch(stateController.setState({ isProcessing: true }))

                const data = await UserPasswordService.setPassword({
                    password: newPassword,
                })

                if (!data.isSuccess) {
                    dispatch(stateController.setState({
                        newPasswordError: data.rules,
                        rePasswordError: null,
                        isValidPassword: false,
                    }));
                } else {
                    dispatch(userActivityInsert({
                        PageName: 'Mandatory password update',
                        Message: 'Applied New Password',
                        PageType: getAuth(state).agencyId > 0 ? PageType.AgencyOnboarding : PageType.ForceUpdate,
                    }))
                    await dispatch(refreshAuthSingle(state.auth.aspNetUserId))
                }
            } catch (error) {

            }
            finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.updatePasswordMandatory;
    public static getNewPassword = (state: AppState) => Selectors.getRoot(state).newPassword;
    public static getRePass = (state: AppState) => Selectors.getRoot(state).confirmNewPassword;
    public static getNewPasswordError = (state: AppState) => Selectors.getRoot(state).newPasswordError;
    public static getRePasswordError = (state: AppState) => Selectors.getRoot(state).rePasswordError;
    public static getProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static isValidPassword = (state: AppState) => Selectors.getRoot(state).isValidPassword;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UpdatePassword as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};
