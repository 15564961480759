import axios from 'axios-config'



export default class PlayerMarketBannerService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async interest() {
        const { data } = await axios.post(`api/AgencyHomePage/PlayerMarketInterest`,
            this.axiosConfig
        )
        return data
    }

}