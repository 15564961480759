import { setLocale } from 'react-redux-i18n';
import { AUTH_SET_LANGUAGE } from 'store/actionTypes'
import { createAction } from 'redux-actions';
import { saveState } from 'store/localStorageState';

export function translate(key, parameters = {}) {
    const I18n = require('react-redux-i18n').I18n;
    const result = I18n.t(key, parameters);
    return result;
}

export function translateNotification(notification) {
    const regex = /\[%[^%]*%\]/g;
    let tags;
    const stringToParse = notification.message;
    do {
        tags = regex.exec(stringToParse);
        if (tags !== undefined && tags !== null) {
            tags.map(tag => {
                const key = tag.replace('[%', '').replace('%]', '');
                const translation = translate(key);
                notification.message = notification.message.replace(
                    tag,
                    translation,
                );
                return '';
            });
        }
    } while (tags);
    return notification;
}

export function parseAndTranslate(text) {
    const regex = /\[%[^%]*%\]/g;
    let tags;
    let stringToParse = text;
    do {
        tags = regex.exec(stringToParse);
        if (tags !== undefined && tags !== null) {
            tags.map(tag => {
                const key = tag.replace('[%', '').replace('%]', '');
                const translation = translate(key);
                text = text.replace(
                    tag,
                    translation,
                );
                return '';
            });
        }
    } while (tags);
    return text;
}

export const updateLocale = lang => (dispatch, getState) => {
    baseUpdateLocale(lang);
    dispatch(setLocale(lang));
};

const baseUpdateLocale = (lang) => {
    const I18n = require('react-redux-i18n').I18n;
    if (!I18n._getTranslations()[lang]) {
        lang = 'en';
    }
}

const getLangCodeByLangId=(langId) =>{
    let lang = undefined;
    if (langId === 1) lang = 'en';
    else if (langId === 2) lang = 'it';
    else if (langId === 3) lang = 'es';
    return lang;
}

export const updateLocaleByLangId = langId => (dispatch, getState) => {
    let lang = getLangCodeByLangId(langId);
    baseUpdateLocale(lang);
    dispatch(setLocale(lang));
};

export const setLocaleToState = langId => (dispatch, getState) => {
    let code =getLangCodeByLangId(langId);

    let action = createAction(AUTH_SET_LANGUAGE)({ id: langId, code: code })
    dispatch(action);
    let auth = getState().auth;
    saveState({ auth: auth })
}

export function getLocale() {
    const I18n = require('react-redux-i18n').I18n;
    const locale = I18n._getLocale();
    return locale;
}
