import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { getAuth } from "store/auth/authReducer";
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as RecommendationsActions } from 'pages/player-v2/agency-finder/redux/recommendations.controller';
import {Actions as MarketActions } from 'pages/player-v2/agency-finder/redux/markets.controller'

export enum AgencyFinderScreen {
    Default = 1,
    CompetitionRecommendations = 2,
    AgencyRecommendations = 3,
    CountryAgencies = 4,
    RequestsSent = 5
}

class State {
    screen: AgencyFinderScreen
    sendedFromScreen: AgencyFinderScreen
    isSendRequestProcessing: boolean
    selectedAgencyIds: number[]
    recommendedAgenciesIds: number[]
    isScrolledAgencyList: boolean
}

const defaultState: State = {
    screen: AgencyFinderScreen.Default,
    sendedFromScreen: null,
    isSendRequestProcessing: false,
    selectedAgencyIds: [],
    recommendedAgenciesIds: [],
    isScrolledAgencyList: false,
}

const stateController = new StateController<State>("PLAYERV2/AGENCY-FINDER/COMMON", defaultState);

class Actions {
    public static init() {
        return (dispatch, getState: () => AppState) => {
            const isClickFromHomePage = getState().playerV2.homePage.isAgentFinderClicked;
            const pageName = isClickFromHomePage ? 'My Page [Find Trusted Agency]' : 'My Page [Agent Finder]';
            const message = isClickFromHomePage? ' Clicked Start Agent Finder' : 'Clicked Agent Finder ';
            const playerId = getState().auth.playerId;
            
            let searchParams = new URL(window.location.href).searchParams;
            const isEa = searchParams.get("ea");
            const eaType = searchParams.get("type");
            
            if (isEa) {      
                if (eaType === "rr") {
                    dispatch(userActivityInsert({
                        PageName: `Mail [Representation Requests] `,
                        Message: 'Clicked Send More Requests',
                        PlayerId: playerId,
                    }));
                }

                if (eaType === "ca") {
                    dispatch(userActivityInsert({
                        PageName: `Mail [Connect Agency]`,
                        Message: 'Clicked Find New Agent',
                        PlayerId: playerId,
                    }));
                }
            }
            
            dispatch(Actions.dispose());

            dispatch(userActivityInsert({
                Message: message,
                PageName: pageName,
                PlayerId: playerId,
            }));
        }
    }
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static setScreen(screen: AgencyFinderScreen) {
        return (dispatch) => {
            dispatch(stateController.setState({ screen: screen }));
        }
    }

    public static clearSelection() {
        return (dispatch) => {
            dispatch(stateController.setState({ selectedAgencyIds: [] }));
        }
    }

    public static openCompetitionRecommendations() {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState())
            dispatch(Actions.setScreen(AgencyFinderScreen.CompetitionRecommendations));
            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Countries]',
                Message: `Clicked Get Recommendations`,
                PlayerId: playerId,
            }))
        }
    }

    public static openDefault() {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;

            dispatch(Actions.setScreen(AgencyFinderScreen.Default));

            dispatch(userActivityInsert({
                Message: 'Clicked Cancel',
                PageName: 'Agent Finder [Recommendations]',
                PlayerId: playerId,
            }))
        }
    }

    public static backToAgentFinder() {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;

            dispatch(Actions.setScreen(AgencyFinderScreen.Default));

            dispatch(userActivityInsert({
                Message: 'Clicked Back to Agent Finder',
                PageName: 'Agent Finder [Recommendations]',
                PlayerId: playerId,
            }))
        }
    }

    public static openAgencyRecommendations() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(RecommendationsActions.loadRecommendedAgencies())

            const playerId = getState().auth.playerId;
            const allRecommendedAgenciesIds = getState().playerV2.agencyFinder.recommendations.allRecommendedAgenciesIds;


            dispatch(stateController.setState({ selectedAgencyIds: allRecommendedAgenciesIds }));
            dispatch(Actions.setScreen(AgencyFinderScreen.AgencyRecommendations));

            dispatch(userActivityInsert({
                Message: 'Clicked Save',
                PageName: 'Agent Finder [Recommendations]',
                PlayerId: playerId,
            }))
        }
    }

    public static sendRequestRepresentations() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(stateController.setState({ isSendRequestProcessing: true }));
            const { screen } = getState().playerV2.agencyFinder.common
            const countryName = getState().playerV2.agencyFinder.markets.selectedArea?.areaName;
            let countryAgencies
            if (screen === AgencyFinderScreen.AgencyRecommendations) {
                countryAgencies = getState().playerV2.agencyFinder.recommendations.recommendedAgencies
            }
            if (screen === AgencyFinderScreen.CountryAgencies) {
                countryAgencies = getState().playerV2.agencyFinder.markets.countryAgencies
            }

            const { selectedAgencyIds } = Selectors.getRoot(getState());
            const { playerId } = getAuth(getState())
            const agenciesList = countryAgencies.filter(item => selectedAgencyIds.includes(item.agencyId))

            if (screen === AgencyFinderScreen.AgencyRecommendations) {
                await dispatch(userActivityInsert({
                    PageName: 'Agent Finder [Country Agency Recommendations]',
                    Message: `Clicked Request Representation`,
                    PlayerId: playerId,
                }))
            }
            if (screen === AgencyFinderScreen.CountryAgencies) {
                dispatch(MarketActions.sendUserActivity('Clicked Request Representation'));
            }

            const filteredNames = getState().playerV2.agencyFinder.markets.countryAgencies
                .filter((agency) => selectedAgencyIds.includes(agency.agencyId))
                .map((agency) => agency.agencyName);

            try {
                await AgencyFinderService.sendRepresentationRequestToAgency(selectedAgencyIds)
                dispatch(Actions.setScreen(AgencyFinderScreen.RequestsSent))

                if (screen === AgencyFinderScreen.CountryAgencies) {
                    dispatch(userActivityInsert({
                        PageName: `Agent Finder [${countryName}]`,
                        Message: `Representation Requests Sent (${filteredNames.length}, ${filteredNames.join(', ')})`,
                        PlayerId: playerId,
                    }))

                    filteredNames.forEach((item) => {
                        dispatch(userActivityInsert({
                            PageName: `Agent Finder [${countryName}]`,
                            Message: `Representation Request Sent: ${item}`,
                            PlayerId: playerId,
                        }))
                    })
                }

                dispatch(stateController.setState({ sendedFromScreen: screen }))
            } finally {
                dispatch(stateController.setState({ isSendRequestProcessing: false }));
                if (screen === AgencyFinderScreen.AgencyRecommendations) {
                    await dispatch(userActivityInsert({
                        PageName: 'Agent Finder [Country Agency Recommendations]',
                        Message: `Representation Requests Sent (${selectedAgencyIds.length}, ${agenciesList.map(i => i.agencyName).join(', ')})`,
                        PlayerId: playerId,
                    }))

                    agenciesList.forEach(item => (
                        dispatch(userActivityInsert({
                            PageName: 'Agent Finder [Country Agency Recommendations]',
                            Message: `Representation Request Sent (${item.agencyName})`,
                            PlayerId: playerId,
                        }))
                    ))
                }
            }
        }
    }

    public static startScrollAgencyList() {
        return (dispatch) => {
            dispatch(stateController.setState({ isScrolledAgencyList: true }));
            dispatch(MarketActions.sendUserActivity('Scrolled Agency List'));
        }
    }

    public static onAgencyCheckboxChanged(agencyId: number, agencyName: string) {
        return async (dispatch, getState: () => AppState) => {
            const { screen } = getState().playerV2.agencyFinder.common
            let countryAgencies

            if (screen === AgencyFinderScreen.AgencyRecommendations) {
                countryAgencies = getState().playerV2.agencyFinder.recommendations.recommendedAgencies
            }
            if (screen === AgencyFinderScreen.CountryAgencies) {
                countryAgencies = getState().playerV2.agencyFinder.markets.countryAgencies
            }

            const { selectedAgencyIds } = Selectors.getRoot(getState());
            const { playerId } = getAuth(getState())
            const selectedAgency = countryAgencies.filter(item => item.agencyId === agencyId).map(i => i.agencyName)
            

            dispatch(stateController.setState(prevState => {
                if (selectedAgencyIds.includes(agencyId)) {
                    return {
                        ...prevState,
                        selectedAgencyIds: prevState.selectedAgencyIds.filter(x => x !== agencyId)
                    }
                } else {
                    return {
                        ...prevState,
                        selectedAgencyIds: [...prevState.selectedAgencyIds, agencyId]
                    }
                }
            }))
            if (!selectedAgencyIds.includes(agencyId)) {
                if (screen === AgencyFinderScreen.AgencyRecommendations) {
                    dispatch(userActivityInsert({
                        PageName: 'Agent Finder [Country Agency Recommendations]',
                        Message: `Agency Selected: ${selectedAgency}`,
                        PlayerId: playerId,
                        AgencyId: agencyId,
                    }))
                }
                if (screen === AgencyFinderScreen.CountryAgencies) {
                    dispatch(MarketActions.sendUserActivity(`Selected Agency: ${agencyName}`, agencyId))
                }
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.agencyFinder.common;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



