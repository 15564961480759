import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { ScatterChartService } from 'api/player/scatter-chart.service';
import { ShortlistService } from 'api/shortlist/shortlist-service';
import { FilterStructure, ScatterChartCriteriaSandbox, ScatterChartRequest, ScatterChartData, ScatterChartItem } from 'api/player/model/scatter-chart';
import debounce from 'lodash/fp/debounce';
import { ChartPlayer } from '../chart';
import Axios from 'axios';
import { getCancelTokenSource } from 'axios-config'
import { Action } from 'rxjs/internal/scheduler/Action';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { playersDeclareInterest } from 'app/declare-interest/actions/declare-interest.actions';
import { ActionType, PageType } from 'constants/enums';
// import { PlayerInstatData } from 'api/player/model/player-instat-index'

export class SliderCriteria {
    min: number;
    max: number;
}

export class FilterCriteria {
    age: SliderCriteria;
    minutesPlayed: SliderCriteria;
    selectedPositions: Array<string>;
    orderedSandboxes: Array<ScatterChartCriteriaSandbox>;
    selectedSandboxes: Array<number>;
    selectedYear: number;
    selectedY: { id: string, name: string };
    selectedX: { id: string, name: string };
    isAvailableOnly: boolean;
    isEmpty: boolean;
    // selectedSandboxesWhenDropdownOpening: Array<number>;
}

class ScatterChartState {
    playerId: number;
    isStructureLoading: boolean;
    isDataLoading: boolean;
    filterStructure: FilterStructure;
    filterCriteria: FilterCriteria;
    scatterChartData: ScatterChartData;
    tooltipPlayer: ChartPlayer;
    isInterestDeclaring: boolean;
    customTooltipPlayer: ChartPlayer;
    criteriaChanged: boolean;
    noResultTooltip: boolean;
}

const defaultState: ScatterChartState = {
    playerId: null,
    isStructureLoading: false,
    isDataLoading: false,
    filterStructure: null,
    filterCriteria: null,
    scatterChartData: null,
    tooltipPlayer: null,
    isInterestDeclaring: false,
    customTooltipPlayer: null,
    criteriaChanged: false,
    noResultTooltip: false
}

const stateController = new StateController<ScatterChartState>(
    "PROFILE/SCATTER_CHART",
    defaultState
);

class Actions {
    public static loadCriteriaData(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let userId = state.auth.userId;
            let squadId = state.auth.squadId;

            dispatch(stateController.setState({ isStructureLoading: true }));
            var data: FilterStructure = await ScatterChartService.getData(userId, squadId, playerId);

            let criteria: FilterCriteria = {
                age: { min: 16, max: 40 },
                isEmpty: data.isEmpty,
                minutesPlayed: { min: 1000, max: null },
                selectedPositions: [],
                orderedSandboxes: [],
                // selectedSandboxesWhenDropdownOpening: [],
                selectedSandboxes: [],
                selectedYear: 2000,
                selectedX: { id: null, name: null },
                selectedY: { id: null, name: null },
                isAvailableOnly: false
            }
            if (!data.isEmpty) {
                let sandboxes = (data.sandBoxSource || []).sort((a, b) => { return compareSandboxes(a, b, [data.sandboxDefault]); });

                sandboxes = sandboxes.map(i => {
                    if (i.id == -10) i.name = `My Squad (${i.name})`;
                    else if (i.id == -20) i.name = `My Shortlist (${i.name})`
                    else i.name = `${i.country}, ${i.name}`;
                    return i;
                });

                let selectedX = (data.positionSource || []).find(i => i.name == data.positionDefault).defaultX;
                let selectedY = (data.positionSource || []).find(i => i.name == data.positionDefault).defaultY;
                criteria = {
                    age: { min: 16, max: 40 },
                    isEmpty: data.isEmpty,
                    minutesPlayed: { min: 1000, max: null },
                    selectedPositions: [data.positionDefault],
                    orderedSandboxes: sandboxes,
                    // selectedSandboxesWhenDropdownOpening: [data.sandboxDefault],
                    selectedSandboxes: [data.sandboxDefault],
                    selectedYear: data.seasonDefault,
                    selectedX: { id: selectedX.key, name: selectedX.name },
                    selectedY: { id: selectedY.key, name: selectedY.name },
                    isAvailableOnly: false
                }
            }

            dispatch(stateController.setState({ isStructureLoading: false, filterStructure: data, filterCriteria: criteria, playerId: playerId }));
            dispatch(Actions.loadChartData());
        }
    }
    public static token = null;
    public static loadChartData() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let criteria = state.profileScatterChart.filterCriteria;
            let userId = state.auth.userId;
            let squadId = state.auth.squadId;

            // criteria.selectedSandboxesWhenDropdownOpening = [...criteria.selectedSandboxes];

            dispatch(stateController.setState({ isDataLoading: true, scatterChartData: null }));
            if (!criteria.isEmpty) {

                const isAllPositionsUnchecked = criteria.selectedPositions.length === 0
                const allPositions = state.profileScatterChart.filterStructure.positionSource.map(item => item.name)

                let request: ScatterChartRequest = {
                    currentSquadId: squadId,
                    currentUserId: userId,
                    minutesMin: criteria.minutesPlayed.min,
                    positions: isAllPositionsUnchecked ? allPositions : criteria.selectedPositions,
                    sandboxes: criteria.selectedSandboxes,
                    seasonYear: criteria.selectedYear,
                    selectedPlayerId: state.profileScatterChart.playerId,
                    statPropertyX: criteria.selectedX.id,
                    statPropertyY: criteria.selectedY.id,
                    yearMax: criteria.age.max,
                    yearMin: criteria.age.min,
                    isAvailableOnly: criteria.isAvailableOnly
                }
                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();

                var data: ScatterChartData = await ScatterChartService.getChartData(request, Actions.token.token);

                if (data !== undefined) {
                    let item = data.chartItems[0];
                    if (data.chartItems.length == 1 && item.playerData.id == state.profileScatterChart.playerId) {
                        dispatch(stateController.setState({ noResultTooltip: true }))
                    }
                    else {
                        dispatch(stateController.setState({ noResultTooltip: false }))
                    }
                    dispatch(stateController.setState({ isDataLoading: false, scatterChartData: data, criteriaChanged: false }));
                }
            }

        }
    }

    public static setTooltipPlayer(player: ChartPlayer) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ tooltipPlayer: player }));
        }
    }

    public static setCustomTooltipPlayer(player: ChartPlayer) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ customTooltipPlayer: player }));
        }
    }

    public static getCurrentCustomTooltipPlayer() {
        let res = (dispatch, getState: () => AppState) => {
            return getState().profileScatterChart.customTooltipPlayer;
        }
        return res;
    }

    public static addToShortList(playerId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            await ShortlistService.addToShortlist(playerId);

            let newChartItems: Array<ScatterChartItem> = [...state.profileScatterChart.scatterChartData.chartItems];
            let find: ScatterChartItem = newChartItems.find(i => i.playerData.id == playerId);
            find.playerData.isInShort = true;

            dispatch(stateController.setState((prev) => {
                return {
                    ...prev, scatterChartData: {
                        ...prev.scatterChartData, chartItems: newChartItems
                    }
                }
            }));
            dispatch(userActivityInsert({
                PageName: 'Player Profile [Scatter chart]',
                Message: `Added to Shortlist`,
                PlayerId: playerId,
                ClubId: squadId,
                ActionType: ActionType.AddToShortlist,
                PageType: PageType.PlayerProfile
            }));
        }
    }

    public static removeFromShortList(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            await ShortlistService.removeFromShortlist(playerId);

            let newChartItems: Array<ScatterChartItem> = [...state.profileScatterChart.scatterChartData.chartItems];
            let find: ScatterChartItem = newChartItems.find(i => i.playerData.id == playerId);
            find.playerData.isInShort = false;

            dispatch(stateController.setState((prev) => {
                return {
                    ...prev, scatterChartData: {
                        ...prev.scatterChartData, chartItems: newChartItems
                    }
                }
            }));
        }
    }

    public static declareInterestForScatter(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            if (state.profileScatterChart.scatterChartData == null) return;
            let newChartItems: Array<ScatterChartItem> = [...state.profileScatterChart.scatterChartData.chartItems];
            let find: ScatterChartItem = newChartItems.find(i => i.playerData.id == playerId);
            find.playerData.interesetDeclared = true;

            dispatch(stateController.setState((prev) => {
                return {
                    ...prev,
                    scatterChartData: {
                        ...prev.scatterChartData, chartItems: newChartItems
                    },
                    isInterestDeclaring: false,
                    tooltipPlayer: playerId == (prev.tooltipPlayer.player || {}).id ?
                        { ...prev.tooltipPlayer, player: { ...prev.tooltipPlayer.player, interesetDeclared: true } } :
                        { ...prev.tooltipPlayer }
                }
            }));
        }
    }

    public static declareInterest(playerId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            dispatch(stateController.setState({ isInterestDeclaring: true }));
            dispatch(playersDeclareInterest(playerId));
            await ShortlistService.declareInterest(playerId, state.auth.squadId, state.auth.userId);

            let newChartItems: Array<ScatterChartItem> = [...state.profileScatterChart.scatterChartData.chartItems];
            let find: ScatterChartItem = newChartItems.find(i => i.playerData.id == playerId);
            find.playerData.interesetDeclared = true;

            dispatch(stateController.setState((prev) => {
                return {
                    ...prev,
                    scatterChartData: {
                        ...prev.scatterChartData, chartItems: newChartItems
                    },
                    isInterestDeclaring: false,
                    tooltipPlayer: playerId == (prev.tooltipPlayer.player || {}).id ?
                        { ...prev.tooltipPlayer, player: { ...prev.tooltipPlayer.player, interesetDeclared: true } } :
                        { ...prev.tooltipPlayer }
                }
            }));

            dispatch(userActivityInsert({
                PageName: 'Player Profile [Scatter chart]',
                Message: `Declared Interest`,
                PlayerId: playerId,
                ClubId: squadId,
                ActionType: ActionType.DeclaredInterest,
                PageType: PageType.PlayerProfile
            }));
        }
    }

    public static loadDataDebounced = debounce(
        2000,
        (dispatch) => { dispatch(Actions.loadChartData()) },
    );

    public static onSeasonChange(selectedSeason: number) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = getState().profileScatterChart;
            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, selectedYear: selectedSeason }, criteriaChanged: true }));

            let selectedSeasonTitle = substate.filterStructure.seasonSource.find(i => i.key == selectedSeason).seasonName;
            dispatch(userActivityInsert({
                PageName: 'Player Profile [Scatter chart]',
                Message: `Selected season: ${selectedSeasonTitle}`,
                ClubId: state.playerProfile.details.currentSquad.id,
                PlayerId: state.profileScatterChart.playerId,
                PageType: PageType.PlayerProfile
            }));

            // Actions.loadDataDebounced(dispatch);
        }
    }

    public static onXChange(selected: { id: string, name: string }) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            dispatch(userActivityInsert({
                Message: `X - axis: ${selected.name}`, 
                PageName: 'Player Profile [Scatter chart]', 
                ClubId: state.playerProfile.details.currentSquad.id, 
                PlayerId: state.profileScatterChart.playerId,
                PageType: PageType.PlayerProfile
            }));

            let substate = getState().profileScatterChart;
            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, selectedX: selected }, criteriaChanged: true }));
            // Actions.loadDataDebounced(dispatch);
        }
    }

    public static onYChange(selected: { id: string, name: string }) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            dispatch(userActivityInsert({
                Message: `Y - axis: ${selected.name}`, 
                PageName: 'Player Profile [Scatter chart]', 
                ClubId: state.playerProfile.details.currentSquad.id, 
                PlayerId: state.profileScatterChart.playerId,
                PageType: PageType.PlayerProfile
            }));

            let substate = getState().profileScatterChart;
            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, selectedY: selected }, criteriaChanged: true }));
            // Actions.loadDataDebounced(dispatch);
        }
    }

    public static onAgeChange(data: SliderCriteria) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().profileScatterChart;
            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, age: data }, criteriaChanged: true }));
            // Actions.loadDataDebounced(dispatch);
        }
    }

    public static onAvailabilityFilterChange() {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = getState().profileScatterChart;

            if (substate.filterCriteria.isAvailableOnly) {
                dispatch(userActivityInsert({
                    PageName: 'Player Profile [Scatter chart]',
                    Message: `Unselected available players only`,
                    ClubId: state.playerProfile.details.currentSquad.id,
                    PlayerId: state.profileScatterChart.playerId,
                    PageType: PageType.PlayerProfile
                }));
            }
            else {
                dispatch(userActivityInsert({
                    PageName: 'Player Profile [Scatter chart]',
                    Message: `Selected available players only`,
                    ClubId: state.playerProfile.details.currentSquad.id,
                    PlayerId: state.profileScatterChart.playerId,
                    PageType: PageType.PlayerProfile
                }));
            }

            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, isAvailableOnly: !substate.filterCriteria.isAvailableOnly }, criteriaChanged: true }));
            // Actions.loadDataDebounced(dispatch);
        }
    }


    public static onMinutesPlayChange(data: SliderCriteria) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().profileScatterChart;
            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, minutesPlayed: data }, criteriaChanged: true }));
            // Actions.loadDataDebounced(dispatch);
        }
    }
    public static refreshChart() {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = getState().profileScatterChart;

            let leaguesString = "";
            for (let i = 0; i < substate.filterCriteria.selectedSandboxes.length; i++) {
                const id = substate.filterCriteria.selectedSandboxes[i];
                let sand = substate.filterStructure.sandBoxSource.find(i => i.id == id);
                if (i == 0) {
                    leaguesString = leaguesString + `${sand.name}`;
                }
                else {
                    leaguesString = leaguesString + `, ${sand.name}`;
                }
            }
            dispatch(userActivityInsert({
                PageName: 'Player Profile [Scatter chart]',
                Message: `Selected leagues: ${leaguesString}`,
                ClubId: state.playerProfile.details.currentSquad.id,
                PlayerId: state.profileScatterChart.playerId,
                PageType: PageType.PlayerProfile
            }));

            substate.filterCriteria.orderedSandboxes.sort((a, b) => {
                return compareSandboxes(a, b, substate.filterCriteria.selectedSandboxes);
            });
            dispatch(Actions.loadChartData());
        }
    }

    public static toggleSandbox(sandbox: number) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().profileScatterChart;

            let selected: Array<number> = [];
            // let sandboxes: Array<ScatterChartCriteriaSandbox> = [];

            let find = substate.filterCriteria.selectedSandboxes.find(i => sandbox == i);
            let sand = substate.filterStructure.sandBoxSource.find(i => i.id == sandbox);

            if (find) {
                if (substate.filterCriteria.selectedSandboxes.length > 1)
                    selected = substate.filterCriteria.selectedSandboxes.filter(i => i != find);
                else
                    selected = [...substate.filterCriteria.selectedSandboxes];
            }
            else {
                if (substate.filterCriteria.selectedSandboxes.length < 10) {
                    selected = [...substate.filterCriteria.selectedSandboxes, sandbox];
                }
                else {
                    selected = [...substate.filterCriteria.selectedSandboxes];
                }
            }

            dispatch(stateController.setState({ filterCriteria: { ...substate.filterCriteria, selectedSandboxes: selected }, criteriaChanged: true }));
        }
    }

    public static togglePosition(position: string) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = getState().profileScatterChart;
            let selected: Array<string> = [];

            let find = substate.filterCriteria.selectedPositions.find(i => position == i);
            if (find) {
                dispatch(userActivityInsert({
                    PageName: 'Player Profile [Scatter chart]',
                    Message: `Unselected position: ${position}`,
                    ClubId: state.playerProfile.details.currentSquad.id,
                    PlayerId: state.profileScatterChart.playerId,
                    PageType: PageType.PlayerProfile
                }));
                selected = substate.filterCriteria.selectedPositions.filter(i => i != find);
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Player Profile [Scatter chart]',
                    Message: `Selected position: ${position}`,
                    ClubId: state.playerProfile.details.currentSquad.id,
                    PlayerId: state.profileScatterChart.playerId,
                    PageType: PageType.PlayerProfile
                }));
                selected = [...substate.filterCriteria.selectedPositions, position];
            }

            let isAllUnchecked = selected.length === 0

            let firstPos = isAllUnchecked ? substate.filterStructure.positionSource[0].name : selected[0]
            
            let defaultX, defaultY;
            if (firstPos) {
                defaultX = substate.filterStructure.positionSource.find(i => i.name == firstPos).defaultX;
                defaultY = substate.filterStructure.positionSource.find(i => i.name == firstPos).defaultY;
            }

            let availablePropertiesForAllSelectedPositions = []
            if (isAllUnchecked) {
                const allNames = substate.filterStructure.positionSource.map(item => item.name)
                allNames.forEach(item => {
                    const availableForPosition = substate.filterStructure.positionSource.find(i => i.name == item).availableProperties
                    availableForPosition.forEach(item => {
                        if (!availablePropertiesForAllSelectedPositions.some(i => i.key === item.key)) {
                            availablePropertiesForAllSelectedPositions.push(item)
                        }
                    })
                })
            } else {
                selected.forEach(item => {
                    const availableForPosition = substate.filterStructure.positionSource.find(i => i.name == item).availableProperties
                    availableForPosition.forEach(item => {
                        if (!availablePropertiesForAllSelectedPositions.some(i => i.key === item.key)) {
                            availablePropertiesForAllSelectedPositions.push(item)
                        }
                    })
                })
            }

            let selectedX;
            let selectedY;

            if (availablePropertiesForAllSelectedPositions.some(item => item.key === substate.filterCriteria.selectedX.id)) {
                selectedX = substate.filterCriteria.selectedX
            } else {
                selectedX = { id: defaultX.key, name: defaultX.name }
            }

            if (availablePropertiesForAllSelectedPositions.some(item => item.key === substate.filterCriteria.selectedY.id)) {
                selectedY = substate.filterCriteria.selectedY
            } else {
                selectedY = { id: defaultY.key, name: defaultY.name }
            }

            dispatch(stateController.setState({
                filterCriteria: {
                    ...substate.filterCriteria,
                    selectedPositions: selected,
                    selectedX: selectedX,
                    selectedY: selectedY
                },
                criteriaChanged: true
            }));
            // Actions.loadDataDebounced(dispatch);
        }
    }

    // public static setSelectedSandboxesWhenDdownWasOpening() {
    //     return (dispatch, getState: () => AppState) => {
    //         let state = getState();
    //         let criteria = state.profileScatterChart.filterCriteria;
    //         criteria.selectedSandboxes = [...criteria.selectedSandboxesWhenDropdownOpening];
    //         dispatch(stateController.setState({ filterCriteria: { ...criteria } }));
    //     }
    // }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }, "DISPOSING SCATTER"));
        }
    }
}

function compareSandboxes(a: ScatterChartCriteriaSandbox, b: ScatterChartCriteriaSandbox, selected: Array<number>) {
    if (a.id == -10) { return -1; }
    if (a.id == -20 && b.id != -10) { return -1; }
    if (a.id == -20 && b.id == -10) { return 1; }
    if ((a.id == -10 || a.id == -20) && (b.id != -10 && b.id != -20)) { return -1 }
    if ((a.id != -10 && a.id != -20) && (b.id != -10 && b.id != -20)) {
        let aSelected = selected.find(i => i == a.id);
        let bSelected = selected.find(i => i == b.id);
        if (aSelected && !bSelected) { return -1 }
        if (!aSelected && bSelected) { return 1 }
    }

    return 0;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ScatterChartState as State,
    Actions as Actions,
    stateController as Controller
};



