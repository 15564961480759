import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { EmailConfirmationService } from 'api/player-side/email-confirmation.service';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { AgentPlayerAgreementTypeEnum, AgentPlayerInvitationStatusEnum } from 'api/agency/player/shared/shared-models';
import historyAccessor from 'history-accessor';
import { HomePageService } from 'api/player-v2/home-page/home-page.service';
import { AgencyModel, GeneralPlayerInfoModel, SetInvitationStatusToPreconnectedNotTrustedRequest } from 'api/player-v2/home-page/models';
import { Agency } from './my-agent-section';
import { playerPathsV2 } from 'routes/paths';
import userActivityInsert from "app/user-activity/actions/user-activity.actions";
import { getAuth } from 'store/auth/authReducer';
import moment from 'moment';
import config from 'config';
import { getPlayerUserPermissions, getUserPreference } from 'store/userPreference/userPreference.reducer';
import { PLAYER_SHOW_EXPLORE_PERFORMANCE_BANNER_FALSE } from 'store/actionTypes';
const LOCAL_STORAGE_KEY = 'playerReferralBanner'

class State {
    isLoading: boolean;
    serverData: GeneralPlayerInfoModel;
    agencyInfo: AgencyModel;
    appBanner: AppBannerModel;
    isInviteAgencyModalOpen: boolean;
    isAddAgencyContractModalOpen: boolean;
    isRequestRepresentationModalOpen: boolean;
    isSendEmailProcessing: boolean;
    sentToEmail: string | null;
    isRequestRepresentationProcessing: boolean;
    isInvitationProcessing: boolean;
    isAgentFinderClicked: boolean;
    agencyName?: string;
    isShowBanner: boolean;
    isShowApplicationBanner: boolean;
    isExplorePerformanceBanner: boolean;
}

export interface ReferralBannerItem {
    logInCounter: number;
    dismissedDate: string;
}
export interface AppBannerModel {
    deviceType: DeviceType
};
export interface ReferralBanner_LocalStorageInfo {
    [userId: string]: ReferralBannerItem;
}
export enum DeviceType {
    Android = 1,
    Apple = 2,
};

const appBannerState = {
    deviceType: 1,
};
const agencyState = {
    id: 0,
    name: '',
    isTransparent: false,
    isTrusted: false,
    playersCount: 0,
    primaryMarket: {
        id: 0,
        name: '',
        flagPath: '',
    },
    secondaryMarket: {
        id: 0,
        name: '',
        flagPath: '',
    },
    coachesCount: null,
};

const defaultState: State = {
    isLoading: false,
    serverData: null,
    agencyInfo: agencyState,
    appBanner: appBannerState,
    isInviteAgencyModalOpen: false,
    isAddAgencyContractModalOpen: false,
    isRequestRepresentationModalOpen: false,
    isSendEmailProcessing: false,
    sentToEmail: null,
    isRequestRepresentationProcessing: false,
    isInvitationProcessing: false,
    isAgentFinderClicked: false,
    agencyName: '',
    isShowBanner: false,
    isShowApplicationBanner: false,
    isExplorePerformanceBanner: false,
}

const stateController = new StateController<State>(
    "PLAYERV2/HOME-PAGE",
    defaultState
)

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const userPreference = getUserPreference(getState());
            const forcePlayerPreferencesScreen = userPreference?.playerUserPermissions?.forcePlayerPreferencesScreen

            if (forcePlayerPreferencesScreen)
                historyAccessor.push(playerPathsV2.playerPreferences)

            dispatch(stateController.setState({ isLoading: true }));
            dispatch(Actions.showExplorePerformanceBanner());
            dispatch(Actions.showReferralBanner());
            dispatch(AppBannerActions.init());

            let data = await HomePageService.init();

            if (data.redirectPolicy.isAgencyConfirmationNeeded)
                historyAccessor.push(playerPathsV2.agencyConfirm);

            if (data.redirectPolicy.isDealConfirmationNeeded)
                historyAccessor.push(playerPathsV2.dealConfirmPage);
            dispatch(stateController.setState({ isLoading: false, serverData: data, agencyInfo: data.agency }))
        }
    }

    public static sendEmailConfirmation() {
        return async (dispatch, getState: () => AppState) => {
            const playerEmail = getState().auth.userEmail;
            await EmailConfirmationService.sendConfirmEmail({ newEmail: playerEmail, oldEmail: playerEmail });
        }
    }

    public static openInviteAgencyModal() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Clicked Send Invitation Email', 'My Agent'));
            dispatch(stateController.setState({ isInviteAgencyModalOpen: true }))
        }
    }

    public static closeInviteAgencyModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isInviteAgencyModalOpen: false }))
        }
    }

    public static openAddAgencyContractModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isAddAgencyContractModalOpen: true }))
        }
    }

    public static closeAddAgencyContractModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isAddAgencyContractModalOpen: false }))
        }
    }

    public static openRequestRepresentationModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isRequestRepresentationModalOpen: true }))
            dispatch(Actions.sendUserActivity('Clicked Request Representation ', 'My Agent'));
        }
    }

    public static closeRequestRepresentationModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isRequestRepresentationModalOpen: false }))
        }
    }

    public static openAgentFinder() {
        return (dispatch) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isAgentFinderClicked: true,
            })));
            historyAccessor.push(playerPathsV2.agentFinder)
        }
    }

    public static openInviteAgency() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Clicked Select Current Agent', 'My Agent'));
            historyAccessor.push(playerPathsV2.inviteAgency)
        }
    }

    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            dispatch(userActivityInsert({
                Message: message,
                PageName: `My Page [${pageName}]`,
                PlayerId: playerId,
            }))
        }
    }

    public static resendVerificationEmail() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isSendEmailProcessing: true }));
                const playerEmail = getState().auth.userEmail;
                await dispatch(Actions.sendEmailConfirmation());
                dispatch(stateController.setState({ sentToEmail: playerEmail }));
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isSendEmailProcessing: false }));
                dispatch(Actions.sendUserActivity('Clicked Resend Verification Email', 'Action Required'));
            }
        }
    }

    public static requestRepresentation(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isRequestRepresentationProcessing: true }));
                await AgencyFinderService.addAgency(agencyId)
                await dispatch(Actions.init())

            } finally {
                dispatch(stateController.setState({ isRequestRepresentationProcessing: false }));
                dispatch(stateController.setState({ isRequestRepresentationModalOpen: false }))
            }
        }
    }

    public static setInvitationStatusForNotTrustedAgency() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().playerV2.inviteAgency;
            const agencyId = getState().playerV2.homePage.agencyInfo.id;
            const { formData } = substate;
            const agentFirstName = formData.firstName;
            const agentLastName = formData.lastName;
            const agentName = `${agentFirstName} ${agentLastName}`;
            try {
                dispatch(stateController.setState({ isInvitationProcessing: true }));

                const payload: SetInvitationStatusToPreconnectedNotTrustedRequest = {
                    agencyId,
                    agentFirstName,
                    agentLastName,
                    agentEmail: formData.email,
                    phone: formData.phoneNumberInput,
                    phoneCodeAreaId: formData.phoneCodeAreaId
                };
                await HomePageService.setInvitationStatus(payload);
                await dispatch(Actions.init());

                dispatch(Actions.trackUserActivityInsert({
                    pageType: 'Invite Agency',
                    message: `Agency Invite Sent: ${agentName}`,
                    agencyId: agencyId,
                }));
                dispatch(stateController.setState({ isInviteAgencyModalOpen: false }));
            }
            finally {
                dispatch(stateController.setState({ isInvitationProcessing: false }));
            }
        }
    }

    public static trackUserActivityInsert(data: any) {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId
            dispatch(userActivityInsert({
                PageName: `Select Agent [${data.pageType}]`,
                Message: data.message,
                PlayerId: playerId,
                AgencyId: data.agencyId
            }));
        }
    }

    public static openInvitePlayers() {
        return (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId;
            dispatch(Actions.sendUserActivity('Tell More Players', 'Referral Banner'));

            const localStorageInfo: ReferralBanner_LocalStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                ...localStorageInfo,
                [userId]: {
                    ...localStorageInfo[userId],
                    dismissedDate: 'Never again!'
                }
            }))
            historyAccessor.push(playerPathsV2.invitePlayers);
        }
    }

    public static hideReferalBanner() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Dismissed', 'Referral Banner'));
            const userId = getAuth(getState()).userId;
            const localStorageInfo: ReferralBanner_LocalStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                ...localStorageInfo,
                [userId]: {
                    ...localStorageInfo[userId],
                    dismissedDate: `${moment()}`,
                }
            }))

            dispatch(stateController.setState({ isShowBanner: false }));
        }
    }

    public static showReferralBanner() {
        return (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId;
            // const isNewPlayer = getState().auth.isNewPlayerForReferralBanner;
            const localStorageInfo: ReferralBanner_LocalStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            const oldDate = localStorageInfo[userId]?.dismissedDate;
            const isPlayerReferralsAvailable = getPlayerUserPermissions(getState())?.isPlayerReferralsAvailable;
            const showExplorePerformanceBanner = Selectors.getShowExplorePerformanceBanner(getState());
            // const logInCounter = localStorageInfo[userId]?.logInCounter;

            // if (isNewPlayer && logInCounter < 2 || !localStorageInfo[userId]) {
            if (!localStorageInfo[userId]) {
                return;
            }

            if (showExplorePerformanceBanner && isPlayerReferralsAvailable) {
                dispatch(stateController.setState({ isShowBanner: false }));
                return;
            }

            if (oldDate === 'Never again!') {
                dispatch(stateController.setState({ isShowBanner: false }));
                return;
            }

            if (!oldDate) {
                dispatch(stateController.setState({ isShowBanner: true }))
                return;
            }

            if (oldDate) {
                const currentDate = moment();
                const difference = currentDate.diff(oldDate, 'day')  // oldDate, set 'minute' for testing
                if (difference >= 14) {
                    dispatch(stateController.setState({ isShowBanner: true }))
                }
            }
        }
    }

    public static showExplorePerformanceBanner() {
        return (dispatch, getState: () => AppState) => {
            const showExplorePerformanceBanner = Selectors.getShowExplorePerformanceBanner(getState());
            dispatch(stateController.setState({ isExplorePerformanceBanner: showExplorePerformanceBanner }))
        }
    }

    public static hideExplorePerformanceBanner() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isExplorePerformanceBanner: false }));
            dispatch({ type: PLAYER_SHOW_EXPLORE_PERFORMANCE_BANNER_FALSE });

            await HomePageService.closeExplorePerformanceBanner();
        }
    }

    public static openPlayerProfile() {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;

            dispatch(userActivityInsert({
                PageName: `Home Page [Explore Performance Banner]`,
                Message: 'Explore Performance Button Clicked',
                PlayerId: playerId,
            }));

            dispatch(Actions.hideExplorePerformanceBanner());

            historyAccessor.push(`${playerPathsV2.myProfile}?performance`);
        }
    }

    public static closeExplorePerformanceBanner() {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            dispatch(userActivityInsert({
                PageName: `Home Page [Explore Performance Banner]`,
                Message: 'Dismiss Button Clicked',
                PlayerId: playerId,
            }))
            dispatch(Actions.hideExplorePerformanceBanner());
        }
    }
}
class AppBannerActions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const isMobileDevice = getAuth(getState()).metaInfo?.deviceVersion.toLowerCase().includes('iphone') || getAuth(getState()).metaInfo?.deviceVersion.toLowerCase().includes('android')
            if (isMobileDevice) {
                const device = getAuth(getState()).metaInfo?.deviceVersion.toLowerCase().includes('iphone') ? DeviceType.Apple : DeviceType.Android
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    appBanner: {
                        deviceType: device,
                    },
                })));
                dispatch(stateController.setState({ isShowApplicationBanner: true }))
            }
        }
    }
    public static goToStore() {
        return async (dispatch, getState: () => AppState) => {
            const { deviceType } = getState().playerV2.homePage.appBanner;
            if (deviceType === DeviceType.Apple) {
                window.open(`${config.baseUrl}/rnmobileappios`, '_self');
            } else {
                window.open(`${config.baseUrl}/mobileapprnandroid`, '_self');
            }
            dispatch(Actions.sendUserActivity('Viewed Download App', 'Download App'));
        }
    }
}



class Selectors {
    public static getRoot = (state: AppState) => state.playerV2.homePage;

    public static getMyAgentData = (state: AppState): Agency => {
        const data = state.playerV2.homePage.serverData;
        if (data == null || data.agency == null)
            return null;

        return {
            id: data.agency.id,
            name: data.agency.name,
            agentSince: data.agentPlayerInvitation.invitationCreatedAt,
            agreement: data.agentPlayerInvitation.agreementType == AgentPlayerAgreementTypeEnum.None ?
                null :
                {
                    agreementType: data.agentPlayerInvitation.agreementType,
                    agreementLastConfirmed: data.agentPlayerInvitation.agreementLastModifiedDate,
                    contractExpiry: data.agentPlayerInvitation.agreementExpiryDate
                },
            isConnected: data.agentPlayerInvitation.invitationStatus == AgentPlayerInvitationStatusEnum.VerifiedAndConfirmed,
            isTransparent: data.agency.isTransparent,
            isTrusted: data.agency.isTrusted,
            sentInvitationExpireInDays: data.agentPlayerInvitation.sentInvitationExpireInDays,
            sentRequestExpireInDays: data.agentPlayerInvitation.sentRequestExpireInDays
        }
    }
    public static getMyAgencyData = (state: AppState): AgencyModel => state.playerV2.homePage.agencyInfo;

    public static getReferralBannerVisibility = (state: AppState): boolean => {
        const isBannerAvailable = getPlayerUserPermissions(state)?.isPlayerReferralsAvailable;
        const showBanner = isBannerAvailable ? state.playerV2.homePage.isShowBanner : isBannerAvailable;

        return showBanner;
    }

    public static getShowExplorePerformanceBanner = (state: AppState): boolean => {
        return getPlayerUserPermissions(state)?.showExplorePerformanceBanner;
    }

    public static getSectionVisibilityForMinorPlayer = (state: AppState): boolean => {
        const isMinorPlayer = !getPlayerUserPermissions(state)?.isPlayerReferralsAvailable && !getPlayerUserPermissions(state)?.isAgentFinderAvailable &&
            !getPlayerUserPermissions(state)?.isMessagesAvailable && !getPlayerUserPermissions(state)?.isPlayerSettingsAvailable;

        const playerHasConnectedAgency = Selectors.getMyAgentData(state) !== null && Selectors.getMyAgentData(state).isConnected;
        const showForMinorPlayer = (isMinorPlayer && !playerHasConnectedAgency) ? false : true;

        return showForMinorPlayer;
    }

    public static getHideMyAgentForPreconnectedPlayer = (state: AppState): boolean => {
        const hideMyAgentSection = state.playerV2.homePage.serverData?.agentPlayerInvitation?.hideMyAgentSection;
        return hideMyAgentSection ?? false;
    }
}



const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    AppBannerActions as AppBannerActions,
    Selectors as Selectors,
    stateController as Controller
};