import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import winterVirtualDealDayImage from 'media/images/events/2023-16-january-virtual/winter-virtual-deal-day-thumb.png'

const VirtualDealDay16thJanuary = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="3"
                        bgImageNumber={8}
                        th="rd"
                        title1="TransferRoom"
                        title2="Virtual Winter Deal Day"
                        date="16th Jan 2023, Online"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0 10%' }}>
                        <img style={{ width: '100%' }} src={winterVirtualDealDayImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="393"
                        countries="47"
                        leagues="82"
                        agents="45"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

export const previousEventAttendees = [
    { id: 1, name: "AC Milan", logo: "logo_1834_AC_Milan.png" },
    { id: 49, name: "Arsenal FC", logo: "logo_1121_Arsenal_FC.png" },
    { id: 976, name: "AS Monaco", logo: "19830_AS_Monaco.png" },
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 23506, name: "Atlas Guadalajara", logo: "15407_Atlas.png" },
    { id: 6249, name: "Bayer 04 Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 30723, name: "Borussia Dortmund", logo: "logo_1259_Borussia_Dortmund.png" },
    { id: 8239, name: "Brentford FC", logo: "logo_1153_Brentford_FC.png" },
    { id: 12027, name: "CA Boston River", logo: "logo_2875_Club_Atletico_Boston_River.png" },
    { id: 9984, name: "CA Velez Sarsfield", logo: "12073_Club_Atlético_Vélez_Sarsfield.png" },
    { id: 5924, name: "CD Everton", logo: "12293_CD_Everton.png" },
    { id: 7845, name: "CF Pachuca", logo: "15413_CF_Pachuca.png" },
    { id: 174, name: "Club Atletico Talleres", logo: "logo_2588_Club_Atletico_Talleres.png" },
    { id: 7083, name: "Elche CF", logo: "logo_1793_Elche_CF.png" },
    { id: 15549, name: "FC Basel 1893", logo: "logo_1932_FC_Basel_1893.png" },
    { id: 1559, name: "FC Cincinnati", logo: "37892_Cincinnati.png" },
    { id: 5159, name: "FC Groningen", logo: "logo_FC_Groningen.png" },
    { id: 18960, name: "FC Porto", logo: "logo_1672_FC_Porto.png" },
    { id: 17, name: "Genoa CFC", logo: "logo_1843_Genoa_CFC.png" },
    { id: 773, name: "Getafe CF", logo: "logo_1728_Getafe_CF.png" },
    { id: 1421, name: "Gremio Foot-Ball Porto Alegrense", logo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png" },
    { id: 8110, name: "Hertha BSC", logo: "2457_Hertha_BSC.png" },
    { id: 496, name: "HNK Rijeka", logo: "logo_2164_HNK_Rijeka.png" },
    { id: 2052, name: "Huachipato FC", logo: "12286_Huachipato_FC.png" },
    { id: 1949, name: "Independiente Medellin", logo: "12399_Independiente_Medellín.png" },
    { id: 5180, name: "Istanbul Basaksehir FK", logo: "logo_2005_Medipol_Basaksehir.png" },
    { id: 46, name: "Liverpool FC", logo: "logo_1123_Liverpool_FC.png" },
    { id: 7972, name: "LOSC Lille", logo: "logo_1974_LOSC_Lille.png" },
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 45, name: "Manchester United", logo: null }, //"logo_1120_Manchester_United.png"
    { id: 60, name: "Newcastle United", logo: "logo_Newcastle_United.png" },
    { id: 21139, name: "Olympiacos Piraeus", logo: "12509_Olympiacos_Piraeus.png" },
    { id: 12294, name: "Olympique Marseille", logo: "logo_1964_Olympique_Marseille.png" },
    { id: 4, name: "Parma Calcio 1913", logo: "3160_Parma_Calcio_1913.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 19206, name: "Santos FC", logo: "5655_Santos_FC.png" },
    { id: 1065, name: "SC Braga", logo: "logo_Sporting_Braga.png" },
    { id: 3695, name: "SK Slavia Prague", logo: "logo_2051_SK_Slavia_Prag.png" },
    { id: 3476, name: "Sporting CP", logo: "9608_Sporting_CP.png" },
    { id: 47, name: "Tottenham Hotspur", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 6571, name: "US Salernitana 1919", logo: "logo_1865_US_Salernitana_1919.png" },
    { id: 12470, name: "VfL Wolfsburg", logo: "logo_VfL_Wolfsburg.png" }
]

export default VirtualDealDay16thJanuary