import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { PlayerTransparencyItem, SavePlayerTransparencyItem } from 'api/agency/agent/agency-transparency/models';
import AgencyTransparencyService from 'api/agency/agent/agency-transparency/agency-transparency.service';
import { Actions as DashBoardStateActions } from 'pages/agency/authorized-agent/home-page/redux/dashboard.controller';
import _ from 'lodash';

export class ManageSharingModalState {
    isLoading: boolean;
    openManageSharingModal: boolean;
    items: PlayerTransparencyItem[];
    isDirty: boolean;
}

const defaultState: ManageSharingModalState = {
    isLoading: false,
    openManageSharingModal: false,
    items: [],
    isDirty: true,
}

const stateController = new StateController<ManageSharingModalState>(
    "LANDING_PAGE/MANAGE_SHARING_MODAL",
    defaultState
);

class Actions {

    public static saveSharingPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))
                const playerItems = getState().landingPage.manageSharingModal.items

                const updatedPlayers = playerItems.map<SavePlayerTransparencyItem>(x => ({
                    playerId: x.player.id,
                    isInsightsShared: x.isInsightsShared,
                }));

                await AgencyTransparencyService.updateSharingSettings(updatedPlayers);
                dispatch(Actions.closeManageSharingModal(false));
                dispatch(Actions.setUserActivity());
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
            dispatch(DashBoardStateActions.loadTransparencyData());
        }
    }

    public static setSharing(id: number, isInsightsShared: boolean) {
        return (dispatch, getState: () => AppState) => {
            const playerItems = getState().landingPage.manageSharingModal.items;
            const initialItems = getState().agency.landingPage.dashboard.priorityPlayerItems;

            const updatedItems = [...playerItems];
            const updatedPlayer = updatedItems.find(item => item.player.id === id);

            updatedPlayer.isInsightsShared = !isInsightsShared;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                items: updatedItems.map(item => ({ ...item })),
            })))

            if (_.isEqual(playerItems, initialItems)) {
                dispatch(stateController.setState({ isDirty: true }));
            } else {
                dispatch(stateController.setState({ isDirty: false }));
            }
        }
    }

    public static setUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const initialItems = getState().agency.landingPage.dashboard.priorityPlayerItems;
            const playerItems = getState().landingPage.manageSharingModal.items;
            let sharedPlayers = [];
            let unsharedPlayers = [];

            const changedItems = playerItems.filter((player, i) => player.isInsightsShared !== initialItems[i].isInsightsShared);

            changedItems.map(item => {
                if (item.isInsightsShared) {
                    sharedPlayers.push(item.player.name);
                } else {
                    unsharedPlayers.push(item.player.name);
                }
            });

            if (sharedPlayers.length) {
                sharedPlayers.forEach(item => dispatch(userActivityInsert({
                    PageName: 'Agency Home [Transparency Sharing Settings]',
                    Message: `Shared (${item})`,
                    PageType: PageType.AgencyHome
                }))

                )
            }
            if (unsharedPlayers.length) {
                unsharedPlayers.forEach(item => dispatch(userActivityInsert({
                    PageName: 'Agency Home [Transparency Sharing Settings]',
                    Message: `Unshared (${item})`,
                    PageType: PageType.AgencyHome
                })))
            }
        }
    }

    public static openManageSharingModal() {
        return (dispatch, getState: () => AppState) => {
            const initialItems = getState().agency.landingPage.dashboard.priorityPlayerItems;

            dispatch(stateController.setState({
                openManageSharingModal: true,
                items: initialItems.map(item => ({ ...item })),
            }));
            dispatch(userActivityInsert({
                PageName: 'Agency Home[High Transparency]',
                Message: 'Clicked Manage Sharing',
            }))
        }
    }

    public static closeManageSharingModal(withUA = true) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ openManageSharingModal: false }));
            dispatch(stateController.setState({ isDirty: true }));
            if (withUA) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Transparency Sharing Settings]',
                    Message: 'Cancelled',
                    PageType: PageType.AgencyHome
                }));
            }
        }
    }

}

class Selectors {
    public static getIsInsightsSharedItems = (state: AppState) => state.landingPage.manageSharingModal.items.filter(item => item.isInsightsShared)
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ManageSharingModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};


