import {
    PAGINATION_TURN_PAGE,
    CLUB_ADS_FETCH,
    CLUB_ADS_MY_PITCHES_FETCH,
    CLUB_ADS_CLEAR,
    CLUB_ADS_CLEAR_MY_PITCHES,
} from 'store/actionTypes';
import { getClubAds } from 'pages/Pitch/store/reducers/club-ads.reducer';

const initialPaginationState = {
    currentPage: 1,
    rowCount: 0,
    pageSize: 20,
    pageCount: 0,
};

const initialState = {
    clubAds: initialPaginationState,
    myPitches: initialPaginationState,
};
export default (pagination = initialState, action) => {
    switch (action.type) {
        case PAGINATION_TURN_PAGE: {
            const { appPage, page } = action.payload;
            return {
                ...pagination,
                [appPage]: { ...pagination[appPage], currentPage: page },
            };
        }
        case CLUB_ADS_CLEAR:
        case CLUB_ADS_CLEAR_MY_PITCHES: {
            return {
                ...initialState,
            };
        }
       
        case CLUB_ADS_FETCH.SUCCESS: {
            
            const { scope } = action.payload.request;
            const {
                rowCount,
                pageCount,
                currentPageNumber,
            } = action.payload.data.items;

            return {
                ...pagination,
                [scope]: {
                    ...pagination[scope],
                    ...{
                        rowCount,
                        pageCount,
                        currentPage: currentPageNumber,
                    },
                },
            };
        }
        case CLUB_ADS_MY_PITCHES_FETCH.SUCCESS: {
            const { scope } = action.payload.request;
            const {
                rowCount,
                pageCount,
                currentPageNumber,
            } = action.payload.data;

            return {
                ...pagination,
                [scope]: {
                    ...pagination[scope],
                    ...{
                        rowCount,
                        pageCount,
                        currentPage: currentPageNumber,
                    },
                },
            };
        }

        default:
            return pagination;
    }
};

export const getPagination = scope => state =>
    getClubAds(state).pagination[scope];

export const getCurrentPage = scope => state =>
    getClubAds(state).pagination[scope].currentPage;

export const getRowCount = scope => state =>
    getClubAds(state).pagination[scope].rowCount;
export const getPageSize = scope => state =>
    getClubAds(state).pagination[scope].pageSize;
export const getPageCount = scope => state =>
    getClubAds(state).pagination[scope].pageCount;
