import { CloseReason, PitchPlayerModel } from "api/agency/custom-pitch/models";
import { Comparisonitem } from "api/super-pitch/models/comparison";
import { anonymousPaths } from "routes/paths";
import config from 'config'

export const generateLink = (publishedUid: string) => {
    const relativeUrl = anonymousPaths.customPitchPublished.replace(":id", publishedUid)
    return `${window.location.origin}${relativeUrl}`;
}

export const getClubName = (clubInfo: PitchPlayerModel) => {
    const playerOnLoan = clubInfo.onLoanSquadName.length > 0;
    return clubInfo.isFreeAgent ? 'Free Agent' : playerOnLoan ? clubInfo.onLoanSquadName : clubInfo.parentSquad?.name;
}

export const getAgencyLogoUrl = (agencyLogo: string) => {
    if(agencyLogo?.includes('https://')){
        return agencyLogo;
    }

    return `${config.imagesUrl}/agency/logo/${agencyLogo}`;
}

export const closeReasonTextById = {
    [CloseReason.PlayerLevel]: 'Player level',
    [CloseReason.ClubNotLookingForPosition]: 'Club not looking for that position',
    [CloseReason.ClubLookingForYoungerPlayers]: 'Club looking for younger players',
    [CloseReason.FeeIsTooHigh]: 'Expected transfer fee is too high',
    [CloseReason.SalaryOutOfRange]: 'Expected Salary is out of range',
    [CloseReason.NotNow]: 'Not now, maybe later',
    [CloseReason.Other]: 'Other'
}