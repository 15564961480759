import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { EmailConfirmationService } from 'api/player-side/email-confirmation.service'
import validator from 'services/validator';
import { logoutWithRedirect } from 'store/auth/auth.actions';
import { anonymousPaths } from 'routes/paths'
import { EmailConfirmationActivityService } from './email-confirmation.activity'
import historyAccessor from 'history-accessor';

export enum EmailConfirmationStep {
    InvalidEmail = 0,
    ActivationSent = 1,
    ChangeEmail = 2,
    SendEmailDone = 3
}

class PlayerEmailConfirmationState {
    currentStep: EmailConfirmationStep;
    email: string;
    emailError: boolean;
    sentToEmail: string;

    didTryToProcess: boolean;

    isInitialyLoading: boolean;
    isSendEmailProcessing: boolean;
    isConfirmPageProcessing: boolean;
    isConfirmSucceed: boolean;

    firstVisit: boolean;
}

const defaultState: PlayerEmailConfirmationState = {
    currentStep: EmailConfirmationStep.ActivationSent,
    email: '',
    didTryToProcess: false,
    emailError: false,
    sentToEmail: '',

    isInitialyLoading: false,
    isSendEmailProcessing: false,

    isConfirmPageProcessing: false,
    isConfirmSucceed: null,


    firstVisit: true
}

const stateController = new StateController<PlayerEmailConfirmationState>(
    'PLAYER_EMAIL_CONFIRMATION',
    defaultState
)

class Actions {

    public static sendActivationLink(oldEmail: string, newEmail: string) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            try {
                let res = await EmailConfirmationService.sendConfirmEmail({ newEmail: newEmail, oldEmail: oldEmail })
                resolve(res)
            } catch (err) {
                console.error(err)
                reject()
            }
        })
    }

    public static validateGuid(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isConfirmPageProcessing: true }))
                const { isSuccess } = await EmailConfirmationService.confirmEmailVerification(guid);
                if (isSuccess) {
                    dispatch(stateController.setState({ isConfirmSucceed: true }))
                } else {
                    dispatch(stateController.setState({ isConfirmSucceed: false }))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isConfirmPageProcessing: false }))
            }
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const playerEmail = getState().auth.userEmail
            try {
                dispatch(stateController.setState({ isInitialyLoading: true }))
                await dispatch(Actions.sendActivationLink(playerEmail, playerEmail))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isInitialyLoading: false, firstVisit: false }))
            }
        }
    }
    public static resendActivationLink() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const playerEmail = getState().auth.userEmail
                dispatch(stateController.setState({ isSendEmailProcessing: true, sentToEmail: playerEmail }))
                await dispatch(Actions.sendActivationLink(playerEmail, playerEmail))
                dispatch(EmailConfirmationActivityService.resendEmail(getState().auth.playerId))
                dispatch(Actions.goToStep(EmailConfirmationStep.SendEmailDone))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isSendEmailProcessing: false }))
            }
        }
    }

    public static closeActivation() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(EmailConfirmationActivityService.closedActivation(getState().auth.playerId))
            historyAccessor.goBack()
        }
    }

    public static openChangeEmail() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(EmailConfirmationActivityService.openedEmailUpdate(getState().auth.playerId))
            dispatch(Actions.goToStep(EmailConfirmationStep.ChangeEmail))
        }
    }

    public static sendActivationLinkToNewEmail() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const playerOldEmail = getState().auth.userEmail
                const playerNewEmail = getState().player.emailConfirmationFlow.email

                const isEmailValid = validator.isValidEmail(playerNewEmail)
                if (!isEmailValid) {
                    dispatch(stateController.setState({ emailError: true, didTryToProcess: true }))
                } else {
                    dispatch(stateController.setState({ isSendEmailProcessing: true, sentToEmail: playerNewEmail }))
                    let res = await dispatch(Actions.sendActivationLink(playerOldEmail, playerNewEmail));
                    if (res.isSuccess) {
                        dispatch(Actions.goToStep(EmailConfirmationStep.SendEmailDone))
                        dispatch(EmailConfirmationActivityService.sendActivationEmail(getState().auth.playerId))
                    }
                    else {
                        dispatch(logoutWithRedirect(anonymousPaths.accountBlocked));
                    }
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isSendEmailProcessing: false }))
            }
        }
    }

    public static closeActivationLinkToNewEmail() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.goToStep(EmailConfirmationStep.ActivationSent))
            dispatch(EmailConfirmationActivityService.closedActivationEmail(getState().auth.playerId))
        }
    }

    public static goToStep(step: EmailConfirmationStep) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }))
        }
    }

    public static onChangeEmail(email: string) {
        return (dispatch) => {
            dispatch(stateController.setState({ email: email }))

            if (email.length === 0) {
                dispatch(stateController.setState({ didTryToProcess: false }))
            }

            const isEmailValid = validator.isValidEmail(email)
            dispatch(stateController.setState({ emailError: !isEmailValid }))
        }
    }
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }




}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerEmailConfirmationState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};