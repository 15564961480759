export const testimonials = [
    {
        fullName: 'Claus Steinlein',
        position: 'CEO',
        club: 'FC Midtjylland',
        img: require('media/images/events/2023-march-london/claus_steinlein.png'),
        text: '"TransferRoom has become a crucial part of how we connect with the transfer market when buying and selling players."',
    },
    {
        fullName: 'Gerard Nijkamp',
        position: 'Technical Director',
        club: 'Sparta Rotterdam',
        img: require('media/images/events/2023-march-london/gerard_nijkamp.png'),
        text: '"With TransferRoom you get the whole package. I know immediately if the player can work. The connections you make during the event with colleagues around the world is very valuable."',
    },
    {
        fullName: 'Sebastian Romero',
        position: 'Head of Player Recruitment',
        club: 'Houston Dynamo',
        img: require('media/images/events/2023-march-london/sebastian_romero.png'),
        text: '"TransferRoom is a ground-breaking platform in the football industry. It has become a platform we use daily in our recruitment process."',
    }
]