import * as redux from 'redux';
import * as grid from './pitch-grid.controller';
import * as createPitch from './create-pitch.controller';
import * as pitch from './pitch.controller';
import * as published from './published-pitch.controller';
import * as message from './send-message.controller';
import * as headToHeadAutosuggest from './head-to-head-autosuggest.controller'

export class CustomPitchState {
    grid: grid.State;
    createPitch: createPitch.State;
    pitch: pitch.State;
    published: published.State;
    headToHeadAutosuggest: headToHeadAutosuggest.State
}

export function combineReducers() {

    let agencyReducer = {
        grid: grid.Reducer,
        createPitch: createPitch.Reducer,
        pitch: pitch.Reducer,
        published: published.Reducer,
        message: message.Reducer,
        headToHeadAutosuggest: headToHeadAutosuggest.Reducer
    };

    return redux.combineReducers(agencyReducer)
}