import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import berlinSummitImage from 'media/images/events/7th-summit-berlin.jpg';
import Replay from 'app/events/components/authorized-event-page/common/video-replay-new/replay'

const BerlinSummit13_16thNovember = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="7"
                        bgImageNumber={8}
                        th="th"
                        title1="TransferRoom"
                        title2="Summit"
                        date="14th-16th NOV 2022, Berlin + Online"
                        vs={true}
                    />
                    <Replay
                        compactMode
                        trackUserActivity={() => {
                            // this.props.userActivityInsert({
                            //     PageName: 'Event',
                            //     Message: 'Opened video (6th TransferRoom Summit)',
                            //     EventId: eventId
                            // })
                        }}
                        content={{
                            videoUrl: 'https://player.vimeo.com/video/783348776',
                            backgroundThumb: berlinSummitImage,
                            heading1: '',
                            heading2: '',
                            highlightsText: '',
                            speakers: []
                        }}
                    />
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="468"
                        countries="50"
                        leagues="87"
                        agents="49"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

export const previousEventAttendees = [
    { id: 2031, name: "Union Berlin", logo: "logo_1277_1.FC_Union_Berlin.png" },
    { id: 8228, name: "AC Horsens", logo: "7465_AC_Horsens.png" },
    { id: 26056, name: "AeK Athens", logo: "12511_AEK_Athens.png" },
    { id: 15821, name: "Arm. Bielefeld", logo: "logo_Arminia_Bielefeld.png" },
    { id: 49, name: "Arsenal", logo: "logo_1121_Arsenal_FC.png" },
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 6249, name: "B. Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 70, name: "Brighton", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 17417, name: "BSC Young Boys", logo: "logo_1934_BSC_Young_Boys.png" },
    { id: 2332, name: "Cercle Brugge", logo: "5070_Cercle_Brugge.png" },
    { id: 50, name: "Chelsea", logo: "logo_Chelsea_FC.png" },
    { id: 2041, name: "Club Flandria", logo: "12112_Club_SD_Flandria.png" },
    { id: 39981, name: "Atletico-MG", logo: "5653_Atlético-MG.png" },
    { id: 27251, name: "Flamengo", logo: "5654_Clube_de_Regatas_do_Flamengo.png" },
    { id: 38328, name: "D.C. United", logo: "logo_2118_D.C._United.png" },
    { id: 1559, name: "Cincinnati", logo: "37892_Cincinnati.png" },
    { id: 39599, name: "FC Midtjylland", logo: "logo_FC_Midtjylland.png" },
    { id: 18960, name: "FC Porto", logo: "logo_1672_FC_Porto.png" },
    { id: 13058, name: "Feyenoord", logo: "logo_1533_Feyenoord_Rotterdam.png" },
    { id: 26699, name: "Galatasaray", logo: "logo_2003_Galatasaray_SK.png" },
    { id: 11544, name: "Grasshoppers", logo: "logo_1936_Grasshopper_Club_Zurich.png" },
    { id: 8110, name: "Hertha BSC", logo: "2457_Hertha_BSC.png" },
    { id: 46, name: "Liverpool", logo: "logo_1123_Liverpool_FC.png" },
    { id: 9657, name: "LAFC", logo: "62148_Los_Angeles_FC.png" },
    { id: 48, name: "Man City", logo: "1625_Manchester_City.png" },
    { id: 21139, name: "Olympiacos", logo: "12509_Olympiacos_Piraeus.png" },
    { id: 32865, name: "Portland", logo: "8113_Portland_Timbers.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 16775, name: "Reading", logo: "logo_1147_Reading_FC.png" },
    { id: 3695, name: "Slavia Prague", logo: "logo_2051_SK_Slavia_Prag.png" },
    { id: 9954, name: "Sparta R.", logo: "logo_Sparta_Rotterdam.png" },
    { id: 33794, name: "Internacional", logo: "logo_2611_Sport_Club_Internacional.png" },
    { id: 35103, name: "Standard Liege", logo: "logo_1574_Standard_Liege.png" },
    { id: 3895, name: "Sumgayit", logo: null },
    { id: 40356, name: "Werder Bremen", logo: "logo_1269_SV_Werder_Bremen.png" },
    { id: 47, name: "Spurs", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 11585, name: "Tromso IL", logo: "7089_Tromsø_IL.png" },
    { id: 24711, name: "Urawa Reds", logo: "logo_2407_Urawa_Red_Diamonds.png" },
    { id: 9150, name: "Venezia", logo: "3191_Venezia_FC.png" },
    { id: 12470, name: "VfL Wolfsburg", logo: "logo_VfL_Wolfsburg.png" },
    { id: 69, name: "Watford", logo: "1644_Watford_FC.png" },
    { id: 33781, name: "Wurzb. Kickers", logo: "logo_1295_Wurzburger_Kickers.png" },
]

export default BerlinSummit13_16thNovember