import { IdName } from "api/core/id-name";
import { IdNameLogo } from "api/core/id-name-logo";
import { SquadShortInfo } from "api/core/squad-short-info";

export interface TransparencyInfo {
    showAlert: boolean;
    playerItems: PlayerTransparencyItem[]
}

export interface PlayerTransparencyItem {
    player: IdName;
    squad: SquadShortInfo;
    isVerified: boolean;
    isFreeAgent: boolean;
    isInsightsShared: boolean;
}

export interface SavePlayerTransparencyItem {
    playerId: number;
    isInsightsShared: boolean;
}

export interface PlayerActivityHistoryResponse {
    items: PlayerSharedActivityHistory[]
}

export interface PlayerSharedActivityHistory {
    competition: IdNameLogo;
    area: IdName;
    squad: SquadShortInfo;
    actionType: PlayerHistoryActionType;
    isSharedByAgent: boolean;
    actionDate: Date;
}

export enum PlayerHistoryActionType {
    ViewedProfile = 1,
    StartedFollowing = 2,
    AgentPitched = 3,
    ClubSavedPitch = 4,
    ClubDeclaredInterest = 5,
    ClubRejectedPitch = 6
}

export interface ActivityListModel {
    id: string;
    isSharedByAgent: boolean;
    date: Date;
    dateFormatted: string;
    imageSrc: string | null;
    text: string;
}
