import { AppState } from 'root.reducer'
import { RcFile } from 'antd/lib/upload'
import validator from 'services/validator'
import { StateController } from 'utils/action-declaration'
import AgencyCreditsService from 'api/admin/agency-credits/agency-credits.service'
import { notificationCreate } from 'app/notifications/notifications.actions'
import { AgencyCreditModel, FilterOptions } from 'api/admin/agency-credits/models'


class AgencyCreditsState {
    credits: Array<AgencyCreditModel>    
    editingAgencyId: number
    isFetching: boolean
    didTryToProcess: boolean 

    filterOptions: FilterOptions
    total: number 
    pagination: {
        rowCount: number,
        pageCount: number
    };

}

const defaultFilterOptions = {
    sortOrder: 'date',
    isSortOrderAscending: false,
    agencyName: '',
    page: 1,
    recordsPerPage: 20,
}

const defaultState: AgencyCreditsState = {
    credits: [],    
    isFetching: false,
    editingAgencyId: null,
    didTryToProcess: false,   
    filterOptions: defaultFilterOptions, 
    total: 1,  
    pagination: {
        rowCount: 11,
        pageCount: 1
    }
}
const stateController = new StateController<AgencyCreditsState>(
    'ADMIN_V2/AGENCY_CREDITS',
    defaultState
)

class Actions {
    //====== Agency Credits Page ======

    public static loadCredits() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isFetching: true }))

            const substate = getState().admin.agencyCredits
            const userId = getState().auth.userId
            const filterOptions = {
                ...substate.filterOptions,
                userId: userId
            }

            const data = await AgencyCreditsService.getAgencyCredits(filterOptions)
            const credits = data.pageItems.map(item => ({
                ...item,
                key: item.agencyId
            }))

            dispatch(stateController.setState({ 
                credits: credits, total: data.total, isFetching: false,
                pagination: {
                    pageCount: data.pageCount,
                    rowCount: data.total
                }
             }))
        }
    }

    public static paginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    page: pageNumber,
                    recordsPerPage: pageSize
                }
            })))

            dispatch(Actions.loadCredits())
        }
    }

    public static silentUpdateAgencyCreditsData() {
    }

    public static toggleAddCredits = (agencyId: number) => (dispatch) => dispatch(stateController.setState({ editingAgencyId: agencyId }))
}

class Selectors { 
    public static selectState = (state: AppState) => state.admin.agencyCredits
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AgencyCreditsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}