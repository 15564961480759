import axios from 'axios-config';
import {
  SquadDownloadsListModel,
  SquadDownloadsParameters,
  SquadsSearchParameters,
  DownloadSquadParameters
} from './models';
import { ClubsListModel } from "../clubs/models";

export default class newSquadsDownloadStateService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  public static async getSquadDownloads(searchParameters: SquadDownloadsParameters): Promise<SquadDownloadsListModel> {
    const { data } = await axios.post(
      `api/v2/AdminSquads/GetSquadDownloads`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

  public static async downloadSquad(parameters: DownloadSquadParameters): Promise<any> {
    const { data } = await axios.post(
        `api/AdminSquads/DownloadSquad`,
        parameters,
        this.axiosConfig
    );
    return data;
  }

  public static async getSquads(searchParameters: SquadsSearchParameters): Promise<ClubsListModel> {
    const { data } = await axios.post(
      `api/AdminSquads/GetSquads/${searchParameters.userId}`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

  public static async detachSubsquad(subsquadId: number): Promise<any> {
    const { data } = await axios.get(
        `api/AdminSquads/DetachSubsquad/${subsquadId}`,
        this.axiosConfig
    );
    return data;
  }

  public static async attachSubsquad(subsquadId: number, mainSquadId: number): Promise<any> {
    const { data } = await axios.get(
        `api/AdminSquads/AttachSubsquad/${subsquadId}/${mainSquadId}`,
        this.axiosConfig
    );
    return data;
  }
}
