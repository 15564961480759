export const benefits = [
    'Supercharge your South American network with up to 23 one-to-one meetings',
    'Discover top talent from the Liga MX, MLS, USL and South America, and act quickly to beat the competition',
    'Get accurate recruitment information you can trust and better prices for players'
]

export const whatToExpectBenefits = [
    'Get straight to business, with “speed-dating” style meetings, to accelerate your success',
    'Exclusive networking opportunities and drinks',
    'Insight and expertise from top talent in the region'
]