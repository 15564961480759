export const previousEventClubs = [
    {
      squadId: 3,
      squadName: "Juventus FC",
      shortSquadName: "Juventus FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1832_Juventus_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 4,
      squadName: "Parma Calcio 1913",
      shortSquadName: "Parma Calcio 1913",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 7,
      squadName: "Udinese Calcio",
      shortSquadName: "Udinese Calcio",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1844_Udinese_Calcio.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 19,
      squadName: "Frosinone Calcio",
      shortSquadName: "Frosinone Calcio",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3254_Frosinone_Calcio.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 45,
      squadName: "Manchester United",
      shortSquadName: "Manchester United",
      squadLogo: null, //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 46,
      squadName: "Liverpool FC",
      shortSquadName: "Liverpool FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 48,
      squadName: "Manchester City",
      shortSquadName: "Manchester City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 33
    },
    {
      squadId: 49,
      squadName: "Arsenal FC",
      shortSquadName: "Arsenal FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 50,
      squadName: "Chelsea FC",
      shortSquadName: "Chelsea FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 20
    },
    {
      squadId: 60,
      squadName: "Newcastle United",
      shortSquadName: "Newcastle United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 16
    },
    {
      squadId: 63,
      squadName: "Everton FC",
      shortSquadName: "Everton FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 64,
      squadName: "Crystal Palace",
      shortSquadName: "Crystal Palace",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 65,
      squadName: "Wolverhampton Wanderers",
      shortSquadName: "Wolverhampton Wanderers",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 29
    },
    {
      squadId: 68,
      squadName: "Cardiff City",
      shortSquadName: "Cardiff City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 11
    },
    {
      squadId: 69,
      squadName: "Watford FC",
      shortSquadName: "Watford FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CB",
        "LB"
      ],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 70,
      squadName: "Brighton & Hove Albion",
      shortSquadName: "Brighton & Hove Albion",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 20
    },
    {
      squadId: 71,
      squadName: "AFC Bournemouth",
      shortSquadName: "AFC Bournemouth",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 72,
      squadName: "Huddersfield Town",
      shortSquadName: "Huddersfield Town",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "CM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 22
    },
    {
      squadId: 87,
      squadName: "St. Mirren FC",
      shortSquadName: "St. Mirren FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_St_Mirren_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 174,
      squadName: "Club Atlético Talleres",
      shortSquadName: "Club Atlético Talleres",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 18
    },
    {
      squadId: 329,
      squadName: "Strømsgodset IF",
      shortSquadName: "Strømsgodset IF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 416,
      squadName: "Al-Fateh",
      shortSquadName: "Al-Fateh",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/16483_Al-Fateh.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "F",
        "GK",
        "LB",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 496,
      squadName: "HNK Rijeka",
      shortSquadName: "HNK Rijeka",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 23
    },
    {
      squadId: 1003,
      squadName: "Hull City",
      shortSquadName: "Hull City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1672_Hull_City.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 1177,
      squadName: "OFI Crete FC",
      shortSquadName: "OFI Crete FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12517_OFI_Crete.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 1213,
      squadName: "UD Las Palmas",
      shortSquadName: "UD Las Palmas",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1719_UD_Las_Palmas.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 1559,
      squadName: "FC Cincinnati",
      shortSquadName: "FC Cincinnati",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "CM"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 1740,
      squadName: "Amiens SC",
      shortSquadName: "Amiens SC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "DM",
        "F"
      ],
      countOfAvailablePlayers: 24
    },
    {
      squadId: 1949,
      squadName: "Independiente Medellín",
      shortSquadName: "Independiente Medellín",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12399_Independiente_Medellín.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 2164,
      squadName: "Colorado Rapids",
      shortSquadName: "Colorado Rapids",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7853_Colorado_Rapids.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 2956,
      squadName: "KAA Gent",
      shortSquadName: "KAA Gent",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 3228,
      squadName: "Real Oviedo",
      shortSquadName: "Real Oviedo",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/789_Real_Oviedo.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 3528,
      squadName: "SD Logroñés",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 3695,
      squadName: "SK Slavia Prague",
      shortSquadName: "SK Slavia Prague",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2051_SK_Slavia_Prag.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 3774,
      squadName: "Club Necaxa",
      shortSquadName: "Club Necaxa",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2283_Club_Necaxa.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 4602,
      squadName: "Universidad de Concepción",
      shortSquadName: "Universidad de Concepción",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12285_Universidad_Concepción.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 4673,
      squadName: "Portsmouth FC",
      shortSquadName: "Portsmouth FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Portsmouth_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 4730,
      squadName: "Jammerbugt FC",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7483_Jammerbugt_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 4999,
      squadName: "KVC Westerlo",
      shortSquadName: "KVC Westerlo",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1591_KVC_Westerlo.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 5072,
      squadName: "SC Paderborn 07",
      shortSquadName: "SC Paderborn 07",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 5109,
      squadName: "PEC Zwolle",
      shortSquadName: "PEC Zwolle",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1543_PEC_Zwolle.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 5121,
      squadName: "Minnesota United FC",
      shortSquadName: "Minnesota United FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2122_Minnesota_United_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 5159,
      squadName: "FC Groningen",
      shortSquadName: "FC Groningen",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Groningen.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "RB"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 5282,
      squadName: "PSV Eindhoven",
      shortSquadName: "PSV Eindhoven",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 5306,
      squadName: "Rakow Czestochowa",
      shortSquadName: "Rakow Czestochowa",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "F",
        "GK",
        "LB"
      ],
      countOfAvailablePlayers: 14
    },
    {
      squadId: 5363,
      squadName: "Kilmarnock FC",
      shortSquadName: "Kilmarnock FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/8516_Kilmarnock_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CM",
        "F"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 5430,
      squadName: "GAIS Göteborg",
      shortSquadName: "GAIS Göteborg",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1451_GAIS_Goteborg.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 5635,
      squadName: "Dynamo Kyiv",
      shortSquadName: "Dynamo Kyiv",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2136_Dynamo_Kyiv.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 5681,
      squadName: "Víkingur Reykjavík",
      shortSquadName: "Víkingur Reykjavík",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1515_Vikingur_Reykjavik.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 5869,
      squadName: "Viking Stavanger",
      shortSquadName: "Viking Stavanger",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7100_Viking_Stavanger.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 5968,
      squadName: "AFC Wimbledon",
      shortSquadName: "AFC Wimbledon",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1178_AFC_Wimbledon.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 5972,
      squadName: "San Jose Earthquakes",
      shortSquadName: "San Jose Earthquakes",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 6098,
      squadName: "Kalmar FF",
      shortSquadName: "Kalmar FF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kalmar_FF.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CM"
      ],
      countOfAvailablePlayers: 11
    },
    {
      squadId: 6160,
      squadName: "CSKA Moscow",
      shortSquadName: "CSKA Moscow",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/13886_CSKA_Moskva.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 6207,
      squadName: "Molde FK",
      shortSquadName: "Molde FK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7098_Molde_FK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 6306,
      squadName: "Hibernian FC",
      shortSquadName: "Hibernian FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hibernian_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "DM",
        "W"
      ],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 6671,
      squadName: "Heart of Midlothian FC",
      shortSquadName: "Heart of Midlothian FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Heart_of_Midlothian.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 7527,
      squadName: "FC Vaduz",
      shortSquadName: "FC Vaduz",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2354_FC_Vaduz.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 7594,
      squadName: "Ceará Sporting Club",
      shortSquadName: "Ceará Sporting Club",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2620_Ceara_Sporting_Club.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 39
    },
    {
      squadId: 7633,
      squadName: "Real Salt Lake City",
      shortSquadName: "Real Salt Lake City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2117_Real_Salt_Lake_City.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM",
        "LB",
        "W"
      ],
      countOfAvailablePlayers: 13
    },
    {
      squadId: 7635,
      squadName: "Spartak Trnava",
      shortSquadName: "Spartak Trnava",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2127_Spartak_Trnava.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 7783,
      squadName: "Pyramids FC",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/27533_Pyramids_Al_Ahram_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 7819,
      squadName: "Sarpsborg 08 FF",
      shortSquadName: "Sarpsborg 08 FF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F",
        "LB"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 7853,
      squadName: "Toronto FC",
      shortSquadName: "Toronto FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2102_Toronto_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 7881,
      squadName: "Club Atlético Platense",
      shortSquadName: "Club Atlético Platense",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15658_Club_Atlético_Platense.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 7898,
      squadName: "Preston North End",
      shortSquadName: "Preston North End",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1161_Preston_North_End.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 7927,
      squadName: "Dinamo Minsk",
      shortSquadName: "Dinamo Minsk",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/10082_Dinamo_Minsk.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 8110,
      squadName: "Hertha BSC",
      shortSquadName: "Hertha BSC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 8228,
      squadName: "AC Horsens",
      shortSquadName: "AC Horsens",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7465_AC_Horsens.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 8239,
      squadName: "Brentford FC",
      shortSquadName: "Brentford FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "GK",
        "LB"
      ],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 8498,
      squadName: "Odense Boldklub",
      shortSquadName: "Odense Boldklub",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1412_Odense_Boldklub.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 8601,
      squadName: "UD Vilafranquense",
      shortSquadName: "UD Vilafranquense",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/9670_UD_Vilafranquense.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 8726,
      squadName: "Trois Bassins FC",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 9150,
      squadName: "Venezia FC",
      shortSquadName: "Venezia FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 19
    },
    {
      squadId: 9197,
      squadName: "Louisville City FC",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/32930_Louisville_City_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 9346,
      squadName: "FC Sochi",
      shortSquadName: "FC Sochi",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/13947_FC_Sochi.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 9566,
      squadName: "HNK Gorica",
      shortSquadName: "HNK Gorica",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/9557_HNK_Gorica.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 18
    },
    {
      squadId: 9657,
      squadName: "Los Angeles FC",
      shortSquadName: "Los Angeles FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/62148_Los_Angeles_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 9929,
      squadName: "Lincoln City",
      shortSquadName: "Lincoln City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1207_Lincoln_City.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "F",
        "GK",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 10026,
      squadName: "Los Angeles Galaxy",
      shortSquadName: "Los Angeles Galaxy",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 10065,
      squadName: "Partick Thistle FC",
      shortSquadName: "Partick Thistle FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 10088,
      squadName: "RB Leipzig",
      shortSquadName: "RB Leipzig",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 10158,
      squadName: "FC Toulouse",
      shortSquadName: "FC Toulouse",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 10183,
      squadName: "Podbeskidzie Bielsko-Biala",
      shortSquadName: "Podbeskidzie Bielsko-Biala",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1610_Podbeskidzie_Bielsko-Biala.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CB",
        "DM"
      ],
      countOfAvailablePlayers: 17
    },
    {
      squadId: 10317,
      squadName: "New York Red Bulls",
      shortSquadName: "New York Red Bulls",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "DM",
        "F",
        "LB",
        "W"
      ],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 10371,
      squadName: "Audax Italiano",
      shortSquadName: "Audax Italiano",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2657_Audax_Italiano.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 10931,
      squadName: "CA San Lorenzo de Almagro",
      shortSquadName: "CA San Lorenzo de Almagro",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12071_Club_Atlético_San_Lorenzo_de_Almagro.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "DM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 10999,
      squadName: "AA Argentinos Juniors",
      shortSquadName: "AA Argentinos Juniors",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12075_Asociación_Atlética_Argentinos_Juniors.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 11474,
      squadName: "Exeter City",
      shortSquadName: "Exeter City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1187_Exeter_City.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 11544,
      squadName: "Grasshopper Club Zurich",
      shortSquadName: "Grasshopper Club Zurich",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1936_Grasshopper_Club_Zurich.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "DM",
        "F"
      ],
      countOfAvailablePlayers: 16
    },
    {
      squadId: 11585,
      squadName: "Tromsø IL",
      shortSquadName: "Tromsø IL",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F",
        "LB"
      ],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 11717,
      squadName: "MOL Fehérvár FC",
      shortSquadName: "MOL Fehérvár FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1629_Videoton_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 11863,
      squadName: "Shakhtar Donetsk",
      shortSquadName: "Shakhtar Donetsk",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2135_Shakhtar_Donetsk.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 11890,
      squadName: "Correcaminos de la UAT",
      shortSquadName: "Correcaminos de la UAT",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15440_Correcaminos_de_la_UAT.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 12027,
      squadName: "Club Atlético Boston River",
      shortSquadName: "Club Atlético Boston River",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 12139,
      squadName: "Budapest Honvéd FC",
      shortSquadName: "Budapest Honvéd FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 12173,
      squadName: "Barnsley FC",
      shortSquadName: "Barnsley FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 14
    },
    {
      squadId: 12372,
      squadName: "Keflavík ÍF",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7725_Keflavík_ÍF.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 12612,
      squadName: "Hammarby IF",
      shortSquadName: "Hammarby IF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/6710_Hammarby_IF.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM",
        "F"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 13058,
      squadName: "Feyenoord Rotterdam",
      shortSquadName: "Feyenoord Rotterdam",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 13073,
      squadName: "Club Brugge KV",
      shortSquadName: "Club Brugge KV",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1572_Club_Brugge_KV.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 13114,
      squadName: "Real Zaragoza",
      shortSquadName: "Real Zaragoza",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/689_Real_Zaragoza.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CB",
        "CM",
        "DM",
        "F",
        "GK",
        "LB",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 11
    },
    {
      squadId: 13404,
      squadName: "Houston Dynamo FC",
      shortSquadName: "Houston Dynamo FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 13510,
      squadName: "FC Utrecht",
      shortSquadName: "FC Utrecht",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 52
    },
    {
      squadId: 13576,
      squadName: "SönderjyskE",
      shortSquadName: "SönderjyskE",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SonderjyskE.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 13685,
      squadName: "Salford City",
      shortSquadName: "Salford City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2317_Salford_City.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 13942,
      squadName: "Racing Club",
      shortSquadName: "Racing Club",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/36882_Racing_Club.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CM",
        "DM",
        "F",
        "RB"
      ],
      countOfAvailablePlayers: 13
    },
    {
      squadId: 14339,
      squadName: "Newport County",
      shortSquadName: "Newport County",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newport_County.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CB",
        "CM",
        "DM",
        "F",
        "LB"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 14512,
      squadName: "Royal Excelsior Virton",
      shortSquadName: "Royal Excelsior Virton",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2366_Royal_Excelsior_Virton.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM",
        "W"
      ],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 14821,
      squadName: "FC Copenhagen",
      shortSquadName: "FC Copenhagen",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Copenhagen.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 14930,
      squadName: "Celaya FC",
      shortSquadName: "Celaya FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F",
        "W"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 15130,
      squadName: "AIK Solna",
      shortSquadName: "AIK Solna",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 15268,
      squadName: "Widzew Lodz",
      shortSquadName: "Widzew Lodz",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 15349,
      squadName: "Club Atlético River Plate",
      shortSquadName: "Club Atlético River Plate",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2577_Club_Atletico_River_Plate.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 15581,
      squadName: "Lech Poznan",
      shortSquadName: "Lech Poznan",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1595_Lech_Poznan.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 15662,
      squadName: "FC Admira Wacker Mödling",
      shortSquadName: "FC Admira Wacker Mödling",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1949_FC_Admira_Wacker_Modling.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 15821,
      squadName: "Arminia Bielefeld",
      shortSquadName: "Arminia Bielefeld",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 16011,
      squadName: "Stevenage FC",
      shortSquadName: "Stevenage FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1705_Stevenage_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 16046,
      squadName: "Olympique Lyon",
      shortSquadName: "Olympique Lyon",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1966_Olympique_Lyon.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 10
    },
    {
      squadId: 16173,
      squadName: "SCR Altach",
      shortSquadName: "SCR Altach",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1946_SC_Rheindorf_Altach.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 16232,
      squadName: "FC Girondins Bordeaux",
      shortSquadName: "FC Girondins Bordeaux",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3772_FC_Girondins_Bordeaux.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 14
    },
    {
      squadId: 16345,
      squadName: "Mjøndalen IF",
      shortSquadName: "Mjøndalen IF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1486_Mjondalen_IF.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 16775,
      squadName: "Reading FC",
      shortSquadName: "Reading FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1147_Reading_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 17017,
      squadName: "Philadelphia Union",
      shortSquadName: "Philadelphia Union",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 17123,
      squadName: "KA Akureyri",
      shortSquadName: "KA Akureyri",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1512_KA_Akureyri.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "LB",
        "RB"
      ],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 17175,
      squadName: "Norwich City",
      shortSquadName: "Norwich City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1144_Norwich_City.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 17207,
      squadName: "Jeunesse Esch",
      shortSquadName: "Jeunesse Esch",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/14630_Jeunesse_Esch.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CM",
        "DM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 17252,
      squadName: "NK Maribor",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/11954_Maribor.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM"
      ],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 17417,
      squadName: "BSC Young Boys",
      shortSquadName: "BSC Young Boys",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 17418,
      squadName: "Vålerenga Fotball",
      shortSquadName: "Vålerenga Fotball",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 17668,
      squadName: "FC Famalicão",
      shortSquadName: "FC Famalicão",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Famalicao.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CM",
        "W"
      ],
      countOfAvailablePlayers: 10
    },
    {
      squadId: 17788,
      squadName: "Paris Saint-Germain",
      shortSquadName: "Paris Saint-Germain",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Paris_Saint-Germain.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 18429,
      squadName: "Ebbsfleet United",
      shortSquadName: "Ebbsfleet United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1218_Ebbsfleet_United.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "GK"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 18516,
      squadName: "SV Wehen Wiesbaden",
      shortSquadName: "SV Wehen Wiesbaden",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 18697,
      squadName: "FC Augsburg",
      shortSquadName: "FC Augsburg",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1274_FC_Augsburg.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 18705,
      squadName: "Fredrikstad FK",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fredrikstad_FK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 18833,
      squadName: "SL Benfica",
      shortSquadName: "SL Benfica",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1673_SL_Benfica.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 18894,
      squadName: "Bröndby IF",
      shortSquadName: "Bröndby IF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 19151,
      squadName: "Clermont Foot 63",
      shortSquadName: "Clermont Foot 63",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3797_Clermont_Foot_Auvergne_63.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 19310,
      squadName: "Academia Deportiva Cantolao",
      shortSquadName: "Academia Deportiva Cantolao",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/36885_Academia_Deportiva_Cantolao.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 19616,
      squadName: "Aalesunds FK",
      shortSquadName: "Aalesunds FK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 19775,
      squadName: "HB Köge",
      shortSquadName: "HB Köge",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 20016,
      squadName: "Motherwell FC",
      shortSquadName: "Motherwell FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "F",
        "LB"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 20133,
      squadName: "Chicago Fire FC",
      shortSquadName: "Chicago Fire FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7848_Chicago_Fire_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 20365,
      squadName: "US Créteil-Lusitanos",
      shortSquadName: "US Créteil-Lusitanos",
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "AM",
        "CB",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 20594,
      squadName: "FK Krasnodar",
      shortSquadName: "FK Krasnodar",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2149_FK_Krasnodar.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 20887,
      squadName: "Millwall FC",
      shortSquadName: "Millwall FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1160_Millwall_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 21139,
      squadName: "Olympiacos Piraeus",
      shortSquadName: "Olympiacos Piraeus",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 21425,
      squadName: "FC St. Gallen 1879",
      shortSquadName: "FC St. Gallen 1879",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 21487,
      squadName: "Doncaster Rovers",
      shortSquadName: "Doncaster Rovers",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/34007_Doncaster.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CM",
        "DM",
        "F",
        "GK",
        "LB",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 22000,
      squadName: "BATE Borisov",
      shortSquadName: "BATE Borisov",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/10083_BATE_Borisov.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 22196,
      squadName: "SpVgg Greuther Fürth",
      shortSquadName: "SpVgg Greuther Fürth",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "DM",
        "F",
        "LB",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 22514,
      squadName: "CF América",
      shortSquadName: "CF América",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15405_América.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 22827,
      squadName: "HNK Hajduk Split",
      shortSquadName: "HNK Hajduk Split",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 23199,
      squadName: "FC Porto",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 23506,
      squadName: "Atlas Guadalajara",
      shortSquadName: "Atlas Guadalajara",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15407_Atlas.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "F",
        "W"
      ],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 23588,
      squadName: "SC Heerenveen",
      shortSquadName: "SC Heerenveen",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 23647,
      squadName: "Atlético de San Luis",
      shortSquadName: "Atlético de San Luis",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 10
    },
    {
      squadId: 24016,
      squadName: "Quilmes Atlético Club",
      shortSquadName: "Quilmes Atlético Club",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12067_Quilmes_Atlético_Club.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 24387,
      squadName: "Luton Town",
      shortSquadName: "Luton Town",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Luton_Town.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "LB"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 24695,
      squadName: "Royale Union Saint Gilloise",
      shortSquadName: "Royale Union Saint Gilloise",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1590_Royale_Union_Saint_Gilloise.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 24701,
      squadName: "CF Atlante",
      shortSquadName: "CF Atlante",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 24711,
      squadName: "Urawa Red Diamonds",
      shortSquadName: "Urawa Red Diamonds",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 24749,
      squadName: "Grenoble Foot 38",
      shortSquadName: "Grenoble Foot 38",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3798_Grenoble_Foot_38.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "CM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 24755,
      squadName: "Milton Keynes Dons",
      shortSquadName: "Milton Keynes Dons",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1164_Milton_Keynes_Dons.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 24917,
      squadName: "Club Atlético Aldosivi",
      shortSquadName: "Club Atlético Aldosivi",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2946_Club_Atletico_Aldosivi_(MdP).png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 25043,
      squadName: "FC Pro Vercelli 1892",
      shortSquadName: "FC Pro Vercelli 1892",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1870_FC_Pro_Vercelli_1892.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 25090,
      squadName: "Cimarrones de Sonora",
      shortSquadName: "Cimarrones de Sonora",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 25436,
      squadName: "Nashville SC",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 25519,
      squadName: "Rapid Vienna",
      shortSquadName: "Rapid Vienna",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "DM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 25911,
      squadName: "Royal Antwerp FC",
      shortSquadName: "Royal Antwerp FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "AM",
        "CB",
        "CM",
        "DM",
        "F",
        "LB",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 26056,
      squadName: "AEK Athens",
      shortSquadName: "AEK Athens",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 26121,
      squadName: "FC Fastav Zlin",
      shortSquadName: "FC Fastav Zlin",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/11245_FC_FASTAV_Zlin.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 26439,
      squadName: "Deportivo Alavés",
      shortSquadName: "Deportivo Alavés",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/696_Deportivo_Alavés.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 20
    },
    {
      squadId: 26623,
      squadName: "Miedz Legnica",
      shortSquadName: "Miedz Legnica",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1613_Miedz_Legnica.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 26667,
      squadName: "Coritiba Foot Ball Club",
      shortSquadName: "Coritiba Foot Ball Club",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 26989,
      squadName: "St. Johnstone FC",
      shortSquadName: "St. Johnstone FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_St_Johnstone.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 27136,
      squadName: "Kisvárda FC",
      shortSquadName: "Kisvárda FC",
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 27176,
      squadName: "KV Mechelen",
      shortSquadName: "KV Mechelen",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 27287,
      squadName: "Vitória Guimarães SC",
      shortSquadName: "Vitória Guimarães SC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1676_Vitoria_Guimaraes_SC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 27648,
      squadName: "UD Ibiza",
      shortSquadName: "UD Ibiza",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/60341_UD_Ibiza.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 28158,
      squadName: "Aalborg BK",
      shortSquadName: "Aalborg BK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 28212,
      squadName: "RC Strasbourg Alsace",
      shortSquadName: "RC Strasbourg Alsace",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CB",
        "LB"
      ],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 28535,
      squadName: "Naestved Boldklub",
      shortSquadName: "Naestved Boldklub",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "CM",
        "DM",
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 28833,
      squadName: "Svay Rieng FC",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 28943,
      squadName: "CA Banfield",
      shortSquadName: "CA Banfield",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12056_Banfield.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 28997,
      squadName: "Aberdeen FC",
      shortSquadName: "Aberdeen FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "GK"
      ],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 28998,
      squadName: "Real Betis Balompié",
      shortSquadName: "Real Betis Balompié",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/684_Real_Betis_Balompié.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 29155,
      squadName: "1.FC Kaiserslautern",
      shortSquadName: "1.FC Kaiserslautern",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1286_1.FC_Kaiserslautern.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 29198,
      squadName: "FC Den Bosch",
      shortSquadName: "FC Den Bosch",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Den_Bosch.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 29306,
      squadName: "Mineros de Zacatecas",
      shortSquadName: "Mineros de Zacatecas",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 29529,
      squadName: "Cheltenham Town",
      shortSquadName: "Cheltenham Town",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CB",
        "CM",
        "DM",
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 29655,
      squadName: "Blackburn Rovers",
      shortSquadName: "Blackburn Rovers",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/16388_Blackburn_Rovers.svg",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 29757,
      squadName: "Bradford City",
      shortSquadName: "Bradford City",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1653_Bradford_City.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 29777,
      squadName: "LR Vicenza",
      shortSquadName: "LR Vicenza",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 11
    },
    {
      squadId: 30168,
      squadName: "Deportivo Saprissa",
      shortSquadName: "Deportivo Saprissa",
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 30372,
      squadName: "Envigado FC",
      shortSquadName: "Envigado FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 30475,
      squadName: "Malmö FF",
      shortSquadName: "Malmö FF",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 30586,
      squadName: "AC Sparta Prague",
      shortSquadName: "AC Sparta Prague",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 30734,
      squadName: "Altinordu FK",
      shortSquadName: "Altinordu FK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 30849,
      squadName: "Atlanta United FC",
      shortSquadName: "Atlanta United FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 30997,
      squadName: "Red Bull Salzburg",
      shortSquadName: "Red Bull Salzburg",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1942_Red_Bull_Salzburg.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 31046,
      squadName: "SJK Seinäjoki",
      shortSquadName: "SJK Seinäjoki",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/4991_SJK.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 31136,
      squadName: "Heracles Almelo",
      shortSquadName: "Heracles Almelo",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1542_Heracles_Almelo.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CM",
        "F"
      ],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 31138,
      squadName: "Club León FC",
      shortSquadName: "Club León FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 31328,
      squadName: "Sabah FK",
      shortSquadName: "Sabah FK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 31362,
      squadName: "Lincoln Red Imps FC",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 31367,
      squadName: "Sheffield United",
      shortSquadName: "Sheffield United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1159_Sheffield_United.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 13
    },
    {
      squadId: 31588,
      squadName: "Rangers FC",
      shortSquadName: "Rangers FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 32121,
      squadName: "Once Caldas",
      shortSquadName: "Once Caldas",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12392_Once_Caldas.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 32742,
      squadName: "Lyngby BK",
      shortSquadName: "Lyngby BK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Lyngby_BK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 32865,
      squadName: "Portland Timbers",
      shortSquadName: "Portland Timbers",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/8113_Portland_Timbers.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 32987,
      squadName: "Bristol Rovers",
      shortSquadName: "Bristol Rovers",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1184_Bristol_Rovers.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 33183,
      squadName: "CD Mafra",
      shortSquadName: "CD Mafra",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3016_CD_Mafra.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "CM",
        "DM",
        "F",
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 33438,
      squadName: "FC Thun",
      shortSquadName: "FC Thun",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 33648,
      squadName: "Atlético Nacional",
      shortSquadName: "Atlético Nacional",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 20
    },
    {
      squadId: 33676,
      squadName: "Sandefjord Fotball",
      shortSquadName: "Sandefjord Fotball",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sandefjord_Fotball.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 33781,
      squadName: "Würzburger Kickers",
      shortSquadName: "Würzburger Kickers",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1295_Wurzburger_Kickers.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 33794,
      squadName: "Sport Club Internacional",
      shortSquadName: "Sport Club Internacional",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 16
    },
    {
      squadId: 33797,
      squadName: "Spezia Calcio",
      shortSquadName: "Spezia Calcio",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1860_Spezia_Calcio.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 33813,
      squadName: "Karlsruher SC",
      shortSquadName: "Karlsruher SC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 33927,
      squadName: "UD Almería",
      shortSquadName: "UD Almería",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/708_UD_Almería.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 34162,
      squadName: "Austria Vienna",
      shortSquadName: "Austria Vienna",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1944_Austria_Vienna.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 34220,
      squadName: "Orlando Pirates FC",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 34484,
      squadName: "SC Farense",
      shortSquadName: "SC Farense",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/9657_SC_Farense.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 34923,
      squadName: "Harrogate Town",
      shortSquadName: "Harrogate Town",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 1
    },
    {
      squadId: 35023,
      squadName: "Blackpool FC",
      shortSquadName: "Blackpool FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1180_Blackpool_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CM"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 35045,
      squadName: "Vitesse Arnhem",
      shortSquadName: "Vitesse Arnhem",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 35103,
      squadName: "Standard Liège",
      shortSquadName: "Standard Liège",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 23
    },
    {
      squadId: 35187,
      squadName: "Virtus Entella",
      shortSquadName: "Virtus Entella",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "DM",
        "LB",
        "RB"
      ],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 36192,
      squadName: "Tigres UANL",
      shortSquadName: "Tigres UANL",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 36381,
      squadName: "US Orléans",
      shortSquadName: "US Orléans",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/3925_US_Orléans.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 36401,
      squadName: "Slovan Bratislava",
      shortSquadName: "Slovan Bratislava",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2123_Slovan_Bratislava.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 7
    },
    {
      squadId: 36502,
      squadName: "Dorados de Sinaloa",
      shortSquadName: "Dorados de Sinaloa",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/15465_Dorados_de_Sinaloa.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 36695,
      squadName: "AC Perugia Calcio",
      shortSquadName: "AC Perugia Calcio",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1858_AC_Perugia_Calcio.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 37333,
      squadName: "NK Istra 1961",
      shortSquadName: "NK Istra 1961",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2169_NK_Istra_1961.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 37341,
      squadName: "IFK Göteborg",
      shortSquadName: "IFK Göteborg",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1438_IFK_Goteborg.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CB"
      ],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 37399,
      squadName: "CF Estrela de Amadora",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/9622_CF_Estrela_de_Amadora.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 37659,
      squadName: "Kongsvinger IL",
      shortSquadName: "Kongsvinger IL",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CM",
        "DM"
      ],
      countOfAvailablePlayers: 3
    },
    {
      squadId: 37706,
      squadName: "Dundee United FC",
      shortSquadName: "Dundee United FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Dundee_United.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 38062,
      squadName: "Bromley FC",
      shortSquadName: "Bromley FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1820_Bromley_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 38328,
      squadName: "D.C. United",
      shortSquadName: "D.C. United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 38553,
      squadName: "Aston Villa",
      shortSquadName: "Aston Villa",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 38623,
      squadName: "Pogon Szczecin",
      shortSquadName: "Pogon Szczecin",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "LB"
      ],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 38864,
      squadName: "Slask Wroclaw",
      shortSquadName: "Slask Wroclaw",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 37
    },
    {
      squadId: 39176,
      squadName: "Leeds United",
      shortSquadName: "Leeds United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 39337,
      squadName: "SK Dnipro-1",
      shortSquadName: "SK Dnipro-1",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 39599,
      squadName: "FC Midtjylland",
      shortSquadName: "FC Midtjylland",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 9
    },
    {
      squadId: 39648,
      squadName: "New England Revolution",
      shortSquadName: "New England Revolution",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2111_New_England_Revolution.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "CM",
        "GK",
        "W"
      ],
      countOfAvailablePlayers: 4
    },
    {
      squadId: 39981,
      squadName: "Clube Atlético Mineiro",
      shortSquadName: "Clube Atlético Mineiro",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/5653_Atlético-MG.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "W"
      ],
      countOfAvailablePlayers: 39
    },
    {
      squadId: 40356,
      squadName: "SV Werder Bremen",
      shortSquadName: "SV Werder Bremen",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 40409,
      squadName: "Oxford United",
      shortSquadName: "Oxford United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 40508,
      squadName: "LASK",
      shortSquadName: "LASK",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 12
    },
    {
      squadId: 40635,
      squadName: "Ross County FC",
      shortSquadName: "Ross County FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CB",
        "CM",
        "DM",
        "F",
        "GK",
        "LB",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 5
    },
    {
      squadId: 40692,
      squadName: "Columbus Crew",
      shortSquadName: "Columbus Crew",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2116_Columbus_Crew_SC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 41155,
      squadName: "Raith Rovers FC",
      shortSquadName: "Raith Rovers FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2285_Raith_Rovers_FC.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 41304,
      squadName: "Cape Town City FC",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 41684,
      squadName: "Twente Enschede FC",
      shortSquadName: "Twente Enschede FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CB",
        "CM",
        "F",
        "GK",
        "LB",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 2
    },
    {
      squadId: 41761,
      squadName: "Leyton Orient",
      shortSquadName: "Leyton Orient",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 6
    },
    {
      squadId: 41956,
      squadName: "Deportivo Cali",
      shortSquadName: "Deportivo Cali",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 20
    },
    {
      squadId: 42059,
      squadName: "Lion City Sailors",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 42063,
      squadName: "Újpest FC",
      shortSquadName: "Újpest FC",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "F",
        "W"
      ],
      countOfAvailablePlayers: 19
    },
    {
      squadId: 42094,
      squadName: "VfL Bochum",
      shortSquadName: "VfL Bochum",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1284_VfL_Bochum.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "AM",
        "CM"
      ],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 42731,
      squadName: "Hartford Athletic",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/63730_Hartford_Athletic.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 42794,
      squadName: "York United FC",
      shortSquadName: "York United",
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/64334_York9.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [
        "CB",
        "F"
      ],
      countOfAvailablePlayers: 22
    },
    {
      squadId: 42798,
      squadName: "Cavalry FC",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [
        "GK",
        "RB",
        "W"
      ],
      countOfAvailablePlayers: 8
    },
    {
      squadId: 50113,
      squadName: "Inter Miami CF",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png",
      transferInStepCompleted: true,
      transferOutStepCompleted: true,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 15
    },
    {
      squadId: 100007,
      squadName: "Austin FC",
      shortSquadName: null,
      squadLogo: "https://transferroom.blob.core.windows.net/clubs/-72309_Austin_FC.png",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: true,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 200469,
      squadName: "RoundGlass Punjab FC",
      shortSquadName: null,
      squadLogo: "https://tmssl.akamaized.net/images/wappen/head/85902.png?lm=1605454890",
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 220000,
      squadName: "United Soccer League",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 221002,
      squadName: "Angkor Tiger FC",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    },
    {
      squadId: 222000,
      squadName: "United World Group Football",
      shortSquadName: null,
      squadLogo: null,
      transferInStepCompleted: false,
      transferOutStepCompleted: false,
      generalNetworkingStepCompleted: false,
      adPositionNames: [],
      countOfAvailablePlayers: 0
    }
  ]