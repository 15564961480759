export const eventClubs = [
    {
        squadId: 48,
        squadName: "Manchester City",
        shortSquadName: "Man City",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
    },
    {
        squadId: 62,
        squadName: "Southampton FC",
        shortSquadName: "Southampton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
    },
    {
        squadId: 174,
        squadName: "CA Talleres",
        shortSquadName: "CA Talleres",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
    },
    {
        squadId: 1065,
        squadName: "SC Braga",
        shortSquadName: "SC Braga",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sporting_Braga.png"
    },
    {
        squadId: 1559,
        squadName: "FC Cincinnati",
        shortSquadName: "Cincinnati",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
    },
    {
        squadId: 1949,
        squadName: "Independiente Medellin",
        shortSquadName: "Indep. Medellin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12399_Independiente_Medellín.png"
    },
    {
        squadId: 2179,
        squadName: "Club Tijuana",
        shortSquadName: "Club Tijuana",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15459_Club_Tijuana.png"
    },
    {
        squadId: 2226,
        squadName: "Sevilla FC",
        shortSquadName: "Sevilla FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/680_Sevilla_FC.png"
    },
    {
        squadId: 4059,
        squadName: "OGC Nice",
        shortSquadName: "OGC Nice",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1965_OGC_Nice.png"
    },
    {
        squadId: 5121,
        squadName: "Minnesota United FC",
        shortSquadName: "Minnesota",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2122_Minnesota_United_FC.png"
    },
    {
        squadId: 5359,
        squadName: "Caracas FC",
        shortSquadName: "Caracas FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
    },
    {
        squadId: 6031,
        squadName: "Tampa Bay Rowdies",
        shortSquadName: "TB Rowdies",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8096_Tampa_Bay_Rowdies.png"
    },
    {
        squadId: 6249,
        squadName: "Bayer 04 Leverkusen",
        shortSquadName: "B. Leverkusen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
    },
    {
        squadId: 7400,
        squadName: "Botafogo de Futebol e Regatas",
        shortSquadName: "Botafogo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7400_Botafogo_de_Futebol_e_Regatas.png"
    },
    {
        squadId: 7845,
        squadName: "CF Pachuca",
        shortSquadName: "Pachuca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
    },
    {
        squadId: 8064,
        squadName: "Kawasaki Frontale",
        shortSquadName: "Kawasaki Front.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2410_Kawasaki_Frontale.png"
    },
    {
        squadId: 8839,
        squadName: "Ferroviaria",
        shortSquadName: "Ferroviaria",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_8839_Associacao_Ferroviaria_de_Esportes_(SP).png"
    },
    {
        squadId: 9276,
        squadName: "CF Monterrey",
        shortSquadName: "Monterrey",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2267_CF_Monterrey.png"
    },
    {
        squadId: 10026,
        squadName: "Los Angeles Galaxy",
        shortSquadName: "Los Angeles",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
    },
    {
        squadId: 10317,
        squadName: "New York Red Bulls",
        shortSquadName: "New York",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
    },
    {
        squadId: 10422,
        squadName: "Vancouver Whitecaps FC",
        shortSquadName: "Vancouver",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
    },
    {
        squadId: 10931,
        squadName: "CA San Lorenzo de Almagro",
        shortSquadName: "San Lorenzo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12071_Club_Atlético_San_Lorenzo_de_Almagro.png"
    },
    {
        squadId: 11592,
        squadName: "Venados FC Yucatan",
        shortSquadName: "Venados FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15428_Venados_FC_Yucatán.png"
    },
    {
        squadId: 13404,
        squadName: "Houston Dynamo FC",
        shortSquadName: "Houston",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
    },
    {
        squadId: 15024,
        squadName: "Yverdon Sport FC",
        shortSquadName: "Yverdon Sport",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/6422_Yverdon_Sport_FC.png"
    },
    {
        squadId: 15241,
        squadName: "CD Magallanes",
        shortSquadName: "Magallanes",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
    },
    {
        squadId: 15326,
        squadName: "Albion FC",
        shortSquadName: "Albion FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15326_Albion_FC.png"
    },
    {
        squadId: 15741,
        squadName: "US Triestina",
        shortSquadName: "Triestina",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1905_US_Triestina.png"
    },
    {
        squadId: 16727,
        squadName: "Fortaleza CEIF",
        shortSquadName: "Fortaleza CEIF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12427_Fortaleza_CEIF_FC.png"
    },
    {
        squadId: 19201,
        squadName: "CSD Xelaju MC",
        shortSquadName: "Xelaju",
        squadLogo: null
    },
    {
        squadId: 20630,
        squadName: "Gillingham FC",
        shortSquadName: "Gillingham FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1648_Gillingham_FC.png"
    },
    {
        squadId: 22011,
        squadName: "Puebla FC",
        shortSquadName: "Puebla FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15414_Puebla_FC.png"
    },
    {
        squadId: 22719,
        squadName: "Clube de Regatas Vasco da Gama",
        shortSquadName: "Vasco da Gama",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
    },
    {
        squadId: 23647,
        squadName: "Atletico de San Luis",
        shortSquadName: "San Luis",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
    },
    {
        squadId: 24432,
        squadName: "Pafos FC",
        shortSquadName: "Pafos FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
    },
    {
        squadId: 24701,
        squadName: "CF Atlante",
        shortSquadName: "Atlante",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
    },
    {
        squadId: 25090,
        squadName: "Cimarrones de Sonora",
        shortSquadName: "Cimarrones",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
    },
    {
        squadId: 25436,
        squadName: "Nashville SC",
        shortSquadName: "Nashville",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
    },
    {
        squadId: 25911,
        squadName: "Royal Antwerp FC",
        shortSquadName: "Royal Antwerp",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
    },
    {
        squadId: 26367,
        squadName: "Santos Laguna",
        shortSquadName: "Santos Laguna",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
    },
    {
        squadId: 26540,
        squadName: "Orlando City SC",
        shortSquadName: "Orlando",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
    },
    {
        squadId: 26867,
        squadName: "CA Penarol",
        shortSquadName: "Penarol",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15633_Peñarol.png"
    },
    {
        squadId: 27650,
        squadName: "FC Juarez",
        shortSquadName: "FC Juarez",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/34402_FC_Juárez.png"
    },
    {
        squadId: 29065,
        squadName: "Club Sporting Cristal",
        shortSquadName: "Sport. Cristal",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
    },
    {
        squadId: 29796,
        squadName: "Alebrijes de Oaxaca",
        shortSquadName: "Oaxaca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
    },
    {
        squadId: 30849,
        squadName: "Atlanta United FC",
        shortSquadName: "Atlanta",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
    },
    {
        squadId: 31536,
        squadName: "Aris Limassol",
        shortSquadName: "Aris Limassol",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_31536_Aris_Limassol.png"
    },
    {
        squadId: 31588,
        squadName: "Rangers FC",
        shortSquadName: "Rangers",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
    },
    {
        squadId: 32247,
        squadName: "Orsomarso SC",
        shortSquadName: "Orsomarso SC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_32247_Orsomarso_SC.png"
    },
    {
        squadId: 32704,
        squadName: "UNAM Pumas",
        shortSquadName: "UNAM Pumas",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
    },
    {
        squadId: 32753,
        squadName: "Deportivo Toluca",
        shortSquadName: "Toluca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15418_Deportivo_Toluca.png"
    },
    {
        squadId: 32865,
        squadName: "Portland Timbers",
        shortSquadName: "Portland",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8113_Portland_Timbers.png"
    },
    {
        squadId: 33217,
        squadName: "Real CD Espana",
        shortSquadName: "Real Espana",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
    },
    {
        squadId: 33615,
        squadName: "Zalaegerszegi TE FC",
        shortSquadName: "Zalaegerszeg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_33615_Zalaegerszegi_TE_FC.png"
    },
    {
        squadId: 33648,
        squadName: "Atletico Nacional",
        shortSquadName: "Atl. Nacional",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
    },
    {
        squadId: 35839,
        squadName: "Sociedade Esportiva Palmeiras",
        shortSquadName: "Palmeiras",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
    },
    {
        squadId: 36192,
        squadName: "Tigres UANL",
        shortSquadName: "Tigres UANL",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
    },
    {
        squadId: 36502,
        squadName: "Dorados de Sinaloa",
        shortSquadName: "Dorados Sinaloa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15465_Dorados_de_Sinaloa.png"
    },
    {
        squadId: 37021,
        squadName: "CSD Municipal",
        shortSquadName: "CSD Municipal",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_37021_CSD_Municipal.png"
    },
    {
        squadId: 37217,
        squadName: "Deportes Tolima",
        shortSquadName: "Deportes Tolima",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
    },
    {
        squadId: 38328,
        squadName: "D.C. United",
        shortSquadName: "D.C. United",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
    },
    {
        squadId: 40208,
        squadName: "Tlaxcala FC",
        shortSquadName: "Tlaxcala",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/31554_Tlaxcala_FC.png"
    },
    {
        squadId: 41692,
        squadName: "Seattle Sounders FC",
        shortSquadName: "Seattle",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8067_Seattle_Sounders_FC.png"
    },
    {
        squadId: 41792,
        squadName: "Las Vegas Lights FC",
        shortSquadName: "Las Vegas",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/62275_Las_Vegas_Lights_FC.png"
    },
    {
        squadId: 43155,
        squadName: "New Mexico United",
        shortSquadName: "New Mexico Utd.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/63763_New_Mexico_United.png"
    },
    {
        squadId: 43347,
        squadName: "Dynamo Puerto FC",
        shortSquadName: "Dynamo Puerto",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/64966_Dynamo_Puerto_FC.png"
    },
    {
        squadId: 50113,
        squadName: "Inter Miami CF",
        shortSquadName: "Miami",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
    },
    {
        squadId: 200001,
        squadName: "Mazatlan FC",
        shortSquadName: "Mazatlan FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
    },
    {
        squadId: 200004,
        squadName: "Charlotte FC",
        shortSquadName: "Charlotte",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
    },
    {
        squadId: 200109,
        squadName: "Cancun FC",
        shortSquadName: "Cancun FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
    },
    {
        squadId: 225041,
        squadName: "Karpaty Lviv",
        shortSquadName: "Karpaty Lviv",
        squadLogo: null
    },
    {
        squadId: 225678,
        squadName: "San Diego FC",
        shortSquadName: "San Diego FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/114977_San_Diego_FC.png"
    },
    {
        squadId: 225869,
        squadName: "Federacion Nacional de Futbol de Guatemala",
        shortSquadName: "Federacion Nacional de Futbol de Guatemala",
        squadLogo: null
    },
    {
        squadId: 225870,
        squadName: "Leagues Cup",
        shortSquadName: "Leagues Cup",
        squadLogo: null
    },
]