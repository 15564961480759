export const eventClubs = [
  {
    squadId: 1,
    squadName: "AC Milan",
    shortSquadName: "AC Milan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1834_AC_Milan.png"
  },
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 3,
    squadName: "Juventus FC",
    shortSquadName: "Juventus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1832_Juventus_FC.png"
  },
  {
    squadId: 4,
    squadName: "Parma Calcio 1913",
    shortSquadName: "Parma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 47,
    squadName: "Tottenham Hotspur",
    shortSquadName: "Tottenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1122_Tottenham_Hotspur.png"
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 61,
    squadName: "Fulham FC",
    shortSquadName: "Fulham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fulham_FC.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 66,
    squadName: "Leicester City",
    shortSquadName: "Leicester",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leicester_City.png"
  },
  {
    squadId: 67,
    squadName: "West Ham United",
    shortSquadName: "West Ham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1633_West_Ham_United.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 119,
    squadName: "FC Lugano",
    shortSquadName: "FC Lugano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1938_FC_Lugano.png"
  },
  {
    squadId: 174,
    squadName: "Club Atletico Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
  },
  {
    squadId: 416,
    squadName: "Al-Fateh",
    shortSquadName: "Al-Fateh",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16483_Al-Fateh.png"
  },
  {
    squadId: 496,
    squadName: "HNK Rijeka",
    shortSquadName: "HNK Rijeka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png"
  },
  {
    squadId: 606,
    squadName: "Quevilly Rouen Metropole",
    shortSquadName: "Quevilly Rouen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3865_US_Quevilly-Rouen_Métropole.png"
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "O'Higgins",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
  },
  {
    squadId: 863,
    squadName: "Grimsby Town",
    shortSquadName: "Grimsby Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1196_Grimsby_Town.png"
  },
  {
    squadId: 930,
    squadName: "Welling United",
    shortSquadName: "Welling Utd.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2297_Welling_United.png"
  },
  {
    squadId: 1003,
    squadName: "Hull City",
    shortSquadName: "Hull City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1672_Hull_City.png"
  },
  {
    squadId: 1065,
    squadName: "SC Braga",
    shortSquadName: "SC Braga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sporting_Braga.png"
  },
  {
    squadId: 1177,
    squadName: "OFI Crete FC",
    shortSquadName: "OFI Crete FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12517_OFI_Crete.png"
  },
  {
    squadId: 1224,
    squadName: "Botafogo FC",
    shortSquadName: "NULL",
    squadLogo: null
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
  },
  {
    squadId: 1423,
    squadName: "LD Alajuelense",
    shortSquadName: "LD Alajuelense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16835_LD_Alajuelense.png"
  },
  {
    squadId: 1559,
    squadName: "FC Cincinnati",
    shortSquadName: "Cincinnati",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "Viktoria Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
  },
  {
    squadId: 2086,
    squadName: "FC Erzgebirge Aue",
    shortSquadName: "Erzgebirge Aue",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Erzgebirge_Aue.png"
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 2994,
    squadName: "1.FC Saarbrucken",
    shortSquadName: "Saarbrucken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2476_1.FC_Saarbrücken.png"
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2051_SK_Slavia_Prag.png"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 4230,
    squadName: "Stade Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
  },
  {
    squadId: 4234,
    squadName: "Queens Park Rangers",
    shortSquadName: "QPR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1149_Queens_Park_Rangers.png"
  },
  {
    squadId: 4270,
    squadName: "SpVgg Bayreuth",
    shortSquadName: "SpVgg Bayreuth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2546_SpVgg_Bayreuth.png"
  },
  {
    squadId: 4416,
    squadName: "Sport Club do Recife",
    shortSquadName: "Sport Recife",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5674_Sport_Club_do_Recife.png"
  },
  {
    squadId: 4999,
    squadName: "KVC Westerlo",
    shortSquadName: "KVC Westerlo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1591_KVC_Westerlo.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 5109,
    squadName: "PEC Zwolle",
    shortSquadName: "PEC Zwolle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1543_PEC_Zwolle.png"
  },
  {
    squadId: 5159,
    squadName: "FC Groningen",
    shortSquadName: "FC Groningen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Groningen.png"
  },
  {
    squadId: 5180,
    squadName: "Basaksehir FK",
    shortSquadName: "Basaksehir",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2005_Medipol_Basaksehir.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
  },
  {
    squadId: 5359,
    squadName: "Caracas FC",
    shortSquadName: "Caracas FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
  },
  {
    squadId: 5430,
    squadName: "GAIS Goteborg",
    shortSquadName: "GAIS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1451_GAIS_Goteborg.png"
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
  },
  {
    squadId: 5869,
    squadName: "Viking FK",
    shortSquadName: "Viking FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7100_Viking_Stavanger.png"
  },
  {
    squadId: 5924,
    squadName: "Everton de Vina del Mar",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 5972,
    squadName: "San Jose Earthquakes",
    shortSquadName: "San Jose",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/690_Real_Valladolid_CF.png"
  },
  {
    squadId: 6207,
    squadName: "Molde FK",
    shortSquadName: "Molde",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7098_Molde_FK.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "B. Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 6306,
    squadName: "Hibernian FC",
    shortSquadName: "Hibernian FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hibernian_FC.png"
  },
  {
    squadId: 6571,
    squadName: "US Salernitana 1919",
    shortSquadName: "Salernitana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1865_US_Salernitana_1919.png"
  },
  {
    squadId: 7083,
    squadName: "Elche CF",
    shortSquadName: "Elche CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1793_Elche_CF.png"
  },
  {
    squadId: 7400,
    squadName: "Botafogo de Futebol e Regatas",
    shortSquadName: "Botafogo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7400_Botafogo_de_Futebol_e_Regatas.png"
  },
  {
    squadId: 7527,
    squadName: "FC Vaduz",
    shortSquadName: "FC Vaduz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2354_FC_Vaduz.png"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 7845,
    squadName: "CF Pachuca",
    shortSquadName: "Pachuca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
  },
  {
    squadId: 7898,
    squadName: "Preston North End",
    shortSquadName: "Preston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1161_Preston_North_End.png"
  },
  {
    squadId: 7994,
    squadName: "Peterborough Sports",
    shortSquadName: "Peterb Sports",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34519_Peterborough_Sports.png"
  },
  {
    squadId: 8076,
    squadName: "CD Universidad Catolica",
    shortSquadName: "U. Catolica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12295_CD_Universidad_Católica.png"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 8498,
    squadName: "Odense Boldklub",
    shortSquadName: "Odense BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1412_Odense_Boldklub.png"
  },
  {
    squadId: 8635,
    squadName: "Mansfield Town",
    shortSquadName: "Mansfield Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1189_Mansfield_Town.png"
  },
  {
    squadId: 8665,
    squadName: "New York City FC",
    shortSquadName: "New York City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2109_New_York_City_FC.png"
  },
  {
    squadId: 8886,
    squadName: "Jeonbuk Hyundai Motors",
    shortSquadName: "Jeonbuk Hyundai",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2425_Jeonbuk_Hyundai_Motors_FC.png"
  },
  {
    squadId: 8934,
    squadName: "FC Sion",
    shortSquadName: "FC Sion",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6421_FC_Sion.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.svg"
  },
  {
    squadId: 9385,
    squadName: "Sporting Kansas City",
    shortSquadName: "Kansas City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7849_Sporting_Kansas_City.png"
  },
  {
    squadId: 9566,
    squadName: "HNK Gorica",
    shortSquadName: "HNK Gorica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9557_HNK_Gorica.png"
  },
  {
    squadId: 9929,
    squadName: "Lincoln City",
    shortSquadName: "Lincoln City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1207_Lincoln_City.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 10026,
    squadName: "Los Angeles Galaxy",
    shortSquadName: "Los Angeles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
  },
  {
    squadId: 10317,
    squadName: "New York Red Bulls",
    shortSquadName: "New York RB",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
  },
  {
    squadId: 10371,
    squadName: "Audax Italiano",
    shortSquadName: "Audax Italiano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12292_Audax_Italiano.png"
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
  },
  {
    squadId: 10474,
    squadName: "FC Winterthur",
    shortSquadName: "FC Winterthur",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2357_FC_Winterthur.png"
  },
  {
    squadId: 10781,
    squadName: "Kristiansund BK",
    shortSquadName: "Kristiansund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1477_Kristiansund_BK.png"
  },
  {
    squadId: 10931,
    squadName: "CA San Lorenzo de Almagro",
    shortSquadName: "San Lorenzo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12071_Club_Atlético_San_Lorenzo_de_Almagro.png"
  },
  {
    squadId: 11001,
    squadName: "Alanyaspor",
    shortSquadName: "Alanyaspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2013_Aytemiz_Alanyaspor.png"
  },
  {
    squadId: 11209,
    squadName: "Bristol City",
    shortSquadName: "Bristol City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1655_Bristol_City.png"
  },
  {
    squadId: 11474,
    squadName: "Exeter City",
    shortSquadName: "Exeter City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1187_Exeter_City.png"
  },
  {
    squadId: 11585,
    squadName: "Tromso IL",
    shortSquadName: "Tromso IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
  },
  {
    squadId: 11717,
    squadName: "MOL Fehervar FC",
    shortSquadName: "Fehervar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1629_Videoton_FC.png"
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "FK Jerv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 12098,
    squadName: "CD Santa Clara",
    shortSquadName: "Santa Clara",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9634_CD_Santa_Clara.png"
  },
  {
    squadId: 12139,
    squadName: "Budapest Honved FC",
    shortSquadName: "Honved",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
  },
  {
    squadId: 12156,
    squadName: "Charlton Athletic",
    shortSquadName: "Charlton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Charlton_Athletic.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 12294,
    squadName: "Olympique Marseille",
    shortSquadName: "Marseille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
  },
  {
    squadId: 12359,
    squadName: "Fortuna Sittard",
    shortSquadName: "Fortuna Sittard",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/44_Fortuna_Sittard.png"
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_VfL_Wolfsburg.png"
  },
  {
    squadId: 12598,
    squadName: "VfB Stuttgart",
    shortSquadName: "VfB Stuttgart",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1273_VfB_Stuttgart.png"
  },
  {
    squadId: 12612,
    squadName: "Hammarby IF",
    shortSquadName: "Hammarby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6710_Hammarby_IF.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 12835,
    squadName: "Cheshunt FC",
    shortSquadName: "Cheshunt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1786_Cheshunt_FC.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 13099,
    squadName: "CF Montreal",
    shortSquadName: "Montreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14969_Club_de_Foot_Montréal.png"
  },
  {
    squadId: 13114,
    squadName: "Real Zaragoza",
    shortSquadName: "Real Zaragoza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/689_Real_Zaragoza.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 13756,
    squadName: "Debreceni VSC",
    shortSquadName: "Debrecen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1634_Debreceni_VSC.png"
  },
  {
    squadId: 14129,
    squadName: "Longford Town FC",
    shortSquadName: "Longford Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_14129_Longford_Town_FC.png"
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9918_FK_Sarajevo.png"
  },
  {
    squadId: 14480,
    squadName: "Real Union Club",
    shortSquadName: "Real Union",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1786_Real_Union_Club.png"
  },
  {
    squadId: 14512,
    squadName: "Royal Excelsior Virton",
    shortSquadName: "RE Virton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2366_Royal_Excelsior_Virton.png"
  },
  {
    squadId: 14590,
    squadName: "Northampton Town",
    shortSquadName: "Northampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1169_Northampton_Town.png"
  },
  {
    squadId: 14649,
    squadName: "SK Beveren",
    shortSquadName: "SK Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5104_SK_Beveren.png"
  },
  {
    squadId: 14737,
    squadName: "SV 07 Elversberg",
    shortSquadName: "SV Elversberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1371_SV_07_Elversberg.png"
  },
  {
    squadId: 14821,
    squadName: "FC Copenhagen",
    shortSquadName: "FC Copenhagen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Copenhagen.png"
  },
  {
    squadId: 14958,
    squadName: "Coventry City",
    shortSquadName: "Coventry",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1188_Coventry_City.png"
  },
  {
    squadId: 14994,
    squadName: "CD Leones Negros de la UdeG",
    shortSquadName: "Leones Negros",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
  },
  {
    squadId: 15052,
    squadName: "Sogndal IL",
    shortSquadName: "Sogndal IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
  },
  {
    squadId: 15130,
    squadName: "AIK Solna",
    shortSquadName: "AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png"
  },
  {
    squadId: 15268,
    squadName: "Widzew Lodz",
    shortSquadName: "Widzew Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1587_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 15546,
    squadName: "Al-Taawoun FC",
    shortSquadName: "Al-Taawoun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15546_Al-Taawoun_FC.png"
  },
  {
    squadId: 15581,
    squadName: "Lech Poznan",
    shortSquadName: "Lech Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1595_Lech_Poznan.png"
  },
  {
    squadId: 15821,
    squadName: "Arminia Bielefeld",
    shortSquadName: "Arm. Bielefeld",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 16046,
    squadName: "Olympique Lyon",
    shortSquadName: "Olympique Lyon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1966_Olympique_Lyon.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Athletico-PR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 16345,
    squadName: "Mjondalen IF",
    shortSquadName: "Mjondalen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1486_Mjondalen_IF.png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 16641,
    squadName: "Oxford City",
    shortSquadName: "Oxford City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1847_Oxford_City.png"
  },
  {
    squadId: 16675,
    squadName: "Odds BK",
    shortSquadName: "Odds BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7090_Odds_BK.png"
  },
  {
    squadId: 16727,
    squadName: "Fortaleza CEIF",
    shortSquadName: "Fortaleza CEIF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12427_Fortaleza_CEIF_FC.png"
  },
  {
    squadId: 16775,
    squadName: "Reading FC",
    shortSquadName: "Reading",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1147_Reading_FC.png"
  },
  {
    squadId: 17017,
    squadName: "Philadelphia Union",
    shortSquadName: "Philadelphia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png"
  },
  {
    squadId: 17175,
    squadName: "Norwich City",
    shortSquadName: "Norwich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1144_Norwich_City.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 17418,
    squadName: "Valerenga Fotball",
    shortSquadName: "Valerenga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png"
  },
  {
    squadId: 17454,
    squadName: "Avai Futebol Clube (SC)",
    shortSquadName: "Avai FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
  },
  {
    squadId: 17504,
    squadName: "Bath City",
    shortSquadName: "Bath City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2308_Bath_City.png"
  },
  {
    squadId: 17668,
    squadName: "FC Famalicao",
    shortSquadName: "Famalicao",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Famalicao.png"
  },
  {
    squadId: 17788,
    squadName: "Paris Saint-Germain",
    shortSquadName: "Paris SG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Paris_Saint-Germain.png"
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
  },
  {
    squadId: 18179,
    squadName: "SC Verl",
    shortSquadName: "SC Verl",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos Athens",
    shortSquadName: "Panathinaikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 18697,
    squadName: "FC Augsburg",
    shortSquadName: "FC Augsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1274_FC_Augsburg.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 18960,
    squadName: "FC Porto",
    shortSquadName: "FC Porto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1672_FC_Porto.png"
  },
  {
    squadId: 19138,
    squadName: "RCD Mallorca",
    shortSquadName: "RCD Mallorca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/686_RCD_Mallorca.png"
  },
  {
    squadId: 19151,
    squadName: "Clermont Foot 63",
    shortSquadName: "Clermont Foot",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3797_Clermont_Foot_Auvergne_63.png"
  },
  {
    squadId: 19177,
    squadName: "SV Wacker Burghausen",
    shortSquadName: "W. Burghausen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1321_SV_Wacker_Burghausen.png"
  },
  {
    squadId: 19206,
    squadName: "Santos FC",
    shortSquadName: "Santos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
  },
  {
    squadId: 19251,
    squadName: "SV Waldhof Mannheim",
    shortSquadName: "Waldh. Mannheim",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1370_SV_Waldhof_Mannheim.png"
  },
  {
    squadId: 19631,
    squadName: "FK Vojvodina Novi Sad",
    shortSquadName: "Vojvodina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1660_FK_Vojvodina_Novi_Sad.png"
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 20127,
    squadName: "FCV Farul Constanta",
    shortSquadName: "FCV Farul",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11797_Viitorul.png"
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
  },
  {
    squadId: 20302,
    squadName: "Hallescher FC",
    shortSquadName: "Hallescher FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hallescher_FC.png"
  },
  {
    squadId: 20448,
    squadName: "SV Meppen",
    shortSquadName: "SV Meppen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Meppen.png"
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 20836,
    squadName: "Banbury United",
    shortSquadName: "Banbury",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2507_Banbury_United.png"
  },
  {
    squadId: 20887,
    squadName: "Millwall FC",
    shortSquadName: "Millwall",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1160_Millwall_FC.png"
  },
  {
    squadId: 20983,
    squadName: "Club Atletico Belgrano",
    shortSquadName: "Belgrano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2591_Club_Atletico_Belgrano.png"
  },
  {
    squadId: 21139,
    squadName: "Olympiacos Piraeus",
    shortSquadName: "Olympiacos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png"
  },
  {
    squadId: 21425,
    squadName: "FC St. Gallen 1879",
    shortSquadName: "FC St. Gallen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
  },
  {
    squadId: 21706,
    squadName: "FC Hansa Rostock",
    shortSquadName: "Hansa Rostock",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hansa_Rostock.png"
  },
  {
    squadId: 22011,
    squadName: "Puebla FC",
    shortSquadName: "Puebla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2282_Puebla_FC.png"
  },
  {
    squadId: 22196,
    squadName: "SpVgg Greuther Furth",
    shortSquadName: "Greuther Furth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
  },
  {
    squadId: 22198,
    squadName: "TSV 1860 Munich",
    shortSquadName: "1860 Munich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2461_TSV_1860_Munich.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Salonika",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13270_Maccabi_Tel_Aviv.png"
  },
  {
    squadId: 22719,
    squadName: "Clube de Regatas Vasco da Gama",
    shortSquadName: "Vasco da Gama",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 22922,
    squadName: "FK Zeleziarne Podbrezova",
    shortSquadName: "Podbrezova",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2129_FK_Zeleziarne_Podbrezova.png"
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
  },
  {
    squadId: 23005,
    squadName: "Albacete Balompie",
    shortSquadName: "Albacete",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1744_Albacete_Balompie.png"
  },
  {
    squadId: 23117,
    squadName: "FC Versailles 78",
    shortSquadName: "FC Versailles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4213_FC_Versailles_78.png"
  },
  {
    squadId: 23168,
    squadName: "1. FC Koln",
    shortSquadName: "1. FC Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
  },
  {
    squadId: 23352,
    squadName: "Stade Rennais FC",
    shortSquadName: "Rennes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Stade_Rennes.png"
  },
  {
    squadId: 23506,
    squadName: "Atlas Guadalajara",
    shortSquadName: "Atlas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15407_Atlas.png"
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
  },
  {
    squadId: 23647,
    squadName: "Atletico de San Luis",
    shortSquadName: "San Luis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
  },
  {
    squadId: 23752,
    squadName: "FK Haugesund",
    shortSquadName: "Haugesund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7114_FK_Haugesund.png"
  },
  {
    squadId: 23767,
    squadName: "Crawley Town",
    shortSquadName: "Crawley Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1198_Crawley_Town.png"
  },
  {
    squadId: 23819,
    squadName: "FC Dordrecht",
    shortSquadName: "FC Dordrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1568_FC_Dordrecht.png"
  },
  {
    squadId: 23873,
    squadName: "Botev Plovdiv",
    shortSquadName: "Botev Plovdiv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
  },
  {
    squadId: 24387,
    squadName: "Luton Town",
    shortSquadName: "Luton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Luton_Town.png"
  },
  {
    squadId: 24408,
    squadName: "Club Alianza Lima",
    shortSquadName: "Alianza Lima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7239_Club_Alianza_Lima.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
  },
  {
    squadId: 24448,
    squadName: "Aarhus GF",
    shortSquadName: "Aarhus GF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1410_Aarhus_GF.png"
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
  },
  {
    squadId: 24695,
    squadName: "Royale Union Saint Gilloise",
    shortSquadName: "Union SG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1590_Royale_Union_Saint_Gilloise.png"
  },
  {
    squadId: 24698,
    squadName: "Cambridge United",
    shortSquadName: "Cambridge Utd.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1201_Cambridge_United.png"
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
  },
  {
    squadId: 24749,
    squadName: "Grenoble Foot 38",
    shortSquadName: "Grenoble",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3798_Grenoble_Foot_38.png"
  },
  {
    squadId: 24755,
    squadName: "Milton Keynes Dons",
    shortSquadName: "MK Dons",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1164_Milton_Keynes_Dons.png"
  },
  {
    squadId: 24973,
    squadName: "Queretaro FC",
    shortSquadName: "Queretaro FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15421_Querétaro_FC.png"
  },
  {
    squadId: 25037,
    squadName: "Montevideo City Torque",
    shortSquadName: "Mvd City Torque",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/23497_Montevideo_City_Torque.png"
  },
  {
    squadId: 25051,
    squadName: "Wycombe Wanderers",
    shortSquadName: "Wycombe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wycombe_Wanderers.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 25098,
    squadName: "FSV Zwickau",
    shortSquadName: "FSV Zwickau",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FSV_Zwickau.png"
  },
  {
    squadId: 25177,
    squadName: "Derby County",
    shortSquadName: "Derby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1647_Derby_County.png"
  },
  {
    squadId: 25436,
    squadName: "Nashville SC",
    shortSquadName: "Nashville",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 25629,
    squadName: "RSC Anderlecht",
    shortSquadName: "RSC Anderlecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_RSC_Anderlecht.png"
  },
  {
    squadId: 25789,
    squadName: "Union La Calera",
    shortSquadName: "La Calera",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12314_Unión_La_Calera.png"
  },
  {
    squadId: 25852,
    squadName: "CA Newell's Old Boys",
    shortSquadName: "Newell's",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12064_Newell's_Old_Boys.png"
  },
  {
    squadId: 25911,
    squadName: "Royal Antwerp FC",
    shortSquadName: "Royal Antwerp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
  },
  {
    squadId: 26054,
    squadName: "CFR Cluj",
    shortSquadName: "CFR Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2341_CFR_Cluj.png"
  },
  {
    squadId: 26056,
    squadName: "AeK Athens",
    shortSquadName: "AeK Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
  },
  {
    squadId: 26333,
    squadName: "SV Ried",
    shortSquadName: "SV Ried",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Ried.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 26367,
    squadName: "Santos Laguna",
    shortSquadName: "Santos Laguna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
  },
  {
    squadId: 26439,
    squadName: "Deportivo Alaves",
    shortSquadName: "Alaves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/696_Deportivo_Alavés.png"
  },
  {
    squadId: 26457,
    squadName: "Stabaek Fotball",
    shortSquadName: "Stabaek Fotball",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1473_Stabaek_IF.png"
  },
  {
    squadId: 26540,
    squadName: "Orlando City SC",
    shortSquadName: "Orlando",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
  },
  {
    squadId: 26623,
    squadName: "Miedz Legnica",
    shortSquadName: "Miedz Legnica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1613_Miedz_Legnica.png"
  },
  {
    squadId: 26667,
    squadName: "Coritiba Foot Ball Club",
    shortSquadName: "Coritiba FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
  },
  {
    squadId: 26699,
    squadName: "Galatasaray",
    shortSquadName: "Galatasaray",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2003_Galatasaray_SK.png"
  },
  {
    squadId: 26895,
    squadName: "Jagiellonia Bialystok",
    shortSquadName: "Jagiellonia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1597_Jagiellonia_Bialystok.png"
  },
  {
    squadId: 27091,
    squadName: "Chesterfield FC",
    shortSquadName: "Chesterfield FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1193_Chesterfield_FC.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 27251,
    squadName: "CR Flamengo",
    shortSquadName: "Flamengo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
  },
  {
    squadId: 27287,
    squadName: "Vitoria SC",
    shortSquadName: "Vitoria SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1676_Vitoria_Guimaraes_SC.png"
  },
  {
    squadId: 27570,
    squadName: "Puskas Akademia FC",
    shortSquadName: "Puskas AFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1630_Puskas_Akademia_FC.png"
  },
  {
    squadId: 27650,
    squadName: "FC Juarez",
    shortSquadName: "FC Juarez",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34402_FC_Juárez.png"
  },
  {
    squadId: 27984,
    squadName: "Ranheim IL",
    shortSquadName: "Ranheim IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7193_Ranheim_IL.png"
  },
  {
    squadId: 27985,
    squadName: "AC Le Havre",
    shortSquadName: "AC Le Havre",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3793_Le_Havre.png"
  },
  {
    squadId: 28061,
    squadName: "MSK Zilina",
    shortSquadName: "MSK Zilina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14368_MSK_Zilina.png"
  },
  {
    squadId: 28108,
    squadName: "FC Wil 1900",
    shortSquadName: "FC Wil 1900",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2361_FC_Wil_1900.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 28372,
    squadName: "Club Atletico Morelia",
    shortSquadName: "Morelia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
  },
  {
    squadId: 28535,
    squadName: "Naestved Boldklub",
    shortSquadName: "Naestved BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
  },
  {
    squadId: 28879,
    squadName: "Athletic Bilbao",
    shortSquadName: "Athletic",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/678_Athletic_Bilbao.png"
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 29198,
    squadName: "FC Den Bosch",
    shortSquadName: "FC Den Bosch",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Den_Bosch.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 29306,
    squadName: "Mineros de Zacatecas",
    shortSquadName: "Min. Zacatecas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png"
  },
  {
    squadId: 29469,
    squadName: "GNK Dinamo Zagreb",
    shortSquadName: "Dinamo Zagreb",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2163_GNK_Dinamo_Zagreb.png"
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 29646,
    squadName: "NK Domzale",
    shortSquadName: "NK Domzale",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2463_NK_Domzale.png"
  },
  {
    squadId: 29655,
    squadName: "Blackburn Rovers",
    shortSquadName: "Blackburn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16388_Blackburn_Rovers.svg"
  },
  {
    squadId: 29757,
    squadName: "Bradford City",
    shortSquadName: "Bradford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1653_Bradford_City.png"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 29777,
    squadName: "LR Vicenza",
    shortSquadName: "LR Vicenza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png"
  },
  {
    squadId: 29796,
    squadName: "Alebrijes de Oaxaca",
    shortSquadName: "Oaxaca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 30372,
    squadName: "Envigado FC",
    shortSquadName: "Envigado",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "Sparta Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
  },
  {
    squadId: 30723,
    squadName: "Borussia Dortmund",
    shortSquadName: "Bor. Dortmund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1259_Borussia_Dortmund.png"
  },
  {
    squadId: 30734,
    squadName: "Altinordu FK",
    shortSquadName: "Altinordu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
  },
  {
    squadId: 30736,
    squadName: "FC Sochaux-Montbeliard",
    shortSquadName: "FC Sochaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3768_FC_Sochaux-Montbéliard.png"
  },
  {
    squadId: 30849,
    squadName: "Atlanta United FC",
    shortSquadName: "Atlanta",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Leon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
  },
  {
    squadId: 31328,
    squadName: "Sabah FK",
    shortSquadName: "Sabah FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 31974,
    squadName: "Aris Thessaloniki",
    shortSquadName: "Aris Saloniki",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12520_Aris_Thessaloniki.png"
  },
  {
    squadId: 32082,
    squadName: "SV Kapfenberg",
    shortSquadName: "SV Kapfenberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1961_SV_Kapfenberg.png"
  },
  {
    squadId: 32380,
    squadName: "VfB Oldenburg",
    shortSquadName: "VfB Oldenburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1337_VfB_Oldenburg.png"
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "E. Frankfurt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
  },
  {
    squadId: 32480,
    squadName: "CS Maritimo",
    shortSquadName: "Maritimo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9612_CS_Marítimo.png"
  },
  {
    squadId: 32515,
    squadName: "IK Sirius",
    shortSquadName: "Sirius",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1446_IK_Sirius.png"
  },
  {
    squadId: 32704,
    squadName: "UNAM Pumas",
    shortSquadName: "UNAM Pumas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
  },
  {
    squadId: 32786,
    squadName: "Norrby IF",
    shortSquadName: "Norrby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1463_Norrby_IF.png"
  },
  {
    squadId: 32987,
    squadName: "Bristol Rovers",
    shortSquadName: "Bristol Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1184_Bristol_Rovers.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid 1923",
    shortSquadName: "FC Rapid 1923",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
  },
  {
    squadId: 33183,
    squadName: "CD Mafra",
    shortSquadName: "Mafra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3016_CD_Mafra.png"
  },
  {
    squadId: 33217,
    squadName: "Real CD Espana",
    shortSquadName: "Real Espana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
  },
  {
    squadId: 33471,
    squadName: "Morecambe FC",
    shortSquadName: "Morecambe FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1200_Morecambe_FC.png"
  },
  {
    squadId: 33479,
    squadName: "Fleetwood Town",
    shortSquadName: "Fleetwood",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fleetwood_Town.png"
  },
  {
    squadId: 33615,
    squadName: "Zalaegerszegi TE FC",
    shortSquadName: "Zalaegerszeg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_33615_Zalaegerszegi_TE_FC.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 33781,
    squadName: "Wurzburger Kickers",
    shortSquadName: "Wurzb. Kickers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1295_Wurzburger_Kickers.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 33797,
    squadName: "Spezia Calcio",
    shortSquadName: "Spezia Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1860_Spezia_Calcio.png"
  },
  {
    squadId: 33813,
    squadName: "Karlsruher SC",
    shortSquadName: "Karlsruher SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
  },
  {
    squadId: 33893,
    squadName: "FC Emmen",
    shortSquadName: "FC Emmen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Emmen.png"
  },
  {
    squadId: 33927,
    squadName: "UD Almeria",
    shortSquadName: "UD Almeria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/708_UD_Almería.png"
  },
  {
    squadId: 33983,
    squadName: "VVV-Venlo",
    shortSquadName: "VVV-Venlo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1549_VVV-Venlo.png"
  },
  {
    squadId: 34152,
    squadName: "Tranmere Rovers",
    shortSquadName: "Tranmere Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1216_Tranmere_Rovers.png"
  },
  {
    squadId: 34616,
    squadName: "Vejle Boldklub",
    shortSquadName: "Vejle BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1422_Vejle_Boldklub.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 34923,
    squadName: "Harrogate Town",
    shortSquadName: "Harrogate Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
  },
  {
    squadId: 35023,
    squadName: "Blackpool FC",
    shortSquadName: "Blackpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1180_Blackpool_FC.png"
  },
  {
    squadId: 35045,
    squadName: "Vitesse Arnhem",
    shortSquadName: "Vitesse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png"
  },
  {
    squadId: 35103,
    squadName: "Standard Liege",
    shortSquadName: "Standard Liege",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 35606,
    squadName: "Abha Club",
    shortSquadName: "Abha",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_35606_Abha_Club.png"
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Palmeiras",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
  },
  {
    squadId: 36226,
    squadName: "Go Ahead Eagles",
    shortSquadName: "Go Ahead Eagles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1553_Go_Ahead_Eagles_Deventer.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 36627,
    squadName: "FC Metz",
    shortSquadName: "FC Metz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1978_FC_Metz.png"
  },
  {
    squadId: 36736,
    squadName: "Olimpia Asuncion",
    shortSquadName: "Olimpia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15486_Olimpia.png"
  },
  {
    squadId: 36785,
    squadName: "America FC",
    shortSquadName: "America FC",
    squadLogo: null
  },
  {
    squadId: 37066,
    squadName: "SSV Ulm 1846",
    shortSquadName: "SSV Ulm 1846",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2508_SSV_Ulm_1846.png"
  },
  {
    squadId: 37217,
    squadName: "Deportes Tolima",
    shortSquadName: "Deportes Tolima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
  },
  {
    squadId: 37341,
    squadName: "IFK Goteborg",
    shortSquadName: "IFK Goteborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1438_IFK_Goteborg.png"
  },
  {
    squadId: 37475,
    squadName: "Mamelodi Sundowns FC",
    shortSquadName: "Sundowns",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
  },
  {
    squadId: 38330,
    squadName: "NK Radomlje",
    shortSquadName: "NK Radomlje",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12004_NK_Radomlje.svg"
  },
  {
    squadId: 38553,
    squadName: "Aston Villa",
    shortSquadName: "Aston Villa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 39198,
    squadName: "Boyaca Patriotas FC",
    shortSquadName: "Patriotas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_39198_Boyaca_Patriotas_FC.png"
  },
  {
    squadId: 39337,
    squadName: "SK Dnipro-1",
    shortSquadName: "SK Dnipro-1",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png"
  },
  {
    squadId: 39599,
    squadName: "FC Midtjylland",
    shortSquadName: "FC Midtjylland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png"
  },
  {
    squadId: 39648,
    squadName: "New England Revolution",
    shortSquadName: "New England",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7854_New_England_Revolution.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 39850,
    squadName: "Warrenpoint Town FC",
    shortSquadName: "Warrenpoint",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2082_Warrenpoint_Town_FC.png"
  },
  {
    squadId: 39981,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Clube Atletico Mineiro",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5653_Atlético-MG.png"
  },
  {
    squadId: 40170,
    squadName: "MSV Duisburg",
    shortSquadName: "MSV Duisburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1292_MSV_Duisburg.png"
  },
  {
    squadId: 40208,
    squadName: "Tlaxcala FC",
    shortSquadName: "Tlaxcala",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31554_Tlaxcala_FC.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 40498,
    squadName: "Swansea City",
    shortSquadName: "Swansea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1131_Swansea_City.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 40550,
    squadName: "Rot-Weiss Essen",
    shortSquadName: "RW Essen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1391_Rot-Weiss_Essen.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 41155,
    squadName: "Raith Rovers FC",
    shortSquadName: "Raith Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2285_Raith_Rovers_FC.png"
  },
  {
    squadId: 41646,
    squadName: "FC Dallas",
    shortSquadName: "Dallas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2113_FC_Dallas.png"
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
  },
  {
    squadId: 41692,
    squadName: "Seattle Sounders FC",
    shortSquadName: "Seattle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2106_Seattle_Sounders_FC.png"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 41956,
    squadName: "Deportivo Cali",
    shortSquadName: "Deportivo Cali",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png"
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 42757,
    squadName: "El Paso Locomotive FC",
    shortSquadName: "El Paso",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/63762_El_Paso_Locomotive_FC.png"
  },
  {
    squadId: 42798,
    squadName: "Cavalry FC",
    shortSquadName: "Cavalry FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 50190,
    squadName: "Damac FC",
    shortSquadName: "Damac FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_50190_Damac_FC.png"
  },
  {
    squadId: 60007,
    squadName: "Atletico Ottawa",
    shortSquadName: "Atl. Ottawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-79097_Atlético_Ottawa.png"
  },
  {
    squadId: 200003,
    squadName: "Oakland Roots SC",
    shortSquadName: "Oakland Roots",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69044_Oakland_Roots_SC.png"
  },
  {
    squadId: 200108,
    squadName: "Tepatitlan FC",
    shortSquadName: "Tepatitlan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64345_Tepatitlán_FC.png"
  },
  {
    squadId: 200109,
    squadName: "Cancun FC",
    shortSquadName: "Cancun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
  },
  {
    squadId: 200113,
    squadName: "1.FC Phonix Lubeck",
    shortSquadName: "Phonix Lubeck",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/2773.png?lm=1593726229"
  },
  {
    squadId: 200193,
    squadName: "Manisa FK",
    shortSquadName: "Manisa FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/48913_Manisa_FK.png"
  },
  {
    squadId: 223011,
    squadName: "Monterey Bay FC",
    shortSquadName: "Monterey Bay",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/87631.png?lm=1639169110"
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "Real Cartagena",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
  },
  {
    squadId: 225037,
    squadName: "Atletico La Paz",
    shortSquadName: "Atletico La Paz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/100124_Atlético_La_Paz.png"
  },
  // {
  //   squadId: 225180,
  //   squadName: "" "",
  //   shortSquadName: "NULL",
  //   squadLogo: null
  // },
];