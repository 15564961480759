import axios from 'axios-config';
import { CreatePlayerAdRequestV2, CreatePlayerAdResponse } from "./models/create-player-ad"
import { PlayerAd, PlayerAdInitialInfoResponse } from './models/player-ad'

export class PlayerAdService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async createPlayerAds(ads: Array<CreatePlayerAdRequestV2>): Promise<CreatePlayerAdResponse> {
        const { data } = await axios.post(
            `api/v2/PlayerAd/CreateBatchV2`,
            ads,
            this.axiosConfig
        );
        return data;
    }

    public static async getMyOutdatedPlayerAds(aspId: string): Promise<Array<PlayerAd>> {
        const { data } = await axios.get(
            `api/PlayerAds/GetMyOutdatedPlayerAds/${aspId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getMyPlayerAds(aspId: string): Promise<Array<PlayerAd>> {
        const { data } = await axios.get(
            `api/PlayerAds/GetMyPlayerAds/${aspId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async updateOutdatedAd(aspId: string, adId: number) {
        const { data } = await axios.post(
            `api/PlayerAds/UpdatePlayerAd/${aspId}`,
            adId,
            this.axiosConfig
        )
        return data
    }
    public static async removeOutdatedAd(aspId: string, adId: number) {
        const { data } = await axios.post(
            `api/PlayerAds/DeactivatePlayerAd/${aspId}`,
            adId,
            this.axiosConfig
        )
        return data
    }

    public static async getPlayerAdInitialInfo(): Promise<PlayerAdInitialInfoResponse> {
        const { data } = await axios.get(
            `api/v2/PlayerAd/InitialInfo`,
            this.axiosConfig
        );
        return data;
    }
}