import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAuth } from 'store/auth/authReducer';
import hostoryAccessor from 'history-accessor'

import { Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'
import Spinner from 'components/Spinner/Spinner';
import userActivityInsert, { UserActivity } from 'app/user-activity/actions/user-activity.actions';
import {
    Switch,
    Route,
    Redirect,
    RouteComponentProps
} from 'react-router-dom';

import { userPaths } from 'routes/paths'
import { Actions as MeetingPreparationActions } from 'app/events/redux/virtual-summit/meeting-preparation.controller'
// import BannerRegistered from 'app/events/components/authorized-event-page/common/banners/banner-registered-map'
import BannerRegistered from 'app/events/components/authorized-event-page/multiple-events/2024-november-istanbul/banners/banner-registered-istanbul'
import Tabs from 'app/events/components/authorized-event-page/common/tabs-for-registered-state/tabs'
import TabAttendance from './tab-attendance/tab-attendance.entry'
import TabMeetings from 'app/events/components/authorized-event-page/common/tab-meetings/tab-meetings.entry'
// import TabInfo from './tab-info/tab-info.entry'
import { PageType } from 'constants/enums';
//import BlogIframeModalCTA from '../../common/blog-iframe-modal/cta'
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import { AppState } from 'root.reducer';
import AuthState from 'store/auth/auth-state';
import { UserSummitModel } from 'api/virtual-summit/models/summit-invitationl';

interface StateProps {
    isLoading: boolean;
    upcommingEventData: UserSummitModel;
    isRegistrationShown: boolean;
    auth: AuthState;
    headerHeight: number;
}
interface DispatchProps {
    userActivityInsert: (obj: UserActivity) => void;
    loadMeetingPreparationData: (redirect, needToRedirect, defaultPath?: boolean) => void;
}

type Props = RouteComponentProps & StateProps & DispatchProps;

const Events = (props: Props) => {
    useEffect(() => {
        let search = new URLSearchParams(hostoryAccessor.location.search)

        let from = search.get("from");
        let type = search.get("type");
        let club = search.get("club");
        let redirect = search.get("redirect");

        if (from === "email" && type === "preparation" && club !== null) {
            props.userActivityInsert({
                Message: `Opened Link from ${club}`,
                PageName: 'Event [Agenda Email] ',
                PageType: PageType.Events,
                EventId: props.auth.eventId,
            });
        }
        if (from === "email" && type === "agenda") {
            props.userActivityInsert({
                Message: 'Clicked Prepare Agenda Now',
                PageName: 'Event [Agenda Email] ',
                PageType: PageType.Events, 
                EventId: props.auth.eventId,
            });
        }

        props.loadMeetingPreparationData(hostoryAccessor.push, redirect === 'true')
    }, []);

    const { isLoading, upcommingEventData } = props;

    const {
        isTransferIn,
        isTransferOut,
        isGeneralNetworking,
        transferInStepCompleted,
        transferOutStepCompleted,
        generalNetworkingStepCompleted,
        registerNowVisible,
        user,
        isUserRegistered,
        extraAttendees,
        offlineEventTimeZone,
        // isShowPracticalInformation
    } = upcommingEventData

    const isObjectivesDefined = isTransferIn || isTransferOut || isGeneralNetworking
    const isUserCompletedAnyStep = transferInStepCompleted || transferOutStepCompleted || generalNetworkingStepCompleted
    //for successfully npm build 
    if (isUserCompletedAnyStep) { }

    const registeredAttendees = [];
    if (extraAttendees) {
        if (isUserRegistered) {
            registeredAttendees.push(user.name)
        }
        extraAttendees.filter(i => i.isRegistered && i.name !== null).forEach(i => {
            registeredAttendees.push(i.name)
        })
    }

    const eventInfo = getEventInfo(props.auth.eventId);
    const { title, eventDates, location } = eventInfo;
    const { canShowAgendaBuilder } = eventInfo.visibility;
    return (
        <div className="authorized-event-page">
            {isLoading && <Spinner />}

            <BannerRegistered
                title='Your transfer window starts here'
                // upperTitle='THE HEAT IS ON:'
                // infoText='TransferRoom Summit'
                date='11+12 November'
                eventLocation='İstanbul, Turkey'
                description='Exclusively for decision makers and Trusted Agents. By Invitation Only.'
                isLoading={isLoading}
                isCompleted={isObjectivesDefined}
                className="event-banner-layout"
                onCompleteAttendanceClick={() => {
                    props.userActivityInsert({
                        Message: 'Complete Details',
                        PageName: 'Event',
                        PageType: PageType.Events,
                        EventId: props.auth.eventId,
                    })
                    hostoryAccessor.push(userPaths.eventCompleteDetails + "?first=true");
                }}
                eventAttendeess={registeredAttendees}
                onAddExtraAttendeeClick={() => {
                    hostoryAccessor.push(userPaths.eventRegistration + "?fromEvent=true")
                }}
                showAddExtraAttendeeBtn={true}
            />
                {!isLoading && canShowAgendaBuilder && (
                <div className="authorized-event-page__navigation-container" style={{ marginTop: 20 }}>
                    <Tabs headerHeight={props.headerHeight} isRegistered={!registerNowVisible} isShowPracticalInfo={false} />
                </div>
            )}
                        
            {/* <BlogIframeModalCTA /> */}

            {!isLoading && canShowAgendaBuilder &&
                <>
                    <Switch>
                        <Route exact path="/events/attendance">
                            <div className="events-page-wrapper">
                                <TabAttendance />
                            </div>
                        </Route>
                        {!registerNowVisible && <Route exact path="/events/agenda-builder">
                            <div className="events-page-wrapper">
                                {/* <TabMeetings /> */}
                            </div>
                        </Route>}
                        {/* {!registerNowVisible && isShowPracticalInformation && <Route exact path="/events/info">
                            <div className="events-page-wrapper">
                                <TabInfo />
                            </div>
                        </Route>} */}
                        {<Redirect to="/events/attendance" />}
                    </Switch>
                </>
            }

            {!isLoading && !canShowAgendaBuilder &&
                <div style={{ marginTop: 60 }} className="events-page-wrapper">
                    <TabAttendance />
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    isLoading: MiscEventSelectors.selectIsLoading(state),
    upcommingEventData: state.events.misc.upcommingSummitData,
    isRegistrationShown: state.events.misc.isRegistrationShown,
    auth: getAuth(state),
    headerHeight: state.commonStyles.header.headerHeight
});

const mapDispatchToProps: DispatchProps = {
    userActivityInsert,
    loadMeetingPreparationData: MeetingPreparationActions.loadMeetingPreparationData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
