import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyCustomPitchService from 'api/agency/custom-pitch/custom-pitch.service'
import {
    CreateCustomPitchRequest, VerifiedPlayer, SquadToPitch, CustomPitchComparisonRequest,
    PlayerTransfer, TransferBenchmark, CustomPitchPreviewInfo, HeadToHeadInfo
} from 'api/agency/custom-pitch/models'
import { createSelector } from 'reselect';
import { Comparisonitem } from 'api/super-pitch/models/comparison';
import * as CustomPitchController from './pitch-grid.controller';
import * as AutosuggestController from './head-to-head-autosuggest.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { anonymousPaths } from 'routes/paths';
import { toKMB } from 'utils/number-utils'
import { getAuthCurrency } from 'store/auth/authReducer';
import { currencyById } from 'constants/currency';
import { formatAverage } from 'app/players-comparison/helpers/format-player';
import getDataForChart from 'app/players-comparison/helpers/get-data-for-chart';
import * as CommonController from 'pages/agency/authorized-agent/pitch-page/redux/common.controller';

class AgencyPlusPitchCreteSession {
    playerId: number;
    fee: number | null;
    loanFee: number | null;
    salary: number | null;
    financialsTBD: boolean | null;
    recipientsSquadId: number;
    message: string;
    messageHeadline: string;
    video: string;
    comparisonKeys: string[];
    head2HeadPlayerId: number;
    aboutAgency: string;

    isMessageVisibleOnPitch: boolean;
    isVideoVisibleOnPitch: boolean;
    isComparisonKeysVisibleOnPitch: boolean;
    isHead2HeadVisibleOnPitch: boolean;
    isTransferHistoryVisibleOnPitch: boolean;
    isXtvBenchmarksVisibleOnPitch: boolean;
    isAboutAgencyVisibleOnPitch: boolean;
}

class AgencyCustomPitchState {
    isLoading: boolean;
    entity: AgencyPlusPitchCreteSession;
    currentStep?: number;
    players: Array<VerifiedPlayer>;
    isLoadingPlayers: boolean;
    isLoadingRecipients: boolean;
    isLoadingComparison: boolean;
    recipientSquads: Array<SquadToPitch>;
    comparisonCharacteristicSource: {
        keys: {
            firstKey: string,
            secondKey: string,
            thirdKey: string,
        },
        characteristicSource: Array<{
            'key': string;
            'value': string
        }>,
        items: { [key: string]: Array<Comparisonitem> } | null;
        wasInitiallyLoaded?: boolean;

        isPlayerSearching: boolean;
        playerSearchResult: Array<Comparisonitem> | [],
        changeItems: { [key: string]: boolean }
    };
    xtvBenchmarks: Array<TransferBenchmark>;
    transferHistory: Array<PlayerTransfer>;
    previewData: CustomPitchPreviewInfo;
    headToHead: {
        draft: {
            info: HeadToHeadInfo,
            chartData: any[]
        }
        info: HeadToHeadInfo,
        chartData: any[]
    };
    isPreviewDataLoading: boolean;
    isProcessing: boolean;
}

export const createPitchSteps = {
    player: 0,
    recipient: 1,
    budget: 2,
    message: 3,
    aboutAgency: 4,
    comparison: 5,
    video: 6,
    headToHead: 7,
    preview: 8
}


const defaultState: AgencyCustomPitchState = {
    isLoading: false,
    entity: {
        playerId: null,
        fee: null,
        loanFee: null,
        salary: null,
        financialsTBD: null,
        recipientsSquadId: null,
        message: '',
        messageHeadline: '',
        video: '',
        comparisonKeys: null,
        head2HeadPlayerId: null,
        aboutAgency: '',

        isMessageVisibleOnPitch: true,
        isVideoVisibleOnPitch: true,
        isComparisonKeysVisibleOnPitch: true,
        isHead2HeadVisibleOnPitch: true,
        isTransferHistoryVisibleOnPitch: true,
        isXtvBenchmarksVisibleOnPitch: true,
        isAboutAgencyVisibleOnPitch: true,
    },
    currentStep: null,
    players: [],
    isLoadingPlayers: false,
    recipientSquads: [],
    isLoadingRecipients: false,
    isLoadingComparison: false,
    isProcessing: false,
    comparisonCharacteristicSource: {
        keys: { firstKey: '', secondKey: '', thirdKey: '', },
        characteristicSource: [],
        items: null,
        wasInitiallyLoaded: false,
        isPlayerSearching: false,
        playerSearchResult: [],
        changeItems: {}
    },
    xtvBenchmarks: null,
    transferHistory: null,
    previewData: null,
    isPreviewDataLoading: false,
    headToHead: {
        draft: {
            info: null,
            chartData: null
        },
        info: null,
        chartData: null
    },
}

const stateController = new StateController<AgencyCustomPitchState>("AGENCY/CUSTOM_PITCH/CREATE", defaultState);


class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static getPreviewInfo() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { playerId, recipientsSquadId } = getState().agency.customPitch.createPitch.entity;
                dispatch(stateController.setState({ isPreviewDataLoading: true }))
                const previewData = await AgencyCustomPitchService.getPitchPreviewData(playerId, recipientsSquadId);
                dispatch(stateController.setState({ previewData: previewData }))
            } finally {
                dispatch(stateController.setState({ isPreviewDataLoading: false }))
            }
        }
    }

    public static getPlayers() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoadingPlayers: true }))
                const players = await AgencyCustomPitchService.getPlayers();
                dispatch(stateController.setState({ players }))
            } finally {
                dispatch(stateController.setState({ isLoadingPlayers: false }))
            }
        }
    }

    public static loadPlayersAdditional() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))
                const playerId = Selectors.getSession(getState()).playerId
                const additionalInfo = await AgencyCustomPitchService.playerAdditionalInfo(playerId);
                const { transferBenchmarks, transferHistory, aboutAgency, message, messageHeadline, video } = additionalInfo;

                const substate = getState().agency.customPitch.createPitch;
                dispatch(stateController.setState({
                    ...substate,
                    xtvBenchmarks: transferBenchmarks,
                    transferHistory: transferHistory,
                    entity: {
                        ...substate.entity,
                        aboutAgency: aboutAgency ?? '',
                        messageHeadline: messageHeadline ?? '',
                        message: message ?? '',
                        video: video,
                        isXtvBenchmarksVisibleOnPitch: transferBenchmarks.length > 0,
                        isTransferHistoryVisibleOnPitch: transferHistory.length > 0
                    }
                }));
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static getClubs() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoadingRecipients: true }));
                const entity = Selectors.getSession(getState());
                const recipientSquads = await AgencyCustomPitchService.getClubs(entity?.playerId);
                dispatch(stateController.setState({ recipientSquads }));
            } finally {
                dispatch(stateController.setState({ isLoadingRecipients: false }));
            }
        }
    }

    public static loadComparisonData() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const prevState = Selectors.getRoot(getState());
                if (prevState.comparisonCharacteristicSource.items == null) {

                    dispatch(stateController.setState({ isLoadingComparison: true }));
                    const entity = Selectors.getSession(getState());
                    const payload = new CustomPitchComparisonRequest();
                    if (entity.recipientsSquadId) {
                        payload.squadId = entity.recipientsSquadId;
                    }
                    const data = await AgencyCustomPitchService.loadComparisonData(entity?.playerId, payload);
                    dispatch(stateController.setState({
                        comparisonCharacteristicSource: {
                            ...prevState.comparisonCharacteristicSource,
                            keys: {
                                firstKey: data.firstKey,
                                secondKey: data.secondKey,
                                thirdKey: data.thirdKey,
                            },
                            characteristicSource: data.characteristicSource,
                            items: data.comparisonLists,
                            wasInitiallyLoaded: true,
                            isPlayerSearching: false,
                            playerSearchResult: [],
                        }
                    }));
                }
            } finally {
                dispatch(stateController.setState({ isLoadingComparison: false }));
            }
        }
    }

    public static loadComparisonDataOnSelect(keys: Array<string>) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoadingComparison: true }));
                const entity = Selectors.getSession(getState());
                const prevKeys = Selectors.getRoot(getState()).comparisonCharacteristicSource.keys;
                const payload = new CustomPitchComparisonRequest();
                payload.keys = keys;
                if (entity.recipientsSquadId) {
                    payload.squadId = entity.recipientsSquadId;
                }
                const data = await AgencyCustomPitchService.loadComparisonDataOnSelect(entity?.playerId, payload);
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    comparisonCharacteristicSource: {
                        ...draftState.comparisonCharacteristicSource,
                        keys: {
                            firstKey: keys[0],
                            secondKey: keys[1],
                            thirdKey: keys[2]
                        },
                        items: data.items,
                    }
                })));

                const { playerId, recipientsSquadId } = Selectors.getSession(getState());
                for (var i = 0; i < 3; i++) {
                    const newKey = keys[i];

                    let prevKey;
                    switch (i) {
                        case 0:
                            prevKey = prevKeys.firstKey;
                            break;
                        case 1:
                            prevKey = prevKeys.secondKey;
                            break;
                        case 2:
                            prevKey = prevKeys.thirdKey;
                            break;
                    }

                    if (newKey === prevKey)
                        continue;

                    dispatch(userActivityInsert({
                        PageName: 'Custom Pitch [Creation]',
                        Message: `Signature skills: Changed '${prevKey}' to '${newKey}'`,
                        PlayerId: playerId,
                        ClubId: recipientsSquadId,
                        PageType: PageType.Pitch
                    }))
                }
            } finally {
                dispatch(stateController.setState({ isLoadingComparison: false }));
            }
        }
    }

    public static comparisonSearchSuggestedPlayer = (mainPlayerId: number, characteristicKey: string, searchKey: string) => {
        return async (dispatch, getState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparisonCharacteristicSource: {
                        ...prevState.comparisonCharacteristicSource,
                        isPlayerSearching: true
                    }
                })))
                const data = await AgencyCustomPitchService.comparisonSearchSuggestedPlayers(mainPlayerId, characteristicKey, searchKey)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparisonCharacteristicSource: {
                        ...prevState.comparisonCharacteristicSource,
                        playerSearchResult: data
                    }
                })))

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparisonCharacteristicSource: {
                        ...prevState.comparisonCharacteristicSource,
                        isPlayerSearching: false
                    }
                })))
            }
        }
    }

    public static onApplyComparisonPlayer = (previousPlayerId: number, nextPlayer: Comparisonitem, metricKey: string) => {
        return (dispatch, getState: () => AppState) => {
            let appState = getState();

            let { comparisonCharacteristicSource } = Selectors.getRoot(appState);

            let items = comparisonCharacteristicSource.items[metricKey];
            let mappedItems = items.map(
                (x) => {
                    if (x.playerId == previousPlayerId) return nextPlayer;
                    else return x;
                }
            )
            let newDict = { ...comparisonCharacteristicSource.items };

            mappedItems.sort((x, y) => y.originalValue - x.originalValue);
            mappedItems.forEach((item, index) => {
                item.position = index + 1;
                item.positionFormatted = `0${item.position}`;
            })
            newDict[metricKey] = [...mappedItems];

            let changedPlayers = comparisonCharacteristicSource.changeItems;

            changedPlayers[metricKey] = true;

            dispatch(stateController.setState({
                comparisonCharacteristicSource: { ...comparisonCharacteristicSource, items: newDict, changeItems: changedPlayers }
            }))
        }
    }

    public static disposePlayerSearch = () => (dispatch, getState: AppState) => {
        dispatch(stateController.setState(prevState => ({
            ...prevState,
            comparisonCharacteristicSource: {
                ...prevState.comparisonCharacteristicSource,
                playerSearchResult: []
            }
        })))
    }

    public static publishPitch() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }))

                const state = getState();
                const { currentStep, comparisonCharacteristicSource } = Selectors.getRoot(state);
                const entity = Selectors.getSession(state);
                const selectedPlayer = Selectors.getSelectedPlayer(state);
                const selectedClub = Selectors.getSelectedClub(state);


                let customizationComparison: { [key: string]: Array<{ comparisonPlayerIds: Array<number> }> } = {};

                const { keys } = comparisonCharacteristicSource;
                if (comparisonCharacteristicSource.changeItems[keys.firstKey] == true) {
                    customizationComparison[keys.firstKey] = { comparisonPlayerIds: comparisonCharacteristicSource.items[keys.firstKey].map(x => x.playerId) } as any;
                }
                if (comparisonCharacteristicSource.changeItems[keys.secondKey] == true) {
                    customizationComparison[keys.secondKey] = { comparisonPlayerIds: comparisonCharacteristicSource.items[keys.secondKey].map(x => x.playerId) } as any;
                }
                if (comparisonCharacteristicSource.changeItems[keys.thirdKey] == true) {
                    customizationComparison[keys.thirdKey] = { comparisonPlayerIds: comparisonCharacteristicSource.items[keys.thirdKey].map(x => x.playerId) } as any;
                }

                const data: CreateCustomPitchRequest = {
                    playerId: entity.playerId,
                    fee: entity.fee,
                    loanFee: entity.loanFee,
                    salary: entity.salary,
                    financialsTBD: entity.financialsTBD,
                    recipientsSquadId: entity.recipientsSquadId,
                    message: entity.message,
                    messageHeadline: entity.messageHeadline,
                    video: entity.video,
                    comparisonKeys: entity.comparisonKeys,
                    head2HeadPlayerId: entity.head2HeadPlayerId,
                    aboutAgency: entity.aboutAgency,
                    isMessageVisibleOnPitch: entity.isMessageVisibleOnPitch,
                    isVideoVisibleOnPitch: entity.isVideoVisibleOnPitch,
                    isComparisonKeysVisibleOnPitch: entity.isComparisonKeysVisibleOnPitch,
                    isHead2HeadVisibleOnPitch: entity.isHead2HeadVisibleOnPitch,
                    isTransferHistoryVisibleOnPitch: entity.isTransferHistoryVisibleOnPitch,
                    isXtvBenchmarksVisibleOnPitch: entity.isXtvBenchmarksVisibleOnPitch,
                    isAboutAgencyVisibleOnPitch: entity.isAboutAgencyVisibleOnPitch,
                    comparisonCustomization: customizationComparison
                }

                const publishedUid = await AgencyCustomPitchService.create(data);

                dispatch(CommonController.Actions.loadCustomPitchInfo());
                dispatch(CustomPitchController.Actions.initSource());
                dispatch(CustomPitchController.Actions.toggleState(CustomPitchController.CustomPitchMode.PitchView));
                dispatch(stateController.setState({ currentStep: null }))

                dispatch(stateController.setState({
                    entity: defaultState.entity,
                    xtvBenchmarks: [],
                    transferHistory: [],
                    headToHead: defaultState.headToHead
                }))

                const { playerId, recipientsSquadId } = entity;
                dispatch(userActivityInsert({
                    PageName: currentStep === createPitchSteps.preview ? 'Custom Pitch [Preview]' : 'Custom Pitch [Creation]',
                    Message: `Published: '${selectedPlayer.name}', '${selectedClub.squad.name}'`,
                    PlayerId: playerId,
                    ClubId: recipientsSquadId,
                    PageType: PageType.Pitch,
                }))

                window.open(`${anonymousPaths.customPitchPublished.replace(":id", publishedUid)}?showModal=true`, '_blank')
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static previewPitch() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.preview }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Preview',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closePreviewPitch() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Preview]',
                Message: 'Moved Back to Pitch Creation',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static openPlayerModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.player }))

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Player Selection',
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closePlayerModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: null }))

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Cancelled Player Selection',
                PageType: PageType.Pitch,
            }))
        }
    }

    public static selectPlayer(playerId: number) {
        return async (dispatch) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: {
                    ...defaultState.entity,
                    playerId
                },
                comparisonCharacteristicSource: defaultState.comparisonCharacteristicSource,
                headToHead: defaultState.headToHead,
                xtvBenchmarks: defaultState.xtvBenchmarks,
                transferHistory: defaultState.transferHistory,
            })));

            dispatch(stateController.setState({ currentStep: null }))
            //dispatch(Actions.loadPlayersAdditional())
            dispatch(Actions.openRecipientModal())
        }
    }

    public static selectPlayerWithTracking(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.selectPlayer(playerId));

            const selectedPlayer = Selectors.getSelectedPlayer(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Selected Player '${selectedPlayer.name}'`,
                PlayerId: playerId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static openRecipientModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.recipient }));

            const { playerId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Recipient Selection',
                PlayerId: playerId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeRecipientModal(skipTrack: boolean = false) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }));

            if (!skipTrack) {
                const { playerId } = Selectors.getSession(getState());
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Creation]',
                    Message: 'Cancelled Recipient Selection',
                    PlayerId: playerId,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static selectRecipient(recipientsSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: {
                    ...draftState.entity,
                    recipientsSquadId
                },
                comparisonCharacteristicSource: defaultState.comparisonCharacteristicSource,
                headToHead: defaultState.headToHead,
                xtvBenchmarks: defaultState.xtvBenchmarks,
                transferHistory: defaultState.transferHistory,
            })))

            const { playerId } = Selectors.getSession(getState());
            const { squad } = Selectors.getSelectedClub(getState());

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Selected Recipient Club '${squad.name}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch
            }))

            await dispatch(Actions.openBudgetSelector())
            //await dispatch(Actions.prefillComparisonData(playerId))
            //await dispatch(Actions.prefillHeadToHeadInfo(playerId, recipientsSquadId))            
        }
    }

    public static openBudgetSelector() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.budget }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Budget Selection',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeBudgetSelector() {
        return (dispatch, getState: () => AppState) => {
            const session = Selectors.getSession(getState());

            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Cancelled Budget Selection',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setBudget(fee: number | null, salary: number | null, financialsTBD: boolean | null, loanFee: number | null) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: {
                    ...draftState.entity,
                    fee,
                    salary,
                    financialsTBD,
                    loanFee
                }
            })))
            dispatch(stateController.setState({ currentStep: null }));

            const currency = getAuthCurrency(getState());
            const currencySymbol = currencyById[currency.id];
            const { playerId, recipientsSquadId } = Selectors.getSession(getState());

            const toBuyFeeMessagePart = fee !== null && fee !== undefined ? `Transfer Fee '${currencySymbol + toKMB(fee)}'` : financialsTBD ? 'Transfer Fee TBD' : '';
            const toBuySalaryMessagePart = salary !== null && salary !== undefined ? `Gross salary ' ${currencySymbol}${toKMB(salary)}'` : '';
            const toLoanMessagePart = loanFee !== null && loanFee !== undefined ? `Loan Fee '${currencySymbol}${toKMB(loanFee)}'` : '';
            const message = `Saved Budget: ${[toBuyFeeMessagePart, toBuySalaryMessagePart, toLoanMessagePart].filter(x => x.length).join(', ')}`;

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: message,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))

            dispatch(Actions.loadPlayersAdditional())            
            dispatch(Actions.prefillComparisonData(playerId))
            dispatch(Actions.prefillHeadToHeadInfo(playerId, recipientsSquadId)) 
        }
    }

    public static openMessageSelector() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.message }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Pitch Message',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeMessageSelector() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Cancelled Pitch Message',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setMessage(messageHeadline: string, message: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: { ...draftState.entity, messageHeadline, message }
            })))
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Saved Pitch message: Headline: '${messageHeadline}', Body: '${message}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setMessageVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isMessageVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed Pitch Message' : 'Hided Pitch Message',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static openAboutAgencyModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.aboutAgency }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened about Agency',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeAboutAgencyModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Cancelled about agency',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setAboutMessage(aboutAgency: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: { ...draftState.entity, aboutAgency }
            })))
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Saved about Agency: '${aboutAgency}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setAboutMessageVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isAboutAgencyVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed about Agency' : 'Hided about Agency',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static openComparisonSelector() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.comparison }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Signature Skills',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeComparisonSelector(skipTrack: boolean = false) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))

            if (!skipTrack) {
                const { playerId, recipientsSquadId } = Selectors.getSession(getState());
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Creation]',
                    Message: 'Cancelled Signature Skills',
                    PlayerId: playerId,
                    ClubId: recipientsSquadId,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static setComparison(isComparisonKeysVisibleOnPitch: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: {
                    ...draftState.entity,
                    isComparisonKeysVisibleOnPitch,
                    comparisonKeys: [
                        draftState.comparisonCharacteristicSource.keys.firstKey,
                        draftState.comparisonCharacteristicSource.keys.secondKey,
                        draftState.comparisonCharacteristicSource.keys.thirdKey,
                    ]
                }
            })))
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Saved Signature Skills',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setComparisonVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isComparisonKeysVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed Signature Skills' : 'Hided Signature Skills',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static openVideoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: createPitchSteps.video }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Opened Video Selection',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeVideoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Cancelled Video Selection',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static selectVideo(video: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({ ...draftState, entity: { ...draftState.entity, video } })));
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Saved video '${video}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setVideoVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isVideoVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed Video' : 'Hided Video',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setXtvBenchmarksVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isXtvBenchmarksVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed xTV Benchmarks' : 'Hided xTV Benchmarks',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setTransferHistoryVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isTransferHistoryVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed Transfer History' : 'Hided Transfer History',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    private static setValues(values: any) {
        return (dispatch) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: { ...draftState.entity, ...values }
            })))
        }
    }

    public static closeHeadToHeadSelector() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))
            dispatch(AutosuggestController.Actions.dispose())

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            const selectedPlayer = Selectors.getSelectedPlayer(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Cancelled head-to-head with '${selectedPlayer.name}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch
            }))
        }
    }

    public static openHeadToHeadSelector() {
        return (dispatch, getState: () => AppState) => {
            const { headToHead } = Selectors.getRoot(getState());
            dispatch(stateController.setState({
                currentStep: createPitchSteps.headToHead,
                headToHead: {
                    ...headToHead,
                    draft: {
                        info: headToHead.info,
                        chartData: headToHead.chartData,
                    }
                }
            }))

            const { info } = headToHead;
            const secondPlayerId = info?.secondPlayer?.playerId;
            const keyword = info?.secondPlayer?.englishShortName ?? formatAverage(info.firstPlayer, info) ?? '';
            dispatch(AutosuggestController.Actions.setKeyword(keyword, secondPlayerId))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            const selectedPlayer = Selectors.getSelectedPlayer(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Opened head-to-head with '${selectedPlayer.name}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch
            }))
        }
    }

    public static setHeadToHeadPlayer(headToHeadPlayerId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                headToHead: {
                    ...draftState.headToHead,
                    info: draftState.headToHead.draft.info,
                    chartData: draftState.headToHead.draft.chartData,
                },
                entity: {
                    ...draftState.entity,
                    head2HeadPlayerId: headToHeadPlayerId
                }
            })))
            dispatch(stateController.setState({ currentStep: null }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            const selectedPlayer = Selectors.getSelectedPlayer(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Saved head-to-head with '${selectedPlayer.name}'`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setHeadToHeadVisibility(isVisible: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setValues({
                isHead2HeadVisibleOnPitch: isVisible
            }))

            const { playerId, recipientsSquadId } = Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: isVisible ? 'Revealed Head-to-head' : 'Hided Head-to-head',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static loadHeadToHeadInfo(head2HeadPlayerId: number | null, isDraft: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const session = Selectors.getSession(getState());
            const { playerId } = session;
            const headToHeadInfo = await AgencyCustomPitchService.getHeadToHeadInfo(playerId, head2HeadPlayerId, null);

            const { firstPlayer, secondPlayer } = headToHeadInfo;

            const args = {
                firstPlayer: firstPlayer,
                secondPlayer: secondPlayer,
                thirdPlayer: null,
                comparison: headToHeadInfo
            }
            const chartData = getDataForChart(args);

            if (isDraft) {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    headToHead: {
                        ...prevState.headToHead,
                        draft: {
                            info: headToHeadInfo,
                            chartData: chartData
                        }
                    }
                })))
            } else {
                dispatch(stateController.setState({
                    headToHead: {
                        info: headToHeadInfo,
                        chartData,
                        draft: {
                            info: headToHeadInfo,
                            chartData: chartData
                        }
                    }
                }))
            }
        }
    }

    public static prefillHeadToHeadInfo(playerId: number, recipientsSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            const headToHeadPlayerId = await AgencyCustomPitchService.getSuggestedHeadToHeadPlayer(playerId, recipientsSquadId);
            await dispatch(Actions.loadHeadToHeadInfo(headToHeadPlayerId, false));
            const { headToHead, entity } = Selectors.getRoot(getState());
            dispatch(stateController.setState({
                entity: {
                    ...entity,
                    head2HeadPlayerId: headToHeadPlayerId,
                    isHead2HeadVisibleOnPitch: headToHead.chartData.length > 0
                }
            }));
        }
    }

    public static prefillComparisonData(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.loadComparisonData())

            const { comparisonCharacteristicSource } = Selectors.getRoot(getState());
            const keys = Object.keys(comparisonCharacteristicSource.items);
            const values = Object.values(comparisonCharacteristicSource.items).flat();

            const comparisonKeys = values.some(x => x.playerId !== playerId) ? keys.slice(0, 3) : [];
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                entity: {
                    ...draftState.entity,
                    comparisonKeys,
                    isComparisonKeysVisibleOnPitch: comparisonKeys.length
                }
            })))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.customPitch.createPitch
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep
    public static isLoadingPlayers = (state: AppState) => Selectors.getRoot(state).isLoading
    public static getSession = (state: AppState) => Selectors.getRoot(state).entity
    public static getPlayers = (state: AppState): Array<VerifiedPlayer> => Selectors.getRoot(state).players
    public static getClubs = (state: AppState): Array<SquadToPitch> => Selectors.getRoot(state).recipientSquads
    public static getHeadToHeadData = (state: AppState) => Selectors.getRoot(state).headToHead

    public static isPlayerLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoadingPlayers
    public static isLoadingRecipients = (state: AppState): boolean => Selectors.getRoot(state).isLoadingRecipients

    public static isPlayerModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.player
    public static isRecipientModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.recipient
    public static isBudgetModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.budget
    public static isMessageModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.message
    public static isAboutAgencyModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.aboutAgency
    public static isComparisonModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.comparison
    public static isVideoModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.video
    public static isHeadToHeadModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.headToHead
    public static isPreviewModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === createPitchSteps.preview

    public static getSelectedPlayer = createSelector([Selectors.getSession, Selectors.getPlayers],
        (entity: AgencyPlusPitchCreteSession, players: Array<VerifiedPlayer>) => players.find(i => i.id == entity.playerId))
    public static getSelectedClub = createSelector([Selectors.getSession, Selectors.getClubs],
        (entity: AgencyPlusPitchCreteSession, squads: Array<SquadToPitch>) => squads.find(i => i.squad.id == entity?.recipientsSquadId))

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyCustomPitchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



