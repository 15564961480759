import { combineReducers } from 'redux';
import clubPlayersInfo from './club-players.reducer';
import searchClub from './search-bar.reducer'
import biggestTransfers from './biggest-transfers.reducer'
import playerValuations from './player-valuations.reducer';
import clubIndicators from './club-indicators.reducer';
import transferOverview from './transfer-overview';
import incomingPlayers from './incoming-players.reducer';
import outgoingPlayers from './outgoing-players.reducer';
import spendingPower from './spending-power.reducer';

export default combineReducers({
    searchClub,
    clubPlayersInfo,
    clubIndicators,
    transferOverview,
    biggestTransfers,
    playerValuations,
    incomingPlayers,
    outgoingPlayers,
    spendingPower
});
