import React from 'react'
import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import londonSummitImage from 'media/images/events/2023-march-london/london-summit.jpg';
import Replay from 'app/events/components/authorized-event-page/common/video-replay-new/replay'
import ThumbLondon from 'media/images/events/2023-march-london/london-summit-thumb.jpg';

const LondonSummitMarch2023 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="8"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom"
                        title2="Summit"
                        date="20-22nd MAR 2023, London + Online"
                        vs={true}
                    />
                    <Replay
                        compactMode
                        trackUserActivity={() => {
                            // this.props.userActivityInsert({
                            //     PageName: 'Event',
                            //     Message: 'Opened video (6th TransferRoom Summit)',
                            //     EventId: eventId
                            // })
                        }}
                        content={{
                            videoUrl: 'https://player.vimeo.com/video/891756655',
                            backgroundThumb: ThumbLondon,
                            heading1: '',
                            heading2: '',
                            highlightsText: '',
                            speakers: []
                        }}
                    />
                    {/* <div className="photo-container row" style={{ padding: '0 0%', borderRadius: '15px', overflow: 'hidden' }}>
                        <img style={{ width: '100%' }} src={londonSummitImage} alt="" />
                    </div> */}
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="615"
                        countries="48"
                        leagues="77"
                        agents="66"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

export const previousEventAttendees = [
    { id: 1, name: "AC Milan", logo: "logo_1834_AC_Milan.png" },
    { id: 416, name: "Al-Fateh", logo: "16483_Al-Fateh.png" },
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 49, name: "Arsenal", logo: "logo_1121_Arsenal_FC.png" },
    { id: 38553, name: "Aston Villa", logo: "logo_1138_Aston_Villa.png" },
    { id: 28879, name: "Athletic", logo: "678_Athletic_Bilbao.png" },
    { id: 30849, name: "Atlanta", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 23647, name: "San Luis", logo: "25866_Atlético_de_San_Luis.png" },
    { id: 6249, name: "B. Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 8239, name: "Brentford", logo: "logo_1153_Brentford_FC.png" },
    { id: 70, name: "Brighton", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 42798, name: "Cavalry FC", logo: "64336_Cavalry_FC.png" },
    { id: 50, name: "Chelsea", logo: "logo_Chelsea_FC.png" },
    { id: 29065, name: "Sport. Cristal", logo: "15532_Club_Sporting_Cristal.png" },
    { id: 27251, name: "Flamengo", logo: "5654_Clube_de_Regatas_do_Flamengo.png" },
    { id: 32429, name: "E. Frankfurt", logo: "logo_1268_Eintracht_Frankfurt.png" },
    { id: 63, name: "Everton", logo: "logo_1124_Everton_FC.png" },
    { id: 18960, name: "FC Porto", logo: "logo_1672_FC_Porto.png" },
    { id: 13058, name: "Feyenoord", logo: "logo_1533_Feyenoord_Rotterdam.png" },
    { id: 1421, name: "Gremio", logo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png" },
    { id: 8886, name: "Jeonbuk Hyundai", logo: "logo_2425_Jeonbuk_Hyundai_Motors_FC.png" },
    { id: 3, name: "Juventus", logo: "logo_1832_Juventus_FC.png" },
    { id: 39176, name: "Leeds", logo: "logo_1150_Leeds_United.png" },
    { id: 66, name: "Leicester", logo: "logo_Leicester_City.png" },
    { id: 46, name: "Liverpool", logo: "logo_1123_Liverpool_FC.png" },
    { id: 10026, name: "Los Angeles", logo: "7847_Los_Angeles_Galaxy.png" },
    { id: 37475, name: "Sundowns", logo: "16344_Mamelodi_Sundowns_FC.png" },
    { id: 48, name: "Man City", logo: "1625_Manchester_City.png" },
    { id: 25037, name: "Mvd City Torque", logo: "23497_Montevideo_City_Torque.png" },
    { id: 60, name: "Newcastle", logo: "logo_Newcastle_United.png" },
    { id: 16046, name: "Olympique Lyon", logo: "logo_1966_Olympique_Lyon.png" },
    { id: 12294, name: "Marseille", logo: "logo_1964_Olympique_Marseille.png" },
    { id: 17788, name: "Paris SG", logo: "logo_Paris_Saint-Germain.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 35839, name: "Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 62, name: "Southampton", logo: "1619_Southampton_FC.png" },
    { id: 23352, name: "Rennes", logo: "logo_Stade_Rennes.png" },
    { id: 47, name: "Spurs", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 24711, name: "Urawa Reds", logo: "logo_2407_Urawa_Red_Diamonds.png" },
    { id: 10422, name: "Vancouver", logo: "15015_Vancouver_Whitecaps_FC.png" },
    { id: 12470, name: "VfL Wolfsburg", logo: "logo_VfL_Wolfsburg.png" },
    { id: 67, name: "West Ham", logo: "1633_West_Ham_United.png" }
]

export default LondonSummitMarch2023