import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyPlayerService from 'api/agency/agent/landing/agency-homepage.service'
import { PlayerVerificationExtended, PlusPitchSessionTypeEnum } from 'api/agency/agent/landing/agency-dashboard'
import AgencyPlusPitchService from 'api/agency/plus-pitch/agency-plus-pitch.service'
import { CompetitionWithActiveSquadsModel, PlusPitchSend, SquadWithRecommendationModel } from 'api/agency/plus-pitch/models'
import * as MessageSend from 'components/send-message-modal/send-message-modal.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAuthCurrency, isPitchCoachAccess } from 'store/auth/authReducer';
import * as CommonController from 'pages/agency/authorized-agent/pitch-page/redux/common.controller';
import moment from 'moment';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { parseAndTranslate, translate, translateNotification } from 'services/localization';
import { ActionType, PageType } from 'constants/enums';
import { HeadCoachesVerification } from 'api/agency/agent/head-coaches/head-coaches.models'
import AgencyHeadCoachesService from 'api/agency/agent/head-coaches/head-coaches.service';
import *as FindHeadCoachController from "pages/landing-page/redux/find-coach-popup.controller"
import *as PlusPitchSearchController from "pages/agency/authorized-agent/pitch-page/redux/agency-plus-pitch-search.controller"
import { CreateAgencyPlusPitchRequest, GetAvailableSquadsRequest } from 'api/agency/plus-pitch/models'

export enum CreatePlusPitchSteps {
    None = 0,
    SelectPersonStep = 1,
    SetPriceStep = 2,
    SelectRecipientsStep = 3,
    AddMessageStep = 4,
}

class AgencyPlusPitchState {
    isLoading: boolean;
    isSaving: boolean;
    isLoadingSquads: boolean;
    isLoadingPlayers: boolean;
    playerSource: Array<PlayerVerificationExtended>;
    currentSession: AgencyPlusPitchCretePlayerSession;
    currentStep: CreatePlusPitchSteps;
    squads: Array<CompetitionWithActiveSquadsModel>;
    squadsWithRecommender: Array<CompetitionWithActiveSquadsModel>;
    isSessionCompleted: boolean;
    plusPitches: Array<PlusPitchSend>;
    testInterestModalPitchId: number;
    testingInterestModalSelectedSquadIds: Array<number>;
    testingInterestPitchSquadIds: Array<number>;
    currentSessionType: PlusPitchSessionTypeEnum;

    currentCoachSession: AgencyPlusPitchCreteCoachSession;
    headCoachSource: Array<HeadCoachesVerification>;
    keyword: string;
    filteredHeadCoachesList: Array<HeadCoachesVerification>;
    isLoadingHeadCoaches: boolean;
    contractExpiryModal: ContractExpiryModal;

}

interface AgencyPlusPitchCretePlayerSession {
    playerId: number;
    squadIdSet: Array<SquadWithRecommendationModel>;
    grossSalaryPerYear: number;
    askingPrice?: number;
    monthlyGrossSalary: number;
    message?: string;
}
interface AgencyPlusPitchCreteCoachSession {
    headCoachId: number | null;
    squadIdSet: Array<SquadWithRecommendationModel>
    grossSalaryPerYear: number;
    currentEmploymentStatus: string;
    contractExpiry: Date;
    message?: string;
}

interface ContractExpiryModal {
    coachId: number;
    contractExpiry: Date;
    plusYearsCount: number;
    processing: boolean;
}

const defaultState: AgencyPlusPitchState = {
    currentStep: CreatePlusPitchSteps.None,
    isLoading: false,
    currentSession: null,
    playerSource: [],
    squads: [],
    squadsWithRecommender: [],
    isSessionCompleted: false,
    isLoadingSquads: false,
    isLoadingPlayers: false,
    isSaving: false,
    plusPitches: [],
    testInterestModalPitchId: null,
    testingInterestModalSelectedSquadIds: [],
    testingInterestPitchSquadIds: [],

    headCoachSource: [],
    currentCoachSession: null,
    currentSessionType: PlusPitchSessionTypeEnum.Player,
    keyword: '',
    filteredHeadCoachesList: [],
    contractExpiryModal: null,
    isLoadingHeadCoaches: false,
    

}

const stateController = new StateController<AgencyPlusPitchState>(
    "AGENCY/PLUS_PITCH",
    defaultState
);

class SendPlusPitchSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private squadPitchId: number;
    private plusPitchId: number;
    private playerId: number | null;
    private squadId: number;
    private coachId: number | null;

    constructor(dispatch, plusPitchId: number, squadPitchId: number, playerId: number, squadId: number, coachId: number) {
        this.dispatch = dispatch;
        this.plusPitchId = plusPitchId;
        this.squadPitchId = squadPitchId;
        this.playerId = playerId;
        this.squadId = squadId;
        this.coachId = coachId;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {
        await AgencyPlusPitchService.sendMessage({
            plusPitchId: this.plusPitchId,
            plusPitchSquadId: this.squadPitchId,
            subject: session.subject,
            message: message
        });

        this.dispatch(this.sendNotification(this.plusPitchId, this.squadPitchId));

        this.dispatch(userActivityInsert({
            PageName: 'Plus Pitch [Drop-down]',
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            CoachId: this.coachId,
            ClubId: this.squadId,
            PageType: PageType.Pitch
        }))
    }

    public cancelMessage() {
        this.dispatch(userActivityInsert({
            PageName: 'Plus Pitch [Drop-down]',
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            CoachId: this.coachId,
            ClubId: this.squadId,
            PageType: PageType.Pitch
        }))
    }

    public sendNotification(pitchId: number, plusPitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let pitch = getState().agency.plusPitch.plusPitches.find(x => x.plusPitchId == pitchId);
            let receiverSquad = pitch.squads.find(x => x.id === plusPitchSquadId);
            let successMessage = `[%notifications.yourMessageTo%] <b>${receiverSquad.squad.name}</b> [%notifications.hasBeenSent%]`;
            dispatch(notificationCreate({ message: parseAndTranslate(successMessage), level: 'success' }));
        }
    }
}


class Actions {
    public static toggleTestInterestModal(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ testInterestModalPitchId: pitchId }));
        }
    }

    public static openTestInterestModal(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            if (pitchId) {
                const pitch = getState().agency.plusPitch.plusPitches.find(x => x.plusPitchId == pitchId);
                const isPlayerPitch = pitch.pitchedClubStaff === null;
                const playerId = isPlayerPitch ? pitch.pitchedPlayer.id : null
                const coachId = !isPlayerPitch ? pitch.pitchedClubStaff.id : null

                if (pitch) {
                    dispatch(userActivityInsert({
                        PageName: 'Plus Pitch',
                        Message: 'Opened Test Box',
                        PlayerId: playerId,
                        CoachId: coachId,
                        PageType: PageType.Pitch
                    }))
                }
            }

            dispatch(stateController.setState({ testingInterestModalSelectedSquadIds: [] }));
            dispatch(Actions.toggleTestInterestModal(pitchId));
        }
    }

    public static testInterest(pitchId: number, pitchSquadIds: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.plusPitch;
            dispatch(stateController.setState({
                testingInterestPitchSquadIds: [...substate.testingInterestPitchSquadIds, ...pitchSquadIds]
            }));
            try {
                await AgencyPlusPitchService.testInterest({ pitchId: pitchId, pitchSquadIds: pitchSquadIds });
                let newPitches = [...substate.plusPitches];
                let currentPitch = newPitches.find(x => x.plusPitchId == pitchId);
                let currentSquads = currentPitch.squads.filter(x => pitchSquadIds.some(y => y == x.id));
                currentSquads.forEach(element => {
                    element.canTestInterest = false;
                    element.interestTestedAt = moment.utc().format();
                    element.status = 3;
                });

                dispatch(stateController.setState({
                    plusPitches: newPitches,
                    testInterestModalPitchId: null,
                    testingInterestModalSelectedSquadIds: [],
                }));
            } catch (e) {
                console.error(e);
                dispatch(
                    notificationCreate({
                        message: translate('notifications.somethingWentWrong'),
                        level: 'error',
                    }),
                )
            } finally {
                dispatch(stateController.setState({
                    testingInterestPitchSquadIds: substate.testingInterestPitchSquadIds.filter(x => !pitchSquadIds.some(y => y == x))
                }));
            }
        }
    }

    public static testInterestList(pitchId: number, pitchSquadIds: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.testInterest(pitchId, pitchSquadIds))

            const pitch = getState().agency.plusPitch.plusPitches.find(x => x.plusPitchId == pitchId);
            const playerId = pitch.pitchedPlayer !== null ? pitch.pitchedPlayer.id : null;
            const coachId = pitch.pitchedClubStaff !== null ? pitch.pitchedClubStaff.id : null;
            
            if (pitch) {
                const squads = pitch.squads.filter(x => pitchSquadIds.includes(x.id)).map(x => x.squad.name).join(', ')
                dispatch(userActivityInsert({
                    PageName: 'Plus Pitch [Test List]',
                    Message: `Tested Interest: ${squads}`,
                    PlayerId: playerId,
                    CoachId: coachId,
                    PageType: PageType.Pitch
                }))
            }
        }
    }

    public static selectPitchSquad(id: number, isSelected: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const prevSquadIds = getState().agency.plusPitch.testingInterestModalSelectedSquadIds;
            let squadIds;
            if (isSelected) {
                squadIds = [...prevSquadIds, id]
            } else {
                squadIds = prevSquadIds.filter(x => x !== id)
            }
            dispatch(stateController.setState({
                testingInterestModalSelectedSquadIds: squadIds
            }));
        }
    }

    public static testInterestSingle(pitchId: number, pitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.testInterest(pitchId, [pitchSquadId]))

            const pitch = getState().agency.plusPitch.plusPitches.find(x => x.plusPitchId == pitchId);
            const playerId = pitch.pitchedPlayer !== null ? pitch.pitchedPlayer.id : null;
            const coachId = pitch.pitchedClubStaff !== null ? pitch.pitchedClubStaff.id : null;

            if (pitch) {
                const pitchSquad = pitch.squads.find(x => x.id == pitchSquadId);
                dispatch(userActivityInsert({
                    PageName: 'Plus Pitch [Drop-down]',
                    Message: 'Tested Interest',
                    PlayerId: playerId,
                    CoachId: coachId,
                    ClubId: pitchSquad ? pitchSquad.squad.id : -1,
                    ActionType: ActionType.TestedInterest,
                    PageType: PageType.Pitch
                }))
            }
        }
    }

    public static initPlayerSource() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoadingPlayers: true }))
            let players = await AgencyPlayerService.getPlayers();

            const positions = { GK: 0, LB: 1, RB: 2, CB: 3, DM: 4, CM: 5, AM: 6, W: 7, F: 8 }

            players.players.sort((a, b) => positions[a.firstPositionCode] > positions[b.firstPositionCode] ? 1 : -1)

            dispatch(stateController.setState({
                playerSource: players.players.filter(x => !x.isRegular),
                isLoadingPlayers: false
            }))
        }
    }

    public static initHeadCoachSource() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoadingHeadCoaches: true }))

            const coaches = await AgencyHeadCoachesService.getCoaches();

            dispatch(stateController.setState({
                headCoachSource: coaches.coaches.filter(x => !x.isRegular),
                isLoadingHeadCoaches: false
            }))
        }
    }

    public static initSource() {
        return (dispatch) => {
            dispatch(CommonController.Actions.loadPlusPitchInfo());
            dispatch(Actions.getPlusPitch());
            dispatch(Actions.initPlayerSource());
            dispatch(Actions.initHeadCoachSource());
            dispatch(userActivityInsert({
                PageName: 'Plus Pitch',
                Message: 'Click on Plus Pitch Tab',
                PageType: PageType.Pitch
            }))
        }
    }

    public static getPlusPitch() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }))

            let plusPitches = await AgencyPlusPitchService.getAll();

            dispatch(stateController.setState({ plusPitches, isLoading: false }))
            dispatch(PlusPitchSearchController.Actions.initGrid());
        }
    }

    public static reloadData() {
        return async (dispatch) => {
            let plusPitches = await AgencyPlusPitchService.getAll();
            dispatch(stateController.setState({ plusPitches }))
            dispatch(PlusPitchSearchController.Actions.initGrid());
        }
    }

    public static initSquadSource() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoadingSquads: true }));

            const sessionType = Selectors.getCurrentSessionType(getState())
            const session = Selectors.getCurentSession(getState());
            const coachSession = Selectors.getCurentHeadCoachSession(getState());
            
            let request = {} as GetAvailableSquadsRequest;

            if (sessionType === PlusPitchSessionTypeEnum.Player) {
                request = {
                    playerId: session.playerId,
                    clubStaffId: null,
                    expectedAskingPrice: session.askingPrice,
                    expectedGrossSalaryPerYear: session.grossSalaryPerYear,
                    expectedMonthlyGrosSalary: session.monthlyGrossSalary,
                }
            } else {
                request = {
                    playerId: null,
                    clubStaffId: coachSession.headCoachId,
                    expectedAskingPrice: null,
                    expectedGrossSalaryPerYear: coachSession.grossSalaryPerYear,
                    expectedMonthlyGrosSalary: null,
                }
            }

            const { competitionWithActiveSquads, recommenders } = await AgencyPlusPitchService.getSquads(request);

            let recommender = [{
                areaId: -1,
                areaName: "",
                divisionLevel: -1,
                id: -1,
                name: "",
                nameWithArea: "Recommended Clubs",
                squads: recommenders.map(x => {
                    return {
                        ...x,
                        isRecommended: true,
                    }
                }),
            }];

            dispatch(stateController.setState({
                squads: competitionWithActiveSquads,
                isLoadingSquads: false,
                squadsWithRecommender: [...recommender, ...competitionWithActiveSquads]
            }))
        }
    }

    public static startCreation() {
        return (dispatch) => {
            dispatch(stateController.setState({ currentSession: { playerId: null, squadIdSet: null, grossSalaryPerYear: null, askingPrice: null, monthlyGrossSalary: null } }))
            dispatch(stateController.setState({ currentCoachSession: { headCoachId: null, squadIdSet: null, grossSalaryPerYear: null, currentEmploymentStatus: null, contractExpiry: null } }))
        }
    }

    public static restartCreation() {
        return (dispatch) => {
            dispatch(stateController.setState({ isSessionCompleted: false }));
            dispatch(Actions.startCreation())
        }
    }

    public static openSelectedModal = () => {
        return (dispatch, getState: () => AppState) => {
            const coaches = Selectors.getHeadCoachSource(getState())
            dispatch(stateController.setState({
                currentStep: CreatePlusPitchSteps.SelectPersonStep,
                filteredHeadCoachesList: coaches,
            }))
        }
    }

    public static closeSelectModal = () => {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: CreatePlusPitchSteps.None }))
        }
    }

    public static selectPlayer = (playerId: number) => {
        return (dispatch) => {
            dispatch(stateController.setState((prev) => ({ ...prev, currentSession: { ...prev.currentSession, playerId: playerId } })));
            dispatch(Actions.closeSelectModal())
        }
    }

    public static openSetPriceModal = () => {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: CreatePlusPitchSteps.SetPriceStep }))
        }
    }

    public static closeSetPriceModal = () => {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: CreatePlusPitchSteps.None }))
        }
    }

    public static setPrice(expectedGrossSalary: number, expectedMonthlyPerMonth: number, expectedAskingPrice: number,) {
        return (dispatch) => {
            dispatch(stateController.setState((prev) => ({
                ...prev,
                currentSession: {
                    ...prev.currentSession,
                    grossSalaryPerYear: expectedGrossSalary,
                    askingPrice: expectedAskingPrice,
                    monthlyGrossSalary: expectedMonthlyPerMonth
                }
            }
            )));
            dispatch(Actions.closeSelectModal())
        }
    }

    public static openRecipientsModal = () => {
        return async (dispatch) => {
            dispatch(Actions.initSquadSource())
            dispatch(stateController.setState({ currentStep: CreatePlusPitchSteps.SelectRecipientsStep }))
        }
    }

    public static closeRecipientsModal = () => {
        return (dispatch) => {
            dispatch(stateController.setState({
                currentStep: CreatePlusPitchSteps.None,
            }))
        }
    }

    public static selectClub = (squadIdSet: Array<SquadWithRecommendationModel>) => {
        return (dispatch, getState: () => AppState) => {
            const sessionType = Selectors.getCurrentSessionType(getState())
            if (sessionType === PlusPitchSessionTypeEnum.Player) {
                dispatch(stateController.setState((prev) => ({ ...prev, currentSession: { ...prev.currentSession, squadIdSet: squadIdSet } })));
            } else {
                dispatch(stateController.setState((prev) => ({ ...prev, currentCoachSession: { ...prev.currentCoachSession, squadIdSet: squadIdSet } })));
            }
            dispatch(Actions.closeSelectModal())
        }
    }

    public static processNewPitch() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const sessionType = Selectors.getCurrentSessionType(getState())
                let session = Selectors.getCurentSession(getState());
                let coachSession = Selectors.getCurentHeadCoachSession(getState());
                const playerSource = Selectors.getPlayerSource(getState());
                const headCoachSource = Selectors.getHeadCoachSource(getState());

                dispatch(stateController.setState({ isSaving: true }))

                let request = {} as CreateAgencyPlusPitchRequest;

                if (sessionType === PlusPitchSessionTypeEnum.Player) {
                    request = {
                        playerId: session.playerId,
                        clubStaffId: null,
                        squadIds: session.squadIdSet.map(x => {
                            return {
                                squadId: x.id,
                                sourceId: x.isRecommended ? 2 : 1
                            }
                        }),
                        expectedAskingPrice: session.askingPrice,
                        expectedGrossSalaryPerYear: session.grossSalaryPerYear,
                        expectedMonthlyGrosSalary: session.monthlyGrossSalary,
                        contractExpiry: null,
                        message: session.message ? session.message : undefined
                    }
                } else {
                    request = {
                        playerId: null,
                        clubStaffId: coachSession.headCoachId,
                        squadIds: coachSession.squadIdSet.map(x => {
                            return {
                                squadId: x.id,
                                sourceId: x.isRecommended ? 2 : 1
                            }
                        }),
                        expectedAskingPrice: null,
                        expectedGrossSalaryPerYear: coachSession.grossSalaryPerYear,
                        expectedMonthlyGrosSalary: null,
                        contractExpiry: coachSession.contractExpiry,
                        message: coachSession.message ? coachSession.message : undefined
                    }
                }

                await AgencyPlusPitchService.create(request);

                const state = getState();
                const recommended = Selectors.getSquadsWithRecommender(state)
                    .find(({ id }) => id === -1);
                const recommendedSquads = recommended && recommended.squads.map(({ id }) => id);
                const currency = getAuthCurrency(state).name;

                let squads = Selectors.getSquads(state);
                let squadIds = Selectors.getSelectedSquadIds(state);
                let recipients = [];
                let recomender = [];

                for (let i = 0; i < squads.length; i++) {
                    const league = squads[i];
                    for (let j = 0; j < league.squads.length; j++) {
                        const squad = league.squads[j];
                        if (squadIds.map(x => x.id).indexOf(squad.id) > -1) {
                            if (recommendedSquads && recommendedSquads.indexOf(squad.id) > -1) {
                                recomender.push(squad.name);
                            } else {
                                recipients.push(squad.name);
                            }
                        }
                    }
                }
                const recipientsText = recipients[0] ? `, Recipients: ${recipients.join(', ')}` : '';
                const recomenderText = recomender[0] ? `, Recommended: ${recomender.join(', ')}` : '';

                let priceText = "";

                if (sessionType === PlusPitchSessionTypeEnum.Player) {
                    if (session.grossSalaryPerYear !== null && session.grossSalaryPerYear !== undefined) {
                        const askingPrice = session.askingPrice !== null && session.askingPrice !== undefined
                            ? `${session.askingPrice}; ` : '';
    
                        priceText += `To Buy (asking price: ${currency} ${askingPrice}${session.grossSalaryPerYear}/yr)`;
                    }
    
                    if (session.monthlyGrossSalary !== null && session.monthlyGrossSalary !== undefined) {
                        if (session.grossSalaryPerYear !== null && session.grossSalaryPerYear !== undefined) {
                            priceText += ', '
                        }
                        priceText += `To Loan (asking price: ${currency} ${session.monthlyGrossSalary}/mo)`;
                    }
    
                    dispatch(userActivityInsert({
                        Message: `Launched Plus Pitch: ${priceText}${recipientsText}${recomenderText}, Message${!!session.message ? '(Yes)' : '(No)'}`,
                        PageName: 'Plus Pitch',
                        PlayerId: session.playerId,
                        PageType: PageType.Pitch
                    }));
                } else {
                    dispatch(userActivityInsert({
                        Message: `Launched Plus Pitch: Price ${coachSession.grossSalaryPerYear}/yr${recipientsText}${recomenderText}, Message${!!coachSession.message ? '(Yes)' : '(No)'}`,
                        PageName: 'Plus Pitch',
                        CoachId: coachSession.headCoachId,
                        PageType: PageType.Pitch
                    }));
                }

                if (sessionType === PlusPitchSessionTypeEnum.Player) {
                    playerSource.find(x => x.playerId === session.playerId).isFreePlusPitch = false;
                    dispatch(stateController.setState({
                        playerSource: playerSource
                    }));
                } else if (sessionType === PlusPitchSessionTypeEnum.HeadCoach) {
                    headCoachSource.find(x => x.staffId === coachSession.headCoachId).isFreePlusPitch = false;
                    dispatch(stateController.setState({
                        headCoachSource: headCoachSource
                    }));
                }
                
                dispatch(Actions.reloadData())
                await dispatch(CommonController.Actions.loadPlusPitchInfo());
                dispatch(stateController.setState({ isSessionCompleted: true, isSaving: false }))
            } catch (error) {
                dispatch(stateController.setState({ isSaving: false }))
            }
        }
    }

    public static openMessageModal = () => {
        return async (dispatch) => {
            dispatch(stateController.setState({ currentStep: CreatePlusPitchSteps.AddMessageStep }))
        }
    }

    public static closeMessageModal = () => {
        return (dispatch) => {
            dispatch(stateController.setState({
                currentStep: CreatePlusPitchSteps.None,
            }))
        }
    }

    public static setMessage = (message: string) => {
        return (dispatch, getState: () => AppState) => {
            const sessionType = Selectors.getCurrentSessionType(getState())
            if (sessionType === PlusPitchSessionTypeEnum.Player) {
                dispatch(stateController.setState((prev) => ({ ...prev, currentSession: { ...prev.currentSession, message: message } })));
            } else {
                dispatch(stateController.setState((prev) => ({ ...prev, currentCoachSession: { ...prev.currentCoachSession, message: message } })));
            }
            dispatch(Actions.closeMessageModal());
        }
    }

    public static newMessage(pitchId: number, pitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => {

            const subState = getState().agency.plusPitch;
            let pitch = subState.plusPitches.find(x => x.plusPitchId == pitchId);
            var plusPitchSquad = pitch.squads.find(x => x.id == pitchSquadId);
            const isPlayerPitch = pitch.pitchedClubStaff === null;
            const playerId = isPlayerPitch ? pitch.pitchedPlayer.id : null
            const coachId = !isPlayerPitch ? pitch.pitchedClubStaff.id : null

            let session: MessageSend.MessageSendSession = {
                subject: "RE: PLUS PITCH OF " + (isPlayerPitch ? pitch.pitchedPlayer.englishShortName : pitch.pitchedClubStaff.name),
                logo: plusPitchSquad.squad.logo,
                checkBoxTitle: null,
                officialName: plusPitchSquad.squad.name,
                leagueName: plusPitchSquad.squad.league + plusPitchSquad.squad.country,
                mesageTypeId: -1
            }
            
            dispatch(userActivityInsert({
                PageName: 'Plus Pitch [Drop-down]',
                Message: 'Opened New Message',
                PlayerId: playerId,
                CoachId: coachId,
                ClubId: plusPitchSquad.squad.id,
                PageType: PageType.Pitch
            }))

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SendPlusPitchSpecification(dispatch, pitchId, pitchSquadId, playerId, plusPitchSquad.squad.id, coachId)
                )
            )
        }
    }

    public static cancelCreation() {
        return (dispatch) => {
            dispatch(stateController.setState({
                currentSession: null,
                isSessionCompleted: false,
                squads: [],
                squadsWithRecommender: [],

                currentSessionType: PlusPitchSessionTypeEnum.Player,
                currentCoachSession: null
            }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    /*  SELECT HEAD COACH MODAL ------------------------------------------------------------- */

    public static openFindCoachPopup = () => {
        return (dispatch) => {
            dispatch(FindHeadCoachController.Actions.openModal(PageType.Pitch, 'Plus Pitch'))
        }
    }

    public static sendUserActivity(pageName: string, message: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                PageType: PageType.Pitch,
            }));
        }
    }

    public static setCurrentSessionType = (sessionType: number) => {
        return (dispatch) => {            
            dispatch(Actions.restartCreation())
            dispatch(stateController.setState({
                squads: [],
                squadsWithRecommender: [],
                contractExpiryModal: null,
            }))
            dispatch(stateController.setState({ currentSessionType: sessionType }))
        }
    }

    public static selectCoach = (coachId: number) => {
        return (dispatch) => {
            dispatch(stateController.setState((prevState) => ({ ...prevState, currentCoachSession: { ...prevState.currentCoachSession, headCoachId: coachId } })));
            dispatch(Actions.closeSelectModal())
        }
    }

    public static onKeywordChange(value: string) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                keyword: value
            })))

            dispatch(Actions.filteredHeadCoachesList(value))
        }
    }

    public static filteredHeadCoachesList(value: string) {
        return async (dispatch, getState: () => AppState) => {
            
            const coaches = Selectors.getHeadCoachSource(getState())

            if (value.length === 0) {
                dispatch(stateController.setState({filteredHeadCoachesList: coaches}))
            }  else {
                dispatch(stateController.setState({filteredHeadCoachesList: [...coaches.filter(item => item.coachName.toUpperCase().indexOf(value.toUpperCase()) !== -1)]}))
            }            
        }
    }

    public static closeHeadCoachModal = () => {
        return (dispatch) => {
            dispatch(stateController.setState({
                currentStep: CreatePlusPitchSteps.None,
                filteredHeadCoachesList: [],
                keyword: '',
            }))
        }
    }

    /*  SELECT HEAD COACH PRICE MODAL ------------------------------------------------------------- */

    public static setHeadCoachPrice(expectedGrossSalary: number, expectedContractExpiry: Date) {
        return (dispatch) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                currentCoachSession: {
                    ...prevState.currentCoachSession,
                    grossSalaryPerYear: expectedGrossSalary,
                    contractExpiry: expectedContractExpiry,
                }
            }
            )));
            dispatch(Actions.closeSelectModal())
        }
    }

    public static openContractExpiryModal = (id: number) => {
        return (dispatch, getState: () => AppState) => {
            const headCoach = Selectors.getHeadCoachSource(getState()).find(x => x.staffId === id);
            dispatch(stateController.setState({
                contractExpiryModal: {
                    coachId: id,
                    contractExpiry: headCoach.clubContractExpiry,
                    plusYearsCount: null,
                    processing: false
                },
            }));
            
            // dispatch(userActivityInsert({
            //     PageName: 'Agency Players [Contract Expiry]',
            //     Message: 'Opened',
            //     PlayerId: player.playerId,
            //     ClubId: player.squadId,
            //     PageType: PageType.AgencyPlayers
            // }))
        }
    }

    public static closeContractExpiryModal() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getPlusPitch(getState());
            const headCoach = subState.headCoachSource.find(x => x.staffId === subState.contractExpiryModal.coachId);
            dispatch(Actions.cancelContractExpiryModal());

            // dispatch(userActivityInsert({
            //     PageName: 'Agency Coaches [Contract Expiry]',
            //     Message: 'Cancelled',
            //     PlayerId: player.playerId,
            //     ClubId: player.squadId,
            //     PageType: PageType.AgencyPlayers
            // }))
        }
    }

    public static cancelContractExpiryModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ contractExpiryModal: null }));
        }
    }

    public static setContractExpiry(contractExpiry: Date, plusDays?: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getPlusPitch(getState());
            const modalState: ContractExpiryModal = { ...subState.contractExpiryModal, plusYearsCount: plusDays, contractExpiry: contractExpiry };

            dispatch(stateController.setState({ contractExpiryModal: modalState }));
        }
    }

    public static saveContractExpiry() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getPlusPitch(getState());
            const modalState = { ...subState.contractExpiryModal, processing: true };
            
            dispatch(stateController.setState({ contractExpiryModal: modalState }))

            if (modalState.contractExpiry == null) {
                dispatch(this.cancelContractExpiryModal());
                return;
            }

            try {

                const headCoach = subState.headCoachSource.find(x => x.staffId === subState.contractExpiryModal.coachId);

                if (headCoach.clubContractExpiry === modalState.contractExpiry && null === modalState.plusYearsCount) {
                    dispatch(this.cancelContractExpiryModal());
                    return;
                }

                const date = subState.contractExpiryModal.contractExpiry
                dispatch(stateController.setState(prevState => ({ ...prevState, currentCoachSession: { ...prevState.currentCoachSession, contractExpiry:  date} })))

                dispatch(this.cancelContractExpiryModal());
            } catch (e) {
                console.error(e)
                dispatch({ type: '--ERROR' })
            } finally {
                // dispatch(stateController.setState(prevState => ({ ...prevState, contractExpiryModal: { ...prevState.contractExpiryModal, processing: false } })))
            }
        }
    }
}

class Selectors {
    public static getPlusPitch = (state: AppState) => state.agency.plusPitch
    public static getPlusPitchList = (state: AppState) => Selectors.getPlusPitch(state).plusPitches
    public static getCurentSession = (state: AppState) => Selectors.getPlusPitch(state).currentSession
    public static getCurentHeadCoachSession = (state: AppState) => Selectors.getPlusPitch(state).currentCoachSession

    public static getPlusPitchCount = (state: AppState) => CommonController.Selectors.getPlusPitchCount(state)
    public static isAnyStepActive = (state: AppState) => Selectors.getPlusPitch(state).currentStep !== CreatePlusPitchSteps.None;
    public static getSquadsWithRecommender = (state: AppState): Array<CompetitionWithActiveSquadsModel> => Selectors.getPlusPitch(state).squadsWithRecommender
    public static getSquads = (state: AppState): Array<CompetitionWithActiveSquadsModel> => Selectors.getPlusPitch(state).squads
    public static getSelectedSquadIds = (state: AppState): Array<SquadWithRecommendationModel> => {
        
        const sessionType = Selectors.getCurrentSessionType(state)
        
        if (sessionType === PlusPitchSessionTypeEnum.Player) {
            return Selectors.getCurentSession(state).squadIdSet
        } else return Selectors.getCurentHeadCoachSession(state).squadIdSet
    }
    public static getSelectedSquadCount = (state: AppState): number => Selectors.getSelectedSquadIds(state) ?
        Selectors.getSelectedSquadIds(state).length : 0

    public static getPlayerSource = (state: AppState): Array<PlayerVerificationExtended> => Selectors.getPlusPitch(state).playerSource
    public static getHeadCoachSource = (state: AppState): Array<HeadCoachesVerification> => Selectors.getPlusPitch(state).headCoachSource

    public static isLoadingSquads = (state: AppState) => Selectors.getPlusPitch(state).isLoadingSquads
    public static isSaving = (state: AppState): boolean => Selectors.getPlusPitch(state).isSaving
    public static isLoading = (state: AppState): boolean => Selectors.getPlusPitch(state).isLoading
    public static isLoadingPlayers = (state: AppState): boolean => Selectors.getPlusPitch(state).isLoadingPlayers
    public static isSessionCompleted = (state: AppState): boolean => Selectors.getPlusPitch(state).isSessionCompleted
    public static canCreate = (state: AppState): boolean => {
        const squadIdSet = Selectors.getSelectedSquadIds(state);

        return !!(squadIdSet && squadIdSet[0])
    }

    public static getSelectedPlayerId = (state: AppState): number => (Selectors.getCurentSession(state) || {}).playerId
    public static getSelectedHeadCoachId = (state: AppState): number => (Selectors.getCurentHeadCoachSession(state) || {}).headCoachId

    public static getSelectedPlayer = (state: AppState): PlayerVerificationExtended | null => {
        return Selectors.getPlayerSource(state)
            .find(x => x.playerId == Selectors.getSelectedPlayerId(state))
    }
    public static getSelectedHeadCoach = (state: AppState): HeadCoachesVerification | null => {
        return Selectors.getHeadCoachSource(state)
            .find(x => x.staffId == Selectors.getSelectedHeadCoachId(state))
    }

    public static isCreatingPlusPitchSessionActive = (state: AppState): boolean => (Selectors.getSelectedPlayerId(state) !== null || Selectors.getSelectedHeadCoachId(state) !== null)
    public static getKeyword = (state: AppState): string => Selectors.getPlusPitch(state).keyword
    public static getHeadCoachFilteredList = (state: AppState): Array<HeadCoachesVerification> => Selectors.getPlusPitch(state).filteredHeadCoachesList
    public static isLoadingHeadCoaches = (state: AppState): boolean => Selectors.getPlusPitch(state).isLoadingHeadCoaches
    public static getCurrentSessionType = (state: AppState): number => Selectors.getPlusPitch(state).currentSessionType

    public static isFreePitch = (state: AppState) => {
        const sessionType = Selectors.getCurrentSessionType(state);
        const isPlayerPitchSession = sessionType && sessionType === PlusPitchSessionTypeEnum.Player;
        const player = Selectors.getSelectedPlayer(state);
        const headCoach = Selectors.getSelectedHeadCoach(state);

        if (!player && !headCoach) {
            return false;
        }
        return isPlayerPitchSession ? player.isFreePlusPitch : headCoach.isFreePlusPitch;
    }

    public static getMessage = (state: AppState) => {
        const sessionType = Selectors.getCurrentSessionType(state);

        if (sessionType === PlusPitchSessionTypeEnum.Player) {
            return Selectors.getCurentSession(state).message;
        } else {
            return Selectors.getCurentHeadCoachSession(state).message;
        }
    }
    public static isFreePitchExists = (state: AppState) => {
        const isPlayerFreePitchesExists = Selectors.getPlayerSource(state).some(x => x.isFreePlusPitch);
        const isHeadCoachFreePitchesExists = Selectors.getHeadCoachSource(state).some(x => x.isFreePlusPitch);
        const isCoachAccess = isPitchCoachAccess(state);

        return isPlayerFreePitchesExists || (isCoachAccess && isHeadCoachFreePitchesExists);
    }
    public static isOnlyFreePitchAvailable = (state: AppState) => Selectors.getPlusPitchCount(state) === 0;
}


const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyPlusPitchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



