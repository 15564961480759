import * as redux from 'redux';
import * as onboardingController from './onboarding/onboarding.controller';
import * as pitchController from "pages/agency/authorized-agent/pitch-page/tabs/pitch-tab/redux/pitch-page.controller";
import * as verifyPlayersController from 'pages/agency/authorized-agent/player-verification/verification.controller';
import * as inviteAgentsController from 'pages/agency/authorized-agent/invite-agents/invite-agents.controller';
import * as addPlayersControllers from 'pages/agency/authorized-agent/add-players/add-players.controller'
import * as agencySettings from 'pages/agency/authorized-agent/profile/redux/root.controller';
import * as agencySubscriptionRoot from 'pages/subscription-flow/redux/agency-subscription-root.controller';
import * as confirmAccountController from './confirm-account/confirm-account.controller';
import * as pitchAccessCriteriaModalController from 'pages/agency/authorized-agent/pitch-page/tabs/pitch-tab/components/pitch-access-criteria-modal/pitch-access-criteria-modal.controller';
import * as restrictedPlayerCreditsModalController from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-player-credits-modal/restricted-player-credits.controller';
import * as restrictedCoachCreditsModalController from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-coach-credits-modal/restricted-coach-credits.controller';
import * as suggestedDeals from 'pages/agency/authorized-agent/suggested-deals/suggested-deals.controller'
import * as agencyTransparency from 'pages/agency/authorized-agent/agency-transparency/agency-transparency.controller';
import * as addCoachesControllers from 'pages/agency/authorized-agent/add-coaches/add-coaches.controller';
import * as verifyCoachesController from 'pages/agency/authorized-agent/coach-verification/coach-verification.controller';

export class AgencyAgentState {
    onboarding: onboardingController.State;
    pitch: pitchController.State;
    verifyPlayers: verifyPlayersController.State;
    inviteAgents: inviteAgentsController.State;
    addPlayers: addPlayersControllers.State;
    addCoaches: addCoachesControllers.State;
    verifyCoaches: verifyCoachesController.State;
    agencySettings: agencySettings.AgencySettingsState;
    agencySubscription: agencySubscriptionRoot.AgencyAgentState;
    confirmAccount: confirmAccountController.State;
    pitchAccessCriteriaModal: pitchAccessCriteriaModalController.State;
    restrictedPlayerCreditsModal: restrictedPlayerCreditsModalController.State;
    restrictedCoachCreditsModal: restrictedCoachCreditsModalController.State;
    suggestedDeals: suggestedDeals.State;
    agencyTransparency: agencyTransparency.State;
}

export function combineReducers() {

    let agencyReducer = {
        onboarding: onboardingController.Reducer,
        pitch: pitchController.Reducer,
        verifyPlayers: verifyPlayersController.Reducer,
        inviteAgents: inviteAgentsController.Reducer,
        addPlayers: addPlayersControllers.Reducer,
        addCoaches: addCoachesControllers.Reducer,
        verifyCoaches: verifyCoachesController.Reducer,
        agencySettings: agencySettings.combineReducers(),
        agencySubscription: agencySubscriptionRoot.combineReducers(),
        confirmAccount: confirmAccountController.Reducer,
        pitchAccessCriteriaModal: pitchAccessCriteriaModalController.Reducer,
        restrictedPlayerCreditsModal: restrictedPlayerCreditsModalController.Reducer,
        restrictedCoachCreditsModal: restrictedCoachCreditsModalController.Reducer,
        suggestedDeals: suggestedDeals.Reducer,
        agencyTransparency: agencyTransparency.Reducer
    };

    return redux.combineReducers(agencyReducer)
}