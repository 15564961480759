import { CoachComparisonResponse } from 'api/coach-profile/model';
import config from "../config";
import { PlayerComparisonResponse } from 'api/player-profile/model/performance';

export const convertFloatToInt = (num: number) => {
    return Math.round(num);
}

const reversedFields = ['ppda'];

const isReversedField = (label: string) => reversedFields.some(x => x === label);

export function getInPercents(comparison: CoachComparisonResponse, label: string, coach: string) {
    if (!comparison[coach] || !comparison[coach][label]) {
        if (isReversedField(label)) {
            return 100;
        }

        return 0;
    }

    let score = comparison[coach][label];
    const domain = {
        min: comparison.average[label].minimum,
        max: comparison.average[label].maximum,
    };
    const scoreProportion =
        (score - Math.min(domain.min, score)) / (domain.max - domain.min);

    let result = scoreProportion * 100;
    result = Math.round(result);
    result = Math.min(100, result);
    if (isReversedField(label)) {
        result = 100 - result;
    }
    return result;
}

export const reversedFieldsPlayers = ['concededGoalsP90'];

const isReversedFieldPlayers = (label: string) => reversedFieldsPlayers.some(x => x === label);

export function getInPercentsPlayers(comparison: PlayerComparisonResponse, label: string, stat: string) {
    if (!comparison[stat] || (stat === 'average' ? !comparison[stat][label]['average'] : !comparison[stat][label].value)) {
        if (isReversedFieldPlayers(label)) {
            return 100;
        }

        return 0;
    }

    let score = stat === 'average' ? comparison[stat][label]['average'] : comparison[stat][label].value;
    const domain = {
        min: comparison.average[label]['minimumAxisValue'],
        max: comparison.average[label]['maximumAxisValue'],
    };
    const scoreProportion =
        (score - Math.min(domain.min, score)) / (domain.max - domain.min);

    let result = scoreProportion * 100;
    result = Math.round(result);
    result = Math.min(100, result);
    if (isReversedFieldPlayers(label)) {
        result = 100 - result;
    }
    return result;
}