import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { userPaths, anonymousPaths } from 'routes/paths';
import Service from 'api/agency/player/onboarding/onboarding.service'
import InvService from 'api/agency/player/shared/agency-player-service';
import { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import history from 'history-accessor'
import historyAccessor from 'history-accessor';
import { PageType } from 'constants/enums';


class OnboardingState {
    termsCheckbox: boolean;
    requestProccessing: boolean;
    playerName: string;
    confirmed: boolean;
    agencyName: string;
    isInvitationInvalid: boolean;
    error: string;
    agencyId: number;
    playerId: number;
}

const defaultState: OnboardingState = {
    termsCheckbox: true,
    requestProccessing: false,
    playerName: "",
    confirmed: false,
    agencyName: "",
    isInvitationInvalid: false,
    error: null,
    agencyId: null,
    playerId: null,
}

const stateController = new StateController<OnboardingState>(
    "AGENCY/PLAYER/CONFIRM",
    defaultState
);

class Actions {
    public static validate(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ requestProccessing: true }));
            let res = await Service.getPlayerInfo(guid);
    
            if (!res) {
                dispatch(stateController.setState({ isInvitationInvalid: true }))
            } else if(res.error) {
                dispatch(stateController.setState({ isInvitationInvalid: true, error: res.error }))
            } else {
                dispatch(stateController.setState({ requestProccessing: false, agencyId: res.agencyId, playerId: res.id, playerName: res.name, agencyName: res.agencyName, }))
                dispatch(insertAnonymousActivity({
                    PageName: 'Player confirmation',
                    Message: 'Opened Confirmation Link',
                    AgencyId: res.agencyId,
                    PlayerId: res.id,
                    PageType: PageType.PlayerSide
                }))
            }
        }
    }

    public static setTerms(agreed: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ termsCheckbox: agreed }));
        }
    }

    public static confirm(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().player.oldOnboarding;
            dispatch(stateController.setState({ requestProccessing: true }));
            await InvService.confirmAnonymous(guid);

            dispatch(insertAnonymousActivity({
                PageName: 'Player confirmation',
                Message: 'Confirmed Agent',
                AgencyId: substate.agencyId,
                PlayerId: substate.playerId,
                PageType: PageType.PlayerSide
            }))
            dispatch(stateController.setState({ requestProccessing: false, confirmed: true }))
        }
    }

    
    public static openTerms() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().player.oldOnboarding;

            dispatch(insertAnonymousActivity({
                PageName: 'Player confirmation',
                Message: 'Opened Verification Terms',
                AgencyId: substate.agencyId,
                PlayerId: substate.playerId,
                PageType: PageType.PlayerSide
            }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    OnboardingState as State,
    Actions as Actions,
    stateController as Controller
};



