import '@fortawesome/fontawesome-free/css/all.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDown,
    faAngleRight,
    faUserAltSlash,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';

library.add(faAngleDown, faAngleRight, faUserAltSlash, faCheck);
