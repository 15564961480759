import axios from 'axios-config';
import { AreaModel } from './area-model'
import { ShortSquadModel } from './short-squad-model'
import { FriendlyMatchModel } from './friendly-invitation'

export class FriendlyMatchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getActiveAreasWithCompetitions(withAdditionalAreas = false): Promise<Array<AreaModel>> {
        const { data } = await axios.get(
            `api/FriendlyMatches/GetActiveAreasWithCompetitions/${withAdditionalAreas}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCountOfMyFriendlyMatches(): Promise<number> {
        const { data } = await axios.get(
            `api/FriendlyMatches/GetMyFriendlyMatchesCount`,
            this.axiosConfig
        );
        return data;
    }

    public static async getAvailableDates(squadId: number, squadTypeId: number): Promise<Array<Date>> {
        const { data } = await axios.get(
            `api/FriendlyMatches/GetAvailabilityMap/${squadId}/${squadTypeId}`,
            this.axiosConfig
        );
        return data.busyDates;
    }

    public static async getSquadsByCompetitionId(competitionId: number, currentSquadId): Promise<Array<ShortSquadModel>> {

        const { data } = await axios.get(
            `api/Squad/GetActiveSquadsByCompetitionId/${competitionId}/2`,
            this.axiosConfig
        );
        return (data as Array<ShortSquadModel>).filter(x => x.id != currentSquadId);
    }

    public static async createFriendlyMatch(request: CreateFriendlyMatchRequest): Promise<number> {
        const { data } = await axios.post(
            `api/FriendlyMatches/CreateFriendlyMatch/1`,
            request,
            this.axiosConfig
        );
        return data.id;
    }

    public static async inviteClubs(request: InviteClubsRequest): Promise<number> {
        const { data } = await axios.post(
            `api/FriendlyMatches/InviteClubs/1`,
            request,
            this.axiosConfig
        );
        return data.id;
    }

    public static async getSuggestions(friendlyMatchId: number): Promise<Array<FriendlyMatchModel>> {
        const { data } = await axios.get(
            `api/FriendlyMatches/GetFriendlySuggestions?friendlyMatchId=${friendlyMatchId}`,
            this.axiosConfig
        );
        return data.friendlyAdsMatchList;
    }

    public static async getFriendlyInivtations(): Promise<Array<FriendlyMatchModel>> {
        const { data } = await axios.get(
            `api/FriendlyMatches/GetInvitations`,
            this.axiosConfig
        );
        return data;
    }

    public static async declareInterestForFriendly(friendlyId: number): Promise<any> {
        await axios.post(
            `api/FriendlyMatches/DeclareInterest/1`,
            friendlyId,
            this.axiosConfig
        );
    }

    public static async setInvitationAsNotAvailable(friendlyId: number): Promise<any> {
        await axios.post(
            `api/FriendlyMatches/SetUnavailableStatusForMatch/1`,
            friendlyId,
            this.axiosConfig
        );
    }

}

export class InviteClubsRequest {
    public friendlyMatchId: number;
    public squadIds: Array<number>;
}


export class CreateFriendlyMatchRequest {
    public squadTypeId: number;

    public startAt: Date;

    public endAt: Date;

    public isHomeVenue: boolean;

    public areaId: number;
}