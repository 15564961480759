import * as types from 'store/actionTypes';

const initialState = {
    clubAds: [],
    leagues: [],
    isSuccess: true,
};

export default (clubAds = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_NEWSLETTER_SEND_EMAIL.SUCCESS: {
            return {
                ...clubAds,
                isSuccess: action.payload.data,
            };
        }

        default:
            return clubAds;
    }
};

// export const getPlayerProposalsList = state => {
//     return state.playerProposals.proposals;
// };

// export const getTotalRecordsCount = state => {
//     return state.playerProposals.totalRecords;
// };

// export const getCurrentPageNumber = state => {
//     return state.playerProposals.currentPageNumber;
// };

// export const getLeagues = state => {
//     return state.playerProposals.leagues;
// };
