export const eventClubs = [
    {
        squadId: 17,
        squadName: "Genoa CFC",
        shortSquadName: "Genoa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
      },
      {
        squadId: 45,
        squadName: "Manchester United",
        shortSquadName: "Man Utd",
        squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
      },
      {
        squadId: 46,
        squadName: "Liverpool FC",
        shortSquadName: "Liverpool",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
      },
      {
        squadId: 48,
        squadName: "Manchester City",
        shortSquadName: "Man City",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
      },
      {
        squadId: 174,
        squadName: "Club Atletico Talleres",
        shortSquadName: "CA Talleres",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
      },
      {
        squadId: 697,
        squadName: "Sport Club Corinthians Paulista",
        shortSquadName: "Corinthians",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_697_Sport_Club_Corinthians_Paulista.png"
      },
      {
        squadId: 862,
        squadName: "CD O'Higgins",
        shortSquadName: "O'Higgins",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
      },
      {
        squadId: 1065,
        squadName: "SC Braga",
        shortSquadName: "SC Braga",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sporting_Braga.png"
      },
      {
        squadId: 1421,
        squadName: "Gremio Foot-Ball Porto Alegrense",
        shortSquadName: "Gremio",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
      },
      {
        squadId: 4230,
        squadName: "Stade Reims",
        shortSquadName: "Stade Reims",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
      },
      {
        squadId: 4416,
        squadName: "Sport Club do Recife",
        shortSquadName: "Sport Recife",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5674_Sport_Club_do_Recife.png"
      },
      {
        squadId: 5359,
        squadName: "Caracas FC",
        shortSquadName: "Caracas FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
      },
      {
        squadId: 5924,
        squadName: "Everton de Vina del Mar",
        shortSquadName: "CD Everton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
      },
      {
        squadId: 5972,
        squadName: "San Jose Earthquakes",
        shortSquadName: "San Jose",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
      },
      {
        squadId: 6249,
        squadName: "Bayer 04 Leverkusen",
        shortSquadName: "B. Leverkusen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
      },
      {
        squadId: 7400,
        squadName: "Botafogo de Futebol e Regatas",
        shortSquadName: "Botafogo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7400_Botafogo_de_Futebol_e_Regatas.png"
      },
      {
        squadId: 8076,
        squadName: "CD Universidad Catolica",
        shortSquadName: "U. Catolica",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12295_CD_Universidad_Católica.png"
      },
      {
        squadId: 8239,
        squadName: "Brentford FC",
        shortSquadName: "Brentford",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
      },
      {
        squadId: 8665,
        squadName: "New York City FC",
        shortSquadName: "New York City",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2109_New_York_City_FC.png"
      },
      {
        squadId: 8896,
        squadName: "Hercilio Luz FC",
        shortSquadName: "NULL",
        squadLogo: null
      },
      {
        squadId: 10026,
        squadName: "Los Angeles Galaxy",
        shortSquadName: "Los Angeles",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
      },
      {
        squadId: 10371,
        squadName: "Audax Italiano",
        shortSquadName: "Audax Italiano",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12292_Audax_Italiano.png"
      },
      {
        squadId: 12027,
        squadName: "CA Boston River",
        shortSquadName: "Boston River",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
      },
      {
        squadId: 13404,
        squadName: "Houston Dynamo FC",
        shortSquadName: "Houston",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
      },
      {
        squadId: 14275,
        squadName: "America Futebol Clube (RN)",
        shortSquadName: "America Futebol Clube (RN)",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_14275_America_Futebol_Clube_(RN).png"
      },
      {
        squadId: 15241,
        squadName: "Magallanes CF",
        shortSquadName: "Magallanes",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
      },
      {
        squadId: 15469,
        squadName: "Club Nacional",
        shortSquadName: "Nacional",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15634_Club_Nacional.png"
      },
      {
        squadId: 16247,
        squadName: "Club Athletico Paranaense",
        shortSquadName: "Athletico-PR",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
      },
      {
        squadId: 16324,
        squadName: "Academia Puerto Cabello",
        shortSquadName: "Puerto Cabello",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_16324_Academia_Puerto_Cabello.png"
      },
      {
        squadId: 16335,
        squadName: "Beerschot V.A.",
        shortSquadName: "Beerschot V.A.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
      },
      {
        squadId: 17454,
        squadName: "Avai FC",
        shortSquadName: "Avai FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
      },
      {
        squadId: 19206,
        squadName: "Santos FC",
        shortSquadName: "Santos",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
      },
      {
        squadId: 20983,
        squadName: "Club Atletico Belgrano",
        shortSquadName: "Belgrano",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2591_Club_Atletico_Belgrano.png"
      },
      {
        squadId: 22240,
        squadName: "PAOK Thessaloniki",
        shortSquadName: "PAOK Salonika",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
      },
      {
        squadId: 22719,
        squadName: "Clube de Regatas Vasco da Gama",
        shortSquadName: "Vasco da Gama",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
      },
      {
        squadId: 22788,
        squadName: "Red Bull Bragantino",
        shortSquadName: "RB Bragantino",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5685_Red_Bull_Bragantino.png"
      },
      {
        squadId: 23647,
        squadName: "Atletico de San Luis",
        shortSquadName: "San Luis",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
      },
      {
        squadId: 24198,
        squadName: "Esporte Clube Bahia",
        shortSquadName: "EC Bahia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5677_Esporte_Clube_Bahia.png"
      },
      {
        squadId: 24408,
        squadName: "Club Alianza Lima",
        shortSquadName: "Alianza Lima",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7239_Club_Alianza_Lima.png"
      },
      {
        squadId: 24432,
        squadName: "Pafos FC",
        shortSquadName: "Pafos FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
      },
      {
        squadId: 24701,
        squadName: "CF Atlante",
        shortSquadName: "Atlante",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
      },
      {
        squadId: 25037,
        squadName: "Montevideo City Torque",
        shortSquadName: "Mvd City Torque",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/23497_Montevideo_City_Torque.png"
      },
      {
        squadId: 25039,
        squadName: "LDU Quito",
        shortSquadName: "LDU Quito",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15764_LDU_Quito.png"
      },
      {
        squadId: 26202,
        squadName: "Ludogorets Razgrad",
        shortSquadName: "Ludogorets",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
      },
      {
        squadId: 26540,
        squadName: "Orlando City SC",
        shortSquadName: "Orlando",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
      },
      {
        squadId: 26667,
        squadName: "Coritiba Foot Ball Club",
        shortSquadName: "Coritiba FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
      },
      {
        squadId: 27251,
        squadName: "CR Flamengo",
        shortSquadName: "Flamengo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
      },
      {
        squadId: 27833,
        squadName: "America Futebol Clube (MG)",
        shortSquadName: "America-MG",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5673_América_Futebol_Clube_(MG).png"
      },
      {
        squadId: 29065,
        squadName: "Club Sporting Cristal",
        shortSquadName: "Sport. Cristal",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
      },
      {
        squadId: 29455,
        squadName: "Nottingham Forest",
        shortSquadName: "Nottm Forest",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1152_Nottingham_Forest.png"
      },
      {
        squadId: 30352,
        squadName: "FC Vizela",
        shortSquadName: "Vizela",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/9683_FC_Vizela.png"
      },
      {
        squadId: 30372,
        squadName: "Envigado FC",
        shortSquadName: "Envigado",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
      },
      {
        squadId: 30475,
        squadName: "Malmo FF",
        shortSquadName: "Malmo FF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
      },
      {
        squadId: 30849,
        squadName: "Atlanta United FC",
        shortSquadName: "Atlanta",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
      },
      {
        squadId: 32429,
        squadName: "Eintracht Frankfurt",
        shortSquadName: "E. Frankfurt",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
      },
      {
        squadId: 33217,
        squadName: "Real CD Espana",
        shortSquadName: "Real Espana",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
      },
      {
        squadId: 33648,
        squadName: "Atletico Nacional",
        shortSquadName: "Atl. Nacional",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
      },
      {
        squadId: 33794,
        squadName: "Sport Club Internacional",
        shortSquadName: "Internacional",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
      },
      {
        squadId: 34038,
        squadName: "Club Cerro Porteno",
        shortSquadName: "Cerro Porteno",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_34038_Club_Cerro_Porteno.png"
      },
      {
        squadId: 35255,
        squadName: "CA Sarmiento (Junin)",
        shortSquadName: "Sarmiento Junin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12095_CA_Sarmiento_(Junin).png"
      },
      {
        squadId: 35839,
        squadName: "Sociedade Esportiva Palmeiras",
        shortSquadName: "Palmeiras",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
      },
      {
        squadId: 36736,
        squadName: "Olimpia Asuncion",
        shortSquadName: "Olimpia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15486_Olimpia.png"
      },
      {
        squadId: 37217,
        squadName: "Deportes Tolima",
        shortSquadName: "Deportes Tolima",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
      },
      {
        squadId: 39337,
        squadName: "SK Dnipro-1",
        shortSquadName: "SK Dnipro-1",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png"
      },
      {
        squadId: 39981,
        squadName: "Clube Atletico Mineiro",
        shortSquadName: "Atletico-MG",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5653_Atlético-MG.png"
      },
      {
        squadId: 40508,
        squadName: "LASK",
        shortSquadName: "LASK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
      },
      {
        squadId: 41646,
        squadName: "FC Dallas",
        shortSquadName: "Dallas",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2113_FC_Dallas.png"
      },
      {
        squadId: 41956,
        squadName: "Deportivo Cali",
        shortSquadName: "Deportivo Cali",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png"
      },
      {
        squadId: 50113,
        squadName: "Inter Miami CF",
        shortSquadName: "Miami",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
      },
      {
        squadId: 100007,
        squadName: "Austin FC",
        shortSquadName: "Austin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-72309_Austin_FC.png"
      },
      {
        squadId: 200001,
        squadName: "Mazatlan FC",
        shortSquadName: "Mazatlan FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
      },
      {
        squadId: 200004,
        squadName: "Charlotte FC",
        shortSquadName: "Charlotte",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
      },
      {
        squadId: 200414,
        squadName: "FCR Valledupar",
        shortSquadName: "Valledupar",
        squadLogo: null
      },
    
]