import * as types from 'store/actionTypes';

const initialState = {
    date: null,
    match: null,
    competition: null,
    minPlayed: null,
    actions: null,
    goals: null,
    assists: null,
    shots: null,
    xG: null,
    passes: null,
    longPasses: null,
    crosses: null,
    duels: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FULL_STAT_FETCH.SUCCESS:
            return {
                ...state,
                fullStat: action.payload.data,
            };

        default:
            return state;
    }
};
