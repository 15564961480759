import { StateController } from 'utils/action-declaration';
import { isValid } from 'utils/validation';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import { Actions as StepActions } from 'pages/virtual-summit/redux/complete-details.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class RewardProgramState {
    isLoading: boolean;
    isCompleting: boolean;
    items: Array<{ id: number, club: string, name: string, email: string, squadId: number }>;
    invalidNames: Array<number>;
    invalidEmails: Array<number>;
    invited: number;
}

const defaultState: RewardProgramState = {
    isLoading: false,
    isCompleting: false,
    items: [],
    invalidEmails: [],
    invalidNames: [],
    invited: 0
}

const stateController = new StateController<RewardProgramState>(
    "VIRTUAL_SUMMIT/REWARDS_PROGRAM",
    defaultState
);


class Actions {
    public static setName(id: number, name: string) {
        return async (dispatch, getState: () => AppState) => {
            let items = [...getState().virtualSummit.rewardProgram.items];
            let find = items.find(i => i.id == id);
            if (find) find.name = name;
            dispatch(stateController.setState({ items: items }));
        }
    }

    public static setEmail(id: number, email: string) {
        return async (dispatch, getState: () => AppState) => {
            let items = [...getState().virtualSummit.rewardProgram.items];
            let find = items.find(i => i.id == id);
            if (find) find.email = email;
            dispatch(stateController.setState({ items: items }))
        }
    }

    public static hideRow(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let items = [...getState().virtualSummit.rewardProgram.items];
            let filtered = items.filter(i => i.id != id);
            dispatch(stateController.setState({ items: filtered }))
        }
    }

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ isLoading: true }))
            var result = await VirtualSummitService.getRewardProgram(appState.auth.userId);
            let filtered = result.items.filter(i => appState.virtualSummit.inviteClubsNonTr.selected.some(x => x == i.squadId));
            dispatch(stateController.setState({
                isLoading: false,
                items: filtered.map(i => {
                    return { club: i.clubName, email: i.emailAddress, id: i.userSummitNonTrContactId, name: i.decisionMakerName, squadId: i.squadId }
                }),
                invited: filtered.filter(i => i.emailAddress != "" && i.emailAddress != null).length
            }))
        }
    }

    public static continue(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            const { eventId  } = appState.auth;

            let invalidNames = [];
            let invalidEmails = [];
            appState.virtualSummit.rewardProgram.items.map(x => {
                if (x.email != null && x.email != "" && (!isValid.email(x.email))) {
                    if (invalidEmails.indexOf(x.id) == -1) invalidEmails.push(x.id);
                }
                if (x.email != null && x.email != "" && (x.name == null || x.name == "")) {
                    if (invalidNames.indexOf(x.id) == -1) invalidNames.push(x.id);
                }
                if (x.name != null && x.name != "" && (x.email == null || x.email == "")) {
                    if (invalidEmails.indexOf(x.id) == -1) invalidEmails.push(x.id);
                }
            });
            if (invalidNames.length > 0 || invalidEmails.length > 0) {
                dispatch(stateController.setState({ invalidEmails: invalidEmails, invalidNames: invalidNames }))
            }
            else {
                dispatch(stateController.setState({ isLoading: true }))
                var result = await VirtualSummitService.setRewardProgram(
                    appState.auth.userId,
                    appState.virtualSummit.rewardProgram.items.map(i => {
                        return { clubName: i.club, decisionMakerName: i.name, emailAddress: i.email, userSummitNonTrContactId: i.id, squadId: i.squadId }
                    })
                );
                let dm = "Invited Decision Makers: ";
                appState.virtualSummit.rewardProgram.items.forEach((e, i) => {
                    let x = 0;
                    if (e.email != null && e.email != "") {
                        dm += (x > 0 ? ', ' : '') + `[${e.name}, ${e.email}, ${e.club}]`;
                        x++;
                    }
                });
                dispatch(userActivityInsert({
                    Message: dm, 
                    PageName: 'Event [Details]',
                    PageType: PageType.Events,
                    EventId: eventId
                }));

                let atLeastOneCompleted = appState.virtualSummit.rewardProgram.items.some(i => i.email != null && i.email != "")
                if (atLeastOneCompleted) {
                    await VirtualSummitService.setRewardIsCompleted(appState.auth.userId);
                }
                dispatch(stateController.setState(defaultState));
                dispatch(StepActions.goToNext(false, redirect));
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    RewardProgramState as State,
    Actions as Actions,
    stateController as Controller
};



