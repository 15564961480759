import { AppState } from 'root.reducer';
import { MessageType } from 'api/messaging/models/message'
import { StateController } from 'utils/action-declaration';
import { SearchItemType } from 'api/search-v3/model/search-result';
import { messageSend } from 'app/send-message/actions/send-message.actions';
import { ReceiverRole } from 'api/search-v3/model/declare-interest-definition';
import { MessagingService } from 'api/messaging/messaging.service'
import {
    IMessageSendSpecificaion,
    MessageSendSession,
    Actions as MessageModalController
} from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller'
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { SearchPlayerItemForClub } from 'api/search-v3/model/search-player-item-for-club';
import { SearchAgencyItemForClub } from 'api/search-v3/model/search-agency-item-for-club';
import { SearchCoachItemForClub } from 'api/search-v3/model/search-coach-item-for-club';
import {
    PlayerActivityService,
    AgencyActivityService,
    ClubActivityService,
    CoachActivityService
} from './player-activity.service';
import { Selectors as GridSelectors } from './grid.controller'

class SendMessageModalState {
    newMessageAvailability?: {
        availableCount?: number;
        lockedTill?: any;
    };
    restrictedAvailableCountModal: boolean;
    restrictedMessageModal: boolean;
}

const defaultState: SendMessageModalState = {
    newMessageAvailability: null,
    restrictedAvailableCountModal: false,
    restrictedMessageModal: false,
}

const stateController = new StateController<SendMessageModalState>(
    'SQUAD_SEARCH/SEND_MESSAGE',
    defaultState
)

class Actions {
    private static getSearchItem = (state: AppState, id: number, itemType?: SearchItemType) => {
        // return state.squadSearch.grid.result.items.find(x => x.player != null && x.player.id == id || x.agency != null && x.agency.id == id || x.club != null && x.club.id == id);
        if (itemType === SearchItemType.Coach) {
            return state.squadSearch.grid.result.items.find(x => (x as SearchCoachItemForClub).staffId === id);
        } else {
            return state.squadSearch.grid.result.items.find(x => (x as SearchPlayerItemForClub | SearchAgencyItemForClub).id === id);
        }
    }

    private static getSession = (
        item: SearchPlayerItemForClub | SearchAgencyItemForClub | SearchCoachItemForClub,
        itemType: SearchItemType,
        messageReceiverType?: ReceiverType,
    ) => {

        // we dont have club in search items in scope of #22825 - https://transferroom.visualstudio.com/TransferRoom/_workitems/edit/22825

        // if (itemType === SearchItemType.Club) {
        //     return {
        //         toClub: item.isActive,
        //         toAgency: false,
        //         subject: `New Message`,
        //         mesageTypeId: MessageType.ChatMessage,
        //         squadId: item.id,
        //         squadName: item.name,
        //         squadLeagueName: item.leagueName,
        //         squadLeagueCountry: item.country,
        //         squadLogo: item.logo,
        //         recipientId: item.id,
        //         recipientType: ReceiverType.Squad
        //     }
        // }

        if (itemType === SearchItemType.Player) {
            const canSendToSquadAndToAgency = (item as SearchPlayerItemForClub).declareInterest.messageRule.receiver === ReceiverRole.All
            const canSendToSquad = canSendToSquadAndToAgency || (item as SearchPlayerItemForClub).declareInterest.messageRule.receiver === ReceiverRole.Club;
            const canSendToAgency = canSendToSquadAndToAgency || (item as SearchPlayerItemForClub).declareInterest.messageRule.receiver === ReceiverRole.Agent;

            let messageSession = {};
            if (!canSendToAgency && canSendToSquad) {
                messageSession = { recipientId: (item as SearchPlayerItemForClub).parentSquad.id, recipientType: ReceiverType.Squad }
            } else if (!canSendToSquad && canSendToAgency) {
                messageSession = { ...messageSession, recipientId: (item as SearchPlayerItemForClub).agency.id, recipientType: ReceiverType.Agency }
            }
            if (canSendToSquadAndToAgency && messageReceiverType !== undefined) {
                if (messageReceiverType === ReceiverType.Squad) {
                    messageSession = { recipientId: (item as SearchPlayerItemForClub).parentSquad.id, recipientType: ReceiverType.Squad }
                } else if (messageReceiverType === ReceiverType.Agency) {
                    messageSession = { ...messageSession, recipientId: (item as SearchPlayerItemForClub).agency.id, recipientType: ReceiverType.Agency }
                }
            }

            let squad = (item as SearchPlayerItemForClub).parentSquad ? (item as SearchPlayerItemForClub).parentSquad : (item as SearchPlayerItemForClub).latestSquad;
            const { shortName, agency } = item as SearchPlayerItemForClub;
            return {
                ...messageSession,
                toClub: messageReceiverType !== undefined ? messageReceiverType === ReceiverType.Squad : canSendToSquad,
                toAgency: messageReceiverType !== undefined ? messageReceiverType === ReceiverType.Agency : canSendToAgency,
                subject: `${shortName} INTEREST`,
                mesageTypeId: canSendToAgency ? MessageType.ClubAgencyChatMessage : MessageType.ChatMessage,
                squadId: (squad || {}).id,
                squadName: (squad || {}).name,
                squadLeagueName: (squad || {}).league,
                squadLeagueCountry: (squad || {}).country,
                squadLogo: (squad || {}).logo,
                agencyId: agency ? agency.id : null,
                agencyCountry: agency ? agency.country : null,
                agencyName: agency ? agency.name : null,
                isTrustedAgent: agency ? (item as SearchPlayerItemForClub).agency.isTrusted : null,
            }
        }

        if (itemType === SearchItemType.Agency) {
            const { id, area, name, isTrusted } = item as SearchAgencyItemForClub;
            return {
                toClub: false,
                toAgency: isTrusted,
                subject: `New Message`,
                mesageTypeId: MessageType.ClubAgencyChatMessage,
                agencyId: id,
                agencyCountry: area,
                agencyName: name,
                isTrustedAgent: isTrusted,
                recipientId: id,
                recipientType: ReceiverType.Agency
            }
        }

        if (itemType === SearchItemType.Coach) {
            const { shortName, agency } = item as SearchCoachItemForClub;
            return {
                toClub: false,
                toAgency: agency ? agency.isTrusted : null,
                subject: `${shortName} INTEREST`,
                mesageTypeId: MessageType.ClubAgencyChatMessage,
                agencyId: agency ? agency.id : null,
                agencyCountry: agency ? agency.country : null,
                agencyName: agency ? agency.name : null,
                isTrustedAgent: agency.isTrusted,
                recipientId: agency ? agency.id : null,
                recipientType: ReceiverType.Agency
            }
        }
    }

    public static openModal(itemId: number, itemType: SearchItemType, messageReceiverType?: ReceiverType) {
        return (dispatch, getState: () => AppState) => {
            const item = Actions.getSearchItem(getState(), itemId, itemType);

            let messageSession: MessageSendSession = Actions.getSession(item, itemType, messageReceiverType);

            if (itemType === SearchItemType.Club || itemType === SearchItemType.Agency) {
                if (!Selectors.isPermited(getState())) {
                    dispatch(Actions.togleRestrictedMessageModal());
                    return
                }

                if (Selectors.isNewMessageLimitRichedOutOrHide(getState())) {
                    dispatch(Actions.togleRestrictedAvailableModal());
                    return
                }
            }

            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    if (itemType === SearchItemType.Agency) {
                        dispatch(AgencyActivityService.cancelNewMessage(session.recipientId))
                    }
                    else if (itemType === SearchItemType.Club) {
                        dispatch(ClubActivityService.cancelNewMessage(session.recipientId))
                    }
                    else if (itemType === SearchItemType.Coach) {
                        const { staffId } = item as SearchCoachItemForClub;
                        dispatch(CoachActivityService.cancelNewMessage(session.recipientId, staffId))
                    }
                    else {
                        const { id, recommendedFlag, isSuspendedContract, isOnReleaseList, parentSquad, agency } = item as SearchPlayerItemForClub;

                        dispatch(PlayerActivityService.cancelNewMessage(
                            id,
                            recommendedFlag,
                            isSuspendedContract,
                            isOnReleaseList,
                            parentSquad ? parentSquad.id : null,
                            !parentSquad && agency ? agency.id : null,
                            GridSelectors.getGridState(getState())
                        ))
                    }
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {
                        if (session.recipientType === ReceiverType.Agency) {
                            await dispatch(messageSend(session.recipientId, message, null, session.agencyName, session.mesageTypeId, session.subject, true));
                        } else {
                            await dispatch(messageSend(session.recipientId, message, null, session.squadName, session.mesageTypeId, session.subject, false));
                        }

                        dispatch(Actions.checkNewMessageLimit())

                        if (itemType === SearchItemType.Agency) {
                            dispatch(AgencyActivityService.sendNewMessage(session.recipientId))
                        }
                        else if (itemType === SearchItemType.Club) {
                            dispatch(ClubActivityService.sendNewMessage(session.recipientId))
                        }
                        else if (itemType === SearchItemType.Coach) {
                            const { staffId } = item as SearchCoachItemForClub;
                            dispatch(CoachActivityService.sendNewMessage(session.recipientId, staffId))
                        }
                        else {
                            const { id, recommendedFlag, isSuspendedContract, isOnReleaseList, parentSquad, agency } = item as SearchPlayerItemForClub;
                            dispatch(PlayerActivityService.sendNewMessage(
                                id,
                                recommendedFlag,
                                isSuspendedContract,
                                isOnReleaseList,
                                session.recipientType === ReceiverType.Squad && parentSquad ? parentSquad.id : null,
                                session.recipientType === ReceiverType.Agency && agency ? agency.id : null,
                                GridSelectors.getGridState(getState())
                            ))
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            if (itemType === SearchItemType.Agency) {
                dispatch(AgencyActivityService.openNewMessage(messageSession.recipientId))
            }
            else if (itemType === SearchItemType.Club) {
                dispatch(ClubActivityService.openNewMessage(messageSession.recipientId))
            }
            else if (itemType === SearchItemType.Coach) {
                const { staffId } = item as SearchCoachItemForClub;

                dispatch(CoachActivityService.openNewMessage(messageSession.recipientId, staffId))
            }
            else {
                const { id, recommendedFlag, isSuspendedContract, isOnReleaseList, parentSquad, agency } = item as SearchPlayerItemForClub;

                dispatch(PlayerActivityService.openNewMessage(
                    id,
                    recommendedFlag,
                    isSuspendedContract,
                    isOnReleaseList,
                    parentSquad ? parentSquad.id : null,
                    !parentSquad && agency ? agency.id : null,
                    GridSelectors.getGridState(getState())
                ))
            }
            dispatch(MessageModalController.openSession(messageSession, specification))
        }
    }

    public static togleRestrictedAvailableModal() {
        return async (dispatch, getState: () => AppState) => {
            const restrictedAvailableCountModal = Selectors.getRestrictedAvailableCountModal(getState())
            dispatch(stateController.setState({ restrictedAvailableCountModal: !restrictedAvailableCountModal }))
        }
    };

    public static togleRestrictedMessageModal() {
        return async (dispatch, getState: () => AppState) => {
            const restrictedMessageModal = Selectors.getRestrictedMessageModal(getState())
            dispatch(stateController.setState({ restrictedMessageModal: !restrictedMessageModal }))
        }
    };

    public static checkNewMessageLimit() {
        return async (dispatch, getState: () => AppState) => {
            const { userId } = getState().auth;
            try {
                const newMessageAvailability = await MessagingService.checkNewMessageLimit(userId)
                dispatch(stateController.setState({ newMessageAvailability: newMessageAvailability }))
            } catch (e) {
                console.error(e)
            }
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.squadSearch.message;
    public static getRestrictedAvailableCountModal = (state: AppState) => Selectors.getRoot(state).restrictedAvailableCountModal
    public static getRestrictedMessageModal = (state: AppState) => Selectors.getRoot(state).restrictedMessageModal

    public static getNewMessageCheckAvailability = (state: AppState) => Selectors.getRoot(state).newMessageAvailability
    public static isNewMessageLimitRichedOutOrHide = (state: AppState) =>
        (Selectors.getNewMessageCheckAvailability(state) || { availableCount: 5 }).availableCount <= 0
    public static isPermited = (state: AppState) => !state.auth.clubPermission || state.auth.clubPermission.canSendMessages

    public static lockMessage = (state: AppState) => !Selectors.isPermited(state) || Selectors.isNewMessageLimitRichedOutOrHide(state)
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    SendMessageModalState as State,
    Actions as Actions,
    stateController as Controller
};