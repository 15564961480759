import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { LoanInsightsService } from 'api/loan-insights/loan-insights-service';
import { CompletedLoansModels, CompletedLoanModel } from 'api/loan-insights/models/completed-loans-model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { stat } from 'fs';
import { PageType } from 'constants/enums';

class CompletedLoansState {
    isLoading: boolean;
    data: CompletedLoansModels;
    dataToDisplay: Array<CompletedLoanModel>;
    selectedSeason: string;
    seasons: Array<string>;
    avarageMinutesPlayed: number;
    instatChange: number;
    mvChange: number;
}

const defaultState: CompletedLoansState = {
    isLoading: false,
    data: null,
    dataToDisplay: null,
    selectedSeason: null,
    seasons: null,
    avarageMinutesPlayed: null,
    instatChange: null,
    mvChange: null
}

const stateController = new StateController<CompletedLoansState>(
    "LOAN_INSIGHTS/COMPLETED_LOANS",
    defaultState
);

class Actions {

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let userId = state.auth.userId;
            let squadId = state.auth.squadId;

            dispatch(stateController.setState({ isLoading: true }));
            var data: CompletedLoansModels = await LoanInsightsService.getCompletedLoansTabData(userId, squadId);
            data.completedLoans.forEach((item, index) => item.index = index)
            
            var closestSeason = null;
            var selectedSeason = null;
            var seasons = [];
            data.completedLoans.map(i => {
                let parsedSeason = i.season.split('/')[1];
                if (closestSeason < parsedSeason) { closestSeason = parsedSeason; selectedSeason = i.season };
                if (!seasons.some(x => x == i.season)) seasons.push(i.season);
            });
            var dataToDisplay = data.completedLoans.filter(i => i.season == selectedSeason);
        
            dispatch(stateController.setState({
                isLoading: false, data: data, dataToDisplay: dataToDisplay, selectedSeason: selectedSeason, seasons: data.seasons,
                avarageMinutesPlayed: data.avg[selectedSeason].avgMinsPlayedPerc, instatChange: data.avg[selectedSeason].avgInstatChange,
                mvChange: data.avg[selectedSeason].avgMvChange
            }));
        }
    }

    public static setSelectedSeason(season: string) {
        return (dispatch, getState: () => AppState) => {
            let data = getState().loanInsights.completedLoans.data;
            let dataToDisplay = data.completedLoans.filter(i => i.season == season);
            
            dispatch(stateController.setState({
                selectedSeason: season, dataToDisplay: dataToDisplay,
                avarageMinutesPlayed: data.avg[season].avgMinsPlayedPerc, instatChange: data.avg[season].avgInstatChange,
                mvChange: data.avg[season].avgMvChange
            }));
            dispatch(userActivityInsert({
                PageName: 'VLA [Completed Loans]',
                Message: `Selected period: ${season}`,
                PageType: PageType.VirtualLoanAssistant
            }))
        }
    }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }, "DISPOSING LOAN INSIGHTS"));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CompletedLoansState as State,
    Actions as Actions,
    stateController as Controller
};



