export const benefits = [
    'Expand your network through one-to-one meetings',
    'Unlock huge transfer opportunities through real-time intelligence',
    'Set up your summer transfer business and accelerate negotiations'
]

export const agentBenefits = benefits;
 


