import { StateController } from 'utils/action-declaration'
import historyAccessor from 'history-accessor';
import { AgentPlayerAgreementTypeEnum } from 'api/agency/player/shared/shared-models';
import { PlayerPreferenceService } from 'api/player-side/player-preference'
import { AppState } from 'root.reducer'
import Service from 'api/agency/player/shared/agency-player-service'
import { GeneralPlayerInfoModel } from 'api/agency/player/shared/shared-models';
import { MyAgentActivityService } from '../my-agent-flow/my-agent.activity'
import moment from 'moment';

class MyAgentState {
    expireDate: string | Date;
    agreementType: AgentPlayerAgreementTypeEnum;
    recommendedAgent: boolean;
    termsAgreed: boolean;
    agencyId: number;
    agencies: Array<any>
    isProcessing: boolean;
    sendRequestIsDone: boolean;
    isLoading: boolean;
    noSpecificExpiryDate: boolean;
}

const defaultState: MyAgentState = {
    expireDate: null,
    agreementType: AgentPlayerAgreementTypeEnum.None,
    recommendedAgent: false,
    termsAgreed: false,
    agencyId: null,
    agencies: [],
    isProcessing: false,
    sendRequestIsDone: false,
    isLoading: false,
    noSpecificExpiryDate: false,
}

const stateController = new StateController<MyAgentState>(
    'PLAYER_MY_AGENT',
    defaultState
)

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(stateController.setState({ isLoading: true }))
            let data: GeneralPlayerInfoModel = await Service.getPlayerHomePageData();
            if (data.agentToPlayerConfirmed || data.playerToAgentPending) {
                dispatch(stateController.setState({ isLoading: false }))
                historyAccessor.push('');
                return;
            }
            await dispatch(Actions.trustedAgencies())
            dispatch(stateController.setState({ isLoading: false }))
            dispatch(MyAgentActivityService.openedAddAgent(substate.auth.playerId));
        }
    }

    public static doneClick() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(MyAgentActivityService.addAgentDone(substate.auth.playerId, substate.player.addAgent.agencyId));
            historyAccessor.push('');
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static setExpireDate(date: string | Date) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            if (date !== null) {
                dispatch(MyAgentActivityService.addAgentExpiryDate(substate.auth.playerId, substate.player.addAgent.agencyId, moment(date).format("MMM D YYYY") ));
                dispatch(stateController.setState({ expireDate: date, noSpecificExpiryDate: false, }))
            } else {
                dispatch(MyAgentActivityService.addAgentNoSpecificExpiryDate(substate.auth.playerId, substate.player.addAgent.agencyId));
                dispatch(stateController.setState({ expireDate: null, noSpecificExpiryDate: true }))
            }

        }
    }

    public static setAgreementType(type: AgentPlayerAgreementTypeEnum) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(MyAgentActivityService.addAgentAgreementType(substate.auth.playerId, substate.player.addAgent.agencyId,
                type == AgentPlayerAgreementTypeEnum.None || type === null ? 'None' :
                    type == AgentPlayerAgreementTypeEnum.ContractualAgreement ? 'Contractual Agreement' :
                        'Verbal Agreement'
            ));

            dispatch(stateController.setState({ agreementType: type }))
        }
    }

    public static setRecommendedAgent(recommended: boolean) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(stateController.setState({ recommendedAgent: recommended }));
            if (recommended)
                dispatch(MyAgentActivityService.addAgentRecommend(substate.auth.playerId, substate.player.addAgent.agencyId));
        }
    }

    public static setTermsAgreed(checked: boolean) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(stateController.setState({ termsAgreed: checked }))
            if (checked)
                dispatch(MyAgentActivityService.addAgentConfirmedRep(substate.auth.playerId, substate.player.addAgent.agencyId));
        }
    }

    public static selectAgency(agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(stateController.setState({ agencyId }));
            dispatch(MyAgentActivityService.addAgentSelectedAgent(substate.auth.playerId, agencyId));
        }
    }

    public static trustedAgencies() {
        return async dispatch => {
            dispatch(stateController.setState({ isLoading: true }))

            const data = await PlayerPreferenceService.trustedAgencies()
            dispatch(stateController.setState({ agencies: data, isLoading: false }))
        }
    }

    public static saveAgentDetails() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(stateController.setState({ isProcessing: true }))

            const agent = Selectors.getRoot(getState())
            await PlayerPreferenceService.addAgent({
                agencyId: agent.agencyId,
                agreementType: agent.agreementType,
                expiryDate: agent.expireDate ? moment(agent.expireDate).toISOString() : null,
                recommendFlag: agent.recommendedAgent,
            })
            dispatch(stateController.setState({ isProcessing: false, sendRequestIsDone: true, }))
            dispatch(MyAgentActivityService.addAgentAddedAgent(substate.auth.playerId, agent.agencyId));
        }
    }

    public static cancel() {
        return (dispatch) => {
            historyAccessor.push('')
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.player.addAgent
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MyAgentState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};