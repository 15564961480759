import axios from 'axios-config'
import { AgencySuggestionsResponse, AddAgentRequest, AddAgentResponse, AreaResponse, CreateAgencyRequest, CreateAgencyResponse, AgentModel, AgencyDataModel } from './models'



export class AgencyOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getAgencyOnboardingSuggestions(keyword: string): Promise<AgencySuggestionsResponse>  {
        const { data } = await axios.post(
            `api/Suggestion/GetAgencySearchFilterSuggestion`,
            { keyword: keyword },
            this.axiosConfig
        )
        return data
    }

    public static async loadAgencyData(agencyId: number): Promise<AgencyDataModel>{
        const { data } = await axios.get(
            `api/v2/AdminAgencyOnboarding/GetAgencyOnboardingData/${agencyId}`,
            this.axiosConfig
        )
        return data
    }

    public static async loadAgents(agencyId: number): Promise<AgentModel[]> {
        const { data } = await axios.get(
            `api/v2/AdminAgencyOnboarding/GetAgentsForAgency/${agencyId}`,
            this.axiosConfig
        )
        return data
    }

    public static async loadSubscriptionPlans() {
        const { data } = await axios.get(
            `api/v2/AgencySubscription/GetAllPlansList`,
            this.axiosConfig
        )
        return data
    }

    public static async onboardAgency(agencyId, agentsToOnboard, subscriptionPlanId){
        const { data } = await axios.post(
            `api/v2/AdminAgencyOnboarding/OnboardAgency`,
            {
                agencyId: agencyId,
                agents: agentsToOnboard,
                subscriptionPlanId: subscriptionPlanId,
            },
            this.axiosConfig
        )
        return data
    }

    public static async onboardAgent(agentId: number, agencyId: number){
        const { data } = await axios.post(
            `api/v2/AdminAgencyOnboarding/OnboardAgent`,
            {
                agentId,
                agencyId
            },
            this.axiosConfig
        )
        return data
    }
    
    public static async getAreas(): Promise<AreaResponse> {
        const { data } = await axios.get(
            'api/v2/Area/GetCountries',
            this.axiosConfig
        )
        return data
    }

    public static async createNewAgency(request: CreateAgencyRequest): Promise<CreateAgencyResponse> {
        const { data } = await axios.post(
            `api/v2/AdminAgencyOnboarding/CreateAgency`,
            request,
            this.axiosConfig
        )
        return data
    }

    public static async getPhoneCodes(): Promise<any> {
        const { data } = await axios.get(
            `api/Area/GetAreaPhoneCodes`,
            this.axiosConfig
        )
        return data;
    }

    public static async createNewAgent(request: AddAgentRequest): Promise<AddAgentResponse> {
        const { data } = await axios.post(
            `api/v2/AdminAgencyOnboarding/CreateAgent`,
            request,
            this.axiosConfig
        )
        return data
    }
}