export const testimonials = [
    {
        fullName: 'Luiz Muzzi',
        position: 'General Manager',
        club: 'Orlando City SC',
        img: require('media/images/events/2023-november-lisbon/luiz-muzzi.jfif'),
        text: '"TransferRoom summits provide us with a unique opportunity to meet a variety of football stakeholders at a dedicated setting. We have been attending for a few editions now and every event provides us with invaluable connections/contacts that end up benefiting many different areas of our Club."',
    },
    {
        fullName: 'Tom Eilers',
        position: 'Football Operations',
        club: 'SV Darmstadt 98',
        img: require('media/images/events/2023-november-lisbon/tom-eilers.jfif'),
        text: '"After a number of offline events the virtual summits gave us the possibility to network in trans continental markets"',
    },
    {
        fullName: 'Carsten V. Jensen',
        position: 'Executive Football Director',
        club: 'Brondby',
        img: require('media/images/events/2023-november-lisbon/carsten-v-jensen.jpeg'),
        text: '"We open up the whole market on TransferRoom and create competition between clubs."',
    },
    {
        fullName: 'Tomasz Baldys',
        position: 'Sporting Director',
        club: 'MKS Cracovia',
        img: require('media/images/events/2023-november-lisbon/tomasz-baldys.jpeg'),
        text: '"What I appreciate in TransferRoom is direct contact with the clubs. We don’t have to use intermediaries. This is very good. It’s how it should be done and we can save a lot of money thanks to this."',
    },
    {
        fullName: 'Paul Winstanley',
        position: 'Director of Global Talent & Transfers',
        club: 'Chelsea FC',
        img: require('media/images/events/2023-november-lisbon/paul-winstanley.png'),
        text: '"TransferRoom is a great platform that allows you to connect and get straight to the point, all at the click of a button."',
    },
    {
        fullName: 'Ben Knapper',
        position: 'Loans Manager',
        club: 'Arsenal',
        img: require('media/images/events/2023-november-lisbon/ben-knapper.jfif'),
        text: '"TransferRoom can help us tap into markets we are less familiar with, and that is something we have had success with."',
    },
]