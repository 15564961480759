export const testimonials = [
    {
        fullName: 'César Camargo',
        position: 'President',
        club: 'Deportes Tolima',
        img: require('media/images/events/2023-june-sao-paolo/cesar-camargo.png'),
        text: '"TransferRoom helps us be closer to, and to meet the decision makers of, the most important teams around the world."',
    },
    {
        fullName: 'Alexandre Mattos',
        position: 'CEO',
        club: 'Club Athletico Paranaense',
        img: require('media/images/events/2023-june-sao-paolo/alexandre-mattos-2.jpg'),
        text: '"Without a doubt, TransferRoom brings together all the markets that exist in our football universe and things are happening very easily and transparently."',
    },
    {
        fullName: 'Andre Zanotta',
        position: 'Chief Soccer Officer',
        club: 'FC Dallas',
        img: require('media/images/events/2023-june-sao-paolo/andre-zanotta.png'),
        text: '"TransferRoom is a great opportunity to meet clubs from everywhere in the world and do business fast."',
    },
    {
        fullName: 'Luiz Muzzi',
        position: 'General Manager',
        club: 'Orlando City SC',
        img: require('media/images/events/2023-june-sao-paolo/luiz-muzzi.png'),
        text: '"TR provides us with a unique opportunity to meet a variety of stakeholders at a dedicated setting. We have been attending for a few editions now and every event provides us with invaluable connections."',
    },
    {
        fullName: 'Jose Castro',
        position: 'Director',
        club: 'FC Porto',
        img: require('media/images/events/2023-june-sao-paolo/jose-castro.png'),
        text: '"Great event, great organization, the improvement is notorious."',
    },
    {
        fullName: 'Rob Newman',
        position: 'Head of Recruitment',
        club: 'West Ham United',
        img: require('media/images/events/2023-june-sao-paolo/rob-newman.png'),
        text: '"When I ring people up now I have a face at the other end of the line. TR provides the networking but also the info sharing and the trust that you gain from having a short 15-minute conversation."',
    }
]