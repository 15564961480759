import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor'
import { agencyPaths } from 'routes/paths';

export enum ReonboardingSubsteppers {
    SelectPlayers = 1,
    SelectPitchablePlayers = 2,
    CreatePitches = 3,
    VerifyPlayers = 4,
}

class State {
    isLoading: boolean;
    activeStepper: ReonboardingSubsteppers;
    isCongratulationScreen: boolean;
    isHeaderHide: boolean;
}

const defaultState: State = {
    isLoading: false,
    activeStepper: ReonboardingSubsteppers.SelectPlayers,
    isCongratulationScreen: false,
    isHeaderHide: false,
}

const stateController = new StateController<State>(
    'AGENCY/REONBOARDING-FLOW',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setActiveStepper(stepper: ReonboardingSubsteppers) {
        return (dispatch) => {
            dispatch(stateController.setState({ activeStepper: stepper }));
        }
    }
    
    public static onVerticalPageScroll(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isHeaderHide: value }))
        }   
    }

    public static setIsCongratulationsScreen(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isCongratulationScreen: value }))
        }   
    }
    public static disposeScrollingEffect() {
        return (dispatch) => {
            dispatch(stateController.setState({ isHeaderHide: false }))
        }   
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.reonboarding.reonboarding;
    public static getCurrentStepper = (state: AppState) => Selectors.getRoot(state).activeStepper;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isCongratulationScreen = (state: AppState) => Selectors.getRoot(state).isCongratulationScreen;
    public static getHeaderVisibility = (state: AppState): boolean => Selectors.getRoot(state)?.isHeaderHide;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};