import axios from 'axios-config';

export class AgecnyAccessService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    public static async giveAccess(externalAgencyId: number) {
        const { data } = await axios.post(
            `api/v2/Agency/SetAccessGranted/${externalAgencyId}`,
            {},
            this.axiosConfig
        )
        return data
    }

    public static async revokeAccess(externalAgencyId: number) {
        const { data } = await axios.post(
            `api/v2/Agency/Revoke/${externalAgencyId}`,
            {},
            this.axiosConfig
        )
        return data
    }
}