import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userPaths } from 'routes/paths.js';

import { Attendee } from 'app/events/redux/virtual-summit/confirmed-attendees.controller'
import { AppState } from 'root.reducer';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import userActivityInsert, { UserActivity } from 'app/user-activity/actions/user-activity.actions';

import Attendees from 'app/events/components/authorized-event-page/common/attendees/attendees'

import Spinner from 'components/Spinner/Spinner';
import WhyAttend from 'app/events/components/authorized-event-page/common/why-attend/why-attend'
import Agenda from '../../common/agenda/agenda'
import PastEvents from 'app/events/components/authorized-event-page/common/past-events/past-events'
import { eventClubs as previousEventClubs } from '../../data/event-attendee';
import Speakers from '../../common/speakers/speakers';

import DividerLine from 'app/events/components/authorized-event-page/common/divider-line/divider-line'
import { PageType } from 'constants/enums';
import { testimonials } from '../../data/testimonials';
import { europeBenefits, americaBenefits } from '../../data/benefits';

import BannerNotRegistered from 'app/events/components/authorized-event-page/common/banners/not-registered/agency-banner'
import { getAuth } from 'store/auth/authReducer';
import { EventAgendaDayModel } from 'api/virtual-summit/models/event-agenda';
import AuthState from 'store/auth/auth-state';
import ToBeAnnouncedSoon from 'app/events/components/authorized-event-page/common/to-be-announced-soon/to-be-announced-soon';

class StateProps {
    auth: AuthState;
    confirmedClubs: Array<Attendee>;
    isUserRegistered: boolean;
    pendingAgencyRequest: boolean;
    isLoading: boolean;
    agendaDays: EventAgendaDayModel[];
}
class DispatchProps {
    userActivityInsert: (obj: UserActivity) => void;
}

class EventPageNotRegistered extends Component<StateProps & DispatchProps, {}> {

    state = {
        activeIndex: 0,
        attendees: null,
        modalVideo: false,
        modalMap: false,
    };

    toggle = modal => {
        this.setState({
            [modal]: !this.state[modal],
        });
    };

    async componentDidMount() {
    }

    async preRegisterUser(userId) {
        // this.setState({isRegistered: true});
        //  await MiscEventService.preRegisterEvent(userId);        
    }

    render() {

        const { confirmedClubs, pendingAgencyRequest, agendaDays } = this.props;

        // // Boolean describes whether to show or hide attendees component,
        // // while user see 'not registered' event page view
        // const canShowAttendeesComponent = true;

        // // Boolean describes whether to show current event confirmed attendees or previous attendees (mocked data),
        // // Clubs is hidden while there are small amount of registered clubs
        // const shouldShowConfirmedClubs = false;

        if (this.props.isLoading) return <Spinner />;

        const { eventId } = this.props.auth;
        const eventInfo = getEventInfo(eventId);
        const { title, eventDates, location } = getEventInfo(eventId);
        const { canShowAttendeesComponent, shouldShowConfirmedAttendeesForAgencies: shouldShowConfirmedAttendees } = eventInfo.visibility;

        return (
            <div className="authorized-event-page">
                
                    {/* <BannerNotRegistered
                        isRegistered={this.props.isUserRegistered}
                        pendingAgencyRequest={pendingAgencyRequest}
                        title='TransferRoom Summit São Paulo'
                        date='19-20 June, São Paulo, BR'
                        description='Exclusively for decision makers. Limited capacity and by invitation only.'
                        className="event-banner-layout"
                        showLimitedButton={false}
                        darkBackgroundMode={!this.props.isUserRegistered}
                        onRegister={() => {
                            this.props.userActivityInsert({
                                Message: 'Register Now',
                                PageName: 'Event',
                                PageType: PageType.AgencyEvents,
                                EventId: eventId,
                            })

                            historyAccessor.push(userPaths.eventRegistration);

                            //  this.preRegisterUser(auth.userId);
                        }}
                    /> */}
                
                <div style={{ height: 70 }} />
                <div className="events-page-wrapper">
                    <div className="row">

                        {/* ---> Add or remove className "big-width" to change basic latout*/}
                        <div className={`col-left content ${shouldShowConfirmedAttendees ? '' : 'big-width'} ${canShowAttendeesComponent ? '' : 'single-col-view'}`}> {/* ---> Add or remove className "big-width" to change basic latout*/}

                            <ToBeAnnouncedSoon eventName='11th TransferRoom Summit' />
                            {/* <Replay
                                trackUserActivity={() => this.props.userActivityInsert({
                                    PageName: 'Event',
                                    Message: 'Opened video (6th TransferRoom Summit)',
                                    EventId: eventId
                                })} 
                                content={{
                                    videoUrl: 'https://player.vimeo.com/video/696040095',
                                    backgroundThumb: Thumb,
                                    heading1: '6th TransferRoom Summit',
                                    heading2: 'Madrid 21st-22nd March 2022',
                                    highlightsText: 'Highlights 01:43',
                                    speakers: [
                                        { imgSrc: Troels, name: 'Troels Bech', title: 'Host and', organization: 'Moderator' },
                                        { imgSrc: Paul, name: 'Paul Mitchell', title: 'Sporting Director', organization: 'AS Monaco' },
                                        { imgSrc: Rasmus, name: 'Rasmus Ankersen', title: 'CEO and Co-Founder', organization: 'Sport Republic' },
                                    ]
                                }}
                            /> */}

                            {/* <Replay trackUserActivity={() => this.props.userActivityInsert({
                                PageName: 'Event',
                                Message: 'Opened video (6th TransferRoom Summit)',
                                EventId: eventId
                            })} /> */}
                            {
                                //!isRegistered && 
                            }
                            {/* <FloatingCTA userActivityInsert={this.props.userActivityInsert} isLoading={isLoading} isUserRegistered={isRegistered} isAgencyPage={true} /> */}

                            {/*<About />*/}
                            {/* <Speakers />
                            <WhyAttend isAgencyRegistered={this.props.isUserRegistered} benefitsEurope={europeBenefits} benefitsAmerica={americaBenefits} testimonials={testimonials} hideBottomLine={true} /> */}

                            {/* <Video
                                trackUserActivity={() => { }}
                                content={{
                                    backgroundThumb: Thumb,
                                    videoUrl: 'https://player.vimeo.com/video/696040095'
                                }}
                            /> */}

                            {/* <Agenda agendaDays={agendaDays} forAgency={true} /> */}

                            {/* UPCOMING EVENTS */}
                            {/* <div className="row small-banner-section">
                                <SmallRegularBanner
                                    style={{width: '100%'}}
                                    isRegistrationClosed={false}
                                    isRegistered={isRegistered}
                                    eventName="Virtual Summit"
                                    location="Online"
                                    dayDate="8th"
                                    monthDate="December 2020"
                                    ctaText={'Register Now!'}
                                    ctaTextCompleted={'Registered'}
                                    isGreenButton
                                    // backgroundImageSrc={virtualSummitBackground}
                                    onCTAClick={() => {
                                        this.props.userActivityInsert({ 
                                            Message: 'Register Now', 
                                            PageName: 'Event [Banner]',
                                            PageType: PageType.AgencyEvents
                                        })
                                        historyAccessor.push(userPaths.eventAddExtraAttendees)
                                    }}
                                    // additionalLocation="Online"
                                    // showMapLink="https://www.google.com/maps/place/Stamford+Bridge/@51.4816663,-0.1931452,17z/data=!3m1!4b1!4m5!3m4!1s0x48760f864b976f3d:0x48aa38781ea565f8!8m2!3d51.481663!4d-0.1909565"
                                />
                            </div> */}

                            <DividerLine title="Past Events" />
                            <PastEvents />
                        </div>


                        {canShowAttendeesComponent && (
                            <div className={`col-right ${shouldShowConfirmedAttendees ? '' : 'small-width'}`}> {/* ---> Add or remove className "small-width" to change basic latout*/}
                                <Attendees
                                    title={shouldShowConfirmedAttendees ? "Confirmed Attendees" : "Previous Attendees"}
                                    titleWrap={shouldShowConfirmedAttendees}
                                    notRegisteredState // configure sticky position for mobile devices
                                    attendees={shouldShowConfirmedAttendees ? confirmedClubs : previousEventClubs}
                                    showDetails={shouldShowConfirmedAttendees}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const { agencyEvent } = state.events;
    return {
        auth: getAuth(state),
        isUserRegistered: agencyEvent.isUserRegistered,
        confirmedClubs: state.events.virtualSummit.confirmedAttendees.attendees,
        pendingAgencyRequest: agencyEvent.isPendingRegistration,
        isLoading: agencyEvent.isLoading,
        agendaDays: agencyEvent.agendaDays,
    }
};

const mapDispatchToProps: DispatchProps = {
    userActivityInsert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPageNotRegistered);

