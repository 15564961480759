import { StateController } from 'utils/action-declaration';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import { AgentUser } from 'api/agency/agent/landing/agency-dashboard'
class State {
    isLoading: boolean
    users: Array<AgentUser>
}

const defaultState: State = {
    users: [],
    isLoading: false,
}

const stateController = new StateController<State>(
    "AGENCY/LANDING_PAGE/AGENCY_USERS",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static load() {
        return async (dispatch) => {

            dispatch(stateController.setState({ isLoading: true }))

            const data = await AgencyHomePageService.getAgencyUsers()


            dispatch(stateController.setState({
                isLoading: false,
                users: data,
            }))
        }
    }

    public static removeAgencyUser(id: number) {
        return async (dispatch) => {

            dispatch(stateController.setState({ isLoading: true }))

            await AgencyHomePageService.removeAgencyUser(id)

            dispatch(stateController.setState({
                isLoading: false,
            }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};
