import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { InvitePlayersPageService } from 'api/player-v2/invite-players/invite-players.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { GetReferralLinkResponse } from 'api/player-v2/invite-players/invite-players.models'

class State {
    isLoading: boolean;
    sharePlayerLinks: GetReferralLinkResponse;
}

const defaultState: State = {
    isLoading: false,
    sharePlayerLinks: {
        webLink: '',
        whatsappLink: '',
    }
}

const stateController = new StateController<State>(
    "PLAYERV2/INVITE-PLAYERS",
    defaultState
)

class Actions {
    public static getSherePlayerLink() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            let searchParams = new URL(window.location.href).searchParams;
            const isEa = searchParams.get("ea");
            const eaType = searchParams.get("type");

            if (isEa) {
                if (eaType === "pr") {
                    dispatch(Actions.sendUserActivity('Opened Referral Link', 'Mail [Player Referral]'));
                }
                
                if (eaType === "ss") {
                    dispatch(Actions.sendUserActivity('Clicked Share Success', 'Mail [Share Success]'));
                }
            }
            dispatch(Actions.sendUserActivity('Clicked Invite Players', 'My Page [Invite Players]'));
            dispatch(Actions.sendUserActivity('Opened Page', 'Invite Players'));
            try {

                const data = await InvitePlayersPageService.getReferralLink();

                dispatch(stateController.setState({
                    sharePlayerLinks: {
                        webLink: data.webLink,
                        whatsappLink: data.whatsappLink,
                    }
                }));

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static copySherePlayerLink() {
        return async (dispatch, getState: () => AppState) => {
            let searchParams = new URL(window.location.href).searchParams;
            const isEa = searchParams.get("ea");
            const eaType = searchParams.get("type");

            if (isEa) {
                if (eaType === "pr") {
                    dispatch(Actions.sendUserActivity('Copied Referral Link', 'Mail [Player Referral]'));
                }
            }

            const shareLink = getState().playerV2.invitePlayers.sharePlayerLinks.webLink;
            copy(shareLink)
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))
            dispatch(Actions.sendUserActivity('Copied Link', 'Invite Players'));
            
            await InvitePlayersPageService.setLinkShared('CopyLink');
        }
    }

    public static sendWhatsApp() {
        return async (dispatch, getState: () => AppState) => {
            const whatsAppReferralLink = getState().playerV2.invitePlayers.sharePlayerLinks.whatsappLink;
            const message = `I’m getting 5x more transfer interest after connecting with an agent on TransferRoom. Check it out: ${whatsAppReferralLink}`
            const encodedMessage = encodeURIComponent(message);
            const link = `https://wa.me/?text=${encodedMessage}`;

            dispatch(Actions.sendUserActivity('Clicked on WhatsApp', 'Invite Players'));
            window.open(link);

            await InvitePlayersPageService.setLinkShared('WhatsApp');
        }
    }

    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            dispatch(userActivityInsert({
                Message: message,
                PageName: `${pageName}`,
                PlayerId: playerId,
            }))
        }
    }

}

class Selectors {
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



