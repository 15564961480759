import { StateController } from 'utils/action-declaration';
import { AgentModel } from 'api/agency/club/agency-profile/models/agent.model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { AppState } from 'root.reducer';
import { IMessageSendSpecificaion, MessageSendSession } from 'components/send-message-modal/send-message-modal.controller';
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { Actions as SendMessageModalActions } from 'components/send-message-modal/send-message-modal.controller';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
class AgentsState {
    items: Array<AgentModel>
}

const defaultState: AgentsState = {
    items: []
}

const stateController = new StateController<AgentsState>(
    'AGENCY_PROFILE/AGENTS',
    defaultState
)

class Actions {
    public static saveDataToState(data: Array<AgentModel>) {
        return dispatch => {
            dispatch(stateController.setState({ items: data }))
        }
    }

    public static openSendMessageModal(toAgentId: number) {
        return async (dispatch, getState: () => AppState) => {

            const state = getState();
            const isPreview = state.agencyProfile.common.isPreview;
            if(isPreview) {
                return
            }
            const agent = state.agencyProfile.agents.items.find(item => item.id === toAgentId)

            const session: MessageSendSession = {
                subject: "New Message",
                logo: undefined,
                checkBoxTitle: null,
                officialName: agent.fullName,
                leagueName: '',
                mesageTypeId: 8,
                toClub: false,
                toAgency: true,
                agencyName: state.agencyProfile.agencyInfo.agencyName,
                agencyCity: state.agencyProfile.agencyInfo.agencyCity,
                agencyCountry: state.agencyProfile.agencyInfo.agencyCountryName,
            }

            const agencyInfo = state.agencyProfile.agencyInfo

            dispatch(userActivityInsert({
                PageName: 'Club Agents [Agency profile]',
                Message: `Opened New Message - ${agent.fullName}`,
                AgencyId: agencyInfo.agencyId,
                PageType: PageType.Agents
            }));

            const specification: IMessageSendSpecificaion = {
                sendMessage: async (session: MessageSendSession, message: string, checked: boolean): Promise<any> => {

                    dispatch(userActivityInsert({
                        PageName: 'Club Agents [Agency profile]',
                        Message: `Sent New Message - ${agent.fullName}`,
                        AgencyId: agencyInfo.agencyId,
                        ActionType: ActionType.SentNewMessage,
                        PageType: PageType.Agents,
                        UserActivityType: UserActivityType.Action
                    }));

                    await dispatch(messageSend(agencyInfo.agencyId, message, null, session.officialName, session.mesageTypeId, session.subject, true));
                },
                cancelMessage: () => {
                    dispatch(userActivityInsert({
                        PageName: 'Club Agents [Agency profile]',
                        Message: `Cancelled New Message - ${agent.fullName}`,
                        AgencyId: agencyInfo.agencyId,
                        PageType: PageType.Agents
                    }));
                }
            }

            dispatch(SendMessageModalActions.openSession(session, specification))
        }
    }

    public static showContactsDetails(agenName: string) {
        return async (dispatch, getState: () => AppState) => {
            const agencyInfo = getState().agencyProfile.agencyInfo

            const isPreview = getState().agencyProfile.common.isPreview;
            if(isPreview) {
                return
            }

            dispatch(userActivityInsert({
                PageName: 'Club Agents [Agency profile]',
                Message: `Contact details - ${agenName}`,
                AgencyId: +agencyInfo.agencyId,
                PageType: PageType.Agents
            }))
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgentsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};