import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import MySquadService from 'api/my-squad/my-squad.service'
import MySuadModel from 'api/my-squad/model/my-squad-player'
import { MySquadUpdatePlayerRequest } from 'api/my-squad/model/my-squad-update-player'
import MySquadPlayer, { MySquadToBuy, MySquadToLoan } from 'api/my-squad/model/my-squad-player'
import { MySquadActivityService } from './my-squad-activity.service'
import { monthDiffSigned } from 'services/utils';
//import { clubAdsFetchAuthForSquadV2 } from 'store/matchMaker/matchMakerActions'
import * as MySquadMatchMaker from './my-squad-matchmaker.controller'
import { getAuth } from 'store/auth/authReducer';
import { PageType, userLinkTypeEnum } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { closeMatchMakerClubAdsdModal, openMatchMakerClubAdsdModal } from 'components/match-maker/actions/match-maker-club-ads-dialog.actions';
import { LandingPageService } from 'api/landing-page/landing-page-service';
import { cloneDeep } from 'lodash';
import { Actions as FilterActions } from './my-squad-filter.controller';

export class MySquadTitle {
    id: number;
    name: string;
    count: number;
}

const AllPlayers: number = -10;
const OutOfLoan: number = -20;

class VideoEditingSession {
    playerId: number;
    videos: Array<string>;
    saving: boolean;
    title: string;
    processing: boolean;
    canSave: boolean;
}

class PlayerDeleteSession {
    playerId: number;
    processing: boolean;
    title: string;
}
export class VideoModalState {
    isVideoModalOpen: boolean;
    videoTitle: string;
    playerVideo: [];
}
class MySquadState {
    players: Array<MySuadModel>;
    pendingPlayers: Array<MySuadModel>;
    squads: Array<MySquadTitle>;
    isLoading: boolean;
    filterSquadId: number;
    filterPositions: Array<string>;
    filterContractExpiryMin: number;
    filterContractExpiryMax: number;
    filterAgeMin: number;
    filterAgeMax: number;
    filterKeyword: string;

    isEditMode: boolean;
    savingAfterEdit: boolean;
    activePlayerId: number;
    videoSession: VideoEditingSession
    playerDeleting: PlayerDeleteSession
    isLoadingSaveChanges: boolean

    videoModalState: VideoModalState

    showBunner: boolean;
    recommendedPlayersIds: Array<number>

    isMatchMakerBusy: boolean
    playersMatchMaker: object
    playersMatchMakerCounters: object
    pitchProcessing: boolean
}

const defaultState: MySquadState = {
    players: [],
    pendingPlayers: [],
    isLoading: false,
    squads: [],
    filterSquadId: AllPlayers,
    filterPositions: [],
    filterContractExpiryMin: 0,
    filterContractExpiryMax: 60,
    filterAgeMin: 16,
    filterAgeMax: 40,
    filterKeyword: '',

    isEditMode: false,
    savingAfterEdit: false,
    activePlayerId: null,
    videoSession: null,
    playerDeleting: null,
    isLoadingSaveChanges: false,

    videoModalState: {
        isVideoModalOpen: false,
        videoTitle: '',
        playerVideo: []
    },

    showBunner: false,
    recommendedPlayersIds: [],
    isMatchMakerBusy: false,
    playersMatchMaker: {},
    playersMatchMakerCounters: {},
    pitchProcessing: false
}

const stateController = new StateController<MySquadState>(
    "MYSQUAD_V2/MAIN",
    defaultState
);

class Actions {
    public static loadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();

            const { canUseMatchMaker, adsCanSeeClubNames } = appState.auth.clubPermission;
            if (canUseMatchMaker && adsCanSeeClubNames) {
                dispatch(Actions.matchMakerFetch());
            }

            let subSquads = appState.auth.squadIds;
            var withApiIds = appState.auth.squadIdWithApiIds;

            dispatch(stateController.setState({ isLoading: true }));
            let players = await MySquadService.loadMySquadPlayers();
            subSquads.sort((a, b) => {
                let findA = withApiIds[a];
                let findB = withApiIds[b];
                if (findA && findB) {
                    return Math.abs(findA) - Math.abs(findB);
                }
                return 0;
            });

            let squadSource = players.map(x => x.currentSquad);
            let squads = new Array<MySquadTitle>();
            squads.push({ id: AllPlayers, name: "All Players", count: players.length });
            for (let squad of subSquads) {
                let squadFind = squadSource.find(s => s.id == squad);
                let count = players.filter(x => x.currentSquad.id == squad || (x.parentSquad && x.parentSquad.id == squad)).length;
                if (squadFind)
                    squads.push({ id: squad, name: squadFind.name, count: count });
            }

            const outOfLoanPlayersQty = players.filter(x => x.outOfLoan).length;
            if (outOfLoanPlayersQty > 0)
                squads.push({ id: OutOfLoan, name: "Out on loan", count: outOfLoanPlayersQty });
            
            const showBunner = await MySquadService.loadRecommendedPlayersBanner();
           
            if (showBunner) {
                dispatch(stateController.setState({ showBunner: showBunner }));
            }
        
            const recommendedPlayersIds = players
                .filter(player => player.isRecommended === true && player.toBuy === null && player.toLoan === null)
                .map(player => player.id)
            dispatch(stateController.setState({ recommendedPlayersIds:  recommendedPlayersIds}));

            dispatch(stateController.setState({ isLoading: false, players: players, squads: squads }));
            dispatch(MySquadActivityService.openedSquadScreen())
        }
    }

    public static onClearFilterSquad() {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState({ filterSquadId: defaultState.filterSquadId }))
            let squads = substate.squads;
            let squad = squads.find(x => x.id == defaultState.filterSquadId);
            dispatch(MySquadActivityService.filterSelectSquad(squad.name));
        }
    }

    public static onClearFilterAge() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterAgeMin: defaultState.filterAgeMin, filterAgeMax: defaultState.filterAgeMax }))
            dispatch(MySquadActivityService.filterAgeRange(defaultState.filterAgeMin, defaultState.filterAgeMax));
        }
    }

    public static onClearFilterContractExpiry() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterContractExpiryMin: defaultState.filterContractExpiryMin, filterContractExpiryMax: defaultState.filterContractExpiryMax }))
            dispatch(MySquadActivityService.filterContractExpiryRange(defaultState.filterContractExpiryMin, defaultState.filterContractExpiryMax));
        }
    }

    public static onClearFilterPosition() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterPositions: defaultState.filterPositions }))
            dispatch(MySquadActivityService.filterSelectPositions(defaultState.filterPositions));
        }
    }

    public static onChangeFilterSquad(squadId: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState({ filterSquadId: squadId }))
            let squads = substate.squads;
            let squad = squads.find(x => x.id == squadId);
            dispatch(MySquadActivityService.filterSelectSquad(squad.name));
        }
    }

    public static onChangeFilterAge(min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterAgeMin: min, filterAgeMax: max }))
            dispatch(MySquadActivityService.filterAgeRange(min, max));
        }
    }

    public static onChangeFilterContractExpiry(min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterContractExpiryMin: min, filterContractExpiryMax: max }))
            dispatch(MySquadActivityService.filterContractExpiryRange(min, max));

        }
    }

    public static onChangeFilterPositionToggle(positionCode: string) {
        return (dispatch, getState: () => AppState) => {

            const substate = Selectors.getRoot(getState());
            let positions = substate.filterPositions;
            if (positions.includes(positionCode)) {
                positions = [...positions.filter(x => x != positionCode)];
            }
            else {
                positions = [...positions, positionCode];
            }

            dispatch(stateController.setState({ filterPositions: positions }))
            dispatch(MySquadActivityService.filterSelectPositions(positions));
        }
    }

    public static onSearchKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterKeyword: keyword }))
        }
    }

    public static activeEditMode() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isEditMode: true }))
            dispatch(MySquadActivityService.edit());
        }
    }

    public static cancelEditMode() {

        // remove pending
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isEditMode: false, pendingPlayers: [] }))
            dispatch(MySquadActivityService.cancel());
        }
    }

    public static saveAfterEdit() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ savingAfterEdit: true }))
            const substate = Selectors.getRoot(getState());

            let currentPendingPlayers: Array<MySquadUpdatePlayerRequest> = substate.pendingPlayers.map(item => {
                return {
                    playerId: item.id,
                    secondPositionCode: item.secondPositionCode,
                    firstPositionCode: item.firstPositionCode,
                    contractExpiration: item.contractExpiration
                }
            })

            try {
                await MySquadService.updatePlayers(currentPendingPlayers);
                let currentPlayersSynced = [...substate.players.map(item => {
                    let updated = substate.pendingPlayers.find(x => x.id == item.id);
                    if (updated == null) {
                        return item;
                    }
                    else return updated;
                })]
                dispatch(stateController.setState({ savingAfterEdit: false, isEditMode: false, pendingPlayers: [], players: currentPlayersSynced }))
                dispatch(MySquadActivityService.save())
            }
            catch {
                dispatch(stateController.setState({ savingAfterEdit: false, isEditMode: false, pendingPlayers: [] }))
            }

        }
    }

    public static selectPlayer(playerId: number) {
        return (dispatch, getState: () => AppState) => {

            const substate = Selectors.getRoot(getState());
            let currentActivePlayerId = substate.activePlayerId;

            if (currentActivePlayerId == playerId) {
                currentActivePlayerId = null;
            }
            else {
                currentActivePlayerId = playerId;
            }

            dispatch(stateController.setState({ activePlayerId: currentActivePlayerId }))
            if (currentActivePlayerId != null) {
                dispatch(MySquadActivityService.openAvailability(currentActivePlayerId));

            }
        }
    }

    public static openVideoModal(playerId: number) {        
        return (dispatch, getState: () => AppState) => {
                    
            const substate = Selectors.getRoot(getState());
            const player = substate.players.find(x => x.id == playerId);
            const video = player.videos.map(item => {
                return {
                    id: item,
                    url: item
                }
            })
            
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                videoModalState: {
                    ...prevState.videoModalState,
                    isVideoModalOpen: true,
                    videoTitle: player.englishShortName,
                    playerVideo: video,
                }
            })))
        }
    }

    public static closeVideoModal() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                videoModalState: {
                    ...prevState.videoModalState,
                    isVideoModalOpen: false,
                    videoTitle: '',
                    playerVideo: []
                }
            })))
        }
    }

    public static initVideoEditing(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            let substate = Selectors.getRoot(getState());

            let player = substate.players.find(x => x.id == playerId);

            let session: VideoEditingSession = {
                playerId: playerId,
                videos: [...player.videos],
                saving: false,
                title: player.shortName,
                processing: false,
                canSave: false
            }

            dispatch(stateController.setState({
                videoSession: session
            }))
        }
    }

    public static closeVideoEditing() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                videoSession: null
            }))
        }
    }

    public static setVideosInCurrentSession(videos: Array<string>) {
        return (dispatch, getState: () => AppState) => {
            let substate = Selectors.getRoot(getState());

            let player = substate.players.find(x => x.id == substate.videoSession.playerId);
            let canSave = videos.sort().join('') != player.videos.sort().join('');
            let session: VideoEditingSession = { ...substate.videoSession, videos: videos, canSave: canSave };

            dispatch(stateController.setState({
                videoSession: session
            }))
        }
    }

    public static saveVideosInSession() {
        return async (dispatch, getState: () => AppState) => {
            let substate = Selectors.getRoot(getState());

            let session: VideoEditingSession = { ...substate.videoSession, processing: true };
            dispatch(stateController.setState({
                videoSession: session
            }))

            let videosToSave = session.videos;

            let players = substate.players.map(item => {
                if (item.id == session.playerId) {
                    return { ...item, videos: videosToSave };
                }
                return item;
            })

            try {
                await MySquadService.saveVideos(session.playerId, videosToSave);
                dispatch(MySquadActivityService.addedVideo(session.playerId));

                dispatch(stateController.setState({
                    videoSession: null,
                    players: players
                }))
            }
            catch {
                dispatch(stateController.setState({
                    videoSession: null,
                }))
            }
        }
    }

    public static editSetContractExpiration(playerId: number, contractExpiration?: Date) {
        return (dispatch, getState: () => AppState) => {
            let substate = Selectors.getRoot(getState());
            let currentPendingCollection = [...substate.pendingPlayers];
            let player = currentPendingCollection.find(x => x.id == playerId);

            if (player == null) {
                player = substate.players.find(x => x.id == playerId);
                currentPendingCollection.push(player);
            }

            let playerInEdit = currentPendingCollection.find(x => x.id == playerId);
            let mutation = { ...playerInEdit, contractExpiration: contractExpiration, contractExpirationInMonthes: monthDiffSigned(contractExpiration, new Date()) }

            let pendingPlayers = currentPendingCollection.map(item => {
                if (item.id == playerId) {
                    return mutation;
                }
                return item;
            })
            dispatch(stateController.setState({
                pendingPlayers: pendingPlayers,
            }))
            dispatch(MySquadActivityService.onEditContractExpiration(playerId, contractExpiration));
        }
    }

    public static editPosition(playerId: number, firstPositionCode: string, secondPositionCode: string) {
        return (dispatch, getState: () => AppState) => {
            let substate = Selectors.getRoot(getState());
            let currentPendingCollection = [...substate.pendingPlayers];
            let player = currentPendingCollection.find(x => x.id == playerId);

            if (player == null) {
                player = substate.players.find(x => x.id == playerId);
                currentPendingCollection.push(player);
            }

            let playerInEdit = currentPendingCollection.find(x => x.id == playerId);
            let mutation = { ...playerInEdit, firstPositionCode: firstPositionCode, secondPositionCode: secondPositionCode }

            let pendingPlayers = currentPendingCollection.map(item => {
                if (item.id == playerId) {
                    return mutation;
                }
                return item;
            })


            dispatch(stateController.setState({
                pendingPlayers: pendingPlayers,
            }))

            dispatch(MySquadActivityService.onEditPosition(playerId, firstPositionCode, secondPositionCode));

        }
    }

    public static updateAvailability(playerId: number, toBuy: MySquadToBuy, toLoan: MySquadToLoan) {
        return async (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState());
            const isPlayerRecommended = subState.recommendedPlayersIds.includes(playerId)
            try {
                dispatch(stateController.setState({ isLoadingSaveChanges: true }))
                var clubAds = await MySquadService.saveAvailabilityAndGetClubAds(playerId, toBuy, toLoan);
                MySquadService.quickSearchFlush();
                let player = subState.players.find(x => x.id == playerId);
                let previousToBuy = { ...player.toBuy };
                let previousToLoan = { ...player.toLoan };
                player = { ...player, toBuy: toBuy, toLoan: toLoan };
                let newPlayers = [...subState.players.map(item => {
                    if (item.id == playerId) {
                        return player;
                    }
                    return item;
                })]
                dispatch(stateController.setState({
                    players: newPlayers,
                    activePlayerId: null
                }));
                dispatch(MySquadActivityService.availabilitySaved(playerId, { buy: previousToBuy, loan: previousToLoan }, { buy: toBuy, loan: toLoan }))

                if (isPlayerRecommended && (toBuy !== null || toLoan !== null)) {
                    const newIds = subState.recommendedPlayersIds.filter(id => id !== playerId)
                    dispatch(stateController.setState({ recommendedPlayersIds: newIds }));
                }
                await dispatch(MySquadMatchMaker.Actions.setClubAds(player, clubAds));
                dispatch(Actions.matchMakerFetch());

            }
            catch (e) {
                console.error(e);
            }
            finally {
                dispatch(stateController.setState({ isLoadingSaveChanges: false }))
            }
        }
    }

    public static initDeletePlayer(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            let state = Selectors.getRoot(getState());
            let player = state.players.find(x => x.id == playerId);

            dispatch(stateController.setState({ playerDeleting: { playerId: playerId, processing: false, title: player.shortName } }))
            dispatch(MySquadActivityService.openRemovePlayer(playerId));

        }
    }

    public static cancelDeleting() {
        return (dispatch, getState: () => AppState) => {
            let currentDeleting = Selectors.getRoot(getState()).playerDeleting;
            dispatch(MySquadActivityService.cancelRemovePlayer(currentDeleting.playerId));
            dispatch(stateController.setState({ playerDeleting: null }));
        }
    }

    public static beginDeleting(reason: string) {
        return async (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState());

            let deleteSession = subState.playerDeleting;

            dispatch(stateController.setState({ playerDeleting: { ...deleteSession, processing: true } }))

            await MySquadService.deletePlayer(deleteSession.playerId, reason);
            let players = subState.players.filter(x => x.id != deleteSession.playerId);

            dispatch(stateController.setState({ playerDeleting: null, players: players }))
            dispatch(MySquadActivityService.removedPlayer(deleteSession.playerId, reason));

        }
    }

    public static hideBanner() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(MySquadActivityService.closeBunner());
            await MySquadService.hideRecommendedPlayersBanner();
            dispatch(stateController.setState({ showBunner: false}))
        }
    }

    public static matchMakerFetch(){
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const { squadId, userId } = getAuth(appState);
            dispatch(stateController.setState({ isMatchMakerBusy: true }));
            try {
                const data = await MySquadService.matchMakerFetch(squadId, userId);
                const playersMatchMakerCounters = {};
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const element = data[key];
                        playersMatchMakerCounters[key] = element.length;
                    }
                }
                
                dispatch(stateController.setState({ 
                    playersMatchMaker: data, 
                    playersMatchMakerCounters
                }));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isMatchMakerBusy: false }));
            }
        }
    }

    public static matchMakerDeclinePlayer(data, player){
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = Selectors.getRoot(getState());
                const firstPlayerAd = substate.playersMatchMaker[data.playerId][0]
               
                await LandingPageService.declineAd(data.playerId, data.playerAdId);
                dispatch(closeMatchMakerClubAdsdModal());
                const adId = data.playerAdId;
                const playerId = data.playerId;
                const playersMatchMakerCopy = cloneDeep(substate.playersMatchMaker);
                const playerMatchMaker = playersMatchMakerCopy[playerId];
                if (playerMatchMaker) {
                    playersMatchMakerCopy[playerId] = playerMatchMaker.filter(x => x.id !== adId);
                    if (playersMatchMakerCopy[playerId].length === 0) {
                        delete playersMatchMakerCopy[playerId];
                    }
                }

                dispatch(stateController.setState({ playersMatchMaker: playersMatchMakerCopy }))

                const playerMm = playersMatchMakerCopy[playerId];
                if (playerMm && playerMm.length !== 0) {
                    dispatch(openMatchMakerClubAdsdModal(player));
                }
                
                dispatch(userActivityInsert({
                    Message: 'Declined: "' + firstPlayerAd.squad.officialName + '"',
                    PageName: 'Squad [Recommender]',
                    PlayerId: data.playerId,
                    PageType: PageType.Squad
                }))
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static matchMakerPitchPlayer(payload){
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const { userId, squadId } = getAuth(getState());
            const data = {
                ...payload,
                userId: userId,
                squadId: squadId,
            };
            try {
                dispatch(stateController.setState({ pitchProcessing: true }))
                await LandingPageService.pitchPlayer(userId, data.playerAdId, data.amount, data.playerId, squadId, null);

                const adId = data.playerAdId;
                const playerId = data.playerId;
                const firstPlayerAd = substate.playersMatchMaker[playerId][0];
                const playersMatchMakerCopy = cloneDeep(substate.playersMatchMaker);
                const playerMatchMaker = playersMatchMakerCopy[playerId];
                if (playerMatchMaker) {
                    playersMatchMakerCopy[playerId] = playerMatchMaker.filter(x => x.id !== adId);
                    if (playersMatchMakerCopy[playerId].length === 0) {
                        delete playersMatchMakerCopy[playerId];
                    }
                }
                dispatch(stateController.setState({ playersMatchMaker: playersMatchMakerCopy }))

                dispatch(closeMatchMakerClubAdsdModal());
        
                const playerMm = playersMatchMakerCopy[playerId];
                if (playerMm && playerMm.length !== 0) {
                    dispatch(openMatchMakerClubAdsdModal(data.player));
                }
        
                dispatch(userActivityInsert({
                    Message: 'Pitched to: "' + firstPlayerAd.squad.officialName + '"',
                    PageName: 'Squad [Recommender]',
                    PlayerId: data.playerId,
                    PageType: PageType.Squad,
                    Links: [{ 
                        Type: userLinkTypeEnum.PlayerAd,
                        TargetId: firstPlayerAd.id
                    }]
                }))
                
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ pitchProcessing: false }))
            }
        }
    }

    public static matchMakerUpdate(playerId: number, selectedAdsIds: number[]){
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            let playersMatchMakerCopy = cloneDeep(substate.playersMatchMaker);
            let playerMatchMaker = playersMatchMakerCopy[playerId];
            if (playerMatchMaker) {
                playersMatchMakerCopy[playerId] = playerMatchMaker.filter(i => !selectedAdsIds.includes(i.id));
                if (playersMatchMakerCopy[playerId].length === 0) {
                    delete playersMatchMakerCopy[playerId];
                }
            }

            dispatch(stateController.setState({ playersMatchMaker: playersMatchMakerCopy }));
        }
    }

    public static disposeMySquadScreenState(){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
            dispatch(FilterActions.dispose());
        }
    }    
}

class Selectors {
    public static getRoot = (state: AppState) => state.mySquadV2.mySquad;

    public static computeSearchedPlayers(subState: MySquadState) {
        var items = subState.players;
        if (subState.filterSquadId != AllPlayers) {
            if (subState.filterSquadId == OutOfLoan) {
                items = [...items.filter(x => x.outOfLoan)]
            }
            else {
                items = [...items.filter(x => x.currentSquad.id == subState.filterSquadId || (x.parentSquad && x.parentSquad.id == subState.filterSquadId))];
            }
        }

        items = [...items.filter((x) => {
            if (x.age == null) return true;
            return x.age >= subState.filterAgeMin && x.age <= subState.filterAgeMax;
        })]

        items = [...items.filter((x) => {
            if (x.contractExpirationInMonthes == null) return true;
            return x.contractExpirationInMonthes >= subState.filterContractExpiryMin && (subState.filterContractExpiryMax === defaultState.filterContractExpiryMax || x.contractExpirationInMonthes <= subState.filterContractExpiryMax);
        })]

        if (subState.filterPositions.length > 0) {
            items = [...items.filter((x) => {
                return subState.filterPositions.includes(x.firstPositionCode) || subState.filterPositions.includes(x.secondPositionCode);
            })]
        }

        if (subState.filterKeyword.length > 0) {
            items = [...items.filter((x) => {
                return x.shortName.toLocaleUpperCase().includes(subState.filterKeyword.toLocaleUpperCase()) ||
                x.englishShortName.toLocaleUpperCase().includes(subState.filterKeyword.toLocaleUpperCase());
            })]
        }

        if (subState.isEditMode) {
            items = items.map(item => {
                let inEdit = subState.pendingPlayers.find(x => x.id == item.id);
                if (inEdit != null) return inEdit;
                else return item;
            })
        }

        return items;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MySquadState as State,
    Selectors as Selectors,
    Actions as Actions,
    stateController as Controller
};



