import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { PageType } from 'constants/enums';
import { SUMMIT_SURVEY } from 'constants/modals';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { UserAttentionService as Service } from 'api/user-attention/user-attention.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAuth } from 'store/auth/authReducer';
import { MADRID_SUMMIT_AUGUST_2024_ID } from 'app/events/components/authorized-event-page/events-map/constants';

export const ACTIVE_SURVEY_EVENT_ID = MADRID_SUMMIT_AUGUST_2024_ID;
const AGENCY_SURVEY_URL = 'https://4x4w69dqcpl.typeform.com/to/Ai5k7Aye';
const CLUB_SURVEY_URL = 'https://4x4w69dqcpl.typeform.com/to/DKjJCw7s';

class State {
    isProcessing: boolean;
}

const defaultState: State = {
    isProcessing: false
}

const stateController = new StateController<State>(
    "SUMMIT_SURVEY",
    defaultState
)

class Actions {
    public static loadSummitSurvey(eventId: number) {
        return async (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState())
            dispatch(stateController.setState({ isProcessing: true }));
            const toShow = await Service.showSurvey(auth.userId, eventId);
            if (toShow) {
                const command = { 
                    type: MODAL_OPEN, 
                    payload: { 
                        id: SUMMIT_SURVEY, 
                        customToggle: () => dispatch(Actions.closeModalWithTracking(eventId)) 
                    }
                };
                dispatch(command);
                await Service.surveyShown(auth.userId, eventId);
            }
        }
    }

    public static closeModal() {
        return async (dispatch, getState: () => AppState) => {
            let command = { type: MODAL_CLOSE, payload: SUMMIT_SURVEY, };
            dispatch(command);
        }
    };

    public static closeModalWithTracking(eventId: number) {
        return async (dispatch, getState: () => AppState) => {
            let command = { type: MODAL_CLOSE, payload: SUMMIT_SURVEY, };
            const auth = getAuth(getState());

            dispatch(command);
            if (auth.adminImpersonateId === null) {
                const { agencyId, squadId } = auth;
                const isAgency = agencyId > 0;
                dispatch(userActivityInsert({
                    Message: `Closed Pop-up`,
                    PageName: 'Event [Survey]',
                    CurrentClubId: isAgency ? -1 : squadId,
                    PageType: isAgency ? PageType.AgencyEvents : PageType.Events,
                    EventId: eventId
                }));
            }
        }
    };

    public static takeSurvey(eventId: number) {
        return async (dispatch, getState: () => AppState) => {


            const { squadId, agencyId, adminImpersonateId } = getAuth(getState())
            const isAgency = agencyId > 0;
            const surveyUrl = isAgency ? AGENCY_SURVEY_URL: CLUB_SURVEY_URL;
                            
            if (adminImpersonateId === null) {
                dispatch(userActivityInsert({
                    Message: `Proceed Pop-up`,
                    PageName: 'Event [Survey]',
                    CurrentClubId: isAgency ? -1 : squadId,
                    PageType: isAgency ? PageType.AgencyEvents : PageType.Events,
                    EventId: eventId
                }));
            }
            var win = window.open(surveyUrl, '_blank');
            win.focus();
            dispatch(Actions.closeModal());
        }
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};