import { StateController } from 'utils/action-declaration'
import { ShortlistService } from 'api/shortlist/shortlist-service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { AppState } from 'root.reducer'
import { gerRankingPlayerById } from './playerRanking.service'
import { getSimilarPlayersById } from 'app/player-info/reducer/player-info.reducer'
import { ActionType, PageType } from 'constants/enums'
import { isAgencyUser, isPlayerUser } from 'store/auth/authReducer';
import { PlayerService } from 'api/player/player-service';
import { getPlayerProfile } from './profileReducer';

class ProfileCommonState {
    shortlistedIds: Array<number>;
    messageId: number | null
}

const defaultState: ProfileCommonState = {
    shortlistedIds: [],
    messageId: null,
}

const stateController = new StateController<ProfileCommonState>(
    'PROFILE_COMMON',
    defaultState
)

class Actions {

    public static trackProfileLoad(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            await PlayerService.trackProfileLoad(playerId);
        }
    }

    public static loadShortlistedIds() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let isUserAgent = state.auth.agentId != null && state.auth.agentId > 0;
            const data = isUserAgent ? await ShortlistService.loadAgencyShortListedPlayers() : await ShortlistService.loadShortListedPlayers()
            dispatch(stateController.setState({ shortlistedIds: data }))
        }
    }

    public static loadRepresentationRequest(playerId:number) {
        return async (dispatch) => {
            const messageId = await PlayerService.profileRepresentationRequestLoad(playerId);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                messageId,
            })))
        }
    }

    public static shortlistToggle(playerId: number, isInShortlist: boolean, pageName?: string) {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const player = getPlayerProfile(appState);
            const isUserAgent = isAgencyUser(appState);
            dispatch(Actions.shortlistToggleProccess(
                isUserAgent,
                playerId,
                isInShortlist,
                'Player Profile',
                player.parentSquad?.id ? player.parentSquad?.id : player.currentSquad?.id,
                player.agencyRelatedInfo.agencyId,
            ))
        }
    }

    public static shortlistToggleRanking(playerId: number, isInShortlist: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const player = gerRankingPlayerById(playerId, state)
            const isUserAgent = isAgencyUser(state);
            const isPlayer = isPlayerUser(state);

            dispatch(Actions.shortlistToggleProccess(
                isUserAgent,
                playerId,
                isInShortlist,
                'Player Profile [Ranking]',
                isPlayer ? null : player.parentSquad?.id,
                isPlayer ? null : player.agencyId,
            ))
        }
    }

    public static shortlistToggleSimilar(playerId: number, isInShortlist: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const player = getSimilarPlayersById(playerId, getState());

            let state = getState();
            let isUserAgent = state.auth.agentId != null && state.auth.agentId > 0;
            dispatch(Actions.shortlistToggleProccess(
                isUserAgent,
                playerId,
                isInShortlist,
                'Player Profile [Similar players]',
                player.parentSquadId ? player.parentSquadId : player.currentSquadId,
                player.agencyId,
            ))
        }
    }

    public static addToShortList(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().playerProfileCommon;
            let isInShortList = substate.shortlistedIds.includes(playerId);

            if (isInShortList) 
                return;
            
            try {
                await ShortlistService.addToShortlist(playerId);
                dispatch(stateController.setState(prevState => ({ shortlistedIds: [...prevState.shortlistedIds, playerId] })))
            } catch (e) {
                console.error(e)
            }
        }
    }

    private static shortlistToggleProccess(
        isUserAgent: boolean,
        playerId: number,
        isInShortlist: boolean,
        pageName: string,
        clubId?: number,
        agencyId?: number) {
        return async (dispatch) => {
            try {
                if (isInShortlist) {
                    dispatch(stateController.setState(prevState => ({ shortlistedIds: prevState.shortlistedIds.filter(item => item !== playerId) })))
                    isUserAgent ? await ShortlistService.agencyRemoveFromShortlist(playerId) : await ShortlistService.removeFromShortlist(playerId)
                } else {
                    dispatch(stateController.setState(prevState => ({ shortlistedIds: [...prevState.shortlistedIds, playerId] })))
                    isUserAgent ? await ShortlistService.agencyAddToShortlist(playerId) : await ShortlistService.addToShortlist(playerId)
                }

                dispatch(userActivityInsert({
                    PageName: pageName ? pageName : null,
                    Message: isInShortlist ? 'Removed from Shortlist' : 'Added to Shortlist',
                    PlayerId: playerId,
                    ClubId: clubId,
                    AgencyId: agencyId,
                    ActionType: isInShortlist ? null : ActionType.AddToShortlist,
                    PageType: PageType.PlayerProfile
                }))
            } catch (e) {
                console.error(e)

                if (isInShortlist) {
                    dispatch(stateController.setState(prevState => ({ shortlistedIds: [...prevState.shortlistedIds, playerId] })))
                } else {
                    dispatch(stateController.setState(prevState => ({ shortlistedIds: prevState.shortlistedIds.filter(item => item !== playerId) })))
                }
            }
        }
    }

    public static onRatingHover() {
        return async (dispatch, getState: () => AppState) => {
            const player = getState().playerProfile.details;

            dispatch(userActivityInsert(
                {
                    Message: 'Opened Player Rating Info',
                    PageName: 'Player Profile',
                    PlayerId: player.id,
                    ClubId: player.parentSquad ? player.parentSquad.id : null,
                    AgencyId: player?.agencyRelatedInfo?.agencyId ? player.agencyRelatedInfo.agencyId : null,
                    PageType: PageType.PlayerProfile
                },
            ))
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ProfileCommonState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};