import { AgencyShortInfo } from "api/core/agency-short-info";
import { SquadShortInfo } from "api/core/squad-short-info";
import { ComparisonLists, ComparisonModel } from "api/super-pitch/models/comparison";

export enum CloseReason {
    Default = 0,
    PlayerLevel = 1,
    ClubNotLookingForPosition = 2,
    ClubLookingForYoungerPlayers = 3,
    FeeIsTooHigh = 4,
    SalaryOutOfRange = 5,
    NotNow = 6,
    Other = 7

}

export enum CloseType {
    Default = 0,
    Won = 1,
    Lost = 2,
    Expired = 3
}

export enum PitchSquadInterestType{
    UserInterest = 1,
    View = 2,
    Share = 3
}

export interface CustomPitchCounters {
    views: number;
    shares: number;
    interests: number;
    viewsLast7Days: number;
    sharesLast7Days: number;
    interestsLast7Days: number;
}

export interface ListResponse {
    counters: CustomPitchCounters;
    sentPitches: CustomPitchSent[];
}

export interface CustomPitchSent {
    pitchedPlayer: RelatedPitchPlayerModel;
    pitchesCount: number;
    views: number;
    shares: number;
    interests: number;
    counters: CustomPitchCounters;

    closedInfo: ClosedInfo;
    openedInfo: OpenedInfo;

    sentSquads: CustomPitchSentSquad[];
}

export interface ClosedInfo {
    closedLost: number;
    closedWon: number;
    closedExpired: number;
}

export interface OpenedInfo {
    creationDate: Date | string;
    lastActionDate: Date | string;
    pitchesNearingExpiry: number;
}

export interface CustomPitchSentSquad {
    customPitchSentSquadId: number;
    publishedUid: string;
    squad: SquadShortInfo;
    fee: FormattedPriceModel;
    financialsTBD?: boolean;
    salary: FormattedPriceModel;
    loanFee: FormattedPriceModel;
    currencyId: number;
    views: number;
    shares: number;
    interests: number;
    createdAt: Date | string;

    closedInfoSquad: ClosedInfoSquad;
    openedInfoSquad: OpenedInfoSquad;
}

export interface FormattedPriceModel {
    price: number;
    formattedComma: string; // "3,000,00"
    formattedKMB: string; // "3m"
}

export interface ClosedInfoSquad {
    closedAt: Date | string;
    reason: CloseReason;
    type: CloseType;
}

export interface OpenedInfoSquad {
    expiresAt: Date | string;
    publishedUid: string;
    lastAction: Date | string;
    isNearingExpiry: boolean;
}

export interface RelatedPitchPlayerModel {
    id: number;
    photo: string;
    age: number | null;
    height: number | null;
    englishFirstName: string;
    englishLastName: string;
    englishShortName: string;
    birthArea: IdName;
    passportArea: IdName;
    firstPositionCode: string;
    secondPositionCode: string;
    clubInfo: PitchPlayerModel;
}

export interface IdName {
    id: number;
    name: string;
    shortName: string;
}

export interface PitchPlayerModel {
    id: number | null;
    onLoanSquadName: string;
    contractExpiration: Date | string | null;
    onLoanSquadTillDate: Date | string | null;
    parentSquad: IdName;
    isFreeAgent: boolean;
    latestSquad: IdName;
    parentSquadCompetitionName: string;
    parentSquadCompetitionCountry: string;
    logo: string;
}

export interface PlayerTransfer {
    id: number;
    playerId: number;
    fromSquadId: number | null;
    toSquadId: number | null;
    active: boolean;
    startDate: Date | string;
    endDate: Date | string | null;
    transferTypeId: number | null;
    value: number;
    currencyId: number;
    currencyCode: string;
    announcedDate: Date | string | null;
    transferType: TransferTypeModel;
    fromSquad: SquadShortInfo;
    toSquad: SquadShortInfo;
}

export interface TransferTypeModel {
    id: number;
    name: string;
}

export interface TransferBenchmark {
    playerId: number;
    playerLastName: string;
    percentMatch: number;
    age: number;
    fromSquadName: string;
    toSquadName: string;
    price: FormattedPriceModel;
    year: number;
    currencyId: number;
}

export interface CreateCustomPitchRequest {
    playerId: number;
    fee: number | null;
    loanFee: number| null;
    salary: number | null;
    financialsTBD: boolean | null;
    recipientsSquadId: number;
    message: string;
    messageHeadline: string;
    video: string;
    comparisonKeys: string[];
    head2HeadPlayerId: number;
    aboutAgency: string;

    isMessageVisibleOnPitch: boolean;
    isVideoVisibleOnPitch: boolean;
    isComparisonKeysVisibleOnPitch: boolean;
    isHead2HeadVisibleOnPitch: boolean;
    isTransferHistoryVisibleOnPitch: boolean;
    isXtvBenchmarksVisibleOnPitch: boolean;
    isAboutAgencyVisibleOnPitch: boolean;
    comparisonCustomization:{ [key: string]: Array<{comparisonPlayerIds: Array<number>}> };
}

export interface VerifiedPlayer {
    id: number;
    name: string;
    firstPositionName: string;
    secondPositionName: string | null;
    squad: SquadShortInfo;
    xTV: string;
}

export interface SquadToPitch {
    isDisabled: boolean;
    squad: SquadShortInfo;
}

export interface PublishPitchInfo {
    isActive?: boolean;
    pitchSquadId: number;
    publishedUid: string,
    backgroundPicture: string;
    pitchHeadline: string;
    pitchMessage: string;
    fee: FormattedPriceModel;
    salary: FormattedPriceModel;
    loanFee: FormattedPriceModel;
    currencyId: number;
    financialsTBD?: boolean;
    player: PublishPitchPlayerInfo;
    video: string;
    aboutAgency: string;
    comparisonSelection: ComparisonModel;
    comparisonResult: ComparisonLists;
    squadId: number;
    recipientSquad: SquadShortInfo;
    backgroundImage: string;

    head2HeadInfo: HeadToHeadInfo;

    transferHistory: PlayerTransfer[];
    xtvBenchmarks: TransferBenchmark[];

    agencyInfo: AgencyShortInfo;
    morePlayers: PublishPitchPlayerInfo[];
}

export interface PublishPitchPlayerInfo {
    id: number;
    photo: string;
    name: string;
    parentSquad: SquadShortInfo;
    latestSquad: SquadShortInfo;
    onLoanTo: SquadShortInfo;
    contractExpiry: Date;
    nationality: string;
    nationalityShort: string;
    position: string;
    positionShort: string;
    age: number;
    height: number;
    foot: string;
    gbeStatus: string;

    xTV: string; //for more agency players section
    trend: number; //for more agency players section
}

export class CustomPitchComparisonRequest {
    keys: Array<string>;
    squadId?: number;
}

export interface CustomPitchPreviewInfo {
    player: PublishPitchPlayerInfo;
    morePlayers: PublishPitchPlayerInfo[];
}

export interface LogCustomPitchRequest {
    userId?: number;
    pitchSquadId: number;
    type: PitchSquadInterestType;
}

export interface HeadToHeadInfo {
    key: number;
    availableSeasons: number[];
    seasonNames: any;
    season: number;
    firstPlayer: any;
    secondPlayer: any;
    thirdPlayer: any;
    average: any;
}

export type SuggestionItem = {
    id: number;
    name: string;
    squad: SquadShortInfo;
}

export interface GetInTouchRequest {
    agencyId: number;
    playerName: string;
    bodyText: string;
    name: string;
    position: string;
    email: string;
    club: string;
}

export class InitInfo {
    created: number;
    left: number;
    isCustomPitchActive: boolean;
}

export class PlayerAdditionalInfo {
    aboutAgency: string;
    messageHeadline: string;
    message: string;
    video: string;
    transferBenchmarks: TransferBenchmark[];
    transferHistory: PlayerTransfer[];
}