import axios from 'axios-config';
import { AutosuggestItem } from './profile-search.model';

export class ProfileSearchService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    public static async getSearchSuggestions(keyword: string): Promise<Array<AutosuggestItem>> {
        
        const encodedKeyword = encodeURIComponent(keyword)
        const uri = `api/v2/AgencySuggestions/?keyword=${encodedKeyword}`

        const { data } = await axios.get(
            uri,
            this.axiosConfig
        )
        return data
    }
}