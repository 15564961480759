import * as types from 'store/actionTypes';
import compose from 'lodash/fp/compose';
import { combineReducers } from 'redux';
import { AppState } from 'root.reducer';
import { PlayerComparisonModel } from '../models/player-comparison.model';
import { ComparedPlayerModel } from '../models/compared-player.model';
import { SuggestionModel } from '../models/suggestion.model';

export type PlayersComparisonRootState = {
    byId: { [playerId: number]: PlayersComparisonState }
}

type PlayersComparisonByIdState = {
    [playerId: number]: PlayersComparisonState
}

type PlayersComparisonState = {
    availableSeasons: number[];
    seasonNames: { [key: number]: string }[];
    comparison: PlayerComparisonModel;
    secondPlayer?: SuggestionModel;
    thirdPlayer?: SuggestionModel;
    comparisonError: boolean;
}

const initialState: PlayersComparisonByIdState = {
    
};

const byId = (playersComparison = initialState, action): PlayersComparisonByIdState => {
    switch (action.type) {
        case types.PLAYER_ADS_ACTIVITIES_COMPARISON_FETCH.SUCCESS: {
            const newValue = {
                ...playersComparison,
            };
            action.payload.data.forEach(c => {
                newValue[c.key] = {
                    ...playersComparison[c.key],
                    comparison: c,
                    availableSeasons: c.availableSeasons,
                    seasonNames: c.seasonNames,
                };
            });
            return newValue;
        }
        case types.PLAYERS_COMPARISON_FETCH.SUCCESS: {
            const comparison: PlayerComparisonModel = action.payload.data;
            const { playerId } = comparison.firstPlayer;
            const newValue = {
                ...playersComparison,
                [playerId]: {
                    ...playersComparison[playerId],
                    comparison: comparison,
                    availableSeasons: comparison.availableSeasons
                        ? comparison.availableSeasons
                        : playersComparison[playerId].availableSeasons,
                    seasonNames: comparison.seasonNames
                        ? comparison.seasonNames
                        : playersComparison[playerId].seasonNames,
                    comparisonError: false,
                },
            };
            return newValue;
        }

        case types.PLAYERS_COMPARISON_CLEAN: {
            const playerId: number = action.payload;
            return {
                ...playersComparison,
                [playerId]: undefined,
            };
        }

        case types.SELECTED_PLAYERS_COMPARISON:
            const { firstPlayerId, thirdPlayer, secondPlayer } = action;
            return {
                ...playersComparison,
                [firstPlayerId]: {
                    ...playersComparison[firstPlayerId],
                    secondPlayer,
                    thirdPlayer,
                },
            };

        default:
            return playersComparison;
    }
};

export default combineReducers({ 
    byId
});

const path = (state: AppState) => state.playersComparison;
export const getComparison = compose(
    comparisons => (id: number) => {
        const c = comparisons.byId[id];
        return c;
    },
    path,
);
