import axios from 'axios-config';
import { PendingSuggestionsResponse, SuggestedDealRequest, SaveAgencyTransfer, ProcessSuggestedDealResponse } from './models'

export interface SuggestionResponse {
    suggestions: Array<SuggestionItem>;
}

export interface SuggestionItem {
    year: number,
    player: {
        id: number,
        name: string,
        shortName: string,
    },
    squadFrom: {
        id: number,
        name: string,
        league: string,
        country: string,
        logo: string,
    },
    squadTo: {
        id: number,
        name: string,
        league: string,
        country: string,
        logo: string,
    }
}

export default class SuggestedDealsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getPendingSuggestions(): Promise<PendingSuggestionsResponse> {
        const { data } = await axios.get<PendingSuggestionsResponse>(`api/AgencySuggestedDeals/GetPendingSuggestions`, this.axiosConfig)
        return data;
    }

    public static async remindMeLater(): Promise<any> {
        const { data } = await axios.post<any>(`api/AgencySuggestedDeals/RemindMeLater`, null, this.axiosConfig)
        return data;
    }

    public static async addDeal(request: SuggestedDealRequest): Promise<ProcessSuggestedDealResponse> {
        const { data } = await axios.post<any>(`api/AgencySuggestedDeals/AddDeal`, request, this.axiosConfig)
        return data;
    }

    public static async rejectDeal(request: SuggestedDealRequest): Promise<ProcessSuggestedDealResponse> {
        const { data } = await axios.post<any>(`api/AgencySuggestedDeals/RejectDeal`, request, this.axiosConfig)
        return data;
    }

    public static async addDealManually(request: SaveAgencyTransfer): Promise<ProcessSuggestedDealResponse> {
        const { data } = await axios.post<any>(`api/AgencySuggestedDeals/AddDealManually`, request, this.axiosConfig)
        return data;
    }
}