import { StateController } from "utils/action-declaration";
import { AppState } from "root.reducer";
import { AllPitchesReportModel, FilterOptions } from "api/admin/all-pitches-report/models";
import allPitchesReportService from "api/admin/all-pitches-report/all-pitches-report.service";
import { ReportsPitchTypeEnum } from "constants/enums";

class AllPitchesReportState {
  isLoading: boolean;
  agencyOnly: boolean;
  isSortOrderAscending: boolean;
  sortOrder: string;
  currentPage: number;
  totalReports: 0;
  recordsPerPage: number;
  pitches: Array<AllPitchesReportModel>;
  filterOptions: FilterOptions;
  previousFilterSnapshot: FilterOptions;
}

const defaultFilterOptions: FilterOptions = {
  playerOrStaffName: '',
  fromClubName: '',
  toClubName: '',
  transferType: null,
  clubAdPosition: '',
  minClubAdBudget: null,
  minPitchBudget: null,
  pitchType: null,
  status: ''
}

const defaultState: AllPitchesReportState = {
  isLoading: false,
  agencyOnly: false,
  isSortOrderAscending: false,
  sortOrder: 'date',
  currentPage: 1,
  totalReports: 0,
  recordsPerPage: 20,
  pitches: [],
  filterOptions: defaultFilterOptions,
  previousFilterSnapshot: defaultFilterOptions,
}

const stateController = new StateController<AllPitchesReportState>(
  'ADMIN_V2/ALL_PITCHES_REPORT',
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData = () => {
    return async (dispatch) => {
      try {
        await dispatch(Actions.getAllPitchesReport());

      } catch (err) {
        console.error(err);
      }
    };
  };

  public static getAllPitchesReport() {
    const wasFiltersChanged = (filterOptions: FilterOptions, previousFilterSnapshot: FilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const {
          isSortOrderAscending,
          recordsPerPage,
          sortOrder,
          currentPage,
          agencyOnly,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          previousFilterSnapshot: {
            ...prevState.filterOptions,
          },
          currentPage: shouldDisposePagination ? 1 : currentPage
        })));

        const searchParameters = {
          sortOrder,
          recordsPerPage,
          isSortOrderAscending,
          agencyOnly,
          page: shouldDisposePagination ? 1 : currentPage,
          playerOrStaffName: filterOptions.playerOrStaffName ? filterOptions.playerOrStaffName : undefined,
          fromClubName: filterOptions.fromClubName ? filterOptions.fromClubName : undefined,
          toClubName: filterOptions.toClubName ? filterOptions.toClubName : undefined,
          isLoan: filterOptions.transferType,
          clubAdPosition: filterOptions.clubAdPosition ? filterOptions.clubAdPosition : undefined,
          minClubAdBudget: filterOptions.minClubAdBudget !== null ? filterOptions.minClubAdBudget : undefined,
          minPitchBudget: filterOptions.minPitchBudget !== null ? filterOptions.minPitchBudget : undefined,
          pitchType: filterOptions.pitchType,
          status: filterOptions.status ? filterOptions.status : undefined,
        }

        const data = await allPitchesReportService.getAllPitchesReport(searchParameters);
        const pitches = data.output.map((pitch, index) => ({
          ...pitch,
          key: `${pitch.id}_${pitch.transferType}`
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          pitches,
          totalReports: data.rowCount,
        })));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static onPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        currentPage: pageNumber,
        recordsPerPage: pageSize
      })))

      dispatch(Actions.getAllPitchesReport());
    }
  }

  public static onChangePlayerOrStaffName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          playerOrStaffName: value
        }
      })));
    }
  }

  public static onChangeFromClubName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          fromClubName: value
        }
      })));
    }
  }

  public static onChangeToClubName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          toClubName: value
        }
      })));
    }
  }

  public static onChangeTransferType(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          transferType: value
        }
      })));
    }
  }

  public static onChangeClubAdPosition(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          clubAdPosition: value
        }
      })));
    }
  }

  public static onChangeClubAdBudget(value: number | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          minClubAdBudget: value
        }
      })));
    }
  }

  public static onChangePitchBudget(value: number | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          minPitchBudget: value
        }
      })));
    }
  }

  public static onChangePitchType(value: ReportsPitchTypeEnum | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          pitchType: value
        }
      })));
    }
  }

  public static onChangeStatus(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          status: value
        }
      })));
    }
  }

  public static applySearch() {
    return dispatch => dispatch(Actions.getAllPitchesReport())
  }

  public static disposeFilters() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })));
      dispatch(Actions.applySearch());
    }
  }
}

class Selectors {
  public static selectState = (state: AppState) => state.admin.allPitchesReport;
  public static isLoading = (state: AppState): boolean => Selectors.selectState(state).isLoading;
  public static getPitches = (state: AppState) => Selectors.selectState(state).pitches;
  public static getTotalReports = (state: AppState) => Selectors.selectState(state).totalReports;
  public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
  public static getCurrentPage = (state: AppState) => Selectors.selectState(state).currentPage;
  public static getFilterOptions = (state: AppState) => Selectors.selectState(state).filterOptions;
  public static isFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.filterOptions) === JSON.stringify(Selectors.getFilterOptions(state))
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  AllPitchesReportState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
