import React from 'react';

const SpinnerCentered = ({
    className = '',
    style = {},
    green = false
}) => {

    const defaultContainerStyles = { position: 'relative', height: '100%', width: '100%' };

    let classNames = (className ? className : '').concat(' spinner-centered-container');
    
    const styles = style ? { ...defaultContainerStyles, ...style } : defaultContainerStyles

    return (
        <div className={classNames} style={styles}>
            <div
                style={{
                    top: '50%',
                    left: '50%',
                    position: 'absolute',
                    height: '15px',
                    width: '15px',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                }}
            >
                <i className={`fas fa-spinner fa-spin tr-spinner__icon--fast spinner fi-spinner ${green && 'green'}`} />
            </div>
        </div>
    )
}

export default SpinnerCentered