import { StateController } from 'utils/action-declaration'
import { AppState } from "root.reducer";
import historyAccessor from 'history-accessor';
import {
    PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE,
    PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS,
} from 'store/actionTypes';
import { playerPathsV2 } from 'routes/paths';
import { getAuth } from 'store/auth/authReducer';
import { getUserPreference } from 'store/userPreference/userPreference.reducer';
import { CurrencyEnum } from 'api/agency/agent/landing/strategy-modal-models';
import { PlayerOnBoardIntentionModel } from 'api/player-preferences/model'
import { PlayerPreferencesService } from 'api/player-preferences/player-preferences.service';
import AgencyFinderService from "api/player-v2/agency-finder.service";
import { Actions as SettingsActions } from 'pages/player-v2/settings-page/settings-page.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

export enum PlayerPreferencesSteps {
    TransferAvailability = 1,
    MotivationPreference = 2,
    NotLookingMoveNow = 3,
    InfoScreen = 4,
    PlayingAbroad = 5,
    ExpectedGrossSalary = 6,
    AgencyConnection = 7,
    PreferencesUpdatedSuccessful = 8,
}

export enum PlayerPreferencesOption {
    ActivelySeekingClub = 1,
    OpenToOpportunities = 2,
    NotLookingMoveNow = 3,
    PlayingTime = 4,
    Level = 5,
    MovingAbroad = 6,
    Money = 7,
    Absolutely = 8,
    MayBeRightClub = 9,
    ProbableNot = 10,
}

export enum PlayerOnBoardIntentionPeriodEnum {
    Week = 1,
    Month = 2,
    Year = 3,
}

export enum ProgressBarEnum {
    MotivationPreference = 1,
    PlayingAbroad = 2,
    ExpectedGrossSalary = 3,
    AgencyConnection = 4,
}

export enum AgencyConnectionOptionEnum {
    ConnectAgent = 1,
    FindAgent = 2,
    SkipForNow = 3,
}

class PlayerPreferencesState {
    isProcessing: boolean;
    currentStep: PlayerPreferencesSteps;
    selectedAgencyConnectionOption: AgencyConnectionOptionEnum | null;
    transferResponseId: PlayerPreferencesOption;
    motivationResponseId: PlayerPreferencesOption;
    relocationResponseId: PlayerPreferencesOption;
    salaryExpectation: number;
    currency: CurrencyEnum;
    formattedInputValue: string;
    period: PlayerOnBoardIntentionPeriodEnum;
    isSettingPage: boolean;
    pageName: string;
    playerId: number;
    initialPreferences: PlayerOnBoardIntentionModel;
}

const defaultState: PlayerPreferencesState = {
    isProcessing: false,
    currentStep: PlayerPreferencesSteps.TransferAvailability,
    selectedAgencyConnectionOption: null,
    transferResponseId: null,
    motivationResponseId: null,
    relocationResponseId: null,
    salaryExpectation: null,
    currency: CurrencyEnum.EUR,
    formattedInputValue: '',
    period: PlayerOnBoardIntentionPeriodEnum.Year,
    isSettingPage: false,
    pageName: '',
    playerId: null,
    initialPreferences: null,
}

const stateController = new StateController<PlayerPreferencesState>(
    'PLAYER/PREFERENCES',
    defaultState
)

class Actions {
    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId, currency } = getAuth(getState());
            const defaultCurrency = currency?.id
            const defaultPeriod = Selectors.getRoot(getState()).period;

            dispatch(stateController.setState({ isProcessing: true }));

            try {
                const data = await PlayerPreferencesService.getPlayerOnBoardIntention();

                if (data?.transferResponseId) {
                    dispatch(Actions.setIsSettingPage());
                }

                const { isSettingPage } = Selectors.getRoot(getState());
                const defaultPageName = `${isSettingPage ? 'Settings' : 'Onboarding'} [Availability Preference]`;

                dispatch(stateController.setState({
                    transferResponseId: data?.transferResponseId === PlayerPreferencesOption.NotLookingMoveNow ? null : data?.transferResponseId,
                    motivationResponseId: data?.motivationResponseId,
                    relocationResponseId: data?.relocationResponseId,
                    salaryExpectation: data?.minGrossSalary,
                    currency: data?.currencyId ?? defaultCurrency,
                    period: data?.periodId ?? defaultPeriod,
                    pageName: defaultPageName,
                    playerId: playerId,
                    initialPreferences: data,
                }));

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static getPageName() {
        return (dispatch, getState: () => AppState) => {
            const { isSettingPage, currentStep } = Selectors.getRoot(getState());
            const pageType = isSettingPage ? 'Settings' : 'Onboarding';

            const stepToPageNameSuffix = {
                [PlayerPreferencesSteps.TransferAvailability]: 'Availability Preference',
                [PlayerPreferencesSteps.MotivationPreference]: 'Priorities Preference',
                [PlayerPreferencesSteps.PlayingAbroad]: 'Playing Abroad Preference',
                [PlayerPreferencesSteps.ExpectedGrossSalary]: 'Salary Preference',
                [PlayerPreferencesSteps.PreferencesUpdatedSuccessful]: 'Transfer Preferences',
                [PlayerPreferencesSteps.NotLookingMoveNow]: 'Skip Transfer Preference',
            };

            const pageNameSuffix = stepToPageNameSuffix[currentStep];
            const pageName = pageNameSuffix ? `${pageType} [${pageNameSuffix}]` : '';

            dispatch(stateController.setState({
                pageName: pageName,
            }));
        }
    }

    public static setStep(step: PlayerPreferencesSteps) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                currentStep: step,
            }));
            dispatch(Actions.getPageName());
        }
    }

    public static skipTransfer() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));

            const showPlayerRepresentationScreen = getUserPreference(getState())?.playerUserPermissions?.showPlayerRepresentationScreen;
            const { isSettingPage, playerId, pageName } = Selectors.getRoot(getState());

            try {
                await dispatch(Actions.addPlayerOnBoardIntention(true));

                dispatch(userActivityInsert({
                    PageName: pageName,
                    Message: 'Next Button Clicked',
                    PlayerId: playerId,
                    PageType: PageType.PlayerPage,
                }));

                dispatch({ type: PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE });

                if (showPlayerRepresentationScreen && !isSettingPage) {
                    dispatch(Actions.setStep(PlayerPreferencesSteps.AgencyConnection));
                } else {
                    historyAccessor.push(playerPathsV2.homePage);
                    dispatch(Actions.dispose());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static setCurrency(currency: CurrencyEnum) {
        return (dispatch, getState: () => AppState) => {
            const { playerId, pageName } = Selectors.getRoot(getState());

            dispatch(stateController.setState({ currency: currency }));

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Currency Option Picked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static setFormattedInputValue(value: string) {
        return (dispatch) => {
            dispatch(stateController.setState({ formattedInputValue: value }));
        }
    }

    public static setPeriod(period: PlayerOnBoardIntentionPeriodEnum) {
        return (dispatch, getState: () => AppState) => {
            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(stateController.setState({ period: period }));

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Time Option Picked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static setSalaryExpectation(value: number) {
        return (dispatch) => {
            dispatch(stateController.setState({ salaryExpectation: value }));
        }
    }

    public static setTransferResponse(value: PlayerPreferencesOption) {
        return (dispatch, getState: () => AppState) => {
            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Preference Selected',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));

            dispatch(stateController.setState({ transferResponseId: value }));
        }
    }

    public static setTransferUserActivity() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPageName());

            const { playerId, pageName } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Next Button Clicked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static setMotivationResponse(value: PlayerPreferencesOption) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPageName());

            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Preference Selected',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));

            dispatch(stateController.setState({ motivationResponseId: value }));
        }
    }

    public static setMotivationUserActivity() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPageName());

            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Next Button Clicked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static setRelocationResponse(value: PlayerPreferencesOption) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPageName());

            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Preference Selected',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
            dispatch(stateController.setState({ relocationResponseId: value }));
        }
    }

    public static setRelocationUserActivity() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPageName());

            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Next Button Clicked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static setSalaryExpectationUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Next Button Clicked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static addPlayerOnBoardIntention(skipTransfer?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));
            try {
                const showPlayerRepresentationScreen = getUserPreference(getState())?.playerUserPermissions?.showPlayerRepresentationScreen;

                const {
                    transferResponseId,
                    motivationResponseId,
                    relocationResponseId,
                    salaryExpectation,
                    currency,
                    period,
                    isSettingPage,
                    pageName,
                    playerId,
                    initialPreferences,
                } = Selectors.getRoot(getState());

                const request = {
                    transferResponseId: transferResponseId,
                    motivationResponseId: skipTransfer ? null : motivationResponseId,
                    relocationResponseId: skipTransfer ? null : relocationResponseId,
                    minGrossSalary: skipTransfer ? null : salaryExpectation,
                    currencyId: skipTransfer ? null : currency,
                    periodId: skipTransfer ? null : period,
                }

                await PlayerPreferencesService.addPlayerOnBoardIntention(request);

                if (!skipTransfer) {
                    if (salaryExpectation !== initialPreferences?.minGrossSalary) {
                        dispatch(userActivityInsert({
                            PageName: pageName,
                            Message: 'Preference Typed',
                            PlayerId: playerId,
                            PageType: PageType.PlayerPage,
                        }));
                    }

                    dispatch(userActivityInsert({
                        PageName: pageName,
                        Message: 'Next Button Clicked',
                        PlayerId: playerId,
                        PageType: PageType.PlayerPage,
                    }));
                }

                dispatch({ type: PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE });

                if (showPlayerRepresentationScreen && !isSettingPage) {
                    dispatch(Actions.setStep(PlayerPreferencesSteps.AgencyConnection))
                } else {
                    dispatch(Actions.setStep(PlayerPreferencesSteps.PreferencesUpdatedSuccessful))
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static setSelectedAgencyConnectionOption(option: AgencyConnectionOptionEnum) {
        return (dispatch) => {
            dispatch(stateController.setState({ selectedAgencyConnectionOption: option }));
        }
    }

    public static onSelectedAgencySubmit() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));
            const { selectedAgencyConnectionOption, playerId } = Selectors.getRoot(getState());

            try {
                if (selectedAgencyConnectionOption === AgencyConnectionOptionEnum.ConnectAgent) {
                    await dispatch(Actions.onRepresentationFinish())
                    historyAccessor.push(playerPathsV2.inviteAgency);
                    dispatch(Actions.dispose());
                }
                if (selectedAgencyConnectionOption === AgencyConnectionOptionEnum.FindAgent) {
                    dispatch({ type: PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS, payload: { data: true } });

                    const isActivatorToggleChecked = getState().playerV2.settings.isActivatorToggleChecked;
                    await dispatch(Actions.onRepresentationFinish());

                    dispatch(SettingsActions.setIsActivatorToggleChecked(isActivatorToggleChecked));
                    dispatch(SettingsActions.onContinue());

                    historyAccessor.push(playerPathsV2.agentFinder);
                    dispatch(Actions.dispose());
                }

                if (selectedAgencyConnectionOption === AgencyConnectionOptionEnum.SkipForNow) {
                    dispatch(Actions.setStep(PlayerPreferencesSteps.PreferencesUpdatedSuccessful))
                }

                dispatch(userActivityInsert({
                    PageName: 'Onboarding [Representation Preference]',
                    Message: 'Next Button Clicked',
                    PlayerId: playerId,
                    PageType: PageType.PlayerPage,
                }));

                dispatch(userActivityInsert({
                    PageName: 'Onboarding [Representation Preference]',
                    Message: 'Preference Selected',
                    PlayerId: playerId,
                    PageType: PageType.PlayerPage,
                }));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static onRepresentationFinish() {
        return async (dispatch, getState: () => AppState) => {
            const { userId } = getAuth(getState());
            try {
                await AgencyFinderService.saveToTempDataTableValues(userId);
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static onPreferencesUpdatedSuccessfulyDone() {
        return (dispatch, getState: () => AppState) => {
            const { pageName, playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Done Button Clicked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
            dispatch(Actions.dispose());
            historyAccessor.push(playerPathsV2.homePage);
        }
    }

    public static sendUserActivityForBackButton() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPageName());
            const { playerId, pageName } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Back Button Clicked',
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static sendUserActivity(pageName: string, message: string) {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = Selectors.getRoot(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                PlayerId: playerId,
                PageType: PageType.PlayerPage,
            }));
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static setIsSettingPage() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isSettingPage: true }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): PlayerPreferencesState => state.playerV2.playerPreferences;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    PlayerPreferencesState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}