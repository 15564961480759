
export interface PlayerAdModel {
    id: number;
    squad: any;//SquadModel;
    positionCode: string;
    positionName: string;
    currencyCode: string;
    amount: number;
    amountFormatted: string;
    amountFormattedWithComma: string;
    matchMakerDefaultAmountForPitch: number;
    type: PlayerAdTypeEnum;
    fromAge: number | null;
    toAge: number | null;
    minHeight: number | null;
    active: boolean;
    lastUpdated: Date | string | null;
    deletedAt: Date | string | null;
    forSuperPitch: boolean | null;
    pitches: any;//PitchOnAd[];
    newPitches: number;
    receivedPitches: number;
    savedPitches: number;
    declaredInterestsPitches: number;
    recommendedPrice: number;
    recommendedPriceFormatted: string;
    recommendedPriceFormattedWithComma: string;
    isUpdatable: boolean;
    isOutdated: boolean;
    lastUpdatedFormatted: string;
    sellOn: number | null;
    grossSalaryFormattedWithComma?: string;
}

export enum PlayerAdTypeEnum {
    Buy = 1,
    Loan = 2
}
