import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import getSections from 'services/filterSections';
import { getAuth } from 'store/auth/authReducer';

const sections = getSections();

class MySquadFilterState {
    expandedSections: number[]
    structure: {
        minAge: number,
        maxAge: number,
        contractExpiryMin: number,
        contractExpiryMax: number,
        positions: any[]
    }
    criteria: {
        keyword: string
        squadId: number
        isForLoan: boolean,
        isForSale: boolean,
        isForFree: boolean,
        isInReleaseList: boolean,
        recommended: boolean,
        minAge: number,
        maxAge: number,
        contractExpiryMin: number,
        contractExpiryMax: number,
        positions: any[]
    }
}

const defaultState: MySquadFilterState = {
    expandedSections: Object.values(sections).map(s => s.id),
    structure: {
        minAge: sections.age.default.minAge,
        maxAge: sections.age.default.maxAge,
        contractExpiryMin: sections.contractExpiry.default.contractExpiryMin,
        contractExpiryMax: sections.contractExpiry.default.contractExpiryMax,
        positions: sections.position.default.positions,
    },
    criteria: {
        keyword: '',
        ...sections.squad.default,
        ...sections.status.default,
        ...sections.age.default,
        ...sections.contractExpiry.default,
        ...sections.position.default,
    }
}

const stateController = new StateController<MySquadFilterState>(
    "MYSQUAD_V2/FILTER",
    defaultState
);

class Actions {
    public static collapseToggle(toggledSectionId: number): any {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                expandedSections: prevState.expandedSections.includes(toggledSectionId) 
                ? prevState.expandedSections.filter(x => x !== toggledSectionId) 
                : [...prevState.expandedSections, toggledSectionId]
            })))
        }
    }

    public static dispose(){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }    
}

class Selectors {
    public static getRoot = (state: AppState) => state.mySquadV2.filter;

    public static getMySquadFilterStructure = (state: AppState) => Selectors.getRoot(state).structure;
    
    public static getMySquadFilterCriteria = (state: AppState) => {
        const substate = Selectors.getRoot(state);
        return substate.criteria;
    }

    public static getMySquadFetchRequest = (state: AppState) => {
        const auth = getAuth(state);
        const criteria = Selectors.getMySquadFilterCriteria(state);
        const newCriteria = { ...criteria };
        return {
            ...auth,
            scope: 'mySquad',
            criteria: newCriteria,
        };
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MySquadFilterState as State,
    Selectors as Selectors,
    Actions as Actions,
    stateController as Controller
};