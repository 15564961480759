import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import PasswordValidation from 'utils/validate-password';
import { modalOpen, modalClose } from 'store/modals/modalsActions';
import { AUTH_LOGIN } from 'store/actionTypes';
import { COACH_HEALTH_CONSENT, COACH_TERMS_OF_SERVICE } from 'constants/modals';
import validator from 'services/validator';
import { AreaModel } from 'api/player-v2/models';
import { getAuth } from 'store/auth/authReducer';
import userActivityInsert, { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import historyAccessor from 'history-accessor';
import { coachPaths } from 'routes/paths';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { AgencyCoachOnboardingService } from 'api/agency/coach/onboarding/coach-onboarding.service';
import { getCoachUserPermissions } from 'store/userPreference/userPreference.reducer';
import { AgentClubStaffInvitationStatusEnum } from 'api/agency/player/shared/shared-models';
import { openPublicProfile } from 'utils/open-link';

export enum CoachOnboardingStep {
    Registration = 1,
    ConfirmAgency = 2,
    InvalidEmail = 3,
    LinkExpired = 5,
}

export enum CoachEmailOnboardingStep {
    EmailVerification = 0,
    AgencyConfirmedStep = 1,
    ResponseSavedStep = 2,
}

class State {
    currentStep: CoachOnboardingStep;
    token: string | null;
    isInitializing: boolean;
    staffId: number | null;
    invitationUid: string;
    registrationStep: {
        phoneCode: string;
        phoneCodeAreaId?: number;
        coachPhone: string;
        email: string;
        phoneCodeAreaIdPredefined?: number;
        coachPhonePredefined: string;
        emailPredefined: string;
        password: string;
        retypePassword: string;
        isAgreedTermsOfService: boolean;
        // isAgreedHealthConsent: boolean;
        isRegistrationProcessing: boolean;
        emailError: boolean;
        emailErrorText: string;
        passwordRules: Array<{ ruleCode: string, passed: boolean }>;
        passwordError: boolean;
        retypePasswordError: any;
    };
    agencyRelationsStep: {
        id: number;
        name: string;
        isRelationConfirmed: boolean;
        shouldAgentBeEndorced: boolean;
        isTrusted: boolean;
        isConfirmProcessing: boolean;
        isDeclineProcessing: boolean;
        isTransparent: boolean;
        playersCount: number;
        coachesCount: number;
        primaryMarket: AreaModel,
        secondaryMarket: AreaModel,
    };
    emailVerificationStep: {
        step: CoachEmailOnboardingStep;
    };
}

const defaultState: State = {
    currentStep: CoachOnboardingStep.Registration,
    token: null,
    isInitializing: false,
    staffId: null,
    invitationUid: '',
    registrationStep: {
        phoneCode: null,
        phoneCodeAreaId: null,
        coachPhone: '',
        email: '',
        phoneCodeAreaIdPredefined: null,
        coachPhonePredefined: '',
        emailPredefined: '',
        password: '',
        retypePassword: '',
        isAgreedTermsOfService: false,
        // isAgreedHealthConsent: false,
        isRegistrationProcessing: false,
        emailError: false,
        emailErrorText: '',
        passwordRules: [],
        passwordError: false,
        retypePasswordError: false
    },
    agencyRelationsStep: {
        id: null,
        name: '',
        isRelationConfirmed: true,
        shouldAgentBeEndorced: false,
        isConfirmProcessing: false,
        isDeclineProcessing: false,
        isTrusted: false,
        isTransparent: false,
        playersCount: 0,
        coachesCount: 0,
        primaryMarket: {
            id: 0,
            name: '',
            flagPath: '',
        },
        secondaryMarket: {
            id: 0,
            name: '',
            flagPath: '',
        },
    },
    emailVerificationStep: {
        step: CoachEmailOnboardingStep.ResponseSavedStep,
    },
}

const stateController = new StateController<State>(
    'COACH_TO_AGENCY_ONBOARDING',
    defaultState
)

class Actions {
    public static init(token: string, queryString?: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isInitializing: true, token: token }));
                const currentStep = Selectors.getCurrentStep(getState());
                const result = await AgencyCoachOnboardingService.getOnboardingAccess(token);

                if (result.isValid) {
                    if (result.isReconfirm) {
                        dispatch(stateController.setState({ currentStep: CoachOnboardingStep.ConfirmAgency }))
                    }
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        staffId: result.coach.id,
                        invitationUid: token,
                        registrationStep: {
                            ...prevState.registrationStep,
                            phoneCode: result.coach.phoneCode,
                            coachPhone: result.coach.phone,
                            phoneCodeAreaId: result.coach.phoneCodeAreaId,
                            email: result.coach.email,
                            coachPhonePredefined: result.coach.phone,
                            phoneCodeAreaIdPredefined: result.coach.phoneCodeAreaId,
                            emailPredefined: result.coach.email
                        },
                        agencyRelationsStep: {
                            ...prevState.agencyRelationsStep,
                            id: result.agency.id,
                            name: result.agency.name,
                            isTransparent: result.agency.isTransparent,
                            isTrusted: result.agency.isTrusted,
                            playersCount: result.agency.playersCount,
                            coachesCount: result.agency.coachesCount,
                            primaryMarket: result.agency.primaryMarket,
                            secondaryMarket: result.agency.secondaryMarket,
                        }
                    })));

                    if (!result.isReconfirm) {
                        dispatch(insertAnonymousActivity({
                            PageName: 'Create Account',
                            Message: 'Opened Create Account Page',
                            CoachId: result.coach.id
                        }));
                    }
                } else {
                    if(result.isExpired){
                        dispatch(stateController.setState({ currentStep: CoachOnboardingStep.LinkExpired }))
                    } else {
                        historyAccessor.push(coachPaths.myProfile);
                    }
                }


            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isInitializing: false }))
            }
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setStep(step: CoachOnboardingStep) {
        return async (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }));
        }
    }

    public static onEmailChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    email: value,
                    emailError: false
                }
            })))
        }
    }

    public static onEmailBlur() {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());

            dispatch(insertAnonymousActivity({
                PageName: 'Create Account',
                Message: 'Entered Email',
                CoachId: staffId
            }));
        }
    }

    public static onPhoneCodeChange(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    phoneCodeAreaId: value
                }
            })))
        }
    }

    public static onPhoneChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    coachPhone: value
                }
            })))
        }
    }

    public static onPhoneBlur() {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());

            dispatch(insertAnonymousActivity({
                PageName: 'Create Account',
                Message: 'Entered Phone Number',
                CoachId: staffId
            }));
        }
    }

    public static onPasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().coach.onboarding;
            const rePassword = subState.registrationStep.retypePassword;

            const validation = PasswordValidation.validate(value);
            const passwordError = !validation.isSuccess;
            const retypePasswordError = PasswordValidation.validateConfirmPassword(value, rePassword);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    password: value,
                    passwordError,
                    retypePasswordError,
                    passwordRules: validation.rules,
                }
            })));
        }
    }

    public static onPasswordBlur() {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());

            dispatch(insertAnonymousActivity({
                PageName: 'Create Account',
                Message: 'Entered Password',
                CoachId: staffId
            }));
        }
    }

    public static onRetypePasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const password = getState().coach.onboarding.registrationStep.password;
            const retypePasswordError = PasswordValidation.validateConfirmPassword(password, value);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    retypePassword: value,
                    retypePasswordError: retypePasswordError
                }
            })))
        }
    }

    public static onRetypePasswordBlur() {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());

            dispatch(insertAnonymousActivity({
                PageName: 'Create Account',
                Message: 'Confirmed Password',
                CoachId: staffId
            }));
        }
    }

    public static onTermsOfServiceCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    isAgreedTermsOfService: isChecked
                }
            })));

            if (isChecked) {
                dispatch(insertAnonymousActivity({
                    PageName: 'Create Account',
                    Message: 'Checked Terms of Service',
                    CoachId: staffId
                }));
            }
        }
    }

    // public static onHealthConsentCheckboxChange(isChecked: boolean) {
    //     return (dispatch, getState: () => AppState) => {
    //         const { staffId } = Selectors.getRoot(getState());
    //         dispatch(stateController.setState(prevState => ({
    //             ...prevState,
    //             registrationStep: {
    //                 ...prevState.registrationStep,
    //                 isAgreedHealthConsent: isChecked
    //             }
    //         })));
    //
    //         if (isChecked) {
    //             dispatch(insertAnonymousActivity({
    //                 PageName: 'Create Account',
    //                 Message: 'Checked Health Consent',
    //                 CoachId: staffId
    //             }));
    //         }
    //     }
    // }

    public static openTermsOfServiceModal = () => (dispatch, getState: () => AppState) => {
        const { staffId } = Selectors.getRoot(getState());

        dispatch(insertAnonymousActivity({
            PageName: 'Create Account',
            Message: 'Opened Terms of Service',
            CoachId: staffId
        }));

        dispatch(modalOpen({
            id: COACH_TERMS_OF_SERVICE,
            content: {
                closeModal: () => dispatch(Actions.closeTermsOfServiceModal)
            }
        }))
    }

    public static closeTermsOfServiceModal = () => (dispatch) => {
        dispatch(modalClose(COACH_TERMS_OF_SERVICE));
    }

    // public static openHealthConsentModal = () => (dispatch, getState: () => AppState) => {
    //     dispatch(modalOpen({
    //         id: COACH_HEALTH_CONSENT,
    //         content: {
    //             closeModal: () => dispatch(Actions.closeHealthConsentModal)
    //         }
    //     }))
    // }

    public static closeHealthConsentModal = () => (dispatch) => {
        dispatch(modalClose(COACH_HEALTH_CONSENT));
    }

    public static onSignUpClick() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().coach.onboarding;

            const isEmailValid = validator.isValidEmail(subState.registrationStep.email);
            if (!isEmailValid) {
                return dispatch(stateController.setState(prevState => ({
                    ...prevState, registrationStep: { ...prevState.registrationStep, emailError: !isEmailValid, emailErrorText: 'Incorrect email' }
                })))
            }

            const { invitationUid } = subState
            const {
                email, password, emailError, isAgreedTermsOfService, passwordError, retypePasswordError, phoneCodeAreaId, coachPhone
            } = subState.registrationStep

            if (!emailError && !passwordError && !retypePasswordError && isAgreedTermsOfService) {
                try {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: true
                        }
                    })));

                    const data = await AgencyCoachOnboardingService.signup({
                        email, password, invitationUid, phone: coachPhone, phoneCodeAreaId: phoneCodeAreaId
                    });

                    if (data.isSuccess) {
                        dispatch(insertAnonymousActivity({
                            PageName: 'Create Account',
                            Message: 'Clicked Continue',
                            CoachId: subState.staffId
                        }));

                        dispatch(Actions.setStep(CoachOnboardingStep.ConfirmAgency));

                    } else {
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            registrationStep: { ...prevState.registrationStep, emailError: true, emailErrorText: data.message }
                        })))
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: false
                        }
                    })));
                }
            } else {
                return;
            }
        }
    }

    public static initConfirmAgencyPage() {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());
            dispatch(insertAnonymousActivity({
                PageName: 'Confirm Agency',
                Message: 'Opened Agency Confirmation Page',
                CoachId: staffId
            }));
        }
    }

    public static onOpenPublicProfile() {
        return (dispatch, getState: () => AppState) => {
            const { staffId } = Selectors.getRoot(getState());
            const { id: agencyId, name: agencyName } = Selectors.getAgencyInfo(getState());

            dispatch(insertAnonymousActivity({
                PageName: 'Confirm Agency',
                Message: 'Clicked Agency Name',
                CoachId: staffId,
                AgencyId: agencyId
            }));

            openPublicProfile(agencyName)
        }
    }

    public static confirmAgency(confirmStatus: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyRelationsStep: {
                        ...prevState.agencyRelationsStep,
                        isConfirmProcessing: confirmStatus,
                        isDeclineProcessing: !confirmStatus,
                    }
                })));
                const state = Selectors.getRoot(getState());
                const { staffId } = Selectors.getRoot(getState());
                const { id: agencyId } = Selectors.getAgencyInfo(getState());
                const confirmRequest = {
                    invitationUid: state.invitationUid,
                    isAgentConfirmed: confirmStatus,
                    endorseAgent: null,
                }
                const authData = await AgencyCoachOnboardingService.submitAgent(confirmRequest);
                dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: authData } });

                if (confirmStatus) {
                    dispatch(insertAnonymousActivity({
                        PageName: 'Confirm Agency',
                        Message: 'Clicked Confirm',
                        CoachId: staffId,
                        AgencyId: agencyId
                    }));
                } else {
                    dispatch(insertAnonymousActivity({
                        PageName: 'Confirm Agency',
                        Message: 'Clicked Not My Agency',
                        CoachId: staffId,
                        AgencyId: agencyId
                    }));
                }

                historyAccessor.push(coachPaths.emailVerification);
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyRelationsStep: {
                        ...prevState.agencyRelationsStep,
                        isConfirmProcessing: false,
                        isDeclineProcessing: false,
                    }
                })));
            }
        }
    }

    public static initCoachEmailOnboardingScreen() {
        return (dispatch, getState: () => AppState) => {
            const { invitationStatus } = getCoachUserPermissions(getState());
            if (invitationStatus === AgentClubStaffInvitationStatusEnum.VerifiedAndConfirmed) {
                dispatch(Actions.setEmailVerificationStep(CoachEmailOnboardingStep.AgencyConfirmedStep));
            } else {
                dispatch(Actions.setEmailVerificationStep(CoachEmailOnboardingStep.ResponseSavedStep));
            }
        }
    }

    public static initConfirmationScreen(){
        return (dispatch, getState: () => AppState) => {
            const { invitationStatus } = getCoachUserPermissions(getState());
            const { coachId } = getAuth(getState());

            if (invitationStatus === AgentClubStaffInvitationStatusEnum.VerifiedAndConfirmed) {
                dispatch(userActivityInsert({
                    PageName: 'Confirm Agency',
                    Message: 'Opened Agency Confirmation Success Page',
                    CoachId: coachId,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Confirm Agency',
                    Message: 'Opened Response Saved Page',
                    CoachId: coachId,
                }));
            }
        }
    }

    public static onConfirmClick() {
        return (dispatch, getState: () => AppState) => {
            const { coachId } = getAuth(getState());

            dispatch(Actions.sendEmailConfirmation());
            dispatch(Actions.setEmailVerificationStep(CoachEmailOnboardingStep.EmailVerification));

            dispatch(userActivityInsert({
                PageName: 'Confirm Agency',
                Message: 'Clicked Complete Email Verification',
                CoachId: coachId,
            }));
        }
    }

    public static initEmailVerificationScreen() {
        return (dispatch, getState: () => AppState) => {
            const { coachId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Email Verification',
                Message: 'Opened Email Verification Page',
                CoachId: coachId
            }))
        }
    }

    public static closeEmailVerificationScreen() {
        return  (dispatch, getState: () => AppState) => {
            historyAccessor.push(coachPaths.myProfile);
            const { coachId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Email Verification',
                Message: 'Clicked Close',
                CoachId: coachId
            }));
        }
    }

    public static resendVerificationEmail() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendEmailConfirmation())
            const { coachId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Email Verification',
                Message: 'Clicked Resent Verification Email',
                CoachId: coachId
            }))
        }
    }

    public static sendEmailConfirmation() {
        return async (dispatch, getState: () => AppState) => {
            try {
                await AgencyCoachOnboardingService.sendConfirmEmail();
                dispatch(notificationCreate({ message: 'Verification email sent!', level: 'success'}));
            } catch(err) {
                console.error(err);
            }
        }
    }

    public static setEmailVerificationStep(step: CoachEmailOnboardingStep) {
        return async (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                emailVerificationStep: {
                    ...prevState.emailVerificationStep,
                    step,
                }
            })));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.coach.onboarding;
    public static getIsInitializing = (state: AppState): boolean => Selectors.getRoot(state).isInitializing;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static getRegistrationStepState = (state: AppState) => Selectors.getRoot(state).registrationStep;
    public static getEmailError = (state: AppState) => Selectors.getRoot(state).registrationStep.emailError;
    public static getEmailErrorText = (state: AppState) => Selectors.getRoot(state).registrationStep.emailErrorText;
    public static getEmail = (state: AppState) => Selectors.getRoot(state).registrationStep.email;
    public static getCoachPhone = (state: AppState) => Selectors.getRoot(state).registrationStep.coachPhone;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).registrationStep.password;
    public static getPasswordError = (state: AppState) => Selectors.getRoot(state).registrationStep.passwordError;
    public static getPasswordRules = (state: AppState) => Selectors.getRoot(state).registrationStep.passwordRules;
    public static getConfirmedPassword = (state: AppState) => Selectors.getRoot(state).registrationStep.retypePassword;
    public static getConfirmedPasswordError = (state: AppState) => Selectors.getRoot(state).registrationStep.retypePasswordError;
    public static isAgreedTermsOfService = (state: AppState) => Selectors.getRoot(state).registrationStep.isAgreedTermsOfService;
    // public static isAgreedHealthConsent = (state: AppState) => Selectors.getRoot(state).registrationStep.isAgreedHealthConsent;
    public static isRegistrationProcessing = (state: AppState) => Selectors.getRoot(state).registrationStep.isRegistrationProcessing;
    public static getAgencyInfo = (state: AppState) => Selectors.getRoot(state).agencyRelationsStep;
    public static isConfirmProcessing= (state: AppState) => Selectors.getRoot(state).agencyRelationsStep.isConfirmProcessing;
    public static isDeclineProcessing= (state: AppState) => Selectors.getRoot(state).agencyRelationsStep.isDeclineProcessing;
    public static getCurrentEmailVerificationStep = (state: AppState) => Selectors.getRoot(state).emailVerificationStep.step;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
