export interface GetHistoryResponse {
    items: HistoryItem[]
}

export interface HistoryItem {
    userId: number;
    userFirstName: string;
    userLastName: string;
    lastDisplayedAt: Date;
    reonboardingType: ReonboardingTypeEnum;
}

export enum ReonboardingTypeEnum {
    ClubTransferWindow = 1,
    ClubManual = 2,
    AgencyManual = 3,
    PlayerManual = 4,
}