import { camalize } from '../../../utils/camel';
import { translate } from '../../../services/localization';

export const minutes = 'minutesPlayed';

const featuresToHighlightBest = [
    'saves',
    'concededGoals',
    'xCG',
    'longPassesAccuracy',
    'forwardPassesAccuracy',
    'defensiveDuelsWon',
    'aerialDuelsWon',
    'interceptions',
    'passesAccuracy',
    'contribution',
    'shots',
    'crossesAccuracy',
    'successfulDribbles',
    'xG',
    'xA',
    'successfulCrosses',
    'passesToFinalThirdAccuracy',
    'goals',
    'assists',
    'shotsOnTarget',
];

export const procent = [
    'longPassesAccuracy',
    'forwardPassesAccuracy',
    'defensiveDuelsWon',
    'aerialDuelsWon',
    'passesAccuracy',
    'crossesAccuracy',
    'passesToFinalThirdAccuracy',
    'shotsOnTarget',
];

export const rows = {
    GK: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'SHOTS AGAINST',
        'SAVES',
        'CONCEDED GOALS',
        'xCG',
        'OFFENSIVE STATS P90',
        'PASSES ACCURACY',
        'LONG PASSES ACCURACY',
        'FORWARD PASSES ACCURACY',
        'HEIGHT',
    ],
    LB: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'DEFENSIVE DUELS WON',
        'AERIAL DUELS WON',
        'INTERCEPTIONS',
        'PASSES ACCURACY',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'SHOTS',
        'CROSSES ACCURACY',
        'FORWARD PASSES ACCURACY',
    ],

    RB: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'DEFENSIVE DUELS WON',
        'AERIAL DUELS WON',
        'INTERCEPTIONS',
        'PASSES ACCURACY',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'SHOTS',
        'CROSSES ACCURACY',
        'FORWARD PASSES ACCURACY',
    ],

    CB: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'DEFENSIVE DUELS WON',
        'AERIAL DUELS WON',
        'INTERCEPTIONS',
        'PASSES ACCURACY',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'PASSES TO FINAL THIRD ACCURACY',
        'LONG PASSES ACCURACY',
        'FORWARD PASSES ACCURACY',
    ],

    DM: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'DEFENSIVE DUELS WON',
        'AERIAL DUELS WON',
        'INTERCEPTIONS',
        'PASSES ACCURACY',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'PASSES TO FINAL THIRD ACCURACY',
        'LONG PASSES ACCURACY',
        'FORWARD PASSES ACCURACY',
    ],

    CM: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'DEFENSIVE DUELS WON',
        'AERIAL DUELS WON',
        'INTERCEPTIONS',
        'PASSES ACCURACY',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'PASSES TO FINAL THIRD ACCURACY',
        'LONG PASSES ACCURACY',
        'FORWARD PASSES ACCURACY',
    ],

    AM: [
        'MINUTES PLAYED',
        'DEFENSIVE STATS P90',
        'FORWARD PASSES ACCURACY',
        'AERIAL DUELS WON',
        'INTERCEPTIONS',
        'PASSES ACCURACY',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'SUCCESSFUL DRIBBLES',
        'SHOTS',
        'xG',
    ],

    W: [
        'MINUTES PLAYED',
        'OFFENSIVE STATS P90',
        'AERIAL DUELS WON',
        'SHOTS ON TARGET',
        'SHOTS',
        'SUCCESSFUL CROSSES',
        'OFFENSIVE STATS P90',
        'SCORING CONTRIBUTION',
        'SUCCESSFUL DRIBBLES',
        'FORWARD PASSES ACCURACY',
        'xG',
    ],

    F: [
        'MINUTES PLAYED',
        'OFFENSIVE STATS P90',
        'AERIAL DUELS WON',
        'SUCCESSFUL DRIBBLES',
        'SHOTS ON TARGET',
        'SHOTS',
        'OFFENSIVE STATS P90',
        'GOALS',
        'xG',
        'ASSISTS',
        'xA',
    ],
};

export const translateLabel = label => {
    const labelLower = label.toLowerCase();

    switch (labelLower) {
        case 'minutes played':
            return translate('playerComparison.constants.minutesPlayed');
        case 'defensive stats p90':
            return translate('playerComparison.constants.defensiveStatsP90');
        case 'shots against':
            return translate('playerComparison.constants.shotsAgainst');
        case 'saves':
            return translate('playerComparison.constants.saves');
        case 'conceded goals':
            return translate('playerComparison.constants.concededGoals');
        case 'xcg':
            return translate('playerComparison.constants.xCG');
        case 'offensive stats p90':
            return translate('playerComparison.constants.offensiveStatsP90');
        case 'passes accuracy':
            return translate('playerComparison.constants.passesAccuracy');
        case 'long passes accuracy':
            return translate('playerComparison.constants.longPassesAccuracy');
        case 'forward passes accuracy':
            return translate(
                'playerComparison.constants.forwardPassesAccuracy',
            );
        case 'height':
            return translate('playerComparison.constants.height');
        case 'defensive duels won':
            return translate('playerComparison.constants.defensiveDuelsWon');
        case 'aerial duels won':
            return translate('playerComparison.constants.aerialDuelsWon');
        case 'interceptions':
            return translate('playerComparison.constants.interceptions');
        case 'scoring contributuion':
            return translate('playerComparison.constants.scoringContributuion');
        case 'shots':
            return translate('playerComparison.constants.shots');
        case 'crosses accuracy':
            return translate('playerComparison.constants.crossesAccuracy');
        case 'passes to final third accuracy':
            return translate(
                'playerComparison.constants.passesToFinalThirdAccuracy',
            );
        case 'shots on target':
            return translate('playerComparison.constants.shotsOnTarget');
        case 'successful crosses':
            return translate('playerComparison.constants.successfulCrosses');
            case 'successful dribbles':
            return translate('playerComparison.constants.successfulDribbles');
            case 'xg':
            return translate('playerComparison.constants.xG');
            case 'goals':
            return translate('playerComparison.constants.goals');
            case 'assists':
            return translate('playerComparison.constants.assists');
            case 'xa':
            return translate('playerComparison.constants.xA');
            case 'season':
            return translate('playerComparison.constants.season');
            case 'scoring contribution':
            return translate('playerComparison.constants.scoringContribution');
        default:
            return label;
    }
};


export const seasons = (function() {
    const result = [];
    const date = new Date();
    const newSeason = date.getMonth() > 6 ? 1 : 0;
    let thisYear = date.getFullYear() + newSeason;

    for (let i = 0; i < 4; i++) {
        result.push({
            text: `${translate(
                'playerComparison.constants.season',
            )} ${thisYear - 1}/${thisYear}`,
            date: thisYear - 1,
        });
        --thisYear;
    }

    return result;
})();

const reversedFields = ['CONCEDED GOALS', 'xCG'];

export const isReversedField = getIsFieldIncludedFor(reversedFields);
export const isFieldToHighlightBest = getIsFieldIncludedFor(
    featuresToHighlightBest,
);

export function isFieldToHighlightBestTranslated(field) {
    const fieldsTranslated = featuresToHighlightBest.map(item => {
        return translate(`playerComparison.constants.${item}`);
    });

    const isToHighlight = 
    fieldsTranslated
            .map(camalize)
            .map(f => f.toLowerCase())
            .includes(camalize(field).toLowerCase());

    return isToHighlight;
} 

export function getIsFieldIncludedFor(fields = []) {
    return (field = '') =>
        fields
            .map(camalize)
            .map(f => f.toLowerCase())
            .includes(camalize(field).toLowerCase());
}
