import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import _ from 'lodash';
import { SuccessStoryService } from 'api/admin/success-story/success-story.service';
import { Items, DataValue, StoryModel } from "api/admin/success-story/models";
import { getAuthClubId, getAuthSquadId } from 'store/auth/authReducer';
import moment from 'moment';

export class SuccessStoryState {
    isLoading: boolean;
    items: Items[];
    windowSource: string[];
    countrySource: DataValue[];
    transferTypeSource: DataValue[];
    proofTypeSource: DataValue[];
    keyword: string;
    isSuccessStoryModalOpen: boolean;
    editingItemId: number;
    autosuggestionsState: AutosuggestionsState;
    successStoryDealModal: SuccessStoryDealModal;
}

export interface AutosuggestionsState {
    playerOptions: any[];
    fromSquadOptions: any[];
    toSquadOptions: any[];
    fromAgencyOptions: any[];
    playerProcessing: boolean;
    fromSquadProcessing: boolean;
    toSquadProcessing: boolean;
    fromAgencyProcessing: boolean
    playerKeyword: string;
    fromSquadKeyword: string;
    toSquadKeyword: string;
    fromAgencyKeyword: string;
}

export interface SuccessStoryDealModal {
    window: string;
    date: string;
    player: DataValue;
    isAgency: boolean;
    fromSquad: DataValue;
    fromAgency: DataValue;
    fromCountry: DataValue;
    toSquad: DataValue;
    toCountry: DataValue;
    transferType: number;
    isDomestic: boolean;
    domesticValue: string;
    firstProof: number;
    secondProof: number;
    note: string;
    approved: boolean;
    confirmedSellingSquad: boolean;
    confirmedBuyingSquad: boolean;
    confirmedAgency: boolean;
    doubleDeal: boolean;
}

const defaultState: SuccessStoryState = {
    isLoading: false,
    items: [],
    windowSource: [],
    countrySource: [],
    transferTypeSource: [],
    proofTypeSource: [],
    keyword: '',
    isSuccessStoryModalOpen: false,
    editingItemId: null,
    autosuggestionsState: {
        playerOptions: [],
        fromSquadOptions: [],
        toSquadOptions: [],
        fromAgencyOptions: [],
        playerProcessing: false,
        fromSquadProcessing: false,
        toSquadProcessing: false,
        fromAgencyProcessing: false,
        playerKeyword: '',
        fromSquadKeyword: '',
        toSquadKeyword: '',
        fromAgencyKeyword: '',
    },
    successStoryDealModal: {
        window: '',
        date: '',
        player: null,
        isAgency: false,
        fromSquad: null,
        fromAgency: null,
        fromCountry: null,
        toSquad: null,
        toCountry: null,
        transferType: null,
        isDomestic: false,
        domesticValue: '',
        firstProof: null,
        secondProof: null,
        note: '',
        approved: false,
        confirmedSellingSquad: false,
        confirmedBuyingSquad: false,
        confirmedAgency: false,
        doubleDeal: false,
    },
}

export enum AutosuggestionTypeEnum {
    Player = 0,
    FromSquad = 1,
    FromAgency = 2,
    ToSquad = 3,
};

const stateController = new StateController<SuccessStoryState>(
    'ADMIN_V2/SUCCESS-STORY',
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static getAll(userId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                const data = await SuccessStoryService.getAll(userId);

                dispatch(stateController.setState({
                    items: data.items,
                    windowSource: data.windowSource,
                    countrySource: data.countrySource,
                    transferTypeSource: data.transferTypeSource,
                    proofTypeSource: data.proofTypeSource,
                }));

            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static updateStory(userId: number, storyModel: StoryModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const editingItemId = getState().admin.successStory.editingItemId;

                dispatch(stateController.setState({ isLoading: true }));

                await SuccessStoryService.updateStory(userId, editingItemId, storyModel);
                await dispatch(Actions.getAll(userId))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static createStory(userId: number, storyModel: StoryModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                await SuccessStoryService.createStory(userId, storyModel);
                await dispatch(Actions.getAll(userId))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static removeStory(userId: number, id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                await SuccessStoryService.deleteStory(userId, id);
                await dispatch(Actions.getAll(userId))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static onChangeKeyword(value: string) {
        return dispatch => {
            dispatch(stateController.setState({ keyword: value }))
        }
    }

    public static successStoryModalToggle() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.successStory;
            dispatch(stateController.setState({ isSuccessStoryModalOpen: !substate.isSuccessStoryModalOpen }));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    playerOptions: [],
                    fromSquadOptions: [],
                    toSquadOptions: [],
                    fromAgencyOptions: [],
                }
            })));
        }
    }

    public static setEditingItem(id: number) {
        return (dispatch, getState: () => AppState) => {
            const editingItem = getState().admin.successStory.items.find(item => item.id === id);

            dispatch(stateController.setState({ editingItemId: id }));
            const domesticValue = editingItem?.isDomestic ? 'Domestic' : 'International';
            const isDomestic = editingItem?.isDomestic ? true : false;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    window: editingItem?.window,
                    date: id ? editingItem?.date : moment(),
                    player: { key: editingItem?.player.key, value: editingItem?.player.value },
                    isAgency: editingItem ? editingItem?.fromAgency.key !== null : false,
                    fromSquad: { key: editingItem?.fromSquad.key, value: editingItem?.fromSquad.value },
                    fromCountry: id === null ? null : { key: editingItem?.fromCountry.key, value: editingItem?.fromCountry.value },
                    toSquad: { key: editingItem?.toSquad.key, value: editingItem?.toSquad.value },
                    toCountry: id === null ? null : { key: editingItem?.toCountry.key, value: editingItem?.toCountry.value },
                    fromAgency: { key: editingItem?.fromAgency.key, value: editingItem?.fromAgency.value },
                    approved: editingItem ? editingItem?.approved : false,
                    confirmedSellingSquad: editingItem ? editingItem?.confirmedSellingSquad : false,
                    confirmedBuyingSquad: editingItem ? editingItem?.confirmedBuyingSquad : false,
                    confirmedAgency: editingItem ? editingItem?.confirmedAgency : false,
                    doubleDeal: editingItem ? editingItem?.doubleDeal : false,
                    transferType: editingItem?.transferType,
                    isDomestic: id === null ? null : isDomestic,
                    domesticValue: id === null ? null : domesticValue,
                    firstProof: editingItem?.firstProof,
                    secondProof: editingItem?.secondProof,
                    note: editingItem?.note,
                }
            })));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    playerKeyword: editingItem?.player.value,
                    fromSquadKeyword: editingItem?.fromSquad.value,
                    toSquadKeyword: editingItem?.toSquad.value,
                    fromAgencyKeyword: editingItem?.fromAgency.value
                }
            })));
        }
    }

    public static onChangePlayerKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    playerKeyword: value,
                }
            })));

            if (value) {
                Actions.loadAutosuggestionsDebounceFuncPlayer(dispatch, value);
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        playerOptions: [],
                    }
                })));
            }
        }
    }

    public static onChangeFromSquadKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    fromSquadKeyword: value,
                }
            })));

            if (value) {
                Actions.loadAutosuggestionsDebounceFuncFromSquad(dispatch, value);
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromSquadOptions: [],
                    }
                })));
            }
        }
    }

    public static onChangeFromAgencyKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    fromAgencyKeyword: value,
                }
            })));

            if (value) {
                Actions.loadAutosuggestionsDebounceFuncAgency(dispatch, value);
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromAgencyOptions: [],
                    }
                })));
            }
        }
    }

    public static onChangeToSquadKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    toSquadKeyword: value,
                }
            })));

            if (value) {
                Actions.loadAutosuggestionsDebounceFuncToSquad(dispatch, value);
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        toSquadOptions: [],
                    }
                })));
            }
        }
    }

    private static loadAutosuggestionsDebounceFuncPlayer = _.debounce((dispatch, keyword) => dispatch(Actions.getAutosuggestionsPlayer(keyword)), 1000);
    private static loadAutosuggestionsDebounceFuncFromSquad = _.debounce((dispatch, keyword) => dispatch(Actions.getAutosuggestionsFromSquad(keyword)), 1000)
    private static loadAutosuggestionsDebounceFuncAgency = _.debounce((dispatch, keyword) => dispatch(Actions.getAutosuggestionsAgency(keyword)), 1000)
    private static loadAutosuggestionsDebounceFuncToSquad = _.debounce((dispatch, keyword) => dispatch(Actions.getAutosuggestionsToSquad(keyword)), 1000)

    public static getAutosuggestionsPlayer(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState());
                const squadId = getAuthSquadId(getState());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        playerProcessing: true,
                    }
                })));

                const options = await SuccessStoryService.loadPlayerSuggestions(keyword, clubId, squadId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        playerOptions: options,
                    }
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        playerProcessing: false,
                    }
                })));
            }
        }
    }

    public static getAutosuggestionsFromSquad(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState());
                const squadId = getAuthSquadId(getState());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromSquadProcessing: true,
                    }
                })));

                const options = await SuccessStoryService.loadSquadSuggestions(keyword, clubId, squadId, []);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromSquadOptions: options,
                    }
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromSquadProcessing: false,
                    }
                })));
            }
        }
    }

    public static getAutosuggestionsAgency(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromAgencyProcessing: true,
                    }
                })));

                const options = await SuccessStoryService.loadAgencySuggestions(keyword);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromAgencyOptions: options,
                    }
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        fromAgencyProcessing: false,
                    }
                })));
            }
        }
    }

    public static getAutosuggestionsToSquad(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState());
                const squadId = getAuthSquadId(getState());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        toSquadProcessing: true,
                    }
                })));

                const options = await SuccessStoryService.loadSquadSuggestions(keyword, clubId, squadId, []);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        toSquadOptions: options,
                    }
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autosuggestionsState: {
                        ...prevState.autosuggestionsState,
                        toSquadProcessing: false,
                    }
                })));
            }
        }
    }

    public static selectFromSquad(id: number, value: string) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    fromSquad: { key: id, value: value },
                }
            })));

            dispatch(Actions.onChangeFromSquadKeyword(value));
        }
    };

    public static selectFromAgency(id: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    fromAgency: { key: id, value: value },
                }
            })));

            dispatch(Actions.onChangeFromAgencyKeyword(value));
        }
    };

    public static selectFromCountry(id: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    fromCountry: { key: id, value: value },
                }
            })));
        }
    };

    public static selectToCountry(id: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    toCountry: { key: id, value: value },
                }
            })));
        }
    };

    public static selectToSquad(id: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    toSquad: { key: id, value: value },
                }
            })));

            dispatch(Actions.onChangeToSquadKeyword(value));
        }
    };

    public static selectPlayer(id: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    player: { key: id, value: value },
                }
            })));

            dispatch(Actions.onChangePlayerKeyword(value));
        }
    };

    public static selectWindow(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    window: value,
                }
            })));
        }
    }

    public static selectDate(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    date: value,
                }
            })));
        }
    }

    public static setIsAgency(value) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsState: {
                    ...prevState.autosuggestionsState,
                    fromSquadKeyword: '',
                    fromAgencyKeyword: '',
                }
            })));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    fromSquad: null,
                    fromAgency: null,
                    isAgency: value,
                },
            })));;
        }
    };

    public static setApproved(value) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    approved: value,
                }
            })));
        }
    };

    public static setConfirmedSellingSquad(value) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    confirmedSellingSquad: value,
                }
            })));
        }
    };

    public static setConfirmedBuyingSquad(value) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    confirmedBuyingSquad: value,
                }
            })));
        }
    };

    public static setConfirmedAgency(value) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    confirmedAgency: value,
                }
            })));
        }
    };

    public static setDoubleDeal(value) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    doubleDeal: value,
                }
            })));
        }
    };

    public static setNote(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    note: value,
                }
            })));
        }
    }

    public static selectTransferType(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    transferType: value,
                }
            })));
        }
    }

    public static selectisDomestic(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    isDomestic: value === 1 ? true : false,
                    domesticValue: value === 1 ? 'Domestic' : 'International',
                }
            })));
        }
    }

    public static selectFirstProof(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    firstProof: value,
                }
            })));
        }
    }

    public static selectSecondProof(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                successStoryDealModal: {
                    ...prevState.successStoryDealModal,
                    secondProof: value,
                }
            })));
        }
    }

    public static onClearAutosuggestions(autosuggestionType: number) {
        return (dispatch) => {
            switch (autosuggestionType) {
                case 0:
                    return dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        successStoryDealModal: {
                            ...prevState.successStoryDealModal,
                            player: null,
                        },
                    })))
                case 1:
                    return dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        successStoryDealModal: {
                            ...prevState.successStoryDealModal,
                            fromSquad: null,
                        },
                    })));;
                case 2:
                    return dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        successStoryDealModal: {
                            ...prevState.successStoryDealModal,
                            fromAgency: null,
                        },
                    })));;
                case 3:
                    return dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        successStoryDealModal: {
                            ...prevState.successStoryDealModal,
                            toSquad: null,
                        },
                    })));;
                default:
                    return null;
            }
        }
    }

}

class Selectors {
    public static selectState = (state: AppState) => state.admin.successStory;
    public static getFilteredItems(state: AppState) {
        const keyword = Selectors.selectState(state).keyword;
        return Selectors.selectState(state).items
            .filter(item => !keyword.length || (
                item.toSquad.value
                    .toLowerCase()
                    .includes(keyword.toLowerCase()) ||
                (item.fromSquad.value !== null && item.fromSquad.value
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||

                (item.fromAgency.value !== null && item.fromAgency.value
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||

                (item.fromCountry.value !== null && item.fromCountry.value
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||

                (item.toCountry.value !== null && item.toCountry.value
                    .toLowerCase()
                    .includes(keyword.toLowerCase()))
            ));
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SuccessStoryState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};