import { StateController } from 'utils/action-declaration';
import { AgencyPermissions as AgencyPermissionsService } from 'admin-v2/pages/agency-permissions/agency-permissions.service';
import { AppState } from 'root.reducer';
import {
    AdditionalPlayerMarketSubscriptionEnum,
    AgenciesSubscriptionEnum,
    CoachAccessOptionsModel,
    Country,
    EventAccessOptionsModel,
    FilterOptions,
    Permissions,
    PlayerMarketModel,
    PlayerMarketsSubscriptionModel,
    SalesSupervisor,
    AgenciesSubscriptionExtendedModel,
    CoachAccessOptionEnum,
    EventAccessOptionEnum
} from 'admin-v2/pages/agency-permissions/models';
import _ from 'lodash';
import { AreaItem } from 'api/area/model';
import { AreaService } from 'api/area/area.service';
import {
    RepresentationRequestSettingOption,
    RepresentationRequestSettingOptionEnum
} from 'api/agency-permissions/model';
import { notificationCreate } from 'app/notifications/notifications.actions';

export enum PlayerMarketModalStep {
    Default = 1,
    Confirm = 2,
}

export interface PlayerMarketRow extends PlayerMarketModel  {
    isInitial: boolean;
}

const defaultPlayerMarket: PlayerMarketRow = {
    id: -1,
    areaId: -1,
    areaName: '',
    isExpert: false,
    isInitial: false,
}

export interface PlayerMarketModalState {
    isModalOpen: boolean;
    isRemoveModalOpen: boolean;
    isProcessing: boolean;
    isEdit: boolean;
    agencyName: string;
    agencyId: number | null;
    removedAreaId: number | null;
    currentStep: PlayerMarketModalStep;
    playerMarkets: PlayerMarketRow[];
    initialPlayerMarkets: PlayerMarketRow[];
}

const defaultPlayerMarketModalState: PlayerMarketModalState = {
    isModalOpen: false,
    isRemoveModalOpen: false,
    isProcessing: false,
    isEdit: false,
    agencyName: '',
    agencyId: null,
    removedAreaId: null,
    currentStep: PlayerMarketModalStep.Default,
    playerMarkets: [],
    initialPlayerMarkets: [],
}

class AgencyPermissionState {
    countries: Array<Country>;
    marketAreas: AreaItem[];
    filterOptions: FilterOptions;
    supervisors: Array<SalesSupervisor>;
    isLoading: boolean;
    isAdditionalInfoLoading: boolean;
    isFiltersSetToDefault: boolean;
    permissions: Array<Permissions>;
    processing: Array<number>;
    processingPermission: Array<string>;
    suggestionItems: Array<any>;
    autosuggestProcessing: boolean;
    editPlusPitchesCountMode: boolean;
    editCustomPitchesCountMode: boolean;
    editPriorityCreditsCountMode: boolean;
    editRegularCreditsCountMode: boolean;
    processingEditModeId: number;
    playerMarketModalState: PlayerMarketModalState;
    playerMarketsSubscriptions: PlayerMarketsSubscriptionModel[];
    agenciesSubscriptions: AgenciesSubscriptionExtendedModel[];
    coachAccessOptions: CoachAccessOptionsModel[];
    eventAccessOptions: EventAccessOptionsModel[];
    representationRequestOptions: RepresentationRequestSettingOption[];
    processingRepresentationRequestIds: number[];
    processingPlayerMarketIds: number[];
    processingSubscriptionsIds: number[];
    processingCoachAccessIds: number[];
}

const defaultFilterOptions: FilterOptions = {
    agencyId: null,
    areaId: null,
    subscriptionId: null,
    supervisorId: null,
    withUsersOnly: false,
    supervisor: '',
    country: '',
    keyword: '',
}

const defaultState: AgencyPermissionState = {
    isLoading: false,
    isAdditionalInfoLoading: false,
    countries: [],
    marketAreas: [],
    supervisors: [],
    filterOptions: defaultFilterOptions,
    isFiltersSetToDefault: false,
    permissions: [],
    processing: [],
    processingPermission: [],
    suggestionItems: [],
    autosuggestProcessing: false,
    editPlusPitchesCountMode: false,
    editCustomPitchesCountMode: false,
    editPriorityCreditsCountMode: false,
    editRegularCreditsCountMode: false,
    processingEditModeId: null,
    playerMarketModalState: defaultPlayerMarketModalState,
    playerMarketsSubscriptions: [],
    agenciesSubscriptions: [],
    coachAccessOptions: [],
    eventAccessOptions: [],
    representationRequestOptions: [],
    processingRepresentationRequestIds: [],
    processingPlayerMarketIds: [],
    processingSubscriptionsIds: [],
    processingCoachAccessIds: [],
}

const stateController = new StateController<AgencyPermissionState>(
    'ADMIN/AGENCY-PERMISSIONS',
    defaultState
)

class Actions {

    public static disposeFilters() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })));
            dispatch(Actions.loadAgencies());
        };
    };

    public static loadSupervisors() {
        return async dispatch => {
            const salesSupervisors = await AgencyPermissionsService.getSalesSupervisorList();
            dispatch(stateController.setState({ supervisors: salesSupervisors }));
        };
    };

    public static getAdditionalPlayerMarketsSubscriptions() {
        return async dispatch => {
            const additionalPlayerMarketSubscriptions = await AgencyPermissionsService.getAdditionalPlayerMarketSubscriptions();

            dispatch(stateController.setState({ playerMarketsSubscriptions: additionalPlayerMarketSubscriptions }));
        };
    };

    public static getAllAgenciesSubscriptions() {
        return async dispatch => {
            const agenciesSubscriptions = await AgencyPermissionsService.getAllAgenciesSubscriptions();


            const agenciesSubscriptionsExtended = agenciesSubscriptions.map(item => {

                switch (item.id) {
                    case AgenciesSubscriptionEnum.Custom: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.None,
                            plusPitchesCount: undefined,
                            customPitch: undefined,
                            transparencyChecked: undefined,
                            representationRequestSettingOptionId: undefined,
                            additionalPlayerMarketSubscriptionId: undefined,
                            inPersonId: EventAccessOptionEnum.None,
                            onlineId: EventAccessOptionEnum.None,
                            agencyCustomPitchesCount: undefined,
                        }
                    }
                    case AgenciesSubscriptionEnum.Temporary: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.None,
                            plusPitchesCount: 0,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.None,
                            inPersonId: EventAccessOptionEnum.None,
                            onlineId: EventAccessOptionEnum.None,
                            agencyCustomPitchesCount: 0,
                        }
                    }
                    case AgenciesSubscriptionEnum.Starter: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Database,
                            plusPitchesCount: 0,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.LimitedTo500k,
                            inPersonId: EventAccessOptionEnum.None,
                            onlineId: EventAccessOptionEnum.None,
                            agencyCustomPitchesCount: -1,
                        }
                    }
                    case AgenciesSubscriptionEnum.Basic: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Database,
                            plusPitchesCount: 0,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.None,
                            inPersonId: EventAccessOptionEnum.None,
                            onlineId: EventAccessOptionEnum.OneOffOffer,
                            agencyCustomPitchesCount: -1,
                        }
                    }
                    case AgenciesSubscriptionEnum.BasicPlus: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Full,
                            plusPitchesCount: 2,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.LimitedTo500k,
                            inPersonId: EventAccessOptionEnum.None,
                            onlineId: EventAccessOptionEnum.Purchase,
                            agencyCustomPitchesCount: -1,
                        }
                    }
                    case AgenciesSubscriptionEnum.Premium: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Full,
                            plusPitchesCount: 4,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.Full,
                            inPersonId: EventAccessOptionEnum.OneOffOffer,
                            onlineId: EventAccessOptionEnum.Purchase,
                            agencyCustomPitchesCount: -1,
                        }
                    }
                    case AgenciesSubscriptionEnum.PremiumPlus: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Full,
                            plusPitchesCount: 8,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.Full,
                            inPersonId: EventAccessOptionEnum.Purchase,
                            onlineId: EventAccessOptionEnum.Purchase,
                            agencyCustomPitchesCount: -1,
                        }
                    }
                    case AgenciesSubscriptionEnum.Vip: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Full,
                            plusPitchesCount: 15,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.Full,
                            inPersonId: EventAccessOptionEnum.OneEventTwoAttendees,
                            onlineId: EventAccessOptionEnum.OneEvent,
                            agencyCustomPitchesCount: -1,
                        }
                    }
                    case AgenciesSubscriptionEnum.VipPlus: {
                        return {
                            ...item,
                            coachAccessId: CoachAccessOptionEnum.Full,
                            plusPitchesCount: 30,
                            customPitch: true,
                            transparencyChecked: true,
                            representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum.ShowAll,
                            additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum.Full,
                            inPersonId: EventAccessOptionEnum.TwoEvent,
                            onlineId: EventAccessOptionEnum.TwoEvent,
                            agencyCustomPitchesCount: -1,
                        }
                    }

                    default: {
                        return {
                            ...item,
                        }
                    }
                }
            });

            dispatch(stateController.setState({ agenciesSubscriptions: agenciesSubscriptionsExtended }));
        };
    };

    public static getCoachAccessOptions () {
        return async dispatch => {
            const coachAccessOptions = await AgencyPermissionsService.getCoachAccessOptions();

            dispatch(stateController.setState({ coachAccessOptions: coachAccessOptions }));
        };
    };

    public static getEventAccessOptions () {
        return async dispatch => {
            const eventAccessOptions = await AgencyPermissionsService.getEventAccessOptions();

            dispatch(stateController.setState({ eventAccessOptions: eventAccessOptions }));
        };
    };

    public static getRepresentationRequestSettingOptions() {
        return async dispatch => {
            const additionalPlayerMarketSubscriptions = await AgencyPermissionsService.getRepresentationRequestOptions();

            dispatch(stateController.setState({ representationRequestOptions: additionalPlayerMarketSubscriptions }));
        };
    };

    public static loadCountries(userId) {
        return async dispatch => {
            const countriesList = await AgencyPermissionsService.getCountries(userId);
            dispatch(stateController.setState({ countries: countriesList }));
        };
    };

    public static loadMarketAreas = () => {
        return async dispatch => {
            const { items } = await AreaService.getCountries();
            dispatch(stateController.setState({ marketAreas: items }));
        };
    };

    public static loadInitialData(userId) {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isAdditionalInfoLoading: true }));
                await Promise.all([
                    dispatch(Actions.loadCountries(userId)),
                    dispatch(Actions.loadMarketAreas()),
                    dispatch(Actions.loadSupervisors()),
                    dispatch(Actions.getAdditionalPlayerMarketsSubscriptions()),
                    dispatch(Actions.getAllAgenciesSubscriptions()),
                    dispatch(Actions.getCoachAccessOptions()),
                    dispatch(Actions.getEventAccessOptions()),
                    dispatch(Actions.getRepresentationRequestSettingOptions()),
                ]);
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isAdditionalInfoLoading: false }));
            }
        };
    };

    public static loadAgencies() {
        var getDefaultCustomPitchesCount = (agency:any) => 
            agency.subscriptionPlanId !== AgenciesSubscriptionEnum.Temporary ? - 1 : 0;

        return async (dispatch, getState) => {
            try {
                dispatch(stateController.setState({ isLoading: true, permissions: [] }));

                const agencies = await AgencyPermissionsService.getAgencyPermissions(getState().admin.agencyPermissions.filterOptions);

                const agenciesIds = agencies.map(({ id }) => id);

                const permissions = await AgencyPermissionsService.getPlayerCostPermissions(
                    agenciesIds,
                );

                const agencyPermission = [];

                agencies.forEach(element => {
                    const permission = permissions.find(i => i.agencyId === element.id) 
                        || { plusPitchesCount: 0, agencyCustomPitchesCount: getDefaultCustomPitchesCount(element) }
                    agencyPermission.push({
                        id: element.id,
                        name: element.name,
                        agencyId: element.id,
                        salesSupervisorId: element.successExpertUserId,
                        successExpertUserName: element.successExpertUserName,
                        permissionCodes: element.permissionCodes,
                        playerMarkets: element.playerMarkets,
                        selectedMarketNames: element.selectedMarketNames,
                        marketExpertNames: element.marketExpertNames,
                        isLta: element.isLta,
                        additionalPlayerMarketSubscriptionId: element.additionalPlayerMarketSubscriptionId,
                        representationRequestSettingOptionId: element.representationRequestSettingOptionId,
                        subscriptionPlanId: element.subscriptionPlanId,
                        subscriptionPlanName: element.subscriptionPlanName,
                        priorityCredits: element.priorityCredits,
                        regularCredits: element.regularCredits,
                        usersNumber: element.usersNumber,
                        coachAccessId: element.coachAccessId,
                        inPersonId: element.inPersonId,
                        onlineId: element.onlineId,
                        slotsInUse: element.slotsInUse,
                        regularPlayersSlotsInUse: element.regularPlayersSlotsInUse,
                        maxUserAmount: element.maxUserAmount,
                        maxUserAmountText: element.maxUserAmountText,
                        ...permission
                    });
                });

                dispatch(stateController.setState({
                    permissions: agencyPermission.map(agency => ({
                        id: agency.id,
                        agencyId: agency.id,
                        name: agency.name,
                        salesSupervisorId: agency.salesSupervisorId,
                        plusPitchesCount: agency.plusPitchesCount,
                        agencyCustomPitchesCount: agency.agencyCustomPitchesCount,
                        successExpertUserName: agency.successExpertUserName,
                        permissionCodes: agency.permissionCodes,
                        checked: agency.permissionCodes.includes('AgencyCustomPitch'),
                        transparencyChecked: agency.permissionCodes.includes('AgencyTransparency'),
                        playerMarketChecked: agency.permissionCodes.includes('AgencyPlayerMarket'),
                        editPlusPitchesCountMode: false,
                        editCustomPitchesCountMode: false,
                        editPriorityCreditsCountMode: false,
                        editRegularCreditsCountMode: false,
                        playerMarkets: agency.playerMarkets,
                        selectedMarketNames: agency.selectedMarketNames,
                        marketExpertNames: agency.marketExpertNames,
                        isLta: agency.isLta,
                        additionalPlayerMarketSubscriptionId: agency.additionalPlayerMarketSubscriptionId,
                        representationRequestSettingOptionId: agency.representationRequestSettingOptionId,
                        subscriptionPlanId: agency.subscriptionPlanId,
                        subscriptionPlanName: agency.subscriptionPlanName,
                        priorityCredits: agency.priorityCredits,
                        regularCredits: agency.regularCredits,
                        usersNumber: agency.usersNumber,
                        coachAccessId: agency.coachAccessId,
                        inPersonId: agency.inPersonId,
                        onlineId: agency.onlineId,
                        slotsInUse: agency.slotsInUse,
                        regularPlayersSlotsInUse: agency.regularPlayersSlotsInUse,
                        maxUserAmount: agency.maxUserAmount,
                        maxUserAmountText: agency.maxUserAmountText,
                    })),
                }));
            } catch (error) {
                dispatch(stateController.setState({ isLoading: false }));
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    };

    public static loadAgenciesSilent() {
        var getDefaultCustomPitchesCount = (agency:any) => 
            agency.subscriptionPlanId !== AgenciesSubscriptionEnum.Temporary ? - 1 : 0;
        return async (dispatch, getState) => {
            try {

                const agencies = await AgencyPermissionsService.getAgencyPermissions(getState().admin.agencyPermissions.filterOptions);

                const agenciesIds = agencies.map(({ id }) => id);

                const permissions = await AgencyPermissionsService.getPlayerCostPermissions(
                    agenciesIds,
                );

                const agencyPermission = [];

                agencies.forEach(element => {
                    const permission = permissions.find(i => i.agencyId === element.id) 
                        || { plusPitchesCount: 0, agencyCustomPitchesCount: getDefaultCustomPitchesCount(element) }
                    agencyPermission.push({
                        id: element.id,
                        name: element.name,
                        agencyId: element.id,
                        salesSupervisorId: element.successExpertUserId,
                        successExpertUserName: element.successExpertUserName,
                        permissionCodes: element.permissionCodes,
                        playerMarkets: element.playerMarkets,
                        selectedMarketNames: element.selectedMarketNames,
                        marketExpertNames: element.marketExpertNames,
                        isLta: element.isLta,
                        additionalPlayerMarketSubscriptionId: element.additionalPlayerMarketSubscriptionId,
                        representationRequestSettingOptionId: element.representationRequestSettingOptionId,
                        subscriptionPlanId: element.subscriptionPlanId,
                        subscriptionPlanName: element.subscriptionPlanName,
                        priorityCredits: element.priorityCredits,
                        regularCredits: element.regularCredits,
                        usersNumber: element.usersNumber,
                        coachAccessId: element.coachAccessId,
                        inPersonId: element.inPersonId,
                        onlineId: element.onlineId,
                        slotsInUse: element.slotsInUse,
                        regularPlayersSlotsInUse: element.regularPlayersSlotsInUse,
                        maxUserAmount: element.maxUserAmount,
                        maxUserAmountText: element.maxUserAmountText,
                        agencyCustomPitchesCount : element.agencyCustomPitchesCount,
                        ...permission
                    });
                });

                dispatch(stateController.setState({
                    permissions: agencyPermission.map(agency => ({
                        id: agency.id,
                        agencyId: agency.id,
                        name: agency.name,
                        salesSupervisorId: agency.salesSupervisorId,
                        plusPitchesCount: agency.plusPitchesCount,
                        agencyCustomPitchesCount: agency.agencyCustomPitchesCount,
                        successExpertUserName: agency.successExpertUserName,
                        permissionCodes: agency.permissionCodes,
                        checked: agency.permissionCodes.includes("AgencyCustomPitch"),
                        transparencyChecked: agency.permissionCodes.includes("AgencyTransparency"),
                        playerMarketChecked: agency.permissionCodes.includes("AgencyPlayerMarket"),
                        editPlusPitchesCountMode: false,
                        editCustomPitchesCountMode: false,
                        editPriorityCreditsCountMode: false,
                        editRegularCreditsCountMode: false,
                        playerMarkets: agency.playerMarkets,
                        selectedMarketNames: agency.selectedMarketNames,
                        marketExpertNames: agency.marketExpertNames,
                        isLta: agency.isLta,
                        additionalPlayerMarketSubscriptionId: agency.additionalPlayerMarketSubscriptionId,
                        representationRequestSettingOptionId: agency.representationRequestSettingOptionId,
                        subscriptionPlanId: agency.subscriptionPlanId,
                        subscriptionPlanName: agency.subscriptionPlanName,
                        priorityCredits: agency.priorityCredits,
                        regularCredits: agency.regularCredits,
                        usersNumber: agency.usersNumber,
                        coachAccessId: agency.coachAccessId,
                        inPersonId: agency.inPersonId,
                        onlineId: agency.onlineId,
                        slotsInUse: agency.slotsInUse,
                        regularPlayersSlotsInUse: agency.regularPlayersSlotsInUse,
                        maxUserAmount: agency.maxUserAmount,
                        maxUserAmountText: agency.maxUserAmountText,
                    })),
                }));
            } catch (error) {
                console.error(error)
            }
        };
    };

    public static setPermission(agencyId, value: boolean, permissionCode: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.agencyPermissions;
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: [...prevState.processing, agencyId],
                    processingPermission: [...prevState.processing, permissionCode]
                })))

                if (!value) {
                    await AgencyPermissionsService.disallowAction(agencyId, permissionCode);
                } else {
                    await AgencyPermissionsService.allowAction(agencyId, permissionCode);
                }

                const updatedItems = [...substate.permissions];
                let updatedItem = updatedItems.find(item => item.id === agencyId);
                if (permissionCode == "AgencyCustomPitch")
                    updatedItem.checked = value;
                if (permissionCode == "AgencyTransparency")
                    updatedItem.transparencyChecked = value;
                if (permissionCode == "AgencyPlayerMarket") {
                    updatedItem.playerMarketChecked = value;
                    if (value) {
                        updatedItem.permissionCodes.push('AgencyPlayerMarket');
                    } else {
                        updatedItem.permissionCodes = updatedItem.permissionCodes.filter(item => item !== 'AgencyPlayerMarket');
                    }
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    permissions: updatedItems
                })))

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: prevState.processing.filter(item => item !== agencyId),
                    processingPermission: prevState.processingPermission.filter(x => x !== permissionCode)
                })))
            }
        }
    };

    public static toggleEditMode(agencyId: number, editMode: boolean, countMode: string) {
        return async (dispatch, getState) => {
            const substate = getState().admin.agencyPermissions;
            const updatedItems = [...substate.permissions];
            if (countMode === 'PlusPitchesCount') {
                updatedItems.find(item => item.id === agencyId).editPlusPitchesCountMode = !editMode;
            } else if (countMode === 'AgencyCustomPitchesCount') {
                updatedItems.find(item => item.id === agencyId).editCustomPitchesCountMode = !editMode;
            } else if (countMode === 'PriorityCredits') {
                updatedItems.find(item => item.id === agencyId).editPriorityCreditsCountMode = !editMode;
            } else if (countMode === 'RegularCredits') {
                updatedItems.find(item => item.id === agencyId).editRegularCreditsCountMode = !editMode;
            }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                permissions: updatedItems
            })))
        };
    };

    public static editPermissionCostValue = (agencyId: number, key: string, value: string) => {
        return async (dispatch, getState) => {
            const oldPermissions = _.cloneDeep(Selectors.getPermissions(getState()))
            try {
                const newPermissions = Selectors.getPermissions(getState())
                const newAgencyPermission = newPermissions.find(x => x.agencyId === agencyId);
                const objectKey = Object.keys(newAgencyPermission).find(x => x.toLowerCase() === key.toLowerCase());
                newAgencyPermission[objectKey] = +value;
                dispatch(stateController.setState({ permissions: [...newPermissions] }));

                await AgencyPermissionsService.editCostValue(
                    {
                        agencyId,
                        permissionCode: key,
                        value: +value
                    },
                );

            } catch (error) {
                dispatch(stateController.setState({ permissions: [...oldPermissions] }));
                throw error
            }
        };
    };

    public static editSubscriptionsCreditsValue = (agencyId: number, key: string, value: number) => {
        return async (dispatch, getState) => {
            const oldPermissions = _.cloneDeep(Selectors.getPermissions(getState()));
            try {
                const newPermissions = Selectors.getPermissions(getState());
                const newValue = +value;
                const newAgencyPermission = newPermissions.find(x => x.agencyId === agencyId);
                const objectKey = Object.keys(newAgencyPermission).find(x => x.toLowerCase() === key.toLowerCase());
                newAgencyPermission[objectKey] = newValue;
                dispatch(stateController.setState({ permissions: [...newPermissions] }));
                
                if (key === 'PriorityCredits') {
                    const isUnlimitedRegularCredits = newAgencyPermission.regularCredits < 0;
                    const usedCredits = newAgencyPermission.slotsInUse;
                    const isSaveAvailable = isUnlimitedRegularCredits
                        ? newValue >= 0 && newValue >= usedCredits
                        : newValue >= 0 && newValue >= usedCredits && newValue <= newAgencyPermission.regularCredits;

                    if (isSaveAvailable) {
                        await AgencyPermissionsService.editSubscriptionsCredits(
                            {
                                agencyId,
                                isPriority: true,
                                value: newValue
                            },
                        );
                    } else {
                        if (newValue < 0) {
                            dispatch(notificationCreate({ message: 'Priority credits can’t be negative', level: 'error'}))
                        } else if (newValue < usedCredits) {
                            dispatch(notificationCreate({ message: `Amount of Priority Credits must be more or equal than currently used Priority Credits (${usedCredits})`, level: 'error'}))
                        } else {
                            dispatch(notificationCreate({ message: 'Priority credits can’t be greater than Total Credits', level: 'error'}))
                        }
                    }
                } else {
                    const isUnlimitedRegularCredits = newValue < 0;
                    const usedCredits = newAgencyPermission.regularPlayersSlotsInUse;
                    const isSaveAvailable = isUnlimitedRegularCredits ? true : newValue >= usedCredits && newValue >= newAgencyPermission.priorityCredits;

                    if (isSaveAvailable) {
                        await AgencyPermissionsService.editSubscriptionsCredits(
                            {
                                agencyId,
                                isPriority: false,
                                value: newValue
                            },
                        );
                    } else {
                        if (newValue < usedCredits) {
                            dispatch(notificationCreate({ message: `Amount of Total Credits must be more or equal than currently used Total Credits (${usedCredits})`, level: 'error'}))
                        } else {
                            dispatch(notificationCreate({ message: 'Priority credits can’t be greater than Total Credits', level: 'error'}))
                        }
                    }
                }
            } catch (error) {
                dispatch(stateController.setState({ permissions: [...oldPermissions] }));
                throw error
            }
        };
    };

    public static onKeywordChange(value: string) {
        return async (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    keyword: value
                }
            })));
            if (value) {
                Actions.loadFirstSuggestionsDebounceFunc(dispatch, value)
            } else {
                dispatch(stateController.setState({ suggestionItems: [] }))
            }
        };
    };

    public static onClearKeyword() {
        return async (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    keyword: '',
                    agencyId: null
                }
            })));
            dispatch(Actions.loadAgencies());
        }
    }

    private static loadFirstSuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getAgenciesAutosuggestions(keyword)), 1000)

    public static getAgenciesAutosuggestions(keyword: string) {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ autosuggestProcessing: true }))
                const suggestionItems = await AgencyPermissionsService.getAllAgencies({
                    filter: {
                        keyword: keyword,
                        onlyAgencies: true
                    },
                    pageSize: 60,
                })

                dispatch(stateController.setState({ suggestionItems: suggestionItems.items.pageItems }))
            } finally {
                dispatch(stateController.setState({ autosuggestProcessing: false }))
            }
        };
    };

    public static selectAgensy(value: number) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    agencyId: value,

                }
            })));
            dispatch(Actions.loadAgencies());
        };
    };

    public static onChangeSupervisor(value: string) {
        return (dispatch, getState) => {
            const supervisors = getState().admin.agencyPermissions.supervisors;
            if (value !== 'none' && value !== 'any') {
                const supervisorId = supervisors.find(el => el.fullName === value).userId;
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    filterOptions: {
                        ...prevState.filterOptions,
                        supervisor: value,
                        supervisorId: supervisorId,
                    }
                })));
            } else {
                if (value === 'none') {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        filterOptions: {
                            ...prevState.filterOptions,
                            supervisor: 'No Supervisor',
                            supervisorId: 0,
                        }
                    })));
                }
                if (value === 'any') {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        filterOptions: {
                            ...prevState.filterOptions,
                            supervisor: 'Any Supervisor',
                            supervisorId: null,
                        }
                    })));
                }
            }
            dispatch(Actions.loadAgencies());
        };
    };

    public static onChangeCountry(value: string) {
        return (dispatch, getState) => {
            if (value !== 'none') {
                const countries = getState().admin.agencyPermissions.countries;
                const countryId = countries.find(el => el.name === value).id
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    filterOptions: {
                        ...prevState.filterOptions,
                        country: value,
                        areaId: countryId,
                    }
                })));
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    filterOptions: {
                        ...prevState.filterOptions,
                        country: 'None',
                        areaId: null,
                    }
                })));
            }
            dispatch(Actions.loadAgencies());
        };
    };

    public static onChangeSubscription(subscriptionId: number) {
        return (dispatch, getState: () => AppState) => {
            const subscriptionName = getState().admin.agencyPermissions.agenciesSubscriptions.find(item => item.id === subscriptionId)?.name

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    subscriptionId: subscriptionId,
                    subscription: subscriptionName,
                }
            })));
            
            dispatch(Actions.loadAgencies());
        };
    };

    public static onTogleAgencieWithUser(checked) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    withUsersOnly: checked
                }
            })));
            dispatch(Actions.loadAgencies());
        };
    };

    public static disposePlayerMarketModal() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: defaultPlayerMarketModalState
            })))
        };
    }

    public static openPlayerMarketModal(data: Permissions) {
        return (dispatch) => {
            const playerMarkets = [ ...data.playerMarkets ];
            const isEdit = playerMarkets.length > 0;
            let playerMarketsExtended = [];
            let playerMarketsInitialExtended = [];

            if (playerMarkets.length) {
                playerMarketsExtended = playerMarkets.map((playerMarket, index) => ({
                    ...playerMarket,
                    isInitial: isEdit
                }));
                playerMarketsInitialExtended = playerMarkets.map((playerMarket, index) => ({
                    ...playerMarket,
                    isInitial: isEdit
                }));
            }

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    isModalOpen: true,
                    agencyName: data.name,
                    agencyId: data.agencyId,
                    isEdit: isEdit,
                    playerMarkets: isEdit ? [ ...playerMarketsExtended ] : [ {...defaultPlayerMarket } ],
                    initialPlayerMarkets: isEdit ? [ ...playerMarketsInitialExtended ] : [ {...defaultPlayerMarket } ],
                }
            })));
        }
    }

    public static closePlayerMarketModal() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: defaultPlayerMarketModalState
            })))
        }
    }

    public static onSelectPlayerMarket(id: string, index: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.agencyPermissions;
            const selectedMarketArea = substate.marketAreas.find(item => item.id === Number(id));
            const currentPlayerMarkets = substate.playerMarketModalState.playerMarkets;

            currentPlayerMarkets[index].areaId = selectedMarketArea.id;
            currentPlayerMarkets[index].areaName = selectedMarketArea.name;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    playerMarkets: [ ...currentPlayerMarkets ],
                }
            })));
        }
    }

    public static onMarketExpertToggle(value: boolean, index: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.agencyPermissions;
            const currentPlayerMarkets = substate.playerMarketModalState.playerMarkets;

            currentPlayerMarkets[index].isExpert = value;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    playerMarkets: [ ...currentPlayerMarkets ],
                }
            })));
        }
    }

    public static onRemovePlayerMarket(areaId: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.agencyPermissions;
            const currentPlayerMarkets = substate.playerMarketModalState.playerMarkets;

            const filteredPlayerMarket = currentPlayerMarkets.filter((playerMarket) => playerMarket.areaId !== areaId);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    playerMarkets:  filteredPlayerMarket.length ? [ ...filteredPlayerMarket ] : [ { ...defaultPlayerMarket } ],
                }
            })));
        }
    }

    public static onRemoveExistingPlayerMarket() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().admin.agencyPermissions;
            const removedAreaId = substate.playerMarketModalState.removedAreaId;

            await dispatch(Actions.onRemovePlayerMarket(removedAreaId));

            dispatch(Actions.closePlayerMarketRemoveModal());
        }
    }

    public static addPlayerMarker() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.agencyPermissions;
            const currentPlayerMarkets = substate.playerMarketModalState.playerMarkets;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    playerMarkets: [ ...currentPlayerMarkets, { ...defaultPlayerMarket } ],
                }
            })))
        }
    }

    public static openPlayerMarketRemoveModal(areaId: number) {
        return (dispatch) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    isRemoveModalOpen: true,
                    removedAreaId: areaId
                }
            })));
        }
    }

    public static closePlayerMarketRemoveModal() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    isRemoveModalOpen: false,
                    removedAreaId: null
                }
            })));
        }
    }

    public static moveToConfirmStep() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    currentStep: PlayerMarketModalStep.Confirm
                }
            })));
        }
    }

    public static moveToDefaultStep() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerMarketModalState: {
                    ...prevState.playerMarketModalState,
                    currentStep: PlayerMarketModalStep.Default
                }
            })));
        }
    }

    public static onSavePlayerMarket() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerMarketModalState: {
                        ...prevState.playerMarketModalState,
                        isProcessing: true
                    }
                })));

                const playerMarkets = Selectors.getPlayerMarkets(getState());
                const agencyId = Selectors.getPlayerMarketModalAgencyId(getState());
                const preparedRequest = {
                    agencyId: agencyId,
                    markets: playerMarkets.filter(playerMarket => playerMarket.areaId > -1).map(playerMarket => ({
                        id: playerMarket.id > -1 ? playerMarket.id : undefined,
                        areaId: playerMarket.areaId,
                        isExpert: playerMarket.isExpert,
                    }))
                }

                await AgencyPermissionsService.savePlayerMarkets(
                    preparedRequest,
                );

                await dispatch(Actions.loadAgenciesSilent());

                dispatch(Actions.closePlayerMarketModal());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerMarketModalState: {
                        ...prevState.playerMarketModalState,
                        isProcessing: false
                    }
                })));
            }
        }
    }

    public static setPlayerMarketSubscription(agencyId: number, subscriptionId: AdditionalPlayerMarketSubscriptionEnum,){
        return async (dispatch, getState: () => AppState) => {
            try {
                const permissions = Selectors.getPermissions(getState());
                const updatedItems = [...permissions];
                updatedItems.find(item => item.id === agencyId).additionalPlayerMarketSubscriptionId = subscriptionId;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPlayerMarketIds: [...prevState.processingPlayerMarketIds, agencyId],
                })));

                await AgencyPermissionsService.saveAdditionalPlayerMarketSubscription(agencyId, subscriptionId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    permissions: updatedItems
                })));

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPlayerMarketIds: prevState.processingPlayerMarketIds.filter(item => item !== agencyId),
                })));
            }
        }
    }

    public static setAgencySubscriptionPlan(agencyId: number, subscriptionPlanId: AgenciesSubscriptionEnum){
        return async (dispatch, getState: () => AppState) => {
            const oldPermissions = _.cloneDeep(Selectors.getPermissions(getState()));
            try {
                const permissions = Selectors.getPermissions(getState());
                const subscriptions = Selectors.getAgenciesSubscriptions(getState());
                const selectedSubscription = subscriptions.find(x => x.id === subscriptionPlanId);
                const updatedItems = [...permissions];

                let updatedItem = updatedItems.find(item => item.id === agencyId);

                updatedItem.subscriptionPlanId = subscriptionPlanId;
                updatedItem.subscriptionPlanName = selectedSubscription.name;
                updatedItem.priorityCredits = selectedSubscription.priorityCreditAmount;
                updatedItem.regularCredits = selectedSubscription.regularCreditAmount;
                updatedItem.plusPitchesCount = selectedSubscription.plusPitchesCount;
                updatedItem.coachAccessId = selectedSubscription.coachAccessId;
                updatedItem.additionalPlayerMarketSubscriptionId = selectedSubscription.additionalPlayerMarketSubscriptionId;
                updatedItem.inPersonId = selectedSubscription.inPersonId;
                updatedItem.onlineId = selectedSubscription.onlineId;
                updatedItem.maxUserAmount = selectedSubscription.maxUserAmount;
                updatedItem.maxUserAmountText = selectedSubscription.maxUserAmount > 0 ? `Limit - ${selectedSubscription.maxUserAmount}` : 'Unlimited';
                updatedItem.agencyCustomPitchesCount = selectedSubscription.agencyCustomPitchesCount;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingSubscriptionsIds: [...prevState.processingSubscriptionsIds, agencyId],
                })));

                await AgencyPermissionsService.saveSubscriptionPlan(agencyId, subscriptionPlanId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    permissions: updatedItems
                })));

            } catch (err) {
                console.error(err);
                dispatch(stateController.setState({ permissions: [...oldPermissions] }));
                throw err;
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingSubscriptionsIds: prevState.processingSubscriptionsIds.filter(item => item !== agencyId),
                })));
            }
        }
    }

    public static setCoachAccess(agencyId: number, selectedOptionId: number) {
        return async (dispatch, getState: () => AppState) => {
            const oldPermissions = _.cloneDeep(Selectors.getPermissions(getState()));
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingCoachAccessIds: [...prevState.processingCoachAccessIds, agencyId],
                })));
                const permissions = Selectors.getPermissions(getState());
                const updatedItems = [...permissions];
                updatedItems.find(item => item.id === agencyId).coachAccessId = selectedOptionId;

                await AgencyPermissionsService.saveCoachAccessOption(agencyId, selectedOptionId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    permissions: updatedItems
                })));
            } catch (err) {
                dispatch(stateController.setState({ permissions: [...oldPermissions] }));
                throw err;
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingCoachAccessIds: prevState.processingCoachAccessIds.filter(item => item !== agencyId),
                })));
            }
        }
    }

    public static setRepresentationRequestOption(agencyId: number, optionId: RepresentationRequestSettingOptionEnum,){
        return async (dispatch, getState: () => AppState) => {
            try {
                const permissions = Selectors.getPermissions(getState());
                const updatedItems = [...permissions];
                updatedItems.find(item => item.id === agencyId).representationRequestSettingOptionId = optionId;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingRepresentationRequestIds: [...prevState.processingRepresentationRequestIds, agencyId],
                })));

                await AgencyPermissionsService.saveRepresentationRequestOption(agencyId, optionId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,                           
                    permissions: updatedItems
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingRepresentationRequestIds: prevState.processingRepresentationRequestIds.filter(item => item !== agencyId),
                })));
            }
        }
    }
}

class Selectors {
    public static getPermissions = (state: AppState): Array<Permissions> => state.admin.agencyPermissions.permissions;
    public static isFiltersSetToDefault(state: AppState) {
        return JSON.stringify(defaultState.filterOptions) === JSON.stringify(state.admin.agencyPermissions.filterOptions)
    }
    public static getCountries = (state: AppState): Array<Country> => state.admin.agencyPermissions.countries;
    public static getMarketAreas = (state: AppState): Array<AreaItem> => state.admin.agencyPermissions.marketAreas;
    public static getAgenciesSubscriptions =
        (state: AppState): Array<AgenciesSubscriptionExtendedModel> => state.admin.agencyPermissions.agenciesSubscriptions;

    public static isPlayerMarketModalOpen = (state: AppState): boolean => state.admin.agencyPermissions.playerMarketModalState.isModalOpen;
    public static isPlayerMarketRemoveModalOpen = (state: AppState): boolean => state.admin.agencyPermissions.playerMarketModalState.isRemoveModalOpen;
    public static isEditModal = (state: AppState): boolean => state.admin.agencyPermissions.playerMarketModalState.isEdit;
    public static getPlayerMarketModalAgencyName = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.agencyName;
    public static getPlayerMarketModalAgencyId = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.agencyId;
    public static getPlayerMarkets = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.playerMarkets;
    public static isEmptySelectExists = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.playerMarkets
        .some(market => market.areaName === '' && market.areaId === -1);
    public static getInitialPlayerMarkets = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.initialPlayerMarkets;
    public static getCurrentPlayerMarketStep = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.currentStep;
    public static isPlayerMarketProcessing = (state: AppState) => state.admin.agencyPermissions.playerMarketModalState.isProcessing;

    public static isEmptyPlayerMarketExists = (state: AppState) => {
        if (!state.admin.agencyPermissions.playerMarketModalState.playerMarkets.length) {
            return false;
        }
        return state.admin.agencyPermissions.playerMarketModalState.playerMarkets.some(el => el.areaId === -1);
    };

    public static isPlayerMarketsChanged = (state: AppState) => {
        const currentPlayerMarkets = Selectors.getPlayerMarkets(state).filter(playerMarket => playerMarket.areaName);
        const currentPlayerMarketsInitial = Selectors.getInitialPlayerMarkets(state).filter(playerMarket => playerMarket.areaName);

        return JSON.stringify(currentPlayerMarketsInitial) === JSON.stringify(currentPlayerMarkets)
    }

    public static getPlayerMarketAdded = (state: AppState) => {
        const playerMarkets = Selectors.getPlayerMarkets(state).map((playerMarket) => playerMarket.areaName);
        const initialPlayerMarkets = Selectors.getInitialPlayerMarkets(state).map((playerMarket) => playerMarket.areaName);

        const filteredMarkets = playerMarkets.filter(areaName => !initialPlayerMarkets.includes(areaName));

        return filteredMarkets.length > 0 ? filteredMarkets.join(', ') : '';
    };

    public static getPlayerMarketRemoved = (state: AppState) => {
        const playerMarkets = Selectors.getPlayerMarkets(state).map((playerMarket) => playerMarket.areaName);
        const initialPlayerMarkets = Selectors.getInitialPlayerMarkets(state).map((playerMarket) => playerMarket.areaName);

        const filteredMarkets = initialPlayerMarkets.filter(areaName => !playerMarkets.includes(areaName));

        return filteredMarkets.length > 0 ? filteredMarkets.join(', ') : '';
    };

    public static getMarketExpertAdded = (state: AppState) => {
        const playerMarkets = Selectors.getPlayerMarkets(state).filter((playerMarket) => playerMarket.isExpert).map((playerMarket) => playerMarket.areaName);
        const initialPlayerMarkets = Selectors.getInitialPlayerMarkets(state).filter((playerMarket) => playerMarket.isExpert).map((playerMarket) => playerMarket.areaName);

        const filteredMarkets = playerMarkets.filter(areaName => !initialPlayerMarkets.includes(areaName));

        return filteredMarkets.length > 0 ? filteredMarkets.join(', ') : '';
    };

    public static getMarketExpertRemoved = (state: AppState) => {
        const playerMarkets = Selectors.getPlayerMarkets(state).filter((playerMarket) => playerMarket.isExpert).map((playerMarket) => playerMarket.areaName);
        const initialPlayerMarkets = Selectors.getInitialPlayerMarkets(state).filter((playerMarket) => playerMarket.isExpert).map((playerMarket) => playerMarket.areaName);

        const filteredMarkets = initialPlayerMarkets.filter(areaName => !playerMarkets.includes(areaName));

        return filteredMarkets.length > 0 ? filteredMarkets.join(', ') : '';
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyPermissionState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};