import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { clubAdsFilterChanged } from 'pages/Pitch/store/actions/filter.actions';
import { getAuthClubId, getAuthSquadId, getAuthUserId } from 'store/auth/authReducer';
import { clubAdsTurnSetPage } from '../components/pagination/actions/pagination.actions';

export class PlayerSuggestionItem {
    id: number;
    name: string;
}

class State {
    isLoading: boolean;
    keyword: string;
    players: PlayerSuggestionItem[];
    selectedPlayerId: number;
    isExpanded: boolean;
}

const defaultState: State = {
    isLoading: false,
    keyword: '',
    players: [],
    selectedPlayerId: null,
    isExpanded: false
}

const stateController = new StateController<State>(
    "CLUB_ADS_FILTER_BY_PLAYER",
    defaultState
)

class Actions {

    private static getClubPlayers(currentClubId: number, currentSquadId: number, players: any[]) {
        let playerArrayData = [];
    
        for (const player of players) {
            if (player.firstPositionCode === null) {
                continue;
            }
    
            // parentSquad could be null because of wyscout
            if (!player.parentSquad) {
                continue;
            }
    
            if (
                player.parentSquad.clubId === null &&
                currentClubId === 0 &&
                player.parentSquad.id === currentSquadId
            ) {
                playerArrayData.push(player);
            } else if (player.parentSquad.clubId === currentClubId) {
                playerArrayData.push(player);
            }
        }
        return playerArrayData;
    };

    public static loadPlayersForFilter() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentSquadId = getAuthSquadId(appState);
            let currentClubId = getAuthClubId(appState);
            let currentUserId = getAuthUserId(appState);

            dispatch(stateController.setState({ isLoading: true }))
            try {
                const data = await VirtualSummitService.loadMySquadPlayers(currentClubId, currentUserId, currentSquadId);
                const clubPlayers = Actions.getClubPlayers(currentClubId, currentSquadId, data.output);
                const players = clubPlayers.map(x => ({ id: x.id, name: x.englishShortName })).sort((a, b) => a.name.localeCompare(b.name));
                dispatch(stateController.setState({
                    players
                }))
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static onKeywordChange(keyword: string) {
        return (dispatch) => {
            dispatch(stateController.setState({
                keyword: keyword
            }))
        }
    }

    public static onPlayerSelected(player: PlayerSuggestionItem) {
        return (dispatch) => {
            dispatch(stateController.setState({
                selectedPlayerId: player.id,
                keyword: ''
            }))

            dispatch(clubAdsTurnSetPage(1, 'clubAds'));
            dispatch(clubAdsFilterChanged({}));

            dispatch(userActivityInsert({
                PageName: `Pitch [Filter for Player]`,
                Message: 'Selected Player',
                PlayerId: player.id,
                PageType: PageType.Pitch
            }))
        }
    }

    public static setIsExpanded(isExpanded: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({
                isExpanded
            }))
        }
    }

    public static onClear() {
        return (dispatch, getState: () => AppState) => {
            const { selectedPlayerId } = Selectors.getRoot(getState())

            dispatch(stateController.setState({
                selectedPlayerId: null,
                keyword: ''
            }))
            dispatch(clubAdsTurnSetPage(1, 'clubAds'));
            dispatch(clubAdsFilterChanged({}));

            dispatch(userActivityInsert({
                PageName: `Pitch [Filter for Player]`,
                Message: 'Cleared',
                PlayerId: selectedPlayerId,
                PageType: PageType.Pitch
            }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.ads.filterByPlayer;
    public static getPlayers = (state: AppState) => Selectors.getRoot(state).players;
    public static getSelectedPlayerId = (state: AppState) => Selectors.getRoot(state).selectedPlayerId;

    public static getSelectedPlayer = (state: AppState) => Selectors.getPlayers(state)
    .find(x => x.id == Selectors.getSelectedPlayerId(state))

    public static getFilteredPlayers = (state: AppState) => {
        const { keyword, players } = Selectors.getRoot(state);
        let arrayData = [...players];

        if (keyword.length === 0)
            return arrayData;

        const filtered = arrayData.filter(player => player.name.toUpperCase().indexOf(keyword.toUpperCase()) > -1);
        return filtered;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};