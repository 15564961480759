import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import historyAccessor from 'history-accessor';
import AgencyTransparencyService from "api/agency/agent/agency-transparency/agency-transparency.service";
import { AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE } from "store/actionTypes";

export enum AgencyTransparencyStep {
  Info = 1,
  TurnOn = 2,
  Congratulations = 3
}

class State {
  isLoading: boolean;
  step: AgencyTransparencyStep;
}

const defaultState: State = {
  isLoading: false,
  step: AgencyTransparencyStep.Info
}

const stateController = new StateController<State>(
  "AGENCY/AGENCY-TRANSPARENCY",
  defaultState
)

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    }
  }

  public static setStep(step: AgencyTransparencyStep) {
    return async (dispatch) => {
      dispatch(stateController.setState({ step: step }));
    }
  }

  public static goToTurnOnStep() {
    return (dispatch) => {
      dispatch(stateController.setState({ step: AgencyTransparencyStep.TurnOn }));
      dispatch(userActivityInsert({
        PageName: `Agency Transparency [Activation]`,
        Message: `Next`,
        PageType: PageType.Agency,
      }));
    }
  }

  public static turnOnSharing() {
    return async (dispatch, getState: () => AppState) => {

      try {
        dispatch(stateController.setState({ isLoading: true }));
        await AgencyTransparencyService.turnOnSharingNow();

        dispatch(Actions.setStep(AgencyTransparencyStep.Congratulations));
        dispatch(userActivityInsert({
          PageName: `Agency Transparency [Activation]`,
          Message: `Turned On Sharing`,
          PageType: PageType.Agency,
        }));
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static close(path: string) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isLoading: true }));
      await AgencyTransparencyService.close();
      dispatch({ type: AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE });
      historyAccessor.push(path);

      dispatch(userActivityInsert({
        PageName: `Agency Transparency [Activation]`,
        Message: `Closed`,
        PageType: PageType.Agency,
      }));
    }
  }

  public static done(path: string) {
    return async (dispatch, getState: () => AppState) => {
      dispatch({ type: AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE });
      historyAccessor.push(path);

      dispatch(userActivityInsert({
        PageName: `Agency Transparency [Activation]`,
        Message: `Done`,
        PageType: PageType.Agency,
      }));
    }
  }

}
class Selectors {
  public static getRoot = (state: AppState): State => state.agency.agent.agencyTransparency;
  public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
  public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  State as State,
  Actions as Actions,
  stateController as Controller,
  Selectors as Selectors
};