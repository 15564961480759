import { map, ignoreElements } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AUTH_LOGIN } from '../../actionTypes';
import {updateLocale} from 'services/localization';

const storeAuthEpic = (action$, state$) => {
    // noinspection JSCheckFunctionSignatures
    return action$.pipe(
        ofType(AUTH_LOGIN.SUCCESS),
        map(() => {
            const {languageCode} = state$.value.auth;
            state$.value.i18n.locale = languageCode;
            return updateLocale(languageCode);
        }),
        ignoreElements(),
    );
};

export default storeAuthEpic;
