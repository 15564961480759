import axios from 'axios-config'
import { PlayerConfirmEmailRequest } from './models/email-confirmation.model'

export class EmailConfirmationService {
    private static axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    public static async sendConfirmEmail(emailData: PlayerConfirmEmailRequest) {
        const { data } = await axios.post(
            `api/v2/PlayerOnboarding/SendConfirmEmail`,
            emailData,
            this.axiosConfig
        )
        return data
    }

    public static async confirmEmailVerification(uid: string) {
        const { data } = await axios.post(
            `api/v2/PlayerOnboarding/ConfirmEmailVerify/${uid}`, {}, this.axiosConfig
        )
        return data
    }
}