// inspired by https://medium.com/code-monkey/client-side-form-validation-in-react-40e367de47ba
export default {
    isNotEmpty: val =>
        val !== null &&
        val !== undefined &&
        Boolean(val.toString().trim().length),
    isValidURL:(str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    },
    // isValidEmail: email => email && /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(String(email).toLowerCase()), ///\S+@\S+\.\S+/
    isValidEmail: email => email && /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(String(email).toLocaleLowerCase()),
    text: (min = 0, max = Infinity) => val => val !== null && val !== undefined && 
        val.toString().trim().length >= min && val.toString().trim().length <= max,
    isNotZero: val => val !== 0 && val !== '0',
    isInRange: (min, max) => val => Number(val) >= min && Number(val) <= max,
    isOutRange: (min, max) => val => Number(val) < min || Number(val) > max,
    isNumber: val => !isNaN(val),
    isPositive: val => Number(val) >= 0,
    isInteger: val => Number.isInteger(val),
    isName: val => {
        if (val === undefined || val === null) {
            return false;
        }

        const valTrimmed = val.toString().trim();
        if (Boolean(valTrimmed.length) === false) {
            return false;
        }

        // must contain at least one break between name parts
        if (valTrimmed.indexOf(' ') < 1) {
            return false;
        }

        return true;
    },
    isPositiveNumber: val => !isNaN(val) && Number(val) >= 0,
    isEqual: compare => val => val === compare,
    isValid: (val = '', validators = []) => {
        return validators
            .map(validator => validator(val))
            .reduce((x, y) => x && y, true);
    },
    validate: (obj, rules) => {
        const validation = Object.keys(obj).reduce((validation, key) => {
            const value = obj[key];
            if (!rules[key]) {
                return {
                    ...validation,
                    [key]: {
                        messages: [],
                        message: '',
                        isValid: true,
                    },
                };
            }
            const keyRules = Array.isArray(rules[key])
                ? rules[key]
                : [rules[key]];
            const errorMessages = keyRules.reduce(
                (acc, rule) =>
                    rule.method(value) ? acc : acc.concat([rule.message]),
                [],
            );
            return {
                ...validation,
                [key]: {
                    messages: errorMessages,
                    message: errorMessages[0] || '',
                    isValid: !Boolean(errorMessages.length),
                },
            };
        }, {});
        return {
            ...validation,
            isValid: Object.values(validation).every(field => field.isValid),
        };
    },
};
