import { combineReducers } from 'redux'
import * as OnboardingController from './player-to-agency-onboarding/onboarding.controller'
import * as MyPageController from './my-page/my-page.controller'
import * as LatestActivity from './latest-activity/latest-activity.controller';
import * as EmailConfirmationController from './email-confirmation-flow/email-confirmation.controller'
import * as MyAgentController from './my-agent-flow/my-agent.controller'
import * as OldOnboardingController from './old-onboarding/confirm.controller'
import * as addAgentController from './add-agent/add-agent.controller'
import * as myMessagesController from 'pages/player/my-messages-flow/my-messages.controller'

export type PlayerState = {
    oldOnboarding: OldOnboardingController.State
    onboarding: OnboardingController.State
    myPage: MyPageController.State
    latestActivity: LatestActivity.State
    emailConfirmationFlow: EmailConfirmationController.State
    myAgentFlow: MyAgentController.State
    addAgent: addAgentController.State
    myMessagesFlow: myMessagesController.State
}

export default combineReducers({
    oldOnboarding: OldOnboardingController.Reducer,
    onboarding: OnboardingController.Reducer,
    myPage: MyPageController.Reducer,
    latestActivity: LatestActivity.Reducer,
    emailConfirmationFlow: EmailConfirmationController.Reducer,
    myAgentFlow: MyAgentController.Reducer,
    addAgent: addAgentController.Reducer,
    myMessagesFlow: myMessagesController.Reducer,
})