import LocalStorage from 'services/localStorage';
const filterLS = LocalStorage('search', 'filter')

const saveFilter = (filter) => {
    filterLS.set({
        clubsAndAgentsFilter: {
            showOnlyClubs: filter.clubsAndAgentsFilter.showOnlyClubs,
            showOnlyTrustedAgents: filter.clubsAndAgentsFilter.showOnlyTrustedAgents,
        },
        keyword: filter.keyword,
        sortByKey: filter.sortByKey,
        playerFilter: filter.playerFilter,
    })
}

const restoredFilter = () => {
    const filter = filterLS.get();
    if (!filter) return null;

    return ({
        clubsAndAgentsFilter: filter.clubsAndAgentsFilter,
        keyword: filter.keyword,
        sortByKey: filter.sortByKey,
        playerFilter: filter.playerFilter,
    })
}

const clearFilter = () => {
    filterLS.remove()
}

export {
    saveFilter,
    restoredFilter,
    clearFilter,
}
