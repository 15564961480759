import { AppState } from 'root.reducer';
import { MessageType } from 'api/messaging/models/message'
import { StateController } from 'utils/action-declaration';
import { SearchItemForClub, SearchItemType } from 'api/search/model/search-result';
import { messageSend } from 'app/send-message/actions/send-message.actions';
import { ReceiverRole } from 'api/search/model/declare-interest-definition';
import { MessagingService } from 'api/messaging/messaging.service'
import {
    IMessageSendSpecificaion,
    MessageSendSession,
    Actions as MessageModalController
} from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller'
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { Selectors as GridSelectors } from './grid.controller'
import { PlayerActivityService, AgencyActivityService, ClubActivityService } from './player-activity.service'
class SendMessageModalState {
    newMessageAvailability?: {
        availableCount?: number;
        lockedTill?: any;
    };
    restrictedAvailableCountModal: boolean;
    restrictedMessageModal: boolean;

}

const defaultState: SendMessageModalState = {
    newMessageAvailability: null,
    restrictedAvailableCountModal: false,
    restrictedMessageModal: false,
}

const stateController = new StateController<SendMessageModalState>(
    'PLAYER_SEARCH_V2/SEND_MESSAGE',
    defaultState
)

class Actions {
    private static getSearchItem = (state: AppState, id: number) => {
        return state.newPlayerSearch.grid.result.items.find(x => x.player != null && x.player.id == id || x.agency != null && x.agency.id == id || x.club != null && x.club.id == id);
    }

    private static getSession = (item: SearchItemForClub, messageReceiverType?: ReceiverType) => {
        if (item.type === SearchItemType.Club) {
            return {
                toClub: item.club.isActive,
                toAgency: false,
                subject: `New Message`,
                mesageTypeId: MessageType.ChatMessage,
                squadId: item.club.id,
                squadName: item.club.name,
                squadLeagueName: item.club.leagueName,
                squadLeagueCountry: item.club.country,
                squadLogo: item.club.logo,
                recipientId: item.club.id,
                recipientType: ReceiverType.Squad
            }
        }
        if (item.type === SearchItemType.Player) {
            const { player } = item;

            const canSendToSquadAndToAgency = player.declareInterest.messageRule.receiver === ReceiverRole.All
            const canSendToSquad = canSendToSquadAndToAgency || player.declareInterest.messageRule.receiver === ReceiverRole.Club;
            const canSendToAgency = canSendToSquadAndToAgency || player.declareInterest.messageRule.receiver === ReceiverRole.Agent;

            let messageSession = {};
            if (!canSendToAgency && canSendToSquad) {
                messageSession = { recipientId: player.parentSquad.id, recipientType: ReceiverType.Squad }
            } else if (!canSendToSquad && canSendToAgency) {
                messageSession = { ...messageSession, recipientId: player.agency.id, recipientType: ReceiverType.Agency }
            }
            if (canSendToSquadAndToAgency && messageReceiverType !== undefined ) {
                if (messageReceiverType === ReceiverType.Squad) {
                    messageSession = { recipientId: player.parentSquad.id, recipientType: ReceiverType.Squad }
                } else if (messageReceiverType === ReceiverType.Agency) {
                    messageSession = { ...messageSession, recipientId: player.agency.id, recipientType: ReceiverType.Agency }
                }
            }

            let squad = player.parentSquad ? player.parentSquad : player.latestSquad;

                return {
                    ...messageSession,
                    toClub: messageReceiverType !== undefined ? messageReceiverType === ReceiverType.Squad : canSendToSquad,
                    toAgency: messageReceiverType !== undefined ? messageReceiverType === ReceiverType.Agency : canSendToAgency,
                    subject: `${player.shortName} INTEREST`,
                    mesageTypeId: canSendToAgency ? MessageType.ClubAgencyChatMessage : MessageType.ChatMessage,
                    squadId: (squad || {}).id,
                    squadName: (squad || {}).name,
                    squadLeagueName: (squad || {}).league,
                    squadLeagueCountry: (squad || {}).country,
                    squadLogo: (squad || {}).logo,
                    agencyId: player.agency ? player.agency.id : null,
                    agencyCountry: player.agency ? player.agency.country : null,
                    agencyName: player.agency ? player.agency.name : null,
                    isTrustedAgent: player.agency ? player.agency.isTrusted : null,
                }
        }

        if (item.type === SearchItemType.Agency) {
            return {
                toClub: false,
                toAgency: item.agency.isTrusted,
                subject: `New Message`,
                mesageTypeId: MessageType.ClubAgencyChatMessage,
                agencyId: item.agency.id,
                agencyCountry: item.agency.area,
                agencyName: item.agency.name,
                isTrustedAgent: item.agency.isTrusted,
                recipientId: item.agency.id,
                recipientType: ReceiverType.Agency
            }
        }
    }

    public static openModal(itemId: number, messageReceiverType?: ReceiverType) {
        return (dispatch, getState: () => AppState) => {
            const item = Actions.getSearchItem(getState(), itemId)
            let messageSession: MessageSendSession = Actions.getSession(item, messageReceiverType);

            if (item.type === SearchItemType.Club || item.type === SearchItemType.Agency) {
                if (!Selectors.isPermited(getState())) {
                    dispatch(Actions.togleRestrictedMessageModal());
                    return
                }

                if (Selectors.isNewMessageLimitRichedOutOrHide(getState())) {
                    dispatch(Actions.togleRestrictedAvailableModal());
                    return
                }
            }

            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    if (item.type === SearchItemType.Agency) { dispatch(AgencyActivityService.cancelNewMessage(session.recipientId)) }
                    else if (item.type === SearchItemType.Club) { dispatch(ClubActivityService.cancelNewMessage(session.recipientId)) }
                    else {
                        dispatch(PlayerActivityService.cancelNewMessage(
                            item.player.id,
                            item.player.recommendedFlag,
                            item.player.isSuspendedContract,
                            item.player.isOnReleaseList,
                            item.player.parentSquad ? item.player.parentSquad.id : null,
                            !item.player.parentSquad && item.player.agency ? item.player.agency.id : null,
                            GridSelectors.getGridState(getState())
                        ))
                    }
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {
                        if (session.recipientType === ReceiverType.Agency) {
                            await dispatch(messageSend(session.recipientId, message, null, session.agencyName, session.mesageTypeId, session.subject, true));
                        } else {
                            await dispatch(messageSend(session.recipientId, message, null, session.squadName, session.mesageTypeId, session.subject, false));
                        }

                        dispatch(Actions.checkNewMessageLimit())

                        if (item.type === SearchItemType.Agency) { dispatch(AgencyActivityService.sendNewMessage(session.recipientId)) }
                        else if (item.type === SearchItemType.Club) { dispatch(ClubActivityService.sendNewMessage(session.recipientId)) }
                        else {
                            dispatch(PlayerActivityService.sendNewMessage(
                                item.player.id,
                                item.player.recommendedFlag,
                                item.player.isSuspendedContract,
                                item.player.isOnReleaseList,
                                session.recipientType === ReceiverType.Squad && item.player.parentSquad ? item.player.parentSquad.id : null,
                                session.recipientType === ReceiverType.Agency && item.player.agency ? item.player.agency.id : null,
                                GridSelectors.getGridState(getState())
                            ))
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            if (item.type === SearchItemType.Agency) { dispatch(AgencyActivityService.openNewMessage(messageSession.recipientId)) }
            else if (item.type === SearchItemType.Club) { dispatch(ClubActivityService.openNewMessage(messageSession.recipientId)) }
            else {
                dispatch(PlayerActivityService.openNewMessage(
                    item.player.id,
                    item.player.recommendedFlag,
                    item.player.isSuspendedContract,
                    item.player.isOnReleaseList,
                    item.player.parentSquad ? item.player.parentSquad.id : null,
                    !item.player.parentSquad && item.player.agency ? item.player.agency.id : null,
                    GridSelectors.getGridState(getState())
                ))
            }
            dispatch(MessageModalController.openSession(messageSession, specification))
        }
    }

    public static togleRestrictedAvailableModal() {
        return async (dispatch, getState: () => AppState) => {
            const restrictedAvailableCountModal = Selectors.getRestrictedAvailableCountModal(getState())
            dispatch(stateController.setState({ restrictedAvailableCountModal: !restrictedAvailableCountModal }))
        }
    };

    public static togleRestrictedMessageModal() {
        return async (dispatch, getState: () => AppState) => {
            const restrictedMessageModal = Selectors.getRestrictedMessageModal(getState())
            dispatch(stateController.setState({ restrictedMessageModal: !restrictedMessageModal }))
        }
    };

    public static checkNewMessageLimit() {
        return async (dispatch, getState: () => AppState) => {
            const { userId } = getState().auth;
            try {
                const newMessageAvailability = await MessagingService.checkNewMessageLimit(userId)
                dispatch(stateController.setState({ newMessageAvailability: newMessageAvailability }))
            } catch (e) {
                console.error(e)
            }
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.newPlayerSearch.message;
    public static getRestrictedAvailableCountModal = (state: AppState) => Selectors.getRoot(state).restrictedAvailableCountModal
    public static getRestrictedMessageModal = (state: AppState) => Selectors.getRoot(state).restrictedMessageModal

    public static getNewMessageCheckAvailability = (state: AppState) => Selectors.getRoot(state).newMessageAvailability
    public static isNewMessageLimitRichedOutOrHide = (state: AppState) =>
        (Selectors.getNewMessageCheckAvailability(state) || { availableCount: 5 }).availableCount <= 0
    public static isPermited = (state: AppState) => !state.auth.clubPermission || state.auth.clubPermission.canSendMessages

    public static lockMessage = (state: AppState) => !Selectors.isPermited(state) || Selectors.isNewMessageLimitRichedOutOrHide(state)
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    SendMessageModalState as State,
    Actions as Actions,
    stateController as Controller
};