import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { PlayerStatisticService } from 'api/player/player-statistic-service'
import getDataForChart from 'app/players-comparison/helpers/get-data-for-chart';
import { PitchPerformanceRadarItem } from 'ts-components/pitch/models/pitch-performance-radar-item'
import { ShortlistService } from "api/shortlist/shortlist-service"
import PlayerAdService, { PlayerAdActivityCounters } from 'api/player-ad-v2/player-ad-v2.service'
import { Actions as PitchController } from '../redux/pitch/pitch.controller';
import { Actions as PlusPitchController } from '../redux/pitch/plus-pitch.controller';
import { Actions as SuperPitchController } from '../redux/pitch/super-pitch.controller';
import { Actions as MyAdsController } from '../redux/my-ads.controller'
import { getCancelTokenSource } from 'axios-config'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PitchPageType } from '../constants/page-type';
import { PitchTypeEnum } from 'api/pitch-v2/models/pitch-type.model';
import { ActionType, PageType } from 'constants/enums';

class CommonState {
    isLoading: boolean;
    playerStatistic: { [id: string]: Array<PitchPerformanceRadarItem>; };
    isRadarLoading: boolean;
    shortListedPlayers: Array<number>;
    isShortListLoading: boolean;
    initialized: boolean;
    counters: PlayerAdActivityCounters;
    includeClubPitches: boolean;
    includeAgentPitches: boolean;
    videoPreviewModalData: VideoPreviewModalData;
}

export class VideoPreviewModalData {
    title: string;
    urls: Array<string>
}

const defaultState: CommonState = {
    isLoading: false,
    playerStatistic: {},
    isRadarLoading: false,
    initialized: false,
    shortListedPlayers: [],
    isShortListLoading: false,
    includeClubPitches: true,
    includeAgentPitches: true,
    counters: null,
    videoPreviewModalData: null
}

const stateController = new StateController<CommonState>(
    "PLAYR_ADS/MY_ADS/COMMON",
    defaultState
);

class Actions {
    public static openVideoPreview(data: VideoPreviewModalData) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ videoPreviewModalData: data }))
        }
    }

    public static closeVideoPreview() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ videoPreviewModalData: null }))
        }
    }

    public static token = null;
    public static loadAllActivityTabData() {
        return dispatch => {
            dispatch(stateController.setState({ isLoading: true }))

            Actions.token = getCancelTokenSource();

            return Promise.allSettled([
                dispatch(Actions.loadShortListedPlayers()),
                dispatch(PitchController.loadPitches(Actions.token.token)),
                dispatch(PlusPitchController.loadPlusPitches(Actions.token.token)),
                dispatch(SuperPitchController.loadAllsuperPitches(Actions.token.token)),
                dispatch(MyAdsController.loadOutDatedAds(Actions.token.token)),
            ]).then(() => {
                    dispatch(Actions.loadStatictic())
                    dispatch(stateController.setState({ isLoading: false }))
            }).catch((e) => console.error(e))
        }
    }

    public static loadShortListedPlayers() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isShortListLoading: true }))
            let playerIds = await ShortlistService.loadShortListedPlayers();
            dispatch(stateController.setState({ isShortListLoading: false, shortListedPlayers: playerIds }));
        }
    }

    public static loadCounters() {
        return async (dispatch, getState: () => AppState) => {
            let counters = await PlayerAdService.getActivityCounters();
            dispatch(stateController.setState({ counters: counters }));
        }

    }

    public static cancelActivityQueries() {
        if (Actions.token) {
            Actions.token.cancel();
            Actions.token = null;
        }
    }

    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState, counters: getState().playerAdPage.common.counters }));
        }
    }

    public static togglePlayerInShortList(pitchPageType: PitchPageType, playerId: number, pitchId?: number, clubId?:number, agencyId?:number, pitchType?: PitchTypeEnum) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let players = getState().playerAdPage.common.shortListedPlayers;
            const isInShortlist = players.includes(playerId)
            let playersWithout = [...players.filter(x => x !== playerId)];

            let prevState = isInShortlist == null ? players.includes(playerId) : isInShortlist;

            if (!prevState) {
                playersWithout = [...playersWithout, playerId];
            }

            try {
                dispatch(stateController.setState({ shortListedPlayers: playersWithout }));
                if (!prevState) {
                    await ShortlistService.addToShortlist(playerId);
                }
                else {
                    await ShortlistService.removeFromShortlist(playerId);
                }

                dispatch(userActivityInsert({
                    PageName: `Player Ads [${pitchPageType}]`,
                    Message: isInShortlist ? 'Removed from Shortlist' : 'Added to Shortlist',
                    PlayerId: playerId,
                    Links: [{ Type: pitchType == PitchTypeEnum.classicPitch ? 2 : 3, TargetId: pitchId }],
                    ClubId: clubId,
                    AgencyId: agencyId,
                    ActionType: isInShortlist ? null : ActionType.AddToShortlist,
                    PageType: PageType.PlayerAds
                }))

                resolve(true)
            } catch (e) {
                dispatch(stateController.setState({ shortListedPlayers: players }));
                reject(e)
            }
        })
    }

    public static loadStatictic() {

        return async (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage;

            if (subState.common.isRadarLoading) {
                return
            }

            let activePitches = [];
            for (var group of subState.pitch.groupedActivePitches) activePitches = [...activePitches, ...group.pitches.map(item => item.player.id)];

            let allPlayerIds = [
                ...subState.pitch.testedPitches.map(item => item.player.id),
                ...subState.plusPitch.activePitches.filter(item => item.player !== null).map(item => item.player.id),
                ...subState.plusPitch.testedPitches.filter(item => item.player !== null).map(item => item.player.id),
                ...activePitches,
            ]

            let withoutDuplicates = [];
            for (var playerId of allPlayerIds) if (!withoutDuplicates.includes(playerId)) withoutDuplicates.push(playerId);

            dispatch(stateController.setState({ isRadarLoading: true }))

            let statistic = await PlayerStatisticService.getPlayerStatisticBatchByPlayerIds(withoutDuplicates);
            var radarCahrtinfoPerPlayer: { [id: string]: any; } = {};
            for (var stat of statistic) {

                const data = getDataForChart({
                    firstPlayer: stat.firstPlayer,
                    secondPlayer: null,
                    thirdPlayer: null,
                    comparison: stat
                });

                radarCahrtinfoPerPlayer[stat.key] = data;
            }

            dispatch(stateController.setState({
                playerStatistic: radarCahrtinfoPerPlayer,
                initialized: true,
                isRadarLoading: false
            }));
        }
    }

    public static ensurePreloadStatistic(dependAction: () => void) {

        return async (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage;

            var states: Array<boolean> = [
                subState.plusPitch.initialized,
                subState.pitch.initialized,
            ];

            if (!states.includes(false) && !subState.common.initialized) {
                dispatch(Actions.loadStatictic())
            }
        }
    }

    public static toggleIncludeClubPitches() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, includeClubPitches: !prevState.includeClubPitches })))

            const includeClubPitches = getState().playerAdPage.common.includeClubPitches;
            dispatch(userActivityInsert({
                PageName: 'Player Ads',
                Message: includeClubPitches ? 'Selected Club Pitches' : 'Unselected Club Pitches',
                PageType: PageType.PlayerAds
            }))
        }
    }

    public static toggleIncludeAgentPitches() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, includeAgentPitches: !prevState.includeAgentPitches })))

            const includeAgentPitches = getState().playerAdPage.common.includeAgentPitches;
            dispatch(userActivityInsert({
                PageName: 'Player Ads',
                Message: includeAgentPitches ? 'Selected Agent Pitches' : 'Unselected Agent Pitches',
                PageType: PageType.PlayerAds
            }))
        }
    }
}

class Selectors {
    public static selectIsActivityTabLoading(state: AppState): boolean {
        const subState = state.playerAdPage

        const isPitchesLoading = subState.pitch.isLoading
        const isPlusPitchesLoading = subState.plusPitch.isLoading
        const isSuperPitchesLoading = subState.superPitch.isLoading
        const isRadarLoading = subState.common.isRadarLoading
        const isShortListLoading = subState.common.isShortListLoading
        const isAlertAdsLoading = subState.myAds.isLoading

        return isPitchesLoading || isPlusPitchesLoading || isSuperPitchesLoading || isRadarLoading || isShortListLoading || isAlertAdsLoading
    }

    public static getCountOfActivity(state: AppState) {
        let counters = state.playerAdPage.common.counters;
        if (counters == null) return null;
        return counters.newPitchCount + counters.outDatedAdsCount;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};



