import createApiThunk from '../../../services/createApiThunk';
import * as types from 'store/actionTypes';


export const getAreaPhoneCodes = () => async (dispatch) => {
    const request = {
        type: types.GET_AREA_PHONE_CODES,
        scope: 'phoneCodes',
        method: 'get',
        url: `Area/GetAreaPhoneCodes/`,
        success: types.GET_AREA_PHONE_CODES.SUCCESS,
    };
    return await dispatch(createApiThunk(request));
};