export const attendeesMadridNovember = [
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '18833', name: 'SL Benfica', logo: 'logo_1673_SL_Benfica.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '12768', name: 'Atlético Madrid', logo: '679_Atlético_Madrid.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },

    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '38580', name: 'Valencia', logo: 'logo_1715_Valencia_CF.png' },
    { id: '47', name: 'Tottenham Hotspur', logo: 'logo_1122_Tottenham_Hotspur.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },

    { id: '11', name: 'Atalanta BC', logo: '3172_Atalanta.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },

    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: 'logo_2101_Los_Angeles_Galaxy.png' },
    { id: '5635', name: 'Dynamo Kyiv', logo: 'logo_2136_Dynamo_Kyiv.png' },

    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '18001', name: 'Fortuna Düsseldorf', logo: 'logo_Fortuna_Dusseldorf.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '13058', name: 'Feyenoord', logo: 'logo_1533_Feyenoord_Rotterdam.png' },

    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '25285', name: 'Levante UD', logo: 'logo_Levante_UD.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '21242', name: 'RC Lens', logo: 'logo_1983_RC_Lens.png' },

    { id: '20', name: 'US Sassuolo', logo: '3315_Sassuolo.png' },
    { id: '1559', name: 'FC Cincinnati', logo: '37892_Cincinnati.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '38291', name: 'São Paulo Futebol Clube', logo: '5638_São_Paulo.png' },
    { id: '31172', name: 'HSC Montpellier', logo: '3787_Montpellier.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },

]

// Old and useless
export const attendeesMadridNovemberOld = [
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '39333', name: 'Sporting Gijón', logo: 'logo_1730_Sporting_Gijon.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '6863', name: 'Rayo Vallecano', logo: 'logo_1734_Rayo_Vallecano.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '13114', name: 'Real Zaragoza', logo: 'logo_1742_Real_Zaragoza.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '23752', name: 'Haugesund', logo: 'logo_1474_FK_Haugesund.png' },
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '7819', name: 'Sarpsborg', logo: 'logo_1472_Sarpsborg_08_FF.png' },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '16042', name: 'Kukësi', logo: 'logo_2454_FK_Kukesi.png' },
    { id: '32987', name: 'Bristol Rovers', logo: 'logo_1184_Bristol_Rovers.png' },
    { id: '11087', name: 'Brann', logo: 'logo_SK_Brann.png' },
    { id: '9794', name: 'Zagłębie Sosnowiec', logo: 'logo_1612_Zaglebie_Sosnowiec.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },
    { id: '21425', name: 'St. Gallen', logo: 'logo_1935_FC_St._Gallen_1879.png' },
    { id: '12139', name: 'Honvéd', logo: 'logo_1632_Budapest_Honved_FC.png' },
    { id: '72', name: 'Huddersfield', logo: 'logo_1137_Huddersfield_Town.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '12612', name: 'Hammarby', logo: 'logo_1441_Hammarby_IF.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '7421', name: 'Granada', logo: 'logo_1732_Granada_CF.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '11544', name: 'Grasshopper', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '5626', name: 'Enosis', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '38623', name: 'Pogoń Szczecin', logo: 'logo_1599_Pogon_Szczecin.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '5', name: 'Internacinale', logo: 'logo_1836_Inter_Milan.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '174', name: 'Talleres Córdoba', logo: 'logo_2588_Club_Atletico_Talleres.png' }
]


export const virtualSummitAttendees = [
    { id: '28998', name: 'Real Betis Balompié', logo: '684_Real_Betis_Balompié.png' },
    { id: '496', name: 'HNK Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '7421', name: 'Granada CF', logo: 'logo_1732_Granada_CF.png' },
    { id: '23352', name: 'Stade Rennais FC', logo: 'logo_Stade_Rennes.png' },
    { id: '26457', name: 'Stabæk Fotball', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '6863', name: 'Rayo Vallecano', logo: '713_Rayo_Vallecano.png' },
    { id: '29178', name: 'Melbourne City FC', logo: 'logo_2473_Melbourne_City_FC.png' },
    { id: '7927', name: 'Dinamo Minsk', logo: '10082_Dinamo_Minsk.png' },
    { id: '3209', name: 'FK Ufa', logo: '14095_FK_Ufa.png' },
    { id: '12686', name: 'FC Nordsjaelland', logo: '7458_Nordsjelland.png' },
    { id: '3695', name: 'SK Slavia Prague', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '8498', name: 'Odense Boldklub', logo: 'logo_1412_Odense_Boldklub.png' },
    { id: '9073', name: 'Krylya Sovetov Samara', logo: '13891_Krylya_Sovetov_Samara.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '50', name: 'Chelsea FC', logo: 'logo_Chelsea_FC.png' },
    { id: '30849', name: 'Atlanta United FC', logo: 'logo_2103_Atlanta_United_FC.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '13404', name: 'Houston Dynamo', logo: 'logo_2120_Houston_Dynamo.png' },
    { id: '40692', name: 'Columbus Crew SC', logo: 'logo_2116_Columbus_Crew_SC.png' },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '21242', name: 'RC Lens', logo: 'logo_1983_RC_Lens.png' },
    { id: '5972', name: 'San Jose Earthquakes', logo: '7851_San_Jose_Earthquakes.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '12612', name: 'Hammarby IF', logo: '6710_Hammarby_IF.png' },
    { id: '41956', name: 'Deportivo Cali', logo: 'logo_2667_Deportivo_Cali.png' },
    { id: '5121', name: 'Minnesota United FC', logo: 'logo_2122_Minnesota_United_FC.png' },
    { id: '38580', name: 'Valencia CF', logo: 'logo_1715_Valencia_CF.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '32987', name: 'Bristol Rovers', logo: 'logo_1184_Bristol_Rovers.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '7845', name: 'CF Pachuca', logo: 'logo_2276_CF_Pachuca.png' },
    { id: '31138', name: 'Club León FC', logo: '15424_Club_León_FC.png' },
    { id: '8239', name: 'Brentford FC', logo: 'logo_1153_Brentford_FC.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_Brighton_And_Hove_Albion_FC.png' },
    { id: '48', name: 'Manchester City', logo: '1625_Manchester_City.png' },
    { id: '11544', name: 'Grasshopper Club Zurich', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '4673', name: 'Portsmouth FC', logo: 'logo_Portsmouth_FC.png' },
]


export const vsSummit = [
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '674', name: 'Valencia CF', logo: 'logo_1715_Valencia_CF.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '41692', name: 'Seattle Sounders FC', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '34388', name: 'Rubin Kazan', logo: '13895_Rubin_Kazan.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '28414', name: 'Dinamo Moscow', logo: '13889_Dinamo_Moscow.png' },
    { id: '27251', name: 'Clube de Regatas do Flamengo', logo: '5654_Clube_de_Regatas_do_Flamengo.png' },
    { id: '17668', name: 'FC Famalicao', logo: 'logo_FC_Famalicao.png' },
    { id: '23352', name: 'Stade Rennais FC', logo: 'logo_Stade_Rennes.png' },
    { id: '48', name: 'Manchester City', logo: '1625_Manchester_City.png' },
    { id: '50', name: 'Chelsea FC', logo: 'logo_Chelsea_FC.png' },
    { id: '43723', name: 'SK Slavia Praha', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '27287', name: 'Vitória Guimarães SC', logo: 'logo_1676_Vitoria_Guimaraes_SC.png' },
    { id: '9276', name: 'CF Monterrey', logo: 'logo_2267_CF_Monterrey.png' },
    { id: '36192', name: 'Tigres UANL', logo: '15417_Tigres_UANL.png' },
    { id: '30849', name: 'Atlanta United FC', logo: 'logo_2103_Atlanta_United_FC.png' },
    { id: '40498', name: 'Swansea City', logo: 'logo_1131_Swansea_City.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '23168', name: '1. FC Koln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '15581', name: 'Lech Poznan', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '20454', name: 'Legia Warszawa', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '13404', name: 'Houston Dynamo', logo: 'logo_2120_Houston_Dynamo.png' },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '41646', name: 'FC Dallas', logo: 'logo_2113_FC_Dallas.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '7845', name: 'CF Pachuca', logo: 'logo_2276_CF_Pachuca.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '15821', name: 'Arminia Bielefeld', logo: 'logo_Arminia_Bielefeld.png' },
    { id: '7927', name: 'Dinamo Minsk', logo: '10082_Dinamo_Minsk.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '41751', name: 'Maccabi Haifa', logo: 'logo_2234_Maccabi_Haifa.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '67', name: 'West Ham United', logo: '1633_West_Ham_United.png' },
    { id: '22240', name: 'PAOK', logo: 'logo_2021_PAOK_Thessaloniki.png' },
    // { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    // { id: '38328', name: 'D.C United', logo: 'logo_2118_D.C._United.png' },
    // { id: '31484', name: 'Deportivo Guadalajara', logo: '15409_Deportivo_Guadalajara.deportivo_guadalajara' },
    // { id: '31172', name: 'HSC Montpellier', logo: '3787_Montpellier.png' },
    // { id: '4059', name: 'OGC Nice', logo: 'logo_1965_OGC_Nice.png' },
    // { id: '26540', name: 'Orlando City SC', logo: '8068_Orlando_City_SC.png' },
]

export const vsDealDay = [
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },
    { id: '67', name: 'West Ham United', logo: '1633_West_Ham_United.png' },
    { id: '16', name: 'SSC Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '6', name: 'SS Lazio', logo: 'logo_1837_SS_Lazio.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '12761', name: 'RCD Espanyol Barcelona', logo: 'logo_1722_RCD_Espanyol_Barcelona.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '17788', name: 'Paris Saint-Germain', logo: 'logo_Paris_Saint-Germain.png' },
    { id: '1064', name: 'Nîmes Olympique', logo: '3813_Nîmes_Olympique.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '48', name: 'Manchester City', logo: '1625_Manchester_City.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '15581', name: 'Lech Poznan', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '18001', name: 'Fortuna Düsseldorf', logo: 'logo_Fortuna_Dusseldorf.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '33906', name: 'FC Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '50', name: 'Chelsea FC', logo: 'logo_Chelsea_FC.png' },
    { id: '3294', name: 'AS Saint-Étienne', logo: '3782_AS_Saint-Étienne.png' },
    { id: '976', name: 'AS Monaco', logo: '19830_AS_Monaco.png' },
    { id: '13073', name: 'Club Brugge KV', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '38328', name: 'D.C United', logo: 'logo_2118_D.C._United.png' },
    { id: '5635', name: 'Dynamo Kyiv', logo: 'logo_2136_Dynamo_Kyiv.png' },
    { id: '32429', name: 'Eintracht Frankfurt', logo: 'logo_1268_Eintracht_Frankfurt.png' },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '850', name: 'Stoke City', logo: 'logo_1129_Stoke_City.png' },
    { id: '69', name: 'Watford FC', logo: '1644_Watford_FC.png' },
    { id: '24711', name: 'Urawa Red Diamonds', logo: 'logo_2407_Urawa_Red_Diamonds.png' },
    { id: '5180', name: 'Istanbul Basaksehir FK', logo: 'logo_2005_Medipol_Basaksehir.png' },
    { id: '1003', name: 'Hull City', logo: '1672_Hull_City.png' },
]