import axios from 'axios-config';
import {
    SearchParametersOptions,
    SearchParametersConnections,
    AgenciesResponse,
    ConnectionsResponse,
    SupervisorsResponse,
    ImpersonateData,
} from "./model";
export class AgenciesService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    private static axiosConfigWithFile = {
        headers: { 'Content-Type': 'multipart/form-data' },
    }

    public static async agenciesFetch(searchParameters: SearchParametersOptions): Promise<AgenciesResponse> {
        const { data } = await axios.post(
            `api/v2/Agency/GetAdminAgencies`,
            {
                withAgencyLogo: searchParameters.withAgencyLogo,
                withAgencyBgImage: searchParameters.withAgencyBgImage,
                agencyName: searchParameters.agencyName,
                successExpertId: searchParameters.successExpertId,
                pageIndex: searchParameters.page,
                pageSize: searchParameters.recordsPerPage,
            },
            this.axiosConfig,
        );
        return data;
    }

    public static async agencyClubConnectionsFetch(searchParameters: SearchParametersConnections): Promise<ConnectionsResponse> {
        const { data } = await axios.post(
            `api/v2/Agency/GetAgencyClubConnections`,
            searchParameters,
            this.axiosConfig,
        );
        return data;
    }

    public static async agencyPlayerConnectionsFetch(searchParameters: SearchParametersConnections): Promise<ConnectionsResponse> {
        const { data } = await axios.post(
            `api/v2/Agency/GetAgencyPlayerConnections`,
            searchParameters,
            this.axiosConfig,
        );
        return data;
    }

    public static async agencyAgentConnectionsFetch(searchParameters: SearchParametersConnections): Promise<ConnectionsResponse> {
        const { data } = await axios.post(
            `api/v2/Agency/GetAgencyAgentConnections`,
            searchParameters,
            this.axiosConfig,
        );
        return data;
    }

    public static async supervisorsFetch(): Promise<SupervisorsResponse> {
        const { data } = await axios.get(
            `api/AdminSquads/GetSalesSupervisorUsers`,
            this.axiosConfig
        )
        return data
    }

    public static async assignSupervisor(agencyId: number, successExpertId: number | string) {
        const { data } = await axios.post(
            `api/v2/Agency/AssignSuccessExpert/${agencyId}/${successExpertId}`,
            {
                id: agencyId,
                successExpertId: successExpertId,
            },
            this.axiosConfig,
        )
        return data
    }

    public static async deactivateAgency(agencyId: number) {
        const { data } = await axios.post(
            `api/v2/Agency/Deactivate/${agencyId}`,
            this.axiosConfig,
        )
        return data
    }

    public static async deactivateUsers(agencyId: number) {
        const { data } = await axios.post(
            `api/v2/Agency/DeactivateUsers/${agencyId}`,
            this.axiosConfig,
        )
        return data
    }

    public static async uploadAgencyLogo(agencyId: number, file): Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append('file', file);

        const { data } = await axios.post(
            `api/v2/Agency/UploadImage/${agencyId}`,
            bodyFormData,
            this.axiosConfigWithFile
        )
        return data;
    }

    public static async deleteAgencyLogo(agencyId: number) {
        const { data } = await axios.post(
            `api/v2/Agency/DeleteImage/${agencyId}`,
            null,
            this.axiosConfig
        )
        return true;
    }

    public static async uploadAgencyBgImage(agencyId: number, file): Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append('file', file);

        const { data } = await axios.post(
            `api/v2/Agency/UploadBackgroundImage/${agencyId}`,
            bodyFormData,
            this.axiosConfigWithFile
        )
        return data;
    }

    public static async deleteAgencyBgImage(agencyId: number) {
        const { data } = await axios.post(
            `api/v2/Agency/DeleteBackgroundImage/${agencyId}`,
            null,
            this.axiosConfig
        )
        return true;
    }

    public static async getAgencyUsersToImpersonate(agencyId: number): Promise<ImpersonateData> {
        const { data } = await axios.get(
            `api/AdminImpersonation/GetAgencyUsersToImpersonate?agencyId=${agencyId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getSession(agencyId: number, userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/AdminImpersonation/RequestSession?squadId=${agencyId}&type=agency&userId=${userId}`,
            this.axiosConfig
        )
        return data
    }
}
