import { translate } from '../services/localization';

// function but not const is needed for localization function

export default function getSections() {
    return {
        squad: {
            name: translate('filter.squad'),
            id: 1,
            default: {
                squadId: 0,
            },
        },
        status: {
            name: translate('filter.status'),
            id: 2,
            default: {
                isForLoan: false,
                isForSale: false,
                isForFree: false,
                isInReleaseList: false,
                recommended: false
            },
        },
        marketValue: {
            name: translate('filter.xTV'),
            id: 3,
            default: {
                minMarketValue: 0,
                maxMarketValue: 75000000,
            },
        },
        age: {
            name: translate('filter.age'),
            id: 4,
            default: {
                minAge: 16,
                maxAge: 40,
            },
        },
        contractExpiry: {
            name: translate('filter.contractExpiry'),
            id: 5,
            default: {
                contractExpiryMin: 0,
                contractExpiryMax: 120,
            },
            closedByDefault: true,
        },
        position: {
            name: translate('filter.position'),
            id: 6,
            default: { positions: [] },
        },
        league: {
            name: translate('filter.league'),
            id: 7,
            default: { competitionLists: [] },
        },
        transferType: {
            name: 'Transfer Type',
            id: 8,
            default: { 
                isToBuy: true,
                isToLoan: true
            }
        },
        transferFee: {
            name: 'Transfer Fee',
            id: 9,
            default: { 
                minTransferFee: 0,
                maxTransferFee: 500000000,
            }
        },
        loanFee: {
            name: 'Monthly loan fee',
            id: 10,
            default: { 
                minLoanFee: 0,
                maxLoanFee: 5000000,
            }
        },
        gsnPerformance: {
            name: translate('filter.gsnPotential'),
            id: 11,
            default: {
                gsnMin: 0,
                gsnMax: 100,
            },
            closedByDefault:true
        },
        minutesPlayed: {
            name: translate('filter.minutesPlayed'),
            id: 12,
            default: {
                mpMin: 0,
                mpMax: 100,
            },
            closedByDefault:true
        },
        faPlayerPoints: {
            name: 'GBE Status',
            id: 13,
            default: { 
                faPointsPassResult: false,
                faPointsAutoPassResult: false,
                faPointsExemptionsPanelResult: false,
                faPointsUkNationalsOrSettled: false,
            },
        },
        isLeftFooted: {
            name: 'Left Footed',
            id: 14,
            default: {
                isLeftFooted: false,
            },
        },
        height: {
            name: translate('filter.height'),
            id: 15,
            default: {
                minHeight: null,
                maxHeight: null,
            },
        },
    };
}

//export default sections;
