export const benefits = [
    'Supercharge transfer talks with one-to-one meetings',
    'Connect with 100s of clubs and verified Trusted Agents',
    'Save weeks of travel and money on negotiations with one event',
    'Get deals done fast with less than four weeks of the Summer Transfer Window left'
]

export const whatToExpectBenefits = [
    'Get straight to business, with “speed-dating” style meetings, to accelerate your success',
    'Exclusive networking opportunities and drinks',
    'Insight and expertise from top talent in the region'
]