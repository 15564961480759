import userActivityInsert from "../../../app/user-activity/actions/user-activity.actions";
import {StateController} from 'utils/action-declaration'
import {AppState} from "root.reducer";
import AgencyPlayerOnboardingService from "api/agency/player/shared/agency-player-service";
import historyAccessor from "history-accessor";
import {playerPathsV2} from "routes/paths";
import {PLAYER_UPDATE_IS_AGENT_FINDER_AVAILABLE} from 'store/actionTypes';
import {getAuth} from "store/auth/authReducer";
import { getPlayerUserPermissions } from 'store/userPreference/userPreference.reducer';

export enum ConfirmSteps {
    ConfirmAgency,
    ConfirmedScreen,
    RepresentationScreen,
}

class State {
    step: ConfirmSteps;
    isAgencyConfirmed: boolean;
    isProcessing: boolean;
}

const defaultState = {
    step: ConfirmSteps.ConfirmAgency,
    isAgencyConfirmed: false,
    isProcessing: false,
}

const stateController = new StateController<State>(
    'PLAYER/AGENCY_CONFIRM',
    defaultState
)

class Actions {
    public static init() {
        return (dispatch, getState: () => AppState) => {
            const status = getState().playerV2.homePage.serverData;
            if(status === null) {
                historyAccessor.push(playerPathsV2.homePage);
            }
        }
    }

    public static setStep(step: ConfirmSteps) {
        return (dispatch) => {
            dispatch(stateController.setState({ step: step }));
        }
    }
    public static confirmAgency(confirmStatus: boolean, agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const uAMessage = confirmStatus ? 'Confirmed Request' : 'Rejected Request'
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isProcessing: true,
            })));
            const confirmRequest = {
                invitationUid: state.playerV2.homePage.serverData.agentPlayerInvitation.invitationUid,
                isAgentConfirmed: confirmStatus,
                endorseAgent: null,
            }
            await dispatch(Actions.sendUserActivity(uAMessage, agencyId))
            await AgencyPlayerOnboardingService.confirmAgentAuthenticated(confirmRequest);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isAgencyConfirmed: confirmStatus,
                isProcessing: false
            })));

            if (getPlayerUserPermissions(state)?.isAgentFinderAvailable) {
                dispatch({ type: PLAYER_UPDATE_IS_AGENT_FINDER_AVAILABLE, payload: { data: !confirmStatus }});
            }
            dispatch(Actions.setStep(ConfirmSteps.ConfirmedScreen));
        }
    }

    public static redirectAfterConfirmAgency() {
        return (dispatch) => {
            dispatch(Actions.setStep(ConfirmSteps.ConfirmAgency));
            historyAccessor.push(playerPathsV2.homePage);
        }
    }
    public static sendUserActivity(message: string, agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Agent Confirmation',
                Message: message,
                PlayerId: playerId,
                AgencyId: agencyId,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.agencyConfirm;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
    public static isAgencyConfirmed = (state: AppState) => Selectors.getRoot(state).isAgencyConfirmed;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}