import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';

const initialState = {
    inTransferList: [],
    totalInAmount: null,
    outTransferList: [],
    totalOutAmount: null
};

export default (transferOver = initialState, action) => {
    switch (action.type) {
        case BIGGEST_TRANSFERS_FETCH.SUCCESS: {
            return {
                ...transferOver,  ...action.payload.data
            };
        }
        case BIGGEST_TRANSFERS_FETCH.REQUEST:
            return { ...transferOver };

        default:
            return transferOver;
    }
};


export const BIGGEST_TRANSFERS_FETCH = asyncActionType('app/financial-insights/BIGGEST_TRANSFERS_FETCH');

export const getBiggestTransfersLoadingStatus = state =>
    getRequests(state).some(r => r.type === BIGGEST_TRANSFERS_FETCH);


