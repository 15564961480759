import { StateController } from 'utils/action-declaration';
import { TEST_INTEREST_MODAL, SUPER_PITCH_VIEW, SUPER_PITCH_CREATE } from 'constants/modals';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { AppState } from 'root.reducer'
import { SuperPitchService } from 'api/super-pitch/super-pitch'
import { arrayToObject } from 'services/utils';
import * as SuperPitchCreateController from '../super-pitch-new-create-flow/store/super-pitch-create.controller'
import moment from 'moment';
import { ComparisonRequest } from 'api/super-pitch/models/comparison'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class SuperPitch {
    id: number
    buy: {
        price: number,
        sellOn: number,
        toBeDiscussed: boolean
    } | null;
    buyFormatted: string;
    loan: {
        price: number,
        toBeDiscussed: boolean
    } | null
    loanFormatted: string;
    comparisonKeys: Array<string>
    insertedAt: Date
    isComparisonKeysVisibleOnPitch: boolean
    isMessageVisibleOnPitch: boolean
    isRsvpVisibleOnPitch: boolean
    isVideoVisibleOnPitch: boolean
    currency: { id: number, name: string }
    message: string
    pitchesWereInterestedCount: number
    pitchesWereOpenedCount: number
    playerInfo: { [key: string]: any }
    recipientsSquadIdList: Array<number>;
    recipientsSquadList: Array<{ [key: string]: any }>
    rsvp: Date | null
    totalSquadsWerePitched: number
    videoId: number | null
    videoUrl: string | null
}

class CommonState {
    pitches: {
        byId: { [key: number]: SuperPitch };
        ids: Array<number>;
        isLoading: boolean
    };
    preview: {
        isLoading: Array<number>,
        comparisonData: any
    }


}

const defaultState: CommonState = {
    pitches: {
        byId: {},
        ids: [],
        isLoading: false
    },
    preview: {
        isLoading: [],
        comparisonData: {}
    },
}

const stateController = new StateController<CommonState>(
    "SUPER_PITCH/COMMON",
    defaultState
)

class Actions {
    public static superPitchesFetch = () => async (dispatch, getState: () => AppState) => {
        const squadId = getState().auth.squadId
        const userId = getState().auth.userId
        let substate = getState().superPitch.common;
        dispatch(stateController.setState({
            pitches: { ...substate.pitches, isLoading: true }
        }))
        const data = await SuperPitchService.superPitchesFetch(squadId, userId);
        await dispatch(stateController.setState({
            ...substate,
            pitches: {
                ...substate.pitches,
                byId: { ...(arrayToObject(data.items)) },
                ids: data.items.map(i => i.id),
                isLoading: false
            },
        }))
        return
    }
    public static updateSuperPitchSquadData = (pitchId: number, pitchSquadId: number) => (dispatch, getState: () => AppState) => {
        const superPitches = getState().superPitch.common.pitches
        let newSquads = superPitches.byId[pitchId].recipientsSquadList.map(i => {
            if (i.id === pitchSquadId) {
                i.interestTestedAt = moment.utc().format();
                i.canTestInterest = false;
                i.testInterestStatus = 0;
            }
            return i;
        });
        const substate = getState().superPitch.common
        dispatch(stateController.setState({
            ...substate,
            pitches: {
                ...superPitches,
                byId: {
                    ...superPitches.byId,
                    [pitchSquadId]: {
                        ...superPitches.byId[pitchSquadId],
                        recipientsSquadList: newSquads
                    }
                }
            }
        }))
    }
    public static loadPreviewComparisonSource = (playerId, pitchId) => {
        return async (dispatch, getState: () => AppState) => {
            const userId = getState().auth.userId
            const squadId = getState().auth.squadId
            const substate = getState().superPitch.common

            try {
                dispatch(stateController.setState({
                    preview: {
                        ...substate.preview,
                        isLoading: [...substate.preview.isLoading, pitchId],
                    }
                }))
                let payload = new ComparisonRequest();
                payload.superPitchId = pitchId;
                const data = await SuperPitchService.loadComparisonData(userId, squadId, playerId, payload)
                dispatch(stateController.setState({
                    preview: {
                        ...substate.preview,
                        comparisonData: {
                            ...substate.preview.comparisonData,
                            characteristicSource: data.characteristicSource
                        },
                        isLoading: substate.preview.isLoading.filter(item => item !== pitchId),
                    }
                }))
            } catch (e) {
                dispatch(stateController.setState({
                    preview: {
                        ...substate.preview,
                        isLoading: substate.preview.isLoading.filter(item => item !== pitchId),
                    }
                }))
            }
        }
    }
    public static loadPreviewComparisonData = (playerId, keys, superPitchId) => {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().superPitch.common

            try {
                dispatch(stateController.setState({
                    preview: {
                        ...substate.preview,
                        isLoading: [...substate.preview.isLoading, superPitchId],
                    }
                }))
                let payload = new ComparisonRequest();
                payload.superPitchId = superPitchId;
                payload.keys = keys;
                const data = await SuperPitchService.loadComparisonDataOnSelect(playerId, payload);
                dispatch(stateController.setState({
                    preview: {
                        ...substate.preview,
                        comparisonData: {
                            ...substate.preview.comparisonData,
                            comparisonLists: data.items,
                            firstKey: keys[0],
                            secondKey: keys[1],
                            thirdKey: keys[2]
                        },
                        isLoading: substate.preview.isLoading.filter(item => item !== superPitchId),
                    }
                }))
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState({
                    preview: {
                        ...substate.preview,
                        isLoading: substate.preview.isLoading.filter(item => item !== superPitchId),
                    }
                }))
            }
        }
    }
    public static loadComparisonForPitch = (superPitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().superPitch.common.pitches.byId[superPitchId].playerInfo.id
            const comparisonKeys = getState().superPitch.common.pitches.byId[superPitchId].comparisonKeys
            await dispatch(Actions.loadPreviewComparisonSource(playerId, superPitchId))
            await dispatch(Actions.loadPreviewComparisonData(playerId, comparisonKeys, superPitchId))
            return
        }
    }

    public static openPreviewModal = (superPitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const isComparisonVisible = getState().superPitch.common.pitches.byId[superPitchId].isComparisonKeysVisibleOnPitch
            let comparison = null
            if (isComparisonVisible) {
                await dispatch(Actions.loadComparisonForPitch(superPitchId))
                comparison = getState().superPitch.common.preview.comparisonData
            }
            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: SUPER_PITCH_VIEW,
                    className: 'spn-modal',
                    content: {
                        id: superPitchId,
                        comparison: comparison
                    }
                }
            };
            dispatch(command)
        }
    }
    public static closePreviewModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_VIEW,
            };

            dispatch(command);
        }
    }

    public static removeSuperPitch = (pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const squadId = getState().auth.squadId
            const userId = getState().auth.userId
            const pitch = getState().superPitch.common.pitches.byId[pitchId]
            await SuperPitchService.removeSuperPitch(squadId, userId, pitchId)
            await dispatch(userActivityInsert({
                PageName: 'Super Pitch [Drop-down]',
                Message: 'Removed Pitch',
                PageType: PageType.Pitch,
                PlayerId: pitch.playerInfo.id,
                ClubId: pitch.playerInfo.parentSquad.id,
            }))
            await dispatch(Actions.superPitchesFetch())
        }
    }



    public static openSuperPitchCreateModal = () => {
        return (dispatch, getState) => {
            let command = { type: MODAL_OPEN, payload: { id: SUPER_PITCH_CREATE }, };
            dispatch(command);
        };
    };
}

class Selectors {
    public static getTotalPitches = (state) => {
        let totalSquadsPitched = 0;
        let totalPitchesInterested = 0;
        let totalPitchesOpened = 0;
        if (state.superPitch.common.pitches.ids) {
            state.superPitch.common.pitches.ids.map(i => {
                let a = state.superPitch.common.pitches.byId[i];
                totalSquadsPitched += a.totalSquadsWerePitched;
                totalPitchesInterested += a.pitchesWereInterestedCount;
                totalPitchesOpened += a.pitchesWereOpenedCount;
                return i;
            });
        }
        return { totalSquadsPitched, totalPitchesInterested, totalPitchesOpened }
    }


}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};