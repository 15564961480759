import React from 'react';
import styled from 'styled-components'

export const PastEventVideoVimeo = ({ id }) => (
    <Section>
        <iframe
            src={`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`}
            width="465"
            height="280"
            frameBorder="0"
            allowFullScreen={true}
            title="event"
            id="ifr-event"
        />
    </Section>
);

const Section = styled.div`
    border-radius: 10px;
    overflow: hidden;
    display: inline-flex;
    box-shadow: 0 18px 30px -14px rgba(0, 0, 0, 0.5), 0 2px 5px -2px rgba(0, 0, 0, 0.3);
    flex-grow: 1;
    width: 80%;
`