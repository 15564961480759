import _ from 'lodash'
import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import MappingService from 'api/admin/mapping/mapping.service'
import { getAuthClubId, getAuthSquadId } from 'store/auth/authReducer'
import { SquadModel, SquadModelWithKey, SquadDataModel, PlayerModel, SuggestedSquadModel, SuggestedSquadModelWithKey, CompetitonModel } from 'api/admin/mapping/models'



export type SquadsTabState = {
    squads: SquadModel[]
    fetchingIds: number[]
    competitions: CompetitonModel[]
    suggestedItems: { id: number, items: SuggestedSquadModelWithKey[] }[]
    processingItems: number[]
    autosuggestKeywords: {id: number, value: string}[]
    rowCount: number
    currentPage: number
    recordsPerPage: number
    isFetching: boolean
    squadNameKeyword: string
    selectLeagueKeyword: string
    selectedLeagueId: string
}
export type PlayersTabState = {
    players: PlayerModel[]
    suggestedItems: { id: number, items: SuggestedSquadModelWithKey[] }[]
    fetchingIds: number[]
    suggestedSquads: SquadModelWithKey[]
    processingItems: number[]
    autosuggestKeywords: {id: number, value: string}[]
    rowCount: number
    currentPage: number
    recordsPerPage: number
    isFetching: boolean
    isAutosuggestFetching: boolean
    nameOfSelectedSquad: string
    apiIdOfSelectedSquad: number
}

class MappingState {
    source: string
    selectedTab: string
    squadsTab: SquadsTabState
    playersTab: PlayersTabState
}

const defaultState = {
    source: 'transfermrkt',
    selectedTab: '1',
    squadsTab: {
        squads: [],
        fetchingIds: [],
        competitions: [],
        suggestedItems: [],
        processingItems: [],
        autosuggestKeywords: [],
        rowCount: 0,
        currentPage: 1,
        recordsPerPage: 10,
        isFetching: false,
        squadNameKeyword: '',
        selectLeagueKeyword: '',
        selectedLeagueId: '',
    },
    playersTab: {
        players: [],
        fetchingIds: [],
        suggestedItems: [],
        suggestedSquads: [],
        processingItems: [],
        autosuggestKeywords: [],
        rowCount: 0,
        currentPage: 1,
        recordsPerPage: 10,
        isFetching: false,
        isAutosuggestFetching: false,
        nameOfSelectedSquad: '',
        apiIdOfSelectedSquad: null,
    },
}

const stateController = new StateController<MappingState>(
    'ADMIN_V2/MAPPING',
    defaultState
)


class Actions {

    public static changeActiveTab(tabNumber: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ selectedTab: tabNumber }))
        }
    }

    //==================================================== Squads tab ===================================================

    public static initSquads() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        isFetching: true,
                    }
                })))
                await dispatch(Actions.loadSquads())
                await dispatch(Actions.loadAllCompetitions())

                // const { competitions } = getState().admin.mapping.squadsTab
            
                await dispatch(Actions.onSelectLeague(null, true))
            
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        isFetching: false,
                    }
                })))
            }
        }
    }

    public static loadSquads(spinner?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                if (spinner) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        squadsTab: {
                            ...prevState.squadsTab,
                            isFetching: true,
                        }
                    })))
                }
                const source = getState().admin.mapping.source
                const currentPage = getState().admin.mapping.squadsTab.currentPage
                const squadName = getState().admin.mapping.squadsTab.squadNameKeyword
                const recordsPerPage = getState().admin.mapping.squadsTab.recordsPerPage
                const selectedLeagueId = getState().admin.mapping.squadsTab.selectedLeagueId
                const data = await (await MappingService.loadSquads(source, currentPage, recordsPerPage, squadName, selectedLeagueId)).data as SquadDataModel
                const squadsWithKeys = data.output.map(item => ({
                    ...item,
                    key: item.apiId,
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        squads: squadsWithKeys,
                        rowCount: data.rowCount,
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                if (spinner) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        squadsTab: {
                            ...prevState.squadsTab,
                            isFetching: false,
                        }
                    })))
                }
            }
        }
    }

    public static createSquad(apiId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        processingItems: [ ...prevState.squadsTab.processingItems, apiId],
                    }
                })))
                const source = getState().admin.mapping.source
                await MappingService.createSquad(source, apiId)
                await dispatch(Actions.loadSquads())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        processingItems: prevState.squadsTab.processingItems.filter(i => i !== apiId)
                    }
                })))
            }
        }
    }

    public static mapSquad(apiId: number, item: any) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        processingItems: [ ...prevState.squadsTab.processingItems, apiId],
                    }
                })))
                const source = getState().admin.mapping.source
                await MappingService.mapSquad(source, apiId, item.id)
                await dispatch(Actions.loadSquads())
            } catch (err) {
                console.error(err) 
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        processingItems: prevState.squadsTab.processingItems.filter(i => i !== apiId)
                    }
                })))
            }
        }
    }

    public static unmapSquad(apiId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        processingItems: [ ...prevState.squadsTab.processingItems, apiId],
                    }
                })))
                const source = getState().admin.mapping.source
                await MappingService.unmapSquad(source, apiId)
                await dispatch(Actions.loadSquads())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        processingItems: prevState.squadsTab.processingItems.filter(i => i !== apiId)
                    }
                })))
            }
        }
    }

    public static activatePlayersMode(item: SquadModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(Actions.changeActiveTab('2'))
                dispatch(Actions.applySquadFilter(item.apiId, item.name))
                dispatch(Actions.loadPlayers(true))
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static onChangeCurrentSquadsPage(page: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        currentPage: page,
                    }
                })))
                dispatch(Actions.loadSquads(true))
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static onChangeSquadsRecordsPerPage(size: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                squadsTab: {
                    ...prevState.squadsTab,
                    recordsPerPage: size,
                }
            })))
        }
    }

    public static onChangeKeywordOfClubNameFilter(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                squadsTab: {
                    ...prevState.squadsTab,
                    squadNameKeyword: keyword,
                }
            })))
            Actions.loadSquadsDebounce(dispatch)
        }
    }

    private static loadSquadsDebounce = _.debounce((dispatch) => dispatch(Actions.loadSquads(true)), 1000)


    public static onMapSquadKeywordChange(apiId: number, keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            
            const autosuggestKeywords = getState().admin.mapping.squadsTab.autosuggestKeywords
            const isItemCreated = !!autosuggestKeywords.find(i => i.id === apiId)
            
            if (isItemCreated) {
                const keywordItem = { id: apiId, value: keyword }
                
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        autosuggestKeywords: [
                            ...prevState.squadsTab.autosuggestKeywords.filter(i => i.id !== apiId),
                            keywordItem,
                        ],
                    },
                })))
            } else {
                const keywordItem = { id: apiId, value: keyword }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        autosuggestKeywords: [
                            ...prevState.squadsTab.autosuggestKeywords,
                            keywordItem,
                        ]
                    } 
                })))
            }
            
            if (keyword) {
                Actions.loadSquadSuggestionsDebounce(dispatch, apiId, keyword)
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        suggestedItems: [
                            ...prevState.squadsTab.suggestedItems.filter(i => i.id !== apiId)
                        ],
                    }
                })))
            }
        }
    }

    public static onBlurSquadAutosuggest(apiId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                squadsTab: {
                    ...prevState.squadsTab,
                    autosuggestKeywords: [
                        ...prevState.squadsTab.autosuggestKeywords.filter(i => i.id !== apiId),
                    ],
                    suggestedItems: [
                        ...prevState.squadsTab.suggestedItems.filter(i => i.id !== apiId),
                    ],
                }
            })))
        }
    }

    private static loadSquadSuggestionsDebounce = _.debounce((dispatch, apiId, keyword) => dispatch(Actions.loadSquadSuggestionsByCompetition(apiId, keyword)), 1000)


    public static loadSquadSuggestionsByCompetition(apiId: number, keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState())
                const squadId = getAuthSquadId(getState())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        fetchingIds: [
                            ...prevState.squadsTab.fetchingIds,
                            apiId,
                        ],
                    }
                })))

                const data = (await MappingService.loadSquadSuggestionsOnSquadsTab(keyword, squadId, clubId)).data as SuggestedSquadModel[]
                const withValue = data.map(item => ({ ...item, value: item.englishFirstName, key: item.id }))
                const item = { id: apiId, items: withValue}

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        suggestedItems: [
                            ...prevState.squadsTab.suggestedItems.filter(i => i.id !== apiId),
                            item,
                        ]
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        fetchingIds: [
                            ...prevState.squadsTab.fetchingIds.filter(i => i !== apiId)
                        ],
                    }
                })))
            }
        }
    }

    public static loadAllCompetitions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { competitions } = getState().admin.mapping.squadsTab
                if (competitions.length !== 0) return

                const { source } = getState().admin.mapping
                const data = (await MappingService.loadAllCompetitions(source)).data
                const dataWithValue: CompetitonModel[] = data.map((item) => ({
                    ...item,
                    value: `(${item.country}) ${item.league}`,
                }))
                const allOption = {
                    id: "ALL",
                    country: "",
                    league: "All",
                    divisionLevel: 0,
                    competitionId: null,
                    apiId: '',
                    isDomesticLeague: null,
                    value: "All",
                }
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        competitions: [ allOption, ...dataWithValue ],
                    }
                })))
            } catch (err) {
                console.error(err)
            } 
        }
    }

    public static onChangeKeywordOfSelectedLeague(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                squadsTab: {
                    ...prevState.squadsTab,
                    selectLeagueKeyword: keyword,
                }
            })))
            if (!keyword) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    squadsTab: {
                        ...prevState.squadsTab,
                        selectedLeagueId: '',
                    }
                })))
            }
        }
    }

    public static onSelectLeague(item: CompetitonModel, init?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                squadsTab: {
                    ...prevState.squadsTab,
                    selectLeagueKeyword: !!item ? item.value : '',
                    selectedLeagueId: !!item ? item.apiId : '',
                }
            })))
            if (init) return
            dispatch(Actions.loadSquads(true))
        }
    }

    //==================================================== Players tab ==================================================

    public static initPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        isFetching: true,
                    }
                })))

                await dispatch(Actions.loadPlayers())
                await dispatch(Actions.loadSquadSuggestions())

                const { suggestedSquads } = getState().admin.mapping.playersTab

                await dispatch(Actions.onSelectSquad(suggestedSquads[0], true))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        isFetching: false,
                    }
                })))
            }
        }
    }

    public static loadPlayers(spinner?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                if (spinner) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        playersTab: {
                            ...prevState.playersTab,
                            isFetching: true,
                        }
                    })))
                }
                const source = getState().admin.mapping.source
                const currentPage = getState().admin.mapping.playersTab.currentPage
                const recordsPerPage = getState().admin.mapping.playersTab.recordsPerPage
                const apiIdOfSelectedSquad = getState().admin.mapping.playersTab.apiIdOfSelectedSquad
                const data = (await MappingService.loadPlayers(source, currentPage, recordsPerPage, apiIdOfSelectedSquad)).data as SquadDataModel
                
                const playersWithKeys = data.output.map(item => ({
                    ...item,
                    key: item.apiId,
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        players: playersWithKeys,
                        rowCount: data.rowCount,
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                if (spinner) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        playersTab: {
                            ...prevState.playersTab,
                            isFetching: false,
                        }
                    })))
                }
            }
        }
    }

    public static createPlayer(apiId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        processingItems: [ ...prevState.playersTab.processingItems, apiId],
                    }
                })))
                const source = getState().admin.mapping.source
                await MappingService.createPlayer(source, apiId)
                await dispatch(Actions.loadPlayers())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        processingItems: prevState.playersTab.processingItems.filter(i => i !== apiId)
                    }
                })))
            }
        }
    }

    public static mapPlayer(apiId: number, playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        processingItems: [ ...prevState.playersTab.processingItems, apiId],
                    }
                })))
                const source = getState().admin.mapping.source
                await MappingService.mapPlayer(source, apiId, playerId)
                await dispatch(Actions.loadPlayers())
            } catch (err) {
                console.error(err) 
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        processingItems: prevState.playersTab.processingItems.filter(i => i !== apiId)
                    }
                })))
            }
        }
    }

    public static unmapPlayer(apiId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        processingItems: [ ...prevState.playersTab.processingItems, apiId],
                    }
                })))
                const source = getState().admin.mapping.source
                await MappingService.unmapPlayer(source, apiId)
                await dispatch(Actions.loadPlayers())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        processingItems: prevState.playersTab.processingItems.filter(i => i !== apiId)
                    }
                })))
            }
        }
    }

    public static applySquadFilter(apiId: number, squadName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playersTab: {
                    ...prevState.playersTab,
                    nameOfSelectedSquad: squadName,
                    apiIdOfSelectedSquad: apiId,
                }
            })))
        }
    }

    public static onChangeCurrentPlayersPage(page: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        currentPage: page,
                    }
                })))
                dispatch(Actions.loadPlayers(true))
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static onChangePlayersRecordsPerPage(size: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playersTab: {
                    ...prevState.playersTab,
                    recordsPerPage: size,
                }
            })))
        }
    }

    public static onMapPlayerKeywordChange(apiId: number, keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            
            const autosuggestKeywords = getState().admin.mapping.playersTab.autosuggestKeywords
            const isItemCreated = !!autosuggestKeywords.find(i => i.id === apiId)
            
            if (isItemCreated) {
                const keywordItem = { id: apiId, value: keyword }
                
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        autosuggestKeywords: [
                            ...prevState.playersTab.autosuggestKeywords.filter(i => i.id !== apiId),
                            keywordItem,
                        ],
                    },
                })))
            } else {
                const keywordItem = { id: apiId, value: keyword }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        autosuggestKeywords: [
                            ...prevState.playersTab.autosuggestKeywords,
                            keywordItem,
                        ]
                    } 
                })))
            }
            
            if (keyword) {
                Actions.loadPlayerSuggestionsDebounce(dispatch, apiId, keyword)
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        suggestedItems: [
                            ...prevState.playersTab.suggestedItems.filter(i => i.id !== apiId)
                        ],
                    }
                })))
            }
        }
    }

    public static loadSquadSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const source = getState().admin.mapping.source
                const data = (await MappingService.loadSquadSuggestionsOnPlayersTab(source, 'All')).data as SquadModel[]
                const dataWithValue: SquadModelWithKey[] = data.map((item) => ({
                    ...item,
                    key: item.apiId,
                    value: item.name,
                }))
                const allOption = {
                    apiId: null,
                    logo: 'https://tmssl.akamaized.net/images/wappen/head/default.png?lm=1457423031',
                    name:'All',
                    country: '',
                    squadId: null,
                    mappedSquad: '',
                    mappedShortSquad: '',
                    competitionApiId: null,
                    competitionName: null,
                    forImport: true,
                    key: 0,
                    value: 'All',
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        suggestedSquads: [ allOption, ...dataWithValue ],
                    }
                })))
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static onBlurPlayerAutosuggest(apiId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playersTab: {
                    ...prevState.playersTab,
                    autosuggestKeywords: [
                        ...prevState.playersTab.autosuggestKeywords.filter(i => i.id !== apiId),
                    ],
                    suggestedItems: [
                        ...prevState.playersTab.suggestedItems.filter(i => i.id !== apiId),
                    ],
                }
            })))
        }
    }

    private static loadPlayerSuggestionsDebounce = _.debounce((dispatch, apiId, keyword) => dispatch(Actions.loadPlayerSuggestionsByCompetition(apiId, keyword)), 1000)


    public static loadPlayerSuggestionsByCompetition(apiId: number, keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState())
                const squadId = getAuthSquadId(getState())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        fetchingIds: [
                            ...prevState.playersTab.fetchingIds,
                            apiId,
                        ],
                    }
                })))

                const data = await (await MappingService.loadPlayerSuggestionsOnPlayersTab(keyword, squadId, clubId)).data
                const withValue = data.map(item => ({ ...item, value: item.englishShortName, key: item.id }))
                const item = { id: apiId, items: withValue}

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        suggestedItems: [
                            ...prevState.playersTab.suggestedItems.filter(i => i.id !== apiId),
                            item,
                        ]
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        fetchingIds: [
                            ...prevState.playersTab.fetchingIds.filter(i => i !== apiId)
                        ],
                    }
                })))
            }
        }
    }

    public static onChangeKeywordOfSelectedSquad(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playersTab: {
                    ...prevState.playersTab,
                    nameOfSelectedSquad: keyword,
                }
            })))
            if (!keyword) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playersTab: {
                        ...prevState.playersTab,
                        apiIdOfSelectedSquad: null,
                    }
                })))
            }
        }
    }

    public static onSelectSquad(item: SquadModelWithKey, init?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playersTab: {
                    ...prevState.playersTab,
                    nameOfSelectedSquad: item.name,
                    apiIdOfSelectedSquad: item.apiId,
                }
            })))
            if (init) return
            dispatch(Actions.loadPlayers(true))
        }
    }
}

class Selectors {

    public static filteredLagues(state: AppState) {
        const { competitions } = state.admin.mapping.squadsTab
        const { selectLeagueKeyword } = state.admin.mapping.squadsTab
        return competitions.filter(item => item.value.toLowerCase().includes(selectLeagueKeyword.toLowerCase()))
    }

    public static filteredSquads(state: AppState) {
        const { suggestedSquads } = state.admin.mapping.playersTab
        const { nameOfSelectedSquad } = state.admin.mapping.playersTab
        return suggestedSquads.filter(item => item.name.toLowerCase().includes(nameOfSelectedSquad.toLowerCase()))
    }
}


const reducer = stateController.getReducer()


export {
    reducer as Reducer,
    MappingState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}
