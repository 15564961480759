import axios from 'axios-config'
import { SuggestionModel } from 'api/admin/onboard-agency/models'
import { ResponseOfSubscriptions, SubscriptionModel } from 'api/admin/payments-of-agencies/models'


export default class AdminPermissionsService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getAgencySubscriptions(): Promise<ResponseOfSubscriptions> {
        const { data } = await axios.post(`api/v2/AdminAgencySubscription/GetAgencySubscriptions`,
            {               
                pageIndex: 1,
                pageSize: 9000,
            },
            this.axiosConfig
        )
        return data
    }

    public static async updateNotes(agencyId: number, textOfNote: string) {
        const { data } = await axios.post(`/api/v2/AdminAgencySubscription/UpdateNotes`,
            {               
                agencySubscriptionId: agencyId,
                notes: textOfNote
            },
            this.axiosConfig
        )
        return data
    }

    public static async getAllPlansForAgencyToSubscribe(agencyId?: number): Promise<SubscriptionModel> {
        const { data } = await axios.get(`/api/v2/AgencySubscription/GetAdminPlansForAgency/${(agencyId || '')}`,
        // const { data } = await axios.get(`/api/v2/AgencySubscription/GetPlansForAgency/${(agencyId || '')}`,
            this.axiosConfig
        )
        return data
    }

    public static async agencyAddSubscription(agencyId: number, planId: string) {
        const { data } = await axios.post(`/api/v2/AdminAgencySubscription/AddSubscription`,
            {
                AgencyId: agencyId,                
                SubscriptionPlanId: planId,                
            },
            this.axiosConfig
        )
        return data
    }

    public static async setRegularPlayersSlotsAmount(agencyId: number, amount: number) {
        const { data } = await axios.post(`/api/v2/AdminAgencySubscription/SetRegularPlayersSlotsAmount`,
            {
                AgencyId: agencyId,                
                Amount: amount,                
            },
            this.axiosConfig
        )
        return data
    }

    public static async getAgencySearchFilterSuggestion(keyword: string): Promise<Array<SuggestionModel>> {
        const { data } = await axios.post(`/api/Suggestion/GetAgencySearchFilterSuggestion`,
            {
                keyword: keyword               
            },
            this.axiosConfig
        )
        return data
    }

    public static async getFutureSubscription(agencyId: number) {
        const { data } = await axios.get(`/api/v2/AdminAgencySubscription/GetFutureSubscription/${agencyId}`, 
            this.axiosConfig
        )
        return data
    }

    public static async renewSubscription(parameters: any) {
        const { data } = await axios.post(`/api/v2/AdminAgencySubscription/RenewSubscription`,
            {
                AgencyId: parameters.agencyId,
                BaseSubscriptionId: parameters.baseSubscriptionId,
                AdditionalSubscriptionId: parameters.additionalSubscriptionId, 
                RegularSlotsToSubscribe: parameters.regularSlotsToSubscribe,
                ExpirationDate: parameters.expirationDate                
            },
            this.axiosConfig
        )
        return data
    }
}