import React from 'react'
import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import styled from 'styled-components'
import RomeSummitImage from 'media/images/events/2024-march-rome/rome-summit.jpg'

const RomeSummitMarch2024 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="13"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom"
                        title2="Summit"
                        date="18th-20th March 2024, Rome + Online"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0' }}>
                        <img style={{ width: '100%', borderRadius: '10px' }} src={RomeSummitImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="675"
                        countries="59"
                        leagues="95"
                        agents="82"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

const SpeakersSection = styled.div`
    padding: 15px 0px 45px 0px;
    @media (max-width: 768px) {
        padding: unset;   
    }
    h2 {
        font-size: 21px;
        line-height: 34px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .speaker-card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .speaker-card {
            width: 250px;
            margin-bottom: 10px;

            .speaker-image {
                display: flex;
                justify-content: center;
        
                & > div{
                    width: 128px;
                    height: 128px;
                    border-radius: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        
            .speaker-info {
                margin-top: 15px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
        
                &__name {
                    font-weight: bold;
                }
        
                &__position {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &__club {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

        }
    }
`;

export const previousEventAttendees = [
    { id: 1, name: "AC Milan", logo: "logo_1834_AC_Milan.png" },
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 3, name: "Juventus FC", logo: "logo_1832_Juventus_FC.png" },
    { id: 46, name: "Liverpool FC", logo: "logo_1123_Liverpool_FC.png" },
    { id: 47, name: "Tottenham Hotspur", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 49, name: "Arsenal FC", logo: "logo_1121_Arsenal_FC.png" },
    { id: 50, name: "Chelsea FC", logo: "logo_Chelsea_FC.png" },
    { id: 60, name: "Newcastle United", logo: "logo_Newcastle_United.png" },
    { id: 62, name: "Southampton FC", logo: "1619_Southampton_FC.png" },
    { id: 63, name: "Everton FC", logo: "logo_1124_Everton_FC.png" },
    { id: 66, name: "Leicester City", logo: "logo_Leicester_City.png" },
    { id: 67, name: "West Ham United", logo: "1633_West_Ham_United.png" },
    { id: 70, name: "Brighton & Hove Albion", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 416, name: "Al-Fateh SC", logo: "16483_Al-Fateh_SC.png" },
    { id: 1421, name: "Gremio Foot-Ball Porto Alegrense", logo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 6249, name: "Bayer 04 Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 8239, name: "Brentford FC", logo: "logo_1153_Brentford_FC.png" },
    { id: 8886, name: "Jeonbuk Hyundai Motors", logo: "logo_2425_Jeonbuk_Hyundai_Motors_FC.png" },
    { id: 10026, name: "Los Angeles Galaxy", logo: "7847_Los_Angeles_Galaxy.png" },
    { id: 10422, name: "Vancouver Whitecaps FC", logo: "15015_Vancouver_Whitecaps_FC.png" },
    { id: 12294, name: "Olympique de Marseille", logo: "logo_1964_Olympique_Marseille.png" },
    { id: 12470, name: "VfL Wolfsburg", logo: "logo_VfL_Wolfsburg.png" },
    { id: 13058, name: "Feyenoord Rotterdam", logo: "logo_1533_Feyenoord_Rotterdam.png" },
    { id: 16046, name: "Olympique Lyonnais", logo: "3766_Olympique_Lyon.png" },
    { id: 17788, name: "Paris Saint-Germain", logo: "3767_Paris_Saint-Germain.png" },
    { id: 18960, name: "FC Porto", logo: "logo_1672_FC_Porto.png" },
    { id: 23352, name: "Stade Rennais FC", logo: "logo_Stade_Rennes.png" },
    { id: 23647, name: "Atletico de San Luis", logo: "25866_Atlético_de_San_Luis.png" },
    { id: 24711, name: "Urawa Red Diamonds", logo: "logo_2407_Urawa_Red_Diamonds.png" },
    { id: 25037, name: "Montevideo City Torque", logo: "23497_Montevideo_City_Torque.png" },
    { id: 27251, name: "CR Flamengo", logo: "5654_Clube_de_Regatas_do_Flamengo.png" },
    { id: 28879, name: "Athletic Bilbao", logo: "678_Athletic_Bilbao.png" },
    { id: 29065, name: "Club Sporting Cristal", logo: "15532_Club_Sporting_Cristal.png" },
    { id: 30849, name: "Atlanta United FC", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 32429, name: "Eintracht Frankfurt", logo: "logo_1268_Eintracht_Frankfurt.png" },
    { id: 35839, name: "Sociedade Esportiva Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 37475, name: "Mamelodi Sundowns FC", logo: "16344_Mamelodi_Sundowns_FC.png" },
    { id: 38553, name: "Aston Villa", logo: "logo_1138_Aston_Villa.png" },
    { id: 39176, name: "Leeds United", logo: "logo_1150_Leeds_United.png" },
    { id: 42798, name: "Cavalry FC", logo: "64336_Cavalry_FC.png" },
]

export default RomeSummitMarch2024