import _ from 'lodash'
import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { AgencyModel } from 'api/player-v2/models';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { HomePageService } from 'api/player-v2/home-page/home-page.service';
import { HubspotFormService } from 'api/hubspot-form/hubspot-form.service';
import { getCancelTokenSource } from 'axios-config';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAreaPhoneCodes } from 'app/phone-codes/actions/phone-codes.actions'
import { Actions as HomePageActions } from "pages/player-v2/home-page/home-page.controller";
import historyAccessor from 'history-accessor';
import { playerPathsV2 } from 'routes/paths';
import { isValid } from 'utils/validation';
import config from 'config';
import { SetInvitationStatusToPreconnectedNotTrustedRequest } from 'api/player-v2/home-page/models';
import { getUserPreference } from 'store/userPreference/userPreference.reducer';
import { AgentPlayerInvitationStatusEnum } from 'api/agency/player/shared/shared-models';

export enum SettingsSteps {
    SelectCurrentAgency = 1,
    ConfirmAgency = 2,
    InviteHubspotAgency = 3,
    ConfirmationScreen = 4,
    PreconnectedAgencyScreen = 5,
    ConfirmPreconnectedAgency = 6
}

export type FormData = {
    agencyName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneCodeAreaId: number,
    phoneNumber: string,
    phoneNumberInput: string,
}

class State {
    keyword: string;
    isLoading: boolean;
    agencies: AgencyModel[];
    wasLoaded: boolean;
    step: SettingsSteps;
    selectedAgency: AgencyModel;
    formData: FormData;
    playerName: string;
    wasScrolled: boolean;
    isLoadingHubspotResponse: boolean;
    isRequestSent: boolean;
    isFormValid: boolean;
    phoneCodes: {
        id: number
        flagImage: string
        phoneCode: string
        mask: string
    }[];
    preconnectedAgency: AgencyModel;
    isPreconnectedToAgency: boolean;
}

const defaultState: State = {
    keyword: '',
    isLoading: false,
    agencies: [],
    wasLoaded: false,
    step: SettingsSteps.SelectCurrentAgency,
    selectedAgency: null,
    formData: {
        agencyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneCodeAreaId: null,
        phoneNumber: '',
        phoneNumberInput: '',
    },
    playerName: '',
    wasScrolled: false,
    isLoadingHubspotResponse: false,
    isRequestSent: false,
    isFormValid: false,
    phoneCodes: [],
    preconnectedAgency: null,
    isPreconnectedToAgency: false,
}

const stateController = new StateController<State>(
    "PLAYERV2/INVITE-AGENCY",
    defaultState
)

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
            dispatch(Actions.setDefaultStep());
        }
    }

    public static setDefaultStep() {
        return (dispatch, getState: () => AppState) => {            
            let searchParams = new URL(window.location.href).searchParams;
            const isEa = searchParams.get("ea");
            const eaType = searchParams.get("type");
            
            if (isEa) {
                const playerId = getState().auth.playerId
                
                if (eaType === "rr") {
                    dispatch(userActivityInsert({
                        PageName: `Mail [Representation Requests]`,
                        Message: 'Clicked Connect With Existing Agency',
                        PlayerId: playerId,
                    }));
                }

                if (eaType === "ca") {
                    dispatch(userActivityInsert({
                        PageName: `Mail [Connect Agency]`,
                        Message: 'Clicked Connect With Existing Agency',
                        PlayerId: playerId,
                    }));
                }
            }
            
            const { invitationStatus } = getUserPreference(getState()).playerUserPermissions;
            const isPreconnectedToAgency = invitationStatus === AgentPlayerInvitationStatusEnum.PreconnectedToAgent;

            if (isPreconnectedToAgency) {
                dispatch(Actions.setStep(SettingsSteps.PreconnectedAgencyScreen));
                dispatch(stateController.setState({ isPreconnectedToAgency: isPreconnectedToAgency }));
            } else {
                dispatch(Actions.setStep(SettingsSteps.SelectCurrentAgency));
            }
        }
    }

    public static createDebounce = () => _.debounce((dispatch, keyword: string) => dispatch(Actions.loadAgencies(keyword)), 1000)

    public static cancelToken = null
    public static fetchDebounced = Actions.createDebounce()

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))

            if (keyword.length > 0) {
                if (keyword.length > 2) { // start searching after 3 letters typed
                    Actions.fetchDebounced(dispatch, keyword)
                    dispatch(Actions.setScrollPosition(false))
                }
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static initForm() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setPlayerName());
            dispatch(Actions.getPhoneCodes());
        }
    }

    public static getPreconnectedAgencyInfo() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await HomePageService.getPreconnectedAgencyInfo();
                const dataExtended = {
                    agencyId: data.id,
                    agencyName: data.name,
                    playersQty: data.playersCount,
                    isTrusted: data.isTrusted,
                    isHighTransparency: data.isTransparent,
                    primaryMarketArea: data.primaryMarket,
                    secondaryMarketArea: data.secondaryMarket,
                    playerRepresentationStatus: null,
                    expertInAreas: null,
                    hasAgencyProfile: null,
                }

                dispatch(stateController.setState({ preconnectedAgency: dataExtended }))

                dispatch(Actions.userActivityInsert({
                    pageType: 'Current Agency',
                    message: 'Viewed Recommended Current Agency',
                    agencyId: data.id,
                }))
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static onAgentFormChange(formData: { [K in keyof FormData]?: FormData[K] }) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    ...formData,
                },
            })))
            dispatch(Actions.validateForm());
            dispatch(Actions.setPhoneNumber());
        };
    }


    public static setPhoneNumber() {
        return (dispatch, getState: () => AppState) => {
            const { phoneCodeAreaId, phoneNumberInput } = Selectors.getRoot(getState()).formData;

            const phoneCodes = Selectors.getRoot(getState()).phoneCodes;
            const phoneCodeArea = phoneCodes.find(x => x.id === phoneCodeAreaId);
            const phoneNumber = `+${(phoneCodeArea?.phoneCode ?? '')} ${(phoneNumberInput ?? '')}`;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    phoneNumber: phoneCodeArea && phoneNumberInput ? phoneNumber : '',
                }
            })))
        };
    }

    public static getPhoneCodes() {
        return async (dispatch, getState: () => AppState) => {
            const data = await dispatch(getAreaPhoneCodes());

            dispatch(stateController.setState({ phoneCodes: data }))
        }
    }

    public static onClose() {
        return (dispatch) => {
            dispatch(Actions.userActivityInsert({
                pageType: 'Invite Agency',
                message: 'Clicked Close'
            }))
            historyAccessor.push(playerPathsV2.homePage);
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: '', wasLoaded: false, isLoading: false, agencies: [] }))
            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel()
                dispatch(Actions.userActivityInsert({
                    pageType: 'Current Agency',
                    message: 'Clicked Clear Search Bar'
                }))
            }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
            dispatch(Actions.setScrollPosition(false))
        }
    }

    public static loadAgencies(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {

                if (Actions.cancelToken)
                    Actions.cancelToken.cancel()

                Actions.cancelToken = getCancelTokenSource();

                const data = await AgencyFinderService.getAgencySuggestions(keyword, Actions.cancelToken.token);
                dispatch(stateController.setState({ agencies: data, wasLoaded: true }));
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setStep(step: SettingsSteps) {
        return (dispatch) => {
            dispatch(stateController.setState({ step: step }));
        }
    }

    public static goToSelectAgencyScreen() {
        return (dispatch) => {
            dispatch(Actions.setStep(SettingsSteps.SelectCurrentAgency))

            dispatch(Actions.userActivityInsert({
                pageType: 'Invite Agency',
                message: 'Clicked Back to Invite Agency'
            }))
        }
    }

    public static myAgencyIsTrusted(selectedAgency: AgencyModel) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setSelectedAgency(selectedAgency));
            dispatch(Actions.setStep(SettingsSteps.ConfirmAgency));
        }
    }

    public static myPreconnectedAgencyIsTrusted(selectedAgency: AgencyModel) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setSelectedAgency(selectedAgency));
            dispatch(Actions.setStep(SettingsSteps.ConfirmPreconnectedAgency));
        }
    }

    public static myAgencyIsNotTrusted(selectedAgency: AgencyModel) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setSelectedAgency(selectedAgency));
            dispatch(Actions.setStep(SettingsSteps.InviteHubspotAgency));
            dispatch(Actions.setPlayerName());
        }
    }

    public static setSelectedAgency(selectedAgency: AgencyModel) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ selectedAgency: selectedAgency }));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    agencyName: selectedAgency.agencyName,
                },
            })))
        }
    }

    public static setAgencyNameForInvitationModalForm(agencyName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    agencyName: agencyName,
                },
            })))
        }
    }

    public static confirmAgency() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const substate = Selectors.getRoot(getState());
                const agencyId = substate.selectedAgency.agencyId
                dispatch(Actions.userActivityInsert({
                    pageType: 'Invite Agency',
                    message: 'Clicked Confirm Agency',
                    agencyId: agencyId,
                }))
                await AgencyFinderService.addAgency(agencyId);
                dispatch(Actions.setStep(SettingsSteps.ConfirmationScreen));
            }
            finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static confirmPreconnectedAgency() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const agencyId = Selectors.getRoot(getState()).selectedAgency.agencyId

                await AgencyFinderService.addAgency(agencyId);

                dispatch(Actions.userActivityInsert({
                    pageType: 'Current Agency',
                    message: 'Clicked Confirm Agency',
                    agencyId: agencyId,
                }))
                dispatch(Actions.setStep(SettingsSteps.ConfirmationScreen));
            }
            finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setInvitationStatus() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const substate = Selectors.getRoot(getState());
                const { formData } = substate;
                const agencyId = substate.selectedAgency?.agencyId;
                const agentFirstName = formData.firstName;
                const agentLastName = formData.lastName;

                const payload: SetInvitationStatusToPreconnectedNotTrustedRequest = {
                    agencyId,
                    agentFirstName,
                    agentLastName,
                    agentEmail: formData.email,
                    phone: formData.phoneNumberInput,
                    phoneCodeAreaId: formData.phoneCodeAreaId
                };
                await HomePageService.setInvitationStatus(payload);
            }
            finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setPlayerName() {
        return (dispatch, getState: () => AppState) => {
            const playerName = getState().auth.userFullName;
            dispatch(stateController.setState({ playerName: playerName }))
        }
    }

    public static setScrollPosition(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ wasScrolled: value }));
        }
    }

    public static userActivityInsert(data: any) {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId
            dispatch(userActivityInsert({
                PageName: `Select Agent [${data.pageType}]`,
                Message: data.message,
                PlayerId: playerId,
                AgencyId: data.agencyId
            }));
        }
    }

    public static submitHubspotForm(agencyName: string, firstName: string, lastName: string, email: string, playerName: string, agencyNameForModal: string, phoneNumber: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoadingHubspotResponse: true }));
            try {
                const substate = Selectors.getRoot(getState());
                const selectedAgency = substate.selectedAgency;
                const agentFullName = `${firstName} ${lastName}`;
                const portalId = config.hubspotForms.portalId;
                const formId = config.hubspotForms.inviteAgencyFormId;
                const isPreconnectedToAgency = substate.isPreconnectedToAgency
                const fields = [
                    {
                        name: 'company',
                        value: agencyName,
                    },
                    {
                        name: 'firstname',
                        value: firstName,
                    },
                    {
                        name: 'lastname',
                        value: lastName,
                    },
                    {
                        name: 'email',
                        value: email,
                    },
                    {
                        name: 'phone',
                        value: phoneNumber,
                    },
                    {
                        name: 'agency_player_invite',
                        value: playerName,
                    },
                ]

                dispatch(Actions.userActivityInsert({
                    pageType: 'Invite Agency',
                    message: `Clicked Invite Agency: (${agencyName}, ${agentFullName})`,
                    agencyId: selectedAgency ? selectedAgency.agencyId : null,
                }))

                if (selectedAgency !== null && !selectedAgency.isTrusted) {
                    dispatch(Actions.setInvitationStatus())
                }

                if (agencyNameForModal) {
                    dispatch(HomePageActions.setInvitationStatusForNotTrustedAgency())
                }

                await HubspotFormService.sendDataToHubspotForm(fields, portalId, formId)

                dispatch(stateController.setState({ isRequestSent: true }));

                if (isPreconnectedToAgency) {
                    dispatch(Actions.userActivityInsert({
                        pageType: 'Current Agency',
                        message: 'Thank You Displayed',
                        agencyId: selectedAgency?.agencyId
                    }))
                }

                if (!(selectedAgency !== null || agencyNameForModal)) {
                    dispatch(Actions.userActivityInsert({
                        pageType: 'Invite Agency',
                        message: `Entered Agency Name: ${agencyName}`
                    }))
                }

                dispatch(Actions.userActivityInsert({
                    pageType: 'Invite Agency',
                    message: `Entered Agent First Name: ${firstName}`
                }))

                dispatch(Actions.userActivityInsert({
                    pageType: 'Invite Agency',
                    message: `Entered Agent Last Name: ${lastName}`
                }))

                if (email) {
                    dispatch(Actions.userActivityInsert({
                        pageType: 'Invite Agency',
                        message: `Entered Agent Email: ${email}`,
                    }))
                }

                if (phoneNumber) {
                    dispatch(Actions.userActivityInsert({
                        pageType: 'Invite Agency',
                        message: `Entered Agent Phone: ${phoneNumber}`,
                        agencyId: selectedAgency?.agencyId,
                    }))
                }

                dispatch(Actions.userActivityInsert({
                    pageType: 'Invite Agency',
                    message: `Agency Invite Sent: (${agencyName}, ${agentFullName})`,
                    agencyId: selectedAgency ? selectedAgency.agencyId : null,
                }))
            }
            finally {
                dispatch(stateController.setState({ isLoadingHubspotResponse: false }));
            }
        }
    }

    public static onConfirmationClick() {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const agencyId = substate.selectedAgency?.agencyId;
            const isPreconnectedToAgency = substate.isPreconnectedToAgency

            if (isPreconnectedToAgency) {
                dispatch(Actions.userActivityInsert({
                    pageType: 'Current Agency',
                    message: 'Clicked Go to Home',
                    agencyId: agencyId
                }))
            } else {
                dispatch(Actions.userActivityInsert({
                    pageType: 'Invite Agency',
                    message: 'Clicked Close'
                }));
            }

            historyAccessor.push(playerPathsV2.homePage);
        }
    }

    public static validateForm() {
        return async (dispatch, getState: () => AppState) => {
            const { agencyName, firstName, lastName, email, phoneNumber, phoneNumberInput } = Selectors.getRoot(getState()).formData;

            const agencyNameValid = agencyName && isValid.name(agencyName ?? '');
            const agentFirstNameValid = firstName && isValid.name(firstName ?? '');
            const agentLastNameValid = lastName && isValid.name(lastName ?? '');
            const agentEmailValid = email && isValid.email(email ?? '');
            const isValidPhone = phoneNumber && phoneNumberInput && isValid.phone(phoneNumber ?? '');


            const isFormValid =
                agencyNameValid &&
                agentFirstNameValid &&
                agentLastNameValid &&
                (agentEmailValid || isValidPhone);

            dispatch(stateController.setState({ isFormValid: isFormValid }));
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.inviteAgency;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



