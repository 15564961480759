import { createElement } from 'react'
import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
import HprTitle from './hpr/hpr-title';
import HprContent from './hpr/hpr-content';

const title = "TransferRoom Summit - Rome & Online";

const eventSettings: EventInfoModel = {
    shortTitle: "Rome Summit",
    title: title,
    shortEventDates: "18-20th March 2024",
    eventDates: "18-20th March 2024",
    location: "Rome, Italy",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null, //SpeakerCardBox,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        title: createElement(HprTitle, { title }),
        userActivityPageName: "Home [TransferRoom Summit Rome]",
        description: createElement(HprContent),
    },
    registration: {
        activateAgencyFeature: true
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: false
    }
};

export default eventSettings;