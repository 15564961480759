import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration'
import PasswordValidation from 'utils/validate-password';
import validator from 'services/validator';
import { AgencyPlayerOnboardingService } from 'api/agency/player/onboarding-v2/onboarding-v2.service'
import historyAccessor from 'history-accessor';
import { AUTH_LOGIN } from 'store/actionTypes';
import { playerPaths } from 'routes/paths'
import { modalOpen, modalClose } from 'store/modals/modalsActions'
import { PLAYER_TERMS_OF_SERVICE } from 'constants/modals'
import { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { OnboardingSource } from 'api/agency/player/onboarding-v2/models';

enum PlayerOnboardingStep {
    InvalidInvitation = 0,
    Registration = 1,
    PlayerAgencyRelations = 2,
    InvalidEmail = 3,
}

class PlayerOnboardingState {
    currentStep: PlayerOnboardingStep;
    token: string;
    isInitializing: boolean;
    playerId: number;
    source: OnboardingSource;
    registrationStep: {
        phoneCode: string;
        playerPhone: string;
        email: string;
        password: string;
        retypePassword: string;
        isAgreed: boolean;
        isRegistrationProcessing: boolean;
        emailError: boolean;
        passwordRules: Array<{ ruleCode: string, passed: boolean }>
        passwordError: any
        retypePasswordError: any
    };
    agencyRelationsStep: {
        agencyId: number,
        agencyName: string;
        isRelationConfirmed: boolean;
        shouldAgentBeEndorced: boolean;
        isSubmitProcessing: boolean;
    }
}

const defaultState: PlayerOnboardingState = {
    currentStep: PlayerOnboardingStep.Registration,
    token: null,
    isInitializing: false,
    playerId: null,
    source: null,
    registrationStep: {
        phoneCode: null,
        playerPhone: '',
        email: '',
        password: '',
        retypePassword: '',
        isAgreed: false,
        isRegistrationProcessing: false,
        emailError: false,
        passwordRules: [],
        passwordError: null,
        retypePasswordError: null
    },
    agencyRelationsStep: {
        agencyId: null,
        agencyName: '',
        isRelationConfirmed: true,
        shouldAgentBeEndorced: false,
        isSubmitProcessing: false,
    },
}

const stateController = new StateController<PlayerOnboardingState>(
    'PLAYER_TO_AGENCY_ONBOARDING',
    defaultState
)

class Actions {
    public static init(token: string, queryString?: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isInitializing: true, token: token }))
                const result = await AgencyPlayerOnboardingService.getAccessForSignUp(token)

                if (result.isValid) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        playerId: result.playerId,
                        source: result.onboardingSource,
                        registrationStep: {
                            ...prevState.registrationStep,
                            phoneCode: result.phoneCode,
                            playerPhone: result.playerPhone
                        },
                        agencyRelationsStep: {
                            ...prevState.agencyRelationsStep,
                            agencyId: result.agencyId,
                            agencyName: result.agencyName
                        }
                    })))

                    dispatch(Actions.userActivityFromVerificationLink(queryString));
                    dispatch(insertAnonymousActivity({
                        PageName: 'Onboarding',
                        Message: 'Opened Onboarding',
                        PlayerId: result.playerId,
                        PageType: PageType.PlayerOnboarding
                    }))
                } else {
                    const res = await AgencyPlayerOnboardingService.getAccessForReconfirm(token)

                    if (res.isValid) {

                        dispatch(stateController.setState(prevState => ({ ...prevState, currentStep: PlayerOnboardingStep.PlayerAgencyRelations })))
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            playerId: res.playerId,
                            registrationStep: {
                                ...prevState.registrationStep,
                                phoneCode: res.phoneCode,
                                playerPhone: res.playerPhone
                            },
                            agencyRelationsStep: {
                                ...prevState.agencyRelationsStep,
                                agencyId: res.agencyId,
                                agencyName: res.agencyName
                            }
                        })));
                        dispatch(Actions.userActivityFromVerificationLink(queryString));
                        dispatch(insertAnonymousActivity({
                            PageName: 'Onboarding',
                            Message: 'Opened Onboarding',
                            PlayerId: res.playerId,
                            PageType: PageType.PlayerOnboarding
                        }))
                    } else {
                        dispatch(stateController.setState({ currentStep: PlayerOnboardingStep.InvalidInvitation }))
                    }
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isInitializing: false }))
            }
        }
    }

    public static userActivityFromVerificationLink(queryString?: string) {
        return async (dispatch, getState: () => AppState) => {
            if (queryString) {
                const playerId = new URLSearchParams(queryString).get('playerId')
                const agencyId = new URLSearchParams(queryString).get('agencyId')
                const source = new URLSearchParams(queryString).get('source')
                const messageType = new URLSearchParams(queryString).get('mt')

                if (parseInt(messageType) == 13) {
                    dispatch(insertAnonymousActivity({
                        Message: 'Opened Registration Link',
                        PageName: source == "sms" ? 'Deal Confirmation SMS' : 'Deal Confirmation Email',
                        PlayerId: parseInt(playerId),
                        AgencyId: parseInt(agencyId),
                    }))
                }
            } else return
        }
    }

    public static registerAndLogin() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().player.onboarding

            const isEmailValid = validator.isValidEmail(substate.registrationStep.email)
            if (!isEmailValid) {
                return dispatch(stateController.setState(prevState => ({
                    ...prevState, registrationStep: { ...prevState.registrationStep, emailError: !isEmailValid }
                })))
            }

            const { token } = substate
            const { email, password, emailError, isAgreed, passwordError, retypePasswordError } = substate.registrationStep

            if (!emailError && !passwordError && !retypePasswordError && isAgreed) {
                try {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: true
                        }
                    })))

                    const data = await AgencyPlayerOnboardingService.signupAndLogin({ email, password, invitationUid: token });

                    if (data.isSuccess) {
                        const authData = data.auth;
                        dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: authData } });
                        historyAccessor.push(playerPaths.homePage);
                    } else {
                        dispatch(Actions.goToStep(PlayerOnboardingStep.InvalidEmail));
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: false
                        }
                    })))
                }
            } else {
                return
            }
        }
    }

    public static register() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().player.onboarding

            const isEmailValid = validator.isValidEmail(substate.registrationStep.email);
            if (!isEmailValid) {
                return dispatch(stateController.setState(prevState => ({
                    ...prevState, registrationStep: { ...prevState.registrationStep, emailError: !isEmailValid }
                })))
            }

            const { token } = substate
            const {
                email, password, emailError, isAgreed, passwordError, retypePasswordError
            } = substate.registrationStep

            if (!emailError && !passwordError && !retypePasswordError && isAgreed) {
                try {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: true
                        }
                    })))

                    const data = await AgencyPlayerOnboardingService.signup({
                        email, password, invitationUid: token
                    })

                    if (data.isSuccess) {
                        dispatch(Actions.goToStep(PlayerOnboardingStep.PlayerAgencyRelations))

                        dispatch(insertAnonymousActivity({
                            PageName: 'Onboarding',
                            Message: 'Created Account',
                            PlayerId: substate.playerId,
                            PageType: PageType.PlayerOnboarding
                        }))
                    } else {
                        dispatch(Actions.goToStep(PlayerOnboardingStep.InvalidEmail))

                        dispatch(insertAnonymousActivity({
                            PageName: 'Onboarding',
                            Message: 'Account is Blocked',
                            PlayerId: substate.playerId,
                            PageType: PageType.PlayerOnboarding
                        }))
                    }

                } catch (error) {
                    console.error(error)
                } finally {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: false
                        }
                    })))
                }

            } else {
                return
            }
        }
    }

    public static goToStep = (step: PlayerOnboardingStep) => (dispatch, getState: () => AppState) => {
        dispatch(stateController.setState({ currentStep: step }))
    }

    public static openTermsOfServiceModal = () => (dispatch, getState: () => AppState) => {
        dispatch(modalOpen({
            id: PLAYER_TERMS_OF_SERVICE,
            content: {
                closeModal: () => dispatch(Actions.closeTermsOfServiceModal)
            }
        }))
        dispatch(insertAnonymousActivity({
            PageName: 'Onboarding',
            Message: 'Opened Terms of Service',
            PlayerId: getState().player.onboarding.playerId,
            PageType: PageType.PlayerOnboarding
        }))
    }
    public static closeTermsOfServiceModal = () => (dispatch) => {
        dispatch(modalClose(PLAYER_TERMS_OF_SERVICE))
    }


    public static validateEmail(email: string) {
        const isEmailValid = validator.isValidEmail(email)
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, registrationStep: { ...prevState.registrationStep, emailError: !isEmailValid }
            })))
        }
    }
    public static onEmailChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    email: value,
                    emailError: false
                }
            })))
        }
    }
    public static onPasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            // dispatch(LoginStepActions.clearErrors())

            let substate = getState().player.onboarding;

            const rePassword = substate.registrationStep.retypePassword;

            const validation = PasswordValidation.validate(value)
            const passwordError = !validation.isSuccess
            let retypePasswordError = PasswordValidation.validateConfirmPassword(value, rePassword);
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    password: value,
                    passwordError,
                    retypePasswordError,
                    passwordRules: validation.rules,
                }
            })));
        }
    }
    public static onRetypePasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const password = getState().player.onboarding.registrationStep.password
            let retypePasswordError = PasswordValidation.validateConfirmPassword(password, value);
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    retypePassword: value,
                    retypePasswordError: retypePasswordError
                }
            })))
        }
    }
    public static onCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    isAgreed: isChecked
                }
            })))
        }
    }

    public static setAgencyRelation = (value: boolean | null) => (dispatch, getState: () => AppState) => {
        dispatch(stateController.setState(prevState => ({
            ...prevState,
            agencyRelationsStep: {
                ...prevState.agencyRelationsStep,
                isRelationConfirmed: value
            }
        })))
    }

    public static setEndorsement = (value: boolean) => (dispatch, getState: () => AppState) => {
        dispatch(stateController.setState(prevState => ({
            ...prevState,
            agencyRelationsStep: {
                ...prevState.agencyRelationsStep,
                shouldAgentBeEndorced: value
            }
        })))
    }
    public static submitAgencyRelation() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().player.onboarding
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyRelationsStep: {
                        ...prevState.agencyRelationsStep,
                        isSubmitProcessing: true
                    }
                })))
                const authData = await AgencyPlayerOnboardingService.submitAgent({
                    endorseAgent: substate.agencyRelationsStep.shouldAgentBeEndorced,
                    invitationUid: substate.token,
                    isAgentConfirmed: substate.agencyRelationsStep.isRelationConfirmed
                })
                dispatch(insertAnonymousActivity({
                    PageName: 'Agent confirmation',
                    Message: substate.agencyRelationsStep.isRelationConfirmed
                        ? `Confirmed${substate.agencyRelationsStep.shouldAgentBeEndorced ? ' + recommend' : ''}`
                        : 'Not My Agent',
                    PlayerId: substate.playerId,
                    AgencyId: substate.agencyRelationsStep.agencyId,
                    PageType: PageType.AgentToPlayerRequest
                }))
                dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: authData } })
                historyAccessor.push(playerPaths.myMessages)

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyRelationsStep: {
                        ...prevState.agencyRelationsStep,
                        isSubmitProcessing: false
                    }
                })))
            }
        }
    }

}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerOnboardingState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};