import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';

const initialState = {
    items: [],
    transferOutAmount: null,
    transferInAmount: null,
    balanceAmount: null,
};

export default (transferOver = initialState, action) => {
    switch (action.type) {
        case TRANSFER_OVERVIEW_FETCH.SUCCESS: {
            return {
                ...transferOver, ...action.payload.data,
            };
        }
        case TRANSFER_OVERVIEW_FETCH.REQUEST:
            return { ...transferOver };

        default:
            return transferOver;
    }
};


export const TRANSFER_OVERVIEW_FETCH = asyncActionType('app/financial-insights/TRANSFER_OVERVIEW_FETCH');

export const getTransferOverviewIsFetching = state =>
    getRequests(state).some(r => r.type === TRANSFER_OVERVIEW_FETCH);


