export const benefits = [
    'Up to 38 one-to-one meetings',
    'Real-time intelligence on squads',
    'Supercharge transfer talks'
]

export const agentBenefits = [
    'Up to 38 one-to-one meetings',
    'Real-time intelligence on squads',
    'Supercharge transfer talks'
]