import { combineReducers } from 'redux';
import { asyncActionType } from 'store/actionTypes';
import { getRequests } from "store/requests/requestsReducer"

import plusPitchPage from './pageReducer';
import plusPitchData from './plusPitchReducer';
import plusPitchReactions from './reactionsReducer';

export const PLUS_PITCHES_FETCH = asyncActionType('app/plusPitch/FETCH');
export const PLUS_PITCH_REACTIONS_FETCH = asyncActionType('app/plusPitchReactions/FETCH');
export const TEST_INTEREST = asyncActionType('app/plusPitchReactions/TEST_INTEREST');
export const TEST_INTEREST_BATCH = asyncActionType('app/plusPitchReactions/TEST_INTEREST_BATCH');

export default combineReducers({ plusPitchPage, plusPitchData, plusPitchReactions });

export const getLoadingStatus = state => getRequests(state).some(r => r.type === PLUS_PITCHES_FETCH);
export const getModalIsTesting = state => getRequests(state).some(r => r.type === TEST_INTEREST_BATCH);
export const getTotalPitches = (state) => {
    let totalSquadsPitched = 0;
    let totalPitchesInterested = 0;
    let totalPitchesOpened = 0;
    if (state.plusPitch.plusPitchData.ids) {
        state.plusPitch.plusPitchData.ids.map(i => {
            let a = state.plusPitch.plusPitchData.byId[i];
            totalSquadsPitched += a.totalSquadsWerePitched;
            totalPitchesInterested += a.pitchesWereInterestedCount;
            totalPitchesOpened += a.pitchesWereOpenedCount;
            return i;
        });
    }
    return { totalSquadsPitched, totalPitchesInterested, totalPitchesOpened }
}