import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import MySquadMatchMakerService from 'api/my-squad/my-squad-match-maker.service'
import MySquadPlayer from 'api/my-squad/model/my-squad-player'
import { notificationCreate } from 'app/notifications/notifications.actions';
import { parseAndTranslate } from 'services/localization';
import { ClubAdModel, ClubAdsModel } from 'api/my-squad/model/match-maker.models'
import { Actions as MySquadActions } from './my-squad.controller'
import { currencyById } from 'constants/currency';
import { PlayerAdType } from 'api/player-ad-v2/models/player-ad-types';

class MySquadMatchMakerState {
    player: MySquadPlayer;
    selectedClubAds: Array<number>;
    clubAds: Array<ClubAdModel>;
    isLoading: boolean;
    currency: string

    saleCostsMaxValue: number
    loanCostsMaxValue: number

    filterToSale: boolean;
    filterToLoan: boolean;

    filterLoanMin: number;
    filterLoanMax: number;
    filterSaleMin: number;
    filterSaleMax: number;

    filterCriteriaLoanMin: number;
    filterCriteriaLoanMax: number;

    filterCriteriaSaleMin: number;
    filterCriteriaSaleMax: number;

    selectedAds: Array<number>;
    processing: boolean;
}

const defaultState: MySquadMatchMakerState = {
    player: null,
    selectedClubAds: [],
    clubAds: [],
    isLoading: false,
    filterToSale: true,
    filterToLoan: true,
    currency: '',

    saleCostsMaxValue: 0,
    loanCostsMaxValue: 0,

    filterLoanMin: 0,
    filterLoanMax: 0,

    filterSaleMin: 0,
    filterSaleMax: 0,

    filterCriteriaLoanMin: 0,
    filterCriteriaLoanMax: 0,

    filterCriteriaSaleMin: 0,
    filterCriteriaSaleMax: 0,

    selectedAds: [],

    processing: false
}

const stateController = new StateController<MySquadMatchMakerState>(
    "MYSQUAD_V2/MATCH_MAKER",
    defaultState
);

class Actions {

    public static selectAd(adId: number): any {
        return (dispatch, getState: () => AppState) => {

            const substate = Selectors.getRoot(getState());
            let ads = substate.selectedAds;
            if (ads.includes(adId)) {
                ads = [...ads.filter(x => x != adId)];
            }
            else {
                ads = [...ads, adId];
            }

            dispatch(stateController.setState({ selectedAds: ads }))
        }
    }
    public static save(): any {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState({ processing: true }))

            const selectedAdsIds = substate.selectedAds;
            const clubAds = substate.clubAds;

            const selectedClubAds = clubAds.filter(
                ad => selectedAdsIds.indexOf(ad.playerAdId) > -1,
            );

            let request = {
                clubAdIds: selectedClubAds.map(ad => ad.playerAdId),
                transferTypeIds: selectedClubAds.map(
                    ad =>
                        ad.transferType === 'loan' || ad.transferType === 'Loan'
                            ? 1
                            : 2,
                ),
            }

            await MySquadMatchMakerService.pitchPlayerToAds(state.auth.userId, state.auth.squadId, substate.player.id, request);
            let successMessage =  `<b>${substate.player.shortName}</b> [%notifications.hasBeenPitched%]`
            dispatch(notificationCreate({message: parseAndTranslate(successMessage), level: 'success'}))

            dispatch(MySquadActions.matchMakerUpdate(substate.player.id, selectedAdsIds));
            
            dispatch(Actions.dispose());
        }
    }

    public static clearTransferFee() {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState(
                {
                    filterSaleMin: substate.filterCriteriaSaleMin,
                    filterSaleMax: substate.filterCriteriaSaleMax
                }
            ));
        }
    }

    public static clearLoanFee() {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState(
                {
                    filterLoanMin: substate.filterCriteriaLoanMin,
                    filterLoanMax: substate.filterCriteriaLoanMax
                }
            ));
        }
    }

    public static clearAvailability() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                {
                    filterToLoan: true,
                    filterToSale: true
                }
            ));
        }
    }

    public static setClubAds(player: MySquadPlayer, clubAds: ClubAdsModel) {
        return async (dispatch, getState: () => AppState) => {
            let result = clubAds
            if (result.items.length == 0) return

            const currencyId = getState().auth.currency.id;
            const currency = currencyById[currencyId];

            const saleCosts = result.items.filter(item => item.transferTypeId === PlayerAdType.Sale).map(item => item.convertedToUserCurrencyCost)
            const loanCosts = result.items.filter(item => item.transferTypeId === PlayerAdType.Loan).map(item => item.convertedToUserCurrencyCost)
            const saleCostsMaxValue = Math.ceil( Math.max(...saleCosts) / 1000 ) * 1000
            const loanCostsMaxValue = Math.ceil( Math.max(...loanCosts) / 1000 ) * 1000

            dispatch(stateController.setState(
                {
                    isLoading: false,
                    clubAds: result.items,
                    player: player,
                    currency: currency,
                    saleCostsMaxValue: saleCostsMaxValue,
                    loanCostsMaxValue: loanCostsMaxValue,
                    filterCriteriaLoanMax: loanCostsMaxValue,
                    filterLoanMax: loanCostsMaxValue,
                    filterCriteriaLoanMin: 0,
                    filterLoanMin: 0,
                    filterCriteriaSaleMax: saleCostsMaxValue,
                    filterSaleMax: saleCostsMaxValue,
                    filterCriteriaSaleMin: 0,
                    filterSaleMin: 0,
                    filterToLoan: loanCostsMaxValue > -1,
                    filterToSale: saleCostsMaxValue > -1,
                }
            ));
        }
    }

    public static onSetToBuyToLoan(toBuy: boolean, toLoan: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                {
                    filterToLoan: toLoan,
                    filterToSale: toBuy
                }
            ));
        }
    }

    public static onSetTransferLoanFee(min: number, max: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                {
                    filterLoanMin: min,
                    filterLoanMax: max
                }
            ));
        }
    }

    public static onSetTransferSaleFee(min: number, max: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                {
                    filterSaleMin: min,
                    filterSaleMax: max
                }
            ));
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.mySquadV2.matchmaker;

    public static isOneValueIsMissing(state: AppState) {
        const subState = Selectors.getRoot(state);
        const saleCostsMaxValue = subState.saleCostsMaxValue
        const loanCostsMaxValue = subState.loanCostsMaxValue
        if ( saleCostsMaxValue < 0 || loanCostsMaxValue < 0 ) {
            return true
        } else {
            return false
        }
    }

    public static getComputedAdList = (subState: MySquadMatchMakerState) => {
        var ads = [];
        for (var item of subState.clubAds) {

            if (item.transferTypeId === PlayerAdType.Sale) {
                if (subState.filterToSale && item.convertedToUserCurrencyCost >= subState.filterSaleMin && item.convertedToUserCurrencyCost <= subState.filterSaleMax) {
                    ads.push(item);
                }
            }
            else if (item.transferTypeId === PlayerAdType.Loan) {
                if (subState.filterToLoan && item.convertedToUserCurrencyCost >= subState.filterLoanMin && item.convertedToUserCurrencyCost <= subState.filterLoanMax) {
                    ads.push(item);
                }
            }
        }

        return ads;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MySquadMatchMakerState as State,
    Selectors as Selectors,
    Actions as Actions,
    stateController as Controller
};



