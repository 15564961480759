import { StateController } from 'utils/action-declaration'
import {AppState} from 'root.reducer';
import { Actions as PlayerSearchGridActions } from "pages/PlayerSearch-v3/squad/redux/grid.controller";
import historyAccessor from 'history-accessor';
import { userPaths, agencyPaths } from 'routes/paths';
import userActivityInsert from "../../../app/user-activity/actions/user-activity.actions";
import { CoachProfileService } from 'api/coach-profile/coach-profile.service';
import { isAgencyUser } from "store/auth/authReducer";
import AgencyHeadCoachesService from 'api/agency/agent/head-coaches/head-coaches.service';
import { PageType } from 'constants/enums';

export enum ConfirmSteps {
	StartScreen,
	ThankYouScreen,
}

class FindHeadCoachPopupState {
	step: ConfirmSteps;
	isFindHeadCoachVisible: boolean;
	currentPageType: PageType;
}

const defaultState: FindHeadCoachPopupState = {
	step: ConfirmSteps.StartScreen,
	isFindHeadCoachVisible: false,
	currentPageType: PageType.LandingPage
}

const stateController = new StateController<FindHeadCoachPopupState>(
	"LANDING_PAGE/FIND_COACH_POPUP",
	defaultState
);

class Actions {
		
	public static setStep(step: ConfirmSteps) {
		return (dispatch) => {			
			dispatch(stateController.setState({ step: step }));
		}
	}
	
	public static onInterested() {
		return (dispatch, getState: () => AppState) => {
            const isUserAgency = isAgencyUser(getState());
			
			if (isUserAgency) {
				AgencyHeadCoachesService.showAgencyInterestToCoachProfile()
			} else {
				CoachProfileService.showInterestToCoachProfile()
			}

			dispatch(Actions.setStep(ConfirmSteps.ThankYouScreen));
		}
	}

	public static openModal(pageType?: PageType, pageName?: string) {
		return (dispatch) => {			
			dispatch(stateController.setState(prevState =>({
				...prevState,
				isFindHeadCoachVisible: true,
			})))
			if (!!pageType) {				
				dispatch(stateController.setState(prevState =>({
					...prevState,
					currentPageType: pageType,
				})));

				dispatch(userActivityInsert({
					PageName: pageName ? pageName : 'Search Bar',
					Message: 'Clicked Find Out More',
					PageType: pageType ? pageType : PageType.Search,
				}));
			}
		}
	}

	public static openExploreHeadCoaches() {
		return (dispatch, getState: () => AppState) => {
			const isUserAgency = isAgencyUser(getState());

			if (isUserAgency) {
				historyAccessor.push(agencyPaths.playerSearch)
				
			} else {
				historyAccessor.push(userPaths.playerSearch)
				dispatch(PlayerSearchGridActions.onSearchHeadCoaches());
			}
		}
	}
	
	public static sendUserActivity(message: string, pageName: string, pageType?: number ) {
		return (dispatch, getState: () => AppState) => {
			dispatch(userActivityInsert({
				PageName: pageName,
				Message: message,
				PageType: pageType
			}))
		}
	}

	public static closeModal() {
		return (dispatch) => {
			dispatch(stateController.setState(prevState =>({
				...prevState,
				isFindHeadCoachVisible: false,
				currentPageType: PageType.LandingPage,
			})));
			dispatch(Actions.setStep(ConfirmSteps.StartScreen));
		}
	}
}


class Selectors {
	public static getRoot = (state: AppState): FindHeadCoachPopupState => state.landingPage.headCoachPopup;
	public static getPopupVisibility = (state: AppState) => Selectors.getRoot(state).isFindHeadCoachVisible;
	public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
	public static getPageType = (state: AppState) => Selectors.getRoot(state).currentPageType;
}

const reducer = stateController.getReducer();

export {
	reducer as Reducer,
	FindHeadCoachPopupState as State,
	Selectors as Selectors,
	Actions as Actions,
	stateController as Controller
};


