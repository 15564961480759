import axios from 'axios-config';
import {
    PersonalAccountEmailConfirmationAccessResponse, 
    PersonalAccountEmailConfirmationResponse, 
    PersonalAccountOnboardingAccessResponse, 
    PersonalAccountSignupRequest, 
    PersonalAccountSignupResponse, 
    SwitchAccountResponse 
} from './model';
import { CommonListResponse } from 'api/core/common-list-response';

export class StaffAccountService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async switchAccount(): Promise<SwitchAccountResponse> {
        const { data } = await axios.post(
            `api/StaffAccount/SwitchAccount`,
            null,
            this.axiosConfig
        )
        return data;
    }

    public static async getOnboardingAccess(): Promise<PersonalAccountOnboardingAccessResponse> {
        const { data } = await axios.get(
            'api/StaffAccount/GetOnboardingAccess',
            this.axiosConfig
        );
        return data;
    }
    
    public static async signup(payload: PersonalAccountSignupRequest): Promise<PersonalAccountSignupResponse> {
        const { data } = await axios.post(
            `api/StaffAccount/Signup`,
            payload,
            this.axiosConfig
        )
        return data;
    }

    public static async getEmailConfirmationAccess(): Promise<PersonalAccountEmailConfirmationAccessResponse> {
        const { data } = await axios.get(
            'api/StaffAccount/GetEmailConfirmationAccess',
            this.axiosConfig
        );
        return data;
    }

    public static async sendConfirmEmail(): Promise<PersonalAccountSignupResponse> {
        const { data } = await axios.post(
            `api/StaffAccount/SendConfirmEmail`,
            null,
            this.axiosConfig
        )
        return data;
    }

    public static async confirmEmailVerification(uid: string): Promise<PersonalAccountEmailConfirmationResponse> {
        const { data } = await axios.post(
            `api/StaffAccount/ConfirmEmailVerify/${uid}`,
            null,
            this.axiosConfig
        )
        return data;
    }
}