export const eventClubs = [
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Manchester City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 33906,
    squadName: "FC Barcelona",
    shortSquadName: "FC Barcelona",
    squadLogo: null // "https://transferroom.blob.core.windows.net/clubs/logo_FC_Barcelona.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Manchester United",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
  },
  {
    squadId: 38553,
    squadName: "Aston Villa",
    shortSquadName: "Aston Villa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "Bayer 04 Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 1,
    squadName: "AC Milan",
    shortSquadName: "AC Milan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1834_AC_Milan.png"
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolverhampton Wanderers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton & Hove Albion",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 23352,
    squadName: "Stade Rennais FC",
    shortSquadName: "Stade Rennais FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Stade_Rennes.png"
  },
  {
    squadId: 12294,
    squadName: "Olympique Marseille",
    shortSquadName: "Olympique Marseille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "Eintracht Frankfurt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_VfL_Wolfsburg.png"
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 27251,
    squadName: "Clube de Regatas do Flamengo",
    shortSquadName: "Clube de Regatas do Flamengo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord Rotterdam",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 17175,
    squadName: "Norwich City",
    shortSquadName: "Norwich City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1144_Norwich_City.png"
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Sociedade Esportiva Palmeiras",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 31172,
    squadName: "Montpellier HSC",
    shortSquadName: "Montpellier HSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3787_Montpellier.png"
  },
  {
    squadId: 4230,
    squadName: "Stade Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "RC Strasbourg Alsace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
  },
  {
    squadId: 7083,
    squadName: "Elche CF",
    shortSquadName: "Elche CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1793_Elche_CF.png"
  },
  {
    squadId: 23168,
    squadName: "1. FC Koln",
    shortSquadName: "1. FC Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
  },
  {
    squadId: 25629,
    squadName: "RSC Anderlecht",
    shortSquadName: "RSC Anderlecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_RSC_Anderlecht.png"
  },
  {
    squadId: 22514,
    squadName: "CF America",
    shortSquadName: "CF America",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15405_América.png"
  },
  {
    squadId: 21139,
    squadName: "Olympiacos Piraeus",
    shortSquadName: "Olympiacos Piraeus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png"
  },
  {
    squadId: 30849,
    squadName: "Atlanta United FC",
    shortSquadName: "Atlanta United FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
  },
  {
    squadId: 19138,
    squadName: "RCD Mallorca",
    shortSquadName: "RCD Mallorca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/686_RCD_Mallorca.png"
  },
  {
    squadId: 22788,
    squadName: "Red Bull Bragantino",
    shortSquadName: "Red Bull Bragantino",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5685_Red_Bull_Bragantino.png"
  },
  {
    squadId: 7853,
    squadName: "Toronto FC",
    shortSquadName: "Toronto FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2102_Toronto_FC.png"
  },
  {
    squadId: 25285,
    squadName: "Levante UD",
    shortSquadName: "Levante UD",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Levante_UD.png"
  },
  {
    squadId: 39981,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Clube Atletico Mineiro",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5653_Atlético-MG.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Club Leon FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
  },
  {
    squadId: 26367,
    squadName: "Santos Laguna",
    shortSquadName: "Santos Laguna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "SV Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1735_Real_Valladolid_CF.png"
  },
  {
    squadId: 25911,
    squadName: "Royal Antwerp FC",
    shortSquadName: "Royal Antwerp FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
  },
  {
    squadId: 9657,
    squadName: "Los Angeles FC",
    shortSquadName: "Los Angeles FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/62148_Los_Angeles_FC.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 33797,
    squadName: "Spezia Calcio",
    shortSquadName: "Spezia Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1860_Spezia_Calcio.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Club Athletico Paranaense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 19206,
    squadName: "Santos FC",
    shortSquadName: "Santos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
  },
  {
    squadId: 41692,
    squadName: "Seattle Sounders FC",
    shortSquadName: "Seattle Sounders FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2106_Seattle_Sounders_FC.png"
  },
  {
    squadId: 7898,
    squadName: "Preston North End",
    shortSquadName: "Preston North End",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1161_Preston_North_End.png"
  },
  {
    squadId: 40498,
    squadName: "Swansea City",
    shortSquadName: "Swansea City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1131_Swansea_City.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa CFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 1535,
    squadName: "FC Zurich",
    shortSquadName: "FC Zurich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6412_FC_Zürich.png"
  },
  {
    squadId: 7400,
    squadName: "Botafogo de Futebol e Regatas",
    shortSquadName: "Botafogo de Futebol e Regatas",
    squadLogo: null
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "SK Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2051_SK_Slavia_Prag.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Sport Club Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Thessaloniki",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 26056,
    squadName: "AeK Athens",
    shortSquadName: "AeK Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
  },
  {
    squadId: 26540,
    squadName: "Orlando City SC",
    shortSquadName: "Orlando City SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver Whitecaps FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio Foot-Ball Porto Alegrense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
  },
  {
    squadId: 10158,
    squadName: "FC Toulouse",
    shortSquadName: "FC Toulouse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png"
  },
  {
    squadId: 40692,
    squadName: "Columbus Crew",
    shortSquadName: "Columbus Crew",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2116_Columbus_Crew_SC.png"
  },
  {
    squadId: 9385,
    squadName: "Sporting Kansas City",
    shortSquadName: "Sporting Kansas City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7849_Sporting_Kansas_City.png"
  },
  {
    squadId: 10026,
    squadName: "Los Angeles Galaxy",
    shortSquadName: "Los Angeles Galaxy",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
  },
  {
    squadId: 24695,
    squadName: "Royale Union Saint Gilloise",
    shortSquadName: "Royale Union Saint Gilloise",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1590_Royale_Union_Saint_Gilloise.png"
  },
  {
    squadId: 27650,
    squadName: "FC Juarez",
    shortSquadName: "FC Juarez",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34402_FC_Juárez.png"
  },
  {
    squadId: 32704,
    squadName: "UNAM Pumas",
    shortSquadName: "UNAM Pumas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
  },
  {
    squadId: 17017,
    squadName: "Philadelphia Union",
    shortSquadName: "Philadelphia Union",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png"
  },
  {
    squadId: 20133,
    squadName: "Chicago Fire FC",
    shortSquadName: "Chicago Fire FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7848_Chicago_Fire_FC.png"
  },
  {
    squadId: 39648,
    squadName: "New England Revolution",
    shortSquadName: "New England Revolution",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2111_New_England_Revolution.png"
  },
  {
    squadId: 330,
    squadName: "SD Eibar",
    shortSquadName: "SD Eibar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1724_SD_Eibar.png"
  },
  {
    squadId: 2179,
    squadName: "Club Tijuana",
    shortSquadName: "Club Tijuana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15459_Club_Tijuana.png"
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "AC Sparta Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
  },
  {
    squadId: 100007,
    squadName: "Austin FC",
    shortSquadName: "Austin FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-72309_Austin_FC.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "HNK Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.png"
  },
  {
    squadId: 32865,
    squadName: "Portland Timbers",
    shortSquadName: "Portland Timbers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8113_Portland_Timbers.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Inter Miami CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 25436,
    squadName: "Nashville SC",
    shortSquadName: "Nashville SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente Enschede FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
  },
  {
    squadId: 174,
    squadName: "Club Atletico Talleres",
    shortSquadName: "Club Atletico Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 5121,
    squadName: "Minnesota United FC",
    shortSquadName: "Minnesota United FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2122_Minnesota_United_FC.png"
  },
  {
    squadId: 15821,
    squadName: "Arminia Bielefeld",
    shortSquadName: "Arminia Bielefeld",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
  },
  {
    squadId: 5972,
    squadName: "San Jose Earthquakes",
    shortSquadName: "San Jose Earthquakes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
  },
  {
    squadId: 11209,
    squadName: "Bristol City",
    shortSquadName: "Bristol City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1655_Bristol_City.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos Athens",
    shortSquadName: "Panathinaikos Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 10317,
    squadName: "New York Red Bulls",
    shortSquadName: "New York Red Bulls",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
  },
  {
    squadId: 35045,
    squadName: "Vitesse Arnhem",
    shortSquadName: "Vitesse Arnhem",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png"
  },
  {
    squadId: 200004,
    squadName: "Charlotte FC",
    shortSquadName: "Charlotte FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
  },
  {
    squadId: 13099,
    squadName: "CF Montreal",
    shortSquadName: "CF Montreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14969_Club_de_Foot_Montréal.png"
  },
  {
    squadId: 22011,
    squadName: "Puebla FC",
    shortSquadName: "Puebla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2282_Puebla_FC.png"
  },
  {
    squadId: 1559,
    squadName: "FC Cincinnati",
    shortSquadName: "FC Cincinnati",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
  },
  {
    squadId: 24387,
    squadName: "Luton Town",
    shortSquadName: "Luton Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Luton_Town.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 22196,
    squadName: "SpVgg Greuther Furth",
    shortSquadName: "SpVgg Greuther Furth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 2164,
    squadName: "Colorado Rapids",
    shortSquadName: "Colorado Rapids",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7853_Colorado_Rapids.png"
  },
  {
    squadId: 23647,
    squadName: "Atletico de San Luis",
    shortSquadName: "Atletico de San Luis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
  },
  {
    squadId: 4178,
    squadName: "Servette FC",
    shortSquadName: "Servette FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2355_Servette_FC.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "Oud-Heverlee Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1587_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow Czestochowa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
  },
  {
    squadId: 3774,
    squadName: "Club Necaxa",
    shortSquadName: "Club Necaxa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2283_Club_Necaxa.png"
  },
  {
    squadId: 14958,
    squadName: "Coventry City",
    shortSquadName: "Coventry City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1188_Coventry_City.png"
  },
  {
    squadId: 28943,
    squadName: "CA Banfield",
    shortSquadName: "CA Banfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12056_Banfield.png"
  },
  {
    squadId: 5159,
    squadName: "FC Groningen",
    shortSquadName: "FC Groningen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Groningen.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston Dynamo FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 10999,
    squadName: "AA Argentinos Juniors",
    shortSquadName: "AA Argentinos Juniors",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12075_Asociación_Atlética_Argentinos_Juniors.png"
  },
  {
    squadId: 7633,
    squadName: "Real Salt Lake City",
    shortSquadName: "Real Salt Lake City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2117_Real_Salt_Lake_City.png"
  },
  {
    squadId: 3228,
    squadName: "Real Oviedo",
    shortSquadName: "Real Oviedo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/789_Real_Oviedo.png"
  },
  {
    squadId: 30436,
    squadName: "Dijon FCO",
    shortSquadName: "Dijon FCO",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1976_FCO_Dijon.png"
  },
  {
    squadId: 13114,
    squadName: "Real Zaragoza",
    shortSquadName: "Real Zaragoza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/689_Real_Zaragoza.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "FC Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 39337,
    squadName: "SK Dnipro-1",
    shortSquadName: "SK Dnipro-1",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png"
  },
  {
    squadId: 200001,
    squadName: "Mazatlan FC",
    shortSquadName: "Mazatlan FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 32920,
    squadName: "Malaga CF",
    shortSquadName: "Malaga CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Malaga_CF.png"
  },
  {
    squadId: 12612,
    squadName: "Hammarby IF",
    shortSquadName: "Hammarby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6710_Hammarby_IF.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atletico Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "SV Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 24973,
    squadName: "Queretaro FC",
    shortSquadName: "Queretaro FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15421_Querétaro_FC.png"
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
  },
  {
    squadId: 12359,
    squadName: "Fortuna Sittard",
    shortSquadName: "Fortuna Sittard",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/44_Fortuna_Sittard.png"
  },
  {
    squadId: 496,
    squadName: "HNK Rijeka",
    shortSquadName: "HNK Rijeka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png"
  },
  {
    squadId: 7783,
    squadName: "Pyramids FC",
    shortSquadName: "Pyramids FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/27533_Pyramids_Al_Ahram_FC.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 37475,
    squadName: "Mamelodi Sundowns FC",
    shortSquadName: "Mamelodi Sundowns FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn 07",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid 1923",
    shortSquadName: "FC Rapid 1923",
    squadLogo: null
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 13175,
    squadName: "Atletico Clube Goianiense",
    shortSquadName: "Atletico Clube Goianiense",
    squadLogo: null
  },
  {
    squadId: 7594,
    squadName: "Ceara Sporting Club",
    shortSquadName: "Ceara Sporting Club",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2620_Ceara_Sporting_Club.png"
  },
  {
    squadId: 9566,
    squadName: "HNK Gorica",
    shortSquadName: "HNK Gorica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9557_HNK_Gorica.png"
  },
  {
    squadId: 35023,
    squadName: "Blackpool FC",
    shortSquadName: "Blackpool FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1180_Blackpool_FC.png"
  },
  {
    squadId: 24448,
    squadName: "Aarhus GF",
    shortSquadName: "Aarhus GF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1410_Aarhus_GF.png"
  },
  {
    squadId: 22352,
    squadName: "Cruzeiro Esporte Clube",
    shortSquadName: "Cruzeiro Esporte Clube",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5640_Cruzeiro_Esporte_Clube.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2178_Pafos_FC.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "BK Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 12098,
    squadName: "CD Santa Clara",
    shortSquadName: "CD Santa Clara",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9634_CD_Santa_Clara.png"
  },
  {
    squadId: 20772,
    squadName: "FC Pacos de Ferreira",
    shortSquadName: "FC Pacos de Ferreira",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Pacos_Ferreira.png"
  },
  {
    squadId: 36784,
    squadName: "Lechia Gdansk",
    shortSquadName: "Lechia Gdansk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1596_Lechia_Gdansk.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "FC Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 13923,
    squadName: "CS Emelec",
    shortSquadName: "CS Emelec",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2708_CS_Emelec.png"
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Club Sporting Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 17043,
    squadName: "FC Tokyo",
    shortSquadName: "FC Tokyo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2416_FC_Tokyo.png"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 17418,
    squadName: "Valerenga Fotball",
    shortSquadName: "Valerenga Fotball",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png"
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 6098,
    squadName: "Kalmar FF",
    shortSquadName: "Kalmar FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kalmar_FF.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 29306,
    squadName: "Mineros de Zacatecas",
    shortSquadName: "Mineros de Zacatecas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png"
  },
  {
    squadId: 8498,
    squadName: "Odense Boldklub",
    shortSquadName: "Odense Boldklub",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1412_Odense_Boldklub.png"
  },
  {
    squadId: 31136,
    squadName: "Heracles Almelo",
    shortSquadName: "Heracles Almelo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1542_Heracles_Almelo.png"
  },
  {
    squadId: 11890,
    squadName: "Correcaminos de la UAT",
    shortSquadName: "Correcaminos de la UAT",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15440_Correcaminos_de_la_UAT.png"
  },
  {
    squadId: 33756,
    squadName: "Suwon Samsung Bluewings",
    shortSquadName: "Suwon Samsung Bluewings",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2428_Suwon_Samsung_Bluewings_FC.png"
  },
  {
    squadId: 37706,
    squadName: "Dundee United FC",
    shortSquadName: "Dundee United FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Dundee_United.png"
  },
  {
    squadId: 17005,
    squadName: "Samsunspor",
    shortSquadName: "Samsunspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4495_Samsunspor.png"
  },
  {
    squadId: 34919,
    squadName: "Al-Gharafa SC",
    shortSquadName: "Al-Gharafa SC",
    squadLogo: null
  },
  {
    squadId: 5924,
    squadName: "CD Everton",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 14994,
    squadName: "CD Leones Negros de la UdeG",
    shortSquadName: "CD Leones Negros de la UdeG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta Rotterdam",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 12725,
    squadName: "AeK Larnaca",
    shortSquadName: "AeK Larnaca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2176_AEK_Larnaca.png"
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
  },
  {
    squadId: 14930,
    squadName: "Celaya FC",
    shortSquadName: "Celaya FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png"
  },
  {
    squadId: 29155,
    squadName: "1.FC Kaiserslautern",
    shortSquadName: "1.FC Kaiserslautern",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1286_1.FC_Kaiserslautern.png"
  },
  {
    squadId: 35255,
    squadName: "CA Sarmiento (Junin)",
    shortSquadName: "CA Sarmiento (Junin)",
    squadLogo: null
  },
  {
    squadId: 12139,
    squadName: "Budapest Honved FC",
    shortSquadName: "Budapest Honved FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
  },
  {
    squadId: 9029,
    squadName: "FC Ingolstadt 04",
    shortSquadName: "FC Ingolstadt 04",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2579_FC_Ingolstadt_04.png"
  },
  {
    squadId: 15862,
    squadName: "Universitario de Deportes",
    shortSquadName: "Universitario de Deportes",
    squadLogo: null
  },
  {
    squadId: 14649,
    squadName: "Waasland-Beveren",
    shortSquadName: "Waasland-Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1579_Waasland-Beveren.png"
  },
  {
    squadId: 39603,
    squadName: "Guarani Futebol Clube (SP)",
    shortSquadName: "Guarani Futebol Clube (SP)",
    squadLogo: null
  },
  {
    squadId: 5869,
    squadName: "Viking Stavanger",
    shortSquadName: "Viking Stavanger",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7100_Viking_Stavanger.png"
  },
  {
    squadId: 8228,
    squadName: "AC Horsens",
    shortSquadName: "AC Horsens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7465_AC_Horsens.png"
  },
  {
    squadId: 10371,
    squadName: "Audax Italiano",
    shortSquadName: "Audax Italiano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2657_Audax_Italiano.png"
  },
  {
    squadId: 23752,
    squadName: "FK Haugesund",
    shortSquadName: "FK Haugesund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7114_FK_Haugesund.png"
  },
  {
    squadId: 33893,
    squadName: "FC Emmen",
    shortSquadName: "FC Emmen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Emmen.png"
  },
  {
    squadId: 36568,
    squadName: "Kolos Kovalivka",
    shortSquadName: "Kolos Kovalivka",
    squadLogo: null
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
  },
  {
    squadId: 23005,
    squadName: "Albacete Balompie",
    shortSquadName: "Albacete Balompie",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1744_Albacete_Balompie.png"
  },
  {
    squadId: 25051,
    squadName: "Wycombe Wanderers",
    shortSquadName: "Wycombe Wanderers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wycombe_Wanderers.png"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "CA Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 15268,
    squadName: "Widzew Lodz",
    shortSquadName: "Widzew Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
  },
  {
    squadId: 33362,
    squadName: "FK RFS",
    shortSquadName: "FK RFS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13201_FK_RFS.png"
  },
  {
    squadId: 11592,
    squadName: "Venados FC Yucatan",
    shortSquadName: "Venados FC Yucatan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15428_Venados_FC_Yucatán.png"
  },
  {
    squadId: 11369,
    squadName: "Rukh Lviv",
    shortSquadName: "Rukh Lviv",
    squadLogo: null
  },
  {
    squadId: 29777,
    squadName: "LR Vicenza",
    shortSquadName: "LR Vicenza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png"
  },
  {
    squadId: 17454,
    squadName: "Avai Futebol Clube (SC)",
    shortSquadName: "Avai Futebol Clube (SC)",
    squadLogo: null
  },
  {
    squadId: 20703,
    squadName: "Club Cienciano",
    shortSquadName: "Club Cienciano",
    squadLogo: null
  },
  {
    squadId: 27136,
    squadName: "Kisvarda FC",
    shortSquadName: "Kisvarda FC",
    squadLogo: null
  },
  {
    squadId: 41956,
    squadName: "Deportivo Cali",
    shortSquadName: "Deportivo Cali",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png"
  },
  {
    squadId: 31328,
    squadName: "Sabah FK",
    shortSquadName: "Sabah FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png"
  },
  {
    squadId: 36502,
    squadName: "Dorados de Sinaloa",
    shortSquadName: "Dorados de Sinaloa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15465_Dorados_de_Sinaloa.png"
  },
  {
    squadId: 40401,
    squadName: "Clube de Regatas Brasil (AL)",
    shortSquadName: "Clube de Regatas Brasil (AL)",
    squadLogo: null
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9918_FK_Sarajevo.png"
  },
  {
    squadId: 19616,
    squadName: "Aalesunds FK",
    shortSquadName: "Aalesunds FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png"
  },
  {
    squadId: 28372,
    squadName: "Club Atletico Morelia",
    shortSquadName: "Club Atletico Morelia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
  },
  {
    squadId: 26895,
    squadName: "Jagiellonia Bialystok",
    shortSquadName: "Jagiellonia Bialystok",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1597_Jagiellonia_Bialystok.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "FK Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "SG Dynamo Dresden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1281_SG_Dynamo_Dresden.png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior Rotterdam",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones de Sonora",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "CD O'Higgins",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 16210,
    squadName: "AeL Limassol",
    shortSquadName: "AeL Limassol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2179_AEL_Limassol.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 5572,
    squadName: "Deportivo Municipal",
    shortSquadName: "Deportivo Municipal",
    squadLogo: null
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 10781,
    squadName: "Kristiansund BK",
    shortSquadName: "Kristiansund BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1477_Kristiansund_BK.png"
  },
  {
    squadId: 200109,
    squadName: "Cancun FC",
    shortSquadName: "Cancun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
  },
  {
    squadId: 37333,
    squadName: "NK Istra 1961",
    shortSquadName: "NK Istra 1961",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2169_NK_Istra_1961.png"
  },
  {
    squadId: 39987,
    squadName: "CD Cobresal",
    shortSquadName: "CD Cobresal",
    squadLogo: null
  },
  {
    squadId: 27522,
    squadName: "Universidad Cesar Vallejo",
    shortSquadName: "Universidad Cesar Vallejo",
    squadLogo: null
  },
  {
    squadId: 26385,
    squadName: "Cesena FC",
    shortSquadName: "Cesena FC",
    squadLogo: null
  },
  {
    squadId: 19251,
    squadName: "SV Waldhof Mannheim",
    shortSquadName: "SV Waldhof Mannheim",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1370_SV_Waldhof_Mannheim.png"
  },
  {
    squadId: 30871,
    squadName: "Hamarkameratene",
    shortSquadName: "Hamarkameratene",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2381_Hamarkameratene.png"
  },
  {
    squadId: 31340,
    squadName: "Casa Pia AC",
    shortSquadName: "Casa Pia AC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9708_Casa_Pia_AC.png"
  },
  {
    squadId: 18182,
    squadName: "NK Olimpija Ljubljana",
    shortSquadName: "NK Olimpija Ljubljana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11953_Olimpija.png"
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "FK Jerv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08 FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 24701,
    squadName: "CF Atlante",
    shortSquadName: "CF Atlante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
  },
  {
    squadId: 22198,
    squadName: "TSV 1860 Munich",
    shortSquadName: "TSV 1860 Munich",
    squadLogo: null
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 17949,
    squadName: "Bourg-en-Bresse Peronnas 01",
    shortSquadName: "Bourg-en-Bresse Peronnas 01",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3824_Bourg_en_Bresse_01.png"
  },
  {
    squadId: 39088,
    squadName: "Cortulua",
    shortSquadName: "Cortulua",
    squadLogo: null
  },
  {
    squadId: 22613,
    squadName: "Kuopion Palloseura",
    shortSquadName: "Kuopion Palloseura",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1499_Kuopion_Palloseura.png"
  },
  {
    squadId: 26327,
    squadName: "Real Murcia CF",
    shortSquadName: "Real Murcia CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1813_Real_Murcia_CF.png"
  },
  {
    squadId: 14480,
    squadName: "Real Union Club",
    shortSquadName: "Real Union Club",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1786_Real_Union_Club.png"
  },
  {
    squadId: 9929,
    squadName: "Lincoln City",
    shortSquadName: "Lincoln City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1207_Lincoln_City.png"
  },
  {
    squadId: 33676,
    squadName: "Sandefjord Fotball",
    shortSquadName: "Sandefjord Fotball",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sandefjord_Fotball.png"
  },
  {
    squadId: 17923,
    squadName: "FC Eindhoven",
    shortSquadName: "FC Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Eindhoven.png"
  },
  {
    squadId: 23003,
    squadName: "Brusque Futebol Clube (SC)",
    shortSquadName: "Brusque Futebol Clube (SC)",
    squadLogo: null
  },
  {
    squadId: 3895,
    squadName: "Sumgayit PFC",
    shortSquadName: "Sumgayit PFC",
    squadLogo: null
  },
  {
    squadId: 26623,
    squadName: "Miedz Legnica",
    shortSquadName: "Miedz Legnica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1613_Miedz_Legnica.png"
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "FC Viktoria Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
  },
  {
    squadId: 1423,
    squadName: "LD Alajuelense",
    shortSquadName: "LD Alajuelense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16835_LD_Alajuelense.png"
  },
  {
    squadId: 37674,
    squadName: "FK Suduva Marijampole",
    shortSquadName: "FK Suduva Marijampole",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13406_Suduva_Marijampole.png"
  },
  {
    squadId: 26457,
    squadName: "Stabaek Fotball",
    shortSquadName: "Stabaek Fotball",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1473_Stabaek_IF.png"
  },
  {
    squadId: 38747,
    squadName: "Deportivo Tachira",
    shortSquadName: "Deportivo Tachira",
    squadLogo: null
  },
  {
    squadId: 9451,
    squadName: "Hamilton Academical FC",
    shortSquadName: "Hamilton Academical FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hamilton_Academical.png"
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 22110,
    squadName: "Le Mans FC",
    shortSquadName: "Le Mans FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3786_Le_Mans.png"
  },
  {
    squadId: 24927,
    squadName: "Adanaspor",
    shortSquadName: "Adanaspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2832_Adanaspor.png"
  },
  {
    squadId: 14512,
    squadName: "Royal Excelsior Virton",
    shortSquadName: "Royal Excelsior Virton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2366_Royal_Excelsior_Virton.png"
  },
  {
    squadId: 200193,
    squadName: "Manisa FK",
    shortSquadName: "Manisa FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/48913_Manisa_FK.png"
  },
  {
    squadId: 29646,
    squadName: "NK Domzale",
    shortSquadName: "NK Domzale",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2463_NK_Domzale.png"
  },
  {
    squadId: 200108,
    squadName: "Tepatitlan FC",
    shortSquadName: "Tepatitlan FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64345_Tepatitlán_FC.png"
  },
  {
    squadId: 9197,
    squadName: "Louisville City FC",
    shortSquadName: "Louisville City FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/32930_Louisville_City_FC.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 3704,
    squadName: "FC Hegelmann",
    shortSquadName: "FC Hegelmann",
    squadLogo: null
  },
  {
    squadId: 29198,
    squadName: "FC Den Bosch",
    shortSquadName: "FC Den Bosch",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Den_Bosch.png"
  },
  {
    squadId: 41155,
    squadName: "Raith Rovers FC",
    shortSquadName: "Raith Rovers FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2285_Raith_Rovers_FC.png"
  },
  {
    squadId: 10183,
    squadName: "Podbeskidzie Bielsko-Biala",
    shortSquadName: "Podbeskidzie Bielsko-Biala",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1610_Podbeskidzie_Bielsko-Biala.png"
  },
  {
    squadId: 25579,
    squadName: "IK Brage",
    shortSquadName: "IK Brage",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2471_IK_Brage.png"
  },
  {
    squadId: 20302,
    squadName: "Hallescher FC",
    shortSquadName: "Hallescher FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hallescher_FC.png"
  },
  {
    squadId: 42798,
    squadName: "Cavalry FC",
    shortSquadName: "Cavalry FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
  },
  {
    squadId: 22250,
    squadName: "FC Carlos Stein",
    shortSquadName: "FC Carlos Stein",
    squadLogo: null
  },
  {
    squadId: 200003,
    squadName: "Oakland Roots SC",
    shortSquadName: "Oakland Roots SC",
    squadLogo: null
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 29796,
    squadName: "Alebrijes de Oaxaca",
    shortSquadName: "Alebrijes de Oaxaca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
  },
  {
    squadId: 15052,
    squadName: "Sogndal IL",
    shortSquadName: "Sogndal IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
  },
  {
    squadId: 11083,
    squadName: "Kalju FC",
    shortSquadName: "Kalju FC",
    squadLogo: null
  },
  {
    squadId: 15542,
    squadName: "El Gouna FC",
    shortSquadName: "El Gouna FC",
    squadLogo: null
  },
  {
    squadId: 40550,
    squadName: "Rot-Weiss Essen",
    shortSquadName: "Rot-Weiss Essen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1391_Rot-Weiss_Essen.png"
  },
  {
    squadId: 30734,
    squadName: "Altinordu FK",
    shortSquadName: "Altinordu FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
  },
  {
    squadId: 9930,
    squadName: "Esbjerg fB",
    shortSquadName: "Esbjerg fB",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Esbjerg_FB.png"
  },
  {
    squadId: 8839,
    squadName: "Associacao Ferroviaria de Esportes (SP)",
    shortSquadName: "Associacao Ferroviaria de Esportes (SP)",
    squadLogo: null
  },
  {
    squadId: 3469,
    squadName: "US Pergolettese 1932",
    shortSquadName: "US Pergolettese 1932",
    squadLogo: null
  },
  {
    squadId: 18705,
    squadName: "Fredrikstad FK",
    shortSquadName: "Fredrikstad FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fredrikstad_FK.png"
  },
  {
    squadId: 4022,
    squadName: "Bengaluru FC",
    shortSquadName: "Bengaluru FC",
    squadLogo: null
  },
  {
    squadId: 15392,
    squadName: "Dalkurd FF",
    shortSquadName: "Dalkurd FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Dalkurd_FF.png"
  },
  {
    squadId: 16345,
    squadName: "Mjondalen IF",
    shortSquadName: "Mjondalen IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1486_Mjondalen_IF.png"
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
  },
  {
    squadId: 22833,
    squadName: "CD Santiago Morning",
    shortSquadName: "CD Santiago Morning",
    squadLogo: null
  },
  {
    squadId: 5795,
    squadName: "Ayr United FC",
    shortSquadName: "Ayr United FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2286_Ayr_United_FC.png"
  },
  {
    squadId: 566,
    squadName: "Odra Opole",
    shortSquadName: "Odra Opole",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1624_Odra_Opole.png"
  },
  {
    squadId: 25293,
    squadName: "IK Start",
    shortSquadName: "IK Start",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7102_IK_Start.png"
  },
  {
    squadId: 29631,
    squadName: "Landskrona BoIS",
    shortSquadName: "Landskrona BoIS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2472_Landskrona_BoIS.png"
  },
  {
    squadId: 2384,
    squadName: "Olbia Calcio 1905",
    shortSquadName: "Olbia Calcio 1905",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3304_Olbia_Calcio_1905.png"
  },
  {
    squadId: 35338,
    squadName: "Skovde AIK",
    shortSquadName: "Skovde AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6792_Skövde_AIK.png"
  },
  {
    squadId: 22202,
    squadName: "Swindon Town",
    shortSquadName: "Swindon Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Swindon_Town.png"
  },
  {
    squadId: 15241,
    squadName: "Magallanes CF",
    shortSquadName: "Magallanes CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
  },
  {
    squadId: 17479,
    squadName: "Gornik Leczna",
    shortSquadName: "Gornik Leczna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1627_Gornik_Leczna.png"
  },
  {
    squadId: 4602,
    squadName: "Universidad de Concepcion",
    shortSquadName: "Universidad de Concepcion",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12285_Universidad_Concepción.png"
  },
  {
    squadId: 4186,
    squadName: "Ermis FC Aradippou",
    shortSquadName: "Ermis FC Aradippou",
    squadLogo: null
  },
  {
    squadId: 35690,
    squadName: "Santiago Wanderers",
    shortSquadName: "Santiago Wanderers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12284_Santiago_Wanderers.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 38454,
    squadName: "Sandnes Ulf",
    shortSquadName: "Sandnes Ulf",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sandnes_Ulf.png"
  },
  {
    squadId: 42796,
    squadName: "HFX Wanderers FC",
    shortSquadName: "HFX Wanderers FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64338_HFX_Wanderers.png"
  },
  {
    squadId: 16011,
    squadName: "Stevenage FC",
    shortSquadName: "Stevenage FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1705_Stevenage_FC.png"
  },
  {
    squadId: 21015,
    squadName: "CCD Fernandez Vial",
    shortSquadName: "CCD Fernandez Vial",
    squadLogo: null
  },
  {
    squadId: 41279,
    squadName: "Racing Club de Montevideo",
    shortSquadName: "Racing Club de Montevideo",
    squadLogo: null
  },
  {
    squadId: 17538,
    squadName: "NK Siroki Brijeg",
    shortSquadName: "NK Siroki Brijeg",
    squadLogo: null
  },
  {
    squadId: 200106,
    squadName: "Club Deportivo Maipu",
    shortSquadName: "Club Deportivo Maipu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/19523.png?lm=1469269275"
  },
  {
    squadId: 39191,
    squadName: "LKS Lodz",
    shortSquadName: "LKS Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13528_Łódzki_KS.png"
  },
  {
    squadId: 19036,
    squadName: "St. Patrick's Athletic",
    shortSquadName: "St. Patrick's Athletic",
    squadLogo: null
  },
  {
    squadId: 39309,
    squadName: "Valletta FC",
    shortSquadName: "Valletta FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3540_Valletta_FC.png"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 200492,
    squadName: "KFUM-Kameratene Oslo",
    shortSquadName: "KFUM-Kameratene Oslo",
    squadLogo: null
  },
  {
    squadId: 1161,
    squadName: "CD San Luis de Quillota",
    shortSquadName: "CD San Luis de Quillota",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2658_CD_San_Luis_de_Quillota.png"
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
  },
  {
    squadId: 33781,
    squadName: "Wurzburger Kickers",
    shortSquadName: "Wurzburger Kickers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1295_Wurzburger_Kickers.png"
  },
  {
    squadId: 17265,
    squadName: "Stjordals Blink IL",
    shortSquadName: "Stjordals Blink IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7302_Stjørdals-Blink.png"
  },
  {
    squadId: 10874,
    squadName: "Larne FC",
    shortSquadName: "Larne FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10859_Larne_FC.png"
  },
  {
    squadId: 14339,
    squadName: "Newport County",
    shortSquadName: "Newport County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newport_County.png"
  },
  {
    squadId: 5430,
    squadName: "GAIS Goteborg",
    shortSquadName: "GAIS Goteborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1451_GAIS_Goteborg.png"
  },
  {
    squadId: 10585,
    squadName: "Breidablik Kopavogur",
    shortSquadName: "Breidablik Kopavogur",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1513_Breidablik_Kopavogur.png"
  },
  {
    squadId: 1771,
    squadName: "Bryne FK",
    shortSquadName: "Bryne FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7107_Bryne_FK.png"
  },
  {
    squadId: 24176,
    squadName: "AC Oulu",
    shortSquadName: "AC Oulu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2721_AC_Oulu.png"
  },
  {
    squadId: 34923,
    squadName: "Harrogate Town",
    shortSquadName: "Harrogate Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
  },
  {
    squadId: 4969,
    squadName: "KR Reykjavik",
    shortSquadName: "KR Reykjavik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KR_Reykjavik.png"
  },
  {
    squadId: 23767,
    squadName: "Crawley Town",
    shortSquadName: "Crawley Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1198_Crawley_Town.png"
  },
  {
    squadId: 28535,
    squadName: "Naestved Boldklub",
    shortSquadName: "Naestved Boldklub",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
  },
  {
    squadId: 41912,
    squadName: "Al-Khaleej",
    shortSquadName: "Al-Khaleej",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16480_Al_Khaleej_Club_(Saihat).gif"
  },
  {
    squadId: 16727,
    squadName: "Fortaleza CEIF",
    shortSquadName: "Fortaleza CEIF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12427_Fortaleza_CEIF_FC.png"
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
  },
  {
    squadId: 4730,
    squadName: "Jammerbugt FC",
    shortSquadName: "Jammerbugt FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7483_Jammerbugt_FC.png"
  },
  {
    squadId: 36885,
    squadName: "Barrow AFC",
    shortSquadName: "Barrow AFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1225_Barrow_AFC.png"
  },
  {
    squadId: 11474,
    squadName: "Exeter City",
    shortSquadName: "Exeter City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1187_Exeter_City.png"
  },
  {
    squadId: 17038,
    squadName: "Stal Rzeszow",
    shortSquadName: "Stal Rzeszow",
    squadLogo: null
  },
  {
    squadId: 12372,
    squadName: "Keflavik IF",
    shortSquadName: "Keflavik IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7725_Keflavík_ÍF.png"
  },
  {
    squadId: 3670,
    squadName: "Queen's Park FC",
    shortSquadName: "Queen's Park FC",
    squadLogo: null
  },
  {
    squadId: 7839,
    squadName: "Kordrengir",
    shortSquadName: "Kordrengir",
    squadLogo: null
  },
  {
    squadId: 200414,
    squadName: "FCR Valledupar",
    shortSquadName: "FCR Valledupar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/18111.png?lm=1512254152"
  },
  {
    squadId: 14447,
    squadName: "CD Trasandino de Los Andes",
    shortSquadName: "CD Trasandino de Los Andes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12332_CD_Trasandino_de_Los_Andes.png"
  },
  {
    squadId: 41240,
    squadName: "Barranquilla FC",
    shortSquadName: "Barranquilla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12419_Barranquilla_FC.png"
  },
  {
    squadId: 16641,
    squadName: "Oxford City",
    shortSquadName: "Oxford City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1847_Oxford_City.png"
  },
  {
    squadId: 12344,
    squadName: "York City",
    shortSquadName: "York City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2322_York_City.png"
  }
];