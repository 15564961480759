import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { getAuth } from 'store/auth/authReducer';
import { ReonboardingService } from 'api/club/reonboarding/reonboarding.service';
import { PlayerItem, TransferTypeEnum, AdditionalPlayerItem } from 'api/club/reonboarding/model';
import { Actions as ReonboardingActions, ReonboardingSubsteppers } from '../reonboarding.controller';
import { removeCommasFromNumber } from 'utils/number-utils';
import validator from 'services/validator';
import { PitchSuggestionsModel } from 'api/agency/agent/reonboarding/reonboarding-models';
import { CurrencyModel } from 'api/common/models';
import { FormattedPriceModel } from 'api/core/formatted-price-model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import MySquadService from 'api/my-squad/my-squad.service'
import { Selectors as ReonboardingPopupSelectors } from 'pages/club/reonboarding-popup/reonboarding-popup.controller'
import { getOpenReonboardingPopupActionType } from 'pages/club/reonboarding-popup/utils';

export enum PlayerAvailableSteps {
    MakePlayersAvailableStep = 1,
    SettingTransferTermsStep = 2,
    Done = 3
}

export interface AskingPriceModelExtended extends FormattedPriceModel {
    isRequestedPrice: boolean;
    isValid: boolean;
    transferroomPrice: string;
    sellOn: any;
}

export interface SellLoanModelExtended extends FormattedPriceModel {
    isRequestedPrice: boolean;
    isValid: boolean;
    transferroomPrice: string;
}

export interface AvailablePlayerItem extends PlayerItem {
    transferType: TransferTypeEnum;
    options: PlayerOption[];
    currency: CurrencyModel;
    askingPrice: AskingPriceModelExtended | null;
    sellLoan: SellLoanModelExtended | null;
}

interface PlayerOption {
    label: string;
    value: TransferTypeEnum
}

export const isValidPriceValue = (price) => {

    return price !== null && price !== undefined && price !== ''
}

class State {
    isLoading: boolean;
    activeStep: PlayerAvailableSteps;
    players: AvailablePlayerItem[];
    availablePlayers: AvailablePlayerItem[];
    isOpenCollapse: boolean;
    totalPitchSuggestions: number;
    totalClubs: number;
    pitchSuggestions: PitchSuggestionsModel[];
    additionalPlayers: AdditionalPlayerItem[];
}

const defaultState: State = {
    isLoading: false,
    activeStep: PlayerAvailableSteps.MakePlayersAvailableStep,
    players: [],
    availablePlayers: [],
    isOpenCollapse: false,
    totalPitchSuggestions: 0,
    totalClubs: 0,
    pitchSuggestions: [],
    additionalPlayers: []
}

const stateController = new StateController<State>(
    'CLUB/REONBOARDING-FLOW/PLAYER-AVAILABLE',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setActiveStep(step: PlayerAvailableSteps) {
        return (dispatch) => {
            dispatch(stateController.setState({ activeStep: step }));
        }
    }


    public static toggleCollapse() {
        return (dispatch, getState: () => AppState) => {
            const isOpenCollapseState = Selectors.getRoot(getState()).isOpenCollapse;

            dispatch(stateController.setState({ isOpenCollapse: !isOpenCollapseState }));

            if (!isOpenCollapseState) {
                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Make Players Available]',
                    Message: 'See More Players',
                }));
            }
        }
    }

    public static getSquadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const currency = getAuth(getState()).currency;

                dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Make Players Available]'));

                const data = await ReonboardingService.getSquadPlayers();
                const playersExtended = data.players.map(player => ({
                    ...player,
                    transferType: TransferTypeEnum.Both,
                    options: [
                        {
                            label: 'Transfer',
                            value: TransferTypeEnum.Transfer
                        },
                        {
                            label: 'Loan',
                            value: TransferTypeEnum.Loan
                        },
                        {
                            label: 'Transfer & Loan',
                            value: TransferTypeEnum.Both
                        }
                    ],
                    askingPrice: player.askingPrice ? {
                        ...player.askingPrice,
                        isRequestedPrice: false,
                        transferroomPrice: player.askingPrice.formattedComma,
                        isValid: isValidPriceValue(player.askingPrice.price),
                        sellOn: null
                    } : {
                        price: null,
                        formattedComma: '',
                        formattedKMB: '',
                        isRequestedPrice: false,
                        transferroomPrice: '',
                        isValid: isValidPriceValue(player.askingPrice?.price),
                        sellOn: null
                    },
                    sellLoan: player.sellLoan ? {
                        ...player.sellLoan,
                        isRequestedPrice: false,
                        transferroomPrice: player.sellLoan.formattedComma,
                        isValid: isValidPriceValue(player.sellLoan.price)
                    } : {
                        price: null,
                        formattedComma: '',
                        formattedKMB: '',
                        isRequestedPrice: false,
                        transferroomPrice: '',
                        isValid: isValidPriceValue(player.sellLoan?.price)
                    },
                    currency: {
                        id: currency.id,
                        name: currency.name,
                        sign: ''
                    },
                }))

                dispatch(stateController.setState({
                    players: playersExtended,
                    availablePlayers: playersExtended.filter(item => item.isRecommended),
                    additionalPlayers: data.additionalPlayers
                }));

                const availablePlayers = Selectors.getRoot(getState()).availablePlayers;
                if (!availablePlayers.length) {
                    dispatch(stateController.setState({ isOpenCollapse: true }));
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setAvailablePlayers(player: AvailablePlayerItem) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const playersState = substate.availablePlayers;
            const availablePlayers = [...playersState];
            const isAvailablePlayer = availablePlayers.find(item => item.playerId === player.playerId)

            if (!isAvailablePlayer) {
                availablePlayers.push(player);

                dispatch(stateController.setState({
                    availablePlayers: availablePlayers,
                }));

                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Make Players Available]',
                    Message: 'Activated Availability',
                    PlayerId: player.playerId,
                }));
            } else {
                dispatch(stateController.setState({
                    availablePlayers: availablePlayers.filter(item => item.playerId !== player.playerId),
                }));

                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Make Players Available]',
                    Message: 'Deactivated Availability',
                    PlayerId: player.playerId,
                }));
            }
        }
    }

    public static onSelectPlayerOptions(value: TransferTypeEnum, playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const availablePlayersState = Selectors.getRoot(getState()).availablePlayers;
            const availablePlayers = [...availablePlayersState];

            availablePlayers.find(item => item.playerId === playerId).transferType = value;

            dispatch(stateController.setState({
                availablePlayers: [...availablePlayers]
            }));

            const getUserActivityMessage = () => {
                switch (value) {
                    case TransferTypeEnum.Transfer:
                        return 'Set Transfer';
                    case TransferTypeEnum.Loan:
                        return 'Set Loan';
                    case TransferTypeEnum.Both:
                        return 'Set Transfer and Loan';
                    default:
                        return ''
                }
            }

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Make Players Available]',
                Message: getUserActivityMessage(),
                PlayerId: playerId,
            }));
        }
    }

    public static onConfirm() {
        return (dispatch, getState: () => AppState) => {
            const availablePlayers = Selectors.getRoot(getState()).availablePlayers;

            const getUserActivityTransferType = (transferType) => {
                switch (transferType) {
                    case TransferTypeEnum.Transfer:
                        return 'Transfer';
                    case TransferTypeEnum.Loan:
                        return 'Loan';
                    case TransferTypeEnum.Both:
                        return 'Transfer and Loan';
                    default:
                        return ''
                }
            }

            availablePlayers.map(({ playerName, playerId, transferType }) => {
                return dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Make Players Available]',
                    Message: `Confirmed Player ${playerName} as Available for ${getUserActivityTransferType(transferType)}`,
                    PlayerId: playerId,
                }));
            });

            dispatch(stateController.setState({ activeStep: PlayerAvailableSteps.SettingTransferTermsStep }));
            dispatch(ReonboardingActions.disposeScrollingEffect());
            dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Set Transfer Terms]'));
        }
    }

    public static onPriceChange(playerId: number, type: string, value: string) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const availablePlayersState = substate.availablePlayers;
            const updatedPlayer = availablePlayersState.find(player => player.playerId === playerId);

            if (type === 'askingPrice') {
                updatedPlayer.askingPrice.price = removeCommasFromNumber(value);
                updatedPlayer.askingPrice.formattedComma = value;
                updatedPlayer.askingPrice.isValid = isValidPriceValue(value)
            }
            if (type === 'sellLoan') {
                updatedPlayer.sellLoan.price = removeCommasFromNumber(value);
                updatedPlayer.sellLoan.formattedComma = value;
                updatedPlayer.sellLoan.isValid = isValidPriceValue(value)
            }

            dispatch(stateController.setState({
                availablePlayers: [...availablePlayersState]
            }));
        }
    }

    public static onPriceUpdated(playerId: number, type: string) {
        return (dispatch) => {

            if (type === 'askingPrice') {
                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Set Transfer Terms]',
                    Message: 'Selected Asking Price',
                    PlayerId: playerId,
                }));
            }
            if (type === 'sellLoan') {
                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Set Transfer Terms]',
                    Message: 'Selected Monthly Loan Fee',
                    PlayerId: playerId,
                }));
            }
        }
    }

    public static onSellOnChange(playerId: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const availablePlayersState = substate.availablePlayers;
            if (!validator.isInRange(0, 100)(value)) {
                return;
            }

            const updatedPlayer = availablePlayersState.find(player => player.playerId === playerId);
            updatedPlayer.askingPrice.sellOn = value;

            dispatch(stateController.setState({
                availablePlayers: [...availablePlayersState]
            }));
        }
    }

    public static onPriceOnRequestChange(playerId: number, type: string, value: boolean) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const availablePlayersState = substate.availablePlayers;

            const updatedPlayer = availablePlayersState.find(player => player.playerId === playerId);

            if (type === 'askingPrice') {
                updatedPlayer.askingPrice.isRequestedPrice = value;
            }
            if (type === 'sellLoan') {
                updatedPlayer.sellLoan.isRequestedPrice = value;
            }

            if (value) {
                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Set Transfer Terms]',
                    Message: 'Selected Price Upon Request',
                    PlayerId: playerId,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Set Transfer Terms]',
                    Message: 'Deselected Price Upon Request',
                    PlayerId: playerId,
                }));
            }

            dispatch(stateController.setState({
                availablePlayers: [...availablePlayersState]
            }));
        }
    }

    public static onConfirmAndContinue() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const availablePlayersState = Selectors.getAvailablePlayers(getState());

                const prepareData = availablePlayersState.map(player => ({
                    playerId: player.playerId,
                    toLoan: player.transferType === TransferTypeEnum.Both || player.transferType === TransferTypeEnum.Loan ? {
                        request: player.sellLoan.isRequestedPrice,
                        monthlyLoan: player.sellLoan.isRequestedPrice ? null : player.sellLoan.price
                    } : null,
                    toBuy: player.transferType === TransferTypeEnum.Both || player.transferType === TransferTypeEnum.Transfer ? {
                        request: player.askingPrice.isRequestedPrice,
                        askingPrice: player.askingPrice.isRequestedPrice ? null : player.askingPrice.price,
                        sellOn: player.askingPrice.isRequestedPrice ? null : player.askingPrice.sellOn
                    } : null
                }));

                const data = await ReonboardingService.setAvailability(prepareData);
                MySquadService.quickSearchFlush();

                availablePlayersState.forEach(player => {
                    if (player.transferType === TransferTypeEnum.Both || player.transferType === TransferTypeEnum.Transfer) {
                        dispatch(userActivityInsert({
                            PageName: 'Re-Onboarding [Set Transfer Terms]',
                            Message: `Confirmed ${player.playerName} Transfer Value: ${player.askingPrice.isRequestedPrice ? 'Price Upon Request' : `${player.askingPrice.price} ${player.currency.name}${player.askingPrice.sellOn ? ' + Sell-on ' + `${player.askingPrice.sellOn}%` : ''}`}`,
                            PlayerId: player.playerId,
                        }));
                    }
                    if (player.transferType === TransferTypeEnum.Both || player.transferType === TransferTypeEnum.Loan) {
                        dispatch(userActivityInsert({
                            PageName: 'Re-Onboarding [Set Transfer Terms]',
                            Message: `Confirmed ${player.playerName} Loan Value: ${player.sellLoan.isRequestedPrice ? 'Price Upon Request' : `${player.sellLoan.price} ${player.currency.name}/mo`}`,
                            PlayerId: player.playerId,
                        }));
                    }
                });

                dispatch(stateController.setState({
                    totalClubs: data.totalClubs,
                    totalPitchSuggestions: data.totalPitchSuggestions,
                    pitchSuggestions: data.players,
                    activeStep: PlayerAvailableSteps.Done
                }));
                dispatch(ReonboardingActions.disposeScrollingEffect());
                dispatch(ReonboardingActions.toggleCongratulationScreen(true));

                if (data?.totalPitchSuggestions > 0) {
                    dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Congratulations]'));
                } else {
                    dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Congratulations - No Opportunities]'));
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getStartedPitching() {
        return (dispatch) => {
            dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.PitchingPlayers));
            dispatch(ReonboardingActions.disposeScrollingEffect());
            dispatch(ReonboardingActions.toggleCongratulationScreen(false));

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Congratulations]',
                Message: 'Get Started With Pitching',
            }));
        }
    }

    public static onClose() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const { isManual } = ReonboardingPopupSelectors.getRoot(appState);
            dispatch({ type: getOpenReonboardingPopupActionType(isManual) })
            dispatch(ReonboardingActions.toggleCongratulationScreen(false));
            dispatch(Actions.dispose());

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Congratulations - No Opportunities]',
                Message: 'Close and Exit',
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.club.reonboarding.playersAvailable;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).activeStep;
    public static getAvailablePlayers = (state: AppState) => Selectors.getRoot(state).availablePlayers;
    public static getTotalPitchSuggestions = (state: AppState) => Selectors.getRoot(state).totalPitchSuggestions;
    public static getTotalClub = (state: AppState) => Selectors.getRoot(state).totalClubs;

    public static isAllSettingsValid = (state: AppState) => {
        const availablePlayers = Selectors.getAvailablePlayers(state);
        const emptyValues = [];

        availablePlayers.map((player) => {
            const isTransferTypeBuy = player.transferType === TransferTypeEnum.Both || player.transferType === TransferTypeEnum.Transfer;
            const isTransferTypeLoan = player.transferType === TransferTypeEnum.Both || player.transferType === TransferTypeEnum.Loan;
            if (isTransferTypeBuy && !player.askingPrice?.isRequestedPrice) {
                if (!player.askingPrice.isValid) {
                    emptyValues.push(true);
                }
            }
            if (isTransferTypeLoan && !player.sellLoan?.isRequestedPrice) {
                if (!player.sellLoan.isValid) {
                    emptyValues.push(true);
                }
            }

        });

        return !emptyValues.some(item => !!item);
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};