import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import fifthVirtualDealDayImage from 'media/images/events/2022-1-august-virtual/fifth-virtual-deal-day-thumb.png'


const VirtualDealDay1stAugust = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="5"
                        bgImageNumber={8}
                        th="th"
                        title1="TransferRoom"
                        title2="Virtual Deal Day"
                        date="1st Aug 2022, Online"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0 10%' }}>
                        <img style={{ width: '100%' }} src={fifthVirtualDealDayImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="347"
                        countries="47"
                        leagues="77"
                        agents="46"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

export const previousEventAttendees = [
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 46, name: "Liverpool FC", logo: "logo_1123_Liverpool_FC.png" },
    { id: 33906, name: "FC Barcelona", logo: null }, //"logo_FC_Barcelona.png"
    { id: 49, name: "Arsenal FC", logo: "logo_1121_Arsenal_FC.png" },
    { id: 45, name: "Manchester United", logo: null }, //"logo_1120_Manchester_United.png"
    { id: 38553, name: "Aston Villa", logo: "logo_1138_Aston_Villa.png" },
    { id: 6249, name: "Bayer 04 Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 63, name: "Everton FC", logo: "logo_1124_Everton_FC.png" },
    { id: 1, name: "AC Milan", logo: "logo_1834_AC_Milan.png" },
    { id: 10088, name: "RB Leipzig", logo: "logo_1261_RB_Leipzig.png" },
    { id: 65, name: "Wolverhampton Wanderers", logo: "logo_Wolverhampton_Wanderers.png" },
    { id: 60, name: "Newcastle United", logo: "logo_Newcastle_United.png" },
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 64, name: "Crystal Palace", logo: "logo_1128_Crystal_Palace.png" },
    { id: 62, name: "Southampton FC", logo: "1619_Southampton_FC.png" },
    { id: 8239, name: "Brentford FC", logo: "logo_1153_Brentford_FC.png" },
    { id: 39176, name: "Leeds United", logo: "logo_1150_Leeds_United.png" },
    { id: 70, name: "Brighton & Hove Albion", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 23352, name: "Stade Rennais FC", logo: "logo_Stade_Rennes.png" },
    { id: 12294, name: "Olympique Marseille", logo: "logo_1964_Olympique_Marseille.png" },
    { id: 32429, name: "Eintracht Frankfurt", logo: "logo_1268_Eintracht_Frankfurt.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 12470, name: "VfL Wolfsburg", logo: "logo_VfL_Wolfsburg.png" },
    { id: 773, name: "Getafe CF", logo: "logo_1728_Getafe_CF.png" },
    { id: 27251, name: "Clube de Regatas do Flamengo", logo: "5654_Clube_de_Regatas_do_Flamengo.png" },
    { id: 13058, name: "Feyenoord Rotterdam", logo: "logo_1533_Feyenoord_Rotterdam.png" },
    { id: 17175, name: "Norwich City", logo: "logo_1144_Norwich_City.png" },
    { id: 35839, name: "Sociedade Esportiva Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 69, name: "Watford FC", logo: "1644_Watford_FC.png" },
    { id: 8110, name: "Hertha BSC", logo: "2457_Hertha_BSC.png" },
    { id: 31172, name: "Montpellier HSC", logo: "3787_Montpellier.png" },
    { id: 4230, name: "Stade Reims", logo: "3802_Stade_Reims.png" },
    { id: 31588, name: "Rangers FC", logo: "logo_2188_Rangers_FC.png" },
    { id: 28212, name: "RC Strasbourg Alsace", logo: "logo_1981_RC_Strasbourg_Alsace.png" },
    { id: 7083, name: "Elche CF", logo: "logo_1793_Elche_CF.png" },
    { id: 23168, name: "1. FC Koln", logo: "logo_1266_1._FC_Koln.png" },
    { id: 36192, name: "Tigres UANL", logo: "15417_Tigres_UANL.png" },
    { id: 25629, name: "RSC Anderlecht", logo: "logo_RSC_Anderlecht.png" },
    { id: 22514, name: "CF America", logo: "15405_América.png" },
    { id: 21139, name: "Olympiacos Piraeus", logo: "12509_Olympiacos_Piraeus.png" },
    { id: 30849, name: "Atlanta United FC", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 19138, name: "RCD Mallorca", logo: "686_RCD_Mallorca.png" }
]

export default VirtualDealDay1stAugust