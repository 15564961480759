import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import PasswordValidation from 'utils/validate-password';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { refreshAuthSingle } from 'store/auth/auth.actions'
import { PageType } from 'constants/enums';
import AgencyOnboardingService from 'api/agency/agent/onboarding/onboarding.service';
class ConfirmAccountState {
    password: string;
    rePassword: string;
    termsCheckbox: boolean;
    passwordError: any;
    rePasswordError: any;
    isProcessing: boolean;
    isValidPassword: boolean;
}

const defaultState: ConfirmAccountState = {
    password: "",
    rePassword: "",
    termsCheckbox: false,
    passwordError: null,
    rePasswordError: null,
    isProcessing: false,
    isValidPassword: true,
}

const stateController = new StateController<ConfirmAccountState>(
    "AGENCY/AGENCY/CONFIRM_ACCOUNT",
    defaultState
);

class Actions {
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static clearErrors() {
        return dispatch => {
            dispatch(stateController.setState({
                passwordError: null,
                rePasswordError: null,
            }));
        }
    }

    public static setPassword(password: string) {
        return async (dispatch, getState: () => AppState) => {
            const rePassword = Selectors.getRePass(getState())
            const validation = PasswordValidation.validate(password)
            let rePasswordError = PasswordValidation.validateConfirmPassword(password, rePassword);
            dispatch(stateController.setState({
                password: password,
                passwordError: validation.rules,
                isValidPassword: validation.isSuccess,
                rePasswordError,
            }));
        }
    }
    public static setPasswordRe(rePassword: string) {
        return async (dispatch, getState: () => AppState) => {
            const password = Selectors.getPass(getState())
            let rePasswordError = PasswordValidation.validateConfirmPassword(password, rePassword);
            dispatch(stateController.setState({ rePassword, rePasswordError }));
        }
    }

    public static setTerms(agreed: boolean) {
        return async dispatch => {
            dispatch(stateController.setState({ termsCheckbox: agreed }));
        }
    }

    public static confirm() {
        return async (dispatch, getState: () => AppState) => {

            try {
                const state = getState();
                const subState = Selectors.getRoot(state);

                if (!subState.password || !subState.rePassword) {
                    return;
                }


                const validation = PasswordValidation.validate(subState.password)
                if (!validation.isSuccess) {
                    dispatch(stateController.setState({
                        passwordError: validation.rules,
                        isValidPassword: validation.isSuccess,
                    }));
                    return
                }

                let rePasswordError = PasswordValidation.validateConfirmPassword(subState.password, subState.rePassword);

                if (rePasswordError) {
                    dispatch(stateController.setState({
                        rePasswordError: rePasswordError,
                    }));
                    return;
                }
                dispatch(stateController.setState({ isProcessing: true }))
                const data = await AgencyOnboardingService.confirmAndSetPassword({ password: subState.password });

                if (!data.isSuccess) {
                    dispatch(stateController.setState({
                        passwordError: data.rules,
                        rePasswordError: null,
                        isValidPassword: false,
                    }));
                } else {
                    dispatch(userActivityInsert({
                        PageName: 'Agency [Manual onboarding]',
                        Message: 'Applied New Password',
                        PageType: PageType.AgencyOnboarding
                    }))
                    await dispatch(refreshAuthSingle(state.auth.aspNetUserId))
                }
            } catch (error) {

            }
            finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.agent.confirmAccount;
    public static getPass = (state: AppState) => Selectors.getRoot(state).password;
    public static getRePass = (state: AppState) => Selectors.getRoot(state).rePassword;
    public static getTerms = (state: AppState) => Selectors.getRoot(state).termsCheckbox;
    public static getPasswordError = (state: AppState) => Selectors.getRoot(state).passwordError;
    public static getRePasswordError = (state: AppState) => Selectors.getRoot(state).rePasswordError;
    public static getProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static isValidPassword = (state: AppState) => Selectors.getRoot(state).isValidPassword;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmAccountState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};
