import { StateController } from 'utils/action-declaration';
import CompetitionService from 'api/agency/agency-competition-list/competition-list.service';
import { CompetitionListModel } from 'api/agency/agency-competition-list/models';

import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { Actions as FilterActions, Selectors as FilterSelectors } from 'pages/PlayerSearch-v3/agency/redux/filter.controller';
import { CustomLeagueList } from 'api/search/model/search-filter-criteria';

class CommonState {
    competitionList: CompetitionListModel[];
    modalData?: any;
    processing: boolean;
    processingIds: Array<number>;
}

const defaultState: CommonState = {
    modalData: null,
    processing: false,
    processingIds: [],
    competitionList: []
}

const stateController = new StateController<CommonState>(
    'AGENCY_SEARCH/COMPETITION_LIST',
    defaultState
)

class CompetitionsRequest {
    name: string;
    competitionIds: Array<number>;
    competitions: Array<CustomLeagueList>;
}

class UpdateCompetitionsRequest {
    id: number;
    name: string;
    competitionIds: Array<number>;
    competitions: Array<CustomLeagueList>;
}

class Actions {
    public static toggleModal = (modalData?: any) => {
        return (dispatch) => {
            dispatch(stateController.setState({ modalData }))
        }
    }

    public static toggleCompetitionListModal = (modalData?: any) => {
        return (dispatch) => {
            dispatch(Actions.toggleModal(modalData));

            if(!modalData) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Cancel`,
                    PageType: PageType.Search,
                }));
            } else {
                if (modalData?.name) {
                    dispatch(userActivityInsert({
                        PageName: 'Search [Filter - League list]',
                        Message: `Edit List: ${modalData.name}`,
                        PageType: PageType.Search,
                    }));
                } else {
                    dispatch(userActivityInsert({
                        PageName: 'Search [Filter - League list]',
                        Message: `Create League List`,
                        PageType: PageType.Search,
                    }));
                }
            }
        }
    }

    public static competitionListCreate = (data: CompetitionsRequest) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ processing: true }))
                const res = await CompetitionService.create({
                    name: data.name,
                    competitions: data.competitionIds
                });
                dispatch(FilterActions.addLeagueList({
                    id: res.id,
                    name: res.name,
                    competitionIds: res.competitionIds,
                    checked: false
                }));

                dispatch(Actions.toggleModal(null))
                dispatch(stateController.setState({ processing: false }))

                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Save Changes: ${res.name}, ${data.competitions.map(item=>item.name).join(', ')}`,
                    PageType: PageType.Search,
                }))
            } catch (error) {
                console.log(error);
                dispatch(stateController.setState({ processing: false }))
            }
        }
    }
    public static competitionListUpdate = (data: UpdateCompetitionsRequest) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const processingIds = Selectors.getProcessingIds(getState());
                dispatch(stateController.setState({ processing: true, processingIds: [...processingIds, data.id] }))
                dispatch(stateController.setState({ processing: true }))
                const res = await CompetitionService.update({
                    competitionListId: data.id,
                    name: data.name,
                    competitionIds: data.competitionIds,
                });

                dispatch(FilterActions.addLeagueList({
                    id: res.id,
                    name: res.name,
                    competitionIds: res.competitionIds,
                    checked: false,
                }));

                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Edit List: ${data.name}`,
                    PageType: PageType.Search,
                }))
                dispatch(Actions.toggleModal(null))

                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== data.id)
                })))
            } catch (error) {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== data.id)
                })))
            }
        }
    }

    public static competitionListDelete = (id: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const processingIds = Selectors.getProcessingIds(getState());
                dispatch(stateController.setState({ processing: true, processingIds: [...processingIds, id] }));
                const filter = getState().agencySearch.filter;
                const competitions: any = filter.playerAttributesFilterModal?.filterData.currentLeagueList || filter.coachAttributesFilterModal?.filterData.leagueExperienceList;
                const deleted = competitions.find(item => item.id === id);
                await CompetitionService.delete(id);
                dispatch(Actions.toggleModal(null))
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== id)
                })))

                dispatch(FilterActions.deleteCompetitionList(id))
                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Delete list: ${deleted.name}`,
                    PageType: PageType.Search,
                }))
            } catch (error) {
                console.log(error);
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== id)
                })))
            }
        }
    }

    public static selectLeague = (list: any) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Select list: ${list.name
                } - ${list.competitionsIds}`,
                PageName: 'Search [Filter - League list]',
                PageType: PageType.Search
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): CommonState => state.agencySearch.competitionList;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).processing;
    public static getProcessingIds = (state: AppState) => Selectors.getRoot(state).processingIds;

    public static getCompetitionListModal = (state: AppState) => Selectors.getRoot(state).modalData;
    public static getCompetitionNames = (state: AppState): Array<string> => {
        const competitionList = FilterSelectors.getRoot(state).playerAttributesFilter.filterData.currentLeagueList;
        return competitionList?.map(item => item.name)
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};