import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';
import { createAction } from 'redux-actions';

export const screenState = {
    landing: "landing",
    landingInput: "landingInput",
    chart: "chart"
}

const initialState = {
    data: null,
    criteria: {
        periodRangeList: [
            { id: 1 * 12, title: 'Latest 12 months' },
            { id: 2 * 12, title: 'Latest 2 years' },
            { id: 3 * 12, title: 'Latest 3 years' },
            { id: 4 * 12, title: 'Latest 4 years' },
            { id: 5 * 12, title: 'Latest 5 years' },
        ],
        currencyList: [
            { id: 1, title: 'EUR' },
            { id: 2, title: 'USD' },
            { id: 3, title: 'GBP' },
            //{ id: 4, title: 'CHF' },
        ]
    },
    selection: {
        periodRange: 24,
        alternativePeriodRange: 12,
        currency: 1
    },
    screenState: screenState.landing,
    currentProfileId: null
};

export default (playerProfileMarketValue = initialState, action) => {
    switch (action.type) {
        case PLAYER_PROFILE_MARKET_VALUE_FETCH.SUCCESS: {
            return {
                ...playerProfileMarketValue,
                data: action.payload.data,
                screenState: action.payload.data.askToUpdateMarketValue ? screenState.landingInput : screenState.landing,
                criteria: {
                    ...playerProfileMarketValue.criteria,
                    periodRangeList: playerProfileMarketValue.criteria.periodRangeList.filter(i => i.id === 12 || i.id <= action.payload.data.maxMonthsToDisplay)
                }
            };
        }

        case PLAYER_PROFILE_MARKET_VALUE_SET_CURRENCY: {
            return {
                ...playerProfileMarketValue,
                selection: {
                    ...playerProfileMarketValue.selection,
                    currency: action.payload
                }
            }
        }

        case PLAYER_PROFILE_MARKET_VALUE_SET_PERIOD_RANGE: {
            return {
                ...playerProfileMarketValue,
                selection: {
                    ...playerProfileMarketValue.selection,
                    periodRange: action.payload
                }
            }
        }

        case PLAYER_PROFILE_MARKET_VALUE_SET_SCREEN_STATE: {
            return { ...playerProfileMarketValue, screenState: action.payload }
        }

        case PLAYER_PROFILE_MARKET_SET_CURRENT_PROFILE_ID: {
            return { ...playerProfileMarketValue, currentProfileId: action.payload }
        }

        case PLAYER_PROFILE_MARKET_VALUE_SET_ASK_UPDATE_VALUE: {
            return {
                ...playerProfileMarketValue, data: {
                    ...playerProfileMarketValue.data, askToUpdateMarketValue: false
                }
            }
        }

        default:
            return playerProfileMarketValue;
    }
};

export const PLAYER_PROFILE_MARKET_VALUE_FETCH = asyncActionType(
    'app/playerProfileMarketValue/PLAYER_PROFILE_MARKET_VALUE_FETCH',
);

export const PLAYER_PROFILE_MARKET_VALUE_PUT_CUSTOM = asyncActionType(
    'app/playerProfileMarketValue/MARKET_VALUE_PUT_CUSTOM',
);

export const PLAYER_PROFILE_MARKET_VALUE_SET_CURRENCY = 'app/playerProfileMarketValue/PLAYER_PROFILE_MARKET_VALUE_SET_CURRENCY';
export const PLAYER_PROFILE_MARKET_VALUE_SET_PERIOD_RANGE = 'app/playerProfileMarketValue/PLAYER_PROFILE_MARKET_VALUE_SET_PERIOD_RANGE'
export const PLAYER_PROFILE_MARKET_SET_CURRENT_PROFILE_ID = 'app/playerProfileMarketValue/PLAYER_PROFILE_SET_CURRENT_PROFILE_ID'

export const MARKET_VALUE_PLAYER_INTEREST = asyncActionType('app/financial-insights/MARKET_VALUE_PLAYER_INTEREST');

export const PLAYER_PROFILE_MARKET_VALUE_SET_SCREEN_STATE = "app/playerProfileMarketValue/SET_SCREEN_STATE";
export const setScreenState = createAction(PLAYER_PROFILE_MARKET_VALUE_SET_SCREEN_STATE);

export const PLAYER_PROFILE_MARKET_VALUE_SET_ASK_UPDATE_VALUE = "app/playerProfileMarketValue/SET_ASK_UPDATE_VALUE";
export const setAskUpdateValue = createAction(PLAYER_PROFILE_MARKET_VALUE_SET_ASK_UPDATE_VALUE);

export const getScreenState = state => state.playerProfileMarketValue.screenState;

export const getIsInitialDataLoading = state =>
    getRequests(state).some(r => r.type === PLAYER_PROFILE_MARKET_VALUE_FETCH);
