import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service'

export const enum LtaUpgradeModalType {
    InstantUpgrade = 1,
    ReachLimitUpgrade = 2,
    AddAgentUpgrade = 3,
    GainPitchAccessUpgrade = 4
}

export const LTA_PROFILE_TRANSFERS_LIMIT = 10;

class LtaUpgradeModalState {
    isOpen: boolean;
    isBusy: boolean;
    step?: number;
    type?: LtaUpgradeModalType;
    UAPageName?: string;
}

const defaultState: LtaUpgradeModalState = {
    isOpen: false,
    isBusy: false,
    step: null,
    type: null,
    UAPageName: '',
}

const stateController = new StateController<LtaUpgradeModalState>(
    "AGENCY/LTA_UPDGRADE_MODAL",
    defaultState
);

class Actions {

    public static close(isUserActivity?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const {UAPageName} = getState().agency.ltaUpgradeModal;

            dispatch(stateController.setState({ isOpen: false }))
            if(Boolean(isUserActivity && UAPageName)) {
                dispatch(Actions.sendUserActivity(UAPageName, 'Closed Upgrade Request'));
            }
        }
    }

    public static open(type: LtaUpgradeModalType, UAPageName?: string) {
        return async (dispatch, getState: () => AppState) => {
            if (UAPageName) {
                dispatch(stateController.setState({ UAPageName }));
            }
            if (type == LtaUpgradeModalType.InstantUpgrade) {
                dispatch(stateController.setState({ isOpen: true, isBusy: true, type: type, step: 2 }));
                await AgencyPlayerService.ltaGetInTouch();
                dispatch(stateController.setState({ isBusy: false }));
            }
            else {
                dispatch(stateController.setState({ isOpen: true, type: type, step: 1 }));
            }
        }
    }

    public static getInTouch() {
        return async (dispatch, getState: () => AppState) => {
            const {UAPageName} = getState().agency.ltaUpgradeModal;
            dispatch(stateController.setState({ isBusy: true }));
            await AgencyPlayerService.ltaGetInTouch();
            dispatch(stateController.setState({ isBusy: false, step: 2 }));

            if(Boolean(UAPageName)) {
                dispatch(Actions.sendUserActivity(UAPageName, 'Sent Upgrade Request'));
            }
        }
    }

    public static sendUserActivity = (pageName: string, message:string) => {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
            }));
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.ltaUpgradeModal
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LtaUpgradeModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



