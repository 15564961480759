import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Panel extends Component {
    static Header = Header;
    static Body = Body;
    static Footer = Footer;
    render() {
        const { children, compact, smallRadius, className, style, dataTestId } = this.props;

        const classNames = ['tr-panel', 'tr-panel--borderless']
            .concat(compact ? ['tr-panel--compact'] : [])
            .concat(smallRadius ? ['tr-panel--small-radius'] : [])
            .concat(className ? [className] : [])
            .join(' ');

        return <div className={classNames} style={style ? style : null} data-test-id={dataTestId}>{children}</div>;
    }
}

Panel.propTypes = {
    compact: PropTypes.bool,
};

function Header({ children }) {
    return <div>{children}</div>;
}

function Body({ children, ...otherProps }) {
    return <div {...otherProps}>{children}</div>;
}

function Footer({ children }) {
    return <div className="text-muted">{children}</div>;
}
