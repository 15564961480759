import axios from 'axios-config';
import { 
    GetPlayerRankingRequest, 
    PlayerRankingConfigurationResponse, 
    PlayerRankingResultItem, 
    PlayerRankingSelection 
} from './model';

export class PlayerRankingService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async getConfiguration(playerId: number): Promise<PlayerRankingConfigurationResponse>{
        const { data } = await axios.get<PlayerRankingConfigurationResponse>(
            `api/v2/PlayerRanking/GetConfiguration/${playerId}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getRanking(playerId: number, selection: PlayerRankingSelection): Promise<PlayerRankingResultItem>{
        const payload: GetPlayerRankingRequest = {
            playerId,
            selection
        };
        const { data } = await axios.post<PlayerRankingResultItem>(
            `api/v2/PlayerRanking/Get`,
            payload,
            this.axiosConfig,
        );
        return data;
    }
}