import { getRequests } from '../requests/requestsReducer';
import { asyncActionType, PLAYERS_DECLARE_INTEREST_SAVE } from '../actionTypes';

export const SHOW_BATCH_COUNT = 10;

const initialState = {
    criteria: {
        availableProperties: [],
        comparisonSandboxes: [],
        availableSeasons: [],
    },
    currentPlayerId: null,
    selection: {
        selectedSeasonId: null,
        comparisonSandbox: null,
        selectedPositions: [],
    },
    firstSet: null,
    secondSet: null,
    disabled: false,
    displayPage: 1,
    declareInterestProcessingIds: []
};

export default (playerRanking = initialState, action) => {
    switch (action.type) {
        case PLAYER_RANKING_FETCH_CONFIGURATION.SUCCESS: {
            let payload = action.payload.data;
            return {
                ...playerRanking,
                firstSet: payload.firstSet,
                secondSet: payload.secondSet,
                currentPlayerId: action.payload.request.currentPlayerId,
                criteria: {
                    availableProperties: payload.availableProperties,
                    comparisonSandboxes: payload.comparisonSandboxes,
                    availableSeasons: payload.availableSeasons,
                },
                selection: payload.selection,
                disabled: payload.disabled,
            };
        }

        case PLAYER_RANKING_SET_SELECTED_SEASON: {
            let comparisonSandbox = playerRanking.selection.comparisonSandbox;
            const currentComparisonSandbox = playerRanking.criteria.comparisonSandboxes.find(
                x =>
                    (!x.season || x.season === action.payload) &&
                    x.key === comparisonSandbox,
            );

            if (!currentComparisonSandbox) {
                comparisonSandbox = (
                    playerRanking.criteria.comparisonSandboxes.find(
                        x => !x.season || x.season === action.payload,
                    ) || {}
                ).key;
            }

            return {
                ...playerRanking,
                selection: {
                    ...playerRanking.selection,
                    selectedSeasonId: action.payload,
                    comparisonSandbox: comparisonSandbox,
                },
                displayPage: 1,
            };
        }

        case PLAYER_RANKING_SET_SELECTED_SANDBOX: {
            return {
                ...playerRanking,
                selection: {
                    ...playerRanking.selection,
                    comparisonSandbox: action.payload,
                },
                displayPage: 1,
            };
        }

        case PLAYER_RANKING_SET_SELECTED_POSITION: {
            return {
                ...playerRanking,
                selection: {
                    ...playerRanking.selection,
                    selectedPositions: action.payload,
                },
            };
        }

        case PLAYER_RANKING_FIRST_SET_FETCH.SUCCESS: {
            return {
                ...playerRanking,
                firstSet: action.payload.data,
            };
        }

        case PLAYER_RANKING_FIRST_SET_FETCH.REQUEST: {
            return {
                ...playerRanking,
                firstSet: null,
            };
        }

        case PLAYER_RANKING_TOGGLE_NEXT_PAGE: {
            return {
                ...playerRanking,
                displayPage: playerRanking.displayPage + 1,
            };
        }

        case PLAYER_RANKING_SHOW_FIRST_PAGE: {
            return { ...playerRanking, displayPage: 1 };
        }

        case PLAYERS_DECLARE_INTEREST_SAVE.REQUEST: {
            let playerIdDeclared = action.payload.playerId;
            return {
                ...playerRanking,
                declareInterestProcessingIds: [...playerRanking.declareInterestProcessingIds, playerIdDeclared]
            }
        }
        case PLAYERS_DECLARE_INTEREST_SAVE.SUCCESS: {
            let playerIdDeclared = action.payload.request.playerId;
            if (playerRanking.currentPlayerId === null)
                return { ...playerRanking };
            return {
                ...playerRanking,
                declareInterestProcessingIds: playerRanking.declareInterestProcessingIds.filter(item => item !== playerIdDeclared),
                firstSet: {
                    ...playerRanking.firstSet,
                    items: [
                        ...(playerRanking.firstSet || { items:[]}).items.map(item => {
                            if (item.player.playerId === playerIdDeclared) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        interestWasDeclared: true,
                                    },
                                };
                            }
                            return item;
                        }),
                    ],
                },
                secondSet: {
                    ...playerRanking.secondSet,
                    items: [
                        ...(playerRanking.secondSet || {items:[]}).items.map(item => {
                            if (item.player.playerId === playerIdDeclared) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        interestWasDeclared: true,
                                    },
                                };
                            }
                            return item;
                        }),
                    ],
                },
            };
        }
        case PLAYERS_DECLARE_INTEREST_SAVE.ERROR: {
            let playerIdDeclared = action.payload.playerId;
            return {
                ...playerRanking,
                declareInterestProcessingIds: playerRanking.declareInterestProcessingIds.filter(item => item !== playerIdDeclared),
            }
        }

        case PLAYER_RANKING_SET_IS_IN_SHORT_LIST.SUCCESS: {
            let shortListTogglePayload = action.payload.request.payload;            

            return {
                ...playerRanking,
                firstSet: {
                    ...playerRanking.firstSet,
                    items: [
                        ...(playerRanking.firstSet || {items:[]}).items.map(item => {
                            if (
                                item.player.playerId ===
                                shortListTogglePayload.playerId
                            ) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        inShortList: shortListTogglePayload.on,
                                    },
                                };
                            }
                            return item;
                        }),
                    ],
                },
                secondSet: {
                    ...playerRanking.secondSet,
                    items: [
                        ...(playerRanking.secondSet || {items:[]}).items.map(item => {
                            if (
                                item.player.playerId ===
                                shortListTogglePayload.playerId
                            ) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        inShortList: shortListTogglePayload.on,
                                    },
                                };
                            }
                            return item;
                        }),
                    ],
                },
            };
        }

        case PLAYER_RANKING_SECOND_SET_FETCH.SUCCESS: {
            return {
                ...playerRanking,
                secondSet: action.payload.data,
            };
        }

        case PLAYER_RANKING_SECOND_SET_FETCH.REQUEST: {
            return {
                ...playerRanking,
                secondSet: null,
            };
        }

        case PLAYER_RANKING_DISPOSE: {
            return {
                ...initialState,
            };
        }

        default:
            return playerRanking;
    }
};

const path = state => state.playerRanking;
export const getPlayerRankingState = state => path(state);

export const PLAYER_RANKING_FETCH_CONFIGURATION = asyncActionType(
    'app/playerRanking/FETCH_CONFIGURATION',
);

export const PLAYER_RANKING_FIRST_SET_FETCH = asyncActionType(
    'app/playerRanking/FIRST_SET_FETCH',
);
export const PLAYER_RANKING_SECOND_SET_FETCH = asyncActionType(
    'app/playerRanking/SECOND_SET_FETCH',
);

export const PLAYER_RANKING_SET_SELECTED_SEASON =
    'app/playerRanking/SET_SELECTED_SEASON';
export const PLAYER_RANKING_TOGGLE_NEXT_PAGE =
    'app/playerRanking/TOGGLE_NEXT_PAGE';
export const PLAYER_RANKING_SHOW_FIRST_PAGE =
    'app/playerRanking/SHOW_FIRST_PAGE';

export const PLAYER_RANKING_SET_SELECTED_SANDBOX =
    'app/playerRanking/SET_SELECTED_SANDBOX';

export const PLAYER_RANKING_SET_SELECTED_POSITION =
    'app/playerRanking/SET_SELECTED_POSITON';
export const PLAYER_RANKING_SET_IS_IN_SHORT_LIST = asyncActionType(
    'app/playerRanking/SET_IS_IN_SHORT_LIST',
);
export const PLAYER_RANKING_DISPOSE = 'app/playerRanking/DISPOSE';

export const getConfigurationLoadingStatus = state =>
    getRequests(state).some(r => r.type === PLAYER_RANKING_FETCH_CONFIGURATION);

export const getCanShowMore = state => {
    let concState = getPlayerRankingState(state);
    if (concState.firstSet == null) return false;
    if (concState.secondSet == null) return false;

    let positions = concState.selection.selectedPositions;

    let lengthTheBiggest = countfilterByPositions(
        (concState.firstSet || {items:[]}).items,
        positions,
    );
    if (
        lengthTheBiggest <=
        countfilterByPositions(concState.secondSet.items, positions)
    ) {
        lengthTheBiggest = countfilterByPositions(
            concState.secondSet.items,
            positions,
        );
    }

    let currentSet = concState.displayPage * SHOW_BATCH_COUNT;
    return currentSet < lengthTheBiggest;
};

export const countfilterByPositions = (set, positions) => {
    if (positions.length === 0) return set.length;
    return set.filter(
        item => positions.indexOf(item.player.positionCode) !== -1,
    ).length;
};
