import axios from 'axios-config';
import { Position } from './models/position';
import { Profile } from './models/profile'

class UserProfiles {
    positions: Array<Position>;
    profiles: Array<Profile>;
}

export class UserProfileService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getSquadUsersProfiles(squadId): Promise<UserProfiles> {
        const { data } = await axios.get(
            `api/Users/GetSquadUsersList/${squadId}`,
            this.axiosConfig
        );
        return data
    }

    public static async deleteUserProfile(userId: number): Promise<any> {
        return await axios.delete(
            `api/Users/${userId}`,
            this.axiosConfig
        )
    }

    public static async deleteUserRequest(userRequestId: number): Promise<any> {
        return await axios.delete(
            `api/Users/UserRequest/${userRequestId}`,
            this.axiosConfig
        )
    }
}

export default UserProfileService