import * as redux from 'redux';
import * as onboardingController from './onboarding/onboarding.controller'
import * as AgencyGridController from './main-page/agency-search/redux/agency-search.controller';
export class AgencyClubState {
    agencySearch: AgencyGridController.State;
    onboarding: onboardingController.State;
}

export function combineReducers() {

    let agencyClubReducer = {
        agencySearch: AgencyGridController.Reducer,
        onboarding: onboardingController.Reducer,
    };

    return redux.combineReducers(agencyClubReducer)
} 