import {FETCH_UNREAD_MESSAGES_COUNT} from "../actionTypes"


export class State {
    unreadMessageCount: number;
}

const initialState: State = {
    unreadMessageCount: 0
};

export default (liveData = initialState, action) => {
    switch (action.type) {
        case FETCH_UNREAD_MESSAGES_COUNT.SUCCESS: {
            return {
                ...liveData,
                unreadMessageCount: action.payload.data,
            };
        }
        default:
            return liveData;
    }
};
