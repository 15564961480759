// inspired by https://medium.com/code-monkey/client-side-form-validation-in-react-40e367de47ba
export default {
    transferFee: { min: 0, max: 500000000 },
    monthlyLoanFee: { min: 1, max: 5000000 },
    pitch: {
        disabledTransferFeeRange: { min: 1, max: 4999 },
        disabledLoanFeeRange: { min: 1, max: 999 },
    },

    prohibitedPlayerAdTransferFee: { min: 1, max: 49999 },
    prohibitedPlayerAdGrossSalary: { min: 1, max: 9999 },
    prohibitedPlayerAdMonthlyLoanFee: { min: 1, max: 999 },
    eaVersion: 'v2',

    lowTierAgencySubscriptionUid: '6e61810d-fe6f-4a98-8a54-bc07a9a8bf4c',
    lowTierAgencySubscriptionId: 275,
};
