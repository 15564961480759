import { ofType } from "redux-observable";
import { filter, map, debounceTime,  } from "rxjs/operators";
import { AnyAction } from 'redux'
import { notificationCreate } from "./notifications.actions";
import { parseAndTranslate } from "services/localization";

export const emitErrorNotificationEpic = (action$, state$) => {
    return action$.pipe(
        filter((action: AnyAction) => action.type.endsWith('--ERROR') && action.type.indexOf('LOGIN--ERROR') < 0),
        debounceTime(2000),
        map(() => notificationCreate({
            message: parseAndTranslate('[%notifications.somethingWentWrong%]'),
            level: 'error'
        })),
    );
};
