import { SearchPlayerItemForClub } from "./search-player-item-for-club";
import { SearchAgencyItemForClub } from "./search-agency-item-for-club";
import { SearchCoachItemForClub } from "./search-coach-item-for-club";

export class SearchResult<TSearchItemType> {
    public items: Array<TSearchItemType>;
    public totalResultCount: number;
    public totalPageCount: number;
    public currentPage: number;
}

export enum SearchItemType {
    Player = 1,
    Club = 2,
    Agency = 3,
    Coach = 4,
}

export type SearchItemForClub = SearchPlayerItemForClub | SearchAgencyItemForClub | SearchCoachItemForClub;