import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import myAds from './my-friendly-matches.reducer';
import activities from './activities.reducer';
import processingIds from './processing.reducer'

export default combineReducers({
    activities,
    myAds,
    processingIds
});

const path = state => state.friendlyMatches;

export const getMyFriendlyMatches = createSelector(path, ads => ads.myAds);

export const getMyFriendlyMatchesArray = createSelector(
    getMyFriendlyMatches,
    ads => ads.allIds.map(id => ads.byId[id]),
);

export const getSoonExpiredFriendlyAdsCount = createSelector(
    getMyFriendlyMatchesArray,
    ads => ads.filter(item => item.longTimeUpdate && item.active && !item.isExpired && !item.deleted).length
)

export const getAvailableFriendlyMatches = createSelector(
    path,
    ads => ads.activities,
);

export const getAvailableFriendlyMatchesArray = createSelector(
    getAvailableFriendlyMatches,
    ads =>
        ads.allIds
            .map(id => ads.byId[id])
            .sort(({ startAt: a }, { startAt: b }) => a.localeCompare(b)),
);

export const getInvitations = state => path(state).activities.invitations;

export const getAreas = state => path(state).myAds.areas;

export const getAvailability = state => path(state).myAds.availabilityBusyDates;


export const getMyArea = state => {
    return path(state).myAds.myArea;
};

export const getSquads = state => path(state).myAds.squads;

export const getSuggestion = state => state.matchedFriendlyAds;

export const getLatestCreatedMatchId = state =>
    path(state).myAds.latestCreatedMatchId;
