import { StateController } from 'utils/action-declaration';
import AgencySettingsService from 'api/agency/agent/agent-profile/agent-profile.service'
import { AgentProfileModel, UserEmailPreferencesModel } from 'api/agency/agent/agent-profile/agent-profile'
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class State {
    isLoading: boolean
    data: AgentProfileModel
}

const defaultState: State = {
    isLoading: false,
    data: null,
}

const stateController = new StateController<State>(
    "AGENCY/EDIT_PROFILE/USERS",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static load() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))

                const data: AgentProfileModel = await AgencySettingsService.getProfile()

                dispatch(stateController.setState({
                    isLoading: false,
                    data,
                }))

                dispatch(userActivityInsert({
                    PageName: 'Edit Profile [Notifications]',
                    Message: 'Opened Notifications',
                    PageType: PageType.EditProfile
                }))

            } catch (error) {
                dispatch(stateController.setState({ isLoading: false }))
            }

        }
    }

    public static save(emailPreferences: UserEmailPreferencesModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))

                const { firstName, lastName, userPositionName: userPosition } = Selectors.getAgencyProfile(getState())

                await AgencySettingsService.saveProfile({
                    firstName,
                    lastName,
                    userPosition,
                    emailPreferences,
                })

                dispatch(stateController.setState({ isLoading: false }))

                dispatch(userActivityInsert({
                    PageName: 'Edit Profile [Notifications]',
                    Message: 'Saved Changes',
                    PageType: PageType.EditProfile
                }))

            } catch (error) {
                dispatch(stateController.setState({ isLoading: false }))
            }

        }
    }
}

class Selectors {
    public static getAgencyNotfications = (state: AppState): State => state.agency.agent.agencySettings.notifications;
    public static getAgencyProfile = (state: AppState): AgentProfileModel => Selectors.getAgencyNotfications(state).data;
    public static getAgencyNotficationsData = (state: AppState): UserEmailPreferencesModel => Selectors.getAgencyProfile(state) && Selectors.getAgencyProfile(state).emailPreferences;
    public static isLoading = (state: AppState): boolean => Selectors.getAgencyNotfications(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};
