import React, { useEffect } from 'react';
import historyAccessor from 'history-accessor';

export default function ScrollMemory() {

    useEffect(() => {
        if (historyAccessor.action !== "POP") {   
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100)
        }
    }, [window.location.pathname]);

    return null
}