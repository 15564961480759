import axios from 'axios-config'
import { AgencyShortInfo } from 'api/core/agency-short-info';
import { AddAgencyConnectionRequest, ChangeAgencyConnectionDetailsRequest } from './models/add-agency-connection-request';

export class PlayerPreferenceService {
    private static axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    public static async trustedAgencies(): Promise<Array<AgencyShortInfo>> {
        const { data } = await axios.get(
            `api/v2/PayerPreference/VerifiedAgencies`,
            this.axiosConfig
        )
        return data
    }

    public static async addAgent(agent: AddAgencyConnectionRequest) {
        const { data } = await axios.post(
            `api/v2/PayerPreference/AddAgency`,
            agent,
            this.axiosConfig
        )
        return data
    }

    public static async changeAgentDetails(agent: ChangeAgencyConnectionDetailsRequest) {
        const { data } = await axios.post(
            `api/v2/PayerPreference/ChangeDetails`,
            agent,
            this.axiosConfig
        )
        return data
    }

    public static async removeAgency() {
        const { data } = await axios.post(
            `api/v2/PayerPreference/RemoveAgency`,
            null,
            this.axiosConfig
        )
        return data
    }
}