import axios from 'axios-config';
import { CoachConfirmAgencyAccessResponse, ConfirmAgentRequest } from './models';

export class CoachService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getConfirmAgencyAccess(): Promise<CoachConfirmAgencyAccessResponse> {
        const { data } = await axios.get(
            `api/Coach/GetConfirmAgencyAccess`,
            this.axiosConfig
        );
        return data
    }
    
    public static async confirmAgent(request: ConfirmAgentRequest) {
        await axios.post(
            `api/Coach/ConfirmAgent`,
            request,
            this.axiosConfig
        );
    }
}