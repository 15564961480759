import { combineReducers } from 'redux';
//import compose from 'lodash/fp/compose';
import { translate } from '../../../services/localization';

const INITIAL_STATE = {
    byKey: {
        GK: {
            key: 'GK',
            value: 'Goalkeeper',
            translationKey: 'positions.goalkeeper',
        },
        LB: {
            key: 'LB',
            value: 'Left Back',
            translationKey: 'positions.leftBack',
        },
        RB: {
            key: 'RB',
            value: 'Right Back',
            translationKey: 'positions.rightBack',
        },
        CB: {
            key: 'CB',
            value: 'Centre Back',
            translationKey: 'positions.centerBack',
        },
        DM: {
            key: 'DM',
            value: 'Defensive Midfielder',
            translationKey: 'positions.defensiveMidfielder',
        },
        CM: {
            key: 'CM',
            value: 'Central Midfielder',
            translationKey: 'positions.centralMidfielder',
        },
        AM: {
            key: 'AM',
            value: 'Attacking Midfielder',
            translationKey: 'positions.attackingMidfielder',
        },
        W: {
            key: 'W',
            value: 'Winger',
            translationKey: 'positions.winger',
        },
        F: {
            key: 'F',
            value: 'Forward',
            translationKey: 'positions.forward',
        },
    },
    allKeys: ['GK', 'LB', 'RB', 'CB', 'DM', 'CM', 'AM', 'W', 'F'],
};

const byKey = (byKey = INITIAL_STATE.byKey, action) => {
    switch (action.type) {
        default:
            return byKey;
    }
};

const allKeys = (keys = INITIAL_STATE.allKeys, action) => {
    switch (action.type) {
        default:
            return keys;
    }
};

export default combineReducers({
    byKey,
    allKeys,
});

//const path = state => state.entities.positions;

export const getPositions = state => {
    return state.entities.positions.allKeys.map(
        code => {
            let position = state.entities.positions.byKey[code];
            position.value = translate(position.translationKey)
            return position;
        },
    );
};

// export const getPositions = compose(
//     positions => positions.allKeys.map(code => positions.byKey[code]),
//     path,
// );
