import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';

class AgencyOnboarding {
    keyword: string;
    isLoading: boolean;
    agents: Array<any>;
}

const defaultState: AgencyOnboarding = {
    isLoading: false,    
    keyword: '',
    agents: [],
};

const stateController = new StateController<AgencyOnboarding>(
    'ADMIN_AGENCY_ONBOARDING',
    defaultState,
);

class Actions {
    public static dispose = () => {
        return async dispatch => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    };
}

class Selectors{

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyOnboarding as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};