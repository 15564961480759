import axios from 'axios-config';
import { ChangeSquadRequest, ChangeSquadResponse } from './model';

export class MultiAccountAccessService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async changeSquad(request: ChangeSquadRequest): Promise<ChangeSquadResponse> {
        const { data } = await axios.post(
            `api/MultiAccountAccess/ChangeSquad`,
            request,
            this.axiosConfig
        )
        return data;
    }
}
