import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Permissions as PermissionsService } from 'api/permission/permission.servise'

export const enum AccessRestrictedModalType {
    PlayerAvailabilityPermission = 1,
}

class AccessRestrictedModalState {
    isOpen: boolean;
    isBusy: boolean;
    step?: number;
    type?: AccessRestrictedModalType;
    UAPageName?: string;
    pageName?: string;
    playerId?: number | null;
}

const defaultState: AccessRestrictedModalState = {
    isOpen: false,
    isBusy: false,
    step: null,
    type: null,
    UAPageName: '',
    pageName: '',
    playerId: null,
}

const stateController = new StateController<AccessRestrictedModalState>(
    "CLUB/ACCESS_RESTRICTED_UPDGRADE_MODAL",
    defaultState
);

class Actions {

    public static close(isUserActivity?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const { UAPageName, playerId } = getState().accessRestrictedModal;
            let squadId = getState().auth.squadId;
            dispatch(stateController.setState({ isOpen: false }))
            if (Boolean(isUserActivity && UAPageName)) {
                switch (UAPageName) {
                    case 'Search [Highlight]':
                        dispatch(Actions.sendUserActivity(UAPageName, 'Closed Availability Restriction'));
                        break;
                    case 'Messaging [Restriction]':
                    case 'Player Ads [Restriction]':
                    case 'Search Bar [Restriction]':
                        dispatch(Actions.sendUserActivity(UAPageName, 'Close'));
                        break;
                    case 'Player Profile [Restriction]':
                        dispatch(Actions.sendUserActivity(UAPageName, 'Close', squadId, playerId ));
                        break;
                    default:
                        dispatch(Actions.sendUserActivity(UAPageName, 'Closed'));
                }
            }
        }
    }

    public static open(type: AccessRestrictedModalType, UAPageName?: string, pageName?: string, playerId?: number) {
        return async (dispatch) => {
            if (UAPageName) {
                dispatch(stateController.setState({ UAPageName }));
            }
            if (pageName) {
                dispatch(stateController.setState({ pageName }));
            }
            if (playerId) {
                dispatch(stateController.setState({ playerId }));
            }
            if (type == AccessRestrictedModalType.PlayerAvailabilityPermission) {
                dispatch(stateController.setState({ isOpen: true, type: type, step: 1 }));
            }
        }
    }

    public static getInTouch() {
        return async (dispatch, getState: () => AppState) => {
            const { UAPageName, pageName, playerId } = getState().accessRestrictedModal;
            let userId = getState().auth.userId;
            let squadId = getState().auth.squadId;
            dispatch(stateController.setState({ isBusy: true }));
            await PermissionsService.sendPermissionRequest(squadId, userId, "Get in touch", pageName);
            dispatch(stateController.setState({ isBusy: false, step: 2 }));

            switch (Boolean(UAPageName) && UAPageName) {
                case 'Search [Highlight]':
                    dispatch(Actions.sendUserActivity(UAPageName, 'Sent Upgrade Request'));
                    break;
                case 'Player Profile [Restriction]':
                    dispatch(Actions.sendUserActivity(UAPageName, 'Get in Touch', squadId, playerId));
                    break;
                default:
                    dispatch(Actions.sendUserActivity(UAPageName, 'Get in Touch'));
            }
        }
    }

    public static sendUserActivity = (pageName: string, message: string, clubId?: number, playerId?: number) => {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                PlayerId: playerId ? playerId : undefined,
                ClubId: clubId ? clubId : undefined,
            }));
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.accessRestrictedModal
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AccessRestrictedModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



