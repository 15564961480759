import styled from 'styled-components'

export default styled.div`
    .testimonial-slider {
        width: 100%;
        height: auto;
        border-radius: 15px;
        
        border: 1px solid #d5d5d5;
        position: relative;
        .slick-track {
            display: flex !important;
        }
        .slick-slide {
            height: inherit !important;
            > div {
                height: 100% !important;
                > div { 
                    height: 100% !important;
                }
            }
        }
        
        &__item {
            width: 100%;
            height: 100%;
            padding: 33px 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &__item-image {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 23px;
            flex-shrink: 0;

            > div {
                width: 80px;
                height: 80px;
                background-position: 50% 50%;
                border-radius: 50%;
                background-size: cover;
            }
        }
        &__item-description {
            color: #1d1c1d;
            h6 {
                font-weight: normal;
                font-size: 16px;
            }
            p {
                font-size: 16px;
                line-height: 1.75;
                margin-bottom: 0;
            }
        }
        &__arrow {
            color: #0E854C;
            font-size: 28px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 75px;
            height: 100%;
            cursor: pointer;
            z-index: 1;
            &.left {
                top: 0px;
                left: 0px;
                i {
                    transition: all 150ms ease-in-out;
                }
                &:hover i {
                    transform: translate(-20%, 0);
                }
            }
            &.right {
                top: 0px;
                right: 0px;
                i {
                    transition: all 150ms ease-in-out;
                }
                &:hover i {
                    transform: translate(20%, 0);
                }
            }
            
        }
    }

    @media (max-width: 750px) {
        .testimonial-slider {
            &__item {
                padding: 33px 50px;
            }

            &__arrow {
                width: 50px;
            }
        }
    }

    @media (max-width: 530px) {
        .testimonial-slider {
            &__item {
                flex-direction: column;
                justify-content: center;
            }

            &__item-image {
                align-self: center;
                margin: 0 0 20px 0;
            }

            &__item-description {
                text-align: center;
            }
        }
    }
`