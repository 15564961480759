import axios from 'axios-config';
import {
    EmailResultResponse,
    PasswordResultResponse,
    SetPasswordRequest,
    UpdateEmailRequest,
    UpdatePasswordRequest
} from './models'

export default class AgencyProfileService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async setPassword(request: SetPasswordRequest): Promise<PasswordResultResponse> {
        const { data } = await axios.post(`api/v2/UserPassword/Set`, request, this.axiosConfig)

        return data;
    }

    public static async updatePassword(request: UpdatePasswordRequest): Promise<PasswordResultResponse> {
        const { data } = await axios.post(`api/v2/UserPassword/Update`, request, this.axiosConfig)

        return data;
    }
    public static async updateEmail(request: UpdateEmailRequest): Promise<EmailResultResponse> {
        const { data } = await axios.post(`api/v2/ChangeEmail/Update`, request, this.axiosConfig)

        return data;
    }
    public static async confirmEmail(id: string): Promise<EmailResultResponse> {
        const { data } = await axios.post(`api/v2/ChangeEmail/ConfirmChange/${id}`, {}, this.axiosConfig)

        return data;
    }
    public static async checkResetPassword(token: string): Promise<any> {
        const { data } = await axios.post(`api/Users/CheckResetPasswordToken`,
            {token: token}, this.axiosConfig)

        return data;
    }

}