import { AppState } from "root.reducer";
import _ from "lodash";
import { StateController } from "utils/action-declaration";
import friendlyMatchesReportService from "api/admin/friendly-matches-report/friendly-matches-report.service";
import {
  FriendlyMatchModel,
  SelectOpponentModalState,
  FilterOptions,
  FriendlyMatchFilters
} from "api/admin/friendly-matches-report/models";
import { getPlayerSearchFilterCriteria } from "store/playerSearch/reducers/playerSearchFilterReducer";
import { PlayerSearchService } from 'api/player/player-search';
import { getOpponentIndexPrefix } from './utils';

class FriendlyMatchesReportState {
  isLoading: boolean;
  totalRecords: number;
  currentPage: number;
  recordsPerPage: number;
  friendlyMatches: Array<FriendlyMatchModel>;
  modalState: SelectOpponentModalState;
  filtersValues: FriendlyMatchFilters;
  filterOptions: FilterOptions;
  previousFilterSnapshot: FilterOptions;
}

const defaultModalState: SelectOpponentModalState = {
  isModalOpen: false,
  isFetching: false,
  processing: false,
  friendlyMatchId: null,
  opponentIndex: null,
  currentOpponentId: null,
  newOpponentId: null,
  currentOpponent: '',
  newOpponent: '',
  modalTitle: '',
  keyword: '',
  autosuggestionsOptions: []
}

const defaultFilterOptions: FilterOptions = {
  isActive: null,
  squadName: '',
  squadType: [],
  area: [],
}

const defaultState: FriendlyMatchesReportState = {
  isLoading: false,
  totalRecords: 0,
  currentPage: 1,
  recordsPerPage: 20,
  friendlyMatches: [] as Array<FriendlyMatchModel>,
  modalState: defaultModalState,
  filtersValues: {
    areas: [],
    squadTypes: [],
  },
  filterOptions: defaultFilterOptions,
  previousFilterSnapshot: defaultFilterOptions,
}

const stateController = new StateController<FriendlyMatchesReportState>(
  'ADMIN_V2/FRIENDLY_MATCHES_REPORT',
  defaultState
);

class Actions {

  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData() {
    return async (dispatch) => {
      try {
        await dispatch(Actions.getFriendlyMatches());
        await dispatch(Actions.getFiltersValues());
      } catch (err) {
        console.error(err);
      }
    };
  };

  public static getFriendlyMatches() {
    const wasFiltersChanged = (filterOptions: FilterOptions, previousFilterSnapshot: FilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const {
          currentPage,
          recordsPerPage,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          previousFilterSnapshot: {
            ...prevState.filterOptions,
          },
          currentPage: shouldDisposePagination ? 1 : currentPage
        })));

        const searchParameters = {
          itemsCount: recordsPerPage,
          pageNumber: shouldDisposePagination ? 1 : currentPage,
          active: filterOptions.isActive !== null ? filterOptions.isActive : undefined,
          squadName: filterOptions.squadName ? filterOptions.squadName : undefined,
          squadTypes: filterOptions.squadType.length > 0 ? filterOptions.squadType : undefined,
          area: filterOptions.area.length > 0 ? filterOptions.area : undefined,
        }

        const data = await friendlyMatchesReportService.getAllFriendlyMatches(searchParameters);

        const friendlyMatches = data.items.map(item => ({
          ...item,
          key: item.id
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          friendlyMatches,
          totalRecords: data.countOfItems,
        })));

      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static getFiltersValues() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const data = await friendlyMatchesReportService.getFiltersValues();
        const squadTypesExtended = [
          {
            id: -1,
            name: 'All',
            order: -1
          },
          ...data.squadTypes.sort((a, b) => a.order > b.order ? 1 : -1),
        ];
        const areasExtended = [
          {
            id: -1,
            name: 'All'
          },
          ...data.areas,
        ];

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          filtersValues: {
            squadTypes: squadTypesExtended,
            // temporary solution, to do verify on BE area id=271
            areas: areasExtended.filter(area => area.id !== 271) 
          }
        })));

      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static onPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        currentPage: pageNumber,
        recordsPerPage: pageSize
      })))

      dispatch(Actions.getFriendlyMatches());
    }
  }

  public static assignOpponent(friendlyMatchId: number, opponentId: number, opponentName: string | null, opponentIndex: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          modalState: {
            ...prevState.modalState,
            isFetching: true
          }
        })));
        await friendlyMatchesReportService.assignOpponent(friendlyMatchId, opponentId, opponentIndex);

        const newItems = [...Selectors.getFriendlyMatches(getState())];
        const opponentIndexPrefix = getOpponentIndexPrefix(opponentIndex);

        if (opponentId === 0) {
          let updatedItem = newItems.find((item) => item.id === friendlyMatchId);
          updatedItem[`${opponentIndexPrefix}OpponentSquadName`] = null;
          updatedItem[`${opponentIndexPrefix}OpponentSquadId`] = null;
        } else {
          let updatedItem = newItems.find((item) => item.id === friendlyMatchId);
          updatedItem[`${opponentIndexPrefix}OpponentSquadName`] = opponentName;
          updatedItem[`${opponentIndexPrefix}OpponentSquadId`] = opponentId;
        }

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          friendlyMatches: newItems,
        })));

        dispatch(Actions.closeModal());
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          modalState: {
            ...prevState.modalState,
            isFetching: false
          }
        })));
      }
    }
  };

  public static openModal(friendlyMatch: FriendlyMatchModel, opponentIndex: number) {
    return (dispatch) => {
      const opponentIndexPrefix = getOpponentIndexPrefix(opponentIndex);
      const currentOpponent = opponentIndexPrefix ? friendlyMatch[`${opponentIndexPrefix}OpponentSquadName`] : null;
      const currentOpponentId = opponentIndexPrefix ? friendlyMatch[`${opponentIndexPrefix}OpponentSquadId`] : null;

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        modalState: {
          ...prevState.modalState,
          opponentIndex,
          currentOpponentId,
          friendlyMatchId: friendlyMatch.id,
          isModalOpen: true,
          modalTitle: `${friendlyMatch.squadName} - Opponent ${opponentIndex}`,
          currentOpponent: currentOpponent ? currentOpponent : 'none'
        },
      })));
    }
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        modalState: {
          ...defaultModalState
        }
      })));
    }
  };

  public static onChangeKeyword(value: string) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        modalState: {
          ...prevState.modalState,
          keyword: value,
        }
      })));

      if (value) {
        Actions.loadFirstClubAutosuggestionsDebounceFunc(dispatch, value)
      } else {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          modalState: {
            ...prevState.modalState,
            autosuggestionsOptions: [],
            newOpponent: '',
            newOpponentId: null
          }
        })));
      }
    }
  };

  private static loadFirstClubAutosuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestions(keyword)), 1000)

  public static getClubAutosuggestions(keyword: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          modalState: {
            ...prevState.modalState,
            processing: true
          }
        })));
        const filter = getPlayerSearchFilterCriteria(getState());
        const autosuggestionsOptions = await PlayerSearchService.getPlayerAutosuggestions(0, 0, { ...filter, keyword }, true)
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          modalState: {
            ...prevState.modalState,
            autosuggestionsOptions: autosuggestionsOptions
          }
        })));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          modalState: {
            ...prevState.modalState,
            processing: false
          }
        })));
      }
    }
  };

  public static selectClub(clubName: string, squadId: number) {
    return (dispatch) => {
      dispatch(Actions.onChangeKeyword(clubName));
      dispatch(Actions.getClubAutosuggestions(clubName));

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        modalState: {
          ...prevState.modalState,
          newOpponent: clubName,
          newOpponentId: squadId
        }
      })));
    };
  };

  public static onChangeSquadName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          squadName: value
        }
      })))
    }
  }

  public static onChangeSquadType(value: any) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          squadType: value > -1 ? [ value ] : []
        }
      })));

      // dispatch(Actions.getFriendlyMatches());
    }
  }

  public static onChangeArea(value: any) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          area: value > -1 ? [ value ] : []
        }
      })));

      // dispatch(Actions.getFriendlyMatches());
    }
  }

  public static onChangeIsActive(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          isActive: value
        }
      })));

      // dispatch(Actions.getFriendlyMatches());
    };
  }

  public static applySearch() {
    return dispatch => dispatch(Actions.getFriendlyMatches())
  }

  public static disposeFilters() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
      dispatch(Actions.applySearch());
    }
  }
}

class Selectors {
  public static selectState = (state: AppState) => state.admin.friendlyMatchesReport;
  public static isLoading = (state: AppState): boolean => Selectors.selectState(state).isLoading;
  public static getFriendlyMatches = (state: AppState) => state.admin.friendlyMatchesReport.friendlyMatches;
  public static getFilterOptions = (state: AppState) => Selectors.selectState(state).filterOptions;
  public static getFilterValues = (state: AppState) => Selectors.selectState(state).filtersValues;
  public static getModalState = (state: AppState) => Selectors.selectState(state).modalState;
  public static isSelectOpponentModalOpen = (state: AppState) => Selectors.selectState(state).modalState.isModalOpen;
  public static getModalTitle = (state: AppState) => Selectors.selectState(state).modalState.modalTitle;
  public static getFriendlyMatchId = (state: AppState) => Selectors.selectState(state).modalState.friendlyMatchId;
  public static getOpponentIndex = (state: AppState) => Selectors.selectState(state).modalState.opponentIndex;
  public static getCurrentOpponent = (state: AppState) => Selectors.selectState(state).modalState.currentOpponent;
  public static getCurrentOpponentId = (state: AppState) => Selectors.selectState(state).modalState.currentOpponentId;
  public static getNewOpponent = (state: AppState) => Selectors.selectState(state).modalState.newOpponent;
  public static getNewOpponentId = (state: AppState) => Selectors.selectState(state).modalState.newOpponentId;
  public static getProcessingModal = (state: AppState) => Selectors.selectState(state).modalState.processing;
  public static getIsFetchingModal = (state: AppState) => Selectors.selectState(state).modalState.isFetching;
  public static getAutosuggestionsOptions = (state: AppState) => Selectors.selectState(state).modalState.autosuggestionsOptions;
  public static getAutosuggestionsKeyword = (state: AppState) => Selectors.selectState(state).modalState.keyword;

  public static getFilteredFriendlyMatches = (state: AppState) => Selectors.selectState(state).friendlyMatches;
  public static getTotalRecords = (state: AppState) => Selectors.selectState(state).totalRecords;
  public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
  public static getCurrentPage = (state: AppState) => Selectors.selectState(state).currentPage;

  public static isFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.filterOptions) === JSON.stringify(Selectors.getFilterOptions(state))
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  FriendlyMatchesReportState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
