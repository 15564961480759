import axios from 'axios-config';
import MySquadPlayer, { MySquadToBuy, MySquadToLoan } from './model/my-squad-player'
import { MySquadUpdatePlayerRequest } from './model/my-squad-update-player'

export default class VirtualSummitService {
  

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loadMySquadPlayers(): Promise<Array<MySquadPlayer>> {
        const { data } = await axios.get(
            `api/v2/MySquad/GetAll`,
            this.axiosConfig
        )
        return data;
    }

    public static async saveVideos(playerId: number, videos: Array<string>): Promise<any> {
        const { data } = await axios.post(
            `api/Players/${playerId}/SaveVideos`,
            videos.map(x => ({ url: x })),
            this.axiosConfig
        )
        return data
    }

    public static async updatePlayers(items: Array<MySquadUpdatePlayerRequest>) {
        const { data } = await axios.post(
            `api/v2/MySquad/UpdatePlayers`,
            items,
            this.axiosConfig
        )
        return data
    }

    public static async saveAvailability(playerId: number, toBuy: MySquadToBuy, toLoan: MySquadToLoan) {
        const { data } = await axios.post(
            `api/v2/MySquad/SetAvailability`,
            {playerId: playerId, toBuy: toBuy, toLoan: toLoan},
            this.axiosConfig
        )
        return data
    }

    public static async saveAvailabilityAndGetClubAds(playerId: number, toBuy: MySquadToBuy, toLoan: MySquadToLoan) {
        const { data } = await axios.post(
            `api/v2/MySquad/SetAvailabilityAndGetClubAds`,
            {playerId: playerId, toBuy: toBuy, toLoan: toLoan},
            this.axiosConfig
        )
        return data
    }

    public static async quickSearchFlush() {
        const { data } = await axios.get(
            `api/v2/MySquad/QuickSearchFlush`,
            this.axiosConfig
        )
        return data;
    }

    public  static async deletePlayer(playerId: number, reason: string) {
        const { data } = await axios.post(
            `api/v2/MySquad/RemovePlayer`,
            {playerId: playerId, reason: reason},
            this.axiosConfig
        )
        return data
    }

    public static async loadRecommendedPlayersBanner(): Promise<any> {
        const { data } = await axios.get(
            `api/v2/MySquad/ShowBanner`,
            this.axiosConfig
        )
        return data;
    }
    public static async hideRecommendedPlayersBanner(): Promise<any> {
        const { data } = await axios.post(
            `api/v2/MySquad/CloseBanner`,
            this.axiosConfig
        )
        return data;
    }

    public static async matchMakerFetch(squadId: number, userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/MySquad/GetMySquadRecommendationPerPlayer/${squadId}/${userId}`,
            this.axiosConfig
        )
        return data;
    }
}