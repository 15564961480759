import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import {
  PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_FALSE,
  PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS
} from 'store/actionTypes';
import historyAccessor from 'history-accessor';
import { playerPathsV2 } from 'routes/paths';
import AgencyFinderService from "api/player-v2/agency-finder.service";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAuth } from 'store/auth/authReducer';
import {Actions as SettingsActions} from 'pages/player-v2/settings-page/settings-page.controller';

export enum RepresentationOptionEnum {
  RepresentedByAgency = 1,
  NotRepresentedByAgency = 2,
  RepresentedBySelf = 3,
}

export enum RepresentationSteps {
  SelectOption = 1,
  SelfRepresentation = 2,
}

class State {
  isLoading: boolean;
  selectedOption: RepresentationOptionEnum | null;
  step: RepresentationSteps;
}

const defaultState: State = {
  isLoading: false,
  selectedOption: null,
  step: RepresentationSteps.SelectOption,
}

const stateController = new StateController<State>(
  'PLAYERV2/REPRESENTATION-SCREEN',
  defaultState
)

class Actions {

  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    }
  }

  public static onSelectOption(option: RepresentationOptionEnum, label: string) {
    return (dispatch) => {
      dispatch(Actions.sendUserActivity(`Selected Representation: ${label}`, 'Representation'));
      dispatch(stateController.setState({ selectedOption: option }));
      dispatch(Actions.onSubmit(option))
    }
  }

  public static onSubmit(selectedOption: RepresentationOptionEnum) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isLoading: true }));
      try {
        if (selectedOption === RepresentationOptionEnum.RepresentedByAgency) {
          await dispatch(Actions.onRepresentationFinish())
          historyAccessor.push(playerPathsV2.inviteAgency);
        }
        if (selectedOption === RepresentationOptionEnum.NotRepresentedByAgency) {
          dispatch({ type: PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS, payload: { data: true } });
  
          const isActivatorToggleChecked = getState().playerV2.settings.isActivatorToggleChecked;
          await dispatch(Actions.onRepresentationFinish());
         
          dispatch(SettingsActions.setIsActivatorToggleChecked(isActivatorToggleChecked));
          dispatch(SettingsActions.onContinue());
          
          historyAccessor.push(playerPathsV2.agentFinder);
        }
        if (selectedOption === RepresentationOptionEnum.RepresentedBySelf) {
          dispatch(stateController.setState({ step: RepresentationSteps.SelfRepresentation }));
        }

      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static onSearchAgency() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isLoading: true }));
      try {
        dispatch(Actions.sendUserActivity('Clicked Search For Trusted Agency', 'Representation/Want More Transfer Interest'));
        await dispatch(Actions.onRepresentationFinish())
        historyAccessor.push(playerPathsV2.agentFinder);
      } catch (err) {
        console.error(err);
      }
      finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static exitToHomepage() {
    return async (dispatch) => {
      dispatch(Actions.sendUserActivity('Clicked No Thanks', 'Representation/Want More Transfer Interest'));
      await dispatch(Actions.onRepresentationFinish())
      historyAccessor.push('/');
    }
  }

  public static onRepresentationFinish() {
    return async (dispatch, getState: () => AppState) => {
      const { userId } = getAuth(getState());
      try {
        await AgencyFinderService.saveToTempDataTableValues(userId);
        dispatch({ type: PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_FALSE });
      } catch (err) {
        console.error(err);
      }
    }
  }

  public static sendUserActivity(message: string, pageName: string) {
    return (dispatch, getState: () => AppState) => {
      const { playerId } = getAuth(getState());
      dispatch(userActivityInsert({
        Message: message,
        PageName: `Onboarding [${pageName}]`,
        PlayerId: playerId
      }))
    }
  }
}

class Selectors {
  public static getRoot = (state: AppState): State => state.playerV2.representationScreen;
  public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
  public static getSelectedOption = (state: AppState) => Selectors.getRoot(state).selectedOption;
  public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  State as State,
  Actions as Actions,
  stateController as Controller,
  Selectors as Selectors
};
