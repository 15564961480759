import React from 'react';
import styled, { css } from 'styled-components'
import Video from 'app/events/components/authorized-event-page/common/video/video';
import Thumb from 'media/images/events/2024-march-rome/rome-event-thumb.png';

type Props = {
    hideBottomLine?: boolean;
}

const PrevEventVideo = (props: Props) => {
    return (
        <Section>
            <h2>The best of Rome 2024:</h2>
            <br />
            <Video
                trackUserActivity={() => { }}
                content={{
                    backgroundThumb: Thumb,
                    videoUrl: 'https://player.vimeo.com/video/935376140'
                }}
            />
        </Section>
    );
}

const Section = styled.div`
    margin-top: 60px;
    margin-bottom: 60px;

    h2 {
        font-size: 40px;
        font-weight: bold;
    }
`;

export default PrevEventVideo;