import { PitchTypeEnum } from './pitch-type.model';
import { AdTypeEnum } from './ad-type.model';
import { PlayerAdModel } from 'api/player-ad-v2/models/player-ad-with-counters'

export interface PitchesAndAds {
    pitches: PitchModel[];
    playerAds: PlayerAdModel[];
}

export class PitchModel {
    pitchId: number;
    playerAdId: number;
    costInfo: PitchCostInfoModel;
    player: RelatedPitchPlayerModel;
    insertedAt: Date;
    testInterest: TestInterestInfoModel;
    saved: boolean;
    interestDeclared: boolean;

    adType?: AdTypeEnum;
    pitchType?: PitchTypeEnum;
    uid?: string;
    type: PitchType;
    agentInfo?: AgencyInfo;
    pitchRequirements?: PitchRequirementsModel;
	pitchedUserName?: string;
    pitchTexts?: PitchTextsModel;
    isAdAnonymised: boolean;
    createdBySquad: { clubId: number, name: string }
}

export class AgencyInfo {
    agencyAreaId?: number | null;
	agencyId?: number | null;
	agencyName?: string | null;
	agentId?: number | null;
	agentMessage?: string | null;
	agentName?: string | null;
	videos?: string[] | null;
}

export enum PitchType {
    Club = 0,
    Agency = 1
}

export class PitchCostInfoModel {
    loan: {
        price: number;
        formattedComma: string;
        formattedKMB: string;
    };
    sale: {
        price: number;
        formattedComma: string;
        formattedKMB: string;
    };
    grossSalary: {
        price: number;
        formattedComma: string;
        formattedKMB: string;
    };
    sellOn: number;
    currencyId: number;
}

export class RelatedPitchPlayerModel {
    id: number;
    photo: string;
    age: number;
    height: number;
    englishFirstName: string;
    englishLastName: string;
    englishShortName: string;
    birthArea: {
        id: number;
        name: string;
        shortName: string;
    };
    passportArea: {
        id: number;
        name: string;
        shortName: string;
    };
    firstPositionCode?: string;
    secondPositionCode?: string;
    clubInfo: ClubInfoModel;
    marketValue?: number;
    marketValueString?: string;
    rating?: number;
    positionRole?: string;
    positionRoleDescription?: string;
}

export class ClubInfoModel {
    id: string;
    clubId?: number;
    onLoanSquadName: string;
    onLoanSquadLogo?: string;
    contractExpiration: Date;
    onLoanSquadTillDate: Date;
    parentSquad: {
        id: number;
        name: string;
        shortName: string;
    };
    isFreeAgent: boolean;
    latestSquad: { id: number, name: string; shortName: string; };
    parentSquadCompetitionName: string;
    parentSquadCompetitionCountry: string;
    logo: string;
}

export class TestInterestInfoModel {
    remainingDays: number;
}
export class PitchRequirementsModel {
    ageRequirements: string | null;
	heightRequirements?: number | null;
	leftFootRequirementPresent?: boolean | null;
	isAgeMatch: boolean | null;
	isGBEMatch: boolean | null;
	isHeightMatch: boolean | null;
	isLeftFootMatch: boolean | null;
    positionRoleRequirements?: boolean | null;
	isPositionRoleMatch: boolean | null;
}
export class PitchTextsModel {
    greenTexts?: string[];
	redText?: string;
}

