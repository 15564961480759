import { UserModel } from '../users/models'


export interface AdminPermissionsResponseModel {
    userPermissions: Array<AdminPagePermissionsModel>
    adminPages: Array<AdminPageModel>
    adminPageGroups: Array<AdminPageGroupModel>
    areas: Array<AreaModel>
    supervisors: Array<UserModel>
}

export interface AdminPageModel {
    id: number
    code: string
    name: string
    order: number
    permissionType: PermissionTypeEnum
    columnCodes: Array<AdminPageColumnModel>
    isForAreas: boolean
    isForColumns: boolean
    isForHistoryDepth: boolean
}

export enum PermissionTypeEnum {
    Page = 0,
    AdminWithCountries = 1,
    Club = 2,
    ClubWithLeagues = 3,
    AdminWithColumns = 4,
    ClubPlayerCost = 5,
    User = 6,
    UserWithMessages = 7,
    AdminHistoryDepth = 8
}

export enum PermissionEnum {
    AdminsPermissions = 'AdminsPermissions',
    Contacts = 'Contacts',
    SeeUsers = 'SeeUsers',
    SeeStaff = 'SeeStaff',
    SoldPlayers = 'SoldPlayers',
    FriendlyMatches = 'FriendlyMatches',
    UserContactInformation = 'UserContactInformation',
    ActivateDeleteusers = 'ActivateDeleteusers',
    RegisterUsers = 'RegisterUsers',
    UserRequests = 'UserRequests',
    GenerateLinks = 'GenerateLinks',
    ExpertCards = 'ExpertCards',
    AdminClubs = 'AdminClubs',
    AdminClubReonboarding = 'AdminClubReonboarding',
    ClubPermissions = 'ClubPermissions',
    AdminMultiAccountManagement = 'AdminMultiAccountManagement',
    SalesSupervisor = 'SalesSupervisor',
    NewSquadsDownload = 'NewSquadsDownload',
    Event = 'Event',
    AgentRequests = 'AgentRequests',
    AdminAgencyPayments = 'AdminAgencyPayments',
    AdminAgencies = 'AdminAgencies',
    AdminPlayerVerification = 'AdminPlayerVerification',
    AgencyPermissions = 'AgencyPermissions',
    AdminAgencyOnboard = 'AdminAgencyOnboard',
}

export interface AdminPageColumnModel {
    id: number
    permissionId: number
    columnCode: string
    columnName: string
}

export interface AdminPageGroupModel {
    id: number
    name: string
    order: number
    pages: Array<AdminPageModel>
}

export interface AdminPagePermissionsModel {
    ActivateDeleteusers: boolean
    AdminAgencies: boolean
    AdminAgencyOnboard: boolean
    AdminAgencyPayments: boolean
    AdminClubs: boolean
    AdminPlayerVerification: boolean
    AdminPlayers: PermissionsModel
    AdminsPermissions: boolean
    AgencyCustomPitch: boolean
    AgencyImpersonate: PermissionsModel
    AgencyPermissions: boolean
    AgentRequests: boolean
    AllPitchesReport: PermissionsModel
    AllUsers: PermissionsModel
    ChangePlayerSquad: boolean
    ClubAds: boolean
    ClubPermissions: boolean
    ConfimationLink: boolean
    Contacts: boolean
    DeclareInterestReport: PermissionsModel
    Event: boolean
    ExpertCards: boolean
    FriendlyMatches: boolean
    FriendlyProposals: boolean
    GenerateLinks: boolean
    HomePageHighPriorityPlayerAvailabilityHidden: boolean
    HomePagePerfPlayerAvailabilityHidden: boolean
    HomePagePerformanceFriendlyAdsAmountHidden: boolean
    HomePagePerformancePlayerPitchesAmountHidden: boolean
    HomePage_HighPriorityRecommendationHidden: boolean
    HomePage_PerformanceIndicatorHidden: boolean
    HomePage_PlayerTrendingHidden: boolean
    HomePage_WellD_AddUserHidden: boolean
    HomePage_WellD_FriendlyMatchHidden: boolean
    HomePage_WellD_PlayerAvailabilityHidden: boolean
    HomePage_WellD_PlayerPitchHidden: boolean
    Impersonate: PermissionsModel
    MultisourceManagement: boolean
    NewSquadsDownload: boolean
    PitchHidden: boolean
    PlayerProposals: boolean
    RegisterUsers: boolean
    RegisterUsersCountries: PermissionsModel
    ResetPasswordLink: boolean
    SalesSupervisor: boolean
    SeeUsers: boolean
    SoldPlayers: boolean
    SquadHidden: boolean
    SquadLeague: boolean
    SuccessStories: PermissionsModel
    UpgradeBanner: PermissionsModel
    UserContactInformation: boolean
    UserPermissions: boolean
    UserRequests: boolean
    UsersActivity: PermissionsModel
    allowedAreas: { [key: string]: Array<number> }
    allowedAssignedClubs: { [key: string]: Array<number> }
    allowedColumns: { [key: string]: string[] }
    allowedHistoryDepths: { 35: number }
    allowedPageCodes: Array<string>
    allowedUserActivityHistoryMonths: number | null
    isAssignedClubs: boolean
    isFirst: boolean
    isLast: boolean
    key: number
    rowSpan: number
    staffTypeId: number | null
    staffTypeName: string
    userId: number
    userName: string
}

export interface PermissionsModel {
    allowedAreas: Array<number>
    allowedAssignedClubs: Array<number>
    isAssignedClubs: boolean
    isPermitted: boolean
}

export interface AreaModel {
    id: number
    code: string
    name: string
    phoneCode: string
    mask: string
    competitions: Array<CompetitionModel>
}

export interface CompetitionModel {
    id: number
    apiId: number
    areaId: number
    name: string
    format: string
    type: string
    category: string
    male: boolean
    divisionLevel: number
    competitionToSquadCompetitionList: Array<CompetitionToSquadCompetitionListModel>
    seasons: Array<SeasonModel>
}

export interface CompetitionToSquadCompetitionListModel {
    id: number
    competitionId: number
    competition: CompetitionModel
}

export interface SeasonModel {
    id: number
    apiId: number
    name: string
    startDate: Date | string
    endDate: Date | string
    active: boolean
    competitionId: number
    competitionName: string
    countryId: number
}

export enum AdminPermissionsModalTabTypeEnum {
    AreasSelectTab= 0,
    ClubsSelectTab = 1,
    AllAreasSelectedTab = 2,
}
export interface AdminPermissionsModalState {
    isModalOpen: boolean
    loading: boolean
    supervisors: Array<any>
    areas: Array<any>
    userId: number
    permissionId: number
    windowTitle: string
    isAllAreasSelected: boolean
    tabKey: number
    processingTabCheckboxIds: number[]

}

export interface ClubsTabState {
    keyword: string
    filteredSupervisorsList: Array<any>
    selectedSupervisors: Array<any>
    processingSupervisorsIds: Array<any>
}

export interface AreasTabState {
    keyword: string
    filteredAreasList: Array<any>
    selectedAreas: Array<any>
    processingAreasIds: Array<any>
}