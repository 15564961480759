import axios from 'axios-config';

export default class AgencyPlayerSwapService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    // To add a test mode for credit swap, use dateTest, 'x-date': dateTest? dateTest : new Date().toISOString(),
    public static async swapOutPlayers(playerInId: number, playerOutId: number, shareInsights: boolean) {
        await axios.post(
            `api/AgencySwapSlot/SwapOutPlayers`,
            {
                playerInId,
                playerOutId,
                shareInsights
            },
            this.axiosConfig)
    }
    // public static async swapOutPlayers(playerInId: number, playerOutId: number, shareInsights: boolean, dateTest?: string) {
    //     await axios.post(
    //         `api/AgencySwapSlot/SwapOutPlayers`,
    //         {
    //             playerInId,
    //             playerOutId,
    //             shareInsights
    //         },
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-date': dateTest? dateTest : new Date().toISOString(),
    //             }
    //         }
    //     )
    // }

    // To add test mode for swap use 'x-date': dateTest? dateTest : new Date().toISOString(),
    public static async dismissAnnualCreditSwapBanner() {
        await axios.post(`api/AgencySwapSlot/DismissAnnualCreditSwapBanner`, null, this.axiosConfig)
    }
    // public static async dismissAnnualCreditSwapBanner(dateTest?: string) {
    //     await axios.post(`api/AgencySwapSlot/DismissAnnualCreditSwapBanner`,
    //         null,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-date': dateTest? dateTest : new Date().toISOString(),
    //             }
    //         }
    //     )
    // }
}