import axios from 'axios-config';
import { CountryWithAgenciesModel, CountryWithAgenciesResponse, PlayerAdModel, AgencyAdRequest, AgencyAdPermissionsRequest, AgencyInvitationRequest } from './onboarding-models';

export default class AgencyOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getCountriesWithAgencies(): Promise<Array<CountryWithAgenciesModel>> {
        const { data } = await axios.get<CountryWithAgenciesResponse>(
            `api/v2/Agency/GetCountryAgencies`,
            this.axiosConfig
        )
        let result: Array<CountryWithAgenciesModel> = [];
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const element = data[key];
                result.push({
                    name: key, agencies: element.map(i => {
                        return { id: i.id, name: i.name, agentsNumber: i.amountOfPlayers, price: i.totalMarketValue }
                    })
                })
            }
        }
        return result;
    }

    public static async getExistingAds(): Promise<Array<PlayerAdModel>> {
        const { data } = await axios.get<Array<PlayerAdModel>>(`api/v2/PlayerAd/GetAllLoansAndBuyWithGrossSalary`, this.axiosConfig)
        return data;
    }

    public static async createAds(request: AgencyAdRequest): Promise<any> {
        await axios.post(
            `api/v2/AgencyAd/CreateAdsAndAgencyAdPermissions`,
            request,
            this.axiosConfig
        );
    }

    public static async shareAds(request: AgencyAdPermissionsRequest): Promise<any> {
        await axios.post(
            `api/v2/AgencyAdPermissions/CreatePermissions`,
            request,
            this.axiosConfig
        );
    }

    public static async createAgencyInvitation(request: AgencyInvitationRequest): Promise<string> {
        const { data } = await axios.post(
            `api/v2/Agency/AgencyInvitationsCreate`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async setUpAgencyFeatures(): Promise<string> {
        const { data } = await axios.post(
            `api/v2/Agency/setUpAgencyFeatures`,
            this.axiosConfig
        );
        return data;
    }
}