import React from 'react'
import { connect } from 'react-redux';
import { getTumbnailUrl } from 'utils/video-links';
import ReactPlayer from 'react-player';
import Thumb from 'media/images/events/event-replay-thumbnail.png';
import PlayVimeoIcon from 'media/images/events/play-vimeo-icon.png';
import Presenter from 'media/images/events/winter-deal-day-presenter.png';
import Speaker1 from 'media/images/events/Arne-Friedrich-Large.png';
import Speaker2 from 'media/images/events/Comolli.png';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import DividerLine from '../divider-line/divider-line';

class ReplayProps {
    autoplay?: boolean;
    trackUserActivity?: () => void;
}

const Replay = (props: ReplayProps) => {
    return (
        <div className="event-video-replay">
            <DividerLine noTopMargin title="Virtual Summit Replay"/>
            <div className="iframe-container">
                <VimeoPlayer url="https://player.vimeo.com/video/643724978" trackUserActivity={props.trackUserActivity} autoplay={props.autoplay} />
            </div>
        </div>

    )
}

class VimeoPlayerProps {
    url: string;
    autoplay: boolean;
    trackUserActivity: () => void
}

class VimeoPlayer extends React.Component<VimeoPlayerProps, any> {
    state = {
        isPlaying: false,
        isUserActivityTracked: false,
    }

    static getDerivedStateFromProps(props) {
        console.log(props)
    }

    getThumb = async () => {
        const thumb = await getTumbnailUrl(this.props.url);
        this.setState({ thumb: thumb })
    }

    componentDidMount() {
        this.getThumb();

        if (this.props.autoplay) {
            setTimeout(() => {
                this.setState({ isPlaying: true })
            }, 200) // Time required for <ReactPlayer /> to be ready to play video
        }
    }

    render() {

        const { url } = this.props
        const { isPlaying } = this.state

        return (
            <div>
                <div className={`video`}>
                    {!isPlaying && (
                        <div onClick={() => {
                            this.setState({ isPlaying: !this.state.isPlaying }, () => {
                                if (!this.state.isUserActivityTracked && this.props.trackUserActivity) {
                                    this.props.trackUserActivity()
                                    this.setState({ isUserActivityTracked: true })
                                }
                            })
                        }}>
                            <img className="tumbnail" src={Thumb} alt="video tumbnail" />
                            <div className="speakers">
                                <img className="speaker-1" src={Speaker1} alt="speaker photo"></img>
                                {/* <img className="speaker-2" src={Speaker2} alt="speaker photo"></img> */}
                            </div>
                            <img className="presenter" src={Presenter} alt="presenter photo"></img>
                            <img className="play-btn" src={PlayVimeoIcon} ></img>
                        </div>
                    )}

                    <ReactPlayer
                        playing={isPlaying}
                        onPause={() => this.setState({ isPlaying: false })}
                        controls={true}
                        style={isPlaying ? { width: '100%', height: '100%' } : { display: 'none', width: '100%', height: '100%' }}
                        url={url}
                    />
                </div>
            </div>
        )
    }
}

export default connect(null, { userActivityInsert: userActivityInsert })(Replay)