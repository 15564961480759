import { SquadShortInfo } from 'api/core/squad-short-info';
import { CoachDeclareInterestDefinition } from "./declare-interest-definition";
import { AgencyShortInfo } from 'api/core/agency-short-info';
import { IdName } from 'api/core/id-name';

export interface SearchCoachItemForClub {
    staffId: number,
    shortName: string,
    isFreeAgent: boolean,
    squad: SquadShortInfo,
    lastClub: string,
    licenseBadge: string,
    age: number,
    nationality: string,
    languages: string,
    preferredFormation: string,
    playingStyle: string,
    experienceInMonths: number,
    greenTexts: string[],
    redText: string,
    rating: number,
    suitability: number,
    experienceInMonthsFormatted: string,
    position: string,
    declareInterest: CoachDeclareInterestDefinition;
    agency: AgencyShortInfo;
    agencyCoachDisplayInfo: AgencyCoachDisplayInfo;
}

export interface AgencyCoachDisplayInfo {
    displayStatus: DisplayCoachStatus;
    representedBy: IdName;
    status: CoachStatus;
    isVerifiedByCurrentAgency: boolean;
}

export enum DisplayCoachStatus {
    ButtonShowOpportunities = 1,
    ButtonViewCoachProfile = 2,
    ButtonGetCoachVerified = 3,
    ButtonClaimRepresentation = 4,
    LabelRepresentedBy = 5,
    LabelVerificationPending = 6,
    ButtonContactCoach = 7,
    ButtonContactCoachMessageSent = 8,
    Empty = 9
}

export enum CoachStatus {
    None = 0,
    PremiumCoach = 1,
    ClaimedNotVerified = 2,
    RegularCoach = 3,
}