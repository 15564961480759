import { IdName } from "api/core/id-name";
import { SquadShortInfo } from "api/core/squad-short-info";
import { Profile } from "api/user-profile/models/profile";

export interface FilterOptions {
    sortOrder: string;
    isSortOrderAscending: boolean;
    page: number;
    recordsPerPage: number;
    userName: string;
    clubName: string;
    areaName: string;
    positionName: string;
    userTypeName: string;
    isRegistrationFinished: boolean;
    isFriendlyRelated: boolean;
    isActive: string;
    isEvent: boolean;
    isDM: boolean;
    isTermsAgreed: boolean;
    userId: number;
    email: string;
    mobileDevice: string;
    supervisorId: string;
    date: string;
    phone: string;
}

export interface SupervisorItem {
    value: string;
    name: string
}

export interface AllUsersResponse {
    output: Array<UserModel>
    currentPageNumber: number;
    pageCount: number;
    rowCount: number;
    hasResult: boolean;
}


export interface UserModel {
    id: number;
    agentId: number | null;
    deletedAt: Date | string | null;
    name: string;
    firstName: string;
    lastName: string;
    clubId: number;
    clubName: string;
    shortClubName: string;
    areaName: string;
    positionName: string;
    userTypeName: string;
    userTypeId: number;
    isMasterUser: boolean;
    isAdminUser: boolean;
    isFriendlyMatchesOnly: boolean;
    email: string;
    phone: string;
    phoneCode: string;
    phoneCodeAreaId: number | null;
    isDM: boolean | null;
    phoneFormatted: string;
    finishedRegistration: boolean;
    termsAgreed: boolean;
    active: boolean;
    createdAt: Date | string;
    permissions: string[];
    allowedMessageTypes: MessageTypeEnum[];
    mobileDevice: string;
    isFriendlyRelated: boolean;
    isEvent: boolean;
    supervisorName: string;
    phoneMask: string;
    lockCount: number;
    lockTime: Date | string | null;
    clubAccessType: ClubAccessType | null;
    multiAccessSquads: IdName[];
}

export enum MessageTypeEnum {
    ChatMessage = 1,
    PlayerInterest = 2,
    FriendlyInterest = 3,
    PlusPitchMessage = 4,
    FriendlyInvitation = 5,
    PitchMessage = 6,
    SuperPitchMessage = 7,
    ClubAgencyChatMessage = 8,
    ClubAgencyPlayerInterest = 9,
    WelcomeMessageToAgency = 10,
}

export interface AllUsersCriteria {
    isPlayersVisible: boolean;
    isStaffVisible: boolean;
    hasMultiAccountManagementAccess: boolean;
}
export interface AccountAccessTabState {
    isLoading: boolean;
    keyword: string;
    autosuggestProcessing: boolean;
    clubsList: MultiAccountAccessItemModel[];
    suggestions: Array<SuggestionItemModel>;
    selectedClubsList: MultiAccountAccessItemModel[];
    isRemoveClubModalOpen: boolean;
    removingClubId: number;
}

export enum ClubAccessType {
    OneClub = 1,
    MultiClub = 2
}

export interface UserProfileAdminResponse {
    profile: Profile;
    squads: Array<MultiAccountAccessItemModel>;
}

export interface MultiAccountAccessItemModel {
    squad: SquadShortInfo;
    isMainSquad: boolean;
}

export class SuggestionItemModel {
    id: number;
    shortName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    photo: string;
    parentSquadId?: number;
    parentSquadName: string;
    currentSquadId?: number;
    currentSquadName: string;
    englishFirstName: string;
    englishLastName: string;
    englishShortName: string;
    suggestionType: SuggestionType
    firstPositionName: string;
    secondPositionName: string;
    firstPositionCode: string;
    secondPositionCode: string;
    competitionId?: number;
    competitionName: string;
    competitionCountry: string;
    countryId: string;
    countryName: string;
    isRestricted: boolean;
    available: boolean;
}

export enum SuggestionType {
    Empty = 0,
    Player = 1,
    Squad = 2,
    League = 3,
    Agency = 4,
}

export enum EditUserTabKeyEnum {
    Profile = 'Profile',
    Notifications = 'Notifications',
    AccountAccess = 'Account Access',
}