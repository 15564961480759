import axios from 'axios-config';

export default class HubspotChatService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getChatToken(firstName: string, lastName: string, email: string): Promise<string> {
        const response = await axios.post(
            `api/Users/GetHsToken`,
            { firstName, lastName, email, },
            this.axiosConfig
        )
        return response.data
    }
}