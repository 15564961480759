import { StateController } from 'utils/action-declaration';
import { LeaguesCoverageDataModel } from 'api/agency/club/agency-profile/models/leagues-coverage.model';

class LeaguesCoverageState {
    items: LeaguesCoverageDataModel;
}

const defaultState: LeaguesCoverageState = {
    items: []
}

const stateController = new StateController<LeaguesCoverageState>(
    'AGENCY_PROFILE/LEAGUES_COVERAGE_CHART',
    defaultState
)

class Actions {
    public static setDataToState(data: LeaguesCoverageDataModel) {
        return async dispatch => {
            dispatch(stateController.setState({ items: data }))
        }
    }
}

class Selectors {
    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LeaguesCoverageState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};