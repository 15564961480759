import { createElement } from 'react'
import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
// import SpeakerCard from './speaker-card/speaker-card-box';
import HprContent from './hpr/hpr-content';
import HprTitle from './hpr/hpr-title';

const title = "TransferRoom Summit São Paulo";

const eventSettings: EventInfoModel = {
    shortTitle: "São Paulo Summit",
    title: title,
    shortEventDates: "19-20th June 2023",
    eventDates: "19-20th June 2023",
    location: "São Paulo, BR",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'GMT-3',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        title: createElement(HprTitle, { title: 'TransferRoom Summit - São Paulo'}),
        userActivityPageName: "Home [TransferRoom Summit São Paulo]",
        description: createElement(HprContent),
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: false,
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: false,
    }
};

export default eventSettings;