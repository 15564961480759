import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import { Actions as PlayerAvailableActions } from './players-available-stepper/players-available-stepper.controller';
import {
    Actions as PitchingPlayerActions,
    Selectors as PitchingPlayerSelectors,
    PitchingPlayersSteps
} from './pitching-players-stepper/pitching-players-stepper.controller';
import userActivityInsert from "app/user-activity/actions/user-activity.actions";
import { ReonboardingService } from 'api/club/reonboarding/reonboarding.service';
import { Selectors as PitchingPlayersSelectors } from 'pages/club/reonboarding-flow/pitching-players-stepper/pitching-players-stepper.controller';

export enum ReonboardingSubsteppers {
    PlayersAvailable = 1,
    PitchingPlayers = 2,
}

class State {
    isLoading: boolean;
    isCongratulationScreen: boolean;
    pageName: string;
    activeStepper: ReonboardingSubsteppers;
    isHeaderHide: boolean;
    isTransferWindowReonboarding: boolean;
}

const defaultState: State = {
    isLoading: false,
    isCongratulationScreen: false,
    pageName: '',
    activeStepper: ReonboardingSubsteppers.PlayersAvailable,
    isHeaderHide: false,
    isTransferWindowReonboarding: false,
}

const stateController = new StateController<State>(
    'CLUB/REONBOARDING-FLOW',
    defaultState
);

class Actions {
    public static init(isTransferWindowReonboarding: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isTransferWindowReonboarding: isTransferWindowReonboarding }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static disposeReonboardingSteps() {
        return (dispatch) => {
            dispatch(Actions.dispose());
            dispatch(PlayerAvailableActions.dispose());
            dispatch(PitchingPlayerActions.dispose());
        }
    }

    public static setActiveStepper(stepper: ReonboardingSubsteppers) {
        return (dispatch) => {
            dispatch(stateController.setState({ activeStepper: stepper }));
        }
    }

    public static setActivePageName(pageName: string) {
        return (dispatch) => {
            dispatch(stateController.setState({ pageName: pageName }));
        }
    }

    public static toggleCongratulationScreen(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isCongratulationScreen: value }));
        }
    }

    public static onClickExit() {
        return (dispatch, getState: () => AppState) => {
            const { isTransferWindowReonboarding } = Selectors.getRoot(getState());
            const pageName = Selectors.getActivePageName(getState());
            const currentStepper = Selectors.getCurrentStepper(getState());

            historyAccessor.push(userPaths.home);

            const message = isTransferWindowReonboarding ? 'Dismiss' : 'Exit to Homepage';

            const currentStep = PitchingPlayerSelectors.getCurrentStep(getState());
            const currentPlayer = PitchingPlayerSelectors.getCurrentPlayer(getState());
            const stepsWithPlayers = [
                PitchingPlayersSteps.PitchOpportunitiesStep, 
                PitchingPlayersSteps.PitchAdditionalOpportunitiesStep
            ];

            if(pageName){
                const playerId = currentStepper === ReonboardingSubsteppers.PitchingPlayers && stepsWithPlayers.includes(currentStep) 
                    ? currentPlayer.playerId 
                    : null;
                dispatch(userActivityInsert({
                    PageName: pageName,
                    Message: message,
                    PlayerId: playerId
                }));
            }
        }
    }

    public static remindMeLater() {
        return async (dispatch, getState: () => AppState) => {
            const pageName = Selectors.getActivePageName(getState());
            const currentStepper = Selectors.getCurrentStepper(getState());
            const currentStep = PitchingPlayerSelectors.getCurrentStep(getState());
            const currentPlayer = PitchingPlayerSelectors.getCurrentPlayer(getState());
            try {
                const promise = ReonboardingService.remindMeLater();
                historyAccessor.push(userPaths.home);

                await promise;

                const stepsWithPlayers = [
                    PitchingPlayersSteps.PitchOpportunitiesStep, 
                    PitchingPlayersSteps.PitchAdditionalOpportunitiesStep
                ];

                if(pageName){
                    const playerId = currentStepper === ReonboardingSubsteppers.PitchingPlayers && stepsWithPlayers.includes(currentStep) 
                        ? currentPlayer.playerId 
                        : null;

                    dispatch(userActivityInsert({
                        PageName: pageName,
                        Message: 'Not Now',
                        PlayerId: playerId,
                    }));
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static onVerticalPageScroll(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isHeaderHide: value }))
        }   
    }
    
    public static disposeScrollingEffect() {
        return (dispatch) => {
            dispatch(stateController.setState({ isHeaderHide: false }))
        }   
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.club.reonboarding.reonboarding;
    public static getCurrentStepper = (state: AppState) => Selectors.getRoot(state).activeStepper;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isCongratulationScreen = (state: AppState) => Selectors.getRoot(state).isCongratulationScreen;
    public static getActivePageName = (state: AppState) => Selectors.getRoot(state).pageName;
    public static getHeaderVisibility = (state: AppState): boolean => Selectors.getRoot(state)?.isHeaderHide;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};