import { AppState } from 'root.reducer'
import { adminPaths } from 'routes/paths'
import historyAccessor from 'history-accessor'
import { getAuth } from 'store/auth/authReducer'
import { StateController } from 'utils/action-declaration'
import { MenuItems } from 'admin-v2/admin-nav-bar/admin-top-menu'

export const LOCAL_STORAGE_KEY = 'adminSettings'



export type AdminSettings = {
    defaultPageName: string
    defaultPageUrl: string
    isMenuIconShow: boolean
    isModalIconShow: boolean
    isMenuIconColorGreen: boolean
}

export type AdminTopMenuSettingsState = {
    allPages: Array<{ url: string, pageName: string }>
    defaultPageName: string
    defaultPageUrl: string
    isMenuIconShow: boolean
    isModalIconShow: boolean
    isMenuIconColorGreen: boolean
}

class AdminSettingsModalState {
    isModalOpen: boolean
    adminTopMenu: AdminTopMenuSettingsState
}

const defaultState = {
    isModalOpen: false,
    adminTopMenu: {
        allPages: [],
        defaultPageName: 'none',
        defaultPageUrl: '/admin',
        isMenuIconShow: true,
        isModalIconShow: false,
        isMenuIconColorGreen: false,
    },

}

const stateController = new StateController<AdminSettingsModalState>(
    'ADMIN_V2/SETTINGS_MODAL',
    defaultState
)

class Actions {

    public static initSettings() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.restoreFromLocalStorage())
            historyAccessor.replace(getState().admin.settingsModal.adminTopMenu.defaultPageUrl)
        }
    }
    public static restoreFromLocalStorage() {
        return (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId
            const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}
            const adminSettings: AdminSettings = localStorageInfo[userId]
            if (adminSettings) {
                dispatch(stateController.setState(state => ({
                    ...state,
                    adminTopMenu: {
                        ...state.adminTopMenu,
                        defaultPageName: adminSettings.defaultPageName,
                        defaultPageUrl: adminSettings.defaultPageUrl,
                        isMenuIconShow: adminSettings.isMenuIconShow,
                        isModalIconShow: adminSettings.isModalIconShow,
                        isMenuIconColorGreen: adminSettings.isMenuIconColorGreen,
                    }
                })))
            }
        }
    }
    public static openModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isModalOpen: true }))
        }
    }
    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId
            const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}
            const adminSettings: AdminSettings = localStorageInfo[userId]

            dispatch(stateController.setState({ isModalOpen: false }))
            if (adminSettings) {
                dispatch(Actions.restoreFromLocalStorage())
            }
            if (!adminSettings) {
                dispatch(stateController.setState(state => ({
                    ...state,
                    adminTopMenu: {
                        ...state.adminTopMenu,
                        defaultPageName: defaultState.adminTopMenu.defaultPageName,
                        defaultPageUrl: defaultState.adminTopMenu.defaultPageUrl,
                        isMenuIconShow: defaultState.adminTopMenu.isMenuIconShow,
                        isModalIconShow: defaultState.adminTopMenu.isModalIconShow,
                        isMenuIconColorGreen: defaultState.adminTopMenu.isMenuIconColorGreen,
                    }
                })))
            }
        }
    }
    public static saveChanges() {
        return (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId
            const adminTopMenu = getState().admin.settingsModal.adminTopMenu

            const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}
            const settingsObject = {
                ...localStorageInfo,
                [userId]: {
                    defaultPageName: adminTopMenu.defaultPageName,
                    defaultPageUrl: adminTopMenu.defaultPageUrl,
                    isMenuIconShow: adminTopMenu.isMenuIconShow,
                    isModalIconShow: adminTopMenu.isModalIconShow,
                    isMenuIconColorGreen: adminTopMenu.isMenuIconColorGreen,
                }
            }
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settingsObject))
            dispatch(Actions.closeModal())
        }
    }

    //====== Admin top menu ==================================================================================================================

    public static getPagesList(items: MenuItems) {
        return (dispatch, getState: () => AppState) => {
            const navItems = []
            Object.values(items).forEach((tab, key) => {
                if (items[key]?.children) {
                    return tab.forEach(navItem =>
                        navItem.pathTo && navItems.push(navItem)
                    )
                }
            }
            )
            const noneOption = { url: '/admin', pageName: 'none' }
            const uaOption = { url: adminPaths.userActivity, pageName: 'User Activity' }
            const allPages = navItems.map(item => ({ url: item.pathTo, pageName: item.navItemName }))
            dispatch(stateController.setState(state => ({
                ...state,
                adminTopMenu: {
                    ...state.adminTopMenu,
                    allPages: [noneOption, uaOption, ...allPages]
                }
            })))
        }
    }
    public static setDefaultPage(pageName: string, url: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(state => ({
                ...state,
                adminTopMenu: {
                    ...state.adminTopMenu,
                    defaultPageName: pageName,
                    defaultPageUrl: url,
                }
            })))
        }
    }
    public static onChangeModalIconShow() {
        return (dispatch, getState: () => AppState) => {
            const { isModalIconShow } = getState().admin.settingsModal.adminTopMenu
            dispatch(stateController.setState(state => ({
                ...state,
                adminTopMenu: {
                    ...state.adminTopMenu,
                    isModalIconShow: !isModalIconShow,
                }
            })))
        }
    }
    public static onChangeMenuIconColor() {
        return (dispatch, getState: () => AppState) => {
            const { isMenuIconColorGreen } = getState().admin.settingsModal.adminTopMenu
            dispatch(stateController.setState(state => ({
                ...state,
                adminTopMenu: {
                    ...state.adminTopMenu,
                    isMenuIconColorGreen: !isMenuIconColorGreen,
                }
            })))
        }
    }
    public static onChangeMenuIconShow() {
        return (dispatch, getState: () => AppState) => {
            const { isMenuIconShow } = getState().admin.settingsModal.adminTopMenu
            dispatch(stateController.setState(state => ({
                ...state,
                adminTopMenu: {
                    ...state.adminTopMenu,
                    isMenuIconShow: !isMenuIconShow,
                }
            })))
        }
    }

}

class Selectors {

}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminSettingsModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}