import compose from 'lodash/fp/compose';
import { COMPETITION_LIST_EDIT } from 'store/actionTypes';

const INITIAL_STATE = {
    edit: {},
};

export default (editor = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPETITION_LIST_EDIT: {
            const list = action.payload;
            return { ...editor, edit: list };
        }
        default:
            return editor;
    }
};

const path = state => state.leagueListEditor;

export const getCompetitionListEdit = compose(
    editor => editor.edit,
    path,
);
