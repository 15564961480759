export const eventClubs = [
  {
    squadId: 1,
    squadName: "AC Milan",
    shortSquadName: "AC Milan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1834_AC_Milan.png"
  },
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 4,
    squadName: "Parma Calcio 1913",
    shortSquadName: "Parma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 47,
    squadName: "Tottenham Hotspur",
    shortSquadName: "Spurs",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1122_Tottenham_Hotspur.png"
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 174,
    squadName: "Club Atletico Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
  },
  {
    squadId: 496,
    squadName: "HNK Rijeka",
    shortSquadName: "HNK Rijeka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png"
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "O'Higgins",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
  },
  {
    squadId: 976,
    squadName: "AS Monaco",
    shortSquadName: "Monaco",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/19830_AS_Monaco.png"
  },
  {
    squadId: 1064,
    squadName: "Nimes Olympique",
    shortSquadName: "Nimes Olympique",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3813_Nîmes_Olympique.png"
  },
  {
    squadId: 1065,
    squadName: "SC Braga",
    shortSquadName: "SC Braga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sporting_Braga.png"
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
  },
  {
    squadId: 1559,
    squadName: "FC Cincinnati",
    shortSquadName: "Cincinnati",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
  },
  {
    squadId: 1595,
    squadName: "Jonkopings Sodra IF",
    shortSquadName: "J-Sodra IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1444_Jonkopings_Sodra_IF.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 1771,
    squadName: "Bryne FK",
    shortSquadName: "Bryne FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7107_Bryne_FK.png"
  },
  {
    squadId: 1949,
    squadName: "Independiente Medellin",
    shortSquadName: "Indep. Medellin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12399_Independiente_Medellín.png"
  },
  {
    squadId: 2052,
    squadName: "Huachipato FC",
    shortSquadName: "Huachipato",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12286_Huachipato_FC.png"
  },
  {
    squadId: 2086,
    squadName: "FC Erzgebirge Aue",
    shortSquadName: "Erzgebirge Aue",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Erzgebirge_Aue.png"
  },
  {
    squadId: 2164,
    squadName: "Colorado Rapids",
    shortSquadName: "Colorado",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7853_Colorado_Rapids.png"
  },
  {
    squadId: 2296,
    squadName: "Moss FK",
    shortSquadName: "Moss FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7103_Moss_FK.png"
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 2531,
    squadName: "Skra Czestochowa",
    shortSquadName: "Skra Czesto.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13843_Skra_Czestochowa.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 2994,
    squadName: "1.FC Saarbrucken",
    shortSquadName: "Saarbrucken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2476_1.FC_Saarbrücken.png"
  },
  {
    squadId: 3028,
    squadName: "Palermo FC",
    shortSquadName: "Palermo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_US_Palermo.png"
  },
  {
    squadId: 3411,
    squadName: "Willem II Tilburg",
    shortSquadName: "Willem II",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Willem_II_Tilburg.png"
  },
  {
    squadId: 3476,
    squadName: "Sporting CP",
    shortSquadName: "Sporting CP",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9608_Sporting_CP.png"
  },
  {
    squadId: 3670,
    squadName: "Queen's Park FC",
    shortSquadName: "Queen's Park",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8546_Queen's_Park_FC.png"
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2051_SK_Slavia_Prag.png"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 4270,
    squadName: "SpVgg Bayreuth",
    shortSquadName: "SpVgg Bayreuth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2546_SpVgg_Bayreuth.png"
  },
  {
    squadId: 4602,
    squadName: "Universidad de Concepcion",
    shortSquadName: "U. Concepcion",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12285_Universidad_de_Concepción.png"
  },
  {
    squadId: 4926,
    squadName: "Rotherham United",
    shortSquadName: "Rotherham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1172_Rotherham_United.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 5109,
    squadName: "PEC Zwolle",
    shortSquadName: "PEC Zwolle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1543_PEC_Zwolle.png"
  },
  {
    squadId: 5159,
    squadName: "FC Groningen",
    shortSquadName: "FC Groningen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Groningen.png"
  },
  {
    squadId: 5180,
    squadName: "Basaksehir FK",
    shortSquadName: "Basaksehir",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2005_Medipol_Basaksehir.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
  },
  {
    squadId: 5430,
    squadName: "GAIS Goteborg",
    shortSquadName: "GAIS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1451_GAIS_Goteborg.png"
  },
  {
    squadId: 5504,
    squadName: "FC Helsingor",
    shortSquadName: "FC Helsingor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Helsingor.png"
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
  },
  {
    squadId: 5924,
    squadName: "Everton de Vina del Mar",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 5972,
    squadName: "San Jose Earthquakes",
    shortSquadName: "San Jose",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
  },
  {
    squadId: 6031,
    squadName: "Tampa Bay Rowdies",
    shortSquadName: "TB Rowdies",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8096_Tampa_Bay_Rowdies.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/690_Real_Valladolid_CF.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "B. Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 6306,
    squadName: "Hibernian FC",
    shortSquadName: "Hibernian FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hibernian_FC.png"
  },
  {
    squadId: 6571,
    squadName: "US Salernitana 1919",
    shortSquadName: "Salernitana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1865_US_Salernitana_1919.png"
  },
  {
    squadId: 7083,
    squadName: "Elche CF",
    shortSquadName: "Elche CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1793_Elche_CF.png"
  },
  {
    squadId: 7246,
    squadName: "Wisla Plock",
    shortSquadName: "Wisla Plock",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/62219_Wisla_Plock.png"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 7845,
    squadName: "CF Pachuca",
    shortSquadName: "Pachuca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
  },
  {
    squadId: 7972,
    squadName: "LOSC Lille",
    shortSquadName: "LOSC Lille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1974_LOSC_Lille.png"
  },
  {
    squadId: 8076,
    squadName: "CD Universidad Catolica",
    shortSquadName: "U. Catolica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2646_CD_Universidad_Catolica.png"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 8228,
    squadName: "AC Horsens",
    shortSquadName: "AC Horsens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7465_AC_Horsens.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 8498,
    squadName: "Odense Boldklub",
    shortSquadName: "Odense BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1412_Odense_Boldklub.png"
  },
  {
    squadId: 8640,
    squadName: "SC Freiburg",
    shortSquadName: "SC Freiburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SC_Freiburg.png"
  },
  {
    squadId: 8655,
    squadName: "Slough Town",
    shortSquadName: "Slough",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2525_Slough_Town_FC.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.png"
  },
  {
    squadId: 9276,
    squadName: "CF Monterrey",
    shortSquadName: "Monterrey",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2267_CF_Monterrey.png"
  },
  {
    squadId: 9385,
    squadName: "Sporting Kansas City",
    shortSquadName: "Kansas City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7849_Sporting_Kansas_City.png"
  },
  {
    squadId: 9566,
    squadName: "HNK Gorica",
    shortSquadName: "HNK Gorica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9557_HNK_Gorica.png"
  },
  {
    squadId: 9657,
    squadName: "Los Angeles FC",
    shortSquadName: "LAFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/62148_Los_Angeles_FC.png"
  },
  {
    squadId: 9699,
    squadName: "CA Colegiales",
    shortSquadName: "NULL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12143_CA_Colegiales.png"
  },
  {
    squadId: 9930,
    squadName: "Esbjerg fB",
    shortSquadName: "Esbjerg fB",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Esbjerg_FB.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 9984,
    squadName: "CA Velez Sarsfield",
    shortSquadName: "Velez Sarsfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12073_Club_Atlético_Vélez_Sarsfield.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 10158,
    squadName: "FC Toulouse",
    shortSquadName: "Toulouse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png"
  },
  {
    squadId: 10183,
    squadName: "Podbeskidzie Bielsko-Biala",
    shortSquadName: "Podbeskidzie",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1610_Podbeskidzie_Bielsko-Biala.png"
  },
  {
    squadId: 10371,
    squadName: "Audax Italiano",
    shortSquadName: "Audax Italiano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12292_Audax_Italiano.png"
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
  },
  {
    squadId: 10585,
    squadName: "Breidablik Kopavogur",
    shortSquadName: "Breidablik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1513_Breidablik_Kopavogur.png"
  },
  {
    squadId: 10781,
    squadName: "Kristiansund BK",
    shortSquadName: "Kristiansund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1477_Kristiansund_BK.png"
  },
  {
    squadId: 11087,
    squadName: "SK Brann",
    shortSquadName: "Brann",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7095_SK_Brann.png"
  },
  {
    squadId: 11209,
    squadName: "Bristol City",
    shortSquadName: "Bristol City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1655_Bristol_City.png"
  },
  {
    squadId: 11585,
    squadName: "Tromso IL",
    shortSquadName: "Tromso IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
  },
  {
    squadId: 11717,
    squadName: "MOL Fehervar FC",
    shortSquadName: "Fehervar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1629_Videoton_FC.png"
  },
  {
    squadId: 11890,
    squadName: "Correcaminos de la UAT",
    shortSquadName: "Correcaminos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15440_Correcaminos_de_la_UAT.png"
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "FK Jerv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "CA Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 12098,
    squadName: "CD Santa Clara",
    shortSquadName: "Santa Clara",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9634_CD_Santa_Clara.png"
  },
  {
    squadId: 12100,
    squadName: "Esporte Clube Vitoria",
    shortSquadName: "Esporte Clube Vitoria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5642_Esporte_Clube_Vitória.png"
  },
  {
    squadId: 12139,
    squadName: "Budapest Honved FC",
    shortSquadName: "Honved",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
  },
  {
    squadId: 12156,
    squadName: "Charlton Athletic",
    shortSquadName: "Charlton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Charlton_Athletic.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 12294,
    squadName: "Olympique Marseille",
    shortSquadName: "Marseille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
  },
  {
    squadId: 12372,
    squadName: "Keflavik IF",
    shortSquadName: "Keflavik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7725_Keflavík_ÍF.png"
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_VfL_Wolfsburg.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 12760,
    squadName: "Dundalk FC",
    shortSquadName: "Dundalk FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2091_Dundalk_FC.png"
  },
  {
    squadId: 12835,
    squadName: "Cheshunt FC",
    shortSquadName: "Cheshunt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1786_Cheshunt_FC.png"
  },
  {
    squadId: 13020,
    squadName: "IL Hodd",
    shortSquadName: "IL Hodd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7112_IL_Hödd.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
  },
  {
    squadId: 13576,
    squadName: "SonderjyskE",
    shortSquadName: "SonderjyskE",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SonderjyskE.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9918_FK_Sarajevo.png"
  },
  {
    squadId: 14512,
    squadName: "Royal Excelsior Virton",
    shortSquadName: "RE Virton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2366_Royal_Excelsior_Virton.png"
  },
  {
    squadId: 14649,
    squadName: "SK Beveren",
    shortSquadName: "SK Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1579_Waasland-Beveren.png"
  },
  {
    squadId: 14737,
    squadName: "SV 07 Elversberg",
    shortSquadName: "SV Elversberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1371_SV_07_Elversberg.png"
  },
  {
    squadId: 14821,
    squadName: "FC Copenhagen",
    shortSquadName: "FC Copenhagen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Copenhagen.png"
  },
  {
    squadId: 14930,
    squadName: "Celaya FC",
    shortSquadName: "Celaya",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png"
  },
  {
    squadId: 14958,
    squadName: "Coventry City",
    shortSquadName: "Coventry",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1188_Coventry_City.png"
  },
  {
    squadId: 14994,
    squadName: "CD Leones Negros de la UdeG",
    shortSquadName: "Leones Negros",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
  },
  {
    squadId: 15052,
    squadName: "Sogndal IL",
    shortSquadName: "Sogndal IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
  },
  {
    squadId: 15241,
    squadName: "Magallanes CF",
    shortSquadName: "Magallanes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1587_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 15549,
    squadName: "FC Basel 1893",
    shortSquadName: "FC Basel",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1932_FC_Basel_1893.png"
  },
  {
    squadId: 15821,
    squadName: "Arminia Bielefeld",
    shortSquadName: "Arm. Bielefeld",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Club Athletico Paranaense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 16280,
    squadName: "Woking FC",
    shortSquadName: "Woking",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1706_Woking_FC.png"
  },
  {
    squadId: 16335,
    squadName: "Beerschot V.A.",
    shortSquadName: "Beerschot V.A.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
  },
  {
    squadId: 16345,
    squadName: "Mjondalen IF",
    shortSquadName: "Mjondalen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1486_Mjondalen_IF.png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 16641,
    squadName: "Oxford City",
    shortSquadName: "Oxford City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1847_Oxford_City.png"
  },
  {
    squadId: 16775,
    squadName: "Reading FC",
    shortSquadName: "Reading",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1147_Reading_FC.png"
  },
  {
    squadId: 17017,
    squadName: "Philadelphia Union",
    shortSquadName: "Philadelphia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png"
  },
  {
    squadId: 17123,
    squadName: "KA Akureyri",
    shortSquadName: "KA Akureyri",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1512_KA_Akureyri.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 17454,
    squadName: "Avai Futebol Clube (SC)",
    shortSquadName: "Avai Futebol Clube (SC)",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
  },
  {
    squadId: 18179,
    squadName: "SC Verl",
    shortSquadName: "SC Verl",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos Athens",
    shortSquadName: "Panathinaikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 18960,
    squadName: "FC Porto",
    shortSquadName: "FC Porto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1672_FC_Porto.png"
  },
  {
    squadId: 19008,
    squadName: "AFC Fylde",
    shortSquadName: "Fylde",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_AFC_Fylde.png"
  },
  {
    squadId: 19206,
    squadName: "Santos FC",
    shortSquadName: "Santos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
  },
  {
    squadId: 19209,
    squadName: "Vendsyssel FF",
    shortSquadName: "Vendsyssel FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Vendsyssel_FF.png"
  },
  {
    squadId: 19616,
    squadName: "Aalesunds FK",
    shortSquadName: "Aalesund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png"
  },
  {
    squadId: 19631,
    squadName: "FK Vojvodina Novi Sad",
    shortSquadName: "Vojvodina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1660_FK_Vojvodina_Novi_Sad.png"
  },
  {
    squadId: 19713,
    squadName: "Fylkir Reykjavik",
    shortSquadName: "Fylkir",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fylkir_Reykjavik.png"
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
  },
  {
    squadId: 20302,
    squadName: "Hallescher FC",
    shortSquadName: "Hallescher FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hallescher_FC.png"
  },
  {
    squadId: 20448,
    squadName: "SV Meppen",
    shortSquadName: "SV Meppen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Meppen.png"
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 20887,
    squadName: "Millwall FC",
    shortSquadName: "Millwall",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1160_Millwall_FC.png"
  },
  {
    squadId: 21139,
    squadName: "Olympiacos Piraeus",
    shortSquadName: "Olympiacos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png"
  },
  {
    squadId: 21425,
    squadName: "FC St. Gallen 1879",
    shortSquadName: "FC St. Gallen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
  },
  {
    squadId: 21487,
    squadName: "Doncaster Rovers",
    shortSquadName: "Doncaster",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34007_Doncaster.png"
  },
  {
    squadId: 22196,
    squadName: "SpVgg Greuther Furth",
    shortSquadName: "Greuther Furth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
  },
  {
    squadId: 22198,
    squadName: "TSV 1860 Munich",
    shortSquadName: "1860 Munich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2461_TSV_1860_Munich.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Salonika",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 22352,
    squadName: "Cruzeiro Esporte Clube",
    shortSquadName: "Cruzeiro Esporte Clube",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5640_Cruzeiro_Esporte_Clube.png"
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13270_Maccabi_Tel_Aviv.png"
  },
  {
    squadId: 22514,
    squadName: "CF America",
    shortSquadName: "America",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15405_América.png"
  },
  {
    squadId: 22719,
    squadName: "Club de Regatas Vasco da Gama",
    shortSquadName: "Vasco da Gama",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
  },
  {
    squadId: 22720,
    squadName: "Korona Kielce",
    shortSquadName: "Korona Kielce",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1605_Korona_Kielce.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 22958,
    squadName: "Concord Rangers",
    shortSquadName: "Concord Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2081_Concord_Rangers.png"
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
  },
  {
    squadId: 23117,
    squadName: "FC Versailles 78",
    shortSquadName: "FC Versailles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4213_FC_Versailles_78.png"
  },
  {
    squadId: 23168,
    squadName: "1. FC Koln",
    shortSquadName: "1. FC Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
  },
  {
    squadId: 23506,
    squadName: "Atlas Guadalajara",
    shortSquadName: "Atlas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15407_Atlas.png"
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
  },
  {
    squadId: 23647,
    squadName: "Atletico de San Luis",
    shortSquadName: "San Luis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
  },
  {
    squadId: 23767,
    squadName: "Crawley Town",
    shortSquadName: "Crawley Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1198_Crawley_Town.png"
  },
  {
    squadId: 23819,
    squadName: "FC Dordrecht",
    shortSquadName: "FC Dordrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1568_FC_Dordrecht.png"
  },
  {
    squadId: 23873,
    squadName: "Botev Plovdiv",
    shortSquadName: "Botev Plovdiv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
  },
  {
    squadId: 24695,
    squadName: "Royale Union Saint Gilloise",
    shortSquadName: "Union SG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1590_Royale_Union_Saint_Gilloise.png"
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
  },
  {
    squadId: 24755,
    squadName: "Milton Keynes Dons",
    shortSquadName: "MK Dons",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1164_Milton_Keynes_Dons.png"
  },
  {
    squadId: 25051,
    squadName: "Wycombe Wanderers",
    shortSquadName: "Wycombe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wycombe_Wanderers.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 25123,
    squadName: "AeZ Zakakiou",
    shortSquadName: "AeZ Zakakiou",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10720_AEZ_Zakakiou.png"
  },
  {
    squadId: 25285,
    squadName: "Levante UD",
    shortSquadName: "Levante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Levante_UD.png"
  },
  {
    squadId: 25293,
    squadName: "IK Start",
    shortSquadName: "IK Start",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7102_IK_Start.png"
  },
  {
    squadId: 25436,
    squadName: "Nashville SC",
    shortSquadName: "Nashville",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 25789,
    squadName: "Union La Calera",
    shortSquadName: "La Calera",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12314_Unión_La_Calera.png"
  },
  {
    squadId: 25852,
    squadName: "CA Newell's Old Boys",
    shortSquadName: "Newell's",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12064_Newell's_Old_Boys.png"
  },
  {
    squadId: 25911,
    squadName: "Royal Antwerp FC",
    shortSquadName: "Royal Antwerp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
  },
  {
    squadId: 26054,
    squadName: "CFR Cluj",
    shortSquadName: "CFR Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2341_CFR_Cluj.png"
  },
  {
    squadId: 26056,
    squadName: "AeK Athens",
    shortSquadName: "AeK Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 26367,
    squadName: "Santos Laguna",
    shortSquadName: "Santos Laguna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
  },
  {
    squadId: 26385,
    squadName: "Cesena FC",
    shortSquadName: "Cesena",
    squadLogo: null
  },
  {
    squadId: 26413,
    squadName: "Nueva Chicago",
    shortSquadName: "Nueva Chicago",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12065_Nueva_Chicago.png"
  },
  {
    squadId: 26540,
    squadName: "Orlando City SC",
    shortSquadName: "Orlando",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
  },
  {
    squadId: 26576,
    squadName: "FC Tulsa",
    shortSquadName: "Tulsa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/33176_Tulsa_Roughnecks_FC.png"
  },
  {
    squadId: 26623,
    squadName: "Miedz Legnica",
    shortSquadName: "Miedz Legnica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1613_Miedz_Legnica.png"
  },
  {
    squadId: 26895,
    squadName: "Jagiellonia Bialystok",
    shortSquadName: "Jagiellonia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1597_Jagiellonia_Bialystok.png"
  },
  {
    squadId: 26905,
    squadName: "Red Star Belgrade",
    shortSquadName: "Red Star",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17090_Crvena_Zvezda.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 27180,
    squadName: "NS Mura",
    shortSquadName: "NS Mura",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31278_NŠ_Mura.png"
  },
  {
    squadId: 27251,
    squadName: "CR Flamengo",
    shortSquadName: "Flamengo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
  },
  {
    squadId: 27984,
    squadName: "Ranheim IL",
    shortSquadName: "Ranheim IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7193_Ranheim_IL.png"
  },
  {
    squadId: 28108,
    squadName: "FC Wil 1900",
    shortSquadName: "FC Wil 1900",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2361_FC_Wil_1900.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "R. Strasbourg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
  },
  {
    squadId: 28372,
    squadName: "Club Atletico Morelia",
    shortSquadName: "Morelia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
  },
  {
    squadId: 28535,
    squadName: "Naestved Boldklub",
    shortSquadName: "Naestved BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
  },
  {
    squadId: 28879,
    squadName: "Athletic Bilbao",
    shortSquadName: "Athletic",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/678_Athletic_Bilbao.png"
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 29646,
    squadName: "NK Domzale",
    shortSquadName: "NK Domzale",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2463_NK_Domzale.png"
  },
  {
    squadId: 29655,
    squadName: "Blackburn Rovers",
    shortSquadName: "Blackburn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16388_Blackburn_Rovers.svg"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 29777,
    squadName: "LR Vicenza",
    shortSquadName: "LR Vicenza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 30372,
    squadName: "Envigado FC",
    shortSquadName: "Envigado",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "Sparta Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
  },
  {
    squadId: 30723,
    squadName: "Borussia Dortmund",
    shortSquadName: "Bor. Dortmund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1259_Borussia_Dortmund.png"
  },
  {
    squadId: 30734,
    squadName: "Altinordu FK",
    shortSquadName: "Altinordu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
  },
  {
    squadId: 30849,
    squadName: "Atlanta United FC",
    shortSquadName: "Atlanta",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Leon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "Dynamo Dresden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1281_SG_Dynamo_Dresden.png"
  },
  {
    squadId: 31328,
    squadName: "Sabah FK",
    shortSquadName: "Sabah FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png"
  },
  {
    squadId: 31366,
    squadName: "Silkeborg IF",
    shortSquadName: "Silkeborg IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1416_Silkeborg_IF.png"
  },
  {
    squadId: 31367,
    squadName: "Sheffield United",
    shortSquadName: "Sheff Utd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1159_Sheffield_United.png"
  },
  {
    squadId: 31484,
    squadName: "Deportivo Guadalajara",
    shortSquadName: "Chivas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15409_Deportivo_Guadalajara.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 32082,
    squadName: "SV Kapfenberg",
    shortSquadName: "SV Kapfenberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1961_SV_Kapfenberg.png"
  },
  {
    squadId: 32380,
    squadName: "VfB Oldenburg",
    shortSquadName: "VfB Oldenburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1337_VfB_Oldenburg.png"
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "E. Frankfurt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
  },
  {
    squadId: 32489,
    squadName: "Stockport County",
    shortSquadName: "Stockport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2324_Stockport_County.png"
  },
  {
    squadId: 32515,
    squadName: "IK Sirius",
    shortSquadName: "Sirius",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1446_IK_Sirius.png"
  },
  {
    squadId: 32704,
    squadName: "UNAM Pumas",
    shortSquadName: "UNAM Pumas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
  },
  {
    squadId: 32753,
    squadName: "Deportivo Toluca",
    shortSquadName: "Toluca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15418_Deportivo_Toluca.png"
  },
  {
    squadId: 32786,
    squadName: "Norrby IF",
    shortSquadName: "Norrby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1463_Norrby_IF.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid 1923",
    shortSquadName: "FC Rapid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
  },
  {
    squadId: 33183,
    squadName: "CD Mafra",
    shortSquadName: "Mafra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3016_CD_Mafra.png"
  },
  {
    squadId: 33217,
    squadName: "Real CD Espana",
    shortSquadName: "Real Espana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 33676,
    squadName: "Sandefjord Fotball",
    shortSquadName: "Sandefjord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sandefjord_Fotball.png"
  },
  {
    squadId: 33781,
    squadName: "Wurzburger Kickers",
    shortSquadName: "Wurzb. Kickers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1295_Wurzburger_Kickers.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 33813,
    squadName: "Karlsruher SC",
    shortSquadName: "Karlsruher SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
  },
  {
    squadId: 33893,
    squadName: "FC Emmen",
    shortSquadName: "FC Emmen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Emmen.png"
  },
  {
    squadId: 33906,
    squadName: "FC Barcelona",
    shortSquadName: "Barcelona",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_FC_Barcelona.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 34923,
    squadName: "Harrogate Town",
    shortSquadName: "Harrogate Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
  },
  {
    squadId: 35045,
    squadName: "Vitesse Arnhem",
    shortSquadName: "Vitesse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png"
  },
  {
    squadId: 35103,
    squadName: "Standard Liege",
    shortSquadName: "Standard Liege",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 35690,
    squadName: "Santiago Wanderers",
    shortSquadName: "Wanderers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12284_Santiago_Wanderers.png"
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Palmeiras",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
  },
  {
    squadId: 36401,
    squadName: "Slovan Bratislava",
    shortSquadName: "Slo. Bratislava",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2123_Slovan_Bratislava.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 36570,
    squadName: "Wrexham AFC",
    shortSquadName: "Wrexham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1221_Wrexham_AFC.png"
  },
  {
    squadId: 36784,
    squadName: "Lechia Gdansk",
    shortSquadName: "Lechia Gdansk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1596_Lechia_Gdansk.png"
  },
  {
    squadId: 37217,
    squadName: "Deportes Tolima",
    shortSquadName: "Deportes Tolima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
  },
  {
    squadId: 37341,
    squadName: "IFK Goteborg",
    shortSquadName: "IFK Goteborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1438_IFK_Goteborg.png"
  },
  {
    squadId: 37475,
    squadName: "Mamelodi Sundowns FC",
    shortSquadName: "Sundowns",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
  },
  {
    squadId: 37706,
    squadName: "Dundee United FC",
    shortSquadName: "Dundee United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8513_Dundee_United_FC.png"
  },
  {
    squadId: 38291,
    squadName: "Sao Paulo Futebol Clube",
    shortSquadName: "Sao Paulo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5638_São_Paulo.png"
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
  },
  {
    squadId: 38553,
    squadName: "Aston Villa",
    shortSquadName: "Aston Villa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 38776,
    squadName: "Linfield FC",
    shortSquadName: "Linfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2078_Linfield_FC.png"
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
  },
  {
    squadId: 39031,
    squadName: "Esporte Clube Juventude",
    shortSquadName: "Juventude",
    squadLogo: null
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 39337,
    squadName: "SK Dnipro-1",
    shortSquadName: "SK Dnipro-1",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png"
  },
  {
    squadId: 39599,
    squadName: "FC Midtjylland",
    shortSquadName: "FC Midtjylland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png"
  },
  {
    squadId: 39648,
    squadName: "New England Revolution",
    shortSquadName: "New England",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2111_New_England_Revolution.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 40170,
    squadName: "MSV Duisburg",
    shortSquadName: "MSV Duisburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1292_MSV_Duisburg.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 40498,
    squadName: "Swansea City",
    shortSquadName: "Swansea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1131_Swansea_City.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 40550,
    squadName: "Rot-Weiss Essen",
    shortSquadName: "RW Essen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1391_Rot-Weiss_Essen.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 40692,
    squadName: "Columbus Crew",
    shortSquadName: "Columbus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2116_Columbus_Crew_SC.png"
  },
  {
    squadId: 41155,
    squadName: "Raith Rovers FC",
    shortSquadName: "Raith Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2285_Raith_Rovers_FC.png"
  },
  {
    squadId: 41660,
    squadName: "VfL Osnabruck",
    shortSquadName: "VfL Osnabruck",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2501_VfL_Osnabrück.jpg"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 41956,
    squadName: "Deportivo Cali",
    shortSquadName: "Deportivo Cali",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png"
  },
  {
    squadId: 41967,
    squadName: "Antalyaspor",
    shortSquadName: "Antalyaspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2007_Antalyaspor.png"
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 42798,
    squadName: "Cavalry FC",
    shortSquadName: "Cavalry FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 60007,
    squadName: "Atletico Ottawa",
    shortSquadName: "Atl. Ottawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-79097_Atlético_Ottawa.png"
  },
  {
    squadId: 60008,
    squadName: "Union Omaha",
    shortSquadName: "Union Omaha",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-77059_Union_Omaha.png"
  },
  {
    squadId: 100007,
    squadName: "Austin FC",
    shortSquadName: "Austin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-72309_Austin_FC.png"
  },
  {
    squadId: 200003,
    squadName: "Oakland Roots SC",
    shortSquadName: "Oakland Roots",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69044_Oakland_Roots_SC.png"
  },
  {
    squadId: 200004,
    squadName: "Charlotte FC",
    shortSquadName: "Charlotte",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
  },
  {
    squadId: 200108,
    squadName: "Tepatitlan FC",
    shortSquadName: "Tepatitlan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64345_Tepatitlán_FC.png"
  },
  {
    squadId: 200113,
    squadName: "1.FC Phonix Lubeck",
    shortSquadName: "Phonix Lubeck",
    squadLogo: null
  },
  {
    squadId: 200193,
    squadName: "Manisa FK",
    shortSquadName: "Manisa FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/48913_Manisa_FK.png"
  },
  {
    squadId: 200392,
    squadName: "FC Drita Gjilan",
    shortSquadName: "FC Drita",
    squadLogo: null
  },
  {
    squadId: 222120,
    squadName: "Maringa Futebol Clube (PR)",
    shortSquadName: "Maringa Futebol Clube (PR)",
    squadLogo: null
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "CD Real Cartagena",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
  },
];