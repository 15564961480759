import compose from 'lodash/fp/compose';
import { COMPETITION_FETCH } from 'store/actionTypes';

const INITIAL_STATE = {
    byId: {},
    allIds: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPETITION_FETCH.SUCCESS: {
            if (!action.payload.data.result.length) {
                return {
                    ...state,
                    byId: {},
                    allIds: [],
                };
            }
            const { countries } = action.payload.data.entities;
            return {
                ...state,
                byId: countries,
                allIds: Object.keys(countries).map(Number),
            };
        }
        default:
            return state;
    }
};

const path = state => state.entities.areas;

export const getAreas = compose(
    areas => areas.allIds.map(id => areas.byId[id]),
    path,
);

export const getAreasById = compose(
    areas => areas.byId,
    path,
);

export const getArea = compose(
    areas => id => areas.byId[id],
    path,
);
