import axios from 'axios-config';
import {
    AgentProfileModel,
    SaveUserAgentProfileRequest,
    SaveUserAgentProfileResponse,
    AgencyProfileUserModel,
    SaveAgentProfileRequest,
    AgencyProfilePermissionsModel,
} from './agent-profile'

export default class AgencyProfileService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getProfile(): Promise<AgentProfileModel> {
        const { data } = await axios.get<AgentProfileModel>(`api/v2/Agent/Profile`, this.axiosConfig)

        return data;
    }

    public static async saveProfile(request: SaveAgentProfileRequest): Promise<SaveUserAgentProfileResponse> {
        const { data } = await axios.put<SaveUserAgentProfileResponse>(`api/v2/Agent/Profile`, request, this.axiosConfig)

        return data;
    }

    public static async getAgencyUsers(): Promise<Array<AgencyProfileUserModel>> {
        const { data } = await axios.get<Array<AgencyProfileUserModel>>(`api/v2/Agent/Users`, this.axiosConfig)

        return data;
    }

    public static async getAgencyProfilePermissions(): Promise<AgencyProfilePermissionsModel> {
        const { data } = await axios.get<AgencyProfilePermissionsModel>(`api/v2/Agent/ProfilePermissions`, this.axiosConfig)

        return data;
    }

    public static async deleteUser(id: number): Promise<any> {
        const { data } = await axios.delete(`api/v2/Agent/Users/${id}`, this.axiosConfig)

        return data;
    }

    public static async updateUser(id: number, request: SaveUserAgentProfileRequest): Promise<SaveUserAgentProfileResponse> {
        const { data } = await axios.put<SaveUserAgentProfileResponse>(`api/v2/Agent/Users/${id}`, request, this.axiosConfig)

        return data;
    }

    public static async changePassword(userId: number, oldPass: string, newPass: string): Promise<boolean> {
        const { data } = await axios.post<any>(
            `api/Users/ChangePassword`,
            {
                Id: userId,
                OldPassword: oldPass,
                NewPassword: newPass
            },
            this.axiosConfig
        );
        return data;
    }


    public static async validatePassword(userId: number, password: string): Promise<boolean> {
        const { data } = await axios.post<any>(
            `api/Users/ValidatePassword/${userId}`,
            { password },
            this.axiosConfig
        );
        return data;
    }
}