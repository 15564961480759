import React, { Component } from 'react';
import RangeSelector from '../RangeSelector/RangeSelector';
import { translate } from 'services/localization';

const monthLimit = 11;

class Props {
    defaultMin: number;
    defaultMax: number;
    selectedMin: number;
    selectedMax: number;
    onChange: (newMin: number, newMax: number) => void;
}

export default class ContractExpirySelector extends Component<Props, {}> {
    static toPoints(months) {
        return months > monthLimit ? monthLimit + months / 12 : months;
    }
    static toMonths(points) {
        return points > monthLimit ? (points - monthLimit) * 12 : points;
    }
    static tomMonthsOrYears(points) {
        return points > monthLimit ? points - monthLimit : points;
    }
    static unts(points) {        
        return points > monthLimit ? (points === 12 ? translate("contractExpirySelector.yr") : translate("contractExpirySelector.yrs")) : translate("contractExpirySelector.mo");
    }

    public static toYrOrMonth(monthes: number) {
        let monthesLabel = this.tomMonthsOrYears(this.toPoints(monthes));
        return monthesLabel + " " + this.unts(monthes);
    }

    render() {

        const rangeValue = {
            min: ContractExpirySelector.toPoints(this.props.selectedMin),
            max: ContractExpirySelector.toPoints(this.props.selectedMax)
        }

        return (
            <RangeSelector
                range
                minValue={ContractExpirySelector.toPoints(this.props.defaultMin)}
                maxValue={ContractExpirySelector.toPoints(this.props.defaultMax)}
                value={rangeValue}
                onChange={value => {
                    this.props.onChange(ContractExpirySelector.toMonths(value.min), ContractExpirySelector.toMonths(value.max));
                }}
                display={ContractExpirySelector.tomMonthsOrYears}
                units={ContractExpirySelector.unts}
            />
        );
    }
}
