import axios from 'axios-config';
import { ClubRegistrationDataModel, ClubRegistrationResponse } from './models/club-registration';
import { AgencyRegistrationDataModel, AgencyRegistrationResponse, AgencyRequestDemoDataModel, AgencyRequestDemoResponse } from './models/agency-registration';

export class RegistrationService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async registerClub(registrationData: ClubRegistrationDataModel) : Promise<ClubRegistrationResponse> {
        const { data } = await axios.post(
            `api/Users/RegisterUser`,
            registrationData,
            this.axiosConfig
        )
        return data
    }
    public static async requestClubDemo(registrationData: ClubRegistrationDataModel) : Promise<any> {
        const { data } = await axios.post(
            `api/Users/DemoRequest`,
            registrationData,
            this.axiosConfig
        )
        return data
    }
   

    public static async registerAgency(registrationData : AgencyRegistrationDataModel) : Promise<AgencyRegistrationResponse>  {
        const { data } = await axios.post(
            `api/Users/RegisterAgency`,
            registrationData,
            this.axiosConfig
        )
        return data
    }

    public static async requestAgencyDemo(requestData : AgencyRequestDemoDataModel) : Promise<AgencyRequestDemoResponse>  {
        const { data } = await axios.post(
            `api/Users/AgencyDemoRequest`,
            requestData,
            this.axiosConfig
        )
        return data
    }

    public static async checkUser(email: String) : Promise<Boolean> {
        const { data } = await axios.post(
            `api/Users/CheckUser`,
            { email: email },
            this.axiosConfig
        )
        return data.value
    }
}