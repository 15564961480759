import axios from 'axios-config'
import { GeneralPlayerInfoModel, AgencyModel, SetInvitationStatusToPreconnectedNotTrustedRequest } from './models'

export class HomePageService {
    private static axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    public static async init(): Promise<GeneralPlayerInfoModel> {
        const { data } = await axios.get(
            `api/v2/PlayerPreference`,
            this.axiosConfig
        )
        return data
    }

    public static async setInvitationStatus(payload: SetInvitationStatusToPreconnectedNotTrustedRequest) {
        const { data } = await axios.post(
            `api/v2/AgencyFinder/SetInvitationStatusToPreconnectedNotTrustedV2`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async getPreconnectedAgencyInfo(): Promise<AgencyModel> {
        const { data } = await axios.get(
            `api/v2/PlayerPreference/GetPreconnectedAgencyInfo`,
            this.axiosConfig
        )
        return data
    }

    public static async closeExplorePerformanceBanner() {
        const { data } = await axios.post(
            `api/v2/PlayerPreference/CloseExplorePerformanceBanner`,
            this.axiosConfig
        )
        return data
    }
}