import axios from 'axios-config';
import MySquadPlayer, { MySquadToBuy, MySquadToLoan } from './model/my-squad-player'
import { MySquadUpdatePlayerRequest } from './model/my-squad-update-player'

export default class MySquadMatchMakerService {
  

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loadClubAds(playerId: number): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/ClubAds/GetRecommendedClubAdsV2/${playerId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async pitchPlayerToAds(userId: number, squadId: number, playerId: number, request: any){
        const { data } = await axios.post(
            `api/ClubAds/PitchClubAds/${playerId}/${squadId}/${userId}`,
            request,
            this.axiosConfig
        )
        return data;
    }
}