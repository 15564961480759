import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'

export class MessageSendSession {
    logo: string;
    officialName: string;
    leagueName: string;
    subject: string;
    checkBoxTitle: string;
    mesageTypeId: number;  
    toClub?: boolean;  
    toAgency?: boolean;
    agencyName?: string;
    agencyCity?: string;
    agencyCountry?: string;
}

class SendMessageModalState {
    isSending: boolean;
    currentSession: MessageSendSession;
    specifcation: IMessageSendSpecificaion;
}

const defaultState: SendMessageModalState = {
    isSending: false,
    currentSession: null,
    specifcation: null
}

const stateController = new StateController<SendMessageModalState>(
    "SEND_MESSAGE_MODAL/DEFAULT",
    defaultState
);

export interface IMessageSendSpecificaion {
    sendMessage: (session: MessageSendSession, message: string, checked: boolean) => Promise<any>;
    cancelMessage: () => any;
}

class Actions {

    private static specifcation: IMessageSendSpecificaion;

    public static openSession(session: MessageSendSession, specification: IMessageSendSpecificaion) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentSession: session }));
            this.specifcation = specification;
        }
    }

    public static sendMessage(message: string, checked: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            dispatch(stateController.setState({ isSending: true }));
            await this.specifcation.sendMessage(state.sendMessageModal.currentSession, message, checked)
            this.specifcation = null;
            dispatch(stateController.setState({ isSending: false, currentSession: null }));
        }
    }

    public static close() {
        return (dispatch) => {            
            this.specifcation.cancelMessage();
            this.specifcation = null;
            dispatch(stateController.setState({ isSending: false, currentSession: null }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SendMessageModalState as State,
    Actions as Actions,
    stateController as Controller
};



