import React from 'react';
import styled from 'styled-components'
import Button from 'components/Buttons/Button/Button'
// import MonthPng from 'media/images/events/deal-day-month-sept.png'

interface OwnProps {
    isCompleted: boolean
    isLoading: boolean
    anonymous?: boolean
    eventAttendeess: Array<string>
    onCompleteAttendanceClick: () => void
    onAddExtraAttendeeClick: () => void
}

const BannerRegistered = ({
    isCompleted = false,
    isLoading = false,
    anonymous = false,
    eventAttendeess = [],
    onAddExtraAttendeeClick,
    onCompleteAttendanceClick
}: OwnProps) => {
    return (
        <Section>
            <div className={'inner ' + (anonymous ? 'full-width' : '')}>
                <div className="title">
                    <p>
                        TransferRoom
                        {' '}
                        <span>Virtual Summit</span>
                        {/* <span><img src={MonthPng}></img></span> */}
                    </p>
                    {/* <p className="subtitle">
                        24th of March 2021, <span>Online</span>
                    </p> */}
                </div>
                <div className="info">
                    <div className="info-item">
                        <span
                            aria-hidden="true"
                            className="fa fa-calendar-alt"
                        />
                        <div
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            10th November 2021
                        </div>
                    </div>
                    <div className="info-item">
                        <span
                            aria-hidden="true"
                            className="fa fa-map-marker-alt"
                        />
                        <div
                            style={{
                                lineHeight: '1em',
                                flex: '0 0 auto',
                                textAlign: 'left',
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'left',
                                    fontSize: 18,
                                    color: '#ffffff',
                                    lineHeight: '1em',
                                }}
                            >
                                Online (Virtual)
                            </div>
                        </div>
                    </div>
                </div>
                <div className="confirmed-label">
                    <span><div className="confirmed-label__icon"><i className="fas fa-check"></i></div>Registration Confirmed</span>
                </div>
                {!isLoading && (
                    <>
                        {isCompleted ? (
                            <>
                                <div className="subtitle" style={{ fontWeight: 300 }}>
                                    <div style={{ display: 'inline', fontSize: 18 }}>
                                        <span>Attendees:{' '}</span>{eventAttendeess.map((item, index) => {
                                            return <div style={{ display: 'inline-block', marginRight: 5 }}><span >{`${item}${eventAttendeess.length - 1 === index ? '' : ', '}`}</span></div>
                                        })}
                                    </div>
                                </div>
                                {/* {eventAttendeess.length < 4 && (
                                    <Button
                                        type="primaryinverse"
                                        effect="no"
                                        onClick={onAddExtraAttendeeClick}
                                    >Add Extra Attendee</Button>
                                )} */}
                            </>
                        ) : (
                            <>
                                {/* <div className="subtitle">
                                        Complete attendance details before 8th March and get early access to meeting booking.
                                    </div> */}
                                <div style={{ height: 20 }}></div>
                                <Button type="primaryinverse" effect="no" onClick={() => onCompleteAttendanceClick()} >
                                    Complete Your Attendance Details Now
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        </Section>
    )
}

const Section = styled.section`
    height: auto;
    text-align: center;
    color: #fff;
    .inner {
        padding: 50px 0;
        background-image: none;
        background-color: #0E854C;
        background-position: center 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 767px) {
            padding: 55px 15px;
        }

        &.full-width{
            position: absolute;
            left: 50%;
            width: 100vw;
            margin-left: -50vw;
        }

        .title {
            font-size: 54px;
            margin: 0 0 8px 0;
            font-weight: 700;
            & span {
                color:#FFFFFF;
            }
            &.small {
                margin-bottom: 10px;
                p {
                    font-size: 36px;
                }
            }
            p {
                font-size: 50px;
                line-height: 3.5rem;
                margin: 0;
                > span {
                    margin: 0;
                    &:last-child {
                        margin-top: 10px;
                    }
                }
                @media (max-width: 499px) {
                    font-size: 30px;
                    line-height: 3.1rem;
                }
                @media (max-width: 400px) {
                    font-size: 28px;
                    line-height: 2.7rem;
                }
            }
            p.subtitle {
                font-size: 26px;
            }
            img {
                height: 40px;
                width: auto;
                position: relative;
                display: inline-block;
                top: -5px;
                margin-left: 10px;
                @media (max-width: 756px) {
                    margin-left: 0px;
                }
            }
            @media (max-width: 767px) {
                font-size: 36px;
                & span {
                    display: block;
                    margin-top: 7px;
                }
            }
            @media (max-width: 450px) {
                font-size: 40px;
            }
        }
        
        .info {
            margin: 15px auto 35px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            > div:first-child {
                // margin-right: 15px;
            }
            @media (max-width: 767px) {
                display: block;
            }
            @media (max-width: 570px) {
                > div {
                    width: auto;
                    margin-left: 20%;
                    margin-bottom: 10px;
                    justify-content: flex-start;
                }
                > div:first-child {
                    margin-left: 20%;
                }
            }
            @media (max-width: 450px) {
                > div {
                    width: auto !important;
                    margin-left: 30px;
                }
                > div:first-child {
                    margin-left: 30px;
                }
            }
        }
        .info-item {
            position: relative;
            flex: 0 0 auto;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0 16px;

            font-size: 18px;
            line-height: 24px;

            @media (max-width: 767px) {
                margin-bottom: 12px;
                justify-content: center;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .fa {
                position: relative;
                width: 36px;
                height: 36px;
                border-radius: 20px;
                position: relative;
                overflow: hidden;
                margin: 0 11px 0 0;
                text-align: center;
                flex-shrink: 0;
                color: #0E9655;

                &::before {
                    z-index: 100;
                    position: relative;
                    top: calc(50% - 9px);
                }

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                }
            }
        }
        .subtitle {
            margin: 20px 0 25px;
            padding: 0 10px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 500;
            color:#ffffff;
            @media (max-width: 450px) {
                font-size: 15px;
            }
        }
        
        button {
            max-width: 100%;
            font-size: 13px;
            padding: 10px 20px;
            text-overflow: ellipsis;
            white-space: normal;
            height: auto;
        }
    }
    .confirmed-label {
        padding: 6px;
        background-color: white;
        display: inline-block;
        width: fit-content;
        margin: auto 0;
        position: relative;
        border-radius: 12px;
        text-transform: uppercase;
        color: #0E9655;
        font-size: 13px;
        line-height: 1rem;
        font-weight: 600;
        span {
            top: 1px;
            position: relative;
            display: flex;
            padding-right: 3px;
            white-space: nowrap;
        }
        &__icon {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #0E9655;
            position: relative;
            margin-right: 5px;
            > i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 11px;
            }
            
        }
    }
`

export default BannerRegistered