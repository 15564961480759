import * as redux from 'redux'
import * as addNewUserModal from './modals/add-user-modal/add-user-modal.controller'
import * as allUsers from './pages/all-users/all-users.controller'
import * as expertCards from './pages/expert-cards/expert-cards.controller'
import * as userActivity from './pages/user-activity/user-activity.controller'
import * as userRequests from './pages/user-requests/user-requests.controller'
import * as agencyRequests from './pages/agency-request/agency-requests.controller'
import * as confirmationLinkModal from 'admin-v2/modals/confirmation-link-modal/confirmation-link.controller'
import * as onboardAgencyModal from 'admin-v2/modals/onboard-agency-modal/onboard-agency.controller'
import * as onboardPlayerModal from 'admin-v2/modals/onboard-player-modal/onboard-player-modal.controller'
import * as resetPasswordLinkModal from 'admin-v2/modals/reset-password-link-modal/reset-password-link.controller'
import * as resetPreconnectedPlayersModal from 'admin-v2/modals/reset-preconnected-players-modal/reset-preconnected-players.controller'
import * as contactsPage from './pages/contacts/contacts.controller'
import * as agencyPermissions from 'admin-v2/pages/agency-permissions/agency-permissions.controller'
import * as clubPermissions from 'admin-v2/pages/club-permissions/club-permissions.controller'
import * as adminPermissions from 'admin-v2/pages/admin-permissions/admin-permissions.controller'
import * as paymentsOfAgencies from 'admin-v2/pages/payments-of-agencies/payments-of-agencies.controller'
import * as agencyCredits from './pages/agency-credits/agency-credits.controller'
import * as clubReonboarding from './pages/club-reonboarding/root.controller'
import * as events from 'admin-v2/pages/events/events.controller'
import * as eventsPractical from 'admin-v2/pages/event-practical/event-practical.controller'
import * as eventAttendance from 'admin-v2/pages/event-attendance/event-attendance.controller'
import * as soldPlayers from 'admin-v2/pages/sold-players/sold-players.controller'
import * as friendlyMatchesReport from 'admin-v2/pages/friendly-matches-report/friendly-matches-report.controller'
import * as upgradeBannerReport from 'admin-v2/pages/upgrade-banner-report/upgrade-banner-report.controller'
import * as declareInterestReport from 'admin-v2/pages/declare-interest-report/declare-interest-report.controller'
import * as allPitchesReport from 'admin-v2/pages/all-pitches-report/all-pitches-report.controller'
import * as players from 'admin-v2/pages/players/players.controller'
import * as friendlySuccessStory from 'admin-v2/pages/friendly-success-story/friendly-success-story.controller'
import * as mapping from 'admin-v2/pages/mapping/mapping.controller'
import * as successStory from 'admin-v2/pages/success-story/success-story.controller'
import * as playerVerification from 'admin-v2/pages/player-verification/player-verification.controller';
import * as clubs from 'admin-v2/pages/clubs/clubs.controller'
import * as agencies from 'admin-v2/pages/agencies/agencies.controller'
import * as newDownloadsSquads from 'admin-v2/pages/new-squads-download/new-squads-download.controller'
import * as eventAgencyRequest from 'admin-v2/pages/event-agency-request/event-agency-request.controller'
import * as settingsModal from 'admin-v2/modals/settings-modal/settings-modal.controller'
import * as adminNavBar from 'admin-v2/admin-nav-bar/admin-nav-bar.controller'

export class AdminState {
    allUsers: allUsers.State
    adminNavBar: adminNavBar.State
    agencyCredits: agencyCredits.State
    agencyRequests: agencyRequests.State
    addNewUserModal: addNewUserModal.State
    adminPermissions: adminPermissions.State
    allPitchesReport: allPitchesReport.State
    agencyPermissions: agencyPermissions.State
    contactsPage: contactsPage.State
    clubPermissions: clubPermissions.State
    confirmationLinkModal: confirmationLinkModal.State
    resetPreconnectedPlayersModal: resetPreconnectedPlayersModal.State
    events: events.State
    eventsPractical: eventsPractical.State
    expertCards: expertCards.State
    eventAttendance: eventAttendance.State
    mapping: mapping.State
    onboardAgencyModal: onboardAgencyModal.State
    onboardPlayerModal: onboardPlayerModal.State
    resetPasswordLinkModal: resetPasswordLinkModal.State
    userActivity: userActivity.State
    userRequests: userRequests.State
    soldPlayers: soldPlayers.State
    friendlyMatchesReport: friendlyMatchesReport.State
    upgradeBannerReport: upgradeBannerReport.State
    friendlySuccessStory: friendlySuccessStory.State;
    successStory: successStory.State;
    declareInterestReport: declareInterestReport.State;
    paymentsOfAgencies: paymentsOfAgencies.State
    playerVerification: playerVerification.State;
    players: players.State;
    clubs: clubs.State;
    agencies: agencies.State;
    newDownloadsSquads: newDownloadsSquads.State;
    eventAgencyRequest: eventAgencyRequest.State;
    settingsModal: settingsModal.State
    clubReonboarding: clubReonboarding.ClubReonboardingRootState
}

export function combineReducers() {

    let adminReducer = {
        allUsers: allUsers.Reducer,
        adminNavBar: adminNavBar.Reducer,
        agencyCredits: agencyCredits.Reducer,
        agencyRequests: agencyRequests.Reducer,
        addNewUserModal: addNewUserModal.Reducer,
        adminPermissions: adminPermissions.Reducer,
        allPitchesReport: allPitchesReport.Reducer,
        agencyPermissions: agencyPermissions.Reducer,
        contactsPage: contactsPage.Reducer,
        clubPermissions: clubPermissions.Reducer,
        confirmationLinkModal: confirmationLinkModal.Reducer,
        resetPreconnectedPlayersModal: resetPreconnectedPlayersModal.Reducer,
        events: events.Reducer,
        eventsPractical: eventsPractical.Reducer,
        expertCards: expertCards.Reducer,
        eventAttendance: eventAttendance.Reducer,
        mapping: mapping.Reducer,
        userActivity: userActivity.Reducer,
        userRequests: userRequests.Reducer,
        onboardAgencyModal: onboardAgencyModal.Reducer,
        onboardPlayerModal: onboardPlayerModal.Reducer,
        resetPasswordLinkModal: resetPasswordLinkModal.Reducer,
        soldPlayers: soldPlayers.Reducer,
        friendlyMatchesReport: friendlyMatchesReport.Reducer,
        upgradeBannerReport: upgradeBannerReport.Reducer,
        friendlySuccessStory: friendlySuccessStory.Reducer,
        successStory: successStory.Reducer,
        declareInterestReport: declareInterestReport.Reducer,
        paymentsOfAgencies: paymentsOfAgencies.Reducer,
        playerVerification: playerVerification.Reducer,
        players: players.Reducer,
        clubs: clubs.Reducer,
        agencies: agencies.Reducer,
        newDownloadsSquads: newDownloadsSquads.Reducer,
        eventAgencyRequest: eventAgencyRequest.Reducer,
        settingsModal: settingsModal.Reducer,
        clubReonboarding: clubReonboarding.combineReducers()
    };

    return redux.combineReducers(adminReducer)
} 