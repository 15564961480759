import { GENERAL_POSITIONS_WITHOUT_PLAYER_ADS_FETCH } from 'store/actionTypes';

const initialState = [];
export default (m = initialState, action) => {
    switch (action.type) {
        case GENERAL_POSITIONS_WITHOUT_PLAYER_ADS_FETCH.SUCCESS: {
            return action.payload.data;
        }
        default:
            return m;
    }
};
