import history from 'history-accessor'
import { AppState } from 'root.reducer'
import { playerPaths } from 'routes/paths'
import { StateController } from 'utils/action-declaration'
import Service from 'api/agency/player/shared/agency-player-service'
import { PlayerMessage } from 'api/agency/player/shared/shared-models'
import { PlayerTransferMessage } from 'api/agency/player/shared/shared-models'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { MessageType } from 'api/messaging/models/message'
import { debug } from 'console'


class MyMessagesState {
    currentPage: number
    isLoading: boolean
    messages: Array<PlayerMessage>
}

const defaultState: MyMessagesState = {
    currentPage: 1,
    isLoading: false,
    messages: null
}

const stateController = new StateController<MyMessagesState>(
    "PLAYER/MY-MESSAGES",
    defaultState
)

class Actions {

    public static setCurrentPage(pageNumber: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentPage: pageNumber }))
        }
    }

    public static init(trackMesagesOpened: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            var data = await Service.getMessages();
            dispatch(stateController.setState({ isLoading: false, messages: data }));

            if (data.length == 0)
                history.push(playerPaths.homePage)
            if (trackMesagesOpened && data.length > 0) {
                dispatch(userActivityInsert({
                    Message: 'Opened',
                    PageName: 'My Page [Messaging]',
                    PlayerId: data[0].playerTransfer.playerId,
                    AgencyId: data[0].playerTransfer.agencyId,
                }))
            }
        }
    }

    public static done() {
        return async (dispatch, getState: () => AppState) => {
            var substate = getState().player.myMessagesFlow;
            var shifted = substate.messages.slice(1);

            if (shifted.length == 0) {
                dispatch(stateController.setState({ currentPage: 1 }))
                history.push(playerPaths.homePage)
            }
            else {
                dispatch(stateController.setState({ messages: shifted, currentPage: 1 }));
            }

        }
    }

    public static skip(TransferMessage: PlayerTransferMessage) {
        return async (dispatch, getState: () => AppState) => {
            var substate = getState().player.myMessagesFlow;
            var shifted = substate.messages.slice(1);

            if (shifted.length == 0) {
                dispatch(stateController.setState({ currentPage: 1 }));
                history.push(playerPaths.homePage)
            }
            else {
                dispatch(stateController.setState({ messages: shifted, currentPage: 1 }));
            }
            dispatch(userActivityInsert({
                Message: 'Cancelled Deal Rejection',
                PageName: 'My Page [Messaging]',
                PlayerId: TransferMessage.playerId,
                AgencyId: TransferMessage.agencyId,
            }))
        }
    }

    public static confirm(transferMessage: PlayerTransferMessage) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            await Service.setMessageAsRead(transferMessage.id);
            dispatch(stateController.setState({ isLoading: false, currentPage: 2 }));
            dispatch(userActivityInsert({
                Message: 'Confirmed Deal',
                PageName: 'My Page [Messaging]',
                PlayerId: transferMessage.playerId,
                AgencyId: transferMessage.agencyId,
            }))
        }
    }

    public static reject(transferMessage: PlayerTransferMessage) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            await Service.setMessageAsRejected(transferMessage.id);
            dispatch(stateController.setState({ isLoading: false, currentPage: 4 }));
            dispatch(userActivityInsert({
                Message: 'Rejected Deal',
                PageName: 'My Page [Messaging]',
                PlayerId: transferMessage.playerId,
                AgencyId: transferMessage.agencyId,
            }))
        }
    }

    public static userActivityFromVerificationLink(queryString?: string) {
        return async (dispatch, getState: () => AppState) => {
            if (queryString) {
                const playerId = new URLSearchParams(queryString).get('playerId')
                const agencyId = new URLSearchParams(queryString).get('agencyId')
                const source = new URLSearchParams(queryString).get('source')
                const messageType = new URLSearchParams(queryString).get('mt')

                if (parseInt(messageType) == 13) {
                    dispatch(userActivityInsert({
                        Message: 'Opened Deal Verification Link',
                        PageName: source == "sms" ? 'Deal Confirmation SMS' : 'Deal Confirmation Email',
                        PlayerId: parseInt(playerId),
                        AgencyId: parseInt(agencyId),
                    }))
                }

                if (parseInt(messageType) == MessageType.ProfileSharingStart || parseInt(messageType) == MessageType.ProfileSharingStop) {
                    dispatch(userActivityInsert({
                        Message: 'Opened Activity Page Link',
                        PageName: source == "sms" ? 'Activity Sharing SMS' : 'Activity Sharing Email',
                        PlayerId: parseInt(playerId),
                        AgencyId: parseInt(agencyId),
                    }))
                }

            } else return
        }
    }
}


const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    MyMessagesState as State,
    Actions as Actions,
    stateController as Controller
}