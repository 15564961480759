export class AgencyPermissionResponse {
    agencyId: number;
    plusPitchesCount: number;
    agencyCustomPitchesCount: number;
}

export class EditCostValueRequest {
    agencyId: number;
    permissionCode: string;
    value: number;
}

export class Country {
    id: number;
    name: string;
    code?: string;
}

export class Filter {
    agencyId?: number;
    areaId?: number;
    subscriptionId?: number;
    supervisorId?: number;
    withUsersOnly?: boolean;
}

export class PendingAgencyEventRequest {
    agencyId: number;
    agencyName: string;
    eventId: number;
    createdAt: Date;
    updatedByUserId?: number;
}

export class SalesSupervisor {
    public userId: number;
    public fullName: string;
    public email: string;
    public photo: string;
    public phone: string;
    public position: string;
    public allIds: Array<number>;
}

export enum AdditionalPlayerMarketSubscriptionEnum {
    None = 0,
    Full = 1,
    LimitedTo150k = 2,
    LimitedTo300k = 3
}

export enum RepresentationRequestSettingOptionEnum {
    MaskAll = 1,
    Over300k = 2,
    ShowAll = 3,
}

export class Permissions {
    additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum;
    representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum;
    agencyId: number;
    name: string;
    salesSupervisorId: number;
    successExpertUserName: string;
    plusPitchCount: number;
    agencyCustomPitchesCount: number;
    permissionCodes: string[];
    playerMarkets: PlayerMarketModel[];
    selectedMarketNames: string;
    marketExpertNames: string;
    isLta: boolean;
}

export interface PlayerMarketsSubscriptionModel {
    id: AdditionalPlayerMarketSubscriptionEnum;
    name: string;
}

export interface RepresentationRequestSettingOption {
    id: RepresentationRequestSettingOptionEnum;
    name: string;
}

export class PlayerMarketModel {
    id: number;
    areaId: number;
    areaName: string;
    isExpert: boolean;
}

export class PlayerMarketRequestModel {
    id: number;
    areaId: number;
    isExpert: boolean;
}

export class PlayerMarketRequest {
    agencyId: number;
    markets: PlayerMarketRequestModel[];
}