import { StateController } from 'utils/action-declaration';
import { AppState } from "root.reducer";
import moment from 'moment';
import userActivityInsert from "app/user-activity/actions/user-activity.actions";
import { getAreaPhoneCodes } from 'app/phone-codes/actions/phone-codes.actions';
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { notificationCreate } from 'app/notifications/notifications.actions';
import validator from 'services/validator';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service';
import { Actions as FindHeadCoachPopupController } from "pages/landing-page/redux/find-coach-popup.controller";
import { Actions as RestrictedCoachCreditsActions, RestrictedCoachCreditsModalType } from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-coach-credits-modal/restricted-coach-credits.controller';
import AgencyHeadCoachesService from 'api/agency/agent/head-coaches/head-coaches.service';
import AgencyCoachOnboardingService from 'api/agency/agent/onboarding/coaches/coach-onboarding.service';
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service'
import { HeadCoachesVerification, PendingVerificationCoach, SetStrategyRequest } from 'api/agency/agent/head-coaches/head-coaches.models';
import { CompetitionWithActiveSquadsModel } from 'api/agency/agent/landing/strategy-modal-models';
import { openCoachProfileById } from "utils/open-link";
import { PageType } from 'constants/enums';
import { getAuth } from 'store/auth/authReducer';


class HeadCoachesState {
    isLoading: boolean;
    priorityCreditsAvailable: number;
    regularCreditsAvailable: number;
    annualCreditSwapWindowDaysLeft: number;
    isAnnualCreditSwapWindowActive: boolean;
    nextSwapWindowMessage: string;
    isUnlimitedRegularSlots: boolean;
    totalPrioritySlots: number;
    priorityPlayerCreditSwaps: number;
    showAnnualCreditSwapBanner: boolean;
    isPriorityBenefitsModalOpen: boolean;
    headCoaches: HeadCoachesVerification[];
    pendingVerificationCoaches: PendingVerificationCoach[];
    removedCoachesList: Array<number>;
    upgradeCoachModal: UpgradeCoachModalState;
    shareLinkModal: ShareLinkModalState;
    contractExpiryModal: ContractExpiryModal;
    strategyModalData: StrategyModalData;
    clubsModal: StrategyClubsModalData;
    pitchAccessCriteriaArgs: PitchAccessCriteriaArgs;
    downgradeCoachModal: DowngradeCoachModalState;
    removeCoachModal: RemoveCoachModalState;
}

export interface PendingVerificationPlayer {
    playerId: number
    insightsShared: boolean
    playerName: string
    playerEmail: string
    playerPhone: string
    playerDateBirth: string
    playerParentSquadId: number
    playerParentSquadName: string
    isPriority: boolean
    timeout: number
    pitchesAwaitingVerification: number
    opportunities: number
    phoneCodeAreaId: number
    currentSquad: {
        country: string
        id: number
        league: string
        logo: string
        name: string
    }
    parentSquad: {
        country: string
        id: number
        league: string
        logo: string
        name: string
    },
    verificationMessage: string;
}

export interface ShareLinkModalState {
    email: string;
    emailValid: boolean;
    phoneNumber: string;
    headCoachFullName: string;
    verificationLink: string;
    coachId: number;
    squadId: number;
    phoneCodeAreaId: number;
    isPriority: boolean;
    isModalOpen: boolean;
    isLinkGenerating: boolean;
    isSendEmailDisabled: boolean;
    isSendPhoneDisabled: boolean;
    isSendEmailProcessing: boolean;
    isSendPhoneProcessing: boolean;
    isEmailSent: boolean;
    isPhoneSent: boolean;
    verificationMessage: string;
}

export interface UpgradeCoachModalState {
    headCoachFullName: string;
    staffId: number;
    squadId: number;
    processing: boolean;
    isModalOpen: boolean;
    isModalForPendingVerificationOpen: boolean;
}

export interface ContractExpiryModal {
    coachId: number;
    squadId: number;
    contractExpiry: Date;
    modalActive: boolean;
    // plusYearsCount: number;
    processing: boolean;
}

export interface PitchAccessCriteriaArgs {
    adIds: number[];
    coachIds: number[];
}

export interface StrategyModalData {
    isStrategyModalOpen: boolean;
    coachId: number;
    title: string;
    coachStatus: CoachStatus;
    transferData: TransferData;
    isClubsModalOpen: boolean;
    isLoading: boolean;
    selectedClubIds: number[];
    leaguesCount: number;
    grossError: string;
}

export enum CoachStatus {
    Remain = 1,
    Transfer = 2
}

export class TransferData {
    transferFee: FromTo;
}

export interface FromTo {
    from: number;
    to: number;
}
export interface StrategyClubsModalData {
    squads: Array<CompetitionWithActiveSquadsModel>;
    recommended: CompetitionWithActiveSquadsModel;
    selectedIds: Array<number>;
    recommendedView: CompetitionWithActiveSquadsModel;
    squadsView: Array<CompetitionWithActiveSquadsModel>;
    selectedSquadsView: Array<CompetitionWithActiveSquadsModel>;
    searchString: string;
    isLoading: boolean;
}

interface DowngradeCoachModalState {
    processing: boolean;
    isDowngraded: boolean;
    isDowngradeCoachModalOpen: boolean;
    coachId: number | null;
    squadId: number | null;
    coachName: string;
}

interface RemoveCoachModalState {
    processing: boolean;
    isGetInTouchRequestSent: boolean;
    isRemoveCoachModalOpen: boolean;
    removingCoachId: number;
    squadId: number;
    coachName: string;
    isRegular: boolean;
    isPriority: boolean;
}

const defaultState: HeadCoachesState = {
    isLoading: false,
    priorityCreditsAvailable: null,
    regularCreditsAvailable: null,
    annualCreditSwapWindowDaysLeft: null,
    isAnnualCreditSwapWindowActive: false,
    nextSwapWindowMessage: '',
    isUnlimitedRegularSlots: false,
    totalPrioritySlots: null,
    priorityPlayerCreditSwaps: null,
    showAnnualCreditSwapBanner: false,
    isPriorityBenefitsModalOpen: false,
    headCoaches: [],
    pendingVerificationCoaches: [],
    removedCoachesList: [],
    upgradeCoachModal: {
        headCoachFullName: '',
        staffId: null,
        squadId: null,
        processing: false,
        isModalOpen: false,
        isModalForPendingVerificationOpen: false,
    },
    shareLinkModal: {
        email: '',
        emailValid: true,
        phoneNumber: '',
        headCoachFullName: '',
        verificationLink: '',
        coachId: null,
        squadId: null,
        phoneCodeAreaId: null,
        isPriority: false,
        isModalOpen: false,
        isLinkGenerating: false,
        isSendEmailDisabled: false,
        isSendPhoneDisabled: false,
        isSendEmailProcessing: false,
        isSendPhoneProcessing: false,
        isEmailSent: false,
        isPhoneSent: false,
        verificationMessage: null
    },
    contractExpiryModal: null,
    strategyModalData: null,
    clubsModal: null,
    pitchAccessCriteriaArgs: null,
    downgradeCoachModal: {
        processing: false,
        isDowngraded: false,
        isDowngradeCoachModalOpen: false,
        coachId: null,
        squadId: null,
        coachName: '',
    },
    removeCoachModal: {
        processing: false,
        isGetInTouchRequestSent: false,
        isRemoveCoachModalOpen: false,
        removingCoachId: null,
        squadId: null,
        coachName: '',
        isRegular: false,
        isPriority: false,
    },
}

const stateController = new StateController<HeadCoachesState>(
    "AGENCY/LANDING_PAGE/HEAD_COACHES_STATE",
    defaultState
)

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await Promise.all([
                    dispatch(Actions.getPendingVerificationCoaches()),
                    dispatch(Actions.getCoaches()),
                ]);

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches',
                    Message: 'Opened Head Coaches Tab',
                    PageType: PageType.AgencyCoaches,
                }));

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    };

    // ________________________VerificationCoaches_________________________________________

    public static getPendingVerificationCoaches() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AgencyHeadCoachesService.getPendingVerificationCoaches()

                dispatch(stateController.setState({
                    pendingVerificationCoaches: data.coaches
                }))

                if (data.coaches.length) {
                    dispatch(getAreaPhoneCodes());
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static upgradePendingVerificationCoach(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subState = Selectors.getRoot(getState());
                const clubId = subState.upgradeCoachModal.squadId;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    upgradeCoachModal: {
                        ...prevState.upgradeCoachModal,
                        processing: true,
                    }
                })));

                await AgencyHeadCoachesService.upgradeCoach(id);

                const headCoachesCopy = [...subState.pendingVerificationCoaches];

                const upgradedCoach = headCoachesCopy.find(x => x.staffId === id);
                upgradedCoach.isPriority = true;

                dispatch(stateController.setState({
                    pendingVerificationCoaches: headCoachesCopy,
                    priorityCreditsAvailable: subState.priorityCreditsAvailable - 1,
                    upgradeCoachModal: { ...defaultState.upgradeCoachModal },
                }));

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Clicked Upgrade Head Coach',
                    CoachId: id,
                    ClubId: clubId,
                    PageType: PageType.AgencyCoaches,
                }));
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static upgradeCoach(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subState = Selectors.getRoot(getState());
                const clubId = subState.upgradeCoachModal.squadId;
                dispatch(stateController.setState({
                    upgradeCoachModal: {
                        ...subState.upgradeCoachModal,
                        processing: true
                    }
                }))

                await AgencyHeadCoachesService.upgradeCoach(id);

                const headCoachesCopy = [...subState.headCoaches];

                const upgradedCoach = headCoachesCopy.find(i => i.staffId === id);
                upgradedCoach.isRegular = false;

                dispatch(stateController.setState({
                    headCoaches: headCoachesCopy,
                    upgradeCoachModal: { ...defaultState.upgradeCoachModal },
                    priorityCreditsAvailable: subState.priorityCreditsAvailable - 1
                }));

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Clicked Upgrade Head Coach',
                    CoachId: id,
                    ClubId: clubId,
                    PageType: PageType.AgencyCoaches,
                }));
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static openUpgradeCoachModalForPendingVerification(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            const { priorityCreditsAvailable, pendingVerificationCoaches } = Selectors.getRoot(getState());
            const headCoach = pendingVerificationCoaches.find(x => x.staffId === coachId);
            const clubId = headCoach?.squad?.id;

            if (priorityCreditsAvailable > 0) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    upgradeCoachModal: {
                        ...prevState.upgradeCoachModal,
                        isModalForPendingVerificationOpen: true,
                        staffId: headCoach.staffId,
                        squadId: clubId,
                        headCoachFullName: headCoach.name,
                    }
                })))
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches',
                    Message: 'Clicked Upgrade',
                    CoachId: coachId,
                    ClubId: clubId,
                    PageType: PageType.AgencyCoaches,
                }));

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Opened Upgrade Head Coach Pop-up',
                    CoachId: coachId,
                    ClubId: clubId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(RestrictedCoachCreditsActions.openRestrictedPriorityCreditsModal(RestrictedCoachCreditsModalType.UpgradeHeadCoach, coachId, clubId));
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Opened Request More Credits Pop-up',
                    CoachId: coachId,
                    ClubId: clubId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    public static openUpgradeCoachModal(coachId: number, isAction?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const { priorityCreditsAvailable, headCoaches } = Selectors.getRoot(getState());
            const headCoach = headCoaches.find(x => x.staffId === coachId);
            const squadId = headCoach?.squadId;

            const upgradePageName = `${isAction ? 'Agency Head Coaches [Actions]' : 'Agency Head Coaches'}`;
            const upgradePageAction = `${isAction ? 'Clicked Upgrade Head Coach' : 'Clicked Upgrade'}`;

            dispatch(userActivityInsert({
                PageName: upgradePageName,
                Message: upgradePageAction,
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            if (priorityCreditsAvailable > 0) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    upgradeCoachModal: {
                        ...prevState.upgradeCoachModal,
                        isModalOpen: true,
                        staffId: headCoach.staffId,
                        squadId: squadId,
                        headCoachFullName: headCoach.coachName,
                    }
                })))

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Opened Upgrade Head Coach Pop-up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(RestrictedCoachCreditsActions.openRestrictedPriorityCreditsModal(RestrictedCoachCreditsModalType.UpgradeHeadCoach, coachId, squadId));
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Opened Request More Credits Pop-up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    public static closeUpgradeCoachModal(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { staffId, squadId } = Selectors.getRoot(getState()).upgradeCoachModal;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Collapsed Upgrade Head Coach Pop-up',
                    CoachId: staffId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Upgrade Head Coach]',
                    Message: 'Clicked Cancel',
                    CoachId: staffId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                upgradeCoachModal: {
                    ...defaultState.upgradeCoachModal
                }
            })))
        }
    }

    // _________Share verification link modal_______________

    public static openShareLinkModal(item: PendingVerificationCoach) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches',
                Message: 'Clicked Share Verification Link',
                CoachId: item.staffId,
                ClubId: item?.squad?.id,
                PageType: PageType.AgencyCoaches,
            }));

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    coachId: item.staffId,
                    squadId: item?.squad?.id,
                    phoneCodeAreaId: item.phoneCodeAreaId,
                    phoneNumber: item.phone,
                    email: item.email || '',
                    headCoachFullName: item.name,
                    isSendEmailDisabled: !!item.email,
                    isSendPhoneDisabled: !!item.phone,
                    isModalOpen: true,
                    isPriority: item.isPriority,
                    verificationMessage: item.verificationMessage
                }
            })))

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Share Verification Link]',
                Message: 'Opened Share Verification Link Pop-up',
                CoachId: item.staffId,
                ClubId: item?.squad?.id,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static sendEmail() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    isSendEmailProcessing: true,
                }
            })))
            try {
                const { email, coachId, isPriority, squadId } = Selectors.getShareLinkModal(getState());

                await AgencyCoachOnboardingService.sendVerificationEmail({ staffId: coachId, email: email, isRegular: !isPriority });

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Share Verification Link]',
                    Message: 'Clicked Send Email',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));

                let headCoachesCopy = [...Selectors.getRoot(getState()).pendingVerificationCoaches];
                let find = headCoachesCopy.find(x => x.staffId === coachId);
                if (find) {
                    find.email = email;
                    dispatch(stateController.setState(prev => ({ ...prev, pendingVerificationCoaches: headCoachesCopy })))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendEmailProcessing: false,
                        isEmailSent: true
                    }
                })))
            }
        }
    }

    public static sendPhone() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendPhoneProcessing: true,
                    }
                })))
                const { phoneCodeAreaId, coachId, phoneNumber, isPriority, squadId } = Selectors.getShareLinkModal(getState());

                const request = [{
                    staffId: coachId,
                    phoneCodeAreaId: phoneCodeAreaId,
                    phone: phoneNumber,
                    isRegular: !isPriority
                }];

                await AgencyCoachOnboardingService.sendToVerify({ coaches: request });

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Share Verification Link]',
                    Message: 'Clicked Send SMS',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));

                let headCoachesCopy = [...Selectors.getRoot(getState()).pendingVerificationCoaches];
                let find = headCoachesCopy.find(x => x.staffId === coachId);
                if (find) {
                    find.phoneCodeAreaId = phoneCodeAreaId;
                    find.phone = phoneNumber;
                    dispatch(stateController.setState(prev => ({ ...prev, pendingVerificationCoaches: headCoachesCopy })));
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendPhoneProcessing: false,
                        isPhoneSent: true
                    }
                })))
            }
        }
    }

    public static closeShareLinkModal(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { coachId, squadId } = Selectors.getShareLinkModal(getState());

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Share Verification Link]',
                    Message: 'Collapsed Share Verification Pop-up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Share Verification Link]',
                    Message: 'Closed Share Verification Pop-up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...defaultState.shareLinkModal
                }
            })))
        }
    }

    public static generateLink() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { coachId, verificationLink, isPriority, squadId } = Selectors.getShareLinkModal(getState())
                let link = verificationLink;

                if (link === '') {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        shareLinkModal: {
                            ...prevState.shareLinkModal,
                            isLinkGenerating: true,
                        }
                    })))
                    const data = await AgencyHeadCoachesService.generateLink({ staffId: coachId, isRegular: !isPriority })

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        shareLinkModal: {
                            ...prevState.shareLinkModal,
                            verificationLink: data.link,
                        }
                    })))

                    dispatch(userActivityInsert({
                        PageName: 'Agency Head Coaches [Share Verification Link]',
                        Message: 'Clicked Generate&Copy',
                        CoachId: coachId,
                        ClubId: squadId,
                        PageType: PageType.AgencyCoaches,
                    }));
                }
                link = Selectors.getShareLinkModal(getState()).verificationLink
                if (link !== '') {
                    await copy(link);
                    dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }));
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isLinkGenerating: false,
                    }
                })))
            }
        }
    }

    public static sendWhatsApp() {
        return async (dispatch, getState: () => AppState) => {
            const { coachId, squadId, isPriority } = Selectors.getShareLinkModal(getState());

            await AgencyHeadCoachesService.generateLink({ staffId: coachId, isRegular: !isPriority });

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Share Verification Link]',
                Message: 'Clicked WhatsApp',
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static onChangeEmail(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    email: value,
                    emailValid: validator.isValidEmail(value)
                }
            })))
        }
    }

    public static onChangePhoneCode(areaId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    phoneCodeAreaId: areaId,
                }
            })))
        }
    }
    public static onChangePhoneNumber(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    phoneNumber: value,
                }
            })))
        }
    }

    // _________Grid Head Coach Portfolio_______________

    public static getCoaches() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const data = await AgencyHeadCoachesService.getCoaches();

                dispatch(stateController.setState({
                    headCoaches: data.coaches,
                    priorityCreditsAvailable: data.priorityCreditsAvailable,
                    totalPrioritySlots: data.totalPrioritySlots,
                    regularCreditsAvailable: data.regularCreditsAvailable,
                    priorityPlayerCreditSwaps: data.priorityPlayerCreditSwaps,
                    annualCreditSwapWindowDaysLeft: data.annualCreditSwapWindowDaysLeft,
                    isAnnualCreditSwapWindowActive: data.isAnnualCreditSwapWindowActive,
                    nextSwapWindowMessage: data.nextSwapWindowMessage,
                    isUnlimitedRegularSlots: data.isUnlimitedRegularSlots,
                }))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static openCoachProfile(coachId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches',
                Message: 'Opened Head Coach Profile',
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            openCoachProfileById(coachId);
        }
    }

    // _________________Priority Benefits Modal__________________

    public static openPriorityBenefitsInfoModal(isPriorityBenefitsInfoModal?: boolean) {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isPriorityBenefitsModalOpen: true,
            })));
            if (isPriorityBenefitsInfoModal) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches',
                    Message: 'Clicked Priority Benefits Hint Icon',
                    PageType: PageType.AgencyCoaches,
                }));
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Priority Benefits]',
                    Message: 'Opened Priority Benefits Pop-up',
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                    Message: 'Clicked Discover Priority Head Coach Benefits',
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    public static closePriorityBenefitsInfoModal(isCollapsed?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(({ isPriorityBenefitsModalOpen: false })));

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Priority Benefits]',
                    Message: 'Collapsed Priority Benefits Pop-up',
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Priority Benefits]',
                    Message: 'Clicked Close',
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    // ____________Contract Expiry Modal____________

    public static openContractExpiryModal = (id: number, isAction?: boolean) => {
        return (dispatch, getState: () => AppState) => {
            const headCoach = Selectors.getRoot(getState()).headCoaches.find(x => x.staffId === id);
            const squadId = headCoach?.squadId;
            const contractExpiryAction = `${headCoach.clubContractExpiry === null ? 'Add' : 'Edit'}`;
            const contractExpiryPageName = `${isAction ? 'Agency Head Coaches [Actions]' : 'Agency Head Coaches'}`;

            dispatch(userActivityInsert({
                PageName: contractExpiryPageName,
                Message: `Clicked ${contractExpiryAction} Contract Expiry`,
                CoachId: id,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            dispatch(stateController.setState({
                contractExpiryModal: {
                    coachId: id,
                    squadId: squadId,
                    contractExpiry: headCoach.clubContractExpiry,
                    processing: false,
                    modalActive: true,
                }
            }));

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Contract Expiry]',
                Message: 'Opened Contract Expiry Pop-up',
                CoachId: id,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static closeContractExpiryModal(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { coachId, squadId } = Selectors.getRoot(getState()).contractExpiryModal;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Contract Expiry]',
                    Message: 'Collapsed Contract Expiry Pop-up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Contract Expiry]',
                    Message: 'Clicked Cancel',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(Actions.cancelContractExpiryModal());
        }
    }

    public static cancelContractExpiryModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ contractExpiryModal: null }));
        }
    }

    public static setContractExpiry(contractExpiry: Date) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const chosenDate = moment(contractExpiry).format('DD.MM.YYYY')

            const modalState: ContractExpiryModal = {
                ...subState.contractExpiryModal,
                contractExpiry: contractExpiry
            };

            dispatch(stateController.setState({ contractExpiryModal: modalState }));

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Contract Expiry]',
                Message: `Chosen Date ${chosenDate}`,
                CoachId: modalState.coachId,
                ClubId: modalState?.squadId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static saveContractExpiry() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const modalState = { ...subState.contractExpiryModal, processing: true };
            dispatch(stateController.setState({ contractExpiryModal: modalState }))

            if (modalState.contractExpiry == null) {
                dispatch(this.cancelContractExpiryModal());
                return;
            }

            try {
                const headCoach = subState.headCoaches.find(x => x.staffId === subState.contractExpiryModal.coachId);

                await AgencyHeadCoachesService.setContractExpiration(modalState.coachId, modalState.contractExpiry);

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Contract Expiry]',
                    Message: 'Clicked Save Changes',
                    CoachId: headCoach.staffId,
                    ClubId: headCoach?.squadId,
                    PageType: PageType.AgencyCoaches,
                }));

                if (headCoach.clubContractExpiry === modalState.contractExpiry) {
                    dispatch(this.cancelContractExpiryModal());
                    return;
                }

                const headCoaches = [...subState.headCoaches.map((x) => {
                    if (x.staffId === subState.contractExpiryModal.coachId) return { ...headCoach, clubContractExpiry: modalState.contractExpiry, }
                    return x;
                })]

                dispatch(stateController.setState({ headCoaches: headCoaches }))
                dispatch(this.cancelContractExpiryModal());
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    contractExpiryModal: null,
                })))
            } catch (e) {
                console.error(e)
                dispatch({ type: '--ERROR' })
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    contractExpiryModal: {
                        ...prevState.contractExpiryModal,
                        processing: false
                    }
                })))
            }
        }
    }



    // _______________ Strategy Modal____________

    public static openStrategyModal(data: HeadCoachesVerification, isAction?: boolean) {
        return (dispatch, getState: () => AppState) => {
            let request: StrategyModalData;
            const strategyAction = `${data.strategy === null ? 'Add' : 'Edit'}`;
            const strategyPageName = `${isAction ? 'Agency Head Coaches [Actions]' : 'Agency Head Coaches'}`;

            dispatch(userActivityInsert({
                PageName: strategyPageName,
                Message: `Clicked ${strategyAction} Strategy`,
                CoachId: data.staffId,
                ClubId: data?.squadId,
                PageType: PageType.AgencyCoaches,
            }));

            if (data?.strategy && data?.strategy?.isPlanningTransfer) {
                const strategyPlan = data?.strategy;
                request = {
                    isStrategyModalOpen: true,
                    coachId: data.staffId,
                    title: `STRATEGY - ${data.coachName}`,
                    coachStatus: CoachStatus.Transfer,
                    transferData: strategyPlan?.isPlanningTransfer ?
                        {
                            transferFee: {
                                from: strategyPlan.transferFeeFrom,
                                to: strategyPlan.transferFeeTo
                            }
                        } :
                        null,
                    isClubsModalOpen: false,
                    isLoading: false,
                    selectedClubIds: strategyPlan.strategySquadSet,
                    leaguesCount: data.strategy.leaguesCount,
                    grossError: null,
                }
            } else {
                request = {
                    isStrategyModalOpen: true,
                    coachId: data.staffId,
                    title: `STRATEGY FOR NEXT WINDOW - ${data.coachName}`,
                    coachStatus: CoachStatus.Remain,
                    transferData: null,
                    isClubsModalOpen: false,
                    isLoading: false,
                    selectedClubIds: [],
                    leaguesCount: 0,
                    grossError: null,
                }
            }

            dispatch(stateController.setState({
                strategyModalData: request,
                // pitchAccessCriteriaArgs: pitchAccessCriteriaArgs
            }))

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Strategy]',
                Message: 'Opened Set Strategy Pop-up',
                CoachId: data.staffId,
                ClubId: data?.squadId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static cancelStrategyModal(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const coachId = subState.strategyModalData.coachId;
            const squadId = subState.headCoaches.find(x => x.staffId === coachId)?.squadId;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: 'Collapsed Set Starategy Pop-Up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: 'Clicked Cancel Set Starategy',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
            dispatch(stateController.setState({ strategyModalData: null }))
        }
    }

    public static saveChangesStrategyModal() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const coachId = subState.strategyModalData.coachId;
            const squadId = subState.headCoaches.find(x => x.staffId === coachId)?.squadId;
            const transferData = subState.strategyModalData.transferData;
            const transferFee = transferData && transferData.transferFee;

            const errorText = "Value 'to' should be greater then 'from'";
            const errorText2 = "Value 'to' should not be empty";

            const grossError = transferFee && transferFee.from && transferFee.to && transferFee.from > transferFee.to;
            const grossError2 = transferFee && transferFee.from && !transferFee.to;

            if (grossError || grossError2) {
                dispatch(stateController.setState({
                    ...subState, strategyModalData: {
                        ...subState.strategyModalData,
                        grossError: grossError ? errorText : grossError2 ? errorText2 : null,
                    }
                }))
            }

            else {
                dispatch(stateController.setState({ ...subState, strategyModalData: { ...subState.strategyModalData, isLoading: true } }))
                const request: SetStrategyRequest = {
                    isAnyPlan: subState.strategyModalData.coachStatus === CoachStatus.Transfer,
                    plan: {
                        staffId: coachId,
                        isPlanningTransfer: subState.strategyModalData.transferData != null,
                        transferFeeFrom: subState.strategyModalData.transferData?.transferFee?.from,
                        transferFeeTo: subState.strategyModalData.transferData?.transferFee?.to,
                        strategySquadSet: subState.strategyModalData.selectedClubIds,
                        leaguesCount: subState.strategyModalData.leaguesCount,
                    }
                }
                try {
                    await AgencyHeadCoachesService.setStrategy(request);

                    const currency = getAuth(getState()).currency.name;
                    const transferFee = subState.strategyModalData.transferData?.transferFee;
                    let trackStr = ''
                    if (request.isAnyPlan && request.plan.isPlanningTransfer) {
                        trackStr += `Transfer ${currency} ${transferFee?.to}; ${transferFee?.to}/yr`;
                        dispatch(userActivityInsert({
                            PageName: 'Agency Head Coaches [Strategy]',
                            Message: `Expected Annual Gross Salary: ${transferFee?.from}-${transferFee?.to} ${currency}/yr`,
                            CoachId: coachId,
                            ClubId: squadId,
                            PageType: PageType.AgencyCoaches,
                        }));
                    }

                    // if (!request.isAnyPlan) {
                    //     trackStr += `Stay`
                    // }

                    if (trackStr) {
                        dispatch(userActivityInsert({
                            PageName: 'Agency Head Coaches [Strategy]',
                            Message: 'Clicked Save Changes',
                            CoachId: coachId,
                            ClubId: squadId,
                            PageType: PageType.AgencyCoaches,
                        }));
                    }

                    dispatch(stateController.setState({
                        strategyModalData: null
                    }))
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        strategyModalData: {
                            ...defaultState.strategyModalData
                        }
                    })))
                    dispatch(Actions.getCoaches());

                    // if (subState.pitchAccessCriteriaArgs) {
                    //     dispatch(PitchAccessCriteriaModalActions.load(subState.pitchAccessCriteriaArgs.adIds, subState.pitchAccessCriteriaArgs.playerIds));
                    // }
                } catch (e) {
                    console.error(e)
                    dispatch({ type: '--ERROR' })
                } finally {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        strategyModalData: {
                            ...prevState.strategyModalData,
                            isLoading: false
                        }
                    })))
                }
            }
        }
    }

    public static openStrategyClubsModal() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const coachId = Selectors.getRoot(getState()).strategyModalData.coachId;
            const squadId = subState.headCoaches.find(x => x.staffId === coachId)?.squadId;

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Strategy]',
                Message: 'Clicked Manage Leagues',
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, isClubsModalOpen: true } })))
        }
    }

    public static changeCoachStatus(status: CoachStatus) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const strategyModalData = subState.strategyModalData;
            const { coachId } = strategyModalData;
            const squadId = subState.headCoaches.find(x => x.staffId === coachId)?.squadId;

            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, coachStatus: status } })))
            if (status === CoachStatus.Remain) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: 'Chosen Stay Option',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: 'Chosen Transfer Option',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    public static changeTransferData(data: TransferData) {
        return (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, transferData: data } })))
        }
    }


    // ____________________Strategy Clubs Modal_________________________

    public static closeStrategyClubsModal(isCollapsed?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const coachId = subState.strategyModalData.coachId;
            const squadId = subState.headCoaches.find(x => x.staffId === coachId)?.squadId;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: 'Collapsed Leagues Selection',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: 'Clicked Cancel Leagues Selection',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, isClubsModalOpen: false }, clubsModal: null })))
        }
    }

    public static saveSelectedSquads() {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const squadId = state.headCoaches.find(x => x.staffId === state.strategyModalData.coachId)?.squadId;

            const uniqueLeagues = [];
            const uniqueClubNames = [];
            const uniqueLeaguesRecommendedNames = [];
            state.clubsModal.squads.map(x => {
                x.squads.map(y => {
                    if (state.clubsModal.selectedIds.some(i => i === y.id)) {
                        let find = uniqueLeagues.find(k => k === x.id);
                        if (!find) {
                            uniqueLeagues.push(x.id);
                        }

                        if (state.clubsModal.recommended && state.clubsModal.recommended.squads.some(i => i.id == y.id)) {
                            uniqueLeaguesRecommendedNames.push(y.name)
                        } else {
                            uniqueClubNames.push(y.name)
                        }
                    }
                });
            });

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Strategy]',
                Message: 'Clicked Save Changes',
                CoachId: state.strategyModalData.coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            let trackSTr = ''

            if (uniqueClubNames[0]) {
                trackSTr = `${uniqueClubNames.join(', ')}`
            }

            if (uniqueLeaguesRecommendedNames[0]) {
                trackSTr = `${trackSTr ? ', ' : ''} ${uniqueLeaguesRecommendedNames.join(', ')}`
            }

            if (trackSTr) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Strategy]',
                    Message: `Selected Leagues: ${trackSTr}`,
                    CoachId: state.strategyModalData.coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(stateController.setState({
                ...state, clubsModal: null, strategyModalData: {
                    ...state.strategyModalData, selectedClubIds: state.clubsModal.selectedIds, isClubsModalOpen: false, leaguesCount: uniqueLeagues.length
                }
            }));
        }
    }

    public static loadSquadsStrategyClubsModal() {
        return async (dispatch, getState: () => AppState) => {
            let state = Selectors.getRoot(getState());

            if (!((state.clubsModal || {}).squads)) {
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, isLoading: true } }));
                const data = await AgencyHomePageService.activeSquadsFetch(state.strategyModalData.coachId);

                const selectedIds = state.strategyModalData.selectedClubIds;
                const view = data.competitionWithActiveSquads.map(x => { x.isCollapsed = true; return x; })
                const recommended: CompetitionWithActiveSquadsModel = data.recommenders.length > 0 ? {
                    id: -1, areaId: -1, areaName: "areaName", nameWithArea: "Recommended", divisionLevel: -1, isCollapsed: true, name: "name",
                    squads: data.recommenders
                } : null
                await dispatch(stateController.setState({
                    clubsModal: {
                        isLoading: true,
                        squads: data.competitionWithActiveSquads,
                        selectedIds: selectedIds,
                        squadsView: view,
                        searchString: "",
                        selectedSquadsView: [],
                        recommended: recommended,
                        recommendedView: recommended
                    }
                }));
                await dispatch(this.selectSquads([], false));
            }
        }
    }

    public static selectSquads(squadIds: Array<number>, isForRemove: boolean) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            let selectedIds;
            if (isForRemove) {
                selectedIds = state.clubsModal.selectedIds.filter(x => !squadIds.some(y => y === x));
            }
            else {
                selectedIds = [...state.clubsModal.selectedIds, ...squadIds];
            }
            const newSquadView: CompetitionWithActiveSquadsModel[] = [];
            const newSelectedSquadView: CompetitionWithActiveSquadsModel[] = [];

            const x = state.clubsModal.recommended;
            let newRecommendedView: CompetitionWithActiveSquadsModel;
            if (x) {
                newRecommendedView = {
                    areaId: x.areaId, areaName: x.areaName, divisionLevel: x.divisionLevel, id: x.id,
                    name: x.name, nameWithArea: x.nameWithArea, squads: [], isCollapsed: false
                }

                state.clubsModal.recommended.squads.map(y => {
                    if (!selectedIds.some(k => k === y.id)) {
                        newRecommendedView.squads.push(y);
                    }
                });
            }
            else {
                newRecommendedView = null;
            }
            state.clubsModal.squads.map(x => {
                x.squads.map(y => {
                    if (selectedIds.some(k => k === y.id)) {
                        let find = newSelectedSquadView.find(i => i.id === x.id);
                        if (!find) {
                            newSelectedSquadView.push({
                                areaId: x.areaId, areaName: x.areaName, divisionLevel: x.divisionLevel, id: x.id,
                                name: x.name, nameWithArea: x.nameWithArea, squads: [y], isCollapsed: false
                            });
                        }
                        else {
                            find.squads.push(y);
                        }
                    }
                });
            });
            state.clubsModal.squads.map(x => {
                x.squads.map(y => {
                    if (!selectedIds.some(k => k === y.id)) {
                        let find = newSquadView.find(i => i.id === x.id);
                        let findLeague = state.clubsModal.squadsView.find(i => i.id === x.id);
                        if (!find) {
                            newSquadView.push({
                                areaId: x.areaId, areaName: x.areaName, divisionLevel: x.divisionLevel, id: x.id,
                                name: x.name, nameWithArea: x.nameWithArea, squads: [y], isCollapsed: findLeague ? findLeague.isCollapsed : x.isCollapsed
                            });
                        }
                        else {
                            find.squads.push(y);
                        }
                    }
                });
            });

            dispatch(stateController.setState({
                ...state, clubsModal: {
                    ...state.clubsModal,
                    squadsView: newSquadView, selectedSquadsView: newSelectedSquadView, selectedIds: selectedIds, recommendedView: newRecommendedView, isLoading: false
                }
            }));
        }
    }

    public static toggleCollapseSelected(competitionId: number, isCollapsed: boolean) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            const tree = [...state.clubsModal.selectedSquadsView];
            const find = tree.find(x => x.id === competitionId);
            if (find) {
                find.isCollapsed = isCollapsed
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, selectedSquadsView: tree } }));
            }
        }
    }

    public static toggleCollapseUnselected(competitionId: number, isCollapsed: boolean) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            const tree = [...state.clubsModal.squadsView];
            const find = tree.find(x => x.id == competitionId);
            if (find) {
                find.isCollapsed = isCollapsed
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, squadsView: tree } }));
            }
        }
    }

    public static toggleCollapseRecommended(isCollapsed: boolean) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const find = { ...state.clubsModal.recommendedView };
            if (find) {
                find.isCollapsed = isCollapsed
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, recommendedView: find } }));
            }
        }
    }

    public static setSearchString(search: string) {
        return (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, clubsModal: { ...state.clubsModal, searchString: search } })));
        }
    }

    public static removeAll() {
        return (dispatch, getState: () => AppState) => {
            const coachId = Selectors.getRoot(getState()).strategyModalData.coachId;
            const squadId = Selectors.getRoot(getState()).headCoaches.find(x => x.staffId === coachId)?.squadId;

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Strategy]',
                Message: 'Clicked Remove All Selected Leagues',
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            dispatch(stateController.setState((state) => ({
                ...state, clubsModal: {
                    ...state.clubsModal,
                    squadsView: state.clubsModal.squads, selectedSquadsView: [], selectedIds: [], recommendedView: state.clubsModal.recommended
                }
            })));
        }
    }


    //________________________Downgrade Coach Modal_______________________________

    public static openDowngradeCoachModal(coachId: number, isAction?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { headCoaches } = Selectors.getRoot(getState());
            const headCoach = headCoaches.find(x => x.staffId === coachId);
            const squadId = headCoach?.squadId;
            const downgradePageName = `${isAction ? 'Agency Head Coaches [Actions]' : 'Agency Head Coaches [Downgrade Head Coach]'}`;

            dispatch(userActivityInsert({
                PageName: downgradePageName,
                Message: 'Clicked Downgrade Head Coach',
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                downgradeCoachModal: {
                    coachId,
                    squadId,
                    coachName: headCoach.coachName,
                    isDowngradeCoachModalOpen: true,
                    isDowngraded: false,
                },
            })));

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Downgrade Head Coach]',
                Message: 'Opened Downgrade Head Coach Pop-up',
                CoachId: coachId,
                ClubId: squadId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static closeDowngradeCoachModal(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { coachId, squadId } = Selectors.getRoot(getState()).downgradeCoachModal;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Downgrade Head Coach]',
                    Message: 'Collapsed Downgrade Head Coach Pop-up',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Downgrade Head Coach]',
                    Message: 'Clicked Cancel Downgrade Head Coach',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                downgradeCoachModal: {
                    coachId: null,
                    squadId: null,
                    coachName: '',
                    isDowngradeCoachModalOpen: false,
                },
            })));
        }
    }

    public static onDowngradeCoach(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { squadId } = Selectors.getRoot(getState()).downgradeCoachModal;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    downgradeCoachModal: {
                        ...prevState.downgradeCoachModal,
                        processing: true
                    }
                })));

                await AgencyHeadCoachesService.downgradeCoach(coachId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    downgradeCoachModal: {
                        ...prevState.downgradeCoachModal,
                        isDowngraded: true,
                        isDowngradeCoachModalOpen: false,
                        // coachId: null,
                        coachName: ''
                    }
                })));

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Downgrade Head Coach]',
                    Message: 'Clicked Downgrade Head Coach',
                    CoachId: coachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));

                dispatch(Actions.init());
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    downgradeCoachModal: {
                        ...prevState.downgradeCoachModal,
                        processing: false
                    }
                })));
            }
        }
    }


    // ____________________Remove Head Coach Modal______________________

    public static openRemoveCoachModal(coachId: number) {
        return (dispatch, getState: () => AppState) => {
            const { headCoaches } = Selectors.getRoot(getState());
            const headCoach = headCoaches.find(x => x.staffId === coachId);

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Actions]',
                Message: 'Clicked Remove Head Coach',
                CoachId: coachId,
                ClubId: headCoach?.squadId,
                PageType: PageType.AgencyCoaches,
            }));

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                removeCoachModal: {
                    removingCoachId: coachId,
                    squadId: headCoach?.squadId,
                    coachName: headCoach.coachName,
                    isRegular: headCoach.isRegular,
                    isPriority: !headCoach.isRegular,
                    isRemoveCoachModalOpen: true,
                    isGetInTouchRequestSent: false,
                },
            })));

            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Remove Head Coach]',
                Message: 'Opened Remove Head Coach Pop-up',
                CoachId: coachId,
                ClubId: headCoach?.squadId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static closeRemoveCoachModal(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { removingCoachId, squadId } = Selectors.getRoot(getState()).removeCoachModal;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Remove Head Coach]',
                    Message: 'Collapsed Remove Head Coach Pop-up',
                    CoachId: removingCoachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Remove Head Coach]',
                    Message: 'Clicked Cancel',
                    CoachId: removingCoachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(Actions.cancelRemoveCoachModal());
        }
    }

    public static closeGetInTouchRequestSent(isCollapsed?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const { removingCoachId, squadId } = Selectors.getRoot(getState()).removeCoachModal;

            if (isCollapsed) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Remove Head Coach]',
                    Message: 'Collapsed Get In Touch Pop-up',
                    CoachId: removingCoachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Remove Head Coach]',
                    Message: 'Clicked Cancel Get In Touch',
                    CoachId: removingCoachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            dispatch(Actions.cancelRemoveCoachModal());
        }
    }

    public static cancelRemoveCoachModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                removeCoachModal: {
                    ...defaultState.removeCoachModal
                }
            })))
        }
    }

    public static onGetInTouchClick() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { removingCoachId, squadId } = Selectors.getRoot(getState()).removeCoachModal;

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Remove Head Coach]',
                    Message: 'Clicked Get In Touch',
                    CoachId: removingCoachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    removeCoachModal: {
                        ...prevState.removeCoachModal,
                        processing: true
                    }
                })));

                await AgencyPlayerService.getinTouchFromRemovalEmail();

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    removeCoachModal: {
                        ...prevState.removeCoachModal,
                        isGetInTouchRequestSent: true,
                    }
                })));

                dispatch(userActivityInsert({
                    PageName: 'Agency Head Coaches [Remove Head Coach]',
                    Message: 'Opened Get In Touch Pop-up',
                    CoachId: removingCoachId,
                    ClubId: squadId,
                    PageType: PageType.AgencyCoaches,
                }));

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    removeCoachModal: {
                        ...prevState.removeCoachModal,
                        processing: false,
                    }
                })));
            }
        }
    }

    public static removeHeadCoach() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const { removeCoachModal } = Selectors.getRoot(getState());
            const removingCoachId = removeCoachModal.removingCoachId;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                removeCoachModal: {
                    ...prevState.removeCoachModal,
                    processing: true
                }
            })));

            try {
                dispatch(stateController.setState({ removedCoachesList: [...subState.removedCoachesList, removingCoachId] }))

                await AgencyHeadCoachesService.removeCoach(removingCoachId);

                const deletedCoach = subState.headCoaches.find(i => i.staffId === removingCoachId);
                const isRegularCoach = deletedCoach.isRegular

                dispatch(stateController.setState({
                    headCoaches: subState.headCoaches.filter(a => a.staffId !== removingCoachId),
                    removedCoachesList: [...subState.removedCoachesList.filter(x => x != removingCoachId)],
                    totalPrioritySlots: isRegularCoach ? subState.totalPrioritySlots : (subState.totalPrioritySlots - 1),
                    regularCreditsAvailable: isRegularCoach ? subState.regularCreditsAvailable + 1 : subState.regularCreditsAvailable
                }
                ))

                if (deletedCoach) {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Head Coaches [Remove Head Coach]',
                        Message: 'Clicked Remove Head Coach',
                        CoachId: deletedCoach.staffId,
                        ClubId: deletedCoach?.squadId,
                        PageType: PageType.AgencyCoaches,
                    }));
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    removeCoachModal: {
                        ...prevState.removeCoachModal,
                        isRemoveCoachModalOpen: false,
                    }
                })));
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState({
                    removedCoachesList: [...subState.removedCoachesList.filter(x => x != removingCoachId)]
                }))
                dispatch({ type: '--ERROR' })
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    removeCoachModal: {
                        ...prevState.removeCoachModal,
                        processing: false,
                    }
                })));
            }
        }
    }

    public static onFindOutMoreClick() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches',
                Message: 'Clicked Find Out More',
                PageType: PageType.AgencyCoaches,
            }));
            dispatch(FindHeadCoachPopupController.openModal());
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Upgrade Access]',
                Message: 'Opened Upgrade Access Pop-up',
                PageType: PageType.AgencyCoaches,
            }));
        }
    }

    public static sendUserActivity(pageName: string, message: string, coachId?: number, clubId?: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                CoachId: coachId,
                ClubId: clubId,
                PageType: PageType.AgencyCoaches,
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): HeadCoachesState => state.agency.landingPage.headCoaches;
    public static getShareLinkModal = (state: AppState) => Selectors.getRoot(state).shareLinkModal;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    Actions as Actions,
    HeadCoachesState as State,
    Selectors as Selectors,
    stateController as Controller
}