import { StateController } from 'utils/action-declaration';
import moment from 'moment';
import { PlayerAdWithCountersModel, PlayerAdModel } from 'api/player-ad-v2/models/player-ad-with-counters';
import PlayerAdV2Service from 'api/player-ad-v2/player-ad-v2.service';
import { PLAYER_AD_CREATOR_MODAL } from 'constants/modals';
import { modalOpen, modalClose } from 'store/modals/modalsActions';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import * as CommonController from './common.controller'
import * as CreatingAdController from "pages/player-ads/create-new-ad-modal/redux/create-new-ad.controller";

import { userPaths } from 'routes/paths';
import { getCancelTokenSource } from 'axios-config'
import { CancelToken } from 'axios';
import { PlayerAdType } from 'api/player-ad-v2/models/player-ad-with-counters';
import LiveChatController from 'services/live-chat-controller';
import historyAccessor from 'history-accessor';
import { PageType } from 'constants/enums';

class PlayerAdMyAds {
    isLoading: boolean;
    myAds: Array<PlayerAdWithCountersModel>;
    outdatedAds: Array<PlayerAdModel>;
    processingIds: Array<number>;
}

const defaultState: PlayerAdMyAds = {
    isLoading: false,
    myAds: [],
    processingIds: [],
    outdatedAds: []
}

const stateController = new StateController<PlayerAdMyAds>(
    "PLAYR_ADS/MY_ADS",
    defaultState
);

class Actions {

    public static token = null;

    public static loadAllAds(redirect: (path) => void) {
        return async dispatch => {
            dispatch(stateController.setState({ isLoading: true }));

            Actions.token = getCancelTokenSource();

            let myAdsItems = await PlayerAdV2Service.getAllAdsWithCounters(Actions.token.token);

            if (myAdsItems) {

                let sortByUpdatedAt = (m: Array<PlayerAdWithCountersModel>) => {
                    return m.sort((firstAd, secondAd) => {
                        const firstAdUpdated = moment(firstAd.ad.updatedAt)
                        const secondAdUpdated = moment(secondAd.ad.updatedAt)
                        if (firstAdUpdated == secondAdUpdated) return 0
                        if (firstAdUpdated > secondAdUpdated) return -1
                        return 1;
                    })
                }

                let sortedAds = [...sortByUpdatedAt(myAdsItems.filter(x => x.ad.isOutdated)), ...sortByUpdatedAt(myAdsItems.filter(x => !x.ad.isOutdated))]


                dispatch(stateController.setState({ isLoading: false, myAds: sortedAds }));

                if (myAdsItems.length === 0) {
                    redirect(userPaths.playerAds_Activity)
                }
            }
        }
    }

    public static loadOutDatedAds(cancelToken: CancelToken) {
        return async dispatch => {
            dispatch(stateController.setState({ isLoading: true }));
            let myAds = await PlayerAdV2Service.getOutdatedAds(cancelToken);

            let sortByUpdatedAt = (m: Array<PlayerAdModel>) => {
                return m.sort((firstAd, secondAd) => {
                    const firstAdUpdated = moment(firstAd.updatedAt)
                    const secondAdUpdated = moment(secondAd.updatedAt)
                    if (firstAdUpdated == secondAdUpdated) return 0
                    if (firstAdUpdated > secondAdUpdated) return -1
                    return 1;
                })
            }

            if (myAds) {
                dispatch(stateController.setState({ isLoading: false, outdatedAds: sortByUpdatedAt(myAds) }));
            }
        }
    }

    public static updateAd(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let processingIds = state.playerAdPage.myAds.processingIds;

            try {
                dispatch(stateController.setState({ processingIds: [...processingIds, adId] }));
                await PlayerAdV2Service.updateAdsBatch([adId])
                dispatch(CommonController.Actions.loadCounters());

                state = getState();
                let allAds = state.playerAdPage.myAds.myAds
                allAds.find(item => item.ad.id === adId).ad.isOutdated = false;
                allAds.find(item => item.ad.id === adId).ad.isUpdatable = false;
                allAds.find(item => item.ad.id === adId).ad.updatedAt = new Date().toUTCString();

                let sortByUpdatedAt = (m: Array<PlayerAdWithCountersModel>) => {
                    return m.sort((firstAd, secondAd) => {
                        const firstAdUpdated = moment(firstAd.ad.updatedAt)
                        const secondAdUpdated = moment(secondAd.ad.updatedAt)
                        if (firstAdUpdated == secondAdUpdated) return 0
                        if (firstAdUpdated > secondAdUpdated) return -1
                        return 1;
                    })
                }

                let sortedAds = [...sortByUpdatedAt(allAds.filter(x => x.ad.isOutdated)), ...sortByUpdatedAt(allAds.filter(x => !x.ad.isOutdated))]

                dispatch(stateController.setState({ myAds: [...sortedAds] }))

            } catch (e) {
                console.error(e)
            } finally {
                processingIds = getState().playerAdPage.myAds.processingIds;
                dispatch(stateController.setState({ processingIds: [...processingIds.filter(x => x != adId)] }));
            }
        }
    }

    public static stillLooking(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let processingIds = state.playerAdPage.myAds.processingIds;

            try {
                dispatch(stateController.setState({ processingIds: [...processingIds, adId] }));
                
                let item = state.playerAdPage.myAds.outdatedAds.find(x => x.id == adId);
                let positionsText = `${item.positionCode}, ${item.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`;
                dispatch(userActivityInsert({
                    Message: `Updated Ad: ${positionsText}`,
                    PageName: 'Player Ads [Activity]',
                    PageType: PageType.PlayerAds
                }));
                
                await PlayerAdV2Service.updateAdsBatch([adId])
                                
                dispatch(CommonController.Actions.loadCounters());

                state = getState();
                let allAds = state.playerAdPage.myAds.outdatedAds
                dispatch(stateController.setState({ outdatedAds: [...allAds.filter(x => x.id != adId)] }))

            } catch (e) {
                console.error(e)
            } finally {
                processingIds = getState().playerAdPage.myAds.processingIds;
                dispatch(stateController.setState({ processingIds: [...processingIds.filter(x => x != adId)] }));
            }
        }
    }

    public static removeAd(adId: number, redirect: (path: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let processingIds = state.playerAdPage.myAds.processingIds;

            try {
                dispatch(stateController.setState({ processingIds: [...processingIds, adId] }));
                await PlayerAdV2Service.deleteAd(adId)
                dispatch(CommonController.Actions.loadCounters());

                let item = state.playerAdPage.myAds.outdatedAds.find(x => x.id == adId);
                let positionsText = '';
                if(item !== undefined && item !== null){
                    positionsText = `${item.positionCode}, ${item.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`;                    
                }
                else{
                    let item = state.playerAdPage.myAds.myAds.find(x => x.ad.id == adId);
                    positionsText = `${item.ad.positionCode}, ${item.ad.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`
                }
                
                dispatch(userActivityInsert({
                    Message: `Removed Ad: ${positionsText}`,
                    PageName: 'Player Ads [Activity]',
                    PageType: PageType.PlayerAds
                }));


                state = getState();
                const adsAfterRemove = [...state.playerAdPage.myAds.myAds.filter(x => x.ad.id != adId)]
                const outdatedAdsAfterRemove = [...state.playerAdPage.myAds.outdatedAds.filter(x => x.id != adId)]
                dispatch(stateController.setState({
                    myAds: adsAfterRemove,
                    outdatedAds: outdatedAdsAfterRemove
                }))

                if ((adsAfterRemove.length + outdatedAdsAfterRemove.length) == 0 && historyAccessor.location.pathname !== userPaths.playerAds_Activity) {
                    redirect(userPaths.playerAds_Activity)
                }

            } catch (e) {
                console.error(e)
            } finally {
                processingIds = getState().playerAdPage.myAds.processingIds;
                dispatch(stateController.setState({ processingIds: [...processingIds.filter(x => x != adId)] }));
            }
        }
    }

    public static softlyRemoveAd(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const adsAfterRemove = [...state.playerAdPage.myAds.myAds.filter(x => x.ad.id != adId)]
            const outdatedAdsAfterRemove = [...state.playerAdPage.myAds.outdatedAds.filter(x => x.id != adId)]
            dispatch(stateController.setState({
                myAds: adsAfterRemove,
                outdatedAds: outdatedAdsAfterRemove
            }))

        }
    }

    // public static openCreateNewAd() {
    //     return async (dispatch, getState: () => AppState) => {
    //         dispatch(modalOpen({ id: PLAYER_AD_CREATOR_MODAL,customToggle: CreatingAdController.Actions.closeModal }))
    //         dispatch(userActivityInsert({
    //             Message: 'Opened',
    //             PageName: 'Player Ads [Create Player Ad]',
    //             PageType: PageType.PlayerAds
    //         }));
    //     }
    // }

    public static closeCreateNewAd() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(modalClose(PLAYER_AD_CREATOR_MODAL));
        }
    }


    public static dispose() {
        if (Actions.token) {
            Actions.token.cancel();
            Actions.token = null;
        }

        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }
}


const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerAdMyAds as State,
    Actions as Actions,
    stateController as Controller
};



