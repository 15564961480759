import { useCallback, useEffect } from "react";
import { connect } from 'react-redux'
import { getAuthUserId } from 'store/auth/authReducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType } from "constants/enums";

interface StateProps {
    userId: number;
}
interface DispatchProps {
    userActivityInsert: (obj: any) => void;
}
interface Props extends StateProps, DispatchProps {}

function PrintScreenEventHandler({ userActivityInsert, userId }: Props) {

    const handlePrintScreenKeyPress = useCallback((event: KeyboardEvent) => {

        const path = location.pathname;
        if (path.startsWith('/admin') || path.startsWith('/login') || path.startsWith('/404')) return;

        // Only Windows
        if (event.code === "PrintScreen" || event.keyCode === 44) {
            userActivityInsert({
                Message: `Used "Print Screen"`,
                PageName: `URL: ${path}`,
                UserId: userId,
                ActionType: ActionType.Printscreen
            })
        }

    }, [location])

    useEffect(() => {
        window.addEventListener('keyup', handlePrintScreenKeyPress)
        return () => {
            window.removeEventListener('keyup', handlePrintScreenKeyPress)
        }
    }, [handlePrintScreenKeyPress])

    return null
}

const mapStateToProps = (state) => ({
    userId: getAuthUserId(state),
})
const mapDispatchToProps = {
    userActivityInsert: userActivityInsert,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintScreenEventHandler)
