import axios from 'axios-config'
import { AdminPlayerOnboardRequest, AdminPlayerOnboardResponse, PlayerDataModel, PlayerSuggestionItem, GetPlayerByLinkResponse } from './models'
import { CancelToken } from 'axios'



export class PlayerOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getPlayerOnboardingSuggestions(keyword: string, cancelToken: CancelToken)  {
        const { data } = await axios.post<PlayerSuggestionItem[]>(
            `api/v2/AdminPlayerOnboarding/Suggestions`,
            { keyword },
            {...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getPlayerUserData(playerId: number)  {
        const { data } = await axios.get<PlayerDataModel>(
            `api/v2/AdminPlayerOnboarding/GetPlayerUserData/${playerId}`,
            this.axiosConfig
        )
        return data
    }

    public static async onboardPlayer(payload: AdminPlayerOnboardRequest){        
        const { data } = await axios.post<AdminPlayerOnboardResponse>(
            `api/v2/AdminPlayerOnboarding/OnboardPlayer`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async getPlayerByLink(link): Promise<GetPlayerByLinkResponse> {
        const { data } = await axios.post(            
            `api/v2/PlayerReferral/GetPlayerByLink`,
            JSON.stringify(link),
            this.axiosConfig
        )
        return data
    }

}