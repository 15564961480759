import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service'
import { MySquadPlayerAdsRecommendation } from 'api/custom-sign-in/models/club-ads-proposal.model';
import { modalClose } from 'store/modals/modalsActions'
import { MODAL_OPEN } from 'store/actionTypes'
import { EMAIL_LANDING_CLUB_ADS_RECOMMENDATIONS_DONE } from 'constants/modals'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PlayerAdTypeEnum } from 'api/custom-sign-in/models/otdated-ads.model';

class FriendlyProposalsState {
    isLoading: boolean;
    data: Array<MySquadPlayerAdsRecommendation>;
    pitchedPlayerIds: Array<{ playerId: number, adId: number, amount: number, type: PlayerAdTypeEnum }>;
    declinedPlayerIds: Array<{ playerId: number, adId: number }>;
    busyPlayerAds: Array<{ playerId: number, adId: number }>;
}

const defaultState: FriendlyProposalsState = {
    isLoading: false,
    data: null,
    pitchedPlayerIds: [],
    declinedPlayerIds: [],
    busyPlayerAds: []
}

const stateController = new StateController<FriendlyProposalsState>(
    "LANDING/CLUB_ADS_PROPOSALS",
    defaultState
);


class Actions {

    public static pitchPlayer(playerId: number, adId: number, amount: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            const squadId = appState.auth.squadId
            dispatch(stateController.setState({ busyPlayerAds: [...appState.landing.clubAdsProposals.busyPlayerAds, { adId: adId, playerId: playerId }] }))
            var result = await CustomSignInService.pitchPlayer(appState.auth.userId, adId, amount, playerId, squadId);

            let ad = appState.landing.clubAdsProposals.data.find(i => i.player.id == playerId).ads.find(x => x.id == adId);

            dispatch(stateController.setState({
                busyPlayerAds: appState.landing.clubAdsProposals.busyPlayerAds.filter(i => i.adId != adId && i.playerId != playerId),
                pitchedPlayerIds: [...appState.landing.clubAdsProposals.pitchedPlayerIds, { adId: adId, playerId: playerId, amount: amount, type: ad.type }]
            }));

            dispatch(userActivityInsert({
                PageName: `Mail [Club Ads Proposals]`,
                Message: `Pitched to ${ad.squad.name}, ${ad.type == PlayerAdTypeEnum.Buy ? 'sale' : 'loan'}, ${amount}`,
                PlayerId: playerId,
            }));
        }
    }

    public static decline(playerId: number, adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ busyPlayerAds: [...appState.landing.clubAdsProposals.busyPlayerAds, { adId: adId, playerId: playerId }] }))
            var result = await CustomSignInService.notInterested(playerId, adId);

            let ad = appState.landing.clubAdsProposals.data.find(i => i.player.id == playerId).ads.find(x => x.id == adId);
            dispatch(userActivityInsert({
                PageName: `Mail [Club Ads Proposals]`,
                Message: `Declined from ${ad.squad.name}, ${ad.type == PlayerAdTypeEnum.Buy ? 'sale' : 'loan'}`,
                PlayerId: playerId,
            }));


            let allData = getState().landing.clubAdsProposals.data
            const playerData = allData.find(item => item.player.id === playerId)
            const player = playerData.player
            const ads = (playerData || {}).ads.filter(item => item.id !== adId)
            const updatedPlayerData = { player: player, ads: ads }

            const isPlayerDoNotHaveAds = ads.length <= 0
            const objectiveIndex = allData.findIndex(item => item.player.id === playerId)

            if (isPlayerDoNotHaveAds) {
                allData.splice(objectiveIndex, 1)
            } else {
                allData[objectiveIndex] = updatedPlayerData
            }

            dispatch(stateController.setState({
                busyPlayerAds: appState.landing.clubAdsProposals.busyPlayerAds.filter(i => i.adId != adId && i.playerId != playerId),
                declinedPlayerIds: [...appState.landing.clubAdsProposals.declinedPlayerIds, { adId: adId, playerId: playerId }],
                data: [...allData]
            }));

            
        }
    }

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ isLoading: true, }))
            var result = await CustomSignInService.getClubAdsProposals(appState.auth.squadId, appState.auth.userId);
            dispatch(stateController.setState({ isLoading: false, data: result }));
        }
    }

    public static doneAndRedirect(redirect) {
        return dispatch => {
            dispatch(modalClose(EMAIL_LANDING_CLUB_ADS_RECOMMENDATIONS_DONE))
            redirect()
        }
    }

    public static openDoneModal(redirect) {
        return dispatch => {
            const command = {
                type: MODAL_OPEN,
                payload: {
                    id: EMAIL_LANDING_CLUB_ADS_RECOMMENDATIONS_DONE,
                    customToggle: () => Actions.doneAndRedirect(redirect),
                    content: {
                        onDone: () => dispatch(Actions.doneAndRedirect(redirect)),
                        text: 'You have cleared all recommendations',
                    }
                }
            };
            dispatch(command);
            dispatch(userActivityInsert({
                PageName: `Mail [Club Ads Proposals]`,
                Message: `Updated all club ads proposals`,
            }));
        }
    }


}

class Selectors {
    public static selectIsAllRecommendationsDone(state) {

        const pitchedPlayerIds = state.landing.clubAdsProposals.pitchedPlayerIds.map(item => item.adId)
        const data = state.landing.clubAdsProposals.data
        if (!data) return false

        const countOfItems = data.reduce((acc, item) => item.ads.length + acc, 0)
        const countOfDoneItems = data.reduce((acc, item) => {
            let doneItems = item.ads.reduce((acc, item) => {
                if (pitchedPlayerIds.some(id => id === item.id)) return acc + 1
                return acc
            }, 0)
            return acc + doneItems
        }, 0)

        return countOfDoneItems >= countOfItems;
        
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FriendlyProposalsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



