export enum PitchPageType {
  pitch = 'Pitch',
  testPitch = 'Test Pitch',
  savedPitch = 'Saved Pitch',
  plusPitch = 'Plus Pitch',
  plusTestPitch = 'Test Plus Pitch',
  plusSavedPitch = 'Saved Plus Pitch',
  superPitch = 'Super Pitch',
  testSuperPitch = 'Test Super Pitch',
  savedSuperPitch = 'Saved Super Pitch'
}