import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { PageType } from 'constants/enums'
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import AgencyCoachOnboardingService from 'api/agency/agent/onboarding/coaches/coach-onboarding.service';
import { CoachVerificationItemModel, PendingVerificationCoachModel } from 'api/agency/agent/onboarding/coaches/models';
import OnboardingService from 'api/agency/agent/onboarding/onboarding.service';
import * as RestrictedCreditsModalController
    from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-coach-credits-modal/restricted-coach-credits.controller';
import { RestrictedCoachCreditsModalType } from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-coach-credits-modal/restricted-coach-credits.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

class State {
    isLoading: boolean;
    serverData: any[];
    uiData: CoachVerificationItemModel[];
    toSend: number[];
    done: boolean;
    successPremiumCount: number;
    successRegularCount: number;
    failedPremiumCount: number;
    failedRegularCount: number;
    amountOfAvailableCredits: number;
    amountOfAvailableRegularCredits: number;
    isUnlimitedRegularCredits: boolean;
    hasSubscription: boolean;
    verifiedPriorityCoachesQty: number;
    isRedirectToPitchPage: boolean;
    premiumBenefitsModalOpen: boolean;
}

const defaultState: State = {
    isLoading: true,
    serverData: null,
    uiData: [],
    toSend: [],
    done: false,
    successPremiumCount: 0,
    successRegularCount: 0,
    failedPremiumCount: 0,
    failedRegularCount: 0,
    amountOfAvailableCredits: 0,
    amountOfAvailableRegularCredits: 0,
    isUnlimitedRegularCredits: false,
    hasSubscription: false,
    verifiedPriorityCoachesQty: 0,
    isRedirectToPitchPage: false,
    premiumBenefitsModalOpen: false
}

const stateController = new StateController<State>(
    'AGENCY/VERIFY-COACHES',
    defaultState
)

class Actions {
    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static init(staffId?: number) {
        return async (dispatch, getState: () => AppState) => {
            await Promise.all(
                [
                    dispatch(Actions.loadCoaches(staffId)),
                    dispatch(Actions.loadAmountOfAvailableCredits()),
                    dispatch(Actions.loadAmountOfAvailableRegularCredits())
                ]
            );
        }
    }

    public static loadCoaches(staffId?: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: true })));
            try {
                const res = await AgencyCoachOnboardingService.getUnverifiedCoaches();

                const sent = res.items.filter(x => x.isImmutable);
                const specific = res.items.filter(x => x.staffId == staffId);
                for (const obj of specific) {
                    obj.isRegular = true;
                }
                const rest = res.items.filter(x => !x.isImmutable && x.staffId !== staffId);
                for (const obj of rest) {
                    obj.isRegular = true;
                }
                rest.sort(function (a, b) {
                    const isRegularComparison = Number(a.isRegular) - Number(b.isRegular);
                    if (isRegularComparison)
                        return isRegularComparison;

                    if (a.coachName < b.coachName) { return -1; }
                    if (a.coachName > b.coachName) { return 1; }
                    return 0;
                });
                let result = [];
                if (specific.length > 0) {
                    result = [...sent, ...specific, ...rest];
                } else {
                    result = [...sent, ...rest];
                }
                const toSend = [];
                res.items.map(x => {
                    if (x.phoneCodeAreaId != null && x.phone != null && !x.isImmutable) {
                        toSend.push(x.staffId);
                    }
                });

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    serverData: res.items,
                    uiData: result,
                    toSend: toSend,
                    verifiedPriorityPlayersQty: res.items.filter(item => !item.isRegular).length, // TODO: ASK ANDRIY
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: false })));
            }
        }
    }

    public static loadAmountOfAvailableCredits = () => {
        return async (dispatch, getState: () => AppState) => {
            const data = await OnboardingService.getSubscriptionStats();
            let amountOfAvailableCredits = 0;
            const hasSubscription = !!data;
            if (hasSubscription) {
                amountOfAvailableCredits = data.totalSlots - data.usedSlots;
            }
            dispatch(stateController.setState(({ amountOfAvailableCredits, hasSubscription })));
        }
    }

    public static loadAmountOfAvailableRegularCredits = () => {
        return async (dispatch, getState: () => AppState) => {
            const data = await OnboardingService.getRegularPlayerSlotStats();
            const { availableRegularPlayerSlots, isUnlimitedRegularPlayerSlots } = data;
            dispatch(stateController.setState(({
                amountOfAvailableRegularCredits: availableRegularPlayerSlots,
                isUnlimitedRegularCredits: isUnlimitedRegularPlayerSlots
            })));
        }
    }

    public static toggleCoachSelected(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const find = subState.toSend.find(x => x === staffId);
            const uiDataCopy = [...subState.uiData];
            const coach = uiDataCopy.find(x => x.staffId == staffId);
            const selectedRegular = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.staffId) && obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);
            const selectedPremium = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.staffId) && !obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);
            const totalRegularAvailable = subState.amountOfAvailableRegularCredits - (selectedRegular + selectedPremium)

            if (find) {
                coach.isRegular = true;
                dispatch(stateController.setState({ toSend: subState.toSend.filter(x => x !== staffId), uiData: uiDataCopy }))
                dispatch(userActivityInsert({
                    Message: 'Unchecked Head Coach',
                    PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                    CoachId: coach.staffId,
                    ClubId: coach.squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            } else {
                if (totalRegularAvailable <= 0 && !subState.isUnlimitedRegularCredits) {
                    dispatch(RestrictedCreditsModalController.Actions.openRestrictedRegularCreditsModal(RestrictedCoachCreditsModalType.AddHeadCoach));
                    return;
                }
                dispatch(stateController.setState({ toSend: [...subState.toSend, staffId] }))
                dispatch(userActivityInsert({
                    Message: 'Checked Head Coach',
                    PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                    CoachId: coach.staffId,
                    ClubId: coach.squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    public static toggleIsPremium(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const selectedRegular = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.staffId) && obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);
            const selectedPremium = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.staffId) && !obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);


            const uiDataCopy = [...subState.uiData];
            let toSendCopy = [...subState.toSend];

            const coach = uiDataCopy.find(x => x.staffId === staffId);

            const totalPremiumAvailable = subState.amountOfAvailableCredits - (selectedPremium);
            const totalRegularAvailable = subState.amountOfAvailableRegularCredits - (selectedRegular + selectedPremium);
            if (totalPremiumAvailable <= 0 && !subState.toSend.some(x => x === staffId)) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedPriorityCreditsModal(RestrictedCoachCreditsModalType.AddHeadCoach));
                return;
            }
            if (totalPremiumAvailable <= 0 && subState.toSend.some(x => x === staffId) && coach.isRegular) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedPriorityCreditsModal(RestrictedCoachCreditsModalType.AddHeadCoach));
                return;
            }
            if (totalRegularAvailable <= 0 && !subState.isUnlimitedRegularCredits && !subState.toSend.some(x => x === staffId)) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedRegularCreditsModal(RestrictedCoachCreditsModalType.AddHeadCoach));
                return;
            }

            dispatch(userActivityInsert({
                Message: coach.isRegular ? 'Checked Priority Head Coach' :  'Unchecked Priority Head Coach',
                PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                CoachId: coach.staffId,
                ClubId: coach.squadId,
                PageType: PageType.AgencyCoaches,
            }));

            if (coach.isRegular && !subState.toSend.some(x => x === staffId)) {
                dispatch(userActivityInsert({
                    Message: `Checked ${coach.coachName}`,
                    PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                    CoachId: coach.staffId,
                    ClubId: coach.squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }

            coach.isRegular = !coach.isRegular;

            if (!coach.isRegular) {
                const isCoachToSend = subState.toSend.find(x => x === staffId);
                if (!isCoachToSend) {
                    toSendCopy = [...toSendCopy, staffId];
                }
            }

            dispatch(stateController.setState({ uiData: uiDataCopy, toSend: toSendCopy }))
        }
    }

    public static setCoachPhoneCodeAreaId(staffId: number, areaId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const stateNew = [...subState.uiData];

            const coach = stateNew.find(x => x.staffId === staffId);
            coach.phoneCodeAreaId = areaId;

            dispatch(stateController.setState({
                uiData: stateNew,
            }));
        }
    }

    public static setCoachPhone(staffId: number, phone: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const stateNew = [...subState.uiData];

            const coach = stateNew.find(x => x.staffId === staffId);
            coach.phone = phone;

            dispatch(stateController.setState({
                uiData: stateNew,
            }));
        }
    }

    public static enteredCoachPhone(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const uiData = subState.uiData;
            const coach = uiData.find(x => x.staffId === staffId);

            if (coach && !!coach.phone) {
                dispatch(userActivityInsert({
                    Message: `Entered Phone Number`,
                    PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                    CoachId: coach.staffId,
                    ClubId: coach.squadId,
                    PageType: PageType.AgencyCoaches,
                }));
            }
        }
    }

    public static redirectToPitchPage() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isRedirectToPitchPage: !prevState.isRedirectToPitchPage,
            })));
        }
    }

    public static closed() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            dispatch(userActivityInsert({
                Message: `Clicked Cancel`,
                PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                PageType: PageType.AgencyCoaches,
            }));

            // if(subState.isRedirectToPitchPage) {
            //     historyAccessor.push(agencyPaths.pitchPage);
            //     dispatch(Actions.redirectToPitchPage());
            // } else {
            //     historyAccessor.push('/')
            // }
            historyAccessor.push('/')
        }
    }

    public static send() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());

            const { hasSubscription } = subState
            if (!hasSubscription) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedPriorityCreditsModal(RestrictedCoachCreditsModalType.AddHeadCoach));
                return;
            }

            dispatch(stateController.setState({ isLoading: true }));
            const allCoaches: PendingVerificationCoachModel[] = [];
            const uaCoaches: CoachVerificationItemModel[] = [];
            subState.toSend.map(staffId => {
                const find = subState.uiData.find(x => x.staffId == staffId);
                allCoaches.push({ staffId: staffId, phone: find.phone, phoneCodeAreaId: find.phoneCodeAreaId, isRegular: find.isRegular })
                uaCoaches.push(find)
            });
            const data = await AgencyCoachOnboardingService.sendToVerify({ coaches: allCoaches });

            const uaPriorityCoaches = uaCoaches.filter(x => !x.isRegular).map(x => `${x.coachName}: Priority`);
            const uaNonPriorityCoaches = uaCoaches.filter(x => x.isRegular).map(x => `${x.coachName}: Non-Priority`);

            dispatch(userActivityInsert({
                Message: `Clicked Send ${uaCoaches.length} Verification Requests: ${uaPriorityCoaches.join('; ')}; ${uaNonPriorityCoaches.join('; ')}`,
                PageName: 'Agency Head Coaches [Get Your Head Coach Verified]',
                PageType: PageType.AgencyCoaches,
            }))


            const successPremiumCount = data.successPremiumQty;
            const failedPremiumCount = data.failedPremiumQty;
            const successRegularCount = data.successRegularQty;
            const failedRegularCount = data.failedRegularQty;

            dispatch(stateController.setState({
                isLoading: false,
                done: true,
                successPremiumCount,
                failedPremiumCount,
                successRegularCount,
                failedRegularCount
            }));
        }
    }

    public static done() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const regularQty = (subState.successRegularCount || 0) + (subState.failedRegularCount || 0);
            const premiumQty = (subState.successPremiumCount || 0) + (subState.failedPremiumCount || 0);
            // dispatch(userActivityInsert({
            //     Message: `Done. ${regularQty + premiumQty} regular and ${premiumQty} priority requests sent`,
            //     PageName: `Agency Home [Player Verification]`,
            //     PageType: PageType.AgencyHome,
            // }));
            if(subState.isRedirectToPitchPage) {
                historyAccessor.push(agencyPaths.pitchPage);
                dispatch(Actions.redirectToPitchPage());
            } else {
                historyAccessor.push('/')
            }

        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.agent.verifyCoaches;
    public static getUiData = (state: AppState) => Selectors.getRoot(state).uiData;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getItemsToSend = (state: AppState) => Selectors.getRoot(state).toSend;
    public static getCanSend = (state: AppState) => {
        const uiData = Selectors.getUiData(state);
        const toSend = Selectors.getItemsToSend(state);
        return toSend.every(id => (uiData || []).some(x => x.staffId == id && !x.isImmutable && x.phone && x.phoneCodeAreaId));
    }
    public static getSentRequests =  (state: AppState) => {
        return Selectors.getRoot(state).successPremiumCount + Selectors.getRoot(state).successRegularCount;
    }
    public static getFailedRequests =  (state: AppState) => {
        return Selectors.getRoot(state).failedPremiumCount + Selectors.getRoot(state).failedRegularCount;
    }

    public static getAvailableRegularCredits = (state: AppState) => {
        const uiData = Selectors.getUiData(state);
        const toSend = Selectors.getItemsToSend(state);
        const selectedRegular = (uiData || []).reduce((acc, obj) => {
            if (toSend.some(x => x == obj.staffId) && obj.isRegular)
                return acc + 1;
            else
                return acc;
        }, 0);
        const selectedPremium = (uiData || []).reduce((acc, obj) => {
            if (toSend.some(x => x == obj.staffId) && !obj.isRegular)
                return acc + 1;
            else
                return acc;
        }, 0);
        return Selectors.getRoot(state).amountOfAvailableRegularCredits - (selectedRegular + selectedPremium);
    }

    public static getAvailableCredits = (state: AppState) => {
        const uiData = Selectors.getUiData(state);
        const toSend = Selectors.getItemsToSend(state);
        const selectedPremium = (uiData || []).reduce((acc, obj) => {
            if (toSend.some(x => x == obj.staffId) && !obj.isRegular)
                return acc + 1;
            else
                return acc;
        }, 0);
        return Selectors.getRoot(state).amountOfAvailableCredits - selectedPremium;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
