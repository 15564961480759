import React from 'react';
import styled from 'styled-components'
import Button from 'components/Buttons/Button/Button'
import { ReactComponent as CheckCircleIcon } from 'media/images/icons/check-circle-v2.svg';
import { maxDevice } from "media/styles/mixins/breakpoints";


interface OwnProps {
    isCompleted: boolean
    isLoading: boolean
    anonymous?: boolean
    eventAttendeess: Array<string>
    onCompleteAttendanceClick: () => void
    onAddExtraAttendeeClick: () => void
    title: string;
    date: string;
    description?: string;
    altDate?: string;
    eventLocation?: string;
    className?: string;
    showAddExtraAttendeeBtn?: boolean;
    altTitle?: string;
    altClassName?: string;
    darkBackgroundMode?: boolean;
}

const BannerRegistered = ({
    isCompleted = false,
    isLoading = false,
    anonymous = false,
    eventAttendeess = [],
    onAddExtraAttendeeClick,
    onCompleteAttendanceClick,
    date,
    description,
    className,
    showAddExtraAttendeeBtn = false,
    darkBackgroundMode = false
}: OwnProps) => {

    const renderAttendeessList = () => {
        return eventAttendeess.map((item, index) =>
            <span key={index + item} className='attendees-list__item'>
                <span >{`${item}${eventAttendeess.length - 1 === index ? '' : ', '}`}</span>
            </span>
        );
    }

    return (
        <Section className={className}>
            <div className={'inner ' + (anonymous ? 'full-width' : '')}>
                <div className="title">
                    <p>
                        <span>TransferRoom Summit <b>İ</b>stanbul</span>
                    </p>
                    {<p className='title-description'>
                        {description ?? 'Exclusively for decision makers. By Invitation Only.'}
                    </p>}
                </div>
                <div className='event-label'>
                    <label>
                        <div className='label-item'>
                            <div>
                                <span>İ</span>stanbul
                            </div>
                        </div>
                        <div className='label-item'>
                            <div>
                                Nov 11 + 12
                            </div>
                        </div>
                    </label>
                    <label>
                        <div className='label-item'>
                            <div>
                                Online
                            </div>
                        </div>
                        <div className='label-item'>
                            <div>
                                Nov 14
                            </div>
                        </div>
                    </label>
                </div>
                <div className="confirmed-label">
                    <div className="confirmed-label__icon"><CheckCircleIcon /></div>
                    <div className="confirmed-label__text"><span>Registration Confirmed</span></div>
                </div>
                {!isLoading && (
                    <>
                        {isCompleted ? (
                            <>
                                <div className='attendees'>
                                    <div className='attendees-list'>
                                        <span className='attendees-list__title'>Attendees:{'  '}</span>
                                        {renderAttendeessList()}
                                    </div>
                                </div>

                                {showAddExtraAttendeeBtn && eventAttendeess.length < 4 && (
                                    <Button
                                        type={darkBackgroundMode ? "primary" : "primaryinverse"}
                                        effect="no"
                                        onClick={onAddExtraAttendeeClick}
                                    >Add Extra Attendee</Button>
                                )}
                            </>
                        ) : (
                            <>
                                <div></div>
                                <Button type="primaryinverse" effect="no" onClick={() => onCompleteAttendanceClick()} >
                                    <div>Complete Your Attendance Details Now</div>
                                    <div style={{ fontWeight: 500, color: '#e55843', fontSize: 11, marginTop: 5, textTransform: 'none' }} >
                                        Registration is confirmed but still subject to approval. We will get in touch.
                                    </div>
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        </Section>
    )
}

const Section = styled.section`
    height: auto;
    text-align: center;
    color: #fff;
    .inner {
        padding: 51px 20px 60px;
        background-image: none;
        background-color: #0E854C;
        background-position: center 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media ${maxDevice.mobileS} {
            padding: 100px 30px 63px;
        }

        &.full-width{
            position: absolute;
            left: 50%;
            width: 100vw;
            margin-left: -50vw;
        }

        .title {
            p {
                &>span {
                    color: #FFF;
                    text-align: center;
                    font-size: 45px;
                    font-weight: 700;
                    line-height: normal;

                    @media ${maxDevice.tabletM} {
                        font-size: 35px;
                    }

                    @media ${maxDevice.mobileM} {
                        text-shadow: 0px 4px 16px rgba(19, 26, 54, 0.50);
                        font-size: 28px;
                    }

                    &>b {
                        font-family: Inter;
                        font-weight: 600;
                    }
                }
            }
                       
            &-description {
                color:#ffffff;
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 25px;
                
                @media ${maxDevice.tabletM} {
                    font-size: 16px;
                }

                @media ${maxDevice.mobileS} {
                    display: none;
                }
            }
        }

        .event-label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-bottom: 30px;
            
            @media ${maxDevice.mobileS} {
                flex-direction: column;
                align-items: center;
                margin-bottom: 23px;
            }

            &>label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0;
                border-radius: 69.067px;
                border: 1.233px solid #FFF;
                background: rgba(255, 255, 255, 0.10);
                
                @media ${maxDevice.mobileM} {
                    height: 30px;
                    border-radius: 56px;
                }

                .label-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 37px;

                    @media ${maxDevice.mobileM} {
                        height: 30px;
                    }
                    
                    & div {
                        color: #FFF;
                        font-size: 18.5px;
                        font-weight: 700;
                        line-height: normal;
                        padding: 0 12px;

                        @media ${maxDevice.mobileM} {
                            font-size: 15px;
                        }
                        & span {
                            font-family: Inter;
                            font-weight: 600;
                        }
                    }

                    &:first-child {
                        border-right: 1.233px solid #FFF;
    					border-radius: 69.067px 0px 0px 69.067px;
                        background: rgba(255, 255, 255, 0.25);
                    }
                }
                    
                @media ${maxDevice.mobileM} {
                    font-size: 17px;
                    max-width: fit-content;
                }
            }
        }
        
        button {
            max-width: 100%;
            padding: 14px 20px;
            text-overflow: ellipsis;
            white-space: normal;
            min-height: 50px;
            color: #131A36;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.14px;
            border-radius: 5px;
            border: 1px solid #FFF;

            &:hover {
                opacity: 0.9;
            }

            @media ${maxDevice.mobileS} {
                width: calc(100% - 20px);
                min-height: 48px;
            }
        }

        .confirmed-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 12px;
            border: 0px solid #FFF;
            background: #FFF;
            color: #0E9655;
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            padding: 5px 11px 5px 8px;
            gap: 6px;
            margin-bottom: 12px;
            &__icon {
                height: 20px;
                width: 20px;
            }

            &__text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }

        .attendees {
            margin-bottom: 18px;
            
            &-list {
                color: #FFF;
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
`

export default BannerRegistered