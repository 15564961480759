import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import validator from 'services/validator';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import historyAccessor from 'history-accessor';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { InviteAgencyReponseErrorReason, InviteAgencyResponse } from 'api/agency/agent/landing/invite-agents.model';
import { PageType } from 'constants/enums';

export class AgentsModel {
    id: number;
    fullName: string;
    email: string;
    masterUser: boolean;
}

export class AgentsErrorModel {
    nameError: string;
    emailError: string;
    email: string;
}

class State {
    isLoading: boolean;
    customAgenst: Array<AgentsModel>;
    errorAgents: Array<AgentsErrorModel>;
    inviteResponse: InviteAgencyResponse;
}

const defaultState: State = {
    isLoading: false,
    customAgenst: [{ id: new Date().getTime() * -1, email: "", fullName: "", masterUser: false }],
    errorAgents: [],
    inviteResponse: null
}

const stateController = new StateController<State>(
    "AGENCY/INVITE-AGENTS",
    defaultState
)

class Actions {
    // public static loadPlayers() {
    //     return async (dispatch, getState: () => AppState) => {
    //         let state = getState();
    //         let subState = state.agency.agent.inviteAgents;

    //         dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: true })));
    //         //let res = await VerifyService.getPlayers();
    //         dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: false })));
    //     }
    // }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static save() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.agent.inviteAgents;
            let errors: Array<AgentsErrorModel> = [];
            substate.customAgenst.map(x => {
                let nameError = !validator.isNotEmpty(x.fullName);
                let emailError = !validator.isValidEmail(x.email);
                if (nameError || emailError) {
                    errors.push({ email: x.email, emailError: emailError ? "Invalid email" : null, nameError: nameError ? "Empty name" : null })
                }
            });
            if (errors.length != 0) {
                dispatch(stateController.setState({ errorAgents: errors }));
            }
            else {
                dispatch(stateController.setState({ errorAgents: errors, isLoading: true }));
                var response = await AgencyHomePageService.addAgents({
                    agents: substate.customAgenst.map(x => { return { email: x.email, isMaster: x.masterUser, name: x.fullName } })
                });

                dispatch(stateController.setState({ isLoading: false, inviteResponse: response }));
                if (!response.isSucess) {

                    let startId = errors.length + 1;
                    const alreadyInSystem = "User with email is already in agency."
                    const alreadyInvited = "User with email is already invited."
                    for (var serverError of response.errors) {

                        errors.push({
                            nameError: null,
                            emailError: serverError.reason == InviteAgencyReponseErrorReason.AlreadyExist
                                ? alreadyInSystem : alreadyInvited,
                            email: serverError.email
                        })
                        startId++;
                    }
                    dispatch(stateController.setState({ errorAgents: [...errors] }))
                }

                if (response.isSucess) {

                    let masters = new Array<{ name: string, email: string }>();
                    let agents = new Array<{ name: string, email: string }>();

                    for (var item of substate.customAgenst) {
                        if (item.masterUser) {
                            masters.push({ name: item.fullName, email: item.email });
                        }
                        else {
                            agents.push({ name: item.fullName, email: item.email });
                        }
                    }

                    for (let master of masters) {
                        dispatch(userActivityInsert({
                            PageName: `${Actions.getOnboardingPrefix(getState())} [Agents Invitation]`,
                            Message: `Added master agent: ${master.name}, ${master.email}`,
                            PageType: Actions.getPageType(getState())
                        }));
                    }

                    for (let agent of agents) {
                        dispatch(userActivityInsert({
                            PageName: `${Actions.getOnboardingPrefix(getState())} [Agents Invitation]`,
                            Message: `Added agent: ${agent.name}, ${agent.email}`,
                            PageType: Actions.getPageType(getState())
                        }));
                    }

                    historyAccessor.push('/');
                }
            }
        }
    }

    public static dismiss() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ customAgenst: [{ id: new Date().getTime() * -1, email: "", fullName: "", masterUser: false }], errorAgents: [] }));
        }
    }

    public static setName(id: number, name: string) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.agent.inviteAgents;
            let agentsCopy = [...substate.customAgenst];
            let item = agentsCopy.find(x => x.id == id);
            item.fullName = name;

            let list = agentsCopy.map(x => { return { id: x.id, name: x.fullName } });
            dispatch(stateController.setState({ customAgenst: agentsCopy }));
        }
    }

    public static setEmail(id: number, email: string) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.agent.inviteAgents;
            let agentsCopy = [...substate.customAgenst];
            let item = agentsCopy.find(x => x.id == id);
            item.email = email;

            dispatch(stateController.setState({ customAgenst: agentsCopy }));
        }
    }

    public static setMaster(id: number, isMaster: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.agent.inviteAgents;
            let agentsCopy = [...substate.customAgenst];
            let item = agentsCopy.find(x => x.id == id);
            item.masterUser = isMaster;

            dispatch(stateController.setState({ customAgenst: agentsCopy }));
        }
    }

    public static remove(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.agent.inviteAgents;
            let agentsCopy = substate.customAgenst.filter(x => x.id != id);
            let removedAgent = substate.customAgenst.find(x => x.id == id);

            dispatch(stateController.setState({ customAgenst: agentsCopy }));

            if (removedAgent) {
                dispatch(userActivityInsert({
                    Message: `Removed Agent: ${removedAgent.fullName}, ${removedAgent.email}`,
                    PageName: `${Actions.getOnboardingPrefix(getState())} [Agents Invitation]`,
                    PageType: Actions.getPageType(getState())
                }));
            }
        }
    }

    public static add() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.agent.inviteAgents;
            let agentsCopy: Array<AgentsModel> = [...substate.customAgenst, { id: new Date().getTime() * -1, email: "", fullName: "", masterUser: false }]

            dispatch(stateController.setState({ customAgenst: agentsCopy }));
        }
    }

    public static closed() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Closed`,
                PageName: `${Actions.getOnboardingPrefix(getState())} [Agents Invitation]`,
                PageType: Actions.getPageType(getState())
            }));
            historyAccessor.push('/')
        }
    }

    public static getOnboardingPrefix(state: AppState): string {
        return 'Agency Home';
    }

    private static getPageType(state: AppState): PageType {
        return PageType.AgencyHome;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};