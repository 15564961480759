import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import ClubsService from 'api/admin/clubs/clubs.service';
import {
  ClubModel,
  CompetitionModelShort,
  Country,
  FilterOptions,
  SupervisorSelectOption,
  UserModel
} from "api/admin/clubs/models";
import AdminPermissionsService from 'api/admin/admin-permissions/admin-permissions.service';
import permissionsConstants from 'constants/permissions';
import { RcFile } from 'antd/lib/upload';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { generateLink } from './utils';

export class ProcessingState {
  salesSupervisorProcessingIds: number[];
  usersFriendlyAccessProcessingIds: number[];
  impersonateProcessingIds: number[];
}

export interface AddImageModalState {
  isOpen: boolean;
  modalTitle: string;
  currentImage: string | null;
  squadId: number | null;
}

export interface EditSquadAreaModalState {
  isOpen: boolean;
  isFetching: boolean;
  modalTitle: string;
  filterByAreaName: string;
  currentAreaName: string;
  currentAreaId: number | null;
  newAreaName: string;
  newAreaId: number | null;
  squadId: number | null;
}

export interface EditSquadLeagueModalState {
  isOpen: boolean;
  isFetching: boolean;
  modalTitle: string;
  squadId: number | null;
  currentCompetitionId: number | null;
  newCompetitionName: string;
  newCompetitionId: number | null;
  filterByCompetitionName: string;
  filterByCountryName: string;
  filterByFormat: string;
  filterByDivisionLevel: string;
}

export interface FriendlyAccessModalState {
  isOpen: boolean;
  modalTitle: string;
  squadId: number | null;
  users: UserModel[];
}

export interface ImpersonateModalState {
  isOpen: boolean;
  isFetching: boolean;
  modalTitle: string;
  squadId: number | null;
  userId: number | null;
}

class ClubsState {
  isLoading: boolean;
  isAllowedChangeSquadLeague: boolean;
  isSortOrderAscending: boolean;
  sortOrder: string;
  currentPage: number;
  totalRecords: number;
  recordsPerPage: number;
  squads: ClubModel[];
  areas: Country[];
  squadUsers: UserModel[];
  competitions: CompetitionModelShort[];
  supervisors: SupervisorSelectOption[];
  filterOptions: FilterOptions;
  previousFilterSnapshot: FilterOptions;
  processing: ProcessingState;
  addImageModalState: AddImageModalState;
  editSquadAreaModalState: EditSquadAreaModalState;
  editSquadLeagueModalState: EditSquadLeagueModalState;
  friendlyAccessModalState: FriendlyAccessModalState;
  impersonateModalState: ImpersonateModalState;
}

const defaultFilterOptions: FilterOptions = {
  isWithLogo: null,
  isWithUsers: null,
  squadName: '',
  countryName: '',
  cityName: '',
  competitionName: '',
  supervisor: null,
}

const defaultState: ClubsState = {
  isLoading: false,
  isAllowedChangeSquadLeague: false,
  isSortOrderAscending: true,
  sortOrder: 'id',
  currentPage: 1,
  totalRecords: 0,
  recordsPerPage: 20,
  squads: [],
  supervisors: [],
  areas: [],
  squadUsers: [],
  competitions: [],
  filterOptions: defaultFilterOptions,
  previousFilterSnapshot: defaultFilterOptions,
  processing: {
    salesSupervisorProcessingIds: [],
    usersFriendlyAccessProcessingIds: [],
    impersonateProcessingIds: [],
  },
  addImageModalState: {
    isOpen: false,
    modalTitle: '',
    currentImage: null,
    squadId: null,
  },
  editSquadAreaModalState: {
    isOpen: false,
    isFetching: false,
    modalTitle: '',
    filterByAreaName: '',
    currentAreaName: '',
    currentAreaId: null,
    newAreaName: '',
    newAreaId: null,
    squadId: null
  },
  editSquadLeagueModalState: {
    isOpen: false,
    isFetching: false,
    modalTitle: '',
    currentCompetitionId: null,
    newCompetitionName: '',
    newCompetitionId: null,
    squadId: null,
    filterByCompetitionName: '',
    filterByCountryName: '',
    filterByFormat: '',
    filterByDivisionLevel: '',
  },
  friendlyAccessModalState: {
    isOpen: false,
    modalTitle: '',
    squadId: null,
    users: []
  },
  impersonateModalState: {
    isOpen: false,
    isFetching: false,
    modalTitle: '',
    squadId: null,
    userId: null,
  }
}

const stateController = new StateController<ClubsState>(
  'ADMIN_V2/CLUBS',
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData = () => {
    return async (dispatch) => {
      try {
        await Promise.allSettled([
          dispatch(Actions.getSupervisors()),
          dispatch(Actions.hasUserPermissionByCode()),
          dispatch(Actions.getSquads()),
          dispatch(Actions.loadAreas()),
          dispatch(Actions.getCompetitions())
        ]);
      } catch (err) {
        console.error(err);
      }
    };
  };

  public static hasUserPermissionByCode = () => {
    return async (dispatch, getState: () => AppState) => {
      try {
        const data = await AdminPermissionsService.hasUserPermissionByCode(permissionsConstants.squadLeague);
        dispatch(stateController.setState({ isAllowedChangeSquadLeague: data }));
      } catch (err) {
        console.error(err);
      }
    }
  }

  public static loadAreas() {
    return async dispatch => {
      const areas = await ClubsService.loadAreas();
      dispatch(stateController.setState({ areas: areas }));
    };
  };

  public static getSquads() {
    const wasFiltersChanged = (filterOptions: FilterOptions, previousFilterSnapshot: FilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const userId = getState().auth.userId;
        const {
          isSortOrderAscending,
          recordsPerPage,
          sortOrder,
          currentPage,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          previousFilterSnapshot: {
            ...prevState.filterOptions,
          },
          currentPage: shouldDisposePagination ? 1 : currentPage
        })));

        const searchParameters = {
          userId,
          sortOrder,
          recordsPerPage,
          isSortOrderAscending,
          page: shouldDisposePagination ? 1 : currentPage,
          isWithLogo: filterOptions.isWithLogo !== null ? filterOptions.isWithLogo : undefined,
          isWithUsers: filterOptions.isWithUsers !== null ? filterOptions.isWithUsers : undefined,
          squadName: filterOptions.squadName ? filterOptions.squadName : undefined,
          countryName: filterOptions.countryName ? filterOptions.countryName : undefined,
          cityName: filterOptions.cityName ? filterOptions.cityName : undefined,
          competitionName: filterOptions.competitionName ? filterOptions.competitionName : undefined,
          supervisorId: filterOptions.supervisor && filterOptions.supervisor.value > -1 ? filterOptions.supervisor.value : undefined,
        }

        const data = await ClubsService.getSquads(searchParameters);
        const squads = data.output.map((squad, index) => ({
          ...squad,
          key: squad.id
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squads,
          totalRecords: data.rowCount,
        })));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static getSupervisors() {
    return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
      try {
        const supervisors = await ClubsService.getSupervisors()
        const supervisorsExtended = supervisors.output.map(item => ({
          value: item.id,
          label: item.name,
          key: item.id
        }));
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          supervisors: supervisorsExtended
        })));
      } catch (e) {
        console.error(e)
      }
    })
  }

  public static getSquadUsers(squadId: number) {
    return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          impersonateModalState: {
            ...prevState.impersonateModalState,
            isFetching: true
          }
        })));

        const users = await ClubsService.getSquadUsersToImpersonate(squadId)

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squadUsers: users
        })));
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          impersonateModalState: {
            ...prevState.impersonateModalState,
            isFetching: false
          }
        })));
      }
    })
  }

  public static getCompetitions() {
    return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
      try {
        const competitions = await ClubsService.getAllCompetitions();
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          competitions
        })));
      } catch (e) {
        console.error(e)
      }
    })
  }

  public static assignSupervisor(squadId: number, supervisorUserId: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const substate = Selectors.selectState(getState());
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            salesSupervisorProcessingIds: [...prevState.processing.salesSupervisorProcessingIds, squadId]
          }
        })))

        const result = await ClubsService.assignSupervisor(squadId, supervisorUserId)
        if (result) {
          const updatedItems = [...substate.squads];
          updatedItems.find(item => item.id === squadId).salesSupervisorUserId = supervisorUserId;
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            squads: updatedItems
          })))
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            salesSupervisorProcessingIds: prevState.processing.salesSupervisorProcessingIds.filter(item => item !== squadId)
          }
        })))
      }
    }
  }

  public static onPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        currentPage: pageNumber,
        recordsPerPage: pageSize
      })))

      dispatch(Actions.getSquads());
    }
  }

  public static applySearch() {
    return dispatch => dispatch(Actions.getSquads())
  }

  public static disposeFilters() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
      dispatch(Actions.applySearch());
    }
  }

  public static onChangeHasLogo(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          isWithLogo: value
        }
      })));
    };
  }

  public static onChangeHasUsers(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          isWithUsers: value
        }
      })));
    };
  }

  public static onChangeSquadName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          squadName: value
        }
      })));
    }
  }

  public static onChangeCountryName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          countryName: value
        }
      })));
    }
  }

  public static onChangeCityName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          cityName: value
        }
      })));
    }
  }

  public static onChangeCompetitionName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          competitionName: value
        }
      })));
    }
  }

  public static onChangeSupervisor(value: SupervisorSelectOption) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          supervisor: value
        }
      })));
    }
  }

  public static openAddImageModal(squad: ClubModel, ) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        addImageModalState: {
          ...prevState.addImageModalState,
          isOpen: true,
          modalTitle: squad.name,
          currentImage: squad.logo ? squad.logo : null,
          squadId: squad.id,
        },
      })));
    }
  }

  public static closeAddImageModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        addImageModalState: {
          ...defaultState.addImageModalState
        },
      })));
    }
  }

  public static uploadImage(squadId: number, file: Blob | RcFile | string) {
    return async (dispatch, getState: () => AppState) => {
      const { logo } = await ClubsService.uploadSquadLogo(squadId, file);
      const newPhotoName = logo + `?${new Date().getTime()}`;
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squads: prevState.squads.map(item => {
          if (item.id === squadId) {
            return {
              ...item,
              logo: newPhotoName
            }
          } else {
            return item
          }
        }),
        addImageModalState: {
          ...prevState.addImageModalState,
          currentImage: newPhotoName
        }
      })));
      dispatch(notificationCreate({
        message: "Image successfully changed",
        level: 'success'
      }))
    }
  }

  public static deleteImage(squadId: number) {
    return async (dispatch) => {
      await ClubsService.deleteSquadLogo(squadId)
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squads: prevState.squads.map(item => {
          if (item.id === squadId) {
            return {
              ...item,
              logo: null
            }
          } else {
            return item
          }
        }),
        addImageModalState: {
          ...prevState.addImageModalState,
          currentImage: null,
        }
      })));
    }
  }

  public static onAssignCountryHandler(squadId: number, areaId: number, areaName: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const substate = Selectors.selectState(getState());
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          editSquadAreaModalState: {
            ...prevState.editSquadAreaModalState,
            isFetching: true
          }
        })));
        const result = await ClubsService.updateArea(squadId, areaId, areaName);

        if (result) {
          const updatedItems = [...substate.squads];
          updatedItems.find(item => item.id === squadId).areaId = areaId;
          updatedItems.find(item => item.id === squadId).areaName = areaName;
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            squads: updatedItems
          })))
        }

        dispatch(Actions.closeEditSquadAreaModal());
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          editSquadAreaModalState: {
            ...prevState.editSquadAreaModalState,
            isFetching: false
          }
        })));
      }
    }
  }

  public static openEditSquadAreaModal(squad: ClubModel) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadAreaModalState: {
          ...prevState.editSquadAreaModalState,
          isOpen: true,
          modalTitle: squad.name,
          squadId: squad.id,
          currentAreaName: squad.areaName,
          currentAreaId: squad.areaId
        },
      })));
    }
  }

  public static closeEditSquadAreaModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadAreaModalState: {
          ...defaultState.editSquadAreaModalState
        },
      })));
    }
  }

  public static onChangeArea(areaId: number) {
    return (dispatch, getState: () => AppState) => {
      const substate = Selectors.selectState(getState());
      const newAreaName = substate.areas.find(area => area.id === areaId).name;

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadAreaModalState: {
          ...prevState.editSquadAreaModalState,
          newAreaId: areaId,
          newAreaName: newAreaName
        },
      })));
    }
  }

  public static onFilterByAreaNameChange(keyword: string) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadAreaModalState: {
          ...prevState.editSquadAreaModalState,
          filterByAreaName: keyword,
        }
      })))
    }
  }

  public static onAssignLeagueHandler(squadId: number, competitionId: number, competitionName: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const substate = Selectors.selectState(getState());
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          editSquadLeagueModalState: {
            ...prevState.editSquadLeagueModalState,
            isFetching: true
          }
        })));
        const result = await ClubsService.updateCompetition(squadId, competitionId, competitionName);

        if (result) {
          const updatedItems = [...substate.squads];
          updatedItems.find(item => item.id === squadId).competitionId = competitionId;
          updatedItems.find(item => item.id === squadId).league = competitionName;
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            squads: updatedItems
          })))
        }

        dispatch(Actions.closeEditSquadLeagueModal());
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          editSquadLeagueModalState: {
            ...prevState.editSquadLeagueModalState,
            isFetching: false
          }
        })));
      }
    }
  }

  public static openEditSquadLeagueModal(squad: ClubModel) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...prevState.editSquadLeagueModalState,
          isOpen: true,
          modalTitle: squad.name,
          squadId: squad.id,
          currentCompetitionId: squad.competitionId
        },
      })));
    }
  }

  public static closeEditSquadLeagueModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...defaultState.editSquadLeagueModalState
        },
      })));
    }
  }

  public static onChangeCompetition(competitionId: number) {
    return (dispatch, getState: () => AppState) => {
      const substate = Selectors.selectState(getState());
      const newCompetitionName = substate.competitions.find(competition => competition.id === competitionId).name;

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...prevState.editSquadLeagueModalState,
          newCompetitionId: competitionId,
          newCompetitionName: newCompetitionName
        },
      })));
    }
  }

  public static onFilterByCompetitionNameChange(keyword: string) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...prevState.editSquadLeagueModalState,
          filterByCompetitionName: keyword,
        }
      })))
    }
  }

  public static onFilterByCountryNameChange(keyword: string) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...prevState.editSquadLeagueModalState,
          filterByCountryName: keyword,
        }
      })))
    }
  }

  public static onFilterByFormatChange(keyword: string) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...prevState.editSquadLeagueModalState,
          filterByFormat: keyword,
        }
      })))
    }
  }

  public static onFilterByDivisionLevelChange(keyword: string) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        editSquadLeagueModalState: {
          ...prevState.editSquadLeagueModalState,
          filterByDivisionLevel: keyword,
        }
      })))
    }
  }

  public static openFriendlyAccessModal(squad: ClubModel) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        friendlyAccessModalState: {
          ...prevState.friendlyAccessModalState,
          isOpen: true,
          modalTitle: squad.name,
          squadId: squad.id,
          users: squad.users
        },
      })));
    }
  }

  public static closeFriendlyAccessModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        friendlyAccessModalState: {
          ...defaultState.friendlyAccessModalState
        },
      })));
    }
  }

  public static toggleFriendlyOnly(squadId: number, userId: number, isFriendlyOnly: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const substate = Selectors.selectState(getState());
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            usersFriendlyAccessProcessingIds: [...prevState.processing.usersFriendlyAccessProcessingIds, userId]
          }
        })));

        const result = await ClubsService.setFriendlyOnly(userId, isFriendlyOnly)
        if (result) {
          const updatedItems = [...substate.squads];
          updatedItems
            .find(item => item.id === squadId).users
            .find(user => user.id === userId).isFriendlyMatchesOnly = isFriendlyOnly;
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            squads: updatedItems
          })))
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            usersFriendlyAccessProcessingIds: prevState.processing.usersFriendlyAccessProcessingIds.filter(item => item !== userId)
          }
        })))
      }
    }
  }

  public static openImpersonateModal(squad: ClubModel) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        impersonateModalState: {
          ...prevState.impersonateModalState,
          isOpen: true,
          modalTitle: squad.name,
          squadId: squad.id
        },
      })));
    }
  }

  public static closeImpersonateModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadUsers: [],
        impersonateModalState: {
          ...defaultState.impersonateModalState
        },
      })));
    }
  }

  public static onChangeImpersonate(userId: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        impersonateModalState: {
          ...prevState.impersonateModalState,
          userId: userId,
        },
      })));
    }
  }

  public static onGenerateLinkHandler(squadId: number, userId: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            impersonateProcessingIds: [...prevState.processing.impersonateProcessingIds, userId]
          }
        })));

        const token = await ClubsService.getSession(squadId, userId);

        if (token) {
          const url = generateLink(token);
          if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(url);
          } else {
            document.execCommand('copy', true, url);
          }
          alert('The link is copied to the clipboard. Please open it in the new private browser tab.');
        }

        dispatch(Actions.closeImpersonateModal());
      } catch (err){
        console.error(err);
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            impersonateProcessingIds: prevState.processing.impersonateProcessingIds.filter(item => item !== userId)
          }
        })));
      }
    }
  }

}

class Selectors {
  public static selectState = (state: AppState) => state.admin.clubs;
  public static isLoading = (state: AppState): boolean => Selectors.selectState(state).isLoading;
  public static isAllowedChangeSquadLeague = (state: AppState): boolean => Selectors.selectState(state).isAllowedChangeSquadLeague;
  public static getProcessing = (state: AppState) => Selectors.selectState(state).processing;
  public static getFilterOptions = (state: AppState) => Selectors.selectState(state).filterOptions;
  public static getSquads = (state: AppState) => Selectors.selectState(state).squads;
  public static getTotalRecords = (state: AppState) => Selectors.selectState(state).totalRecords;
  public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
  public static getCurrentPage = (state: AppState) => Selectors.selectState(state).currentPage;
  public static getSupervisors = (state: AppState) => Selectors.selectState(state).supervisors;

  public static getAddImageModalState = (state: AppState) => Selectors.selectState(state).addImageModalState;
  public static isOpenAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).isOpen;
  public static getTitleAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).modalTitle;
  public static getCurrentImageAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).currentImage;
  public static getSquadIdAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).squadId;

  public static getEditSquadAreaModalState = (state: AppState) => Selectors.selectState(state).editSquadAreaModalState;
  public static isOpenEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).isOpen;
  public static isFetchingEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).isFetching;
  public static getTitleEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).modalTitle;
  public static getSquadIdEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).squadId;
  public static getCurrentAreaEditSquadAreaModal= (state: AppState) => Selectors.getEditSquadAreaModalState(state).currentAreaName;
  public static getCurrentAreaIdEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).currentAreaId;
  public static getNewAreaEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).newAreaName;
  public static getNewAreaIdEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).newAreaId;
  public static getFilterByAreaNameEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadAreaModalState(state).filterByAreaName;
  public static getFilteredAreas = (state: AppState) => {
    const areas = Selectors.selectState(state).areas;
    const currentAreaId = Selectors.getCurrentAreaIdEditSquadAreaModal(state);
    const filterByAreaName = Selectors.getFilterByAreaNameEditSquadAreaModal(state);
    const currentArea = areas.find(area => area.id === currentAreaId);
    const filteredAreas = areas.filter(area => area.id !== currentAreaId);
    const formattedAreas = currentArea ? [currentArea, ...filteredAreas] : [...filteredAreas];

    return formattedAreas
      .filter(item => !filterByAreaName.length || (item.name && item.name.toLowerCase() || '')
        .includes(filterByAreaName.toLowerCase()));
  };

  public static getEditSquadLeagueModalState = (state: AppState) => Selectors.selectState(state).editSquadLeagueModalState;
  public static isOpenEditSquadLeagueModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).isOpen;
  public static isFetchingEditSquadLeagueModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).isFetching;
  public static getSquadIdEditSquadLeagueModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).squadId;
  public static getTitleEditSquadLeagueModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).modalTitle;
  public static getCurrentCompetitionIdEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).currentCompetitionId;
  public static getNewCompetitionNameEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).newCompetitionName;
  public static getNewCompetitionIdEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).newCompetitionId;
  public static getCompetitions = (state: AppState) => Selectors.selectState(state).competitions;
  public static getFilterByCompetitionNameEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).filterByCompetitionName;
  public static getFilterByCountryNameEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).filterByCountryName;
  public static getFilterByFormatEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).filterByFormat;
  public static getFilterByDivisionLevelEditSquadAreaModal = (state: AppState) => Selectors.getEditSquadLeagueModalState(state).filterByDivisionLevel;

  public static getFilteredCompetitions = (state: AppState) => {
    const competitions = Selectors.selectState(state).competitions;
    const currentCompetitionId = Selectors.getCurrentCompetitionIdEditSquadAreaModal(state);
    const filterByCompetitionName = Selectors.getFilterByCompetitionNameEditSquadAreaModal(state);
    const filterByCountryName = Selectors.getFilterByCountryNameEditSquadAreaModal(state);
    const filterByFormat = Selectors.getFilterByFormatEditSquadAreaModal(state);
    const filterByDivisionLevel = Selectors.getFilterByDivisionLevelEditSquadAreaModal(state);
    const currentCompetition = competitions.find(competition => competition.id === currentCompetitionId);
    const filteredCompetitions = competitions.filter(competition => competition.id !== currentCompetitionId);
    const formattedCompetitions = currentCompetition ? [currentCompetition, ...filteredCompetitions] : [...filteredCompetitions];

    return formattedCompetitions
      .filter(item => !filterByCompetitionName.length || (item.name && item.name.toLowerCase() || '')
        .includes(filterByCompetitionName.toLowerCase()))
      .filter(item => !filterByCountryName.length || (item.areaName && item.areaName.toLowerCase() || '')
        .includes(filterByCountryName.toLowerCase()))
      .filter(item => !filterByFormat.length || (item.format && item.format.toLowerCase() || '')
        .includes(filterByFormat.toLowerCase()))
      .filter(item => !filterByDivisionLevel.length || (item.divisionLevel && item.divisionLevel.toString() || '')
        .includes(filterByDivisionLevel.toLowerCase()));
  };

  public static getFriendlyAccessModalState = (state: AppState) => Selectors.selectState(state).friendlyAccessModalState;
  public static isOpenFriendlyAccessModal = (state: AppState) => Selectors.getFriendlyAccessModalState(state).isOpen;
  public static getSquadIdFriendlyAccessModal = (state: AppState) => Selectors.getFriendlyAccessModalState(state).squadId;
  public static getTitleFriendlyAccessModal = (state: AppState) => Selectors.getFriendlyAccessModalState(state).modalTitle;
  public static getUsersFriendlyAccessModal = (state: AppState) => Selectors.getFriendlyAccessModalState(state).users;

  public static getImpersonateModalState = (state: AppState) => Selectors.selectState(state).impersonateModalState;
  public static isOpenImpersonateModal = (state: AppState) => Selectors.getImpersonateModalState(state).isOpen;
  public static isFetchingImpersonateModal = (state: AppState) => Selectors.getImpersonateModalState(state).isFetching;
  public static getSquadIdImpersonateModal = (state: AppState) => Selectors.getImpersonateModalState(state).squadId;
  public static getUserIdImpersonateModal = (state: AppState) => Selectors.getImpersonateModalState(state).userId;
  public static getTitleImpersonateModal = (state: AppState) => Selectors.getImpersonateModalState(state).modalTitle;
  public static getSquadUsers= (state: AppState) => Selectors.selectState(state).squadUsers;

  public static isFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.filterOptions) === JSON.stringify(Selectors.getFilterOptions(state))
  }

  public static getSupervisorsList = (state: AppState) => {
    const supervisors = Selectors.selectState(state).supervisors;
    return [
      {
        value: 0,
        label: 'No supervisor',
        key: 0
      },
      ...supervisors
    ];
  };

  public static getSupervisorsFilters = (state: AppState) => {
    const supervisors = Selectors.selectState(state).supervisors;
    return [
      {
        value: -1,
        label: 'All',
        key: -1
      },
      {
        value: 0,
        label: 'No supervisor',
        key: 0
      },
      ...supervisors
    ];
  };
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  ClubsState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
