export default class FeatureCoordinator {

    static ShowCreateMultipleAdsModal = "modal:multiplePlayerAds";
    static eventsNewFeature = "feature:event3";


    static setIsDisplayed = (featureKey) => {
        localStorage.setItem(featureKey, true);
    }

    static getIsAlreadyDisplayed = (featureKey) => {
        return false; //need to always show counter; #9954
        //return localStorage.getItem(featureKey) !== null;
    }
}