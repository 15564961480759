import { combineReducers } from 'redux';
import {
    AVAILABLE_FRIENDLY_MATCHES_FETCH,
    FRIENDLY_MATCH_DECLARE_INTEREST,
    FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE,
    FRIENDLY_MATCHES_INVITATIONS_FETCH,
    FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS,
    MY_FRIENDLY_MATCHES_FILTER_CHANGE,
    MY_FRIENDLY_MATCHES_DISPOSE
} from 'store/actionTypes';
import { arrayToObject } from 'services/utils';

export default combineReducers({ 
    byId, 
    allIds, 
    invitations, 
    criteria, 
    filterOptions, 
    declareInterestProcessingIds 
});

function byId(adsById = {}, action) {
    switch (action.type) {
        case AVAILABLE_FRIENDLY_MATCHES_FETCH.SUCCESS: {
            const ads = action.payload.data.items;
            return arrayToObject(ads);
        }

        case FRIENDLY_MATCH_DECLARE_INTEREST.SUCCESS: {
            const id = action.payload.request.payload;
            return {
                ...adsById,
                [id]: {
                    ...adsById[id],
                    friendlyMatchResponse: 1,
                },
            };
        }

        case FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE.SUCCESS: {
            const id = action.payload.request.payload;
            return {
                ...adsById,
                [id]: {
                    ...adsById[id],
                    friendlyMatchResponse: 4,
                },
            };
        }

        case FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS.SUCCESS: {
            const id = action.payload.request.payload;
            return {
                ...adsById,
                [id]: {
                    ...adsById[id],
                    friendlyMatchResponse: 2,
                },
            };
        }

        case MY_FRIENDLY_MATCHES_DISPOSE: {
            return {};
        }


        default:
            return adsById;
    }
}

function allIds(ids = [], action) {
    switch (action.type) {
        case AVAILABLE_FRIENDLY_MATCHES_FETCH.SUCCESS: {
            const ads = action.payload.data.items;
            return ads.map(ad => ad.id);
        }

        case MY_FRIENDLY_MATCHES_DISPOSE: {
            return [];
        }

        default:
            return ids;
    }
}

function filterOptions(
    criteriaState = {
        squadsTypes: [],
        start: null,
        end: null,
        venueTypes: [],
        countries: []
    },
    action
) {
    switch (action.type) {

        case MY_FRIENDLY_MATCHES_FILTER_CHANGE: {
            return { ...criteriaState, ...action.payload };
        }

        case MY_FRIENDLY_MATCHES_DISPOSE: {
            return {
                squadsTypes: [],
                start: null,
                end: null,
                venueTypes: [],
                countries: []
            };
        }

        default:
            return criteriaState;
    }
}

function criteria(
    criteriaState = {
        availableSquadsTypes: [],
        availableCountries: []
    },
    action
) {
    switch (action.type) {

        case AVAILABLE_FRIENDLY_MATCHES_FETCH.SUCCESS: {
            if (!action.payload.request.refresh)
                return action.payload.data.criteria;
            return criteriaState;
        }

        case MY_FRIENDLY_MATCHES_DISPOSE: {
            return {
                availableSquadsTypes: [],
                availableCountries: []
            };
        }


        default:
            return criteriaState;
    }
}

function invitations(ids = [], action) {
    switch (action.type) {
        case FRIENDLY_MATCHES_INVITATIONS_FETCH.SUCCESS: {
            return action.payload.data;
        }

        case FRIENDLY_MATCH_DECLARE_INTEREST.SUCCESS: {
            
            const id = action.payload.request.payload;
            const ad = action.payload.request.ad
            let objectives = [...ids]
            const match = objectives.filter(a => a.id === id)[0];
            if (match) {
                objectives.filter(a => a.id === id)[0].friendlyMatchResponse = 1;
            } else {
                ad.friendlyMatchResponse = 1
                objectives = [...objectives, ad]
            }
            return objectives;
        }

        case FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE.SUCCESS: {
            const id = action.payload.request.payload;
            const ad = action.payload.request.ad
            let objectives = [...ids]
            const match = objectives.filter(a => a.id === id)[0];
            if (match) {
                objectives.filter(a => a.id === id)[0].friendlyMatchResponse = 4;
            } else {
                ad.friendlyMatchResponse = 4
                objectives = [...objectives, ad]
            }
            return objectives;
        }

        case FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS.SUCCESS: {
            const id = action.payload.request.payload;
            const match = ids.filter(a => a.id === id)[0];
            if (match != null) {
                ids.filter(a => a.id === id)[0].friendlyMatchResponse = 2;
            }
            return [...ids];
        }

        case MY_FRIENDLY_MATCHES_DISPOSE: {
            return [];
        }

        default:
            return ids;
    }
}


function declareInterestProcessingIds(ids = [], action) {
    switch(action.type) {
        case FRIENDLY_MATCH_DECLARE_INTEREST.REQUEST: {
            return [ ...ids, action.payload.ad.id]
        }
        case FRIENDLY_MATCH_DECLARE_INTEREST.SUCCESS: {
            return [ ...ids.filter(item => item !== action.payload.request.ad.id)]
        }
        case FRIENDLY_MATCH_DECLARE_INTEREST.ERROR: {
            return [ ...ids.filter(item => item !== action.payload.ad.id)]
        }
        default:
            return ids;
    }
}