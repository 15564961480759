import axios from 'axios-config';
import { ReconfirmAgentRequest } from "../onboarding-v2/models";
import {
    GeneralPlayerInfoModel,
    InvitationReactionResponse,
    PlayerMessage,
    UserPlayerResponse,
    PlayerCreditsRequest,
    CreatePlayerRequest
} from './shared-models';

export default class AgencyPlayerOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async isInvitationReacted(): Promise<boolean> {
        let result = null;
        try {
            result = await axios.get<boolean>(`api/v2/AgentPlayerInvitation/IsInvitationReactedByPlayer`, this.axiosConfig)
        } catch (error) {
            let a = error.response.status == 400;
        }
        return result ? result.data : null;
    }

    public static async getInvitationReaction(): Promise<InvitationReactionResponse> {
        let result = null;
        try {
            result = await axios.get<InvitationReactionResponse>(`api/v2/AgentPlayerInvitation/GetInvitationReaction`, this.axiosConfig)
        } catch (error) {
            let a = error.response.status == 400;
        }
        return result ? result.data : null;
    }


    public static async getUserData(): Promise<UserPlayerResponse> {
        let result = null;
        try {
            result = await axios.get<UserPlayerResponse>(`api/v2/AgencyPlayer/GetPlayerPageData`, this.axiosConfig)
        } catch (error) {
            let a = error.response.status == 400;
        }
        return result ? result.data : null;
    }

    public static async confirmAnonymous(guid: string): Promise<any> {
        const { data } = await axios.post<any>(
            `api/v2/AgentPlayerInvitation/ConfirmByPlayer/${guid}`,
            {},
            this.axiosConfig
        );
        return data;
    }

    public static async confirm(): Promise<any> {
        const { data } = await axios.post<any>(
            `api/v2/AgentPlayerInvitation/ConfirmInvitationByPlayer`,
            {},
            this.axiosConfig
        );
        return data;
    }

    public static async reject(): Promise<any> {
        const { data } = await axios.post<any>(
            `api/v2/AgentPlayerInvitation/RejectInvitationByPlayer`,
            {},
            this.axiosConfig
        );
        return data;
    }
    public static async remove(): Promise<any> {
        const { data } = await axios.post<any>(
            `api/v2/AgentPlayerInvitation/RemoveInvitationByPlayer`,
            {},
            this.axiosConfig
        );
        return data;
    }
    public static async changePassword(userId: number, oldPass: string, newPass: string): Promise<boolean> {
        const { data } = await axios.post<any>(
            `api/Users/ChangePassword`,
            {
                Id: userId,
                OldPassword: oldPass,
                NewPassword: newPass
            },
            this.axiosConfig
        );
        return data;
    }

    public static async changePhone(areaId: number, phone: string): Promise<boolean> {
        const { data } = await axios.post<any>(
            `api/Users/UpdateUserProfilePhone`,
            {
                PhoneAreaId: areaId,
                Phone: phone
            },
            this.axiosConfig
        );
        return data;


    }

    public static async getAllByKeyword(keyword): Promise<any> {
        const { data } = await axios.get(
            `api/v2/ExternalAgencyPlayers/GeyAllByKeywordExtended/${keyword}`,
            this.axiosConfig
        )
        return data
    }

    public static async getPlayerHomePageData(): Promise<GeneralPlayerInfoModel> {
        const { data } = await axios.get(
            `api/v2/PayerPreference`,
            this.axiosConfig
        )
        return data
    }

    public static async getMessages(): Promise<Array<PlayerMessage>> {
        const { data } = await axios.get(`api/v2/PayerPreference/Messages`, this.axiosConfig)
        return data
    }

    public static async setMessageAsRead(transferId: number): Promise<any> {
        await axios.post(`api/v2/PayerPreference/SetMessageAsRead`, transferId, this.axiosConfig)
    }

    public static async setMessageAsRejected(transferId: number): Promise<any> {
        await axios.post(`api/v2/PayerPreference/SetMessageAsRejected`, transferId, this.axiosConfig)
    }

    public static async confirmAgentAuthenticated(request: ReconfirmAgentRequest): Promise<{ isSuccess: boolean, message?: string }> {
        const { data } = await axios.post(`api/v2/PayerPreference/ConfirmAgent`, request, this.axiosConfig);
        return data
    }

    public static async requestPlayerCreditsEmail(request: PlayerCreditsRequest): Promise<any> {
        await axios.post(`api/v2/AgentPlayerInvitation/RequestPlayerCreditsEmail`, request, this.axiosConfig)
    }

    public static async getinTouchFromRemovalEmail(): Promise<any> {
        await axios.post(`api/v2/AgentPlayerInvitation/GetinTouchFromRemovalEmail`, {}, this.axiosConfig)
    }

    public static async ltaGetInTouch(): Promise<any> {
        await axios.post(`api/v2/AgentPlayerInvitation/LtaGetinTouch`, {}, this.axiosConfig)
    }

    public static async createTicketAddPlayer(request: CreatePlayerRequest): Promise<any> {
        await axios.post(`api/v2/AgencyPlayer/CreateTicketAddPlayer`, request, this.axiosConfig)
    }
}