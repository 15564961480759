import moment from 'moment';
import {
  PlayerHistoryActionType,
  PlayerSharedActivityHistory
} from 'api/agency/agent/agency-transparency/models';
import { isToday } from 'utils/date';

export const getImageSrc = (item: PlayerSharedActivityHistory) => {
  if (item.squad && item.squad.logo) {
    return item.squad.logo;
  }
  if (item.competition && item.competition.logo) {
    return item.competition.logo;
  }
  return null;
}

const getIndefiniteArticle = (noun: string, isCapitalized?: boolean) => {
  noun = noun.toLowerCase();

  if (!noun) return noun;

  var first = noun[0];

  if (first == 'a' || first == 'e' || first == 'i' || first == 'o' || first == 'u' || first == 'y')
    return isCapitalized ? "An" : "an";

  return isCapitalized ? "A" : "a";
}

export const getActivityListText = (item: PlayerSharedActivityHistory) => {
  const competitionName = item.competition?.name ?? '';
  const squadName = item.squad?.name;

  switch (item.actionType) {
    case(PlayerHistoryActionType.ViewedProfile): {
      return `<strong>${getIndefiniteArticle(competitionName, true)} ${competitionName} team</strong> viewed your profile`
    }
    case(PlayerHistoryActionType.StartedFollowing): {
      return `<strong>${getIndefiniteArticle(competitionName, true)} ${competitionName} team</strong> started following you`
    }
    case(PlayerHistoryActionType.AgentPitched): {
      return `Your agent pitched you to <strong>${squadName}</strong>`
    }
    case(PlayerHistoryActionType.ClubSavedPitch): {
      return `<strong>${squadName}</strong> saved a pitch from your agent for review`
    }
    case(PlayerHistoryActionType.ClubDeclaredInterest): {
      return `<strong>${squadName ? squadName : `${getIndefiniteArticle(competitionName, true)} ${competitionName} team` }</strong> declared interest in you`
    }
    case(PlayerHistoryActionType.ClubRejectedPitch): {
      return `<strong>${squadName}</strong> rejected a pitch from your agent`
    }

    default:
      return null;
  }
}

export const getFormattedDate = (date: Date) => {
  if (isToday(date)) {
    return 'Today ' + moment.utc(date).local().format('@ HH:mm');
  }
  return moment.utc(date).local().format('Do MMM YY @ HH:mm');
}
