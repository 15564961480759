import axios from 'axios-config';
import {
    PlayerRegistrationRequest,
    PlayerOnboardingAccessModel,
    ReconfirmAgentRequest,
    PlayerOnboardingAccessModelV2,
    PlayerInvitationLinkOnboardingAccessModel,
    PlayerSignupResponse,
    SignupFromInvitationLinkRequest,
    InvitationStatusResponse
} from './models';

export class AgencyPlayerOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async signupAndLogin(request: PlayerRegistrationRequest): Promise<{ isSuccess: boolean, message?: string, auth: any }> {
        const { data } = await axios.post(
            `api/v2/PlayerOnboarding/SignupAndLogin`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async signup(request: PlayerRegistrationRequest): Promise<{ isSuccess: boolean, message?: string, auth: any, userId:string }> {
        
        const { data } = await axios.post(
            `api/v2/PlayerOnboarding/Signup`,
            request,
            this.axiosConfig
        );

        return data

    }
    public static async getAccessForSignUpV2(token: string): Promise<PlayerOnboardingAccessModelV2> {
        
        const { data } = await axios.get(
            `api/v2/PlayerOnboarding/GetOnboardingAccessForSignUpV2/${token}`,
            this.axiosConfig
        );

        return data

    }
    public static async getAccessForSignUp(token: string): Promise<PlayerOnboardingAccessModel> {
        
        const { data } = await axios.get(
            `api/v2/PlayerOnboarding/GetOnboardingAccessForSignUp/${token}`,
            this.axiosConfig
        );

        return data

    }
    public static async getAccessForReconfirm(token: string): Promise<PlayerOnboardingAccessModel> {
        
        const { data } = await axios.get(
            `api/v2/PlayerOnboarding/GetOnboardingAccessForReconfirm/${token}`,
            this.axiosConfig
        );

        return data

    }
    public static async getAccessForReconfirmV2(token: string): Promise<PlayerOnboardingAccessModelV2> {

        const { data } = await axios.get(
            `api/v2/PlayerOnboarding/GetOnboardingAccessForReconfirmV2/${token}`,
            this.axiosConfig
        );

        return data

    }

    public static async submitAgent(request: ReconfirmAgentRequest): Promise<{ isSuccess: boolean, message?: string }> {
        
        const { data } = await axios.post(
            `api/v2/PlayerOnboarding/ReconfirmAgent`,
            request,
            this.axiosConfig
        );

        return data

    }

    public static async getAccessForInvitationLinkSignUp(token: string) {
        const { data } = await axios.get<PlayerInvitationLinkOnboardingAccessModel>(`api/v2/PlayerOnboarding/GetOnboardingAccessForInvitationLinkSignUp/${token}`, this.axiosConfig)
        return data;
    }

    public static async invitationLinkSignup(request: SignupFromInvitationLinkRequest) {
        const { data } = await axios.post<PlayerSignupResponse>(
            `api/v2/PlayerOnboarding/InvitationLinkSignup`,
            request,
            this.axiosConfig
        );
        return data;
    }
    public static async GetPlayerInvitationStatus():Promise <InvitationStatusResponse>  {
        const { data } = await axios.get<InvitationStatusResponse>(
            `api/v2/PlayerPreference/GetPlayerInvitationStatus`,
            this.axiosConfig
        );
        return data;
    }
}