import { AppState } from 'root.reducer';
import { MessageType } from 'api/messaging/models/message'
import { StateController } from 'utils/action-declaration';
import { SearchItemForClub, SearchItemType } from 'api/search/model/search-result';
import { messageSend } from 'app/send-message/actions/send-message.actions';
import { ReceiverRole } from 'api/search/model/declare-interest-definition';
import { MessagingService } from 'api/messaging/messaging.service'
import {
    IMessageSendSpecificaion,
    MessageSendSession,
    Actions as MessageModalController
} from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller'
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import * as PublishedPitchController from './published-pitch.controller';
import { PitchSquadInterestType } from 'api/agency/custom-pitch/models';
// import { Selectors as GridSelectors } from './grid.controller'
// import { PlayerActivityService, AgencyActivityService, ClubActivityService } from './player-activity.service'
class SendMessageModalState {
    newMessageAvailability?: {
        availableCount?: number;
        lockedTill?: any;
    };
    restrictedAvailableCountModal: boolean;
    restrictedMessageModal: boolean;
}

const defaultState: SendMessageModalState = {
    newMessageAvailability: null,
    restrictedAvailableCountModal: false,
    restrictedMessageModal: false,
}

const stateController = new StateController<SendMessageModalState>(
    'CUSTOM_PITCH/SEND_MESSAGE',
    defaultState
)

class Actions {

    private static getSession = (agencyId: number, agencyName: string, agencyCountry: string, subject: string) => {
        return {
            toClub: false,
            toAgency: true,
            subject: subject,
            mesageTypeId: MessageType.CustomPitchMessage,
            agencyId: agencyId,
            agencyCountry: agencyCountry,
            agencyName: agencyName,
            isTrustedAgent: true,
            recipientId: agencyId,
            recipientType: ReceiverType.Agency
        }
    }

    public static openModal(agencyId: number, agencyName: string, agencyCountry: string, subject: string) {
        return (dispatch, getState: () => AppState) => {
            let messageSession: MessageSendSession = Actions.getSession(agencyId, agencyName, agencyCountry, subject);

            const { player, squadId, pitchSquadId } = getState().agency.customPitch.published.publishedPitchData;

            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    dispatch(userActivityInsert({
                        PageName: 'Custom Pitch [Message]',
                        Message: 'Closed',
                        PlayerId: player.id,
                        ClubId: squadId,
                        PageType: PageType.Pitch
                    }))
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {

                        await dispatch(messageSend(session.recipientId, message, null, session.agencyName, session.mesageTypeId, session.subject, true, player.id));
                        dispatch(Actions.checkNewMessageLimit())

                        dispatch(PublishedPitchController.Actions.logCustomPitchAction(pitchSquadId, PitchSquadInterestType.UserInterest))
                        dispatch(userActivityInsert({
                            PageName: 'Custom Pitch [Message]',
                            Message: 'Sent a Message',
                            PlayerId: player.id,
                            ClubId: squadId,
                            PageType: PageType.Pitch
                        }))
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            // dispatch(AgencyActivityService.openNewMessage(messageSession.recipientId))

            dispatch(MessageModalController.openSession(messageSession, specification))

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Published]',
                Message: `Opened New Message`,
                PlayerId: player.id,
                ClubId: squadId,
                PageType: PageType.Pitch
            }))
        }
    }

    public static togleRestrictedAvailableModal() {
        return async (dispatch, getState: () => AppState) => {
            const restrictedAvailableCountModal = Selectors.getRestrictedAvailableCountModal(getState())
            dispatch(stateController.setState({ restrictedAvailableCountModal: !restrictedAvailableCountModal }))
        }
    };

    public static togleRestrictedMessageModal() {
        return async (dispatch, getState: () => AppState) => {
            const restrictedMessageModal = Selectors.getRestrictedMessageModal(getState())
            dispatch(stateController.setState({ restrictedMessageModal: !restrictedMessageModal }))
        }
    };

    public static checkNewMessageLimit() {
        return async (dispatch, getState: () => AppState) => {
            const { userId } = getState().auth;
            try {
                const newMessageAvailability = await MessagingService.checkNewMessageLimit(userId)
                dispatch(stateController.setState({ newMessageAvailability: newMessageAvailability }))
            } catch (e) {
                console.error(e)
            }
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.newPlayerSearch.message;
    public static getRestrictedAvailableCountModal = (state: AppState) => Selectors.getRoot(state).restrictedAvailableCountModal
    public static getRestrictedMessageModal = (state: AppState) => Selectors.getRoot(state).restrictedMessageModal

    public static getNewMessageCheckAvailability = (state: AppState) => Selectors.getRoot(state).newMessageAvailability
    public static isNewMessageLimitRichedOutOrHide = (state: AppState) =>
        (Selectors.getNewMessageCheckAvailability(state) || { availableCount: 5 }).availableCount <= 0
    public static isPermited = (state: AppState) => !state.auth.clubPermission || state.auth.clubPermission.canSendMessages

    public static lockMessage = (state: AppState) => !Selectors.isPermited(state) || Selectors.isNewMessageLimitRichedOutOrHide(state)
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    SendMessageModalState as State,
    Actions as Actions,
    stateController as Controller
};