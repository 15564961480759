export class CountryWithAgenciesModel {
    name: string;
    agencies: Array<AgencyModel>;
}

export class AgencyModel {
    id: number;
    name: string;
    agentsNumber: number;
    price: number;
}

export class CountryWithAgenciesResponse {
    [name: string]: Array<AgencyResponse>
}

export class AgencyResponse {
    id: number;
    name: string;
    amountOfPlayers: number | null;
    totalMarketValue: number | null;
}


export interface PlayerAdModel {
    id: number;
    updatedAt: Date | string;
    amount: FormattedPriceModel;
    sellOn: number | null;
    grossSalary: FormattedPriceModel;
    positionCode: string;
    positionName: string;
    type: PlayerAdTypeEnumModel;
    currency: CurrencyModel;
    minHeight: number | null;
    fromAge: number | null;
    toAge: number | null;
    updateAtFormatted: string;
    isOutdated: boolean;
}

export interface CurrencyModel {
    id: number;
    name: string;
    sign: string;
}

export enum PlayerAdTypeEnumModel {
    Buy = 1,
    Loan = 2,
}

export interface FormattedPriceModel {
    price: number;
    formattedComma: string; // "3,000,00"
    formattedKMB: string; // "3m"
}


export interface AgencyAdRequest {
    invitationGuid: string;
    playerAds: CreatePlayerAdRequest[];
}

export interface CreatePlayerAdRequest {
    positionId: number;
    currencyId: number;
    minHeight?: number | null;
    maxHeight?: number | null;
    toAge?: number | null;
    fromAge?: number | null;
    isLeftFooted: boolean;
    type: PlayerAdTypeEnumModel;
    amount: number;
    sellOn?: number | null;
    sessionId?: string | null;
    grossSalary?: number | null;
}

export interface AgencyAdPermissionsRequest {
    invitationGuid: string;
    adsIds: number[];
}

export interface AgencyInvitationRequest {
    permissions: SquadAgencyPermissionTypeModelEnum[];
    specificAgenciesIds: number[];
}

export enum SquadAgencyPermissionTypeModelEnum {
    AgentsRepMyPlayers = 1,
    AgentsRepShortlist = 2,
    AgentsRegInMyCountry = 3,
    AgentsRecommended = 4,
    AgentsTopFives = 5,
    SpecificAgencies = 6
}