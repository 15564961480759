import { AppState } from "root.reducer";
import { PageTypeEnum, ActionTypeEnum, UserActivityItem, UserActivityCriteria } from 'api/admin/user-activity/models'
import { StateController } from "utils/action-declaration";
import { AdminUserActivityService } from 'api/admin/user-activity/user-activity.service'
import { FilterOptions } from 'api/admin/user-activity/models'
import { createSelector } from "reselect";

class UserActivityState {
    items: UserActivityItem[];
    isLoading: boolean;
    previousFilterSnapshot: FilterOptions;
    filterOptions: FilterOptions;
    pagination: {
        rowCount: number,
        pageCount: number
    }
    criteria: UserActivityCriteria;
}

const defaultFilterOptions: FilterOptions = {
    userId: null,
    userName: '',
    userJobTitle: '',
    userClubName: '',
    userCountryName: '',

    date: '',
    message: '',
    pageUrl: '',

    currentLocation: '',
    currentLocationCountry: '',
    device: '',
    appVersionFormatted: '',
    deviceVersionFormatted: '',
    eventAgencyName: '',
    eventClubName: '',
    eventPlayerName: '',
    eventStaffName: '',
    source: '',
    ip: '',

    isSortOrderAscending: false,
    sortOrder: 'date',

    showAdminImpersonate: null,
    showMultiAccountUsers: null,
    isAgentsOnly: false,
    isClubsOnly: false,
    isPlayersOnly: false,
    isStaffOnly: false,

    page: 1,
    recordsPerPage: 20,
    pageType: null,
    actionType: null,
}

const defaultState: UserActivityState = {
    items: [],
    isLoading: false,
    previousFilterSnapshot: defaultFilterOptions,
    filterOptions: defaultFilterOptions,
    pagination: {
        rowCount: 1,
        pageCount: 1
    },
    criteria: null
}

const stateController = new StateController<UserActivityState>(
    'ADMIN_V2/USER_ACTIVITY',
    defaultState
)

class Actions {
    public static loadUserActivityData() {

        function wasFilterChanged(state: AppState) {
            const { page, recordsPerPage } = defaultState.filterOptions;
            const { filterOptions, previousFilterSnapshot } = state.admin.userActivity;

            // we should ignore pagination changes
            const filterOptionsWithoutPagination: FilterOptions = {
                ...filterOptions,
                page: page,
                recordsPerPage: recordsPerPage,
            }
            const previousFilterSnapshotWithoutPagination: FilterOptions = {
                ...previousFilterSnapshot,
                page: page,
                recordsPerPage: recordsPerPage,
            }
            const wasFiltersChanged = JSON.stringify(filterOptionsWithoutPagination) !== JSON.stringify(previousFilterSnapshotWithoutPagination)
            return wasFiltersChanged
        }

        return async (dispatch, getState: () => AppState) => {
            try {
                const shouldDisposePagination = wasFilterChanged(getState())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    previousFilterSnapshot: {
                        ...prevState.filterOptions,
                    },
                    filterOptions: {
                        ...prevState.filterOptions,
                        page: shouldDisposePagination ? 1 : prevState.filterOptions.page
                    },
                })))

                const substate = getState().admin.userActivity
                const filterOptions: FilterOptions = {
                    ...substate.filterOptions,
                    userId: getState().auth.userId
                }
                dispatch(stateController.setState({ isLoading: true }))
                const data = await AdminUserActivityService.getUserActivityData(filterOptions)
                const dataWithKey = data.output.map((item, index) => ({
                    ...item,
                    key: index
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: dataWithKey,
                    pagination: {
                        pageCount: data.pageCount,
                        rowCount: data.rowCount
                    }
                })))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static paginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    page: pageNumber,
                    recordsPerPage: pageSize
                }
            })))

            dispatch(Actions.loadUserActivityData())
        }
    }

    public static applySearch() {
        return dispatch => { 
            dispatch(stateController.setState(prevState => ({ 
            ...prevState, 
                filterOptions: 
                { 
                    ...prevState.filterOptions, 
                    page: 1 
                } 
            })))
            dispatch(Actions.loadUserActivityData())
        }
    }
    public static disposeFilters() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
            dispatch(Actions.applySearch())
        }
    }

    public static onSort(key: string, order: 'ascend' | 'descend' | undefined) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    sortOrder: key,
                    isSortOrderAscending: order === 'ascend' ? true : false
                }
            })))
            dispatch(Actions.loadUserActivityData())
        }
    }

    public static onChangeCountry(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                userCountryName: value
            }
        })))
    }
    public static onChangeUserName(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                userName: value
            }
        })))
    }
    public static onChangeUserClubName(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                userClubName: value
            }
        })))
    }
    public static onChangeUserJobTitle(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                userJobTitle: value
            }
        })))
    }
    public static onChangeEventMessage(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                message: value
            }
        })))
    }
    public static onChangeImpersonate(value: boolean | null) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                showAdminImpersonate: value
            }
        })))
    }
    public static onChangeMultiAccount(value: boolean | null) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                showMultiAccountUsers: value
            }
        })))
    }
    public static onChangeSource(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    source: value
                }
            })))
        }
    }
    public static onChangeAppVersion(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                appVersionFormatted: value
            }
        })))
    }
    public static onChangeDeviceVersion(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                deviceVersionFormatted: value,
            }
        })))
    }
    public static onChangePageName(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                pageUrl: value
            }
        })))
    }
    public static onChangePageType(value: PageTypeEnum | null) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                pageType: value
            }
        })))
    }
    public static onChangeActionType(value: ActionTypeEnum | null) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                actionType: value
            }
        })))
    }
    public static onChangeDate(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                date: value
            }
        })))
    }
    public static onChangeEventPlayer(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                eventPlayerName: value
            }
        })))
    }

    public static onChangeEventStaff(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                eventStaffName: value
            }
        })))
    }
    

    public static onChangeEventClub(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                eventClubName: value
            }
        })))
    }
    public static onChangeEventAgency(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                eventAgencyName: value
            }
        })))
    }
    public static onChangeCurrentCountry(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                currentLocationCountry: value
            }
        })))
    }
    public static onChangeCurrentCity(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                currentLocation: value
            }
        })))
    }
    public static onChangeIp(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                ip: value
            }
        })))
    }
    public static onChangeUserSide(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isClubsOnly: value === 'all' ? false : value === 'clubsOnly' ? true : false,
                isAgentsOnly: value === 'all' ? false : value === 'agentsOnly' ? true : false,
                isPlayersOnly: value === 'all' ? false : value === 'playersOnly' ? true : false,
                isStaffOnly: value === 'all' ? false : value === 'staffOnly' ? true : false,
            }
        })))
    }

    public static loadUserActivityCriteria() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AdminUserActivityService.getUserActivityCriteria()

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    criteria: data,
                })))
            } catch (e) {
                console.error(e)
            }
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.userActivity
    public static selectItems = createSelector(
        Selectors.selectState,
        state => state.items
    )
    public static selectFilterOptions = createSelector(
        Selectors.selectState,
        state => state.filterOptions
    )
    public static selectPagination = createSelector(
        Selectors.selectState,
        state => state.pagination
    )
    public static selectIsLoading = createSelector(
        Selectors.selectState,
        state => state.isLoading
    )
    public static selectCurrentPage = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => filterOptions.page
    )
    public static selectRecordsPerPage = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => filterOptions.recordsPerPage
    )

    public static selectIsAllFiltersSetToDefault = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => JSON.stringify(defaultState.filterOptions) === JSON.stringify(filterOptions)
    )

    public static selectCriteria = createSelector(
        Selectors.selectState,
        state => state.criteria
    )
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UserActivityState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};