import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';

const initialState = {
    topCountries: [
        {
            placeName: '',
            total: '',
            dealsAmount: undefined,
            averageAmount: ''
        }
    ],
    topLeagues: [
        {
            placeName: '',
            total: '',
            dealsAmount: undefined,
            averageAmount: ''
        }
    ],
    freeTransferPerecent: undefined,
    onLoanPerecent: undefined,
    transfersPerecent: undefined,
    internalTransfersPercent: undefined,
};

export default (incomingPlayers = initialState, action) => {
    switch (action.type) {
        case INCOMING_PLAYERS_FETCH.SUCCESS: {
            return {
                ...incomingPlayers,  ...action.payload.data
            };
        }
        case INCOMING_PLAYERS_FETCH.REQUEST:
            return { ...incomingPlayers };

        default:
            return incomingPlayers;
    }
};


export const INCOMING_PLAYERS_FETCH = asyncActionType('app/financial-insights/INCOMING_PLAYERS_FETCH');

export const getIncomingPlayersLoadingStatus = state =>
    getRequests(state).some(r => r.type === INCOMING_PLAYERS_FETCH);


