export const photos_3 = [
    { src: '/img/events/img-3.png', },
    { src: '/img/events/img-2.png', },
    { src: '/img/events/img-1.png', },
];

export const photos_4 = [
    { src: '/img/events/img-4-1.jpg', },
    { src: '/img/events/img-4-3.jpg', },
    { src: '/img/events/img-4-2.jpg', },
];

export const vs_photo = {src: '/img/events/zoom-screen.jpg'}

export const photos_5 = [
    { src: '/img/events/img-5-1.jpg', },
    { src: '/img/events/img-5-2.jpg', },
    { src: '/img/events/img-5-3.jpg', },
    { src: '/img/events/img-5-4.jpg', },
]

export const attendees_4 = [
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png', },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '20454', name: 'Legia Warszawa', logo: 'logo_1594_Legia_Warszawa.png', },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '11544', name: 'Grasshopper', logo: 'logo_1936_Grasshopper_Club_Zurich.png', },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '21425', name: 'St. Gallen', logo: 'logo_1935_FC_St._Gallen_1879.png', },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '1177', name: 'OFI', logo: 'logo_2747_OFI_Crete.png' },
    { id: '16210', name: 'AEL', logo: 'logo_2179_AEL_Limassol.png' },
    { id: '51', name: 'Burnley', logo: 'logo_1136_Burnley_FC.png' },
    { id: '42094', name: 'Bochum', logo: 'logo_1284_VfL_Bochum.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '32443', name: 'Badalona', logo: 'logo_1802_CF_Badalona.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '39333', name: 'Sporting Gijón', logo: 'logo_1730_Sporting_Gijon.png', },
    { id: '6863', name: 'Rayo Vallecano', logo: 'logo_1734_Rayo_Vallecano.png', },
    { id: '4474', name: 'Burgos', logo: 'logo_1781_Burgos_CF.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '12686', name: 'Nordsjælland', logo: 'logo_1413_FC_Nordsjaelland.png', },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '7427', name: 'Osijek', logo: 'logo_2166_NK_Osijek.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '17668', name: 'Famalicão', logo: 'logo_FC_Famalicao.png' },
    { id: '38234', name: 'Sabadell', logo: 'logo_1800_CE_Sabadell.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png', },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '19138', name: 'Mallorca', logo: 'logo_RCD_Mallorca.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },
    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '39309', name: 'Valletta', logo: 'logo_3540_Valletta_FC.png' },
    { id: '15280', name: 'Córdoba', logo: 'logo_1745_Cordoba_CF.png' },
    { id: '37893', name: 'Skënderbeu Korçë', logo: 'logo_2451_KF_Skenderbeu.png', },
    { id: '16042', name: 'Kukësi', logo: 'logo_2454_FK_Kukesi.png' },
    { id: '174', name: 'Talleres Córdoba', logo: 'logo_2588_Club_Atletico_Talleres.png', },
    { id: '11640', name: 'Cornellà', logo: 'logo_1799_UE_Cornella.png' },
    { id: '11717', name: 'MOL Vidi FC', logo: 'logo_1629_Videoton_FC.png' },
    { id: '33927', name: 'Almería', logo: 'logo_UD_Almeria.png' },
    { id: '38623', name: 'Pogoń Szczecin', logo: 'logo_1599_Pogon_Szczecin.png', },
    { id: '427', name: 'SV Roeselare', logo: 'logo_1588_KSV_Roeselare.png' },
    { id: '9794', name: 'Zagłębie Sosnowiec', logo: 'logo_1612_Zaglebie_Sosnowiec.png', },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png', },
    { id: '71', name: 'AFC Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png', },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '2', name: 'Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png', },
];

export const attendees_3 = [
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png', },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '67', name: 'West Ham United', logo: 'logo_1127_West_Ham_United.png', },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '2', name: 'Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '24698', name: 'Cambridge United', logo: 'logo_1201_Cambridge_United.png', },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '26191', name: 'Bury', logo: 'logo_1168_Bury_FC.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '20454', name: 'Legia Warszawa', logo: 'logo_1594_Legia_Warszawa.png', },
    { id: '32843', name: 'Shanghai SIPG', logo: 'logo_1640_Shanghai_SIPG.png' },
    { id: '11544', name: 'Grasshopper', logo: 'logo_1936_Grasshopper_Club_Zurich.png', },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '21425', name: 'St. Gallen', logo: 'logo_1935_FC_St._Gallen_1879.png', },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '12686', name: 'Nordsjælland', logo: 'logo_1413_FC_Nordsjaelland.png', },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '7427', name: 'Osijek', logo: 'logo_2166_NK_Osijek.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png', },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },
    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '11717', name: 'MOL Vidi FC', logo: 'logo_1629_Videoton_FC.png' },
    { id: '32987', name: 'Bristol Rovers', logo: 'logo_1184_Bristol_Rovers.png', },
    { id: '71', name: 'AFC Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png', },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png', },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '40409', name: 'Oxford United', logo: 'logo_1166_Oxford_United.png' },
    { id: '15872', name: 'Wigan Athletic', logo: 'logo_1163_Wigan_Athletic.png', },
    { id: '3740', name: 'Kortrijk', logo: 'logo_KV_Kortrijk.png' },
    { id: '14821', name: 'København', logo: 'logo_FC_Copenhagen.png' },
    { id: '7354', name: 'Forest Green Rovers', logo: 'logo_Forest_Green_Rovers.png', },
    { id: '29469', name: 'Dinamo Zagreb', logo: 'logo_2163_GNK_Dinamo_Zagreb.png', },
    { id: '38623', name: 'Pogoń Szczecin', logo: 'logo_1599_Pogon_Szczecin.png', },
    { id: '5306', name: 'Raków Częstochowa', logo: 'logo_1622_Rakow_Czestochowa.png', },
    { id: '31136', name: 'Heracles', logo: 'logo_1542_Heracles_Almelo.png' },
    { id: '29529', name: 'Cheltenham Town', logo: 'logo_1197_Cheltenham_Town.png', },
    { id: '30586', name: 'Sparta Praha', logo: 'logo_2050_AC_Sparta_Praha.png', },
    { id: '12758', name: 'Tubize', logo: 'logo_1593_AFC_Tubize.png' },
    { id: '6249', name: 'Bayer Leverkusen', logo: 'logo_1260_Bayer_04_Leverkusen.png', },
    { id: '12139', name: 'Honvéd', logo: 'logo_1632_Budapest_Honved_FC.png' },
    { id: '29529', name: 'Cheltenham Town', logo: 'logo_1197_Cheltenham_Town.png', },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '14958', name: 'Coventry City', logo: 'logo_1188_Coventry_City.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '5626', name: 'Enosis', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
];






