import * as redux from 'redux';
import * as SearchAutosuggestController from './autosuggest.controller'
import * as FilterController from './filter.controller';
import * as Grid from './grid.controller'
import * as SendMessageController from './send-message.controller';
import * as CompetitionListController from './competition-list.controller';

export class SquadSearchState {
    search: SearchAutosuggestController.State;
    grid: Grid.State;
    filter: FilterController.State;
    message: SendMessageController.State;
    competitionList: CompetitionListController.State;
}

export function combineReducers() {

    const SquadSearchReducers = {
        search: SearchAutosuggestController.Reducer,
        filter: FilterController.Reducer,
        grid: Grid.Reducer,
        message: SendMessageController.Reducer,
        competitionList: CompetitionListController.Reducer
    };

    return redux.combineReducers(SquadSearchReducers);
}
