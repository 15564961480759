import { IdName } from 'api/core/id-name';
import { SquadShortInfo } from 'api/core/squad-short-info';
import { PlayerContract, PlayerXtv } from './shared'

export class SearchPlayerItemForAgency {
    id: number;
    firstPositionCode: string;
    secondPositionCode: string;
    shortName: string;
    age: number;
    contract: PlayerContract;
    xtv: PlayerXtv;
    gbe: string;
    isShortListed: boolean;
    playerVideos: Array<string>;
    nationality: string;
    currentSquad: SquadShortInfo;
    parentSquad: SquadShortInfo;
    latestSquad: SquadShortInfo;
    isFreeAgent: boolean;
    agencyPlayerDisplayInfo: AgencyPlayerDisplayInfo;
    rating: string;
    height: number;
    positionRole: string;
    greenTexts: string[];
    redText: string;
    positionRoleDescription: string;
}

export class AgencyPlayerDisplayInfo {
    displayStatus: DisplayStatus;
    pitchOpportunityCount?: number;
    representedBy: IdName;
    status: PlayerStatus;
    isPlayerVerifiedByCurrentAgency: boolean;
    contactPlayerMessageChannel: string;
}

export enum DisplayStatus {
    ButtonShowOpportunities = 1,
    ButtonViewPlayerProfile = 2,
    ButtonGetPlayerVerified = 3,
    ButtonClaimRepresentation = 4,
    LabelRepresentedBy = 5,
    LabelVerificationPending = 6,
    ButtonContactPlayer = 7,
    ButtonContactPlayerMessageSent = 8,
}

export enum PlayerStatus {
    None = 0,
    PremiumPlayer = 1,
    ClaimedNotVerified = 2,
    RegularPlayer = 3,
    LookingForAgent = 4,
}