import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer';
import { PlayerStatisticService } from 'api/player/player-statistic-service'
import {CreateplayerAd, CreatePlayerAdRequestV2} from 'api/player-ad/models/create-player-ad'
import { Player } from 'api/player/model/player'
import { getAuth, hasAgentFeature } from 'store/auth/authReducer'
import { PlayerService } from 'api/player/player-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
// import { suggestedPlayers } from './suggestions-mocked'  // For dev purposes
import * as Recommendation from './performance-data.controller'
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service';
import { doneCreatePlayerAds, cancelCreatePlayerAds } from 'pages/landing-page/redux/landing-page.actions'
import { PlayerAd } from 'api/player-ad/models/player-ad'
import { PlayerAdService } from 'api/player-ad/player-ad-service'
import moment from 'moment'
import { Actions as PerformanceActions } from './performance-data.controller'
import { PlayerAdType } from 'api/player-ad-v2/models/player-ad-with-counters';
import {getPositionCodeById, getPositionIdByCode} from "../../../utils/position-helper";
import AgencyOnboardingService from "../../../api/agency/club/onboarding/onboarding.servise";
import {setupAgencyFeature} from "store/auth/auth.actions";
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import PlayerAdV2Service from 'api/player-ad-v2/player-ad-v2.service';
import { getCancelTokenSource } from 'axios-config'
import { PlayerAdWithCountersModel } from 'api/player-ad-v2/models/player-ad-with-counters';

export const ageRule = { min: 16, max: 40 };
export const heightRule = { min: 150, max: 210 };

export class CreateAdModel {
    toLoan: boolean;
    toBuy: boolean;

    loan?: number;
    buy?: number;
    grossSalary?: number;

    age: { min: number, max: number }
    height: number;
    isAgencyRecommended?: boolean;
}

class CreatePlayerAdState {
    positions: {
        [key: string]: CreateAdModel
    }
    originalPositions: Array<string>;
    currentPositionIndex: number;
    playerSuggestions: Array<Player>;
    currentStep: number;

    adsProcessing: boolean;
    processingSuggestions: Array<number>;
    outdatedAds: Array<PlayerAd>;
    outdatedAdsLoading: boolean;
    processingOutdatedAds: Array<number>;
    outdatedAdsRemoved: Array<PositionIdPair>;
    // outdatedAdsApproved: Array<PositionIdPair>;
    outdatedAdsApprovedV2: Array<PlayerAd>

    playerAds: Array<PlayerAdWithCountersModel>;
    isLoading: boolean
}


const defaultState: CreatePlayerAdState = {
    positions: {},
    originalPositions: [],
    playerSuggestions: [],
    currentPositionIndex: 0,
    currentStep: 1,
    adsProcessing: false,
    processingSuggestions: [],
    outdatedAds: [],
    outdatedAdsLoading: false,
    processingOutdatedAds: [],
    outdatedAdsRemoved: [],
    // outdatedAdsApproved: [],
    outdatedAdsApprovedV2: [],

    playerAds: [],
    isLoading: false,
}

export class PositionIdPair {
    position: string;
    id: number;
    type: number;
}

const stateController = new StateController<CreatePlayerAdState>(
    "LANDING_PAGE/CREATE_PLAYER_AD_MODAL",
    defaultState
);

class Actions {
    public static token = null;

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            await dispatch(Actions.loadOutdatedAds());
            await dispatch(Actions.loadAllAds());

            dispatch(stateController.setState({ isLoading: false}));
        }
    }

    public static profileLinkActivity(playerId: number, squadId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Opened Player Profile`,
                PageName: 'Home [Player recommender]',
                PlayerId: playerId,
                ClubId: squadId,
                PageType: PageType.Home
            }));
        }
    }
    public static removeAd(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState().landingPage.createPlayerAd;
            let item = appState.outdatedAds.find(x => x.id == id);

            dispatch(stateController.setState({ processingOutdatedAds: [...appState.processingOutdatedAds, id] }))

            await PlayerAdService.removeOutdatedAd(getState().auth.aspNetUserId, id);

            dispatch(stateController.setState({
                outdatedAds: [...appState.outdatedAds.filter(x => x.id !== id)],
                outdatedAdsRemoved: [
                    ...appState.outdatedAdsRemoved,
                    { id: item.id, position: item.positionCode, type: item.type },
                ],
                processingOutdatedAds: appState.processingOutdatedAds.filter(x => x != id)

            }))

            let positionsText = `${item.positionCode}, ${item.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`;
            dispatch(userActivityInsert({
                Message: `Removed Ad: ${positionsText}`,
                PageName: 'Home [Create player ads]',
                PageType: PageType.Home
            }))
        }
    }

    public static stillLookingAd(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState().landingPage.createPlayerAd;
            let item = appState.outdatedAds.find(x => x.id == id);

            dispatch(stateController.setState({ processingOutdatedAds: [...appState.processingOutdatedAds, id] }))
            let duration = moment.duration(moment.utc().diff(item.lastUpdated))
            if (duration.asHours() > 24) {
                let positionsText = `${item.positionCode}, ${item.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`;;
                dispatch(userActivityInsert({
                    Message: `Updated Ad: ${positionsText}`,
                    PageName: 'Home [Create player ads]',
                    PageType: PageType.Home
                }))
                
                await PlayerAdService.updateOutdatedAd(getState().auth.aspNetUserId, id);                
            }

            dispatch(stateController.setState({
                outdatedAds: [...appState.outdatedAds.filter(x => x.id !== id)],
                // outdatedAdsApproved: [
                //     ...appState.outdatedAdsApproved,
                //     { id: item.id, position: item.positionCode, type: item.type }
                // ],
                outdatedAdsApprovedV2: [...appState.outdatedAdsApprovedV2, item],
                processingOutdatedAds: appState.processingOutdatedAds.filter(x => x != id)
            }))
        }
    }


    public static deleteIndexFromMap(positionCode: string) {
        return (dispatch, getState: () => AppState) => {
            let newState = getState().landingPage.createPlayerAd;
            let positions = { ...newState.positions };
            delete positions[positionCode];

            dispatch(stateController.setState({ currentPositionIndex: newState.currentPositionIndex - 1, positions: positions }))
        }
    }
    
    public static loadOutdatedAds() {
        return async (dispatch, getState: () => AppState) => {

            let appState = getState();
            const aspId = appState.auth.aspNetUserId;

            dispatch(stateController.setState({ outdatedAdsLoading: true }));

            let result = await PlayerAdService.getMyOutdatedPlayerAds(aspId);

            let ads = result.filter(x => x.active);
            dispatch(stateController.setState({ outdatedAdsLoading: false, outdatedAds: ads }))
        }
    }

    public static nextStep() {
        return (dispatch, getState: () => AppState) => {
            let step = getState().landingPage.createPlayerAd.currentStep;
            let state = getState();

            if (step === 1) {
                const positions = getState().landingPage.createPlayerAd.positions
                let positionsText = "";
                if (Object.keys(positions).length > 0) {
                    positionsText = Object.keys(positions).reduce((result, item) => {
                        return `${result},${item}`;
                    });
                }
                if (positionsText == ',') {
                    positionsText = ''
                }
                for (let key in positions) {
                    if (positions.hasOwnProperty(key)) {
                        let ad = positions[key];
                        ad.isAgencyRecommended = state.auth.isAgentsVisible;
                    }
                }

                dispatch(userActivityInsert({
                    Message: `Selected Positions: ${positionsText}`,
                    PageName: 'Home [Create Player Ads]',
                    PageType: PageType.Home
                }));
            }

            dispatch(stateController.setState({ currentStep: getState().landingPage.createPlayerAd.currentStep + 1 }))
        }
    }
    public static prevStep() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: getState().landingPage.createPlayerAd.currentStep - 1 }))
        }
    }
    public static changePositionsSelection(positionCode: string) {

        let defaultAdState: CreateAdModel = {
            toLoan: false,
            toBuy: false,
            age: null, //{ min: 16, max: 40 },
            height: null,
            isAgencyRecommended: false
        }

        return (dispatch, getState: () => AppState) => {
            let state = getState();
            const positions = state.landingPage.createPlayerAd.positions
            
            defaultAdState.isAgencyRecommended = hasAgentFeature(state) || state.auth.isFreemiumSubscription;
            
            if (Object.keys(positions).includes(positionCode)) {
                delete positions[positionCode]
            } else {
                positions[positionCode] = defaultAdState
            }

            dispatch(stateController.setState({
                positions: { ...positions },
                originalPositions: [...Object.keys(positions)]
            }))
        }
    }

    public static goToPrevPosition() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.createPlayerAd;
            let currentPositionIndex = substate.currentPositionIndex;
            let position = Object.keys(substate.positions)[substate.currentPositionIndex - 1];

            let outdatedAds = substate.outdatedAds;
            let stillLooking = substate.outdatedAdsApprovedV2;

            let adsToRevert = stillLooking.filter(x => x.positionCode == position);
            let adsToKeep = stillLooking.filter(x => x.positionCode != position);

            dispatch(stateController.setState({
                currentPositionIndex: currentPositionIndex - 1,
                outdatedAds: [...outdatedAds, ...adsToRevert],
                outdatedAdsApprovedV2: adsToKeep
            }))
        }
    }

    public static goToNextPosition(removePrev?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currencyId = state.auth.currency.id;
            let subState = state.landingPage.createPlayerAd;
            let currentPositionIndex = subState.currentPositionIndex;
            var currentPositionCode = Object.keys(subState.positions)[subState.currentPositionIndex];
            

            if (!hasAgentFeature(state) && subState.positions[currentPositionCode].isAgencyRecommended){
                dispatch(stateController.setState({ adsProcessing: true }))
                for (let key in subState.positions) {
                    if (subState.positions.hasOwnProperty(key)) {
                        let ad = subState.positions[key];
                        ad.isAgencyRecommended = true;
                    }
                }
                dispatch(stateController.setState({ positions: {...subState.positions} } ))
                await AgencyOnboardingService.setUpAgencyFeatures();
                dispatch(setupAgencyFeature());
                dispatch(stateController.setState({ adsProcessing: false }))
            }


            if (currentPositionIndex === Object.keys(subState.positions).length - 1) {
                let request: Array<CreatePlayerAdRequestV2> = new Array<CreatePlayerAdRequestV2>();
                for (var key of Object.keys(subState.positions)) {
                    let current = subState.positions[key];
                    if (current.toBuy) {
                        request.push({
                            toAge: current.age ? current.age.max : null,
                            fromAge: current.age ? current.age.min : null,
                            minHeight: current.height,
                            type: 1,
                            amount: current.buy,
                            positionId: getPositionIdByCode(key),
                            currencyId: currencyId,
                            grossSalary: current.grossSalary,
                            isAgencyRecommendation: current.isAgencyRecommended
                        })
                    }

                    if (current.toLoan) {
                        request.push({
                            toAge: current.age ? current.age.max : null,
                            fromAge: current.age ? current.age.min : null,
                            minHeight: current.height,
                            type: 2,
                            amount: current.loan,
                            positionId: getPositionIdByCode(key),
                            currencyId: currencyId,
                            isAgencyRecommendation: current.isAgencyRecommended
                        })
                    }
                }

                dispatch(
                    stateController.setState(
                        { adsProcessing: true }
                    )
                )


                // FOR DEV purposes
                // dispatch(
                //     stateController.setState(
                //         { playerSuggestions: require('../modals/create-player-ads/players-suggestions-mocks').suggestions, adsProcessing: false }
                //     )
                // )
                // dispatch(this.nextStep());

                try {
                    let suggestions = [];
                    if (request.length > 0) {
                        const { playerAds } = await PlayerAdService.createPlayerAds(request);
                        const playerAdIds = playerAds.map(x => x.id);
                        suggestions = await PlayerStatisticService.getRecomendedPlayersByPlayerAdIds(playerAdIds, state.auth.clubId, state.auth.squadId, state.auth.userId);
                    }

                    dispatch(Recommendation.Actions.completedCreatePlayerAdsRecommendation());


                    const positions = request;
                    let positionsText = '';

                    positions.map((item, index) => {

                        let positionValueText = '';
                        let costValue = '';

                        if (item.type === 1) {
                            positionValueText += 'To Buy';
                            costValue = `, ${item.amount};${item.grossSalary}/yr`

                        }
                        if (item.type === 2) {
                            if (positionValueText.length > 0) {
                                positionValueText += ', '
                            }
                            costValue = `, ${item.amount}/mo`
                            positionValueText += 'To Loan';
                        }

                        let finalText = `${getPositionCodeById(item.positionId)}(${positionValueText}${costValue}${item.isAgencyRecommendation ? ', Invited Agents' : ''})`;
                        positionsText += `${finalText}`;

                        const isLastPosition = Object.keys(positions).length - 1 === index
                        positionsText += isLastPosition ? '.' : ', '
                    });


                    if (request.length > 0) {
                        dispatch(userActivityInsert({
                            Message: `Launched Player Ad: ${positionsText}`,
                            PageName: 'Home [Create Player Ads]',
                            PageType: PageType.Home,
                            ActionType: ActionType.LaunchedPlayerAd,
                        }));

                        const auth = state.auth
                        const isAgentsFreeTrialActive = hasAgentFeature(state)
                        const i = request[request.length -1]

                        if(auth.isAgentsVisible) {
                            let message = '';
                            if(isAgentsFreeTrialActive && !i.isAgencyRecommendation){
                                message = 'Unchecked Agents Invitation'
                            } else if(!isAgentsFreeTrialActive && i.isAgencyRecommendation) {
                                message = 'Activated Agency Trial'
                            }
                            else if(!isAgentsFreeTrialActive && !i.isAgencyRecommendation) {
                                message = 'Unchecked Agency Trial activation'
                            }

                            if(message) {
                                dispatch(userActivityInsert({
                                    Message:  message,
                                    PageName: 'Home [Create Player Ads]',
                                    PageType: PageType.Home
                                }));
                            }
                        }
                    }

                    dispatch(
                        stateController.setState(
                            { playerSuggestions: suggestions, adsProcessing: false }
                        )
                    )

                    if (request.length == 0) {
                        dispatch(cancelCreatePlayerAds())
                    } else {
                        dispatch(this.nextStep());
                    }

                } catch (e) {
                    dispatch(stateController.setState({ adsProcessing: false }))
                    console.error(e)
                }

            }
            else {
                dispatch(
                    stateController.setState(
                        { currentPositionIndex: currentPositionIndex + 1 }
                    )
                )

                if (removePrev) {
                    dispatch(this.deleteIndexFromMap(currentPositionCode))
                }
            }
        }
    }

    public static onAdPropertiesChange(position: string, toBuy: boolean, toLoan: boolean, loan?: number, buy?: number, grossSalary?: number, isAgencyRecommended?: boolean) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().landingPage.createPlayerAd.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, toBuy: toBuy, toLoan: toLoan, loan: loan, buy: buy, grossSalary: grossSalary, isAgencyRecommended: isAgencyRecommended };

            dispatch(stateController.setState({
                positions: changedPositions
            }))

        }
    }

    public static onAgePropertyChanged(position: string, min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().landingPage.createPlayerAd.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            let upd = (min == ageRule.min && max == ageRule.max) ? null : { min: min, max: max };
            changedPositions[position] = { ...currentEntity, age: upd };

            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static onHeightPropertyChanged(position: string, val: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().landingPage.createPlayerAd.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, height: val == heightRule.min ? null : val };

            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static disposeModalState() {
        return dispatch => {
            dispatch(stateController.setState({ ...defaultState, positions: {} }))
            dispatch(PerformanceActions.loadPerformanceData());
        }
    }

    public static declareInterest(playerId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            const { userId, squadId } = getAuth(state);
            dispatch(stateController.setState({ processingSuggestions: [...state.landingPage.createPlayerAd.processingSuggestions, playerId] }))

            await PlayerService.declareInterestSave(playerId, userId, squadId)

            const suggestions = getState().landingPage.createPlayerAd.playerSuggestions

            suggestions.find(i => i.id === playerId).player.interestWasDeclared = true
            dispatch(stateController.setState({
                playerSuggestions: [...suggestions],
                processingSuggestions: [...getState().landingPage.createPlayerAd.processingSuggestions.filter(x => x != playerId)]
            }))

            let playerClubId = suggestions.find(i => i.id === playerId).player.parentSquadId
            dispatch(userActivityInsert({
                Message: 'Declared Interest',
                PageName: 'Home [Player recommender]',
                PlayerId: playerId,
                ClubId: playerClubId,
                ActionType: ActionType.DeclaredInterest,
                PageType: PageType.Home,
                UserActivityType: UserActivityType.Action
            }));

            if (getState().landingPage.createPlayerAd.playerSuggestions.length == 0) {
                dispatch(doneCreatePlayerAds());
            }
        }
    }

    public static notInterestedSuggestion(playerId: number, playerAdId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            await CustomSignInService.declinePlayer(playerId);

            const suggestions = state.landingPage.createPlayerAd.playerSuggestions;
            let suggestionsWithout = [...suggestions.filter(x => x.id != playerId)];
            dispatch(stateController.setState({
                playerSuggestions: [...suggestionsWithout]
            }))

            if (getState().landingPage.createPlayerAd.playerSuggestions.length == 0) {
                dispatch(doneCreatePlayerAds());
            }

            let playerClubId = suggestions.find(i => i.id === playerId).player.parentSquadId
            dispatch(userActivityInsert({
                Message: 'Not Interested',
                PageName: 'Home [Player recommender]',
                PlayerId: playerId,
                ClubId: playerClubId,
                PageType: PageType.Home
            }));
        }
    }

    public static suggestionsPlayerShortlistToggle(playerId: number) {
        return async (dispatch, getState: () => AppState) => {

            const suggestions = getState().landingPage.createPlayerAd.playerSuggestions
            let objective = suggestions.filter(i => i.id === playerId)[0];

            let playerClubId = suggestions.find(i => i.id === playerId).player.parentSquadId;
            if (objective.player.isInShortList) {
                await PlayerService.deleteFromShortList(playerId);
                dispatch(userActivityInsert({
                    Message: 'Removed from Shortlist',
                    PageName: 'Home [Player recommender]',
                    PlayerId: playerId,
                    ClubId: playerClubId,
                    PageType: PageType.Home
                }));
            } else {
                await PlayerService.addToShortList(playerId);
                dispatch(userActivityInsert({
                    Message: 'Added to Shortlist',
                    PageName: 'Home [Player recommender]',
                    PlayerId: playerId,
                    ClubId: playerClubId,
                    ActionType: ActionType.AddToShortlist,
                    PageType: PageType.Home
                }));
            }

            suggestions.find(item => item.id === objective.id).player.isInShortList = objective.player.isInShortList ? false : true

            dispatch(stateController.setState({
                playerSuggestions: [...suggestions]
            }))
        }
    }

    public static onContinue(item: any) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            dispatch(userActivityInsert({
                Message: `Continue`,
                PageName: 'Player Ads [Create Player Ad]',
                PageType: PageType.PlayerAds
            }))

            if(!item) {
                return
            }

            const auth = state.auth
            const isAgentsFreeTrialActive = hasAgentFeature(state)
            const i = item

            if(auth.isAgentsVisible) {
                let message = '';
                if(isAgentsFreeTrialActive && !i.isAgencyRecommended){
                    message = 'Unchecked Agents Invitation'
                } else if(!isAgentsFreeTrialActive && i.isAgencyRecommended) {
                    message = 'Activated Agency Trial'
                }
                else if(!isAgentsFreeTrialActive && !i.isAgencyRecommended) {
                    message = 'Unchecked Agency Trial activation'
                }

                if(message) {
                    dispatch(userActivityInsert({
                        Message:  message,
                        PageName: 'Home [Create player ads]',
                        PageType: PageType.Home
                    }));
                }
            }
        }
    }

    public static loadAllAds() {
        return async (dispatch) => {

            Actions.token = getCancelTokenSource();

            let myAdsItems = await PlayerAdV2Service.getAllAdsWithCounters(Actions.token.token);

            if (myAdsItems) {
                dispatch(stateController.setState({ playerAds: myAdsItems }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): CreatePlayerAdState => state.landingPage.createPlayerAd;
    public static getPlayerAds = (state: AppState) => Selectors.getRoot(state).playerAds     
    public static getPLayerAdsPositions = (state: AppState) => {
        const subState = Selectors.getPlayerAds(state)
        let playerAdsPositions = subState.map(x => x.ad.positionCode);        
        let uniquePlayerAdsPositions = playerAdsPositions.filter((element, index) => {
            return playerAdsPositions.indexOf(element) === index;
        });
        
        return uniquePlayerAdsPositions
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CreatePlayerAdState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};


