import { PlayerAdWithCountersModel, PlayerAdModel } from './models/player-ad-with-counters';
import axios from 'axios-config';
import { CancelToken } from 'axios';

export class PlayerAdActivityCounters {
    public newPitchCount: number;
    public activeAdCount: number;
    public savedPitchCount: number;
    public outDatedAdsCount: number;

}

export default class PlayerAdV2Service {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAllAdsWithCounters(cancelToken: CancelToken): Promise<Array<PlayerAdWithCountersModel>> {
        const { data } = await axios.get(
            `api/v2/PlayerAd/GetAllWithCounters`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getOutdatedAds(cancelToken: CancelToken): Promise<Array<PlayerAdModel>> {
        const { data } = await axios.get(
            `api/v2/PlayerAd/GetAll`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        var result = (data as Array<PlayerAdModel>).filter(x => x.isOutdated);
        return result;
    }

    public static async updateAdsBatch(adIds: Array<number>): Promise<any> {
        const { data } = await axios.post(
            `api/v2/PlayerAd/UpdateBatch`,
            {
                adIds: adIds
            },
            this.axiosConfig
        )
        return data
    }

    public static async deleteAd(adId: number): Promise<any> {
        const { data } = await axios.delete(
            `api/v2/PlayerAd/Delete?playerAdId=${adId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getActivityCounters(): Promise<PlayerAdActivityCounters> {
        const { data } = await axios.get(
            `api/v2/PlayerAd/GetPlayerAdActivityCountersV2`,
            this.axiosConfig
        )
        return data
    }
}