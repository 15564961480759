import axios from 'axios-config';
import { AgencyPlayerAd, AgencyPlayerAdResponse, PlayerIdName } from "./pitch-models";
import { AgencyAdFilterRequest } from "./agency-ad-filter-model";
import { PitchAccessCriteriaRequest, PitchAccessCriteriaResponse } from "./pitch-access-criteria-models";


export default class PitchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getPlayerAdsByFilter(filter: AgencyAdFilterRequest): Promise<AgencyPlayerAdResponse> {
        const { data } = await axios.post<AgencyPlayerAdResponse>(`api/v2/AgencyAd/GetByFilter`, filter, this.axiosConfig)
        return data;
    }

    public static async getVerifiedPremiumPlayersIdNames(): Promise<PlayerIdName> {
        const { data } = await axios.get<PlayerIdName>(`api/v2/AgentPlayerInvitation/GetVerifiedPlayersIdNames`, this.axiosConfig)
        return data;
    }

    public static async pitch(adId: number, playerId: number, amount: number, note: string) {
        const { data } = await axios.post(
            `api/v2/AgencyAd/Pitch`,
            { playerAdId: adId, playerId: playerId, agentMessage: note, amount: amount },
            this.axiosConfig
        );
        return data;
    }

    public static async notInterestedv2(adId: number, playerId: number) {
        const { data } = await axios.post(`api/v2/AgencyAd/NotInterestAd/v2`, { adId: adId, playerId: playerId }, this.axiosConfig);
        return data;
    }

    public static async getPitchOpportunityAmount(): Promise<number> {
        const { data } = await axios.get<number>(`api/v2/AgencyAd/GetPitchOpportunityAmount`)
        return data;
    }

    public static async getPitchAccessCriteria(adIds: Array<number>, playerIds: Array<number>): Promise<PitchAccessCriteriaResponse> {
        const payload: PitchAccessCriteriaRequest = {
            adIds,
            playerIds
        }
        const { data } = await axios.post<PitchAccessCriteriaResponse>(`api/v2/AgencyAd/GetPitchAccessCriteria`, payload, this.axiosConfig)
        return data;
    }
}