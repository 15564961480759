import { FETCH_UNREAD_MESSAGES_COUNT } from '../actionTypes';
import { createAction } from 'redux-actions';
import { MessagingService } from 'api/messaging/messaging.service'

export function loadUnreadMessageCount() {
    return async (dispatch, getState) => {
        const result = await MessagingService.getUnreadMessagesCount()
        dispatch(setUnreadMessageCount(result))
    }
}

export function setUnreadMessageCount(count) {
    return (dispatch) => {
        dispatch(createAction(FETCH_UNREAD_MESSAGES_COUNT.SUCCESS)({ data: count }));
    }
}
