import * as redux from 'redux';
import * as CommonController from './common.controller';
import * as SearchBarController from './search-bar.controller';
import * as AgentsController from './agents.controller';
import * as PlayerPortfolioController from './player-portfolio.controller';
import * as LeaguesCoverageController from './leagues-coverage-chart.controller';
import * as AgencyInfoController from './agency-info.controller'
import * as ClubConnectionsController from './club-connections.controller'

export class AgencyProfileState {
    common: CommonController.State;
    agencyInfo: AgencyInfoController.State;
    clubConnections: ClubConnectionsController.State;
    searchBar: SearchBarController.State;
    agents: AgentsController.State;
    playerPortfolio: PlayerPortfolioController.State;
    leaguesCoverage: LeaguesCoverageController.State;
}

export function combineReducers() {

    let agencyReducer = {
        common: CommonController.Reducer,
        agencyInfo: AgencyInfoController.Reducer,
        clubConnections: ClubConnectionsController.Reducer,
        searchBar: SearchBarController.Reducer,
        agents: AgentsController.Reducer,
        playerPortfolio: PlayerPortfolioController.Reducer,
        leaguesCoverage: LeaguesCoverageController.Reducer
    };

    return redux.combineReducers(agencyReducer)
} 