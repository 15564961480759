import { 
    UPDATE_HEADER_STYLE, 
    RECALCULATE_HEADER_HEIGHT, 
    SAVE_PREVENT_RECALCULATION_STATE,
    DISPOSE_HEADER_RECALCULATION 
} from './header.actions'

export class HeaderStylesState {
    headerHeight: number;
    preventRecalculation: boolean;
    shouldRecalculateHeight: boolean
}

const initialState: HeaderStylesState = {
    headerHeight: 0,
    preventRecalculation: false,
    shouldRecalculateHeight: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case UPDATE_HEADER_STYLE:
        return { ...state, ...payload }

    case SAVE_PREVENT_RECALCULATION_STATE:
        return { ...state, preventRecalculation: payload }

    case RECALCULATE_HEADER_HEIGHT: 
        return { ...state, shouldRecalculateHeight: true }
    
    case DISPOSE_HEADER_RECALCULATION: 
        return { ...state, shouldRecalculateHeight: false }

    default:
        return state
    }
}
