import React, { Component } from 'react'
import { Slider } from 'antd';
import styled from 'styled-components'
import { Either } from 'types/either'

interface OwnProps {
    value?: number | { min: number; max: number };
    minValue: number;
    maxValue: number;
    onChange: (value: Either<number, { min: number; max: number }>) => void;

    step?: number;
    range?: false | true | { draggableTrack?: boolean };
    disabled?: boolean;
    allowSameValues?: boolean;
    tooltipVisible?: boolean;
    customClassNames?: string;
    onChangeStart?: (value: Either<number, { min: number; max: number }>) => void;
    onChangeComplete?: (value: Either<number, { min: number; max: number }>) => void;
    callbackAfterChange?: (value: { min: number; max: number }) => void;
}

export default class FormInputRange extends Component <OwnProps, any> {

    sameValuesSelected = false
    prevMin = (this.props.value as any)?.min
    prevMax = (this.props.value as any)?.max

    getValueFormatted = (value) => this.props.range ? { min: value[0], max: value[1] } : value

    onAfterChange = (value) => {
        if (this.props.callbackAfterChange) {
            this.props.callbackAfterChange(value);
        }
        if (this.sameValuesSelected && !this.props.allowSameValues && this.props.range) {
            
            this.props.onChange(this.getValueFormatted([this.prevMin, this.prevMax]))
            this.props.onChangeComplete && this.props.onChangeComplete(this.getValueFormatted([this.prevMin, this.prevMax]))

            setTimeout(() => {
                this.sameValuesSelected = false
                // if slider values where changed programaticaly (not by user) we need to set new previous values 
                if ((this.props.maxValue - value[1]) > 0) {
                    this.prevMin = value[0]
                    this.prevMax = value[1] + 1
                } else {
                    this.prevMin = value[0] - 1
                    this.prevMax = value[1]
                }
            }, 100)

        } else {
            this.props.onChangeComplete && this.props.onChangeComplete(this.getValueFormatted(value))
        }
    }

    render() {
        return (
            <StyledSlider className={this.props.customClassNames}>
                <Slider
                    range={this.props.range}
                    step={this.props.step ? this.props.step : 1}
                    min={this.props.minValue}
                    max={this.props.maxValue}
                    value={typeof this.props.value === "object" ? [this.props.value.min, this.props.value.max] : this.props.value as any}

                    tooltipVisible={this.props.tooltipVisible ? this.props.tooltipVisible : false}
                    disabled={this.props.disabled}

                    onChange={(value) => {
                        // Keep state of last valid selection
                        if (this.props.range && !this.props.allowSameValues) {
                            if (value[0] === value[1]) {
                                this.sameValuesSelected = true
                            } else {
                                this.sameValuesSelected = false
                                this.prevMin = value[0]
                                this.prevMax = value[1]
                            }
                        }

                        this.props.onChange(this.getValueFormatted(value))
                    }}
                    onAfterChange={this.onAfterChange}
                />
            </StyledSlider>
        )
    }
}

const StyledSlider = styled.div`
    .ant-slider {
        height: 10px;
        .ant-slider-step {
            height: 2px;
        }
        .ant-slider-track {
            height: 2px;
            background-color: #0e9655;
        }
        .ant-slider-rail {
            height: 2px;
            background-color: #c8c9cd;
        }
        .ant-slider-handle {
            margin-top: -7px;
            border-color: #0e9655;
            width: 16px;
            height: 16px;
            &:hover {
                border-color: #0d854b;
            }
        }
        &:hover {
            .ant-slider-rail {

            }
            .ant-slider-handle:not(.ant-tooltip-open) {
                border-color: #0d854b;
            }
        }
    }
`