import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { SearchItemForClub, SearchResult, SearchItemType } from 'api/search/model/search-result'
import { SearchPlayerItemForClub } from "api/search/model/search-player-item-for-club";
import { getPageSize, setPageSize } from 'ts-components/pagination/helpers/paging-store'
import { SearchRequest } from 'api/search/model/search-request'
import { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from 'axios-config';
import ClubSideSearchService from 'api/search/search.clubside.service'
import { GridActivityService } from './grid-activity.service'
import { PlayerActivityService, AgencyActivityService, ClubActivityService } from './player-activity.service'
import * as storedFilter from 'pages/PlayerSearch-v2/redux/stored-filter'
import * as filterController from './filter.controller'

type SearchResultClub = SearchResult<SearchItemForClub>;

class GridState {
    result: SearchResultClub;
    resultLoading: boolean;
    selectedItemId: number;
    gridState: GridToggleState;
    pageSize: number;
    processingItems: Array<number>;
    similarityPlayerId: number;
    paginationState: number;
}

export enum GridToggleState {
    None = 0,
    Search = 1,
    ShortList = 2,
    Similarity = 3
}

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

const defaultState: GridState = {
    result: null,
    gridState: GridToggleState.None,
    resultLoading: false,
    selectedItemId: null,
    pageSize: getPageSize('search', 10),
    processingItems: [],
    similarityPlayerId: null,
    paginationState: PaginationClickState.Number,
}

const stateController = new StateController<GridState>(
    "SEARCH_SCREEN/GRID",
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static setSelectedItem(id: number) {
        return (dispatch, getState: () => AppState) => {
            let oldId = getState().newPlayerSearch.grid.selectedItemId;
            let newId = oldId == id ? null : id;
            dispatch(stateController.setState({ selectedItemId: newId }));
            if (newId == null) return;
            const item = Selectors.getSearchItem(getState(), newId)

            if (!item == null) return;

            if (item.type === SearchItemType.Agency) { dispatch(AgencyActivityService.openDropDown(item.agency.id)) }
            else if (item.type === SearchItemType.Club) { dispatch(ClubActivityService.openDropDown(item.club.id)) }
            else {
                dispatch(PlayerActivityService.openDropDown(
                    item.player.id,
                    item.player.recommendedFlag,
                    item.player.isSuspendedContract,
                    item.player.isOnReleaseList,
                    item.player.parentSquad ? item.player.parentSquad.id : null,
                    !item.player.parentSquad && item.player.agency ? item.player.agency.id : null,
                    Selectors.getGridState(getState())
                ))
            }
        }
    }

    public static toggleGridState(state: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState())

            dispatch(stateController.setState({
                gridState: state,
                similarityPlayerId: state != GridToggleState.Similarity ? null : subState.similarityPlayerId
            }));
            dispatch(filterController.Actions.initSorting());
        }
    }

    public static load(state: GridToggleState) {
        return async (dispatch, getState: () => AppState) => {
            let accessRestricted = Selectors.accessRestricted(getState())
            dispatch(Actions.toggleGridState(state));

            if (!accessRestricted) {
                dispatch(Actions.refresh(
                    () => {
                        if (state == GridToggleState.Search) {
                            dispatch(GridActivityService.openSearch())
                        } else if (state == GridToggleState.ShortList) {
                            dispatch(GridActivityService.openShortList())
                        }
                    }
                ));
            }
        }
    }

    public static initSimilarity(playerId: number, clubId?: number, agencyId?: number) {
        return async dispatch => {
            dispatch(stateController.setState({ similarityPlayerId: playerId }));
            dispatch(Actions.refresh(
                () => dispatch(GridActivityService.selectSimilarPlayer(playerId, clubId, agencyId))
            ));
        }
    }

    public static refresh(action?: () => void) {
        return async (dispatch, getState: () => AppState) => {
            let tabKey = Selectors.getGridState(getState());
            let searchMode = filterController.Selectors.getRoot(getState()).searchMode;
            let isAnyFilterActive = filterController.Selectors.isFiltersInActiveState(getState());
            if (tabKey == GridToggleState.Search && searchMode == filterController.SearchMode.Default && !isAnyFilterActive)
                return;

            if (Actions.token) {
                Actions.token.cancel();
            }

            Actions.token = getCancelTokenSource();

            const state = getState();
            const subState = state.newPlayerSearch;

            dispatch(stateController.setState({ resultLoading: true, result: null }));

            let req: SearchRequest = Selectors.getRequest(state);

            let result: SearchResultClub = null;
            const isSimilarPlayerSelected = subState.grid.similarityPlayerId != null;
            const isSimilartyTabActive = Selectors.isSimilarity(state);

            if (!isSimilartyTabActive) {
                result = await ClubSideSearchService.search(req, Actions.token.token);
            } else if (isSimilarPlayerSelected) {
                result = await ClubSideSearchService.searchSimilarity(subState.grid.similarityPlayerId, req, Actions.token.token);
            }

            if (result && action) {
                action()
            }

            dispatch(stateController.setState({ result: result, resultLoading: false }));

        }
    }

    public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const paginationState = Selectors.getRoot(getState()).paginationState;

            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: page },
                }
            }))

            dispatch(Actions.refresh(
                () => dispatch(GridActivityService.setPage(page, paginationState))
            ))
        }
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: page },
                    pageSize,
                }
            }))
            setPageSize('search', pageSize)
            dispatch(Actions.refresh(
                () => dispatch(GridActivityService.setPageSize(pageSize))
            ))
        }
    }

    public static resetPage = () => {
        return (dispatch) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: 1 },
                }
            }))
        }
    }

    public static setAsProcessing = (id: number, isProcessing: boolean) => {
        return (dispatch, getState: () => AppState) => {
            let state = getState().newPlayerSearch.grid;

            if (isProcessing) {
                dispatch(stateController.setState(
                    { processingItems: [...state.processingItems, id] }
                ))
            }
            else {
                dispatch(stateController.setState(
                    { processingItems: [...state.processingItems.filter(x => x != id)] }
                ))
            }
        }
    }

    public static updatePlayer(id: number, newItem: SearchPlayerItemForClub) {
        return (dispatch, getState: () => AppState) => {
            const state = getState().newPlayerSearch.grid;

            if (!state.result) return;

            const array: Array<SearchItemForClub> = state.result.items
                .map(item => item.player.id == id ? { ...item, player: newItem } : item)

            dispatch(stateController.setState(
                {
                    result: {
                        ...state.result,
                        items: array
                    }
                }
            ))
        }
    }

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.newPlayerSearch.grid;
    public static getCurrentPage = (state: AppState) => (Selectors.getRoot(state).result || {}).currentPage || 1;
    public static getGridState = (state: AppState) => Selectors.getRoot(state).gridState;
    public static isSimilarity = (state: AppState) => Selectors.getGridState(state) == GridToggleState.Similarity;
    public static isInShortList = (state: AppState) => Selectors.getGridState(state) == GridToggleState.ShortList;
    public static isSearchPage = (state: AppState) => Selectors.getGridState(state) == GridToggleState.Search;
    public static getTotalRowCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalResultCount;
    public static getTotalPageCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalPageCount;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).resultLoading || (Selectors.getRoot(state).result == null && Selectors.getGridState(state) != GridToggleState.Similarity);
    public static getItems = (state: AppState) => (Selectors.getRoot(state).result || {}).items || [];
    public static accessRestricted = (state: AppState) => !(state.auth.clubPermission.isAllLeaguesAvailable || state.auth.clubPermission.availableLeagues.length > 0);
    public static getSearchItem = (state: AppState, id: number) => {
        return Selectors.getItems(state).find(x => x.player != null && x.player.id == id || x.agency != null && x.agency.id == id || x.club != null && x.club.id == id);
    }
    public static getRequest = (state: AppState) => {
        const subState = state.newPlayerSearch;
        const gridState = Selectors.getGridState(state);
        const structure = subState.filter.structure;
        const pageNumber = Selectors.getCurrentPage(state);

        const getRange = (criteria, structure) => {
            if (!criteria) return null;
            if (criteria.max === null && criteria.min === null) return null;

            const value = { ...criteria };
            if (value.max === structure.max) { value.max = null; }
            if (value.min === structure.min) { value.min = null; }

            return value;
        }

        let req: SearchRequest = {
            clubsAndAgentsFilter: {
                showOnlyClubs: subState.filter.clubAgentsFilter.showOnlyClubs,
                showOnlyTrustedAgents: subState.filter.clubAgentsFilter.showOnlyTrustedAgents
            },
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
            playerFilter: {
                positionList: subState.filter.transferTypeFilter.positionList,
                showOnlyFreeAgentPlayers: subState.filter.transferTypeFilter.showOnlyFreeAgentPlayers,
                anuallGrossSalaryYearly: subState.filter.transferTypeFilter.anuallGrossSalaryYearly,
                loanFee: subState.filter.transferTypeFilter.loanFee,
                transferFee: subState.filter.transferTypeFilter.transferFee,
                minutesPlayed: getRange(subState.filter.moreFilter.minutesPlayed, structure.minutesPlayed),
                showOnlyAvailablePlayers: subState.filter.transferTypeFilter.showOnlyAvailablePlayers,
                showOnlyPlayersWithSuspendedContract: subState.filter.transferTypeFilter.showOnlyPlayersWithSuspendedContract,
                showOnlyPlayersOnReleaseList: subState.filter.transferTypeFilter.showOnlyPlayersOnReleaseList,
                age: { min: subState.filter.moreFilter.age.min, max: subState.filter.moreFilter.age.max },
                gbePass: subState.filter.moreFilter.gbePass,
                contractExpiryMonthesMax: subState.filter.moreFilter.contractExpiryMonthesMax,
                minHeight: subState.filter.moreFilter.minHeight,
                leaguesList: [
                    ...subState.filter.moreFilter.leaguesList.map(i => i),
                    ...subState.filter.moreFilter.leaguesLists.map(i => i.competitionIds).flat()
                ],
                nationalityList: subState.filter.moreFilter.nationalityList.map(i => i),
                minRating: subState.filter.moreFilter.minRating,
            },
            shortListOnly: gridState == GridToggleState.ShortList,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            playerSet: (subState.filter.searchSubset || {}).playerSet || null
        };

        return req;
    }

    public static getStoredFilter = (state: AppState) => {
        const subState = state.newPlayerSearch;
        return {
            clubsAndAgentsFilter: {
                showOnlyClubs: subState.filter.clubAgentsFilter.showOnlyClubs,
                showOnlyTrustedAgents: subState.filter.clubAgentsFilter.showOnlyTrustedAgents
            },
            playerFilter: {
                positionList: subState.filter.transferTypeFilter.positionList,
                showOnlyFreeAgentPlayers: subState.filter.transferTypeFilter.showOnlyFreeAgentPlayers,
                showOnlyAvailablePlayers: subState.filter.transferTypeFilter.showOnlyAvailablePlayers,
                showOnlyPlayersWithSuspendedContract: subState.filter.transferTypeFilter.showOnlyPlayersWithSuspendedContract,
                showOnlyPlayersOnReleaseList: subState.filter.transferTypeFilter.showOnlyPlayersOnReleaseList,
                anuallGrossSalaryYearly: subState.filter.transferTypeFilter.anuallGrossSalaryYearly,
                loanFee: subState.filter.transferTypeFilter.loanFee,
                transferFee: subState.filter.transferTypeFilter.transferFee,
                age: { min: subState.filter.moreFilter.age.min, max: subState.filter.moreFilter.age.max },
                gbePass: subState.filter.moreFilter.gbePass,
                contractExpiryMonthesMax: subState.filter.moreFilter.contractExpiryMonthesMax,
                minHeight: subState.filter.moreFilter.minHeight,
                minutesPlayed: subState.filter.moreFilter.minutesPlayed,
                leaguesList: subState.filter.moreFilter.leaguesList,
                leaguesLists: subState.filter.moreFilter.leaguesLists,
                nationalityList: subState.filter.moreFilter.nationalityList,
                minRating: subState.filter.moreFilter.minRating,
            },
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
        };
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    GridState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};
