import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { IMessageSendSpecificaion, MessageSendSession } from 'components/send-message-modal/send-message-modal.controller';
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { Actions as SendMessageModalActions } from 'components/send-message-modal/send-message-modal.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { toKMB } from 'utils/number-utils'
import { currencyById } from 'constants/currency'
import { AgecnyAccessService } from 'api/agency/club/agency-access/agency-access.service'
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { Selectors as SendMessageSelectors, Actions as SendMessageActions  } from 'pages/PlayerSearch-v2/redux/send-message.controller'

class AgencyInfo {
    agencyCity: string;
    agencyCountryName: string;
    agencyName: string;
    accessGranted: boolean;
    isVerified: boolean;
    isTrusted: boolean;
    webSiteUrl: string;
    markets: string;
    agencyId: number;
    isAccessProcessing: boolean;
    countOfAllActiveSquads: number;
    agencyIsNotAccesible: boolean;
}

const defaultState: AgencyInfo = {
    agencyCity: '',
    agencyCountryName: '',
    agencyName: '',
    isVerified: null,
    isTrusted: false,
    accessGranted: null,
    webSiteUrl: null,
    markets: null,
    agencyId: null,
    isAccessProcessing: false,
    countOfAllActiveSquads: 0,
    agencyIsNotAccesible: false
}

const stateController = new StateController<AgencyInfo>(
    'AGENCY_PROFILE/AGENCY_INFO',
    defaultState
)

class Actions {
    public static setDataToState(data) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...data }))
        }
    }
    public static givePitchAccess(agencyId) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState().agencyProfile;
            const agency = state.agencyInfo;
            const isPreview = state.common.isPreview;
            if(isPreview) {
                return
            }

            try {
                dispatch(stateController.setState({ isAccessProcessing: true }))
                await AgecnyAccessService.giveAccess(agencyId)

                dispatch(userActivityInsert({
                    PageName: 'Club Agents [Agency profile]',
                    Message: 'Granted Access',
                    AgencyId: agency.agencyId,
                    PageType: PageType.Agents
                }));

                dispatch(stateController.setState({ accessGranted: true }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isAccessProcessing: false }))
            }
        }
    }
    public static revokePitchAccess(agencyId) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState().agencyProfile;
            const agency = state.agencyInfo;
            const isPreview = state.common.isPreview;
            if(isPreview) {
                return
            }

            try {
                dispatch(stateController.setState({ isAccessProcessing: true }))
                await AgecnyAccessService.revokeAccess(agencyId)

                dispatch(userActivityInsert({
                    PageName: 'Club Agents [Agency profile]',
                    Message: 'Revoked Access',
                    AgencyId: agency.agencyId,
                    PageType: PageType.Agents
                }));

                dispatch(stateController.setState({ accessGranted: false }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isAccessProcessing: false }))
            }
        }
    }

    public static openSendMessageModal() {
        return async (dispatch, getState: () => AppState) => {

            const state = getState();
            const agent = state.agencyProfile.agencyInfo
            const isPreview = state.agencyProfile.common.isPreview;
            if(isPreview) {
                return
            }

            if (!SendMessageSelectors.isPermited(getState())) {
                dispatch(SendMessageActions.togleRestrictedMessageModal());
                return
            }

            if (SendMessageSelectors.isNewMessageLimitRichedOutOrHide(state)) {
                dispatch(SendMessageActions.togleRestrictedAvailableModal());
                return
            }

            const session: MessageSendSession = {
                subject: "New Message",
                logo: undefined,
                checkBoxTitle: null,
                officialName: agent.agencyName,
                leagueName: '',
                mesageTypeId: 8,
                toClub: false,
                toAgency: true,
                agencyName: agent.agencyName,
                agencyCity: agent.agencyCity,
                agencyCountry: agent.agencyCountryName,
            }

            dispatch(userActivityInsert({
                PageName: 'Club Agents [Agency profile]',
                Message: 'Opened New Message',
                AgencyId: agent.agencyId,
                PageType: PageType.Agents
            }));

            const specification: IMessageSendSpecificaion = {
                sendMessage: async (session: MessageSendSession, message: string, checked: boolean): Promise<any> => {
                    await dispatch(messageSend(agent.agencyId, message, null, session.officialName, session.mesageTypeId, session.subject, true));
                    dispatch(userActivityInsert({
                        PageName: 'Club Agents [Agency profile]',
                        Message: 'Sent New Message',
                        AgencyId: agent.agencyId,
                        ActionType: ActionType.SentNewMessage,
                        PageType: PageType.Agents,
                        UserActivityType: UserActivityType.Action
                    }));
                },
                cancelMessage: () => {
                    dispatch(userActivityInsert({
                        PageName: 'Club Agents [Agency profile]',
                        Message: 'Cancelled New Message',
                        AgencyId: agent.agencyId,
                        PageType: PageType.Agents
                    }));
                }
            }

            dispatch(SendMessageModalActions.openSession(session, specification))
        }
    }

    
    public static openWebSite() {
        return async (dispatch, getState) => {
            const agent = getState().agencyProfile.agencyInfo
            const isPreview = getState().agencyProfile.common.isPreview

            if(!isPreview){
                dispatch(userActivityInsert({
                    PageName: 'Club Agents [Agency profile]',
                    Message: `Opened website`,
                    AgencyId: agent.agencyId,
                    PageType: PageType.Agents
                }))
            }
        }
    }
}

class Selectors {
    public static selectClubConnectionsCount(state: AppState) {
        return state.agencyProfile.clubConnections.connectedByClubs.length
    }
    public static selectPlayersInPortfolioCount(state: AppState) {
        return state.agencyProfile.playerPortfolio.players.length
    }
    public static selectAgencyMarketValue(state: AppState) {
        const players = state.agencyProfile.playerPortfolio.players
        const allPlayersMarketValue = players.reduce((acc, item) => {
            return acc + item.marketValue
        }, 0)

        const allPlayersMarketValueFormatted = toKMB(allPlayersMarketValue)
        const currency = currencyById[state.agencyProfile.searchBar.selectedCurrencyId]

        return `${currency}${allPlayersMarketValueFormatted}`
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyInfo as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};