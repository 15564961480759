import * as redux from 'redux';
import * as createPlayerAdController from 'pages/player-ads/create-new-ad-modal/redux/create-new-ad.controller';

export class PlayerAdsState {
    createPlayerAds: createPlayerAdController.State;
}

export function combineReducers() {

    let playerAdsReducers = {
        createPlayerAds: createPlayerAdController.Reducer,
    };

    return redux.combineReducers(playerAdsReducers)
} 