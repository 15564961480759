import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { BOOK_EXPERT_SESSION_MODAL } from 'constants/modals';
import { PageType } from 'constants/enums';

export class AddUserState {
    isLoading: boolean
}

const defaultState: AddUserState = {
    isLoading: false,
}

const stateController = new StateController<AddUserState>(
    "AGENCY/LANDING_PAGE/BOOK_EXPERT_SESSION_MODAL",
    defaultState
);

class Actions {
    public static openBookSessionModal = () => {
        return (dispatch, getState) => {

            const state = getState();
            const salesExpert = state.agency.landingPage.notification.notification.successSalesExpert;
            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: BOOK_EXPERT_SESSION_MODAL,
                    className: 'hp-book-session-modal',
                    content: {
                        pageName: 'Agency Home [Success Expert]',
                        pageType: PageType.AgencyHome,
                        salesExpert: salesExpert
                    }
                },
            };
            dispatch(command);
        }
    }

    public static openBookSessionAdvisorModal = () => {
        return (dispatch, getState) => {

            const state = getState();
            const salesExpert = state.agency.landingPage.notification.notification.defaultAdvisor;

            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: BOOK_EXPERT_SESSION_MODAL,
                    className: 'hp-book-session-modal',
                    content: {
                        pageName: 'Agency Home [Success Expert]',
                        pageType: PageType.AgencyHome,
                        salesExpert: salesExpert
                    }
                },
            };
            dispatch(command);
        }
    }

    public static processAutoOpenModalFromEmail = () => {
        return (dispatch, getState: () => AppState) => {

            const params = new URLSearchParams(window.location.search)
            const fromEmail = params.get("fromEmail")
            const openBookSessionModal = params.get("openBookSessionModal")

            if (fromEmail && openBookSessionModal) {
                
                const state = getState();
                
                const successExpert = state.agency.landingPage.notification.notification.successSalesExpert;
                const isAgencySubscribed = state.auth.isAgencySubscribed
                
                if (successExpert !== null && isAgencySubscribed) {
                    dispatch(Actions.openBookSessionModal())
                } else {
                    dispatch(Actions.openBookSessionAdvisorModal())
                }
                
            }
        }
    }


    public static closeBookSessionModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: BOOK_EXPERT_SESSION_MODAL,
            };
            dispatch(command);
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AddUserState as State,
    Actions as Actions,
    stateController as Controller
};


