import axios from 'axios-config';
import {
    Country,
    FilterOptions,
    SalesSupervisor,
    AgencyPermissionResponse,
    EditCostValueRequest,
    AdminAgencyEditCreditsRequest,
    PlayerMarketRequest,
    PlayerMarketsSubscriptionModel,
    RepresentationRequestSettingOption,
    AdditionalPlayerMarketSubscriptionEnum,
    RepresentationRequestSettingOptionEnum,
    AgenciesSubscriptionModel,
    CoachAccessOptionsModel,
    EventAccessOptionsModel
} from "admin-v2/pages/agency-permissions/models";

export class AgencyPermissions {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getCountries(userId: number): Promise<Array<Country>> {
        const { data } = await axios.get(
            `api/Competition/GetAreasWithCompetitions/${userId}?withSubSquads=true&onlyWithUsers=false`,
            this.axiosConfig
        );
        return data;
    };

    public static async getSalesSupervisorList(): Promise<Array<SalesSupervisor>> {
        const { data } = await axios.get(
            `api/v2/AdminAgencySubscription/GetAvailableSaleSupervisors`,
            this.axiosConfig
        );
        return data;
    };

    public static async getAdditionalPlayerMarketSubscriptions(): Promise<Array<PlayerMarketsSubscriptionModel>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetAdditionalPlayerMarketSubscriptions`,
            this.axiosConfig
        );
        return data;
    }

    public static async getAllAgenciesSubscriptions(): Promise<Array<AgenciesSubscriptionModel>> {
        const { data } = await axios.get(
            `api/v2/AgencySubscription/GetAllPlansList`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCoachAccessOptions(): Promise<Array<CoachAccessOptionsModel>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetCoachAccessOptions`,
            this.axiosConfig
        );
        return data;
    }

    public static async getEventAccessOptions(): Promise<Array<EventAccessOptionsModel>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetEventAccessOptions`,
            this.axiosConfig
        );
        return data;
    }

    public static async getRepresentationRequestOptions(): Promise<Array<RepresentationRequestSettingOption>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetRepresentationRequestOptions`,
            this.axiosConfig
        );
        return data;
    }

    public static async getAgencyPermissions(request: FilterOptions): Promise<any> {       
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/GetAgencyPermissions`,
            request,
            this.axiosConfig
        );     
        return data;
    };

    public static async getPlayerCostPermissions(request: any): Promise<Array<AgencyPermissionResponse>> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/GetPlayerCostPermissions`,
            request,
            this.axiosConfig
        );  
        return data;
    };

    public static async allowAction(agencyId: number, permissionCode: string) {       
        await axios.post(
            `api/AdminAgencyPermissions/AllowAction`,
            { agencyId, permissionCode }
        );
    };

    public static async disallowAction(agencyId: number, permissionCode: string) {
        await axios.post(
            `api/AdminAgencyPermissions/DisallowAction`,
            { agencyId, permissionCode }
        );
    };

    public static async editCostValue(request: EditCostValueRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/EditCostValue`,
            request,
            this.axiosConfig,
        );
        return data.output;
    };

    public static async editSubscriptionsCredits(request: AdminAgencyEditCreditsRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/EditCredits`,
            request,
            this.axiosConfig,
        );
        return data.output;
    };

    public static async setAgencySalesSupervisor(agencyId: number, salesSupervisorId?: number){
        const { data } = await axios.post(
            `api/v2/AdminAgencySubscription/SetAgencySalesSuperVisor`,
            {
                agencyId: agencyId,
                salesSupervisorId: salesSupervisorId 
            },
            this.axiosConfig
        );        
        return data;
    };

    public static async getAllAgencies(request: any): Promise<any> {        
        const { data } = await axios.post(
            `api/v2/Agency/GetAllAgencies`,
            request,
            this.axiosConfig
        );     
        return data;
    };

    public static async savePlayerMarkets (request: PlayerMarketRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SavePlayerMarkets`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async saveAdditionalPlayerMarketSubscription(agencyId: number, additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SaveAdditionalPlayerMarketSubscription`,
            {
                agencyId,
                additionalPlayerMarketSubscriptionId
            },
            this.axiosConfig
        );
        return data;
    }

    public static async saveSubscriptionPlan(agencyId: number, subscriptionPlanId: number): Promise<any> {
        const { data } = await axios.post(
            `api/v2/AdminAgencySubscription/AddSubscription`,
            {
                agencyId,
                subscriptionPlanId
            },
            this.axiosConfig
        );
        return data;
    }

    public static async saveRepresentationRequestOption(agencyId: number, representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SaveReprestntationRequestSettingOption`,
            {
                agencyId,
                representationRequestSettingOptionId
            },
            this.axiosConfig
        );
        return data;
    }

    public static async saveCoachAccessOption(agencyId: number, coachAccessOptionId: number): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SaveCoachAccessOption`,
            {
                agencyId,
                optionId: coachAccessOptionId
            },
            this.axiosConfig
        );
        return data;
    }
}
