export const adjustCriteriaBySelectionRanges = (criteria, ranges, loanFeeRangeChanged, transferFeeRangeChanged, forLoan, forSale) => {
    let monthlyLoanFeeMin = Math.min(ranges.monthlyLoanFeeMin, ranges.grossSalaryMin);
    let monthlyLoanFeeMax = Math.max(ranges.monthlyLoanFeeMax, ranges.grossSalaryMax);
    if(forLoan && !forSale)
    {
        monthlyLoanFeeMin = ranges.monthlyLoanFeeMin;
        monthlyLoanFeeMax = ranges.monthlyLoanFeeMax;
    }
    if(!forLoan && forSale)
    {
        monthlyLoanFeeMin = ranges.grossSalaryMin;
        monthlyLoanFeeMax = ranges.grossSalaryMax;
    }
    let transferFeeMin = ranges.transferFeeMin;
    let transferFeeMax = ranges.transferFeeMax;

    let criteriaCloned = { ...criteria };

    if (loanFeeRangeChanged) {
        criteriaCloned = { ...criteriaCloned, monthlyLoanFeeMin: monthlyLoanFeeMin, monthlyLoanFeeMax: monthlyLoanFeeMax };
    }
    if (transferFeeRangeChanged) {
        criteriaCloned = { ...criteriaCloned, transferFeeMin: transferFeeMin, transferFeeMax: transferFeeMax };
    }

    return criteriaCloned;
}