import React from 'react';
import TestimonialSlider from 'app/testimonial-slider/testimonials-slider'
import styled, { css } from 'styled-components'

type Props = {
    benefits?: string[];
    testimonials: any[];
    hideBottomLine?: boolean;
}

const WhatToExpect = (props: Props) => {
    const { benefits } = props;
    return (
        <Section hideBottomLine={props.hideBottomLine}>
            <div className='extra-text'>
                From Italy to the World, the global transfer market is coming to Rome for the first TransferRoom Summit of 2024! Hundreds of clubs and Trusted Agencies will descend on 
                the Italian capital for the biggest gathering of decision makers in world football. The Summer Transfer Window unofficially kicks off in Rome and Online from 18-20 March,
                and you can meet with up to 38 clubs of your choice, connect with the right people and get business done quickly.
            </div>
            <h2>What to expect:</h2>
            <div className="row">
                <div className="list-container">
                    <ul>
                        {benefits.map((item, index) => (
                            <li className={'mobile-li'} key={index}>
                                <span className="fa fa-check" />
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div style={{ height: 20 }} />
            <TestimonialSlider testimonials={props.testimonials} />
        </Section>
    )
}

const Section = styled.div`
    margin: 0 auto;
    margin-bottom: 60px;
    padding-left: 5px;
    margin-top: 74px;

    ${props => !props.hideBottomLine && css`
        border-bottom: solid 1px #d4d4d4;
        padding-bottom: 50px;
    `}

    h2 {
        font-size: 40px;
        font-weight: bold;
    }

    .extra-text {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .list-container {
        padding: 0;
        margin-top: 15px;

        @media (max-width: 768px) {
            padding: 0 15px;
        }

        ul {
            font-size: 18px;
            padding: 0;

            .mobile-li {
                margin-bottom: 7px;
                font-weight: 500;
            }
            li {
                display: flex;
            }
            span {
                color: #0E854C;
                font-size: 21px;
                margin-right: 15px;
                margin-top: 4px;
            }
        }

        @media (min-width: 768px) {
            padding-left: 15px;
        }
    }
`

export default WhatToExpect