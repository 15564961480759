import * as redux from 'redux';
import * as OnboardingController from 'pages/staff/personal-account/onboarding/onboarding.controller';

export type PersonalAccountState = {
    onboarding: OnboardingController.State,
}

export function combineReducers() {

    const coachReducers = {
        onboarding: OnboardingController.Reducer,
    };

    return redux.combineReducers(coachReducers);
}
