import history from 'history-accessor';

export const isSampleDataRoute = () => history.location.pathname === '/player-points/sample-data'


export const FaPlayerPointResult = {
    PASS: 'Pass',
    FAIL: 'Fail',
    SETTLED: 'Settled',
    UK_NATIONAL: 'UK National',
    AUTO_PASS: 'Auto-Pass',
    ExceptionsPanel: "Exceptions Panel",
    YouthExceptionsPanel: "Youth Exceptions Panel",
    ExcPanelEscEligible: "Exc. Panel: ESC Eligible",
    FailEscEligible: "Fail: ESC Eligible",
}

export const isPlayerPointPass = (result) => result === FaPlayerPointResult.PASS;
export const isPlayerPointSettled = (result) => result === FaPlayerPointResult.SETTLED;
export const isPlayerPointUKNational = (result) => result === FaPlayerPointResult.UK_NATIONAL;
export const isPlayerPointAutoPass = (result) => result === FaPlayerPointResult.AUTO_PASS;
export const isPlayerPointAutoFail = (result) => [FaPlayerPointResult.FAIL.toLocaleLowerCase(), FaPlayerPointResult.FailEscEligible.toLocaleLowerCase()].includes(result.toLocaleLowerCase());
export const isException = (result) => [FaPlayerPointResult.ExceptionsPanel.toLocaleLowerCase(), FaPlayerPointResult.YouthExceptionsPanel.toLocaleLowerCase(), FaPlayerPointResult.ExcPanelEscEligible.toLocaleLowerCase()].includes(result.toLocaleLowerCase());

export const isUKPlayer = (result) => isPlayerPointSettled(result) || isPlayerPointUKNational(result);
