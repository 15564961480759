import React from 'react'
import { connect } from 'react-redux';
import { getTumbnailUrl } from 'utils/video-links';
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player';
import Thumb from './bg-madrid.png';
import PlayVimeoIcon from './play-btn.png';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import DividerLine from '../divider-line/divider-line';
import screenfull from 'screenfull';

class VimeoPlayerProps {
    content: Content;
    autoplay?: boolean;
    trackUserActivity: () => void;
    compactMode?: boolean;
}

type Content = {
    videoUrl: string;
    backgroundThumb: string;
    heading1: string;
    heading2: string;
    highlightsText: string;
    speakers: Array<{
        imgSrc: string;
        name: string;
        title: string;
        organization: string;
    }>
}

class ReplayComponent extends React.Component<VimeoPlayerProps, any> {
    state = {
        isPlaying: false,
        isUserActivityTracked: false,
    }

    componentDidMount() {
        if (this.props.autoplay) {
            setTimeout(() => {
                this.setState({ isPlaying: true })
            }, 200) // Time required for <ReactPlayer /> to be ready to play video
        }
    }

    render() {

        const {
            videoUrl,
            backgroundThumb,
            heading1,
            heading2,
            highlightsText,
            speakers
        } = this.props.content
        const { isPlaying } = this.state

        return (
            <StyledSection compactMode={this.props.compactMode} >
                {/* <DividerLine noTopMargin title="Virtual Summit Replay" /> */}
                <div className="iframe-container">
                    <div>
                        <div className={`video`}>
                            {!isPlaying && (
                                <div onClick={() => {
                                    this.setState({ isPlaying: !this.state.isPlaying }, () => {
                                        if (!this.state.isUserActivityTracked && this.props.trackUserActivity) {
                                            this.props.trackUserActivity()
                                            this.setState({ isUserActivityTracked: true })
                                        }
                                    })
                                }}>
                                    <img className="tumbnail" src={backgroundThumb} alt="video tumbnail" />

                                    <div className="overlay-container">
                                        <div className="heading">
                                            <div>{heading1}</div>
                                            <div>{heading2}</div>
                                        </div>
                                        <img className="play-btn" src={PlayVimeoIcon} ></img>
                                        <div className="highlights">{highlightsText}</div>
                                        <div className="speakers">
                                            {speakers.map((item, index) => (
                                                <div key={index}>
                                                    <img src={item.imgSrc}></img>
                                                    <div className="text">
                                                        <div className="name">{item.name}</div>
                                                        <div className="title">{item.title}</div>
                                                        <div className="organization">{item.organization}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            )}

                            <ReactPlayer
                                playing={isPlaying}
                                onPause={() => { 
                                    if(screenfull.isFullscreen){
                                        screenfull.exit();
                                    }
                                    this.setState({ isPlaying: false });
                                }}
                                controls={true}
                                style={isPlaying ? { width: '100%', height: '100%' } : { display: 'none', width: '100%', height: '100%' }}
                                url={videoUrl}
                            />
                        </div>
                    </div>
                </div>
            </StyledSection>
        )
    }
}

export default connect(null, { userActivityInsert: userActivityInsert })(ReplayComponent)

const StyledSection = styled.div`

    .iframe-container {
        overflow: hidden;
        padding-top: 57.25%;
        position: relative;
        border-radius: 15px;

        > div {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            .video {
                position: relative;
                width: 100%;
                height: 100%;
                > div:first-child {
                    width: 100% !important;
                    height: 100% !important;
                }
                .tumbnail {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                .overlay-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @media (max-width: 650px) {
                        padding: 25px;
                    }
                    @media (max-width: 550px) {
                        flex-direction: column-reverse;
                    }
                    .heading {
                        margin-bottom: 30px;
                        @media (max-width: 1200px) {
                            margin-bottom: 20px;
                        }
                        @media (max-width: 550px) {
                            margin: 0;
                        }
                        > div:first-child {
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 63px;
                            text-align: center;
                            color: #FFFFFF;
                            @media (max-width: 1200px) {
                                font-size: 30px;
                                line-height: 1.3;
                            }
                            @media (max-width: 650px) {
                                font-size: 25px;
                            }
                        }
                        > div:last-child {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 24px;
                            text-align: center;
                            color: #FFFFFF;
                            @media (max-width: 1200px) {
                                font-size: 16px;
                                line-height: 1.3;
                            }
                            @media (max-width: 650px) {
                                font-size: 14px;
                            }
                        }
                    }
                    .play-btn {
                        display: block;
                        position: relative;
                        margin: 0 auto;
                        height: 87px;
                        width: 87px;
                        transition: all 100ms ease-in-out;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.8;
                        }
                        @media (max-width: 1200px) {
                            height: 60px;
                            width: 60px;
                        }
                        @media (max-width: 650px) {
                            height: 40px;
                            width: 40px;
                        }
                        @media (max-width: 550px) {
                            margin-top: 0;
                            margin-bottom: 10px;
                            height: 50px;
                            width: 50px;
                        }
                    }
                    .highlights {
                        margin-top: 20px;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 29px;
                        text-align: center;
                        color: #FFFFFF;
                        @media (max-width: 1200px) {
                            font-weight: 600;
                            margin-top: 10px;
                            font-size: 18px;
                        }
                        @media (max-width: 650px) {
                            font-size: 15px;
                        }
                        @media (max-width: 550px) {
                            display: none;
                        }
                    }
                    .speakers {
                        margin-top: 45px;
                        display: flex;
                        justify-content: center;
                        gap: 0 30px;
                        @media (max-width: 1200px) {
                            margin-top: 30px;
                            gap: 0 20px;
                        }
                        @media (max-width: 550px) {
                            display: none;
                        }
                        > div {
                            display: flex;
                            align-items: center;
                            > img {
                                margin-right: 14px;
                                width: 107px;
                                height: 107px;
                                border: 4px solid white;
                                border-radius: 100%;
                                @media (max-width: 1200px) {
                                    width: 70px;
                                    height: 70px;
                                    margin-right: 12px;
                                }
                                @media (max-width: 750px) {
                                    border-width: 2px;
                                    width: 40px;
                                    height: 40px;
                                    margin-right: 10px;
                                }
                                @media (max-width: 650px) {
                                    display: none;
                                }
                            }
                            > .text {
                                color: white;
                                line-height: 1.2;
                                font-size: 14px;
                                
                                .name {
                                    font-weight: 700;
                                }
                                @media (max-width: 1200px) {
                                    font-size: 13px;
                                }
                                @media (max-width: 650px) {
                                    text-align: center;
                                }
                            }
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        .play-btn {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }   
    }

    ${props => props.compactMode && css`
        .overlay-container {
            .speakers, .heading, .highlights {
                display: none !important;
            }
        }
    `}

`