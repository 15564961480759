import { FormattedPriceModel } from 'api/core/formatted-price-model';
import { PitchSuggestionsModel } from 'api/agency/agent/reonboarding/reonboarding-models';

export interface SquadPlayersResponse {
    players: PlayerItem[],
    additionalPlayers: AdditionalPlayerItem[];
}
export interface PlayerItem {
    playerId: number,
    playerName: string,
    firstPositionCode: string,
    isRecommended: true,
    parentSquad: {
        id: number,
        name: string,
        league: string,
        country: string,
        logo: string
    },
    currentSquad: {
        id: number,
        name: string,
        league: string,
        country: string,
        logo: string,
    },
    askingPrice: FormattedPriceModel | null;
    sellLoan: FormattedPriceModel | null;
}

export interface AdditionalPlayerItem {
    id: number;
    name: string;
    shortName: string;
}

export enum TransferTypeEnum {
    Transfer = 1,
    Loan = 2,
    Both = 3
}

export interface PlayerToLoanModel {
    request: boolean;
    monthlyLoan: number | null;
}

export interface PlayerToBuyModel {
    request: boolean;
    askingPrice: number | null;
    sellOn: number | null;
}

export interface PlayerTransferTerms {
    playerId: number;
    toLoan: PlayerToLoanModel;
    toBuy: PlayerToBuyModel;
}

export interface PitchSuggestionsResponse {
    players: PitchSuggestionsModel[];
    totalPitchSuggestions: number;
    totalClubs: number;
}

export interface PitchSuggestionsItem {
    playerId: number;
    transferType: TransferTypeEnum;
}
