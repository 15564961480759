import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration'
import { getPlayerSearchFilterCriteria } from 'store/playerSearch/reducers/playerSearchFilterReducer';
import { PlayerSearchService, SuggestionModel, SuggestionType } from 'api/player/player-search'

import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import history from 'history-accessor'
import { ActionType, PageType } from 'constants/enums';
import { PlayerPointsService } from 'api/player/player-points';

class PlayerPointsSearchState {
    keyword: string;
    suggestions: Array<SuggestionModel>;
    isLoading: boolean;
}

const defaultState: PlayerPointsSearchState = {
    keyword: '',
    suggestions: [],
    isLoading: false
}

const stateController = new StateController<PlayerPointsSearchState>(
    'FA_PLAYER_POINTS/SEARCH_BAR',
    defaultState
)

class Actions {

    public static onKeywordChange(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({ keyword: keyword }))
        }
    }

    // Needed to dispatch from inside the component before debounced function will start fetching data
    public static setIsLoadingState(isLoading: boolean) {
        return dispatch => {
            dispatch(stateController.setState({ isLoading }))
        }
    }

    public static onSuggestionsFetchRequested() {
        return async (dispatch, getState: () => AppState) => {

            const state = getState()
            let filterCriteria = getPlayerSearchFilterCriteria(state)
            filterCriteria = { ...filterCriteria, keyword: state.playerPoints.searchBar.keyword, isForFaPlayerPoints: true }
            const { squadId, clubId } = state.auth

            try {
                dispatch(stateController.setState({ isLoading: true }));
                let data = await PlayerPointsService.getPlayerAutosuggestionsAnonymous(filterCriteria);

                dispatch(userActivityInsert({
                    PageName: 'GBE Calculator',
                    Message: `Search for: ${state.playerPoints.searchBar.keyword}`,
                    ActionType: ActionType.Search,
                    PageType: PageType.GBE_Calculator
                }))

                // Remove clubs from the list
                data = data.filter(item => item.suggestionType == SuggestionType.Player);

                dispatch(stateController.setState({ suggestions: data }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }


        }
    }

    public static onSuggestionSelect(playerId: number) {
        return (dispatch) => {
            history.push(`/player-points/${playerId}`)
        }
    }

    public static onSuggestionsClear() {
        return dispatch => {
            dispatch(stateController.setState({ suggestions: [] }))
        }
    }
    
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerPointsSearchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};