import axios from 'axios-config';
import { PerformanceData } from './models/performance-data'
import { UserNotification } from './models/user-notification'
import { HomePagePitchPlayersModel} from './models/pitch-players-modal';
import { DismissType } from 'pages/landing-page/redux/performance-data.controller';
import { PlayersTrendingModel } from './models/players-trending';
import { AgencyItem } from './models/agency-card-alert';

export class LandingPageService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loadPerformanceData(currentClubId: number, currentUserId: number, currentSquadId: number): Promise<PerformanceData> {
        const { data } = await axios.get(
            `api/LandingPage/GetPerformanceData/${currentClubId}/${currentUserId}/${currentSquadId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async dismissPerformance(type: DismissType) {
        const res = await axios.post(
            `api/LandingPage/DismissPerformance/${type}`,
            this.axiosConfig
        );
        return res;
    }

    public static async loadMySquadPlayers(currentClubId: number, currentUserId: number, currentSquadId: number): Promise<any> {
        const { data } = await axios.get(
            `api/LandingPage/GetMySquadPlayers/${currentClubId}/${currentUserId}/${currentSquadId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async loadMySquadRecommendation(): Promise<HomePagePitchPlayersModel> {
        const { data } = await axios.get(
            `api/LandingPage/GetMySquadPlayersWithRecommendations`,
            this.axiosConfig
        )
        return data;
    }

    public static async pitchPlayer(userId: number, playerAdId: number, amount: number, playerId: number, squadId?: number, squadName?: string): Promise<any> {
        await axios.post(
            `api/ClubAds/PitchPlayer`,
            { userId: userId, playerAdId: playerAdId, amount: amount, playerId: playerId, squadName: squadName, squadId: squadId },
            this.axiosConfig
        );
    }

    public static async declineAd(playerId: number, playerAdId: number): Promise<any> {
        await axios.post(
            `api/Recommend/DeclinePlayerAd`,
            {playerId: playerId, playerAdId: playerAdId},
            this.axiosConfig
        );
    }

    public static async savePlayerAvailabilities(availabilities: Array<any>): Promise<any> {
        const { data } = await axios.patch(
            `api/PlayerAvailability/Multiple`,
            availabilities,
            this.axiosConfig
        )
        return data;
    }

    public static async getUnreadNotification(): Promise<UserNotification> {
        const { data } = await axios.get(
            `api/UserNotification/GetFirstUnreadNotificationByUserId`,
            this.axiosConfig
        )
        return data;
    }

    public static async markNotificationAsRead(): Promise<UserNotification> {
        const { data } = await axios.post(
            `api/UserNotification/MarkFirstNotificationAsRead`,
            {},
            this.axiosConfig
        )
        return data;
    }

    public static async activeSquadsFetch(showOnlyPrimary: boolean): Promise<any> {
        const { data } = await axios.get(
            `api/Competition/GetCompetitionWithActiveSquads?showOnlyPrimary=${showOnlyPrimary}`,
            this.axiosConfig
        )
        return data
    }

    public static async getIsSpeakerCardDismissed(): Promise<any> {
        const { data } = await axios.get(`api/LandingPage/ShowSpeakerCard`, this.axiosConfig)
        return data;
    }

    public static async dismissSpeakerCard(): Promise<any> {
        const { data } = await axios.get(`api/LandingPage/DismissSpeakerCard`, this.axiosConfig)
        return data;
    }

    public static async getIsPlayerTrendingDismissed(): Promise<any> {
        const { data } = await axios.get(
            `api/PlayersTrending/`,
            this.axiosConfig
        )
        return data
    }

    public static async getTrendingPlayers(): Promise<Array<PlayersTrendingModel>> {
        const { data } = await axios.get(
            `api/PlayersTrending/GetPlayers/`,
            this.axiosConfig
        )
        return data
    }

    public static async dismissPlayersTrending(): Promise<any> {
        const { data } = await axios.post(
            `api/PlayersTrending/Dismiss`,
            { panelWasDismissed: true },
            this.axiosConfig
        )
        return data
    }

    public static async showSavedPitches(): Promise<boolean> {
        const { data } = await axios.get(
            `api/LandingPage/ShowSavedPitches`,
            this.axiosConfig
        )
        return data
    }

    public static async dismissSavedPitches(): Promise<boolean> {
        const { data } = await axios.post(
            `api/LandingPage/DismissSavedPitches`,
            {},
            this.axiosConfig
        )
        return data
    }

     public static async getAgencyCards(): Promise<Array<AgencyItem>> {
        const { data } = await axios.get(
            `api/LandingPage/GetAgencyCards`,
            this.axiosConfig
        );
        return data;
    }

    public static async dismissAgencyCard(agencyToClubNotificationId: number): Promise<any>{
        await axios.post(
            `api/LandingPage/DismissAgencyCard`,
            agencyToClubNotificationId,
            this.axiosConfig
        )
    }

    public static async sendWelcomeMessageToAgency(agencyId: number): Promise<any>{
        await axios.post(
            `api/LandingPage/SendWelcomeMessageToAgency`,
            agencyId,
            this.axiosConfig
        )
    }

    public static async getAreasWithCompetitions(userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/Competition/GetAreasWithCompetitions/${userId}?withSubSquads=true&checkPermission=false`,
            this.axiosConfig
        )
        return data
    }
}
