import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PageType } from 'constants/enums';

export class MyAgentActivityService {
    private static page: string = 'My Page';
    private static containers = {
        myAgent: 'My Agent',
        removeAgent: 'My Agent - remove agent',
        contractDetails: 'My Agent - contract details',
        addAgent: 'My Agent - add agent'
    }

    private static buildCommand(container: string, action: string, playerId: number, agencyId: number) {
        return {
            Message: action,
            PlayerId: playerId,
            AgencyId: agencyId,
            PageName: `${this.page}${container != null ? ` [${container}]` : ''}`,
            PageType: PageType.PlayerPage
        }
    }

    public static openedMyAgent(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.myAgent, 'Opened', playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static removeMyAgent(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.myAgent, 'Remove Agent', playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static removeMyAgentConfirmed(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.removeAgent, 'Confirmed', playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static removeMyAgentCancelled(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.removeAgent, 'Cancelled', playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static openedContractDetails(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.contractDetails, 'Opened Contract Details', playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static agreementType(playerId: number, agencyId: number, agreementType: string) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.contractDetails, `Agreement Type: ${agreementType}`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static expiryDate(playerId: number, agencyId: number, date: string) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.contractDetails, `Expiry Date: ${date}`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static noSpecificExpiryDate(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.contractDetails, 'No Specific Expiry Date', playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }

    public static openedAddAgent(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Opened`, playerId, null);
            dispatch(userActivityInsert(command));
        }
    }

    public static addAgentSelectedAgent(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Selected Agent`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentAgreementType(playerId: number, agencyId: number, agreement: string) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Agreement Type: ${agreement}`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentExpiryDate(playerId: number, agencyId: number, date: string) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Expiry Date: ${date}`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentNoSpecificExpiryDate(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `No Specific Expiry Date`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentConfirmedRep(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Confirmed Representation Agreement`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentRecommend(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Recommend`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentAddedAgent(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Added Agent`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
    public static addAgentDone(playerId: number, agencyId: number) {
        return (dispatch) => {
            let command = this.buildCommand(this.containers.addAgent, `Done`, playerId, agencyId);
            dispatch(userActivityInsert(command));
        }
    }
}
