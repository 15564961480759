import * as redux from 'redux';

import * as myAdsController from './my-ads.controller';
import * as pitchController from './pitch/pitch.controller';
import * as plusPitchController from './pitch/plus-pitch.controller';
import * as superPitchController from './pitch/super-pitch.controller';
import * as commonController from './common.controller'
import * as savedPitchesTab from './saved-pitches.controller';

export class PlayerAdPageState {
    myAds: myAdsController.State;
    pitch: pitchController.State;
    plusPitch: plusPitchController.State;
    superPitch: superPitchController.State;
    common: commonController.State;
    savedPitchesTab: savedPitchesTab.State;
}

export function combineReducers() {

    let reducers = {
        myAds: myAdsController.Reducer,
        pitch: pitchController.Reducer,
        plusPitch: plusPitchController.Reducer,
        superPitch: superPitchController.Reducer,
        common: commonController.Reducer,
        savedPitchesTab: savedPitchesTab.Reducer
    };

    return redux.combineReducers(reducers)
} 