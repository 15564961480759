import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
import SpeakerCard from './speaker-card/speaker-card';

const eventSettings: EventInfoModel = {
    shortTitle: "Madrid Summit",
    title: "Madrid Summit",
    shortEventDates: "21st-22nd March 2022",
    eventDates: "21st-22nd March 2022",
    location: "Madrid, Spain",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null, //MadridSpeakerCard,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        userActivityPageName: "Home [TransferRoom Summit]",
        description: "Register for our next event and book meetings with decision makers from up to 300 clubs."
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: false
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: true
    }
};

export default eventSettings;