import * as types from 'store/actionTypes';
import orderBy from 'lodash/orderBy';

const initialState = {
    list: null,
};

export default (s = initialState, action) => {
    switch (action.type) {
        case types.EXPIRED_ADS_FETCH.SUCCESS:
            const ads = orderBy(action.payload.data.ads, [ad => new Date(ad.lastUpdated)], 'desc');
            action.payload.data.ads = ads;
            return {
                ...s,
                list: action.payload.data,
            };

        case types.EXPIRED_ADS_UPDATE.SUCCESS:
            const updatedItems = s.list.ads.map(item => {
                    if(item.id === action.payload.data.id){
                        return { ...item, lastUpdated:action.payload.data.lastUpdated, updated: true  }
                    }
                    return item;
                  });
            return {
                ...s,
                list: {
                    valid:true,
                    ads: updatedItems
                }
            };
        case types.EXPIRED_ADS_REMOVE.SUCCESS:
            return {
                ...s,
                list: {
                    valid:true,
                    ads: s.list.ads.filter(x => x.id !== action.payload.data)
                }
            };
        default:
            return s;
    }
};

export const getExpiredAdsList = state => {
    return state.expiredAds.list;
};
