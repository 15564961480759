import axios from 'axios-config';
import { GetSquadsResponse, FilterOptions } from './models/get-squads-response';
import { GetHistoryResponse } from './models/get-history-response';
import { GetSquadInfoResponse } from './models/get-users-response';

export class AdminClubReonboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getSquads(dataOptions: FilterOptions): Promise<GetSquadsResponse> {
        const { data } = await axios.post(
            `api/AdminClubReonboarding/GetSquads`,
            dataOptions,
            this.axiosConfig
        );
        return data;
    }

    public static async getHistory(squadId: number): Promise<GetHistoryResponse> {
        const { data } = await axios.get(
            `api/AdminClubReonboarding/GetHistory/${squadId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSquadInfo(squadId: number): Promise<GetSquadInfoResponse> {
        const { data } = await axios.get(
            `api/AdminClubReonboarding/GetSquadInfo/${squadId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async activate(userId: number) {
        await axios.post(
            `api/AdminClubReonboarding/Activate`,
            { userId },
            this.axiosConfig
        );
    }

    public static async deactivate(reonboardingId: number) {
        await axios.post(
            `api/AdminClubReonboarding/Deactivate`,
            { reonboardingId },
            this.axiosConfig
        );
    }
}