import { asyncActionType } from 'store/actionTypes';
import moment from "moment";

const initialState = {
    generalInfo: null,
    responsesMostPitches: null,
    successRate: null,
    recentActivity: null,
    log: null,
};

export default (pitchInsight = initialState, action) => {
    switch (action.type) {
        case PITCH_INSIGHT_LOAD_GENERAL_INFO_HIDDEN.SUCCESS:
        case PITCH_INSIGHT_LOAD_GENERAL_INFO.SUCCESS: {
            return {
                ...pitchInsight,
                generalInfo: action.payload.data,
            };
        }

        case PITCH_INSIGHT_LOAD_RESPONSES_MOST_PITCHED.SUCCESS: {
            return {
                ...pitchInsight,
                responsesMostPitches: action.payload.data,
            };
        }
        case PITCH_INSIGHT_SUCCESS_RATE_DATA.SUCCESS: {
            return {
                ...pitchInsight,
                successRate: action.payload.data,
            };
        }

        case PITCH_INSIGHT_LOAD_RECENT_ACTIVITY.SUCCESS: {
            return {
                ...pitchInsight,
                recentActivity: action.payload.data,
            };
        }

        case PITCH_INSIGHT_LOAD_LOG.SUCCESS: {
            return {
                ...pitchInsight,
                log: action.payload.data,
            };
        }

        case PITCH_INSIGHT_TEST_INTEREST.SUCCESS: {
            let pitchId = action.payload.request.payload;
            let now = new Date();

            let recentActivity = null;

            if (pitchInsight.recentActivity !== null) {
                recentActivity = [
                    ...pitchInsight.recentActivity.map(item => {
                        if (item.pitchInfo.pitchId === pitchId) {
                            return {
                                ...item,
                                pitchInfo: {
                                    ...item.pitchInfo,
                                    interestStatus: PitchInterestStatus.Sent,
                                    interestStatusAt: now.toISOString(),
                                    interestStatusAtFormatted: moment(now.toISOString()).format('D MMM YYYY')
                                },
                            };
                        }
                        return item;
                    }),
                ];
            }

            let playerResponses = null;

            if (pitchInsight.responsesMostPitches !== null) {
                playerResponses = {
                    ...pitchInsight.responsesMostPitches,
                    pitchReactionDataList: [
                        ...pitchInsight.responsesMostPitches.pitchReactionDataList.map(
                            item => {
                                if (
                                    item.pitchReactionDetailList
                                        .map(item => item.pitchId)
                                        .indexOf(pitchId) !== -1
                                ) {
                                    return {
                                        ...item,
                                        pitchReactionDetailList: item.pitchReactionDetailList.map(
                                            inner => {
                                                if (inner.pitchId === pitchId) {
                                                    return {
                                                        ...inner,
                                                        interestStatus:
                                                            PitchInterestStatus.Sent,
                                                    };
                                                }
                                                return inner;
                                            },
                                        ),
                                    };
                                }
                                return item;
                            },
                        ),
                    ],
                };
            }

            return {
                ...pitchInsight,
                responsesMostPitches: playerResponses,
                recentActivity: recentActivity,
            };
        }

        default:
            return pitchInsight;
    }
};

export const PitchInsightPlans = {
    None: 0,
    Pro: 1,
    Premium: 2,
    Vip: 3,
};

export const getIsGlobalInfoLoading = state => {
    return state.requests.some(r => r.type === PITCH_INSIGHT_LOAD_GENERAL_INFO);
};

export const getIsGlobalInfoPIScreenLoading = state => {
    return state.requests.some(
        r => r.type === PITCH_INSIGHT_LOAD_GENERAL_INFO_HIDDEN,
    );
};

export const getIsMostResponsesLoading = state => {
    return state.requests.some(
        r => r.type === PITCH_INSIGHT_LOAD_RESPONSES_MOST_PITCHED,
    );
};

export const getIsSuccessRateIsLoading = state => {
    return state.requests.some(r => r.type === PITCH_INSIGHT_SUCCESS_RATE_DATA);
};

export const getIsLogLoading = state => {
    return state.requests.some(r => r.type === PITCH_INSIGHT_LOAD_LOG);
};

export const getIsRecentActivitiesLoading = state => {
    return state.requests.some(
        r => r.type === PITCH_INSIGHT_LOAD_RECENT_ACTIVITY,
    );
};

const path = state => state.clubAds.pitchInsight;
export const getGeneralInfo = state => path(state).generalInfo;
export const getResponsesMostPitched = state =>
    path(state).responsesMostPitches;

export const salesClub = [
    42706,
    42707,
    42708,
    42709,
    50000,
    50001,
    50002,
    60002,
    60003,
    60004,
    60005,
    200000,
    200100,
    210000,
    210004,
    223000,
    223001,
    223002,
    223003,
    223004
];

export const getIsAtLeastOnePitchExist = state => {
    if (salesClub.indexOf(state.auth.squadId) !== -1) return true;
    if (path(state).generalInfo === null) return false;
    return path(state).generalInfo.pitchesSent > 0; //&& state.auth.pitchInsightsSubscription === 3
};

export const getIsProType = state => {
    // let plan = (path(state).generalInfo || {}).plan;
    let plan = state.auth.pitchInsightsSubscription;
    return plan === PitchInsightPlans.Pro || plan === PitchInsightPlans.None;
};

export const getIsVipType = state => {
    // let plan = (path(state).generalInfo || {}).plan;
    let plan = state.auth.pitchInsightsSubscription;
    return plan === PitchInsightPlans.Vip;
};

export const getIsPremium = state => {
    let plan = state.auth.pitchInsightsSubscription;
    return plan === PitchInsightPlans.Premium;
    // return (path(state).generalInfo || {}).plan === PitchInsightPlans.Premium;
};

export const PitchInterestStatus = {
    Soon: 0,
    Available: 1,
    Sent: 2,
    None: 3,
};

export const PITCH_INSIGHT_LOAD_GENERAL_INFO = asyncActionType(
    'PITCH_INSIGHT/PITCH_INSIGHT_LOAD_GENERAL_INFO',
);
export const PITCH_INSIGHT_LOAD_GENERAL_INFO_HIDDEN = asyncActionType(
    'PITCH_INSIGHT/PITCH_INSIGHT_LOAD_GENERAL_INFO__HIDDEN',
);

export const PITCH_INSIGHT_LOAD_RESPONSES_MOST_PITCHED = asyncActionType(
    'PITCH_INSIGHT/LOAD_RESPONSES_MOST_PITCHED',
);
export const PITCH_INSIGHT_TEST_INTEREST = asyncActionType(
    'PITCH_INSIGHT/PITCH_INSIGHT_TEST_INTEREST',
);

export const PITCH_INSIGHT_LOAD_RECENT_ACTIVITY = asyncActionType(
    'PITCH_INSIGHT/PITCH_INSIGHT_LOAD_RECENT_ACTIVITY',
);

export const PITCH_INSIGHT_SUCCESS_RATE_DATA = asyncActionType(
    'PITCH_INSIGHT/PITCH_INSIGHT_SUCCESS_RATE_DATA',
);
export const PITCH_INSIGHT_LOAD_LOG = asyncActionType(
    'PITCH_INSIGHT/PITCH_INSIGHT_LOAD_LOG',
);
