import config from "../config";

export const openPublicProfile = (agencyName) => {
    window.open(`${config.profileWebAppUrl}/agency-profile/${encodeURIComponent(agencyName)}`);
}

export const openCoachProfile = (mostSimilarToId: number, mostSimilarTo: string) => {
    window.open(`/coach-profile/${mostSimilarToId}/${mostSimilarTo}`, '_blank')
}

export const openCoachProfileById = (mostSimilarToId: number) => {
    window.open(`/coach-profile/${mostSimilarToId}`, '_blank')
}

export const openProfileLogginedUser = (agencyName) => {
    window.open(`/agency-profile/${encodeURIComponent(agencyName)}`, '_blank');
}

export const openPlayerProfileById = (mostSimilarToId: number) => {
    window.open(`/profile/${mostSimilarToId}`, '_blank')
}
export const openAgencyProfile = (agencyName: string) => {
    window.open(`/agency-profile/${encodeURIComponent(agencyName)}`, '_blank');
}
