import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { userPaths } from 'routes/paths';
import { Actions as StepActions, RegistrationState } from './confirm.controller';
import { Actions as Step2Actions, AttendeeOption } from './step-2.controller';
import AgencyOnboardingService from "api/agency/club/onboarding/onboarding.servise";
import { setupAgencyFeature } from 'store/auth/auth.actions'
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import { Actions as DateSelectorActions } from './date-selector.controller';
import { EventParticipationDaysEnum } from 'api/virtual-summit/models/summit-invitationl';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE } from 'store/actionTypes';

export class User {
    firstName: string;
    lastName: string;
    club: string;
    title: string;
    email: string;
    phoneCodeAreaId: number | null;
    mobilePhone: string;

    eventParticipationDays: EventParticipationDaysEnum[]
}

export class Validation {
    titleValid: boolean;
    isPhoneCodeAreaIdValid: boolean;
    phoneValid: boolean;
}

class ConfirmStep1State {
    user: User;
    validation: Validation;
    isLoading: boolean;
}

const defaultState: ConfirmStep1State = {
    user: { 
        club: "", 
        email: "", 
        firstName: "", 
        lastName: "", 
        phoneCodeAreaId: null, 
        mobilePhone: "", 
        title: "",
        eventParticipationDays: []
     },
    validation: { isPhoneCodeAreaIdValid: true, phoneValid: true, titleValid: true },
    isLoading: false
}

const stateController = new StateController<ConfirmStep1State>(
    "VIRTUAL_SUMMIT/CONFIRM_STEP1",
    defaultState
);

class Actions {
    public static getData(redirect: (url: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(StepActions.setAgentFeatureFromState());
            dispatch(stateController.setState({ isLoading: true }))
            let data = await VirtualSummitService.getConfirmRegistrationData(appState.auth.userId);

            let attendees: Array<AttendeeOption> = data.extraAttendees.map(i => {
                return { id: i.id, name: i.name, disabled: i.isRegistered, order: i.order, positionTitle: i.position }
            });
            dispatch(Step2Actions.setAttendeesInfo(data.extraAttendees));
            dispatch(Step2Actions.setOptionsList(attendees));

            let result: User = {
                firstName: data.user.firstName, 
                lastName: data.user.lastName, 
                club: data.user.clubName,
                title: data.user.positionName, 
                email: data.user.email, 
                phoneCodeAreaId: data.user.phoneCodeAreaId, 
                mobilePhone: data.user.phone,
                eventParticipationDays: []
            }
            dispatch(stateController.setState({ user: result, isLoading: false }));

            if (data.isUserRegistered && !getState().virtualSummit.confirmPage.fromEvent) {
                redirect(userPaths.events);
            }
            if (data.isUserRegistered) {
                dispatch(StepActions.setStep(RegistrationState.Attendees))
            }
        }
    }

    public static setUserData = (data: User) => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let { userId, eventId } = appState.auth;
            dispatch(stateController.setState({ isLoading: true, validation: { isPhoneCodeAreaIdValid: true, phoneValid: true, titleValid: true } }));

            const { registration } = getEventInfo(eventId);
            const { activateAgencyFeature, hasDateSelectorStep } = registration;
            if (activateAgencyFeature) {
                await AgencyOnboardingService.setUpAgencyFeatures();
                dispatch(setupAgencyFeature());
            }
            if(hasDateSelectorStep){
                dispatch(DateSelectorActions.setParticipationDays(data));
            }
            await VirtualSummitService.setConfirmRegistrationData(userId, data);
            dispatch(stateController.setState({ isLoading: false }));
            dispatch({ type: USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE });

            dispatch(userActivityInsert({
                Message:  'Confirmed Registration',
                PageName: 'Event [Registration]',
                PageType: PageType.Events,
                EventId: eventId
            }));

            dispatch(StepActions.setStep(RegistrationState.InviteClubs));
        }
    }

    public static setTitle = (title: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep1;
            dispatch(stateController.setState({ user: { ...substate.user, title: title } }));
        }
    }

    public static setPhone = (phone: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep1;
            dispatch(stateController.setState({ user: { ...substate.user, mobilePhone: phone } }));
        }
    }
    public static setPhoneAreaId = (areaId: number) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep1;
            dispatch(stateController.setState({ user: { ...substate.user, phoneCodeAreaId: areaId } }));
        }
    }

    public static setValidation = (fields: Validation) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ validation: fields }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmStep1State as State,
    Actions as Actions,
    stateController as Controller
};



