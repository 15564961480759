import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { AgencyModel, PlayerMarkedInterestAreaSourceEnum, RecommendationModel } from 'api/player-v2/models';
import { Actions as CommonActions, AgencyFinderScreen } from './common.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAuth } from 'store/auth/authReducer';

class State {
    isLoading: boolean;
    recommendations: RecommendationModel[];
    selectedCountriesIds: number[];
    selectedLeaguesIds: number[];
    selectedLeaguesNames: string[];
    isScrolledAgencyList: boolean
    isRecommendedAgenciesLoading: boolean;
    recommendedAgencies: AgencyModel[];
    allRecommendedAgenciesIds: number[];
}

const defaultState: State = {
    isLoading: false,
    recommendations: [],
    selectedCountriesIds: [],
    selectedLeaguesIds: [],
    selectedLeaguesNames: [],
    isScrolledAgencyList: false,
    isRecommendedAgenciesLoading: false,
    recommendedAgencies: [],
    allRecommendedAgenciesIds: [],
}

const stateController = new StateController<State>("PLAYERV2/AGENCY-FINDER/RECOMMENDATIONS", defaultState);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static setAllAgencyRecommendationsIds() {
        return async (dispatch, getState: () => AppState) => {
            const recommendedAgencies = getState().playerV2.agencyFinder.recommendations.recommendedAgencies;
            const selectedAgencies = recommendedAgencies.filter(item => item.playerRepresentationStatus !== null);
            let allRecommendedAgenciesIds = [];

            recommendedAgencies.forEach(item => {
                if (!selectedAgencies.includes(item)) {
                    allRecommendedAgenciesIds.push(item.agencyId);
                }
            });

            dispatch(stateController.setState({ allRecommendedAgenciesIds: allRecommendedAgenciesIds }));
        }
    }

    public static loadRecommendations() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            const playerId = getState().auth.playerId;
            dispatch(userActivityInsert({
                Message: 'Viewed Recommendations Page',
                PageName: 'Agent Finder [Recommendations]',
                PlayerId: playerId,
            }))

            try {
                const data = await AgencyFinderService.getRecommendations();
                dispatch(stateController.setState({ recommendations: data }));
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static onSelectCountry(areaId: number) {
        return (dispatch, getState: () => AppState) => {
            const countriesIds = Selectors.getSelectedCountriesIds(getState());
            const areaRecommendations = Selectors.getRoot(getState()).recommendations.find(item => item.areaId === areaId);
            const countryName = areaRecommendations.countryName;
            const areaRecommendationsIds = areaRecommendations.leagues.map(league => league.id);
            const playerId = getState().auth.playerId;

            if (countriesIds.includes(areaId)) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    selectedCountriesIds: prevState.selectedCountriesIds.filter(x => x !== areaId),
                    selectedLeaguesIds: prevState.selectedLeaguesIds.filter(x => !areaRecommendationsIds.includes(x))
                })));
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    selectedCountriesIds: [...prevState.selectedCountriesIds, areaId],
                    selectedLeaguesIds: [...prevState.selectedLeaguesIds, ...areaRecommendationsIds],
                })));

                AgencyFinderService.savePlayerInterestedMarkets([areaId], PlayerMarkedInterestAreaSourceEnum.Recommendation);
                dispatch(userActivityInsert({
                    Message: `Opened Country List: ${countryName}`,
                    PageName: 'Agent Finder [Recommendations]',
                    PlayerId: playerId,
                }))
            }
        }
    }

    public static loadRecommendedAgencies() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isRecommendedAgenciesLoading: true }));
            try {
                const competitionIds = Selectors.getSelectedLeaguesIds(getState())
                var data = await AgencyFinderService.getRecommendedAgencies(competitionIds);
                dispatch(stateController.setState({ recommendedAgencies: data }));
                dispatch(Actions.setAllAgencyRecommendationsIds());
            } finally {
                dispatch(stateController.setState({ isRecommendedAgenciesLoading: false }));
            }
        }
    }

    public static goBackToDefault() {
        return (dispatch, getState: () => AppState) => {
            dispatch(CommonActions.setScreen(AgencyFinderScreen.CompetitionRecommendations));
        }
    }

    public static goBackToCompetitionRecommendations() {
        return (dispatch, getState: () => AppState) => {
            dispatch(CommonActions.clearSelection());
            dispatch(CommonActions.setScreen(AgencyFinderScreen.CompetitionRecommendations));
        }
    }

    public static viewedAgencyRecommendationsScreen() {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState())

            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Country Agency Recommendations]',
                Message: `Viewed Agency Recommendations Page`,
                PlayerId: playerId,
            }))
        }
    }

    public static startScrollAgencyList() {
        return (dispatch, getState: () => AppState) => {
            const isScrolled = getState().playerV2.agencyFinder.recommendations.isScrolledAgencyList;
            const playerId = getState().auth.playerId;

            if (!isScrolled) {
                dispatch(userActivityInsert({
                    PageName: 'Agent Finder [Country Agency Recommendations]',
                    Message: `Scrolled Recommended Agencies List`,
                    PlayerId: playerId,
                }))
            }

            dispatch(stateController.setState({ isScrolledAgencyList: true }));
        }
    }
    public static sendUserActivity(message: string, agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;

            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Country Agency Recommendations]',
                Message: message,
                PlayerId: playerId,
                AgencyId: agencyId,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.agencyFinder.recommendations;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isRecommendedAgenciesLoading = (state: AppState) => Selectors.getRoot(state).isRecommendedAgenciesLoading;
    public static getRecommendations = (state: AppState) => Selectors.getRoot(state).recommendations;
    public static getSelectedLeaguesIds = (state: AppState) => Selectors.getRoot(state).selectedLeaguesIds;
    public static getSelectedCountriesIds = (state: AppState) => Selectors.getRoot(state).selectedCountriesIds;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



