export const madridAttendes = [
    { id: '33906', name: 'Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '12768', name: 'Atlético Madrid', logo: '679_Atlético_Madrid.png' },
    { id: '38580', name: 'Valencia', logo: 'logo_1715_Valencia_CF.png' },
    { id: '18833', name: 'SL Benfica', logo: 'logo_1673_SL_Benfica.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2', name: 'AS Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '16', name: 'Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },
    { id: '13440', name: 'Boca Juniors', logo: '12057_Boca_Juniors.png' },
    { id: '15349', name: 'River Plate', logo: 'logo_2577_Club_Atletico_River_Plate.png' },
    { id: '6160', name: 'CSKA Moscow',  logo: '13886_CSKA_Moskva.png' },
    { id: '773', name: 'Getafe', logo: 'logo_1728_Getafe_CF.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' }, 
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' }, 
    { id: '40356', name: 'Werder Bremen', logo: 'logo_1269_SV_Werder_Bremen.png' },
    
    

    { id: '25359', name: 'Celta Vigo', logo: 'logo_1718_Celta_de_Vigo.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiakos_Piraeus.png' },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '65', name: 'Wolverhampton', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '60', name: 'Newcastle', logo: 'logo_Newcastle_United.png' },
    { id: '67', name: 'West Ham', logo: '1633_West_Ham_United.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '7972', name: 'LOSC Lille', logo: 'logo_1974_LOSC_Lille.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '70', name: 'Brighton', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '850', name: 'Stoke', logo: 'logo_1129_Stoke_City.png' },
    { id: '71', name: 'Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '12761', name: 'Espanyol', logo: 'logo_1722_RCD_Espanyol_Barcelona.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '7421', name: 'Granada', logo: 'logo_1732_Granada_CF.png' },
    { id: '19254', name: 'Fluminense', logo: '59046_Fluminense_SC.png' },


    { id: '5', name: 'Internacinale', logo: 'logo_1836_Inter_Milan.png' },
    { id: '69', name: 'Watford', logo: 'logo_Watford_FC.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png' },
    { id: '10744', name: 'Cruz Azul', logo: '15408_Cruz_Azul.png' },
    { id: '26367', name: 'Santos Laguna', logo: 'logo_2268_Santos_Laguna.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '8', name: 'Sampdoria', logo: 'logo_1842_UC_Sampdoria.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '10', name: 'Bologna', logo: 'logo_1845_Bologna_FC_1909.png' },
    { id: '18', name: 'SPAL', logo: 'logo_1850_SPAL_2013.png' },
    { id: '17', name: 'Genoa', logo: 'logo_1843_Genoa_CFC.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '13058', name: 'Feyenoord', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '41692', name: 'Seattle Sounders', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },

// 4th row
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },
    { id: '22011', name: 'Puebla FC', logo: 'logo_2282_Puebla_FC.png' },
    { id: '25285', name: 'Levante UD', logo: 'logo_Levante_UD.png' },
    { id: '31172', name: 'HSC Montpellier', logo: '3787_Montpellier.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '22196', name: 'SpVgg Greuther Fürth', logo: 'logo_Greuther_Furth.jpg' },
    { id: '23588', name: 'SC Heerenveen', logo: 'logo_1537_SC_Heerenveen.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' }, 
    { id: '20', name: 'US Sassuolo', logo: '3315_Sassuolo.png' }, 
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' }, 
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '5635', name: 'Dynamo Kyiv', logo: 'logo_2136_Dynamo_Kyiv.png' },
    { id: '5626', name: 'Enosis Neon Paralimniou', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
    { id: '22240', name: 'PAOK Thessaloniki', logo: 'logo_2021_PAOK_Thessaloniki.png' },
    { id: '3695', name: 'SK Slavia Prague', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: 'logo_2101_Los_Angeles_Galaxy.png' },
    { id: '1559', name: 'FC Cincinnati', logo: '37892_Cincinnati.png' },


    { id: '36694', name: 'APOEL Nicosa', logo: 'logo_2173_APOEL_Nicosia.png' },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '29455', name: 'Nottingham Forrest', logo: 'logo_1152_Nottingham_Forest.png' },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    { id: '72', name: 'Huddersfield', logo: 'logo_1137_Huddersfield_Town.png' },
    { id: '13193', name: 'Girona', logo: 'logo_Girona_FC.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '39333', name: 'Sporting Gijon', logo: 'logo_1730_Sporting_Gijon.png' },
    { id: '26867', name: 'Peñarol', logo: '15633_Peñarol.png' },
    { id: '174', name: 'Talleres de Cordoba', logo: 'logo_2588_Club_Atletico_Talleres.png' },
    { id: '7526', name: 'Lugo', logo: 'logo_1740_CD_Lugo.png' },
    { id: '33927', name: 'Almeria', logo: 'logo_UD_Almeria.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '13114', name: 'Real Zaragoza', logo: 'logo_1742_Real_Zaragoza.png' },


    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '5109', name: 'PEC Zwolle', logo: 'logo_1543_PEC_Zwolle.png' },
    { id: '9385', name: 'Sporting Kansas City', logo: 'logo_2121_Sporting_Kansas_City.png' },
    { id: '19', name: 'Frosinone', logo: 'logo_Frosinone_Calcio.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '39599', name: 'FC Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '28158', name: 'Aalborg', logo: 'logo_Aalborg_BK.png' },
    { id: '23752', name: 'Haugesund', logo: 'logo_1474_FK_Haugesund.png' },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '7819', name: 'Sarpsborg', logo: 'logo_1472_Sarpsborg_08_FF.png' },
    { id: '11087', name: 'Brann', logo: 'logo_SK_Brann.png' },
    { id: '12612', name: 'Hammarby', logo: 'logo_1441_Hammarby_IF.png' },
    { id: '6677', name: 'Östersunds', logo: 'logo_1439_Ostersunds_FK.png' },
    { id: '11544', name: 'Grasshoppers Zurich', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '25519', name: 'Rapid Wien', logo: 'logo_1943_Rapid_Vienna.png' },
    { id: '1177', name: 'OFI Crete', logo: 'logo_2747_OFI_Crete.png' },
    { id: '42063', name: 'Újpest FC', logo: 'logo_1633_Ujpest_FC.png' },
]

export const londonAttendes = [
    { id: '33906', name: 'Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '40356', name: 'Werder Bremen', logo: 'logo_1269_SV_Werder_Bremen.png' },
    { id: '38580', name: 'Valencia', logo: 'logo_1715_Valencia_CF.png' },
    { id: '41692', name: 'Seattle Sounders', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2', name: 'AS Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '16', name: 'Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },
    { id: '13440', name: 'Boca Juniors', logo: '12057_Boca_Juniors.png' },
    { id: '15349', name: 'River Plate', logo: 'logo_2577_Club_Atletico_River_Plate.png' },
    { id: '6160', name: 'CSKA Moscow',  logo: '13886_CSKA_Moskva.png' },
    { id: '773', name: 'Getafe', logo: 'logo_1728_Getafe_CF.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' }, 
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' }, 
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '25359', name: 'Celta Vigo', logo: 'logo_1718_Celta_de_Vigo.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '65', name: 'Wolverhampton', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '60', name: 'Newcastle', logo: 'logo_Newcastle_United.png' },
    { id: '67', name: 'West Ham', logo: 'logo_1127_West_Ham_United.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '7972', name: 'LOSC Lille', logo: 'logo_1974_LOSC_Lille.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '70', name: 'Brighton', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '850', name: 'Stoke', logo: 'logo_1129_Stoke_City.png' },
    { id: '71', name: 'Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '12761', name: 'Espanyol', logo: 'logo_1722_RCD_Espanyol_Barcelona.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '7421', name: 'Granada', logo: 'logo_1732_Granada_CF.png' },
    { id: '19254', name: 'Fluminense', logo: '59046_Fluminense_SC.png' },
    { id: '5', name: 'Internacinale', logo: 'logo_1836_Inter_Milan.png' },
    { id: '69', name: 'Watford', logo: 'logo_Watford_FC.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png' },
    { id: '10744', name: 'Cruz Azul', logo: '15408_Cruz_Azul.png' },
    { id: '26367', name: 'Santos Laguna', logo: 'logo_2268_Santos_Laguna.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '8', name: 'Sampdoria', logo: 'logo_1842_UC_Sampdoria.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '10', name: 'Bologna', logo: 'logo_1845_Bologna_FC_1909.png' },
    { id: '18', name: 'SPAL', logo: 'logo_1850_SPAL_2013.png' },
    { id: '17', name: 'Genoa', logo: 'logo_1843_Genoa_CFC.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '13058', name: 'Feyenoord', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '45', name: 'Manchester United', logo: null }, // 'logo_1120_Manchester_United.png'
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },
    { id: '36694', name: 'APOEL Nicosa', logo: 'logo_2173_APOEL_Nicosia.png' },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '29455', name: 'Nottingham Forrest', logo: 'logo_1152_Nottingham_Forest.png' },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    { id: '72', name: 'Huddersfield', logo: 'logo_1137_Huddersfield_Town.png' },
    { id: '13193', name: 'Girona', logo: 'logo_Girona_FC.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '39333', name: 'Sporting Gijon', logo: 'logo_1730_Sporting_Gijon.png' },
    { id: '26867', name: 'Peñarol', logo: '15633_Peñarol.png' },
    { id: '174', name: 'Talleres de Cordoba', logo: 'logo_2588_Club_Atletico_Talleres.png' },
    { id: '7526', name: 'Lugo', logo: 'logo_1740_CD_Lugo.png' },
    { id: '33927', name: 'Almeria', logo: 'logo_UD_Almeria.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '13114', name: 'Real Zaragoza', logo: 'logo_1742_Real_Zaragoza.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '5109', name: 'PEC Zwolle', logo: 'logo_1543_PEC_Zwolle.png' },
    { id: '5159', name: 'Groningen', logo: 'logo_FC_Groningen.png' },
    { id: '19', name: 'Frosinone', logo: 'logo_Frosinone_Calcio.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '39599', name: 'FC Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '28158', name: 'Aalborg', logo: 'logo_Aalborg_BK.png' },
    { id: '23752', name: 'Haugesund', logo: 'logo_1474_FK_Haugesund.png' },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '7819', name: 'Sarpsborg', logo: 'logo_1472_Sarpsborg_08_FF.png' },
    { id: '11087', name: 'Brann', logo: 'logo_SK_Brann.png' },
    { id: '12612', name: 'Hammarby', logo: 'logo_1441_Hammarby_IF.png' },
    { id: '6677', name: 'Östersunds', logo: 'logo_1439_Ostersunds_FK.png' },
    { id: '11544', name: 'Grasshoppers Zurich', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '25519', name: 'Rapid Wien', logo: 'logo_1943_Rapid_Vienna.png' },
    { id: '1177', name: 'OFI Crete', logo: 'logo_2747_OFI_Crete.png' },
]


export const attendees_4 = [
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    {
        id: '65',
        name: 'Wolverhampton Wanderers',
        logo: 'logo_Wolverhampton_Wanderers.png',
    },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    {
        id: '20454',
        name: 'Legia Warszawa',
        logo: 'logo_1594_Legia_Warszawa.png',
    },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    {
        id: '11544',
        name: 'Grasshopper',
        logo: 'logo_1936_Grasshopper_Club_Zurich.png',
    },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    {
        id: '21425',
        name: 'St. Gallen',
        logo: 'logo_1935_FC_St._Gallen_1879.png',
    },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '1177', name: 'OFI', logo: 'logo_2747_OFI_Crete.png' },
    { id: '16210', name: 'AEL', logo: 'logo_2179_AEL_Limassol.png' },
    { id: '51', name: 'Burnley', logo: 'logo_1136_Burnley_FC.png' },
    { id: '42094', name: 'Bochum', logo: 'logo_1284_VfL_Bochum.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '32443', name: 'Badalona', logo: 'logo_1802_CF_Badalona.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    {
        id: '39333',
        name: 'Sporting Gijón',
        logo: 'logo_1730_Sporting_Gijon.png',
    },
    {
        id: '6863',
        name: 'Rayo Vallecano',
        logo: 'logo_1734_Rayo_Vallecano.png',
    },
    { id: '4474', name: 'Burgos', logo: 'logo_1781_Burgos_CF.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    {
        id: '12686',
        name: 'Nordsjælland',
        logo: 'logo_1413_FC_Nordsjaelland.png',
    },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '7427', name: 'Osijek', logo: 'logo_2166_NK_Osijek.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '17668', name: 'Famalicão', logo: 'logo_FC_Famalicao.png' },
    { id: '38234', name: 'Sabadell', logo: 'logo_1800_CE_Sabadell.png' },
    {
        id: '70',
        name: 'Brighton & Hove Albion',
        logo: 'logo_1135_Brighton_And_Hove_Albion.png',
    },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '19138', name: 'Mallorca', logo: 'logo_RCD_Mallorca.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },
    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '39309', name: 'Valletta', logo: 'logo_3540_Valletta_FC.png' },
    { id: '15280', name: 'Córdoba', logo: 'logo_1745_Cordoba_CF.png' },
    {
        id: '37893',
        name: 'Skënderbeu Korçë',
        logo: 'logo_2451_KF_Skenderbeu.png',
    },
    { id: '16042', name: 'Kukësi', logo: 'logo_2454_FK_Kukesi.png' },
    {
        id: '174',
        name: 'Talleres Córdoba',
        logo: 'logo_2588_Club_Atletico_Talleres.png',
    },
    { id: '11640', name: 'Cornellà', logo: 'logo_1799_UE_Cornella.png' },
    { id: '11717', name: 'MOL Vidi FC', logo: 'logo_1629_Videoton_FC.png' },
    { id: '33927', name: 'Almería', logo: 'logo_UD_Almeria.png' },
    {
        id: '38623',
        name: 'Pogoń Szczecin',
        logo: 'logo_1599_Pogon_Szczecin.png',
    },
    { id: '427', name: 'SV Roeselare', logo: 'logo_1588_KSV_Roeselare.png' },
    {
        id: '9794',
        name: 'Zagłębie Sosnowiec',
        logo: 'logo_1612_Zaglebie_Sosnowiec.png',
    },
    {
        id: '6051',
        name: 'Real Valladolid',
        logo: 'logo_1735_Real_Valladolid_CF.png',
    },
    {
        id: '71',
        name: 'AFC Bournemouth',
        logo: 'logo_1134_AFC_Bournemouth.png',
    },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '2', name: 'Roma', logo: 'logo_1835_AS_Roma.png' },
    {
        id: '22827',
        name: 'Hajduk Split',
        logo: 'logo_2165_HNK_Hajduk_Split.png',
    },
];

export const attendees_3 = [
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    {
        id: '65',
        name: 'Wolverhampton Wanderers',
        logo: 'logo_Wolverhampton_Wanderers.png',
    },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    {
        id: '67',
        name: 'West Ham United',
        logo: 'logo_1127_West_Ham_United.png',
    },

    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '2', name: 'Roma', logo: 'logo_1835_AS_Roma.png' },

    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },

    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    {
        id: '24698',
        name: 'Cambridge United',
        logo: 'logo_1201_Cambridge_United.png',
    },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },

    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },

    { id: '26191', name: 'Bury', logo: 'logo_1168_Bury_FC.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    {
        id: '20454',
        name: 'Legia Warszawa',
        logo: 'logo_1594_Legia_Warszawa.png',
    },
    { id: '32843', name: 'Shanghai SIPG', logo: 'logo_1640_Shanghai_SIPG.png' },
    {
        id: '11544',
        name: 'Grasshopper',
        logo: 'logo_1936_Grasshopper_Club_Zurich.png',
    },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    {
        id: '21425',
        name: 'St. Gallen',
        logo: 'logo_1935_FC_St._Gallen_1879.png',
    },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    {
        id: '12686',
        name: 'Nordsjælland',
        logo: 'logo_1413_FC_Nordsjaelland.png',
    },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '7427', name: 'Osijek', logo: 'logo_2166_NK_Osijek.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    {
        id: '70',
        name: 'Brighton & Hove Albion',
        logo: 'logo_1135_Brighton_And_Hove_Albion.png',
    },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },

    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '11717', name: 'MOL Vidi FC', logo: 'logo_1629_Videoton_FC.png' },
    {
        id: '32987',
        name: 'Bristol Rovers',
        logo: 'logo_1184_Bristol_Rovers.png',
    },
    {
        id: '71',
        name: 'AFC Bournemouth',
        logo: 'logo_1134_AFC_Bournemouth.png',
    },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    {
        id: '6051',
        name: 'Real Valladolid',
        logo: 'logo_1735_Real_Valladolid_CF.png',
    },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '40409', name: 'Oxford United', logo: 'logo_1166_Oxford_United.png' },
    {
        id: '15872',
        name: 'Wigan Athletic',
        logo: 'logo_1163_Wigan_Athletic.png',
    },
    { id: '3740', name: 'Kortrijk', logo: 'logo_KV_Kortrijk.png' },
    { id: '14821', name: 'København', logo: 'logo_FC_Copenhagen.png' },
    {
        id: '7354',
        name: 'Forest Green Rovers',
        logo: 'logo_Forest_Green_Rovers.png',
    },
    {
        id: '29469',
        name: 'Dinamo Zagreb',
        logo: 'logo_2163_GNK_Dinamo_Zagreb.png',
    },
    {
        id: '38623',
        name: 'Pogoń Szczecin',
        logo: 'logo_1599_Pogon_Szczecin.png',
    },
    {
        id: '5306',
        name: 'Raków Częstochowa',
        logo: 'logo_1622_Rakow_Czestochowa.png',
    },
    { id: '31136', name: 'Heracles', logo: 'logo_1542_Heracles_Almelo.png' },
    {
        id: '29529',
        name: 'Cheltenham Town',
        logo: 'logo_1197_Cheltenham_Town.png',
    },
    {
        id: '30586',
        name: 'Sparta Praha',
        logo: 'logo_2050_AC_Sparta_Praha.png',
    },
    { id: '12758', name: 'Tubize', logo: 'logo_1593_AFC_Tubize.png' },
    {
        id: '6249',
        name: 'Bayer Leverkusen',
        logo: 'logo_1260_Bayer_04_Leverkusen.png',
    },
    { id: '12139', name: 'Honvéd', logo: 'logo_1632_Budapest_Honved_FC.png' },
    {
        id: '29529',
        name: 'Cheltenham Town',
        logo: 'logo_1197_Cheltenham_Town.png',
    },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '14958', name: 'Coventry City', logo: 'logo_1188_Coventry_City.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '5626', name: 'Enosis', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
];