export const testimonials = [
    {
        fullName: 'Jose Castro',
        position: 'Director',
        club: 'FC Porto',
        img: require('media/images/events/2023-august-virtual/jose-castro.png'),
        text: '"Great events, great organization, improvement is notorious."',
    },
    {
        fullName: 'Andreas Georgson',
        position: 'Technical Director',
        club: 'Malmö FF',
        img: require('media/images/events/2023-august-virtual/andreas-georgson.png'),
        text: '"The TransferRoom Summit was a new and interesting way of networking in football for me. It came across as an innovative way of making football communication processes more effective and economically smart."',
    },
    {
        fullName: 'Khaled El-Ahmad',
        position: 'CEO & Sporting Director',
        club: 'Barnsley FC',
        img: require('media/images/events/2023-august-virtual/khaled-el-ahmad.png'),
        text: '"I have been attending since 2017 and the event and product only gets better. It is an essential platform and event to have."',
    },
    {
        fullName: 'Luiz Muzzi',
        position: 'General Manager',
        club: 'Orlando City SC',
        img: require('media/images/events/2023-august-virtual/luiz-muzzi.png'),
        text: '"TransferRoom Summits provide us with a unique opportunity to meet a variety of football stakeholders at a dedicated setting. We have been attending for a few editions now and every event provides us with invaluable connections/contacts that end up benefiting many different areas of our Club."',
    },
    {
        fullName: 'Neil McGuinness',
        position: 'Director of Scouting & Player Recruitment',
        club: 'Columbus Crew',
        img: require('media/images/events/2023-august-virtual/neil-mcguinness.png'),
        text: '"TransferRoom is a refreshingly simple way of communicating directly with the right people in a smooth and streamlined way. It allows you to save time on all sides and provides concrete information from the source."',
    },
    {
        fullName: 'Tom Eilers',
        position: 'Football Operations',
        club: 'SV Darmstadt 98',
        img: require('media/images/events/2023-august-virtual/tom-eilers.png'),
        text: '"After a number of offline events the virtual summits gave us the possibility to network in trans continental markets."',
    }
]