

export const madridTestimonials = [
    {
        fullName: 'Lucy Rushton',
        position: 'General Manager and Head of Technical Recruitment & Analysis',
        club: 'DC United',
        img: require('media/images/events/2022-16-june-madrid/lucy-rushton.png'),
        text: 'People are open and honest about the players they have available and the money involved. That is the most important thing because it’s not always that way in football.',
    },
    {
        fullName: 'Rob Newman',
        position: 'Head of Recruitment',
        club: 'West Ham United',
        img: require('media/images/events/2022-16-june-madrid/rob-newman.png'),
        text: 'You’ve got an environment where there’s 250-300 clubs in one place. That would take you over a year to visit all those clubs and build up valuable contacts.',
    },
    {
        fullName: 'Miguel Torrecilla',
        position: 'Sporting Director',
        club: 'Real Zaragoza',
        img: require('media/images/events/2022-16-june-madrid/miguel-torrecilla.png'),
        text: 'Having 200 clubs in one place together is an excellent opportunity to improve face-to-face relationships to find different solutions for the future of our clubs.',
    },
    {
        fullName: 'Santiago San Roman',
        position: 'Sporting Director',
        club: 'Club Necaxa',
        img: require('media/images/events/2022-16-june-madrid/santiago-san-roman.png'),
        text: 'It is a very important event that has opened many doors for us to complete transactions, generate value and connect with clubs from our continent but also from all over the world.',
    },
    {
        fullName: 'Alex Kroes',
        position: 'Owner',
        club: 'Go Ahead Eagles',
        img: require('media/images/events/2022-16-june-madrid/alex-kroes.png'),
        text: 'TransferRoom fits our sustainable model to work smarter.',
    },
    {
        fullName: 'Tom Van den Abbeele',
        position: 'Sporting Director',
        club: 'Royal Excelsior Virton',
        img: require('media/images/events/2022-16-june-madrid/tom-van-den-abbeele.png'),
        text: 'It is the ideal way to meet the colleagues you will be dealing with sooner or later.',
    },
    {
        fullName: 'Necati Özdeniz',
        position: 'CEO',
        club: 'Altinordu',
        img: require('media/images/events/2022-16-june-madrid/necati-ozdeniz.png'),
        text: 'We can now contact very, very big clubs, which normally you cannot get access to. The TransferRoom Summit gives us the opportunity to meet with really big clubs, from Italy, Spain, Germany.',
    },
    {
        fullName: 'John Williams',
        position: 'Sporting Director',
        club: 'Amiens SC',
        img: require('media/images/events/2022-16-june-madrid/john-williams.png'),
        text: 'The only event that brings clubs together in the same place to develop our network and ultimately our own business.',
    },
]