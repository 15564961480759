export const UPDATE_HEADER_STYLE = 'app/styles/header/UPDATE_STYLE'
export const SAVE_PREVENT_RECALCULATION_STATE = 'app/styles/header/SAVE_HEADER_EXPANDED_STATE'
export const RECALCULATE_HEADER_HEIGHT = 'app/styles/header/RECALCULATE_HEADER_HEIGHT'
export const DISPOSE_HEADER_RECALCULATION = 'app/styles/header/DISPOSE_HEADER_RECALCULATION'

export const updateStyle = (payload) => ({
    type: UPDATE_HEADER_STYLE,
    payload: {
        ...payload
    }
})

export const recalculateHeaderHeight = () => (dispatch, getState) => {
    const canRecalculate = !getState().commonStyles.header.preventRecalculation
    if (canRecalculate) {
        dispatch({type: RECALCULATE_HEADER_HEIGHT})
        setTimeout(() => dispatch({type: DISPOSE_HEADER_RECALCULATION}), 0)
    }
}

export const savePreventRecalculationState = (boolean) => ({
    type: SAVE_PREVENT_RECALCULATION_STATE,
    payload: boolean
})