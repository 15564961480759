import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { getAuthUserId } from 'store/auth/authReducer';
import { UserReaction } from 'api/user-reaction/user-reaction-service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class State {
    shouldShow: boolean;
    isSending: boolean;
    isDone: boolean;
    score: number;
    message: string;
}

const defaultState: State = {
    shouldShow: false,
    isSending: false,
    isDone: false,
    score: null,
    message: '',
};

const stateController = new StateController<State>(
    'AGENCY/FEEDBACK_COLLECTOR',
    defaultState,
);

class Actions {
    public static shouldCollectFeedback = () => {
        return async (dispatch, getState) => {
            try {
                let shouldShow = await UserReaction.shouldCollectFeedback(getAuthUserId(getState()));

                dispatch(stateController.setState({ shouldShow: shouldShow }));
            } catch (error) {}
        };
    };

    public static sendFeedback = (score: number, message: string) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isSending: true }));
                await UserReaction.sendFeedback(score, message);
                dispatch(userActivityInsert({
                    PageName: 'Customer Reaction',
                    Message: `Scored ${score}`,
                    PageType: PageType.CustomerReaction
                }))
                if(message) {
                    dispatch(userActivityInsert({
                        PageName: 'Customer Reaction',
                        Message: `Comment: ${message}`,
                        PageType: PageType.CustomerReaction
                    })) 
                }
    
                dispatch(stateController.setState({ isSending: false, isDone: true }));
            } catch (error) {
                dispatch(stateController.setState({ isSending: false }));
            }
        }
    };

    public static dismissFeedback = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isSending: true }));
                await UserReaction.sendFeedback();
                dispatch(userActivityInsert({
                    PageName: 'Customer Reaction',
                    Message: 'Closed',
                    PageType: PageType.CustomerReaction
                }))
                dispatch(stateController.setState({ isSending: false }));  
            } catch (error) {
                dispatch(stateController.setState({ isSending: false }));  
            }
        }
    };

    public static hidePanel = () => {
        return (dispatch) => {
            dispatch(stateController.setState({ shouldShow: false }));
        }
    };

    public static setScore = (score: number) => {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Customer Reaction',
                Message: `Selected ${score}`,
                PageType: PageType.CustomerReaction
            }))
            dispatch(stateController.setState({ score }));
        }
    };

    public static setMessage = (message: string) => {
        return (dispatch) => {
            dispatch(stateController.setState({ message }));
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.feedback;
    public static shouldCollectFeedback = (state: AppState) => Selectors.getRoot(state).shouldShow;
    public static isSending = (state: AppState) => Selectors.getRoot(state).isSending;
    public static isDone = (state: AppState) => Selectors.getRoot(state).isDone;
    public static getScore = (state: AppState) => Selectors.getRoot(state).score;
    public static getMessage = (state: AppState) => Selectors.getRoot(state).message;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};