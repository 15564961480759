import _ from 'lodash'
import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import AgencyPublicProfileService from 'api/agency/public-profile/public-profile.service'
import { getCancelTokenSource } from 'axios-config';
import { IdName } from 'api/core/id-name';

class SquadSuggestionState {
    filteredSquads: IdName[];
    isLoading: boolean;
    keyword: string;
}

const defaultState: SquadSuggestionState = {
    filteredSquads: [],
    isLoading: false,
    keyword: ''
}

const stateController = new StateController<SquadSuggestionState>("AGENCY/PUBLIC_PROFILE/SQUAD_SUGGESTION", defaultState);

class Actions {
    public static createDebounce = () => _.debounce((dispatch, keyword: string) => dispatch(Actions.loadSquads(keyword)), 1000)
    public static cancelToken = null
    public static fetchDebounced = Actions.createDebounce()

    public static loadSquads(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();

            if (Actions.cancelToken)
                Actions.cancelToken.cancel()

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState({ isLoading: true }))

                let data = await AgencyPublicProfileService.getSquads(keyword, Actions.cancelToken.token)
                dispatch(stateController.setState({ filteredSquads: data }));
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: keyword }))

            if (keyword.length > 0) {
                Actions.fetchDebounced(dispatch, keyword)
            } else {
                dispatch(Actions.clearKeyword())
            }
        }
    }

    public static clearKeywordWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: '', isLoading: false, filteredSquads: [] }))
            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) { Actions.cancelToken.cancel() }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
        }
    }

    public static clearKeyword() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearKeywordWithoutRefresh())
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.publicProfile.squadSuggestion
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SquadSuggestionState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



