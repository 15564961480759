import React, { PureComponent, RefObject } from 'react'
import debounce from 'lodash/fp/debounce'

class ClubNameOwnProps {
    clubName: string;
    shortClubName: string;
    oneDigitLengh?: number;
    maxWidth?: number;
    [key: string]: any;
}
export default class DynamicClubName extends PureComponent<ClubNameOwnProps, any> {

    ONE_DIGIT_LENGTH = this.props.oneDigitLengh ? this.props.oneDigitLengh : 6

    isMaxWidthPropExists = () => this.props.maxWidth ? true : false

    clubContainerRef: RefObject<any> = React.createRef()
    clubText: RefObject<any> = React.createRef()
    updateClubName = () => {
        const longClubName = this.props.clubName
        const shortClubName = this.props.shortClubName
        
        const containerWidth = this.isMaxWidthPropExists() ? this.props.maxWidth : ((this.clubContainerRef || {}).current.clientWidth || 0) - 5
        const longClubNameWidth = longClubName.length * this.ONE_DIGIT_LENGTH
        
        if (longClubNameWidth > containerWidth && shortClubName !== null && shortClubName !== undefined && shortClubName !== "") {
            this.clubText.current.innerText = shortClubName
        } else {
            this.clubText.current.innerText = longClubName
        }
    }
    updateClubNameBinded = this.updateClubName.bind(this)
    updateClubNameDebounced = debounce(500, this.updateClubNameBinded)
    componentDidMount() {
        window.addEventListener('resize', this.updateClubNameDebounced)
        this.updateClubName()
    }
    componentDidUpdate() {
        this.updateClubName()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateClubNameDebounced)
    }
    render() {

        const { 
            clubName, 
            shortClubName, 
            oneDigitLengh,
            ...otherProps 
        } = this.props

        return (
            <div ref={this.clubContainerRef} {...otherProps}>
                <span style={{display: 'inline-block', verticalAlign: 'text-top'}} ref={this.clubText}>{clubName}</span>
            </div>
        )
    }
}

export function getNameDependOnWidth(fullName: string, shortName: string, digitWidth: number, maxWidth: number): string {
    if ((fullName.length * digitWidth) >= maxWidth && shortName !== null && shortName !== undefined && shortName !== "") {
        return shortName
    } else {
        return fullName
    }
}