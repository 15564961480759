import { createAction } from 'redux-actions';
import createApiThunk from '../../../services/createApiThunk';
import {
    PLAYERS_DECLARE_INTEREST_SAVE,
    PLAYERS_DECLARE_INTEREST,
    PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE,
} from 'store/actionTypes';
import { getAuth } from 'store/auth/authReducer';
import { Actions } from 'pages/Profile/components/scatter-chart/redux/scatter-chart.controller';

export const playersDeclareInterest = createAction(PLAYERS_DECLARE_INTEREST);

export const playersDeclareInterestSave = (playerId, trackingObj = null) => (dispatch, getState) => {
    const { userId, squadId } = getAuth(getState());
    dispatch(Actions.declareInterestForScatter(playerId));
    const request = {
        playerId,
        type: PLAYERS_DECLARE_INTEREST_SAVE,
        method: 'post',
        url: `Players/DeclareInterestForPlayer/${playerId}/${userId}/${squadId}`,
        success: PLAYERS_DECLARE_INTEREST_SAVE.SUCCESS,
        error: PLAYERS_DECLARE_INTEREST_SAVE.ERROR,
    };
    return dispatch(createApiThunk(request, (trackingObj || null)));
};

export const playersDeclareInterestToAgentSave = (playerId, trackingObj = null) => (dispatch, getState) => {
    const request = {
        playerId,
        type: PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE,
        method: 'post',
        url: `v2/SquadAgentInterest/DeclareInterest/${playerId}/`,
        success: PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.SUCCESS,
        error: PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.ERROR,
    };
    return dispatch(createApiThunk(request, (trackingObj || null)));
};
