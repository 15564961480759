import { AppState } from "root.reducer";
import { StateController } from "utils/action-declaration";
import { AdminClubReonboardingService } from 'api/admin/club-reonboarding/club-reonboarding.service';
import { createSelector } from "reselect";
import { ClubReonboardingItemAdminStatusEnum, FilterOptions, SquadReonboardingItem } from "api/admin/club-reonboarding/models/get-squads-response";
import { UserItem } from "api/admin/club-reonboarding/models/get-users-response";
import { ReonboardingTypeEnum } from "api/admin/club-reonboarding/models/get-history-response";

class ClubReonboardingState {
    items: SquadReonboardingItem[];
    isLoading: boolean;
    filterOptions: FilterOptions;
    previousFilterSnapshot?: FilterOptions;
    pagination: {
        rowCount: number,
        pageCount: number
    };
}

const defaultFilterOptions: FilterOptions = {
    sortOrder: 'date',
    isSortOrderAscending: false,

    squadName: null,

    page: 1,
    recordsPerPage: 20,
}
const defaultState: ClubReonboardingState = {
    items: [],
    isLoading: false,
    previousFilterSnapshot: defaultFilterOptions,
    filterOptions: defaultFilterOptions,
    pagination: {
        rowCount: 1,
        pageCount: 1
    }
}

const stateController = new StateController<ClubReonboardingState>(
    'ADMIN_V2/CLUB_REONBOARDING',
    defaultState
)

class Actions {

    public static initData() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadData())
        }
    }

    public static loadData() {

        function wasFiltersChanged(state: AppState) {
            const { filterOptions, previousFilterSnapshot } = Selectors.getRoot(state);

            // we should ignore pagination changes
            const filterOptionsWithoutPagination = {
                ...filterOptions,
                page: defaultState.filterOptions.page,
                recordsPerPage: defaultState.filterOptions.recordsPerPage,
            }
            const defaultFilterOptionsWithoutPagination = {
                ...previousFilterSnapshot,
                page: defaultState.filterOptions.page,
                recordsPerPage: defaultState.filterOptions.recordsPerPage,
            }
            const wasFiltersChanged = JSON.stringify(filterOptionsWithoutPagination) !== JSON.stringify(defaultFilterOptionsWithoutPagination)
            return wasFiltersChanged
        }

        return async (dispatch, getState: () => AppState) => {
            try {
                const shouldDisposePagination = wasFiltersChanged(getState())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    previousFilterSnapshot: {
                        ...prevState.filterOptions,
                    },
                    filterOptions: {
                        ...prevState.filterOptions,
                        page: shouldDisposePagination ? 1 : prevState.filterOptions.page
                    },
                })))

                const { filterOptions } = Selectors.getRoot(getState());

                dispatch(stateController.setState({ isLoading: true }))
                const data = await AdminClubReonboardingService.getSquads(filterOptions)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: data.output,
                    pagination: {
                        pageCount: data.pageCount,
                        rowCount: data.rowCount
                    }
                })))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }

    }

    public static paginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    page: pageNumber,
                    recordsPerPage: pageSize
                }
            })))

            dispatch(Actions.loadData())
        }
    }

    public static applySearch() {
        return dispatch => dispatch(Actions.loadData())
    }
    public static disposeFilters() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
            dispatch(Actions.applySearch())
        }
    }

    public static onSort(key: string = 'date', order: 'ascend' | 'descend' | undefined) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    sortOrder: key,
                    isSortOrderAscending: order === 'ascend' ? true : false
                }
            })))
            dispatch(Actions.loadData())
        }
    }

    public static onChangeSquadName(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                squadName: value
            }
        })))
    }

    public static updateSquadItem(squadId: number, userItem: UserItem, status: ClubReonboardingItemAdminStatusEnum) {
        return (dispatch, getState: () => AppState) => {
            const { items } = Selectors.getRoot(getState());
            const item = items.find(x => x.squad.id === squadId);
            if(item){
                if(userItem.isActive){
                    item.lastDisplayedAt = userItem.lastDisplayedAt;
                }
                item.status = status;
                dispatch(stateController.setState({ items: [...items] }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.admin.clubReonboarding.reonboarding;
    public static selectItems = createSelector(
        Selectors.getRoot,
        state => state.items
    )
    public static selectFilterOptions = createSelector(
        Selectors.getRoot,
        state => state.filterOptions
    )
    public static selectPagination = createSelector(
        Selectors.getRoot,
        state => state.pagination
    )
    public static selectIsLoading = createSelector(
        Selectors.getRoot,
        state => state.isLoading
    )
    public static selectCurrentPage = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => filterOptions.page
    )
    public static selectRecordsPerPage = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => filterOptions.recordsPerPage
    )

    public static selectIsAllFiltersSetToDefault = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => JSON.stringify(defaultState.filterOptions) === JSON.stringify(filterOptions)
    )
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ClubReonboardingState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};