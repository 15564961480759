import * as redux from 'redux';
import * as reonboarding from './reonboarding.controller';
import * as playersAvailable from './players-available-stepper/players-available-stepper.controller';
import * as pitchingPlayers from './pitching-players-stepper/pitching-players-stepper.controller';


export class ReonboardingRootState {
    reonboarding: reonboarding.State;
    playersAvailable: playersAvailable.State;
    pitchingPlayers: pitchingPlayers.State;
}

export function combineReducers() {

    let reonboardingRoot = {
        reonboarding: reonboarding.Reducer,
        playersAvailable: playersAvailable.Reducer,
        pitchingPlayers: pitchingPlayers.Reducer,
    };

    return redux.combineReducers(reonboardingRoot)
}