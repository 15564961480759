import axios from 'axios-config';
import { SquadPlayersResponse, PlayerTransferTerms, PitchSuggestionsResponse, PitchSuggestionsItem } from './model';
import { RecommendedPitchesRequest } from 'api/agency/agent/reonboarding/reonboarding-models';

export class ReonboardingService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async setReonboarding() {
        const { data } = await axios.post(`api/ClubReonboarding/Reonboarding`, this.axiosConfig)
        return data;
    }

    public static async setRecruitPlayers() {
        const { data } = await axios.post(`api/ClubReonboarding/RecruitPlayers`, this.axiosConfig)
        return data;
    }

    public static async setExit() {
        const { data } = await axios.post(`api/ClubReonboarding/Exit`, this.axiosConfig)
        return data;
    }

    public static async remindMeLater() {
        const { data } = await axios.post(`api/ClubReonboarding/RemindMeLater`, this.axiosConfig)
        return data;
    }

    public static async getSquadPlayers(): Promise<SquadPlayersResponse> {
        const { data } = await axios.get(
            `api/ClubReonboarding/GetSquadPlayers`,
            this.axiosConfig
        );
        return data;
    }

    public static async setAvailability(request: PlayerTransferTerms[]): Promise<PitchSuggestionsResponse> {
        const { data } = await axios.post(
            `api/ClubReonboarding/SetAvailability`,
            { items: [ ...request ] },
            this.axiosConfig
        )
        return data;
    }

    public static async getPitchSuggestions(request: PitchSuggestionsItem[]): Promise<PitchSuggestionsResponse> {
        const { data } = await axios.post(
            `api/ClubReonboarding/GetPitchSuggestions`,
            { items: [ ...request ] },
            this.axiosConfig
        )
        return data;
    }

    public static async createPitches(request: RecommendedPitchesRequest): Promise<any> {
        const { data } = await axios.post(
            `api/ClubReonboarding/CreatePitches`,
            request,
            this.axiosConfig
        )
        return data;
    }
}
