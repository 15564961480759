import './Spinner.scss';
import React, { Component } from 'react';

class OwnProps {
    className?: string;
    green?: boolean;
    style?: {[key: string]: any}
}
export default class Spinner extends Component<OwnProps, any> {
    render() {
        return (
            <div className={`tr-spinner ${this.props.className && this.props.className}`} style={this.props.style ? this.props.style : null}>
                <i className={`fas fa-spinner fa-spin tr-spinner__icon--fast ${this.props.green && 'green'}`} />
            </div>
        );
    }
}
