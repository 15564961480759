import {
    CLUB_ADS_MY_PITCHES_FETCH,
    CLUB_ADS_CLEAR_MY_PITCHES,
} from 'store/actionTypes';
import { combineReducers } from 'redux';
import { arrayToObject } from '../../../../services/utils';

const byId = (clubAdsById = {}, action) => {
    switch (action.type) {
        case CLUB_ADS_MY_PITCHES_FETCH.SUCCESS: {
            const ads = action.payload.data.output;
            return arrayToObject(ads);
        }
        case CLUB_ADS_CLEAR_MY_PITCHES: {
            return {};
        }
        default:
            return clubAdsById;
    }
};

const allIds = (ids = [], action) => {
    switch (action.type) {
        case CLUB_ADS_MY_PITCHES_FETCH.SUCCESS: {
            const ads = action.payload.data.output;
            return ads.map(ad => ad.id);
        }
        case CLUB_ADS_CLEAR_MY_PITCHES: {
            return [];
        }
        default:
            return ids;
    }
};

// const filter = (filter = {}, action) => {
//     switch (action.type) {
//         case MY_PITCHES_SET_FILTER: {
//             return {
//                 ...filter,
//                 ...action.payload,
//             };
//         }
//         default:
//             return filter;
//     }
// };

export default combineReducers({
    byId,
    allIds,
    // filter,
});
