import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import {
    PitchSuggestionsModel,
    PitchSuggestionsModelExtended
} from 'api/agency/agent/reonboarding/reonboarding-models';
import { ReonboardingService } from 'api/club/reonboarding/reonboarding.service';
import { PlayerAdTypeEnumModel } from 'api/agency/club/onboarding/onboarding-models';
import { validatePriceInput } from "../../../agency/authorized-agent/reonboarding-flow/create-pitches-stepper/helpers/create-pitches.helpers";
import { formatWithCommas } from 'services/utils';
import { Actions as ReonboardingActions } from '../reonboarding.controller';
import { Selectors as PlayersAvailableSelectors } from '../players-available-stepper/players-available-stepper.controller';
import { userPaths } from 'routes/paths';
import { AdditionalPlayerItem, TransferTypeEnum } from 'api/club/reonboarding/model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAuthSquadId } from 'store/auth/authReducer';
import { Selectors as ReonboardingPopupSelectors } from 'pages/club/reonboarding-popup/reonboarding-popup.controller'
import { getOpenReonboardingPopupActionType } from 'pages/club/reonboarding-popup/utils';

export enum PitchingPlayersSteps {
    PitchOpportunitiesStep = 1,
    SelectAdditionalPlayersStep = 2,
    PitchAdditionalOpportunitiesStep = 3,
    Done = 4,
}

class State {
    isLoading: boolean;
    processing: boolean;
    activeStep: PitchingPlayersSteps;
    playersPitches: PitchSuggestionsModelExtended[];
    additionalPitchSuggestions: PitchSuggestionsModel[];
    currentPlayerIndex: number;
    currentPlayer: PitchSuggestionsModelExtended;
    isRemovedPitchExists: boolean;
    isEditing: boolean;
    editingPlayerAdId: number | null;
    sentClubsIds: number[];
    additionalPlayers: AdditionalPlayerItem[];
    selectedAdditionalPlayersIds: number[]
}

const defaultState: State = {
    isLoading: false,
    processing: false,
    activeStep: PitchingPlayersSteps.PitchOpportunitiesStep,
    playersPitches: [],
    additionalPitchSuggestions: [],
    currentPlayerIndex: 0,
    currentPlayer: {
        playerId: null,
        playerName: '',
        pitchSuggestions: []
    },
    isRemovedPitchExists: false,
    isEditing: false,
    editingPlayerAdId: null,
    sentClubsIds: [],
    additionalPlayers: [],
    selectedAdditionalPlayersIds:[]
}

const stateController = new StateController<State>(
    'CLUB/REONBOARDING-FLOW/PITCHING-PLAYERS',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static init() {
        return (dispatch, getState: () => AppState) => {
            const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());
            const additionalPlayers = PlayersAvailableSelectors.getRoot(getState()).additionalPlayers;

            const pitchSuggestions = !isAdditionalPlayersSuggestionsStep
                ? PlayersAvailableSelectors.getRoot(getState()).pitchSuggestions
                : Selectors.getRoot(getState()).additionalPitchSuggestions;

            const playersPitchesExtended = pitchSuggestions.map(playerPitch => {
                const suggestionExtended = playerPitch.pitchSuggestions.map(pitchSuggestion => {
                    const suggestionsExtended = pitchSuggestion.suggestions.map((x, index) => ({
                        ...x,
                        id: index,
                        isCustom: false,
                        isEdit: false,
                        isSelected: x.isRecommended
                    }))
                    return {
                        ...pitchSuggestion,
                        suggestions: [...suggestionsExtended, {
                            id: pitchSuggestion.suggestions.length,
                            isCustom: true,
                            isEdit: false,
                            isRecommended: false,
                            isSelected: false,
                            amount: {
                                price: '',
                                formattedComma: '',
                                formattedKMB: '',
                            }
                        }]
                    }
                })
                return {
                    ...playerPitch,
                    pitchSuggestions: [...suggestionExtended]
                }
            });

            if (!isAdditionalPlayersSuggestionsStep) {
                dispatch(stateController.setState({
                    currentPlayerIndex: 0,
                    currentPlayer: playersPitchesExtended[0] ? playersPitchesExtended[0] : defaultState.currentPlayer,
                    playersPitches: playersPitchesExtended,
                    additionalPlayers: [...additionalPlayers]
                }));
                dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Suggestions]'));
            } else {
                dispatch(stateController.setState({
                    currentPlayerIndex: 0,
                    currentPlayer: playersPitchesExtended[0] ? playersPitchesExtended[0] : defaultState.currentPlayer,
                    playersPitches: playersPitchesExtended,
                    additionalPlayers: []
                }));
                dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Suggestions for Other Players]'));
            }
        }
    }

    public static setActiveStep(step: PitchingPlayersSteps) {
        return (dispatch) => {
            dispatch(stateController.setState({ activeStep: step }));
            dispatch(ReonboardingActions.disposeScrollingEffect());
        }
    }


    public static removePlayerPitch(pitchId: number) {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const squadId = getAuthSquadId(getState());
            const removedPitch = currentPlayer.pitchSuggestions.find(i => i.playerAd.id === pitchId);
            const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: currentPlayer.pitchSuggestions.filter(i => i.playerAd.id !== pitchId)
                },
                isRemovedPitchExists: true
            }));

            dispatch(userActivityInsert({
                PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players]' : 'Re-Onboarding [Pitch Suggestions]',
                Message: `Removed Ad (${removedPitch?.playerAd?.createdBySquad?.name}, ${removedPitch?.playerAd?.type === PlayerAdTypeEnumModel.Buy ? 'Buy' : 'Loan' }, ${removedPitch?.playerAd?.positionName}, ${removedPitch?.playerAd?.amount.price} ${removedPitch?.playerAd?.currency.name})`,
                PlayerId: currentPlayer.playerId,
                ClubId: squadId
            }));
        }
    }

    public static goToNextPlayer() {
        return (dispatch, getState: () => AppState) => {
            const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
            const playersPitches = Selectors.getPlayersPitches(getState());

            if (currentPlayerIndex + 1 > playersPitches.length) {
                return;
            }

            dispatch(stateController.setState({
                currentPlayerIndex: currentPlayerIndex + 1,
                currentPlayer: playersPitches[currentPlayerIndex + 1],
                isRemovedPitchExists: false
            }));
        }
    }

    public static skipPlayer() {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
            const playersPitches = Selectors.getPlayersPitches(getState());
            const additionalPlayers = Selectors.getAdditionalPlayers(getState());
            const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());
            const isSentPitchExists = Selectors.getRoot(getState()).sentClubsIds.length > 0;

            if (currentPlayerIndex + 1 === playersPitches.length) {
                if (additionalPlayers.length > 0 && !isAdditionalPlayersSuggestionsStep) {
                    dispatch(Actions.setActiveStep(PitchingPlayersSteps.SelectAdditionalPlayersStep));
                    dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Other Players]'));
                } else {
                    dispatch(Actions.setActiveStep(PitchingPlayersSteps.Done));
                    dispatch(ReonboardingActions.toggleCongratulationScreen(true));
                    if (isSentPitchExists) {
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations]'));
                    } else {
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations - No Pitches]'));
                    }
                }
            } else {
                dispatch(Actions.goToNextPlayer());
            }

            dispatch(userActivityInsert({
                PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players]' : 'Re-Onboarding [Pitch Suggestions]',
                Message: 'Skipped',
                PlayerId: currentPlayer.playerId,
            }));
        }
    }

    public static sendPitchAndContinue() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ processing: true }));
                const squadId = getAuthSquadId(getState());
                const substate = Selectors.getRoot(getState());
                const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
                const playersPitches = Selectors.getPlayersPitches(getState());
                const currentPlayer = Selectors.getCurrentPlayer(getState());
                const additionalPlayers = Selectors.getAdditionalPlayers(getState());
                const sentClubsIdsState = [...substate.sentClubsIds]
                const uniquePlayerClubIds = Array.from(new Set(currentPlayer.pitchSuggestions.map(item => item.playerAd.createdBySquad.id)));
                const uniqueSentClubsIds = Array.from(new Set([...sentClubsIdsState, ...uniquePlayerClubIds]));
                const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());
                const isSentPitchExists = Selectors.getRoot(getState()).sentClubsIds.length > 0;

                const prepareRequestData = {
                    playerId: currentPlayer.playerId,
                    pitches: currentPlayer.pitchSuggestions.map((pitchSuggestion) => {
                        const selectedSuggestion = pitchSuggestion.suggestions.find(x => x.isSelected);
                        const selectedSuggestionPrice = selectedSuggestion.amount.price.toString().replace(/\,/g,'');
                        return {
                            playerAdId: pitchSuggestion.playerAd.id,
                            amount: Number(selectedSuggestionPrice)
                        }
                    })
                }

                await ReonboardingService.createPitches(prepareRequestData);

                currentPlayer.pitchSuggestions.forEach((pitchSuggestion) => {
                    const selectedSuggestion = pitchSuggestion.suggestions.find(x => x.isSelected);

                    dispatch(userActivityInsert({
                        PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players]' : 'Re-Onboarding [Pitch Suggestions]',
                        Message: `Pitched ${currentPlayer.playerName} (${pitchSuggestion.playerAd?.createdBySquad?.name}, ${pitchSuggestion?.playerAd?.type === PlayerAdTypeEnumModel.Buy ? 'Buy' : 'Loan' }, ${pitchSuggestion?.playerAd?.positionName}, ${selectedSuggestion.amount.price} ${pitchSuggestion.playerAd.currency.name})`,
                        PlayerId: currentPlayer.playerId,
                        ClubId: squadId
                    }));
                });

                if (currentPlayerIndex + 1 === playersPitches.length) {
                    if (additionalPlayers.length > 0 && !isAdditionalPlayersSuggestionsStep) {
                        dispatch(Actions.setActiveStep(PitchingPlayersSteps.SelectAdditionalPlayersStep));
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Other Players]'));
                    } else {
                        dispatch(Actions.setActiveStep(PitchingPlayersSteps.Done));
                        dispatch(ReonboardingActions.toggleCongratulationScreen(true));
                        if (isSentPitchExists) {
                            dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations]'));
                        } else {
                            dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Suggestions for Other Players - No More Opportunities]'));
                        }
                    }
                } else {
                    dispatch(Actions.goToNextPlayer());
                }
                dispatch(stateController.setState({ sentClubsIds: [ ...uniqueSentClubsIds ] }));
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }

    public static continueWithoutPitch() {
        return async (dispatch, getState: () => AppState) => {
            const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
            const playersPitches = Selectors.getPlayersPitches(getState());
            const additionalPlayers = Selectors.getAdditionalPlayers(getState());
            const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const isSentPitchExists = Selectors.getRoot(getState()).sentClubsIds.length > 0;

            if (currentPlayerIndex + 1 === playersPitches.length) {
                if (additionalPlayers.length > 0 && !isAdditionalPlayersSuggestionsStep){
                    dispatch(Actions.setActiveStep(PitchingPlayersSteps.SelectAdditionalPlayersStep));
                    dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Other Players]'));
                } else {
                    dispatch(Actions.setActiveStep(PitchingPlayersSteps.Done));
                    dispatch(ReonboardingActions.toggleCongratulationScreen(true));
                    if (isSentPitchExists) {
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations]'));
                    } else {
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations - No Pitches]'));
                    }
                }
            } else {
                dispatch(Actions.goToNextPlayer());
            }

            dispatch(userActivityInsert({
                PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players - No More Opportunities]' : 'Re-Onboarding [Pitch Suggestions - No More Opportunities]',
                Message: 'Next ',
                PlayerId: currentPlayer.playerId
            }));
        }
    }

    public static selectSalary(playerAdId: number, salaryId: number, isMobile?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const squadId = getAuthSquadId(getState());
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());
            const isEditing = Selectors.isEditing(getState());
            const isEditableOption = !!currentPlayer.pitchSuggestions
                .find(suggestion => suggestion.playerAd.id === playerAdId).suggestions
                .find(x => x.id === salaryId && x.isCustom);

            if (isEditing) {
                dispatch(Actions.finishEditing());
            }

            const pitchSuggestionsUpdated = currentPlayer.pitchSuggestions.map(pitchSuggestion => {
                if (pitchSuggestion.playerAd.id === playerAdId) {
                    return {
                        ...pitchSuggestion,
                        suggestions: pitchSuggestion.suggestions.map(x => ({
                            ...x,
                            isSelected: x.id === salaryId,
                            isEdit: isMobile ? false : isEditableOption
                        }))
                    }
                } else {
                    return ({
                        ...pitchSuggestion
                    })
                }
            });

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: [
                        ...pitchSuggestionsUpdated,
                    ]
                },
                isEditing: isMobile ? false : isEditableOption,
                editingPlayerAdId: isEditableOption ? playerAdId : null
            }));

            if (!isEditableOption) {
                const selectedPitch = currentPlayer.pitchSuggestions.find(suggestion => suggestion.playerAd.id === playerAdId);
                const selectedSuggestion = selectedPitch.suggestions.find(x => x.id === salaryId);

                if (selectedSuggestion.isRecommended) {
                    dispatch(userActivityInsert({
                        PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players]' : 'Re-Onboarding [Pitch Suggestions]',
                        Message: `Selected Recommended Value: ${selectedSuggestion.amount.price} ${selectedPitch.playerAd.currency.name} (${selectedPitch?.playerAd?.createdBySquad?.name}, ${selectedPitch?.playerAd?.type === PlayerAdTypeEnumModel.Buy ? 'Buy' : 'Loan' }, ${selectedPitch?.playerAd?.positionName}, ${selectedPitch?.playerAd?.amount.price} ${selectedPitch?.playerAd?.currency.name})`,
                        PlayerId: currentPlayer.playerId,
                        ClubId: squadId
                    }));
                } else {
                    dispatch(userActivityInsert({
                        PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players]' : 'Re-Onboarding [Pitch Suggestions]',
                        Message: `Selected Value: ${selectedSuggestion.amount.price} ${selectedPitch.playerAd.currency.name} (${selectedPitch?.playerAd?.createdBySquad?.name}, ${selectedPitch?.playerAd?.type === PlayerAdTypeEnumModel.Buy ? 'Buy' : 'Loan' }, ${selectedPitch?.playerAd?.positionName}, ${selectedPitch?.playerAd?.amount.price} ${selectedPitch?.playerAd?.currency.name})`,
                        PlayerId: currentPlayer.playerId,
                        ClubId: squadId
                    }));
                }
            }
        }
    }

    public static changeSalary(playerAdId: number, salaryId: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const currentPitchSuggestion = currentPlayer.pitchSuggestions.find(suggestion => suggestion.playerAd.id === playerAdId);
            const validatedValue = validatePriceInput(value, currentPitchSuggestion.playerAd.type === PlayerAdTypeEnumModel.Buy ? 'buy' : 'loan');

            const pitchSuggestionsUpdated =
                currentPlayer.pitchSuggestions.map(pitchSuggestion => {
                    if (pitchSuggestion.playerAd.id === playerAdId) {
                        return {
                            ...pitchSuggestion,
                            suggestions: pitchSuggestion.suggestions.map(x => {
                                return x.id === salaryId ? ({
                                    ...x,
                                    amount: {
                                        ...x.amount,
                                        price: value === '' ? '' : formatWithCommas(validatedValue),
                                        formattedComma: value === '' ? '' : formatWithCommas(validatedValue),
                                    }
                                }) : ({
                                    ...x
                                })

                            })
                        }
                    } else {
                        return ({
                            ...pitchSuggestion
                        })
                    }
                });

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: [
                        ...pitchSuggestionsUpdated,
                    ]
                }
            }));
        }
    }

    public static finishEditing() {
        return (dispatch, getState: () => AppState) => {
            const squadId = getAuthSquadId(getState());
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const playerAdId = Selectors.getEditingPlayerAdId(getState());
            const isAdditionalPlayersSuggestionsStep = Selectors.isAdditionalPlayersSuggestionsStep(getState());

            const pitchSuggestionsUpdated = currentPlayer.pitchSuggestions.map(pitchSuggestion => {
                if (pitchSuggestion.playerAd.id === playerAdId) {
                    return {
                        ...pitchSuggestion,
                        suggestions: pitchSuggestion.suggestions.map(x => ({
                            ...x,
                            isEdit: false
                        }))
                    }
                } else {
                    return ({
                        ...pitchSuggestion
                    })
                }
            });

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: [
                        ...pitchSuggestionsUpdated,
                    ]
                },
                isEditing: false,
                editingPlayerAdId: null
            }));

            const selectedPitch = currentPlayer.pitchSuggestions.find(suggestion => suggestion.playerAd.id === playerAdId);
            const selectedSuggestion = selectedPitch?.suggestions.find(x => x.isCustom);

            if (selectedSuggestion?.amount.price) {
                dispatch(userActivityInsert({
                    PageName: isAdditionalPlayersSuggestionsStep ? 'Re-Onboarding [Pitch Suggestions for Other Players]' : 'Re-Onboarding [Pitch Suggestions]',
                    Message: `Selected Custom Value: ${selectedSuggestion.amount.price} ${selectedPitch.playerAd.currency.name} (${selectedPitch?.playerAd?.createdBySquad?.name}, ${selectedPitch?.playerAd?.type === PlayerAdTypeEnumModel.Buy ? 'Buy' : 'Loan' }, ${selectedPitch?.playerAd?.positionName}, ${selectedPitch?.playerAd?.amount.price} ${selectedPitch?.playerAd?.currency.name})`,
                    PlayerId: currentPlayer.playerId,
                    ClubId: squadId
                }));
            }
        }
    }

    public static exitToHomePage() {
        return (dispatch) => {
            historyAccessor.push(userPaths.home);
            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Pitch Congratulations]',
                Message: ' Exit to Homepage'
            }));
        }
    }

    public static goToRecruitPlayers() {
        return (dispatch) => {
            historyAccessor.push(userPaths.recruitPlayers);

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Pitch Congratulations]',
                Message: 'Recruit Players'
            }));
        }
    }

    public static goAllPitches() {
        return (dispatch) => {
            historyAccessor.push(userPaths.clubAds);

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Pitch Congratulations - No Pitches]',
                Message: 'See All Opportunities'
            }));
        }
    }

    public static onClose() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const { isManual } = ReonboardingPopupSelectors.getRoot(appState);
            dispatch({ type: getOpenReonboardingPopupActionType(isManual) })
            dispatch(ReonboardingActions.disposeReonboardingSteps());

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Pitch Congratulations - No Pitches]',
                Message: 'Close and Exit'
            }));
        }
    }

    public static onSelectPlayer(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const selectedPlayers = substate.selectedAdditionalPlayersIds;
            const additionalPlayers = Selectors.getAdditionalPlayers(getState());
            const selectedPlayer = additionalPlayers.find(x => x.id === playerId);

            if (selectedPlayers.includes(playerId)) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    selectedAdditionalPlayersIds: prevState.selectedAdditionalPlayersIds.filter(x => x !== playerId)
                })));

                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Pitch Other Players]',
                    Message: `Deselected ${selectedPlayer?.shortName}`,
                    PlayerId: playerId,
                }));

            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    selectedAdditionalPlayersIds: [...prevState.selectedAdditionalPlayersIds, playerId]
                })));

                dispatch(userActivityInsert({
                    PageName: 'Re-Onboarding [Pitch Other Players]',
                    Message: `Selected ${selectedPlayer?.shortName}`,
                    PlayerId: playerId,
                }));
            }
        }
    }

    public static skipAdditionalPlayers() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setActiveStep(PitchingPlayersSteps.Done));
            dispatch(ReonboardingActions.toggleCongratulationScreen(true));
            const isSentPitchExists = Selectors.getRoot(getState()).sentClubsIds.length > 0;

            if (isSentPitchExists) {
                dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations]'));
            } else {
                dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations - No Pitches]'));
            }

            dispatch(userActivityInsert({
                PageName: 'Re-Onboarding [Pitch Other Players]',
                Message: 'Skipped'
            }));
        }
    }

    public static pitchAdditionalPlayers(){
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ processing: true }));
                const substate = Selectors.getRoot(getState());
                const additionalPlayers = Selectors.getAdditionalPlayers(getState());
                const isSentPitchExists = Selectors.getRoot(getState()).sentClubsIds.length > 0;
                const playerIds = substate.selectedAdditionalPlayersIds;
                const preparedData = playerIds.map((playerId)=>({
                    playerId,
                    transferType: TransferTypeEnum.Both
                }))

                const data = await ReonboardingService.getPitchSuggestions(preparedData);

                playerIds.forEach((id) => {
                    const pitchedPlayer = additionalPlayers.find(x => x.id === id);

                    dispatch(userActivityInsert({
                        PageName: 'Re-Onboarding [Pitch Other Players]',
                        Message: `Pitch ${pitchedPlayer?.shortName}`,
                        PlayerId: pitchedPlayer?.id,
                    }));
                });

                if (data.totalPitchSuggestions > 0) {
                    dispatch(stateController.setState({
                        additionalPitchSuggestions: [...data.players]
                    }));
                    dispatch(Actions.setActiveStep(PitchingPlayersSteps.PitchAdditionalOpportunitiesStep));
                    dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Suggestions for Other Players]'));
                } else {
                    dispatch(Actions.setActiveStep(PitchingPlayersSteps.Done));
                    dispatch(ReonboardingActions.toggleCongratulationScreen(true));
                    if (isSentPitchExists) {
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations]'));
                    } else {
                        dispatch(ReonboardingActions.setActivePageName('Re-Onboarding [Pitch Congratulations - No Pitches]'));
                    }
                }

            } catch(err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.club.reonboarding.pitchingPlayers;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).activeStep;
    public static isProcessing = (state: AppState): boolean => Selectors.getRoot(state).processing;
    public static isRemovedPitchExists = (state: AppState): boolean => Selectors.getRoot(state).isRemovedPitchExists;
    public static isEditing = (state: AppState): boolean => Selectors.getRoot(state).isEditing;
    public static getCurrentPlayer = (state: AppState) => Selectors.getRoot(state).currentPlayer;
    public static getCurrentPlayerIndex = (state: AppState) => Selectors.getRoot(state).currentPlayerIndex;
    public static getPlayersPitchesLength = (state: AppState) => Selectors.getRoot(state).playersPitches.length;
    public static getPlayersPitches = (state: AppState) => Selectors.getRoot(state).playersPitches;
    public static getEditingPlayerAdId = (state: AppState) => Selectors.getRoot(state).editingPlayerAdId;
    public static getAdditionalPlayers = (state: AppState) => Selectors.getRoot(state).additionalPlayers;
    public static isAdditionalPlayersSuggestionsStep = (state: AppState) => {
        return Selectors.getCurrentStep(state) === PitchingPlayersSteps.PitchAdditionalOpportunitiesStep;
    };

    public static isEmptySalaryExists = (state: AppState) => {
        const currentPlayer = Selectors.getCurrentPlayer(state);
        const allSuggestions = currentPlayer.pitchSuggestions.reduce((acc, suggestion) => {
            acc.push(...suggestion.suggestions)
            return acc;
        }, []);

        return allSuggestions.some(x => x.isSelected && x.isCustom && !x.amount.price);
    }

    public static getNextPlayerName = (state: AppState) => {
        const currentPlayerIndex = Selectors.getCurrentPlayerIndex(state);
        const getPlayersPitchesLength = Selectors.getPlayersPitchesLength(state);
        const playersPitches = Selectors.getPlayersPitches(state);

        if (currentPlayerIndex + 1 >= getPlayersPitchesLength) {
            return null;
        }

        return playersPitches[currentPlayerIndex + 1].playerName;
    };

    public static getPrevPlayerName = (state: AppState) => {
        const currentPlayerIndex = Selectors.getCurrentPlayerIndex(state);
        const playersPitches = Selectors.getPlayersPitches(state);

        if (currentPlayerIndex === 0) {
            return null;
        }

        return playersPitches[currentPlayerIndex - 1].playerName;
    };

    public static getCurrentPlayerClubsQty = (state: AppState) => {
        const currentPlayer = Selectors.getCurrentPlayer(state);
        const uniqueClubIds = Array.from(new Set(currentPlayer.pitchSuggestions.map(item => item.playerAd.createdBySquad.id)));

        return uniqueClubIds.length;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};