import styled from 'styled-components'

export default styled.div`
   .ep-attendance-details {
        margin: 0 auto;
        margin-bottom: 0;
        /* margin-top: 74px; */
        padding-bottom: 60px;
        // border-bottom: solid 1px #d4d4d4;

        &__heading {
            font-size: 36px;
            color: black;
            font-weight: bold;
            span {
                font-weight: 400;
            }
            @media (max-width: 499px) {
                font-size: 31px;
                display: flex;
                flex-direction: column;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 0px;
        }
        &__items-container {
            margin-top: 18px;
        }

        &__suggestion-item {
            margin-bottom: 10px !important;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .suggestion-item {
            width: 100%;
            border-radius: 5px;
            padding: 12px;
            transition: all 0.5s;
            display: grid;
            grid-template-areas: 'number text btn';
            gap: 12px;
            grid-template-columns: 60px 11fr 3fr;
            padding: 12px;
            background-color: #F6F6F6;
            border: 1px solid #D3D3D3;
            box-shadow: none;
            &:hover {
                box-shadow: 0 2px 15px -5px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                
                .suggestion-item__number {
                    box-shadow: inset 0 2px 3px 0px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                }
            }
            &__number {
                grid-area: number;
                width: 60px;
                height: 60px;
                border-radius: 5px;
                border: 1px solid #acacac;
                background-color: #fff;
                line-height: 24px;
                position: relative;
                transition: all 0.3s;
                span {
                    position: absolute;
                    cursor: default;
                    top: 50%;
                    left: 51%;
                    transform: translate(-50%, -50%);
                    color: #e55843; 
                    font-weight: bold;
                    font-size: 16px;
                    white-space: nowrap;
                    text-align: center;
                }
            }
            &__text {
                grid-area: text;
                cursor: default;
                > div:first-child {
                    font-weight: bold;
                    font-size: 16px;
                }
                > div:last-child {
                    font-size: 13px;
                    line-height: 1rem;
                }
            }
            &__btn {
                grid-area: btn;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-right: 10px;
                button {
                    width: 100%;
                    height: auto !important;
                }
            }
            @media (max-width: 990px) {
                grid-template-columns: 60px 10fr 4fr;
            }
            @media (max-width: 440px) {
                grid-template-areas: 
                'number text' 
                'btn btn';
                grid-template-columns: 60px 1fr;
                grid-template-rows: auto auto;
                &__btn {
                    padding-right: 0;
                }
            }
            &.completed {
                .suggestion-item__number {
                    border: 2px solid #acacac;
                    span {
                        display: none;
                    }
                    i {
                        color: #0E9655;
                        font-size: 30px;
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%)
                    }
                }
                .suggestion-item__btn {
                    position: relative;
                    
                    span {
                        text-transform: uppercase;
                        color: #222222;
                        font-size: 12px;
                        letter-spacing: 0;
                        font-weight: bold;
                        position: absolute;
                        top: 53%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
            &.disabled {
                opacity: 0.7;
                pointer-events: none;
            }
        }

    }
`