import React from 'react'
import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import styled from 'styled-components'
import AndreMazzuco from 'media/images/events/2023-june-sao-paolo/andre-mazzuco.png';
import RicardoMoreira from 'media/images/events/2023-june-sao-paolo/ricardo-moreira.png';
import Replay from 'app/events/components/authorized-event-page/common/video-replay-new/replay'
import ThumbSaoPaolo from 'media/images/events/2023-june-sao-paolo/sao-paulo-thumb.jpg';

const SaoPaoloSummitJune2023 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="11"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom"
                        title2="Summit"
                        date="19th-20th JUNE 2023, São Paulo, Brazil"
                        vs={true}
                    />
                    <Replay
                        compactMode
                        trackUserActivity={() => {
                            // this.props.userActivityInsert({
                            //     PageName: 'Event',
                            //     Message: 'Opened video (6th TransferRoom Summit)',
                            //     EventId: eventId
                            // })
                        }}
                        content={{
                            videoUrl: 'https://player.vimeo.com/video/891752761',
                            backgroundThumb: ThumbSaoPaolo,
                            heading1: '',
                            heading2: '',
                            highlightsText: '',
                            speakers: []
                        }}
                    />
                    {/* <SpeakersSection>
                        <h2>Speakers</h2>
                        <div className="speaker-card-container">
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${AndreMazzuco}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Andre Mazzuco</div>
                                    <div className="speaker-info__position">Sporting Director</div>
                                    <div className="speaker-info__club">Botafogo FR</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${RicardoMoreira}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Ricardo Moreira</div>
                                    <div className="speaker-info__position">Technical Director</div>
                                    <div className="speaker-info__club">Orlando City SC</div>
                                </div>
                            </div>
                        </div>
                    </SpeakersSection> */}
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="116"
                        countries="24"
                        leagues="28"
                        agents="14"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

const SpeakersSection = styled.div`
    h2 {
        font-size: 21px;
        line-height: 34px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .speaker-card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .speaker-card {
            width: 250px;
            margin-bottom: 10px;

            .speaker-image {
                display: flex;
                justify-content: center;
        
                & > div{
                    width: 128px;
                    height: 128px;
                    border-radius: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        
            .speaker-info {
                margin-top: 15px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
        
                &__name {
                    font-weight: bold;
                }
        
                &__position {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &__club {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

        }
    }
`;

export const previousEventAttendees = [
    { id: 27833, name: "America Futebol Clube (MG)", logo: "5673_América_Futebol_Clube_(MG).png" },
    { id: 30849, name: "Atlanta United FC", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 23647, name: "Atletico de San Luis", logo: "25866_Atlético_de_San_Luis.png" },
    { id: 6249, name: "Bayer 04 Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 7400, name: "Botafogo de Futebol e Regatas", logo: "logo_7400_Botafogo_de_Futebol_e_Regatas.png" },
    { id: 8239, name: "Brentford FC", logo: "logo_1153_Brentford_FC.png" },
    { id: 12027, name: "CA Boston River", logo: "logo_2875_Club_Atletico_Boston_River.png" },
    { id: 16247, name: "Club Athletico Paranaense", logo: "5651_Club_Athletico_Paranaense.png" },
    { id: 174, name: "Club Atletico Talleres", logo: "logo_2588_Club_Atletico_Talleres.png" },
    { id: 39981, name: "Clube Atletico Mineiro", logo: "5653_Atlético-MG.png" },
    { id: 22719, name: "Clube de Regatas Vasco da Gama", logo: "5657_Club_de_Regatas_Vasco_da_Gama.png" },
    { id: 27251, name: "CR Flamengo", logo: "5654_Clube_de_Regatas_do_Flamengo.png" },
    { id: 37217, name: "Deportes Tolima", logo: "12396_Deportes_Tolima.png" },
    { id: 32429, name: "Eintracht Frankfurt", logo: "logo_1268_Eintracht_Frankfurt.png" },
    { id: 41646, name: "FC Dallas", logo: "logo_2113_FC_Dallas.png" },
    { id: 17, name: "Genoa CFC", logo: "logo_1843_Genoa_CFC.png" },
    { id: 1421, name: "Gremio Foot-Ball Porto Alegrense", logo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png" },
    { id: 50113, name: "Inter Miami CF", logo: "-69261_Inter_Miami_CF.png" },
    { id: 46, name: "Liverpool FC", logo: "logo_1123_Liverpool_FC.png" },
    { id: 10026, name: "Los Angeles Galaxy", logo: "7847_Los_Angeles_Galaxy.png" },
    { id: 26202, name: "Ludogorets Razgrad", logo: "9311_Ludogorets.png" },
    { id: 30475, name: "Malmo FF", logo: "logo_1432_Malmo_FF.png" },
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 45, name: "Manchester United", logo: null }, //"logo_1120_Manchester_United.png"
    { id: 29455, name: "Nottingham Forest", logo: "logo_1152_Nottingham_Forest.png" },
    { id: 26540, name: "Orlando City SC", logo: "8068_Orlando_City_SC.png" },
    { id: 22240, name: "PAOK Thessaloniki", logo: "logo_2021_PAOK_Thessaloniki.png" },
    { id: 22788, name: "Red Bull Bragantino", logo: "5685_Red_Bull_Bragantino.png" },
    { id: 5972, name: "San Jose Earthquakes", logo: "7851_San_Jose_Earthquakes.png" },
    { id: 19206, name: "Santos FC", logo: "5655_Santos_FC.png" },
    { id: 1065, name: "SC Braga", logo: "logo_Sporting_Braga.png" },
    { id: 39337, name: "SK Dnipro-1", logo: "59603_SK_Dnipro-1.png" },
    { id: 35839, name: "Sociedade Esportiva Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 697, name: "Sport Club Corinthians Paulista", logo: "logo_697_Sport_Club_Corinthians_Paulista.png" },
    { id: 33794, name: "Sport Club Internacional", logo: "logo_2611_Sport_Club_Internacional.png" },
    { id: 4230, name: "Stade Reims", logo: "3802_Stade_Reims.png" }
]

export default SaoPaoloSummitJune2023