export const benefits = [
    'Supercharge your South American network with up to 28 one-to-one meetings',
    'Discover top talent from the Americas and act quickly to beat the competition',
    'Get accurate information you can trust and better prices '
]

export const agentBenefits = [
    'Supercharge your South American network with up to 28 one-to-one meetings',
    'Discover top talent from the Americas and act quickly to beat the competition',
    'Get accurate information you can trust and better prices'
]

export const europeBenefits = [
    'Supercharge your South American network with up to 28 one-to-one meetings',
    'Discover top talent from the Americas and act quickly to beat the competition',
    'Get accurate information you can trust and better prices'
]
export const americaBenefits = [
    'Showcase your players to a global audience and increase their visibility in the global football market',
    'Connect with European clubs and establish long-term relationships for future player transfers',
    'Access to top decision makers looking for new talent from the Americas'
]