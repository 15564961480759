import { StateController } from "utils/action-declaration";
import { AppState } from "root.reducer";
import userActivityInsert, {insertAnonymousActivity} from 'app/user-activity/actions/user-activity.actions';
import {PageType, UserTypeEnum} from "constants/enums";

export enum ModalState {
    Default = 0,
    UpgradePlayerToPriority = 1,
    PlayerRepRequestUpgrade = 2,
    PlayerVerification = 3,
    RemoveMessageChanel,
}

class SharedModalState {
    isOpen: boolean;
    modalState: ModalState;
    logOutModalInfo: {
        isOpen: boolean;
        location: string;
    }
}

const defaultState: SharedModalState = {
    isOpen: false,
    modalState: ModalState.Default,
    logOutModalInfo: {
        isOpen: false,
        location: '',
    }
}

const stateController = new StateController<SharedModalState>(
    "SHARED_MODAL",
    defaultState
);

class Actions {
    public static onOpen(modalState: ModalState = ModalState.Default) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isOpen: true, modalState }))
        }
    }
    public static onClose() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().sharedModal;
            switch (substate.modalState) {
                case ModalState.UpgradePlayerToPriority:
                    dispatch(userActivityInsert({
                        PageName: 'Agency Players [Upgrade]',
                        Message: `Closed Info about Sharing`,
                        PageType: PageType.AgencyPlayers
                    }));
                    break;

                case ModalState.PlayerRepRequestUpgrade:
                    
                    break;

                case ModalState.PlayerVerification:
                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Player Verification]',
                        Message: `Closed Info about Sharing`,
                        PageType: PageType.AgencyHome
                    }));
                    break;

                default:
                    break;
            }
            dispatch(stateController.setState({ isOpen: false, modalState: ModalState.Default }))
        }
    }
    public static getUserPageName() {
        return (dispatch, getState) => {
            const userTypeId = getState().auth.userTypeId;
            switch (userTypeId) {
                case UserTypeEnum.Agency:
                    return 'Agency Pages [Logout Alert]'
                case UserTypeEnum.Player:
                    return 'Player Pages [Logout Alert]'
                case UserTypeEnum.MasterUser:
                    return 'Club Pages [Logout Alert]'
                case UserTypeEnum.Staff:
                    return 'Head Coach Pages [Logout Alert]'
                default:
                    return 'unknown page'
            }
    
        }
    }
    public static openModalOnLogOut(location: string) {
        return (dispatch, getState: () => AppState) => {
        dispatch(stateController.setState((prevState)=> ({
                ...prevState,
                logOutModalInfo : {
                    isOpen: true,
                    location: location
                }
            })));
            localStorage.removeItem('IS_LOGOUT');
        }
    }
    public static closeModalOnLogOut(isOkClicked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState)=> ({
                ...prevState,
                logOutModalInfo : {
                    isOpen: false,
                    location: ''
                }
            })));
        }
    }
    
    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch) => {
            dispatch(insertAnonymousActivity({
                Message: message,
                PageName: pageName,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.sharedModal.isOpen
}

const reducer = stateController.getReducer();


export {
    reducer as Reducer,
    SharedModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};