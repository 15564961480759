import { createAction } from 'redux-actions';
import { MODAL_CLOSE, MESSAGES_SEND } from 'store/actionTypes';
import {
    SEND_MESSAGE_MODAL,
    ACCESS_RESTRICTED_MODAL,
} from '../../../constants/modals';
import createApiThunk from '../../../services/createApiThunk';
import { getAuth } from 'store/auth/authReducer';
import { MessagingService } from 'api/messaging/messaging.service';
import { ConversationTypeEnum, MessageType, NewMessage } from 'api/messaging/models/message'
import { notificationCreate } from 'app/notifications/notifications.actions';
import { parseAndTranslate } from 'services/localization';

export const modalCloseSendMessage = createAction(
    MODAL_CLOSE,
    () => SEND_MESSAGE_MODAL,
);

export const modalCloseRestrictedAccess = createAction(
    MODAL_CLOSE,
    () => ACCESS_RESTRICTED_MODAL,
);

export const messageSend = (
    receiverId: number,
    message: string,
    player = null,
    officialName = null,
    messageTypeId: MessageType = 1,
    subject = null,
    isAgencyReceiver: boolean = false,
    refId: number = null,
) => async (dispatch, getState) => {
    return new Promise<any>(async (resolve, reject) => {        

        if(messageTypeId === 1 && isAgencyReceiver){
            messageTypeId = 8;
        }

        const newMessage: NewMessage = {
            messageType: messageTypeId,
            chatType: messageTypeId as any,
            text: message,
            subject: subject || 'New Message',
            refId: refId || null
        }

        try {
            dispatch(createAction(MESSAGES_SEND.REQUEST))

            if (isAgencyReceiver) {
                let chat = await MessagingService.createChatWithAgency(receiverId, newMessage)
                let auth = getAuth(getState());
                await MessagingService.sendPushNotificationToAgency({ 
                    squadId: auth.squadId, 
                    channelId: chat.id, 
                    receiverAgencyId: receiverId, 
                    messageText: "New Message", 
                    agencyId: 0, 
                    receiverSquadId: 0,
                    playerId: 0,
                    receiverPlayerId: 0
                });
            } else {
                let chat = await MessagingService.createChatWithSquad(receiverId, newMessage);
                let auth = getAuth(getState());
                await MessagingService.sendPushNotificationToSquadWithoutFriendlySupervisors({ 
                    squadId: auth.squadId, 
                    channelId: chat.id, 
                    receiverSquadId: receiverId, 
                    messageText: "New Message", 
                    agencyId: 0, 
                    receiverAgencyId: 0,
                    playerId: 0,
                    receiverPlayerId: 0
                });
            }

            dispatch(createAction(MESSAGES_SEND.SUCCESS)({ player, officialName }))
            dispatch(notificationCreate({ 
                level: 'success', 
                message: parseAndTranslate(`[%notifications.yourMessageTo%] <b>${officialName == null ? player.parentSquad.officialName: officialName}</b> [%notifications.hasBeenSent%]`) 
            }))

            resolve(true)

        } catch (e) {
            dispatch(createAction(MESSAGES_SEND.ERROR))
            reject(e)
        }
    }
    )
}
