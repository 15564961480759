import { SquadShortInfo } from "api/core/squad-short-info";

export interface FilterOptions {
    sortOrder: string;
    isSortOrderAscending: boolean;
    page: number;
    recordsPerPage: number;
    squadName: string;
}

export interface GetSquadsResponse {
    output: Array<SquadReonboardingItem>
    currentPageNumber: number;
    pageCount: number;
    rowCount: number;
    hasResult: boolean; 
}

export interface SquadReonboardingItem {
    squad: SquadShortInfo
    lastDisplayedAt: Date | null
    status: ClubReonboardingItemAdminStatusEnum
}

export enum ClubReonboardingItemAdminStatusEnum {
    TransferWindow = 1,
    ManualLaunch = 2,
    NotActive = 3
}