import { combineReducers } from 'redux';
import {
    MY_FRIENDLY_MATCHES_FETCH,
    MY_FRIENDLY_MATCHES_AREAS_FETCH,
    FRIENDLY_MATCH_REMOVE,
    FRIENDLY_MATCH_UPDATE,
    FRIENDLY_MATCH_SAVE,
    GET_SQUADS_FOR_INVITATION_ON_FRIENDLY_MATCH,
    GET_AREA_BY_SQUAD_ID,
    INVITE_SQUADS_TO_FRIENDLY_MATCH,
    MY_FRIENDLY_REFRESH_ALL,
    INVITE_TO_MATCH_ID,
    FRIENDLY_MATCH_LOAD_AVAILABILITY
} from 'store/actionTypes';
import { arrayToObject } from '../../../../services/utils';

export default combineReducers({
    byId,
    allIds,
    areas,
    squads,
    latestCreatedMatchId,
    myArea,
    availabilityBusyDates
});

function latestCreatedMatchId(id = 0, action) {
    switch (action.type) {
        case INVITE_TO_MATCH_ID: {
            return action.payload;
        }
        case FRIENDLY_MATCH_SAVE.SUCCESS: {
            return action.payload.data.id;
        }

        case INVITE_SQUADS_TO_FRIENDLY_MATCH.SUCCESS: {
            return 0;
        }

        default:
            return id;
    }
}

function availabilityBusyDates(availability = null, action) {
    switch (action.type) {
        case FRIENDLY_MATCH_LOAD_AVAILABILITY.REQUEST: {
            return null;
        }
        case FRIENDLY_MATCH_LOAD_AVAILABILITY.SUCCESS: {
            return action.payload.data.busyDates;
        }
        default:
            return availability;
    }
}

function byId(adsById = {}, action) {
    switch (action.type) {
        case MY_FRIENDLY_MATCHES_FETCH.SUCCESS: {
            const ads = action.payload.data;
            return arrayToObject(ads);
        }

        case MY_FRIENDLY_REFRESH_ALL.SUCCESS: {
            const ads = action.payload.data;
            return arrayToObject(ads);
        }

        case FRIENDLY_MATCH_SAVE.SUCCESS: {
            const data = action.payload.data;
            const updatedArray = {
                ...adsById,
            };

            updatedArray[data.id] = data;
            return updatedArray;
        }

        case FRIENDLY_MATCH_REMOVE.SUCCESS: {
            const data = action.payload.data;
            return {
                ...adsById,
                [data.id]: {
                    ...adsById[data.id],
                    deleted: data.deleted,
                    deletedAgo: data.deletedAgo,
                    active: data.active,
                },
            };
        }
        case FRIENDLY_MATCH_UPDATE.SUCCESS: {
            const data = action.payload.data;
            return {
                ...adsById,
                [data.id]: {
                    ...adsById[data.id],
                    deleted: data.deleted,
                    updatedAgo: data.updatedAgo,
                    active: data.active,
                    longTimeUpdate: false,
                },
            };
        }

        default:
            return adsById;
    }
}

function allIds(ids = [], action) {
    switch (action.type) {
        case FRIENDLY_MATCH_SAVE.SUCCESS: {
            ids.push(action.payload.data.id);
            return ids;
        }

        case MY_FRIENDLY_MATCHES_FETCH.SUCCESS: {
            const ads = action.payload.data;
            return ads.map(ad => ad.id);
        }

        default:
            return ids;
    }
}

function areas(ids = [], action) {
    switch (action.type) {
        case MY_FRIENDLY_MATCHES_AREAS_FETCH.SUCCESS: {
            return action.payload.data;
        }


        default:
            return ids;
    }
}

function myArea(area = null, action) {
    switch (action.type) {
        case GET_AREA_BY_SQUAD_ID.SUCCESS: {
            return action.payload.data;
        }

        default:
            return area;
    }
}

function squads(ids = [], action) {
    switch (action.type) {
        case GET_SQUADS_FOR_INVITATION_ON_FRIENDLY_MATCH.SUCCESS: {
            return action.payload.data;
        }

        default:
            return ids;
    }
}
