import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { LoanInsightsService } from 'api/loan-insights/loan-insights-service';
import { ActiveLoansModel } from 'api/loan-insights/models/active-loans-model';
import { CompletedLoansModels } from 'api/loan-insights/models/completed-loans-model';
import { LoanInsightsClubInfoModel } from 'api/loan-insights/models/club-info';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { MatchByMatchPerformanceModel } from 'api/loan-insights/models/mbm-performance';
import { PageType } from 'constants/enums';

export enum LoanInsightsTabs {
    ActiveLoans = 0,
    CompletedLoans = 1,
    LoanBenchmarking = 2
}

export enum RangeFilter {
    Default = 0,
    CurrentWeek = 1,
    CurrentMonth = 2
}
export const RangeFilters = [{ id: RangeFilter.Default, name: "All" }, { id: RangeFilter.CurrentWeek, name: "Last 7 days" }, { id: RangeFilter.CurrentMonth, name: "Last 30 days" }];

function getFilterDate(range: RangeFilter) {
    switch (range) {
        case RangeFilter.Default:
            return new Date(null);
        case RangeFilter.CurrentWeek:
            return new Date((Date.now() - 7 * 24 * 60 * 60 * 1000))
        case RangeFilter.CurrentMonth:
            return new Date((Date.now() - 30 * 24 * 60 * 60 * 1000))
        default:
            return new Date(null);
    }
}

class LoanInsightsState {
    isClubInfoLoading: boolean;
    isLoading: boolean;
    activeLoansData: ActiveLoansModel;
    clubInfo: LoanInsightsClubInfoModel;
    playerDetailsId: number;
    activeTab: LoanInsightsTabs;
    disabledTabs: Array<LoanInsightsTabs>;
    mbmGridSelectedPlayer: { id: number, name: string, secondaryName: string };
    mbmGridSelectedRange: { id: number, name: string };
    mbmGridData: Array<MatchByMatchPerformanceModel>;
}

const defaultState: LoanInsightsState = {
    isClubInfoLoading: false,
    isLoading: false,
    activeLoansData: null,
    clubInfo: null,
    playerDetailsId: null,
    activeTab: LoanInsightsTabs.ActiveLoans,
    disabledTabs: [],
    mbmGridSelectedPlayer: { id: -1, name: "Select player...", secondaryName: "All players..." },
    mbmGridSelectedRange: { id: RangeFilter.Default, name: RangeFilters.find(i => i.id == RangeFilter.Default).name },
    mbmGridData: null
}

const stateController = new StateController<LoanInsightsState>(
    "LOAN_INSIGHTS",
    defaultState
);

class Actions {

    public static loadClubInfo() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let userId = state.auth.userId;
            let squadId = state.auth.squadId;

            dispatch(stateController.setState({ isClubInfoLoading: true }));
            var data: LoanInsightsClubInfoModel = await LoanInsightsService.getClubInfo(squadId, userId);
            dispatch(stateController.setState({ isClubInfoLoading: false, clubInfo: data }));
        }
    }

    public static loadActiveLoansData() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let userId = state.auth.userId;
            let squadId = state.auth.squadId;

            dispatch(stateController.setState({ isLoading: true }));
            var data: ActiveLoansModel = await LoanInsightsService.getActiveLoansTabData(userId, squadId);
            var completedData: CompletedLoansModels = await LoanInsightsService.getCompletedLoansTabData(userId, squadId);
            let playerDetailsSelectedId = null;
            let activeTab = LoanInsightsTabs.ActiveLoans;
            let disabledTabs = [];
            if (data.playerDetails.length > 0) {
                let firstMbmPlayer = data.matchByMatchPerformance[0];
                if (firstMbmPlayer) {
                    let detailsForFirst = data.playerDetails.find(i => i.playerId == firstMbmPlayer.playerId);
                    if (detailsForFirst) { playerDetailsSelectedId = detailsForFirst.playerId }
                    else { playerDetailsSelectedId = data.playerDetails[0].playerId; }
                }
                else { playerDetailsSelectedId = data.playerDetails[0].playerId; }
            }
            if (data.playerDetails.length == 0 && data.matchByMatchPerformance.length == 0, data.performanceOverview.length == 0) {
                activeTab = LoanInsightsTabs.CompletedLoans;
                disabledTabs = [LoanInsightsTabs.ActiveLoans];                      
            }

            if(completedData.completedLoans.length == 0 && completedData.seasons.length == 0){
                if(activeTab == LoanInsightsTabs.CompletedLoans){
                    activeTab = LoanInsightsTabs.LoanBenchmarking;
                }                
                disabledTabs = disabledTabs.concat(LoanInsightsTabs.CompletedLoans);
            }

            dispatch(stateController.setState({
                isLoading: false,
                activeLoansData: data,
                playerDetailsId: playerDetailsSelectedId,
                mbmGridData: data.matchByMatchPerformance,
                activeTab: activeTab,
                disabledTabs: disabledTabs
            }));
        }
    }

    public static setSelectedPlayerDetailsId = (playerId: number) => {
        return (dispatch) => {
            dispatch(stateController.setState({ playerDetailsId: playerId }));
            dispatch(userActivityInsert({
                PageName: `VLA [Active Loans]`,
                Message: `Player Details: selected player`,
                PlayerId: playerId,
                PageType: PageType.VirtualLoanAssistant
            })) 
        }
    }

    public static setSelectedTab = (tab: LoanInsightsTabs) => {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab: tab }));
        }
    }

    public static setMbmSelectedPlayer = (player: { id: number, name: string }) => {
        return (dispatch, getState: () => AppState) => {
            let mbmState = getState().loanInsights.activeLoans.activeLoansData.matchByMatchPerformance;
            dispatch(stateController.setState((prev) => {
                let filterDate = getFilterDate(prev.mbmGridSelectedRange.id);
                let filteredData;
                if (player.id != -1)
                    filteredData = mbmState.filter(i => i.playerId == player.id && new Date(i.matchDate) > filterDate)
                else
                    filteredData = mbmState.filter(i => new Date(i.matchDate) > filterDate);
                return {
                    ...prev,
                    mbmGridSelectedPlayer: player,
                    mbmGridData: filteredData
                }
            }));

            if (player.id != -1) {
                dispatch(userActivityInsert({
                    PageName: `VLA [Active Loans]`,
                    Message: 'Match by Match Performance: selected player',
                    PlayerId: player.id,
                    PageType: PageType.VirtualLoanAssistant
                }))
            } else {
                dispatch(userActivityInsert({
                    PageName: `VLA [Active Loans]`,
                    Message: 'Match by Match Performance: selected all players',
                    PageType: PageType.VirtualLoanAssistant
                }))
            }
        }
    }

    public static setMbmSelectedRange = (range: { id: number, name: string }) => {
        return (dispatch, getState: () => AppState) => {
            let mbmState = getState().loanInsights.activeLoans.activeLoansData.matchByMatchPerformance;
            dispatch(stateController.setState((prev) => {
                let filterDate = getFilterDate(range.id);

                let filteredData;
                if (range.id != 0)
                    filteredData = prev.mbmGridSelectedPlayer.id != -1 ?
                        mbmState.filter(i => new Date(i.matchDate) > filterDate && i.playerId == prev.mbmGridSelectedPlayer.id) :
                        mbmState.filter(i => new Date(i.matchDate) > filterDate)
                else
                    filteredData = prev.mbmGridSelectedPlayer.id != -1 ?
                        mbmState.filter(i => i.playerId == prev.mbmGridSelectedPlayer.id) :
                        mbmState;
                return {
                    ...prev,
                    mbmGridSelectedRange: range,
                    mbmGridData: filteredData
                }
            }));
            
            const selectedPlayerId = getState().loanInsights.activeLoans.mbmGridSelectedPlayer.id
            const playerId = selectedPlayerId != -1 ? {
                PlayerId: selectedPlayerId
            } : {}
            dispatch(userActivityInsert({
                PageName: `VLA [Active Loans]`,
                Message: `Match by Match Performance: selected period: ${range.name}`,
                PageType: PageType.VirtualLoanAssistant,
                ...playerId,
            })) 
            
        }
    }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }, "DISPOSING LOAN INSIGHTS"));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LoanInsightsState as State,
    Actions as Actions,
    stateController as Controller
};



