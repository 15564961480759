import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import history from 'history-accessor';
import { ReonboardingService } from 'api/club/reonboarding/reonboarding.service';
import { userPaths } from 'routes/paths';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getHideReonboardingPopupActionType, getUserActivityPageName } from './utils';
import { Actions as ReonboardingFlowActions } from 'pages/club/reonboarding-flow/reonboarding.controller';
import { Actions as RecruitPlayersActions } from 'pages/club/recruit-players/recruit-players.controller';
import { Selectors as PitchingPlayersSelectors } from 'pages/club/reonboarding-flow/pitching-players-stepper/pitching-players-stepper.controller';

class State {
    isLoading: boolean;
    isManual: boolean;
}

const defaultState: State = {
    isLoading: false,
    isManual: false
}

const stateController = new StateController<State>(
    'CLUB/REONBOARDING-POPUP',
    defaultState
)

class Actions {
    public static init(isManual: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isManual }));

            dispatch(userActivityInsert({
                PageName: getUserActivityPageName(isManual),
                Message: 'Viewed',
            }));
        }
    }

    public static onClickReonboarding() {
        return async (dispatch, getState: () => AppState) => {
            const { isManual } = Selectors.getRoot(getState())
            try {
                dispatch({ type: getHideReonboardingPopupActionType(isManual) });
                const isTransferWindowReonboarding = !isManual;
                dispatch(ReonboardingFlowActions.init(isTransferWindowReonboarding));
                const promise = ReonboardingService.setReonboarding();
                history.push(userPaths.reonboardingFlow);

                await promise;

                dispatch(userActivityInsert({
                    PageName: getUserActivityPageName(isManual),
                    Message: 'Sell/Loan Players',
                }));
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static onClickRecruitPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { isManual } = Selectors.getRoot(getState())
                dispatch({ type: getHideReonboardingPopupActionType(isManual) });
                const isTransferWindowReonboarding = !isManual;
                dispatch(RecruitPlayersActions.init(isTransferWindowReonboarding));
                const promise = ReonboardingService.setRecruitPlayers();
                history.push(userPaths.recruitPlayers)

                await promise;

                dispatch(userActivityInsert({
                    PageName: getUserActivityPageName(isManual),
                    Message: 'Recruit Players',
                }));
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static onClickExit() {
        return async (dispatch, getState: () => AppState) => {
            const { isManual } = Selectors.getRoot(getState())
            const currentPlayer = PitchingPlayersSelectors.getCurrentPlayer(getState());
            try {
                dispatch({ type: getHideReonboardingPopupActionType(isManual) })
                const promise = ReonboardingService.setExit()
                history.push(userPaths.home)

                await promise;

                dispatch(userActivityInsert({
                    PageName: getUserActivityPageName(isManual),
                    Message: isManual ? 'Exit to Homepage' : 'Dismiss',
                    PlayerId: currentPlayer?.playerId ? currentPlayer?.playerId : null,
                }));
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static remindMeLater() {
        return async (dispatch, getState: () => AppState) => {
            const { isManual } = Selectors.getRoot(getState())
            const currentPlayer = PitchingPlayersSelectors.getCurrentPlayer(getState());
            try {
                dispatch({ type: getHideReonboardingPopupActionType(isManual) })
                const promise = ReonboardingService.remindMeLater()
                history.push(userPaths.home)

                await promise;

                dispatch(userActivityInsert({
                    PageName: getUserActivityPageName(isManual),
                    Message: 'Not Now',
                    PlayerId: currentPlayer?.playerId ? currentPlayer?.playerId : null,
                }));
            } catch (e) {
                console.error(e)
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.club.reonboardingPopup;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
}