import { createElement } from 'react'
import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
import HprTitle from './hpr/hpr-title';
import SpeakerCardBox from './speaker-card/speaker-card-box';

const title = "TransferRoom Winter Virtual Deal Day 2024";

const eventSettings: EventInfoModel = {
    shortTitle: "TransferRoom Winter Virtual Deal Day",
    title: title,
    shortEventDates: "15th January 2024",
    eventDates: "15th January 2024",
    location: "Online (Virtual)",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null, //SpeakerCardBox,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        title: 'TransferRoom Winter Virtual Deal Day for Clubs and Trusted Agents',
        userActivityPageName: "Home [TransferRoom Winter Virtual Deal Day]",
        description: "Register for our next event and book meetings with decision makers from up to 300 clubs as well as trusted agencies."
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: false
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: false
    }
};

export default eventSettings;