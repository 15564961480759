import { createAction } from 'redux-actions';
import {
    CLUB_ADS_FETCH,
    CLUB_ADS_SELECT_CLUB_AD,
    CLUB_ADS_MAKE_AVAILABLE,
    CLUB_AD_PITCH_PLAYER,
    CLUB_AD_MAKE_AVAILABLE_REQUEST,
    CLUB_ADS_CLEAR_SELECTED_CLUB_AD,
    MY_PITCHES_SET_FILTER,
    CLUB_ADS_SET_SHORT_LIST,
    CLUB_ADS_SET_PLUS_PITCH_TAB,
    CLUB_ADS_SCREEN_DISPOSE,
    CLUB_ADS_CLEAR,
    CLUB_ADS_CLEAR_MY_PITCHES,
} from 'store/actionTypes';
import createApiThunk, {
    createApiWithCancel
} from 'services/createApiThunk';
import {
    getAuthUserId,
    getAuthSquadId,
} from 'store/auth/authReducer';
import {
    getClubAdsFilterStructure,
} from 'pages/Pitch/store/reducers/filter-reducer';
import { userLinkTypeEnum } from 'constants/enums'

import { loadGeneralInfoStartHidden } from "./pitch-insight.actions"
import { getCancelTokenSource } from 'axios-config';
import { PageType } from 'constants/enums';

let source = null;

export const clubAdsFetch = (payload = {}) => async (dispatch, getState) => {
    let structure = getClubAdsFilterStructure(getState());

    if(payload.competitionsIds == null || payload.competitionsIds.length == 0)
    {
        let compIds = structure.competitions != null ? structure.competitions.map(x => x.key) : [];
        payload = {...payload, competitionsIds: compIds}
    }

    const request = {
        type: CLUB_ADS_FETCH,
        scope: 'clubAds',
        method: 'post',
        url: `v2/ClubAds/GetClubAds`,
        payload: {
            ...payload,
            initData: {
                ...structure,
                transferFeeMin: structure.transferFeeMin,
                transferFeeMax: structure.transferFeeMax,
                monthlyLoanFeeMin: structure.monthlyLoanFeeMin,
                monthlyLoanFeeMax: structure.monthlyLoanFeeMax
            }
        },
    };

    dispatch(createAction(request.type.REQUEST)(request));

    if (source) {
        source.cancel();
    }

    source = getCancelTokenSource();

    let data = await createApiWithCancel(request, source.token);
    if (data) {
        dispatch(
            createAction(CLUB_ADS_FETCH.SUCCESS)({
                request: request,
                data: data,
            }),
        );
    }
};

export const clubAdPitchPlayer = (payload, clubAdId) => async (dispatch, getState) => {
    const userId = getAuthUserId(getState());
    const squadId = getAuthSquadId(getState());
    const data = {
        ...payload,
        userId: userId,
        squadId: squadId,
        squadName: null,
        clubAdId
    };
    const request = {
        type: CLUB_AD_PITCH_PLAYER,
        scope: 'clubAds',
        method: 'post',
        url: `ClubAds/PitchPlayer`,
        payload: data,
    };

    const trackingObj = {
        Message: getState().clubAds.byId[clubAdId].isAnonymousSquad ? 'Pitched Player. Anonymised Ad' : `Pitched Player`,
        PageName: 'Pitch [Ads]',
        ClubId: getState().clubAds.byId[clubAdId].squad?.id,
        PlayerId: payload.playerId,
        PageType: PageType.Pitch,
        Links: [{
            Type: userLinkTypeEnum.PlayerAd,
            TargetId: clubAdId
        }]
    }

    await dispatch(createApiThunk(request, trackingObj));
    dispatch(clubAdsClearSelectedClubAd())
    await dispatch(loadGeneralInfoStartHidden());
};

export const clubAdMakeAvailableRequest = (payload) => async (dispatch, getState) => {
    const data = { ...payload };

    const request = {
        type: CLUB_AD_MAKE_AVAILABLE_REQUEST,
        scope: 'clubAds',
        method: 'post',
        url: `v2/MySquad/SetAvailability`,
        payload: data,
    };

    // const trackingObj = {
    //     Message: `Pitched Player`,
    //     PageName: 'Pitch [Ads]',
    //     ClubId: getState().clubAds.byId[clubAdId].squadId,
    //     PlayerId: payload.playerId,
    //     PageType: PageType.Pitch,
    //     Links: [{
    //         Type: userLinkTypeEnum.PlayerAd,
    //         TargetId: clubAdId
    //     }]
    // }

    await dispatch(createApiThunk(request, null));
    dispatch(clubAdsMakeAvailable(false));
};

export const clubAdsSelectClubAd = createAction(CLUB_ADS_SELECT_CLUB_AD);
export const clubAdsMakeAvailable = createAction(CLUB_ADS_MAKE_AVAILABLE);

export const clubAdsSetShortList = createAction(CLUB_ADS_SET_SHORT_LIST);
export const clubAdsSetPlusPitch = createAction(CLUB_ADS_SET_PLUS_PITCH_TAB);
export const myPitchesSetFilter = createAction(MY_PITCHES_SET_FILTER);

export const clubAdsClearSelectedClubAd = createAction(
    CLUB_ADS_CLEAR_SELECTED_CLUB_AD,
);

export const disposeClubAdsScreenState = createAction(CLUB_ADS_SCREEN_DISPOSE);

export const clubAdsClear = createAction(CLUB_ADS_CLEAR);
export const clubAdsClearMyPitches = createAction(CLUB_ADS_CLEAR_MY_PITCHES);
