import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import SubscriptionService from 'api/agency/agent/subscription/subscription'
import { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import { Actions as OnboardingActions } from 'pages/agency/agent/onboarding/onboarding.controller'
import { PageType } from 'constants/enums';
import { commonPagesPaths } from 'routes/paths';
import config from 'config';
import { PaddleSubscriptionCreatedArgs } from 'api/agency/agent/subscription/subscription-models';

class State {
    isProcessing: boolean;
    termsAgreed: boolean;
    isModalOpen: boolean;
}

const defaultState: State = {
    isProcessing: false,
    termsAgreed: false,
    isModalOpen: false
}

const stateController = new StateController<State>(
    "AGENCY/LTA_CHECKOUT",
    defaultState
)

class Actions {

    public static onInit(paddleEventCallback: (eventData: any) => void) {
        return (dispatch, getState: () => AppState) => {
            const { agencyId } = getState().agency.agent.onboarding.agencyNameStepState;
            dispatch(insertAnonymousActivity({
                Message: 'Opened Subscription',
                PageName: 'Agency Payment [Credit Card]',
                AgencyId: agencyId
            }))

            if(config.isPaddleSandbox){
                (window as any).Paddle.Environment.set('sandbox');
            }
            (window as any).Paddle.Setup({ 
                vendor: config.paddleVendorId,
                eventCallback: paddleEventCallback
             });
        }
    }

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                defaultState
            ));
        }
    }

    public static completeLtaSubscription(invitationGuid: string, paidAmount: string, currency: string, eventData: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));
            try {
                await SubscriptionService.completeLtaSubscription(invitationGuid, paidAmount, currency, eventData);
                dispatch(stateController.setState({ isProcessing: false }));
                dispatch(OnboardingActions.getAccess(invitationGuid));
            } catch (error) {
                throw error;
            }
        }
    }

    public static logPaymentDetails(invitationGuid: string, eventData: string){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));
            try {
                await SubscriptionService.logPaymentDetails(invitationGuid, eventData);
                dispatch(stateController.setState({ isProcessing: false }));
            } catch (error) {
                throw error;
            }
        }
    }

    public static setTermsAgreed(termsAgreed: boolean){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ termsAgreed }));
            if(termsAgreed){
                const { agencyId } = getState().agency.agent.onboarding.agencyNameStepState;
                dispatch(insertAnonymousActivity({
                    Message: 'Accepted Terms',
                    PageName: 'Agency Payment [Credit Card]',
                    AgencyId: agencyId
                }))
            }
        }
    }

    public static openTermsOfService(){
        return async (dispatch, getState: () => AppState) => {
            window.open(commonPagesPaths.agencyTermsOfService, '_blank');
            const { agencyId } = getState().agency.agent.onboarding.agencyNameStepState;
            dispatch(insertAnonymousActivity({
                Message: 'Opened Terms',
                PageName: 'Agency Payment [Credit Card]',
                AgencyId: agencyId
            }))
        }
    }
 
    public static payNow(checkoutLoadCallback: () => void){
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.toggleModal(true));

            const { userEmail, agencyId, userId } = getState().agency.agent.onboarding.agencyNameStepState;

            const additionalData: PaddleSubscriptionCreatedArgs = { agencyId, userId };
            
            (window as any).Paddle.Checkout.open({
                method: 'inline',
                product: config.ltaSubscriptionPlanId,
                email: userEmail,
                allowQuantity: false,
                disableLogout: true,
                frameTarget: 'lta-checkout-container',
                frameInitialHeight: 400,
                frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
                loadCallback: checkoutLoadCallback,
                locale: "en",
                passthrough: JSON.stringify(additionalData)
            });

            dispatch(insertAnonymousActivity({
                Message: 'Pay Now',
                PageName: 'Agency Payment [Credit Card]',
                AgencyId: agencyId
            }))
        }
    }    

    public static toggleModal(isModalOpen: boolean){
        return async (dispatch, getState: () => AppState) => {
            await dispatch(stateController.setState({ isModalOpen }));
        }
    }    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};