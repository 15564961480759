import _ from 'lodash';
import { StateController } from 'utils/action-declaration';
import PlusPitchService from 'api/pitch-v2/plus-pitch-service.v2';

import { AppState } from 'root.reducer';
import { PlusPitchModel } from 'api/pitch-v2/models/plus-pitch.model';
import * as CommonController from '../common.controller'
import * as MessageSend from 'pages/landing-page/redux/message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { CancelToken } from 'axios';
import { PitchPageType } from '../../constants/page-type'
import { translate } from 'services/localization';
import * as CommontController from 'pages/player-ads/redux/common.controller'
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { openCoachProfileById, openAgencyProfile } from "utils/open-link";

class PlayerAdMyActivity {
    initialized: boolean;
    isLoading: boolean;
    activePitches: Array<PlusPitchModel>;
    testedPitches: Array<PlusPitchModel>;
    savedPitches: Array<PlusPitchModel>;
    processingIds: Array<number>;
    declareInterestProcessingIds: Array<number>;
}

const defaultState: PlayerAdMyActivity = {
    initialized: false,
    isLoading: false,
    activePitches: [],
    testedPitches: [],
    savedPitches: [],
    processingIds: [],
    declareInterestProcessingIds: []
}

const stateController = new StateController<PlayerAdMyActivity>(
    "PLAYR_ADS/ACTIVITY/PLUS_PITCH",
    defaultState
);


class SendTestPlusPitchSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private playerId: number;
    private squadPitchId: number;
    private isSavedMode: boolean;
    private isAgencyReceiver: boolean;
    private agencyId: number;
    private coachId: number | null;

    constructor(dispatch, toSquadId: number, playerId: number, squadPitchId: number, isSavedMode: boolean, isAgencyReceiver: boolean, agencyId: number, coachId: number) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.playerId = playerId;
        this.squadPitchId = squadPitchId;
        this.isSavedMode = isSavedMode;
        this.isAgencyReceiver = isAgencyReceiver;
        this.agencyId = agencyId;
        this.coachId = coachId;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {
        const pitchSource = this.isAgencyReceiver ? 'Agents ' : ''

        let receiverId = this.isAgencyReceiver ? this.agencyId : this.toSquadId;

        this.dispatch(userActivityInsert({
            PageName: this.isSavedMode ? `Player Ads [Saved ${pitchSource}Plus Pitch]` : `Player Ads [${pitchSource}Test Plus Pitch]`,
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            CoachId: this.coachId,
            ClubId: !this.isAgencyReceiver ? this.toSquadId : -1,
            AgencyId: this.isAgencyReceiver ? this.agencyId : null,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.PlayerAds,
            UserActivityType: UserActivityType.Action
        }));

        if (!this.isSavedMode) {
            if (!checked) {
                this.dispatch(Actions.dismissPitch(PitchPageType.plusTestPitch, this.squadPitchId));
            } else {
                this.dispatch(Actions.deleteTestInterest(this.squadPitchId));
            }
        }
        // else {
        //     this.dispatch(Actions.togglePlayerInShortList(PitchPageType.plusPitch, this.playerId, this.squadPitchId));
        // }

        await this.dispatch(messageSend(receiverId, message, null, session.officialName, session.mesageTypeId, session.subject, this.isAgencyReceiver, this.squadPitchId));
    }

    public cancelMessage() {
        const pitchSource = this.isAgencyReceiver ? 'Agents ' : ''

        this.dispatch(userActivityInsert({
            PageName: this.isSavedMode ? `Player Ads [Saved ${pitchSource}Plus Pitch]` : `Player Ads [${pitchSource}Test Plus Pitch]`,
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            CoachId: this.coachId,
            ClubId: !this.isAgencyReceiver ? this.toSquadId : -1,
            AgencyId: this.isAgencyReceiver ? this.agencyId : null,
            PageType: PageType.PlayerAds
        }));
    }
}

class Actions {

    public static deleteTestInterest(squadPitchId: number): any {

        return async (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage.plusPitch;

            try {

                stateController.setState({ processingIds: [...subState.processingIds, squadPitchId] })
                await PlusPitchService.deleteTestInterest(squadPitchId);
                subState = getState().playerAdPage.plusPitch;

                const applyDismissToCollection = (items: Array<PlusPitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }

                let testedPitches = applyDismissToCollection(subState.testedPitches, squadPitchId);


                dispatch(stateController.setState({
                    testedPitches: testedPitches,
                }))

                dispatch(CommonController.Actions.loadCounters());
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(x => x != squadPitchId),
                }))
            }
        }
    }

    public static loadPlusPitches(cancelToken?: CancelToken) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            const pitches = await PlusPitchService.getPlusPitches(cancelToken);            
            if (pitches) {
                dispatch(stateController.setState({
                    activePitches: pitches.active,
                    testedPitches: pitches.tested,
                    initialized: true,
                    isLoading: false
                }))

                dispatch(CommonController.Actions.ensurePreloadStatistic(() => { }));
            }
        }
    }

    public static loadSavedPlusPitches(cancelToken?: CancelToken) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            const savedPitches = await PlusPitchService.getSavedPlusPitches(cancelToken);
            if (savedPitches) {
                savedPitches.forEach(item => item.uid = _.uniqueId('plus-pitch-'));

                dispatch(stateController.setState({
                    savedPitches: savedPitches,
                    isLoading: false,
                    initialized: true
                }));
            }
        }
    }

    private static getPitch(id: number, pitches: Array<PlusPitchModel>): PlusPitchModel {
        let pitch = null;
        pitches.map(p => {
            if (p.pitchId == id) {
                pitch = p;
            }
        });

        return pitch;
    }



    public static declareInterest(pitchPageType: PitchPageType, pitchSquadId: number, personId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.plusPitch;
            let shortlistedPlayers = getState().playerAdPage.common.shortListedPlayers

            dispatch(stateController.setState({
                processingIds: [...subState.processingIds, pitchSquadId],
                declareInterestProcessingIds: [...subState.declareInterestProcessingIds, pitchSquadId]
            }))


            try {

                const pitch = this.getPitch(pitchSquadId, [
                    ...subState.activePitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                const isPlayerPitch = pitch.player !== null;
                
                if (!shortlistedPlayers.includes(personId) && isPlayerPitch) {
                    dispatch(Actions.togglePlayerInShortList(PitchPageType.plusPitch, personId, pitchSquadId));
                }

                const isSaved = subState.savedPitches.some(item => item.pitchId === pitchSquadId)
                if (!isSaved) {
                    await PlusPitchService.savePitch(pitchSquadId);
                }

                await PlusPitchService.declareInterest(pitchSquadId);

                subState = getState().playerAdPage.plusPitch;

                const applyDeclareInterestToCollection = (items: Array<PlusPitchModel>, processingId: number) => {
                    return [...items.map(
                        x => {
                            if (x.pitchId == processingId) {
                                return { ...x, saved: true, interestDeclared: true }
                            }
                            else return x
                        })
                    ]
                }

                const applyDismissToCollection = (items: Array<PlusPitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }

                let savedPitches = applyDeclareInterestToCollection(subState.savedPitches, pitchSquadId);
                let testedPitches = applyDismissToCollection(subState.testedPitches, pitchSquadId);
                let activePitches = applyDismissToCollection(subState.activePitches, pitchSquadId);


                dispatch(stateController.setState({
                    savedPitches: savedPitches,
                    testedPitches: testedPitches,
                    activePitches: activePitches
                }))

                dispatch(Actions.userActivityInsert(pitchPageType, 'Declared Interest', pitch, ActionType.DeclaredInterest, UserActivityType.Action))

                dispatch(CommonController.Actions.loadCounters());
                resolve(true)
            } catch (e) {
                console.log(e)
                reject(e)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(x => x != pitchSquadId),
                    declareInterestProcessingIds: subState.declareInterestProcessingIds.filter(x => x !== pitchSquadId)
                }))
            }
        })
    }

    public static savePitch(pitchPageType: PitchPageType, pitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.plusPitch;

            try {

                const pitch = this.getPitch(pitchSquadId, [
                    ...subState.activePitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                dispatch(stateController.setState({ processingIds: [...subState.processingIds, pitchSquadId] }))
                await PlusPitchService.savePitch(pitchSquadId);
                subState = getState().playerAdPage.plusPitch;

                const applyDismissToCollection = (items: Array<PlusPitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }

                let activePitches = applyDismissToCollection(subState.activePitches, pitchSquadId);

                dispatch(stateController.setState({
                    activePitches: activePitches,
                }))

                dispatch(CommonController.Actions.loadCounters());

                dispatch(Actions.userActivityInsert(pitchPageType, 'Saved Plus Pitch', pitch, null))

                resolve(true)
            } catch (error) {
                console.error(error)
                reject(error)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(x => x != pitchSquadId),
                }))
            }
        })
    }

    public static dismissPitch(pitchPageType: PitchPageType, pitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.plusPitch;

            try {

                const pitch = this.getPitch(pitchSquadId, [
                    ...subState.activePitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                dispatch(stateController.setState({ processingIds: [...subState.processingIds, pitchSquadId] }))
                await PlusPitchService.dismiss(pitchSquadId);
                subState = getState().playerAdPage.plusPitch;

                const applyDismissToCollection = (items: Array<PlusPitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }

                let savedPitches = applyDismissToCollection(subState.savedPitches, pitchSquadId);
                let testedPitches = applyDismissToCollection(subState.testedPitches, pitchSquadId);
                let activePitches = applyDismissToCollection(subState.activePitches, pitchSquadId)

                dispatch(stateController.setState({
                    savedPitches: savedPitches,
                    testedPitches: testedPitches,
                    activePitches: activePitches
                }))

                dispatch(CommonController.Actions.loadCounters());

                const message = pitchPageType === PitchPageType.plusPitch ? 'Dismissed Plus Pitch' : pitchPageType === PitchPageType.plusSavedPitch ? 'Removed Pitch' : 'Deleted Saved Pitch'
                dispatch(Actions.userActivityInsert(pitchPageType, message, pitch, null))

                resolve(true)
            } catch (error) {
                console.error(error)
                reject(error)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(x => x != pitchSquadId),
                }))
            }
        })
    }

    public static newMessage(pitchId: number, isSavedMode: boolean) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            let pitch = state.playerAdPage.plusPitch.testedPitches.find(x => x.pitchId == pitchId);
            if (!pitch) pitch = state.playerAdPage.plusPitch.savedPitches.find(x => x.pitchId == pitchId);
            if (pitch != null) {
                dispatch(this.userActivityInsert(isSavedMode ? 'Saved Plus Pitch' : 'Test Plus Pitch', 'Opened New Message', pitch, null));
            }
            const isPlayerhSession = pitch.clubStaff === null;
            const playerId = isPlayerhSession ? pitch.player.id : null
            const coachId = !isPlayerhSession ? pitch.clubStaff.id : null

            let session = {} as MessageSend.MessageSendSession;
            if (isPlayerhSession) {
                session = {
                    subject: `Re: ${pitch.player.englishShortName} Plus Pitch`,
                    logo: pitch.player.clubInfo.logo,
                    checkBoxTitle: isSavedMode ? null : 'Keep player in saved pitches',
                    officialName: pitch.player.clubInfo.parentSquad.name,
                    leagueName: pitch.player.clubInfo.parentSquadCompetitionName + ", " + pitch.player.clubInfo.parentSquadCompetitionCountry,
                    mesageTypeId: 4,
                }
            }

            if (pitch.isFromAgency) {
                let area = translate(`apiTexts.areas.${pitch.agencyAreaId}`)
                session = {
                    subject: "Re: " + (!isPlayerhSession ? pitch.clubStaff.name : pitch.player.englishShortName) + " Plus Pitch",
                    logo: false,
                    checkBoxTitle: isSavedMode ? null : `Keep ${!isPlayerhSession ? 'Head Coach' : 'player'} in saved pitches`,
                    officialName: pitch.agencySenderInfo.name,
                    leagueName: `Agent${area ? ', ' : ''} ${area}`,
                    mesageTypeId: 4,
                }
            }

            let toSquadId = null;
            if (!pitch.isFromAgency) {
                toSquadId = pitch.player.clubInfo.parentSquad.id;
            }

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SendTestPlusPitchSpecification(dispatch, toSquadId, playerId, pitch.pitchId, isSavedMode, pitch.isFromAgency, (pitch.agencySenderInfo || {}).id, coachId)
                )
            )
        }
    }

    public static userActivityInsert(pageType: string, message: string, pitch: any, actionType: ActionType | null, userActivityType: number = null) {
        return async (dispatch, getState: () => AppState) => {

            if (pitch != null) {
                const coachId = pitch.clubStaff?.id ?? null;
                const playerId = pitch.player?.id ?? null;

                let agencyId = null;
                let clubId = null
                if (pitch.agencySenderInfo) {
                    agencyId = pitch.agencySenderInfo.id;
                    switch (pageType) {
                        case PitchPageType.plusSavedPitch:
                            pageType = "Saved Agents Plus Pitch";
                            break;
                        case PitchPageType.plusPitch:
                            pageType = "Agents Plus Pitch";
                            break;
                        case PitchPageType.plusTestPitch:
                            pageType = "Agents Test Plus Pitch";
                            break;
                        default:
                            break;
                    }
                } else {
                    clubId = playerId ? pitch.player.clubInfo?.parentSquad?.id : pitch.clubStaff.clubInfo?.currentSquad?.id
                }

                dispatch(userActivityInsert({
                    PageName: `Player Ads [${pageType}]`,
                    Message: message,
                    PlayerId: playerId,
                    CoachId: coachId,
                    ClubId: clubId,
                    AgencyId: agencyId,
                    ActionType: actionType,
                    PageType: PageType.PlayerAds,
                    Links: [{ Type: 3, TargetId: pitch.pitchId }],
                    UserActivityType: userActivityType
                }));
            }
        }
    }

    public static togglePlayerInShortList(pageType, playerId, pitchId) {
        return (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage.plusPitch;
            const pitch = this.getPitch(pitchId, [
                ...subState.activePitches,
                ...subState.savedPitches,
                ...subState.testedPitches,
            ])
            let agencyId = null;
            let clubId = null;
            if (pitch != null) {
                if (pitch.agencySenderInfo) {
                    agencyId = pitch.agencySenderInfo.id;
                    switch (pageType) {
                        case PitchPageType.plusSavedPitch:
                            pageType = "Saved Agents Plus Pitch";
                            break;
                        case PitchPageType.plusPitch:
                            pageType = "Agents Plus Pitch";
                            break;
                        case PitchPageType.plusTestPitch:
                            pageType = "Agents Test Plus Pitch";
                            break;
                        default:
                            break;
                    }
                } else {
                    clubId = pitch.player.clubInfo.parentSquad.id
                }
            }

            dispatch(CommontController.Actions.togglePlayerInShortList(pageType, playerId, pitchId, clubId, agencyId, pitch.pitchType))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static onSuitabilityTooltipHover = (pitchPageType: PitchPageType, pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const plusPitchesAll = Selectors.getAllPitches(getState());
            const pitch = plusPitchesAll.find(x => x.pitchId === pitchId)    
    
            dispatch(Actions.userActivityInsert(pitchPageType, 'Opened Coach Suitability Info', pitch, null))
        
        }
    }

    public static onRatingTooltipHover = (pitchPageType: PitchPageType, pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const plusPitchesAll = Selectors.getAllPitches(getState());
            const pitch = plusPitchesAll.find(x => x.pitchId === pitchId)    
    
            dispatch(Actions.userActivityInsert(pitchPageType, 'Opened Coach Rating Info', pitch, null))
        }
    }

    public static openCoachProfile = (pitchPageType: PitchPageType, pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const plusPitchesAll = Selectors.getAllPitches(getState());
            const pitch = plusPitchesAll.find(x => x.pitchId === pitchId)    
            const coachId = pitch.clubStaff?.id

            dispatch(Actions.userActivityInsert(pitchPageType, 'Opened Head Coach Profile', pitch, null))
            openCoachProfileById(coachId)

        }
    }

    public static openAgencyProfile = (pitchPageType: PitchPageType, pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const plusPitchesAll = Selectors.getAllPitches(getState());
            const pitch = plusPitchesAll.find(x => x.pitchId === pitchId)    
            const agencyName = pitch.agencySenderInfo?.name;

            dispatch(Actions.userActivityInsert(pitchPageType, 'Opened Agency Profile', pitch, null))

            openAgencyProfile(agencyName)

        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.playerAdPage.plusPitch
    public static getActivePitches = (state: AppState) => this.getRoot(state).activePitches
    public static getActivePlayerPitches = (state: AppState) => Selectors.getActivePitches(state).filter(item => item.player !== null)
    public static getActiveCoachPitches = (state: AppState) => Selectors.getActivePitches(state).filter(item => item.clubStaff !== null)
    public static getTestedPitches = (state: AppState) => this.getRoot(state).testedPitches
    public static getTestedPlayerPitches = (state: AppState) => Selectors.getTestedPitches(state).filter(item => item.player !== null)
    public static getTestedCoachPitches = (state: AppState) => Selectors.getTestedPitches(state).filter(item => item.clubStaff !== null)
    public static getSavedPitches = (state: AppState) => this.getRoot(state).savedPitches
    public static getAllPitches = (state: AppState) => [...Selectors.getActivePitches(state), ...Selectors.getTestedPitches(state), ...Selectors.getSavedPitches(state)]
    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerAdMyActivity as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};



