import axios from 'axios-config';
import { AgentPlayerRegistration, PlayerInvitationConfirm } from './onboarding-models';

export default class AgencyPlayerOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async validate(guid: string): Promise<string> {
        let result = null;
        try {
            result = await axios.get<string>(`api/v2/AgencyPlayerRegistration/GetPlayerEmail/${guid}`, this.axiosConfig)
        } catch (error) {
            let a = error.response.status == 400;
        }
        return result ? result.data : null;
    }

    public static async validateNew(guid: string): Promise<string> {
        let result = null;
        try {
            result = await axios.get<string>(`api/v2/AgencyPlayerRegistration/GetPlayerName/${guid}`, this.axiosConfig)
        } catch (error) {
            let a = error.response.status == 400;
        }
        return result ? result.data : null;
    }

    public static async getPlayerInfo(guid: string): Promise<PlayerInvitationConfirm> {
        let result = null;
        try {
            result = await axios.get<PlayerInvitationConfirm>(`api/v2/AgencyPlayerRegistration/GetPlayerInfo/${guid}`, this.axiosConfig)
        } catch (error) {
            let a = error.response.status == 400;
        }
        return result ? result.data : null;
    }

    public static async signup(request: AgentPlayerRegistration): Promise<boolean> {
        const { data } = await axios.post<boolean>(
            `api/v2/AgencyPlayerRegistration/Signup`,
            request,
            this.axiosConfig
        );
        return data;
    }
}