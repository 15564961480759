import { createAction } from 'redux-actions';
import { getAuth } from 'store/auth/authReducer';
import createApiThunk from '../../../services/createApiThunk';
import {
    SHORTLIST_FETCH,
    SHORTLIST_PLAYER_SELECTED,
    SHORTLIST_FILTER_DATA_FETCH,
    SHORT_LIST_SCREEN_DISPOSE,
    SHORTLIST_FILTER_CHANGED,
    SHORTLIST_FILTER_CLEAR,
    SHORTLIST_FILTER_COLLAPSE_TOGGLE
} from 'store/actionTypes';
import { getPagination } from '../../pagination/reducers/pagination.reducer';

export const shortListFilterChanged = createAction(
    SHORTLIST_FILTER_CHANGED,
);

export const disposePlayerSearchScreenState = () => dispatch => {
    return dispatch(createAction(SHORT_LIST_SCREEN_DISPOSE)());
};

export const shortListFilterCollapseToggle = createAction(
    SHORTLIST_FILTER_COLLAPSE_TOGGLE,
);

export const shortListFilterClear = createAction(SHORTLIST_FILTER_CLEAR);

export const shortListPlayerSelected = createAction(SHORTLIST_PLAYER_SELECTED);

export function shortListFetch(page) {
    return async (dispatch, getState) => {
        const state = getState();
        const { clubId, squadId, userId } = getAuth(state);
        const criteria = { ...state.shortList.criteria };

        const pagination = getPagination('shortList')(state);

        const defaultPagination = {
            currentPageNumber: 1,
            pageSize: 10000,
        };
        
        const payload = pagination.pageSize
            ? {
                  ...criteria,
                  ...pagination,
                  currentPageNumber: page ? page : pagination.currentPageNumber,
                  playersHasShortList: true,
              }
            : { ...criteria, ...defaultPagination, playersHasShortList: true };

        const request = {
            type: SHORTLIST_FETCH,
            scope: 'shortList',
            method: 'post',
            url: `Suggestion/GetShortListSuggestion/${clubId}/${squadId}/${userId}`,
            payload,
        };

        const requestGetFilterData = {
            type: SHORTLIST_FILTER_DATA_FETCH,
            scope: 'shortList',
            method: 'get',
            url: `MySquad/GetFilterInitData/${clubId}/${squadId}/${userId}`,
            payload,
        };

        await dispatch(createApiThunk(requestGetFilterData));
        await dispatch(createApiThunk(request));
        
    };
}
