import axios from 'axios-config';
import { PlayersListModel, PlayersSearchParameters, PositionModel } from "./models";

export default class PlayersService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  private static axiosConfigWithFile = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }

  public static async getPlayersNew(searchParameters: PlayersSearchParameters): Promise<PlayersListModel> {
    const { data } = await axios.post(
      `api/v2/AdminPlayers/GetPlayers`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

  public static async toggleActivatePlayer(aspId: string, playerId: number, isActive: boolean): Promise<boolean> {
    const { data } = await axios.post(
      isActive ? `api/AdminPlayers/ActivatePlayer/${aspId}` : `api/AdminPlayers/DeactivatePlayer/${aspId}`,
      playerId,
      this.axiosConfig
    )
    return data;
  }

  public static async showPlayer(aspId: string, playerId: number, isParent: boolean): Promise<boolean> {
    const { data } = await axios.post(
      `api/AdminPlayers/ShowPlayer/${aspId}/${isParent}`,
      playerId,
      this.axiosConfig
    )
    return data;
  }

  public static async hidePlayer(aspId: string, playerId: number, isParent: boolean): Promise<boolean> {
    const { data } = await axios.post(
      `api/AdminPlayers/HidePlayer/${aspId}/${isParent}`,
      playerId,
      this.axiosConfig
    )
    return data;
  }

  public static async assignSquad(playerId: number, squadId: number, squadName: string, isParent: boolean) {
    let methodName= '';
    if (isParent) {
      methodName = "UpdateParentSquad";
    } else {
      methodName = "UpdateCurrentSquad";
    }
    const { data } = await axios.post(
      `api/AdminPlayers/${methodName}/${playerId}/${squadId || ''}`,
      {
        playerId,
        squadId,
        squadName,
        isParent
      },
      this.axiosConfig
    )
    return data;
  }

  public static async uploadPlayerImage(playerId, file) {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);

    const { data } = await axios.post(
      `api/AdminPlayers/UploadImage/${playerId}`,
      bodyFormData,
      this.axiosConfigWithFile
    )
    return data;
  }

  public static async deletePlayerImage(playerId) {
    const { data } = await axios.post(
      `api/AdminPlayers/DeleteImage/${playerId}`,
      null,
      this.axiosConfig
    )
    return true;
  }

  public static async updatePlayerPosition(playerId, positionCode, isFirstPosition) {
    const url = isFirstPosition ? `api/AdminPlayers/SetPlayerFirstPosition/${playerId}/${positionCode}` : `api/AdminPlayers/SetPlayerSecondPosition/${playerId}/${positionCode}`;

    const { data } = await axios.post(
        url,
        null,
        this.axiosConfig
    )
    return true;
  }

  public static async getPositions(): Promise<any> {
    return await axios.get(
        `api/Positions/GetPositions`,
        this.axiosConfig
    );
  }

  public static async applyContractExpire(playerId) {
    const { data } = await axios.post(
        `api/AdminPlayers/ApplyPlayerContract`,
        playerId,
        this.axiosConfig
    )
    return true;
  }

  public static async deleteContractExpire(playerId) {
    const { data } = await axios.post(
        `api/AdminPlayers/RejectPlayerContract`,
        playerId,
        this.axiosConfig
    )
    return true;
  }

}
