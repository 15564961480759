import React, { Component } from 'react';
import { connect } from 'react-redux';
import historyAccessor from 'history-accessor';
import { agencyPaths, userPaths } from 'routes/paths.js';

import { Attendee } from 'app/events/redux/virtual-summit/confirmed-attendees.controller'
import { AppState } from 'root.reducer';

import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

import { previousEventClubs } from 'app/events/components/authorized-event-page/common/previous-attendees.data'
// import virtualSummitBackground from 'media/images/events/section-06-blue.jpg'

import Spinner from 'components/Spinner/Spinner';
import WhyAttend from 'app/events/components/authorized-event-page/common/why-attend/why-attend'
import Agenda from 'app/events/components/authorized-event-page/common/agenda/agenda'
import SmallRegularBanner from 'app/events/components/authorized-event-page/common/small-banner/regular-banner'
import PastEvents from 'app/events/components/authorized-event-page/common/past-events/past-events'
import Speakers from 'app/events/components/authorized-event-page/common/speakers/speakers';
import Replay from 'app/events/components/authorized-event-page/common/video-replay/replay'
import Attendees from 'app/events/components/authorized-event-page/common/attendees/attendees'
import AgencyBanner from '../banner/agency-banner'
import DividerLine from 'app/events/components/authorized-event-page/common/divider-line/divider-line'
import FloatingCTA from 'app/events/components/authorized-event-page/common/floating-cta/floating-cta'
import { PageType } from 'constants/enums';
import { testimonials } from 'app/events/components/authorized-event-page/common/why-attend/data/testimonials';
import { agencyBenefits } from 'app/events/components/authorized-event-page/common/why-attend/data/benefits';
import { getAuth } from 'store/auth/authReducer';
import AuthState from 'store/auth/auth-state';

class StateProps {
    isLoading: boolean;
    confirmedClubs: Array<Attendee>;
    isRegistered: boolean;
    pendingAgencyRequest: boolean;
    auth: AuthState;
}
class DispatchProps {
    userActivityInsert: (obj: any) => void;
}

class EventPageNotRegistered extends Component<StateProps & DispatchProps, {}> {

    state = {
        activeIndex: 0,
        attendees: null,
        modalVideo: false,
        modalMap: false,
    };

    toggle = modal => {
        this.setState({
            [modal]: !this.state[modal],
        });
    };

    render() {

        const { isLoading, isRegistered, confirmedClubs, pendingAgencyRequest, auth } = this.props;

        // Boolean describes whether to show or hide attendees component,
        // while user see 'not registered' event page view
        const canShowAttendeesComponent = true

        // Boolean describes whether to show current event confirmed attendees or previous attendees (mocked data),
        // Clubs is hidden while there are small amount of registered clubs
        const shouldShowConfirmedClubs = false;

        return (
            <div className="authorized-event-page">
                {isLoading && <Spinner />}
                {/* <AgencyBanner 
                    isRegistrationClosed={true}
                    isLoading={isLoading}
                    isRegistered={isRegistered}
                    pendingAgencyRequest={pendingAgencyRequest}
                    title={'Virtual Summit'}
                    date={'10th November 2021'}
                    eventLocation={'Online (Virtual)'}
                    onRegister={() => {
                        this.props.userActivityInsert({ 
                            Message: 'Register Now', 
                            PageName: 'Event',
                            PageType: PageType.AgencyEvents 
                        })
                        historyAccessor.push(agencyPaths.eventRegistration)
                    }}
                /> */}
                <div style={{ height: 70 }} />
                <div className="events-page-wrapper">
                    <div className="row">

                        {/* ---> Add or remove className "big-width" to change basic latout*/}
                        <div className={`col-left content big-width ${canShowAttendeesComponent ? '' : 'single-col-view'}`}> {/* ---> Add or remove className "big-width" to change basic latout*/}
                            <Replay trackUserActivity={() => this.props.userActivityInsert({
                                PageName: 'Event',
                                Message: 'Opened video (4th Virtual Summit)'
                            })} />
                            {/* {!isRegistered && <Speakers />} */}
                            {/* <FloatingCTA userActivityInsert={this.props.userActivityInsert} isLoading={isLoading} isUserRegistered={isRegistered} isAgencyPage={true} eventId={auth.eventId} /> */}

                            {/* <WhyAttend isAgencyRegistered={isRegistered} benefits={agencyBenefits} testimonials={testimonials} /> */}

                            {/* <Agenda forAgency /> */}

                            {/* UPCOMING EVENTS */}
                            {/* <div className="row small-banner-section">
                                <SmallRegularBanner
                                    style={{width: '100%'}}
                                    isRegistrationClosed={false}
                                    isRegistered={isRegistered}
                                    eventName="Virtual Summit"
                                    location="Online"
                                    dayDate="8th"
                                    monthDate="December 2020"
                                    ctaText={'Register Now!'}
                                    ctaTextCompleted={'Registered'}
                                    isGreenButton
                                    // backgroundImageSrc={virtualSummitBackground}
                                    onCTAClick={() => {
                                        this.props.userActivityInsert({ 
                                            Message: 'Register Now', 
                                            PageName: 'Event [Banner]',
                                            PageType: PageType.AgencyEvents
                                        })
                                        historyAccessor.push(userPaths.eventAddExtraAttendees)
                                    }}
                                    // additionalLocation="Online"
                                    // showMapLink="https://www.google.com/maps/place/Stamford+Bridge/@51.4816663,-0.1931452,17z/data=!3m1!4b1!4m5!3m4!1s0x48760f864b976f3d:0x48aa38781ea565f8!8m2!3d51.481663!4d-0.1909565"
                                />
                            </div> */}

                            <DividerLine title="Past Events" />
                            <PastEvents />
                        </div>


                        {canShowAttendeesComponent && (
                            <div className="col-right small-width"> {/* ---> Add or remove className "small-width" to change basic latout*/}
                                <Attendees
                                    title={shouldShowConfirmedClubs ? "Confirmed Attendees" : "Previous Attendees"}
                                    titleWrap={false}
                                    notRegisteredState // configure sticky position for mobile devices
                                    attendees={shouldShowConfirmedClubs ? confirmedClubs : previousEventClubs}
                                    showDetails={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    isLoading: state.events.agencyEvent.isLoading,
    isRegistered: state.events.agencyEvent.isUserRegistered,
    confirmedClubs: state.events.virtualSummit.confirmedAttendees.attendees,
    pendingAgencyRequest: state.events.agencyEvent.isPendingRegistration,
    auth: getAuth(state)
});

const mapDispatchToProps: DispatchProps = {
    userActivityInsert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPageNotRegistered);

