import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components'

import { londonAttendes } from 'app/events/components/shared/data/londonEventAttendes';
import { attendeesMadridNovember, vsSummit, vsDealDay } from 'app/events/components/shared/data/attendeesMadridNovember';
import attendeesVirtualSummitSep2020 from 'app/events/components/shared/data/virtualSummitSep2020'
import {
    secondWinterDealDayAttendees, virtualSummit24thMarchAttendees, virtualDealDay9thJuneAttendees,
    virtualDealDay11thAugustAttendees, virtualSummit10thNovemberAttendees
} from '../../../shared/data/past-events-clubs';
import { EventTitle } from './components/past-event-title'
import { PastEventStats } from './components/past-event-stats'
import { PastEventClubLogos } from './components/past-event-club-logos'
import { EventPhotosSidebar } from './components/past-event-photos-sidebar'
import { PastEventVideoVimeo } from './components/past-event-video'
import { PastEventMainPhoto } from './components/past-event-main-photo'
import AmericasSummit14_15thFebruary from './components/events/americas-summit-14-15th-february'
import MadridSummit21_22ndMarch from './components/events/madrid-summit-21-22nd-march'
import MadridSummit16thJune from './components/events/madrid-summit-16th-june'
import VirtualDealDay1stAugust from './components/events/virtual-deal-day-1st-august';
import BerlinSummit13_16thNovember from './components/events/berlin-summit-13-16th-november';
import VirtualDealDay16thJanuary from './components/events/virtual-deal-day-16th-january-2023';
import LondonSummitMarch2023 from './components/events/london-summit-march-2023';
import SaoPaoloSummitJune2023 from './components/events/sao-paolo-summit-june-2023';
import VirtualDealDayAugust2023 from './components/events/virtual-deal-day-august-2023';
import LisbonSummitNovember2023 from './components/events/lisbon-summit-november-2023';
import VirtualDealDayJanuary2024 from './components/events/virtual-deal-day-january-2024';
import RomeSummitMarch2024 from './components/events/rome-summit-march-2024';
import CancunSummitJune2024 from './components/events/cancun-summit-june-2024';
import MadridSummitAugust2024 from './components/events/madrid-summit-august-2024';

import Panel from 'components/Panel';
import { translate } from 'services/localization';
import { photos_5, photos_4, photos_3, attendees_3, attendees_4, vs_photo } from './data'
import firstVirtualDealDayImage from 'media/images/events/dealday_29_07_2020.png'
import secondDealDayImage from 'media/images/events/dealday_16_07_2019.jpg'
import firstVirtualSummitImage from 'media/images/events/summit_4_06_2020.png'
import fourthSummitImage from 'media/images/events/summit_19_03_2019.jpg'
import secondVirtualSummitImage from 'media/images/events/virtualSummit_16_09_2020.png'
import secondWinterDealDayImage from 'media/images/events/2nd-winter-deal-day-img.png';
import virtualSUmmit24thMarchImage from 'media/images/events/virtual-summit-24th-march.png';
import thirdVirtualDealDayImage from 'media/images/events/third-virtual-deal-day-thumb.png';
import fourthVirtualDealDayImage from 'media/images/events/fourth-virtual-deal-day.png';
import fourthVirtualSummitImage from 'media/images/events/fourth-virtual-summit.png';
import { getAuth } from 'store/auth/authReducer';
import { AppState } from 'root.reducer';
import AuthState from 'store/auth/auth-state';
import { 
    AMERICAS_SUMMIT_14_15TH_FEBRUARY_2022_ID,
    BERLIN_SUMMIT_13TH_16TH_NOVEMBER_2022_ID,
    LONDON_SUMMIT_MARCH_2023_ID,
    MADRID_SUMMIT_21ST_22ND_MARCH_2022_ID,
    MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID,
    SAO_PAOLO_SUMMIT_JUNE_2023_ID,
    VIRTUAL_DEAL_DAY_AUGUST_2022_ID,
    VIRTUAL_DEAL_DAY_AUGUST_2023_ID,
    VIRTUAL_DEAL_DAY_JANUARY_2023_ID,
    LISBON_SUMMIT_NOVEMBER_2023_ID,
    VIRTUAL_DEAL_DAY_JANUARY_2024_ID,
    ROME_SUMMIT_MARCH_2024_ID,
    CANCUN_SUMMIT_JUNE_2024_ID,
    MADRID_SUMMIT_AUGUST_2024_ID,
} from 'app/events/components/authorized-event-page/events-map/constants';

// import './past-events.scss';\

class StateProps {
    auth: AuthState;
}

class DispatchProps {

}

class PastEvents extends Component<StateProps & DispatchProps, any> {

    render() {
        const { auth } = this.props;

        return (
            <Section>
                {auth.userEventIds.includes(MADRID_SUMMIT_AUGUST_2024_ID) && <MadridSummitAugust2024 />}
                {auth.userEventIds.includes(CANCUN_SUMMIT_JUNE_2024_ID) && <CancunSummitJune2024 />}
                {auth.userEventIds.includes(ROME_SUMMIT_MARCH_2024_ID) && <RomeSummitMarch2024 />}
                {auth.userEventIds.includes(VIRTUAL_DEAL_DAY_JANUARY_2024_ID) && <VirtualDealDayJanuary2024 />}
                {auth.userEventIds.includes(LISBON_SUMMIT_NOVEMBER_2023_ID) && <LisbonSummitNovember2023 />}
                {auth.userEventIds.includes(VIRTUAL_DEAL_DAY_AUGUST_2023_ID) && <VirtualDealDayAugust2023 />}
                {auth.userEventIds.includes(SAO_PAOLO_SUMMIT_JUNE_2023_ID) && <SaoPaoloSummitJune2023 />}
                {auth.userEventIds.includes(LONDON_SUMMIT_MARCH_2023_ID) && <LondonSummitMarch2023 />}
                {auth.userEventIds.includes(VIRTUAL_DEAL_DAY_JANUARY_2023_ID) && <VirtualDealDay16thJanuary />}
                {auth.userEventIds.includes(BERLIN_SUMMIT_13TH_16TH_NOVEMBER_2022_ID) && <BerlinSummit13_16thNovember />}
                {auth.userEventIds.includes(VIRTUAL_DEAL_DAY_AUGUST_2022_ID) && <VirtualDealDay1stAugust />}
                {auth.userEventIds.includes(MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID) && <MadridSummit16thJune />}
                {auth.userEventIds.includes(MADRID_SUMMIT_21ST_22ND_MARCH_2022_ID) && <MadridSummit21_22ndMarch />}
                {auth.userEventIds.includes(AMERICAS_SUMMIT_14_15TH_FEBRUARY_2022_ID) && <AmericasSummit14_15thFebruary />}

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="4"
                                bgImageNumber={7}
                                th="th"
                                title1="TransferRoom"
                                title2="Virtual Summit"
                                date="10th November 2021, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={fourthVirtualSummitImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="323"
                                countries="48"
                                leagues="76"
                                agents="27"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={virtualSummit10thNovemberAttendees}
                                countlimit={36}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="4"
                                bgImageNumber={7}
                                th="th"
                                title1="TransferRoom"
                                title2="Virtual Deal Day"
                                date="11th August 2021, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={fourthVirtualDealDayImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="265"
                                countries="41"
                                leagues="65"
                                agents="27"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={virtualDealDay11thAugustAttendees}
                                countlimit={36}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="3"
                                bgImageNumber={7}
                                th="rd"
                                title1="TransferRoom"
                                title2="Virtual Deal Day"
                                date="9th June 2021, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={thirdVirtualDealDayImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="188"
                                countries="43"
                                leagues="64"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={virtualDealDay9thJuneAttendees}
                                countlimit={42}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="3"
                                bgImageNumber={7}
                                th="rd"
                                title1="TransferRoom"
                                title2="Virtual Summit"
                                date="24th March 2021, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={virtualSUmmit24thMarchImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="253"
                                countries="43"
                                leagues="65"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={virtualSummit24thMarchAttendees}
                                countlimit={50}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="2"
                                bgImageNumber={7}
                                th="nd"
                                title1="TransferRoom"
                                title2="Winter Deal Day"
                                date="8th December 2020, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={secondWinterDealDayImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="255"
                                countries="43"
                                leagues="62"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={secondWinterDealDayAttendees}
                                countlimit={50}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="2"
                                bgImageNumber={7}
                                th="nd"
                                title1="TransferRoom"
                                title2="Virtual Summit"
                                date="16th September 2020, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={secondVirtualSummitImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="180"
                                countries="37"
                                leagues="48"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={attendeesVirtualSummitSep2020}
                                countlimit={50}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="1"
                                bgImageNumber={7}
                                th="st"
                                title1="TransferRoom"
                                title2="Virtual Deal Day"
                                date="29th July 2020, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={firstVirtualDealDayImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="190"
                                countries="37"
                                leagues="40"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={vsDealDay}
                                countlimit={50}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="1"
                                bgImageNumber={7}
                                th="st"
                                title1="TransferRoom"
                                title2="Virtual Summit"
                                date="4th June 2020, Online"
                                vs={true}
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%' }} src={firstVirtualSummitImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="228"
                                countries="40"
                                leagues="52"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={vsSummit}
                                countlimit={42}
                            />
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="5"
                                bgImageNumber={6}
                                th="th"
                                title1="TransferRoom"
                                title2={translate('events.summit')}
                                date="11th-12th November 2019, Madrid"
                            />
                            <PastEventMainPhoto images={photos_5} />
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="170"
                                countries="31"
                                leagues="45"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={attendeesMadridNovember}
                                countlimit={42}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper" >
                            <EventTitle
                                number="2"
                                th="nd"
                                bgImageNumber={3}
                                title1={'TransferRoom'}
                                title2={'Deal Day'}
                                date="16th July 2019, London"
                            />  
                           <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%', maxHeight: '256px', borderRadius: '3px' }} src={secondDealDayImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="150"
                                countries="28"
                                leagues="38"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={londonAttendes}
                                countlimit={42}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="4"
                                bgImageNumber={4}
                                th="th"
                                title1="TransferRoom"
                                title2={translate('events.summit')}
                                date="19th March 2019, Barcelona"
                            />
                            <div className="photo-container row" style={{ padding: '0 10%' }}>
                                <img style={{ width: '100%', maxHeight: '256px', borderRadius: '3px' }} src={fourthSummitImage} alt="" />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="145"
                                countries="26"
                                leagues="35"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={attendees_4}
                                countlimit={42}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <Panel className="content-wrapper">
                            <EventTitle
                                number="3"
                                bgImageNumber={3}
                                th="rd"
                                title1="TransferRoom"
                                title2={translate('events.summit')}
                                date="27th March 2018, London"
                            />
                            <div className="video-container">
                                <PastEventVideoVimeo id="303029076" />
                                <EventPhotosSidebar
                                    galleryLink="https://www.flickr.com/photos/160640981@N04/sets/72157702424524591/page1"
                                    images={photos_3}
                                    numberOfPhotos={36}
                                />
                            </div>
                        </Panel>
                    </div>
                    <div className="col-md-4 col-12 row no-gutters">
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventStats
                                participants="145"
                                countries="22"
                                leagues="32"
                            />
                        </div>
                        <div className="col-md-12 col-sm-6 col-12">
                            <PastEventClubLogos
                                attendees={attendees_3}
                                countlimit={42}
                            />
                        </div>
                    </div>
                </div>
            </Section>
        )
    }
}

const Section = styled.div`
    > .row {
        @media (max-width: 767px) {
            margin-bottom: 25px;
        }
    }
    > .row > .row {
        margin-left: 0;
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
        @media (max-width: 767.98px) {
            justify-content: flex-start;
        }
    }
    @media (max-width: 767px) {
        padding: 10px;
    }
    @media (max-width: 499px) {
        > .row {
            margin-right: 0;
            margin-left: 0;
            > [class*="col-"] {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    .content-wrapper {
        max-width: 640px;
        margin-bottom: 60px;
        padding: 40px;
        @media (max-width: 767px) {
            margin-bottom: 25px;
        }
    }
    .photo-container {
        display: flex;
        flex-wrap: wrap;
        margin-right: 0px;
        margin-left: 0px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }
    .video-container {
        display: flex;
        align-items: center;
    }
`

const mapStateToProps = (state: AppState, ownProps): StateProps => ({
    auth: getAuth(state)
})

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PastEvents)