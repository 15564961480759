import {StateController} from 'utils/action-declaration'
import {AppState} from "root.reducer";
import EventsService from "api/admin/events/events.service";
import {EventModel, EventsData} from "api/admin/events/models";

class EventsPracticalState {
    isEventLoading: boolean;
    events: EventModel[];
    selectedEvent: {
        name: string;
        id: number;
    };
    isLoading: boolean;
    eventsData: EventsData[];
    eventDetails: EventsData;
    isOpenDetailsModal: boolean;
    filteredKeyword: string;
    page: number;
    recordsPerPage: number;
    sortOrder: string;
    isSortOrderAscending: boolean;
    total: number;
}

const defaultState = {
    isEventLoading: false,
    events: [],
    selectedEvent: {
        name: '',
        id: null
    },
    isLoading: false,
    page: 1,
    recordsPerPage: 25,
    sortOrder: 'id',
    isSortOrderAscending: true,
    total: 0,
    eventsData: [],
    eventDetails: null,
    isOpenDetailsModal: false,
    filteredKeyword: '',
}

const stateController = new StateController<EventsPracticalState>(
    'ADMIN_V2/EVENT_PRACTICAL',
    defaultState
)

class Actions {
    public static loadActiveEvents = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isEventLoading: true }));
                const events = (await EventsService.getActiveEvents()).data;
                const event = events[0];
                dispatch(stateController.setState((prevState) => {
                    return {
                        ...prevState,
                        events,
                        selectedEvent: {
                            name: event?.name,
                            id: event?.id,
                        }
                    }
                }));
                dispatch(Actions.loadEventsData());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isEventLoading: false }));
            }
        }
    }
    
    public static onPaginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                page: pageNumber,
                recordsPerPage: pageSize,
            })));
            //dispatch(Actions.loadActiveEvents());
        }
    }

    public static loadEventsData = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const {
                    page, 
                    recordsPerPage, 
                    sortOrder, 
                    isSortOrderAscending, 
                    selectedEvent
                } = getState().admin.eventsPractical;
    
                const searchParameters = {
                    page,
                    recordsPerPage,
                    sortOrder,
                    isSortOrderAscending,
                    eventId: selectedEvent.id,
                }
                const eventsData = (await EventsService.getEventsPractical(searchParameters)).data.output;
               
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    eventsData,
                })))
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static saveEventsData = (row: EventsData) => {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().admin.eventsPractical;
            const newData = [...substate.eventsData];//copy existing data
            const index = newData.findIndex(item => row.userId === item.userId);//find index that needs to be replace
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });//replace data at given index
            dispatch(stateController.setState((prevState) => {
                return({
                    ...prevState,
                    eventsData: newData,
                })
            }));
            await EventsService.setEventsPractical(row);
        }
    }

    public static setSelectedEvent = (id: number, name: string) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return({
                    ...prevState,
                    selectedEvent: {
                        id,
                        name,
                    }
                })
            }));
        }
    }

    public static setFilterKeyword = (filteredKeyword: string) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return({
                    ...prevState,
                   filteredKeyword,
                })
            }));
        }
    }

    public static onCheckbox = (item: EventsData, value) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                item[value] = !item[value];
                await EventsService.setEventsPractical(item);
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static openDetailsModal = (data: EventsData) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return({
                    ...prevState,
                    eventDetails: data,
                    isOpenDetailsModal: true,
                })
            }));
        }
    }

    public static closeDetailsModal = () => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return({
                    ...prevState,
                    eventDetails: null,
                    isOpenDetailsModal: false,
                })
            }));
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.eventsPractical;
    public static getActiveEvents = (state: AppState) => Selectors.selectState(state).events;
    public static getSelectedEvent = (state: AppState) => Selectors.selectState(state).selectedEvent;
    public static isEventsLoading = (state: AppState) => Selectors.selectState(state).isEventLoading;
    public static getEventsData = (state: AppState) => Selectors.selectState(state).eventsData;
    public static isLoading = (state: AppState) => Selectors.selectState(state).isLoading;
    public static isDetailsModalOpen = (state: AppState) => Selectors.selectState(state).isOpenDetailsModal;
    public static getEventDetails = (state: AppState) => Selectors.selectState(state).eventDetails;
    public static getFilteredKeyword = (state: AppState) => Selectors.selectState(state).filteredKeyword;
    public static getFilteredEventsData(state: AppState, filterKey) {
        const items = state.admin.eventsPractical.eventsData;
        const keyword = state.admin.eventsPractical.filteredKeyword;
        return items.filter(item => (item[filterKey] && item[filterKey].toLowerCase() || '').includes(keyword.toLowerCase()))
    }
    public static getPageNumber = (state: AppState) => Selectors.selectState(state).page;
    public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
    public static getTotalRecords = (state: AppState) => Selectors.selectState(state).total;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    EventsPracticalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}