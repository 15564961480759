import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'



class AdminNavBarState {
    currentPage: string
}

const defaultState = {
    currentPage: '',
}

const stateController = new StateController<AdminNavBarState>(
    'ADMIN_V2/NAV-BAR',
    defaultState
)

class Actions {

    public static setCurrentPage(page: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentPage: page}))
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminNavBarState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}