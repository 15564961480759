import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppState } from 'root.reducer'
import userActivityInsert, { UserActivity } from 'app/user-activity/actions/user-activity.actions';

import { Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'

// import Spinner from 'components/Spinner/Spinner';
import Speakers from '../../../common/speakers/speakers'

// import Speakers from 'app/events/components/authorized-event-page/common/speakers/speakers'
import WhyAttend from 'app/events/components/authorized-event-page/common/why-attend/why-attend'

import Attendees from 'app/events/components/authorized-event-page/common/attendees/attendees'
import PastEvents from 'app/events/components/authorized-event-page/common/past-events/past-events'

import { IdName } from 'api/agency/agent/landing/agency-dashboard'
import DividerLine from 'app/events/components/authorized-event-page/common/divider-line/divider-line'
import { testimonials } from '../../../data/testimonials';

import { benefits as clubBenefits } from '../../../data/benefits';
import { eventClubs as previousEventClubs } from '../../../data/event-attendee';

import PreviewVideo from 'app/events/components/authorized-event-page/common/preview-video/preview-video';

import Agenda from '../../../common/agenda/agenda'
import AttendanceDetails from './attendance-details/attendance-details'
import { getAuth } from 'store/auth/authReducer';
import AuthState from 'store/auth/auth-state';

import Video from 'app/events/components/authorized-event-page/common/video/video'
import { EventAgendaDayModel } from 'api/virtual-summit/models/event-agenda';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';

interface StateProps {
    isLoading: boolean;
    upcommingEventData: object;
    attendees: Array<any>;
    trustedAgents: Array<{ id: number, name: string }>;
    invitedAgencySuggestionCount: number;
    auth: AuthState,
    agendaDays: EventAgendaDayModel[];
    pendingAgencyRequest: boolean;
}
interface DispatchProps {
    userActivityInsert: (obj: UserActivity) => void;
}

class TabAttendance extends Component<StateProps & DispatchProps, {}> {
    render() {

        const { isLoading, upcommingEventData, attendees, trustedAgents, invitedAgencySuggestionCount, agendaDays } = this.props; //isRegistered

        const {
            isTransferIn,
            isTransferOut,
            isGeneralNetworking,
            transferInStepCompleted,
            transferOutStepCompleted,
            generalNetworkingStepCompleted,
            generalNetworkingClubs,
            transferOutPlayers,
            positionNames,

            registerNowVisible,
            user,
            isUserRegistered,
            extraAttendees,
            isRewardComplete,
            offlineEventTimeZone
        } = upcommingEventData as any

        const shouldRegister = registerNowVisible

        // const isObjectivesDefined = isTransferIn || isTransferOut || isGeneralNetworking
        // const isUserCompletedAnyStep = transferInStepCompleted || transferOutStepCompleted || generalNetworkingStepCompleted

        // const registeredAttendees = [];
        // if (extraAttendees && extraAttendees.length > 0) {
        //     if (isUserRegistered) {
        //         registeredAttendees.push(user.name)
        //     }
        //     extraAttendees.filter(i => i.isRegistered && i.name !== null).forEach(i => {
        //         registeredAttendees.push(i.name)
        //     })
        // }


        // // Boolean describes whether to show attendees or hide them
        // const canShowAttendees = true;

        // // Boolean describes whether to show current event confirmed attendees or previous attendees (mocked data),
        // // Attendees is hidden while there are small amount of registered clubs
        // const shouldShowConfirmedAttendees = false;

        const { eventId } = this.props.auth;
        const eventInfo = getEventInfo(eventId);
        const { canShowAttendeesComponent, shouldShowConfirmedAttendees } = eventInfo.visibility;

        return (
            <>
                <div className="authorized-event-page">
                    <div className="row" >
                        <div className={`col-left content ${canShowAttendeesComponent ? '' : 'single-col-view'}`}> {/* ---> Add or remove className "big-width" to change basic latout*/}
                            {shouldRegister && <WhyAttend benefits={clubBenefits} testimonials={testimonials} />}
                            {!shouldRegister && (
                                <AttendanceDetails
                                    details={{
                                        isTransferIn,
                                        isTransferOut,
                                        isGeneralNetworking,
                                        transferInStepCompleted,
                                        transferOutStepCompleted,
                                        generalNetworkingStepCompleted,
                                        generalNetworkingClubs,
                                        transferOutPlayers,
                                        positionNames,
                                        isRewardComplete,
                                        trustedAgents,
                                        invitedAgencySuggestionCount
                                    }}
                                />
                            )}
                            {/* <Speakers /> */}
                            
                            {/* <AdditionalInfo /> */}

                            {/* <Video
                                trackUserActivity={() => { }}
                                content={{
                                    backgroundThumb: Thumb,
                                    videoUrl: 'https://player.vimeo.com/video/696040095'
                                }}
                            /> */}

                            <Agenda agendaDays={agendaDays} />

                            {/* UPCOMING EVENT */}
                            {/* <div style={{ marginBottom: 40 }}>
                                <RegularSmallBanner
                                    isRegistered={!shouldRegister}
                                    eventName="Virtual Deal Day 2020"
                                    location="Online"
                                    dayDate="4th"
                                    monthDate="June"
                                    isGreenButton
                                    ctaText={translate('events.registerNow') + "!"}
                                    ctaTextCompleted={translate('events.registrationCompleted')}
                                    backgroundImageSrc={virtualSummitBackground}
                                    onCTAClick={() => {
                                        historyAccessor.push(userPaths.summitConfirm)
                                        this.props.userActivityInsert({ 
                                            Message: 'Register Now', 
                                            PageName: 'Event [Banner]',
                                            PageType: PageType.Events,
                                              EventId: this.props.auth.eventId,
                                        })
                                    }}
                                />
                            </div> */}


                            <DividerLine title="Past Events" />
                            <PastEvents />
                        </div>

                        {canShowAttendeesComponent && (
                            <div className="col-right"> {/* ---> Add or remove className "small-width" to change basic latout*/}

                                <Attendees
                                    title={shouldShowConfirmedAttendees ? "Confirmed Attendees" : "Previous Attendees"}
                                    titleWrap={true}
                                    showDetails={true}
                                    attendees={shouldShowConfirmedAttendees ? attendees : previousEventClubs}
                                />
                            </div>
                        )}

                    </div>
                    <div className="past-events-wrapper--single-centered-col">
                        {/* <PastEvents /> */}
                    </div>
                </div>
                <div className="events__bottom" />
            </>
        )
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    const { upcommingSummitData, agendaDays } = state.events.misc;
    return {
        isLoading: MiscEventSelectors.selectIsLoading(state),
        upcommingEventData: upcommingSummitData,
        attendees: state.events.virtualSummit.confirmedAttendees.attendees,
        trustedAgents: upcommingSummitData.invitedAgncies,
        invitedAgencySuggestionCount: upcommingSummitData.invitedAgncySuggestionCount,
        pendingAgencyRequest: upcommingSummitData.pendingAgencyRequest,
        auth: getAuth(state),
        agendaDays: agendaDays
    }
}

const mapDispatchToProps: DispatchProps = {
    userActivityInsert
}

export default connect(mapStateToProps, mapDispatchToProps)(TabAttendance)
