import axios from "axios-config";
import { NewUserData, NewUserRequest } from "api/admin/add-user-modal/models";

export class AddUserModalService {
  private static axiosConfig = {
    headers: { "Content-Type": "application/json" },
  };

  public static async loadAllowedAreas(userId: number): Promise<Array<any>> {
    const { data } = await axios.get(
      `api/AdminPagePermissions/GetAllowedAresForUserRegistration/${userId}`,
      this.axiosConfig
    );
    return data;
  }

  public static async addNewUser(
    userData: NewUserData
  ): Promise<NewUserRequest> {
    const { data } = await axios.post(
      `api/Users/AddUserProfileV2`,
      { ...userData },
      this.axiosConfig
    );
    return data;
  }
}
