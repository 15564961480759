import * as redux from 'redux';

import * as ClubRegistrationController from './club-registration.controller';
import * as AgentRegistrationController from './agent-registration.controller';

export class RegistrationState {
    club: ClubRegistrationController.State;
    agent: AgentRegistrationController.State;
    
}

export function combineReducers() {

    let reducers = {
        club: ClubRegistrationController.Reducer,
        agent: AgentRegistrationController.Reducer
    };

    return redux.combineReducers(reducers)
} 