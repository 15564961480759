import axios from 'axios-config';
import { PitchGroupModel } from './models/pitch-group.model';
import { PitchesAndAds, PitchModel } from './models/pitch.model';
import { CancelToken } from 'axios'

class GetPitchesResponse {
    groupedPitches: Array<PitchGroupModel>;
    testInterestedPitches: Array<PitchModel>;
}

export default class PitchV2Service {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async sendAgentMessage(squadPitchId: number, subject: string, message: string) {
        await axios.post(
            `api/v2/Pitch/SendMessage`,
            { subject: subject, pitchId: squadPitchId, message: message },
            this.axiosConfig
        )
    }

    public static async GetPitches(cancelToken?: CancelToken): Promise<GetPitchesResponse> {
        const { data } = await axios.get(
            `api/v2/Pitch/GetPitches`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data;
    }

    public static async GetSavedPitches(cancelToken?: CancelToken): Promise<PitchesAndAds> {
        const { data } = await axios.get(
            `api/v2/Pitch/GetSavedPitches/v2`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async savePitch(id: number): Promise<any> {
        await axios.post(
            `api/v2/Pitch/Save`,
            id,
            this.axiosConfig
        )
    }

    public static async declareInterest(id: number): Promise<any> {
        await axios.post(
            `api/v2/Pitch/DeclareInterest`,
            id,
            this.axiosConfig
        )
    }

    public static async dismiss(id: number): Promise<any> {
        await axios.post(
            `api/v2/Pitch/Dismiss`,
            id,
            this.axiosConfig
        )
    }

    public static async remove(id: number): Promise<any> {
        await axios.post(
            `api/v2/Pitch/Remove`,
            id,
            this.axiosConfig
        )
    }

    public static async deleteTestInterest(id: number) {
        await axios.post(
            `api/v2/Pitch/DeleteTestInterest`,
            id,
            this.axiosConfig
        )
    }
} 