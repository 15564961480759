import * as types from 'store/actionTypes';

const initialState = {
    season: null,
    competition: '',
    apps: null,
    startingApps: null,
    goals: null,
    assists: null,
    minPerGoal: null,
    minPerAssist: null,
    totalMinPlayed: null,
    avgMinPlayed: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PERFORMANCE_FETCH.SUCCESS:
            return {
                ...state,
                performance: action.payload.data,
            };
        case types.SELECTED_SEASON:
            return {
                ...state,
                season: action.payload,
            };

        default:
            return state;
    }
};
