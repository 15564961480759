import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { commonPagesPaths } from 'routes/paths';
import CommonService from 'api/common/common.service'
import { hideTermsAndConditions } from "store/auth/auth.actions";
import { AgreeDocumentsUserTypeEnum } from 'api/common/models';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

class AgreeDocumentsModalState {
    isProcessing: boolean;
    isModalOpen: boolean;
    isAgreedTermsOfService: boolean;
    isAgreedHealthConsent: boolean;
}


const defaultState: AgreeDocumentsModalState = {
    isProcessing: false,
    isModalOpen: false,
    isAgreedTermsOfService: false,
    isAgreedHealthConsent: false,
}

const stateController = new StateController<AgreeDocumentsModalState>(
    "PLAYERV2/AGREE-DOCUMENTS",
    defaultState
)

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const isModalCanBeShown = getState().auth.showTermsAndConditionsModal
            if (isModalCanBeShown) {
                dispatch(stateController.setState({ isModalOpen: true }))
            }
        }
    }

    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            dispatch(userActivityInsert({
                Message: message,
                PageName: pageName,
                PlayerId: playerId,
            }))
        }
    }

    public static openAgreeDocumentsModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isModalOpen: true }))
        }
    }

    public static closeAgreeDocumentsModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isModalOpen: false }))
        }
    }

    public static onTermsOfServiceCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            if (isChecked) {
                dispatch(Actions.sendUserActivity('Checked Terms of Service','Documents Update Notification'))
            } else {
                dispatch(Actions.sendUserActivity('Unchecked Terms of Service','Documents Update Notification'))
            }
            dispatch(stateController.setState({ isAgreedTermsOfService: isChecked }))
        }
    }

    public static onHealthConsentCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            if (isChecked) {
                dispatch(Actions.sendUserActivity('Checked Health Consent','Documents Update Notification'))
            } else {
                dispatch(Actions.sendUserActivity('Unchecked Health Consent','Documents Update Notification'))
            }
            dispatch(stateController.setState({ isAgreedHealthConsent: isChecked }))
        }
    }

    public static openTermsOfServiceDocument = () => (dispatch, getState: () => AppState) => {
        dispatch(Actions.sendUserActivity('Opened Terms of Service','Documents Update Notification'))
        window.open(commonPagesPaths.playerTermsOfService, '_blank')
    }

    public static openHealthConsentDocument = () => (dispatch, getState: () => AppState) => {
        dispatch(Actions.sendUserActivity('Opened Health Consent','Documents Update Notification'))
        window.open(commonPagesPaths.playerHealthConsent, '_blank')
    }

    public static sendEmailToSupportTeam = () => (dispatch, getState: () => AppState) => {
        dispatch(Actions.sendUserActivity('Opened Support Team','Documents Update Notification'))
        window.open('mailto: support@transferroom.com', '_blank')
    }

    public static onAgreeDocuments() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                dispatch(Actions.sendUserActivity('Agreed Terms of Service and Health Consent','Documents Update Notification'))
                dispatch(hideTermsAndConditions())
                
                await CommonService.acceptTermsAndConditions(AgreeDocumentsUserTypeEnum.Player)
                
                dispatch(Actions.closeAgreeDocumentsModal())
            }
            finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }
    public static onSkipAgree() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Not Now','Documents Update Notification'))
            dispatch(Actions.closeAgreeDocumentsModal())
            dispatch(hideTermsAndConditions())
            await CommonService.skipTermsAndConditions(AgreeDocumentsUserTypeEnum.Player)
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgreeDocumentsModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};