import { combineReducers } from 'redux';
import compose from 'lodash/fp/compose';

const INITIAL_STATE = {
    byId: {},
    allIds: [],
};

const byId = (byId = INITIAL_STATE.byId, action) => {
    switch (action.type) {
        default:
            return byId;
    }
};

const allIds = (ids = INITIAL_STATE.allIds, action) => {
    switch (action.type) {
        default:
            return ids;
    }
};

export default combineReducers({
    byId,
    allIds,
});

const path = state => state.entities.users;

export const getUser = compose(
    users => id => users.byId[id],
    path,
);
