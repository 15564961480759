import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
import SpeakerCard from './speaker-card/speaker-card';

const eventSettings: EventInfoModel = {
    shortTitle: "Virtual Summit",
    title: "Virtual Summit",
    shortEventDates: "10th November 2021",
    eventDates: "10th November 2021",
    location: "Online (Virtual)",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: SpeakerCard,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        userActivityPageName: "Home [TransferRoom Virtual Summit]",
        description: "We are back with a live event! Join more than 160 clubs at this exclusive gathering."
    },
    registration: {
        activateAgencyFeature: false,
        hasDateSelectorStep: true
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: true
    }
};

export default eventSettings;