import axios from 'axios-config';
import {
    TopPanelModel,
    OverviewModel,
    InternationalAppModel,
    DomesticMinsModel,
    ContinentalMinsModel,
    LastClubModel,
    CurrentClubModel,
} from './model/player-point'

export class PlayerPointsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getDefaultPlayerId(): Promise<number> {
        const { data } = await axios.get(
            `api/GbeCalculator/DefaultPlayer`,
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getDefaultPlayerIdAnonymous(): Promise<number> {
        const { data } = await axios.get(
            `api/GbeCalculator/DefaultPlayerAnonymous`,
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getPlayerAutosuggestionsAnonymous(filterCriteria: any): Promise<Array<any>> {
        const { data } = await axios.post(
            `api/GbeCalculator/GetPlayerSearchFilterSuggestion`,
            { ...filterCriteria },
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getPlayerPoint(playerId: number): Promise<TopPanelModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/TopPanel`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async getPlayerPointAnonymous(playerId: number): Promise<TopPanelModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/TopPanelAnonymous`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async getPlayerPointOverview(playerId: number): Promise<OverviewModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/Overview`,
           { ...this.axiosConfig }
        )
        return data
    }

    
    public static async getInternationalApp(playerId: number): Promise<InternationalAppModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/InternationalApp`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async getDomesticMins(playerId: number): Promise<DomesticMinsModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/DomesticMins`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async getContinentalAppearences(playerId: number): Promise<ContinentalMinsModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/ContinentalMins`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async getLastClub(playerId: number): Promise<LastClubModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/LastClub`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async getCurrentClub(playerId: number): Promise<CurrentClubModel> {
        const { data } = await axios.get(
            `api/GbeCalculator/${playerId}/CurrentClub`,
           { ...this.axiosConfig }
        )
        return data
    }

    public static async sendPermissionRequest(squadId: number, userId: number) {
        const { data } = await axios.post(
            `api/GbeCalculator/SendPermissionRequest/${squadId}/${userId}`,
            {},
            { ...this.axiosConfig }
        )
        return data
    }
}
