import { combineReducers } from 'redux'
import * as CommonController from './common.controller'
import * as SuperPitchReactionsController from './reactions.controller'
import * as TestInterestModalController from './test-interest-modal.controller'
import * as CreateSuperPitchController from '../super-pitch-new-create-flow/store/super-pitch-create.controller'

export class SuperPitchState {
    common: CommonController.State;
    testInterestModal: TestInterestModalController.State;
    reactions: SuperPitchReactionsController.State;
    createSuperPitch2: CreateSuperPitchController.State
}

export const Reducers = combineReducers({
    common: CommonController.Reducer,
    testInterestModal: TestInterestModalController.Reducer,
    reactions: SuperPitchReactionsController.Reducer,
    createSuperPitch2: CreateSuperPitchController.Reducer
})
