import axios from 'axios-config';
import {CancelToken} from 'axios';
import { PlayerProfileModel,  } from './model';
import { SimilarPlayersResponse } from './model/similar-players';
import { PlayerComparisonResponse } from './model/performance';
import { SuggestionModel } from "../player/player-search";
import { PlayerImpactResponse } from './model/impact';
import { MatchHistoryResponse } from './model/match-history';
import { InjuriesSummaryResponse } from './model/injuries';

export class PlayerProfileService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async getPlayerProfileById(playerId: number): Promise<PlayerProfileModel>{
        const { data } = await axios.get<PlayerProfileModel>(
            `api/PlayerProfile/${playerId}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getPlayerProfileCurrent(): Promise<PlayerProfileModel>{
        const { data } = await axios.get<PlayerProfileModel>(
            `api/PlayerProfile/Current`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getSimilarPlayers(playerId: number): Promise<SimilarPlayersResponse>{
        const { data } = await axios.get<SimilarPlayersResponse>(
            `api/PlayerProfile/SimilarPlayers/${playerId}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getImpactSummary(playerId: number): Promise<PlayerImpactResponse>{
        const { data } = await axios.get<PlayerImpactResponse>(
            `api/PlayerProfile/ImpactSummary/${playerId}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getPlayerComparison(season: number, firstPlayerId: number, secondPlayerId: number = 0, secondPlayerType = -1): Promise<PlayerComparisonResponse>{
        const seasonParam = !!season ? `season=${season}&` : '';
        const { data } = await axios.get<PlayerComparisonResponse>(
            `api/Statistic/GetPlayerComparisonV2?${seasonParam}firstPlayerId=${firstPlayerId}&secondPlayerId=${secondPlayerId}&secondPlayerType=${secondPlayerType}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getPlayerSearchSuggestion(year: number, keyword: string, squadId: number, clubId: number, positionCode: string, cancelToken: CancelToken): Promise<Array<SuggestionModel>>{
        const { data } = await axios.get<Array<SuggestionModel>>(
            `api/Suggestion/GetPlayerComparisonSuggestion/${squadId}/${clubId}/${year}/${keyword}?profileSearchPositionCode=${positionCode || ''}`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        );
        return data;
    }

    public static async getMatchHistory(playerId: number, currentPlayer: boolean): Promise<MatchHistoryResponse>{
        const { data } = await axios.get<MatchHistoryResponse>(
            `api/PlayerProfile/MatchHistory/${playerId}/${currentPlayer}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getInjuriesSummary(playerId: number): Promise<InjuriesSummaryResponse>{
        const { data } = await axios.get<InjuriesSummaryResponse>(
            `api/PlayerProfile/InjuriesSummary/${playerId}`,
            this.axiosConfig,
        );
        return data;
    }
}