import { PlayerModel } from "api/admin/players/models";
import moment from "moment";

export const getContractExpirationDate = (player: PlayerModel) => {

  if (player.contractExpirationApply) {
    return moment(player.contractExpirationApply.contractExpiration).format('MM/DD/yyyy')
      + (player.contractExpirationApply.additionalYears ? `+${player.contractExpirationApply.additionalYears}` : '')
  }

  if(player.contractExpiration) {
    return moment(player.contractExpiration).format('MM/DD/yyyy');
  }

  return '';
}

export const getContractStatus = (player: PlayerModel) => {
  if (player.contractExpirationApply) {

    const { contractExpirationApply } = player;

    if (contractExpirationApply.autoApply) {
      return 'Auto Applied';
    }

    if (contractExpirationApply.appliedByAdmin) {
      return `Applied By ${contractExpirationApply.appliedByAdmin.shortName}`;
    } else {
      return 'Pending';
    }
  }

  if (player.contractExpiration) {
    return 'Original';
  }

  return '';
}
