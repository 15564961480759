import axios from 'axios-config';
import { PlayerPreferencesOptionRequest, SettingsInitialInfoRequest, PlayerOnBoardIntentionModel } from './model';

export class PlayerPreferencesService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async addPlayerOnBoardIntention(request: PlayerPreferencesOptionRequest): Promise<any> {
        const { data } = await axios.post(
            `api/v2/PlayerPreference/AddPlayerOnBoardIntention`,
            request,
            this.axiosConfig,
        );
        return data.output;
    };

    public static async getSettingsInitialInfo(): Promise<SettingsInitialInfoRequest> {
        const { data } = await axios.get(
            `api/v2/PlayerPreference/SettingsInitialInfo`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getPlayerOnBoardIntention(): Promise<PlayerOnBoardIntentionModel> {
        const { data } = await axios.get(
            `api/v2/PlayerPreference/PlayerOnBoardIntention`,
            this.axiosConfig,
        );
        return data;
    }
}