import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { PlayerPointsService } from 'api/player/player-points';
import { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import { ContinentalMinsModel, CurrentClubModel, DomesticMinsModel, InternationalAppModel, LastClubModel, OverviewModel, TopPanelModel } from 'api/player/model/player-point';
import { CommonValue, OverviewItem, StatisticsTabsEnunm } from 'pages/player-points/redux/statisctics-common.controller';
import { isUKPlayer } from 'pages/player-points/redux/utils';
import { getPlayerSearchFilterCriteria } from 'store/playerSearch/reducers/playerSearchFilterReducer';
import { ActionType, PageType } from 'constants/enums';

class GbeIframeState {
    countOfCalculatedPlayers: number;

    isDataLoading: boolean;
    playerId: number;

    keyword: string;
    suggestions: Array<any>;
    isSearchLoading: boolean;

    gbeInfo: TopPanelModel;

    currentTab: StatisticsTabsEnunm;
    isTabsLoading: boolean;
    overview: OverviewModel & CommonValue
    internalApp: InternationalAppModel & CommonValue
    domesticMins: DomesticMinsModel & CommonValue
    continentalAppereances: ContinentalMinsModel & CommonValue
    lastClub: LastClubModel & CommonValue
    currentClub: CurrentClubModel & CommonValue
}

const defaultState: GbeIframeState = {

    countOfCalculatedPlayers: 0,

    isDataLoading: false,

    keyword: '',
    suggestions: [],
    isSearchLoading: false,

    playerId: null,
    gbeInfo: null,

    currentTab: StatisticsTabsEnunm.Overview,
    isTabsLoading: false,
    overview: null,
    domesticMins: {
        points: 0,
        pointsTableColumnValue: null,
        pointsTableRowValue: null,
        appearances: [],
        domesticMinutes: 0,
        totalAvailableMinutes: 0,
        totalMinutesPlayed: 0,
        loaded: false,
    },
    internalApp: {
        points: 0,
        appearancesPerc: 0,
        appearances: 0,
        includeFriendlies: false,
        totalAvailableCompetitiveFixture: null,
        totalCompetitiveFixture: null,
        competitive: 0,
        fifaRnk: 0,
        table: [],
        fifaRanking: [],
        loaded: false,
    },
    continentalAppereances: {
        points: 0,
        pointsTableColumnValue: null,
        pointsTableRowValue: null,
        appearances: [],
        continentalMinutes: 0,
        totalAvailableMinutes: 0,
        totalMinutesPlayed: 0,
        loaded: false,
    },
    lastClub: {
        points: 0,
        pointsTableCompetition: '',
        pointsTableColumnValue: null,
        pointsTableRowValue: null,
        pointsTableContinentalCompetition: '',
        pointsTableContinentalColumnValue: null,
        pointsTableContinentalRowValue: null,
        leagueSeason: '',
        leagueTable: [],
        loaded: false,
    },
    currentClub: {
        points: 0,
        pointsTableCompetition: '',
        pointsTableRowValue: null,
        loaded: false,
    },
}

const stateController = new StateController<GbeIframeState>(
    'GBE_IFRAME/HUBSPOT',
    defaultState
)

class Actions {

    public static initializeGbeCalculator() {
        return async (dispatch, getState: () => AppState) => {
            const defaultPlayer = await dispatch(Actions.initDefaultPlayer())
            await dispatch(Actions.initTopPanelInfo(defaultPlayer))
            dispatch(Actions.initTabData(StatisticsTabsEnunm.Overview, defaultPlayer))
        }
    }

    public static initDefaultPlayer() {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            try {
                dispatch(stateController.setState({ isDataLoading: true }))
                const defaultPlayerId = await PlayerPointsService.getDefaultPlayerIdAnonymous()
                dispatch(stateController.setState({ playerId: defaultPlayerId }))
                resolve(defaultPlayerId)
            } catch(e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState({ isDataLoading: false }))
            }
        })
    }
    public static initTopPanelInfo(playerId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            try {
                dispatch(stateController.setState({ isDataLoading: true }));
                const gbeInfo: TopPanelModel = await PlayerPointsService.getPlayerPointAnonymous(playerId);
                dispatch(stateController.setState({ gbeInfo }));
                
                if (gbeInfo.player) {
                    dispatch(insertAnonymousActivity({
                        PageName: 'GBE Calculator (Hubspot)',
                        Message: 'Opened Player Profile',
                        ClubId: gbeInfo.player.parentSquadId,
                        PlayerId: gbeInfo.player.id,
                        AgencyId: gbeInfo.player.agencyId,
                        PageType: PageType.GBE_Calculator
                    }))
                }
                resolve(true)
            } catch(e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState({ isDataLoading: false }));
            }
        })
    }



    // SEARCH BAR methods
    public static onKeywordChange(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({ keyword: keyword }))
        }
    }

    // Needed to dispatch from inside the component before debounced function will start fetching data
    public static setIsLoadingState(isLoading: boolean) {
        return dispatch => {
            dispatch(stateController.setState({ isSearchLoading: isLoading }))
        }
    }

    public static onSuggestionsFetchRequested() {
        return async (dispatch, getState: () => AppState) => {

            const state = getState()
            let filterCriteria = getPlayerSearchFilterCriteria(state)
            filterCriteria = { ...filterCriteria, keyword: state.gbeIframe.keyword, isForFaPlayerPoints: true }

            try {
                dispatch(stateController.setState({ isSearchLoading: true }))
                let data = await PlayerPointsService.getPlayerAutosuggestionsAnonymous(filterCriteria)
                dispatch(stateController.setState({ suggestions: data }))

                dispatch(insertAnonymousActivity({
                    PageName: 'GBE Calculator (Hubspot)',
                    Message: `Search for: ${state.gbeIframe.keyword}`,
                    ActionType: ActionType.Search,
                    PageType: PageType.GBE_Calculator
                }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isSearchLoading: false }))
            }


        }
    }

    public static onSuggestionSelect(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ playerId: playerId }))
            await dispatch(Actions.initTopPanelInfo(playerId))
            dispatch(Actions.disposeAllTabsData())
            dispatch(Actions.initTabData(StatisticsTabsEnunm.Overview, playerId))

            dispatch(stateController.setState(prevState => ({ ...prevState, countOfCalculatedPlayers: prevState.countOfCalculatedPlayers + 1 })))
        }
    }

    public static onSuggestionsClear() {
        return dispatch => {
            dispatch(stateController.setState({ suggestions: [] }))
        }
    }
    
    public static disposeSearchBar() {
        return dispatch => {
            dispatch(stateController.setState({
                keyword: '',
                suggestions: [],
                isSearchLoading: false
            }))
        }
    }


    // TABS methods
    public static changeTab(tab: StatisticsTabsEnunm = StatisticsTabsEnunm.Overview, playerId: number) {
        return dispatch => {
            dispatch(stateController.setState({ currentTab: tab }))
            dispatch(Actions.initTabData(tab, playerId))
        }
    }

    public static disposeSearchCount() {
        return dispatch => {
            dispatch(stateController.setState({ countOfCalculatedPlayers: 0 }))
        }
    }

    public static initTabData(tab: StatisticsTabsEnunm, playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {

                let message = ''
                const state = getState()

                if (!state.gbeIframe.gbeInfo.faPointResult || isUKPlayer(state.gbeIframe.gbeInfo.faPointResult)) {

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        overview: { ...prevState.overview, loaded: true },
                        internalApp: { ...prevState.internalApp, loaded: true },
                        domesticMins: { ...prevState.domesticMins, loaded: true },
                        continentalAppereances: { ...prevState.continentalAppereances, loaded: true },
                        lastClub: { ...prevState.lastClub, loaded: true },
                        currentClub: { ...prevState.currentClub, loaded: true }
                    })))

                } else {

                    dispatch(stateController.setState({ isTabsLoading: true }))

                    if (tab === StatisticsTabsEnunm.Overview && !Selectors.getOverview(state)) {
                        const data = await PlayerPointsService.getPlayerPointOverview(playerId)
                        dispatch(stateController.setState({ overview: { loaded: true, ...data } }))
                    } else if (tab === StatisticsTabsEnunm.InternationalApps && !Selectors.getInternationalApp(state).loaded) {
                        const data = await PlayerPointsService.getInternationalApp(playerId)
                        dispatch(stateController.setState({ internalApp: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.DomesticMins && !Selectors.getDomescticMins(state).loaded) {
                        const data = await PlayerPointsService.getDomesticMins(playerId)
                        dispatch(stateController.setState({ domesticMins: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.ContinentalMins && !Selectors.getContinentalAppearences(state).loaded) {
                        const data = await PlayerPointsService.getContinentalAppearences(playerId)
                        dispatch(stateController.setState({ continentalAppereances: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.LastClub && !Selectors.getLastClub(state).loaded) {
                        const data = await PlayerPointsService.getLastClub(playerId)
                        dispatch(stateController.setState({ lastClub: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.CurrentClub && !Selectors.getCurrentClub(state).loaded) {
                        const data = await PlayerPointsService.getCurrentClub(playerId)
                        dispatch(stateController.setState({ currentClub: { loaded: true, ...data } }));
                    }

                }

                if (tab === StatisticsTabsEnunm.Overview) {
                    message = 'Opened Overview'
                } else if (tab === StatisticsTabsEnunm.InternationalApps) {
                    message = 'Opened International Apps'
                } else if (tab === StatisticsTabsEnunm.DomesticMins) {
                    message = 'Opened Domestic Mins'
                } else if (tab === StatisticsTabsEnunm.ContinentalMins) {
                    message = 'Opened Continental Mins'
                } else if (tab === StatisticsTabsEnunm.LastClub) {
                    message = 'Opened Last Club'
                } else if (tab === StatisticsTabsEnunm.CurrentClub) {
                    message = 'Opened Current Club'
                } else if (tab === StatisticsTabsEnunm.Information) {
                    message = 'Opened Information'
                }

                const player = getState().gbeIframe.gbeInfo.player;

                if (player && message) {
                    dispatch(insertAnonymousActivity({
                        PageName: 'GBE Calculator (Hubspot)',
                        Message: message,
                        ClubId: player.parentSquadId,
                        PlayerId: player.id,
                        AgencyId: player.agencyId,
                        PageType: PageType.GBE_Calculator
                    }))
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isTabsLoading: false }))
            }
        }
    }

    public static disposeAllTabsData() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({ 
                ...prevState,
                currentTab: defaultState.currentTab,
                overview: defaultState.overview,
                internalApp: defaultState.internalApp,
                domesticMins: defaultState.domesticMins,
                continentalAppereances: defaultState.continentalAppereances,
                lastClub: defaultState.lastClub,
                currentClub: defaultState.currentClub
            })))
        }
    }

    public static toggleOverview(fly: OverviewItem, open: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const player = getState().gbeIframe.gbeInfo.player;

            let message = ''

            if (fly === OverviewItem.InternationalApps) {
                message = open ? 'Opened International Apps' : 'Closed International Apps'
            } else if (fly === OverviewItem.DomesticMins) {
                message = open ? 'Opened Domestic Mins' : 'Closed Domestic Mins'
            } else if (fly === OverviewItem.ContinentalMins) {
                message = open ? 'Opened Continental Mins' : 'Closed Continental Mins'
            } else if (fly === OverviewItem.LastClub) {
                message = open ? 'Opened Last Club' : 'Closed Last Club'
            } else if (fly === OverviewItem.CurrentClub) {
                message = open ? 'Opened Current Club' : 'Closed Current Club'
            }

            if (player && message) {
                dispatch(insertAnonymousActivity({
                    PageName: 'GBE Calculator (Hubspot) [Overview]',
                    Message: message,
                    ClubId: player.parentSquadId,
                    PlayerId: player.id,
                    AgencyId: player.agencyId,
                    PageType: PageType.GBE_Calculator
                }))
            }
        }
    }

    public static onOpenFA = () => {
        return (dispatch, getState: () => AppState) => {
            const player = Selectors.getPlayer(getState());
            dispatch(insertAnonymousActivity(
                {
                    Message: 'Opened FA Website',
                    PageName: 'GBE Calculator (Hubspot) [Information]',
                    PlayerId: player.Id,
                    ClubId: player.parentSquadId,
                    AgencyId: player.agencyId,
                    PageType: PageType.GBE_Calculator
                },
            ))
        }
    }

    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }


}

class Selectors {
    public static getGbeState = (state: AppState) => state.gbeIframe;
    public static getPlayerPanel = (state: AppState): TopPanelModel => Selectors.getGbeState(state).gbeInfo;
    public static isDataLoading = (state: AppState) => Selectors.getGbeState(state).isDataLoading;
    public static isSearchLoading = (state: AppState) => Selectors.getGbeState(state).isSearchLoading;

    public static getPlayer = (state: AppState) => Selectors.getPlayerPanel(state).player
    public static getPlayerId = (state: AppState) => Selectors.getGbeState(state).playerId
    

    public static getOverview = (state: AppState) => Selectors.getGbeState(state).overview
    public static getInternationalApp = (state: AppState) => Selectors.getGbeState(state).internalApp
    public static getInternationalAppPoints = (state: AppState) => Selectors.getInternationalApp(state).points
    public static getInternationalAppFifaRanking = (state: AppState) => Selectors.getInternationalApp(state).fifaRanking
    public static getDomescticMins = (state: AppState) => Selectors.getGbeState(state).domesticMins
    public static getContinentalAppearences = (state: AppState) => Selectors.getGbeState(state).continentalAppereances
    public static getLastClub = (state: AppState) => Selectors.getGbeState(state).lastClub
    public static getLastClubTable = (state: AppState) => Selectors.getLastClub(state).leagueTable
    public static getCurrentClub = (state: AppState) => Selectors.getGbeState(state).currentClub

    public static getCurrentTab = (state: AppState) => Selectors.getGbeState(state).currentTab
    public static isTabsLoading = (state: AppState) => Selectors.getGbeState(state).isTabsLoading;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    GbeIframeState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};