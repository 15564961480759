import axios from 'axios-config';
import { SubscriptionStats } from '../subscription/subscription-models';
import { AgencyPlayerDefaultResponse, AgencyPlayerSearchResponse, AreaResponse, ExternalAgentShortModel, OnboardingAccessModel, AgentRegistrationState, AgentPlayerInvitationItemModel, AgencyRegularPlayerSlotStats } from './onboarding-models';
import { AgencyOnboarding, AgentOnboarding } from './onboarding-set-models';
import { PasswordResultResponse, SetPasswordRequest } from 'api/user-password/models';

export default class AgencyOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };


    public static async getOnoardingAccessByInvitationGuid(guid: string): Promise<OnboardingAccessModel> {
        const { data } = await axios.get<OnboardingAccessModel>(`api/v2/Agency/GetOnBoardingAccessByInvitationGuid/${guid}`, this.axiosConfig)
        return data;
    }

    public static async authenticateAgentByOnboardingGuid(guid: string): Promise<boolean> {
        return await axios.post(`api/Users/AuthenticateAgentByInvitationId/${guid}`, this.axiosConfig)
    }

    public static async getCountries(guid: string): Promise<Array<AreaResponse>> {
        const { data } = await axios.get<Array<AreaResponse>>(`api/v2/Area/GetAllAreasByInvitationGuid/${guid}`, this.axiosConfig)
        return data;
    }

    public static async getAgents(guid: string): Promise<Array<ExternalAgentShortModel>> {
        const { data } = await axios.get<Array<ExternalAgentShortModel>>(`api/v2/ExternalAgent/GetWithInvitationGuid/${guid}`, this.axiosConfig)
        return data;
    }

    public static async getAgentsAuthorized(): Promise<Array<ExternalAgentShortModel>> {
        const { data } = await axios.get<Array<ExternalAgentShortModel>>(`api/v2/Agent/GetShorAgentsInfoByAgency`, this.axiosConfig)
        return data;
    }


    public static async getPlayers(guid: string): Promise<Array<AgencyPlayerDefaultResponse>> {
        const { data } = await axios.get<Array<AgencyPlayerDefaultResponse>>(`api/v2/ExternalAgencyPlayers/GetWithInvitationGuid/${guid}`, this.axiosConfig)
        return data;
    }

    public static async getPlayersByAgentInvitationGuid(guid: string): Promise<Array<AgencyPlayerDefaultResponse>> {
        const { data } = await axios.get<Array<AgencyPlayerDefaultResponse>>(`api/v2/AgentPlayerInvitation/GetPlayersByAgentInvitationGuid/${guid}`, this.axiosConfig)
        return data;
    }

    public static async addPlayers(playerItems: Array<AgentPlayerInvitationItemModel>): Promise<any> {
        const { data } = await axios.post(
            `api/v2/AgentPlayerInvitation/CreateRange`, { playerItems }, this.axiosConfig)
        return data;
    }


    public static async removePlayer(guid: string, playerId: number): Promise<void> {
        await axios.delete(`api/v2/ExternalAgencyPlayers/DeletePlayerFromInvitation/${guid}/${playerId}`, this.axiosConfig)
    }

    public static async getPlayersByKeyword(keyword: string, guid: string): Promise<Array<AgencyPlayerSearchResponse>> {
        const { data } = await axios.get<Array<AgencyPlayerSearchResponse>>(`api/v2/ExternalAgencyPlayers/GeyByKeyword/${guid}/${keyword}`, this.axiosConfig)
        return data;
    }

    public static async getPlayersByKeywordAuthorized(keyword: string): Promise<Array<AgencyPlayerSearchResponse>> {
        const { data } = await axios.get<Array<AgencyPlayerSearchResponse>>(`api/v2/ExternalAgencyPlayers/GeyByKeyword/${keyword}`, this.axiosConfig)
        return data;
    }

    public static async getSubscriptionStats(): Promise<SubscriptionStats> {
        const { data } = await axios.get<SubscriptionStats>(`api/v2/AgencySubscription/GetSubscriptionStats/`, this.axiosConfig)
        return data;
    }

    public static async isAgentInvalidForOnboarding(guid: string, email: string): Promise<AgentRegistrationState> {
        const { data } = await axios.post<AgentRegistrationState>(
            `api/v2/Agency/IsAgentRegistered`,
            { guid: guid, email: email },
            this.axiosConfig
        );
        return data;
    }

    public static async setAgencyOnboarding(request: AgencyOnboarding): Promise<any> {
        await axios.post(`api/v2/AgencyAgentOnboarding/SetAgencyOnboarding`, request, this.axiosConfig);
    }

    public static async setAgentOnboarding(request: AgentOnboarding): Promise<any> {
        await axios.post(`api/v2/AgencyAgentOnboarding/SetAgentOnboarding`, request, this.axiosConfig);
    }

    public static async setAgentOnboardingByUsedInvitation(request: AgentOnboarding): Promise<any> {
        await axios.post(`api/v2/AgencyAgentOnboarding/SetAgentOnboardingByUsedInvitation`, request, this.axiosConfig);
    }

    public static async getRegularPlayerSlotStats(): Promise<AgencyRegularPlayerSlotStats> {
        const { data } = await axios.get<AgencyRegularPlayerSlotStats>(`api/v2/Agency/GetRegularPlayerSlotStats/`, this.axiosConfig)
        return data;
    }

    public static async confirmAndSetPassword(request: SetPasswordRequest): Promise<PasswordResultResponse> {
        const { data } = await axios.post(`api/v2/AgencyAgentOnboarding/ConfirmAndSetPassword`, request, this.axiosConfig)

        return data;
    }
}