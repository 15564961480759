import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { EventParticipationDaysEnum } from 'api/virtual-summit/models/summit-invitationl';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { Actions as Step1Actions, User } from 'pages/virtual-summit/redux/confirm-page-steps/step-1.controller';
import { Actions as StepActions, RegistrationState } from 'pages/virtual-summit/redux/confirm-page-steps/confirm.controller';
import { Actions as AgencyActions, RegistrationState as AgencyRegistrationState } from 'pages/virtual-summit/redux/agency/authorized-registration.controller';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import AuthState from 'store/auth/auth-state';

export const LOCAL_STORAGE_KEY = "eventDateSelector";

class DateSelectorState {
    isLoading: boolean;
    isVipNetworkingEveningSelected: boolean;
    isInPersonSummitSelected: boolean;
    isOnlineSummitSelected: boolean;
}

const defaultState: DateSelectorState = {
    isLoading: false,
    isVipNetworkingEveningSelected: false,
    isInPersonSummitSelected: false,
    isOnlineSummitSelected: false,
}

export interface EventDateSelector_LocalStorageInfo {
    isVipNetworkingEveningSelected: boolean;
    isInPersonSummitSelected: boolean;
    isOnlineSummitSelected: boolean;
}

const stateController = new StateController<DateSelectorState>(
    "VIRTUAL_SUMMIT/DATE_SELECTOR",
    defaultState
);

class Actions {

    public static getData = (redirect: (url: string) => void) => {
        return async (dispatch, getState: () => AppState) => {
            const { auth } = getState();
            dispatch(stateController.setState({ isLoading: true }));
            const selectedDatesInfo = Actions.getSelectedDates(auth);
            if(selectedDatesInfo){
                const { isVipNetworkingEveningSelected, isInPersonSummitSelected, isOnlineSummitSelected } = selectedDatesInfo;
                if (isVipNetworkingEveningSelected || isInPersonSummitSelected || isOnlineSummitSelected) {
                    dispatch(StepActions.setStep(RegistrationState.Complete))
                }
            }

            await dispatch(Step1Actions.getData(redirect));
            dispatch(stateController.setState({ isLoading: false }));
        }
    }

    public static toggleVipNetworkingEveningSelected = () => {
        return async (dispatch, getState: () => AppState) => {
            const { isVipNetworkingEveningSelected, isInPersonSummitSelected } = getState().virtualSummit.dateSelector;

            const newValue = !isVipNetworkingEveningSelected;
            dispatch(stateController.setState({ isVipNetworkingEveningSelected: newValue }))

            if(newValue && !isInPersonSummitSelected){
                dispatch(stateController.setState({ isInPersonSummitSelected: true }))
            }
        }
    }

    public static toggleInPersonSummitSelectedSelected = () => {
        return async (dispatch, getState: () => AppState) => {
            const { isInPersonSummitSelected, isVipNetworkingEveningSelected } = getState().virtualSummit.dateSelector;

            const newValue = !isInPersonSummitSelected;
            dispatch(stateController.setState({ isInPersonSummitSelected: newValue }))

            if(!newValue && isVipNetworkingEveningSelected){
                dispatch(stateController.setState({ isVipNetworkingEveningSelected: false }))
            }
        }
    }

    public static toggleOnlineSummitSelected = () => {
        return async (dispatch, getState: () => AppState) => {
            const { isOnlineSummitSelected } = getState().virtualSummit.dateSelector;

            dispatch(stateController.setState({ isOnlineSummitSelected: !isOnlineSummitSelected }))
        }
    }

    public static setParticipationDays = (data: { eventParticipationDays: EventParticipationDaysEnum[] }) => {
        return async (dispatch, getState: () => AppState) => {
            const { auth } = getState();
            let dateSelectorlocalStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            const infoKey = Actions.getInfoKey(auth.userId, auth.eventId);
            const info: EventDateSelector_LocalStorageInfo = dateSelectorlocalStorageInfo[infoKey];
            if (info.isVipNetworkingEveningSelected)
                data.eventParticipationDays.push(EventParticipationDaysEnum.VipNetworkingEvening);
            if (info.isInPersonSummitSelected)
                data.eventParticipationDays.push(EventParticipationDaysEnum.InPersonSummit);
            if (info.isOnlineSummitSelected)
                data.eventParticipationDays.push(EventParticipationDaysEnum.OnlineSummit);
        }
    }

    public static continue = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.saveDatesToLocalStorage());
            dispatch(StepActions.setStep(RegistrationState.Complete));
        }
    }

    public static cancel = () => {
        return async (dispatch, getState: () => AppState) => {
            const { auth } = getState();

            dispatch(userActivityInsert({
                Message: 'Cancelled Registration',
                PageName: 'Event [Registration]',
                PageType: PageType.Events,
                EventId: auth.eventId
            }))
            historyAccessor.push(userPaths.events);
        }
    }

    public static getDataForAgency = (redirect: (url: string) => void) => {
        return async (dispatch, getState: () => AppState) => {
            const { auth } = getState();
            dispatch(stateController.setState({ isLoading: true }));
            const selectedDatesInfo = Actions.getSelectedDates(auth);
            if(selectedDatesInfo){
                const { isInPersonSummitSelected, isOnlineSummitSelected } = selectedDatesInfo;
                if (isInPersonSummitSelected || isOnlineSummitSelected) {
                    dispatch(AgencyActions.setStep(AgencyRegistrationState.InProgress));
                }
            }

            dispatch(stateController.setState({ isLoading: false }));
        }
    }

    public static continueForAgency = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.saveDatesToLocalStorage());
            dispatch(AgencyActions.setStep(AgencyRegistrationState.InProgress));
        }
    }

    private static saveDatesToLocalStorage = () => {
        return async (dispatch, getState: () => AppState) => {
            const { auth, virtualSummit } = getState();
            const { 
                isVipNetworkingEveningSelected, 
                isInPersonSummitSelected, 
                isOnlineSummitSelected
            } = virtualSummit.dateSelector;

            const info: EventDateSelector_LocalStorageInfo = {
                isVipNetworkingEveningSelected: isVipNetworkingEveningSelected,
                isInPersonSummitSelected: isInPersonSummitSelected,
                isOnlineSummitSelected: isOnlineSummitSelected
            };
            let localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            const infoKey = Actions.getInfoKey(auth.userId, auth.eventId);
            localStorageInfo[infoKey] = info;
		    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageInfo));

            const selectedEvents = [];
            if(isVipNetworkingEveningSelected){
                selectedEvents.push('VIP');
            }

            if(isInPersonSummitSelected){
                selectedEvents.push('In Person');
            }

            if(isOnlineSummitSelected){
                selectedEvents.push('Online');
            }

            dispatch(userActivityInsert({
                Message: `Selected: ${selectedEvents.join(', ')}`,
                PageName: 'Online Event [Registration]',
                PageType: PageType.Events,
                EventId: auth.eventId
            }))
        }
    }

    private static getInfoKey(userId: number, eventId: number){
        return `U${userId}_E${eventId}`;
    }

    private static getSelectedDates = (auth: AuthState) => {
        let localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
        const infoKey = Actions.getInfoKey(auth.userId, auth.eventId);
        const info: EventDateSelector_LocalStorageInfo = localStorageInfo[infoKey];
        return info;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    DateSelectorState as State,
    Actions as Actions,
    stateController as Controller
};



