export const eventClubs = [
    {
        squadId: 2,
        squadName: "AS Roma",
        shortSquadName: "AS Roma",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
    },
    {
        squadId: 3,
        squadName: "Juventus FC",
        shortSquadName: "Juventus",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1832_Juventus_FC.png"
    },
    {
        squadId: 17,
        squadName: "Genoa CFC",
        shortSquadName: "Genoa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
    },
    {
        squadId: 45,
        squadName: "Manchester United",
        shortSquadName: "Man Utd",
        squadLogo: null, //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
    },
    {
        squadId: 47,
        squadName: "Tottenham Hotspur",
        shortSquadName: "Tottenham",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1122_Tottenham_Hotspur.png"
    },
    {
        squadId: 48,
        squadName: "Manchester City",
        shortSquadName: "Man City",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
    },
    {
        squadId: 49,
        squadName: "Arsenal FC",
        shortSquadName: "Arsenal",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
    },
    {
        squadId: 50,
        squadName: "Chelsea FC",
        shortSquadName: "Chelsea",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
    },
    {
        squadId: 60,
        squadName: "Newcastle United",
        shortSquadName: "Newcastle",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
    },
    {
        squadId: 62,
        squadName: "Southampton FC",
        shortSquadName: "Southampton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
    },
    {
        squadId: 63,
        squadName: "Everton FC",
        shortSquadName: "Everton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
    },
    {
        squadId: 64,
        squadName: "Crystal Palace",
        shortSquadName: "Crystal Palace",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
    },
    {
        squadId: 65,
        squadName: "Wolverhampton Wanderers",
        shortSquadName: "Wolves",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
    },
    {
        squadId: 67,
        squadName: "West Ham United",
        shortSquadName: "West Ham",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1633_West_Ham_United.png"
    },
    {
        squadId: 68,
        squadName: "Cardiff City",
        shortSquadName: "Cardiff",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
    },
    {
        squadId: 69,
        squadName: "Watford FC",
        shortSquadName: "Watford",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
    },
    {
        squadId: 70,
        squadName: "Brighton & Hove Albion",
        shortSquadName: "Brighton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
    },
    {
        squadId: 71,
        squadName: "AFC Bournemouth",
        shortSquadName: "Bournemouth",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
    },
    {
        squadId: 72,
        squadName: "Huddersfield Town",
        shortSquadName: "Huddersfield",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
    },
    {
        squadId: 174,
        squadName: "Club Atletico Talleres",
        shortSquadName: "CA Talleres",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
    },
    {
        squadId: 255,
        squadName: "Zaglebie Lubin",
        shortSquadName: "Zaglebie Lubin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KGHM_Zaglebie_Lubin.png"
    },
    {
        squadId: 329,
        squadName: "Stromsgodset IF",
        shortSquadName: "Stromsgodset",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
    },
    {
        squadId: 416,
        squadName: "Al-Fateh SC",
        shortSquadName: "Al-Fateh",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/16483_Al-Fateh_SC.png"
    },
    {
        squadId: 513,
        squadName: "Rodez AF",
        shortSquadName: "Rodez AF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3867_Rodez_AF.png"
    },
    {
        squadId: 606,
        squadName: "Quevilly Rouen Metropole",
        shortSquadName: "Quevilly Rouen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3865_US_Quevilly-Rouen_Métropole.png"
    },
    {
        squadId: 773,
        squadName: "Getafe CF",
        shortSquadName: "Getafe",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
    },
    {
        squadId: 862,
        squadName: "CD O'Higgins",
        shortSquadName: "O'Higgins",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
    },
    {
        squadId: 976,
        squadName: "AS Monaco",
        shortSquadName: "Monaco",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/19830_AS_Monaco.png"
    },
    {
        squadId: 1003,
        squadName: "Hull City",
        shortSquadName: "Hull City",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1672_Hull_City.png"
    },
    {
        squadId: 1064,
        squadName: "Nimes Olympique",
        shortSquadName: "Nimes Olympique",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3813_Nîmes_Olympique.png"
    },
    {
        squadId: 1177,
        squadName: "OFI Crete FC",
        shortSquadName: "OFI Crete FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12517_OFI_Crete.png"
    },
    {
        squadId: 1421,
        squadName: "Gremio Foot-Ball Porto Alegrense",
        shortSquadName: "Gremio",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
    },
    {
        squadId: 1714,
        squadName: "Sheffield Wednesday",
        shortSquadName: "Sheff Wed",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1141_Sheffield_Wednesday.png"
    },
    {
        squadId: 1740,
        squadName: "Amiens SC",
        shortSquadName: "Amiens SC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
    },
    {
        squadId: 1771,
        squadName: "Bryne FK",
        shortSquadName: "Bryne",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7107_Bryne_FK.png"
    },
    {
        squadId: 1930,
        squadName: "FC Viktoria Koln",
        shortSquadName: "Viktoria Koln",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
    },
    {
        squadId: 1949,
        squadName: "Independiente Medellin",
        shortSquadName: "Indep. Medellin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12399_Independiente_Medellín.png"
    },
    {
        squadId: 2031,
        squadName: "1.FC Union Berlin",
        shortSquadName: "Union Berlin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1277_1.FC_Union_Berlin.png"
    },
    {
        squadId: 2086,
        squadName: "FC Erzgebirge Aue",
        shortSquadName: "Erzgebirge Aue",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Erzgebirge_Aue.png"
    },
    {
        squadId: 2179,
        squadName: "Club Tijuana",
        shortSquadName: "Club Tijuana",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15459_Club_Tijuana.png"
    },
    {
        squadId: 2226,
        squadName: "Sevilla FC",
        shortSquadName: "Sevilla FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/680_Sevilla_FC.png"
    },
    {
        squadId: 2296,
        squadName: "Moss FK",
        shortSquadName: "Moss",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7103_Moss_FK.png"
    },
    {
        squadId: 2332,
        squadName: "Cercle Brugge",
        shortSquadName: "Cercle Brugge",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
    },
    {
        squadId: 2391,
        squadName: "Roda JC Kerkrade",
        shortSquadName: "Roda JC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Roda_JC_Kerkrade.png"
    },
    {
        squadId: 2956,
        squadName: "KAA Gent",
        shortSquadName: "KAA Gent",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
    },
    {
        squadId: 2994,
        squadName: "1.FC Saarbrucken",
        shortSquadName: "Saarbrucken",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2476_1.FC_Saarbrücken.png"
    },
    {
        squadId: 3411,
        squadName: "Willem II Tilburg",
        shortSquadName: "Willem II",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15_Willem_II_Tilburg.png"
    },
    {
        squadId: 3670,
        squadName: "Queen's Park FC",
        shortSquadName: "Queen's Park",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8546_Queen's_Park_FC.png"
    },
    {
        squadId: 3695,
        squadName: "SK Slavia Prague",
        shortSquadName: "Slavia Prague",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/11242_SK_Slavia_Prague.png"
    },
    {
        squadId: 3725,
        squadName: "KV Oostende",
        shortSquadName: "KV Oostende",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5080_KV_Oostende.png"
    },
    {
        squadId: 3740,
        squadName: "KV Kortrijk",
        shortSquadName: "KV Kortrijk",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
    },
    {
        squadId: 3774,
        squadName: "Club Necaxa",
        shortSquadName: "Necaxa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2283_Club_Necaxa.png"
    },
    {
        squadId: 4186,
        squadName: "Ermis Aradippou",
        shortSquadName: "Ermis Aradippou",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/10711_Ermis_Aradippou.png"
    },
    {
        squadId: 4230,
        squadName: "Stade Reims",
        shortSquadName: "Stade Reims",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
    },
    {
        squadId: 4234,
        squadName: "Queens Park Rangers",
        shortSquadName: "QPR",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1149_Queens_Park_Rangers.png"
    },
    {
        squadId: 4247,
        squadName: "Farsley Celtic",
        shortSquadName: "Farsley",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1804_Farsley_Celtic.png"
    },
    {
        squadId: 4602,
        squadName: "Universidad de Concepcion",
        shortSquadName: "U. Concepcion",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12285_Universidad_de_Concepción.png"
    },
    {
        squadId: 4673,
        squadName: "Portsmouth FC",
        shortSquadName: "Portsmouth",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Portsmouth_FC.png"
    },
    {
        squadId: 4870,
        squadName: "Solihull Moors",
        shortSquadName: "Solihull Moors",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2015_Solihull_Moors.png"
    },
    {
        squadId: 5072,
        squadName: "SC Paderborn 07",
        shortSquadName: "SC Paderborn",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
    },
    {
        squadId: 5121,
        squadName: "Minnesota United FC",
        shortSquadName: "Minnesota",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2122_Minnesota_United_FC.png"
    },
    {
        squadId: 5282,
        squadName: "PSV Eindhoven",
        shortSquadName: "PSV Eindhoven",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
    },
    {
        squadId: 5306,
        squadName: "Rakow Czestochowa",
        shortSquadName: "Rakow",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
    },
    {
        squadId: 5359,
        squadName: "Caracas FC",
        shortSquadName: "Caracas FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
    },
    {
        squadId: 5504,
        squadName: "FC Helsingor",
        shortSquadName: "FC Helsingor",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Helsingor.png"
    },
    {
        squadId: 5592,
        squadName: "SD Aucas",
        shortSquadName: "SD Aucas",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_5592_SD_Aucas.png"
    },
    {
        squadId: 5842,
        squadName: "Hobro IK",
        shortSquadName: "Hobro IK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
    },
    {
        squadId: 5869,
        squadName: "Viking FK",
        shortSquadName: "Viking",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7100_Viking_Stavanger.png"
    },
    {
        squadId: 5924,
        squadName: "Everton de Vina del Mar",
        shortSquadName: "CD Everton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
    },
    {
        squadId: 5972,
        squadName: "San Jose Earthquakes",
        shortSquadName: "San Jose",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
    },
    {
        squadId: 6051,
        squadName: "Real Valladolid CF",
        shortSquadName: "Real Valladolid",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/690_Real_Valladolid_CF.png"
    },
    {
        squadId: 6059,
        squadName: "CD Leganes",
        shortSquadName: "CD Leganes",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/712_CD_Leganés.png"
    },
    {
        squadId: 6207,
        squadName: "Molde FK",
        shortSquadName: "Molde",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7098_Molde_FK.png"
    },
    {
        squadId: 6249,
        squadName: "Bayer 04 Leverkusen",
        shortSquadName: "B. Leverkusen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
    },
    {
        squadId: 6322,
        squadName: "NK GOSK Gabela",
        shortSquadName: "GOSK Gabela",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_6322_NK_GOSK_Gabela.png"
    },
    {
        squadId: 6559,
        squadName: "Kiraly SZE",
        shortSquadName: "NULL",
        squadLogo: null
    },
    {
        squadId: 6571,
        squadName: "US Salernitana 1919",
        shortSquadName: "Salernitana",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1865_US_Salernitana_1919.png"
    },
    {
        squadId: 7061,
        squadName: "CD Antofagasta",
        shortSquadName: "Antofagasta",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7061_CD_Antofagasta.png"
    },
    {
        squadId: 7083,
        squadName: "Elche CF",
        shortSquadName: "Elche CF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1793_Elche_CF.png"
    },
    {
        squadId: 7354,
        squadName: "Forest Green Rovers",
        shortSquadName: "Forest Green",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Forest_Green_Rovers.png"
    },
    {
        squadId: 7390,
        squadName: "Club Atletico Lanus",
        shortSquadName: "Lanus",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12063_Club_Atlético_Lanús.png"
    },
    {
        squadId: 7400,
        squadName: "Botafogo de Futebol e Regatas",
        shortSquadName: "Botafogo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7400_Botafogo_de_Futebol_e_Regatas.png"
    },
    {
        squadId: 7819,
        squadName: "Sarpsborg 08 FF",
        shortSquadName: "Sarpsborg 08",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
    },
    {
        squadId: 7845,
        squadName: "CF Pachuca",
        shortSquadName: "Pachuca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
    },
    {
        squadId: 7972,
        squadName: "LOSC Lille",
        shortSquadName: "LOSC Lille",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1974_LOSC_Lille.png"
    },
    {
        squadId: 8076,
        squadName: "CD Universidad Catolica",
        shortSquadName: "U. Catolica",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12295_CD_Universidad_Católica.png"
    },
    {
        squadId: 8110,
        squadName: "Hertha BSC",
        shortSquadName: "Hertha BSC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
    },
    {
        squadId: 8228,
        squadName: "AC Horsens",
        shortSquadName: "AC Horsens",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7465_AC_Horsens.png"
    },
    {
        squadId: 8239,
        squadName: "Brentford FC",
        shortSquadName: "Brentford",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
    },
    {
        squadId: 8896,
        squadName: "Hercilio Luz FC",
        shortSquadName: "NULL",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/6377_Hercílio_Luz_FC.png"
    },
    {
        squadId: 9029,
        squadName: "FC Ingolstadt 04",
        shortSquadName: "FC Ingolstadt",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2579_FC_Ingolstadt_04.png"
    },
    {
        squadId: 9150,
        squadName: "Venezia FC",
        shortSquadName: "Venezia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.svg"
    },
    {
        squadId: 9276,
        squadName: "CF Monterrey",
        shortSquadName: "Monterrey",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2267_CF_Monterrey.png"
    },
    {
        squadId: 9385,
        squadName: "Sporting Kansas City",
        shortSquadName: "Kansas City",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7849_Sporting_Kansas_City.png"
    },
    {
        squadId: 9451,
        squadName: "Hamilton Academical FC",
        shortSquadName: "Hamilton Acad.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hamilton_Academical.png"
    },
    {
        squadId: 9954,
        squadName: "Sparta Rotterdam",
        shortSquadName: "Sparta R.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
    },
    {
        squadId: 10026,
        squadName: "Los Angeles Galaxy",
        shortSquadName: "Los Angeles",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
    },
    {
        squadId: 10065,
        squadName: "Partick Thistle FC",
        shortSquadName: "Partick Thistle",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
    },
    {
        squadId: 10088,
        squadName: "RB Leipzig",
        shortSquadName: "RB Leipzig",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
    },
    {
        squadId: 10158,
        squadName: "FC Toulouse",
        shortSquadName: "Toulouse",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png"
    },
    {
        squadId: 10317,
        squadName: "New York Red Bulls",
        shortSquadName: "New York RB",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
    },
    {
        squadId: 10371,
        squadName: "Audax Italiano",
        shortSquadName: "Audax Italiano",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12292_Audax_Italiano.png"
    },
    {
        squadId: 10585,
        squadName: "Breidablik Kopavogur",
        shortSquadName: "Breidablik",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1513_Breidablik_Kopavogur.png"
    },
    {
        squadId: 10744,
        squadName: "CF Cruz Azul",
        shortSquadName: "CF Cruz Azul",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15408_CD_Cruz_Azul.png"
    },
    {
        squadId: 10781,
        squadName: "Kristiansund BK",
        shortSquadName: "Kristiansund",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1477_Kristiansund_BK.png"
    },
    {
        squadId: 11256,
        squadName: "Club Guarani",
        shortSquadName: "Guarani",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_11256_Club_Guarani.png"
    },
    {
        squadId: 11585,
        squadName: "Tromso IL",
        shortSquadName: "Tromso",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
    },
    {
        squadId: 11717,
        squadName: "Fehervar FC",
        shortSquadName: "Fehervar",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/10260_Fehérvár_FC.png"
    },
    {
        squadId: 11890,
        squadName: "Correcaminos de la UAT",
        shortSquadName: "Correcaminos",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15440_Correcaminos_de_la_UAT.png"
    },
    {
        squadId: 11978,
        squadName: "FK Jerv",
        shortSquadName: "Jerv",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
    },
    {
        squadId: 12027,
        squadName: "CA Boston River",
        shortSquadName: "Boston River",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
    },
    {
        squadId: 12098,
        squadName: "CD Santa Clara",
        shortSquadName: "Santa Clara",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/9634_CD_Santa_Clara.png"
    },
    {
        squadId: 12139,
        squadName: "Budapest Honved FC",
        shortSquadName: "Honved",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
    },
    {
        squadId: 12156,
        squadName: "Charlton Athletic",
        shortSquadName: "Charlton",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Charlton_Athletic.png"
    },
    {
        squadId: 12173,
        squadName: "Barnsley FC",
        shortSquadName: "Barnsley FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
    },
    {
        squadId: 12294,
        squadName: "Olympique Marseille",
        shortSquadName: "Marseille",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
    },
    {
        squadId: 12314,
        squadName: "Club Universidad de Chile",
        shortSquadName: "U. de Chile",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2644_Club_Universidad_de_Chile.png"
    },
    {
        squadId: 12686,
        squadName: "FC Nordsjaelland",
        shortSquadName: "Nordsjaelland",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
    },
    {
        squadId: 12725,
        squadName: "AeK Larnaca",
        shortSquadName: "AeK Larnaca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2176_AEK_Larnaca.png"
    },
    {
        squadId: 13020,
        squadName: "IL Hodd",
        shortSquadName: "Hodd",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7112_IL_Hödd.png"
    },
    {
        squadId: 13114,
        squadName: "Real Zaragoza",
        shortSquadName: "Real Zaragoza",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/689_Real_Zaragoza.png"
    },
    {
        squadId: 13404,
        squadName: "Houston Dynamo FC",
        shortSquadName: "Houston",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
    },
    {
        squadId: 13510,
        squadName: "FC Utrecht",
        shortSquadName: "FC Utrecht",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
    },
    {
        squadId: 13576,
        squadName: "Sonderjyske Fodbold",
        shortSquadName: "Sonderjyske",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7499_Sønderjyske_Fodbold.png"
    },
    {
        squadId: 13679,
        squadName: "FC Lausanne-Sport",
        shortSquadName: "Lausanne-Sport",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
    },
    {
        squadId: 13685,
        squadName: "Salford City",
        shortSquadName: "Salford",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2317_Salford_City.png"
    },
    {
        squadId: 14649,
        squadName: "SK Beveren",
        shortSquadName: "SK Beveren",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5104_SK_Beveren.png"
    },
    {
        squadId: 14737,
        squadName: "SV 07 Elversberg",
        shortSquadName: "SV Elversberg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1371_SV_07_Elversberg.png"
    },
    {
        squadId: 14821,
        squadName: "FC Copenhagen",
        shortSquadName: "FC Copenhagen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Copenhagen.png"
    },
    {
        squadId: 14994,
        squadName: "CD Leones Negros de la UdeG",
        shortSquadName: "Leones Negros",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
    },
    {
        squadId: 15052,
        squadName: "Sogndal IL",
        shortSquadName: "Sogndal",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
    },
    {
        squadId: 15130,
        squadName: "AIK Solna",
        shortSquadName: "AIK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png"
    },
    {
        squadId: 15241,
        squadName: "Magallanes CF",
        shortSquadName: "Magallanes",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
    },
    {
        squadId: 15268,
        squadName: "Widzew Lodz",
        shortSquadName: "Widzew Lodz",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
    },
    {
        squadId: 15326,
        squadName: "Albion FC",
        shortSquadName: "Albion FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15326_Albion_FC.png"
    },
    {
        squadId: 15469,
        squadName: "Club Nacional",
        shortSquadName: "Nacional",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15634_Club_Nacional.png"
    },
    {
        squadId: 15517,
        squadName: "Oud-Heverlee Leuven",
        shortSquadName: "OH Leuven",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5121_Oud-Heverlee_Leuven.png"
    },
    {
        squadId: 15643,
        squadName: "Deportes La Serena",
        shortSquadName: "La Serena",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15643_Deportes_La_Serena.png"
    },
    {
        squadId: 15741,
        squadName: "US Triestina",
        shortSquadName: "Triestina",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1905_US_Triestina.png"
    },
    {
        squadId: 15821,
        squadName: "Arminia Bielefeld",
        shortSquadName: "Arm. Bielefeld",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
    },
    {
        squadId: 15840,
        squadName: "Warta Poznan",
        shortSquadName: "Warta Poznan",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
    },
    {
        squadId: 16046,
        squadName: "Olympique Lyon",
        shortSquadName: "Olympique Lyon",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1966_Olympique_Lyon.png"
    },
    {
        squadId: 16232,
        squadName: "FC Girondins Bordeaux",
        shortSquadName: "G. Bordeaux",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3772_FC_Girondins_Bordeaux.png"
    },
    {
        squadId: 16247,
        squadName: "Club Athletico Paranaense",
        shortSquadName: "Athletico-PR",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
    },
    {
        squadId: 16335,
        squadName: "Beerschot V.A.",
        shortSquadName: "Beerschot V.A.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
    },
    {
        squadId: 16345,
        squadName: "Mjondalen IF",
        shortSquadName: "Mjondalen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1486_Mjondalen_IF.png"
    },
    {
        squadId: 16437,
        squadName: "Excelsior Rotterdam",
        shortSquadName: "Excelsior",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
    },
    {
        squadId: 16832,
        squadName: "Boldklubben af 1893",
        shortSquadName: "B.93",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_16832_Boldklubben_af_1893.png"
    },
    {
        squadId: 17017,
        squadName: "Philadelphia Union",
        shortSquadName: "Philadelphia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png"
    },
    {
        squadId: 17053,
        squadName: "Oldham Athletic",
        shortSquadName: "Oldham Athletic",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Oldham_Athletic.png"
    },
    {
        squadId: 17331,
        squadName: "TOP Oss",
        shortSquadName: "TOP Oss",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1565_TOP_Oss.png"
    },
    {
        squadId: 17417,
        squadName: "BSC Young Boys",
        shortSquadName: "BSC Young Boys",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
    },
    {
        squadId: 17418,
        squadName: "Valerenga Fotball",
        shortSquadName: "Valerenga",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png"
    },
    {
        squadId: 17454,
        squadName: "Avai FC",
        shortSquadName: "Avai FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
    },
    {
        squadId: 17788,
        squadName: "Paris Saint-Germain",
        shortSquadName: "Paris SG",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Paris_Saint-Germain.png"
    },
    {
        squadId: 17825,
        squadName: "Hillerod Fodbold",
        shortSquadName: "Hillerod F",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7699_Hilleröd_Fodbold.png"
    },
    {
        squadId: 18028,
        squadName: "FC Lorient",
        shortSquadName: "FC Lorient",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1982_FC_Lorient.png"
    },
    {
        squadId: 18071,
        squadName: "Deportivo Santamarina",
        shortSquadName: "NULL",
        squadLogo: null
    },
    {
        squadId: 18100,
        squadName: "Cracovia",
        shortSquadName: "Cracovia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
    },
    {
        squadId: 18179,
        squadName: "SC Verl",
        shortSquadName: "SC Verl",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
    },
    {
        squadId: 18516,
        squadName: "SV Wehen Wiesbaden",
        shortSquadName: "Wehen Wiesbaden",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
    },
    {
        squadId: 18582,
        squadName: "Panathinaikos Athens",
        shortSquadName: "Panathinaikos",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
    },
    {
        squadId: 18697,
        squadName: "FC Augsburg",
        shortSquadName: "FC Augsburg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1274_FC_Augsburg.png"
    },
    {
        squadId: 18705,
        squadName: "Fredrikstad FK",
        shortSquadName: "Fredrikstad",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fredrikstad_FK.png"
    },
    {
        squadId: 18894,
        squadName: "Brondby IF",
        shortSquadName: "Brondby IF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
    },
    {
        squadId: 19151,
        squadName: "Clermont Foot 63",
        shortSquadName: "Clermont Foot",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3797_Clermont_Foot_Auvergne_63.png"
    },
    {
        squadId: 19177,
        squadName: "SV Wacker Burghausen",
        shortSquadName: "W. Burghausen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1321_SV_Wacker_Burghausen.png"
    },
    {
        squadId: 19206,
        squadName: "Santos FC",
        shortSquadName: "Santos",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
    },
    {
        squadId: 19251,
        squadName: "SV Waldhof Mannheim",
        shortSquadName: "Waldh. Mannheim",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1370_SV_Waldhof_Mannheim.png"
    },
    {
        squadId: 19616,
        squadName: "Aalesunds FK",
        shortSquadName: "Aalesund",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png"
    },
    {
        squadId: 19631,
        squadName: "FK Vojvodina Novi Sad",
        shortSquadName: "Vojvodina",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1660_FK_Vojvodina_Novi_Sad.png"
    },
    {
        squadId: 19775,
        squadName: "HB Koge",
        shortSquadName: "HB Koge",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
    },
    {
        squadId: 20016,
        squadName: "Motherwell FC",
        shortSquadName: "Motherwell FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
    },
    {
        squadId: 20144,
        squadName: "Plymouth Argyle",
        shortSquadName: "Plymouth",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
    },
    {
        squadId: 20454,
        squadName: "Legia Warszawa",
        shortSquadName: "Legia Warszawa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
    },
    {
        squadId: 20897,
        squadName: "Hannover 96",
        shortSquadName: "Hannover 96",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hannover_96.png"
    },
    {
        squadId: 20983,
        squadName: "Club Atletico Belgrano",
        shortSquadName: "Belgrano",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2591_Club_Atletico_Belgrano.png"
    },
    {
        squadId: 21425,
        squadName: "FC St. Gallen 1879",
        shortSquadName: "FC St. Gallen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
    },
    {
        squadId: 21706,
        squadName: "FC Hansa Rostock",
        shortSquadName: "Hansa Rostock",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hansa_Rostock.png"
    },
    {
        squadId: 21881,
        squadName: "Aldershot Town",
        shortSquadName: "Aldershot",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1222_Aldershot_Town.png"
    },
    {
        squadId: 22011,
        squadName: "Puebla FC",
        shortSquadName: "Puebla FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15414_Puebla_FC.png"
    },
    {
        squadId: 22110,
        squadName: "Le Mans FC",
        shortSquadName: "Le Mans FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3786_Le_Mans.png"
    },
    {
        squadId: 22196,
        squadName: "SpVgg Greuther Furth",
        shortSquadName: "Greuther Furth",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
    },
    {
        squadId: 22240,
        squadName: "PAOK Thessaloniki",
        shortSquadName: "PAOK Salonika",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
    },
    {
        squadId: 22392,
        squadName: "Maccabi Tel Aviv",
        shortSquadName: "M. Tel Aviv",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/13270_Maccabi_Tel_Aviv.png"
    },
    {
        squadId: 22514,
        squadName: "CF America",
        shortSquadName: "America",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15405_América.png"
    },
    {
        squadId: 22613,
        squadName: "Kuopion Palloseura",
        shortSquadName: "KuPS",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1499_Kuopion_Palloseura.png"
    },
    {
        squadId: 22719,
        squadName: "Clube de Regatas Vasco da Gama",
        shortSquadName: "Vasco da Gama",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
    },
    {
        squadId: 22720,
        squadName: "Korona Kielce",
        shortSquadName: "Korona Kielce",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1605_Korona_Kielce.png"
    },
    {
        squadId: 22740,
        squadName: "Birmingham City",
        shortSquadName: "Birmingham",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Birmingham_City.png"
    },
    {
        squadId: 22827,
        squadName: "HNK Hajduk Split",
        shortSquadName: "Hajduk Split",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
    },
    {
        squadId: 22866,
        squadName: "KMSK Deinze",
        shortSquadName: "KMSK Deinze",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2368_KMSK_Deinze.png"
    },
    {
        squadId: 22922,
        squadName: "FK Zeleziarne Podbrezova",
        shortSquadName: "Podbrezova",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2129_FK_Zeleziarne_Podbrezova.png"
    },
    {
        squadId: 22992,
        squadName: "Pisa Sporting Club",
        shortSquadName: "Pisa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
    },
    {
        squadId: 23117,
        squadName: "FC Versailles 78",
        shortSquadName: "FC Versailles",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/4213_FC_Versailles_78.png"
    },
    {
        squadId: 23168,
        squadName: "1.FC Koln",
        shortSquadName: "1.FC Koln",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
    },
    {
        squadId: 23571,
        squadName: "Piast Gliwice",
        shortSquadName: "Piast Gliwice",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/13530_Piast_Gliwice.png"
    },
    {
        squadId: 23588,
        squadName: "SC Heerenveen",
        shortSquadName: "Heerenveen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
    },
    {
        squadId: 23647,
        squadName: "Atletico de San Luis",
        shortSquadName: "San Luis",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
    },
    {
        squadId: 23752,
        squadName: "FK Haugesund",
        shortSquadName: "Haugesund",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7114_FK_Haugesund.png"
    },
    {
        squadId: 23873,
        squadName: "Botev Plovdiv",
        shortSquadName: "Botev Plovdiv",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
    },
    {
        squadId: 24099,
        squadName: "Valenciennes FC",
        shortSquadName: "Valenciennes FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1992_Valenciennes_FC.png"
    },
    {
        squadId: 24408,
        squadName: "Club Alianza Lima",
        shortSquadName: "Alianza Lima",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7239_Club_Alianza_Lima.png"
    },
    {
        squadId: 24432,
        squadName: "Pafos FC",
        shortSquadName: "Pafos FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
    },
    {
        squadId: 24449,
        squadName: "Viborg FF",
        shortSquadName: "Viborg FF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
    },
    {
        squadId: 24701,
        squadName: "CF Atlante",
        shortSquadName: "Atlante",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
    },
    {
        squadId: 24711,
        squadName: "Urawa Red Diamonds",
        shortSquadName: "Urawa Reds",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
    },
    {
        squadId: 24973,
        squadName: "Queretaro FC",
        shortSquadName: "Queretaro FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15421_Querétaro_FC.png"
    },
    {
        squadId: 24997,
        squadName: "Randers FC",
        shortSquadName: "Randers FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Randers_FC.png"
    },
    {
        squadId: 25037,
        squadName: "Montevideo City Torque",
        shortSquadName: "Mvd City Torque",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/23497_Montevideo_City_Torque.png"
    },
    {
        squadId: 25054,
        squadName: "Preussen Munster",
        shortSquadName: "Pr. Munster",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Preussen_Munster.png"
    },
    {
        squadId: 25090,
        squadName: "Cimarrones de Sonora",
        shortSquadName: "Cimarrones",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
    },
    {
        squadId: 25293,
        squadName: "IK Start",
        shortSquadName: "Start",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7102_IK_Start.png"
    },
    {
        squadId: 25519,
        squadName: "Rapid Vienna",
        shortSquadName: "Rapid Vienna",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
    },
    {
        squadId: 26056,
        squadName: "AeK Athens",
        shortSquadName: "AeK Athens",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
    },
    {
        squadId: 26202,
        squadName: "Ludogorets Razgrad",
        shortSquadName: "Ludogorets",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
    },
    {
        squadId: 26329,
        squadName: "Trabzonspor",
        shortSquadName: "Trabzonspor",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2006_Trabzonspor.png"
    },
    {
        squadId: 26353,
        squadName: "SV Darmstadt 98",
        shortSquadName: "Darmstadt 98",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
    },
    {
        squadId: 26367,
        squadName: "Santos Laguna",
        shortSquadName: "Santos Laguna",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
    },
    {
        squadId: 26457,
        squadName: "Stabaek Fotball",
        shortSquadName: "Stabaek",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1473_Stabaek_IF.png"
    },
    {
        squadId: 26540,
        squadName: "Orlando City SC",
        shortSquadName: "Orlando",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
    },
    {
        squadId: 26667,
        squadName: "Coritiba Foot Ball Club",
        shortSquadName: "Coritiba FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
    },
    {
        squadId: 26895,
        squadName: "Jagiellonia Bialystok",
        shortSquadName: "Jagiellonia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1597_Jagiellonia_Bialystok.png"
    },
    {
        squadId: 27176,
        squadName: "KV Mechelen",
        shortSquadName: "KV Mechelen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
    },
    {
        squadId: 27180,
        squadName: "NS Mura",
        shortSquadName: "NS Mura",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/31278_NŠ_Mura.png"
    },
    {
        squadId: 27185,
        squadName: "Aveley FC",
        shortSquadName: "Aveley FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1913_Aveley_FC.png"
    },
    {
        squadId: 27522,
        squadName: "Universidad Cesar Vallejo",
        shortSquadName: "Cesar Vallejo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_27522_Universidad_Cesar_Vallejo.png"
    },
    {
        squadId: 27650,
        squadName: "FC Juarez",
        shortSquadName: "FC Juarez",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/34402_FC_Juárez.png"
    },
    {
        squadId: 27833,
        squadName: "America Futebol Clube (MG)",
        shortSquadName: "America-MG",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5673_América_Futebol_Clube_(MG).png"
    },
    {
        squadId: 27984,
        squadName: "Ranheim IL",
        shortSquadName: "Ranheim",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7193_Ranheim_IL.png"
    },
    {
        squadId: 28130,
        squadName: "IF Brommapojkarna",
        shortSquadName: "Brommapojkarna",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_IF_Brommapojkarna.png"
    },
    {
        squadId: 28158,
        squadName: "Aalborg BK",
        shortSquadName: "Aalborg BK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
    },
    {
        squadId: 28212,
        squadName: "RC Strasbourg Alsace",
        shortSquadName: "R. Strasbourg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
    },
    {
        squadId: 28372,
        squadName: "Club Atletico Morelia",
        shortSquadName: "Morelia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
    },
    {
        squadId: 28535,
        squadName: "Naestved Boldklub",
        shortSquadName: "Naestved BK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
    },
    {
        squadId: 28879,
        squadName: "Athletic Bilbao",
        shortSquadName: "Athletic",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/678_Athletic_Bilbao.png"
    },
    {
        squadId: 28997,
        squadName: "Aberdeen FC",
        shortSquadName: "Aberdeen FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
    },
    {
        squadId: 29285,
        squadName: "BK Hacken",
        shortSquadName: "Hacken",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
    },
    {
        squadId: 29306,
        squadName: "Mineros de Zacatecas",
        shortSquadName: "Min. Zacatecas",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png"
    },
    {
        squadId: 29455,
        squadName: "Nottingham Forest",
        shortSquadName: "Nottm Forest",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1152_Nottingham_Forest.png"
    },
    {
        squadId: 29469,
        squadName: "GNK Dinamo Zagreb",
        shortSquadName: "Dinamo Zagreb",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2163_GNK_Dinamo_Zagreb.png"
    },
    {
        squadId: 29478,
        squadName: "FC Trollhattan",
        shortSquadName: "FC Trollhattan",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_29478_FC_Trollhattan.png"
    },
    {
        squadId: 29529,
        squadName: "Cheltenham Town",
        shortSquadName: "Cheltenham",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
    },
    {
        squadId: 29646,
        squadName: "NK Domzale",
        shortSquadName: "NK Domzale",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2463_NK_Domzale.png"
    },
    {
        squadId: 29655,
        squadName: "Blackburn Rovers",
        shortSquadName: "Blackburn",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/16388_Blackburn_Rovers.svg"
    },
    {
        squadId: 29704,
        squadName: "NAC Breda",
        shortSquadName: "NAC Breda",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_NAC_Breda.png"
    },
    {
        squadId: 29757,
        squadName: "Bradford City",
        shortSquadName: "Bradford",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1653_Bradford_City.png"
    },
    {
        squadId: 29766,
        squadName: "Gornik Zabrze",
        shortSquadName: "Gornik Zabrze",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
    },
    {
        squadId: 29787,
        squadName: "Phoenix Rising FC",
        shortSquadName: "Phoenix Rising",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/29992_Phoenix_Rising.png"
    },
    {
        squadId: 29796,
        squadName: "Alebrijes de Oaxaca",
        shortSquadName: "Oaxaca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
    },
    {
        squadId: 30012,
        squadName: "Crewe Alexandra",
        shortSquadName: "Crewe Alexandra",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
    },
    {
        squadId: 30372,
        squadName: "Envigado FC",
        shortSquadName: "Envigado",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
    },
    {
        squadId: 30436,
        squadName: "Dijon FCO",
        shortSquadName: "Dijon",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1976_FCO_Dijon.png"
    },
    {
        squadId: 30440,
        squadName: "CA Independiente",
        shortSquadName: "Independiente",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30440_CA_Independiente.png"
    },
    {
        squadId: 30475,
        squadName: "Malmo FF",
        shortSquadName: "Malmo FF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
    },
    {
        squadId: 30586,
        squadName: "AC Sparta Prague",
        shortSquadName: "Sparta Prague",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
    },
    {
        squadId: 30723,
        squadName: "Borussia Dortmund",
        shortSquadName: "Bor. Dortmund",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1259_Borussia_Dortmund.png"
    },
    {
        squadId: 30734,
        squadName: "Altinordu FK",
        shortSquadName: "Altinordu",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
    },
    {
        squadId: 30849,
        squadName: "Atlanta United FC",
        shortSquadName: "Atlanta",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
    },
    {
        squadId: 31138,
        squadName: "Club Leon FC",
        shortSquadName: "Leon",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
    },
    {
        squadId: 31205,
        squadName: "SG Dynamo Dresden",
        shortSquadName: "Dynamo Dresden",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2478_SG_Dynamo_Dresden.png"
    },
    {
        squadId: 31306,
        squadName: "Kolding IF",
        shortSquadName: "Kolding IF",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7622_Kolding_IF.png"
    },
    {
        squadId: 31340,
        squadName: "Casa Pia AC",
        shortSquadName: "Casa Pia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/9708_Casa_Pia_AC.png"
    },
    {
        squadId: 31438,
        squadName: "Havant & Waterlooville FC",
        shortSquadName: "Havant & Water.",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2299_Havant_And_Waterlooville_FC.PNG"
    },
    {
        squadId: 31536,
        squadName: "Aris Limassol",
        shortSquadName: "Aris Limassol",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_31536_Aris_Limassol.png"
    },
    {
        squadId: 31588,
        squadName: "Rangers FC",
        shortSquadName: "Rangers",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
    },
    {
        squadId: 32070,
        squadName: "Wisla Pulawy",
        shortSquadName: "Wisla Pulawy",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/13769_Wisla_Pulawy.png"
    },
    {
        squadId: 32247,
        squadName: "Orsomarso SC",
        shortSquadName: "Orsomarso SC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_32247_Orsomarso_SC.png"
    },
    {
        squadId: 32489,
        squadName: "Stockport County",
        shortSquadName: "Stockport",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1667_Stockport_County.jpg"
    },
    {
        squadId: 32515,
        squadName: "IK Sirius",
        shortSquadName: "Sirius",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1446_IK_Sirius.png"
    },
    {
        squadId: 32586,
        squadName: "Omonia Nicosia",
        shortSquadName: "Omonia Nikosia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2175_Omonia_Nicosia.png"
    },
    {
        squadId: 32694,
        squadName: "Sacramento Republic FC",
        shortSquadName: "Sacramento FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/28727_Sacramento_Republic.png"
    },
    {
        squadId: 32704,
        squadName: "UNAM Pumas",
        shortSquadName: "UNAM Pumas",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
    },
    {
        squadId: 32753,
        squadName: "Deportivo Toluca",
        shortSquadName: "Toluca",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15418_Deportivo_Toluca.png"
    },
    {
        squadId: 33026,
        squadName: "FC Rapid 1923",
        shortSquadName: "FC Rapid 1923",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
    },
    {
        squadId: 33048,
        squadName: "RSC Charleroi",
        shortSquadName: "RSC Charleroi",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1580_RSC_Charleroi.png"
    },
    {
        squadId: 33438,
        squadName: "FC Thun",
        shortSquadName: "FC Thun",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
    },
    {
        squadId: 33615,
        squadName: "Zalaegerszegi TE FC",
        shortSquadName: "Zalaegerszeg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_33615_Zalaegerszegi_TE_FC.png"
    },
    {
        squadId: 33648,
        squadName: "Atletico Nacional",
        shortSquadName: "Atl. Nacional",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
    },
    {
        squadId: 33676,
        squadName: "Sandefjord Fotball",
        shortSquadName: "Sandefjord",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sandefjord_Fotball.png"
    },
    {
        squadId: 33797,
        squadName: "Spezia Calcio",
        shortSquadName: "Spezia Calcio",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/3205_Spezia_Calcio.webp"
    },
    {
        squadId: 33813,
        squadName: "Karlsruher SC",
        shortSquadName: "Karlsruher SC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
    },
    {
        squadId: 33893,
        squadName: "FC Emmen",
        shortSquadName: "FC Emmen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Emmen.png"
    },
    {
        squadId: 34038,
        squadName: "Club Cerro Porteno",
        shortSquadName: "Cerro Porteno",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_34038_Club_Cerro_Porteno.png"
    },
    {
        squadId: 34616,
        squadName: "Vejle Boldklub",
        shortSquadName: "Vejle BK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1422_Vejle_Boldklub.png"
    },
    {
        squadId: 34776,
        squadName: "Goztepe",
        shortSquadName: "Goztepe",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
    },
    {
        squadId: 34923,
        squadName: "Harrogate Town",
        shortSquadName: "Harrogate Town",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
    },
    {
        squadId: 35045,
        squadName: "Vitesse Arnhem",
        shortSquadName: "Vitesse",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png"
    },
    {
        squadId: 35103,
        squadName: "Standard Liege",
        shortSquadName: "Standard Liege",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
    },
    {
        squadId: 35181,
        squadName: "SpVgg Unterhaching",
        shortSquadName: "Unterhaching",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2471_SpVgg_Unterhaching.png"
    },
    {
        squadId: 35839,
        squadName: "Sociedade Esportiva Palmeiras",
        shortSquadName: "Palmeiras",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
    },
    {
        squadId: 36491,
        squadName: "FK Vozdovac",
        shortSquadName: "Vozdovac",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
    },
    {
        squadId: 36736,
        squadName: "Olimpia Asuncion",
        shortSquadName: "Olimpia",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15486_Olimpia.png"
    },
    {
        squadId: 36837,
        squadName: "AJ Auxerre",
        shortSquadName: "AJ Auxerre",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1985_AJ_Auxerre.png"
    },
    {
        squadId: 37066,
        squadName: "SSV Ulm 1846",
        shortSquadName: "SSV Ulm 1846",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2508_SSV_Ulm_1846.png"
    },
    {
        squadId: 37217,
        squadName: "Deportes Tolima",
        shortSquadName: "Deportes Tolima",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
    },
    {
        squadId: 37333,
        squadName: "NK Istra 1961",
        shortSquadName: "NK Istra",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2169_NK_Istra_1961.png"
    },
    {
        squadId: 37341,
        squadName: "IFK Goteborg",
        shortSquadName: "IFK Goteborg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1438_IFK_Goteborg.png"
    },
    {
        squadId: 37475,
        squadName: "Mamelodi Sundowns FC",
        shortSquadName: "Sundowns",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
    },
    {
        squadId: 37659,
        squadName: "Kongsvinger IL",
        shortSquadName: "Kongsvinger",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
    },
    {
        squadId: 38062,
        squadName: "Bromley FC",
        shortSquadName: "Bromley",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1820_Bromley_FC.png"
    },
    {
        squadId: 38328,
        squadName: "D.C. United",
        shortSquadName: "D.C. United",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
    },
    {
        squadId: 38454,
        squadName: "Sandnes Ulf",
        shortSquadName: "Sandnes Ulf",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sandnes_Ulf.png"
    },
    {
        squadId: 38553,
        squadName: "Aston Villa",
        shortSquadName: "Aston Villa",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
    },
    {
        squadId: 38623,
        squadName: "Pogon Szczecin",
        shortSquadName: "Pogon Szczecin",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
    },
    {
        squadId: 38864,
        squadName: "Slask Wroclaw",
        shortSquadName: "Slask Wroclaw",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
    },
    {
        squadId: 39077,
        squadName: "Cerro Largo FC",
        shortSquadName: "Cerro Largo",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/15654_Cerro_Largo_FC.png"
    },
    {
        squadId: 39176,
        squadName: "Leeds United",
        shortSquadName: "Leeds",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
    },
    {
        squadId: 39191,
        squadName: "LKS Lodz",
        shortSquadName: "LKS Lodz",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/13528_Łódzki_KS.png"
    },
    {
        squadId: 39337,
        squadName: "SK Dnipro-1",
        shortSquadName: "SK Dnipro-1",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png"
    },
    {
        squadId: 39599,
        squadName: "FC Midtjylland",
        shortSquadName: "FC Midtjylland",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png"
    },
    {
        squadId: 39648,
        squadName: "New England Revolution",
        shortSquadName: "New England",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7854_New_England_Revolution.png"
    },
    {
        squadId: 40144,
        squadName: "Delfin SC",
        shortSquadName: "Delfin SC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_40144_Delfin_SC.png"
    },
    {
        squadId: 40170,
        squadName: "MSV Duisburg",
        shortSquadName: "MSV Duisburg",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1292_MSV_Duisburg.png"
    },
    {
        squadId: 40208,
        squadName: "Tlaxcala FC",
        shortSquadName: "Tlaxcala",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/31554_Tlaxcala_FC.png"
    },
    {
        squadId: 40356,
        squadName: "SV Werder Bremen",
        shortSquadName: "Werder Bremen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
    },
    {
        squadId: 40409,
        squadName: "Oxford United",
        shortSquadName: "Oxford United",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
    },
    {
        squadId: 40508,
        squadName: "LASK",
        shortSquadName: "LASK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
    },
    {
        squadId: 40550,
        squadName: "Rot-Weiss Essen",
        shortSquadName: "RW Essen",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1391_Rot-Weiss_Essen.png"
    },
    {
        squadId: 40635,
        squadName: "Ross County FC",
        shortSquadName: "Ross County",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
    },
    {
        squadId: 40692,
        squadName: "Columbus Crew",
        shortSquadName: "Columbus",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7855_Columbus_Crew.png"
    },
    {
        squadId: 40821,
        squadName: "Skeid Oslo",
        shortSquadName: "Skeid",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/7108_Skeid_Oslo.png"
    },
    {
        squadId: 41660,
        squadName: "VfL Osnabruck",
        shortSquadName: "VfL Osnabruck",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/2501_VfL_Osnabrück.jpg"
    },
    {
        squadId: 41684,
        squadName: "Twente Enschede FC",
        shortSquadName: "Twente FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
    },
    {
        squadId: 41761,
        squadName: "Leyton Orient",
        shortSquadName: "Leyton Orient",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
    },
    {
        squadId: 41956,
        squadName: "Deportivo Cali",
        shortSquadName: "Deportivo Cali",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png"
    },
    {
        squadId: 42063,
        squadName: "Ujpest FC",
        shortSquadName: "Ujpest FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
    },
    {
        squadId: 42124,
        squadName: "Altrincham FC",
        shortSquadName: "Altrincham",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2531_Altrincham_FC.png"
    },
    {
        squadId: 42757,
        squadName: "El Paso Locomotive FC",
        shortSquadName: "El Paso",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/63762_El_Paso_Locomotive_FC.png"
    },
    {
        squadId: 42798,
        squadName: "Cavalry FC",
        shortSquadName: "Cavalry FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
    },
    {
        squadId: 42813,
        squadName: "Loudoun United FC",
        shortSquadName: "Loudoun United",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/63759_Loudoun_United_FC.png"
    },
    {
        squadId: 50113,
        squadName: "Inter Miami CF",
        shortSquadName: "Miami",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
    },
    {
        squadId: 200001,
        squadName: "Mazatlan FC",
        shortSquadName: "Mazatlan FC",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
    },
    {
        squadId: 200003,
        squadName: "Oakland Roots SC",
        shortSquadName: "Oakland Roots",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69044_Oakland_Roots_SC.png"
    },
    {
        squadId: 200004,
        squadName: "Charlotte FC",
        shortSquadName: "Charlotte",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
    },
    {
        squadId: 200102,
        squadName: "FC KTP",
        shortSquadName: "FC KTP",
        squadLogo: null
    },
    {
        squadId: 200140,
        squadName: "Grand Ouest Association Lyonnaise FC",
        shortSquadName: "GOAL FC",
        squadLogo: null
    },
    {
        squadId: 200193,
        squadName: "Manisa FK",
        shortSquadName: "Manisa FK",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/48913_Manisa_FK.png"
    },
    {
        squadId: 224024,
        squadName: "St. Louis CITY SC",
        shortSquadName: "St. Louis CITY",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82686_St._Louis_City_SC.png"
    },
    {
        squadId: 225010,
        squadName: "CD Real Cartagena",
        shortSquadName: "Real Cartagena",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
    },
    {
        squadId: 225037,
        squadName: "Atletico La Paz",
        shortSquadName: "Atletico La Paz",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/100124_Atlético_La_Paz.png"
    },
    {
        squadId: 225531,
        squadName: "Cibao FC",
        shortSquadName: "Cibao FC",
        squadLogo: null
    },
    {
        squadId: 225557,
        squadName: "Clube Atletico Mineiro",
        shortSquadName: "Atletico-MG",
        squadLogo: "https://transferroom.blob.core.windows.net/clubs/-330_Clube_Atlético_Mineiro.png"
    }
]