export const testimonials = [
    {
        fullName: 'Joe Shields',
        position: 'Co-Director of Recruitment',
        club: 'Chelsea',
        img: require('media/images/events/2024-january-virtual/joe-shields.jpg'),
        text: '“It’s a really easy opportunity to connect with loads of people at once and maximise your time. Everyone knows what they’re here for. It cuts out the small talk, travel and time wasted so you can get to the point. It’s a great environment.”',
    },
    {
        fullName: 'Carlos Aviña',
        position: 'Technical Director',
        club: 'Monaco',
        img: require('media/images/events/2024-january-virtual/carlos-avina.jpg'),
        text: '“It’s a perfect opportunity to meet key decision makers from all over the world. I had meetings with clubs from Asia, Europe, Latin America… everywhere. I’m sure it will lead to a lot of transfer opportunities.”',
    },
    {
        fullName: 'Victor Orta',
        position: 'Sporting Director',
        club: 'Sevilla FC',
        img: require('media/images/events/2024-january-virtual/victor-orta.jpg'),
        text: '“The ability to network directly with other club decision makers and agents is really useful. I always leave as a better professional with much better knowledge.”',
    },
    {
        fullName: 'Alexandre Mattos',
        position: 'CEO',
        club: 'Club Athletico Paranaense',
        img: require('media/images/events/2024-january-virtual/mattos.png'),
        text: '“TransferRoom allows you to reach out directly to clubs without relying on intermediaries and that facilitates the negotiation process. It means you instantly know their needs and whether or not it is possible to close a deal. This network you are able to create with clubs from all over the world is crucial to have success in the transfer market, and I recommend it to everyone in the football industry.”',
    },
    {
        fullName: 'Cristiano Giaretta',
        position: 'Sporting Director',
        club: 'Watford FC',
        img: require('media/images/events/2024-january-virtual/cristiano-giaretta.jpg'),
        text: '“It’s an important event to attend because in one day a Sporting Director can do the activity that is normally done in two months.”',
    },
    {
        fullName: 'Ricardo Moreira',
        position: 'Technical Director',
        club: 'Orlando City SC',
        img: require('media/images/events/2024-january-virtual/ricardo-moreira.jpg'),
        text: '“There’s no doubt that it’s a place that you just have to be, as a Sporting Director, as a club. It’s very important to be part of this network of great people and decision makers. If you’re a club that’s not here you’re probably missing something in the market.”',
    },
    {
        fullName: 'Bruno Alves',
        position: 'Sporting Director',
        club: 'AEK Athens',
        img: require('media/images/events/2024-january-virtual/bruno-alves.jpg'),
        text: '“My first experience of a TransferRoom Summit was very productive. It’s important to share contacts, connections and information. Exchanging club visions and mentalities is a great learning opportunity.”',
    },
]