import React from 'react'
import styled from 'styled-components'

class Props {
    forAgency?: boolean
}

const Agenda = (props: Props) => {
    return (
        <Section>
            <div className="ep-auth-agenda">
                <h2 className="ep-auth-agenda__heading">
                    Agenda
                </h2>
                <div className="ep-auth-agenda__item-container ep-auth-agenda__item-container-row">
                    <div className="ep-auth-agenda__item">
                        <div className="ep-auth-agenda__date">
                            MONDAY, 21st MARCH
                        </div>
                        <div className="ep-auth-agenda__timeline">
                            <div className="ep-auth-agenda__timeline-tip">
                                All times are CET
                            </div>

                            <div className='ep-auth-agenda__subtitle'>
                                Part 1: Speaker sessions
                            </div>
                            <div className="timeline-row">
                                <div className="time">11.30 - 12.30</div>
                                <div className="description">Registration & coffee</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">12.30 - 13.00</div>
                                <div className="description">TransferRoom presentation</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">13.00 - 13.45</div>
                                <div className="description">Rasmus Ankersen: Winning on and off the pitch</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">13.45 - 15.00</div>
                                <div className="description">Lunch</div>
                            </div>
                            <div className='ep-auth-agenda__subtitle'>
                                Part 2: Networking
                            </div>
                            <div className="timeline-row">
                                <div className="time">15.00 - 16.30</div>
                                <div className="description">One-to-one meetings</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">16.30 - 18.00</div>
                                <div className="description">Drinks & networking</div>
                            </div>

                        </div>
                    </div>

                    <div className="ep-auth-agenda__item">
                        <div className="ep-auth-agenda__date">
                            TUESDAY, 22nd MARCH
                        </div>
                        <div className="ep-auth-agenda__timeline">
                            <div className="ep-auth-agenda__timeline-tip">
                                All times are CET
                            </div>
                            <div className='ep-auth-agenda__subtitle'>
                                Part 1: Speaker sessions
                            </div>
                            <div className="timeline-row">
                                <div className="time">09.00 - 10.00</div>
                                <div className="description">Registration & coffee</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">10.00 - 11.00</div>
                                <div className="description">Paul Mitchell: Big club rebuild</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">11.00 - 11.15</div>
                                <div className="description">Break</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">11.15 - 12.45</div>
                                <div className="description">One-to-one meetings</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">12.45 - 13.45</div>
                                <div className="description">Lunch</div>
                            </div>
                            <div className='ep-auth-agenda__subtitle'>
                                Part 2: Networking
                            </div>
                            <div className="timeline-row">
                                <div className="time">13.45 - 15.15</div>
                                <div className="description">One-to-one meetings</div>
                            </div>
                            <div className="timeline-row">
                                <div className="time">15.15 - 16.30</div>
                                <div className="description">Drinks & networking</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

const Section = styled.div`
    .ep-auth-agenda {
        margin: 0 auto;
        padding-left: 5px;
        margin-top: 74px;
        margin-bottom: 40px;
        padding-bottom: 0px;

        &__item-container {
            display: flex;
            justify-content: space-between;
        }

        
        &__item-container-row {
            flex-direction: column;
            .ep-auth-agenda__item {
                &:first-child {
                    margin-bottom: 50px;
                }
            }
        
        }

        &__item {
            margin-bottom: 20px;
            &:first-child {
                padding-right: 10px;
            }
        }

        @media (max-width: 1000px) {
            &__item-container {
                flex-direction: column;
            }

            &__item {
                margin-bottom: 20px;
                &:first-child {
                    padding: 0;
                }
            }
        }

        &__heading {
            font-size: 40px;
            font-weight: bold;
        }
        &__date {
            font-size: 32px;
            font-weight: 400;
            color: #1d1c1d;
            @media (max-width: 420px) {
                font-size: 28px;
            }
        }

        &__subtitle {
            font-weight: bold;
            font-size: 24px;
            line-height: 23px;
            color: #131A36;
            margin-top: 30px;
        }
        &__timeline {
            margin-top: 10px;
            &-tip {
                color: #e55843;
                font-size: 15px;
                font-weight: 500;
            }
            &-subheader {
                font-size: 24px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 22px;
                margin-top: 32px;
            }
            .timeline-row {
                display: grid;
                grid-template-areas: "time description";
                grid-template-columns: 120px 1fr;
                gap: 18px;
                margin-top: 15px;
                @media (max-width: 420px) {
                    grid-template-columns: 102px 1fr;
                    gap: 5px;
                }
                .time {
                    grid-area: time;
                    font-size: 19px;
                    color: #0E9655;
                    text-align: left;
                    font-weight: 700;
                    @media (max-width: 420px) {
                        font-size: 17px;
                    }
                }
                .description {
                    grid-area: description;
                    font-size: 19px;
                    font-weight: 700;
                    text-align: left;
                    color: #1d1c1d;
                    @media (max-width: 420px) {
                        font-size: 17px;
                    }
                }
            }
        }
    }
`

export default Agenda