import * as redux from 'redux';
import * as reonboarding from './reonboarding.controller';
import * as selectPlayers from './select-players-stepper/select-players-stepper.controller';
import * as selectPitchablePlayers from './select-pitchable-players-stepper/select-pitchable-players-stepper.controller';
import * as createPitches from './create-pitches-stepper/create-pitches-stepper.controller';
import * as verifyPlayers from './verify-players-stepper/verify-players-stepper.controller';

export class ReonboardingRootState {
    reonboarding: reonboarding.State;
    selectPlayers: selectPlayers.State;
    selectPitchablePlayers: selectPitchablePlayers.State;
    createPitches: createPitches.State;
    verifyPlayers: verifyPlayers.State;
}

export function combineReducers() {

    let reonboardingRoot = {
        reonboarding: reonboarding.Reducer,
        selectPlayers: selectPlayers.Reducer,
        selectPitchablePlayers: selectPitchablePlayers.Reducer,
        createPitches: createPitches.Reducer,
        verifyPlayers: verifyPlayers.Reducer
    };

    return redux.combineReducers(reonboardingRoot)
}