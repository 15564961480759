import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ADMIN_PLAYER_PROPOSALS_SEND_EMAIL } from 'store/actionTypes';
import { notificationCreate } from '../../../app/notifications/notifications.actions';

const playerProposalSentEpic = (action$, state$) => {
    return action$.pipe(
        ofType(ADMIN_PLAYER_PROPOSALS_SEND_EMAIL.SUCCESS),
        map(() => {
            const isSuccess = state$.value.playerProposals.isSuccess;

            const message = isSuccess
                ? 'Player proposal email sent successfully'
                : 'Failed to send Player proposal email';
            const level = isSuccess ? 'success' : 'error';

            return notificationCreate({
                message: message,
                level: level,
            });
        }),
    );
};

export default playerProposalSentEpic;
