import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { userPaths, agencyPaths, commonPagesPaths } from 'routes/paths';
import CommonService from 'api/common/common.service';
import { hideTermsAndConditions } from 'store/auth/auth.actions';
import { AgreeDocumentsUserTypeEnum } from 'api/common/models';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { isAgencyUser, isClubUser, isPlayerUser, isHeadCoachUser } from 'store/auth/authReducer';
import historyAccessor from 'history-accessor';
import LiveChatController from 'services/live-chat-controller';

class UpdatedDocumentsModalState {
    isProcessing: boolean;
    isModalOpen: boolean;
    isAgreedTermsOfService: boolean;
}

const defaultState: UpdatedDocumentsModalState = {
    isProcessing: false,
    isModalOpen: false,
    isAgreedTermsOfService: false,
}

const stateController = new StateController<UpdatedDocumentsModalState>(
    "COMMON/UPDATED-DOCUMENTS",
    defaultState
)

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const isModalCanBeShown = getState().auth.showTermsAndConditionsModal
            if (isModalCanBeShown) {
                dispatch(stateController.setState({ isModalOpen: true }));

                dispatch(userActivityInsert({
                    Message: 'Viewed Documents Update Modal',
                    PageName: 'Documents Update Modal',
                }));
            }
        }
    }

    public static openDocumentPage = () => (dispatch, getState: () => AppState) => {
        const isUserClub = isClubUser(getState());
        const isUserAgency = isAgencyUser(getState());
        const isUserPlayer = isPlayerUser(getState());
        const isCoachUser = isHeadCoachUser(getState());

        if (isUserClub) {
            historyAccessor.push(userPaths.editProfileDocuments);
            dispatch(Actions.onClose());
        }
        if (isUserAgency) {
            historyAccessor.push(agencyPaths.editProfileDocuments);
            dispatch(Actions.onClose());
        }
        if (isUserPlayer) {
            // historyAccessor.push(commonPagesPaths.playerTermsOfService);
            window.open(commonPagesPaths.playerTermsOfService, '_blank')
        }
        if (isCoachUser) {
            // historyAccessor.push(commonPagesPaths.coachTermsOfService);
            window.open(commonPagesPaths.coachTermsOfService, '_blank')
        }

        dispatch(userActivityInsert({
            Message: 'Clicked Terms of Service Button',
            PageName: 'Documents Update Modal',
        }));
    }

    public static openLiveChat() {
        return async (dispatch, getState: () => AppState) => {
            await LiveChatController.show();
            LiveChatController.openConversation();

            dispatch(userActivityInsert({
                Message: 'Clicked Contact Button',
                PageName: 'Documents Update Modal',
            }));

            dispatch(Actions.onClose());

        }
    }

    public static onClose(isSendUaOnClick?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                dispatch(hideTermsAndConditions());
                const isUserClub = isClubUser(getState());
                const isUserAgency = isAgencyUser(getState());
                const isUserPlayer = isPlayerUser(getState());
                const isCoachUser = isHeadCoachUser(getState());

                if (isUserClub) {
                    await CommonService.setTermsAndConditionsAsShown(AgreeDocumentsUserTypeEnum.Club);
                    await CommonService.acceptTermsAndConditions(AgreeDocumentsUserTypeEnum.Club);
                }
                if (isUserAgency) {
                    await CommonService.setTermsAndConditionsAsShown(AgreeDocumentsUserTypeEnum.Agency);
                    await CommonService.acceptTermsAndConditions(AgreeDocumentsUserTypeEnum.Agency);
                }
                if (isUserPlayer) {
                    await CommonService.setTermsAndConditionsAsShown(AgreeDocumentsUserTypeEnum.Player);
                    await CommonService.acceptTermsAndConditions(AgreeDocumentsUserTypeEnum.Player);
                }
                if (isCoachUser) {
                    await CommonService.setTermsAndConditionsAsShown(AgreeDocumentsUserTypeEnum.HeadCoach);
                    await CommonService.acceptTermsAndConditions(AgreeDocumentsUserTypeEnum.HeadCoach);
                }

                if (!!isSendUaOnClick) {
                    dispatch(userActivityInsert({
                        Message: 'Closed Modal',
                        PageName: 'Documents Update Modal',
                    }));
                }

            } catch(err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({
                    isProcessing: false,
                    isModalOpen: false,
                }))
            }
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UpdatedDocumentsModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};