import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { Actions as StepActions, RegistrationState } from './confirm.controller';
import { Actions as ExtraActions } from './step-2-1.controller';
import { ExtraAttendeeModel } from 'api/virtual-summit/models/summit-invitationl';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class ConfirmStep2State {
    isLoading: boolean;
    options: Array<AttendeeOption>
    selectedNo2?: { id: number, name: string };
    selectedNo3?: { id: number, name: string };
    selectedNo4?: { id: number, name: string };
    usersToRegister: Array<number>;
    createdUsers: Array<{ id: number, order: number }>
    isCompleting: boolean;
    attendeesInfo: Array<ExtraAttendeeModel>;
}

export class AttendeeOption {
    id: number;
    name: string;
    disabled: boolean;
    order?: number;
    positionTitle: string;
}

const defaultState: ConfirmStep2State = {
    isLoading: false,
    options: [],
    selectedNo2: null,
    selectedNo3: null,
    selectedNo4: null,
    usersToRegister: [],
    createdUsers: [],
    isCompleting: false,
    attendeesInfo: null
}

const stateController = new StateController<ConfirmStep2State>(
    "VIRTUAL_SUMMIT/CONFIRM_STEP2",
    defaultState
);

class Actions {
    public static continue = (redirect: () => void) => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            const { eventId } = state.auth;

            let toRegister = [];
            if ((state.virtualSummit.confirmStep2.selectedNo2 || {}).id == -1) toRegister.push(2);
            if ((state.virtualSummit.confirmStep2.selectedNo3 || {}).id == -1) toRegister.push(3);
            if ((state.virtualSummit.confirmStep2.selectedNo4 || {}).id == -1) toRegister.push(4);

            dispatch(stateController.setState({ usersToRegister: toRegister }));

            if (toRegister.length > 0) {
                dispatch(ExtraActions.init(toRegister[0], state.virtualSummit.confirmStep1.user.club))
                dispatch(StepActions.setStep(RegistrationState.AttendeesDetails));
            }
            else {

                await dispatch(Actions.setAttendees())

                let ids: Array<number> = [];
                let substate = state.virtualSummit.confirmStep2
                if (substate.selectedNo2 && substate.selectedNo2.id != -1) ids.push(substate.selectedNo2.id);
                if (substate.selectedNo3 && substate.selectedNo3.id != -1) ids.push(substate.selectedNo3.id);
                if (substate.selectedNo4 && substate.selectedNo4.id != -1) ids.push(substate.selectedNo4.id);

                for (var id of ids) {
                    var userGet = state.virtualSummit.confirmStep2.options.find(item => item.id == id)
                    const isUserRegistrationWasAlreadyTracked = userGet.disabled
                    
                    if (!isUserRegistrationWasAlreadyTracked) {
                        dispatch(
                            userActivityInsert({
                                Message: `Added Extra Attendees. Selected: ${userGet.name}, ${userGet.positionTitle}`,
                                PageName: 'Event [Details]',
                                CurrentClubId: state.auth.squadId,
                                PageType: PageType.Events,
                                EventId: eventId
                            }),
                        );
                    }
                }
                if (redirect != null && state.virtualSummit.confirmPage.fromEvent)
                    redirect();
                else
                    dispatch(StepActions.setStep(RegistrationState.Done));
            }
        }
    }

    public static skipStep = (redirect: () => void) => {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            if (redirect != null && state.virtualSummit.confirmPage.fromEvent)
                redirect();
            else
                dispatch(StepActions.setStep(RegistrationState.Done));
        }
    }

    public static addCreatedUser = (user: { id: number, order: number }) => {
        return async (dispatch, getState: () => AppState) => {
            let created = [...getState().virtualSummit.confirmStep2.createdUsers, user];
            dispatch(stateController.setState({ createdUsers: created }));
        }
    }

    public static setAttendees = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isCompleting: true }));
            let substate = getState().virtualSummit.confirmStep2;

            let attendees: Array<{ userId: number, orderNumber: number }> = [];            
            if (substate.selectedNo2 && substate.selectedNo2.id != -1) attendees.push({ userId: substate.selectedNo2.id, orderNumber: 2 });
            if (substate.selectedNo3 && substate.selectedNo3.id != -1) attendees.push({ userId: substate.selectedNo3.id, orderNumber: 3 });
            if (substate.selectedNo4 && substate.selectedNo4.id != -1) attendees.push({ userId: substate.selectedNo4.id, orderNumber: 4 });

            attendees = attendees.filter(x => x.userId !== null);

            substate.createdUsers.map(i => attendees.push({ userId: i.id, orderNumber: i.order }));
            await VirtualSummitService.setExtraAtendee(getState().auth.userId, attendees);
            dispatch(stateController.setState({ isCompleting: false }));
        }
    }

    public static attendeesRemoveFirst = () => {
        return async (dispatch, getState: () => AppState) => {
            let toRegister = [...getState().virtualSummit.confirmStep2.usersToRegister];
            toRegister.shift();
            dispatch(stateController.setState({ usersToRegister: toRegister }));
        }
    }

    public static onNo2Changed = (id: { id: number, name: string }) => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = state.virtualSummit.confirmStep2;
            dispatch(stateController.setState({ selectedNo2: id }));
        }
    }

    public static onNo3Changed = (id: { id: number, name: string }) => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = state.virtualSummit.confirmStep2;
            dispatch(stateController.setState({ selectedNo3: id }));
        }
    }

    public static onNo4Changed = (id: { id: number, name: string }) => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = state.virtualSummit.confirmStep2;
            dispatch(stateController.setState({ selectedNo4: id }));
        }
    }

    public static setOptionsList = (options: Array<AttendeeOption>) => {
        return async (dispatch, getState: () => AppState) => {
            let selectedNo2: any = options.find(i => i.order == 2);
            let selectedNo3: any = options.find(i => i.order == 3);
            let selectedNo4: any = options.find(i => i.order == 4);

            if (selectedNo2) selectedNo2 = { id: selectedNo2.id, name: selectedNo2.name }
            if (selectedNo3) selectedNo3 = { id: selectedNo3.id, name: selectedNo3.name }
            if (selectedNo4) selectedNo4 = { id: selectedNo4.id, name: selectedNo4.name }

            dispatch(stateController.setState({
                options: options,
                selectedNo2: selectedNo2,
                selectedNo3: selectedNo3,
                selectedNo4: selectedNo4,
            }));
        }
    }

    public static setAttendeesInfo = (attendees: Array<ExtraAttendeeModel>) => {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({ attendeesInfo: attendees }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmStep2State as State,
    Actions as Actions,
    stateController as Controller
};



