import axios from 'axios-config';
import { AlertList } from './agent-alert'

export default class AgencyAdPermissionsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAlert(): Promise<AlertList> {
        const { data } = await axios.get<AlertList>(`api/v2/AgencyAlert/Get`, this.axiosConfig)
        return data;
    }

    public static async dimsiss() {
        const { data } = await axios.post(
            `api/v2/AgencyAlert/Dismiss`,
             1,// only one dismiss type
            this.axiosConfig
        );
        return data;
    }
  
}