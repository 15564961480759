import React from 'react';
import styled, { css } from 'styled-components'
import { CTAButtonAgencyFlow } from 'app/events/components/authorized-event-page/common/banners/not-registered/cta-button'
import { maxDevice } from "media/styles/mixins/breakpoints";

interface OwnProps {
    onRegister: () => void;
    isRegistered: boolean;
    title: string;
    date: string;
    altDate?: string;
    eventLocation?: string;
    pendingAgencyRequest: boolean;
    description?: string;
    className?: string;
    showLimitedButton?: boolean;
    darkBackgroundMode?: boolean;
    altTitle?: string;
    altClassName?: string;
    infoText?: string;
    upperTitle?: string;
}

export default function BannerNotRegistered({
    onRegister,
    isRegistered,
    title,
    date,
    altDate = null,
    eventLocation,
    pendingAgencyRequest,
    description,
    className,
    showLimitedButton = true,
    darkBackgroundMode = false,
    altTitle = null,
    altClassName = null,
    infoText,
    upperTitle,
}: OwnProps) {
    return (
        <Banner className={className} blackIcons={darkBackgroundMode}>
            <div className="title">
                <p>
                    <span>TransferRoom Summit <b>İ</b>stanbul</span>
                </p>
            </div>
            <div className="subtitle">
                {description || 'Exclusively for decision makers and Trusted Agents. By Invitation Only.'}
            </div>
            <div className='event-label'>
                <label>
                    <div className='label-item'>
                        <div>
                            <span>İ</span>stanbul
                        </div>
                    </div>
                    <div className='label-item'>
                        <div>
                            Nov 11 + 12
                        </div>
                    </div>
                </label>
                <label>
                    <div className='label-item'>
                        <div>
                            Online
                        </div>
                    </div>
                    <div className='label-item'>
                        <div>
                            Nov 14
                        </div>
                    </div>
                </label>
            </div>

            {pendingAgencyRequest && (
                <div className="confirmed-label">
                    <span>
                        {/* <div className="confirmed-label__icon"><i className="fas fa-check"></i> </div> */}
                        REGISTRATION PENDING
                    </span>
                </div>
            )}
            {isRegistered && (
                <div className="confirmed-label">
                    <span>
                        {/* <div className="confirmed-label__icon"><i className="fas fa-check"></i> </div> */}
                        REGISTRATION CONFIRMED
                    </span>
                </div>
            )}

            {!pendingAgencyRequest && !isRegistered && <CTAButtonAgencyFlow
                onRegister={onRegister}
                isPending={pendingAgencyRequest}
                isRegistered={isRegistered}
            />}
            {showLimitedButton && !pendingAgencyRequest && !isRegistered && <div className="spaces">
                <div className="label">
                    <div>LIMITED SPACES SIGN UP NOW</div>
                </div>
            </div>}
        </Banner>
    )
}

const Banner = styled.section<any>`
    color: white;
    height: auto;
    text-align: center;
    background-color: #0E854C;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 100%;
    padding: 51px 20px 60px;

    @media ${maxDevice.mobileS} {
        padding: 100px 30px 63px;
    }

    .title {
        margin: 0 0 15px;
        font-size: 54px;
        font-weight: bold;
        p {
            &>span {
                color: #FFF;
                text-align: center;
                font-size: 45px;
                font-weight: 700;
                line-height: normal;

                @media ${maxDevice.tabletM} {
                    font-size: 35px;
                }

                @media ${maxDevice.mobileM} {
                    text-shadow: 0px 4px 16px rgba(19, 26, 54, 0.50);
                    font-size: 28px;
                }

                &>b {
                    font-family: Inter;
                    font-weight: 600;
                }
            }
        }
    }
    .subtitle {
        display: block;
        margin-bottom: 25px;
        color:#ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;

        @media ${maxDevice.tabletM} {
            font-size: 16px;
        }

        @media ${maxDevice.mobileS} {
            display: none;
        }
    }
    .event-label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 30px;
        
        @media ${maxDevice.mobileS} {
            flex-direction: column;
            align-items: center;
            margin-bottom: 23px;
            gap: 10px;
        }

        &>label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
            border-radius: 69.067px;
            border: 1.233px solid #FFF;
            background: rgba(255, 255, 255, 0.10);

            @media ${maxDevice.mobileM} {
                border-radius: 56px;
                border: 1px solid #FFF;
            }

            .label-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 37px;
                
                @media ${maxDevice.mobileM} {
                    height: 30px;
                }
                
                & div {
                    color: #FFF;
                    font-size: 18.5px;
                    font-weight: 700;
                    line-height: normal;
                    padding: 0 12px;

                    @media ${maxDevice.mobileM} {
                        font-size: 15px;
                    }

                    & span {
                        font-family: Inter;
                        font-weight: 600;
                    }
                }

                &:first-child {
                    border-right: 1.233px solid #FFF;
					border-radius: 69.067px 0px 0px 69.067px;
                    background: rgba(255, 255, 255, 0.25);
                }
            }
                
            @media ${maxDevice.mobileM} {
                font-size: 17px;
                max-width: fit-content;
            }
        }
    }

    .confirmed-label {
        padding-top: 7px;
        padding-bottom: 9px;
        background-color: white;
        display: inline-block;
        /* width: fit-content; */
        width: 206px;
        margin: auto 0;
        position: relative;
        border-radius: 12px;
        text-transform: uppercase;
        color: #0E9655;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        span {
            top: 1px;
            position: relative;
            display: flex;
            justify-content: center;
            padding-right: 3px;
            white-space: nowrap;
        }
        &__icon {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #0E9655;
            position: relative;
            margin-right: 5px;
            > i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 11px;
            }
            
        }
    }
    .spaces{
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .label{
            border-radius: 5px;
            display:flex;
            flex-direction: column;
            padding: 10px 20px;
            background-color: #ffffff;
            width: 250px;
            color:red;
        }
    }
    
    &> button {
        background: #FFF;
        color: #131A36;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.16px;
        min-height: 50px;
        border-radius: 5px;
        border: 1px solid #FFF;

        @media ${maxDevice.mobileM} {
            min-height: 48px;
            font-size: 14px;
            letter-spacing: -0.14px;
        }

        &:hover {
            background: #FFF;
            opacity: 0.9;
        }

        @media ${maxDevice.mobileS} {
            width: calc(100% - 20px);
            min-height: 48px;
        }
    }
`