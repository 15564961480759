import compose from 'lodash/fp/compose';
import { COMPETITION_FETCH } from 'store/actionTypes';

const INITIAL_STATE = {
    byId: {},
    allIds: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPETITION_FETCH.SUCCESS: {
            if (!action.payload.data.result.length) {
                return {
                    ...state,
                    byId: {},
                    allIds: [],
                };
            }
            const { competitions } = action.payload.data.entities;
            return {
                ...state,
                byId: competitions,
                allIds: Object.keys(competitions).map(Number),
            };
        }
        default:
            return state;
    }
};

const path = state => state.entities.competitions;

export const getCompetitions = compose(
    competitions => competitions.allIds.map(id => competitions.byId[id]),
    path,
);

export const getCompetitionsById = compose(
    competitions => competitions.byId,
    path,
);

export const getCompetition = compose(
    competitions => id => competitions.byId[id],
    path,
);
